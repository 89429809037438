import React from 'react'
import { createSvgIcon } from '@mui/material'

export const EyeIcon = createSvgIcon(
    <svg width="25" height="18" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.75861 10.0001C9.75861 11.0888 10.1911 12.133 10.961 12.9028C11.7308 13.6727 12.7749 14.1052 13.8637 14.1052C14.9524 14.1052 15.9966 13.6727 16.7664 12.9028C17.5363 12.133 17.9688 11.0888 17.9688 10.0001C17.9688 8.91136 17.5363 7.86722 16.7664 7.09737C15.9966 6.32752 14.9524 5.89502 13.8637 5.89502C12.7749 5.89502 11.7308 6.32752 10.961 7.09737C10.1911 7.86722 9.75861 8.91136 9.75861 10.0001Z"
            fill="#919191"
            fillOpacity="0.31"
        />
        <path
            d="M27 10C27 10 22.0739 0.968872 13.8638 0.968872C5.65363 0.968872 0.727539 10 0.727539 10C0.727539 10 5.65363 19.0312 13.8638 19.0312C22.0739 19.0312 27 10 27 10ZM13.8638 15.7471C12.3395 15.7471 10.8777 15.1416 9.79996 14.0638C8.72217 12.9861 8.11667 11.5243 8.11667 10C8.11667 8.4758 8.72217 7.01401 9.79996 5.93622C10.8777 4.85843 12.3395 4.25293 13.8638 4.25293C15.388 4.25293 16.8498 4.85843 17.9276 5.93622C19.0054 7.01401 19.6109 8.4758 19.6109 10C19.6109 11.5243 19.0054 12.9861 17.9276 14.0638C16.8498 15.1416 15.388 15.7471 13.8638 15.7471Z"
            fill="#919191"
            fillOpacity="0.31"
        />
    </svg>,
    'EyeIcon',
)
