import { useEffect, useState } from 'react'
import { AccountStatus } from 'shared/types'

const getOnLineStatus = () =>
    typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
        ? AccountStatus.ONLINE
        : AccountStatus.OFFLINE

export const useGetConnectionStatus = () => {
    const [status, setStatus] = useState(getOnLineStatus())

    useEffect(() => {
        const setOnline = () => setStatus(AccountStatus.ONLINE)
        const setOffline = () => setStatus(AccountStatus.OFFLINE)

        window.addEventListener('online', setOnline)
        window.addEventListener('offline', setOffline)

        return () => {
            window.removeEventListener('online', setOnline)
            window.removeEventListener('offline', setOffline)
        }
    }, [])

    return status
}
