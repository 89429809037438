/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import { ConfigToken, FetchStatus } from 'shared/types'
import { ConfigSlice } from 'shared/types'
import { getConfig } from './hooks'
import { RootState } from 'stores/store'
import { SvgIcon } from '@mui/material'
import { EstarTokenIcon, MufTokenIcon, OuroTokenIcon } from 'shared/icons'

const initialState: ConfigSlice = {
    id: '',
    tokens: [],
    isUnderMaintenance: false,
    status: FetchStatus.IDLE,
}

const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getConfig.pending, (state) => {
            state.status = FetchStatus.PENDING
        })
        builder.addCase(getConfig.fulfilled, (state, action) => {
            state.id = action.payload.id
            state.tokens = action.payload.tokens
            state.isUnderMaintenance = action.payload.isUnderMaintenance
            state.status = FetchStatus.SUCCESS
        })
        builder.addCase(getConfig.rejected, (state) => {
            state.status = FetchStatus.FAIL
        })
    },
})

export const getToken = (state: RootState, identifier: string): ConfigToken | undefined => {
    const tokens = state.config.tokens
    const token = tokens.find((token) => token.identifier === identifier)
    return token
}

export const getTokenName = (state: RootState, identifier: string): string => {
    const tokens = state.config.tokens
    const token = tokens.find((token) => token.identifier === identifier)
    return token ? token.name : 'TOKEN-NOT-FOUND'
}

export const getTokenDecimals = (state: RootState, identifier: string): number => {
    const tokens = state.config.tokens
    const token = tokens.find((token) => token.identifier === identifier)
    return token ? token.decimals : 18
}

export const getTokenFightIndex = (state: RootState, identifier?: string): number => {
    const tokens = state.config.tokens
    const token = tokens.find((token) => token.identifier === (identifier ? identifier : state.account.data.token))
    return token ? token.fight_index : 1
}

export const getTokenCharacterUpgradeIndex = (state: RootState, identifier?: string): number => {
    const tokens = state.config.tokens
    const token = tokens.find((token) => token.identifier === (identifier ? identifier : state.account.data.token))
    return token ? token.character_upgrade_index : 1
}

export const getTokenMedicIndex = (state: RootState, identifier?: string): number => {
    const tokens = state.config.tokens
    const token = tokens.find((token) => token.identifier === (identifier ? identifier : state.account.data.token))
    return token ? token.medic_index : 1
}

export const getTokenWheelIndex = (state: RootState, identifier?: string): number => {
    const tokens = state.config.tokens
    const token = tokens.find((token) => token.identifier === (identifier ? identifier : state.account.data.token))
    return token ? token.wheel_index : 1
}

export const getTokenIcon = (state: RootState, identifier?: string): typeof SvgIcon => {
    const tokens = state.config.tokens
    const token = tokens.find((token) => token.identifier === (identifier ? identifier : state.account.data.token))
    if (!token) return MufTokenIcon

    switch (token.name) {
        default:
            return MufTokenIcon
        case 'OURO':
            return OuroTokenIcon
        case 'ESTAR':
            return EstarTokenIcon
    }
}

export default eventSlice.reducer
