import React from 'react'
import { createSvgIcon } from '@mui/material'

export const MufTokenIcon = createSvgIcon(
    <svg width={200} height={200} viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M100 200C73.4784 200 48.043 189.464 29.2893 170.711C10.5357 151.957 0 126.522 0 100V97.9375H4.09375L0 97.875C0.578124 73.8086 9.79172 50.7527 25.9589 32.9161C42.1261 15.0794 64.1685 3.6516 88.0625 0.71875H88.4063C90.1875 0.5 92.0937 0.3125 93.9062 0.1875H94.25C96.25 0 98.1563 0 100 0H105.5L108.625 0.25H110.687C111.969 0.25 113.281 0.53125 114.562 0.71875H115.625C141.219 4.7807 164.222 18.6588 179.75 39.4062L184.125 45.9062C194.132 61.4228 199.628 79.4155 200 97.875V100C200 126.522 189.464 151.957 170.711 170.711C151.957 189.464 126.522 200 100 200ZM13.7187 98.1875V100C13.7187 122.891 22.8124 144.845 38.9991 161.032C55.1858 177.219 77.1397 186.312 100.031 186.312C122.923 186.312 144.877 177.219 161.063 161.032C177.25 144.845 186.344 122.891 186.344 100V98.1875C186.001 80.0846 179.88 62.5652 168.875 48.1875L165.75 44.125C152.382 28.545 133.882 18.2591 113.594 15.125H112.656C111.562 14.9375 110.437 14.8125 109.312 14.6875H107.656L104.75 14.4688H94.625C93.0313 14.4688 91.5 14.7187 89.7812 14.9062H89.4375C68.9454 17.4455 50.041 27.2419 36.1503 42.52C22.2595 57.7981 14.3016 77.5469 13.7187 98.1875Z"
            fill="#801A01"
        />
        <path
            d="M100.437 13.0938C83.2704 13.1247 66.497 18.2394 52.2335 27.7924C37.9699 37.3454 26.8551 50.9089 20.2914 66.7716C13.7276 82.6343 12.0089 100.086 15.352 116.924C18.6952 133.763 26.9504 149.234 39.0763 161.386C51.2023 173.538 66.6556 181.826 83.4868 185.206C100.318 188.585 117.773 186.904 133.65 180.374C149.527 173.845 163.114 162.759 172.698 148.516C182.281 134.273 187.432 117.511 187.5 100.344V100C187.559 81.0182 181.34 62.5494 169.812 47.4688L166.687 43.5C158.996 34.5248 149.557 27.2109 138.946 22.0046C128.335 16.7984 116.774 13.8089 104.969 13.2187H100.437V13.0938Z"
            fill="url(#paint0_linear_1777_96)"
        />
        <path
            d="M193.75 99.9998C193.793 118.435 188.368 136.468 178.16 151.818C167.952 167.169 153.421 179.147 136.404 186.238C119.388 193.328 100.651 195.212 82.5629 191.651C64.4754 188.09 47.8503 179.245 34.791 166.234C21.7316 153.222 12.8248 136.63 9.19726 118.556C5.56973 100.481 7.38451 81.7372 14.412 64.6945C21.4395 47.6518 33.3639 33.0764 48.6768 22.8121C63.9896 12.5478 82.0028 7.05588 100.438 7.0311C112.672 6.99823 124.792 9.37799 136.106 14.0342C147.42 18.6905 157.704 25.5318 166.371 34.1668C175.038 42.8018 181.917 53.061 186.615 64.3573C191.313 75.6537 193.738 87.7655 193.75 99.9998Z"
            fill="url(#paint1_linear_1777_96)"
        />
        <path
            opacity={0.49}
            d="M100.437 7.0311C88.2163 7.01878 76.1125 9.41372 64.8169 14.0792C53.5213 18.7446 43.2553 25.5892 34.6049 34.2222C25.9545 42.8551 19.0893 53.1073 14.4011 64.3935C9.71287 75.6797 7.29356 87.7787 7.28125 99.9998V103.375C32.9001 125.86 65.9153 138.1 100 137.75C134.591 138.065 168.043 125.398 193.75 102.25V99.9998C193.738 87.7655 191.313 75.6537 186.615 64.3573C181.917 53.061 175.038 42.8018 166.371 34.1668C157.704 25.5318 147.42 18.6905 136.106 14.0342C124.792 9.37799 112.672 6.99823 100.437 7.0311Z"
            fill="white"
        />
        <path
            opacity={0.49}
            d="M174.781 43.75C166.045 32.2968 154.774 23.0233 141.853 16.6567C128.931 10.2902 114.71 7.00382 100.305 7.05528C85.9007 7.10675 71.7038 10.4947 58.8281 16.9534C45.9523 23.4121 34.748 32.7659 26.0938 44.2813C48.3566 57.8277 73.94 64.9348 100 64.8125C126.413 64.9736 152.335 57.6726 174.781 43.75Z"
            fill="white"
        />
        <path
            opacity={0.49}
            d="M100.469 12.4998C124.681 12.4892 147.945 21.9156 165.32 38.7778C182.696 55.64 192.816 78.6103 193.531 102.812V100.156C192.784 75.9725 182.658 53.0282 165.294 36.1785C147.931 19.3289 124.693 9.89582 100.498 9.87552C76.3025 9.85522 53.0487 19.2493 35.6569 36.0698C18.2651 52.8903 8.09998 75.8175 7.3125 99.9998V102.656C8.06048 78.4626 18.2083 55.5134 35.6015 38.6802C52.9948 21.8471 76.2639 12.4557 100.469 12.4998Z"
            fill="white"
        />
        <path
            d="M100.437 189.062C76.1012 189.085 52.7251 179.57 35.3222 162.558C17.9192 145.547 7.8751 122.393 7.34375 98.0625V100C7.34375 124.719 17.1633 148.426 34.6423 165.905C52.1213 183.384 75.8279 193.203 100.547 193.203C125.266 193.203 148.972 183.384 166.451 165.905C183.93 148.426 193.75 124.719 193.75 100V98.0625C193.202 122.426 183.123 145.603 165.676 162.617C148.229 179.631 124.807 189.126 100.437 189.062Z"
            fill="#B94C02"
        />
        <path
            d="M176.469 100C176.5 115.038 172.069 129.747 163.736 142.266C155.404 154.785 143.546 164.55 129.661 170.326C115.777 176.102 100.491 177.63 85.7379 174.715C70.9851 171.8 57.4285 164.574 46.7841 153.952C36.1397 143.329 28.8859 129.788 25.9407 115.041C22.9955 100.294 24.4914 85.0048 30.2391 71.1085C35.9867 57.2122 45.7277 45.3334 58.2292 36.9755C70.7307 28.6176 85.4307 24.1563 100.469 24.1563C120.603 24.1397 139.92 32.12 154.172 46.3426C168.424 60.5652 176.444 79.8656 176.469 100Z"
            fill="url(#paint2_linear_1777_96)"
        />
        <path
            d="M100.437 24.156C89.8349 24.1599 79.351 26.3868 69.6622 30.6929C59.9735 34.999 51.2952 41.2887 44.1875 49.156C58.5714 36.1881 77.3664 29.2058 96.7282 29.6374C116.09 30.069 134.555 37.882 148.347 51.478C162.138 65.0741 170.214 83.4259 170.922 102.779C171.631 122.133 164.917 141.026 152.156 155.594C163.353 145.225 171.16 131.719 174.556 116.842C177.953 101.965 176.781 86.409 171.193 72.2088C165.605 58.0087 155.863 45.8252 143.239 37.2518C130.615 28.6784 115.697 24.1142 100.437 24.156Z"
            fill="#A73E01"
        />
        <path
            opacity={0.17}
            d="M53.125 144.875C53.1843 130.782 57.1596 116.982 64.6071 105.017C72.0546 93.0523 82.6812 83.3928 95.3003 77.1172C107.92 70.8417 122.035 68.1971 136.07 69.4787C150.105 70.7603 163.507 75.9178 174.781 84.3751C172.279 72.3379 166.894 61.0873 159.089 51.5886C151.283 42.09 141.289 34.6269 129.965 29.8396C118.641 25.0523 106.324 23.0838 94.0718 24.1029C81.8196 25.122 69.9975 29.0983 59.6199 35.6907C49.2422 42.2832 40.6188 51.2948 34.4896 61.9526C28.3603 72.6103 24.9081 84.5959 24.4293 96.8811C23.9504 109.166 26.4591 121.384 31.7402 132.487C37.0212 143.589 44.917 153.245 54.75 160.625C53.6795 155.443 53.1351 150.166 53.125 144.875Z"
            fill="#801A01"
        />
        <path
            d="M100.437 171.875C80.6604 171.883 61.6606 164.175 47.4791 150.39C33.2976 136.605 25.0534 117.832 24.5 98.0625V100.25C24.5 120.406 32.5071 139.737 46.7599 153.99C61.0127 168.243 80.3435 176.25 100.5 176.25C120.656 176.25 139.987 168.243 154.24 153.99C168.493 139.737 176.5 120.406 176.5 100.25V98.0625C175.938 117.851 167.674 136.638 153.467 150.425C139.261 164.211 120.234 171.907 100.437 171.875Z"
            fill="#B94C02"
        />
        <path
            d="M37.5 36.7813C23.9687 48.5001 13 70.1563 13.2812 82.8126C13.5625 95.4688 20.125 75.6251 23.5312 68.4063C26.9375 61.1876 43.9375 39.2501 56.25 32.6251C68.5625 26.0001 58.75 18.4376 37.5 36.7813Z"
            fill="white"
        />
        <path
            d="M170.094 129.688L168.656 123.438L167.031 121.875L166.844 121.687H166.688L166.406 121.469L165.625 120.813L165.438 120.625L165 120.25V120.094C164.877 119.731 164.814 119.351 164.813 118.969V50.1562L172 38.7188L171.781 36.4062L166.25 31.5312L164.094 31.1875L100 66.5625L38.6875 29.0625L36.5625 29.375H34.2188L29.2188 36.4062L29 38.7188L36.1875 50.1562V118.75C36.1815 119.12 36.129 119.487 36.0313 119.844L35.7813 120.031L35.5938 120.219L35.1875 120.594L34.9687 120.781L34.7188 120.969L33.4375 121.875H33.25L31.6563 123.281L30.25 129.531L31.0938 131.594L62.5 150.375H64.1875L69.125 147.469L69.2813 147.187L69.5 146.781L69.6875 146.469V145.781L70.5625 144.156V92.2187L81.9688 98.875V145.75C81.7032 146.123 81.3487 146.424 80.9375 146.625C80.5424 146.841 80.1054 146.969 79.6563 147L78.0938 148.438L77.9375 148.625L76.5 154.875L77.3438 156.906L97.25 168.562L98.0938 168.781H101.219L102.063 168.562L121.969 156.906L122.813 154.844V154.656L121.375 148.406V147.375L119.781 145.938C119.37 145.912 118.967 145.804 118.597 145.622C118.227 145.44 117.896 145.186 117.625 144.875L117.406 144.625V97.75L128.844 91.0312V143.469L129.719 145.062L130 145.531L130.25 145.969L130.875 147.094L135.844 150H137.5L169.344 131.406L170.219 129.375L170.094 129.688Z"
            fill="#801A01"
        />
        <path
            d="M166.812 127.25H163.719C162.792 127.166 161.885 126.934 161.031 126.563C160.874 126.461 160.707 126.377 160.531 126.312C159.381 124.779 158.757 122.916 158.75 121V48.6875L166.781 35.9375H166.594V35.5938H165.344V35.4062H165.187V35.25H164.844V34.9375H164.687V34.7812H164.375V34.625H164.219L100 72.5625L36.25 33.5H36.0937V33.6563H35.7812V33.8125H35.4687V33.9687H35.3125V34.125H35.1562V34.3125H34.9687V34.4687H34.3437V34.625H34.1562V34.7812H34V34.9375H33.8437V35.0937H33.6875L41.6875 47.8438V118.75C41.6968 120.669 41.0704 122.537 39.9063 124.063C38.9437 124.595 37.878 124.915 36.7813 125H33L64.875 143.594V83.375L86.25 95.875V146.875C85.6752 148.36 84.702 149.657 83.4375 150.625C82.2319 151.588 80.7587 152.155 79.2187 152.25H78.5312L100 166.063L119.906 154.437H119.187C117.646 154.333 116.168 153.779 114.937 152.844C113.68 151.877 112.717 150.578 112.156 149.094V95.875L133.5 83.375V143.438L165.406 124.844H165.219L166.812 127.25Z"
            fill="url(#paint3_linear_1777_96)"
        />
        <path
            d="M100 163.875L81.0313 152.25C82.9405 152.121 84.7436 151.324 86.125 150C87.5263 148.582 88.4073 146.732 88.625 144.75V93.4062L66.75 79.9687V143.437L36.2812 125C38.3837 124.83 40.3358 123.843 41.7188 122.25C43.1539 120.605 43.9424 118.495 43.9375 116.312V46.25L36.2812 33.5L100 72.5625L163.781 33.5L156.25 46.25V116.187C156.245 118.37 157.034 120.48 158.469 122.125C159.852 123.718 161.804 124.705 163.906 124.875L133.375 143.437V79.9687L111.5 93.4062V144.625C111.703 146.611 112.586 148.466 114 149.875C115.347 151.25 117.144 152.093 119.062 152.25L100 163.875Z"
            fill="url(#paint4_linear_1777_96)"
        />
        <path
            d="M100 163.031L81.0312 151.625C82.9608 151.46 84.7722 150.627 86.1529 149.269C87.5336 147.911 88.3969 146.114 88.5938 144.187V93.9687L66.7187 80.8125V143.031L36.2812 124.812C38.3972 124.616 40.3612 123.628 41.7805 122.047C43.1999 120.465 43.9702 118.406 43.9375 116.281V47.7187L36.2812 35.2188L100 73.5312L163.812 35.25L156.156 47.75V116.281C156.116 118.408 156.884 120.471 158.305 122.054C159.726 123.637 161.694 124.623 163.812 124.812L133.375 143.031V80.8125L111.5 93.9687V144.156C111.69 146.088 112.55 147.893 113.932 149.257C115.313 150.621 117.128 151.459 119.062 151.625L100 163.031Z"
            fill="url(#paint5_linear_1777_96)"
        />
        <path
            opacity={0.1}
            d="M100 163.031L81.0312 151.625C82.9608 151.46 84.7722 150.627 86.1529 149.269C87.5336 147.911 88.3969 146.114 88.5938 144.187V93.9687L66.7187 80.8125V143.031L36.2812 124.812C38.3972 124.616 40.3612 123.628 41.7805 122.047C43.1999 120.465 43.9702 118.406 43.9375 116.281V47.7187L36.2812 35.2188L100 73.5312L163.812 35.25L156.156 47.75V116.281C156.116 118.408 156.884 120.471 158.305 122.054C159.726 123.637 161.694 124.623 163.812 124.812L133.375 143.031V80.8125L111.5 93.9687V144.156C111.69 146.088 112.55 147.893 113.932 149.257C115.313 150.621 117.128 151.459 119.062 151.625L100 163.031Z"
            fill="url(#paint6_linear_1777_96)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_1777_96"
                x1={13.2812}
                y1={100}
                x2={187.656}
                y2={100}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FD8403" />
                <stop offset={1} stopColor="#821C01" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_1777_96"
                x1={100.438}
                y1={-1.37515}
                x2={100.438}
                y2={173.656}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F47D03" />
                <stop offset={1} stopColor="#D16102" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_1777_96"
                x1={114.656}
                y1={210.094}
                x2={97.5}
                y2={77.0625}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F47D03" />
                <stop offset={1} stopColor="#D16102" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_1777_96"
                x1={100.063}
                y1={166.313}
                x2={100.063}
                y2={33.5}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#CACBCC" />
                <stop offset={0.57} stopColor="#828484" />
                <stop offset={0.72} stopColor="#8B8D8D" />
                <stop offset={0.97} stopColor="#A2A4A4" />
                <stop offset={1} stopColor="#A6A8A8" />
            </linearGradient>
            <linearGradient
                id="paint4_linear_1777_96"
                x1={36.2812}
                y1={98.6875}
                x2={163.812}
                y2={98.6875}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FDFAF7" />
                <stop offset={1} stopColor="#E5E6E7" />
            </linearGradient>
            <linearGradient
                id="paint5_linear_1777_96"
                x1={100.062}
                y1={35.25}
                x2={100.062}
                y2={163.031}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E5E6E7" />
                <stop offset={1} stopColor="#CACBCC" />
            </linearGradient>
            <linearGradient
                id="paint6_linear_1777_96"
                x1={89.8125}
                y1={25.25}
                x2={115.062}
                y2={160.25}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.25} stopColor="white" />
                <stop offset={0.31} stopColor="#CACBCC" stopOpacity={0} />
                <stop offset={0.41} stopColor="#CACBCC" stopOpacity={0} />
                <stop offset={0.42} stopColor="white" />
                <stop offset={0.46} stopColor="white" />
                <stop offset={0.48} stopColor="#CACBCC" stopOpacity={0} />
                <stop offset={0.58} stopColor="#CACBCC" stopOpacity={0} />
                <stop offset={0.59} stopColor="white" />
                <stop offset={0.65} stopColor="white" />
                <stop offset={0.66} stopColor="#CACBCC" stopOpacity={0} />
            </linearGradient>
        </defs>
    </svg>,
    'MufTokenIcon',
)
