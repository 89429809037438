import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { APIEndpoint } from 'shared/types'
import { API_URL } from '../../../config'
import { RootState } from 'stores/store'

export const claimQuestBigReward = createAsyncThunk(
    'user/me/claim-quest-big-reward',
    async (arg, { rejectWithValue, getState }) => {
        // check if token is expired
        const token = (getState() as RootState).auth.token
        if (!token) {
            return rejectWithValue('Token is not found')
        }
        try {
            const { data: user } = await axios.post(
                API_URL + APIEndpoint.ME_CLAIM_QUEST_BIG_REWARD,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            )
            return user
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    },
)
