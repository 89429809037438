import React from 'react'
import { createSvgIcon } from '@mui/material'

export const DailyWheelArrow = createSvgIcon(
    <svg width={31} height={84} viewBox="0 0 31 84" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M30.9605 0.987809V73.1601L15.7439 83.2398L0.527344 73.1601V0.987809C5.54882 0.774642 10.6007 0.652832 15.7439 0.652832C20.8871 0.652832 25.9695 0.652832 30.9605 0.987809Z"
            fill="url(#paint0_linear_1693_1151)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_1693_1151"
                x1={15.8352}
                y1={10.0322}
                x2={15.8352}
                y2={96.2735}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFE01F" />
                <stop offset={0.3} stopColor="#FFC817" />
                <stop offset={0.93} stopColor="#FF8B02" />
                <stop offset={1} stopColor="#FF8400" />
            </linearGradient>
        </defs>
    </svg>,
    'DailyWheelArrow',
)
