import React from 'react'
import Lottie from 'lottie-react'
import { useGetConnectionStatus } from 'shared/hooks/useGetConnectionStatus'
import { AccountStatus } from 'shared/types'
import { noConnectionAnimation } from 'shared/animations'
import { Box } from '@mui/material'

const NoConnection = () => {
    const status = useGetConnectionStatus()

    if (status === AccountStatus.ONLINE) return null
    return (
        <Box
            sx={{
                position: 'absolute',
                top: '10px',
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 1,
            }}
            height="64px"
            width="64px"
        >
            <Lottie animationData={noConnectionAnimation} />
        </Box>
    )
}

export default NoConnection
