import { type Location } from 'react-router-dom'
import { SignJWT, jwtVerify } from 'jose'
import { JWT_SECRET } from 'config'
import { routes } from 'routes'

/**
 * Check if the current URL is an active one, by the location pathname.
 * Note that it will return true for sub-routes, i.e. url `/services` is active for pathname `/services/32`.
 * @param {Location}  location  Location, best provided by the `useLocation` hook from `react-router` library.
 * @param {string}    url       URL to check against, whether it's active or not.
 */
const checkIfUrlIsActive = (location: Location, url: string): boolean => {
    if (['/'].includes(url)) return location.pathname === url
    return location.pathname.startsWith(url)
}

const getPageNameUsingPath = (path: string): string => {
    const route = routes.find((route) => route.path === path)

    return route !== undefined ? route.name : 'Multifights'
}

const signToken = async (payload: any, expiration?: string): Promise<string> =>
    await new SignJWT({
        ...payload,
    })
        .setProtectedHeader({ alg: 'HS256' })
        .setIssuedAt()
        .setExpirationTime(expiration ? expiration : '24h')
        .sign(new TextEncoder().encode(JWT_SECRET))

const updateToken = async (token: string, data: object): Promise<string> => {
    const secret = new TextEncoder().encode(JWT_SECRET)
    const { payload } = await jwtVerify(token, secret)

    const modifiedPayload = {
        ...payload,
        ...data,
    }
    return await signToken(modifiedPayload)
}

const getUnixTimestamp = (): number => Math.floor(Date.now() / 1000)

const toReadableFormat = (amount: number): string =>
    amount.toLocaleString('en-EN', {
        maximumFractionDigits: 2,
    })

const toUTCDate = (date: string): string =>
    new Date(date)
        .toLocaleDateString('en-GB', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            timeZone: 'UTC',
        })
        .replace(',', '')

const isNumber = (str: string) => {
    if (str.trim() === '') {
        return false
    }

    return !isNaN(Number(str))
}

const cutString = (str: string, start: number, end?: number): string =>
    `${str.slice(0, start)}...${str.slice(end ? -end : start)}`

// REMOVE AFTER EVENT
const toEventDate = (deadline: number) => {
    const date = new Date(deadline * 1000 + 1000)
    const options: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'short',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: 'UTC',
        hour12: false,
    }
    const formattedDate = date.toLocaleDateString('en-US', options)
    return formattedDate + ' UTC'
}

export {
    checkIfUrlIsActive,
    getPageNameUsingPath,
    signToken,
    updateToken,
    getUnixTimestamp,
    toReadableFormat,
    toUTCDate,
    isNumber,
    cutString,
    // REMOVE AFTER EVENT
    toEventDate,
}
