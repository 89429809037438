import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { APIEndpoint } from 'shared/types'
import { API_URL } from '../../../config'
import { RootState } from 'stores/store'

export const increaseCharacterEnergy = createAsyncThunk(
    'user/me/increase-character-energy',
    async ({ identifier, amount }: { identifier: string; amount: number }, { rejectWithValue, getState }) => {
        // check if token is expired
        const token = (getState() as RootState).auth.token
        if (!token) {
            return rejectWithValue('Token is not found')
        }
        try {
            const { data } = await axios.patch(
                API_URL + APIEndpoint.UPGRADE_CHARACTER + identifier,
                {
                    amount,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            )
            return data
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    },
)
