import React from 'react'
import { createSvgIcon } from '@mui/material'

export const CharacterRentedIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 334.51 384.62">
        <defs>
            <linearGradient
                id="linear-gradient"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-8.01 14.57) scale(1.05 1)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0} stopColor="#475569" />
                <stop offset={0.57} stopColor="#3c4759" />
                <stop offset={0.73} stopColor="#364050" />
                <stop offset={0.99} stopColor="#272e39" />
                <stop offset={1} stopColor="#262d38" />
            </linearGradient>
            <linearGradient
                id="linear-gradient-3"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-7.85 14.29) scale(1.05 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-5"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-7.69 14) scale(1.05 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-7"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-7.54 13.72) scale(1.05 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-9"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-7.38 13.44) scale(1.04 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-11"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-7.22 13.16) scale(1.04 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-13"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-7.07 12.88) scale(1.04 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-15"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-6.91 12.6) scale(1.04 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-17"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-6.75 12.32) scale(1.04 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-19"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-6.6 12.04) scale(1.04 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-21"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-6.44 11.76) scale(1.04 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-23"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-6.28 11.48) scale(1.04 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-25"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-6.13 11.2) scale(1.04 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-27"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-5.97 10.92) scale(1.04 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-29"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-5.82 10.64) scale(1.03 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-31"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-5.66 10.36) scale(1.03 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-33"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-5.51 10.08) scale(1.03 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-35"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-5.35 9.8) scale(1.03 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-37"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-5.19 9.52) scale(1.03 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-39"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-5.04 9.24) scale(1.03 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-41"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-4.88 8.96) scale(1.03 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-43"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-4.73 8.68) scale(1.03 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-45"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-4.58 8.4) scale(1.03 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-47"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-4.42 8.12) scale(1.03 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-49"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-4.27 7.84) scale(1.03 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-51"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-4.11 7.56) scale(1.02 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-53"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-3.96 7.28) scale(1.02 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-55"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-3.8 7) scale(1.02 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-57"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-3.65 6.72) scale(1.02 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-59"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-3.5 6.44) scale(1.02 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-61"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-3.34 6.16) scale(1.02 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-63"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-3.19 5.88) scale(1.02 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-65"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-3.04 5.6) scale(1.02 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-67"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-2.88 5.32) scale(1.02 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-69"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-2.73 5.04) scale(1.02 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-71"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-2.58 4.76) scale(1.02 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-73"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-2.42 4.48) scale(1.01 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-75"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-2.27 4.2) scale(1.01 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-77"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-2.12 3.92) scale(1.01 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-79"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-1.97 3.64) scale(1.01 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-81"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-1.82 3.36) scale(1.01 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-83"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-1.66 3.08) scale(1.01 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-85"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-1.51 2.8) scale(1.01 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-87"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-1.36 2.52) scale(1.01 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-89"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-1.21 2.24) scale(1.01 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-91"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-1.06 1.96) scale(1.01 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-93"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-0.91 1.68) scale(1.01 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-95"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-0.75 1.4)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-97"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-0.6 1.12)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-99"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-0.45 0.84)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-101"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-0.3 0.56)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-103"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(-0.15 0.28)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-105"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-107"
                x1={21.5}
                y1={185.17}
                x2={313.01}
                y2={185.17}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0} stopColor="#edeef1" />
                <stop offset={1} stopColor="#c9cdd5" />
            </linearGradient>
            <linearGradient
                id="linear-gradient-109"
                x1={167.26}
                y1={14}
                x2={167.26}
                y2={356.33}
                gradientTransform="translate(0 6.5) scale(1 0.98)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0} stopColor="#a2aab7" />
                <stop offset={1} stopColor="#788496" />
            </linearGradient>
        </defs>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M21.61,133.94c0-11.94.18-23.88,0-35.82-.39-20.48,14.58-37.31,36.74-38.45,20.82-1.07,40.93-5.6,59.34-15.6,9.15-5,17.52-11.39,26.11-17.36A49.3,49.3,0,0,0,151.24,20c7.76-8.16,23.55-7.91,31.54,0a132.15,132.15,0,0,0,40.43,27.52,154,154,0,0,0,44.47,11.53c7.05.8,14.28,1.4,21,3.47,13.21,4.07,23.08,17.77,23.53,31.61.87,26.32,1.48,52.67-.64,79-2.34,29.17-7.71,57.64-20.36,84.35-14.07,29.7-35,53.33-62.4,71.37a244.12,244.12,0,0,1-52.74,25.78,24.58,24.58,0,0,1-16.72.45C127.44,344,98.4,328.16,74.6,303.75c-22.85-23.43-37.12-51.55-44.9-83.14C22.68,192.14,21,163.12,21.61,133.94Zm118.65,80.19h-.11c0,4.69,0,9.38,0,14.06A28.13,28.13,0,0,0,144,242.54c5.47,9.39,17.52,14.73,28.18,12.37,11.32-2.52,20.2-11.76,20.88-22.81.65-10.68.58-21.4.61-32.11a7.46,7.46,0,0,1,3-6.46c15.1-12.58,20-34.67,11.93-52.63-8.38-18.63-27.92-29.85-47.55-26.77-19.19,3-32.3,13.88-37.68,32.58-5.41,18.87.21,34.9,14.88,47.88a6.42,6.42,0,0,1,1.9,4.15C140.4,203.86,140.26,209,140.26,214.13Z" />
                <path d="M160.41,209.19c0-5.36-.22-10.72.06-16.06.3-5.65-2-9.37-6.73-12.38-9.23-5.85-13.3-14.42-11.75-25.37s10.88-20.18,21.45-21.28c12.28-1.28,23.23,5.2,27.19,16.08,4.1,11.26.61,23.6-9.3,29.79-5.87,3.67-8,8.18-7.76,14.91.37,10.36,0,20.74,0,31.12a20,20,0,0,1-.14,3c-.61,4.09-3.22,6.41-6.92,6.23s-6.13-2.86-6.19-7c-.1-6.36,0-12.72,0-19.07Z" />
                <path
                    d="M166.8,384.62a40.31,40.31,0,0,1-12.62-2c-39.14-13-70-30.87-94.43-54.78S18.13,273.63,9,238.24c-6.78-26.32-9.61-55-8.89-90.15,0-3.68,0-7.36,0-11,0-8,.08-16.31-.08-24.38C-.49,84.12,22,61.47,52.44,60c21.6-1.06,39.88-5.61,55.88-13.92a197.64,197.64,0,0,0,19.52-12.21c2.15-1.46,4.29-2.91,6.45-4.34a39.26,39.26,0,0,0,5.57-4.84c6.57-6.59,16.38-10.37,26.91-10.37s20.28,3.67,27,10.07A124.64,124.64,0,0,0,231.68,49a151.7,151.7,0,0,0,42.37,10.45l2.73.29c6.76.71,14.43,1.51,21.92,3.72,19.57,5.75,34.28,24.48,35,44.52.9,25.87,1.58,53.41-.71,80.55-3,35.73-10.21,64.08-22.68,89.22-15.64,31.53-39.36,57.46-70.52,77.07-17.24,10.85-36.33,19.78-58.35,27.28A45.41,45.41,0,0,1,166.8,384.62ZM153.54,242.43a13.59,13.59,0,0,0,2.06,7.34c2.11,3.46,6.92,6,11.42,6a10.93,10.93,0,0,0,2.25-.23c5.73-1.21,10.1-5.42,10.4-10,.57-8.85.58-17.68.6-27v-4.27a21.16,21.16,0,0,1,8.41-17.18,30.17,30.17,0,0,0,8.5-36.12,33.42,33.42,0,0,0-29.61-19,29.74,29.74,0,0,0-4.53.34c-14.88,2.22-23.65,9.41-27.62,22.62s-.38,23.73,11.19,33.52a20.14,20.14,0,0,1,6.93,14c.19,4.07.16,8,.13,11.88,0,1.38,0,2.75,0,4.13v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.85,263.53c-.38,0-.75,0-1.13,0-11.66-.54-20.25-9.27-20.43-20.75-.07-4.59,0-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.14c0-.82,0-1.64.1-2.46l-.23-.14c-14.67-8.87-21.41-22.78-19-39.17,2.54-17.19,17.79-31.49,35.46-33.24a46.62,46.62,0,0,1,4.72-.24c17.82,0,33.15,10,39,25.46,6.77,17.78.3,36.88-15.74,46.45a6.48,6.48,0,0,0-1.16.83,7.18,7.18,0,0,0-.09,1.7c.3,7.68.18,15.33.08,22.73,0,3-.09,6-.1,9v.35a28.41,28.41,0,0,1-.3,4.67A21.21,21.21,0,0,1,166.85,263.53Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.8,384.34a40.25,40.25,0,0,1-12.61-2c-39.1-13-70-30.87-94.34-54.78S18.27,273.35,9.15,238C2.36,211.64-.46,183,.26,147.81c0-3.68,0-7.36,0-11,0-8,.08-16.31-.08-24.38-.56-28.55,21.94-51.2,52.32-52.7,21.58-1.06,39.84-5.61,55.83-13.92a197.53,197.53,0,0,0,19.51-12.21q3.21-2.19,6.44-4.34a39.26,39.26,0,0,0,5.57-4.84C146.45,17.79,156.25,14,166.77,14s20.26,3.68,27,10.08a124.5,124.5,0,0,0,37.83,24.64A151.33,151.33,0,0,0,274,59.17l2.72.29c6.76.71,14.42,1.51,21.9,3.72,19.55,5.75,34.25,24.48,34.94,44.52.9,25.87,1.58,53.41-.71,80.55-3,35.73-10.2,64.08-22.66,89.22-15.62,31.53-39.33,57.45-70.45,77.07-17.23,10.85-36.3,19.78-58.3,27.28A45.35,45.35,0,0,1,166.8,384.34ZM153.55,242.15a13.59,13.59,0,0,0,2.06,7.34c2.11,3.46,6.91,6,11.41,6a10.93,10.93,0,0,0,2.25-.23c5.72-1.21,10.09-5.42,10.39-10,.56-8.85.58-17.68.6-27V214a21.15,21.15,0,0,1,8.4-17.18,30.18,30.18,0,0,0,8.49-36.12,33.38,33.38,0,0,0-29.58-19,31.18,31.18,0,0,0-4.53.33c-14.86,2.23-23.63,9.42-27.59,22.63s-.38,23.73,11.18,33.52a20.13,20.13,0,0,1,6.92,14c.19,4.07.16,8,.13,11.88,0,1.38,0,2.75,0,4.13v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.85,263.25l-1.13,0c-11.65-.54-20.23-9.27-20.41-20.75-.07-4.59-.05-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.14c0-.82.05-1.64.1-2.46l-.24-.14c-14.65-8.87-21.38-22.78-19-39.17,2.55-17.19,17.78-31.49,35.43-33.24a46.77,46.77,0,0,1,4.72-.24c17.81,0,33.12,10,39,25.46,6.76,17.78.3,36.88-15.73,46.45a6.48,6.48,0,0,0-1.16.83,7.18,7.18,0,0,0-.09,1.7c.3,7.68.19,15.33.08,22.73,0,3-.09,6-.1,9v.35a29.77,29.77,0,0,1-.31,4.67A21.19,21.19,0,0,1,166.85,263.25Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.8,384.06a40.24,40.24,0,0,1-12.6-2c-39.06-13-69.9-30.87-94.25-54.78-24.51-24-41.55-54.18-50.66-89.57-6.78-26.32-9.6-55-8.88-90.15,0-3.68,0-7.36,0-11,0-8,.08-16.31-.08-24.38-.56-28.55,21.92-51.2,52.28-52.7,21.56-1.06,39.8-5.61,55.78-13.92a197.2,197.2,0,0,0,19.48-12.21c2.15-1.46,4.29-2.91,6.44-4.34a39.17,39.17,0,0,0,5.56-4.84c6.56-6.59,16.35-10.38,26.86-10.38s20.24,3.68,27,10.08a124.37,124.37,0,0,0,37.8,24.64,151.48,151.48,0,0,0,42.29,10.45l2.72.29c6.75.71,14.41,1.51,21.88,3.72,19.54,5.75,34.22,24.48,34.91,44.52.9,25.87,1.58,53.41-.71,80.55-3,35.73-10.19,64.08-22.64,89.22-15.61,31.53-39.29,57.45-70.39,77.07-17.21,10.85-36.26,19.78-58.24,27.28A45.29,45.29,0,0,1,166.8,384.06ZM153.56,241.87a13.68,13.68,0,0,0,2.06,7.34c2.11,3.46,6.9,6,11.4,6a10.83,10.83,0,0,0,2.24-.23c5.72-1.21,10.09-5.42,10.38-10,.57-8.85.59-17.68.61-27v-4.27a21.14,21.14,0,0,1,8.39-17.18,30.2,30.2,0,0,0,8.49-36.12,33.36,33.36,0,0,0-29.56-19,31.18,31.18,0,0,0-4.53.33c-14.84,2.23-23.6,9.42-27.57,22.62s-.37,23.74,11.18,33.53a20.16,20.16,0,0,1,6.91,14c.19,4.07.16,8,.13,11.88,0,1.38,0,2.75,0,4.13v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.85,263l-1.13,0c-11.63-.54-20.21-9.27-20.39-20.75-.07-4.59-.05-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.14c0-.82.05-1.64.1-2.46l-.24-.14c-14.63-8.87-21.36-22.78-18.94-39.17,2.54-17.19,17.76-31.49,35.4-33.24a46.43,46.43,0,0,1,4.71-.24c17.79,0,33.09,10,39,25.46,6.76,17.78.3,36.88-15.71,46.45a6.48,6.48,0,0,0-1.16.83,7.45,7.45,0,0,0-.08,1.7c.29,7.68.18,15.33.07,22.73,0,3-.09,6-.1,9v.35a29.77,29.77,0,0,1-.31,4.67A21.17,21.17,0,0,1,166.85,263Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.8,383.78a40.18,40.18,0,0,1-12.59-2c-39-13-69.83-30.88-94.17-54.78S18.53,272.79,9.43,237.4c-6.77-26.32-9.59-55-8.87-90.15,0-3.68,0-7.36,0-11,0-8,.08-16.31-.08-24.38C0,83.28,22.42,60.63,52.75,59.13c21.54-1.06,39.77-5.61,55.73-13.92A197.09,197.09,0,0,0,128,33q3.21-2.19,6.43-4.34a39.17,39.17,0,0,0,5.56-4.84c6.55-6.59,16.33-10.38,26.83-10.38s20.23,3.68,27,10.08a124.31,124.31,0,0,0,37.76,24.64,151.18,151.18,0,0,0,42.26,10.45l2.71.29c6.75.71,14.4,1.51,21.86,3.72,19.52,5.75,34.19,24.48,34.88,44.52.9,25.87,1.57,53.41-.7,80.55-3,35.73-10.19,64.08-22.63,89.22-15.59,31.53-39.25,57.45-70.32,77.07-17.2,10.85-36.24,19.78-58.2,27.28A45.13,45.13,0,0,1,166.8,383.78ZM153.57,241.59a13.68,13.68,0,0,0,2.06,7.34c2.11,3.46,6.9,6,11.39,6a10.83,10.83,0,0,0,2.24-.23c5.72-1.21,10.08-5.42,10.37-10,.57-8.85.59-17.68.61-27v-4.27a21.13,21.13,0,0,1,8.38-17.18,30.21,30.21,0,0,0,8.48-36.12,33.31,33.31,0,0,0-29.53-19,31,31,0,0,0-4.52.33c-14.84,2.23-23.59,9.42-27.55,22.62s-.37,23.74,11.17,33.53a20.16,20.16,0,0,1,6.91,14c.18,4.07.15,8,.12,11.88,0,1.38,0,2.75,0,4.13v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.85,262.69l-1.13,0c-11.62-.54-20.19-9.27-20.37-20.75-.07-4.59,0-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.14c0-.82,0-1.64.1-2.46l-.24-.14c-14.62-8.87-21.34-22.78-18.92-39.17,2.54-17.19,17.74-31.49,35.36-33.24a46.57,46.57,0,0,1,4.71-.24c17.78,0,33.06,10,38.93,25.46,6.75,17.78.3,36.88-15.7,46.45a6.48,6.48,0,0,0-1.16.83,7.45,7.45,0,0,0-.08,1.7c.29,7.68.18,15.33.07,22.73,0,3-.08,6-.1,9v.34a29.81,29.81,0,0,1-.31,4.68A21.16,21.16,0,0,1,166.85,262.69Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.8,383.5a39.73,39.73,0,0,1-12.57-2c-39-12.95-69.78-30.87-94.09-54.77S18.67,272.51,9.57,237.12C2.81,210.8,0,182.15.71,147c0-3.68,0-7.36,0-11,0-8,.08-16.31-.08-24.38C.11,83,22.55,60.35,52.85,58.85c21.52-1.06,39.74-5.61,55.68-13.92A195.71,195.71,0,0,0,128,32.72q3.21-2.19,6.43-4.34A38.53,38.53,0,0,0,140,23.54c6.55-6.59,16.32-10.38,26.81-10.38s20.21,3.68,27,10.08a124.13,124.13,0,0,0,37.72,24.64,151,151,0,0,0,42.22,10.45l2.72.29c6.74.71,14.37,1.51,21.83,3.72,19.51,5.75,34.16,24.48,34.86,44.52.89,25.87,1.56,53.41-.71,80.55-3,35.73-10.18,64.08-22.61,89.22-15.58,31.53-39.22,57.45-70.26,77.07-17.18,10.85-36.2,19.78-58.14,27.28A45.07,45.07,0,0,1,166.8,383.5ZM153.59,241.31a13.58,13.58,0,0,0,2,7.34c2.11,3.46,6.89,6,11.38,6a10.83,10.83,0,0,0,2.24-.23c5.71-1.21,10.07-5.42,10.36-10,.57-8.85.59-17.68.6-27v-4.27a21.19,21.19,0,0,1,8.38-17.18,30.22,30.22,0,0,0,8.47-36.12,33.29,33.29,0,0,0-29.51-19,31,31,0,0,0-4.51.33c-14.82,2.23-23.56,9.42-27.52,22.62s-.38,23.74,11.16,33.53a20.19,20.19,0,0,1,6.9,14c.19,4.07.16,8,.13,11.88,0,1.38,0,2.75,0,4.13v14h-.11Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.85,262.4c-.37,0-.75,0-1.12,0-11.62-.54-20.18-9.27-20.36-20.75-.07-4.59-.05-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.14c0-.82.05-1.64.1-2.46l-.24-.14c-14.61-8.87-21.32-22.78-18.9-39.17,2.53-17.19,17.72-31.49,35.33-33.24a46.23,46.23,0,0,1,4.7-.24c17.76,0,33,10,38.9,25.46,6.74,17.78.29,36.88-15.69,46.45a6.92,6.92,0,0,0-1.16.83,7.45,7.45,0,0,0-.08,1.7c.29,7.68.18,15.33.07,22.73,0,3-.08,6-.1,9v.34a28.64,28.64,0,0,1-.31,4.68A21.12,21.12,0,0,1,166.85,262.4Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.8,383.22a39.67,39.67,0,0,1-12.56-2c-39-12.95-69.71-30.87-94-54.77-24.44-24-41.44-54.18-50.53-89.57C3,210.52.14,181.87.86,146.69c0-3.68,0-7.36,0-11,0-8,.08-16.31-.08-24.38C.26,82.72,22.68,60.07,53,58.57c21.5-1.06,39.69-5.61,55.62-13.92A195.6,195.6,0,0,0,128,32.44c2.14-1.46,4.27-2.91,6.42-4.34A39.64,39.64,0,0,0,140,23.26c6.53-6.59,16.3-10.38,26.78-10.38S187,16.56,193.7,23A124,124,0,0,0,231.39,47.6a150.79,150.79,0,0,0,42.18,10.45l2.72.29c6.73.71,14.36,1.51,21.82,3.72,19.48,5.75,34.13,24.48,34.82,44.52.89,25.87,1.56,53.41-.71,80.55-3,35.73-10.17,64.08-22.58,89.22-15.57,31.53-39.19,57.45-70.2,77.07-17.17,10.85-36.17,19.78-58.09,27.28A45,45,0,0,1,166.8,383.22ZM153.6,241a13.67,13.67,0,0,0,2.05,7.34c2.11,3.46,6.89,6,11.37,6a10.83,10.83,0,0,0,2.24-.23c5.7-1.21,10.06-5.42,10.35-10,.57-8.85.58-17.68.6-27v-4.27a21.18,21.18,0,0,1,8.37-17.18,30.25,30.25,0,0,0,8.47-36.12,33.27,33.27,0,0,0-29.49-19,30.77,30.77,0,0,0-4.5.33c-14.81,2.23-23.55,9.42-27.5,22.62s-.38,23.74,11.14,33.53a20.15,20.15,0,0,1,6.9,14c.19,4.07.16,8,.13,11.88,0,1.38,0,2.75,0,4.13v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.85,262.12c-.37,0-.74,0-1.12,0-11.61-.54-20.16-9.27-20.34-20.75-.07-4.59,0-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.14q0-1.23.09-2.46l-.23-.14c-14.59-8.87-21.3-22.78-18.89-39.17,2.54-17.19,17.71-31.49,35.3-33.24a46.38,46.38,0,0,1,4.7-.24c17.75,0,33,10,38.86,25.46,6.74,17.78.3,36.88-15.67,46.45a7.43,7.43,0,0,0-1.16.83,7.45,7.45,0,0,0-.08,1.7c.29,7.68.18,15.33.07,22.73,0,3-.08,6-.1,9v.34a29.62,29.62,0,0,1-.3,4.68A21.13,21.13,0,0,1,166.85,262.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.8,382.94a39.61,39.61,0,0,1-12.55-2C115.32,368,84.6,350,60.33,326.13S18.93,272,9.86,236.56C3.1,210.24.29,181.59,1,146.41c0-3.68,0-7.36,0-11,0-8,.08-16.31-.08-24.38-.56-28.55,21.84-51.2,52.09-52.7,21.48-1.06,39.66-5.61,55.58-13.92a196.43,196.43,0,0,0,19.41-12.21c2.14-1.46,4.27-2.91,6.42-4.34A39,39,0,0,0,140,23c6.53-6.59,16.29-10.38,26.76-10.38s20.17,3.68,26.91,10.08a123.86,123.86,0,0,0,37.66,24.64,150.42,150.42,0,0,0,42.14,10.45l2.71.29c6.73.71,14.35,1.51,21.8,3.72,19.47,5.75,34.1,24.48,34.79,44.52.89,25.87,1.56,53.41-.71,80.55-3,35.73-10.16,64.08-22.56,89.22-15.55,31.53-39.15,57.45-70.14,77.07-17.15,10.85-36.13,19.78-58,27.28A45,45,0,0,1,166.8,382.94ZM153.61,240.75a13.67,13.67,0,0,0,2,7.34c2.11,3.46,6.88,6,11.36,6a10.83,10.83,0,0,0,2.24-.23c5.7-1.21,10.05-5.42,10.34-10,.57-8.85.58-17.68.6-27v-4.27a21.18,21.18,0,0,1,8.37-17.18A30.27,30.27,0,0,0,197,159.25a33.24,33.24,0,0,0-29.46-19,30.77,30.77,0,0,0-4.5.33c-14.79,2.23-23.52,9.42-27.47,22.62s-.38,23.74,11.13,33.53a20.18,20.18,0,0,1,6.89,14c.19,4.07.16,8,.13,11.88,0,1.38,0,2.75,0,4.13v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.85,261.84c-.37,0-.74,0-1.12,0-11.6-.54-20.14-9.27-20.32-20.75-.07-4.59-.06-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.14q0-1.23.09-2.46l-.23-.14c-14.58-8.87-21.28-22.78-18.87-39.17,2.53-17.19,17.69-31.49,35.26-33.24a46.53,46.53,0,0,1,4.7-.24c17.73,0,33,10,38.83,25.46,6.73,17.77.29,36.88-15.66,46.45a7.43,7.43,0,0,0-1.16.83,7.45,7.45,0,0,0-.08,1.7c.29,7.68.18,15.33.07,22.73,0,3-.08,6-.1,9V239a29.62,29.62,0,0,1-.3,4.68A21.12,21.12,0,0,1,166.85,261.84Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.8,382.66a39.59,39.59,0,0,1-12.54-2c-38.89-12.95-69.58-30.87-93.83-54.77S19.07,271.67,10,236.28c-6.75-26.32-9.56-55-8.84-90.15,0-3.68,0-7.36,0-11,0-8,.08-16.31-.08-24.38-.56-28.55,21.82-51.2,52-52.7C74.62,57,92.79,52.4,108.69,44.09a196.32,196.32,0,0,0,19.4-12.21c2.13-1.46,4.26-2.91,6.41-4.34A39.54,39.54,0,0,0,140,22.7c6.52-6.59,16.27-10.38,26.73-10.38S186.93,16,193.66,22.4A123.68,123.68,0,0,0,231.28,47a150.31,150.31,0,0,0,42.1,10.45l2.71.29c6.72.71,14.34,1.51,21.78,3.72C317.32,67.25,331.94,86,332.63,106c.89,25.87,1.56,53.4-.71,80.55-3,35.73-10.15,64.08-22.54,89.22-15.54,31.53-39.11,57.45-70.07,77.07-17.14,10.85-36.11,19.78-58,27.27A44.59,44.59,0,0,1,166.8,382.66ZM153.62,240.47a13.67,13.67,0,0,0,2,7.34c2.1,3.46,6.88,6,11.35,6a10.67,10.67,0,0,0,2.23-.23c5.7-1.22,10.05-5.42,10.34-10,.56-8.85.58-17.68.6-27v-4.27a21.2,21.2,0,0,1,8.36-17.18A30.3,30.3,0,0,0,197,159a33.19,33.19,0,0,0-29.43-19,30.87,30.87,0,0,0-4.5.33c-14.78,2.23-23.5,9.42-27.44,22.62s-.38,23.74,11.12,33.53a20.13,20.13,0,0,1,6.88,14c.19,4.07.16,8,.13,11.88,0,1.38,0,2.75,0,4.13v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.85,261.56c-.37,0-.74,0-1.12,0-11.58-.54-20.12-9.27-20.3-20.75-.07-4.59-.06-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.14q0-1.23.09-2.46l-.23-.15c-14.57-8.86-21.26-22.77-18.85-39.16,2.52-17.19,17.67-31.49,35.23-33.24a46.18,46.18,0,0,1,4.69-.24c17.72,0,32.94,10,38.79,25.46,6.73,17.77.3,36.88-15.64,46.45a7.43,7.43,0,0,0-1.16.83,8.15,8.15,0,0,0-.08,1.7c.29,7.68.18,15.33.07,22.73,0,3-.08,6-.1,9v.34a29.62,29.62,0,0,1-.3,4.68A21.1,21.1,0,0,1,166.85,261.56Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.81,382.38a39.55,39.55,0,0,1-12.54-2c-38.85-12.95-69.52-30.87-93.75-54.77S19.2,271.39,10.14,236C3.4,209.68.59,181,1.31,145.85c0-3.68,0-7.36,0-11,0-8,.08-16.31-.07-24.38-.56-28.55,21.8-51.2,52-52.7,21.45-1.06,39.59-5.61,55.48-13.92A194.94,194.94,0,0,0,128.12,31.6c2.14-1.46,4.27-2.91,6.41-4.34a38.89,38.89,0,0,0,5.53-4.84C146.58,15.83,156.32,12,166.77,12s20.14,3.68,26.86,10.08a123.66,123.66,0,0,0,37.59,24.64,150.1,150.1,0,0,0,42.07,10.45l2.7.29c6.72.71,14.33,1.51,21.76,3.72,19.44,5.75,34,24.48,34.73,44.52.89,25.87,1.56,53.4-.71,80.55-3,35.73-10.14,64.08-22.52,89.22-15.52,31.53-39.08,57.45-70,77.07-17.12,10.85-36.07,19.78-57.93,27.27A44.56,44.56,0,0,1,166.81,382.38ZM153.63,240.19a13.68,13.68,0,0,0,2.06,7.34c2.09,3.46,6.86,6,11.33,6a10.67,10.67,0,0,0,2.23-.23c5.69-1.22,10-5.42,10.33-10,.56-8.85.58-17.68.6-27V212a21.16,21.16,0,0,1,8.35-17.18A30.32,30.32,0,0,0,197,158.69c-5.32-11.38-17.14-19-29.41-19a30.63,30.63,0,0,0-4.49.33c-14.77,2.23-23.48,9.42-27.43,22.62s-.37,23.74,11.12,33.53a20.21,20.21,0,0,1,6.88,14c.18,4.07.15,8,.12,11.88,0,1.38,0,2.75,0,4.13v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.85,261.28c-.37,0-.74,0-1.12,0-11.57-.54-20.1-9.27-20.28-20.75-.07-4.59-.06-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.14q0-1.23.09-2.46l-.23-.15c-14.56-8.86-21.25-22.77-18.84-39.16,2.53-17.19,17.66-31.49,35.2-33.24a46.18,46.18,0,0,1,4.69-.24c17.7,0,32.91,10,38.76,25.46,6.72,17.77.29,36.88-15.63,46.45a7.43,7.43,0,0,0-1.16.83,8.15,8.15,0,0,0-.08,1.7c.29,7.68.18,15.33.08,22.73q-.08,4.48-.11,9v.34a29.62,29.62,0,0,1-.3,4.68A21.08,21.08,0,0,1,166.85,261.28Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.81,382.1a39.49,39.49,0,0,1-12.53-2c-38.82-12.95-69.45-30.87-93.66-54.77s-41.29-54.18-50.34-89.57c-6.73-26.32-9.54-55-8.82-90.15,0-3.68,0-7.36,0-11,0-8,.08-16.31-.07-24.38C.86,81.6,23.2,59,53.37,57.45c21.42-1.06,39.55-5.61,55.43-13.92a197.06,197.06,0,0,0,19.36-12.21q3.19-2.19,6.4-4.34a38.8,38.8,0,0,0,5.52-4.84c6.52-6.59,16.25-10.38,26.69-10.38s20.12,3.68,26.84,10.08a123.48,123.48,0,0,0,37.55,24.64,149.81,149.81,0,0,0,42,10.45l2.71.29c6.71.71,14.31,1.51,21.74,3.72,19.41,5.75,34,24.48,34.69,44.52.89,25.87,1.56,53.4-.7,80.55-3,35.73-10.14,64.08-22.51,89.22-15.5,31.53-39,57.45-69.94,77.07-17.11,10.85-36,19.78-57.88,27.27A44.46,44.46,0,0,1,166.81,382.1ZM153.65,239.91a13.67,13.67,0,0,0,2,7.34c2.09,3.46,6.85,6,11.32,6a10.67,10.67,0,0,0,2.23-.23c5.69-1.22,10-5.42,10.32-10,.56-8.84.58-17.67.6-27v-4.27a21.18,21.18,0,0,1,8.34-17.18A30.33,30.33,0,0,0,197,158.41c-5.32-11.38-17.12-19-29.38-19a30.63,30.63,0,0,0-4.49.33c-14.75,2.23-23.46,9.42-27.4,22.62s-.37,23.74,11.11,33.53a20.2,20.2,0,0,1,6.87,14c.19,4.07.16,8,.13,11.88,0,1.38,0,2.75,0,4.13v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.85,261c-.37,0-.74,0-1.12,0-11.56-.54-20.08-9.27-20.26-20.75-.07-4.59-.06-9.19,0-13.64,0-1.88,0-3.76,0-5.64V207h.14q0-1.23.09-2.46l-.23-.15c-14.54-8.86-21.23-22.77-18.82-39.16,2.52-17.19,17.64-31.49,35.17-33.24a46,46,0,0,1,4.69-.24c17.68,0,32.88,10,38.71,25.46,6.72,17.77.3,36.88-15.61,46.45a6.66,6.66,0,0,0-1.15.83,7.18,7.18,0,0,0-.09,1.7c.29,7.68.18,15.33.08,22.73,0,3-.09,6-.1,9v.34a28.46,28.46,0,0,1-.3,4.68A21.07,21.07,0,0,1,166.85,261Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.81,381.82a39.32,39.32,0,0,1-12.51-2C115.51,366.83,84.9,348.91,60.72,325s-41.25-54.18-50.3-89.57c-6.73-26.32-9.53-55-8.81-90.15q0-5.52,0-11c0-8,.08-16.31-.07-24.38C1,81.32,23.33,58.67,53.47,57.17c21.4-1.06,39.52-5.61,55.38-13.92A197,197,0,0,0,128.2,31q3.18-2.19,6.39-4.34a39.36,39.36,0,0,0,5.52-4.84c6.51-6.59,16.23-10.38,26.66-10.38s20.1,3.68,26.81,10.08A123.39,123.39,0,0,0,231.11,46.2a149.44,149.44,0,0,0,42,10.45l2.7.29c6.7.71,14.3,1.51,21.72,3.71,19.4,5.76,34,24.49,34.66,44.53.89,25.87,1.56,53.4-.7,80.55-3,35.73-10.13,64.08-22.48,89.22-15.5,31.53-39,57.45-69.89,77.07-17.09,10.85-36,19.78-57.82,27.27A44.4,44.4,0,0,1,166.81,381.82ZM153.66,239.63a13.67,13.67,0,0,0,2.05,7.34c2.09,3.46,6.85,6,11.31,6a10.74,10.74,0,0,0,2.23-.23c5.68-1.22,10-5.42,10.31-10,.56-8.84.58-17.67.59-27v-4.27a21.21,21.21,0,0,1,8.34-17.18,30.33,30.33,0,0,0,8.42-36.12c-5.31-11.38-17.1-19-29.35-19a30.63,30.63,0,0,0-4.49.33c-14.74,2.23-23.44,9.42-27.37,22.62s-.37,23.74,11.1,33.53a20.22,20.22,0,0,1,6.86,14c.19,4.07.16,8,.13,11.88,0,1.37,0,2.75,0,4.13v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.85,260.72c-.37,0-.74,0-1.12,0A20.71,20.71,0,0,1,145.49,240c-.07-4.59-.06-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.14q0-1.23.09-2.46l-.23-.15c-14.53-8.86-21.21-22.77-18.81-39.16,2.53-17.19,17.63-31.49,35.14-33.24a46.18,46.18,0,0,1,4.69-.24c17.66,0,32.85,10,38.68,25.46,6.71,17.77.29,36.88-15.6,46.45a6.66,6.66,0,0,0-1.15.83,7.18,7.18,0,0,0-.09,1.7c.29,7.68.18,15.33.08,22.73,0,3-.09,6-.1,9v.34a28.46,28.46,0,0,1-.3,4.68A21.06,21.06,0,0,1,166.85,260.72Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.81,381.54a39.3,39.3,0,0,1-12.5-2c-38.75-12.95-69.34-30.87-93.5-54.77S19.6,270.55,10.56,235.16C3.84,208.84,1,180.19,1.76,145q0-5.52,0-11c0-8,.08-16.31-.07-24.38C1.16,81,23.46,58.39,53.57,56.89,75,55.83,93.05,51.28,108.9,43a195.56,195.56,0,0,0,19.33-12.21c2.13-1.46,4.25-2.91,6.39-4.35a38,38,0,0,0,5.51-4.83C146.64,15,156.35,11.2,166.77,11.2s20.09,3.68,26.79,10.08a123,123,0,0,0,37.49,24.63A148.91,148.91,0,0,0,273,56.37l2.7.29c6.7.71,14.29,1.51,21.7,3.71,19.38,5.76,34,24.49,34.63,44.53.89,25.87,1.56,53.4-.7,80.55-3,35.73-10.11,64.08-22.46,89.22-15.48,31.53-39,57.45-69.82,77.07-17.08,10.85-36,19.78-57.78,27.27A44.29,44.29,0,0,1,166.81,381.54ZM153.67,239.35a13.67,13.67,0,0,0,2.05,7.34c2.09,3.46,6.84,6,11.3,6a10.74,10.74,0,0,0,2.23-.23c5.67-1.22,10-5.42,10.29-10,.57-8.84.58-17.67.6-27v-4.27A21.23,21.23,0,0,1,188.48,194a30.36,30.36,0,0,0,8.42-36.12c-5.31-11.38-17.09-19-29.33-19a30.6,30.6,0,0,0-4.48.33c-14.73,2.23-23.42,9.42-27.35,22.62s-.37,23.74,11.08,33.53a20.14,20.14,0,0,1,6.86,14c.19,4.07.16,8,.13,11.88,0,1.37,0,2.75,0,4.13v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.85,260.44c-.37,0-.74,0-1.11,0a20.7,20.7,0,0,1-20.23-20.75c-.07-4.59-.06-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.14q0-1.23.09-2.46l-.23-.15C131,194.92,124.29,181,126.69,164.62c2.52-17.19,17.61-31.49,35.11-33.24a45.84,45.84,0,0,1,4.68-.24c17.65,0,32.82,10,38.65,25.46,6.7,17.77.29,36.88-15.59,46.45a6.66,6.66,0,0,0-1.15.83,7.18,7.18,0,0,0-.09,1.7c.29,7.68.18,15.33.08,22.73,0,3-.09,6-.1,9v.34a29.81,29.81,0,0,1-.31,4.68A21,21,0,0,1,166.85,260.44Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.81,381.26a39.24,39.24,0,0,1-12.49-2c-38.72-13-69.27-30.87-93.41-54.77S19.73,270.27,10.7,234.88c-6.71-26.32-9.51-55-8.8-90.15q0-5.52,0-11c0-8,.08-16.31-.08-24.38-.55-28.55,21.73-51.2,51.81-52.7C75,55.55,93.12,51,109,42.69a195.45,195.45,0,0,0,19.32-12.21c2.12-1.46,4.24-2.91,6.38-4.35a38.58,38.58,0,0,0,5.51-4.83c6.49-6.59,16.19-10.38,26.61-10.38S186.84,14.6,193.54,21A122.94,122.94,0,0,0,231,45.63a148.7,148.7,0,0,0,41.92,10.46l2.69.29c6.7.71,14.28,1.51,21.68,3.71,19.37,5.76,33.92,24.49,34.61,44.53.88,25.87,1.55,53.4-.71,80.55-3,35.73-10.1,64.08-22.44,89.22-15.47,31.53-38.94,57.45-69.76,77.07-17.06,10.85-35.94,19.77-57.72,27.27A44.23,44.23,0,0,1,166.81,381.26ZM153.68,239.07a13.67,13.67,0,0,0,2,7.34c2.09,3.46,6.84,6,11.29,6a10.74,10.74,0,0,0,2.23-.23c5.67-1.22,10-5.42,10.28-10,.57-8.84.58-17.67.6-27v-4.27a21.25,21.25,0,0,1,8.32-17.18,30.39,30.39,0,0,0,8.41-36.12c-5.3-11.38-17.07-19-29.3-19a30.6,30.6,0,0,0-4.48.33c-14.71,2.23-23.4,9.42-27.32,22.62s-.38,23.74,11.07,33.53a20.22,20.22,0,0,1,6.86,14c.18,4.07.15,8,.12,11.88,0,1.37,0,2.75,0,4.13v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.85,260.16c-.37,0-.74,0-1.11,0a20.69,20.69,0,0,1-20.21-20.75c-.07-4.59-.06-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.13c0-.82.06-1.64.1-2.46l-.23-.15c-14.5-8.86-21.17-22.77-18.77-39.16,2.52-17.19,17.59-31.49,35.07-33.24a46,46,0,0,1,4.68-.24c17.63,0,32.79,10,38.61,25.46,6.7,17.77.3,36.88-15.57,46.45a6.66,6.66,0,0,0-1.15.83,7.45,7.45,0,0,0-.08,1.7c.28,7.68.17,15.33.07,22.72,0,3-.09,6-.1,9v.34A30.88,30.88,0,0,1,188,242,21,21,0,0,1,166.85,260.16Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.81,381a39.18,39.18,0,0,1-12.48-2C115.65,366,85.12,348.07,61,324.17S19.87,270,10.84,234.6c-6.7-26.32-9.5-55-8.79-90.15,0-3.68,0-7.36,0-11,0-8,.08-16.31-.08-24.38-.55-28.55,21.7-51.2,51.77-52.7C75.12,55.27,93.19,50.72,109,42.41A195.13,195.13,0,0,0,128.3,30.2c2.12-1.46,4.24-2.91,6.38-4.35a38,38,0,0,0,5.5-4.83c6.49-6.59,16.18-10.38,26.59-10.38s20,3.68,26.74,10.08a122.81,122.81,0,0,0,37.42,24.63,148.51,148.51,0,0,0,41.88,10.46l2.7.29c6.68.71,14.26,1.51,21.66,3.71,19.34,5.76,33.88,24.49,34.57,44.53.88,25.87,1.55,53.4-.7,80.55-3,35.73-10.1,64.08-22.43,89.22-15.45,31.53-38.9,57.45-69.69,77.07C221.87,362,203,371,181.25,378.45A44.22,44.22,0,0,1,166.81,381ZM153.7,238.79a13.57,13.57,0,0,0,2,7.34c2.08,3.46,6.83,6,11.28,6a10.64,10.64,0,0,0,2.22-.23c5.67-1.22,10-5.42,10.28-10,.56-8.84.58-17.67.6-27v-4.27a21.24,21.24,0,0,1,8.31-17.18,30.4,30.4,0,0,0,8.4-36.12c-5.29-11.38-17.06-19-29.27-19a30.36,30.36,0,0,0-4.47.33c-14.71,2.23-23.38,9.42-27.3,22.62s-.38,23.74,11.06,33.53a20.21,20.21,0,0,1,6.85,14c.18,4.07.15,8,.13,11.88,0,1.37,0,2.75,0,4.13v14h-.11Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.85,259.88c-.37,0-.74,0-1.11,0a20.68,20.68,0,0,1-20.19-20.75c-.07-4.59-.06-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.13c0-.82.06-1.64.1-2.46l-.23-.15c-14.49-8.86-21.15-22.77-18.76-39.16,2.52-17.19,17.59-31.49,35.05-33.24a45.65,45.65,0,0,1,4.67-.24c17.62,0,32.76,10,38.58,25.46,6.69,17.77.29,36.88-15.56,46.45a6.66,6.66,0,0,0-1.15.83,7.45,7.45,0,0,0-.08,1.7c.28,7.68.18,15.33.07,22.72,0,3-.08,6-.1,9v.34a30.88,30.88,0,0,1-.3,4.68A21,21,0,0,1,166.85,259.88Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.81,380.7a39.12,39.12,0,0,1-12.47-2c-38.64-13-69.14-30.87-93.24-54.77S20,269.71,11,234.32c-6.7-26.32-9.49-55-8.78-90.15,0-3.68,0-7.36,0-11,0-8,.08-16.31-.08-24.38-.55-28.55,21.68-51.2,51.72-52.7C75.2,55,93.25,50.44,109.06,42.13a195,195,0,0,0,19.28-12.21c2.12-1.46,4.23-2.91,6.37-4.35a39,39,0,0,0,5.5-4.83c6.48-6.59,16.16-10.38,26.56-10.38s20,3.68,26.72,10.08a122.67,122.67,0,0,0,37.39,24.63,148.14,148.14,0,0,0,41.84,10.46l2.69.29c6.68.71,14.25,1.51,21.64,3.71,19.33,5.76,33.86,24.49,34.54,44.53.88,25.87,1.55,53.4-.7,80.55-3,35.73-10.09,64.08-22.4,89.22-15.44,31.53-38.87,57.45-69.64,77.07-17,10.85-35.87,19.77-57.61,27.27A44.16,44.16,0,0,1,166.81,380.7Zm-13.1-142.19a13.75,13.75,0,0,0,2,7.34c2.08,3.46,6.83,6,11.27,6a10.64,10.64,0,0,0,2.22-.23c5.66-1.22,10-5.42,10.27-10,.56-8.84.58-17.67.6-27v-4.27a21.23,21.23,0,0,1,8.3-17.18,30.43,30.43,0,0,0,8.4-36.12c-5.29-11.38-17-19-29.25-19a30.36,30.36,0,0,0-4.47.33c-14.69,2.23-23.36,9.42-27.28,22.62s-.37,23.74,11.06,33.53a20.24,20.24,0,0,1,6.84,14c.19,4.07.16,8,.13,11.88,0,1.37,0,2.75,0,4.13v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.85,259.6c-.37,0-.73,0-1.11,0a20.67,20.67,0,0,1-20.17-20.75c-.07-4.59-.06-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.13c0-.82.06-1.64.1-2.46l-.23-.15c-14.48-8.86-21.13-22.77-18.74-39.16,2.51-17.19,17.57-31.49,35-33.24a45.65,45.65,0,0,1,4.67-.24c17.6,0,32.73,10,38.54,25.46,6.69,17.77.3,36.88-15.54,46.45a6.66,6.66,0,0,0-1.15.83,7.45,7.45,0,0,0-.08,1.7c.29,7.68.18,15.33.07,22.72,0,3-.08,6-.1,9v.34a29.62,29.62,0,0,1-.3,4.68A21,21,0,0,1,166.85,259.6Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.81,380.42a39.06,39.06,0,0,1-12.46-2c-38.61-12.95-69.08-30.87-93.16-54.77S20.13,269.43,11.13,234c-6.7-26.32-9.49-55-8.78-90.15,0-3.68,0-7.36,0-11,0-8,.08-16.31-.08-24.38C1.76,79.92,24,57.27,54,55.77c21.31-1.06,39.34-5.61,55.13-13.92a194.8,194.8,0,0,0,19.26-12.21c2.12-1.46,4.23-2.91,6.37-4.35a38.4,38.4,0,0,0,5.49-4.83c6.48-6.59,16.15-10.38,26.54-10.38s20,3.68,26.7,10.08a122.49,122.49,0,0,0,37.35,24.63,148,148,0,0,0,41.8,10.46l2.69.29c6.68.71,14.24,1.51,21.62,3.71,19.32,5.76,33.83,24.49,34.51,44.53.88,25.87,1.56,53.4-.7,80.55-3,35.73-10.08,64.08-22.38,89.22-15.43,31.53-38.83,57.45-69.57,77.07-17,10.85-35.84,19.77-57.57,27.27A44,44,0,0,1,166.81,380.42ZM153.72,238.23a13.77,13.77,0,0,0,2,7.34c2.08,3.46,6.82,6,11.26,6a10.64,10.64,0,0,0,2.22-.23c5.65-1.22,10-5.42,10.26-10,.56-8.84.58-17.67.6-27V210a21.22,21.22,0,0,1,8.29-17.18,30.44,30.44,0,0,0,8.39-36.12c-5.29-11.38-17-19-29.22-19a30.46,30.46,0,0,0-4.47.33c-14.67,2.23-23.33,9.42-27.25,22.62s-.37,23.74,11.05,33.53a20.19,20.19,0,0,1,6.83,14c.19,4.07.16,8,.13,11.88,0,1.37,0,2.75,0,4.13v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.85,259.32c-.36,0-.73,0-1.11,0a20.66,20.66,0,0,1-20.15-20.75c-.07-4.59-.06-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.13c0-.82.06-1.64.1-2.46l-.23-.15c-14.47-8.86-21.12-22.77-18.72-39.16,2.51-17.19,17.55-31.49,35-33.24a45.6,45.6,0,0,1,4.66-.24c17.59,0,32.7,10,38.51,25.46,6.68,17.77.29,36.88-15.53,46.45a7.53,7.53,0,0,0-1.15.82,7.62,7.62,0,0,0-.08,1.71c.29,7.68.18,15.33.07,22.72,0,3-.08,6-.1,9v.34a29.62,29.62,0,0,1-.3,4.68A21,21,0,0,1,166.85,259.32Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.81,380.14a38.94,38.94,0,0,1-12.44-2c-38.58-13-69-30.87-93.08-54.77s-41-54.18-50-89.57c-6.69-26.32-9.48-55-8.77-90.15,0-3.68,0-7.36,0-11,0-8,.08-16.31-.07-24.38C1.91,79.64,24.1,57,54.08,55.49c21.29-1.06,39.31-5.61,55.08-13.92a194.69,194.69,0,0,0,19.25-12.21q3.17-2.19,6.35-4.35a38,38,0,0,0,5.5-4.83c6.47-6.59,16.13-10.38,26.51-10.38s20,3.68,26.67,10.08a122.48,122.48,0,0,0,37.32,24.63A147.82,147.82,0,0,0,272.53,55l2.68.29c6.67.71,14.23,1.51,21.61,3.71,19.29,5.76,33.79,24.49,34.47,44.53.89,25.87,1.56,53.4-.7,80.55-3,35.73-10.07,64.08-22.36,89.22-15.41,31.53-38.79,57.45-69.5,77.07-17,10.85-35.82,19.77-57.52,27.27A43.94,43.94,0,0,1,166.81,380.14ZM153.73,238a13.77,13.77,0,0,0,2,7.34c2.08,3.46,6.81,6,11.25,6a10.65,10.65,0,0,0,2.22-.22c5.65-1.22,10-5.42,10.25-10,.56-8.84.58-17.67.6-27v-4.27a21.24,21.24,0,0,1,8.28-17.18,30.45,30.45,0,0,0,8.38-36.12c-5.28-11.38-17-19-29.19-19a30.23,30.23,0,0,0-4.46.33c-14.66,2.23-23.32,9.42-27.23,22.62s-.37,23.74,11,33.53a20.22,20.22,0,0,1,6.82,14c.19,4.07.16,8,.13,11.88,0,1.37,0,2.75,0,4.13v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.85,259c-.36,0-.73,0-1.11,0a20.65,20.65,0,0,1-20.13-20.75c-.07-4.59-.06-9.19,0-13.64,0-1.88,0-3.76,0-5.64V205h.13c0-.82.06-1.64.1-2.46l-.23-.15c-14.45-8.86-21.1-22.77-18.71-39.16,2.51-17.19,17.54-31.49,34.95-33.24a45.6,45.6,0,0,1,4.66-.24c17.57,0,32.67,10,38.48,25.46,6.67,17.77.29,36.88-15.52,46.45a6.85,6.85,0,0,0-1.15.82,7.62,7.62,0,0,0-.08,1.71c.29,7.68.18,15.33.07,22.72,0,3-.08,6-.1,9v.34a29.62,29.62,0,0,1-.3,4.68A21,21,0,0,1,166.85,259Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.81,379.86a38.88,38.88,0,0,1-12.43-2c-38.55-12.95-69-30.87-93-54.77s-41-54.18-50-89.57c-6.69-26.32-9.47-55-8.76-90.15q0-5.52,0-11c0-8,.08-16.31-.07-24.38-.55-28.55,21.62-51.2,51.57-52.7,21.27-1.06,39.27-5.61,55-13.92a193.32,193.32,0,0,0,19.23-12.21q3.17-2.19,6.35-4.35a37.86,37.86,0,0,0,5.49-4.83c6.47-6.59,16.12-10.38,26.49-10.38s20,3.68,26.65,10.08a122.35,122.35,0,0,0,37.29,24.63,147.47,147.47,0,0,0,41.72,10.46l2.69.29c6.66.71,14.21,1.51,21.58,3.71,19.28,5.76,33.76,24.49,34.45,44.53.88,25.87,1.55,53.4-.7,80.55-3,35.73-10.06,64.08-22.34,89.22-15.4,31.53-38.77,57.45-69.45,77.07-17,10.85-35.78,19.77-57.46,27.27A43.88,43.88,0,0,1,166.81,379.86ZM153.74,237.67a13.77,13.77,0,0,0,2,7.34c2.08,3.46,6.81,6,11.24,6a10.65,10.65,0,0,0,2.22-.22c5.64-1.22,9.95-5.42,10.24-10,.56-8.84.58-17.67.59-27v-4.27a21.27,21.27,0,0,1,8.29-17.18,30.47,30.47,0,0,0,8.37-36.12c-5.28-11.38-17-19-29.17-19a30.23,30.23,0,0,0-4.46.33c-14.65,2.23-23.29,9.42-27.2,22.62s-.37,23.74,11,33.53a20.23,20.23,0,0,1,6.83,14c.18,4.07.15,8,.12,11.88,0,1.37,0,2.75,0,4.13v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.85,258.76c-.36,0-.73,0-1.11,0A20.65,20.65,0,0,1,145.62,238c-.06-4.59-.05-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.13c0-.82.06-1.64.1-2.46l-.23-.15c-14.44-8.86-21.08-22.77-18.69-39.16,2.51-17.19,17.52-31.49,34.92-33.24a45.4,45.4,0,0,1,4.65-.24c17.56,0,32.65,10,38.44,25.46,6.67,17.77.29,36.88-15.5,46.45a7.35,7.35,0,0,0-1.15.82,8.34,8.34,0,0,0-.08,1.71c.29,7.68.18,15.33.07,22.72,0,3-.08,6-.1,9v.34a29.62,29.62,0,0,1-.3,4.68A21,21,0,0,1,166.85,258.76Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.81,379.58a38.86,38.86,0,0,1-12.42-2c-38.51-13-68.9-30.87-92.91-54.77S20.53,268.59,11.55,233.2c-6.68-26.32-9.47-55-8.76-90.15q0-5.52,0-11c0-8,.08-16.31-.08-24.38-.54-28.55,21.61-51.2,51.54-52.7,21.25-1.06,39.23-5.61,55-13.92A195.44,195.44,0,0,0,128.48,28.8q3.17-2.19,6.34-4.35a37.77,37.77,0,0,0,5.48-4.83C146.77,13,156.41,9.24,166.77,9.24s20,3.68,26.63,10.08A122.17,122.17,0,0,0,230.65,44a147.26,147.26,0,0,0,41.69,10.46l2.68.29c6.66.71,14.2,1.51,21.56,3.71,19.26,5.76,33.74,24.49,34.42,44.53.88,25.87,1.55,53.4-.7,80.55-3,35.73-10.05,64.08-22.32,89.22-15.38,31.52-38.73,57.45-69.38,77.07-17,10.85-35.75,19.77-57.42,27.27A43.72,43.72,0,0,1,166.81,379.58ZM153.76,237.39a13.76,13.76,0,0,0,2,7.34c2.08,3.46,6.8,6,11.23,6a10.48,10.48,0,0,0,2.21-.22c5.64-1.22,10-5.42,10.24-10,.56-8.84.57-17.67.59-27v-4.27A21.27,21.27,0,0,1,188.35,192a30.51,30.51,0,0,0,8.36-36.13c-5.27-11.37-17-19-29.14-19a30.23,30.23,0,0,0-4.46.33c-14.63,2.23-23.27,9.42-27.17,22.62s-.37,23.74,11,33.53a20.26,20.26,0,0,1,6.82,14c.18,4.07.15,8,.13,11.88,0,1.37,0,2.75,0,4.13v14h-.11Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.85,258.48c-.36,0-.73,0-1.1,0a20.65,20.65,0,0,1-20.11-20.75c-.07-4.59,0-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.13c0-.82.05-1.64.1-2.46l-.23-.15C131.19,193,124.56,179.05,127,162.66c2.5-17.2,17.5-31.49,34.88-33.24a45.4,45.4,0,0,1,4.65-.24c17.54,0,32.62,10,38.41,25.45,6.66,17.78.29,36.89-15.49,46.46a6.58,6.58,0,0,0-1.14.82,7.35,7.35,0,0,0-.09,1.71c.29,7.68.18,15.33.08,22.72-.05,3-.09,6-.1,9v.34a28.46,28.46,0,0,1-.3,4.68A20.94,20.94,0,0,1,166.85,258.48Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.81,379.3a38.76,38.76,0,0,1-12.41-2c-38.47-12.95-68.84-30.87-92.82-54.77-24.14-24-40.92-54.18-49.89-89.57C5,206.6,2.23,178,2.94,142.77c0-3.68,0-7.36,0-11,0-8,.08-16.31-.08-24.38-.55-28.55,21.59-51.2,51.49-52.7,21.23-1.06,39.2-5.61,54.93-13.92a194,194,0,0,0,19.19-12.21q3.17-2.19,6.34-4.35a38.31,38.31,0,0,0,5.48-4.83C146.78,12.75,156.42,9,166.77,9s19.94,3.68,26.6,10.08a122.16,122.16,0,0,0,37.22,24.63,147.07,147.07,0,0,0,41.65,10.46l2.68.29c6.65.71,14.19,1.51,21.55,3.71,19.24,5.76,33.7,24.49,34.38,44.53.88,25.87,1.55,53.4-.7,80.55-2.95,35.73-10,64.08-22.3,89.22-15.37,31.52-38.69,57.45-69.32,77.07-16.95,10.85-35.71,19.77-57.36,27.27A43.71,43.71,0,0,1,166.81,379.3Zm-13-142.19a13.76,13.76,0,0,0,2,7.34c2.08,3.46,6.8,6,11.22,6a10.55,10.55,0,0,0,2.21-.22c5.64-1.22,9.94-5.42,10.23-10,.56-8.84.57-17.67.59-27v-4.27a21.28,21.28,0,0,1,8.27-17.18,30.52,30.52,0,0,0,8.35-36.13c-5.26-11.37-17-19-29.11-19a30.09,30.09,0,0,0-4.45.33c-14.62,2.23-23.25,9.42-27.15,22.62s-.38,23.74,11,33.53a20.25,20.25,0,0,1,6.81,14c.19,4.07.16,8,.13,11.88,0,1.37,0,2.75,0,4.13v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.85,258.2c-.36,0-.73,0-1.1,0a20.64,20.64,0,0,1-20.09-20.75c-.07-4.59,0-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.13c0-.82,0-1.64.1-2.46l-.23-.15c-14.42-8.86-21-22.77-18.66-39.16,2.5-17.2,17.49-31.49,34.86-33.24a45.06,45.06,0,0,1,4.64-.24c17.53,0,32.59,10,38.38,25.45,6.65,17.78.29,36.89-15.48,46.46a6.58,6.58,0,0,0-1.14.82,7.35,7.35,0,0,0-.09,1.71c.29,7.68.18,15.33.08,22.72,0,3-.09,6-.1,9v.34a29.43,29.43,0,0,1-.29,4.68A21,21,0,0,1,166.85,258.2Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.81,379a38.7,38.7,0,0,1-12.4-2C116,364,85.64,346.11,61.67,322.21S20.79,268,11.83,232.64c-6.67-26.32-9.45-55-8.74-90.15,0-3.68,0-7.36,0-11,0-8,.08-16.31-.08-24.38-.55-28.55,21.57-51.2,51.44-52.7,21.21-1.06,39.16-5.61,54.88-13.92a193.83,193.83,0,0,0,19.17-12.21q3.17-2.19,6.34-4.35a37.68,37.68,0,0,0,5.47-4.83c6.45-6.59,16.08-10.38,26.43-10.38s19.92,3.67,26.57,10.08a122,122,0,0,0,37.18,24.63,146.77,146.77,0,0,0,41.62,10.46l2.68.29c6.64.71,14.17,1.51,21.52,3.71,19.23,5.76,33.67,24.49,34.36,44.53.87,25.87,1.54,53.4-.7,80.55-3,35.73-10,64.08-22.28,89.22-15.36,31.52-38.66,57.45-69.26,77.07-16.94,10.85-35.68,19.77-57.31,27.27A43.61,43.61,0,0,1,166.81,379Zm-13-142.19a13.76,13.76,0,0,0,2,7.34c2.07,3.46,6.79,6,11.21,6a10.55,10.55,0,0,0,2.21-.22c5.63-1.22,9.93-5.42,10.22-10,.55-8.84.57-17.67.59-27v-4.27a21.25,21.25,0,0,1,8.26-17.18,30.55,30.55,0,0,0,8.35-36.13c-5.27-11.37-16.95-19-29.09-19a30.19,30.19,0,0,0-4.45.33c-14.61,2.23-23.23,9.42-27.13,22.62s-.37,23.74,11,33.53a20.22,20.22,0,0,1,6.8,14c.19,4.07.16,8,.13,11.88,0,1.37,0,2.75,0,4.13v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.85,257.92c-.36,0-.73,0-1.1,0a20.63,20.63,0,0,1-20.07-20.75c-.07-4.59-.05-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.13c0-.82.05-1.64.1-2.46l-.23-.15c-14.4-8.86-21-22.77-18.64-39.16,2.5-17.2,17.47-31.49,34.82-33.24a45.36,45.36,0,0,1,4.64-.24c17.51,0,32.56,10,38.34,25.45,6.65,17.78.29,36.89-15.46,46.46a6.58,6.58,0,0,0-1.14.82,7.35,7.35,0,0,0-.09,1.71c.29,7.68.18,15.33.08,22.72,0,3-.09,6-.1,9v.34a30.88,30.88,0,0,1-.3,4.68A20.94,20.94,0,0,1,166.85,257.92Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.81,378.74a38.68,38.68,0,0,1-12.39-2c-38.4-12.95-68.71-30.87-92.65-54.77C37.68,297.88,20.92,267.75,12,232.36c-6.66-26.32-9.44-55-8.73-90.15,0-3.68,0-7.36,0-11,0-8,.08-16.31-.07-24.38-.55-28.55,21.54-51.2,51.39-52.7C75.78,53,93.72,48.48,109.42,40.17A193.73,193.73,0,0,0,128.58,28c2.11-1.46,4.21-2.91,6.33-4.35a38.77,38.77,0,0,0,5.47-4.83c6.44-6.59,16.06-10.38,26.4-10.38s19.9,3.67,26.55,10.08a121.85,121.85,0,0,0,37.15,24.63,146.43,146.43,0,0,0,41.57,10.46l2.68.29c6.64.71,14.16,1.51,21.51,3.71,19.2,5.76,33.64,24.49,34.32,44.53.88,25.87,1.54,53.4-.7,80.55-2.95,35.73-10,64.08-22.26,89.22-15.34,31.52-38.62,57.45-69.19,77.07-16.93,10.85-35.66,19.77-57.26,27.27A43.6,43.6,0,0,1,166.81,378.74Zm-13-142.19a13.85,13.85,0,0,0,2,7.34c2.07,3.46,6.78,6,11.2,6a10.55,10.55,0,0,0,2.21-.22c5.62-1.22,9.92-5.42,10.2-10,.56-8.84.58-17.67.6-27v-4.27a21.27,21.27,0,0,1,8.25-17.18A30.56,30.56,0,0,0,196.63,155c-5.26-11.37-16.94-19-29.06-19a30,30,0,0,0-4.44.33c-14.6,2.23-23.21,9.42-27.11,22.62s-.37,23.74,11,33.53a20.3,20.3,0,0,1,6.8,14c.18,4.07.15,8,.12,11.88,0,1.37,0,2.75,0,4.13v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.85,257.64l-1.1,0a20.62,20.62,0,0,1-20.05-20.75c-.07-4.59,0-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.13q0-1.23.09-2.46l-.22-.15c-14.39-8.86-21-22.77-18.63-39.16,2.5-17.2,17.46-31.49,34.8-33.24a45,45,0,0,1,4.63-.24c17.5,0,32.53,10,38.31,25.45,6.64,17.78.29,36.89-15.45,46.46a6.58,6.58,0,0,0-1.14.82,7.62,7.62,0,0,0-.08,1.71c.28,7.68.17,15.33.07,22.72,0,3-.08,6-.1,9v.34a30.88,30.88,0,0,1-.3,4.68A20.92,20.92,0,0,1,166.85,257.64Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.81,378.45a38.61,38.61,0,0,1-12.38-2c-38.37-12.95-68.65-30.87-92.57-54.77s-40.81-54.18-49.75-89.57c-6.66-26.32-9.43-55-8.73-90.15q0-5.52,0-11c0-8,.08-16.31-.07-24.38C2.8,78,24.87,55.31,54.69,53.81c21.17-1.06,39.09-5.61,54.79-13.92a193.4,193.4,0,0,0,19.13-12.21c2.11-1.46,4.21-2.91,6.33-4.35a38.13,38.13,0,0,0,5.46-4.83c6.44-6.59,16-10.38,26.38-10.38s19.88,3.67,26.52,10.08a121.71,121.71,0,0,0,37.12,24.63A146.3,146.3,0,0,0,272,53.29l2.67.29c6.64.71,14.15,1.51,21.49,3.71,19.19,5.76,33.61,24.49,34.29,44.53.88,25.87,1.55,53.4-.7,80.55-2.94,35.73-10,64.08-22.24,89.22-15.32,31.52-38.58,57.45-69.13,77.07-16.91,10.85-35.62,19.77-57.21,27.27A43.42,43.42,0,0,1,166.81,378.45Zm-13-142.18a13.76,13.76,0,0,0,2,7.34c2.07,3.46,6.78,6,11.19,6a10.55,10.55,0,0,0,2.21-.22c5.62-1.22,9.91-5.42,10.19-10,.56-8.84.58-17.67.6-27v-4.27a21.26,21.26,0,0,1,8.24-17.18,30.57,30.57,0,0,0,8.33-36.13c-5.25-11.37-16.92-19-29-19a30,30,0,0,0-4.44.33c-14.58,2.23-23.19,9.42-27.08,22.62s-.37,23.74,11,33.52a20.33,20.33,0,0,1,6.79,14c.18,4.07.15,8,.12,11.88,0,1.37,0,2.75,0,4.13v14h-.11Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,257.36l-1.11,0a20.61,20.61,0,0,1-20-20.75c-.07-4.59-.05-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.14q0-1.23.09-2.46l-.22-.15c-14.38-8.86-21-22.77-18.61-39.16,2.5-17.2,17.44-31.49,34.76-33.24a45,45,0,0,1,4.63-.24c17.48,0,32.5,10,38.27,25.45,6.64,17.78.29,36.89-15.43,46.46a7.06,7.06,0,0,0-1.14.82,7.62,7.62,0,0,0-.08,1.71c.28,7.68.17,15.33.07,22.72,0,3-.08,6-.1,9v.34a29.62,29.62,0,0,1-.3,4.68A20.9,20.9,0,0,1,166.86,257.36Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.81,378.17a38.49,38.49,0,0,1-12.36-2C116.11,363.19,85.86,345.27,62,321.37c-24.05-24.05-40.77-54.18-49.71-89.57-6.65-26.32-9.42-55-8.72-90.15,0-3.68,0-7.36,0-11,0-8,.08-16.31-.08-24.38C3,77.68,25,55,54.79,53.53,76,52.47,93.85,47.92,109.53,39.61A194.47,194.47,0,0,0,128.65,27.4c2.1-1.46,4.2-2.91,6.32-4.35a38,38,0,0,0,5.45-4.83c6.44-6.59,16-10.38,26.36-10.38s19.86,3.67,26.5,10.08a121.66,121.66,0,0,0,37.08,24.63A146.1,146.1,0,0,0,271.87,53l2.67.29C281.16,54,288.67,54.81,296,57c19.18,5.76,33.59,24.49,34.26,44.53.88,25.87,1.55,53.4-.69,80.55-2.95,35.73-10,64.08-22.22,89.22-15.32,31.52-38.56,57.45-69.07,77.07-16.89,10.85-35.59,19.77-57.16,27.27A43.36,43.36,0,0,1,166.81,378.17ZM153.82,236a13.85,13.85,0,0,0,2,7.34c2.07,3.46,6.77,6,11.18,6a10.55,10.55,0,0,0,2.21-.22c5.61-1.22,9.89-5.42,10.18-10,.56-8.84.58-17.67.59-27v-4.27a21.31,21.31,0,0,1,8.24-17.18,30.6,30.6,0,0,0,8.33-36.13c-5.25-11.37-16.91-19-29-19a30.06,30.06,0,0,0-4.44.33c-14.57,2.23-23.16,9.42-27,22.62s-.37,23.74,11,33.52a20.33,20.33,0,0,1,6.79,14c.18,4.07.15,8,.13,11.88,0,1.37,0,2.75,0,4.13v14h-.11Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,257.08l-1.11,0a20.6,20.6,0,0,1-20-20.75c-.07-4.59-.05-9.19,0-13.64,0-1.88,0-3.76,0-5.64V203h.14c0-.81.05-1.63.09-2.45l-.22-.15c-14.37-8.86-21-22.77-18.59-39.16,2.49-17.2,17.42-31.49,34.73-33.24a44.82,44.82,0,0,1,4.62-.24c17.47,0,32.47,10,38.24,25.45,6.63,17.78.29,36.89-15.42,46.46a7.06,7.06,0,0,0-1.14.82,7.62,7.62,0,0,0-.08,1.71c.28,7.68.18,15.33.07,22.72,0,3-.08,6-.1,9v.34a29.62,29.62,0,0,1-.3,4.68A20.89,20.89,0,0,1,166.86,257.08Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.81,377.89a38.39,38.39,0,0,1-12.35-2c-38.3-12.95-68.53-30.87-92.41-54.77s-40.73-54.18-49.67-89.57c-6.64-26.32-9.41-55-8.7-90.15,0-3.68,0-7.36,0-11,0-8,.07-16.31-.08-24.38-.55-28.55,21.49-51.2,51.25-52.7,21.14-1.06,39-5.61,54.69-13.92a191.92,191.92,0,0,0,19.1-12.21c2.11-1.46,4.2-2.91,6.32-4.35a38,38,0,0,0,5.45-4.83c6.43-6.59,16-10.38,26.33-10.38s19.85,3.67,26.47,10.08a121.57,121.57,0,0,0,37.06,24.63,145.75,145.75,0,0,0,41.46,10.46l2.67.29c6.62.71,14.12,1.51,21.45,3.71,19.15,5.76,33.55,24.49,34.23,44.53.87,25.87,1.54,53.4-.7,80.55-2.94,35.73-10,64.08-22.2,89.22-15.3,31.52-38.52,57.45-69,77.07-16.87,10.85-35.55,19.77-57.1,27.27A43.35,43.35,0,0,1,166.81,377.89Zm-13-142.18a13.85,13.85,0,0,0,2,7.34c2.07,3.46,6.77,6,11.17,6a10.39,10.39,0,0,0,2.2-.22c5.61-1.22,9.89-5.42,10.18-10,.56-8.84.58-17.67.59-27v-4.27a21.33,21.33,0,0,1,8.23-17.18,30.59,30.59,0,0,0,8.32-36.13c-5.24-11.37-16.89-19-29-19a29.82,29.82,0,0,0-4.43.33c-14.56,2.23-23.15,9.42-27,22.62s-.37,23.74,10.95,33.52a20.28,20.28,0,0,1,6.78,14c.19,4.07.16,8,.13,11.88,0,1.37,0,2.75,0,4.13v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,256.8l-1.11,0a20.59,20.59,0,0,1-20-20.75c-.07-4.59,0-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.14c0-.81,0-1.63.09-2.45l-.23-.15c-14.35-8.86-20.94-22.77-18.57-39.16,2.49-17.2,17.41-31.49,34.7-33.24a44.87,44.87,0,0,1,4.63-.24c17.44,0,32.44,10,38.2,25.45,6.62,17.78.29,36.89-15.41,46.46a7.06,7.06,0,0,0-1.14.82,7.62,7.62,0,0,0-.08,1.71c.29,7.68.18,15.33.07,22.72,0,3-.08,6-.1,9V234a29.62,29.62,0,0,1-.3,4.68A20.87,20.87,0,0,1,166.86,256.8Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.81,377.61a38.33,38.33,0,0,1-12.34-2C116.2,362.63,86,344.71,62.14,320.81c-24-24.05-40.69-54.18-49.62-89.57-6.63-26.32-9.4-55-8.69-90.15q0-5.52,0-11c0-8,.08-16.31-.07-24.38C3.24,77.12,25.26,54.47,55,53c21.12-1.06,39-5.61,54.64-13.92a191.82,191.82,0,0,0,19.09-12.21q3.15-2.19,6.31-4.35a38,38,0,0,0,5.44-4.83c6.42-6.59,16-10.38,26.31-10.38S186.61,11,193.23,17.36a121.39,121.39,0,0,0,37,24.63,145.54,145.54,0,0,0,41.43,10.46l2.66.29c6.62.71,14.11,1.51,21.43,3.71C314.91,62.21,329.29,80.94,330,101c.88,25.87,1.54,53.4-.69,80.55-2.94,35.73-10,64.08-22.18,89.22-15.29,31.52-38.49,57.45-69,77.07-16.86,10.85-35.52,19.77-57.05,27.27A43.25,43.25,0,0,1,166.81,377.61Zm-13-142.18a13.85,13.85,0,0,0,2,7.34c2.07,3.46,6.76,6,11.17,6a10.36,10.36,0,0,0,2.19-.22c5.61-1.22,9.88-5.42,10.17-10,.56-8.84.57-17.67.59-27v-4.27a21.32,21.32,0,0,1,8.22-17.18,30.62,30.62,0,0,0,8.31-36.13c-5.23-11.37-16.87-19-29-19a29.82,29.82,0,0,0-4.43.33c-14.54,2.23-23.13,9.42-27,22.62s-.36,23.74,11,33.52a20.31,20.31,0,0,1,6.77,14c.19,4.07.16,8,.13,11.88,0,1.37,0,2.75,0,4.12v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,256.52l-1.11,0a20.59,20.59,0,0,1-20-20.75c-.07-4.59-.05-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.14c0-.81.05-1.63.09-2.45l-.23-.15c-14.33-8.86-20.92-22.77-18.55-39.16,2.49-17.2,17.39-31.49,34.67-33.24a44.68,44.68,0,0,1,4.62-.24c17.43,0,32.41,10,38.16,25.45,6.62,17.78.29,36.89-15.39,46.46a7.06,7.06,0,0,0-1.14.82,8.34,8.34,0,0,0-.08,1.71c.29,7.68.18,15.33.07,22.72,0,3-.08,6-.1,9v.34a29.62,29.62,0,0,1-.3,4.68A20.86,20.86,0,0,1,166.86,256.52Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.81,377.33a38.31,38.31,0,0,1-12.33-2c-38.23-12.95-68.4-30.87-92.24-54.77S21.58,266.35,12.66,231C6,204.64,3.27,176,4,140.81c0-3.68,0-7.36,0-11,0-8,.08-16.31-.08-24.38-.54-28.55,21.45-51.2,51.17-52.7,21.09-1.06,38.95-5.61,54.58-13.92a191.6,191.6,0,0,0,19.07-12.21q3.15-2.19,6.31-4.35a38.5,38.5,0,0,0,5.44-4.83C146.91,10.79,156.49,7,166.78,7s19.81,3.67,26.43,10.08a121.21,121.21,0,0,0,37,24.63,145.35,145.35,0,0,0,41.39,10.46l2.67.29c6.61.71,14.09,1.51,21.41,3.71,19.12,5.76,33.49,24.49,34.17,44.53.87,25.87,1.53,53.4-.7,80.55-2.94,35.73-10,64.08-22.16,89.22-15.27,31.52-38.45,57.45-68.88,77.07-16.85,10.85-35.5,19.77-57,27.27A43.24,43.24,0,0,1,166.81,377.33Zm-13-142.18a13.85,13.85,0,0,0,2,7.34c2.06,3.46,6.76,6,11.16,6a10.36,10.36,0,0,0,2.19-.22c5.6-1.22,9.87-5.42,10.16-10,.56-8.84.57-17.68.59-27V207a21.32,21.32,0,0,1,8.22-17.18,30.66,30.66,0,0,0,8.3-36.13c-5.24-11.37-16.86-19-28.93-19a29.82,29.82,0,0,0-4.43.33c-14.53,2.23-23.1,9.42-27,22.62s-.37,23.74,10.94,33.52a20.35,20.35,0,0,1,6.77,14c.18,4.07.15,8,.12,11.88,0,1.37,0,2.75,0,4.12v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,256.24l-1.1,0c-11.39-.54-19.78-9.27-20-20.75-.07-4.59-.05-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.14c0-.81.05-1.63.09-2.45l-.23-.15c-14.32-8.86-20.9-22.77-18.53-39.16,2.48-17.2,17.37-31.49,34.63-33.24a44.82,44.82,0,0,1,4.62-.24c17.41,0,32.38,10,38.13,25.45,6.61,17.78.29,36.88-15.38,46.46a7.6,7.6,0,0,0-1.14.82,8.34,8.34,0,0,0-.08,1.71c.29,7.68.18,15.33.08,22.72,0,3-.09,6-.1,9v.34a29.43,29.43,0,0,1-.29,4.68C186.05,248.77,177.48,256.24,166.86,256.24Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.81,377.05a38.25,38.25,0,0,1-12.32-2c-38.2-12.95-68.34-30.87-92.16-54.77C38.38,296.2,21.71,266.07,12.8,230.68c-6.62-26.32-9.38-55-8.68-90.15,0-3.68,0-7.36,0-11,0-8,.08-16.31-.08-24.38-.54-28.55,21.43-51.2,51.12-52.7,21.07-1.06,38.91-5.61,54.53-13.92a191.5,191.5,0,0,0,19.06-12.21c2.09-1.46,4.19-2.92,6.29-4.35a37.42,37.42,0,0,0,5.44-4.83c6.41-6.59,16-10.38,26.26-10.38s19.79,3.67,26.4,10.08a121.25,121.25,0,0,0,37,24.63,145.07,145.07,0,0,0,41.35,10.46l2.66.29c6.6.71,14.09,1.51,21.39,3.71,19.11,5.76,33.46,24.49,34.14,44.53.87,25.87,1.54,53.4-.69,80.55-2.94,35.73-10,64.08-22.15,89.22-15.25,31.52-38.41,57.45-68.82,77.07-16.83,10.85-35.46,19.77-57,27.27A43.08,43.08,0,0,1,166.81,377.05ZM153.87,234.87a13.84,13.84,0,0,0,2,7.34c2.06,3.46,6.75,6,11.15,6a10.36,10.36,0,0,0,2.19-.22c5.59-1.22,9.86-5.42,10.15-10,.55-8.84.57-17.68.59-27v-4.27a21.32,21.32,0,0,1,8.21-17.18,30.67,30.67,0,0,0,8.29-36.13c-5.23-11.37-16.84-19-28.9-19a29.59,29.59,0,0,0-4.42.33c-14.52,2.23-23.09,9.42-27,22.62s-.37,23.74,10.93,33.52a20.38,20.38,0,0,1,6.76,14c.18,4.07.15,8,.12,11.88,0,1.37,0,2.75,0,4.12v14h-.11Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,256l-1.1,0c-11.38-.54-19.77-9.27-19.94-20.75-.07-4.59-.06-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.13c0-.81.06-1.63.1-2.45l-.23-.15c-14.31-8.86-20.89-22.77-18.52-39.16,2.48-17.2,17.36-31.49,34.61-33.24a44.49,44.49,0,0,1,4.61-.24c17.4,0,32.35,10,38.1,25.45,6.6,17.78.28,36.88-15.37,46.46a6.77,6.77,0,0,0-1.13.82,7.35,7.35,0,0,0-.09,1.71c.29,7.68.18,15.33.08,22.72-.05,3-.09,6-.1,9v.34a29.43,29.43,0,0,1-.29,4.68C186,248.49,177.47,256,166.86,256Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.81,376.77a38.23,38.23,0,0,1-12.31-2C116.34,361.79,86.22,343.87,62.43,320c-23.94-24.05-40.59-54.18-49.49-89.57-6.62-26.32-9.38-55-8.67-90.15q0-5.52,0-11c0-8,.08-16.31-.07-24.38-.55-28.55,21.41-51.2,51.07-52.7,21.06-1.06,38.88-5.61,54.49-13.92a192.34,192.34,0,0,0,19-12.21c2.1-1.46,4.19-2.92,6.29-4.35a37.33,37.33,0,0,0,5.43-4.83c6.41-6.59,16-10.38,26.24-10.38s19.78,3.67,26.38,10.08a121.07,121.07,0,0,0,36.92,24.63A144.86,144.86,0,0,0,271.4,51.61l2.66.29c6.59.71,14.07,1.51,21.37,3.71,19.08,5.76,33.43,24.49,34.1,44.53.87,25.87,1.54,53.4-.69,80.55-2.93,35.73-10,64.08-22.12,89.22C291.47,301.43,268.34,327.36,238,347c-16.82,10.85-35.43,19.77-56.9,27.27A43,43,0,0,1,166.81,376.77ZM153.88,234.59a13.93,13.93,0,0,0,2,7.34c2.06,3.46,6.74,6,11.14,6a10.43,10.43,0,0,0,2.19-.22c5.59-1.22,9.85-5.42,10.14-10,.55-8.84.57-17.68.59-27v-4.27a21.31,21.31,0,0,1,8.2-17.18,30.7,30.7,0,0,0,8.29-36.13c-5.23-11.37-16.83-19-28.88-19a29.69,29.69,0,0,0-4.42.33c-14.5,2.23-23.06,9.42-26.93,22.62s-.37,23.74,10.92,33.52a20.33,20.33,0,0,1,6.75,14c.18,4.07.15,8,.13,11.88,0,1.37,0,2.75,0,4.12v14h-.11Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,255.68l-1.1,0c-11.37-.54-19.75-9.27-19.92-20.75-.07-4.59-.06-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.13c0-.81.06-1.63.1-2.45l-.23-.15c-14.3-8.86-20.87-22.77-18.5-39.16,2.48-17.2,17.34-31.49,34.57-33.24a44.49,44.49,0,0,1,4.61-.24c17.38,0,32.32,10,38.06,25.45,6.6,17.78.29,36.88-15.35,46.46a6.77,6.77,0,0,0-1.13.82,7,7,0,0,0-.08,1.71c.28,7.68.17,15.33.07,22.72,0,3-.09,6-.1,9v.34a30.88,30.88,0,0,1-.3,4.68C186,248.21,177.46,255.68,166.86,255.68Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.81,376.49a38,38,0,0,1-12.29-2c-38.13-12.95-68.22-30.87-92-54.77C38.61,295.64,22,265.51,13.08,230.12c-6.61-26.32-9.37-55-8.67-90.16,0-3.67,0-7.35,0-11,0-8,.08-16.31-.08-24.38-.54-28.55,21.4-51.2,51-52.7,21-1.06,38.85-5.62,54.44-13.92a192.23,192.23,0,0,0,19-12.21c2.09-1.46,4.18-2.92,6.28-4.35a37.86,37.86,0,0,0,5.43-4.83c6.4-6.59,16-10.38,26.21-10.38s19.76,3.67,26.36,10.08A120.89,120.89,0,0,0,230,40.87,144.67,144.67,0,0,0,271.3,51.33l2.66.29c6.59.71,14.06,1.51,21.35,3.71,19.07,5.76,33.4,24.49,34.08,44.53.87,25.87,1.53,53.4-.69,80.55-2.94,35.73-10,64.08-22.11,89.22-15.23,31.52-38.34,57.45-68.69,77.06-16.81,10.86-35.4,19.78-56.85,27.28A43,43,0,0,1,166.81,376.49ZM153.89,234.31a13.93,13.93,0,0,0,2,7.34c2.06,3.46,6.74,6,11.13,6a10.43,10.43,0,0,0,2.19-.22c5.58-1.22,9.84-5.42,10.13-10,.55-8.84.57-17.68.59-27v-4.27a21.33,21.33,0,0,1,8.19-17.18,30.71,30.71,0,0,0,8.28-36.13c-5.22-11.37-16.81-19-28.85-19a29.46,29.46,0,0,0-4.41.33c-14.5,2.23-23,9.42-26.91,22.62s-.37,23.74,10.9,33.52a20.33,20.33,0,0,1,6.75,14c.19,4.07.16,8,.13,11.88,0,1.37,0,2.75,0,4.12v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,255.4l-1.1,0c-11.36-.54-19.73-9.27-19.9-20.75-.07-4.59-.06-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14H146c0-.81.06-1.63.1-2.45l-.23-.15C131.55,189.88,125,176,127.34,159.58c2.48-17.2,17.33-31.49,34.55-33.24a44.44,44.44,0,0,1,4.6-.24c17.37,0,32.29,10,38,25.45,6.59,17.78.29,36.88-15.34,46.46a6.77,6.77,0,0,0-1.13.82,7.62,7.62,0,0,0-.08,1.71c.28,7.68.17,15.33.07,22.72,0,3-.08,6-.1,9v.34a30.88,30.88,0,0,1-.3,4.68C186,247.93,177.45,255.4,166.86,255.4Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.81,376.21a38,38,0,0,1-12.28-2c-38.1-12.95-68.16-30.87-91.91-54.77-23.9-24.05-40.52-54.18-49.4-89.57-6.61-26.32-9.36-55-8.66-90.16,0-3.67,0-7.35,0-11,0-8,.07-16.31-.08-24.38-.54-28.55,21.37-51.2,51-52.7,21-1.06,38.81-5.62,54.39-13.92a190.86,190.86,0,0,0,19-12.21c2.09-1.46,4.18-2.92,6.28-4.35a37.77,37.77,0,0,0,5.42-4.83C147,9.67,156.53,5.88,166.78,5.88S186.52,9.55,193.11,16A120.93,120.93,0,0,0,230,40.59a144.31,144.31,0,0,0,41.24,10.46l2.65.29c6.59.71,14.05,1.51,21.34,3.71,19.05,5.76,33.37,24.49,34,44.53.87,25.87,1.53,53.4-.69,80.55-2.93,35.73-9.94,64.08-22.08,89.22-15.22,31.52-38.31,57.45-68.64,77.06-16.78,10.86-35.36,19.78-56.79,27.28A43,43,0,0,1,166.81,376.21ZM153.9,234a13.93,13.93,0,0,0,2,7.34c2.06,3.46,6.73,6,11.12,6a10.27,10.27,0,0,0,2.18-.22c5.58-1.22,9.84-5.42,10.13-10,.55-8.84.57-17.68.58-27v-4.27a21.36,21.36,0,0,1,8.19-17.18,30.71,30.71,0,0,0,8.27-36.13c-5.21-11.37-16.8-19-28.82-19a29.56,29.56,0,0,0-4.41.33c-14.48,2.23-23,9.42-26.89,22.62s-.36,23.74,10.9,33.52a20.32,20.32,0,0,1,6.74,14c.19,4.07.16,8,.13,11.88,0,1.37,0,2.75,0,4.12v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,255.12l-1.1,0c-11.35-.55-19.71-9.27-19.88-20.75-.07-4.59-.06-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14H146c0-.81.05-1.63.1-2.45l-.23-.15c-14.27-8.86-20.83-22.77-18.47-39.16,2.48-17.2,17.31-31.49,34.51-33.25,1.53-.15,3.08-.23,4.6-.23,17.35,0,32.26,10,38,25.45,6.59,17.78.29,36.88-15.31,46.46a6.58,6.58,0,0,0-1.14.82,7.62,7.62,0,0,0-.08,1.71c.28,7.68.17,15.33.07,22.72,0,3-.08,6-.1,9v.34a29.62,29.62,0,0,1-.3,4.68C186,247.65,177.45,255.12,166.86,255.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.81,375.93a38,38,0,0,1-12.27-2C116.48,361,86.44,343,62.71,319.13,38.84,295.08,22.23,265,13.36,229.56c-6.6-26.32-9.35-55-8.65-90.16q0-5.51,0-11c0-8,.08-16.31-.07-24.38C4.12,75.44,26,52.79,55.6,51.29c21-1.06,38.78-5.62,54.34-13.92a191.91,191.91,0,0,0,19-12.21c2.08-1.46,4.17-2.92,6.27-4.35A37.77,37.77,0,0,0,140.62,16C147,9.39,156.54,5.6,166.78,5.6s19.73,3.67,26.31,10.08a120.75,120.75,0,0,0,36.82,24.63,144.19,144.19,0,0,0,41.21,10.46l2.65.29c6.58.71,14,1.51,21.31,3.71,19,5.76,33.34,24.49,34,44.53.86,25.87,1.53,53.4-.7,80.55-2.92,35.73-9.93,64.08-22.06,89.22-15.2,31.52-38.27,57.45-68.57,77.06C221,357,202.44,365.91,181,373.41A42.75,42.75,0,0,1,166.81,375.93Zm-12.9-142.18a13.93,13.93,0,0,0,2,7.34c2.06,3.46,6.73,6,11.11,6a10.27,10.27,0,0,0,2.18-.22c5.58-1.22,9.83-5.42,10.12-10,.55-8.84.57-17.68.58-27v-4.27a21.37,21.37,0,0,1,8.18-17.18,30.73,30.73,0,0,0,8.27-36.13c-5.21-11.37-16.79-19-28.8-19a29.56,29.56,0,0,0-4.41.33c-14.46,2.23-23,9.42-26.86,22.62s-.37,23.74,10.89,33.52a20.4,20.4,0,0,1,6.74,14c.18,4.07.15,8,.12,11.88,0,1.37,0,2.75,0,4.12v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,254.84l-1.1,0c-11.33-.55-19.69-9.27-19.86-20.75-.07-4.59-.06-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14H146c0-.81.05-1.63.1-2.45l-.23-.15c-14.26-8.86-20.81-22.77-18.46-39.16,2.48-17.2,17.3-31.49,34.49-33.25,1.52-.15,3.07-.23,4.59-.23,17.34,0,32.24,10,38,25.45,6.58,17.78.29,36.88-15.3,46.46a6.58,6.58,0,0,0-1.14.82,7.62,7.62,0,0,0-.08,1.71c.28,7.68.18,15.33.07,22.72,0,3-.08,6-.1,9V232a29.62,29.62,0,0,1-.3,4.68C186,247.37,177.44,254.84,166.86,254.84Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.81,375.65a37.89,37.89,0,0,1-12.26-2c-38-12.95-68-30.87-91.75-54.77C39,294.8,22.36,264.67,13.49,229.28c-6.59-26.32-9.34-55-8.64-90.16,0-3.67,0-7.35,0-11,0-8,.08-16.31-.08-24.38C4.27,75.16,26.15,52.51,55.7,51,76.68,50,94.44,45.39,110,37.09a190.54,190.54,0,0,0,19-12.21c2.09-1.46,4.17-2.92,6.27-4.35a38.23,38.23,0,0,0,5.41-4.83C147,9.11,156.55,5.32,166.78,5.32S186.49,9,193.07,15.4A120.57,120.57,0,0,0,229.85,40,144,144,0,0,0,271,50.49l2.65.29c6.57.71,14,1.51,21.3,3.71C314,60.25,328.28,79,329,99c.87,25.87,1.53,53.4-.69,80.55-2.92,35.73-9.93,64.08-22,89.22C291,300.31,268,326.24,237.7,345.85,221,356.71,202.4,365.63,181,373.13A42.74,42.74,0,0,1,166.81,375.65ZM153.93,233.47a13.83,13.83,0,0,0,2,7.34c2,3.46,6.72,6,11.1,6a10.27,10.27,0,0,0,2.18-.22c5.57-1.22,9.82-5.42,10.1-10,.56-8.85.57-17.68.59-27v-4.27a21.39,21.39,0,0,1,8.17-17.18A30.76,30.76,0,0,0,196.34,152c-5.2-11.37-16.77-19-28.77-19a29.43,29.43,0,0,0-4.4.34c-14.46,2.23-23,9.42-26.84,22.62s-.37,23.74,10.88,33.52a20.39,20.39,0,0,1,6.73,14c.18,4.08.15,8.05.12,11.89,0,1.37,0,2.75,0,4.12v14h-.11Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,254.56l-1.1,0c-11.32-.55-19.67-9.27-19.85-20.75-.06-4.59,0-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14H146c0-.81,0-1.63.09-2.45l-.22-.15c-14.25-8.86-20.8-22.77-18.44-39.16,2.47-17.2,17.28-31.49,34.45-33.25,1.53-.15,3.07-.23,4.59-.23,17.32,0,32.21,10,37.93,25.45,6.58,17.78.29,36.88-15.29,46.46A6.58,6.58,0,0,0,188,198a8.34,8.34,0,0,0-.08,1.71c.28,7.68.18,15.33.07,22.72,0,3-.08,6-.1,9v.34a30.48,30.48,0,0,1-.29,4.67C186,247.09,177.43,254.56,166.86,254.56Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.82,375.37a37.89,37.89,0,0,1-12.26-2c-38-12.95-68-30.87-91.66-54.77S22.49,264.39,13.63,229C7,202.68,4.3,174,5,138.84c0-3.67,0-7.35,0-11,0-8,.08-16.31-.07-24.38-.54-28.55,21.31-51.2,50.84-52.7,21-1.06,38.71-5.62,54.24-13.92A190.33,190.33,0,0,0,129,24.6c2.09-1.46,4.17-2.92,6.27-4.35a37.6,37.6,0,0,0,5.4-4.83C147,8.83,156.56,5,166.78,5s19.69,3.67,26.27,10.08A120.44,120.44,0,0,0,229.8,39.75a143.64,143.64,0,0,0,41.13,10.46l2.65.29c6.56.71,14,1.51,21.27,3.71,19,5.76,33.28,24.49,33.95,44.53.87,25.87,1.53,53.4-.68,80.55-2.92,35.73-9.92,64.08-22,89.22C290.92,300,267.89,326,237.64,345.57c-16.74,10.86-35.27,19.78-56.64,27.28A42.67,42.67,0,0,1,166.82,375.37ZM153.94,233.19a13.92,13.92,0,0,0,2,7.34c2.05,3.46,6.71,6,11.09,6a10.27,10.27,0,0,0,2.18-.22c5.56-1.22,9.81-5.42,10.09-10,.56-8.85.57-17.68.59-27V205a21.42,21.42,0,0,1,8.16-17.19,30.75,30.75,0,0,0,8.25-36.12c-5.2-11.37-16.75-19-28.74-19a29.43,29.43,0,0,0-4.4.34c-14.44,2.23-23,9.42-26.81,22.62s-.37,23.74,10.87,33.52a20.38,20.38,0,0,1,6.72,14c.18,4.08.15,8.05.12,11.89,0,1.37,0,2.75,0,4.12v14h-.11Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,254.28l-1.09,0c-11.32-.55-19.66-9.27-19.84-20.75-.06-4.59-.05-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14H146c0-.81.05-1.63.09-2.45l-.22-.15c-14.24-8.86-20.78-22.77-18.42-39.16,2.47-17.2,17.26-31.49,34.42-33.25,1.52-.15,3.06-.23,4.58-.23,17.31,0,32.18,10,37.89,25.45,6.58,17.78.29,36.88-15.27,46.46a7.06,7.06,0,0,0-1.14.82,8.34,8.34,0,0,0-.08,1.71c.29,7.68.18,15.33.07,22.72,0,3-.08,6-.09,9v.34a29.25,29.25,0,0,1-.29,4.67C185.93,246.81,177.42,254.28,166.86,254.28Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.82,375.09a37.79,37.79,0,0,1-12.25-2c-38-12.95-67.91-30.87-91.58-54.77-23.81-24.05-40.37-54.18-49.22-89.57-6.58-26.32-9.32-55-8.63-90.16q0-5.51,0-11c0-8,.08-16.31-.07-24.38C4.56,74.6,26.4,52,55.9,50.45c20.95-1.06,38.67-5.62,54.2-13.92A191.28,191.28,0,0,0,129,24.32c2.08-1.46,4.16-2.92,6.26-4.35a38.14,38.14,0,0,0,5.4-4.83c6.37-6.59,15.88-10.38,26.09-10.38S186.45,8.43,193,14.84a120.43,120.43,0,0,0,36.72,24.63,143.43,143.43,0,0,0,41.1,10.46l2.64.29c6.56.71,14,1.51,21.26,3.71,19,5.76,33.25,24.49,33.92,44.53.87,25.87,1.53,53.4-.69,80.55-2.92,35.73-9.91,64.08-22,89.22-15.17,31.52-38.18,57.45-68.39,77.06-16.73,10.86-35.24,19.78-56.59,27.28A42.66,42.66,0,0,1,166.82,375.09ZM154,232.91a14,14,0,0,0,2,7.34c2.05,3.46,6.71,6,11.08,6a10.33,10.33,0,0,0,2.18-.22c5.56-1.22,9.8-5.42,10.08-10,.55-8.85.57-17.68.59-27v-4.27a21.39,21.39,0,0,1,8.16-17.19,30.79,30.79,0,0,0,8.24-36.12c-5.2-11.37-16.74-19-28.72-19a29.43,29.43,0,0,0-4.4.34c-14.42,2.23-22.94,9.42-26.78,22.62s-.37,23.74,10.85,33.52a20.38,20.38,0,0,1,6.72,14c.18,4.08.16,8.05.13,11.89,0,1.37,0,2.75,0,4.12v14H154Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,254l-1.09,0c-11.31-.55-19.64-9.27-19.82-20.75-.07-4.59,0-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.13c0-.81.05-1.63.09-2.45l-.22-.15c-14.22-8.86-20.76-22.77-18.41-39.16,2.47-17.2,17.25-31.49,34.39-33.25,1.52-.15,3.07-.23,4.58-.23,17.29,0,32.15,10,37.86,25.45,6.57,17.78.29,36.88-15.26,46.46a7.06,7.06,0,0,0-1.14.82,8.34,8.34,0,0,0-.08,1.71c.29,7.68.18,15.33.08,22.72,0,3-.09,6-.1,9v.34a29.25,29.25,0,0,1-.29,4.67C185.92,246.53,177.41,254,166.86,254Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.82,374.81a37.77,37.77,0,0,1-12.24-2C116.66,359.83,86.73,341.91,63.09,318,39.3,294,22.75,263.83,13.91,228.44c-6.58-26.32-9.32-55-8.62-90.16,0-3.67,0-7.35,0-11,0-8,.07-16.31-.08-24.38C4.71,74.32,26.53,51.67,56,50.17c20.93-1.06,38.64-5.62,54.15-13.92A191.06,191.06,0,0,0,129.06,24c2.08-1.46,4.16-2.92,6.26-4.35a37.51,37.51,0,0,0,5.39-4.83c6.37-6.59,15.87-10.38,26.07-10.38S186.44,8.15,193,14.56a120.18,120.18,0,0,0,36.69,24.63,143.26,143.26,0,0,0,41.05,10.46l2.64.29c6.56.71,14,1.51,21.24,3.71,19,5.76,33.22,24.49,33.89,44.53.87,25.87,1.53,53.4-.68,80.55-2.92,35.73-9.9,64.08-22,89.22C290.69,299.47,267.7,325.4,237.51,345c-16.71,10.86-35.2,19.78-56.54,27.28A42.75,42.75,0,0,1,166.82,374.81ZM154,232.63a14,14,0,0,0,2,7.34c2,3.46,6.7,6,11.07,6a10.17,10.17,0,0,0,2.17-.22c5.56-1.22,9.8-5.42,10.08-10,.55-8.85.57-17.68.59-27v-4.27A21.38,21.38,0,0,1,188,187.24a30.79,30.79,0,0,0,8.23-36.12c-5.19-11.37-16.72-19-28.69-19a29.2,29.2,0,0,0-4.39.34c-14.42,2.23-22.92,9.42-26.77,22.62s-.36,23.74,10.85,33.52a20.37,20.37,0,0,1,6.71,14c.19,4.07.16,8.05.13,11.89,0,1.37,0,2.75,0,4.12v14H154Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,253.72l-1.09,0c-11.3-.55-19.62-9.27-19.8-20.75-.07-4.59-.05-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.13c0-.81,0-1.63.09-2.45l-.22-.15c-14.21-8.86-20.74-22.77-18.39-39.16,2.46-17.2,17.23-31.49,34.36-33.25,1.52-.15,3.06-.23,4.57-.23,17.28,0,32.12,10,37.83,25.45,6.56,17.78.29,36.88-15.25,46.46a6.77,6.77,0,0,0-1.13.82,7,7,0,0,0-.08,1.71c.28,7.68.17,15.33.07,22.72,0,3-.09,6-.1,9v.34a30.68,30.68,0,0,1-.3,4.67C185.9,246.25,177.4,253.72,166.86,253.72Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.82,374.53a37.61,37.61,0,0,1-12.22-2c-37.89-12.95-67.8-30.87-91.42-54.77-23.77-24.05-40.3-54.18-49.13-89.57-6.57-26.32-9.31-55-8.61-90.16q0-5.5,0-11c0-8,.08-16.31-.07-24.38C4.86,74,26.65,51.39,56.1,49.89,77,48.83,94.7,44.27,110.2,36a191,191,0,0,0,18.9-12.21c2.08-1.46,4.15-2.92,6.24-4.35a38.14,38.14,0,0,0,5.4-4.83C147.09,8,156.59,4.2,166.78,4.2S186.42,7.87,193,14.28a120,120,0,0,0,36.65,24.63,143.05,143.05,0,0,0,41,10.46l2.64.29c6.55.71,14,1.51,21.22,3.71,18.95,5.76,33.19,24.49,33.86,44.53.86,25.87,1.52,53.4-.69,80.55-2.91,35.73-9.89,64.08-22,89.22-15.14,31.52-38.11,57.45-68.27,77.06C220.76,355.59,202.28,364.51,181,372A42.65,42.65,0,0,1,166.82,374.53ZM154,232.35a14,14,0,0,0,2,7.34c2.05,3.46,6.7,6,11.06,6a10.17,10.17,0,0,0,2.17-.22c5.55-1.22,9.79-5.42,10.07-10,.55-8.85.57-17.68.59-27v-4.27A21.4,21.4,0,0,1,188,187a30.82,30.82,0,0,0,8.23-36.12c-5.19-11.37-16.71-19-28.67-19a29.3,29.3,0,0,0-4.39.34c-14.4,2.23-22.9,9.42-26.74,22.62s-.36,23.74,10.84,33.52a20.45,20.45,0,0,1,6.71,14c.18,4.07.15,8.05.12,11.89,0,1.37,0,2.75,0,4.12v14H154Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,253.44l-1.09,0c-11.29-.55-19.6-9.27-19.78-20.75-.07-4.59-.05-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.14c0-.81.05-1.63.09-2.45l-.22-.15c-14.2-8.86-20.72-22.77-18.38-39.16,2.47-17.2,17.22-31.49,34.33-33.25,1.52-.15,3.06-.23,4.57-.23,17.26,0,32.1,10,37.79,25.45,6.56,17.78.29,36.88-15.23,46.46a6.77,6.77,0,0,0-1.13.82,7.62,7.62,0,0,0-.08,1.71c.28,7.68.17,15.33.07,22.72,0,3-.08,6-.1,9v.34a30.68,30.68,0,0,1-.3,4.67C185.88,246,177.39,253.44,166.86,253.44Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.82,374.25a37.55,37.55,0,0,1-12.21-2c-37.86-13-67.73-30.87-91.34-54.77S23,263.27,14.19,227.88c-6.57-26.32-9.31-55-8.61-90.16,0-3.67,0-7.35,0-11,0-8,.07-16.31-.08-24.38C5,73.76,26.78,51.1,56.2,49.61,77.09,48.55,94.77,44,110.25,35.69a189.59,189.59,0,0,0,18.88-12.21c2.08-1.46,4.15-2.92,6.24-4.35a37.51,37.51,0,0,0,5.39-4.83c6.35-6.59,15.84-10.38,26-10.38S186.4,7.59,193,14a120.11,120.11,0,0,0,36.62,24.63,142.84,142.84,0,0,0,41,10.46l2.63.29c6.55.71,14,1.51,21.2,3.71,18.94,5.76,33.16,24.49,33.83,44.53.87,25.87,1.53,53.4-.68,80.55-2.91,35.73-9.88,64.08-22,89.22-15.12,31.52-38.07,57.45-68.2,77.06-16.68,10.86-35.14,19.78-56.44,27.28A42.64,42.64,0,0,1,166.82,374.25ZM154,232.07a13.93,13.93,0,0,0,2,7.34c2.05,3.46,6.69,6,11.05,6a10.24,10.24,0,0,0,2.17-.22c5.55-1.22,9.78-5.42,10.06-10,.55-8.85.57-17.68.59-27v-4.27A21.42,21.42,0,0,1,188,186.68a30.83,30.83,0,0,0,8.22-36.12c-5.18-11.37-16.69-19-28.64-19a29.3,29.3,0,0,0-4.39.34c-14.38,2.23-22.87,9.42-26.71,22.62S136.1,178.24,147.3,188a20.44,20.44,0,0,1,6.7,14c.18,4.07.15,8.05.12,11.89,0,1.37,0,2.75,0,4.12v14H154Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,253.16l-1.09,0c-11.28-.55-19.59-9.27-19.76-20.75-.07-4.59,0-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.14c0-.81,0-1.63.09-2.45l-.23-.15c-14.18-8.86-20.7-22.77-18.35-39.16,2.46-17.2,17.2-31.49,34.3-33.25,1.51-.15,3-.23,4.56-.23,17.25,0,32.07,10,37.76,25.45,6.55,17.78.29,36.88-15.22,46.46a6.77,6.77,0,0,0-1.13.82,7.62,7.62,0,0,0-.08,1.71c.28,7.68.17,15.33.07,22.72,0,3-.08,6-.1,9v.34a29.44,29.44,0,0,1-.3,4.67C185.87,245.69,177.38,253.16,166.86,253.16Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.82,374a37.49,37.49,0,0,1-12.2-2C116.8,359,87,341.07,63.37,317.17c-23.73-24.05-40.23-54.18-49-89.57-6.56-26.32-9.29-55-8.59-90.16q0-5.5,0-11c0-8,.08-16.31-.07-24.38C5.15,73.48,26.91,50.82,56.3,49.33c20.87-1.06,38.53-5.62,54-13.92A190.64,190.64,0,0,0,129.17,23.2c2.07-1.46,4.14-2.92,6.23-4.35A37.42,37.42,0,0,0,140.78,14c6.35-6.6,15.83-10.38,26-10.38s19.6,3.67,26.15,10.08a120,120,0,0,0,36.59,24.63,142.5,142.5,0,0,0,40.94,10.46l2.64.28c6.54.72,13.94,1.52,21.18,3.72,18.91,5.76,33.13,24.49,33.8,44.53.86,25.87,1.52,53.4-.69,80.55-2.9,35.73-9.87,64.08-21.92,89.22-15.11,31.52-38,57.45-68.14,77.06C220.66,355,202.22,364,180.94,371.45A42.58,42.58,0,0,1,166.82,374ZM154,231.79a14,14,0,0,0,2,7.34c2,3.46,6.69,6,11,6a10.24,10.24,0,0,0,2.17-.22c5.54-1.22,9.77-5.42,10.05-10,.55-8.85.57-17.68.58-27v-4.27A21.45,21.45,0,0,1,188,186.4a30.84,30.84,0,0,0,8.21-36.12c-5.17-11.37-16.67-19-28.61-19a29.06,29.06,0,0,0-4.38.34c-14.38,2.23-22.86,9.42-26.69,22.62s-.37,23.74,10.82,33.52a20.43,20.43,0,0,1,6.69,14c.18,4.07.15,8.05.12,11.89,0,1.37,0,2.75,0,4.12v14H154Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,252.88l-1.09,0c-11.26-.55-19.57-9.27-19.74-20.75-.07-4.59-.05-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.13c0-.81.06-1.63.1-2.45l-.23-.15c-14.17-8.86-20.68-22.77-18.34-39.16,2.46-17.2,17.19-31.49,34.27-33.25,1.52-.15,3-.23,4.57-.23,17.22,0,32,10,37.72,25.45,6.54,17.78.28,36.88-15.21,46.46a6.77,6.77,0,0,0-1.13.82,7.62,7.62,0,0,0-.08,1.71c.28,7.68.17,15.33.07,22.72,0,3-.08,6-.1,9v.34a30.55,30.55,0,0,1-.29,4.67C185.85,245.41,177.37,252.88,166.86,252.88Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.82,373.69a37.43,37.43,0,0,1-12.19-2c-37.79-13-67.61-30.87-91.17-54.77s-40.19-54.18-49-89.57c-6.55-26.32-9.28-55-8.59-90.16,0-3.67,0-7.35,0-11,0-8,.08-16.31-.08-24.38C5.29,73.2,27,50.54,56.4,49.05c20.85-1.06,38.5-5.62,54-13.92A189.27,189.27,0,0,0,129.2,22.92c2.07-1.46,4.15-2.92,6.23-4.35a38,38,0,0,0,5.38-4.83c6.34-6.6,15.81-10.38,26-10.38S186.37,7,192.91,13.43a119.54,119.54,0,0,0,36.55,24.64,142.38,142.38,0,0,0,40.91,10.46l2.63.28c6.53.72,13.94,1.52,21.16,3.72,18.9,5.76,33.11,24.49,33.78,44.53.86,25.87,1.52,53.4-.69,80.55-2.9,35.73-9.86,64.08-21.9,89.22-15.1,31.52-38,57.45-68.09,77.06-16.65,10.86-35.08,19.78-56.34,27.28A42.43,42.43,0,0,1,166.82,373.69ZM154,231.51a14,14,0,0,0,2,7.34c2,3.46,6.68,6,11,6a10.24,10.24,0,0,0,2.17-.22c5.53-1.22,9.75-5.42,10-10,.55-8.85.56-17.68.58-27v-4.27A21.47,21.47,0,0,1,188,186.12,30.87,30.87,0,0,0,196.16,150c-5.17-11.37-16.67-19-28.59-19a29.06,29.06,0,0,0-4.38.34c-14.36,2.23-22.83,9.42-26.67,22.62s-.36,23.74,10.81,33.52a20.39,20.39,0,0,1,6.69,14c.18,4.07.15,8.05.13,11.89,0,1.37,0,2.75,0,4.12v14H154Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,252.6l-1.09,0c-11.25-.55-19.55-9.27-19.72-20.75-.07-4.59-.05-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.13c0-.81.06-1.63.1-2.45l-.23-.15c-14.15-8.86-20.66-22.77-18.32-39.16,2.46-17.2,17.18-31.49,34.24-33.25,1.51-.15,3.05-.23,4.56-.23,17.21,0,32,10,37.68,25.45,6.54,17.78.29,36.88-15.19,46.46a6.77,6.77,0,0,0-1.13.82,8.34,8.34,0,0,0-.08,1.71c.28,7.68.18,15.33.07,22.72,0,3-.08,6-.1,9v.34a30.55,30.55,0,0,1-.29,4.67C185.83,245.13,177.36,252.6,166.86,252.6Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.82,373.41a37.41,37.41,0,0,1-12.18-2c-37.75-12.95-67.55-30.87-91.09-54.77C39.87,292.56,23.4,262.43,14.6,227c-6.55-26.32-9.28-55-8.58-90.16q0-5.5,0-11c0-8,.08-16.31-.07-24.38C5.44,72.92,27.16,50.26,56.5,48.77,77.33,47.71,95,43.15,110.4,34.85a190.33,190.33,0,0,0,18.84-12.21c2.07-1.46,4.14-2.92,6.22-4.35a37.88,37.88,0,0,0,5.37-4.83c6.34-6.6,15.8-10.38,25.95-10.38s19.57,3.67,26.1,10.07a119.75,119.75,0,0,0,36.53,24.64,142,142,0,0,0,40.87,10.46l2.63.28c6.52.72,13.92,1.52,21.14,3.72,18.88,5.76,33.07,24.49,33.74,44.53.86,25.87,1.52,53.4-.68,80.55-2.91,35.73-9.86,64.08-21.89,89.22-15.08,31.52-38,57.45-68,77.06-16.64,10.86-35,19.78-56.29,27.28A42.41,42.41,0,0,1,166.82,373.41ZM154,231.23a14,14,0,0,0,2,7.34c2,3.46,6.67,6,11,6a10.24,10.24,0,0,0,2.17-.22c5.53-1.22,9.74-5.42,10-10,.55-8.85.56-17.68.58-27V203a21.46,21.46,0,0,1,8.11-17.19,30.88,30.88,0,0,0,8.2-36.12c-5.17-11.37-16.65-19-28.57-19a29,29,0,0,0-4.36.34c-14.35,2.23-22.82,9.42-26.65,22.62s-.36,23.74,10.8,33.52a20.42,20.42,0,0,1,6.68,14c.19,4.07.16,8.05.13,11.89,0,1.37,0,2.75,0,4.12v14H154Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,252.32l-1.09,0c-11.24-.55-19.53-9.27-19.7-20.75-.07-4.59-.06-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.13c0-.81.05-1.63.1-2.45l-.23-.15c-14.14-8.86-20.64-22.77-18.3-39.16,2.45-17.2,17.16-31.49,34.2-33.25,1.51-.15,3.05-.23,4.56-.23,17.19,0,32,10,37.65,25.45,6.53,17.78.29,36.88-15.18,46.46a6.77,6.77,0,0,0-1.13.82,8.27,8.27,0,0,0-.08,1.71c.28,7.68.18,15.33.07,22.72,0,3-.08,6-.09,9v.34a29.31,29.31,0,0,1-.29,4.67C185.82,244.85,177.35,252.32,166.86,252.32Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.82,373.13a37.35,37.35,0,0,1-12.17-2c-37.72-13-67.49-30.87-91-54.77s-40.12-54.18-48.92-89.57c-6.54-26.32-9.26-55-8.57-90.16,0-3.67,0-7.35,0-11,0-8,.08-16.31-.08-24.38C5.59,72.64,27.28,50,56.6,48.49,77.41,47.43,95,42.87,110.45,34.57a189,189,0,0,0,18.82-12.21c2.07-1.46,4.14-2.92,6.22-4.35a37.88,37.88,0,0,0,5.37-4.83c6.32-6.6,15.78-10.38,25.92-10.38s19.55,3.67,26.08,10.07a119.58,119.58,0,0,0,36.49,24.64A141.82,141.82,0,0,0,270.19,48l2.62.28c6.52.72,13.91,1.52,21.13,3.72,18.86,5.76,33,24.49,33.71,44.53.86,25.87,1.51,53.4-.69,80.55-2.9,35.73-9.84,64.08-21.86,89.22-15.07,31.52-37.94,57.45-68,77.06-16.62,10.86-35,19.78-56.24,27.28A42.31,42.31,0,0,1,166.82,373.13ZM154,231a14,14,0,0,0,2,7.34c2,3.46,6.67,6,11,6a10.08,10.08,0,0,0,2.16-.22c5.53-1.22,9.74-5.42,10-10,.55-8.85.56-17.68.58-27v-4.26a21.45,21.45,0,0,1,8.1-17.19,30.91,30.91,0,0,0,8.2-36.12c-5.17-11.37-16.64-19-28.55-19a29,29,0,0,0-4.36.34c-14.34,2.23-22.8,9.42-26.62,22.62s-.36,23.74,10.79,33.52a20.41,20.41,0,0,1,6.67,14c.19,4.07.16,8.05.13,11.89,0,1.37,0,2.75,0,4.12v14H154Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,252l-1.08,0c-11.24-.55-19.52-9.27-19.69-20.75-.07-4.59-.06-9.19,0-13.64,0-1.88,0-3.76,0-5.64V198h.13c0-.81.05-1.63.1-2.45l-.23-.15c-14.13-8.86-20.63-22.77-18.29-39.16,2.45-17.2,17.15-31.49,34.17-33.25,1.52-.15,3.05-.23,4.56-.23,17.18,0,31.94,10,37.62,25.45,6.52,17.78.28,36.88-15.17,46.46a6.77,6.77,0,0,0-1.13.82,8.27,8.27,0,0,0-.08,1.71c.29,7.68.18,15.33.08,22.72,0,3-.09,6-.1,9v.34a30.76,30.76,0,0,1-.3,4.67C185.8,244.57,177.35,252,166.86,252Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.82,372.85a37.34,37.34,0,0,1-12.16-2C117,357.87,87.24,340,63.74,316.05,40.1,292,23.66,261.87,14.87,226.48c-6.53-26.32-9.26-55-8.56-90.16q0-5.5,0-11c0-8,.08-16.31-.07-24.38C5.73,72.36,27.41,49.7,56.7,48.21c20.79-1.06,38.4-5.62,53.81-13.92A189.8,189.8,0,0,0,129.3,22.08c2.07-1.46,4.14-2.92,6.22-4.35a38.34,38.34,0,0,0,5.36-4.83c6.32-6.6,15.77-10.38,25.9-10.38s19.54,3.67,26.06,10.07a119.4,119.4,0,0,0,36.45,24.64,141.39,141.39,0,0,0,40.8,10.45l2.63.29c6.51.71,13.89,1.52,21.1,3.72,18.85,5.76,33,24.49,33.68,44.53.86,25.87,1.52,53.4-.68,80.55C323.92,212.5,317,240.85,305,266c-15.05,31.52-37.9,57.45-67.9,77.06-16.6,10.86-35,19.78-56.18,27.28A42.3,42.3,0,0,1,166.82,372.85ZM154,230.67a14.12,14.12,0,0,0,2,7.34,13.39,13.39,0,0,0,11,6,10.08,10.08,0,0,0,2.16-.22c5.52-1.22,9.73-5.42,10-10,.54-8.85.56-17.68.58-27v-4.26a21.45,21.45,0,0,1,8.1-17.19,30.93,30.93,0,0,0,8.18-36.12c-5.16-11.37-16.62-19-28.52-19a29,29,0,0,0-4.36.34c-14.32,2.23-22.77,9.42-26.59,22.62s-.37,23.74,10.78,33.52a20.45,20.45,0,0,1,6.67,14c.18,4.07.15,8.05.12,11.89,0,1.37,0,2.75,0,4.12v14H154Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,251.76l-1.08,0c-11.23-.55-19.5-9.27-19.67-20.75-.07-4.59-.06-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.13c0-.81,0-1.63.09-2.45l-.22-.15c-14.12-8.86-20.61-22.77-18.27-39.16,2.45-17.2,17.13-31.49,34.14-33.25,1.51-.15,3-.23,4.55-.23,17.16,0,31.92,10,37.59,25.45,6.51,17.78.28,36.88-15.16,46.46a6.51,6.51,0,0,0-1.12.82,7,7,0,0,0-.08,1.71c.28,7.68.17,15.33.07,22.72,0,3-.09,6-.1,9v.34a30.76,30.76,0,0,1-.3,4.67C185.78,244.29,177.34,251.76,166.86,251.76Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.82,372.57a37.24,37.24,0,0,1-12.15-2c-37.65-13-67.36-30.87-90.84-54.77S23.79,261.59,15,226.2c-6.53-26.32-9.25-55-8.56-90.16,0-3.67,0-7.35,0-11,0-8,.07-16.31-.08-24.38C5.88,72.08,27.54,49.42,56.8,47.93,77.57,46.87,95.16,42.31,110.56,34A189.7,189.7,0,0,0,129.34,21.8c2.06-1.46,4.13-2.92,6.2-4.35a37.25,37.25,0,0,0,5.36-4.83C147.22,6,156.65,2.24,166.78,2.24s19.52,3.67,26,10.07A119.44,119.44,0,0,0,229.24,37,141,141,0,0,0,270,47.4l2.62.29c6.51.71,13.89,1.52,21.09,3.72,18.83,5.76,33,24.49,33.65,44.53.86,25.87,1.51,53.4-.69,80.54-2.89,35.74-9.82,64.09-21.82,89.23-15,31.52-37.87,57.45-67.84,77.06-16.59,10.86-34.95,19.78-56.13,27.28A42.24,42.24,0,0,1,166.82,372.57ZM154.06,230.39a14.11,14.11,0,0,0,2,7.34,13.38,13.38,0,0,0,11,6,10.08,10.08,0,0,0,2.16-.22c5.51-1.22,9.72-5.42,10-10,.54-8.85.56-17.68.58-27v-4.26A21.44,21.44,0,0,1,187.88,185a31,31,0,0,0,8.17-36.12c-5.15-11.37-16.6-19-28.49-19a28.77,28.77,0,0,0-4.35.34c-14.31,2.23-22.76,9.42-26.58,22.62s-.36,23.74,10.77,33.52a20.45,20.45,0,0,1,6.67,14c.18,4.07.15,8,.12,11.89,0,1.37,0,2.75,0,4.12v14h-.11Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,251.48l-1.08,0c-11.22-.55-19.48-9.27-19.66-20.75-.06-4.59-.05-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.13c0-.81.05-1.63.09-2.45l-.22-.15C132,186,125.51,172.05,127.84,155.66c2.45-17.2,17.12-31.49,34.11-33.25,1.52-.15,3-.23,4.55-.23,17.15,0,31.89,10,37.55,25.45,6.51,17.78.29,36.88-15.14,46.46a7,7,0,0,0-1.12.82,7.56,7.56,0,0,0-.08,1.71c.28,7.68.17,15.33.07,22.72,0,3-.08,6-.1,9v.34a30.55,30.55,0,0,1-.29,4.67C185.77,244,177.33,251.48,166.86,251.48Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.82,372.29a37.07,37.07,0,0,1-12.13-2c-37.62-12.95-67.31-30.87-90.76-54.77-23.6-24.05-40-54.18-48.78-89.57C8.62,199.6,5.9,171,6.6,135.76q0-5.5,0-11c0-8,.08-16.31-.07-24.38C6,71.8,27.66,49.14,56.9,47.65,77.66,46.59,95.22,42,110.61,33.73a189.49,189.49,0,0,0,18.76-12.21c2.07-1.46,4.13-2.92,6.2-4.35a37.79,37.79,0,0,0,5.36-4.83C147.24,5.74,156.66,2,166.79,2s19.49,3.67,26,10.07a119.26,119.26,0,0,0,36.39,24.64,140.83,140.83,0,0,0,40.73,10.45l2.62.29c6.5.71,13.87,1.52,21.06,3.72,18.82,5.76,33,24.49,33.62,44.53.86,25.87,1.52,53.4-.68,80.54-2.89,35.74-9.82,64.09-21.8,89.23-15,31.52-37.84,57.45-67.78,77.06-16.58,10.86-34.92,19.78-56.09,27.28A42.09,42.09,0,0,1,166.82,372.29ZM154.07,230.11a14.11,14.11,0,0,0,2,7.34c2,3.46,6.65,6,11,6a10.08,10.08,0,0,0,2.16-.22c5.51-1.22,9.71-5.42,10-10,.54-8.85.56-17.68.58-27v-4.26a21.46,21.46,0,0,1,8.08-17.19A31,31,0,0,0,196,148.6c-5.15-11.37-16.59-19-28.47-19a28.77,28.77,0,0,0-4.35.34c-14.3,2.23-22.73,9.42-26.55,22.62s-.36,23.74,10.76,33.52a20.44,20.44,0,0,1,6.66,14c.18,4.07.15,8,.12,11.89,0,1.37,0,2.75,0,4.12v14h-.11Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,251.2l-1.08,0c-11.21-.55-19.46-9.27-19.64-20.75-.06-4.59,0-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.13c0-.81.05-1.63.09-2.45l-.22-.15c-14.1-8.86-20.57-22.77-18.24-39.16,2.44-17.2,17.1-31.49,34.08-33.25,1.51-.15,3-.23,4.54-.23,17.13,0,31.86,10,37.52,25.45,6.51,17.78.28,36.88-15.13,46.46a7,7,0,0,0-1.12.82,7.51,7.51,0,0,0-.08,1.71c.28,7.68.17,15.33.07,22.72,0,3-.08,6-.1,9v.34a30.55,30.55,0,0,1-.29,4.67C185.75,243.73,177.32,251.2,166.86,251.2Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.82,372a37.06,37.06,0,0,1-12.12-2C117.11,357,87.45,339.11,64,315.21s-40-54.18-48.74-89.57c-6.52-26.32-9.23-55-8.54-90.16,0-3.67,0-7.35,0-11,0-8,.07-16.31-.08-24.38C6.17,71.52,27.79,48.86,57,47.37c20.74-1.06,38.29-5.62,53.66-13.92a189.39,189.39,0,0,0,18.75-12.21c2.06-1.46,4.12-2.92,6.19-4.35A37.7,37.7,0,0,0,141,12.06c6.31-6.6,15.72-10.38,25.84-10.38s19.47,3.67,26,10.07a119.13,119.13,0,0,0,36.36,24.64,140.55,140.55,0,0,0,40.69,10.45l2.61.29c6.5.71,13.86,1.52,21.05,3.72,18.8,5.76,32.92,24.49,33.59,44.53.86,25.87,1.51,53.4-.68,80.54-2.89,35.74-9.81,64.09-21.79,89.23-15,31.52-37.8,57.45-67.71,77.06-16.57,10.86-34.89,19.78-56,27.28A42.07,42.07,0,0,1,166.82,372ZM154.08,229.83a14.11,14.11,0,0,0,2,7.34,13.33,13.33,0,0,0,11,6,10.14,10.14,0,0,0,2.16-.22c5.5-1.22,9.7-5.42,10-10,.55-8.85.57-17.68.58-27v-4.26a21.52,21.52,0,0,1,8.08-17.19c10.33-8.55,13.76-23.74,8.16-36.12-5.14-11.37-16.57-19-28.44-19a28.87,28.87,0,0,0-4.35.34c-14.28,2.23-22.71,9.42-26.52,22.62s-.36,23.74,10.75,33.52a20.47,20.47,0,0,1,6.65,14c.18,4.07.15,8,.13,11.89,0,1.37,0,2.75,0,4.12v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,250.92l-1.08,0c-11.19-.54-19.44-9.26-19.62-20.74-.06-4.59,0-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.13c0-.81,0-1.63.09-2.45l-.22-.15c-14.08-8.86-20.56-22.77-18.23-39.16,2.45-17.2,17.09-31.49,34.05-33.25,1.51-.15,3-.23,4.54-.23,17.12,0,31.83,10,37.49,25.45,6.5,17.78.28,36.88-15.12,46.46a7,7,0,0,0-1.12.82,8.21,8.21,0,0,0-.08,1.71c.28,7.68.17,15.33.07,22.72,0,3-.08,6-.1,9v.34a30.55,30.55,0,0,1-.29,4.67C185.73,243.45,177.31,250.92,166.86,250.92Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.82,371.73a37,37,0,0,1-12.11-2c-37.55-12.95-67.19-30.87-90.6-54.77-23.55-24.05-39.93-54.18-48.69-89.57-6.51-26.32-9.23-55-8.54-90.16q0-5.5,0-11c0-8,.08-16.31-.07-24.38C6.31,71.24,27.91,48.58,57.1,47.09,77.82,46,95.35,41.47,110.71,33.17A189.18,189.18,0,0,0,129.44,21c2.06-1.46,4.12-2.92,6.19-4.35A37.61,37.61,0,0,0,141,11.78c6.3-6.6,15.71-10.38,25.82-10.38s19.46,3.67,26,10.07a119,119,0,0,0,36.32,24.64,140.45,140.45,0,0,0,40.65,10.45l2.62.29c6.49.71,13.85,1.52,21,3.72,18.78,5.76,32.89,24.49,33.56,44.53.85,25.87,1.51,53.4-.69,80.54-2.88,35.74-9.8,64.09-21.76,89.23-15,31.52-37.76,57.45-67.66,77.06-16.54,10.86-34.86,19.78-56,27.28A42,42,0,0,1,166.82,371.73ZM154.09,229.55a14.11,14.11,0,0,0,2,7.34,13.33,13.33,0,0,0,11,6,10.14,10.14,0,0,0,2.16-.22c5.49-1.22,9.69-5.42,10-10,.55-8.85.57-17.68.58-27v-4.26a21.51,21.51,0,0,1,8.07-17.19c10.32-8.55,13.75-23.74,8.16-36.12-5.14-11.37-16.56-19-28.42-19a28.64,28.64,0,0,0-4.34.34c-14.27,2.23-22.7,9.42-26.5,22.62s-.37,23.74,10.74,33.52a20.46,20.46,0,0,1,6.64,14c.19,4.07.16,8,.13,11.89,0,1.37,0,2.75,0,4.12v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,250.64l-1.08,0c-11.18-.54-19.43-9.26-19.6-20.74-.07-4.59-.05-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.13c0-.81.05-1.63.09-2.45l-.22-.15c-14.07-8.86-20.54-22.77-18.21-39.16,2.44-17.2,17.07-31.49,34-33.25,1.51-.15,3-.23,4.53-.23,17.1,0,31.8,10,37.45,25.45,6.5,17.78.29,36.88-15.1,46.46a7,7,0,0,0-1.12.82,8.21,8.21,0,0,0-.08,1.71c.28,7.68.18,15.33.07,22.72,0,3-.08,6-.09,9v.34a29.31,29.31,0,0,1-.29,4.67C185.72,243.17,177.3,250.64,166.86,250.64Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.82,371.45a36.94,36.94,0,0,1-12.1-2C117.2,356.47,87.6,338.55,64.2,314.65s-39.89-54.18-48.64-89.57c-6.51-26.32-9.22-55-8.53-90.16,0-3.67,0-7.35,0-11,0-8,.08-16.31-.07-24.38C6.46,71,28,48.3,57.19,46.81c20.71-1.06,38.23-5.62,53.57-13.92a187.81,187.81,0,0,0,18.71-12.21c2.06-1.46,4.12-2.92,6.19-4.35A37.61,37.61,0,0,0,141,11.5c6.29-6.6,15.69-10.38,25.79-10.38s19.44,3.67,25.93,10.07A118.87,118.87,0,0,0,229,35.83a140.09,140.09,0,0,0,40.61,10.45l2.62.29c6.48.71,13.83,1.52,21,3.72,18.77,5.76,32.87,24.49,33.53,44.53.86,25.87,1.51,53.4-.68,80.54-2.88,35.74-9.79,64.09-21.74,89.23-15,31.52-37.73,57.45-67.6,77.06-16.53,10.86-34.83,19.78-55.93,27.28A41.92,41.92,0,0,1,166.82,371.45ZM154.1,229.27a14.11,14.11,0,0,0,2,7.34,13.31,13.31,0,0,0,10.95,6,10,10,0,0,0,2.15-.22c5.5-1.22,9.69-5.42,10-10,.55-8.85.56-17.68.58-27v-4.26a21.53,21.53,0,0,1,8.06-17.19c10.31-8.55,13.74-23.74,8.15-36.12-5.13-11.37-16.54-19-28.39-19a28.64,28.64,0,0,0-4.34.34c-14.26,2.23-22.67,9.42-26.48,22.62s-.36,23.74,10.74,33.52a20.5,20.5,0,0,1,6.64,14c.18,4.07.15,8,.12,11.89,0,1.37,0,2.75,0,4.12v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,250.36l-1.08,0c-11.17-.54-19.41-9.26-19.58-20.74-.07-4.59,0-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.13c0-.81.06-1.63.1-2.45l-.22-.15c-14.06-8.86-20.52-22.77-18.2-39.16,2.44-17.2,17.06-31.49,34-33.25,1.51-.15,3-.23,4.53-.23,17.09,0,31.78,10,37.42,25.45,6.49,17.78.28,36.88-15.09,46.46a7,7,0,0,0-1.12.82,8.15,8.15,0,0,0-.08,1.7c.28,7.69.18,15.34.08,22.73-.05,3-.09,6-.1,9v.34a30.76,30.76,0,0,1-.3,4.67C185.7,242.89,177.29,250.36,166.86,250.36Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.82,371.17a36.88,36.88,0,0,1-12.09-2c-37.48-12.95-67.06-30.87-90.43-54.77-23.51-24.05-39.87-54.18-48.61-89.57-6.5-26.32-9.21-55-8.52-90.16,0-3.67,0-7.35,0-11,0-8,.08-16.31-.07-24.38C6.6,70.68,28.16,48,57.29,46.53,78,45.47,95.48,40.91,110.81,32.61a188.86,188.86,0,0,0,18.7-12.21c2.05-1.46,4.11-2.92,6.18-4.35A38.08,38.08,0,0,0,141,11.22C147.31,4.62,156.7.84,166.79.84s19.42,3.67,25.91,10.07A118.82,118.82,0,0,0,229,35.55,140,140,0,0,0,269.54,46l2.61.29c6.48.71,13.82,1.52,21,3.72,18.75,5.76,32.84,24.49,33.5,44.53.86,25.87,1.51,53.4-.68,80.54-2.88,35.74-9.79,64.09-21.73,89.23-15,31.52-37.69,57.45-67.53,77.06-16.52,10.86-34.8,19.78-55.89,27.28A41.8,41.8,0,0,1,166.82,371.17ZM154.12,229a14,14,0,0,0,2,7.34,13.31,13.31,0,0,0,10.94,6,10,10,0,0,0,2.15-.22c5.49-1.22,9.68-5.42,10-10,.55-8.85.56-17.68.58-27v-4.26a21.53,21.53,0,0,1,8.05-17.19c10.3-8.55,13.73-23.74,8.15-36.12-5.14-11.37-16.53-19-28.37-19a28.64,28.64,0,0,0-4.34.34c-14.24,2.23-22.65,9.42-26.45,22.62s-.36,23.74,10.72,33.52a20.46,20.46,0,0,1,6.64,14c.18,4.07.15,8,.12,11.89,0,1.37,0,2.75,0,4.12v14h-.11Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,250.08l-1.07,0c-11.17-.54-19.4-9.26-19.57-20.74-.07-4.59-.05-9.19,0-13.64,0-1.88,0-3.76,0-5.64V196h.13c0-.81.06-1.63.1-2.45l-.23-.15c-14-8.86-20.49-22.77-18.17-39.16,2.44-17.2,17-31.49,34-33.25,1.5-.15,3-.23,4.52-.23,17.07,0,31.75,10,37.39,25.45,6.48,17.78.28,36.88-15.08,46.46a6.25,6.25,0,0,0-1.11.82,6.86,6.86,0,0,0-.08,1.7c.27,7.69.17,15.34.07,22.73,0,3-.09,6-.1,9v.34a31.9,31.9,0,0,1-.29,4.67C185.68,242.61,177.28,250.08,166.86,250.08Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.82,370.89a36.82,36.82,0,0,1-12.08-2c-37.45-12.95-67-30.87-90.35-54.77s-39.83-54.18-48.56-89.57c-6.5-26.32-9.2-55-8.51-90.16q0-5.51,0-11c0-8,.08-16.31-.07-24.38-.54-28.55,21-51.21,50.11-52.7,20.67-1.06,38.16-5.62,53.47-13.92a187.5,187.5,0,0,0,18.68-12.21c2.06-1.46,4.11-2.92,6.17-4.35A37,37,0,0,0,141,10.94C147.33,4.34,156.71.56,166.79.56s19.41,3.67,25.89,10.07A119,119,0,0,0,228.9,35.27a139.77,139.77,0,0,0,40.55,10.45l2.61.29c6.47.71,13.8,1.52,21,3.72,18.73,5.76,32.8,24.48,33.47,44.53.85,25.87,1.5,53.4-.68,80.54-2.88,35.74-9.78,64.09-21.71,89.23-15,31.52-37.66,57.45-67.47,77.06-16.51,10.86-34.77,19.78-55.84,27.28A41.75,41.75,0,0,1,166.82,370.89ZM154.13,228.71a14.1,14.1,0,0,0,2,7.34A13.29,13.29,0,0,0,167,242a10.66,10.66,0,0,0,2.15-.22c5.48-1.22,9.67-5.42,9.95-10,.55-8.85.56-17.68.58-27v-4.26a21.5,21.5,0,0,1,8.05-17.19c10.29-8.55,13.71-23.74,8.13-36.12-5.13-11.37-16.51-19-28.34-19a28.61,28.61,0,0,0-4.33.34c-14.23,2.23-22.63,9.42-26.43,22.62s-.36,23.74,10.71,33.52a20.49,20.49,0,0,1,6.63,14c.18,4.07.15,8,.12,11.89,0,1.37,0,2.75,0,4.12v14h-.11Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.86,249.8l-1.07,0c-11.16-.54-19.38-9.26-19.55-20.74-.07-4.59-.05-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.13c0-.81,0-1.63.1-2.45l-.23-.15c-14-8.86-20.48-22.77-18.16-39.16,2.44-17.2,17-31.49,33.93-33.25,1.51-.15,3-.23,4.52-.23,17.06,0,31.72,10,37.35,25.45,6.48,17.78.29,36.88-15.06,46.45a7.14,7.14,0,0,0-1.11.83,7.45,7.45,0,0,0-.08,1.7c.28,7.69.17,15.34.07,22.73,0,3-.08,6-.1,9V227a30.55,30.55,0,0,1-.29,4.67C185.67,242.33,177.27,249.8,166.86,249.8Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.82,370.61a36.81,36.81,0,0,1-12.07-2c-37.41-12.95-66.94-30.87-90.27-54.77C41,289.76,24.69,259.63,16,224.24c-6.48-26.32-9.19-55-8.5-90.16,0-3.67,0-7.35,0-11,0-8,.07-16.31-.08-24.38-.53-28.55,21-51.21,50.07-52.7,20.65-1.06,38.12-5.62,53.42-13.92a188.55,188.55,0,0,0,18.67-12.21c2-1.46,4.1-2.92,6.16-4.35a37.52,37.52,0,0,0,5.33-4.83C147.35,4.06,156.72.28,166.79.28S186.18,4,192.65,10.35A118.74,118.74,0,0,0,228.85,35a139.42,139.42,0,0,0,40.51,10.45l2.6.29c6.47.71,13.8,1.52,21,3.72,18.72,5.76,32.78,24.48,33.44,44.53.86,25.87,1.51,53.4-.68,80.54-2.87,35.74-9.76,64.09-21.68,89.23-14.95,31.52-37.63,57.45-67.42,77.06-16.48,10.86-34.73,19.78-55.78,27.28A41.74,41.74,0,0,1,166.82,370.61ZM154.14,228.43a14.2,14.2,0,0,0,2,7.34,13.26,13.26,0,0,0,10.92,6,10,10,0,0,0,2.15-.23c5.48-1.21,9.66-5.41,9.94-10,.54-8.85.56-17.68.58-27v-4.26a21.52,21.52,0,0,1,8-17.19c10.28-8.55,13.7-23.74,8.12-36.12-5.12-11.37-16.5-19-28.31-19a28.61,28.61,0,0,0-4.33.34c-14.22,2.23-22.61,9.42-26.4,22.62s-.36,23.74,10.7,33.52a20.52,20.52,0,0,1,6.62,14c.18,4.07.15,8,.12,11.89,0,1.37,0,2.75,0,4.12v14h-.11Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.87,249.52l-1.08,0c-11.15-.54-19.36-9.26-19.53-20.74-.07-4.59-.06-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.13c0-.81,0-1.63.09-2.45l-.22-.15c-14-8.86-20.46-22.77-18.14-39.16,2.43-17.2,17-31.49,33.9-33.25,1.5-.15,3-.23,4.51-.23,17.05,0,31.69,10,37.32,25.45,6.47,17.78.28,36.88-15,46.45a7.14,7.14,0,0,0-1.11.83,7.45,7.45,0,0,0-.08,1.7c.28,7.69.17,15.34.07,22.73,0,3-.08,6-.1,9v.34a30.55,30.55,0,0,1-.29,4.67C185.65,242.05,177.26,249.52,166.87,249.52Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.82,370.33a36.75,36.75,0,0,1-12.06-2c-37.38-12.95-66.88-30.87-90.19-54.77S24.82,259.35,16.1,224c-6.48-26.32-9.18-55-8.5-90.16q0-5.51,0-11c0-8,.08-16.31-.07-24.38-.54-28.55,21-51.21,50-52.7C78.22,44.63,95.68,40.07,111,31.77a187.18,187.18,0,0,0,18.65-12.21c2-1.46,4.1-2.92,6.16-4.35a37.44,37.44,0,0,0,5.32-4.83C147.36,3.78,156.73,0,166.79,0s19.37,3.67,25.84,10.07a118.69,118.69,0,0,0,36.16,24.64,139.31,139.31,0,0,0,40.47,10.45l2.61.29c6.46.71,13.78,1.52,20.93,3.72,18.7,5.76,32.75,24.48,33.41,44.53.85,25.87,1.5,53.4-.68,80.54-2.87,35.74-9.76,64.09-21.67,89.23-14.93,31.52-37.59,57.45-67.35,77.06-16.47,10.86-34.7,19.78-55.73,27.28A41.64,41.64,0,0,1,166.82,370.33ZM154.15,228.15a14.2,14.2,0,0,0,2,7.34,13.25,13.25,0,0,0,10.91,6,10,10,0,0,0,2.15-.23c5.47-1.21,9.65-5.41,9.93-10,.54-8.85.56-17.68.58-27V200a21.51,21.51,0,0,1,8-17.19c10.27-8.55,13.69-23.74,8.12-36.12-5.12-11.37-16.49-19-28.29-19a28.38,28.38,0,0,0-4.32.34c-14.21,2.23-22.6,9.42-26.39,22.62s-.36,23.74,10.7,33.52a20.51,20.51,0,0,1,6.61,14c.18,4.07.15,8,.13,11.89,0,1.37,0,2.75,0,4.12v14h-.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M166.87,249.24l-1.08,0c-11.14-.54-19.34-9.26-19.51-20.74-.07-4.59-.06-9.19,0-13.64,0-1.88,0-3.76,0-5.64v-14h.13c0-.81.05-1.63.09-2.45l-.22-.15c-14-8.86-20.44-22.77-18.12-39.16,2.43-17.2,17-31.49,33.86-33.25,1.5-.15,3-.23,4.51-.23,17,0,31.67,10,37.29,25.45,6.46,17.78.28,36.88-15,46.45a6.92,6.92,0,0,0-1.12.83,8.15,8.15,0,0,0-.08,1.7c.28,7.69.17,15.34.07,22.73,0,3-.08,6-.1,9v.34a30.55,30.55,0,0,1-.29,4.67C185.63,241.77,177.26,249.24,166.87,249.24Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M14.63,148.5c0-11.93.19-23.88,0-35.81-.4-20.49,15.28-37.31,38.5-38.45,21.82-1.07,42.9-5.6,62.18-15.6,9.59-5,18.36-11.4,27.36-17.36a51.62,51.62,0,0,0,7.84-6.69c8.13-8.15,24.68-7.91,33.05,0a139.08,139.08,0,0,0,42.37,27.53,168,168,0,0,0,46.6,11.52c7.39.8,15,1.41,22,3.48,13.83,4.07,24.18,17.77,24.66,31.6.91,26.33,1.54,52.67-.68,79-2.45,29.16-8.07,57.64-21.33,84.35-14.75,29.69-36.72,53.33-65.4,71.37-17.25,10.85-35.82,19.16-55.25,25.78a27,27,0,0,1-17.53.44c-33.43-11.05-63.87-26.9-88.81-51.31-23.94-23.43-38.9-51.56-47-83.15C15.76,206.7,14,177.68,14.63,148.5ZM139,228.7h-.12c0,4.68,0,9.37,0,14.06a27.15,27.15,0,0,0,4.06,14.34c5.74,9.4,18.36,14.74,29.54,12.37,11.86-2.51,21.16-11.76,21.87-22.81.69-10.67.61-21.4.65-32.1a7.35,7.35,0,0,1,3.13-6.47,43.87,43.87,0,0,0,12.5-52.63,47.82,47.82,0,0,0-49.83-26.77c-20.1,3-33.85,13.89-39.48,32.59-5.67,18.86.22,34.89,15.59,47.87a6.37,6.37,0,0,1,2,4.15C139.12,218.42,139,223.56,139,228.7Z"
                    style={{
                        fill: 'url(#linear-gradient)',
                    }}
                />
                <path
                    d="M160.08,223.75c0-5.35-.22-10.72.07-16.06.31-5.64-2.07-9.37-7.06-12.38-9.67-5.84-13.93-14.41-12.31-25.37s11.4-20.17,22.48-21.27c12.87-1.28,24.34,5.19,28.49,16.07,4.29,11.27.64,23.6-9.74,29.8-6.15,3.67-8.39,8.17-8.14,14.91.39,10.35,0,20.74,0,31.11a20,20,0,0,1-.14,3c-.65,4.09-3.38,6.41-7.26,6.23s-6.42-2.86-6.49-7c-.09-6.35,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient)',
                    }}
                />
                <path
                    d="M14.77,148.22c0-11.93.19-23.88,0-35.81C14.33,91.92,30,75.1,53.19,74c21.8-1.07,42.86-5.6,62.13-15.6,9.58-5,18.34-11.4,27.33-17.36a51.62,51.62,0,0,0,7.84-6.69c8.12-8.15,24.65-7.91,33,0a138.84,138.84,0,0,0,42.32,27.53A167.79,167.79,0,0,0,272.4,73.36c7.38.8,14.94,1.41,22,3.48,13.83,4.07,24.16,17.77,24.64,31.6.91,26.33,1.54,52.67-.67,79-2.46,29.16-8.07,57.64-21.32,84.35-14.73,29.69-36.68,53.33-65.34,71.37-17.23,10.85-35.78,19.16-55.2,25.78a26.89,26.89,0,0,1-17.51.44C125.57,358.3,95.17,342.45,70.25,318c-23.92-23.43-38.86-51.56-47-83.15C15.9,206.42,14.16,177.4,14.77,148.22ZM139,228.42h-.13c0,4.68,0,9.37,0,14.06a27,27,0,0,0,4.06,14.34c5.73,9.4,18.34,14.74,29.5,12.37,11.86-2.51,21.15-11.76,21.86-22.81.69-10.67.61-21.4.64-32.1a7.35,7.35,0,0,1,3.13-6.47,43.89,43.89,0,0,0,12.49-52.63,47.76,47.76,0,0,0-49.78-26.77c-20.09,3-33.82,13.89-39.44,32.59-5.68,18.86.21,34.89,15.57,47.87a6.36,6.36,0,0,1,2,4.15C139.14,218.14,139,223.28,139,228.42Z"
                    style={{
                        fill: 'url(#linear-gradient-3)',
                    }}
                />
                <path
                    d="M160.09,223.47c0-5.35-.23-10.72.06-16.06.31-5.64-2.06-9.37-7.05-12.38-9.66-5.84-13.92-14.41-12.3-25.37s11.39-20.17,22.46-21.27c12.86-1.28,24.32,5.19,28.47,16.07,4.29,11.27.64,23.6-9.73,29.8-6.15,3.66-8.39,8.17-8.14,14.91.4,10.35,0,20.74,0,31.11a20,20,0,0,1-.15,3c-.64,4.09-3.37,6.41-7.25,6.23s-6.42-2.86-6.48-7c-.1-6.35,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-3)',
                    }}
                />
                <path
                    d="M14.91,147.94c0-11.93.19-23.88-.05-35.81-.4-20.49,15.26-37.31,38.44-38.45,21.77-1.07,42.81-5.6,62.07-15.6,9.57-5,18.32-11.4,27.31-17.36A51.47,51.47,0,0,0,150.5,34c8.12-8.15,24.64-7.91,33,0a138.66,138.66,0,0,0,42.28,27.53A167.45,167.45,0,0,0,272.3,73.08c7.38.8,14.94,1.41,22,3.48,13.81,4.06,24.14,17.77,24.61,31.6.91,26.33,1.54,52.67-.67,79-2.45,29.16-8.06,57.64-21.3,84.35-14.71,29.69-36.65,53.33-65.27,71.37a261,261,0,0,1-55.16,25.78,26.83,26.83,0,0,1-17.49.44c-33.38-11.05-63.76-26.9-88.65-51.31-23.9-23.43-38.83-51.56-47-83.15C16,206.14,14.3,177.12,14.91,147.94ZM139,228.14h-.12c0,4.68,0,9.37,0,14.06A27.15,27.15,0,0,0,143,256.54c5.73,9.4,18.32,14.74,29.48,12.37,11.84-2.51,21.13-11.76,21.84-22.81.68-10.67.6-21.4.64-32.1a7.34,7.34,0,0,1,3.12-6.47,43.91,43.91,0,0,0,12.48-52.63,47.7,47.7,0,0,0-49.74-26.77c-20.06,3-33.78,13.89-39.4,32.59-5.67,18.86.21,34.89,15.55,47.87a6.37,6.37,0,0,1,2,4.15C139.17,217.86,139,223,139,228.14Z"
                    style={{
                        fill: 'url(#linear-gradient-5)',
                    }}
                />
                <path
                    d="M160.1,223.19c0-5.35-.23-10.72.06-16.06.31-5.64-2.07-9.37-7-12.38-9.66-5.84-13.91-14.41-12.29-25.37s11.38-20.17,22.44-21.28c12.84-1.27,24.3,5.2,28.43,16.08,4.29,11.27.64,23.6-9.72,29.8-6.14,3.66-8.38,8.17-8.12,14.91.39,10.35,0,20.74,0,31.11a21.38,21.38,0,0,1-.14,3c-.65,4.09-3.38,6.41-7.25,6.23s-6.41-2.86-6.47-7c-.1-6.35,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-5)',
                    }}
                />
                <path
                    d="M15,147.66c0-11.93.19-23.88,0-35.81C14.6,91.36,30.24,74.54,53.4,73.4c21.76-1.07,42.78-5.6,62-15.6,9.56-5,18.31-11.4,27.29-17.36a52,52,0,0,0,7.82-6.69c8.1-8.15,24.61-7.91,33,0a138.64,138.64,0,0,0,42.25,27.53A167.18,167.18,0,0,0,272.21,72.8c7.37.8,14.92,1.41,21.94,3.48,13.8,4.06,24.12,17.77,24.6,31.6.91,26.33,1.54,52.67-.67,79C315.63,216,310,244.49,296.79,271.2c-14.7,29.69-36.61,53.33-65.21,71.37-17.21,10.85-35.73,19.16-55.11,25.78a26.8,26.8,0,0,1-17.48.44c-33.34-11.05-63.69-26.9-88.57-51.31-23.87-23.43-38.79-51.56-46.92-83.15C16.17,205.86,14.44,176.84,15,147.66Zm124,80.2h-.13c0,4.68,0,9.37,0,14.06a27,27,0,0,0,4,14.34c5.72,9.4,18.31,14.74,29.45,12.37,11.84-2.51,21.11-11.76,21.82-22.81.69-10.67.61-21.4.64-32.1a7.36,7.36,0,0,1,3.12-6.47,43.92,43.92,0,0,0,12.47-52.63,47.64,47.64,0,0,0-49.69-26.77c-20,3-33.76,13.89-39.37,32.59-5.66,18.86.21,34.89,15.54,47.87a6.31,6.31,0,0,1,2,4.15C139.19,217.58,139.05,222.72,139.05,227.86Z"
                    style={{
                        fill: 'url(#linear-gradient-7)',
                    }}
                />
                <path
                    d="M160.1,222.91c0-5.35-.22-10.72.07-16.06.3-5.64-2.07-9.37-7-12.38-9.65-5.84-13.9-14.41-12.28-25.37s11.37-20.17,22.42-21.28c12.83-1.27,24.28,5.2,28.41,16.08,4.28,11.27.64,23.6-9.71,29.8-6.14,3.66-8.37,8.17-8.12,14.91.39,10.35,0,20.74,0,31.11a18.73,18.73,0,0,1-.14,3c-.64,4.09-3.37,6.41-7.24,6.23s-6.4-2.86-6.47-7c-.1-6.35,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-7)',
                    }}
                />
                <path
                    d="M15.18,147.38c0-11.93.19-23.88,0-35.81-.4-20.49,15.23-37.31,38.36-38.45,21.74-1.07,42.74-5.6,62-15.6,9.55-5,18.29-11.4,27.26-17.36a51.39,51.39,0,0,0,7.81-6.69c8.1-8.15,24.59-7.91,32.94,0A138.53,138.53,0,0,0,225.67,61a167,167,0,0,0,46.44,11.52c7.36.8,14.91,1.41,21.93,3.48,13.79,4.06,24.09,17.77,24.57,31.6.91,26.33,1.54,52.67-.67,79-2.45,29.16-8.05,57.63-21.26,84.35-14.69,29.69-36.58,53.33-65.16,71.37-17.19,10.85-35.69,19.16-55.06,25.78a26.74,26.74,0,0,1-17.46.44c-33.31-11.05-63.64-26.9-88.49-51.31-23.85-23.43-38.76-51.56-46.88-83.15C16.3,205.58,14.58,176.56,15.18,147.38Zm123.89,80.2H139c0,4.68,0,9.37,0,14.06A27.23,27.23,0,0,0,143,256c5.72,9.4,18.29,14.74,29.43,12.37,11.82-2.51,21.09-11.76,21.8-22.81.68-10.67.6-21.4.64-32.1A7.34,7.34,0,0,1,198,207a44,44,0,0,0,12.45-52.63,47.58,47.58,0,0,0-49.65-26.77c-20,3-33.72,13.89-39.33,32.59C115.8,179,121.67,195.05,137,208a6.4,6.4,0,0,1,2,4.15C139.22,217.3,139.07,222.44,139.07,227.58Z"
                    style={{
                        fill: 'url(#linear-gradient-9)',
                    }}
                />
                <path
                    d="M160.11,222.63c0-5.35-.23-10.72.06-16.06.31-5.64-2.06-9.37-7-12.38-9.64-5.84-13.88-14.41-12.26-25.37s11.35-20.17,22.39-21.28c12.83-1.27,24.26,5.2,28.39,16.08,4.28,11.27.64,23.6-9.7,29.8-6.13,3.66-8.37,8.17-8.11,14.9.39,10.36,0,20.75,0,31.12a21.38,21.38,0,0,1-.14,3c-.65,4.09-3.37,6.41-7.24,6.23s-6.4-2.86-6.46-7c-.1-6.35,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-9)',
                    }}
                />
                <path
                    d="M15.32,147.1c0-11.93.19-23.88-.05-35.81C14.87,90.8,30.49,74,53.6,72.84c21.72-1.07,42.71-5.6,61.91-15.6,9.54-5,18.27-11.4,27.23-17.36a51.39,51.39,0,0,0,7.81-6.69c8.09-8.15,24.56-7.91,32.9,0a138.39,138.39,0,0,0,42.17,27.53A166.73,166.73,0,0,0,272,72.24c7.35.8,14.89,1.41,21.9,3.48,13.78,4.06,24.08,17.77,24.55,31.6.91,26.33,1.54,52.67-.66,79-2.45,29.16-8.05,57.63-21.25,84.35C281.89,300.33,260,324,231.46,342c-17.17,10.85-35.66,19.16-55,25.78a26.71,26.71,0,0,1-17.45.44c-33.29-11.05-63.58-26.9-88.41-51.31-23.84-23.43-38.73-51.56-46.84-83.15C16.44,205.3,14.71,176.28,15.32,147.1ZM139.1,227.3H139c0,4.68,0,9.37,0,14.06a27.22,27.22,0,0,0,4,14.34c5.72,9.4,18.28,14.73,29.4,12.37,11.82-2.51,21.08-11.76,21.78-22.81.69-10.67.61-21.4.64-32.1a7.36,7.36,0,0,1,3.12-6.47,44,44,0,0,0,12.44-52.63c-8.74-18.63-29.12-29.85-49.6-26.77-20,3-33.7,13.89-39.3,32.59-5.65,18.86.22,34.89,15.52,47.87a6.35,6.35,0,0,1,2,4.15C139.24,217,139.1,222.16,139.1,227.3Z"
                    style={{
                        fill: 'url(#linear-gradient-11)',
                    }}
                />
                <path
                    d="M160.12,222.35c0-5.35-.23-10.72.06-16.06.31-5.64-2.06-9.37-7-12.38-9.64-5.84-13.88-14.41-12.26-25.37s11.35-20.17,22.38-21.28c12.81-1.27,24.23,5.2,28.36,16.08,4.27,11.27.64,23.6-9.7,29.79-6.12,3.67-8.35,8.18-8.1,14.91.39,10.36,0,20.75,0,31.12a20,20,0,0,1-.14,3c-.64,4.09-3.37,6.41-7.23,6.23s-6.39-2.86-6.45-7c-.1-6.35,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-11)',
                    }}
                />
                <path
                    d="M15.45,146.82c0-11.93.19-23.88,0-35.81C15,90.52,30.61,73.7,53.71,72.56,75.4,71.49,96.37,67,115.55,57c9.54-5,18.26-11.4,27.21-17.36a51.31,51.31,0,0,0,7.8-6.69c8.09-8.15,24.55-7.91,32.88,0a138.2,138.2,0,0,0,42.13,27.53A166.39,166.39,0,0,0,271.92,72c7.35.8,14.88,1.41,21.89,3.48,13.76,4.06,24.05,17.77,24.53,31.6.9,26.33,1.53,52.67-.67,79-2.44,29.16-8,57.63-21.22,84.35-14.67,29.69-36.52,53.33-65,71.37a259.67,259.67,0,0,1-55,25.78A26.65,26.65,0,0,1,159,368c-33.26-11.05-63.53-26.9-88.33-51.31C46.87,293.2,32,265.08,23.89,233.49,16.57,205,14.85,176,15.45,146.82ZM139.12,227H139c0,4.68,0,9.37,0,14.06a27.31,27.31,0,0,0,4,14.34c5.71,9.4,18.26,14.73,29.38,12.37,11.8-2.51,21.05-11.76,21.76-22.81.68-10.67.6-21.4.64-32.1a7.35,7.35,0,0,1,3.11-6.47,44,44,0,0,0,12.44-52.63c-8.74-18.63-29.11-29.85-49.57-26.77-20,3-33.66,13.89-39.26,32.59-5.64,18.86.22,34.89,15.5,47.87a6.36,6.36,0,0,1,2,4.15C139.27,216.74,139.12,221.88,139.12,227Z"
                    style={{
                        fill: 'url(#linear-gradient-13)',
                    }}
                />
                <path
                    d="M160.12,222.07c0-5.35-.22-10.72.07-16.06.3-5.64-2.06-9.37-7-12.38-9.63-5.84-13.86-14.41-12.25-25.37s11.34-20.17,22.36-21.28c12.8-1.27,24.21,5.2,28.34,16.08,4.27,11.27.63,23.6-9.69,29.79-6.12,3.67-8.35,8.18-8.1,14.91.39,10.36,0,20.75,0,31.12a20,20,0,0,1-.15,3c-.64,4.09-3.36,6.41-7.21,6.23s-6.39-2.86-6.46-7c-.1-6.35,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-13)',
                    }}
                />
                <path
                    d="M15.59,146.54c0-11.93.19-23.88,0-35.81-.4-20.49,15.18-37.31,38.26-38.45,21.68-1.08,42.62-5.6,61.79-15.6,9.53-5,18.24-11.4,27.19-17.36a51.73,51.73,0,0,0,7.79-6.69c8.07-8.15,24.52-7.91,32.84,0a138.06,138.06,0,0,0,42.1,27.53,166.12,166.12,0,0,0,46.31,11.52c7.34.8,14.87,1.41,21.87,3.48,13.75,4.06,24,17.77,24.5,31.6.91,26.33,1.54,52.67-.66,79-2.45,29.16-8,57.63-21.21,84.35-14.65,29.69-36.48,53.33-65,71.37a259.28,259.28,0,0,1-54.91,25.78,26.62,26.62,0,0,1-17.42.44c-33.22-11.05-63.46-26.9-88.25-51.31C47,292.92,32.12,264.8,24,233.21,16.71,204.74,15,175.72,15.59,146.54Zm123.56,80.2H139c0,4.68,0,9.37,0,14.06a27.22,27.22,0,0,0,4,14.34c5.7,9.4,18.24,14.73,29.34,12.37,11.8-2.51,21-11.76,21.74-22.81.69-10.67.61-21.4.64-32.1a7.38,7.38,0,0,1,3.11-6.47,44.05,44.05,0,0,0,12.43-52.63c-8.73-18.63-29.08-29.85-49.52-26.77-20,3-33.63,13.89-39.23,32.59-5.64,18.86.22,34.89,15.49,47.87a6.4,6.4,0,0,1,2,4.15C139.29,216.46,139.15,221.6,139.15,226.74Z"
                    style={{
                        fill: 'url(#linear-gradient-15)',
                    }}
                />
                <path
                    d="M160.13,221.79c0-5.35-.23-10.72.06-16.06.31-5.64-2.06-9.37-7-12.38-9.61-5.84-13.85-14.41-12.23-25.37s11.32-20.17,22.33-21.28c12.79-1.27,24.19,5.2,28.31,16.08,4.27,11.27.64,23.6-9.67,29.79-6.12,3.67-8.34,8.18-8.09,14.91.39,10.36,0,20.75,0,31.12a21.38,21.38,0,0,1-.14,3c-.64,4.09-3.36,6.41-7.21,6.23s-6.39-2.86-6.45-7c-.1-6.35,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-15)',
                    }}
                />
                <path
                    d="M15.73,146.26c0-11.93.19-23.88-.05-35.81C15.29,90,30.86,73.14,53.91,72c21.66-1.08,42.59-5.6,61.73-15.6,9.52-5,18.23-11.4,27.17-17.36a51.16,51.16,0,0,0,7.78-6.69c8.07-8.15,24.5-7.91,32.82,0a137.83,137.83,0,0,0,42.05,27.53A165.92,165.92,0,0,0,271.74,71.4c7.33.8,14.85,1.41,21.84,3.48,13.74,4.06,24,17.77,24.49,31.6.9,26.33,1.53,52.67-.67,79-2.44,29.16-8,57.63-21.19,84.35-14.63,29.69-36.45,53.33-64.92,71.37A258.66,258.66,0,0,1,176.43,367a26.56,26.56,0,0,1-17.4.44c-33.19-11.05-63.41-26.9-88.17-51.31-23.77-23.44-38.62-51.56-46.72-83.15C16.85,204.46,15.12,175.44,15.73,146.26Zm123.44,80.2h-.12c0,4.68,0,9.37,0,14.06a27.22,27.22,0,0,0,4,14.34c5.7,9.4,18.22,14.73,29.32,12.37,11.78-2.51,21-11.76,21.72-22.81.68-10.67.6-21.4.64-32.1a7.35,7.35,0,0,1,3.11-6.47,44.07,44.07,0,0,0,12.41-52.63c-8.72-18.63-29-29.85-49.47-26.77-20,3-33.61,13.89-39.2,32.59-5.63,18.86.22,34.89,15.48,47.87a6.35,6.35,0,0,1,2,4.15C139.32,216.18,139.17,221.32,139.17,226.46Z"
                    style={{
                        fill: 'url(#linear-gradient-17)',
                    }}
                />
                <path
                    d="M160.14,221.51c0-5.35-.23-10.72.06-16.06.3-5.64-2.06-9.37-7-12.38-9.6-5.84-13.83-14.41-12.22-25.37a25.82,25.82,0,0,1,22.32-21.28c12.77-1.27,24.17,5.2,28.28,16.08,4.27,11.27.64,23.6-9.67,29.79-6.11,3.67-8.33,8.18-8.08,14.91.39,10.36,0,20.75,0,31.12a18.73,18.73,0,0,1-.14,3c-.64,4.09-3.35,6.41-7.2,6.23s-6.38-2.86-6.44-7c-.1-6.35,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-17)',
                    }}
                />
                <path
                    d="M15.86,146c0-11.93.19-23.88,0-35.81C15.42,89.68,31,72.86,54,71.72c21.64-1.08,42.55-5.6,61.68-15.6,9.51-5,18.21-11.4,27.14-17.36a51.16,51.16,0,0,0,7.78-6.69c8.06-8.15,24.48-7.91,32.78,0a137.94,137.94,0,0,0,42,27.53,165.58,165.58,0,0,0,46.23,11.52c7.33.8,14.84,1.41,21.83,3.48,13.72,4.06,24,17.77,24.46,31.6.9,26.33,1.53,52.67-.66,79-2.44,29.16-8,57.63-21.17,84.35-14.63,29.69-36.42,53.33-64.87,71.37-17.11,10.85-35.53,19.16-54.81,25.78a26.5,26.5,0,0,1-17.38.44C125.87,356.06,95.69,340.21,71,315.8c-23.75-23.44-38.59-51.56-46.68-83.15C17,204.18,15.26,175.16,15.86,146Zm123.34,80.2h-.12c0,4.68,0,9.37,0,14.06a27.3,27.3,0,0,0,4,14.34c5.69,9.4,18.21,14.73,29.29,12.37,11.78-2.51,21-11.76,21.71-22.81.68-10.67.6-21.4.63-32.1a7.38,7.38,0,0,1,3.11-6.47,44.1,44.1,0,0,0,12.4-52.63c-8.71-18.63-29-29.85-49.43-26.77-19.94,3-33.57,13.89-39.16,32.59-5.63,18.86.22,34.89,15.46,47.87a6.4,6.4,0,0,1,2,4.15C139.34,215.9,139.2,221,139.2,226.18Z"
                    style={{
                        fill: 'url(#linear-gradient-19)',
                    }}
                />
                <path
                    d="M160.14,221.23c0-5.35-.22-10.72.06-16.06.31-5.64-2-9.37-7-12.38-9.6-5.84-13.83-14.41-12.22-25.37a25.8,25.8,0,0,1,22.3-21.28c12.77-1.27,24.15,5.2,28.26,16.08,4.26,11.27.64,23.6-9.66,29.79-6.1,3.67-8.33,8.18-8.07,14.91.39,10.36,0,20.75,0,31.12a21.38,21.38,0,0,1-.14,3c-.64,4.09-3.36,6.41-7.2,6.23s-6.37-2.86-6.44-7c-.1-6.35,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-19)',
                    }}
                />
                <path
                    d="M16,145.7c0-11.93.19-23.88,0-35.81C15.56,89.4,31.1,72.58,54.11,71.44c21.62-1.08,42.51-5.6,61.63-15.6,9.5-5,18.19-11.4,27.11-17.36a51.09,51.09,0,0,0,7.77-6.69c8.06-8.15,24.46-7.91,32.76,0a137.63,137.63,0,0,0,42,27.53,165.31,165.31,0,0,0,46.19,11.52c7.32.8,14.82,1.41,21.81,3.48,13.71,4.06,24,17.77,24.44,31.6.9,26.33,1.53,52.67-.67,79-2.44,29.16-8,57.63-21.15,84.35-14.61,29.69-36.38,53.33-64.8,71.36-17.1,10.86-35.5,19.17-54.77,25.79a26.44,26.44,0,0,1-17.36.44c-33.14-11.05-63.3-26.9-88-51.31C47.3,292.08,32.48,264,24.4,232.37,17.12,203.9,15.4,174.88,16,145.7Zm123.22,80.2h-.12c0,4.68,0,9.37,0,14.06a27.2,27.2,0,0,0,4,14.34c5.69,9.4,18.2,14.73,29.27,12.37,11.76-2.51,21-11.76,21.68-22.81.68-10.67.6-21.4.64-32.1a7.37,7.37,0,0,1,3.1-6.47,44.13,44.13,0,0,0,12.39-52.63c-8.7-18.63-29-29.85-49.38-26.77-19.92,3-33.55,13.89-39.12,32.59-5.63,18.86.21,34.89,15.44,47.87a6.35,6.35,0,0,1,2,4.15C139.37,215.62,139.22,220.76,139.22,225.9Z"
                    style={{
                        fill: 'url(#linear-gradient-21)',
                    }}
                />
                <path
                    d="M160.15,221c0-5.35-.23-10.72.06-16.06.31-5.64-2.05-9.37-7-12.38-9.59-5.84-13.81-14.41-12.2-25.37a25.78,25.78,0,0,1,22.28-21.28c12.75-1.27,24.12,5.2,28.23,16.08,4.25,11.27.63,23.6-9.65,29.79-6.1,3.67-8.32,8.18-8.07,14.91.39,10.36,0,20.75,0,31.12a18.73,18.73,0,0,1-.14,3c-.64,4.09-3.35,6.41-7.19,6.23s-6.37-2.86-6.43-7c-.1-6.35,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-21)',
                    }}
                />
                <path
                    d="M16.13,145.42c0-11.93.19-23.88,0-35.81-.4-20.49,15.13-37.31,38.13-38.45,21.6-1.08,42.47-5.61,61.56-15.6,9.5-5,18.18-11.4,27.09-17.36a51.09,51.09,0,0,0,7.77-6.69c8.05-8.15,24.43-7.91,32.72,0A137.61,137.61,0,0,0,225.31,59a165,165,0,0,0,46.14,11.52c7.32.8,14.82,1.41,21.79,3.48,13.7,4.06,23.95,17.77,24.42,31.6.9,26.33,1.53,52.67-.66,79-2.44,29.16-8,57.63-21.13,84.35-14.6,29.69-36.36,53.33-64.75,71.36a257.51,257.51,0,0,1-54.71,25.79,26.44,26.44,0,0,1-17.36.44c-33.1-11.05-63.24-26.9-87.93-51.31C47.41,291.8,32.6,263.68,24.53,232.09,17.25,203.62,15.53,174.6,16.13,145.42Zm123.12,80.2h-.12c0,4.68,0,9.37,0,14.06a27.41,27.41,0,0,0,4,14.34c5.69,9.4,18.18,14.73,29.24,12.37,11.75-2.51,21-11.76,21.67-22.81.68-10.67.6-21.4.63-32.1a7.39,7.39,0,0,1,3.1-6.47,44.14,44.14,0,0,0,12.38-52.63c-8.69-18.63-29-29.85-49.34-26.77-19.9,3-33.51,13.89-39.08,32.59-5.62,18.86.21,34.89,15.43,47.87a6.39,6.39,0,0,1,2,4.15C139.39,215.34,139.25,220.48,139.25,225.62Z"
                    style={{
                        fill: 'url(#linear-gradient-23)',
                    }}
                />
                <path
                    d="M160.16,220.67c0-5.35-.23-10.72.06-16.06.3-5.64-2.05-9.37-7-12.38-9.58-5.84-13.8-14.41-12.19-25.37a25.77,25.77,0,0,1,22.26-21.28c12.74-1.27,24.1,5.2,28.21,16.08,4.25,11.27.63,23.6-9.65,29.79-6.09,3.67-8.31,8.18-8.06,14.91.39,10.36,0,20.75,0,31.12a21.38,21.38,0,0,1-.14,3c-.64,4.09-3.35,6.41-7.19,6.23s-6.36-2.86-6.42-7c-.1-6.35,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-23)',
                    }}
                />
                <path
                    d="M16.27,145.14c0-11.93.19-23.88,0-35.81C15.83,88.84,31.35,72,54.32,70.88c21.58-1.08,42.43-5.61,61.51-15.6,9.48-5,18.16-11.4,27.07-17.36a51.42,51.42,0,0,0,7.75-6.69c8-8.15,24.41-7.91,32.7,0a137.31,137.31,0,0,0,41.91,27.53,164.71,164.71,0,0,0,46.1,11.52c7.31.8,14.8,1.41,21.77,3.48,13.69,4.06,23.92,17.77,24.4,31.6.9,26.33,1.52,52.67-.67,79-2.43,29.16-8,57.63-21.11,84.35C281.17,298.37,259.43,322,231.06,340,214,350.9,195.62,359.21,176.4,365.83a26.38,26.38,0,0,1-17.34.44C126,355.22,95.88,339.37,71.2,315c-23.68-23.44-38.48-51.56-46.54-83.15C17.39,203.34,15.67,174.32,16.27,145.14Zm123,80.2h-.12c0,4.68,0,9.37,0,14.06a27.23,27.23,0,0,0,4,14.34c5.67,9.4,18.16,14.73,29.21,12.37,11.74-2.51,20.94-11.76,21.64-22.81.68-10.67.6-21.4.64-32.1a7.37,7.37,0,0,1,3.1-6.47,44.18,44.18,0,0,0,12.36-52.63c-8.68-18.63-28.94-29.85-49.29-26.77-19.88,3-33.48,13.89-39,32.59-5.62,18.86.21,34.89,15.42,47.87a6.38,6.38,0,0,1,2,4.15C139.42,215.06,139.27,220.2,139.27,225.34Z"
                    style={{
                        fill: 'url(#linear-gradient-25)',
                    }}
                />
                <path
                    d="M160.16,220.39c0-5.35-.22-10.72.06-16.06.31-5.64-2-9.37-7-12.38-9.57-5.84-13.78-14.41-12.18-25.37A25.76,25.76,0,0,1,163.3,145.3c12.73-1.27,24.08,5.2,28.19,16.08,4.24,11.27.63,23.6-9.64,29.79-6.09,3.67-8.3,8.18-8,14.91.39,10.36,0,20.75,0,31.12a20,20,0,0,1-.14,3c-.64,4.09-3.34,6.41-7.18,6.23s-6.35-2.86-6.42-7c-.1-6.35,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-25)',
                    }}
                />
                <path
                    d="M16.41,144.86c0-11.94.19-23.88-.05-35.81C16,88.56,31.47,71.74,54.42,70.6,76,69.52,96.81,65,115.88,55c9.47-5,18.14-11.4,27-17.36A51.92,51.92,0,0,0,150.67,31c8-8.15,24.39-7.91,32.67,0A137,137,0,0,0,225.2,58.47,164.18,164.18,0,0,0,271.27,70c7.3.8,14.78,1.41,21.75,3.48,13.67,4.06,23.9,17.77,24.37,31.6.9,26.33,1.53,52.67-.66,79-2.43,29.16-8,57.63-21.1,84.35-14.57,29.69-36.28,53.33-64.63,71.36a256.51,256.51,0,0,1-54.61,25.79,26.32,26.32,0,0,1-17.32.44c-33-11.05-63.13-26.9-87.78-51.31-23.66-23.44-38.45-51.56-46.5-83.15C17.52,203.06,15.81,174,16.41,144.86Zm122.89,80.2h-.12c0,4.68,0,9.37,0,14.06a27.41,27.41,0,0,0,4,14.34c5.67,9.4,18.14,14.73,29.19,12.37,11.72-2.51,20.92-11.76,21.62-22.81.68-10.67.6-21.4.63-32.1a7.37,7.37,0,0,1,3.1-6.47,44.22,44.22,0,0,0,12.36-52.63c-8.68-18.63-28.92-29.85-49.26-26.77-19.86,3-33.45,13.89-39,32.59-5.61,18.86.21,34.89,15.4,47.87a6.39,6.39,0,0,1,2,4.15C139.44,214.78,139.3,219.92,139.3,225.06Z"
                    style={{
                        fill: 'url(#linear-gradient-27)',
                    }}
                />
                <path
                    d="M160.17,220.11c0-5.36-.23-10.72.06-16.06.3-5.64-2-9.37-7-12.38-9.57-5.84-13.78-14.41-12.17-25.37A25.75,25.75,0,0,1,163.31,145c12.71-1.27,24.06,5.2,28.15,16.08,4.25,11.27.64,23.6-9.62,29.79-6.08,3.67-8.3,8.18-8.05,14.91.39,10.36,0,20.75,0,31.12a20,20,0,0,1-.15,3c-.63,4.09-3.34,6.41-7.17,6.23s-6.35-2.86-6.41-7c-.1-6.35,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-27)',
                    }}
                />
                <path
                    d="M16.54,144.58c0-11.94.19-23.88,0-35.81-.4-20.49,15.09-37.31,38-38.45,21.54-1.08,42.36-5.61,61.4-15.6,9.47-5,18.13-11.4,27-17.36a51.35,51.35,0,0,0,7.74-6.69c8-8.15,24.37-7.91,32.64,0a137,137,0,0,0,41.83,27.52,163.93,163.93,0,0,0,46,11.53c7.3.8,14.78,1.41,21.73,3.48,13.67,4.06,23.88,17.77,24.36,31.6.89,26.33,1.52,52.67-.67,79-2.42,29.16-8,57.63-21.07,84.35C281,297.81,259.27,321.45,231,339.48a256.43,256.43,0,0,1-54.57,25.79,26.29,26.29,0,0,1-17.31.44c-33-11-63.06-26.9-87.69-51.31C47.74,291,33,262.84,24.91,231.25,17.66,202.78,15.94,173.76,16.54,144.58Zm122.78,80.2h-.12c0,4.68,0,9.37,0,14.06a27.31,27.31,0,0,0,4,14.34c5.67,9.4,18.13,14.73,29.16,12.37,11.72-2.51,20.9-11.76,21.61-22.81.67-10.67.59-21.4.63-32.1a7.38,7.38,0,0,1,3.09-6.47,44.23,44.23,0,0,0,12.35-52.63c-8.67-18.63-28.9-29.85-49.21-26.77-19.85,3-33.42,13.89-39,32.59-5.61,18.86.21,34.89,15.39,47.87a6.38,6.38,0,0,1,2,4.15C139.47,214.5,139.32,219.64,139.32,224.78Z"
                    style={{
                        fill: 'url(#linear-gradient-29)',
                    }}
                />
                <path
                    d="M160.17,219.83c0-5.36-.22-10.72.07-16.06.3-5.64-2.05-9.37-7-12.38-9.56-5.84-13.76-14.41-12.16-25.37s11.26-20.18,22.2-21.28c12.71-1.27,24,5.2,28.13,16.08,4.24,11.27.63,23.6-9.62,29.79-6.07,3.67-8.28,8.18-8,14.91.38,10.36,0,20.75,0,31.12a21.38,21.38,0,0,1-.14,3c-.64,4.09-3.34,6.41-7.17,6.23s-6.34-2.86-6.4-7c-.1-6.35,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-29)',
                    }}
                />
                <path
                    d="M16.68,144.3c0-11.94.19-23.88,0-35.81C16.24,88,31.71,71.18,54.62,70,76.15,69,96.94,64.43,116,54.44c9.46-5,18.11-11.4,27-17.36a51.35,51.35,0,0,0,7.74-6.69c8-8.15,24.34-7.91,32.61,0A136.79,136.79,0,0,0,225.1,57.91a163.58,163.58,0,0,0,46,11.53c7.29.8,14.76,1.41,21.71,3.48,13.65,4.06,23.86,17.77,24.33,31.6.9,26.33,1.52,52.67-.66,79-2.43,29.16-8,57.63-21.06,84.35-14.54,29.69-36.22,53.33-64.51,71.36A255.52,255.52,0,0,1,176.37,365a26.17,26.17,0,0,1-17.29.45c-33-11.05-63-26.9-87.62-51.32C47.84,290.68,33.09,262.56,25,231,17.79,202.5,16.08,173.48,16.68,144.3Zm122.67,80.2h-.12c0,4.68,0,9.37,0,14.06a27.4,27.4,0,0,0,4,14.34c5.66,9.4,18.11,14.73,29.14,12.37,11.7-2.51,20.88-11.76,21.58-22.81.68-10.67.6-21.4.64-32.1a7.37,7.37,0,0,1,3.08-6.47A44.25,44.25,0,0,0,210,151.26c-8.66-18.63-28.87-29.85-49.16-26.77-19.83,3-33.4,13.89-39,32.59-5.6,18.86.21,34.89,15.38,47.87a6.43,6.43,0,0,1,2,4.15C139.49,214.22,139.35,219.36,139.35,224.5Z"
                    style={{
                        fill: 'url(#linear-gradient-31)',
                    }}
                />
                <path
                    d="M160.18,219.55c0-5.36-.22-10.72.06-16.06.31-5.64-2-9.37-7-12.38-9.54-5.84-13.75-14.41-12.14-25.37s11.24-20.18,22.17-21.28c12.7-1.27,24,5.2,28.11,16.08,4.24,11.27.63,23.6-9.61,29.79-6.07,3.67-8.28,8.18-8,14.91.39,10.36,0,20.75,0,31.12a18.73,18.73,0,0,1-.14,3c-.63,4.09-3.33,6.41-7.16,6.23s-6.34-2.86-6.4-7c-.1-6.35,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-31)',
                    }}
                />
                <path
                    d="M16.81,144c0-11.94.19-23.88,0-35.81-.39-20.49,15.06-37.31,38-38.45C76.23,68.68,97,64.15,116,54.16c9.46-5,18.1-11.4,27-17.36a50.79,50.79,0,0,0,7.73-6.69c8-8.15,24.33-7.91,32.58,0a136.65,136.65,0,0,0,41.76,27.52A163.31,163.31,0,0,0,271,69.16c7.28.8,14.74,1.41,21.69,3.48,13.64,4.06,23.83,17.77,24.31,31.6.89,26.33,1.52,52.67-.67,79-2.42,29.16-8,57.63-21,84.35-14.53,29.69-36.19,53.33-64.46,71.36a255.29,255.29,0,0,1-54.46,25.78,26.14,26.14,0,0,1-17.28.45c-33-11-62.95-26.9-87.54-51.32C48,290.4,33.21,262.28,25.17,230.69,17.92,202.22,16.22,173.2,16.81,144Zm122.56,80.2h-.12c0,4.68,0,9.37,0,14.06a27.39,27.39,0,0,0,4,14.34c5.66,9.4,18.1,14.73,29.11,12.37,11.7-2.51,20.87-11.76,21.57-22.81.67-10.67.59-21.4.63-32.1a7.38,7.38,0,0,1,3.09-6.47A44.28,44.28,0,0,0,210,151c-8.65-18.63-28.84-29.85-49.12-26.77-19.81,3-33.36,13.89-38.91,32.59-5.59,18.86.21,34.89,15.36,47.87a6.33,6.33,0,0,1,2,4.15C139.52,213.94,139.37,219.08,139.37,224.22Z"
                    style={{
                        fill: 'url(#linear-gradient-33)',
                    }}
                />
                <path
                    d="M160.19,219.27c0-5.36-.23-10.72.06-16.06.3-5.64-2-9.37-7-12.38-9.53-5.84-13.73-14.41-12.13-25.37s11.24-20.18,22.16-21.28c12.68-1.27,24,5.2,28.08,16.08,4.23,11.27.63,23.6-9.6,29.79-6.07,3.67-8.28,8.18-8,14.91.38,10.36,0,20.75,0,31.12a21.38,21.38,0,0,1-.14,3c-.64,4.09-3.34,6.41-7.16,6.23s-6.33-2.86-6.39-7c-.1-6.35,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-33)',
                    }}
                />
                <path
                    d="M17,143.74c0-11.94.19-23.88,0-35.81C16.51,87.44,32,70.62,54.82,69.48c21.49-1.08,42.25-5.61,61.24-15.6,9.44-5,18.08-11.4,26.95-17.36a51.69,51.69,0,0,0,7.72-6.69c8-8.15,24.3-7.91,32.55,0A136.47,136.47,0,0,0,225,57.35a163,163,0,0,0,45.89,11.53c7.28.8,14.74,1.41,21.67,3.48,13.63,4.06,23.82,17.77,24.29,31.6.9,26.33,1.52,52.67-.66,79-2.42,29.16-8,57.63-21,84.35C280.66,297,259,320.61,230.77,338.64a254.68,254.68,0,0,1-54.41,25.78,26.09,26.09,0,0,1-17.26.45c-32.93-11.05-62.9-26.9-87.46-51.32C48.06,290.12,33.33,262,25.3,230.41,18.06,201.94,16.35,172.92,17,143.74Zm122.45,80.2h-.12c0,4.68,0,9.37,0,14.06a27.48,27.48,0,0,0,4,14.34c5.66,9.4,18.08,14.73,29.09,12.37,11.68-2.51,20.84-11.76,21.54-22.81.68-10.67.6-21.4.64-32.1a7.37,7.37,0,0,1,3.08-6.47,44.31,44.31,0,0,0,12.31-52.63c-8.65-18.63-28.81-29.85-49.07-26.77-19.8,3-33.34,13.89-38.88,32.59-5.59,18.86.21,34.89,15.35,47.87a6.43,6.43,0,0,1,2,4.15C139.54,213.66,139.4,218.8,139.4,223.94Z"
                    style={{
                        fill: 'url(#linear-gradient-35)',
                    }}
                />
                <path
                    d="M160.19,219c0-5.36-.22-10.72.07-16.06.3-5.64-2-9.37-6.95-12.38-9.53-5.84-13.73-14.41-12.13-25.37S152.41,145,163.32,143.9c12.67-1.27,24,5.2,28.06,16.08,4.22,11.27.63,23.6-9.6,29.79-6.05,3.67-8.26,8.18-8,14.91.39,10.36,0,20.75,0,31.12a20,20,0,0,1-.14,3c-.63,4.09-3.33,6.41-7.15,6.23s-6.32-2.86-6.39-7c-.09-6.35,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-35)',
                    }}
                />
                <path
                    d="M17.08,143.46c0-11.94.19-23.88,0-35.81-.39-20.49,15-37.31,37.89-38.45,21.46-1.08,42.2-5.61,61.18-15.6,9.43-5,18.06-11.4,26.92-17.36a51.12,51.12,0,0,0,7.71-6.69c8-8.15,24.28-7.91,32.52,0a136.4,136.4,0,0,0,41.68,27.52A162.86,162.86,0,0,0,270.8,68.6c7.27.8,14.72,1.41,21.65,3.48,13.62,4.06,23.8,17.77,24.27,31.6.89,26.33,1.52,52.67-.66,79-2.42,29.16-7.95,57.63-21,84.35-14.51,29.69-36.13,53.33-64.34,71.36a254.6,254.6,0,0,1-54.37,25.78,26.06,26.06,0,0,1-17.25.45c-32.89-11-62.84-26.9-87.38-51.32-23.55-23.43-38.27-51.55-46.3-83.14C18.19,201.66,16.49,172.64,17.08,143.46Zm122.34,80.2h-.12c0,4.68,0,9.37,0,14.06a27.47,27.47,0,0,0,4,14.34c5.65,9.4,18.07,14.73,29.06,12.37,11.68-2.51,20.83-11.76,21.53-22.81.67-10.67.6-21.4.63-32.1a7.4,7.4,0,0,1,3.08-6.47,44.34,44.34,0,0,0,12.3-52.63c-8.64-18.63-28.79-29.85-49-26.77-19.77,3-33.3,13.89-38.84,32.59-5.58,18.86.21,34.89,15.34,47.87a6.37,6.37,0,0,1,2,4.15C139.57,213.38,139.42,218.52,139.42,223.66Z"
                    style={{
                        fill: 'url(#linear-gradient-37)',
                    }}
                />
                <path
                    d="M160.2,218.71c0-5.36-.22-10.72.06-16.06.3-5.64-2-9.37-6.94-12.38-9.52-5.84-13.71-14.41-12.11-25.37s11.21-20.18,22.11-21.28c12.67-1.28,24,5.2,28,16.08,4.23,11.27.63,23.6-9.58,29.79-6.05,3.67-8.26,8.18-8,14.91.39,10.36,0,20.75,0,31.12a21.38,21.38,0,0,1-.14,3c-.64,4.09-3.33,6.41-7.15,6.23s-6.32-2.86-6.38-7c-.1-6.35,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-37)',
                    }}
                />
                <path
                    d="M17.22,143.18c0-11.94.19-23.88,0-35.81-.4-20.49,15-37.31,37.85-38.45,21.44-1.08,42.16-5.61,61.12-15.6,9.43-5,18-11.4,26.9-17.36a51.12,51.12,0,0,0,7.71-6.69c8-8.15,24.26-7.91,32.49,0a136.34,136.34,0,0,0,41.64,27.52,162.59,162.59,0,0,0,45.82,11.53c7.26.8,14.7,1.41,21.63,3.48,13.6,4.06,23.77,17.77,24.24,31.6.9,26.33,1.52,52.67-.66,79-2.41,29.16-7.94,57.63-21,84.35-14.49,29.69-36.09,53.33-64.28,71.36a254,254,0,0,1-54.32,25.78,26,26,0,0,1-17.23.45C126.24,353.26,96.33,337.4,71.81,313c-23.54-23.43-38.24-51.55-46.26-83.14C18.33,201.38,16.62,172.36,17.22,143.18Zm122.23,80.2h-.12c0,4.68,0,9.37,0,14.06a27.48,27.48,0,0,0,4,14.34c5.64,9.4,18,14.73,29,12.37,11.66-2.51,20.81-11.76,21.51-22.81.67-10.67.59-21.4.63-32.1a7.39,7.39,0,0,1,3.07-6.47,44.35,44.35,0,0,0,12.29-52.63c-8.63-18.63-28.76-29.85-49-26.77-19.76,3-33.27,13.88-38.81,32.59s.22,34.89,15.32,47.87a6.43,6.43,0,0,1,2,4.15C139.59,213.1,139.45,218.24,139.45,223.38Z"
                    style={{
                        fill: 'url(#linear-gradient-39)',
                    }}
                />
                <path
                    d="M160.21,218.43c0-5.36-.23-10.72.06-16.06.3-5.64-2-9.37-6.94-12.38-9.51-5.84-13.7-14.41-12.1-25.37s11.21-20.18,22.1-21.28c12.65-1.28,23.93,5.2,28,16.08,4.22,11.27.63,23.6-9.57,29.79-6,3.67-8.25,8.18-8,14.91.38,10.36,0,20.75,0,31.12a21.38,21.38,0,0,1-.14,3c-.63,4.09-3.32,6.41-7.14,6.23s-6.31-2.86-6.37-7c-.1-6.35,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-39)',
                    }}
                />
                <path
                    d="M17.35,142.9c0-11.94.19-23.88,0-35.81-.39-20.49,15-37.31,37.82-38.45C76.55,67.56,97.26,63,116.2,53c9.42-5,18-11.4,26.87-17.36a51,51,0,0,0,7.7-6.69c8-8.15,24.24-7.91,32.46,0a136.2,136.2,0,0,0,41.61,27.52A162.35,162.35,0,0,0,270.61,68c7.26.8,14.7,1.41,21.62,3.48,13.59,4.06,23.75,17.77,24.22,31.6.89,26.33,1.51,52.67-.66,79-2.42,29.17-7.94,57.64-21,84.36-14.48,29.69-36.06,53.33-64.23,71.36a253.61,253.61,0,0,1-54.27,25.78,25.94,25.94,0,0,1-17.21.45c-32.84-11-62.73-26.91-87.23-51.32-23.51-23.43-38.2-51.55-46.21-83.14C18.46,201.1,16.76,172.08,17.35,142.9Zm122.12,80.2h-.12c0,4.68,0,9.37,0,14.06a27.38,27.38,0,0,0,4,14.34c5.63,9.4,18,14.73,29,12.37,11.66-2.51,20.79-11.76,21.49-22.81.68-10.67.6-21.4.63-32.1a7.4,7.4,0,0,1,3.08-6.47,44.4,44.4,0,0,0,12.28-52.63c-8.63-18.63-28.74-29.85-48.94-26.77-19.75,3-33.25,13.88-38.78,32.59s.22,34.89,15.31,47.87a6.37,6.37,0,0,1,2,4.15C139.62,212.82,139.47,218,139.47,223.1Z"
                    style={{
                        fill: 'url(#linear-gradient-41)',
                    }}
                />
                <path
                    d="M160.21,218.15c0-5.36-.22-10.72.06-16.06.31-5.64-2-9.37-6.93-12.38-9.5-5.84-13.68-14.41-12.09-25.37s11.2-20.18,22.08-21.28c12.64-1.28,23.91,5.2,28,16.08,4.22,11.27.63,23.6-9.56,29.79-6.05,3.67-8.25,8.18-8,14.91.39,10.36,0,20.75,0,31.12a18.73,18.73,0,0,1-.14,3c-.63,4.09-3.32,6.41-7.12,6.23s-6.31-2.86-6.38-7c-.09-6.36,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-41)',
                    }}
                />
                <path
                    d="M17.49,142.62c0-11.94.19-23.88,0-35.81-.4-20.49,15-37.31,37.78-38.45,21.41-1.08,42.09-5.61,61-15.6,9.41-5,18-11.4,26.85-17.36a51,51,0,0,0,7.7-6.69c8-8.15,24.21-7.91,32.43,0a135.9,135.9,0,0,0,41.57,27.52,162,162,0,0,0,45.73,11.53c7.25.8,14.68,1.41,21.59,3.48,13.58,4.06,23.73,17.77,24.2,31.6.9,26.33,1.52,52.67-.66,79-2.41,29.17-7.92,57.64-20.94,84.36-14.46,29.69-36,53.33-64.16,71.36a253.53,253.53,0,0,1-54.23,25.78,25.88,25.88,0,0,1-17.19.45C126.32,352.7,96.45,336.84,72,312.43,48.49,289,33.81,260.88,25.81,229.29,18.59,200.82,16.89,171.8,17.49,142.62Zm122,80.2h-.12c0,4.68,0,9.37,0,14.06a27.56,27.56,0,0,0,4,14.34c5.63,9.4,18,14.73,29,12.37,11.64-2.51,20.77-11.76,21.47-22.81.67-10.67.59-21.4.63-32.1a7.39,7.39,0,0,1,3.07-6.47,44.41,44.41,0,0,0,12.27-52.63c-8.62-18.63-28.71-29.85-48.9-26.77-19.72,3-33.21,13.88-38.74,32.59s.22,34.89,15.3,47.87a6.42,6.42,0,0,1,2,4.15C139.64,212.54,139.5,217.68,139.5,222.82Z"
                    style={{
                        fill: 'url(#linear-gradient-43)',
                    }}
                />
                <path
                    d="M160.22,217.87c0-5.36-.23-10.72.06-16.06.3-5.64-2-9.37-6.92-12.38-9.5-5.84-13.68-14.41-12.08-25.37s11.18-20.18,22.05-21.28c12.63-1.28,23.89,5.2,28,16.08,4.21,11.27.63,23.6-9.56,29.79-6,3.67-8.23,8.18-8,14.91.38,10.36,0,20.75,0,31.12a21.38,21.38,0,0,1-.14,3c-.63,4.09-3.32,6.41-7.12,6.23s-6.31-2.86-6.37-7c-.1-6.36,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-43)',
                    }}
                />
                <path
                    d="M17.62,142.34c0-11.94.19-23.88,0-35.81-.39-20.49,15-37.31,37.75-38.45,21.39-1.08,42.05-5.61,61-15.6,9.4-5,18-11.4,26.82-17.36a50.49,50.49,0,0,0,7.69-6.69c8-8.15,24.19-7.91,32.41,0A135.72,135.72,0,0,0,224.74,56a161.73,161.73,0,0,0,45.69,11.53c7.24.8,14.67,1.41,21.57,3.48,13.57,4.06,23.71,17.77,24.18,31.6.89,26.33,1.51,52.67-.66,79-2.41,29.17-7.92,57.64-20.92,84.36-14.45,29.69-36,53.33-64.11,71.36A253.3,253.3,0,0,1,176.32,363a25.88,25.88,0,0,1-17.19.45c-32.78-11.05-62.61-26.91-87.06-51.32C48.59,288.72,33.93,260.6,25.93,229,18.73,200.54,17,171.52,17.62,142.34Zm121.9,80.2h-.12c0,4.68,0,9.37,0,14.06a27.46,27.46,0,0,0,4,14.34c5.63,9.4,18,14.73,29,12.37,11.64-2.51,20.76-11.76,21.45-22.81.68-10.67.6-21.4.63-32.1a7.41,7.41,0,0,1,3.07-6.47,44.44,44.44,0,0,0,12.26-52.63c-8.61-18.63-28.69-29.85-48.85-26.77-19.71,3-33.19,13.88-38.71,32.59S122.41,190,137.47,203a6.37,6.37,0,0,1,2,4.15C139.67,212.26,139.52,217.4,139.52,222.54Z"
                    style={{
                        fill: 'url(#linear-gradient-45)',
                    }}
                />
                <path
                    d="M160.23,217.59c0-5.36-.23-10.72.06-16.06.3-5.64-2-9.37-6.92-12.38-9.49-5.84-13.66-14.41-12.07-25.37s11.18-20.18,22-21.28c12.61-1.28,23.86,5.2,27.93,16.08,4.21,11.27.63,23.6-9.55,29.79-6,3.67-8.23,8.18-8,14.91.38,10.36,0,20.75,0,31.12a18.73,18.73,0,0,1-.14,3c-.63,4.09-3.31,6.41-7.11,6.23s-6.3-2.86-6.36-7c-.1-6.36,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-45)',
                    }}
                />
                <path
                    d="M17.76,142.06c0-11.94.18-23.88,0-35.81-.4-20.49,15-37.31,37.71-38.45,21.37-1.08,42-5.61,60.91-15.6,9.39-5,18-11.4,26.8-17.36a51.39,51.39,0,0,0,7.68-6.69c8-8.15,24.17-7.91,32.37,0a135.78,135.78,0,0,0,41.49,27.52A161.55,161.55,0,0,0,270.33,67.2c7.24.8,14.66,1.41,21.56,3.48,13.55,4.06,23.69,17.77,24.16,31.6.89,26.33,1.51,52.67-.66,79-2.41,29.17-7.92,57.64-20.9,84.36-14.45,29.69-36,53.33-64.06,71.36a252.69,252.69,0,0,1-54.12,25.78,25.82,25.82,0,0,1-17.17.45c-32.75-11.05-62.56-26.91-87-51.32-23.45-23.43-38.1-51.55-46.09-83.14C18.86,200.26,17.16,171.24,17.76,142.06Zm121.79,80.2h-.12c0,4.68,0,9.37,0,14.06a27.55,27.55,0,0,0,4,14.34c5.62,9.4,18,14.73,28.93,12.37,11.62-2.51,20.73-11.76,21.43-22.81.67-10.67.59-21.4.63-32.1a7.39,7.39,0,0,1,3.07-6.47A44.46,44.46,0,0,0,209.71,149c-8.6-18.63-28.66-29.85-48.81-26.77-19.69,3-33.15,13.88-38.66,32.59s.21,34.89,15.26,47.87a6.42,6.42,0,0,1,2,4.15C139.69,212,139.55,217.12,139.55,222.26Z"
                    style={{
                        fill: 'url(#linear-gradient-47)',
                    }}
                />
                <path
                    d="M160.23,217.31c0-5.36-.22-10.72.06-16.06.3-5.64-2-9.37-6.91-12.38-9.48-5.84-13.65-14.41-12.06-25.37s11.17-20.18,22-21.28c12.61-1.28,23.85,5.2,27.91,16.08,4.2,11.27.62,23.6-9.54,29.79-6,3.67-8.22,8.18-8,14.91.39,10.36,0,20.75,0,31.12a21.38,21.38,0,0,1-.14,3c-.63,4.09-3.31,6.41-7.11,6.23s-6.29-2.86-6.36-7c-.09-6.36,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-47)',
                    }}
                />
                <path
                    d="M17.89,141.78c0-11.94.19-23.88,0-35.81-.39-20.49,15-37.31,37.68-38.45,21.35-1.08,42-5.61,60.85-15.61,9.38-5,18-11.39,26.78-17.35a50.82,50.82,0,0,0,7.67-6.69c8-8.15,24.15-7.91,32.35,0a135.6,135.6,0,0,0,41.45,27.52,161.29,161.29,0,0,0,45.61,11.53c7.23.8,14.64,1.41,21.54,3.48,13.54,4.06,23.66,17.77,24.13,31.6.89,26.33,1.51,52.67-.66,79-2.4,29.17-7.9,57.64-20.88,84.36-14.43,29.69-35.93,53.33-64,71.36a252.61,252.61,0,0,1-54.08,25.78,25.76,25.76,0,0,1-17.15.45c-32.72-11.05-62.5-26.91-86.91-51.32-23.43-23.43-38.07-51.55-46-83.14C19,200,17.3,171,17.89,141.78ZM139.57,222h-.12c0,4.69,0,9.38,0,14.07a27.54,27.54,0,0,0,4,14.34c5.62,9.4,18,14.73,28.9,12.37,11.62-2.51,20.72-11.76,21.42-22.81.67-10.67.59-21.4.63-32.1a7.38,7.38,0,0,1,3.06-6.47,44.48,44.48,0,0,0,12.23-52.63C201.08,130.11,181,118.89,160.91,122c-19.67,3-33.13,13.88-38.63,32.59s.21,34.89,15.25,47.87a6.41,6.41,0,0,1,1.95,4.15C139.72,211.7,139.57,216.84,139.57,222Z"
                    style={{
                        fill: 'url(#linear-gradient-49)',
                    }}
                />
                <path
                    d="M160.24,217c0-5.36-.23-10.72.06-16.06.3-5.65-2-9.37-6.91-12.38-9.47-5.84-13.63-14.41-12-25.37s11.15-20.18,22-21.28c12.59-1.28,23.82,5.2,27.88,16.08,4.2,11.27.62,23.6-9.54,29.79-6,3.67-8.21,8.18-8,14.91.38,10.36,0,20.75,0,31.12a20,20,0,0,1-.14,3c-.63,4.09-3.31,6.41-7.1,6.23s-6.29-2.86-6.35-7c-.1-6.36,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-49)',
                    }}
                />
                <path
                    d="M18,141.5c0-11.94.18-23.88-.05-35.81-.39-20.49,15-37.31,37.65-38.46,21.33-1.07,41.94-5.6,60.8-15.6,9.37-5,17.95-11.39,26.75-17.35a50.27,50.27,0,0,0,7.66-6.69c7.95-8.15,24.13-7.91,32.32,0a135.46,135.46,0,0,0,41.42,27.52,160.94,160.94,0,0,0,45.57,11.53c7.22.8,14.63,1.41,21.52,3.48,13.53,4.06,23.64,17.77,24.11,31.6.89,26.33,1.51,52.67-.66,79-2.4,29.17-7.9,57.64-20.86,84.36-14.42,29.69-35.9,53.33-63.94,71.36a252.23,252.23,0,0,1-54,25.78,25.71,25.71,0,0,1-17.13.45c-32.7-11.05-62.45-26.91-86.84-51.32-23.41-23.43-38-51.55-46-83.14C19.13,199.7,17.43,170.68,18,141.5ZM139.6,221.69h-.12c0,4.69,0,9.38,0,14.07a27.55,27.55,0,0,0,4,14.34c5.61,9.4,17.94,14.73,28.87,12.37,11.6-2.51,20.7-11.76,21.39-22.81.67-10.67.59-21.4.63-32.1a7.4,7.4,0,0,1,3.06-6.47,44.54,44.54,0,0,0,12.23-52.63c-8.59-18.63-28.61-29.85-48.73-26.77-19.65,3-33.09,13.88-38.59,32.59s.21,34.89,15.23,47.87a6.42,6.42,0,0,1,2,4.15C139.74,211.42,139.6,216.56,139.6,221.69Z"
                    style={{
                        fill: 'url(#linear-gradient-51)',
                    }}
                />
                <path
                    d="M160.24,216.75c0-5.36-.22-10.72.07-16.06.3-5.65-2-9.37-6.9-12.38-9.46-5.84-13.63-14.41-12-25.37s11.15-20.18,22-21.28c12.58-1.28,23.8,5.2,27.85,16.08,4.2,11.27.63,23.6-9.52,29.79-6,3.67-8.21,8.18-8,14.91.39,10.36,0,20.75,0,31.12a21.38,21.38,0,0,1-.14,3c-.63,4.09-3.31,6.41-7.1,6.23s-6.28-2.86-6.34-7c-.1-6.36,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-51)',
                    }}
                />
                <path
                    d="M18.16,141.22c0-11.94.19-23.88,0-35.81C17.73,84.92,33.05,68.1,55.73,67c21.31-1.07,41.9-5.6,60.74-15.6,9.37-5,17.94-11.39,26.73-17.35a50.75,50.75,0,0,0,7.66-6.69c7.94-8.15,24.11-7.91,32.29,0a135.28,135.28,0,0,0,41.38,27.52,160.76,160.76,0,0,0,45.53,11.53c7.22.8,14.61,1.41,21.49,3.48,13.52,4.06,23.63,17.77,24.1,31.6.88,26.33,1.5,52.67-.66,79-2.4,29.17-7.89,57.64-20.85,84.36-14.4,29.69-35.86,53.33-63.88,71.36a251.62,251.62,0,0,1-54,25.78,25.68,25.68,0,0,1-17.12.45C126.5,351.3,96.77,335.44,72.41,311c-23.39-23.43-38-51.55-46-83.14C19.26,199.42,17.57,170.4,18.16,141.22Zm121.46,80.19h-.12c0,4.69,0,9.38,0,14.07a27.54,27.54,0,0,0,4,14.34c5.6,9.4,17.93,14.73,28.85,12.37,11.59-2.51,20.67-11.76,21.37-22.81.67-10.67.59-21.4.63-32.1a7.42,7.42,0,0,1,3-6.47,44.55,44.55,0,0,0,12.22-52.63C201,129.55,181,118.33,160.92,121.41c-19.64,3-33.06,13.88-38.56,32.59-5.55,18.86.21,34.89,15.22,47.87a6.36,6.36,0,0,1,1.95,4.15C139.77,211.14,139.62,216.28,139.62,221.41Z"
                    style={{
                        fill: 'url(#linear-gradient-53)',
                    }}
                />
                <path
                    d="M160.25,216.47c0-5.36-.22-10.72.06-16.06.3-5.65-2-9.37-6.89-12.38-9.45-5.84-13.61-14.41-12-25.37s11.14-20.18,22-21.28c12.57-1.28,23.78,5.2,27.83,16.08,4.2,11.27.63,23.6-9.51,29.79-6,3.67-8.2,8.18-7.95,14.91.38,10.36,0,20.75,0,31.12a21.38,21.38,0,0,1-.14,3c-.63,4.09-3.3,6.41-7.09,6.23s-6.28-2.86-6.34-7c-.09-6.36,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-53)',
                    }}
                />
                <path
                    d="M18.29,140.94c0-11.94.19-23.88,0-35.81-.39-20.49,14.92-37.31,37.58-38.46,21.29-1.07,41.87-5.6,60.69-15.6,9.36-5,17.92-11.39,26.7-17.35A50.67,50.67,0,0,0,150.87,27c7.94-8.15,24.09-7.91,32.26,0a135.26,135.26,0,0,0,41.35,27.52A160.43,160.43,0,0,0,270,66.08c7.22.8,14.61,1.41,21.48,3.48,13.51,4.06,23.6,17.77,24.07,31.6.89,26.33,1.51,52.67-.65,79-2.4,29.17-7.89,57.64-20.83,84.36-14.39,29.69-35.83,53.33-63.82,71.36a251.92,251.92,0,0,1-53.93,25.78,25.65,25.65,0,0,1-17.11.45C126.54,351,96.84,335.16,72.49,310.75c-23.36-23.43-38-51.55-45.92-83.14C19.39,199.14,17.7,170.12,18.29,140.94Zm121.36,80.19h-.12c0,4.69,0,9.38,0,14.07a27.64,27.64,0,0,0,4,14.34c5.6,9.4,17.92,14.73,28.82,12.37,11.58-2.51,20.66-11.76,21.35-22.81.67-10.67.6-21.4.63-32.1a7.4,7.4,0,0,1,3.06-6.47,44.59,44.59,0,0,0,12.2-52.63C201,129.27,181,118.05,160.93,121.13c-19.62,3-33,13.88-38.53,32.59-5.54,18.86.21,34.89,15.21,47.87a6.45,6.45,0,0,1,1.95,4.15C139.79,210.86,139.65,216,139.65,221.13Z"
                    style={{
                        fill: 'url(#linear-gradient-55)',
                    }}
                />
                <path
                    d="M160.26,216.19c0-5.36-.23-10.72.06-16.06.3-5.65-2-9.37-6.89-12.38-9.44-5.84-13.6-14.41-12-25.37s11.12-20.18,21.94-21.28c12.56-1.28,23.75,5.2,27.8,16.08,4.19,11.27.63,23.6-9.51,29.79-6,3.67-8.19,8.18-7.94,14.91.38,10.36,0,20.75,0,31.12a18.73,18.73,0,0,1-.14,3c-.62,4.09-3.3,6.41-7.08,6.23s-6.27-2.86-6.33-7c-.1-6.36,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-55)',
                    }}
                />
                <path
                    d="M18.43,140.66c0-11.94.18-23.88,0-35.81C18,84.36,33.29,67.54,55.93,66.39c21.27-1.07,41.83-5.6,60.64-15.6,9.34-5,17.9-11.39,26.67-17.35a50.67,50.67,0,0,0,7.65-6.69c7.93-8.15,24.06-7.91,32.23,0a135.08,135.08,0,0,0,41.31,27.52A160.16,160.16,0,0,0,269.87,65.8c7.21.8,14.59,1.41,21.46,3.48,13.49,4.06,23.58,17.77,24.05,31.6.89,26.33,1.5,52.67-.66,79-2.39,29.17-7.87,57.64-20.81,84.36-14.37,29.69-35.8,53.33-63.76,71.36a251.32,251.32,0,0,1-53.88,25.78,25.59,25.59,0,0,1-17.09.45c-32.61-11.05-62.28-26.91-86.6-51.32C49.23,287,34.65,258.92,26.69,227.33,19.53,198.86,17.84,169.84,18.43,140.66Zm121.24,80.19h-.12c0,4.69,0,9.38,0,14.07a27.53,27.53,0,0,0,4,14.34c5.6,9.4,17.9,14.73,28.8,12.37,11.57-2.51,20.64-11.76,21.33-22.81.67-10.67.59-21.4.63-32.1a7.42,7.42,0,0,1,3.05-6.47,44.6,44.6,0,0,0,12.19-52.63C201,129,181,117.77,160.93,120.85c-19.6,3-33,13.88-38.49,32.59-5.54,18.86.21,34.89,15.2,47.87a6.39,6.39,0,0,1,1.94,4.15C139.82,210.58,139.67,215.72,139.67,220.85Z"
                    style={{
                        fill: 'url(#linear-gradient-57)',
                    }}
                />
                <path
                    d="M160.26,215.91c0-5.36-.22-10.72.06-16.06.3-5.65-2-9.37-6.88-12.38-9.43-5.84-13.58-14.41-12-25.37s11.12-20.18,21.92-21.28c12.55-1.28,23.74,5.2,27.78,16.08,4.19,11.27.62,23.6-9.5,29.79-6,3.67-8.18,8.18-7.93,14.91.38,10.36,0,20.75,0,31.12a21.38,21.38,0,0,1-.14,3c-.63,4.09-3.3,6.41-7.08,6.23s-6.26-2.86-6.32-7c-.1-6.36,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-57)',
                    }}
                />
                <path
                    d="M18.56,140.38c0-11.94.19-23.88,0-35.81C18.13,84.08,33.41,67.26,56,66.11c21.26-1.07,41.79-5.6,60.58-15.6,9.34-5,17.89-11.39,26.66-17.35a50.53,50.53,0,0,0,7.63-6.69c7.92-8.15,24-7.91,32.21,0A134.78,134.78,0,0,0,224.38,54a159.81,159.81,0,0,0,45.4,11.53c7.2.8,14.58,1.41,21.44,3.48,13.48,4.06,23.56,17.77,24,31.6.88,26.33,1.5,52.67-.66,79-2.39,29.17-7.87,57.64-20.79,84.36-14.36,29.69-35.77,53.33-63.71,71.36a250.94,250.94,0,0,1-53.83,25.78,25.56,25.56,0,0,1-17.08.45C126.61,350.46,97,334.6,72.66,310.19c-23.32-23.43-37.89-51.55-45.84-83.14C19.66,198.58,18,169.56,18.56,140.38ZM139.7,220.57h-.12c0,4.69,0,9.38,0,14.07a27.72,27.72,0,0,0,4,14.34c5.59,9.4,17.89,14.73,28.77,12.37,11.56-2.51,20.62-11.76,21.32-22.81.67-10.67.59-21.4.62-32.1A7.42,7.42,0,0,1,197.3,200a44.62,44.62,0,0,0,12.18-52.63c-8.55-18.63-28.5-29.85-48.54-26.77-19.59,3-33,13.88-38.46,32.59C117,172,122.69,188.05,137.66,201a6.45,6.45,0,0,1,2,4.15C139.84,210.3,139.7,215.44,139.7,220.57Z"
                    style={{
                        fill: 'url(#linear-gradient-59)',
                    }}
                />
                <path
                    d="M160.27,215.63c0-5.36-.22-10.72.06-16.06.3-5.65-2-9.37-6.87-12.38-9.43-5.84-13.58-14.41-12-25.37s11.11-20.18,21.9-21.28c12.54-1.28,23.72,5.2,27.76,16.08,4.18,11.27.62,23.6-9.49,29.79-6,3.67-8.18,8.18-7.93,14.91.38,10.36,0,20.75,0,31.12a20,20,0,0,1-.14,3c-.62,4.09-3.29,6.41-7.07,6.23s-6.26-2.86-6.32-7c-.09-6.36,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-59)',
                    }}
                />
                <path
                    d="M18.7,140.1c0-11.94.18-23.88-.05-35.81C18.26,83.8,33.53,67,56.13,65.83c21.24-1.07,41.75-5.6,60.53-15.6,9.33-5,17.87-11.39,26.63-17.35a51,51,0,0,0,7.63-6.69c7.91-8.15,24-7.91,32.17,0a134.84,134.84,0,0,0,41.23,27.52,159.7,159.7,0,0,0,45.37,11.53c7.19.8,14.56,1.4,21.42,3.48,13.47,4.06,23.54,17.77,24,31.6.89,26.33,1.5,52.67-.65,79-2.39,29.17-7.87,57.64-20.77,84.36C279.34,293.33,258,317,230,335a250.63,250.63,0,0,1-53.79,25.78,25.5,25.5,0,0,1-17.06.45C126.65,350.18,97,334.32,72.75,309.91,49.44,286.48,34.88,258.36,27,226.77,19.79,198.3,18.11,169.28,18.7,140.1Zm121,80.19h-.12c0,4.69,0,9.38,0,14.07a27.62,27.62,0,0,0,3.95,14.34c5.59,9.4,17.87,14.73,28.75,12.37,11.55-2.51,20.6-11.76,21.29-22.81.67-10.67.59-21.4.63-32.1a7.42,7.42,0,0,1,3.05-6.47,44.66,44.66,0,0,0,12.17-52.63c-8.55-18.63-28.48-29.85-48.51-26.77-19.56,3-32.94,13.88-38.42,32.59-5.53,18.86.21,34.89,15.17,47.87a6.39,6.39,0,0,1,1.94,4.15C139.86,210,139.72,215.16,139.72,220.29Z"
                    style={{
                        fill: 'url(#linear-gradient-61)',
                    }}
                />
                <path
                    d="M160.28,215.35c0-5.36-.23-10.72.06-16.06.3-5.65-2-9.37-6.87-12.38-9.42-5.84-13.56-14.41-12-25.37s11.09-20.18,21.88-21.28c12.52-1.28,23.69,5.2,27.73,16.08,4.18,11.27.62,23.6-9.48,29.79-6,3.67-8.17,8.18-7.93,14.91.39,10.36,0,20.74,0,31.12a21.38,21.38,0,0,1-.14,3c-.63,4.09-3.29,6.41-7.07,6.23s-6.25-2.86-6.31-7c-.1-6.36,0-12.71,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-61)',
                    }}
                />
                <path
                    d="M18.83,139.82c0-11.94.19-23.88,0-35.81C18.4,83.52,33.65,66.7,56.23,65.55,77.45,64.48,98,60,116.7,50c9.33-5,17.86-11.39,26.61-17.35a50.45,50.45,0,0,0,7.62-6.69c7.91-8.16,24-7.91,32.15,0a134.66,134.66,0,0,0,41.19,27.52A159.44,159.44,0,0,0,269.6,65c7.18.8,14.55,1.4,21.4,3.48,13.45,4.06,23.51,17.77,24,31.6.88,26.33,1.5,52.67-.65,79-2.39,29.17-7.86,57.64-20.76,84.35-14.33,29.7-35.7,53.34-63.59,71.37a250.26,250.26,0,0,1-53.74,25.78,25.44,25.44,0,0,1-17,.45C126.68,349.9,97.09,334,72.83,309.63,49.55,286.2,35,258.08,27.07,226.49,19.93,198,18.24,169,18.83,139.82ZM139.75,220h-.12c0,4.69,0,9.38,0,14.07a27.81,27.81,0,0,0,4,14.34c5.58,9.4,17.86,14.73,28.72,12.37,11.54-2.51,20.59-11.76,21.28-22.81.67-10.67.59-21.4.62-32.1a7.42,7.42,0,0,1,3.05-6.47,44.69,44.69,0,0,0,12.16-52.63C200.87,128.15,181,116.93,161,120c-19.55,3-32.92,13.88-38.39,32.59-5.52,18.86.21,34.89,15.15,47.87a6.45,6.45,0,0,1,1.95,4.15C139.89,209.74,139.75,214.88,139.75,220Z"
                    style={{
                        fill: 'url(#linear-gradient-63)',
                    }}
                />
                <path
                    d="M160.28,215.07c0-5.36-.22-10.72.06-16.06.3-5.65-2-9.37-6.86-12.38-9.41-5.84-13.55-14.41-12-25.37s11.08-20.18,21.86-21.28c12.51-1.28,23.67,5.2,27.7,16.08,4.18,11.27.63,23.6-9.47,29.79-6,3.67-8.16,8.18-7.91,14.91.38,10.36,0,20.74,0,31.12a20,20,0,0,1-.14,3c-.63,4.09-3.29,6.41-7.06,6.23s-6.24-2.86-6.31-7c-.09-6.36,0-12.72,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-63)',
                    }}
                />
                <path
                    d="M19,139.54c0-11.94.19-23.88,0-35.81-.39-20.49,14.85-37.31,37.41-38.46,21.2-1.07,41.68-5.6,60.42-15.6,9.31-5,17.84-11.39,26.58-17.35A50.45,50.45,0,0,0,151,25.63c7.9-8.16,24-7.91,32.11,0a134.82,134.82,0,0,0,41.16,27.52A159.19,159.19,0,0,0,269.5,64.68c7.18.8,14.54,1.4,21.39,3.48,13.44,4.06,23.49,17.77,24,31.6.88,26.33,1.5,52.67-.66,79-2.38,29.17-7.85,57.64-20.73,84.35-14.32,29.7-35.67,53.34-63.54,71.37a249.65,249.65,0,0,1-53.69,25.78,25.39,25.39,0,0,1-17,.45c-32.49-11.05-62.06-26.91-86.29-51.32-23.26-23.43-37.8-51.55-45.72-83.14C20.06,197.74,18.37,168.72,19,139.54Zm120.81,80.19h-.12c0,4.69,0,9.38,0,14.07a27.7,27.7,0,0,0,3.94,14.34c5.58,9.4,17.84,14.73,28.7,12.37,11.53-2.51,20.57-11.76,21.26-22.81.66-10.67.58-21.4.62-32.1a7.44,7.44,0,0,1,3-6.47,44.7,44.7,0,0,0,12.15-52.63c-8.53-18.63-28.43-29.85-48.42-26.77-19.53,3-32.88,13.88-38.35,32.59-5.52,18.86.21,34.89,15.14,47.87a6.39,6.39,0,0,1,1.94,4.15C139.91,209.46,139.77,214.6,139.77,219.73Z"
                    style={{
                        fill: 'url(#linear-gradient-65)',
                    }}
                />
                <path
                    d="M160.29,214.79c0-5.36-.22-10.72.06-16.06.3-5.65-2-9.37-6.86-12.38-9.4-5.84-13.53-14.41-12-25.37s11.08-20.18,21.84-21.28c12.51-1.28,23.65,5.2,27.68,16.08,4.17,11.27.63,23.6-9.46,29.79-6,3.67-8.16,8.18-7.91,14.91.38,10.36,0,20.74,0,31.12a20,20,0,0,1-.13,3c-.63,4.09-3.29,6.41-7.06,6.23s-6.24-2.86-6.3-7c-.09-6.36,0-12.72,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-65)',
                    }}
                />
                <path
                    d="M19.1,139.26c0-11.94.18-23.88-.05-35.81C18.67,83,33.89,66.14,56.43,65c21.18-1.07,41.64-5.6,60.36-15.6,9.31-5,17.83-11.39,26.56-17.35A50.38,50.38,0,0,0,151,25.35c7.89-8.16,24-7.91,32.09,0a134.52,134.52,0,0,0,41.12,27.52A158.93,158.93,0,0,0,269.41,64.4c7.17.8,14.53,1.4,21.36,3.48,13.44,4.06,23.48,17.77,23.94,31.6.89,26.33,1.5,52.67-.65,79-2.38,29.17-7.84,57.64-20.71,84.35-14.32,29.7-35.64,53.34-63.48,71.37a250.2,250.2,0,0,1-53.64,25.78,25.39,25.39,0,0,1-17,.45c-32.45-11.05-62-26.91-86.21-51.32-23.24-23.43-37.76-51.55-45.67-83.14C20.19,197.46,18.51,168.44,19.1,139.26Zm120.7,80.19h-.12c0,4.69,0,9.38,0,14.07a27.71,27.71,0,0,0,3.95,14.34c5.57,9.4,17.82,14.73,28.66,12.37,11.52-2.51,20.55-11.76,21.24-22.81.67-10.67.59-21.4.63-32.1a7.43,7.43,0,0,1,3-6.47,44.72,44.72,0,0,0,12.14-52.63c-8.52-18.63-28.4-29.85-48.37-26.77-19.51,3-32.86,13.88-38.32,32.59-5.51,18.86.21,34.89,15.13,47.87a6.49,6.49,0,0,1,1.94,4.15C139.94,209.18,139.8,214.32,139.8,219.45Z"
                    style={{
                        fill: 'url(#linear-gradient-67)',
                    }}
                />
                <path
                    d="M160.29,214.51c0-5.36-.22-10.72.07-16.06.29-5.65-2-9.37-6.85-12.38-9.4-5.84-13.53-14.41-12-25.37s11.07-20.18,21.83-21.28c12.49-1.28,23.63,5.2,27.65,16.08,4.17,11.26.62,23.6-9.45,29.79-6,3.67-8.15,8.18-7.9,14.91.38,10.36,0,20.74,0,31.12a21.45,21.45,0,0,1-.14,3c-.63,4.09-3.28,6.41-7.05,6.23s-6.23-2.86-6.29-7c-.1-6.36,0-12.72,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-67)',
                    }}
                />
                <path
                    d="M19.23,139c0-11.94.18-23.88,0-35.81C18.8,82.68,34,65.86,56.53,64.71c21.16-1.07,41.6-5.6,60.31-15.6,9.3-5,17.81-11.39,26.53-17.36A49.76,49.76,0,0,0,151,25.07c7.88-8.16,23.93-7.91,32.05,0a134.51,134.51,0,0,0,41.09,27.52,158.58,158.58,0,0,0,45.2,11.53c7.17.8,14.51,1.4,21.34,3.48,13.42,4.06,23.46,17.77,23.92,31.6.88,26.33,1.5,52.67-.65,79-2.38,29.17-7.84,57.64-20.7,84.35-14.3,29.7-35.61,53.34-63.42,71.37a249.59,249.59,0,0,1-53.59,25.78,25.33,25.33,0,0,1-17,.45c-32.43-11.05-61.94-26.91-86.13-51.32-23.22-23.43-37.73-51.55-45.64-83.14C20.32,197.18,18.64,168.16,19.23,139Zm120.59,80.19h-.12c0,4.69,0,9.38,0,14.07a27.7,27.7,0,0,0,3.94,14.34c5.56,9.4,17.8,14.73,28.64,12.37,11.51-2.51,20.53-11.76,21.22-22.81.66-10.67.59-21.4.62-32.1a7.44,7.44,0,0,1,3-6.47,44.75,44.75,0,0,0,12.12-52.63c-8.51-18.63-28.37-29.85-48.33-26.77-19.49,3-32.82,13.88-38.28,32.59-5.51,18.86.21,34.89,15.11,47.87a6.39,6.39,0,0,1,1.94,4.15C140,208.9,139.82,214,139.82,219.17Z"
                    style={{
                        fill: 'url(#linear-gradient-69)',
                    }}
                />
                <path
                    d="M160.3,214.23c0-5.36-.22-10.72.06-16.06.3-5.65-2-9.37-6.84-12.38-9.39-5.84-13.52-14.41-11.94-25.37s11-20.18,21.8-21.28c12.48-1.28,23.61,5.2,27.63,16.08,4.16,11.26.62,23.6-9.45,29.79-6,3.67-8.14,8.18-7.89,14.91.38,10.36,0,20.74,0,31.12a20,20,0,0,1-.13,3c-.63,4.09-3.28,6.41-7,6.23s-6.23-2.86-6.3-7c-.09-6.36,0-12.72,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-69)',
                    }}
                />
                <path
                    d="M19.36,138.7c0-11.94.19-23.88,0-35.81C18.93,82.4,34.13,65.57,56.63,64.43c21.14-1.07,41.56-5.6,60.25-15.6,9.29-5,17.79-11.39,26.52-17.36A49.62,49.62,0,0,0,151,24.79c7.88-8.16,23.91-7.91,32,0a134.33,134.33,0,0,0,41,27.52,158.32,158.32,0,0,0,45.16,11.53c7.16.8,14.5,1.4,21.32,3.47C304,71.38,314,85.09,314.45,98.92c.88,26.33,1.49,52.67-.65,79-2.38,29.17-7.83,57.64-20.68,84.35-14.29,29.7-35.58,53.34-63.37,71.37a249.44,249.44,0,0,1-53.54,25.78,25.27,25.27,0,0,1-17,.45c-32.4-11.06-61.89-26.91-86.06-51.32C50,285.08,35.48,257,27.58,225.37,20.45,196.9,18.78,167.88,19.36,138.7Zm120.49,80.19h-.12c0,4.69,0,9.38,0,14.07a27.8,27.8,0,0,0,3.94,14.34c5.56,9.4,17.79,14.73,28.61,12.37,11.5-2.51,20.52-11.76,21.2-22.81.67-10.67.59-21.4.63-32.1a7.43,7.43,0,0,1,3-6.47,44.79,44.79,0,0,0,12.12-52.63C200.75,127,180.9,115.81,161,118.89c-19.48,3-32.8,13.88-38.25,32.59-5.5,18.86.21,34.89,15.1,47.87a6.44,6.44,0,0,1,1.94,4.15C140,208.62,139.85,213.76,139.85,218.89Z"
                    style={{
                        fill: 'url(#linear-gradient-71)',
                    }}
                />
                <path
                    d="M160.31,214c0-5.36-.22-10.72.06-16.06.3-5.65-2-9.37-6.84-12.38-9.38-5.84-13.5-14.41-11.93-25.37s11.05-20.18,21.78-21.28c12.47-1.28,23.59,5.2,27.61,16.08,4.16,11.26.62,23.6-9.44,29.79-6,3.67-8.13,8.18-7.89,14.91.38,10.36,0,20.74,0,31.12a21.45,21.45,0,0,1-.14,3c-.63,4.09-3.28,6.41-7,6.23s-6.23-2.86-6.29-7c-.1-6.36,0-12.72,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-71)',
                    }}
                />
                <path
                    d="M19.5,138.42c0-11.94.18-23.88-.05-35.81-.38-20.49,14.8-37.32,37.28-38.46,21.12-1.07,41.53-5.6,60.2-15.6,9.28-5,17.77-11.39,26.49-17.36A50.59,50.59,0,0,0,151,24.51c7.87-8.16,23.89-7.91,32,0A134.15,134.15,0,0,0,224,52a158.06,158.06,0,0,0,45.12,11.53c7.15.8,14.48,1.4,21.3,3.47,13.4,4.07,23.41,17.77,23.88,31.61.88,26.33,1.49,52.67-.65,79-2.38,29.17-7.83,57.64-20.66,84.35-14.28,29.7-35.55,53.34-63.31,71.37a249.14,249.14,0,0,1-53.5,25.78,25.21,25.21,0,0,1-17,.45c-32.37-11.06-61.84-26.91-86-51.32C50.08,284.8,35.6,256.68,27.7,225.09,20.59,196.62,18.91,167.6,19.5,138.42Zm120.37,80.19h-.12c0,4.69,0,9.38,0,14.06A27.7,27.7,0,0,0,143.69,247c5.56,9.4,17.77,14.73,28.59,12.37,11.49-2.51,20.49-11.76,21.18-22.81.67-10.67.59-21.4.62-32.1a7.45,7.45,0,0,1,3-6.47,44.8,44.8,0,0,0,12.11-52.63c-8.5-18.63-28.33-29.85-48.24-26.77-19.46,3-32.77,13.88-38.22,32.59-5.5,18.86.21,34.89,15.09,47.87a6.43,6.43,0,0,1,1.93,4.15C140,208.34,139.87,213.48,139.87,218.61Z"
                    style={{
                        fill: 'url(#linear-gradient-73)',
                    }}
                />
                <path
                    d="M160.31,213.67c0-5.36-.22-10.72.06-16.06.3-5.65-2-9.37-6.83-12.38-9.36-5.84-13.49-14.41-11.92-25.37s11-20.18,21.77-21.28c12.46-1.28,23.56,5.2,27.58,16.08,4.15,11.26.62,23.6-9.43,29.79-6,3.67-8.13,8.18-7.88,14.91.38,10.36,0,20.74,0,31.12a20,20,0,0,1-.14,3c-.62,4.09-3.27,6.41-7,6.23s-6.22-2.86-6.28-7c-.1-6.36,0-12.72,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-73)',
                    }}
                />
                <path
                    d="M19.63,138.14c0-11.94.18-23.88,0-35.81C19.2,81.84,34.37,65,56.83,63.87c21.1-1.07,41.49-5.6,60.14-15.6,9.28-5,17.76-11.39,26.47-17.36A50,50,0,0,0,151,24.23c7.86-8.16,23.87-7.91,32,0a134,134,0,0,0,41,27.52A157.81,157.81,0,0,0,269,63.28c7.15.8,14.48,1.4,21.29,3.47,13.38,4.07,23.39,17.77,23.85,31.61.88,26.33,1.5,52.67-.65,79-2.37,29.17-7.81,57.64-20.64,84.35C278.63,291.37,257.38,315,229.64,333a248.76,248.76,0,0,1-53.45,25.78,25.18,25.18,0,0,1-16.95.45C126.9,348.21,97.47,332.36,73.34,308c-23.16-23.43-37.62-51.55-45.51-83.14C20.72,196.34,19,167.32,19.63,138.14ZM139.9,218.33h-.12c0,4.69,0,9.38,0,14.06a27.89,27.89,0,0,0,3.93,14.35c5.55,9.4,17.76,14.73,28.57,12.37,11.47-2.51,20.47-11.76,21.16-22.81.66-10.67.58-21.4.62-32.1a7.43,7.43,0,0,1,3-6.47,44.85,44.85,0,0,0,12.09-52.63c-8.49-18.63-28.3-29.85-48.2-26.77-19.44,3-32.74,13.88-38.18,32.59-5.49,18.86.21,34.89,15.07,47.87a6.44,6.44,0,0,1,1.94,4.15C140,208.06,139.9,213.2,139.9,218.33Z"
                    style={{
                        fill: 'url(#linear-gradient-75)',
                    }}
                />
                <path
                    d="M160.32,213.39c0-5.36-.22-10.72.06-16.06.3-5.65-2-9.37-6.82-12.38-9.36-5.84-13.48-14.41-11.91-25.37s11-20.18,21.74-21.28c12.45-1.28,23.55,5.2,27.56,16.08,4.15,11.26.62,23.6-9.42,29.79-6,3.67-8.12,8.18-7.88,14.91.38,10.36,0,20.74,0,31.12a21.45,21.45,0,0,1-.14,3c-.63,4.09-3.27,6.41-7,6.23s-6.22-2.86-6.28-7c-.09-6.36,0-12.72,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-75)',
                    }}
                />
                <path
                    d="M19.76,137.86c0-11.94.19-23.88,0-35.81-.39-20.49,14.77-37.32,37.21-38.46C78,62.52,98.38,58,117,48c9.26-5,17.74-11.39,26.44-17.36A50,50,0,0,0,151,24c7.85-8.16,23.84-7.91,31.94,0a134,134,0,0,0,40.93,27.52A157.7,157.7,0,0,0,269,63c7.14.8,14.46,1.4,21.27,3.47,13.37,4.07,23.37,17.77,23.83,31.61.88,26.33,1.49,52.67-.65,79-2.37,29.17-7.8,57.64-20.62,84.35-14.25,29.7-35.48,53.33-63.19,71.37a248.61,248.61,0,0,1-53.4,25.78,25.16,25.16,0,0,1-16.94.45c-32.31-11.06-61.72-26.91-85.82-51.32C50.29,284.24,35.83,256.12,28,224.53,20.85,196.06,19.18,167,19.76,137.86Zm120.16,80.19h-.12c0,4.69,0,9.38,0,14.06a27.79,27.79,0,0,0,3.92,14.35c5.55,9.4,17.74,14.73,28.54,12.37,11.47-2.51,20.46-11.76,21.14-22.81.67-10.68.59-21.4.63-32.11a7.44,7.44,0,0,1,3-6.46,44.87,44.87,0,0,0,12.08-52.63C200.66,126.19,180.87,115,161,118.05c-19.43,3-32.71,13.88-38.15,32.59-5.49,18.86.21,34.89,15.06,47.87a6.43,6.43,0,0,1,1.93,4.15C140.06,207.78,139.92,212.92,139.92,218.05Z"
                    style={{
                        fill: 'url(#linear-gradient-77)',
                    }}
                />
                <path
                    d="M160.33,213.11c0-5.36-.22-10.72.06-16.06.29-5.65-2-9.37-6.82-12.38-9.35-5.84-13.47-14.41-11.9-25.37s11-20.18,21.72-21.28c12.44-1.28,23.53,5.2,27.54,16.08,4.14,11.26.61,23.6-9.42,29.79-5.94,3.67-8.11,8.18-7.86,14.91.38,10.36,0,20.74,0,31.12a20,20,0,0,1-.14,3c-.62,4.09-3.26,6.41-7,6.23s-6.21-2.86-6.27-7c-.1-6.36,0-12.72,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-77)',
                    }}
                />
                <path
                    d="M19.89,137.58c0-11.94.19-23.88,0-35.81C19.46,81.28,34.61,64.45,57,63.31c21.06-1.07,41.41-5.6,60-15.6,9.26-5,17.73-11.39,26.42-17.36a50,50,0,0,0,7.57-6.68c7.85-8.16,23.83-7.91,31.91,0a133.89,133.89,0,0,0,40.9,27.52,157.36,157.36,0,0,0,45,11.53c7.14.8,14.45,1.4,21.25,3.47C303.47,70.26,313.46,84,313.92,97.8c.88,26.33,1.49,52.67-.65,79-2.37,29.17-7.8,57.64-20.6,84.35-14.24,29.7-35.45,53.33-63.14,71.37a248.23,248.23,0,0,1-53.35,25.78,25.1,25.1,0,0,1-16.92.45C127,347.65,97.59,331.8,73.51,307.39,50.39,284,36,255.84,28.08,224.25,21,195.78,19.31,166.76,19.89,137.58ZM140,217.77h-.12c0,4.69,0,9.38,0,14.06a27.88,27.88,0,0,0,3.92,14.35c5.54,9.4,17.73,14.73,28.52,12.37,11.45-2.51,20.43-11.76,21.12-22.81.66-10.68.59-21.4.62-32.11a7.44,7.44,0,0,1,3-6.46,44.89,44.89,0,0,0,12.07-52.63c-8.47-18.63-28.25-29.85-48.11-26.77-19.4,3-32.68,13.88-38.11,32.59s.21,34.89,15.05,47.87a6.48,6.48,0,0,1,1.93,4.15C140.09,207.5,140,212.64,140,217.77Z"
                    style={{
                        fill: 'url(#linear-gradient-79)',
                    }}
                />
                <path
                    d="M160.33,212.83c0-5.36-.22-10.72.06-16.06.3-5.65-2-9.37-6.81-12.38-9.34-5.84-13.45-14.41-11.89-25.37s11-20.18,21.71-21.28c12.42-1.28,23.5,5.2,27.5,16.08,4.15,11.26.62,23.6-9.4,29.79-5.94,3.67-8.1,8.18-7.86,14.91.38,10.36,0,20.74,0,31.12a20,20,0,0,1-.13,3c-.63,4.09-3.27,6.41-7,6.23s-6.2-2.86-6.26-7c-.1-6.36,0-12.72,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-79)',
                    }}
                />
                <path
                    d="M20,137.3c0-11.94.18-23.88,0-35.81C19.6,81,34.73,64.17,57.13,63c21-1.07,41.37-5.6,60-15.6,9.25-5,17.71-11.39,26.39-17.36a49.4,49.4,0,0,0,7.56-6.68c7.85-8.16,23.81-7.91,31.89,0a133.71,133.71,0,0,0,40.86,27.52,157.18,157.18,0,0,0,45,11.53c7.13.8,14.43,1.4,21.23,3.47,13.35,4.07,23.33,17.77,23.79,31.61.88,26.33,1.49,52.67-.65,79-2.37,29.17-7.79,57.64-20.59,84.35-14.22,29.7-35.41,53.33-63.08,71.37A247.63,247.63,0,0,1,176.17,358a25.07,25.07,0,0,1-16.91.45C127,347.37,97.65,331.52,73.59,307.11,50.5,283.68,36.07,255.56,28.2,224,21.11,195.5,19.44,166.48,20,137.3ZM140,217.49h-.12c0,4.69,0,9.38,0,14.06a28,28,0,0,0,3.92,14.35c5.54,9.4,17.71,14.73,28.49,12.37,11.45-2.51,20.42-11.76,21.11-22.81.66-10.68.58-21.4.62-32.11a7.44,7.44,0,0,1,3-6.46c15.26-12.58,20.23-34.67,12.06-52.63-8.47-18.63-28.23-29.85-48.07-26.77-19.39,3-32.65,13.88-38.08,32.59S123.13,185,138,198a6.43,6.43,0,0,1,1.93,4.15C140.11,207.22,140,212.36,140,217.49Z"
                    style={{
                        fill: 'url(#linear-gradient-81)',
                    }}
                />
                <path
                    d="M160.34,212.55c0-5.36-.22-10.72.06-16.06.3-5.65-2-9.37-6.81-12.38-9.33-5.84-13.44-14.42-11.87-25.37s11-20.18,21.68-21.28c12.41-1.28,23.48,5.2,27.48,16.08,4.14,11.26.62,23.6-9.39,29.79-5.94,3.67-8.1,8.18-7.85,14.91.37,10.36,0,20.74,0,31.12a21.45,21.45,0,0,1-.14,3c-.62,4.09-3.26,6.41-7,6.23s-6.2-2.86-6.26-7c-.09-6.36,0-12.72,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-81)',
                    }}
                />
                <path
                    d="M20.16,137c0-11.94.18-23.88,0-35.81-.39-20.49,14.73-37.32,37.11-38.46,21-1.07,41.34-5.6,59.92-15.6,9.24-5,17.7-11.39,26.37-17.36a49.4,49.4,0,0,0,7.56-6.68c7.83-8.16,23.78-7.91,31.86,0a133.41,133.41,0,0,0,40.82,27.52,156.83,156.83,0,0,0,44.92,11.53c7.12.8,14.42,1.4,21.21,3.47,13.33,4.07,23.31,17.77,23.77,31.61.87,26.33,1.48,52.67-.65,79-2.37,29.17-7.79,57.64-20.57,84.35-14.21,29.7-35.38,53.33-63,71.37a247.56,247.56,0,0,1-53.26,25.78,25,25,0,0,1-16.89.45c-32.22-11.06-61.55-26.91-85.59-51.32C50.6,283.4,36.19,255.28,28.33,223.69,21.25,195.22,19.57,166.2,20.16,137ZM140,217.21h-.11c0,4.69,0,9.38,0,14.06a27.88,27.88,0,0,0,3.92,14.35c5.53,9.4,17.69,14.73,28.46,12.37,11.44-2.52,20.4-11.76,21.08-22.81.67-10.68.59-21.4.62-32.11a7.44,7.44,0,0,1,3-6.46C212.23,184,217.19,161.94,209,144c-8.46-18.63-28.2-29.85-48-26.77-19.37,3-32.62,13.88-38,32.59s.21,34.89,15,47.87a6.42,6.42,0,0,1,1.92,4.15C140.13,206.94,140,212.08,140,217.21Z"
                    style={{
                        fill: 'url(#linear-gradient-83)',
                    }}
                />
                <path
                    d="M160.34,212.27c0-5.36-.22-10.72.06-16.06.3-5.65-2-9.37-6.8-12.38-9.32-5.84-13.42-14.42-11.86-25.37s11-20.18,21.66-21.28c12.41-1.28,23.46,5.2,27.46,16.08,4.14,11.26.62,23.6-9.39,29.79-5.93,3.67-8.08,8.18-7.84,14.91.38,10.36,0,20.74,0,31.12a20,20,0,0,1-.13,3c-.63,4.09-3.26,6.41-7,6.23s-6.19-2.86-6.25-7c-.1-6.36,0-12.72,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-83)',
                    }}
                />
                <path
                    d="M20.29,136.74c0-11.94.19-23.88,0-35.81C19.86,80.44,35,63.61,57.33,62.47c21-1.07,41.3-5.6,59.87-15.6,9.23-5,17.68-11.39,26.35-17.36a49.73,49.73,0,0,0,7.54-6.68c7.83-8.16,23.77-7.91,31.83,0a133.52,133.52,0,0,0,40.79,27.52,156.57,156.57,0,0,0,44.88,11.53c7.11.8,14.4,1.4,21.19,3.47C303.1,69.42,313.06,83.12,313.52,97c.88,26.33,1.49,52.67-.64,79-2.37,29.17-7.78,57.64-20.55,84.35-14.2,29.7-35.35,53.33-63,71.37a247,247,0,0,1-53.21,25.78,25,25,0,0,1-16.87.45c-32.2-11.06-61.5-26.91-85.52-51.32C50.71,283.12,36.31,255,28.45,223.41,21.38,194.94,19.71,165.92,20.29,136.74ZM140,216.93h-.12c0,4.69,0,9.38,0,14.06a27.88,27.88,0,0,0,3.92,14.35c5.52,9.4,17.67,14.73,28.43,12.37,11.43-2.52,20.39-11.76,21.07-22.81.66-10.68.58-21.4.62-32.11a7.45,7.45,0,0,1,3-6.46c15.24-12.58,20.2-34.67,12-52.63-8.45-18.63-28.17-29.85-48-26.77-19.35,3-32.59,13.88-38,32.59s.21,34.89,15,47.87a6.47,6.47,0,0,1,1.92,4.15C140.16,206.66,140,211.8,140,216.93Z"
                    style={{
                        fill: 'url(#linear-gradient-85)',
                    }}
                />
                <path
                    d="M160.35,212c0-5.36-.22-10.72.06-16.06.3-5.65-2-9.37-6.79-12.38-9.32-5.84-13.42-14.42-11.86-25.37s11-20.18,21.65-21.28c12.39-1.28,23.44,5.2,27.43,16.08,4.13,11.26.62,23.6-9.38,29.79-5.92,3.67-8.08,8.18-7.84,14.91.38,10.36,0,20.74,0,31.12a21.45,21.45,0,0,1-.14,3c-.62,4.09-3.26,6.41-7,6.23s-6.18-2.86-6.25-7c-.09-6.36,0-12.72,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-85)',
                    }}
                />
                <path
                    d="M20.42,136.46c0-11.94.19-23.88,0-35.81-.38-20.49,14.7-37.32,37-38.46,21-1.07,41.27-5.6,59.82-15.6,9.23-5,17.67-11.39,26.33-17.36a49.73,49.73,0,0,0,7.54-6.68c7.82-8.16,23.74-7.91,31.8,0a133.34,133.34,0,0,0,40.75,27.52A156.31,156.31,0,0,0,268.5,61.6c7.11.8,14.39,1.4,21.17,3.47,13.31,4.07,23.26,17.77,23.72,31.61.88,26.33,1.49,52.67-.64,79-2.37,29.17-7.78,57.64-20.53,84.35-14.19,29.7-35.32,53.33-62.91,71.37a247,247,0,0,1-53.16,25.78,25,25,0,0,1-16.86.45c-32.17-11.06-61.45-26.91-85.44-51.32-23-23.43-37.43-51.55-45.27-83.14C21.51,194.66,19.84,165.64,20.42,136.46ZM140,216.65h-.11c0,4.69,0,9.38,0,14.06a28,28,0,0,0,3.91,14.35c5.52,9.4,17.66,14.73,28.41,12.37,11.42-2.52,20.36-11.76,21.05-22.81.66-10.68.58-21.4.62-32.11a7.4,7.4,0,0,1,3-6.46c15.22-12.58,20.17-34.67,12-52.63-8.44-18.63-28.14-29.85-47.93-26.77-19.34,3-32.57,13.88-38,32.59s.21,34.89,15,47.87a6.42,6.42,0,0,1,1.92,4.15C140.18,206.38,140,211.52,140,216.65Z"
                    style={{
                        fill: 'url(#linear-gradient-87)',
                    }}
                />
                <path
                    d="M160.36,211.71c0-5.36-.22-10.72.06-16.06.29-5.65-2-9.37-6.79-12.38-9.31-5.84-13.41-14.42-11.84-25.37s11-20.18,21.62-21.28c12.38-1.28,23.42,5.2,27.41,16.08,4.13,11.26.61,23.6-9.37,29.79-5.92,3.67-8.08,8.18-7.83,14.91.38,10.36,0,20.74,0,31.12a20,20,0,0,1-.14,3c-.62,4.09-3.25,6.41-7,6.23s-6.18-2.86-6.24-7c-.09-6.36,0-12.72,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-87)',
                    }}
                />
                <path
                    d="M20.56,136.18c0-11.94.18-23.88,0-35.81-.38-20.49,14.69-37.32,37-38.46,21-1.07,41.23-5.6,59.77-15.6,9.21-5,17.65-11.39,26.3-17.36a49.19,49.19,0,0,0,7.53-6.68c7.82-8.16,23.72-7.91,31.77,0a133.2,133.2,0,0,0,40.72,27.52,156.05,156.05,0,0,0,44.8,11.53c7.1.8,14.38,1.4,21.15,3.47,13.3,4.07,23.24,17.77,23.7,31.61.88,26.33,1.49,52.67-.64,79-2.37,29.17-7.77,57.64-20.52,84.35-14.17,29.7-35.28,53.33-62.85,71.37a246.66,246.66,0,0,1-53.11,25.78,25,25,0,0,1-16.85.45C127.16,346.25,97.9,330.4,73.93,306c-23-23.43-37.39-51.55-45.23-83.14C21.64,194.38,20,165.36,20.56,136.18Zm119.51,80.19H140c0,4.69,0,9.38,0,14.06a28,28,0,0,0,3.91,14.35c5.51,9.4,17.64,14.73,28.38,12.37,11.41-2.52,20.35-11.76,21-22.81.66-10.68.59-21.4.62-32.11a7.45,7.45,0,0,1,3-6.46c15.21-12.58,20.16-34.67,12-52.63-8.44-18.63-28.13-29.85-47.9-26.77-19.32,3-32.53,13.88-37.94,32.59s.21,34.89,15,47.87A6.47,6.47,0,0,1,140,201C140.21,206.1,140.07,211.24,140.07,216.37Z"
                    style={{
                        fill: 'url(#linear-gradient-89)',
                    }}
                />
                <path
                    d="M160.36,211.43c0-5.36-.22-10.72.06-16.06.3-5.65-2-9.37-6.78-12.38-9.3-5.84-13.39-14.42-11.83-25.37s11-20.18,21.6-21.28c12.37-1.28,23.4,5.2,27.39,16.08,4.12,11.26.61,23.6-9.36,29.79-5.92,3.67-8.07,8.18-7.83,14.91.38,10.36,0,20.74,0,31.12a21.45,21.45,0,0,1-.14,3c-.62,4.09-3.25,6.41-7,6.23s-6.17-2.86-6.23-7c-.1-6.36,0-12.72,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-89)',
                    }}
                />
                <path
                    d="M20.69,135.9c0-11.94.18-23.88,0-35.81-.39-20.49,14.67-37.32,37-38.46C78.57,60.56,98.81,56,117.33,46c9.21-5,17.64-11.39,26.28-17.36A50.15,50.15,0,0,0,151.14,22c7.8-8.16,23.7-7.91,31.74,0a133.15,133.15,0,0,0,40.68,27.52A155.81,155.81,0,0,0,268.31,61c7.1.8,14.37,1.4,21.14,3.47,13.29,4.07,23.22,17.77,23.68,31.61.87,26.33,1.48,52.67-.64,79-2.36,29.17-7.76,57.64-20.5,84.35-14.16,29.7-35.26,53.33-62.8,71.37a246.28,246.28,0,0,1-53.06,25.78,24.9,24.9,0,0,1-16.83.45C127.19,346,98,330.12,74,305.71c-23-23.43-37.35-51.55-45.18-83.14C21.77,194.1,20.11,165.08,20.69,135.9Zm119.4,80.19H140c0,4.69,0,9.38,0,14.06a27.86,27.86,0,0,0,3.9,14.35c5.51,9.4,17.63,14.73,28.36,12.37,11.4-2.52,20.33-11.76,21-22.81.66-10.68.58-21.4.62-32.11a7.47,7.47,0,0,1,3-6.46c15.2-12.58,20.14-34.67,12-52.63-8.43-18.63-28.1-29.85-47.85-26.77-19.31,3-32.51,13.88-37.91,32.59s.2,34.89,15,47.87A6.42,6.42,0,0,1,140,200.7C140.23,205.82,140.09,211,140.09,216.09Z"
                    style={{
                        fill: 'url(#linear-gradient-91)',
                    }}
                />
                <path
                    d="M160.37,211.15c0-5.36-.22-10.72.06-16.06.3-5.65-2-9.37-6.78-12.38-9.29-5.84-13.38-14.42-11.82-25.37s10.95-20.18,21.59-21.28c12.36-1.28,23.37,5.2,27.36,16.08,4.12,11.26.61,23.6-9.36,29.79-5.9,3.67-8.06,8.18-7.81,14.91.37,10.36,0,20.74,0,31.12a20,20,0,0,1-.14,3c-.62,4.09-3.24,6.41-7,6.23s-6.17-2.86-6.23-7c-.09-6.36,0-12.72,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-91)',
                    }}
                />
                <path
                    d="M20.82,135.62c0-11.94.18-23.88,0-35.81-.39-20.49,14.66-37.32,36.94-38.46,20.93-1.07,41.15-5.6,59.66-15.6,9.2-5,17.62-11.39,26.25-17.36a49.59,49.59,0,0,0,7.52-6.68c7.8-8.16,23.68-7.91,31.72,0a132.85,132.85,0,0,0,40.64,27.52,155.55,155.55,0,0,0,44.71,11.53c7.09.8,14.36,1.4,21.12,3.47C302.61,68.3,312.54,82,313,95.84c.87,26.33,1.48,52.67-.65,79C310,204,304.6,232.44,291.88,259.15c-14.14,29.7-35.23,53.33-62.74,71.37a246,246,0,0,1-53,25.78,24.84,24.84,0,0,1-16.81.45C127.23,345.69,98,329.84,74.1,305.43,51.13,282,36.78,253.88,29,222.29,21.9,193.82,20.24,164.8,20.82,135.62Zm119.3,80.19H140c0,4.69,0,9.38,0,14.06a28.06,28.06,0,0,0,3.9,14.35,27.18,27.18,0,0,0,28.34,12.37c11.38-2.52,20.3-11.76,21-22.81.66-10.68.58-21.4.61-32.11a7.45,7.45,0,0,1,3-6.46c15.18-12.58,20.12-34.67,12-52.63C200.42,124,180.77,112.73,161,115.81c-19.28,3-32.47,13.88-37.87,32.59s.2,34.89,15,47.87a6.47,6.47,0,0,1,1.92,4.15C140.26,205.54,140.12,210.68,140.12,215.81Z"
                    style={{
                        fill: 'url(#linear-gradient-93)',
                    }}
                />
                <path
                    d="M160.38,210.87c0-5.36-.22-10.72.06-16.06.29-5.65-2-9.37-6.77-12.38-9.29-5.84-13.37-14.42-11.82-25.37s10.94-20.18,21.57-21.28c12.35-1.28,23.36,5.2,27.34,16.08,4.11,11.26.61,23.6-9.35,29.79-5.9,3.67-8,8.18-7.81,14.91.38,10.36,0,20.74,0,31.12a20,20,0,0,1-.13,3c-.62,4.09-3.25,6.41-7,6.23s-6.16-2.86-6.22-7c-.09-6.36,0-12.72,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-93)',
                    }}
                />
                <path
                    d="M21,135.34c0-11.94.18-23.88,0-35.81C20.52,79,35.56,62.21,57.82,61.07c20.91-1.07,41.12-5.6,59.6-15.6,9.19-5,17.6-11.39,26.23-17.36a49.59,49.59,0,0,0,7.52-6.68c7.79-8.16,23.65-7.91,31.68,0A132.83,132.83,0,0,0,223.46,49a155.29,155.29,0,0,0,44.67,11.53c7.09.8,14.35,1.4,21.1,3.47,13.26,4.07,23.18,17.77,23.64,31.61.87,26.33,1.48,52.67-.65,79-2.35,29.17-7.74,57.64-20.45,84.35-14.13,29.7-35.2,53.33-62.69,71.37a245.38,245.38,0,0,1-53,25.78,24.81,24.81,0,0,1-16.8.45c-32-11.06-61.22-26.91-85.13-51.32-23-23.43-37.29-51.55-45.1-83.14C22,193.54,20.37,164.52,21,135.34Zm119.19,80.19H140c0,4.69,0,9.38,0,14.06a28,28,0,0,0,3.89,14.35,27.15,27.15,0,0,0,28.31,12.37c11.38-2.52,20.29-11.76,21-22.81.66-10.68.58-21.4.62-32.11a7.47,7.47,0,0,1,3-6.46c15.17-12.58,20.1-34.67,12-52.63-8.41-18.63-28-29.85-47.76-26.77-19.27,3-32.45,13.88-37.84,32.59S123.4,183,138.14,196a6.46,6.46,0,0,1,1.91,4.15C140.28,205.26,140.14,210.4,140.14,215.53Z"
                    style={{
                        fill: 'url(#linear-gradient-95)',
                    }}
                />
                <path
                    d="M160.38,210.59c0-5.36-.22-10.72.06-16.06.3-5.65-2-9.37-6.76-12.38-9.28-5.84-13.36-14.42-11.8-25.37s10.92-20.18,21.55-21.28c12.33-1.28,23.33,5.2,27.3,16.08,4.12,11.26.62,23.6-9.33,29.79-5.9,3.67-8.05,8.18-7.8,14.91.37,10.36,0,20.74,0,31.12a21.45,21.45,0,0,1-.14,3c-.62,4.09-3.24,6.41-7,6.23s-6.15-2.86-6.21-7c-.1-6.36,0-12.72,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-95)',
                    }}
                />
                <path
                    d="M21.08,135.06c0-11.94.19-23.88,0-35.81-.38-20.49,14.64-37.32,36.88-38.46,20.89-1.07,41.08-5.6,59.55-15.6,9.18-5,17.58-11.39,26.2-17.36a49.52,49.52,0,0,0,7.51-6.68c7.79-8.16,23.64-7.91,31.66,0a132.65,132.65,0,0,0,40.57,27.52A155,155,0,0,0,268,60.2c7.08.8,14.33,1.4,21.08,3.47,13.25,4.07,23.16,17.77,23.62,31.61.87,26.33,1.48,52.67-.65,79-2.35,29.17-7.73,57.64-20.43,84.35C277.54,288.29,256.49,311.92,229,330a245.46,245.46,0,0,1-52.92,25.78,24.79,24.79,0,0,1-16.79.45c-32-11.06-61.17-26.91-85-51.32C51.34,281.44,37,253.32,29.2,221.73,22.16,193.26,20.5,164.24,21.08,135.06Zm119.09,80.19h-.12c0,4.69,0,9.38,0,14.06a28,28,0,0,0,3.89,14.35A27.12,27.12,0,0,0,172.23,256c11.36-2.52,20.27-11.76,21-22.81.66-10.68.58-21.4.62-32.11a7.46,7.46,0,0,1,3-6.46C212,182.07,216.88,160,208.77,142c-8.41-18.63-28-29.85-47.73-26.77-19.25,3-32.41,13.88-37.8,32.59s.2,34.89,14.92,47.87a6.47,6.47,0,0,1,1.92,4.15C140.3,205,140.17,210.12,140.17,215.25Z"
                    style={{
                        fill: 'url(#linear-gradient-97)',
                    }}
                />
                <path
                    d="M160.39,210.31c0-5.36-.22-10.72.06-16.06.29-5.65-2-9.37-6.76-12.38-9.27-5.84-13.34-14.42-11.79-25.37s10.92-20.18,21.53-21.28c12.32-1.28,23.31,5.2,27.28,16.08,4.12,11.26.62,23.6-9.32,29.79-5.9,3.67-8,8.18-7.8,14.91.38,10.36,0,20.74,0,31.12a20,20,0,0,1-.13,3c-.62,4.09-3.24,6.41-6.95,6.23s-6.16-2.86-6.22-7c-.09-6.36,0-12.72,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-97)',
                    }}
                />
                <path
                    d="M21.21,134.78c0-11.94.19-23.88,0-35.82C20.79,78.48,35.8,61.65,58,60.51c20.87-1.07,41-5.6,59.49-15.6,9.18-5,17.57-11.39,26.18-17.36a49.52,49.52,0,0,0,7.51-6.68c7.77-8.16,23.61-7.91,31.62,0a132.64,132.64,0,0,0,40.54,27.52A154.77,154.77,0,0,0,268,59.92c7.07.8,14.32,1.4,21.06,3.47,13.24,4.07,23.14,17.77,23.6,31.61.87,26.33,1.47,52.67-.65,79-2.35,29.17-7.73,57.64-20.42,84.35-14.1,29.7-35.13,53.33-62.57,71.37a244.86,244.86,0,0,1-52.87,25.78,24.73,24.73,0,0,1-16.77.45c-32-11.06-61.11-26.91-85-51.32-22.91-23.43-37.22-51.55-45-83.14C22.29,193,20.63,164,21.21,134.78Zm119,80.19h-.12c0,4.69,0,9.38,0,14.06A28,28,0,0,0,144,243.38a27.08,27.08,0,0,0,28.26,12.37c11.36-2.52,20.26-11.76,20.94-22.81.65-10.68.58-21.4.61-32.11a7.47,7.47,0,0,1,3-6.46c15.14-12.58,20.06-34.67,12-52.63-8.4-18.63-28-29.85-47.68-26.77-19.23,3-32.39,13.88-37.77,32.59s.2,34.89,14.91,47.87a6.46,6.46,0,0,1,1.91,4.15C140.33,204.7,140.19,209.84,140.19,215Z"
                    style={{
                        fill: 'url(#linear-gradient-99)',
                    }}
                />
                <path
                    d="M160.39,210c0-5.36-.21-10.72.06-16.06.3-5.65-2-9.37-6.75-12.38-9.26-5.84-13.33-14.42-11.78-25.37s10.91-20.18,21.51-21.28c12.32-1.28,23.29,5.2,27.26,16.08,4.11,11.26.61,23.6-9.32,29.79-5.88,3.67-8,8.18-7.79,14.91.38,10.36,0,20.74,0,31.12a21.45,21.45,0,0,1-.14,3c-.62,4.09-3.23,6.41-6.94,6.23s-6.15-2.86-6.21-7c-.1-6.36,0-12.72,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-99)',
                    }}
                />
                <path
                    d="M21.34,134.5c0-11.94.19-23.88,0-35.82C20.92,78.2,35.91,61.37,58.11,60.23c20.86-1.07,41-5.6,59.45-15.6,9.16-5,17.55-11.39,26.16-17.36a49.37,49.37,0,0,0,7.49-6.68c7.77-8.16,23.59-7.91,31.6,0a132.34,132.34,0,0,0,40.5,27.52,154.51,154.51,0,0,0,44.55,11.53c7.07.8,14.3,1.4,21,3.47,13.23,4.07,23.12,17.77,23.58,31.61.87,26.33,1.47,52.67-.65,79-2.35,29.17-7.72,57.64-20.4,84.35-14.09,29.7-35.1,53.33-62.51,71.37a244.79,244.79,0,0,1-52.83,25.78,24.67,24.67,0,0,1-16.75.45c-32-11.06-61.06-26.91-84.91-51.32-22.89-23.43-37.19-51.55-45-83.14C22.42,192.7,20.77,163.68,21.34,134.5Zm118.87,80.19h-.11c0,4.69,0,9.38,0,14.06A28.13,28.13,0,0,0,144,243.1a27.05,27.05,0,0,0,28.24,12.37c11.34-2.52,20.23-11.76,20.91-22.81.66-10.68.58-21.4.62-32.11a7.46,7.46,0,0,1,3-6.46c15.13-12.58,20-34.67,11.95-52.63-8.39-18.63-28-29.85-47.64-26.77-19.21,3-32.35,13.88-37.73,32.59s.2,34.89,14.9,47.87a6.51,6.51,0,0,1,1.91,4.15C140.35,204.42,140.21,209.56,140.21,214.69Z"
                    style={{
                        fill: 'url(#linear-gradient-101)',
                    }}
                />
                <path
                    d="M160.4,209.75c0-5.36-.22-10.72.06-16.06.29-5.65-2-9.37-6.75-12.38-9.25-5.85-13.32-14.42-11.77-25.37s10.9-20.18,21.5-21.28c12.3-1.28,23.27,5.2,27.23,16.08,4.11,11.26.61,23.6-9.31,29.79-5.88,3.67-8,8.18-7.78,14.91.37,10.36,0,20.74,0,31.12a21.45,21.45,0,0,1-.13,3c-.62,4.09-3.24,6.41-6.94,6.23s-6.14-2.86-6.2-7c-.1-6.36,0-12.72,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-101)',
                    }}
                />
                <path
                    d="M21.48,134.22c0-11.94.18-23.88,0-35.82C21.05,77.92,36,61.09,58.21,60c20.84-1.07,41-5.6,59.39-15.6,9.16-5,17.54-11.39,26.14-17.36a49.3,49.3,0,0,0,7.48-6.68c7.77-8.16,23.58-7.91,31.58,0a132.28,132.28,0,0,0,40.46,27.52,154.25,154.25,0,0,0,44.51,11.53c7.06.8,14.29,1.4,21,3.47C302,66.9,311.89,80.6,312.35,94.44c.86,26.33,1.47,52.67-.65,79-2.34,29.17-7.71,57.64-20.38,84.35-14.08,29.7-35.07,53.33-62.46,71.37a244.19,244.19,0,0,1-52.78,25.78,24.64,24.64,0,0,1-16.74.45c-31.93-11.06-61-26.91-84.82-51.32-22.87-23.43-37.16-51.55-44.95-83.14C22.55,192.42,20.9,163.4,21.48,134.22Zm118.76,80.19h-.12c0,4.69,0,9.38,0,14.06A28,28,0,0,0,144,242.82c5.48,9.39,17.53,14.73,28.2,12.37,11.34-2.52,20.22-11.76,20.9-22.81.66-10.68.58-21.4.61-32.11a7.48,7.48,0,0,1,3-6.46c15.12-12.58,20-34.67,11.95-52.63-8.39-18.63-27.95-29.85-47.6-26.77-19.2,3-32.33,13.88-37.7,32.58-5.43,18.87.2,34.9,14.88,47.88a6.46,6.46,0,0,1,1.91,4.15C140.38,204.14,140.24,209.28,140.24,214.41Z"
                    style={{
                        fill: 'url(#linear-gradient-103)',
                    }}
                />
                <path
                    d="M160.41,209.47c0-5.36-.22-10.72.06-16.06.29-5.65-2-9.37-6.74-12.38-9.25-5.85-13.31-14.42-11.76-25.37s10.89-20.18,21.47-21.28c12.29-1.28,23.25,5.2,27.21,16.08,4.1,11.26.61,23.6-9.3,29.79-5.88,3.67-8,8.18-7.78,14.91.38,10.36,0,20.74,0,31.12a21.45,21.45,0,0,1-.14,3c-.62,4.09-3.23,6.41-6.93,6.23s-6.14-2.86-6.2-7c-.09-6.36,0-12.72,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-103)',
                    }}
                />
                <path
                    d="M21.61,133.94c0-11.94.18-23.88,0-35.82-.39-20.48,14.58-37.31,36.74-38.45,20.82-1.07,40.93-5.6,59.34-15.6,9.15-5,17.52-11.39,26.11-17.36A49.3,49.3,0,0,0,151.24,20c7.76-8.16,23.55-7.91,31.54,0a132.15,132.15,0,0,0,40.43,27.52,154,154,0,0,0,44.47,11.53c7.05.8,14.28,1.4,21,3.47,13.21,4.07,23.08,17.77,23.53,31.61.87,26.32,1.48,52.67-.64,79-2.34,29.17-7.71,57.64-20.36,84.35-14.07,29.7-35,53.33-62.4,71.37a244.12,244.12,0,0,1-52.74,25.78,24.58,24.58,0,0,1-16.72.45C127.44,344,98.4,328.16,74.6,303.75c-22.85-23.43-37.12-51.55-44.9-83.14C22.68,192.14,21,163.12,21.61,133.94Zm118.65,80.19h-.11c0,4.69,0,9.38,0,14.06A28.13,28.13,0,0,0,144,242.54c5.47,9.39,17.52,14.73,28.18,12.37,11.32-2.52,20.2-11.76,20.88-22.81.65-10.68.58-21.4.61-32.11a7.46,7.46,0,0,1,3-6.46c15.1-12.58,20-34.67,11.93-52.63-8.38-18.63-27.92-29.85-47.55-26.77-19.19,3-32.3,13.88-37.68,32.58-5.41,18.87.21,34.9,14.88,47.88a6.42,6.42,0,0,1,1.9,4.15C140.4,203.86,140.26,209,140.26,214.13Z"
                    style={{
                        fill: 'url(#linear-gradient-105)',
                    }}
                />
                <path
                    d="M160.41,209.19c0-5.36-.22-10.72.06-16.06.3-5.65-2-9.37-6.73-12.38-9.23-5.85-13.3-14.42-11.75-25.37s10.88-20.18,21.45-21.28c12.28-1.28,23.23,5.2,27.19,16.08,4.1,11.26.61,23.6-9.3,29.79-5.87,3.67-8,8.18-7.76,14.91.37,10.36,0,20.74,0,31.12a20,20,0,0,1-.14,3c-.61,4.09-3.22,6.41-6.92,6.23s-6.13-2.86-6.19-7c-.1-6.36,0-12.72,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-105)',
                    }}
                />
                <path
                    d="M21.61,133.94c0-11.94.18-23.88,0-35.82-.39-20.48,14.58-37.31,36.74-38.45,20.82-1.07,40.93-5.6,59.34-15.6,9.15-5,17.52-11.39,26.11-17.36A49.3,49.3,0,0,0,151.24,20c7.76-8.16,23.55-7.91,31.54,0a132.15,132.15,0,0,0,40.43,27.52,154,154,0,0,0,44.47,11.53c7.05.8,14.28,1.4,21,3.47,13.21,4.07,23.08,17.77,23.53,31.61.87,26.32,1.48,52.67-.64,79-2.34,29.17-7.71,57.64-20.36,84.35-14.07,29.7-35,53.33-62.4,71.37a244.12,244.12,0,0,1-52.74,25.78,24.58,24.58,0,0,1-16.72.45C127.44,344,98.4,328.16,74.6,303.75c-22.85-23.43-37.12-51.55-44.9-83.14C22.68,192.14,21,163.12,21.61,133.94Zm118.65,80.19h-.11c0,4.69,0,9.38,0,14.06A28.13,28.13,0,0,0,144,242.54c5.47,9.39,17.52,14.73,28.18,12.37,11.32-2.52,20.2-11.76,20.88-22.81.65-10.68.58-21.4.61-32.11a7.46,7.46,0,0,1,3-6.46c15.1-12.58,20-34.67,11.93-52.63-8.38-18.63-27.92-29.85-47.55-26.77-19.19,3-32.3,13.88-37.68,32.58-5.41,18.87.21,34.9,14.88,47.88a6.42,6.42,0,0,1,1.9,4.15C140.4,203.86,140.26,209,140.26,214.13Z"
                    style={{
                        fill: 'url(#linear-gradient-107)',
                    }}
                />
                <path
                    d="M160.41,209.19c0-5.36-.22-10.72.06-16.06.3-5.65-2-9.37-6.73-12.38-9.23-5.85-13.3-14.42-11.75-25.37s10.88-20.18,21.45-21.28c12.28-1.28,23.23,5.2,27.19,16.08,4.1,11.26.61,23.6-9.3,29.79-5.87,3.67-8,8.18-7.76,14.91.37,10.36,0,20.74,0,31.12a20,20,0,0,1-.14,3c-.61,4.09-3.22,6.41-6.92,6.23s-6.13-2.86-6.19-7c-.1-6.36,0-12.72,0-19.07Z"
                    style={{
                        fill: 'url(#linear-gradient-107)',
                    }}
                />
                <path
                    d="M21.61,137.76c0-11.7.18-23.4,0-35.09C21.18,82.59,36.15,66.1,58.31,65c20.82-1.05,40.93-5.49,59.34-15.29,9.15-4.87,17.52-11.16,26.11-17a49,49,0,0,0,7.48-6.55c7.76-8,23.55-7.75,31.54,0a132.12,132.12,0,0,0,40.43,27,156.56,156.56,0,0,0,44.47,11.3c7.05.79,14.28,1.38,21,3.41,13.21,4,23.08,17.41,23.53,31,.87,25.8,1.48,51.62-.64,77.38-2.34,28.59-7.71,56.49-20.36,82.67-14.07,29.1-35,52.26-62.4,69.94A246.46,246.46,0,0,1,176.07,354a25,25,0,0,1-16.72.44C127.44,343.64,98.4,328.1,74.6,304.18c-22.85-23-37.12-50.52-44.9-81.48C22.68,194.8,21,166.36,21.61,137.76Zm118.65,78.59h-.11c0,4.6,0,9.19,0,13.78A27.16,27.16,0,0,0,144,244.19c5.47,9.21,17.52,14.44,28.18,12.12,11.32-2.46,20.2-11.52,20.88-22.35.65-10.46.58-21,.61-31.46a7.26,7.26,0,0,1,3-6.34,43.75,43.75,0,0,0,11.93-51.58c-8.38-18.25-27.92-29.25-47.55-26.23-19.19,3-32.3,13.61-37.68,31.93-5.41,18.49.21,34.2,14.88,46.92a6.27,6.27,0,0,1,1.9,4.07C140.4,206.29,140.26,211.32,140.26,216.35Z"
                    style={{
                        fill: 'url(#linear-gradient-109)',
                    }}
                />
                <path
                    d="M160.41,211.51c0-5.25-.22-10.51.06-15.74.3-5.53-2-9.18-6.73-12.13-9.23-5.73-13.3-14.13-11.75-24.87s10.88-19.77,21.45-20.85c12.28-1.25,23.23,5.09,27.19,15.76,4.1,11,.61,23.13-9.3,29.19-5.87,3.6-8,8-7.76,14.62.37,10.15,0,20.32,0,30.49a19.18,19.18,0,0,1-.14,2.94c-.61,4-3.22,6.28-6.92,6.11s-6.13-2.81-6.19-6.83c-.1-6.23,0-12.46,0-18.69Z"
                    style={{
                        fill: 'url(#linear-gradient-109)',
                    }}
                />
            </g>
        </g>
    </svg>,
    'CharacterRentedIcon',
)
