import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_URL } from 'config'
import { APIEndpoint } from 'shared/types'
import { signToken } from 'shared/utils'

export const login = createAsyncThunk('auth/login', async (address: string, { rejectWithValue }) => {
    try {
        if (!address) {
            throw new Error('Address is required')
        }

        const token = await signToken(address)

        if (!token) {
            throw new Error('Token is required')
        }

        const { data } = await axios.post(
            API_URL + APIEndpoint.LOGIN,
            {
                address,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        )
        return data.token
    } catch (error: any) {
        return rejectWithValue(error.response.data)
    }
})
