import React from 'react'
import { createSvgIcon } from '@mui/material'

export const MintIcon = createSvgIcon(
    <svg width="73" height="21" viewBox="0 0 73 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.440002 21.0001V0.39209H6.296L11.416 10.8561L16.504 0.39209H22.328V21.0001H16.312V10.6961L13.016 17.4801H9.784L6.456 10.6961V21.0001H0.440002Z" />
        <path d="M25.2213 21.0001V0.39209H31.2373V21.0001H25.2213Z" />
        <path d="M34.1275 21.0001V0.39209H39.2475L47.0235 11.3041V0.39209H53.0395V21.0001H47.8875L40.1435 10.1201V21.0001H34.1275Z" />
        <path d="M60.4208 21.0001V4.87209H54.6288V0.39209H72.2288V4.87209H66.4368V21.0001H60.4208Z" />
    </svg>,
    'MintIcon',
)
