import { WheelPrize, WheelPrizeType } from 'shared/types'

const freeWheelPrizes = new Map<number, WheelPrize>()

freeWheelPrizes.set(1, { type: WheelPrizeType.XP, amount: 25, degree: 0 })
freeWheelPrizes.set(2, { type: WheelPrizeType.XP, amount: 50, degree: 270 })
freeWheelPrizes.set(3, { type: WheelPrizeType.XP, amount: 75, degree: 180 })
freeWheelPrizes.set(4, { type: WheelPrizeType.MUF, amount: 3, degree: 315 })
freeWheelPrizes.set(5, { type: WheelPrizeType.MUF, amount: 7, degree: 225 })
freeWheelPrizes.set(6, { type: WheelPrizeType.MUF, amount: 12, degree: 135 })
freeWheelPrizes.set(7, { type: WheelPrizeType.NFT, amount: 1, degree: 45 })
freeWheelPrizes.set(8, { type: WheelPrizeType.UNLUCKY, amount: 0, degree: 90 })

const premiumWheelPrizes = new Map<number, WheelPrize>()

premiumWheelPrizes.set(1, { type: WheelPrizeType.XP, amount: 250, degree: 0 })
premiumWheelPrizes.set(2, { type: WheelPrizeType.XP, amount: 500, degree: 300 })
premiumWheelPrizes.set(3, { type: WheelPrizeType.XP, amount: 750, degree: 240 })
premiumWheelPrizes.set(4, { type: WheelPrizeType.MUF, amount: 200, degree: 330 })
premiumWheelPrizes.set(5, { type: WheelPrizeType.MUF, amount: 250, degree: 270 })
premiumWheelPrizes.set(6, { type: WheelPrizeType.MUF, amount: 350, degree: 210 })
premiumWheelPrizes.set(7, { type: WheelPrizeType.MUF, amount: 500, degree: 150 })
premiumWheelPrizes.set(8, { type: WheelPrizeType.EGLD, amount: 0.1, degree: 180 })
premiumWheelPrizes.set(9, { type: WheelPrizeType.EGLD, amount: 0.15, degree: 120 })
premiumWheelPrizes.set(10, { type: WheelPrizeType.EGLD, amount: 0.2, degree: 30 })
premiumWheelPrizes.set(11, { type: WheelPrizeType.EGLD, amount: 0.3, degree: 90 })
premiumWheelPrizes.set(12, { type: WheelPrizeType.NFT, amount: 1, degree: 60 })

export { freeWheelPrizes, premiumWheelPrizes }
