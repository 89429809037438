import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { APIEndpoint } from 'shared/types'
import { API_URL } from '../../../config'
import { RootState } from 'stores/store'

export const getMe = createAsyncThunk('user/me', async (arg, { rejectWithValue, getState }) => {
    const token = (getState() as RootState).auth.token
    if (!token) {
        return rejectWithValue('Token is not found')
    }
    try {
        const queryParams = new URLSearchParams({
            withProfile: 'true',
            withInventory: 'true',
            withSelectedCharacter: 'true',
            withCharacters: 'true',
            withFights: 'true',
            withFight: 'true',
            withQuests: 'true',
            withNotifications: 'true',
            withRents: 'true',
        })
        const url = new URL(`${API_URL + APIEndpoint.ME}?${queryParams.toString()}`)
        const { data } = await axios.get(url.toString(), {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        return data
    } catch (err: any) {
        return rejectWithValue(err.response.data)
    }
})
