import React from 'react'
import { createSvgIcon } from '@mui/material'

export const OuroTokenIcon = createSvgIcon(
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 200 200"
        xmlSpace="preserve"
    >
        <style type="text/css">
            .st0{'fill:#534741'}
            .st1{'fill:#9B2F1E'}
        </style>
        <g>
            <path
                className="st0"
                d="M98.187,199.974c-14.736-0.463-28.453-3.351-40.619-8.558c-8.59-3.676-16.455-8.508-23.432-14.402
		c-4.77-4.032-9.247-8.701-13.135-13.696C9.072,147.997,1.826,128.737,0.288,108.253C0.05,105.121,0,103.714,0,100.001
		s0.05-5.12,0.288-8.251C1.826,71.272,9.084,51.975,21,36.684c2.282-2.932,4.458-5.413,7.133-8.126
		c10.266-10.414,22.72-18.084,37.199-22.916c8.915-2.976,18.725-4.807,29.04-5.438c2.663-0.156,5.295-0.231,6.815-0.194
		c21.325,0.594,40.094,6.089,55.767,16.34c8.378,5.476,15.842,12.365,22.044,20.335c11.929,15.321,19.175,34.581,20.713,55.066
		c0.238,3.132,0.288,4.538,0.288,8.251s-0.05,5.12-0.288,8.251c-1.432,19.097-7.852,37.219-18.424,52.003
		c-5.245,7.345-11.616,13.952-18.668,19.378c-6.708,5.157-14.204,9.414-22.263,12.646c-10.603,4.251-22.226,6.764-34.761,7.52
		C102.501,199.987,100.2,200.037,98.187,199.974L98.187,199.974z M104.751,189.992c8.559-0.525,16.174-1.819,23.57-4.007
		c12.735-3.769,23.864-9.958,33.354-18.566c1.094-0.988,3.889-3.776,5.027-5.001c5.445-5.901,9.934-12.433,13.629-19.847
		c5.877-11.789,9.14-24.654,9.759-38.444c0.081-1.813,0.081-6.439,0-8.251c-0.556-12.402-3.214-23.879-8.084-34.912
		c-0.781-1.769-2.551-5.32-3.482-6.989c-3.376-6.051-7.29-11.477-11.854-16.422c-1.144-1.238-3.939-4.019-5.039-5.013
		c-5.052-4.57-10.516-8.426-16.53-11.671c-1.294-0.694-4.514-2.269-5.839-2.85c-11.06-4.876-22.97-7.508-36.574-8.101
		c-5.689-0.25-14.048,0.431-20.944,1.7c-16.649,3.069-30.947,9.889-42.857,20.46c-1.45,1.288-5.158,4.995-6.446,6.445
		c-5.195,5.851-9.265,11.883-12.773,18.909c-5.189,10.414-8.359,21.735-9.447,33.724c-0.3,3.307-0.344,4.432-0.344,8.845
		c0,4.419,0.044,5.52,0.35,8.876c1.844,20.503,9.966,39.332,23.095,53.572c1.019,1.106,3.776,3.851,4.845,4.832
		c12.21,11.158,27.446,18.384,45.045,21.36c4.514,0.763,8.615,1.181,14.286,1.463C98.543,190.154,103.264,190.079,104.751,189.992
		L104.751,189.992z"
            />
        </g>
        <g>
            <path
                className="st1"
                d="M98.687,178.577c-5.833-0.506-11.266-2.982-14.973-6.826c-3.145-3.263-4.939-7.157-5.414-11.771
		c-0.213-2.088,0-5.32,0.45-6.72c0.4-1.238,0.456-1.65,0.463-3.188c0-1.25-0.019-1.481-0.144-2.057
		c-0.594-2.632-2.013-4.394-4.514-5.595c-1.219-0.581-2.438-0.863-3.714-0.844c-0.681,0.006-0.681,0.006-1.188-0.356
		c-1.282-0.919-2.838-2.194-4.32-3.532c-2.188-1.982-5.914-5.951-5.746-6.12c0.106-0.106,1.113-0.688,1.776-1.025
		c1.944-0.994,4.176-1.694,6.44-2.019c0.619-0.088,1.182-0.113,2.688-0.106c2.044,0,2.713,0.069,4.439,0.438
		c8.065,1.719,14.93,7.933,17.093,15.453c0.556,1.925,0.75,3.494,0.706,5.638c-0.031,1.594-0.113,2.288-0.394,3.532
		c-0.088,0.381-0.206,0.925-0.275,1.219c-0.6,2.7-0.463,5.157,0.375,6.857c1.032,2.094,2.926,3.238,6.189,3.751
		c1.013,0.156,4.464,0.156,5.877,0c3.92-0.444,7.121-1.125,14.873-3.169c1.05-0.275,1.913-0.488,1.932-0.475
		c0.038,0.044-0.225,1.169-0.5,2.1c-1.651,5.651-5.139,9.995-10.053,12.496c-2.319,1.188-4.626,1.875-7.409,2.232
		C102.445,178.602,99.619,178.652,98.687,178.577z M97.631,172.307c0.275-0.181,0.494-0.575,0.494-0.875
		c0-0.194-0.044-0.288-0.206-0.45c-0.288-0.288-0.638-0.338-1.019-0.156c-0.638,0.313-0.706,0.994-0.15,1.463
		C96.999,172.495,97.337,172.507,97.631,172.307L97.631,172.307z M103.157,172.226c0.363-0.169,0.494-0.631,0.331-1.163
		c-0.119-0.394-0.538-0.525-1.119-0.356c-0.188,0.062-0.256,0.125-0.331,0.319c-0.131,0.319-0.125,0.794,0,0.981
		C102.251,172.307,102.776,172.407,103.157,172.226z M105.277,171.882c0.131-0.1,0.163-0.181,0.163-0.394
		c0-0.763-1.007-0.813-1.107-0.056c-0.031,0.219-0.012,0.281,0.125,0.419C104.664,172.057,105.033,172.07,105.277,171.882z
		 M100.406,171.263c0.369-0.181,0.494-0.431,0.456-0.95c-0.025-0.456-0.206-0.769-0.575-0.994c-0.444-0.269-1.2-0.069-1.432,0.381
		c-0.238,0.456-0.031,1.256,0.394,1.55C99.519,171.432,100.044,171.438,100.406,171.263L100.406,171.263z M94.217,170.607
		c0.194-0.188,0.206-0.481,0.031-0.763c-0.106-0.181-0.156-0.206-0.394-0.206c-0.313,0-0.431,0.081-0.544,0.356
		c-0.088,0.225-0.044,0.494,0.106,0.663C93.573,170.826,94.029,170.801,94.217,170.607z M105.964,170.263
		c0.431-0.263,0.613-1.025,0.363-1.5c-0.244-0.469-1.044-0.644-1.626-0.369c-0.794,0.388-0.713,1.544,0.144,1.944
		C105.158,170.488,105.664,170.457,105.964,170.263L105.964,170.263z M96.243,169.776c0.319-0.231,0.444-0.5,0.444-0.931
		c0-0.656-0.275-1.044-0.844-1.2c-0.763-0.2-1.369,0.425-1.263,1.313c0.081,0.663,0.519,1.025,1.163,0.969
		C95.936,169.907,96.155,169.844,96.243,169.776L96.243,169.776z M109.253,169.526c0.556-0.556,0.419-1.269-0.281-1.444
		c-0.569-0.144-1.032,0.213-1.032,0.788c0,0.313,0.069,0.469,0.3,0.725c0.131,0.138,0.213,0.169,0.469,0.169
		C108.984,169.763,109.047,169.738,109.253,169.526z M102.57,168.944c0.456-0.156,0.731-0.606,0.644-1.063
		c-0.063-0.306-0.313-0.781-0.475-0.894c-0.181-0.131-0.813-0.131-1.188,0c-0.25,0.081-0.306,0.131-0.419,0.4
		c-0.175,0.4-0.175,0.9-0.006,1.175C101.357,168.944,102.038,169.119,102.57,168.944L102.57,168.944z M92.923,168.763
		c0.263-0.163,0.444-0.556,0.45-0.956c0-0.25-0.031-0.331-0.181-0.488c-0.225-0.225-0.531-0.238-1.025-0.056
		c-0.375,0.144-0.481,0.294-0.481,0.681c0,0.425,0.488,0.931,0.907,0.944C92.66,168.888,92.81,168.832,92.923,168.763z
		 M99.012,168.25c0.288-0.219,0.425-0.525,0.425-0.938c0-0.644-0.406-0.981-1.175-0.988c-0.725,0-1.075,0.3-1.075,0.919
		c0,0.394,0.081,0.619,0.3,0.856c0.256,0.269,0.494,0.356,0.925,0.331C98.712,168.419,98.843,168.375,99.012,168.25L99.012,168.25z
		 M105.564,167.581c0.669-0.356,0.669-1.657,0-2.013c-0.531-0.288-1.263-0.075-1.507,0.425c-0.175,0.356-0.15,0.988,0.044,1.25
		C104.439,167.694,105.077,167.844,105.564,167.581z M95.492,167.088c0.538-0.288,0.807-1.119,0.519-1.588
		c-0.2-0.319-0.594-0.519-1.044-0.519c-0.675,0-1.094,0.388-1.094,1.013c0,0.188,0.038,0.413,0.088,0.506
		c0.119,0.219,0.463,0.55,0.663,0.631C94.873,167.231,95.261,167.213,95.492,167.088L95.492,167.088z M108.709,167.131
		c0.269-0.075,0.631-0.369,0.769-0.625c0.125-0.25,0.113-0.738-0.038-0.975c-0.263-0.431-1.057-0.638-1.582-0.406
		c-0.369,0.163-0.544,0.438-0.544,0.844c0,0.513,0.263,0.969,0.656,1.144C108.203,167.213,108.396,167.219,108.709,167.131
		L108.709,167.131z M112.085,166.45c0.306-0.244,0.381-0.919,0.138-1.288c-0.138-0.213-0.5-0.356-0.769-0.313
		c-0.45,0.069-0.706,0.369-0.719,0.831c-0.013,0.375,0.113,0.688,0.325,0.8C111.31,166.619,111.891,166.6,112.085,166.45
		L112.085,166.45z M114.192,165.637c0.169-0.169,0.25-0.394,0.25-0.688c0-0.194-0.031-0.269-0.156-0.35
		c-0.131-0.088-0.2-0.088-0.463-0.025c-0.263,0.063-0.319,0.1-0.413,0.306c-0.1,0.225-0.1,0.25,0,0.45
		c0.163,0.325,0.281,0.431,0.481,0.431C113.986,165.762,114.123,165.706,114.192,165.637L114.192,165.637z M93.392,165.569
		c0.369-0.281,0.35-0.963-0.031-1.319c-0.156-0.144-0.244-0.175-0.5-0.175c-0.169,0-0.363,0.031-0.438,0.069
		c-0.281,0.15-0.463,0.938-0.288,1.263c0.119,0.231,0.256,0.288,0.681,0.294C93.142,165.7,93.267,165.675,93.392,165.569
		L93.392,165.569z M91.329,165.069c0.194-0.106,0.231-0.175,0.231-0.481c0-0.544-0.556-0.775-0.944-0.394
		c-0.1,0.1-0.181,0.25-0.181,0.331C90.441,164.969,90.941,165.269,91.329,165.069z M91.366,163.231
		c0.331-0.169,0.506-0.444,0.506-0.788c0-0.275-0.025-0.331-0.281-0.594c-0.269-0.288-0.281-0.294-0.6-0.263
		c-0.794,0.081-1.094,1.063-0.481,1.582C90.722,163.35,91.091,163.375,91.366,163.231z M91.178,160.13
		c0.144-0.269,0.15-0.313,0.075-0.544c-0.081-0.238-0.1-0.256-0.381-0.294c-0.475-0.063-0.75,0.163-0.75,0.619
		c0,0.381,0.188,0.55,0.594,0.525C91.016,160.418,91.035,160.405,91.178,160.13z M70.266,139.564c0.2-0.094,0.294-0.356,0.225-0.638
		c-0.063-0.288-0.088-0.325-0.313-0.419c-0.156-0.062-0.238-0.062-0.444,0c-0.2,0.069-0.256,0.125-0.313,0.319
		C69.247,139.408,69.716,139.814,70.266,139.564L70.266,139.564z M69.009,138.27c0.238-0.219,0.356-0.506,0.356-0.856
		c0-0.238-0.031-0.325-0.181-0.475c-0.163-0.163-0.225-0.181-0.55-0.175c-0.556,0.012-0.656,0.056-0.819,0.369
		c-0.175,0.338-0.15,0.594,0.081,0.95C68.19,138.527,68.653,138.608,69.009,138.27L69.009,138.27z M71.122,137.389
		c0.444-0.444,0.006-1.081-0.6-0.881c-0.4,0.131-0.538,0.569-0.275,0.9c0.106,0.131,0.181,0.163,0.406,0.163
		C70.885,137.57,70.972,137.533,71.122,137.389z M67.577,136.508c0.325-0.163,0.488-0.381,0.519-0.713
		c0.056-0.55-0.344-0.931-0.975-0.931c-0.631,0-0.944,0.269-0.944,0.8C66.177,136.426,66.883,136.851,67.577,136.508L67.577,136.508
		z M71.497,135.614c0.263-0.175,0.369-0.375,0.369-0.719c0-0.6-0.325-0.931-0.863-0.869c-0.494,0.056-0.888,0.481-0.888,0.963
		c0,0.15,0.031,0.331,0.069,0.4C70.378,135.757,71.103,135.882,71.497,135.614z M73.517,135.564c0.338-0.4,0.106-0.869-0.419-0.869
		c-0.488,0-0.775,0.369-0.575,0.75c0.125,0.238,0.256,0.306,0.575,0.313C73.31,135.757,73.385,135.726,73.517,135.564z
		 M65.708,134.695c0.344-0.213,0.556-0.581,0.581-1.031c0.031-0.481-0.1-0.713-0.488-0.869c-0.832-0.331-1.813,0.163-1.813,0.919
		c0,0.438,0.406,0.988,0.825,1.119C65.039,134.901,65.483,134.832,65.708,134.695z M77.555,134.72c0.256-0.156,0.4-0.444,0.4-0.806
		c0-0.444-0.15-0.7-0.469-0.781c-0.438-0.119-0.838,0.031-1.057,0.394c-0.244,0.394-0.088,0.988,0.3,1.2
		C76.955,134.845,77.343,134.845,77.555,134.72z M83.839,134.657c0.15-0.063,0.281-0.363,0.281-0.65
		c0-0.269-0.206-0.563-0.394-0.563c-0.481,0-0.825,0.531-0.606,0.938C83.239,134.607,83.601,134.745,83.839,134.657z M68.79,134.232
		c0.388-0.2,0.65-0.663,0.663-1.144c0.006-0.575-0.375-0.888-1.144-0.944c-0.519-0.038-0.763,0.075-0.944,0.425
		c-0.144,0.288-0.163,0.781-0.044,1.081c0.05,0.113,0.206,0.306,0.35,0.438c0.231,0.206,0.288,0.231,0.606,0.231
		C68.465,134.32,68.696,134.282,68.79,134.232L68.79,134.232z M74.836,134.232c0.606-0.256,0.625-1.275,0.031-1.588
		c-0.213-0.113-0.775-0.063-0.938,0.081c-0.069,0.063-0.181,0.263-0.25,0.456c-0.131,0.338-0.131,0.338,0.006,0.619
		C73.911,134.27,74.348,134.432,74.836,134.232z M79.762,134.195c0.213-0.169,0.263-0.425,0.131-0.706
		c-0.113-0.238-0.225-0.294-0.606-0.294c-0.331,0-0.544,0.219-0.544,0.563c0,0.363,0.213,0.563,0.581,0.563
		C79.506,134.32,79.656,134.276,79.762,134.195L79.762,134.195z M81.813,133.738c0.325-0.219,0.494-0.513,0.494-0.869
		c0-0.375-0.163-0.569-0.569-0.675c-0.225-0.063-0.331-0.056-0.594,0.025c-0.313,0.1-0.325,0.113-0.463,0.475
		c-0.119,0.319-0.125,0.4-0.063,0.588c0.038,0.125,0.156,0.313,0.263,0.413C81.119,133.932,81.494,133.951,81.813,133.738z
		 M72.041,133.576c0.832-0.431,0.944-1.431,0.206-1.925c-0.575-0.388-1.394-0.169-1.694,0.45c-0.231,0.475-0.1,1.006,0.338,1.394
		c0.2,0.175,0.275,0.2,0.575,0.2C71.679,133.695,71.897,133.651,72.041,133.576z M63.426,132.682
		c0.713-0.363,0.769-1.394,0.106-1.888c-0.256-0.188-0.882-0.213-1.25-0.05c-0.394,0.175-0.544,0.431-0.544,0.938
		c0,0.419,0.088,0.644,0.325,0.869C62.388,132.857,62.97,132.907,63.426,132.682z M79.462,132.663
		c0.475-0.194,0.469-1.025-0.006-1.4c-0.2-0.163-0.631-0.175-0.907-0.031c-0.213,0.106-0.425,0.513-0.431,0.794
		C78.112,132.601,78.818,132.938,79.462,132.663z M76.461,131.888c0.338-0.156,0.506-0.356,0.6-0.694
		c0.15-0.575-0.069-1.044-0.606-1.294c-0.594-0.269-1.25-0.119-1.569,0.356c-0.169,0.244-0.194,0.781-0.056,1.05
		C75.136,131.888,75.88,132.157,76.461,131.888z M66.621,131.8c0.294-0.194,0.431-0.494,0.431-0.95c0-0.494-0.144-0.769-0.5-0.95
		c-0.531-0.275-1.238-0.113-1.544,0.35c-0.106,0.156-0.144,0.306-0.144,0.544c0,0.763,0.381,1.144,1.163,1.15
		C66.321,131.944,66.458,131.913,66.621,131.8z M69.553,131.319c0.494-0.138,0.838-0.8,0.725-1.394c-0.131-0.694-1.013-1-1.726-0.6
		c-0.325,0.181-0.438,0.406-0.438,0.863C68.115,131.057,68.74,131.544,69.553,131.319z M73.198,131.094
		c0.456-0.231,0.669-0.55,0.669-0.994c0-0.35-0.113-0.575-0.4-0.831c-0.469-0.413-1.1-0.388-1.544,0.056
		c-0.519,0.519-0.444,1.381,0.144,1.744C72.323,131.225,72.91,131.238,73.198,131.094L73.198,131.094z"
            />
            <path
                className="st1"
                d="M97.968,162.881c-1.194-0.175-2.319-0.769-2.97-1.575c-1.194-1.475-1.494-3.626-0.869-6.189
		c0.1-0.419,0.238-1.094,0.306-1.5c0.069-0.413,0.131-0.756,0.144-0.769c0.013-0.013,0.325,0.006,0.706,0.038
		c0.375,0.031,1.313,0.088,2.088,0.125c11.535,0.519,22.926-2.463,31.697-8.301c0.706-0.475,1.332-0.863,1.394-0.875
		c0.319-0.063,1.613,0.513,2.007,0.881c0.144,0.138,0.438,0.369,0.656,0.525c0.219,0.15,1.263,0.95,2.326,1.775
		c2.057,1.6,3.138,2.369,3.514,2.494c0.238,0.075,0.238,0.075-0.313,0.506c-1.619,1.269-4.02,2.913-5.896,4.032
		c-8.478,5.082-17.899,8.014-27.852,8.683c-1.169,0.075-6.114,0.238-6.471,0.213C98.387,162.937,98.174,162.912,97.968,162.881
		L97.968,162.881z M106.314,158.642c6.108-0.663,12.616-2.325,17.924-4.576c1.025-0.438,2.876-1.338,3.651-1.782
		c0.919-0.519,2.257-1.431,2.901-1.975c0.882-0.744,1.844-1.882,1.707-2.025c-0.025-0.019-1.457,0.581-3.188,1.338
		c-1.732,0.756-3.832,1.663-4.676,2.013c-7.896,3.294-13.485,4.838-19.631,5.42c-1.4,0.131-5.583,0.15-6.802,0.031
		c-0.763-0.075-0.763-0.075-1.457,0.269c-0.381,0.194-0.681,0.381-0.669,0.413c0.038,0.119,0.738,0.375,1.582,0.588
		c0.694,0.175,1.607,0.325,2.688,0.431c0.138,0.012,1.263,0.019,2.501,0.006C104.608,158.78,105.358,158.742,106.314,158.642z"
            />
            <path
                className="st1"
                d="M75.305,158.174c-4.295-1.8-8.121-3.938-11.972-6.689c-3.37-2.4-6.465-5.082-9.553-8.276
		c-2.151-2.219-4.539-5.076-5.589-6.67c-0.294-0.444-0.975-1.4-1.507-2.119c-1.688-2.275-2.369-3.369-2.807-4.532
		c-0.331-0.881-0.431-2.032-0.238-2.788c0.238-0.944,1.125-2.263,1.988-2.969c0.556-0.463,1.15-0.719,3.264-1.438
		c1.032-0.35,1.919-0.663,1.982-0.694c0.081-0.044,0.194,0.138,0.7,1.169c1.344,2.738,2.907,5.295,4.789,7.808
		c1.776,2.382,3.057,3.844,5.402,6.195c1.844,1.844,3.163,3.038,4.833,4.376c0.513,0.406,0.938,0.763,0.944,0.794
		c0.069,0.15,2.395,1.769,3.889,2.7c1.138,0.713,2.92,1.725,4.295,2.438c1.2,0.625,1.2,0.625,1.313,1.094
		c0.081,0.344,0.113,0.719,0.106,1.375c0,1.025-0.075,1.575-0.525,3.751c-0.169,0.806-0.35,1.782-0.406,2.157
		c-0.113,0.719-0.15,2.025-0.075,2.425c0.031,0.125,0.025,0.231,0,0.225C76.111,158.505,75.736,158.355,75.305,158.174
		L75.305,158.174z M74.492,152.535c0-0.019-0.163-0.369-0.356-0.781c-0.356-0.756-0.356-0.756-0.731-0.944
		c-0.744-0.375-2.645-1.45-3.539-2.007c-6.383-3.957-12.204-9.27-16.686-15.234c-1.494-1.988-3.088-4.445-4.058-6.251
		c-0.319-0.594-0.319-0.594-1.088-0.925c-0.425-0.181-0.807-0.325-0.85-0.325c-0.094,0-0.081,0.069,0.219,1.063
		c0.581,1.932,1.688,4.051,3.195,6.12c1.307,1.794,2.757,3.438,7.44,8.476c0.256,0.275,1.719,1.588,4.32,3.882
		c4.739,4.163,8.002,6.114,11.441,6.839C74.398,152.579,74.492,152.591,74.492,152.535L74.492,152.535z"
            />
            <path
                className="st1"
                d="M48.078,150.26c-0.275-0.031-0.731-0.088-1.013-0.131c-0.606-0.081-0.563,0.038-0.425-1.15
		c0.294-2.494,0.832-4.851,1.657-7.251c0.181-0.513,0.344-0.956,0.369-0.981c0.019-0.025,0.369,0.338,0.763,0.813
		c1.894,2.232,3.37,3.763,5.783,5.989l1.632,1.507l-0.756,0.256c-0.988,0.338-2.363,0.669-3.414,0.831
		C51.722,150.291,48.984,150.36,48.078,150.26L48.078,150.26z"
            />
            <path
                className="st1"
                d="M147.89,150.222c-1.926-0.225-4.064-0.813-5.858-1.6c-0.325-0.138-0.581-0.288-0.569-0.325
		c0.013-0.038,0.213-0.425,0.444-0.863c1.119-2.094,2.219-4.782,3.02-7.395c0.35-1.144,1.025-3.719,1.025-3.919
		c0-0.163,0.094-0.144,0.688,0.169c0.982,0.5,2.251,0.844,3.157,0.844c0.388,0,0.388,0,0.431,1.206
		c0.119,3.207-0.325,7.157-1.188,10.639c-0.319,1.281-0.338,1.344-0.388,1.338C148.628,150.31,148.284,150.272,147.89,150.222
		L147.89,150.222z"
            />
            <path
                className="st1"
                d="M150.372,149.685c0.875-3.676,1.25-6.626,1.319-10.489c0.044-2.15,0.044-2.15,0.325-2.232
		c0.156-0.05,0.381-0.094,0.5-0.106c0.119-0.019,0.475-0.125,0.794-0.244l0.569-0.213l0.75,1.131
		c1.588,2.407,2.638,4.532,3.514,7.076c0.338,0.988,0.863,2.919,0.969,3.563c0.038,0.256,0.038,0.256-0.488,0.481
		c-2.138,0.894-4.883,1.482-7.684,1.638l-0.725,0.044L150.372,149.685L150.372,149.685z"
            />
            <path
                className="st1"
                d="M108.965,149.603c0.538-1,1.244-2.213,1.894-3.251c0.957-1.507,0.706-1.388,2.001-0.988
		c1.988,0.606,4.952,1.294,6.583,1.525c0.294,0.038,0.569,0.094,0.619,0.119c0.056,0.031-0.263,0.181-0.875,0.425
		c-2.738,1.069-5.283,1.832-7.965,2.388c-0.9,0.188-2.351,0.438-2.52,0.438C108.646,150.26,108.746,150.022,108.965,149.603
		L108.965,149.603z"
            />
            <path
                className="st1"
                d="M44.201,149.572c-2.963-0.694-5.852-2.088-8.146-3.919c-0.675-0.538-0.788-0.269,0.888-2.069
		c1.444-1.557,3.464-3.426,5.352-4.951c0.669-0.544,3.02-2.319,3.038-2.294c0.069,0.088,1.969,2.688,2.069,2.832
		c0.138,0.194,0.138,0.194-0.081,0.75c-0.975,2.475-1.882,6.389-2.119,9.12c-0.05,0.588-0.081,0.719-0.175,0.713
		C44.989,149.753,44.614,149.672,44.201,149.572L44.201,149.572z"
            />
            <path
                className="st1"
                d="M107.34,148.447c0.131-4.057,1.632-8.026,4.326-11.408c0.3-0.375,1.032-1.169,1.613-1.75
		c1.469-1.463,2.695-2.413,4.445-3.463c2.845-1.7,5.964-2.782,9.378-3.238c1.169-0.156,4.12-0.15,5.252,0.006
		c3.52,0.481,6.546,1.682,9.222,3.638c0.394,0.288,0.875,0.669,1.063,0.838l0.344,0.306l-0.563-0.188
		c-3.295-1.094-7.127-1.625-10.46-1.444c-2.688,0.15-4.795,0.531-7.158,1.3c-5.064,1.644-9.528,4.813-12.804,9.089
		c-0.431,0.563-1.469,2.025-2.101,2.969c-0.563,0.844-1.444,2.313-2.051,3.438l-0.544,1L107.34,148.447L107.34,148.447z"
            />
            <path
                className="st1"
                d="M160.556,147.716c-0.012-0.063-0.044-0.213-0.069-0.331c-0.113-0.594-0.563-2.275-0.807-3.013
		c-0.556-1.694-1.425-3.638-2.319-5.207c-0.456-0.806-1.413-2.294-1.919-2.988c-0.169-0.238-0.3-0.438-0.288-0.444
		c0.469-0.338,0.888-0.706,1.313-1.163l0.538-0.563l1.676,1.113c3.082,2.05,5.771,4.119,8.14,6.264l0.7,0.631l-0.388,0.475
		c-1.3,1.594-2.963,3.101-4.676,4.232c-0.706,0.469-1.763,1.1-1.838,1.1C160.606,147.822,160.575,147.772,160.556,147.716z"
            />
            <path
                className="st1"
                d="M139.481,147.366c-1.4-0.825-3.632-2.482-4.433-3.288l-0.619-0.625l0.438-0.675
		c1.813-2.782,3.032-5.732,3.682-8.876c0.012-0.081,0.063-0.144,0.106-0.144c0.106,0,2.276,0.519,2.982,0.713
		c1.332,0.369,3.001,0.938,3.001,1.019c0,0.194-0.769,3.076-1.094,4.113c-0.438,1.388-1.025,2.982-1.569,4.238
		c-0.394,0.906-1.682,3.519-1.851,3.751C140.05,147.691,140,147.678,139.481,147.366L139.481,147.366z"
            />
            <path
                className="st1"
                d="M121.35,145.684c-2.332-0.256-5.158-0.838-7.671-1.594c-0.975-0.294-0.975-0.294-0.781-0.569
		c0.563-0.788,1.751-2.157,2.657-3.076c1.019-1.031,1.019-1.031,1.157-0.844c0.731,1,2.138,2.457,3.232,3.351
		c0.656,0.538,1.832,1.406,2.276,1.675c0.231,0.144,0.231,0.144,0.075,0.313c-0.088,0.088-0.238,0.306-0.331,0.475
		C121.788,145.728,121.788,145.728,121.35,145.684L121.35,145.684z"
            />
            <path
                className="st1"
                d="M33.736,143.527c-1.238-1.338-2.488-3.126-3.482-4.995c-0.219-0.4-0.331-0.681-0.294-0.719
		c0.031-0.031,1.926-0.769,4.208-1.644c4.72-1.8,6.708-2.569,7.559-2.919c0.325-0.138,0.594-0.238,0.6-0.231
		c0.4,0.625,0.869,1.206,1.338,1.682l0.6,0.6l-0.969,0.713c-2.82,2.069-5.664,4.582-7.884,6.957
		c-0.594,0.644-1.088,1.163-1.094,1.163C34.311,144.134,34.048,143.859,33.736,143.527L33.736,143.527z"
            />
            <path
                className="st1"
                d="M122.713,143.259c-1.957-1.369-3.495-2.782-4.52-4.151c-0.394-0.519-0.45-0.625-0.369-0.688
		c0.05-0.038,0.294-0.231,0.538-0.425c0.638-0.513,1.876-1.344,2.67-1.794c0.375-0.213,0.688-0.375,0.694-0.369
		c0.006,0.012,0.075,0.244,0.15,0.519c0.438,1.55,1.519,3.794,2.732,5.663l0.388,0.606l-0.381,0.275
		C123.307,143.84,123.489,143.803,122.713,143.259L122.713,143.259z"
            />
            <path
                className="st1"
                d="M132.948,142.452c-0.431-0.231-1.482-0.556-2.232-0.694l-0.769-0.138v-4.176v-4.176l0.613-0.056
		c0.888-0.075,3.614-0.056,4.451,0.038c0.863,0.088,2.082,0.263,2.119,0.294c0.05,0.05-0.431,1.894-0.719,2.75
		c-0.325,0.975-0.838,2.219-1.288,3.113c-0.656,1.306-1.769,3.163-1.894,3.163C133.198,142.565,133.067,142.515,132.948,142.452
		L132.948,142.452z"
            />
            <path
                className="st1"
                d="M125.839,141.152c-1.219-1.863-1.988-3.476-2.495-5.232c-0.169-0.581-0.2-0.756-0.138-0.794
		c0.225-0.144,2.082-0.838,2.751-1.031c0.725-0.206,2.169-0.525,2.388-0.525c0.094,0,0.1,0.35,0.1,4.026v4.019l-0.45,0.038
		c-0.388,0.031-1.182,0.206-1.5,0.325C126.427,142.002,126.246,141.771,125.839,141.152L125.839,141.152z"
            />
            <path
                className="st1"
                d="M167.477,139.989c-2.532-2.213-5.095-4.163-7.934-6.039c-0.869-0.569-1.626-1.069-1.682-1.1
		c-0.1-0.063-0.094-0.094,0.081-0.406c0.388-0.688,0.581-1.3,0.794-2.469c0.031-0.15,0.138-0.15,1.844,0.038
		c2.626,0.281,4.989,0.794,7.559,1.632c1.144,0.375,3.42,1.25,3.532,1.363c0.131,0.131-0.506,2.338-1.082,3.738
		c-0.475,1.15-1.157,2.469-1.676,3.251c-0.256,0.381-0.488,0.713-0.519,0.731C168.371,140.739,167.959,140.408,167.477,139.989z"
            />
            <path
                className="st1"
                d="M29.115,135.995c-0.838-2.332-1.263-4.638-1.263-6.939c0-0.544,0.006-0.988,0.019-0.988
		c0.013,0,0.269,0.081,0.569,0.188c1.375,0.463,3.651,0.938,5.57,1.169c1.188,0.138,4.877,0.156,6.127,0.031
		c0.45-0.044,0.869-0.088,0.932-0.094c0.106-0.012,0.131,0.056,0.2,0.538c0.044,0.3,0.156,0.831,0.25,1.169
		c0.094,0.344,0.156,0.638,0.144,0.656c-0.019,0.013-2.77,1.088-6.121,2.382c-3.351,1.294-6.127,2.363-6.164,2.382
		C29.334,136.501,29.228,136.307,29.115,135.995L29.115,135.995z"
            />
            <path
                className="st1"
                d="M149.603,135.007c-1.163-0.263-2.445-0.919-3.82-1.95c-1.007-0.756-2.276-1.869-2.226-1.95
		c0.025-0.038,0.275-0.375,0.556-0.756c5.064-6.839,8.365-15.546,9.24-24.41c0.256-2.569,0.325-6.232,0.169-8.589
		c-0.081-1.175-0.081-1.175,0.344-1.607c0.681-0.688,1.676-1.156,4.483-2.113c2.263-0.775,3.351-1.238,4.295-1.844
		c0.188-0.119,0.35-0.206,0.363-0.194c0.075,0.075,0.35,3.238,0.45,5.145c0.081,1.619,0.081,5.307,0,6.889
		c-0.406,7.839-2.013,14.921-4.845,21.347c-0.263,0.594-1.263,2.463-2.757,5.132c-2.351,4.194-2.351,4.194-2.676,4.382
		C152.147,135.095,150.828,135.282,149.603,135.007L149.603,135.007z M148.959,132.494c0.888-0.581,2.201-2.088,3.163-3.613
		c3.101-4.926,5.946-13.64,6.983-21.441c0.481-3.576,0.538-6.845,0.156-9.127c-0.194-1.175-0.575-2.438-0.744-2.438
		c-0.069,0-0.219,0.225-0.638,0.969c-0.288,0.5-0.288,0.5-0.206,2.938c0.119,3.438,0.019,5.663-0.381,8.376
		c-0.638,4.344-1.969,8.858-4.17,14.127c-0.744,1.782-1.507,3.501-3.145,7.051c-0.838,1.813-1.525,3.326-1.525,3.357
		C148.453,132.801,148.553,132.763,148.959,132.494L148.959,132.494z"
            />
            <path
                className="st1"
                d="M171.147,131.15c-3.545-1.425-7.208-2.3-11.028-2.644c-0.413-0.038-0.756-0.075-0.769-0.081
		c-0.006-0.012,0.325-0.731,0.731-1.607c0.744-1.594,0.744-1.594,1.075-1.8c0.575-0.356,3.163-1.613,4.208-2.044
		c1.801-0.75,5.214-1.819,5.352-1.675c0.069,0.075,0.594,1.732,0.769,2.425c0.375,1.507,0.613,3.101,0.694,4.72
		c0.038,0.756-0.056,2.707-0.144,2.932C171.997,131.475,171.91,131.457,171.147,131.15L171.147,131.15z"
            />
            <path
                className="st1"
                d="M35.874,128.131c-1.719-0.1-3.664-0.394-5.364-0.819c-0.719-0.181-2.47-0.725-2.47-0.769
		c0-0.012,0.144-0.775,0.313-1.688c0.438-2.319,0.988-3.938,1.988-5.863l0.413-0.788l0.781,0.75
		c2.726,2.619,5.458,4.551,9.109,6.439c1.125,0.581,1.125,0.581,1.013,0.956c-0.056,0.206-0.169,0.644-0.244,0.969
		c-0.075,0.325-0.138,0.6-0.144,0.606c-0.006,0.012-0.45,0.056-0.982,0.106C39.012,128.15,37.049,128.194,35.874,128.131z"
            />
            <path
                className="st1"
                d="M41.319,124.074c-3.52-1.819-6.383-3.851-8.909-6.32c-0.888-0.869-0.888-0.869-0.644-1.2
		c1.213-1.682,3.126-3.588,4.795-4.782c0.281-0.206,0.281-0.206,0.825,0.731c1.932,3.351,4.476,6.307,7.459,8.683
		c0.313,0.25,0.575,0.475,0.569,0.5c0,0.019-0.244,0.194-0.544,0.381c-0.675,0.431-1.751,1.494-2.138,2.107
		c-0.156,0.25-0.294,0.45-0.313,0.45C42.401,124.624,41.907,124.38,41.319,124.074L41.319,124.074z"
            />
            <path
                className="st1"
                d="M161.894,122.524c0.919-2.444,1.838-5.745,2.501-8.92c0.106-0.519,0.206-0.95,0.219-0.963
		c0.056-0.056,2.138,2.088,2.676,2.744c0.994,1.225,1.932,2.657,2.57,3.919l0.275,0.55l-0.7,0.175
		c-2.088,0.544-5.045,1.663-7.083,2.688c-0.319,0.156-0.594,0.288-0.606,0.288C161.726,123.005,161.794,122.786,161.894,122.524
		L161.894,122.524z"
            />
            <path
                className="st1"
                d="M46.202,120.336c-1.55-1.2-3.776-3.326-4.864-4.645c-1.119-1.363-2.094-2.757-2.907-4.157
		c-0.438-0.75-0.438-0.75-0.15-0.931c0.819-0.519,3.907-1.869,5.077-2.225c0.531-0.163,1.032-0.313,1.1-0.338
		c0.119-0.038,0.188,0.056,0.619,0.813c1.313,2.3,2.813,4.282,4.795,6.307l1.038,1.069l-0.969,1.056
		c-1.132,1.244-3.207,3.344-3.301,3.344C46.608,120.624,46.408,120.492,46.202,120.336z"
            />
            <path
                className="st1"
                d="M49.297,120.149c0.156-0.163,0.663-0.694,1.125-1.175c1.044-1.081,1.826-1.944,2.226-2.45
		c0.169-0.206,0.544-0.656,0.825-1c1.838-2.163,3.395-4.938,4.276-7.614c1.694-5.17,1.6-11.027-0.275-16.847
		c-0.944-2.932-2.457-6.057-4.164-8.608c-0.513-0.769-0.5-0.75-0.225-0.581c1.444,0.863,3.382,2.363,4.508,3.482
		c4.858,4.838,6.852,11.908,5.389,19.097c-0.731,3.607-2.369,7.051-4.67,9.802c-0.531,0.638-1.863,1.963-2.532,2.519
		c-1.838,1.525-3.864,2.669-6.108,3.451C49.015,120.449,49.015,120.449,49.297,120.149z"
            />
            <path
                className="st1"
                d="M147.121,118.692c-3.101-1.688-5.689-4.163-7.459-7.139c-1.425-2.4-2.357-5.045-2.77-7.895
		c-0.75-5.113,0.306-10.502,2.92-14.846c2.638-4.401,6.54-7.407,11.529-8.908c4.026-1.206,6.502-3.163,7.183-5.688
		c0.188-0.688,0.206-2.363,0.038-3.157c-0.681-3.157-2.926-6.714-7.327-11.596c-2.357-2.619-5.608-5.926-7.052-7.17l-0.731-0.638
		l0.569-0.238c1.225-0.513,2.726-0.919,4.245-1.156c1.125-0.175,4.02-0.156,5.252,0.031c3.726,0.581,7.133,2.019,10.097,4.263
		c2.857,2.157,4.933,4.745,6.59,8.189c2.157,4.501,2.52,9.708,1.025,14.846c-0.631,2.182-1.794,4.57-3.132,6.439
		c-1.607,2.244-3.789,4.251-6.014,5.532c-0.256,0.15-0.7,0.425-0.988,0.613c-0.744,0.488-1.65,0.881-3.232,1.388
		c-2.757,0.894-3.451,1.175-4.395,1.813c-0.3,0.2-0.688,0.513-0.869,0.681c-0.181,0.175-0.356,0.319-0.388,0.319
		c-0.031,0-0.219,0.188-0.406,0.413c-0.925,1.081-1.494,2.25-1.801,3.669c-0.181,0.856-0.2,2.557-0.031,3.357
		c0.219,1.031,0.625,2.044,1.138,2.844c0.2,0.319,0.2,0.319,0.119,1.125c-0.431,4.213-1.4,8.339-2.876,12.246
		c-0.225,0.581-0.413,1.069-0.419,1.081C147.921,119.117,147.559,118.93,147.121,118.692L147.121,118.692z M147.208,117.342
		c0.331-0.163,0.619-0.65,0.619-1.038c0-0.213-0.206-0.619-0.375-0.738c-0.1-0.069-0.3-0.169-0.444-0.219
		c-0.238-0.088-0.3-0.088-0.594,0c-0.575,0.169-0.825,0.594-0.763,1.288C145.721,117.323,146.521,117.692,147.208,117.342
		L147.208,117.342z M144.776,115.366c0.669-0.375,0.706-1.406,0.063-1.838c-0.413-0.281-1.132-0.238-1.482,0.081
		c-0.544,0.506-0.406,1.519,0.25,1.807C143.914,115.554,144.489,115.529,144.776,115.366z M147.44,114.354
		c0.425-0.225,0.7-0.706,0.7-1.219c0-0.625-0.313-0.913-1-0.913c-0.757,0-1.219,0.388-1.213,1.025c0,0.688,0.444,1.194,1.038,1.194
		C147.133,114.441,147.346,114.404,147.44,114.354L147.44,114.354z M142.745,113.191c0.663-0.3,0.694-1.238,0.063-1.794
		c-0.413-0.363-0.963-0.331-1.394,0.063c-0.525,0.481-0.338,1.519,0.319,1.794C141.957,113.347,142.47,113.316,142.745,113.191z
		 M145.358,112.447c0.344-0.156,0.513-0.363,0.594-0.713c0.169-0.756-0.331-1.369-1.119-1.369c-0.65-0.006-0.95,0.225-1.044,0.813
		c-0.075,0.488,0.231,1.063,0.688,1.269C144.801,112.591,145.039,112.597,145.358,112.447L145.358,112.447z M149.39,111.785
		c0.225-0.119,0.306-0.369,0.181-0.588c-0.069-0.125-0.15-0.169-0.344-0.181c-0.313-0.025-0.4,0.069-0.419,0.431
		c-0.012,0.231,0.012,0.281,0.144,0.35C149.153,111.897,149.172,111.897,149.39,111.785z M147.996,111.341
		c0.313-0.15,0.519-0.488,0.519-0.856c0-0.488-0.3-0.794-0.781-0.794c-0.506,0-0.844,0.363-0.844,0.906
		C146.89,111.266,147.409,111.616,147.996,111.341L147.996,111.341z M141.113,110.716c0.3-0.144,0.638-0.538,0.688-0.806
		s-0.05-0.638-0.231-0.869c-0.313-0.4-1.057-0.544-1.482-0.288c-0.694,0.425-0.644,1.663,0.075,1.975
		C140.425,110.841,140.857,110.834,141.113,110.716z M143.795,110.028c0.638-0.3,0.588-1.206-0.081-1.519
		c-0.3-0.144-0.3-0.144-0.65,0.013c-0.406,0.181-0.55,0.356-0.55,0.688c0,0.356,0.263,0.763,0.563,0.875
		C143.263,110.153,143.595,110.128,143.795,110.028L143.795,110.028z M145.833,109.941c0.169-0.094,0.163-0.475-0.019-0.669
		c-0.188-0.2-0.625-0.206-0.763-0.006c-0.106,0.15-0.131,0.406-0.05,0.525C145.133,109.984,145.589,110.066,145.833,109.941
		L145.833,109.941z M149.816,109.228c0.494-0.256,0.419-1.169-0.119-1.45c-0.25-0.125-0.694-0.088-0.919,0.081
		c-0.444,0.331-0.406,1.219,0.063,1.431C149.084,109.397,149.547,109.365,149.816,109.228L149.816,109.228z M140.15,107.59
		c0.494-0.463,0.488-1.3-0.013-1.557c-0.231-0.119-0.706-0.119-0.938,0c-0.581,0.3-0.638,1-0.125,1.538
		c0.238,0.25,0.256,0.256,0.563,0.231C139.875,107.778,140.006,107.728,140.15,107.59L140.15,107.59z M150.266,106.946
		c0.031-0.069,0.063-0.213,0.063-0.319c0-0.313-0.1-0.438-0.344-0.438c-0.238,0-0.469,0.231-0.469,0.475
		C149.515,106.978,150.122,107.209,150.266,106.946z M139.043,105.471c0.125-0.119,0.156-0.213,0.156-0.463
		c0-0.263-0.025-0.331-0.163-0.438c-0.563-0.444-1.275,0.331-0.832,0.894C138.387,105.69,138.825,105.696,139.043,105.471
		L139.043,105.471z M167.196,60.163c0.181-0.188,0.238-0.494,0.138-0.731c-0.094-0.231-0.231-0.313-0.531-0.313
		c-0.319,0-0.531,0.225-0.531,0.563C166.271,60.201,166.871,60.513,167.196,60.163z M153.054,59.47
		c0.256-0.256,0.269-0.413,0.038-0.669c-0.156-0.175-0.219-0.2-0.481-0.194c-0.406,0.006-0.619,0.163-0.644,0.494
		c-0.013,0.175,0.019,0.294,0.113,0.413c0.113,0.144,0.181,0.169,0.45,0.169C152.804,59.682,152.867,59.657,153.054,59.47z
		 M165.577,58.719c0.256-0.138,0.406-0.4,0.406-0.731c0.013-0.55-0.263-0.806-0.857-0.806c-0.45,0-0.731,0.181-0.85,0.55
		c-0.075,0.238-0.069,0.3,0.025,0.55c0.069,0.181,0.181,0.331,0.306,0.406C164.839,58.832,165.327,58.844,165.577,58.719
		L165.577,58.719z M155.036,58.344c0.538-0.219,0.631-1.106,0.163-1.5c-0.35-0.294-1.007-0.181-1.213,0.213
		c-0.138,0.263-0.119,0.8,0.031,1.044C154.198,58.401,154.648,58.507,155.036,58.344L155.036,58.344z M151.779,57.932
		c0.125-0.038,0.313-0.163,0.419-0.288c0.156-0.175,0.194-0.275,0.194-0.5c0-0.7-0.525-1.063-1.15-0.788
		c-0.832,0.369-0.656,1.532,0.244,1.632C151.522,57.988,151.654,57.963,151.779,57.932z M159.5,57.494
		c0.275-0.169,0.356-0.481,0.238-0.931c-0.113-0.444-0.206-0.519-0.619-0.556c-0.275-0.019-0.388,0-0.556,0.106
		c-0.544,0.331-0.556,1.231-0.019,1.444C158.787,57.657,159.287,57.625,159.5,57.494L159.5,57.494z M161.713,57.494
		c0.281-0.156,0.406-0.769,0.2-0.95c-0.238-0.206-0.388-0.25-0.613-0.175c-0.119,0.038-0.263,0.131-0.313,0.206
		c-0.113,0.163-0.125,0.606-0.012,0.744C161.15,57.538,161.475,57.619,161.713,57.494z M163.464,56.838
		c0.569-0.294,0.575-1.231,0.019-1.5c-0.488-0.231-1.05-0.025-1.2,0.438c-0.144,0.431,0.044,0.956,0.388,1.094
		C162.882,56.957,163.263,56.938,163.464,56.838z M156.924,56.369c0.363-0.175,0.494-0.438,0.456-0.919
		c-0.069-0.956-0.975-1.438-1.738-0.931c-0.369,0.244-0.469,0.438-0.463,0.888c0.006,0.444,0.144,0.731,0.463,0.944
		C155.924,56.544,156.549,56.55,156.924,56.369L156.924,56.369z M153.592,55.869c0.481-0.138,0.8-0.556,0.8-1.063
		c0-0.681-0.588-1.188-1.275-1.113c-0.638,0.075-0.875,0.35-0.9,1.038c-0.013,0.394,0.006,0.519,0.106,0.681
		C152.579,55.825,153.092,56.006,153.592,55.869L153.592,55.869z M150.741,55.625c0.106-0.063,0.281-0.25,0.388-0.419
		c0.519-0.781-0.019-1.713-0.988-1.713c-0.356,0-0.581,0.094-0.825,0.356c-0.344,0.356-0.381,0.706-0.138,1.2
		C149.484,55.663,150.228,55.938,150.741,55.625z M161.313,55.619c0.488-0.219,0.681-0.769,0.475-1.369
		c-0.163-0.488-0.456-0.713-0.975-0.744c-0.325-0.019-0.419,0-0.6,0.125c-0.331,0.219-0.475,0.513-0.469,0.956
		C159.744,55.506,160.488,55.994,161.313,55.619L161.313,55.619z M158.374,54.075c0.244-0.113,0.488-0.444,0.55-0.75
		c0.225-1.106-1.55-1.725-2.163-0.75c-0.1,0.156-0.119,0.281-0.1,0.556c0.025,0.413,0.225,0.731,0.594,0.925
		C157.543,54.206,158.074,54.219,158.374,54.075L158.374,54.075z M148.259,53.237c0.356-0.2,0.469-0.4,0.494-0.881
		c0.025-0.344,0-0.456-0.106-0.638c-0.188-0.3-0.575-0.475-1.05-0.475c-0.688,0-1.05,0.4-1,1.106c0.05,0.7,0.381,1.013,1.069,1.019
		C147.921,53.369,148.096,53.331,148.259,53.237L148.259,53.237z M154.698,53.118c0.506-0.256,0.775-0.794,0.631-1.263
		c-0.331-1.113-2.044-0.981-2.176,0.169c-0.038,0.363,0.219,0.925,0.513,1.1C153.923,53.281,154.386,53.281,154.698,53.118z
		 M151.454,52.837c0.531-0.275,0.744-1.056,0.419-1.538c-0.419-0.631-1.644-0.656-1.938-0.038c-0.144,0.294-0.138,0.888,0.006,1.163
		C150.191,52.925,150.903,53.118,151.454,52.837z"
            />
            <path
                className="st1"
                d="M50.872,114.054c-1.5-1.519-2.526-2.769-3.589-4.394c-0.569-0.856-1.3-2.113-1.294-2.2
		c0-0.025,0.244-0.206,0.544-0.394c0.375-0.238,0.794-0.594,1.338-1.138c0.438-0.444,0.813-0.8,0.838-0.8
		c0.019,0,1.6,0.888,3.501,1.975l3.464,1.982l-0.525,1.019c-0.888,1.7-1.938,3.313-3.151,4.826
		C51.885,115.073,51.885,115.073,50.872,114.054L50.872,114.054z"
            />
            <path
                className="st1"
                d="M36.893,108.547c-0.056-0.494-0.213-2.388-0.288-3.482c-0.113-1.582-0.15-6.164-0.069-7.908
		c0.588-12.521,4.026-22.923,10.122-30.668c0.45-0.575,0.506-0.625,0.919-0.794c0.65-0.275,1.05-0.35,1.782-0.35
		c1.913,0.006,4.045,1.138,6.652,3.538l0.431,0.4l-0.625,0.838c-5.327,7.17-8.478,16.309-9.303,26.992
		c-0.131,1.744-0.238,5.132-0.2,6.326c0.038,1.031,0.038,1.031-0.481,1.419c-0.563,0.431-1.532,0.956-2.319,1.269
		c-0.275,0.113-1.332,0.481-2.344,0.825c-1.888,0.638-2.907,1.069-3.664,1.563c-0.238,0.15-0.463,0.294-0.5,0.319
		C36.955,108.865,36.918,108.772,36.893,108.547L36.893,108.547z M41.907,104.396c0.388-1.063,0.413-1.363,0.419-5.238
		c0.006-3.669,0.031-4.126,0.344-6.457c0.569-4.238,1.907-8.901,4.108-14.296c0.644-1.575,1.394-3.307,3.013-6.957
		c0.825-1.869,1.507-3.432,1.507-3.482c0-0.138-0.056-0.119-0.456,0.144c-2.732,1.819-5.808,7.72-8.028,15.39
		c-1.138,3.951-1.869,7.683-2.282,11.689c-0.113,1.081-0.113,5.138,0,5.938c0.181,1.25,0.469,2.382,0.781,3.044
		C41.651,104.883,41.719,104.908,41.907,104.396L41.907,104.396z"
            />
            <path
                className="st1"
                d="M52.842,105.721c-1.819-1.044-3.32-1.913-3.339-1.925c-0.013-0.019,0.069-0.281,0.188-0.588
		c0.125-0.3,0.269-0.781,0.331-1.063c0.075-0.35,0.138-0.525,0.206-0.55c0.056-0.013,0.525-0.056,1.05-0.094
		c1.613-0.119,3.251,0.044,5.17,0.513c1.05,0.263,1.05,0.263,1.013,0.719c-0.056,0.594-0.244,1.613-0.456,2.469
		c-0.206,0.856-0.719,2.382-0.8,2.4C56.174,107.615,54.655,106.765,52.842,105.721L52.842,105.721z"
            />
            <path
                className="st1"
                d="M57.549,100.789c-0.075-0.069-1.325-0.363-2.157-0.506c-1.532-0.263-3.482-0.331-4.883-0.181
		c-0.319,0.037-0.319,0.037-0.356-0.644c-0.025-0.369-0.056-0.775-0.081-0.9c-0.038-0.231-0.038-0.231,0.919-0.606
		c1.257-0.494,2.063-0.769,3.214-1.106c0.819-0.238,2.838-0.719,3.007-0.719c0.069,0,0.238,1.138,0.338,2.282
		C57.618,99.139,57.612,100.858,57.549,100.789L57.549,100.789z"
            />
            <path
                className="st1"
                d="M49.322,96.426c-0.238-0.494-0.238-0.494,0.094-1.163c1.307-2.638,2.938-4.57,5.027-5.938
		c0.338-0.219,0.638-0.381,0.663-0.35c0.125,0.138,0.775,1.863,1.169,3.119c0.213,0.663,0.725,2.607,0.694,2.619
		c-0.006,0.006-0.475,0.1-1.044,0.219c-1.644,0.325-4.07,1.069-5.852,1.788C49.565,96.92,49.565,96.92,49.322,96.426L49.322,96.426z
		"
            />
            <path
                className="st1"
                d="M49.047,92.763c0-0.15,0.375-2.269,0.563-3.169c0.219-1.05,0.6-2.607,0.882-3.594
		c0.213-0.731,1.132-3.513,1.15-3.488c0.05,0.056,0.95,1.569,1.294,2.175c0.431,0.763,1.507,2.882,1.475,2.9
		c-0.013,0.013-0.263,0.163-0.563,0.344c-1.557,0.931-3.082,2.413-4.283,4.176C49.065,92.838,49.047,92.863,49.047,92.763
		L49.047,92.763z"
            />
            <path
                className="st1"
                d="M34.63,86.937c-3.345-3.063-5.614-6.889-6.49-10.946c-0.463-2.119-0.531-4.538-0.194-6.764
		c1.213-8.07,7.44-15.434,15.342-18.134c2.226-0.763,4.308-1.113,6.602-1.113c4.064,0,7.802,1.156,11.504,3.563
		c1.082,0.706,2.138,1.507,2.507,1.907c1.032,1.106,1.919,1.807,3.026,2.382c2.395,1.238,5.12,1.375,7.284,0.356
		c1-0.469,2.288-1.757,3.232-3.226c0.832-1.288,1.413-2.525,2.645-5.632c1.069-2.688,1.594-3.901,2.069-4.745
		c0.188-0.344,0.513-1.038,0.719-1.55c1.651-4.107,3.451-7.164,5.683-9.633c0.725-0.806,1.288-1.331,2.319-2.163
		c0.625-0.506,0.794-0.681,1.119-1.175c1.225-1.844,3.47-3.426,6.777-4.763c0.694-0.281,1.194-0.544,1.969-1.025
		c2.876-1.794,4.927-2.738,6.821-3.138c0.8-0.169,1.901-0.156,2.501,0.031c0.969,0.3,1.663,0.906,2.126,1.863
		c0.144,0.294,0.25,0.538,0.238,0.544c-0.006,0.013-0.325,0.219-0.694,0.469c-1.713,1.144-4.551,3.469-6.915,5.67l-0.344,0.319
		l0.375,0.431c0.913,1.038,1.457,2.163,1.626,3.363c0.044,0.306,0.044,0.306-1.075-0.594c-0.613-0.5-1.15-0.925-1.194-0.95
		c-0.1-0.063-1.457-0.238-1.519-0.2c-0.025,0.019-0.731,0.9-1.563,1.963c-2.369,3.038-3.726,4.645-5.283,6.251
		c-2.101,2.169-4.458,3.988-6.696,5.176c-0.606,0.319-0.775,0.456-0.563,0.456c0.25,0,1.869-0.463,2.513-0.719
		c2.038-0.806,3.726-1.975,5.689-3.938c1.575-1.575,2.788-3.076,5.996-7.451c0.563-0.763,0.563-0.763,0.875-0.763
		c0.313-0.006,0.319,0,1.032,0.656c1.394,1.275,2.007,1.532,2.401,1.006c0.331-0.444-0.019-2.157-0.713-3.469
		c-0.169-0.325-0.306-0.6-0.3-0.6c0.012-0.006,0.394-0.256,0.844-0.556c0.456-0.3,0.857-0.544,0.888-0.544
		c0.125,0,1.632,1.557,1.901,1.963c0.288,0.431,0.288,0.431,0.094,0.813c-1.269,2.513-3.182,5.376-5.639,8.445
		c-0.819,1.025-1.913,2.338-3.945,4.751c-1.544,1.832-2.995,3.607-3.701,4.532c-0.888,1.169-3.545,5.445-3.764,6.064
		c-0.663,1.857-1.913,4.151-3.182,5.851c-4.195,5.601-10.56,9.233-17.362,9.902c-0.888,0.094-2.995,0.094-3.876,0
		c-4.051-0.4-7.69-1.888-10.816-4.419c-0.807-0.65-0.838-0.681-1.582-1.3c-2.138-1.794-3.745-2.657-5.452-2.938
		c-4.864-0.788-8.822,3.732-12.148,13.877c-0.8,2.438-1.275,4.119-2.226,7.92l-0.669,2.669L34.63,86.937L34.63,86.937z
		 M34.798,85.774c0.5-0.256,0.481-1.3-0.025-1.569c-0.3-0.15-0.763-0.081-1,0.156c-0.163,0.163-0.194,0.25-0.219,0.563
		c-0.031,0.45,0.094,0.731,0.381,0.856C34.204,85.899,34.567,85.893,34.798,85.774L34.798,85.774z M32.91,83.349
		c0.363-0.188,0.525-0.725,0.369-1.194c-0.1-0.294-0.281-0.406-0.681-0.406c-0.488,0-0.65,0.094-0.8,0.45
		c-0.075,0.175-0.131,0.375-0.131,0.45c0,0.181,0.263,0.6,0.444,0.7C32.31,83.461,32.691,83.455,32.91,83.349L32.91,83.349z
		 M35.592,82.898c0.075-0.05,0.181-0.175,0.231-0.275c0.113-0.219,0.125-0.744,0.019-0.969c-0.144-0.319-0.606-0.425-1.194-0.275
		c-0.244,0.056-0.275,0.094-0.406,0.406c-0.075,0.188-0.138,0.381-0.138,0.431c0,0.138,0.281,0.569,0.45,0.681
		C34.742,83.03,35.405,83.036,35.592,82.898z M33.723,81.436c0.131-0.069,0.319-0.488,0.319-0.706c0-0.256-0.169-0.419-0.438-0.419
		c-0.375,0-0.544,0.088-0.625,0.344c-0.094,0.275-0.05,0.456,0.15,0.675C33.292,81.505,33.523,81.548,33.723,81.436z M31.279,81.123
		c0.175-0.106,0.2-0.156,0.2-0.381c0-0.331-0.088-0.488-0.35-0.594c-0.188-0.075-0.244-0.075-0.431,0
		c-0.119,0.05-0.25,0.163-0.288,0.25c-0.106,0.219-0.019,0.569,0.175,0.725C30.785,81.279,31.016,81.279,31.279,81.123z
		 M36.536,80.461c0.213-0.175,0.263-0.469,0.125-0.731c-0.106-0.194-0.175-0.231-0.45-0.231c-0.319,0-0.456,0.081-0.538,0.319
		c-0.106,0.306-0.081,0.425,0.113,0.625C36.018,80.673,36.28,80.679,36.536,80.461L36.536,80.461z M65.808,63.814
		c0.456-0.463-0.069-1.244-0.638-0.944c-0.063,0.038-0.163,0.156-0.219,0.263C64.645,63.72,65.345,64.277,65.808,63.814
		L65.808,63.814z M67.896,62.658c0.244-0.188,0.369-0.5,0.369-0.956c0-0.588-0.394-0.925-1.088-0.925
		c-0.738,0.006-1.132,0.325-1.175,0.963c-0.031,0.456,0.138,0.719,0.613,0.963c0.294,0.156,0.394,0.175,0.706,0.15
		C67.577,62.826,67.74,62.776,67.896,62.658L67.896,62.658z M69.766,62.745c0.238-0.088,0.369-0.331,0.325-0.6
		c-0.063-0.325-0.231-0.463-0.563-0.463c-0.319,0-0.431,0.088-0.538,0.406C68.828,62.589,69.247,62.945,69.766,62.745z
		 M64.301,62.564c0.119-0.063,0.288-0.206,0.363-0.325c0.125-0.181,0.144-0.275,0.125-0.594c-0.056-0.9-0.85-1.394-1.657-1.025
		c-0.356,0.156-0.519,0.431-0.519,0.875c0.006,0.563,0.306,1.063,0.725,1.181C63.582,62.751,64.033,62.695,64.301,62.564z
		 M72.729,61.895c0.1-0.069,0.138-0.156,0.138-0.344c0-0.319-0.081-0.469-0.319-0.588c-0.5-0.263-1.038,0.419-0.706,0.894
		c0.075,0.113,0.156,0.138,0.419,0.138C72.454,61.995,72.648,61.957,72.729,61.895L72.729,61.895z M58.631,61.47
		c0.275-0.113,0.4-0.269,0.488-0.606c0.206-0.775-0.281-1.244-1.075-1.019c-0.413,0.119-0.494,0.238-0.494,0.719
		c0,0.344,0.1,0.581,0.325,0.781C58.124,61.576,58.318,61.607,58.631,61.47L58.631,61.47z M70.41,61.176
		c0.394-0.15,0.681-0.55,0.769-1.075c0.044-0.306,0.038-0.331-0.169-0.588c-0.344-0.419-0.588-0.544-0.994-0.5
		c-0.413,0.044-0.694,0.194-0.888,0.488c-0.131,0.188-0.144,0.269-0.125,0.725c0.012,0.431,0.044,0.531,0.169,0.663
		C69.472,61.214,69.991,61.339,70.41,61.176L70.41,61.176z M61.488,60.563c0.35-0.181,0.575-0.556,0.575-0.938
		c0-0.438-0.206-0.75-0.644-0.969c-0.556-0.275-1.038-0.194-1.419,0.238c-0.175,0.2-0.2,0.275-0.2,0.575
		c0,0.381,0.088,0.6,0.356,0.85C60.531,60.676,61.088,60.782,61.488,60.563z M67.09,60.432c0.113-0.075,0.294-0.263,0.4-0.425
		c0.169-0.25,0.194-0.338,0.169-0.588c-0.05-0.581-0.606-1.013-1.307-1.019c-1.144,0-1.344,1.5-0.269,2.057
		C66.352,60.601,66.833,60.582,67.09,60.432L67.09,60.432z M72.704,60.238c0.125-0.1,0.163-0.181,0.163-0.369
		c0-0.356-0.113-0.55-0.356-0.631c-0.325-0.106-0.506-0.031-0.644,0.275c-0.163,0.35-0.156,0.519,0.031,0.7
		C72.091,60.413,72.466,60.426,72.704,60.238L72.704,60.238z M74.117,59.901c0.35-0.181,0.494-0.725,0.25-0.969
		c-0.3-0.3-0.794-0.188-0.969,0.231c-0.094,0.219-0.094,0.256,0.006,0.475C73.573,60.001,73.773,60.076,74.117,59.901z
		 M56.593,59.776c0.244-0.163,0.394-0.463,0.394-0.819c0-0.269-0.025-0.338-0.188-0.494c-0.15-0.144-0.256-0.188-0.556-0.206
		c-0.213-0.013-0.419,0.006-0.5,0.05c-0.188,0.094-0.381,0.531-0.381,0.85C55.361,59.763,56.068,60.12,56.593,59.776z M64.27,59.132
		c0.413-0.244,0.569-0.944,0.306-1.388c-0.238-0.413-0.813-0.675-1.288-0.594c-0.681,0.119-1.007,0.994-0.613,1.638
		C62.957,59.251,63.764,59.426,64.27,59.132z M59.544,58.426c0.325-0.181,0.469-0.475,0.431-0.869
		c-0.038-0.363-0.263-0.85-0.463-0.981c-0.238-0.15-0.638-0.169-1.057-0.038c-0.319,0.1-0.394,0.156-0.513,0.363
		c-0.275,0.494-0.244,1.106,0.069,1.4C58.375,58.638,59.068,58.694,59.544,58.426L59.544,58.426z M54.398,57.713
		c0.363-0.275,0.519-0.894,0.306-1.194c-0.119-0.175-0.469-0.338-0.713-0.338c-0.281,0-0.675,0.219-0.819,0.456
		c-0.163,0.263-0.156,0.519,0.019,0.825C53.454,57.938,53.967,58.038,54.398,57.713z M62.107,57c0.288-0.15,0.425-0.344,0.519-0.763
		c0.175-0.763-0.269-1.369-1.013-1.369c-0.406,0-0.669,0.113-0.919,0.394c-0.475,0.538-0.269,1.481,0.388,1.769
		C61.363,57.157,61.832,57.138,62.107,57L62.107,57z M56.837,56.682c0.219-0.081,0.538-0.394,0.625-0.606
		c0.356-0.85-0.5-1.719-1.438-1.456c-0.469,0.131-0.788,0.544-0.788,1.019c0,0.275,0.275,0.819,0.488,0.956
		C56.03,56.794,56.436,56.825,56.837,56.682z M52.76,55.694c0.4-0.238,0.681-0.863,0.575-1.288c-0.163-0.644-1.163-1-1.851-0.644
		c-0.338,0.169-0.5,0.456-0.5,0.881C50.985,55.525,52.01,56.138,52.76,55.694L52.76,55.694z M59.506,55.206
		c0.475-0.244,0.731-0.613,0.731-1.056c0-0.356-0.138-0.606-0.45-0.825c-0.8-0.55-1.832-0.088-1.826,0.825
		c0,0.456,0.319,0.988,0.65,1.113C58.812,55.331,59.312,55.3,59.506,55.206L59.506,55.206z M44.039,55
		c0.275-0.194,0.325-0.619,0.094-0.844c-0.063-0.063-0.206-0.1-0.4-0.1c-0.263,0-0.325,0.025-0.463,0.194
		c-0.188,0.219-0.2,0.363-0.063,0.625C43.37,55.194,43.707,55.244,44.039,55L44.039,55z M39.262,54.831
		c0.163-0.063,0.3-0.388,0.256-0.619c-0.081-0.413-0.463-0.644-0.794-0.469c-0.256,0.138-0.394,0.663-0.225,0.881
		C38.662,54.838,39,54.931,39.262,54.831L39.262,54.831z M48.728,54.581c0.313-0.225,0.406-0.794,0.2-1.2
		c-0.138-0.275-0.344-0.388-0.7-0.388c-0.525,0-0.807,0.331-0.807,0.938c0,0.288,0.025,0.35,0.213,0.538
		c0.194,0.194,0.244,0.213,0.581,0.213C48.453,54.681,48.634,54.644,48.728,54.581z M42.463,54.194
		c0.388-0.275,0.394-0.994,0.006-1.281c-0.206-0.156-0.763-0.213-1.063-0.113c-0.281,0.094-0.425,0.35-0.425,0.756
		c0,0.388,0.069,0.531,0.331,0.694C41.588,54.419,42.188,54.394,42.463,54.194L42.463,54.194z M56.937,53.962
		c0.35-0.244,0.581-0.688,0.525-1.006c-0.056-0.294-0.419-0.681-0.756-0.813c-0.75-0.281-1.457,0.125-1.519,0.875
		c-0.031,0.419,0.144,0.838,0.444,1.038C55.936,54.256,56.574,54.212,56.937,53.962L56.937,53.962z M45.846,53.512
		c0.313-0.231,0.45-0.506,0.45-0.888c0-0.431-0.188-0.788-0.5-0.969c-0.313-0.188-1.044-0.188-1.294,0
		c-0.6,0.438-0.5,1.538,0.169,1.888C45.02,53.725,45.577,53.712,45.846,53.512z M53.967,52.762c0.869-0.694,0.606-1.957-0.444-2.113
		c-0.45-0.069-0.957,0.213-1.138,0.638c-0.219,0.506,0,1.294,0.438,1.582C53.117,53.068,53.654,53.018,53.967,52.762z
		 M50.172,52.712c0.1-0.05,0.263-0.2,0.356-0.325c0.238-0.306,0.25-0.881,0.031-1.188c-0.2-0.281-0.65-0.481-1.082-0.488
		c-0.613,0-0.932,0.344-0.932,1.006c0,0.456,0.156,0.731,0.531,0.944C49.378,52.831,49.897,52.856,50.172,52.712L50.172,52.712z
		 M96.768,33.146c0.2-0.05,0.506-0.169,0.681-0.256c0.444-0.225,1.05-0.844,1.275-1.306c0.169-0.344,0.181-0.431,0.181-1.063
		c0-0.644-0.012-0.713-0.2-1.106c-0.263-0.55-0.675-0.988-1.194-1.256c-0.569-0.294-0.975-0.381-1.651-0.344
		c-0.456,0.031-0.625,0.069-0.957,0.231c-0.619,0.306-1.188,0.969-1.444,1.675c-0.125,0.363-0.094,1.413,0.056,1.557
		c0.013,0.019,0.406-0.081,0.869-0.219c0.456-0.131,1.144-0.306,1.519-0.381c0.381-0.075,0.756-0.15,0.844-0.169
		c0.088-0.019-0.419,0.388-1.125,0.913c-0.706,0.519-1.294,0.975-1.307,1.013c-0.056,0.169,0.556,0.588,1.05,0.719
		C95.761,33.259,96.343,33.259,96.768,33.146L96.768,33.146z M104.77,26.883c0.325-0.538,0.494-1.094,0.506-1.644
		c0.013-0.975-0.4-1.475-1.319-1.6c-0.313-0.038-0.313-0.038-0.388,0.163c-0.188,0.538-0.012,1.2,0.431,1.657
		c0.238,0.244,0.244,0.263,0.313,0.938c0.044,0.381,0.081,0.725,0.1,0.769C104.458,27.314,104.558,27.233,104.77,26.883z
		 M110.941,23.045c0-0.038-0.156-0.238-0.344-0.444c-0.306-0.331-0.344-0.4-0.344-0.644c0-0.306,0.038-0.288-0.65-0.406
		c-0.594-0.1-0.725-0.088-0.725,0.081c0,0.075,0.069,0.281,0.15,0.456c0.206,0.45,0.625,0.744,1.3,0.925
		C110.735,23.126,110.941,23.132,110.941,23.045L110.941,23.045z"
            />
            <path
                className="st1"
                d="M148.253,76.916c-1.632-3.226-3.082-5.576-4.845-7.839c-0.319-0.406-0.681-0.881-0.813-1.056
		c-1.163-1.575-4.633-5.213-6.896-7.226c-3.914-3.482-8.021-6.257-12.16-8.208l-1-0.469l0.006-1.331
		c0.006-1.063,0.044-1.638,0.2-2.919c0.363-2.994,0.406-4.495,0.169-5.813c-0.05-0.294-0.094-0.538-0.094-0.544
		c0-0.031,2.551,1.031,3.457,1.444c9.534,4.332,18.362,11.446,24.995,20.135c3.807,4.982,5.052,7.195,5.22,9.252
		c0.05,0.656-0.081,1.281-0.419,1.969c-0.213,0.425-0.375,0.638-0.832,1.1c-0.894,0.894-2.051,1.588-3.764,2.263
		c-0.713,0.281-2.301,0.825-2.395,0.825C149.065,78.498,148.69,77.785,148.253,76.916L148.253,76.916z M152.766,74.266
		c0-0.063-0.15-0.519-0.338-1.019c-1.644-4.457-4.514-8.733-8.759-13.058c-3.789-3.857-8.246-7.37-13.285-10.458
		c-1.919-1.181-2.876-1.7-3.214-1.744c-0.456-0.069-1.663-0.069-1.663,0c0,0.1,1.375,1.381,2.151,2
		c1.107,0.881,1.919,1.45,5.058,3.532c2.895,1.919,3.851,2.6,5.045,3.588c0.875,0.725,4.526,4.376,5.702,5.701
		c2.676,3.032,5.045,6.301,6.99,9.658c0.319,0.55,0.631,1.081,0.688,1.181c0.088,0.138,0.263,0.244,0.763,0.45
		C152.629,74.397,152.766,74.422,152.766,74.266L152.766,74.266z"
            />
            <path
                className="st1"
                d="M127.108,71.74c-0.513-0.031-0.969-0.063-1.007-0.081c-0.056-0.019-0.05-0.175,0.019-0.613
		c0.131-0.863,0.131-3.482-0.006-4.495c-0.056-0.431-0.119-0.863-0.144-0.969c-0.031-0.181-0.031-0.181,1.794-0.175
		c1.832,0.012,1.832,0.012,2.132,0.438c0.975,1.381,1.932,3.276,2.426,4.82c0.15,0.456,0.175,0.613,0.119,0.65
		c-0.125,0.075-1.075,0.238-1.932,0.331C129.434,71.765,128.215,71.803,127.108,71.74L127.108,71.74z"
            />
            <path
                className="st1"
                d="M124.32,71.303c-0.575-0.156-1.851-0.594-2.47-0.85c-1.282-0.519-3.007-1.513-3.751-2.163
		c-0.25-0.219-0.25-0.219,0.2-0.688c0.875-0.925,1.801-2.175,2.513-3.401c0.144-0.25,0.269-0.456,0.281-0.456
		c0.006,0,0.244,0.138,0.525,0.306c0.644,0.388,1.425,0.713,2.207,0.925c0.606,0.163,0.606,0.163,0.706,0.794
		c0.194,1.244,0.244,1.9,0.25,3.069c0.006,1.094-0.113,2.538-0.206,2.525C124.558,71.365,124.439,71.334,124.32,71.303z"
            />
            <path
                className="st1"
                d="M133.792,70.359c-0.531-1.456-1.219-2.875-2.126-4.394l-0.444-0.756l1.344-0.263
		c0.744-0.144,1.857-0.394,2.47-0.544l1.125-0.288l0.9,0.894c0.963,0.956,2.076,2.2,2.882,3.207l0.5,0.625l-0.45,0.206
		c-1.175,0.55-2.545,1.063-3.839,1.444c-0.863,0.25-1.888,0.506-2.044,0.506C134.067,70.996,133.929,70.721,133.792,70.359
		L133.792,70.359z"
            />
            <path
                className="st1"
                d="M116.318,66.639c-1.113-1.219-2.088-2.875-2.632-4.482l-0.238-0.694l0.7-0.081
		c0.388-0.044,0.969-0.15,1.294-0.231c0.669-0.181,1.763-0.688,2.382-1.106l0.413-0.275l0.269,0.644
		c0.294,0.719,0.731,1.488,1.157,2.025c0.269,0.344,0.269,0.344-0.15,1.031c-0.575,0.944-0.869,1.363-1.438,2.069
		c-0.556,0.694-1.275,1.456-1.369,1.456C116.674,66.996,116.499,66.833,116.318,66.639L116.318,66.639z"
            />
            <path
                className="st1"
                d="M126.233,63.995c-1.3-0.15-2.42-0.469-3.389-0.963c-0.819-0.413-1.244-0.725-1.701-1.238
		c-1.05-1.181-1.601-2.394-1.857-4.094c-0.125-0.794-0.138-2.669-0.038-3.769c0.131-1.35,0.463-3.907,0.506-3.938
		c0.081-0.05,0.244-1.507,0.325-2.819c0.144-2.525-0.169-5.426-0.85-7.776c-0.369-1.294-0.775-2.332-1.382-3.563
		c-1.788-3.626-4.426-6.332-7.921-8.126l-0.988-0.506l0.344-0.3c0.344-0.294,0.344-0.294,0.769-0.063
		c0.231,0.125,0.857,0.519,1.388,0.869c2.319,1.538,4.383,3.482,6.046,5.707c0.788,1.044,1.294,1.863,1.938,3.113
		c2.176,4.232,2.72,8.395,1.644,12.646c-0.156,0.625-0.188,0.875-0.188,1.488c0,1.325,0.281,2.507,0.869,3.663
		c0.4,0.788,0.781,1.319,1.444,2.007c1.607,1.669,3.632,2.538,5.927,2.538c0.781,0,0.781,0,1.851,0.806
		c1,0.756,2.307,1.838,3.457,2.857l0.494,0.444l-1.407,0.288c-2.682,0.556-3.926,0.725-5.633,0.763
		C127.246,64.039,126.508,64.027,126.233,63.995L126.233,63.995z M126.915,63.158c0.206-0.206,0.244-0.4,0.131-0.669
		c-0.075-0.188-0.138-0.238-0.319-0.269c-0.119-0.025-0.313-0.025-0.431,0c-0.175,0.031-0.231,0.081-0.313,0.281
		c-0.106,0.263-0.063,0.481,0.131,0.694C126.271,63.37,126.727,63.345,126.915,63.158z M132.316,63.033
		c0.269-0.144,0.381-0.363,0.381-0.756c0-0.306-0.025-0.375-0.175-0.531c-0.244-0.238-0.563-0.294-0.925-0.156
		c-0.369,0.138-0.525,0.388-0.525,0.831c0,0.288,0.025,0.363,0.181,0.519c0.163,0.156,0.231,0.181,0.538,0.181
		C131.985,63.12,132.223,63.076,132.316,63.033z M128.865,62.626c0.288-0.131,0.369-0.25,0.431-0.613
		c0.05-0.331-0.094-0.669-0.356-0.8c-0.269-0.144-0.932-0.106-1.113,0.063c-0.3,0.269-0.294,0.919,0.006,1.206
		C128.134,62.758,128.465,62.808,128.865,62.626L128.865,62.626z M130.091,60.97c0.506-0.206,0.694-0.775,0.406-1.2
		c-0.269-0.394-0.713-0.506-1.157-0.294c-0.575,0.269-0.619,1.194-0.069,1.494C129.459,61.076,129.841,61.076,130.091,60.97z"
            />
            <path
                className="st1"
                d="M113.067,60.013c0-0.031-0.044-0.369-0.1-0.756c-0.094-0.694-0.338-3.526-0.3-3.563
		c0.006-0.013,0.294,0.013,0.631,0.063c0.656,0.088,3.582,0.113,4.089,0.038c0.288-0.044,0.288-0.044,0.331,0.75
		c0.025,0.438,0.069,0.975,0.094,1.194c0.056,0.475,0.075,0.45-0.825,1.075c-0.7,0.481-1.457,0.838-2.188,1.025
		C114.173,59.995,113.067,60.107,113.067,60.013L113.067,60.013z"
            />
            <path
                className="st1"
                d="M68.746,56.4c-0.613-0.225-1.307-0.738-2.476-1.819c-1.782-1.65-2.563-2.225-4.389-3.232
		c-1.207-0.663-1.263-0.438,0.45-1.725c4.989-3.763,10.61-6.689,16.755-8.726c1.032-0.344,3.514-1.094,3.614-1.094
		c0.019,0-0.356,0.794-0.832,1.769c-1.388,2.832-2.507,5.382-3.876,8.789L77.255,52.2l-1.494,0.756
		c-1.938,0.988-3.332,1.794-5.195,2.988C69.484,56.638,69.422,56.65,68.746,56.4z M67.265,52.712
		c0.794-0.206,1.713-0.575,2.632-1.056c0.725-0.381,1.132-0.619,3.345-1.957c1.694-1.025,2.882-1.613,3.901-1.932
		c0.369-0.119,0.369-0.119,0.588-0.569c0.219-0.469,0.225-0.644,0.031-0.644c-0.063,0-0.369-0.088-0.681-0.194
		c-0.563-0.194-0.563-0.194-1.45,0.25c-0.488,0.244-1.626,0.769-2.532,1.169c-2.038,0.9-3.176,1.482-4.114,2.1
		c-0.781,0.519-1.594,1.238-2.026,1.8c-0.263,0.338-0.719,1.075-0.719,1.15C66.239,52.912,66.696,52.862,67.265,52.712z"
            />
            <path
                className="st1"
                d="M114.567,54.387c-0.488-0.031-1.525-0.15-1.682-0.188c-0.15-0.038-0.15-0.038-0.106-0.663
		c0.081-1.138,0.388-2.575,0.794-3.701c0.188-0.519,0.188-0.519,0.731-0.3c1.05,0.413,2.557,0.806,3.532,0.925
		c0.194,0.019,0.356,0.056,0.356,0.075s-0.056,0.375-0.125,0.781c-0.075,0.413-0.188,1.231-0.25,1.819
		c-0.069,0.588-0.144,1.088-0.169,1.113C117.518,54.369,115.536,54.456,114.567,54.387L114.567,54.387z"
            />
            <path
                className="st1"
                d="M117.224,48.905c-0.375-0.081-0.9-0.206-1.157-0.288c-0.481-0.144-1.682-0.606-1.769-0.688
		c-0.025-0.019,0.05-0.4,0.175-0.844c0.169-0.625,0.531-2.275,0.531-2.438c0-0.131,1.407,0.069,2.032,0.281
		c0.45,0.156,1.375,0.606,1.463,0.713c0.044,0.05,0.038,0.606-0.025,1.732c-0.05,0.906-0.088,1.657-0.088,1.669
		C118.374,49.093,117.812,49.024,117.224,48.905L117.224,48.905z"
            />
            <path
                className="st1"
                d="M111.191,48.486c-2.745-0.563-5.746-0.894-9.897-1.094c-0.794-0.038-0.794-0.038-0.531-0.356
		c0.15-0.181,0.506-0.575,0.794-0.888c1.507-1.613,3.307-3.813,5.32-6.495c0.925-1.231,1.232-1.6,1.338-1.6
		c0.219,0.006,4.97,0.725,5.008,0.763c0.05,0.05,0.263,1.181,0.35,1.894c0.044,0.344,0.081,1.2,0.081,1.907
		c0,1.375-0.094,2.207-0.375,3.501c-0.2,0.9-0.769,2.632-0.863,2.613C112.379,48.73,111.829,48.618,111.191,48.486L111.191,48.486z
		 M111.122,43.817c0.325-0.2,0.6-0.388,0.613-0.425c0.012-0.038-0.169-0.269-0.4-0.506c-0.425-0.444-0.425-0.444-1.275-0.55
		c-1.319-0.169-2.932-0.075-2.932,0.169c0,0.05,0.7,1.263,0.781,1.35c0.031,0.038,2.076,0.313,2.344,0.319
		C110.491,44.173,110.628,44.117,111.122,43.817z"
            />
            <path
                className="st1"
                d="M118.381,43.898c-0.525-0.3-2.226-0.719-2.932-0.719c-0.35,0-0.35,0-0.331-0.544
		c0.019-0.513-0.069-1.932-0.156-2.482c-0.038-0.244-0.038-0.244,0.444-0.206c1.694,0.144,2.638,0.863,3.007,2.294
		c0.094,0.356,0.213,1.757,0.15,1.75C118.55,43.986,118.468,43.948,118.381,43.898L118.381,43.898z"
            />
            <path
                className="st1"
                d="M117.162,38.885c-0.525-0.175-1.457-0.369-2.069-0.431c-0.444-0.038-0.444-0.038-0.556-0.519
		c-0.056-0.263-0.244-0.888-0.406-1.4c-0.175-0.544-0.275-0.938-0.238-0.956c0.031-0.025,0.331-0.038,0.663-0.038
		c0.631,0,1.701,0.2,1.813,0.344c0.038,0.044,0.275,0.556,0.531,1.138c0.469,1.069,0.756,1.863,0.719,1.963
		C117.605,39.01,117.405,38.966,117.162,38.885L117.162,38.885z"
            />
            <path
                className="st1"
                d="M113.104,34.009c-0.163-0.338-0.744-1.225-1.138-1.725c-0.381-0.488-0.381-0.488,0.05-0.956
		c0.294-0.319,0.169-0.381,1.3,0.631c0.706,0.638,1.469,1.456,1.794,1.932c0.156,0.238,0.156,0.238-0.206,0.194
		c-0.325-0.031-1.063,0.025-1.525,0.131C113.242,34.247,113.204,34.215,113.104,34.009z"
            />
            <path
                className="st1"
                d="M110.128,30.327c-0.581-0.494-1.425-1.05-2.157-1.413c-0.344-0.175-0.638-0.325-0.65-0.338
		c-0.019-0.019,0.044-0.1,0.131-0.188c0.156-0.163,0.156-0.163,1.182,0.319c0.832,0.388,2.269,1.113,2.357,1.188
		c0.038,0.031-0.363,0.781-0.413,0.781C110.553,30.671,110.353,30.515,110.128,30.327z"
            />
            <path
                className="st1"
                d="M112.766,27.47c-0.832-0.831-0.832-0.831-1.644-1.069c-0.444-0.138-0.788-0.269-0.763-0.294
		c0.025-0.025,0.444-0.331,0.925-0.681l0.875-0.638l0.313,0.338c0.394,0.419,0.725,1.05,0.932,1.744
		c0.138,0.475,0.281,1.431,0.219,1.431C113.604,28.302,113.223,27.927,112.766,27.47L112.766,27.47z"
            />
        </g>
    </svg>,
    'ouro',
)
