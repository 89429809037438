import { SvgIconProps } from '@mui/material'
import React, { FC, lazy } from 'react'
import { createBrowserRouter, RouteObject } from 'react-router-dom'
import { DashboardIcon, MatchIcon, ProfileIcon, StatsIcon } from 'shared/icons'

const LobbyPage = lazy(async () => await import('pages/Lobby'))
const LoginPage = lazy(async () => await import('pages/Login'))
const InventoryPage = lazy(async () => await import('pages/Inventory'))
const CharacterPage = lazy(async () => await import('pages/Character'))
const LookingForFightPage = lazy(async () => await import('pages/LookingForFight'))
const BankPage = lazy(async () => await import('pages/Bank'))
const FightPage = lazy(async () => await import('pages/Fight'))
const MedicPage = lazy(async () => await import('pages/Medic'))
const QuestsPage = lazy(async () => await import('pages/Quests'))
const AdminPage = lazy(async () => await import('pages/Admin'))
const AdminUsersPage = lazy(async () => await import('pages/Admin/pages/Users'))
const AdminUserPage = lazy(async () => await import('pages/Admin/pages/User'))
const AdminFightsPage = lazy(async () => await import('pages/Admin/pages/Fights'))
const AdminAnalyticsPage = lazy(async () => await import('pages/Admin/pages/Analytics'))
const LeaderboardPage = lazy(async () => await import('pages/Leaderboard'))
// const WheelPage = lazy(async () => await import('pages/Wheel'))

type MUFRouteObject = RouteObject & {
    path: string
    name: string
    icon?: FC<SvgIconProps>
    adminRoute?: boolean
    toShowInTopBar?: boolean
}

const routes: MUFRouteObject[] = [
    {
        path: '/',
        element: <LobbyPage />,
        name: 'Lobby',
        toShowInTopBar: true,
    },
    {
        path: '/login',
        element: <LoginPage />,
        name: 'Login',
    },
    {
        path: '/inventory',
        element: <InventoryPage />,
        name: 'Inventory',
    },
    {
        path: '/inventory/characters/:name',
        element: <CharacterPage />,
        name: 'Character',
    },
    {
        path: '/looking',
        element: <LookingForFightPage />,
        name: 'Looking...',
    },
    {
        path: '/bank',
        element: <BankPage />,
        name: 'Bank',
        toShowInTopBar: true,
    },
    {
        path: '/fight',
        element: <FightPage />,
        name: 'Fight',
        toShowInTopBar: false,
    },
    {
        path: '/medic',
        element: <MedicPage />,
        name: 'Medic',
        toShowInTopBar: true,
    },
    {
        path: '/quests',
        element: <QuestsPage />,
        name: 'Quests',
        toShowInTopBar: false,
    },
    {
        path: '/event',
        element: <LeaderboardPage />,
        name: 'Event',
        toShowInTopBar: true,
    },
    // {
    //     path: '/wheel',
    //     element: <WheelPage />,
    //     name: 'Wheel',
    //     toShowInTopBar: true,
    // },
    {
        path: '/admin/overview',
        element: <AdminPage />,
        name: 'Admin Overview',
        icon: DashboardIcon,
        adminRoute: true,
        toShowInTopBar: true,
    },
    {
        path: '/admin/users',
        element: <AdminUsersPage />,
        icon: ProfileIcon,
        name: 'Users',
        adminRoute: true,
        toShowInTopBar: true,
    },
    {
        path: '/admin/users/:id',
        element: <AdminUserPage />,
        icon: ProfileIcon,
        name: 'User',
        adminRoute: true,
    },
    {
        path: '/admin/fights',
        element: <AdminFightsPage />,
        icon: MatchIcon,
        name: 'Fights',
        adminRoute: true,
        toShowInTopBar: true,
    },
    {
        path: '/admin/analytics',
        element: <AdminAnalyticsPage />,
        icon: StatsIcon,
        name: 'Analytics',
        adminRoute: true,
        toShowInTopBar: true,
    },
]

const router = createBrowserRouter(routes)

const routesToDisplay = routes.filter((route) => route.toShowInTopBar && !route.adminRoute)
const adminRoutesToDisplay = routes.filter((route) => route.adminRoute && route.toShowInTopBar)

export { routes, adminRoutesToDisplay, routesToDisplay }

export default router
