import { createSlice } from '@reduxjs/toolkit'
import { FetchStatus } from 'shared/types'
import { login } from './hooks/login'
import { logout as mxLogout } from '@multiversx/sdk-dapp/utils'
import { register } from './hooks/register'
import { AuthSlice } from 'shared/types'

const initialState: AuthSlice = {
    token: null,
    status: FetchStatus.IDLE,
    error: null,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.token = null
            state.status = FetchStatus.IDLE
            state.error = null
            mxLogout()
        },
        setError: (state, action) => {
            state.status = FetchStatus.FAIL
            state.error = {
                error: 'Unknown',
                message: action.payload,
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            state.status = FetchStatus.PENDING
        }),
            builder.addCase(login.fulfilled, (state, action) => {
                state.token = action.payload
                state.status = FetchStatus.SUCCESS
            }),
            builder.addCase(login.rejected, (state, action) => {
                state.status = FetchStatus.FAIL
                state.error = action.payload
            })
        builder.addCase(register.pending, (state) => {
            state.status = FetchStatus.PENDING
        }),
            builder.addCase(register.fulfilled, (state, action) => {
                state.token = action.payload
                state.status = FetchStatus.SUCCESS
            }),
            builder.addCase(register.rejected, (state, action) => {
                state.status = FetchStatus.FAIL
                state.error = action.payload
            })
    },
})

export const { logout, setError } = authSlice.actions
export default authSlice.reducer
