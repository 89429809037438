import { Arena, FightType } from 'shared/types'

export const arenas: Arena[] = [
    {
        id: 1,
        name: 'Free Arena #1',
        type: FightType.FREE,
        fee: 0,
        prize_pool: 0.028,
    },
    {
        id: 2,
        name: 'Custom Arena #1',
        type: FightType.CUSTOM,
        fee: 0.5,
        prize_pool: 0.95,
    },
    {
        id: 3,
        name: 'Custom Arena #2',
        type: FightType.CUSTOM,
        fee: 1,
        prize_pool: 1.9,
    },
    {
        id: 4,
        name: 'Custom Arena #3',
        type: FightType.CUSTOM,
        fee: 1.5,
        prize_pool: 2.85,
    },
]
