import React from 'react'
import { createSvgIcon } from '@mui/material'

export const DiscordIcon = createSvgIcon(
    <svg
        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-b933ss"
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 56 44"
        data-testid="DiscordIcon"
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="44"
        fill="none"
    >
        <path d="M47.4373 3.68499C43.8123 1.9635 39.9363 0.712392 35.8841 0C35.3864 0.913261 34.805 2.14162 34.4041 3.11877C30.0965 2.46123 25.8285 2.46123 21.6 3.11877C21.1992 2.14162 20.6046 0.913261 20.1025 0C16.0459 0.712392 12.1654 1.9681 8.54045 3.69411C1.22883 14.9087 -0.753226 25.8448 0.237806 36.6256C5.08725 40.3014 9.78695 42.5343 14.4073 43.9955C15.5481 42.4019 16.5656 40.7078 17.4421 38.9224C15.7728 38.2786 14.1739 37.4841 12.6632 36.5617C13.064 36.2603 13.456 35.9452 13.8348 35.621C23.0491 39.9955 33.0608 39.9955 42.1651 35.621C42.5483 35.9452 42.9402 36.2603 43.3366 36.5617C41.8215 37.4886 40.2182 38.2831 38.5489 38.927C39.4254 40.7078 40.4385 42.4064 41.5836 44C46.2085 42.5388 50.9125 40.306 55.762 36.6256C56.9248 24.1279 53.7756 13.2923 47.4373 3.68499ZM18.6974 29.9955C15.9314 29.9955 13.663 27.3745 13.663 24.1827C13.663 20.9909 15.8829 18.3653 18.6974 18.3653C21.512 18.3653 23.7803 20.9863 23.7319 24.1827C23.7362 27.3745 21.512 29.9955 18.6974 29.9955ZM37.3024 29.9955C34.5363 29.9955 32.2679 27.3745 32.2679 24.1827C32.2679 20.9909 34.4878 18.3653 37.3024 18.3653C40.1169 18.3653 42.3853 20.9863 42.3368 24.1827C42.3368 27.3745 40.1169 29.9955 37.3024 29.9955Z" />
    </svg>,
    'DiscordIcon',
)
