import { WS_URL } from 'config'
import React, { FC, createContext, useContext, useEffect, useState } from 'react'
import { Socket, io } from 'socket.io-client'
import { useRootDispatch, useRootSelector } from 'stores/hooks'
import { Notification, NotificationType } from 'shared/types'
import { getBalance } from 'stores/user/hooks'
import { sendErrorToast, sendNotificationToast } from 'shared/toasts'
import { notificationNotAllowedRoutes } from 'shared/constants/notification-not-allowed-routes'
import { insertNotification } from 'stores/user/account.slice'

interface Props {
    children: React.ReactNode
}

interface SocketContextI {
    socket: Socket | null
}

const SocketContext = createContext<SocketContextI>({
    socket: null,
})

const useSocket = () => useContext(SocketContext)

const SocketProvider: FC<Props> = ({ children }) => {
    const [socket, setSocket] = useState<Socket | null>(null)
    const { token } = useRootSelector((state) => state.auth)
    const tokenSelected = useRootSelector((state) => state.account.data.token)
    const dispatch = useRootDispatch()

    const disconnect = () => socket?.disconnect()
    useEffect(() => {
        const initialize = (token: string) => {
            if (socket) return
            const newSocket = io(WS_URL, {
                extraHeaders: {
                    authorization: token,
                },
            })
            setSocket(newSocket)
        }

        if (token) {
            initialize(token)
        }
        return () => {
            disconnect()
        }
        // eslint-disable-next-line
    }, [token])

    const handleNewNotification = (notification: Notification) => {
        dispatch(insertNotification(notification))
        if (notificationNotAllowedRoutes.includes(window.location.pathname)) return
        switch (notification.type) {
            case NotificationType.DEPOSIT:
            case NotificationType.CHARACTER_UPGRADE:
                sendNotificationToast({
                    title: notification.title,
                    message: notification.message,
                })
                dispatch(getBalance({ identifier: tokenSelected }))
                break
            case NotificationType.WITHDRAW:
            case NotificationType.LEVEL:
                sendNotificationToast({
                    title: notification.title,
                    message: notification.message,
                })
                break
        }
    }

    socket?.on('notification', (notification: Notification) => handleNewNotification(notification))
    socket?.on('exception', (err: any) => sendErrorToast({ title: err.status, message: err.message }))

    return (
        <SocketContext.Provider
            value={{
                socket,
            }}
        >
            {children}
        </SocketContext.Provider>
    )
}

export { SocketProvider, useSocket }
