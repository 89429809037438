import React from 'react'
import { createSvgIcon } from '@mui/material'

export const CrownIcon = createSvgIcon(
    <svg width="179" height="153" viewBox="0 0 179 153" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M177.731 36.1505C176.871 35.353 175.66 35.0975 174.544 35.48L171.737 36.4051C168.2 37.5857 164.407 37.3302 161.06 35.6395C160.136 35.1932 159.02 35.1932 158.095 35.7033C157.203 36.1824 156.565 37.1075 156.469 38.1596C154.875 52.4511 148.246 59.4688 142.38 60.0755C137.44 60.6494 132.977 56.8536 130.778 50.25C130.427 49.197 129.566 48.3995 128.483 48.1449C127.399 47.8894 126.283 48.24 125.487 49.0062C119.877 54.62 114.299 56.6941 109.771 54.8436C105.405 53.025 102.249 47.5063 102.249 41.7011C102.249 35.1613 110.537 27.5047 113.725 25.1122C114.713 24.3475 115.191 23.1347 114.936 21.9222C114.68 20.7103 113.788 19.7533 112.577 19.4658C101.293 16.6271 92.3034 1.92053 92.2396 1.76167C91.0615 -0.152652 87.9362 -0.152652 86.7578 1.76167C86.694 1.92053 77.704 16.6271 66.4199 19.4658C65.209 19.7533 64.3171 20.7103 64.0618 21.9222C63.8065 23.1347 64.2843 24.3475 65.2728 25.1122C68.4603 27.5047 76.7484 35.1613 76.7484 41.7011C76.7484 47.5066 73.5921 53.0253 69.2262 54.8436C64.6987 56.6941 59.1202 54.62 53.5109 49.0062C52.714 48.24 51.5981 47.8894 50.5146 48.1449C49.4315 48.3995 48.5706 49.197 48.219 50.25C46.0196 56.8536 41.5887 60.6178 36.6174 60.0755C30.7512 59.4688 24.1224 52.4508 22.5287 38.1596C22.4321 37.1075 21.794 36.1821 20.9021 35.7033C19.9777 35.1932 18.8618 35.1932 17.9371 35.6395C14.5909 37.3302 10.7977 37.5857 7.25993 36.4051L4.45365 35.48C3.33771 35.0975 2.12678 35.353 1.26615 36.1505C0.438041 36.948 0.0552214 38.128 0.343053 39.2448L19.1493 116.923L25.9059 117.72L45.9555 120.113C74.8678 123.622 104.161 123.653 133.041 120.113L153.09 117.72L159.847 116.923L178.653 39.2448C178.942 38.1277 178.559 36.948 177.731 36.1505ZM107.7 88.5948L91.7625 104.545C91.1243 105.183 90.3275 105.502 89.4993 105.502C88.6712 105.502 87.8744 105.183 87.2362 104.545L71.2984 88.5948C70.055 87.351 70.055 85.3409 71.2984 84.0972L87.2362 68.1469C88.4797 66.9024 90.5187 66.9024 91.7621 68.1469L107.7 84.0972C108.944 85.3406 108.944 87.351 107.7 88.5948Z" />
        <path d="M133.806 126.46C119.112 128.247 104.29 129.14 89.4992 129.14C74.7085 129.14 59.8866 128.247 45.1926 126.46L27.5007 124.323L20.7441 123.526L25.8445 144.516C26.1307 145.76 27.1829 146.718 28.4251 146.909L44.3316 149.429C59.3138 151.79 74.422 153.002 89.4992 152.97C104.576 152.97 119.684 151.79 134.667 149.429L150.573 146.909C151.815 146.717 152.867 145.76 153.154 144.516L158.254 123.526L151.498 124.323L133.806 126.46Z" />
    </svg>,
    'CrownIcon',
)
