import { createSlice } from '@reduxjs/toolkit'
import { getUsers, getUsersOnline, getFights, getFightsOngoing, getFightsCount } from './hooks'
import { Account, FetchStatus, Fight } from 'shared/types'
import { getUser } from './hooks/get-user'
import { AdminOverview } from 'shared/types'
import { getFightsMonthly } from './hooks/get-fights-monthly'

interface AdminState {
    user: {
        data?: Account
        status: FetchStatus
        error: any
    }
    users: {
        data: Account[]
        status: FetchStatus
        error: any
    }
    usersOnline: Account[]
    fights: {
        data: Fight[]
        status: FetchStatus
        error: any
    }
    analytics: {
        data: AdminOverview
        status: FetchStatus
        error: any
    }
    fightsOngoing: any
    fightsCount: number
}

const initialState: AdminState = {
    user: {
        status: FetchStatus.IDLE,
        error: undefined,
    },
    users: {
        data: [],
        status: FetchStatus.IDLE,
        error: undefined,
    },
    usersOnline: [],
    fights: {
        data: [],
        status: FetchStatus.IDLE,
        error: undefined,
    },
    analytics: {
        data: {
            monthly: {
                fights: [],
            },
        },
        status: FetchStatus.IDLE,
        error: undefined,
    },
    fightsOngoing: [],
    fightsCount: 0,
}

const userSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUser.pending, (state) => {
            state.user.status = FetchStatus.PENDING
        })
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.user.data = action.payload
            state.user.status = FetchStatus.SUCCESS
        })
        builder.addCase(getUser.rejected, (state) => {
            state.user.status = FetchStatus.FAIL
        })
        builder.addCase(getUsers.pending, (state) => {
            state.users.status = FetchStatus.PENDING
        })
        builder.addCase(getUsers.fulfilled, (state, action) => {
            state.users.data = action.payload
            state.users.status = FetchStatus.SUCCESS
        })
        builder.addCase(getUsers.rejected, (state, action) => {
            state.users.error = action.payload
            state.users.status = FetchStatus.FAIL
        })
        builder.addCase(getUsersOnline.fulfilled, (state, action) => {
            state.usersOnline = action.payload
        })
        builder.addCase(getFights.pending, (state) => {
            state.fights.status = FetchStatus.PENDING
        })
        builder.addCase(getFights.fulfilled, (state, action) => {
            state.fights.data = action.payload
            state.fights.status = FetchStatus.SUCCESS
        })
        builder.addCase(getFights.rejected, (state, action) => {
            state.fights.error = action.payload
            state.fights.status = FetchStatus.FAIL
        })
        builder.addCase(getFightsOngoing.fulfilled, (state, action) => {
            state.fightsOngoing = action.payload
        })
        builder.addCase(getFightsCount.fulfilled, (state, action) => {
            state.fightsCount = action.payload
        })
        builder.addCase(getFightsMonthly.pending, (state) => {
            state.analytics.status = FetchStatus.PENDING
        })
        builder.addCase(getFightsMonthly.fulfilled, (state, action) => {
            state.analytics.data = action.payload
            state.analytics.status = FetchStatus.SUCCESS
        })
        builder.addCase(getFightsMonthly.rejected, (state, action) => {
            state.analytics.error = action.payload
            state.analytics.status = FetchStatus.FAIL
        })
    },
})

// export const { _ } = userSlice.actions

export default userSlice.reducer
