import React from 'react'
import { createSvgIcon } from '@mui/material'

export const TelegramIcon = createSvgIcon(
    <svg
        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-b933ss"
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 53 44"
        data-testid="TelegramIcon"
        xmlns="http://www.w3.org/2000/svg"
        width="53"
        height="44"
        fill="none"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.598 43.5142C41.309 44.0158 42.2255 44.1412 43.0425 43.8332C43.8596 43.523 44.4602 42.8278 44.6413 41.9852C46.5603 33.0004 51.2154 10.2589 52.9621 2.08593C53.0946 1.46993 52.8738 0.829727 52.388 0.418327C51.9022 0.00692624 51.2286 -0.111874 50.628 0.110326C41.3687 3.52473 12.8531 14.1837 1.19777 18.4803C0.457993 18.7531 -0.0234125 19.4615 0.000878574 20.2381C0.027378 21.0169 0.552949 21.6924 1.31039 21.919C6.5374 23.4766 13.3985 25.6436 13.3985 25.6436C13.3985 25.6436 16.605 35.2906 18.2766 40.1966C18.4864 40.8126 18.97 41.2966 19.6082 41.4638C20.2442 41.6288 20.9244 41.455 21.3991 41.0084C24.0844 38.4828 28.236 34.5778 28.236 34.5778C28.236 34.5778 36.124 40.3396 40.598 43.5142ZM16.2848 24.4248L19.9925 36.6084L20.8162 28.893C20.8162 28.893 35.1413 16.0207 43.3075 8.68374C43.546 8.46814 43.5791 8.10734 43.3804 7.85434C43.1839 7.60134 42.8217 7.54193 42.5501 7.71353C33.0854 13.7349 16.2848 24.4248 16.2848 24.4248Z"
        />
    </svg>,
    'TelegramIcon',
)
