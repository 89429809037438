import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { APIEndpoint } from 'shared/types'
import { API_URL } from '../../../config'
import { RootState } from 'stores/store'

export const selectToken = createAsyncThunk(
    'user/me/select-token',
    async ({ identifier }: { identifier: string }, { rejectWithValue, getState }) => {
        // check if token is expired
        const token = (getState() as RootState).auth.token
        if (!token) {
            return rejectWithValue('Token is not found')
        }
        try {
            const { data: quest } = await axios.post(
                API_URL + APIEndpoint.SELECT_TOKEN,
                {
                    identifier,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            )
            return quest
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    },
)
