import React from 'react'
import { createSvgIcon } from '@mui/material'

export const QuestSimpleIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 408.72 408.83">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M0,192.45c1.05-7.21,1.9-14.46,3.18-21.63a206.12,206.12,0,0,1,15.91-50,18.82,18.82,0,0,1,1.17-1.91c11.83,11.85,23.53,23.46,35,35.28.89.92.78,3.43.32,4.94a148.1,148.1,0,0,0-6.74,50.78c2,42.26,18.16,78.31,48.87,107.56a149.59,149.59,0,0,0,78.08,39.83c47.23,8.51,90-2.5,127.09-32.65,31.29-25.41,50-58.67,55.5-98.77,6.19-44.82-5.35-85.1-33.79-120-25.46-31.24-58.71-49.68-98.77-55.55a149.93,149.93,0,0,0-66.61,5.38c-2.71.83-4.48.54-6.58-1.61-11.06-11.32-22.32-22.44-33.86-34,1.83-.89,3.31-1.67,4.84-2.34C152.19,5.29,182-1.47,213.21.27c61.4,3.43,111.7,29.28,150.53,76.84a195,195,0,0,1,44,107.27C413,241,397.25,291.55,360.87,335.23c-30.21,36.28-68.81,59.28-115,69.26-8.42,1.81-17.09,2.42-25.64,3.61-1.3.18-2.58.48-3.87.73h-24a20.53,20.53,0,0,0-3.07-.67c-21.4-1.43-42.16-6-61.77-14.59-68-29.79-109.75-81-124.36-153.94C1.69,232,1.05,224.15,0,216.4Z" />
                <path d="M0,57.51c2.56-7.07,7.66-9.84,15.08-9.65,11,.28,22.05.07,33.43.07.07-1.48.18-2.76.19-4,0-10.11,0-20.22,0-30.33,0-5.71,2.05-10.22,7.44-12.51S66.23.58,70.22,4.62q34.94,35.33,69.89,70.63a14.74,14.74,0,0,1,4.45,11.1c-.12,12.37,0,24.74-.1,37.11a8.75,8.75,0,0,0,2.81,7q31.72,31.5,63.24,63.19a30.4,30.4,0,0,1,3.93,4.49,11.84,11.84,0,0,1-16.5,16.24,29.15,29.15,0,0,1-4.19-3.67Q162,179,130.27,147.17a8.37,8.37,0,0,0-6.62-2.69c-12.37.14-24.74,0-37.11.11A15,15,0,0,1,75.1,140Q40,104.81,4.89,69.67C3,67.76,1.62,65.31,0,63.1Z" />
                <path d="M103.4,168.5c4.22,0,8.21-.14,12.18.07a6.31,6.31,0,0,1,3.74,1.66q12.25,12,24.25,24.29a5.71,5.71,0,0,1,1.4,4.12c-3.62,31,19.47,60.74,50.58,64.92,38,5.11,70.24-24.14,68.64-62.3-1.42-33.75-31.78-59.64-65.82-56.21a5.35,5.35,0,0,1-3.77-1.35Q182.2,131.57,170,119.18a5.07,5.07,0,0,1-1.48-3c-.17-4.24-.07-8.49-.07-12.63C210.68,87,263.8,100.87,293.57,144c28.84,41.75,23.68,98.34-12.42,136-35.12,36.62-91.83,42.61-136.08,14.39C104.08,268.18,86,213.77,103.4,168.5Z" />
            </g>
        </g>
    </svg>,
    'QuestSimpleIcon',
)
