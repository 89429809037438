import { createTheme } from '@mui/material'

interface CustomBreakpoints {
    xs: number
    sm: number
    md: number
    lg: number
    xl: number
    lgPlus: number
}

const defaultTheme = createTheme({
    typography: {
        h1: {
            fontSize: '2.5rem',
            fontWeight: 700,
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 600,
        },
        h3: {
            fontSize: '1.75rem',
            fontWeight: 500,
        },
        h4: {
            fontSize: '1.5rem',
            fontWeight: 400,
        },
        h5: {
            fontSize: '1.25rem',
            fontWeight: 400,
        },
        h6: {
            fontSize: '1rem',
            fontWeight: 400,
        },
        subtitle1: {
            fontSize: '1rem',
            fontWeight: 400,
        },
        subtitle2: {
            fontSize: '0.875rem',
            fontWeight: 400,
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: 'Kanit',
                    color: '#fff',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&.Mui-focused fieldset': {
                        border: 'transparent',
                    },
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    background: '#202020',
                },
            },
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1601,
            lgPlus: 1300,
        } as CustomBreakpoints,
    },
})

const siderBarWidth = 110
const siderBarLeftMargin = 45

export { defaultTheme, siderBarWidth, siderBarLeftMargin }
