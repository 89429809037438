import React from 'react'
import { createSvgIcon } from '@mui/material'

export const ErrorIcon = createSvgIcon(
    <svg width="20" height="96" viewBox="0 0 20 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 76.5C15.2 76.5 19.5 80.8 19.5 86C19.5 91.2 15.2 95.5 10 95.5C4.8 95.5 0.5 91.2 0.5 86C0.5 80.8 4.8 76.5 10 76.5Z" />
        <path d="M10 0.5C4.8 0.5 0.5 4.8 0.5 10L3.8 61.2C4 64.4 6.7 67 10 67C13.3 67 16 64.4 16.2 61.2L19.5 10C19.5 4.8 15.2 0.5 10 0.5Z" />
    </svg>,
    'ErrorIcon',
)
