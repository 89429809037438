import React from 'react'
import { createSvgIcon } from '@mui/material'

export const ArrowDownIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width={34} height={25} viewBox="0 0 34 25" fill="none">
        <path
            d="M17.3462 24.5C17.1293 24.5015 16.9151 24.4515 16.7211 24.3543C16.5272 24.257 16.359 24.1152 16.2305 23.9404L1.20801 3.11653C1.0689 2.92546 0.986897 2.69882 0.971552 2.46297C0.956206 2.22712 1.00815 1.99176 1.12133 1.78428C1.24052 1.57048 1.41524 1.39285 1.62703 1.27014C1.83883 1.14743 2.07983 1.0842 2.32459 1.08713H32.3917C32.6638 1.0863 32.9303 1.16498 33.1583 1.31349C33.7745 1.71732 33.9339 2.52538 33.5116 3.1153L18.4599 23.9409C18.3656 24.0715 18.2481 24.1837 18.1132 24.272C17.8854 24.4214 17.6187 24.5007 17.3462 24.5Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.4884C17.1294 24.4899 16.9154 24.44 16.7216 24.3427C16.5279 24.2454 16.36 24.1036 16.2317 23.9289L1.22237 3.10495C1.0677 2.89184 0.984182 2.63538 0.983688 2.37206C0.984124 2.16305 1.03635 1.95741 1.13569 1.77351C1.25441 1.55986 1.4286 1.38221 1.63987 1.25929C1.85113 1.13638 2.09166 1.07276 2.33607 1.07514H32.3761C32.6481 1.07451 32.9144 1.15332 33.1422 1.3019C33.7584 1.70573 33.917 2.51379 33.4947 3.10371L18.4591 23.9293C18.3648 24.0598 18.2474 24.172 18.1128 24.2604C17.8851 24.4098 17.6186 24.4891 17.3462 24.4884Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.4768C17.1295 24.4781 16.9155 24.4279 16.7219 24.3304C16.5283 24.2329 16.3606 24.0909 16.2325 23.916L1.23675 3.09332C1.09807 2.90205 1.01637 2.67542 1.00111 2.43966C0.985838 2.20389 1.03763 1.96862 1.15048 1.76106C1.2689 1.54742 1.44285 1.36974 1.65393 1.24681C1.86501 1.12388 2.10538 1.06027 2.34964 1.06269H32.3625C32.6343 1.06193 32.9004 1.14076 33.1279 1.28946C33.7441 1.69328 33.9022 2.50134 33.4803 3.09126L18.4579 23.916C18.3638 24.0466 18.2466 24.1588 18.112 24.2471C17.8846 24.3965 17.6183 24.4758 17.3462 24.4751V24.4768Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.4654C17.1297 24.4668 16.916 24.4169 16.7226 24.3196C16.5292 24.2223 16.3616 24.0805 16.2337 23.9059L1.25153 3.08196C1.0969 2.8687 1.01352 2.61208 1.01326 2.34866C1.01367 2.13957 1.06574 1.93382 1.16485 1.7497C1.2832 1.53618 1.45702 1.35859 1.66794 1.23566C1.87886 1.11274 2.11906 1.04905 2.36318 1.05133H32.3494C32.621 1.05133 32.8867 1.13103 33.1135 1.28056C33.7297 1.68439 33.8874 2.49245 33.4659 3.08196L18.457 23.91C18.363 24.0406 18.2457 24.1528 18.1111 24.2411C17.884 24.3904 17.618 24.4697 17.3462 24.4691V24.4654Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.4539C17.1299 24.4553 16.9163 24.4053 16.723 24.308C16.5298 24.2108 16.3624 24.069 16.2346 23.8944L1.26593 3.07048C1.12725 2.87921 1.04555 2.65259 1.03028 2.41682C1.01501 2.18106 1.0668 1.94579 1.17966 1.73823C1.29764 1.52475 1.47114 1.34714 1.6818 1.22421C1.89247 1.10127 2.13245 1.03757 2.37635 1.03985H32.3358C32.6071 1.03918 32.8726 1.11802 33.0995 1.26662C33.7157 1.67045 33.8723 2.47851 33.4512 3.06802L18.4566 23.896C18.3628 24.0265 18.2458 24.1388 18.1116 24.2272C17.8846 24.3765 17.6187 24.4558 17.3471 24.4552L17.3462 24.4539Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.4423C17.1302 24.4435 16.9171 24.3934 16.7242 24.2961C16.5314 24.1989 16.3644 24.0572 16.237 23.8828L1.28029 3.05888C1.12622 2.84552 1.04302 2.58917 1.04243 2.326C1.04278 2.11677 1.09485 1.91087 1.19402 1.72663C1.31187 1.51322 1.48523 1.33564 1.69575 1.2127C1.90627 1.08976 2.14611 1.02603 2.38989 1.02825H32.3223C32.5934 1.02756 32.8588 1.10641 33.0855 1.25502C33.7018 1.65885 33.8575 2.46691 33.4368 3.05642L18.455 23.8844C18.3611 24.0149 18.2442 24.1272 18.1099 24.2156C17.8833 24.3649 17.6176 24.4442 17.3462 24.4436V24.4423Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.4308C17.1302 24.4321 16.917 24.382 16.7242 24.2848C16.5314 24.1875 16.3644 24.0458 16.237 23.8713L1.29467 3.047C1.1565 2.85557 1.07515 2.62904 1.05996 2.39344C1.04477 2.15784 1.09636 1.92274 1.20881 1.71516C1.32639 1.50178 1.49951 1.3242 1.70984 1.20125C1.92016 1.0783 2.15983 1.01456 2.40344 1.01678H32.3103C32.5812 1.0161 32.8463 1.09495 33.0728 1.24355C33.6853 1.65025 33.8427 2.45626 33.4228 3.047L18.4542 23.8717C18.3602 24.0021 18.2433 24.1143 18.1091 24.2028C17.8828 24.3522 17.6174 24.4316 17.3462 24.4308Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.4194C17.1302 24.4208 16.917 24.3708 16.7241 24.2735C16.5312 24.1763 16.3643 24.0344 16.2371 23.8598L1.30907 3.03593C1.1553 2.82245 1.07226 2.56615 1.07162 2.30305C1.07216 2.09384 1.12422 1.88798 1.22321 1.70368C1.34065 1.49037 1.51364 1.31282 1.72382 1.18987C1.93399 1.06691 2.17353 1.00314 2.41702 1.0053H32.2952C32.5659 1.00463 32.8309 1.08349 33.0572 1.23207C33.6706 1.63589 33.8279 2.44396 33.4081 3.03347L18.4546 23.8615C18.3609 23.9919 18.2443 24.1041 18.1103 24.1926C17.8841 24.3419 17.6189 24.4212 17.3479 24.4206L17.3462 24.4194Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3463 24.4091C17.1302 24.4106 16.9169 24.3606 16.724 24.2633C16.5312 24.166 16.3642 24.0242 16.2371 23.8496L1.32387 3.02441C1.16992 2.81101 1.08687 2.55466 1.08643 2.29153C1.0867 2.08207 1.13862 1.87592 1.2376 1.69133C1.35474 1.47831 1.52731 1.30093 1.73704 1.17798C1.94677 1.05504 2.18585 0.991108 2.42895 0.992959H32.282C32.5524 0.992218 32.817 1.07109 33.0429 1.21973C33.6558 1.62355 33.8131 2.43161 33.3937 3.02112L18.4521 23.8491C18.3586 23.9796 18.2419 24.0918 18.1079 24.1803C17.8821 24.3299 17.6171 24.4095 17.3463 24.4091Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.3964C17.1306 24.3976 16.9178 24.3475 16.7254 24.2502C16.5329 24.1529 16.3664 24.0112 16.2395 23.8369L1.33866 3.01298C1.20057 2.82145 1.11923 2.59488 1.10398 2.35925C1.08872 2.12363 1.14015 1.88847 1.25239 1.68072C1.36935 1.46755 1.54189 1.29005 1.75166 1.16708C1.96143 1.04411 2.20059 0.980276 2.44373 0.982349H32.2685C32.5387 0.981616 32.8032 1.06049 33.0289 1.20912C33.1734 1.30162 33.2976 1.42249 33.394 1.56441C33.4905 1.70634 33.5571 1.86636 33.5898 2.03478C33.6226 2.2032 33.6208 2.37652 33.5846 2.54424C33.5485 2.71196 33.4786 2.87058 33.3793 3.01051L18.4509 23.8385C18.3575 23.9689 18.2412 24.0811 18.1075 24.1696C17.8817 24.319 17.6169 24.3983 17.3462 24.3976V24.3964Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3463 24.3848C17.1308 24.3861 16.9181 24.3361 16.7259 24.2387C16.5336 24.1414 16.3673 23.9997 16.2408 23.8253L1.35264 3.00139C1.19898 2.78786 1.11609 2.53157 1.1156 2.2685C1.11601 2.05933 1.16793 1.85348 1.26678 1.66913C1.38364 1.45587 1.55615 1.2783 1.76594 1.15532C1.97574 1.03234 2.21495 0.968552 2.45812 0.970758H32.2549C32.5251 0.970326 32.7894 1.04948 33.0149 1.19835C33.1593 1.29096 33.2834 1.4119 33.3797 1.55385C33.4761 1.69581 33.5426 1.85583 33.5752 2.02423C33.6079 2.19263 33.6061 2.36591 33.5699 2.53359C33.5337 2.70126 33.4638 2.85985 33.3645 2.99974L18.4501 23.8257C18.3567 23.9561 18.2403 24.0683 18.1067 24.1568C17.8812 24.3061 17.6167 24.3854 17.3463 24.3848Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.3733C17.1309 24.3745 16.9184 24.3244 16.7263 24.2271C16.5342 24.1298 16.368 23.9881 16.2416 23.8138L1.367 2.9899C1.21346 2.77634 1.13071 2.52003 1.13037 2.25701C1.13065 2.04787 1.18243 1.84202 1.28114 1.65764C1.39784 1.44469 1.57002 1.26732 1.77942 1.14435C1.98882 1.02139 2.22761 0.957433 2.47043 0.959267H32.2418C32.5115 0.958614 32.7754 1.03749 33.0005 1.18603C33.1448 1.27872 33.2689 1.3997 33.3651 1.54166C33.4613 1.68362 33.5278 1.84362 33.5605 2.01199C33.5932 2.18035 33.5914 2.3536 33.5552 2.52125C33.5191 2.68891 33.4493 2.84749 33.3501 2.98743L18.4493 23.8155C18.3559 23.9458 18.2395 24.058 18.1058 24.1466C17.8807 24.2959 17.6164 24.3752 17.3462 24.3746V24.3733Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.3617C17.1311 24.3629 16.9187 24.3128 16.7268 24.2155C16.5349 24.1182 16.369 23.9765 16.2428 23.8022L1.37973 2.9783C1.24256 2.78625 1.16212 2.55952 1.14759 2.32396C1.13306 2.08839 1.18503 1.85349 1.29757 1.64605C1.4141 1.43313 1.58614 1.25576 1.7954 1.13279C2.00466 1.00983 2.24333 0.945851 2.48604 0.94767H32.2286C32.4982 0.947 32.762 1.02588 32.987 1.17444C33.1312 1.2672 33.2551 1.38822 33.3513 1.5302C33.4475 1.67218 33.5139 1.83217 33.5465 2.00052C33.5791 2.16887 33.5773 2.34208 33.5412 2.50971C33.505 2.67734 33.4353 2.8359 33.3362 2.97583L18.4484 23.8039C18.3554 23.9342 18.2392 24.0464 18.1058 24.135C17.8808 24.2842 17.6167 24.3635 17.3467 24.363L17.3462 24.3617Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.3504C17.1314 24.3518 16.9193 24.3019 16.7275 24.205C16.5358 24.108 16.3699 23.9668 16.2436 23.7929L1.39575 2.96694C1.24256 2.75324 1.15997 2.497 1.15953 2.23406C1.15985 2.02498 1.21148 1.81917 1.30989 1.63469C1.42618 1.42184 1.59799 1.2445 1.80705 1.12152C2.0161 0.998548 2.25459 0.934542 2.49713 0.936315H32.2151C32.4839 0.936228 32.7468 1.01539 32.9709 1.1639C33.1151 1.25665 33.239 1.37761 33.3352 1.51951C33.4314 1.66142 33.4979 1.82132 33.5306 1.98959C33.5633 2.15787 33.5617 2.33103 33.5257 2.49864C33.4898 2.66626 33.4202 2.82486 33.3214 2.96489L18.4472 23.7929C18.3541 23.9232 18.238 24.0354 18.1046 24.124C17.8799 24.2733 17.616 24.3526 17.3462 24.352V24.3504Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.3389C17.1314 24.34 16.9194 24.2898 16.7278 24.1925C16.5362 24.0952 16.3707 23.9536 16.2449 23.7794L1.41013 2.95547C1.27258 2.76368 1.19164 2.53712 1.17654 2.30158C1.16143 2.06605 1.21276 1.83101 1.32469 1.62321C1.44074 1.41043 1.61233 1.23311 1.82118 1.11013C2.03003 0.987148 2.26833 0.923111 2.51069 0.924839H32.2015C32.4707 0.924192 32.7341 1.00308 32.9586 1.15161C33.1024 1.24479 33.2258 1.36602 33.3216 1.50804C33.4174 1.65006 33.4836 1.80994 33.5161 1.97812C33.5487 2.14631 33.547 2.31933 33.5111 2.48684C33.4753 2.65435 33.406 2.8129 33.3074 2.953L18.446 23.7798C18.353 23.9101 18.237 24.0223 18.1038 24.1109C17.8794 24.2602 17.6157 24.3395 17.3462 24.3389Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.327C17.1315 24.3281 16.9196 24.2779 16.7282 24.1805C16.5368 24.0832 16.3714 23.9416 16.2457 23.7675L1.42452 2.94399C1.28712 2.75214 1.20625 2.52563 1.19108 2.29014C1.1759 2.05466 1.22703 1.81964 1.33866 1.61174C1.45454 1.39899 1.62598 1.22168 1.8347 1.09869C2.04341 0.975702 2.28159 0.911651 2.52384 0.913364H32.1904C32.4594 0.912756 32.7225 0.99165 32.9467 1.14013C33.0904 1.23335 33.2139 1.35461 33.3096 1.49664C33.4054 1.63867 33.4715 1.79856 33.504 1.96674C33.5365 2.13492 33.5348 2.30794 33.4989 2.47543C33.463 2.64292 33.3937 2.80145 33.2951 2.94153L18.4472 23.7695C18.3543 23.8998 18.2385 24.012 18.1054 24.1007C17.8804 24.2497 17.6161 24.3285 17.3462 24.327Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3463 24.3158C17.1317 24.3169 16.92 24.2667 16.7288 24.1693C16.5375 24.072 16.3723 23.9304 16.2469 23.7563L1.4385 2.93239C1.30137 2.74041 1.22072 2.51387 1.20569 2.27841C1.19066 2.04296 1.24185 1.808 1.35346 1.60014C1.4691 1.38746 1.64032 1.21017 1.84883 1.08718C2.05734 0.964181 2.29533 0.900098 2.53741 0.901765H32.1748C32.4435 0.901522 32.7062 0.980703 32.9299 1.12935C33.0736 1.22261 33.197 1.34389 33.2927 1.48593C33.3884 1.62798 33.4545 1.78788 33.487 1.95606C33.5194 2.12423 33.5177 2.29724 33.4817 2.46471C33.4458 2.63219 33.3765 2.7907 33.2778 2.93075L18.4435 23.7588C18.3506 23.889 18.2348 24.0012 18.1017 24.0899C17.8778 24.2391 17.6146 24.3185 17.3454 24.3179L17.3463 24.3158Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.3044C17.1318 24.3054 16.9202 24.2551 16.7291 24.1578C16.538 24.0604 16.373 23.9189 16.2477 23.7448L1.45282 2.92092C1.30022 2.70696 1.21795 2.45083 1.21742 2.18803C1.21794 1.97897 1.26956 1.77321 1.36778 1.58866C1.48322 1.37608 1.65422 1.19884 1.86251 1.07584C2.07081 0.952842 2.3086 0.88871 2.5505 0.890287H32.1616C32.43 0.889393 32.6926 0.968016 32.9163 1.11623C33.06 1.20949 33.1833 1.33076 33.2791 1.47281C33.3748 1.61486 33.4409 1.77476 33.4733 1.94294C33.5058 2.11111 33.504 2.28412 33.4681 2.45159C33.4322 2.61907 33.3628 2.77758 33.2642 2.91763L18.4431 23.7456C18.3504 23.8759 18.2347 23.9882 18.1017 24.0768C17.878 24.226 17.6151 24.3054 17.3462 24.3048V24.3044Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.2928C17.1319 24.2937 16.9205 24.2435 16.7295 24.1461C16.5386 24.0488 16.3737 23.9072 16.2486 23.7332L1.46724 2.90932C1.33033 2.71724 1.24982 2.49072 1.23479 2.25532C1.21977 2.01991 1.27082 1.785 1.3822 1.57707C1.49744 1.36449 1.66827 1.18723 1.87644 1.06422C2.08462 0.941213 2.3223 0.877085 2.5641 0.878692H32.1493C32.4177 0.877974 32.6801 0.956894 32.9036 1.10546C33.0472 1.19874 33.1706 1.32005 33.2663 1.46211C33.3619 1.60417 33.428 1.76408 33.4604 1.93225C33.4929 2.10043 33.4911 2.27342 33.4551 2.44088C33.4191 2.60834 33.3498 2.76683 33.2511 2.90686L18.4423 23.7336C18.3497 23.8638 18.2341 23.976 18.1013 24.0648C17.8778 24.2139 17.615 24.2933 17.3462 24.2928Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.2813C17.1323 24.2821 16.9213 24.2317 16.7307 24.1344C16.5402 24.0371 16.3757 23.8956 16.251 23.7217L1.48161 2.89783C1.3447 2.70575 1.26419 2.47923 1.24917 2.24382C1.23414 2.00842 1.2852 1.77351 1.39657 1.56557C1.51193 1.35356 1.68269 1.17684 1.89063 1.05428C2.09856 0.931715 2.33588 0.867908 2.57724 0.869664H32.135C32.403 0.868541 32.6653 0.946881 32.8888 1.09479C33.0324 1.18811 33.1557 1.30943 33.2514 1.45151C33.347 1.59359 33.413 1.7535 33.4454 1.92167C33.4778 2.08985 33.476 2.26283 33.44 2.43028C33.404 2.59772 33.3346 2.75618 33.2359 2.89619L18.4419 23.7242C18.3493 23.8544 18.2337 23.9666 18.1009 24.0553C17.8777 24.2045 17.6151 24.2839 17.3466 24.2833L17.3462 24.2813Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.2698C17.1323 24.2707 16.9213 24.2205 16.7308 24.1232C16.5402 24.0259 16.3757 23.8845 16.251 23.7107L1.496 2.88637C1.35909 2.69429 1.27858 2.46777 1.26356 2.23236C1.24853 1.99696 1.29959 1.76205 1.41096 1.55411C1.52582 1.34167 1.69629 1.16446 1.90412 1.04145C2.11194 0.918429 2.34931 0.854237 2.59081 0.855739H32.1218C32.3896 0.855149 32.6515 0.934072 32.8744 1.08251C33.018 1.17582 33.1413 1.29715 33.237 1.43923C33.3326 1.58131 33.3986 1.74122 33.431 1.90939C33.4634 2.07757 33.4616 2.25055 33.4256 2.41799C33.3896 2.58544 33.3202 2.7439 33.2216 2.8839L18.4402 23.7107C18.3478 23.8408 18.2326 23.9529 18.1001 24.0418C17.877 24.191 17.6146 24.2703 17.3462 24.2698Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3463 24.2582C17.1323 24.2593 16.9212 24.209 16.7306 24.1117C16.54 24.0143 16.3756 23.8727 16.251 23.6987L1.50998 2.8748C1.35799 2.66108 1.27616 2.40541 1.27582 2.14315C1.27605 1.93416 1.32739 1.72839 1.42535 1.54378C1.53992 1.33133 1.71014 1.15406 1.91776 1.03096C2.12539 0.907862 2.36259 0.843577 2.60396 0.844994H32.1083C32.3759 0.844362 32.6378 0.923292 32.8605 1.07176C33.0038 1.16535 33.1268 1.28681 33.2222 1.42891C33.3176 1.57102 33.3834 1.73087 33.4158 1.89894C33.4481 2.06702 33.4463 2.23988 33.4105 2.40725C33.3747 2.57462 33.3055 2.73306 33.2072 2.87316L18.4394 23.7012C18.347 23.8312 18.2318 23.9434 18.0993 24.0323C17.8765 24.1814 17.6143 24.2608 17.3463 24.2603V24.2582Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.2469C17.1322 24.248 16.9211 24.1978 16.7305 24.1004C16.5399 24.0031 16.3755 23.8614 16.251 23.6873L1.52434 2.86342C1.37227 2.64926 1.29045 2.39318 1.29018 2.13053C1.29047 1.9216 1.34165 1.71587 1.4393 1.53116C1.55379 1.31885 1.72389 1.1417 1.93136 1.01867C2.13884 0.895645 2.37588 0.831389 2.61709 0.832786H32.0951C32.363 0.832141 32.625 0.911378 32.8477 1.06037C32.9909 1.15403 33.1139 1.27553 33.2092 1.41766C33.3045 1.55978 33.3703 1.71963 33.4026 1.88768C33.4349 2.05574 33.4331 2.22858 33.3973 2.39592C33.3614 2.56326 33.2923 2.72168 33.194 2.86177L18.4394 23.6898C18.3472 23.8193 18.232 23.9308 18.0997 24.0189C17.8772 24.1681 17.6153 24.2474 17.3475 24.2469H17.3462Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.2353C17.1327 24.2361 16.922 24.1858 16.732 24.0884C16.5419 23.991 16.378 23.8495 16.2539 23.6757L1.5387 2.85182C1.38663 2.63766 1.30481 2.38158 1.30453 2.11893C1.30482 1.91 1.35601 1.70427 1.45366 1.51956C1.56794 1.30735 1.73782 1.13024 1.94509 1.00721C2.15235 0.884185 2.38919 0.819879 2.63022 0.821187H32.0819C32.3492 0.820675 32.6106 0.899605 32.8329 1.04795C32.9759 1.14179 33.0987 1.2634 33.1938 1.40556C33.289 1.54772 33.3546 1.70754 33.3868 1.87555C33.419 2.04355 33.4172 2.21632 33.3814 2.38359C33.3456 2.55087 33.2766 2.70925 33.1784 2.84935L18.4373 23.6774C18.3453 23.8075 18.2303 23.9197 18.098 24.0085C17.8757 24.1576 17.6139 24.237 17.3462 24.2365V24.2353Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.2239C17.1328 24.2247 16.9223 24.1744 16.7324 24.077C16.5425 23.9796 16.3787 23.8381 16.2547 23.6644L1.55269 2.8417C1.4007 2.62707 1.31903 2.37057 1.31894 2.10758C1.31923 1.89864 1.37041 1.69292 1.46806 1.50821C1.58218 1.29603 1.7519 1.11893 1.95904 0.995895C2.16617 0.872862 2.40289 0.808541 2.6438 0.809834H32.0672C32.3342 0.809311 32.5953 0.88825 32.8173 1.0366C32.9603 1.13048 33.083 1.2521 33.1781 1.39426C33.2732 1.53643 33.3388 1.69624 33.3711 1.86423C33.4033 2.03222 33.4014 2.20496 33.3657 2.37223C33.3299 2.5395 33.2609 2.69788 33.1628 2.838L18.4349 23.666C18.3429 23.7961 18.2279 23.9084 18.0956 23.9971C17.8735 24.1462 17.612 24.2256 17.3446 24.2251L17.3462 24.2239Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.2124C17.1329 24.2132 16.9226 24.1628 16.7328 24.0654C16.543 23.9681 16.3793 23.8266 16.2555 23.6529L1.56705 2.82899C1.4153 2.6147 1.33364 2.35868 1.3333 2.0961C1.33359 1.88717 1.38477 1.68145 1.48242 1.49673C1.59626 1.2846 1.76576 1.10749 1.97269 0.984445C2.17962 0.861399 2.41618 0.797066 2.65692 0.798359H32.0553C32.3228 0.79776 32.5844 0.877004 32.8066 1.02595C32.9495 1.11993 33.072 1.24162 33.1671 1.3838C33.2621 1.52598 33.3276 1.68578 33.3598 1.85373C33.392 2.02169 33.3901 2.19439 33.3544 2.36162C33.3186 2.52885 33.2497 2.68722 33.1517 2.82735L18.4369 23.6554C18.3452 23.7856 18.2303 23.8978 18.098 23.9865C17.8763 24.1356 17.6151 24.215 17.3479 24.2145L17.3462 24.2124Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.2007C17.1331 24.2015 16.9229 24.151 16.7333 24.0537C16.5438 23.9563 16.3803 23.8149 16.2568 23.6412L1.58101 2.8173C1.44523 2.62486 1.36548 2.39854 1.35061 2.1635C1.33574 1.92845 1.38635 1.69388 1.4968 1.48587C1.6104 1.2738 1.77967 1.09671 1.9864 0.973661C2.19312 0.850609 2.42949 0.786245 2.67007 0.787493H32.0429C32.3095 0.786918 32.5703 0.865871 32.7918 1.01426C32.9345 1.10841 33.0569 1.23021 33.1517 1.37246C33.2465 1.5147 33.3119 1.67451 33.3439 1.84244C33.3759 2.01037 33.3739 2.18302 33.3381 2.35017C33.3023 2.51733 33.2333 2.67561 33.1353 2.81566L18.4336 23.6437C18.3418 23.7737 18.2271 23.8859 18.0951 23.9748C17.8736 24.1239 17.6125 24.2033 17.3454 24.2028L17.3462 24.2007Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.1892C17.1331 24.1898 16.923 24.1392 16.7335 24.0416C16.544 23.944 16.3808 23.8023 16.2576 23.6285L1.59538 2.80579C1.44393 2.59139 1.36243 2.33541 1.36205 2.07291C1.36239 1.86403 1.41342 1.65835 1.51076 1.47354C1.62422 1.26153 1.79335 1.08448 1.99994 0.961425C2.20652 0.838371 2.44275 0.773974 2.68321 0.775163H32.0289C32.2953 0.774601 32.5558 0.85356 32.777 1.00193C32.9197 1.09606 33.0421 1.21782 33.137 1.36003C33.2319 1.50223 33.2973 1.662 33.3294 1.82991C33.3616 1.99781 33.3597 2.17045 33.3241 2.33764C33.2884 2.50482 33.2196 2.66317 33.1217 2.80333L18.4336 23.6285C18.3418 23.7585 18.2271 23.8707 18.0951 23.9596C17.8739 24.1087 17.613 24.1881 17.3462 24.1876V24.1892Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.1778C17.1334 24.1785 16.9235 24.1281 16.7343 24.0307C16.545 23.9333 16.3819 23.7919 16.2588 23.6183L1.60935 2.79436C1.4581 2.57972 1.37676 2.32364 1.37642 2.06107C1.37676 1.85219 1.42779 1.64651 1.52513 1.4617C1.63839 1.24979 1.8073 1.07278 2.01368 0.949724C2.22005 0.826667 2.45607 0.76222 2.69635 0.76332H32.0158C32.283 0.763305 32.5441 0.843285 32.7655 0.992962C32.9081 1.0872 33.0304 1.20902 33.1251 1.35125C33.2199 1.49347 33.2853 1.65323 33.3174 1.8211C33.3494 1.98897 33.3476 2.16156 33.3119 2.32871C33.2763 2.49586 33.2076 2.65419 33.1098 2.79436L18.4344 23.6224C18.3429 23.7524 18.2285 23.8646 18.0968 23.9535C17.8758 24.1025 17.6152 24.1819 17.3487 24.1815L17.3462 24.1778Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.1662C17.1335 24.1668 16.9238 24.1164 16.7346 24.019C16.5455 23.9216 16.3826 23.7802 16.2596 23.6066L1.62373 2.78272C1.48811 2.59 1.40863 2.36343 1.39412 2.12822C1.37962 1.89301 1.43066 1.65839 1.54157 1.45046C1.65456 1.2386 1.82323 1.06159 2.02941 0.938516C2.23558 0.815447 2.47144 0.750989 2.71155 0.752089H32.0027C32.2686 0.751598 32.5287 0.830561 32.7495 0.978856C32.8921 1.07314 33.0145 1.19501 33.1093 1.3373C33.2041 1.47958 33.2695 1.63939 33.3016 1.80733C33.3337 1.97527 33.3319 2.14794 33.2963 2.31517C33.2607 2.4824 33.192 2.64081 33.0942 2.78108L18.4324 23.6091C18.3409 23.7391 18.2265 23.8513 18.0947 23.9402C17.874 24.0892 17.6137 24.1687 17.3474 24.1682L17.3462 24.1662Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.1549C17.1337 24.1556 16.924 24.105 16.7351 24.0077C16.5461 23.9103 16.3833 23.7689 16.2605 23.5954L1.63567 2.77149C1.48463 2.55692 1.40343 2.301 1.40315 2.0386C1.40335 1.82976 1.45424 1.62408 1.55145 1.43923C1.66431 1.22743 1.83284 1.05045 2.03887 0.927379C2.24491 0.804308 2.48063 0.739816 2.72062 0.740857H31.9895C32.2553 0.740354 32.5151 0.819326 32.7356 0.967624C32.878 1.06201 33.0001 1.18391 33.0947 1.32616C33.1894 1.4684 33.2546 1.62813 33.2866 1.79595C33.3187 1.96377 33.3168 2.1363 33.2813 2.30341C33.2457 2.47051 33.177 2.62882 33.0794 2.76902L18.4308 23.597C18.3393 23.727 18.2249 23.8392 18.0931 23.9282C17.8727 24.0772 17.6127 24.1566 17.3467 24.1562L17.3462 24.1549Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.1432C17.1338 24.1439 16.9244 24.0934 16.7356 23.996C16.5469 23.8986 16.3843 23.7572 16.2617 23.5837L1.65211 2.75939C1.50158 2.5448 1.42083 2.28904 1.42082 2.02692C1.42102 1.81807 1.47191 1.61239 1.56912 1.42755C1.68154 1.21606 1.84959 1.03931 2.05514 0.916373C2.26068 0.793437 2.49591 0.72899 2.73541 0.729992H31.9764C32.2419 0.729502 32.5014 0.80848 32.7216 0.956759C32.8641 1.05122 32.9863 1.17322 33.081 1.31558C33.1756 1.45795 33.2409 1.61781 33.2729 1.78576C33.3049 1.95371 33.303 2.12636 33.2673 2.29356C33.2316 2.46077 33.1628 2.61915 33.065 2.75939L18.4283 23.5841C18.3371 23.7141 18.223 23.8263 18.0915 23.9152C17.8712 24.0642 17.6113 24.1436 17.3454 24.1432H17.3462Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.1319C17.1339 24.1324 16.9246 24.0819 16.736 23.9845C16.5474 23.8871 16.385 23.7457 16.2625 23.5723L1.66729 2.74842C1.51656 2.53372 1.43553 2.27786 1.43518 2.01553C1.43543 1.80674 1.48617 1.60111 1.58307 1.41616C1.69547 1.20434 1.86367 1.0273 2.06946 0.904202C2.27526 0.781104 2.5108 0.71664 2.75059 0.717789H31.9632C32.2286 0.717306 32.488 0.796286 32.708 0.944555C32.8505 1.039 32.9726 1.16093 33.0673 1.30321C33.162 1.44549 33.2274 1.60524 33.2595 1.77311C33.2917 1.94097 33.29 2.11356 33.2545 2.28076C33.2191 2.44795 33.1506 2.60639 33.0531 2.74677L18.4283 23.5727C18.3372 23.7027 18.223 23.8148 18.0914 23.9034C17.8716 24.0527 17.6119 24.1323 17.3462 24.1319Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.1216C17.1341 24.1221 16.925 24.0715 16.7366 23.9742C16.5481 23.8768 16.386 23.7354 16.2637 23.562L1.68001 2.73814C1.52929 2.52344 1.44825 2.26757 1.44791 2.00525C1.44795 1.79573 1.49884 1.58935 1.59621 1.40383C1.70848 1.19225 1.87642 1.01537 2.0819 0.892292C2.28737 0.76921 2.52257 0.704604 2.76208 0.705453H31.9501C32.2152 0.704958 32.4743 0.783947 32.6941 0.932219C32.8361 1.0269 32.9579 1.14895 33.0523 1.29124C33.1466 1.43353 33.2117 1.5932 33.2436 1.76092C33.2756 1.92865 33.2738 2.10106 33.2383 2.26807C33.2028 2.43508 33.1344 2.59335 33.0371 2.73362L18.4283 23.5612C18.337 23.6903 18.223 23.8017 18.0918 23.8899C17.8724 24.0403 17.6127 24.1211 17.3466 24.1216H17.3462Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.1088C17.1342 24.1093 16.9253 24.0588 16.737 23.9614C16.5487 23.864 16.3866 23.7226 16.2646 23.5493L1.6944 2.72539C1.5435 2.51075 1.46232 2.25487 1.46188 1.9925C1.46199 1.78374 1.51259 1.57811 1.60936 1.39313C1.72163 1.18135 1.88977 1.00436 2.09552 0.881391C2.30127 0.758418 2.53678 0.694155 2.77647 0.69558H31.9369C32.2019 0.695142 32.4609 0.77413 32.6805 0.922346C32.8225 1.01713 32.9441 1.13924 33.0384 1.28156C33.1326 1.42389 33.1976 1.58356 33.2295 1.75127C33.2613 1.91897 33.2594 2.09134 33.2239 2.25831C33.1884 2.42528 33.12 2.58351 33.0227 2.72374L18.4283 23.5497C18.3373 23.6789 18.2234 23.7903 18.0923 23.8784C17.8729 24.0276 17.6136 24.1072 17.3483 24.1068L17.3462 24.1088Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3462 24.0973C17.1343 24.0978 16.9255 24.0472 16.7373 23.9498C16.5492 23.8524 16.3873 23.7111 16.2654 23.5378L1.70835 2.71387C1.55787 2.4991 1.47712 2.24323 1.47707 1.98098C1.47768 1.77209 1.52885 1.56644 1.62619 1.38161C1.73794 1.17005 1.90548 0.993126 2.11064 0.870015C2.3158 0.746905 2.55075 0.682311 2.79001 0.683237H31.9238C32.1886 0.683055 32.4473 0.762337 32.6665 0.910826C32.8084 1.00568 32.93 1.12783 33.0242 1.27016C33.1184 1.41248 33.1833 1.57214 33.2151 1.73981C33.247 1.90748 33.2451 2.07982 33.2097 2.24677C33.1743 2.41372 33.1059 2.57195 33.0087 2.71222L18.425 23.5382C18.3342 23.6681 18.2203 23.7802 18.089 23.8689C17.8699 24.0182 17.6109 24.0978 17.3458 24.0973H17.3462Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3466 24.0858C17.1349 24.0864 16.9261 24.0359 16.738 23.9384C16.55 23.841 16.3883 23.6996 16.2666 23.5262L1.72398 2.70233C1.57366 2.48748 1.49293 2.23166 1.49269 1.96945C1.4929 1.79819 1.52699 1.62866 1.59299 1.47063C1.65898 1.3126 1.75559 1.16918 1.87724 1.04864C1.99889 0.928095 2.14318 0.832805 2.30181 0.768255C2.46044 0.703705 2.63027 0.671171 2.80153 0.672526H31.9106C32.1752 0.672108 32.4338 0.751104 32.653 0.899293C32.7947 0.994253 32.9162 1.11646 33.0103 1.25881C33.1044 1.40116 33.1692 1.56079 33.201 1.72843C33.2328 1.89607 33.231 2.06836 33.1956 2.23528C33.1601 2.4022 33.0919 2.5604 32.9948 2.70069L18.4246 23.5287C18.3341 23.6586 18.2205 23.7707 18.0894 23.8594C17.8705 24.0087 17.6116 24.0883 17.3466 24.0878V24.0858Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3466 24.0742C17.135 24.0747 16.9264 24.0241 16.7385 23.9267C16.5506 23.8293 16.389 23.688 16.2674 23.5147L1.7367 2.69079C1.58639 2.47594 1.50566 2.22012 1.50542 1.95791C1.50553 1.74915 1.55613 1.54352 1.6529 1.35854C1.76424 1.14708 1.9314 0.970183 2.13622 0.847059C2.34104 0.723935 2.57569 0.659296 2.81467 0.660165H31.8975C32.1611 0.660145 32.4187 0.739132 32.6369 0.886932C32.7787 0.981924 32.9001 1.10416 32.9941 1.24652C33.0882 1.38888 33.153 1.54852 33.1848 1.71616C33.2165 1.8838 33.2146 2.05608 33.1792 2.22298C33.1438 2.38988 33.0755 2.54807 32.9783 2.68833L18.4217 23.5164C18.331 23.6455 18.2174 23.7569 18.0865 23.845C17.8679 23.9942 17.6093 24.0738 17.3446 24.0734L17.3466 24.0742Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3467 24.0627C17.1354 24.0632 16.9271 24.0129 16.7395 23.9158C16.5519 23.8188 16.3904 23.6779 16.2687 23.5052L1.74948 2.67927C1.59945 2.4643 1.51888 2.20853 1.5186 1.94639C1.5187 1.77506 1.55273 1.60545 1.61871 1.44735C1.6847 1.28924 1.78133 1.14576 1.90305 1.02519C2.02477 0.904615 2.16915 0.809338 2.32788 0.744852C2.48661 0.680365 2.65653 0.647946 2.82785 0.649465H31.8844C32.148 0.64937 32.4056 0.728363 32.6238 0.876232C32.7655 0.971238 32.8868 1.09343 32.9809 1.23572C33.0749 1.37801 33.1397 1.53756 33.1716 1.70511C33.2035 1.87265 33.2018 2.04487 33.1665 2.21174C33.1313 2.37862 33.0633 2.53684 32.9664 2.67722L18.4238 23.5052C18.3332 23.6343 18.2197 23.7456 18.089 23.8339C17.8705 23.9831 17.612 24.0627 17.3475 24.0623L17.3467 24.0627Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3467 24.0513C17.1353 24.0517 16.927 24.0011 16.7395 23.9037C16.5519 23.8062 16.3907 23.6649 16.2695 23.4918L1.76467 2.6679C1.61465 2.45293 1.53407 2.19716 1.5338 1.93501C1.53401 1.76389 1.56804 1.5945 1.63394 1.43658C1.69983 1.27865 1.79629 1.13531 1.91777 1.01479C2.03924 0.894267 2.18334 0.798942 2.34178 0.734295C2.50022 0.669648 2.66988 0.636953 2.84099 0.63809H31.8716C32.1353 0.637818 32.3929 0.716827 32.6111 0.864857C32.7526 0.960067 32.8738 1.08241 32.9676 1.2248C33.0615 1.36718 33.1261 1.52678 33.1578 1.69434C33.1895 1.8619 33.1877 2.03409 33.1524 2.20092C33.117 2.36776 33.049 2.52592 32.9521 2.66625L18.4217 23.4922C18.3316 23.6221 18.2182 23.7342 18.0874 23.8229C17.8695 23.9719 17.6118 24.0515 17.3479 24.0513H17.3467Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3467 24.0393C17.1355 24.0397 16.9273 23.989 16.7399 23.8916C16.5525 23.7941 16.3914 23.6529 16.2704 23.4798L1.77865 2.6563C1.62875 2.44129 1.54832 2.18552 1.54819 1.92342C1.54829 1.75237 1.58221 1.58303 1.64799 1.42514C1.71377 1.26724 1.81011 1.12391 1.93148 1.00339C2.05285 0.882857 2.19684 0.787511 2.35519 0.72283C2.51354 0.658149 2.68311 0.625408 2.85415 0.626491H31.8573C32.1209 0.626218 32.3786 0.705228 32.5967 0.853258C32.7381 0.948573 32.8591 1.07098 32.9529 1.21338C33.0466 1.35579 33.1112 1.51537 33.1429 1.68289C33.1746 1.85042 33.1727 2.02256 33.1374 2.18936C33.1021 2.35617 33.0341 2.51431 32.9373 2.65466L18.4197 23.4827C18.3294 23.6124 18.2161 23.7245 18.0853 23.8134C17.8675 23.9624 17.6097 24.0421 17.3459 24.0418L17.3467 24.0393Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3467 24.0283C17.1356 24.0286 16.9276 23.978 16.7404 23.8805C16.5532 23.7831 16.3923 23.6418 16.2716 23.4687L1.7926 2.64482C1.64282 2.42978 1.56253 2.174 1.56255 1.91194C1.56249 1.74098 1.59624 1.57171 1.66186 1.41385C1.72748 1.25599 1.82367 1.11267 1.9449 0.992128C2.06612 0.871589 2.20999 0.776215 2.36822 0.711495C2.52645 0.646774 2.69591 0.613985 2.86686 0.615014H31.8453C32.109 0.614512 32.3667 0.693542 32.5848 0.84178C32.726 0.937202 32.847 1.05966 32.9406 1.20209C33.0343 1.34451 33.0988 1.50408 33.1304 1.67157C33.162 1.83907 33.1601 2.01117 33.1249 2.17793C33.0896 2.3447 33.0217 2.50282 32.9249 2.64318L18.4205 23.4712C18.2998 23.6439 18.1392 23.785 17.9523 23.8823C17.7654 23.9797 17.5578 24.0304 17.3471 24.0303L17.3467 24.0283Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3466 24.0167C17.1357 24.017 16.9278 23.9663 16.7408 23.8689C16.5537 23.7714 16.393 23.6302 16.2724 23.4571L1.80655 2.63322C1.65695 2.41808 1.57669 2.16237 1.57649 1.90034C1.57644 1.72945 1.61016 1.56024 1.67573 1.40244C1.7413 1.24463 1.83741 1.10135 1.95855 0.980819C2.07969 0.860292 2.22346 0.7649 2.3816 0.700131C2.53973 0.635361 2.70911 0.602492 2.87999 0.603412H31.8322C32.0959 0.602757 32.3536 0.681801 32.5716 0.830179C32.7128 0.925674 32.8336 1.04817 32.9272 1.1906C33.0208 1.33303 33.0853 1.49257 33.1169 1.66003C33.1485 1.8275 33.1467 1.99956 33.1115 2.16631C33.0763 2.33306 33.0084 2.49119 32.9118 2.63158L18.4201 23.4596C18.33 23.5887 18.2169 23.7001 18.0865 23.7882C17.8688 23.9375 17.611 24.0172 17.347 24.0167H17.3466Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3466 24.0052C17.1358 24.0055 16.9281 23.9547 16.7412 23.8573C16.5542 23.7599 16.3937 23.6186 16.2732 23.4457L1.82052 2.62174C1.68621 2.42692 1.60786 2.19901 1.59397 1.96278C1.58008 1.72655 1.63119 1.49103 1.74175 1.28181C1.85231 1.07259 2.01809 0.897662 2.22108 0.776038C2.42407 0.654413 2.65651 0.59074 2.89315 0.591935H31.8207C32.0837 0.591592 32.3407 0.670626 32.5581 0.818702C32.6992 0.914265 32.8199 1.0368 32.9134 1.17925C33.007 1.32169 33.0714 1.48123 33.1029 1.64868C33.1345 1.81613 33.1327 1.98816 33.0975 2.15489C33.0622 2.32161 32.9944 2.47972 32.8978 2.6201L18.418 23.4461C18.3282 23.5758 18.2152 23.6879 18.0849 23.7768C17.8672 23.9261 17.6094 24.0058 17.3454 24.0052H17.3466Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3466 23.9938C17.136 23.994 16.9285 23.9433 16.7417 23.8458C16.555 23.7484 16.3947 23.6072 16.2744 23.4342L1.83449 2.61033C1.68518 2.39507 1.60507 2.13941 1.60484 1.87744C1.605 1.7069 1.63881 1.53807 1.70432 1.38062C1.76983 1.22317 1.86576 1.08019 1.98662 0.959865C2.10747 0.839544 2.25088 0.744248 2.40862 0.679435C2.56636 0.614622 2.73534 0.581567 2.90588 0.582161H31.8063C32.0692 0.581824 32.3261 0.660861 32.5433 0.808928C32.6842 0.904596 32.8049 1.02719 32.8983 1.16965C32.9917 1.31212 33.056 1.47165 33.0876 1.63906C33.1191 1.80647 33.1172 1.97847 33.0821 2.14515C33.0469 2.31184 32.9791 2.46993 32.8826 2.61033L18.4172 23.4383C18.2971 23.6109 18.1369 23.7519 17.9505 23.8493C17.7641 23.9466 17.5569 23.9975 17.3466 23.9975V23.9938Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3466 23.9823C17.1362 23.9826 16.9288 23.9319 16.7422 23.8345C16.5556 23.7371 16.3954 23.596 16.2752 23.4232L1.84843 2.59891C1.69925 2.38362 1.61928 2.12795 1.6192 1.86602C1.61904 1.69542 1.65257 1.52646 1.71787 1.36885C1.78317 1.21124 1.87895 1.06808 1.99972 0.947577C2.12048 0.827077 2.26386 0.731612 2.42162 0.666663C2.57937 0.601713 2.7484 0.568557 2.919 0.569098H31.7931C32.0294 0.568442 32.2614 0.63232 32.464 0.75384C32.6666 0.875359 32.8322 1.04991 32.9429 1.25864C33.0536 1.46738 33.1052 1.70238 33.0921 1.93829C33.079 2.1742 33.0017 2.40205 32.8686 2.59726L18.4164 23.4232C18.2965 23.5955 18.1367 23.7363 17.9508 23.8336C17.7648 23.9309 17.5581 23.982 17.3483 23.9823H17.3466Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3466 23.9707C17.1363 23.971 16.9291 23.9202 16.7427 23.8227C16.5563 23.7253 16.3963 23.5841 16.2765 23.4112L1.8624 2.58731C1.72831 2.39279 1.64999 2.1653 1.63592 1.92947C1.62185 1.69364 1.67257 1.45845 1.78259 1.24938C1.8926 1.04031 2.05772 0.865318 2.26006 0.743362C2.4624 0.621405 2.69425 0.55713 2.9305 0.557497H31.7796C32.0157 0.557066 32.2475 0.621108 32.45 0.742718C32.6524 0.864327 32.8178 1.03889 32.9284 1.24759C33.0389 1.45629 33.0904 1.6912 33.0773 1.927C33.0641 2.1628 32.9869 2.39054 32.8538 2.58566L18.4143 23.4137C18.2946 23.5862 18.135 23.7271 17.949 23.8245C17.763 23.9218 17.5561 23.9727 17.3462 23.9728L17.3466 23.9707Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3467 23.9594C17.1367 23.9593 16.9298 23.9084 16.7438 23.8109C16.5578 23.7135 16.3982 23.5724 16.2786 23.3998L1.87643 2.57592C1.72738 2.36059 1.64755 2.10492 1.64761 1.84304C1.64776 1.63448 1.69776 1.42898 1.79345 1.24367C1.90281 1.03306 2.06793 0.856539 2.27077 0.733381C2.47362 0.610223 2.70641 0.545158 2.94372 0.54529H31.7673C32.0035 0.544867 32.2353 0.60893 32.4377 0.730569C32.6401 0.852208 32.8054 1.0268 32.9159 1.23553C33.0264 1.44425 33.0778 1.67917 33.0645 1.91495C33.0513 2.15073 32.9739 2.37842 32.8408 2.57345L18.4144 23.4015C18.2947 23.574 18.135 23.7149 17.949 23.8123C17.763 23.9096 17.5562 23.9605 17.3463 23.9606L17.3467 23.9594Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3467 23.9478C17.1366 23.9479 16.9297 23.897 16.7437 23.7996C16.5577 23.7021 16.3981 23.561 16.2786 23.3883L1.89038 2.56437C1.74151 2.34895 1.6617 2.09334 1.66156 1.83148C1.66175 1.6228 1.71175 1.41718 1.8074 1.2317C1.91665 1.021 2.08174 0.844411 2.28461 0.721238C2.48749 0.598065 2.72033 0.533057 2.95766 0.533327H31.7554C31.9915 0.532986 32.2232 0.597125 32.4255 0.718823C32.6279 0.840521 32.7931 1.01516 32.9035 1.22389C33.0138 1.43262 33.0651 1.66752 33.0517 1.90325C33.0383 2.13897 32.9608 2.36657 32.8276 2.56149L18.4144 23.3895C18.2948 23.5621 18.1352 23.7032 17.9491 23.8005C17.7631 23.8979 17.5562 23.9487 17.3463 23.9486L17.3467 23.9478Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3467 23.9363C17.1366 23.9366 16.9296 23.8859 16.7436 23.7884C16.5575 23.6909 16.3979 23.5497 16.2786 23.3768L1.90434 2.55413C1.75523 2.33836 1.67542 2.08228 1.67552 1.82001C1.67572 1.61133 1.72571 1.4057 1.82136 1.22023C1.9305 1.00944 2.09557 0.832772 2.29846 0.709584C2.50136 0.586396 2.73426 0.521446 2.97162 0.521852H31.7414C31.9774 0.521662 32.2091 0.58591 32.4113 0.707668C32.6135 0.829425 32.7786 1.00407 32.8888 1.21278C32.9991 1.42148 33.0503 1.65632 33.0369 1.89198C33.0235 2.12763 32.946 2.35516 32.8128 2.55002L18.4123 23.378C18.2931 23.5504 18.1338 23.6913 17.9482 23.7887C17.7627 23.886 17.5562 23.937 17.3467 23.9371V23.9363Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3467 23.9247C17.1366 23.9251 16.9296 23.8744 16.7435 23.7769C16.5574 23.6795 16.3978 23.5381 16.2786 23.3652L1.91832 2.5413C1.76957 2.32584 1.68991 2.07023 1.68991 1.80841C1.68996 1.59976 1.73982 1.39414 1.83534 1.20863C1.94433 0.997728 2.10937 0.820956 2.3123 0.697748C2.51523 0.57454 2.7482 0.509667 2.9856 0.510258H31.7287C31.9645 0.510524 32.1958 0.575119 32.3976 0.697086C32.5994 0.819052 32.7641 0.993769 32.874 1.20242C32.9839 1.41107 33.0348 1.64574 33.0212 1.88116C33.0076 2.11658 32.93 2.34383 32.7968 2.53842L18.4094 23.3664C18.2903 23.5387 18.1312 23.6795 17.9458 23.7769C17.7603 23.8743 17.5541 23.9253 17.3446 23.9256L17.3467 23.9247Z"
            fill="#1E1E1E"
        />
        <path
            d="M17.3466 23.9132C17.137 23.9133 16.9304 23.8624 16.7448 23.765C16.5592 23.6675 16.4 23.5264 16.281 23.3537L1.93227 2.52981C1.78369 2.31427 1.70404 2.05871 1.70386 1.79692C1.70425 1.58871 1.75409 1.38356 1.84928 1.19838C1.95798 0.987846 2.12255 0.811309 2.32494 0.68812C2.52733 0.564931 2.75974 0.499846 2.99667 0.5H31.7155C31.9513 0.500266 32.1826 0.564861 32.3844 0.686828C32.5862 0.808795 32.751 0.983512 32.8608 1.19216C32.9707 1.40081 33.0216 1.63548 33.008 1.8709C32.9944 2.10632 32.9168 2.33357 32.7836 2.52816L18.4094 23.3562C18.2905 23.5284 18.1315 23.6693 17.9462 23.7667C17.7609 23.8641 17.5548 23.915 17.3454 23.9153L17.3466 23.9132Z"
            fill="#1E1E1E"
        />
        <path
            d="M1.64225 2.06116C1.57939 2.17627 1.55047 2.30684 1.55883 2.43773C1.56719 2.56862 1.6125 2.69445 1.6895 2.80062L16.7251 23.6249C16.8432 23.7831 17.0171 23.8903 17.2114 23.9246C17.4058 23.959 17.6059 23.9179 17.771 23.8098C17.8458 23.7605 17.911 23.6982 17.9637 23.6258L33.0264 2.80062C33.0825 2.72305 33.122 2.63478 33.1425 2.5413C33.163 2.44782 33.1641 2.35112 33.1457 2.25721C33.1273 2.16329 33.0898 2.07416 33.0355 1.99535C32.9812 1.91654 32.9113 1.84973 32.8301 1.79907C32.7008 1.71539 32.5498 1.6718 32.3958 1.67377H2.309C2.17334 1.67265 2.03988 1.70801 1.92258 1.77617C1.80528 1.84432 1.70846 1.94275 1.64225 2.06116Z"
            fill="url(#paint0_linear_44_18)"
        />
        <path
            d="M1.65618 2.04833C1.59311 2.16376 1.5641 2.29472 1.57254 2.42599C1.58097 2.55726 1.6265 2.68343 1.70384 2.78984L16.7267 23.6142C16.8446 23.772 17.0183 23.8789 17.2124 23.9131C17.4064 23.9473 17.6062 23.9062 17.771 23.7982C17.8457 23.749 17.9108 23.6866 17.9632 23.6142L33.012 2.78984C33.0678 2.71216 33.107 2.62389 33.1274 2.53047C33.1477 2.43704 33.1488 2.34044 33.1304 2.24661C33.112 2.15278 33.0745 2.06371 33.0204 1.98488C32.9663 1.90606 32.8966 1.83915 32.8156 1.78829C32.6863 1.70417 32.5349 1.66028 32.3806 1.66217H2.32457C2.18877 1.66067 2.05506 1.69573 1.93747 1.76367C1.81987 1.83161 1.72272 1.92993 1.65618 2.04833Z"
            fill="url(#paint1_linear_44_18)"
        />
        <path
            d="M1.67056 2.03677C1.61471 2.13939 1.58534 2.25433 1.58511 2.37117C1.58528 2.51741 1.63157 2.65987 1.71739 2.77828L16.7271 23.6026C16.8449 23.7606 17.0185 23.8676 17.2125 23.9018C17.4066 23.936 17.6063 23.8948 17.771 23.7866C17.8457 23.7374 17.9108 23.6751 17.9632 23.6026L32.9989 2.77828C33.0545 2.70056 33.0937 2.61229 33.114 2.51889C33.1343 2.42548 33.1352 2.32892 33.1168 2.23512C33.0984 2.14133 33.0611 2.05228 33.007 1.97345C32.953 1.89462 32.8834 1.82768 32.8025 1.77673C32.6732 1.69246 32.5218 1.64843 32.3674 1.6502H2.33731C2.20171 1.64886 2.06825 1.68405 1.95093 1.75207C1.83362 1.82009 1.73677 1.91843 1.67056 2.03677Z"
            fill="url(#paint2_linear_44_18)"
        />
        <path
            d="M1.68458 2.02525C1.62201 2.14044 1.59325 2.27097 1.60161 2.40179C1.60996 2.53261 1.6551 2.65841 1.73182 2.76471L16.7264 23.589C16.844 23.7471 17.0175 23.8541 17.2115 23.8884C17.4055 23.9226 17.6052 23.8813 17.7698 23.7731C17.8445 23.7239 17.9096 23.6615 17.9621 23.589L32.9841 2.76676C33.0399 2.68909 33.0791 2.60084 33.0994 2.50744C33.1198 2.41405 33.1208 2.31747 33.1025 2.22366C33.0841 2.12985 33.0468 2.04078 32.9927 1.96194C32.9387 1.8831 32.8691 1.81615 32.7882 1.76521C32.6594 1.68122 32.5085 1.63733 32.3548 1.63909H2.34968C2.21439 1.63797 2.08128 1.67321 1.96428 1.74115C1.84728 1.80908 1.75068 1.9072 1.68458 2.02525Z"
            fill="url(#paint3_linear_44_18)"
        />
        <path
            d="M1.69853 2.01379C1.64307 2.11667 1.61399 2.23171 1.61391 2.3486C1.61418 2.49476 1.6603 2.63715 1.74578 2.75571L16.7284 23.5796C16.8457 23.7378 17.0192 23.8449 17.2131 23.8792C17.407 23.9134 17.6066 23.8721 17.771 23.7637C17.8456 23.7144 17.9106 23.6521 17.9629 23.5796L32.971 2.7553C33.0266 2.67761 33.0658 2.58936 33.0861 2.49597C33.1065 2.40258 33.1075 2.30603 33.0891 2.21224C33.0708 2.11844 33.0334 2.02939 32.9794 1.95054C32.9254 1.8717 32.8559 1.80473 32.775 1.75375C32.6461 1.66955 32.4951 1.62564 32.3412 1.62763H2.36322C2.22799 1.62649 2.09493 1.66174 1.97799 1.72967C1.86105 1.79761 1.76453 1.89574 1.69853 2.01379Z"
            fill="url(#paint4_linear_44_18)"
        />
        <path
            d="M1.71289 2.00223C1.65031 2.11742 1.62155 2.24795 1.62991 2.37877C1.63827 2.50959 1.68341 2.6354 1.76013 2.74169L16.7288 23.5681C16.8461 23.7261 17.0195 23.8332 17.2133 23.8674C17.4072 23.9015 17.6067 23.8601 17.771 23.7517C17.8455 23.7024 17.9105 23.6401 17.9629 23.5677L32.9574 2.74333C33.0129 2.66563 33.0521 2.5774 33.0724 2.48405C33.0926 2.3907 33.0937 2.29419 33.0754 2.20043C33.0571 2.10668 33.0198 2.01764 32.9659 1.93878C32.912 1.85991 32.8426 1.79288 32.7618 1.74178C32.6329 1.65765 32.4819 1.61375 32.328 1.61566H2.37675C2.24158 1.61454 2.1086 1.64984 1.99178 1.71786C1.87496 1.78589 1.77863 1.88412 1.71289 2.00223Z"
            fill="url(#paint5_linear_44_18)"
        />
        <path
            d="M1.72686 1.99093C1.66387 2.10636 1.63488 2.23728 1.64324 2.36852C1.6516 2.49975 1.69697 2.62593 1.7741 2.73244L16.7276 23.5568C16.8446 23.7155 17.0182 23.8232 17.2125 23.8575C17.4067 23.8917 17.6067 23.85 17.771 23.7408C17.8456 23.6916 17.9106 23.6292 17.9629 23.5568L32.9422 2.73244C32.9974 2.65477 33.0363 2.56666 33.0564 2.47349C33.0765 2.38032 33.0774 2.28404 33.0591 2.1905C33.0408 2.09695 33.0037 2.00811 32.95 1.92938C32.8963 1.85065 32.8271 1.78368 32.7466 1.73253C32.6182 1.64782 32.4675 1.60333 32.3137 1.60477H2.38991C2.2549 1.60371 2.1221 1.639 2.00544 1.70695C1.88878 1.77489 1.79255 1.87298 1.72686 1.99093Z"
            fill="url(#paint6_linear_44_18)"
        />
        <path
            d="M1.74083 1.97894C1.68548 2.08187 1.65641 2.19688 1.6562 2.31375C1.65648 2.45991 1.7026 2.6023 1.78807 2.72087L16.73 23.5464C16.8468 23.7042 17.0197 23.8113 17.213 23.8455C17.4064 23.8797 17.6055 23.8386 17.7694 23.7305C17.8439 23.6812 17.9089 23.6189 17.9612 23.5464L32.9299 2.72087C32.9853 2.64309 33.0244 2.55482 33.0446 2.46146C33.0648 2.3681 33.0658 2.2716 33.0475 2.17784C33.0292 2.08409 32.992 1.99504 32.9382 1.91614C32.8843 1.83723 32.815 1.77012 32.7343 1.7189C32.6057 1.63513 32.4552 1.59138 32.3017 1.59319H2.40347C2.26857 1.59217 2.13589 1.62743 2.01931 1.6953C1.90273 1.76317 1.80654 1.86114 1.74083 1.97894Z"
            fill="url(#paint7_linear_44_18)"
        />
        <path
            d="M1.75477 1.96747C1.69199 2.08304 1.66311 2.21398 1.67147 2.34524C1.67983 2.47649 1.72508 2.60272 1.80202 2.70939L16.7304 23.5337C16.8471 23.6921 17.0202 23.7996 17.2139 23.8338C17.4076 23.8681 17.6071 23.8265 17.771 23.7178C17.8455 23.6686 17.9104 23.6063 17.9624 23.5337L32.9159 2.70939C32.9712 2.6316 33.0102 2.54335 33.0304 2.45003C33.0506 2.35671 33.0516 2.26025 33.0333 2.16653C33.0151 2.07281 32.978 1.98378 32.9242 1.90486C32.8705 1.82593 32.8013 1.75876 32.7207 1.70743C32.5922 1.62365 32.4416 1.5799 32.2882 1.58172H2.417C2.28216 1.58068 2.14952 1.61594 2.03301 1.68382C1.91649 1.75169 1.82039 1.84966 1.75477 1.96747Z"
            fill="url(#paint8_linear_44_18)"
        />
        <path
            d="M1.76914 1.95588C1.7063 2.07141 1.67735 2.20234 1.68564 2.33359C1.69392 2.46484 1.7391 2.59109 1.81597 2.6978L16.7312 23.5221C16.8477 23.6805 17.0207 23.7879 17.2143 23.8222C17.4079 23.8564 17.6072 23.8149 17.771 23.7062C17.8452 23.6568 17.9099 23.5945 17.962 23.5221L32.9003 2.6978C32.9556 2.62001 32.9946 2.53177 33.0148 2.43844C33.035 2.34512 33.036 2.24867 33.0177 2.15494C32.9995 2.06122 32.9623 1.97219 32.9086 1.89327C32.8549 1.81434 32.7856 1.74717 32.7051 1.69584C32.5766 1.61195 32.426 1.56819 32.2725 1.57013H2.43054C2.29581 1.56906 2.16328 1.60432 2.04689 1.67221C1.9305 1.74009 1.83455 1.83808 1.76914 1.95588Z"
            fill="url(#paint9_linear_44_18)"
        />
        <path
            d="M1.78313 1.94427C1.72777 2.0472 1.6987 2.16221 1.6985 2.27908C1.69913 2.42538 1.74569 2.56778 1.8316 2.6862L16.7317 23.5105C16.8479 23.6683 17.0203 23.7754 17.2133 23.8097C17.4063 23.8439 17.6051 23.8027 17.7685 23.6946C17.8428 23.6452 17.9075 23.5829 17.9596 23.5105L32.8863 2.6862C32.9416 2.60838 32.9805 2.52014 33.0007 2.42683C33.0208 2.33351 33.0218 2.23708 33.0036 2.14337C32.9853 2.04967 32.9482 1.96065 32.8945 1.88172C32.8408 1.8028 32.7716 1.73561 32.6912 1.68423C32.563 1.60049 32.4129 1.55673 32.2598 1.55852H2.44371C2.3091 1.55752 2.1767 1.59281 2.06045 1.6607C1.9442 1.72858 1.8484 1.82654 1.78313 1.94427Z"
            fill="url(#paint10_linear_44_18)"
        />
        <path
            d="M1.79714 1.93292C1.7343 2.04845 1.70535 2.17938 1.71364 2.31063C1.72192 2.44189 1.7671 2.56813 1.84397 2.67484L16.7321 23.4992C16.8482 23.6569 17.0205 23.764 17.2134 23.7983C17.4062 23.8325 17.6048 23.7913 17.7682 23.6832C17.8424 23.634 17.907 23.5716 17.9588 23.4992L32.8712 2.67484C32.9264 2.59703 32.9653 2.50881 32.9855 2.41553C33.0056 2.32224 33.0066 2.22584 32.9884 2.13215C32.9702 2.03846 32.9332 1.94945 32.8795 1.87051C32.8259 1.79156 32.7568 1.72433 32.6764 1.67288C32.5483 1.58913 32.3981 1.54538 32.2451 1.54717H2.45731C2.32275 1.54615 2.1904 1.58144 2.07422 1.64933C1.95804 1.71722 1.86231 1.81519 1.79714 1.93292Z"
            fill="url(#paint11_linear_44_18)"
        />
        <path
            d="M1.81109 1.92147C1.74824 2.037 1.7193 2.16793 1.72758 2.29918C1.73587 2.43044 1.78105 2.55668 1.85792 2.66339L16.7329 23.4877C16.849 23.6452 17.0212 23.752 17.2139 23.7859C17.4066 23.8198 17.6049 23.7782 17.7677 23.6697C17.8419 23.6206 17.9065 23.5584 17.9583 23.4861L32.8584 2.66339C32.9136 2.58556 32.9524 2.49734 32.9726 2.40407C32.9927 2.31079 32.9937 2.2144 32.9755 2.12074C32.9573 2.02707 32.9203 1.93806 32.8667 1.85912C32.8131 1.78017 32.744 1.71292 32.6637 1.66143C32.5354 1.57732 32.3849 1.53354 32.2315 1.53572H2.47085C2.33636 1.53477 2.2041 1.5701 2.08799 1.63799C1.97188 1.70587 1.87623 1.8038 1.81109 1.92147Z"
            fill="url(#paint12_linear_44_18)"
        />
        <path
            d="M1.82506 1.90993C1.76983 2.01289 1.7409 2.1279 1.74084 2.24474C1.74112 2.3909 1.78724 2.53329 1.87271 2.65185L16.7333 23.4762C16.849 23.6339 17.021 23.741 17.2135 23.7752C17.4061 23.8094 17.6044 23.7681 17.7673 23.6598C17.8415 23.6107 17.9061 23.5485 17.9579 23.4762L32.8444 2.65185C32.8996 2.57406 32.9384 2.48589 32.9585 2.39268C32.9786 2.29946 32.9797 2.20313 32.9615 2.10952C32.9433 2.0159 32.9064 1.92694 32.8529 1.84801C32.7993 1.76908 32.7304 1.70182 32.6501 1.6503C32.5223 1.56651 32.3724 1.52274 32.2196 1.52459H2.484C2.34967 1.52367 2.21757 1.55896 2.10161 1.62678C1.98565 1.69459 1.89012 1.7924 1.82506 1.90993Z"
            fill="url(#paint13_linear_44_18)"
        />
        <path
            d="M1.83902 1.89673C1.78379 1.99969 1.75487 2.11471 1.75481 2.23154C1.75484 2.37761 1.80067 2.51999 1.88585 2.63865L16.7341 23.4642C16.8495 23.6219 17.0213 23.729 17.2137 23.7632C17.4061 23.7974 17.6042 23.7561 17.7669 23.6478C17.841 23.5987 17.9054 23.5365 17.9571 23.4642L32.8305 2.6403C32.8856 2.56247 32.9244 2.47427 32.9446 2.38102C32.9647 2.28778 32.9657 2.19142 32.9475 2.09777C32.9294 2.00412 32.8924 1.91512 32.8389 1.83615C32.7854 1.75719 32.7164 1.68989 32.6361 1.63833C32.5086 1.5546 32.359 1.51083 32.2064 1.51263H2.49755C2.36339 1.51152 2.23141 1.54661 2.11552 1.61421C1.99962 1.68181 1.90411 1.77941 1.83902 1.89673Z"
            fill="url(#paint14_linear_44_18)"
        />
        <path
            d="M1.85299 1.8869C1.79057 2.00256 1.76187 2.13341 1.77015 2.26457C1.77843 2.39573 1.82335 2.52193 1.89982 2.62882L16.7345 23.4531C16.8498 23.6108 17.0214 23.7179 17.2137 23.7521C17.4059 23.7863 17.604 23.745 17.7665 23.6368C17.8406 23.5877 17.905 23.5254 17.9567 23.4531L32.8165 2.62882C32.8716 2.551 32.9104 2.46282 32.9305 2.3696C32.9507 2.27638 32.9517 2.18005 32.9336 2.08642C32.9155 1.99279 32.8786 1.9038 32.8251 1.82481C32.7717 1.74583 32.7028 1.67849 32.6226 1.62686C32.4951 1.54313 32.3454 1.49936 32.1929 1.50115H2.51069C2.37649 1.50027 2.24453 1.53564 2.12877 1.60354C2.013 1.67144 1.91772 1.76934 1.85299 1.8869Z"
            fill="url(#paint15_linear_44_18)"
        />
        <path
            d="M1.86698 1.87531C1.80456 1.99096 1.77586 2.12181 1.78414 2.25297C1.79242 2.38413 1.83735 2.51034 1.91381 2.61723L16.735 23.4416C16.85 23.5992 17.0215 23.7064 17.2136 23.7406C17.4058 23.7748 17.6037 23.7335 17.7661 23.6252C17.8402 23.5761 17.9046 23.5138 17.9563 23.4416L32.8026 2.61723C32.8576 2.53939 32.8964 2.45121 32.9165 2.358C32.9366 2.26479 32.9376 2.16847 32.9195 2.07486C32.9013 1.98124 32.8645 1.89226 32.8111 1.81328C32.7576 1.73429 32.6888 1.66694 32.6086 1.61526C32.4814 1.53153 32.3321 1.48775 32.1798 1.48956H2.52427C2.39013 1.4887 2.25824 1.52409 2.14255 1.59199C2.02685 1.65989 1.93165 1.75777 1.86698 1.87531Z"
            fill="url(#paint16_linear_44_18)"
        />
        <path
            d="M1.88092 1.86395C1.81858 1.97961 1.78989 2.11042 1.7981 2.24155C1.8063 2.37268 1.85107 2.49889 1.92734 2.60587L16.7358 23.4302C16.8506 23.5878 17.0219 23.6949 17.2138 23.7291C17.4057 23.7633 17.6035 23.722 17.7657 23.6138C17.8397 23.5648 17.904 23.5026 17.9555 23.4302L32.7886 2.60587C32.8436 2.52804 32.8823 2.43988 32.9024 2.3467C32.9225 2.25351 32.9235 2.15723 32.9055 2.06363C32.8874 1.97004 32.8506 1.88106 32.7972 1.80206C32.7439 1.72306 32.6751 1.65566 32.5951 1.60391C32.4678 1.52018 32.3185 1.4764 32.1662 1.4782H2.53739C2.40335 1.47732 2.27156 1.51271 2.156 1.58062C2.04043 1.64852 1.94538 1.74643 1.88092 1.86395Z"
            fill="url(#paint17_linear_44_18)"
        />
        <path
            d="M1.89486 1.85227C1.83236 1.96802 1.80357 2.09899 1.81178 2.23028C1.81999 2.36158 1.86485 2.48794 1.94128 2.59501L16.7362 23.4185C16.8507 23.5761 17.0219 23.6833 17.2137 23.7175C17.4056 23.7517 17.6032 23.7104 17.7652 23.6021C17.8393 23.5531 17.9036 23.4909 17.955 23.4185L32.775 2.59501C32.8299 2.51714 32.8685 2.42898 32.8885 2.33581C32.9086 2.24265 32.9096 2.1464 32.8915 2.05284C32.8734 1.95927 32.8367 1.87032 32.7834 1.79131C32.7301 1.71231 32.6614 1.64487 32.5815 1.59305C32.4544 1.50932 32.3052 1.46554 32.153 1.46734H2.55092C2.41702 1.4664 2.28536 1.50168 2.16987 1.56945C2.05438 1.63721 1.95936 1.73493 1.89486 1.85227Z"
            fill="url(#paint18_linear_44_18)"
        />
        <path
            d="M1.90843 1.8409C1.85409 1.94411 1.82588 2.05906 1.82627 2.17571C1.82604 2.32143 1.87096 2.46365 1.95485 2.58282L16.7366 23.4071C16.8511 23.5643 17.0218 23.6711 17.2132 23.7053C17.4046 23.7395 17.6018 23.6985 17.7636 23.5908C17.8373 23.5415 17.9015 23.4793 17.953 23.4071L32.761 2.58282C32.8159 2.50495 32.8546 2.41678 32.8746 2.32362C32.8946 2.23046 32.8956 2.13421 32.8776 2.04065C32.8595 1.94708 32.8227 1.85813 32.7694 1.77912C32.7162 1.70011 32.6475 1.63268 32.5675 1.58085C32.4407 1.49691 32.2915 1.4531 32.1395 1.45515H2.56408C2.43016 1.45432 2.29851 1.48974 2.18309 1.55765C2.06766 1.62556 1.97276 1.72344 1.90843 1.8409Z"
            fill="url(#paint19_linear_44_18)"
        />
        <path
            d="M1.92239 1.82946C1.86805 1.93267 1.83984 2.04763 1.84023 2.16427C1.84061 2.31022 1.88625 2.45246 1.97087 2.57138L16.7374 23.3957C16.8516 23.5532 17.0224 23.6603 17.2139 23.6945C17.4054 23.7288 17.6027 23.6875 17.7644 23.5793C17.8381 23.5302 17.9022 23.468 17.9534 23.3957L32.747 2.57138C32.8021 2.49348 32.8409 2.40524 32.861 2.31198C32.8812 2.21872 32.8823 2.12235 32.8642 2.02865C32.8462 1.93496 32.8094 1.84587 32.7561 1.76675C32.7028 1.68762 32.634 1.62008 32.554 1.56819C32.427 1.48517 32.278 1.44223 32.1263 1.44495H2.57722C2.44355 1.44406 2.31212 1.47933 2.19685 1.54702C2.08158 1.61471 1.98675 1.7123 1.92239 1.82946Z"
            fill="url(#paint20_linear_44_18)"
        />
        <path
            d="M1.93636 1.81782C1.87426 1.93355 1.8457 2.06431 1.8539 2.19539C1.8621 2.32647 1.90674 2.45266 1.98278 2.55974L16.7378 23.3841C16.8519 23.5417 17.0227 23.6489 17.2143 23.6832C17.4058 23.7174 17.6032 23.676 17.7648 23.5677C17.8385 23.5186 17.9026 23.4563 17.9538 23.3841L32.7331 2.55974C32.7879 2.48188 32.8265 2.39376 32.8465 2.30065C32.8666 2.20754 32.8676 2.11135 32.8496 2.01782C32.8317 1.9243 32.795 1.83535 32.7419 1.75631C32.6887 1.67727 32.6202 1.60975 32.5404 1.55778C32.4139 1.4739 32.265 1.43009 32.1132 1.43207H2.59078C2.45701 1.43116 2.32549 1.46656 2.21025 1.53449C2.095 1.60242 2.00034 1.70034 1.93636 1.81782Z"
            fill="url(#paint21_linear_44_18)"
        />
        <path
            d="M1.95032 1.80635C1.8881 1.922 1.85941 2.05272 1.86746 2.1838C1.87552 2.31488 1.92001 2.44111 1.99592 2.54827L16.7382 23.3726C16.8522 23.5304 17.023 23.6378 17.2146 23.672C17.4062 23.7063 17.6037 23.6648 17.7652 23.5562C17.8389 23.5071 17.903 23.4448 17.9542 23.3726L32.7195 2.54827C32.7743 2.47039 32.8129 2.38227 32.8328 2.28917C32.8528 2.19607 32.8539 2.09989 32.8359 2.00638C32.8179 1.91287 32.7813 1.82394 32.7282 1.7449C32.6751 1.66585 32.6066 1.59831 32.5268 1.5463C32.4003 1.46242 32.2514 1.41862 32.0996 1.4206H2.60392C2.47029 1.41983 2.33895 1.4553 2.22386 1.52322C2.10877 1.59115 2.01424 1.68899 1.95032 1.80635Z"
            fill="url(#paint22_linear_44_18)"
        />
        <path
            d="M1.96392 1.79499C1.90975 1.89827 1.88156 2.01318 1.88176 2.1298C1.88222 2.27562 1.92754 2.41775 2.01158 2.53692L16.7391 23.3612C16.8525 23.519 17.0227 23.6265 17.2139 23.661C17.4051 23.6956 17.6022 23.6545 17.7636 23.5465C17.8373 23.4974 17.9014 23.4351 17.9526 23.3629L32.7056 2.53692C32.7604 2.45904 32.7989 2.37092 32.8189 2.27782C32.8389 2.18471 32.84 2.08854 32.822 1.99503C32.804 1.90152 32.7674 1.81259 32.7143 1.73354C32.6612 1.6545 32.5927 1.58696 32.5129 1.53495C32.3867 1.45107 32.2381 1.40725 32.0865 1.40924H2.61711C2.48355 1.40856 2.35229 1.44406 2.23728 1.51198C2.12227 1.5799 2.02781 1.6777 1.96392 1.79499Z"
            fill="url(#paint23_linear_44_18)"
        />
        <path
            d="M1.97787 1.7834C1.9237 1.88667 1.8955 2.00159 1.89571 2.11821C1.89598 2.26405 1.94132 2.40624 2.02552 2.52532L16.7407 23.3496C16.8541 23.5071 17.0244 23.6144 17.2154 23.6486C17.4065 23.6829 17.6033 23.6415 17.7644 23.5333C17.838 23.4841 17.9019 23.4219 17.953 23.3496L32.6916 2.52532C32.7464 2.44744 32.7849 2.35935 32.8049 2.26627C32.8249 2.1732 32.8259 2.07705 32.808 1.98356C32.7901 1.89007 32.7535 1.80114 32.7005 1.72208C32.6474 1.64302 32.579 1.57543 32.4993 1.52336C32.3733 1.43948 32.2248 1.39566 32.0733 1.39765H2.63064C2.49715 1.39703 2.36598 1.43257 2.25105 1.50049C2.13612 1.5684 2.04172 1.66616 1.97787 1.7834Z"
            fill="url(#paint24_linear_44_18)"
        />
        <path
            d="M1.99182 1.77365C1.93746 1.87686 1.90925 1.99182 1.90966 2.10846C1.90953 2.25322 1.95399 2.39451 2.03701 2.51311L16.7399 23.3383C16.8531 23.4957 17.0232 23.6029 17.2141 23.6372C17.4051 23.6714 17.6018 23.6301 17.7628 23.5219C17.8364 23.4727 17.9003 23.4105 17.9513 23.3383L32.678 2.51311C32.7327 2.43519 32.7711 2.34709 32.791 2.25404C32.811 2.16099 32.812 2.06488 32.7941 1.97142C32.7761 1.87796 32.7396 1.78905 32.6866 1.70999C32.6337 1.63092 32.5654 1.5633 32.4858 1.51114C32.3598 1.42732 32.2115 1.38351 32.0602 1.38544H2.64377C2.51017 1.38521 2.379 1.42119 2.26421 1.48955C2.14941 1.5579 2.05528 1.65608 1.99182 1.77365Z"
            fill="url(#paint25_linear_44_18)"
        />
        <path
            d="M2.00537 1.76033C1.95118 1.8636 1.92298 1.97851 1.9232 2.09514C1.92333 2.24094 1.96853 2.38313 2.05261 2.50225L16.7407 23.3266C16.8537 23.4839 17.0236 23.5911 17.2143 23.6254C17.405 23.6597 17.6016 23.6183 17.7624 23.5102C17.8359 23.4611 17.8998 23.3988 17.9509 23.3266L32.664 2.50225C32.7187 2.42436 32.7572 2.33628 32.7771 2.24324C32.7971 2.1502 32.7982 2.0541 32.7803 1.96065C32.7624 1.86719 32.7259 1.77828 32.673 1.6992C32.62 1.62011 32.5518 1.55247 32.4722 1.50028C32.3463 1.41642 32.1979 1.3726 32.0466 1.37458H2.65691C2.52362 1.37419 2.39271 1.40983 2.27802 1.47773C2.16333 1.54564 2.06912 1.64328 2.00537 1.76033Z"
            fill="url(#paint26_linear_44_18)"
        />
        <path
            d="M2.01934 1.74878C1.96517 1.85205 1.93697 1.96697 1.93718 2.08358C1.93764 2.2294 1.98296 2.37153 2.067 2.4907L16.7411 23.315C16.8539 23.4724 17.0237 23.5796 17.2143 23.6139C17.4049 23.6482 17.6013 23.6069 17.762 23.4987C17.8355 23.4496 17.8993 23.3873 17.9501 23.315L32.6505 2.4907C32.7051 2.41279 32.7435 2.32471 32.7635 2.23168C32.7834 2.13865 32.7844 2.04257 32.7665 1.94913C32.7487 1.85569 32.7122 1.76679 32.6593 1.6877C32.6064 1.60862 32.5382 1.54096 32.4587 1.48873C32.333 1.40459 32.1847 1.36061 32.0335 1.36261H2.67006C2.5368 1.36206 2.40589 1.39768 2.29129 1.46569C2.17669 1.5337 2.0827 1.63154 2.01934 1.74878Z"
            fill="url(#paint27_linear_44_18)"
        />
        <path
            d="M2.03331 1.73739C1.97138 1.85309 1.94278 1.9837 1.95069 2.11469C1.9586 2.24568 2.0027 2.3719 2.07808 2.47931L16.744 23.3036C16.8567 23.461 17.0263 23.5682 17.2167 23.6025C17.4072 23.6367 17.6035 23.5954 17.764 23.4873C17.8373 23.4381 17.901 23.3758 17.9517 23.3036L32.6365 2.47931C32.6911 2.40141 32.7295 2.31335 32.7494 2.22035C32.7694 2.12735 32.7704 2.03129 32.7526 1.93787C32.7347 1.84444 32.6983 1.75555 32.6455 1.67645C32.5927 1.59735 32.5245 1.52964 32.4451 1.47735C32.3195 1.39331 32.1714 1.34946 32.0203 1.35164H2.68321C2.5501 1.35106 2.41933 1.38664 2.30487 1.45458C2.19041 1.52252 2.09655 1.62027 2.03331 1.73739Z"
            fill="url(#paint28_linear_44_18)"
        />
        <path
            d="M2.0469 1.72591C1.99272 1.82918 1.96452 1.9441 1.96474 2.06072C1.96492 2.20651 2.01011 2.34869 2.09414 2.46783L16.744 23.2922C16.8565 23.4495 17.0259 23.5568 17.2163 23.5911C17.4067 23.6254 17.6029 23.584 17.7632 23.4758C17.8365 23.4265 17.9001 23.3643 17.951 23.2922L32.623 2.46783C32.6775 2.38989 32.7158 2.30183 32.7356 2.20885C32.7555 2.11587 32.7565 2.01985 32.7387 1.92646C32.7209 1.83306 32.6845 1.74419 32.6317 1.66509C32.579 1.58598 32.5109 1.51824 32.4316 1.46587C32.3061 1.38189 32.1581 1.33804 32.0072 1.34016H2.6968C2.56369 1.33958 2.43293 1.37516 2.31847 1.4431C2.204 1.51104 2.11014 1.60879 2.0469 1.72591Z"
            fill="url(#paint29_linear_44_18)"
        />
        <path
            d="M2.06086 1.71444C1.99935 1.83031 1.97109 1.96095 1.97921 2.09189C1.98733 2.22283 2.03151 2.34897 2.10687 2.45636L16.744 23.2807C16.8563 23.4376 17.0254 23.5446 17.2153 23.5789C17.4052 23.6132 17.6011 23.5721 17.7612 23.4643C17.8344 23.4151 17.8979 23.3529 17.9485 23.2807L32.6094 2.45636C32.6639 2.3784 32.7021 2.29034 32.7219 2.19737C32.7418 2.10439 32.7428 2.00839 32.725 1.91502C32.7071 1.82164 32.6708 1.73278 32.6181 1.65367C32.5653 1.57456 32.4973 1.5068 32.418 1.45439C32.2927 1.37042 32.1449 1.32657 31.9941 1.32869H2.70994C2.57696 1.32816 2.44633 1.36378 2.33201 1.43172C2.21769 1.49966 2.12397 1.59738 2.06086 1.71444Z"
            fill="url(#paint30_linear_44_18)"
        />
        <path
            d="M2.0744 1.70275C2.0204 1.80609 1.99221 1.92096 1.99223 2.03756C1.99206 2.18303 2.03635 2.32508 2.11917 2.44467L16.744 23.269C16.8559 23.4264 17.0251 23.5337 17.2151 23.568C17.4052 23.6024 17.6012 23.5609 17.7611 23.4526C17.8343 23.4034 17.8978 23.3412 17.9485 23.269L32.5955 2.44467C32.6496 2.3668 32.6876 2.27892 32.7073 2.18618C32.7269 2.09343 32.7279 1.99769 32.7101 1.90456C32.6923 1.81143 32.6561 1.72279 32.6036 1.64383C32.5512 1.56488 32.4834 1.4972 32.4044 1.44476C32.2794 1.36067 32.1316 1.31681 31.9809 1.31905H2.72306C2.59034 1.31832 2.4599 1.35362 2.34566 1.42119C2.23143 1.48876 2.13767 1.58607 2.0744 1.70275Z"
            fill="url(#paint31_linear_44_18)"
        />
        <path
            d="M2.08836 1.69137C2.02684 1.80724 1.99858 1.93788 2.0067 2.06882C2.01482 2.19976 2.05901 2.3259 2.13437 2.43329L16.744 23.2588C16.8559 23.416 17.0249 23.5232 17.2148 23.5575C17.4047 23.5918 17.6004 23.5505 17.7603 23.4425C17.8334 23.3932 17.8969 23.331 17.9476 23.2588L32.5819 2.43329C32.6362 2.35532 32.6744 2.26728 32.6942 2.17434C32.714 2.0814 32.7151 1.98545 32.6973 1.8921C32.6795 1.79876 32.6432 1.70992 32.5906 1.63079C32.538 1.55166 32.4701 1.48384 32.3909 1.43133C32.2659 1.34732 32.1183 1.30346 31.9677 1.30562H2.73538C2.6027 1.30525 2.47241 1.34094 2.35845 1.40888C2.24448 1.47683 2.15113 1.57447 2.08836 1.69137Z"
            fill="url(#paint32_linear_44_18)"
        />
        <path
            d="M2.10192 1.67977C2.04811 1.78317 2.01993 1.898 2.01976 2.01457C2.02019 2.16026 2.0652 2.30233 2.14875 2.42169L16.744 23.246C16.8559 23.403 17.0247 23.51 17.2144 23.5443C17.4041 23.5786 17.5997 23.5374 17.7595 23.4296C17.8326 23.3804 17.8961 23.3181 17.9468 23.246L32.5683 2.42169C32.6226 2.3437 32.6607 2.25565 32.6805 2.16273C32.7003 2.0698 32.7013 1.97386 32.6835 1.88054C32.6657 1.78721 32.6295 1.69837 32.5769 1.61924C32.5243 1.54011 32.4565 1.47228 32.3773 1.41972C32.2525 1.3358 32.105 1.29195 31.9546 1.29402H2.74936C2.6166 1.29352 2.4862 1.32916 2.37215 1.39712C2.2581 1.46507 2.16469 1.56278 2.10192 1.67977Z"
            fill="url(#paint33_linear_44_18)"
        />
        <path
            d="M2.11546 1.66842C2.06144 1.77175 2.03325 1.88663 2.0333 2.00323C2.0331 2.1487 2.07739 2.29076 2.16024 2.41034L16.744 23.2347C16.8554 23.3919 17.024 23.4991 17.2136 23.5335C17.4032 23.5678 17.5988 23.5264 17.7582 23.4183C17.8313 23.3691 17.8947 23.3069 17.9452 23.2347L32.5544 2.41034C32.6087 2.33239 32.6469 2.24438 32.6667 2.15146C32.6866 2.05854 32.6877 1.96259 32.6699 1.86925C32.6521 1.77591 32.6159 1.68706 32.5633 1.60791C32.5108 1.52877 32.4429 1.46093 32.3637 1.40838C32.2391 1.32437 32.0917 1.2805 31.9414 1.28267H2.76248C2.62978 1.28221 2.49946 1.31786 2.38548 1.38582C2.27149 1.45377 2.17815 1.55146 2.11546 1.66842Z"
            fill="url(#paint34_linear_44_18)"
        />
        <path
            d="M2.12946 1.65682C2.07545 1.76015 2.04726 1.87503 2.0473 1.99163C2.0474 2.13731 2.09229 2.27943 2.17589 2.39874L16.744 23.2231C16.8553 23.3802 17.0238 23.4875 17.2132 23.5218C17.4027 23.5561 17.5981 23.5148 17.7575 23.4067C17.8304 23.3574 17.8938 23.2952 17.9444 23.2231L32.5408 2.39874C32.5951 2.32076 32.6332 2.23274 32.653 2.13983C32.6727 2.04693 32.6738 1.95102 32.656 1.85771C32.6383 1.7644 32.6021 1.67557 32.5496 1.59643C32.4971 1.51728 32.4293 1.4494 32.3502 1.39678C32.2257 1.31282 32.0785 1.26896 31.9283 1.27107H2.77649C2.64376 1.27047 2.51338 1.30607 2.39938 1.37404C2.28537 1.44201 2.19206 1.53977 2.12946 1.65682Z"
            fill="url(#paint35_linear_44_18)"
        />
        <path
            d="M2.14301 1.64534C2.08177 1.7613 2.05366 1.89189 2.06178 2.02277C2.06989 2.15365 2.11392 2.27977 2.18902 2.38727L16.746 23.2116C16.8571 23.3688 17.0255 23.4761 17.2148 23.5104C17.4042 23.5447 17.5995 23.5034 17.7587 23.3952C17.8317 23.3459 17.895 23.2837 17.9456 23.2116L32.5293 2.38727C32.5835 2.30927 32.6215 2.22127 32.6413 2.1284C32.661 2.03554 32.6621 1.93967 32.6444 1.84639C32.6267 1.75312 32.5905 1.6643 32.5381 1.58514C32.4857 1.50597 32.4181 1.43804 32.3391 1.3853C32.2146 1.30135 32.0674 1.25748 31.9172 1.25959H2.7888C2.65627 1.25918 2.52614 1.29486 2.41236 1.36283C2.29858 1.43079 2.20547 1.52846 2.14301 1.64534Z"
            fill="url(#paint36_linear_44_18)"
        />
        <path
            d="M2.15658 1.63374C2.10273 1.73713 2.07455 1.85197 2.07442 1.96855C2.07419 2.11402 2.11849 2.25609 2.20136 2.37566L16.744 23.2C16.8552 23.3576 17.0241 23.4652 17.214 23.4992C17.4039 23.5333 17.5996 23.4912 17.7587 23.382C17.8314 23.3327 17.8945 23.2704 17.9448 23.1983L32.5137 2.37566C32.5678 2.29764 32.6058 2.20964 32.6255 2.11679C32.6453 2.02393 32.6463 1.92808 32.6286 1.83482C32.6109 1.74156 32.5748 1.65275 32.5224 1.57359C32.47 1.49442 32.4024 1.42647 32.3235 1.37369C32.1994 1.28991 32.0526 1.24605 31.9029 1.24799H2.80155C2.66915 1.24767 2.53917 1.28341 2.42554 1.35137C2.31192 1.41933 2.21894 1.51694 2.15658 1.63374Z"
            fill="url(#paint37_linear_44_18)"
        />
        <path
            d="M2.17054 1.62247C2.11648 1.72579 2.08829 1.84068 2.08838 1.95728C2.08852 2.10289 2.13325 2.24497 2.21655 2.36439L16.7473 23.1887C16.858 23.3459 17.026 23.4532 17.2152 23.4876C17.4043 23.5219 17.5994 23.4805 17.7583 23.3724C17.831 23.323 17.8941 23.2608 17.9444 23.1887L32.4998 2.36439C32.5538 2.28643 32.5919 2.19849 32.6116 2.10568C32.6314 2.01287 32.6324 1.91706 32.6148 1.82384C32.5971 1.73061 32.561 1.64184 32.5087 1.56269C32.4564 1.48354 32.3888 1.41561 32.31 1.36284C32.1859 1.27891 32.0391 1.23504 31.8893 1.23713H2.81469C2.68244 1.23675 2.55258 1.27242 2.43908 1.34032C2.32559 1.40821 2.23275 1.50576 2.17054 1.62247Z"
            fill="url(#paint38_linear_44_18)"
        />
        <path
            d="M2.18408 1.60928C2.12259 1.72543 2.09431 1.85629 2.10235 1.98747C2.1104 2.11865 2.15445 2.24507 2.22968 2.35284L16.7477 23.1768C16.8582 23.3339 17.026 23.4413 17.2151 23.4757C17.4041 23.5102 17.599 23.4689 17.7578 23.3608C17.8306 23.3113 17.8937 23.2489 17.9439 23.1768L32.4866 2.35284C32.5406 2.27483 32.5786 2.18685 32.5983 2.09402C32.6181 2.00119 32.6191 1.90537 32.6015 1.81212C32.5838 1.71888 32.5478 1.63008 32.4955 1.5509C32.4432 1.47171 32.3756 1.40372 32.2968 1.35088C32.1729 1.26695 32.0262 1.22308 31.8765 1.22517H2.82781C2.69574 1.22465 2.56602 1.26014 2.4526 1.32781C2.33918 1.39549 2.24634 1.4928 2.18408 1.60928Z"
            fill="url(#paint39_linear_44_18)"
        />
        <path
            d="M2.19763 1.59924C2.14373 1.70262 2.11555 1.81747 2.11546 1.93405C2.11539 2.0795 2.15968 2.22152 2.2424 2.34116L16.7477 23.1651C16.8579 23.3222 17.0255 23.4297 17.2144 23.4641C17.4032 23.4986 17.598 23.4573 17.7566 23.3491C17.8294 23.2996 17.8924 23.2373 17.9427 23.1651L32.4726 2.34116C32.5266 2.26316 32.5646 2.1752 32.5843 2.08239C32.604 1.98959 32.6051 1.89379 32.5875 1.80057C32.5699 1.70734 32.5339 1.61855 32.4817 1.53934C32.4294 1.46014 32.362 1.39211 32.2832 1.3392C32.1589 1.25508 32.0118 1.21107 31.8617 1.21308H2.84095C2.70876 1.21297 2.57904 1.24886 2.4657 1.31689C2.35237 1.38492 2.25969 1.48253 2.19763 1.59924Z"
            fill="url(#paint40_linear_44_18)"
        />
        <path
            d="M2.21119 1.58787C2.1573 1.69125 2.12912 1.80609 2.12903 1.92268C2.12935 2.06826 2.17406 2.21028 2.2572 2.32979L16.7489 23.1537C16.859 23.3108 17.0265 23.4182 17.2152 23.4527C17.4039 23.4871 17.5986 23.4458 17.757 23.3377C17.8297 23.2883 17.8926 23.2259 17.9427 23.1537L32.4591 2.32979C32.513 2.25177 32.5509 2.16381 32.5706 2.07101C32.5903 1.97822 32.5914 1.88244 32.5738 1.78923C32.5562 1.69602 32.5202 1.60723 32.468 1.52803C32.4158 1.44882 32.3484 1.38077 32.2697 1.32783C32.1458 1.24401 31.9994 1.20001 31.8498 1.20171H2.85411C2.72197 1.20158 2.5923 1.23747 2.47902 1.3055C2.36575 1.37354 2.27315 1.47116 2.21119 1.58787Z"
            fill="url(#paint41_linear_44_18)"
        />
        <path
            d="M2.2252 1.57627C2.17112 1.67959 2.14293 1.79448 2.14304 1.91108C2.14303 2.05665 2.18761 2.19873 2.2708 2.3182L16.7494 23.1421C16.8592 23.2993 17.0266 23.4067 17.2152 23.4412C17.4038 23.4756 17.5984 23.4343 17.7567 23.3262C17.8293 23.2767 17.8922 23.2143 17.9423 23.1421L32.4439 2.3182C32.4978 2.24016 32.5357 2.15219 32.5553 2.05941C32.575 1.96663 32.5761 1.87086 32.5584 1.77767C32.5408 1.68447 32.5049 1.5957 32.4527 1.51649C32.4006 1.43728 32.3332 1.36922 32.2545 1.31623C32.1309 1.23237 31.9845 1.18836 31.8351 1.19011H2.86688C2.73493 1.19008 2.60546 1.22601 2.49239 1.29405C2.37933 1.36209 2.28696 1.45966 2.2252 1.57627Z"
            fill="url(#paint42_linear_44_18)"
        />
        <path
            d="M2.23876 1.56483C2.17758 1.68089 2.14941 1.8115 2.15731 1.94246C2.1652 2.07342 2.20886 2.1997 2.28354 2.30757L16.7494 23.1315C16.859 23.2885 17.0261 23.396 17.2145 23.4304C17.4029 23.4649 17.5973 23.4236 17.7554 23.3155C17.8281 23.266 17.891 23.2037 17.9411 23.1315L32.432 2.30757C32.4859 2.22954 32.5237 2.1416 32.5434 2.04884C32.5631 1.95608 32.5641 1.86034 32.5466 1.76717C32.529 1.67399 32.4931 1.58522 32.441 1.50599C32.3889 1.42677 32.3216 1.35866 32.243 1.30561C32.1195 1.22181 31.9733 1.1778 31.824 1.17949H2.88004C2.74821 1.17936 2.61885 1.21517 2.50586 1.28306C2.39287 1.35096 2.30053 1.44837 2.23876 1.56483Z"
            fill="url(#paint43_linear_44_18)"
        />
        <path
            d="M2.25228 1.5532C2.19138 1.66923 2.16343 1.79973 2.17147 1.93053C2.17951 2.06132 2.22323 2.18742 2.29788 2.29512L16.7506 23.119C16.8599 23.2761 17.027 23.3836 17.2153 23.4181C17.4036 23.4526 17.5979 23.4112 17.7558 23.3031C17.8284 23.2537 17.8912 23.1913 17.9411 23.119L32.4184 2.29512C32.4723 2.21719 32.5103 2.12936 32.53 2.03669C32.5498 1.94402 32.5511 1.84835 32.5337 1.75519C32.5163 1.66204 32.4807 1.57325 32.4288 1.49395C32.3769 1.41465 32.3099 1.34641 32.2315 1.29316C32.1081 1.20936 31.962 1.16535 31.8129 1.16704H2.89314C2.76128 1.16697 2.63191 1.2029 2.51898 1.27095C2.40604 1.339 2.31383 1.43659 2.25228 1.5532Z"
            fill="url(#paint44_linear_44_18)"
        />
        <path
            d="M2.26583 1.54185C2.20493 1.65788 2.17698 1.78838 2.18502 1.91918C2.19306 2.04997 2.23678 2.17607 2.31143 2.28377L16.751 23.1077C16.8603 23.2648 17.0272 23.3722 17.2155 23.4067C17.4037 23.4412 17.5979 23.3999 17.7558 23.2917C17.8282 23.2422 17.8908 23.1798 17.9407 23.1077L32.4048 2.28377C32.4588 2.20568 32.4968 2.11767 32.5165 2.02482C32.5362 1.93197 32.5374 1.83613 32.5198 1.74284C32.5023 1.64954 32.4665 1.56066 32.4143 1.48131C32.3622 1.40197 32.2949 1.33374 32.2163 1.28058C32.0926 1.1978 31.9465 1.1548 31.7977 1.15733H2.90628C2.77465 1.15714 2.64547 1.19284 2.53262 1.26059C2.41977 1.32834 2.32753 1.42558 2.26583 1.54185Z"
            fill="url(#paint45_linear_44_18)"
        />
        <path
            d="M2.2794 1.53038C2.22549 1.63375 2.19731 1.7486 2.19724 1.86519C2.19729 2.01068 2.24171 2.15271 2.32459 2.2723L16.7518 23.0946C16.8609 23.2516 17.0276 23.359 17.2157 23.3935C17.4037 23.428 17.5977 23.3867 17.7554 23.2786C17.8278 23.2291 17.8904 23.1667 17.9403 23.0946L32.3913 2.2723C32.445 2.19423 32.4828 2.10631 32.5024 2.0136C32.522 1.92088 32.523 1.82521 32.5055 1.73208C32.488 1.63895 32.4522 1.5502 32.4003 1.47096C32.3483 1.39171 32.2812 1.32353 32.2027 1.27033C32.0797 1.18648 31.9338 1.14246 31.7849 1.14422H2.91903C2.78735 1.14424 2.65819 1.18022 2.54547 1.24827C2.43274 1.31632 2.34074 1.41387 2.2794 1.53038Z"
            fill="url(#paint46_linear_44_18)"
        />
        <path
            d="M2.29295 1.51891C2.23904 1.62229 2.21086 1.73714 2.21078 1.85372C2.21087 1.99915 2.25514 2.14113 2.33772 2.26083L16.7522 23.0847C16.8187 23.1807 16.9075 23.2591 17.0109 23.3132C17.1143 23.3673 17.2293 23.3956 17.346 23.3956C17.4627 23.3956 17.5777 23.3673 17.6812 23.3132C17.7846 23.2591 17.8733 23.1807 17.9398 23.0847L32.3777 2.26083C32.4521 2.15169 32.4951 2.02425 32.502 1.89238C32.5089 1.76051 32.4795 1.62926 32.4171 1.51293C32.3546 1.3966 32.2614 1.29964 32.1476 1.2326C32.0338 1.16557 31.9038 1.13103 31.7718 1.13275H2.93299C2.80123 1.13261 2.67194 1.16852 2.55912 1.23658C2.4463 1.30465 2.35426 1.40228 2.29295 1.51891Z"
            fill="url(#paint47_linear_44_18)"
        />
        <path
            d="M2.30648 1.50732C2.25258 1.61069 2.22439 1.72554 2.22432 1.84212C2.22441 1.98756 2.26868 2.12953 2.35126 2.24924L16.7522 23.0731C16.8606 23.2305 17.0271 23.3384 17.215 23.3732C17.4029 23.408 17.5969 23.3669 17.7545 23.2588C17.8268 23.2093 17.8893 23.1469 17.939 23.0748L32.3642 2.24924C32.4386 2.14019 32.4817 2.0128 32.4887 1.88094C32.4958 1.74909 32.4665 1.61783 32.4041 1.50148C32.3417 1.38512 32.2485 1.28812 32.1348 1.22106C32.021 1.154 31.8911 1.11944 31.759 1.12115H2.94488C2.81339 1.12127 2.68442 1.1573 2.57192 1.22535C2.45941 1.29341 2.36762 1.3909 2.30648 1.50732Z"
            fill="url(#paint48_linear_44_18)"
        />
        <path
            d="M2.32009 1.49585C2.26619 1.59922 2.238 1.71407 2.23793 1.83065C2.23826 1.97617 2.28282 2.11815 2.36569 2.23777L16.7535 23.0617C16.8197 23.1576 16.9082 23.236 17.0114 23.2902C17.1147 23.3443 17.2295 23.3726 17.3461 23.3726C17.4626 23.3726 17.5774 23.3443 17.6807 23.2902C17.7839 23.236 17.8724 23.1576 17.9386 23.0617L32.3507 2.23777C32.4252 2.12878 32.4685 2.0014 32.4756 1.86953C32.4827 1.73766 32.4535 1.60636 32.3911 1.48996C32.3287 1.37357 32.2355 1.27655 32.1218 1.20949C32.008 1.14243 31.878 1.1079 31.7459 1.10968H2.95808C2.82663 1.10974 2.6977 1.14574 2.58524 1.21381C2.47279 1.28188 2.3811 1.37941 2.32009 1.49585Z"
            fill="url(#paint49_linear_44_18)"
        />
        <path
            d="M2.3336 1.48597C2.27352 1.60178 2.24599 1.73172 2.25395 1.86194C2.26191 1.99217 2.30506 2.11779 2.37879 2.22542L16.7538 23.0493C16.82 23.1452 16.9085 23.2235 17.0116 23.2776C17.1148 23.3317 17.2295 23.36 17.346 23.36C17.4625 23.36 17.5772 23.3317 17.6804 23.2776C17.7835 23.2235 17.872 23.1452 17.9382 23.0493L32.3375 2.22542C32.4119 2.11643 32.455 1.98912 32.462 1.85734C32.4691 1.72556 32.4399 1.59436 32.3776 1.47803C32.3153 1.3617 32.2222 1.2647 32.1086 1.19757C31.995 1.13045 31.8651 1.09578 31.7332 1.09734H2.97076C2.83918 1.09769 2.71021 1.1341 2.59787 1.20261C2.48554 1.27113 2.39413 1.36913 2.3336 1.48597Z"
            fill="url(#paint50_linear_44_18)"
        />
        <path
            d="M2.34719 1.47289C2.28663 1.589 2.25884 1.71941 2.26681 1.85012C2.27477 1.98083 2.31818 2.1069 2.39238 2.21481L16.7543 23.0387C16.8204 23.1345 16.9089 23.2127 17.0119 23.2668C17.115 23.3209 17.2296 23.3491 17.346 23.3491C17.4624 23.3491 17.5771 23.3209 17.6802 23.2668C17.7832 23.2127 17.8716 23.1345 17.9378 23.0387L32.3239 2.21481C32.3985 2.10588 32.4417 1.97858 32.4489 1.84678C32.456 1.71498 32.4269 1.58374 32.3646 1.46738C32.3023 1.35101 32.2092 1.25397 32.0956 1.18685C31.9819 1.11973 31.852 1.0851 31.72 1.08672H2.98395C2.85267 1.08682 2.72392 1.12286 2.61167 1.19093C2.49942 1.25901 2.40796 1.35652 2.34719 1.47289Z"
            fill="url(#paint51_linear_44_18)"
        />
        <path
            d="M2.36033 1.46128C2.30659 1.56472 2.27842 1.67952 2.27817 1.79609C2.27894 1.94166 2.32377 2.08359 2.40675 2.2032L16.7559 23.0271C16.8218 23.123 16.91 23.2015 17.0129 23.2557C17.1159 23.3099 17.2305 23.3382 17.3469 23.3382C17.4632 23.3382 17.5778 23.3099 17.6808 23.2557C17.7837 23.2015 17.8719 23.123 17.9378 23.0271L32.3104 2.2032C32.3849 2.09433 32.4281 1.9671 32.4353 1.83538C32.4425 1.70365 32.4134 1.57248 32.3512 1.45614C32.289 1.3398 32.1961 1.24275 32.0825 1.17557C31.969 1.10839 31.8392 1.07364 31.7073 1.07512H2.99667C2.86546 1.07529 2.7368 1.11136 2.62463 1.17943C2.51246 1.2475 2.42106 1.34497 2.36033 1.46128Z"
            fill="url(#paint52_linear_44_18)"
        />
        <path
            d="M2.36033 1.46128C2.30659 1.56472 2.27842 1.67952 2.27817 1.79609C2.27894 1.94166 2.32377 2.08359 2.40675 2.2032L16.7559 23.0271C16.8218 23.123 16.91 23.2015 17.0129 23.2557C17.1159 23.3099 17.2305 23.3382 17.3469 23.3382C17.4632 23.3382 17.5778 23.3099 17.6808 23.2557C17.7837 23.2015 17.8719 23.123 17.9378 23.0271L32.3104 2.2032C32.3849 2.09433 32.4281 1.9671 32.4353 1.83538C32.4425 1.70365 32.4134 1.57248 32.3512 1.45614C32.289 1.3398 32.1961 1.24275 32.0825 1.17557C31.969 1.10839 31.8392 1.07364 31.7073 1.07512H2.99667C2.86546 1.07529 2.7368 1.11136 2.62463 1.17943C2.51246 1.2475 2.42106 1.34497 2.36033 1.46128Z"
            fill="url(#paint53_linear_44_18)"
        />
        <path
            d="M2.36033 1.79109C2.30662 1.89233 2.27842 2.00514 2.27817 2.11974C2.27926 2.26259 2.32414 2.40168 2.40675 2.51823L16.7551 22.926C16.8652 23.0801 17.0312 23.1852 17.2177 23.2188C17.4041 23.2524 17.5963 23.2119 17.7533 23.1059C17.8244 23.0575 17.8861 22.9965 17.9353 22.926L32.3104 2.51823C32.3636 2.44181 32.4011 2.35551 32.4205 2.26441C32.4399 2.17331 32.4409 2.07924 32.4234 1.98775C32.4059 1.89627 32.3702 1.8092 32.3186 1.73169C32.267 1.65417 32.2003 1.58777 32.1226 1.53639C31.9999 1.45418 31.855 1.41121 31.7073 1.41315H2.99667C2.86632 1.41262 2.73827 1.4475 2.6262 1.51406C2.51412 1.58062 2.42223 1.67638 2.36033 1.79109Z"
            fill="url(#paint54_linear_44_18)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_44_18"
                x1={17.3578}
                y1={1.67377}
                x2={17.3578}
                y2={23.9363}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_44_18"
                x1={17.3577}
                y1={1.66217}
                x2={17.3577}
                y2={23.9247}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_44_18"
                x1={17.3577}
                y1={1.65061}
                x2={17.3577}
                y2={23.9132}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_44_18"
                x1={17.3578}
                y1={1.63909}
                x2={17.3578}
                y2={23.9017}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint4_linear_44_18"
                x1={17.3578}
                y1={1.62763}
                x2={17.3578}
                y2={23.8902}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint5_linear_44_18"
                x1={17.3577}
                y1={1.61607}
                x2={17.3577}
                y2={23.8786}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint6_linear_44_18"
                x1={17.3577}
                y1={1.60477}
                x2={17.3577}
                y2={23.8673}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint7_linear_44_18"
                x1={17.3577}
                y1={1.59319}
                x2={17.3577}
                y2={23.8558}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint8_linear_44_18"
                x1={17.3577}
                y1={1.58172}
                x2={17.3577}
                y2={23.8443}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint9_linear_44_18"
                x1={17.3577}
                y1={1.57013}
                x2={17.3577}
                y2={23.8327}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint10_linear_44_18"
                x1={17.3577}
                y1={1.55852}
                x2={17.3577}
                y2={23.8211}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint11_linear_44_18"
                x1={17.3578}
                y1={1.54717}
                x2={17.3578}
                y2={23.8097}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint12_linear_44_18"
                x1={17.3577}
                y1={1.53572}
                x2={17.3577}
                y2={23.7983}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint13_linear_44_18"
                x1={17.3578}
                y1={1.52418}
                x2={17.3578}
                y2={23.7868}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint14_linear_44_18"
                x1={17.3577}
                y1={1.51263}
                x2={17.3577}
                y2={23.7752}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint15_linear_44_18"
                x1={17.3577}
                y1={1.50115}
                x2={17.3577}
                y2={23.7637}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint16_linear_44_18"
                x1={17.3578}
                y1={1.48956}
                x2={17.3578}
                y2={23.7521}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint17_linear_44_18"
                x1={17.3577}
                y1={1.4782}
                x2={17.3577}
                y2={23.7408}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint18_linear_44_18"
                x1={17.3577}
                y1={1.46652}
                x2={17.3577}
                y2={23.7291}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint19_linear_44_18"
                x1={17.3577}
                y1={1.45515}
                x2={17.3577}
                y2={23.7177}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint20_linear_44_18"
                x1={17.3577}
                y1={1.44371}
                x2={17.3577}
                y2={23.7063}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint21_linear_44_18"
                x1={17.3577}
                y1={1.43207}
                x2={17.3577}
                y2={23.6946}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint22_linear_44_18"
                x1={17.3577}
                y1={1.4206}
                x2={17.3577}
                y2={23.6832}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint23_linear_44_18"
                x1={17.3578}
                y1={1.40924}
                x2={17.3578}
                y2={23.6718}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint24_linear_44_18"
                x1={17.3577}
                y1={1.39765}
                x2={17.3577}
                y2={23.6602}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint25_linear_44_18"
                x1={17.3577}
                y1={1.38626}
                x2={17.3577}
                y2={23.6488}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint26_linear_44_18"
                x1={17.3577}
                y1={1.37458}
                x2={17.3577}
                y2={23.6371}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint27_linear_44_18"
                x1={17.3577}
                y1={1.36303}
                x2={17.3577}
                y2={23.6256}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint28_linear_44_18"
                x1={17.3577}
                y1={1.35123}
                x2={17.3577}
                y2={23.6142}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint29_linear_44_18"
                x1={17.3578}
                y1={1.33975}
                x2={17.3578}
                y2={23.6027}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint30_linear_44_18"
                x1={17.3578}
                y1={1.32828}
                x2={17.3578}
                y2={23.5913}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint31_linear_44_18"
                x1={17.3577}
                y1={1.31659}
                x2={17.3577}
                y2={23.5796}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint32_linear_44_18"
                x1={17.3577}
                y1={1.30521}
                x2={17.3577}
                y2={23.5682}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint33_linear_44_18"
                x1={17.3577}
                y1={1.2936}
                x2={17.3577}
                y2={23.5566}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint34_linear_44_18"
                x1={17.3577}
                y1={1.28226}
                x2={17.3577}
                y2={23.5452}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint35_linear_44_18"
                x1={17.3577}
                y1={1.27066}
                x2={17.3577}
                y2={23.5336}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint36_linear_44_18"
                x1={17.3577}
                y1={1.25918}
                x2={17.3577}
                y2={23.5222}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint37_linear_44_18"
                x1={17.3577}
                y1={1.24758}
                x2={17.3577}
                y2={23.5106}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint38_linear_44_18"
                x1={17.3577}
                y1={1.23631}
                x2={17.3577}
                y2={23.4993}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint39_linear_44_18"
                x1={17.3577}
                y1={1.22476}
                x2={17.3577}
                y2={23.4877}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint40_linear_44_18"
                x1={17.3577}
                y1={1.21308}
                x2={17.3577}
                y2={23.4761}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint41_linear_44_18"
                x1={17.3577}
                y1={1.20171}
                x2={17.3577}
                y2={23.4647}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint42_linear_44_18"
                x1={17.3578}
                y1={1.19011}
                x2={17.3578}
                y2={23.4531}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint43_linear_44_18"
                x1={17.3578}
                y1={1.17867}
                x2={17.3578}
                y2={23.4416}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint44_linear_44_18"
                x1={17.3577}
                y1={1.16704}
                x2={17.3577}
                y2={23.43}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint45_linear_44_18"
                x1={17.3577}
                y1={1.15569}
                x2={17.3577}
                y2={23.4187}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint46_linear_44_18"
                x1={17.3577}
                y1={1.14422}
                x2={17.3577}
                y2={23.4072}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint47_linear_44_18"
                x1={17.3577}
                y1={1.13275}
                x2={17.3577}
                y2={23.3957}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint48_linear_44_18"
                x1={17.3577}
                y1={1.12115}
                x2={17.3577}
                y2={23.3841}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint49_linear_44_18"
                x1={17.3578}
                y1={1.10968}
                x2={17.3578}
                y2={23.3727}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint50_linear_44_18"
                x1={17.3577}
                y1={1.09816}
                x2={17.3577}
                y2={23.3611}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint51_linear_44_18"
                x1={17.3577}
                y1={1.08672}
                x2={17.3577}
                y2={23.3497}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint52_linear_44_18"
                x1={17.3577}
                y1={1.07512}
                x2={17.3577}
                y2={23.3381}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint53_linear_44_18"
                x1={2.27899}
                y1={12.2064}
                x2={32.4365}
                y2={12.2064}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F2F2F2" />
                <stop offset={1} stopColor="#D8D8D8" />
            </linearGradient>
            <linearGradient
                id="paint54_linear_44_18"
                x1={17.3577}
                y1={1.41274}
                x2={17.3577}
                y2={23.2304}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#BCBCBC" />
                <stop offset={1} stopColor="#9E9E9E" />
            </linearGradient>
        </defs>
    </svg>,
    'ArrowIcon',
)
