import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_URL } from 'config'
import { APIEndpoint } from 'shared/types'

export const getConfig = createAsyncThunk('game/config', async (args, { rejectWithValue }) => {
    try {
        const { data } = await axios.get(API_URL + APIEndpoint.GET_CONFIG)
        return data
    } catch (error: any) {
        return rejectWithValue(error.response.data)
    }
})
