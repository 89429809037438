import React from 'react'
import { createSvgIcon } from '@mui/material'

export const ProfileIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="32" viewBox="0 0 30 32" fill="none">
        <path
            d="M5.12977 30.4332H24.4172C26.4104 30.4332 27.9998 28.6852 27.7159 26.7135C27.2029 23.1811 25.3705 20.4224 21.8744 18.8396C16.4733 16.3938 10.8287 16.309 5.82134 20.0336C3.49873 21.7583 2.27975 24.0185 1.84939 26.6083C1.50998 28.6121 3.09554 30.4332 5.12977 30.4332Z"
            fill="black"
        />
        <path
            d="M14.8877 15.4034C18.9362 15.3575 22.3785 11.9353 22.3097 8.02348C22.241 4.06129 18.7388 0.719953 14.7141 0.775337C10.6318 0.835152 7.33812 4.15655 7.37416 8.18742C7.41021 12.2183 10.7649 15.4489 14.8877 15.4034Z"
            fill="black"
        />
        <path
            d="M4.67665 32C4.05607 32.0032 3.44188 31.8748 2.87467 31.6233C2.30747 31.3718 1.80016 31.003 1.38629 30.5412C0.57881 29.6295 0.233857 28.4366 0.443491 27.2702C0.956484 24.3232 2.46219 21.9444 4.91845 20.2026C7.79898 18.1561 10.9989 17.1188 14.4291 17.1188C17.0473 17.1188 19.7786 17.728 22.5466 18.9243C24.4577 19.7512 25.9894 20.9148 27.0992 22.3831C28.1529 23.7793 28.8378 25.4652 29.1306 27.3931C29.3031 28.5346 28.9426 29.6938 28.1423 30.5744C27.7265 31.0265 27.2206 31.3869 26.6571 31.6324C26.0936 31.8779 25.485 32.0031 24.8703 32H4.67665Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.9703C12.5196 16.9703 10.3728 16.1451 8.75621 14.6442C7.13958 13.1432 6.23173 11.1322 6.21398 8.98555C6.17128 4.53376 9.9763 0.8579 14.6992 0.791992H14.8134C19.4303 0.791992 23.3979 4.46009 23.4766 8.80167C23.5143 10.8559 22.6608 12.8447 21.0736 14.4038C19.4215 16.0168 17.2129 16.9356 14.9027 16.9709L14.8012 16.9703Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.68552 31.9845C4.06546 31.9875 3.45182 31.859 2.88518 31.6075C2.31854 31.356 1.81181 30.9873 1.39849 30.5257C1.00397 30.0871 0.714158 29.5649 0.550859 28.9983C0.38756 28.4317 0.355021 27.8356 0.45569 27.2547C0.968128 24.3077 2.47272 21.9289 4.92677 20.1871C7.80452 18.1406 11.0017 17.1033 14.4291 17.1033C17.045 17.1033 19.7736 17.7125 22.5393 18.9088C24.4482 19.7357 25.9778 20.8993 27.087 22.3675C28.1407 23.7638 28.8239 25.4497 29.1167 27.3776C29.1995 27.9478 29.1539 28.5293 28.9831 29.0796C28.8123 29.63 28.5207 30.1354 28.1296 30.5589C27.7145 31.0106 27.2094 31.3708 26.6468 31.6163C26.0842 31.8618 25.4765 31.9872 24.8625 31.9845H4.68552Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.9548C12.5218 16.9548 10.3767 16.1296 8.76174 14.6287C7.14678 13.1278 6.23948 11.1162 6.22173 8.97227C6.17903 4.51826 9.98238 0.842406 14.6991 0.775391H14.8134C19.4264 0.775391 23.3901 4.44405 23.4688 8.78562C23.5065 10.8398 22.6536 12.8287 21.0675 14.3877C19.4176 16.0007 17.2107 16.9196 14.9021 16.9548H14.8012Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.69497 31.969C4.0753 31.9721 3.46204 31.8436 2.89583 31.5921C2.32963 31.3406 1.82341 30.9718 1.41071 30.5102C1.01626 30.0715 0.726496 29.5493 0.563201 28.9828C0.399906 28.4162 0.367327 27.8201 0.467913 27.2392C0.980351 24.2922 2.48328 21.9134 4.93511 20.1716C7.81009 18.1251 11.0045 17.0878 14.4285 17.0878C17.0423 17.0878 19.7686 17.697 22.5316 18.8933C24.4394 19.7202 25.97 20.8838 27.0759 22.352C28.1296 23.7483 28.8112 25.4342 29.104 27.3621C29.2759 28.5036 28.916 29.6622 28.1174 30.5434C27.7029 30.9951 27.1985 31.3552 26.6364 31.6007C26.0744 31.8462 25.4671 31.9717 24.8537 31.969H4.69497Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.9393C12.5241 16.9393 10.3806 16.1141 8.76674 14.6132C7.15289 13.1122 6.24614 11.099 6.2284 8.95288C6.1868 4.50275 9.98683 0.826894 14.6992 0.760986H14.8134C19.422 0.760986 23.3823 4.42964 23.4611 8.77122C23.4988 10.8254 22.6464 12.8143 21.0619 14.3733C19.4141 15.986 17.209 16.905 14.9021 16.9404L14.8012 16.9393Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.70382 31.9535C4.08457 31.9564 3.47177 31.8279 2.90604 31.5764C2.3403 31.3249 1.83456 30.9561 1.42233 30.4946C1.02806 30.0559 0.738405 29.5337 0.575119 28.9672C0.411834 28.4007 0.379155 27.8046 0.479535 27.2237C0.990864 24.2767 2.49269 21.8979 4.9423 20.1561C7.81506 18.1096 11.0061 17.0723 14.4257 17.0723C17.0367 17.0723 19.7609 17.6815 22.5227 18.8778C24.4288 19.7047 25.9561 20.8683 27.0631 22.3365C28.1168 23.7328 28.7967 25.4187 29.0896 27.3466C29.1722 27.9167 29.1266 28.4981 28.956 29.0484C28.7854 29.5987 28.4942 30.1041 28.1035 30.5279C27.6896 30.9795 27.1856 31.3396 26.624 31.5851C26.0624 31.8307 25.4556 31.9561 24.8425 31.9535H4.70382Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.9238C12.5274 16.9238 10.385 16.0986 8.77227 14.5977C7.15953 13.0967 6.25389 11.0835 6.23615 8.93738C6.19455 4.48614 9.9907 0.810837 14.6991 0.745483H14.8134C19.4165 0.745483 23.3745 4.41414 23.4527 8.75571C23.4905 10.8099 22.6397 12.7988 21.0564 14.3578C19.4103 15.9706 17.2062 16.8895 14.9005 16.9243L14.8012 16.9238Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.7127 31.9379C4.09413 31.9407 3.48203 31.812 2.91704 31.5605C2.35205 31.309 1.84709 30.9404 1.43565 30.4791C1.04147 30.0403 0.751892 29.518 0.588612 28.9516C0.425332 28.3851 0.392593 27.789 0.49285 27.2081C1.00418 24.2611 2.50434 21.8823 4.95173 20.1405C7.82116 18.094 11.0095 17.0583 14.4274 17.0583C17.0367 17.0583 19.7575 17.6676 22.516 18.8639C24.4199 19.6891 25.9484 20.8527 27.052 22.3199C28.1057 23.7161 28.7845 25.402 29.0763 27.3299C29.2482 28.4714 28.8888 29.63 28.0919 30.5112C27.6784 30.9627 27.175 31.3228 26.6138 31.5683C26.0527 31.8138 25.4463 31.9394 24.8337 31.9368L4.7127 31.9379Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.9083C12.5274 16.9083 10.3889 16.0831 8.77782 14.5821C7.16675 13.0812 6.26111 11.068 6.24336 8.92187C6.20509 4.47174 9.99514 0.795334 14.6991 0.72998H14.8134C19.4137 0.72998 23.3668 4.39864 23.445 8.74021C23.4827 10.7944 22.6325 12.7833 21.0508 14.3423C19.4072 15.9545 17.2056 16.8735 14.9021 16.9088L14.8012 16.9083Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.72214 31.9225C4.10361 31.9251 3.49161 31.7962 2.92686 31.5443C2.36211 31.2923 1.85754 30.9232 1.44675 30.4614C1.05342 30.0228 0.764433 29.5011 0.601369 28.9352C0.438305 28.3694 0.405381 27.7741 0.505057 27.1937C1.01583 24.2467 2.51488 21.868 4.96005 20.1262C7.82727 18.0797 11.0128 17.0424 14.428 17.0424C17.0345 17.0424 19.752 17.6516 22.5088 18.8479C24.4116 19.6748 25.9362 20.8384 27.0409 22.3066C28.0913 23.7029 28.7718 25.3888 29.0635 27.3167C29.1461 27.8866 29.1006 28.4678 28.9305 29.018C28.7603 29.5683 28.4697 30.0738 28.0797 30.498C27.667 30.9495 27.1641 31.3096 26.6035 31.5552C26.0429 31.8007 25.437 31.9262 24.8248 31.9236L4.72214 31.9225Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.8922C12.5302 16.8922 10.3928 16.067 8.78337 14.5661C7.17396 13.0652 6.26887 11.052 6.25112 8.90582C6.21008 4.45624 9.99958 0.779831 14.6991 0.714478H14.8134C19.4093 0.714478 23.359 4.38313 23.4372 8.72471C23.4749 10.7789 22.6253 12.7678 21.0453 14.3268C19.4034 15.9384 17.2034 16.857 14.9016 16.8922H14.8012Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.73101 31.9068C4.11316 31.9096 3.50181 31.7809 2.93763 31.5294C2.37345 31.2779 1.86937 30.9092 1.45895 30.448C1.06516 30.009 0.775821 29.4867 0.612562 28.9203C0.449304 28.3539 0.416345 27.758 0.51615 27.177C1.02637 24.23 2.52431 21.8513 4.96727 20.1094C7.83171 18.063 11.0123 17.0256 14.4269 17.0256C17.0334 17.0256 19.747 17.6349 22.5005 18.8312C24.4011 19.6581 25.9245 20.8217 27.0282 22.2899C28.0775 23.6861 28.7574 25.372 29.0491 27.3C29.1318 27.8699 29.0864 28.451 28.9164 29.0013C28.7463 29.5515 28.4558 30.057 28.0658 30.4812C27.6536 30.9326 27.1512 31.2927 26.5911 31.5383C26.0309 31.7838 25.4254 31.9094 24.8137 31.9068H4.73101Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.8773C12.5318 16.8773 10.3967 16.0521 8.78836 14.5511C7.18006 13.0502 6.27774 11.0387 6.26055 8.89253C6.21785 4.44074 10.004 0.764328 14.6997 0.698975H14.8106C19.4032 0.698975 23.3513 4.36708 23.4272 8.70921C23.4649 10.7629 22.6164 12.7523 21.0375 14.3113C19.3979 15.923 17.1997 16.8422 14.8994 16.8778L14.8012 16.8773Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.73988 31.8913C4.12243 31.8941 3.51149 31.7654 2.94776 31.5139C2.38403 31.2623 1.88045 30.8937 1.47059 30.4325C1.07735 29.9932 0.788569 29.4708 0.625797 28.9044C0.463026 28.338 0.43047 27.7422 0.53056 27.1615C1.04023 24.2145 2.53706 21.8358 4.97779 20.0939C7.84002 18.0475 11.0195 17.0101 14.4285 17.0101C17.0306 17.0101 19.7442 17.6194 22.495 18.8157C24.3944 19.6425 25.9162 20.8062 27.0187 22.2744C28.0675 23.6706 28.7463 25.3565 29.038 27.2845C29.1205 27.8543 29.0751 28.4353 28.9053 28.9855C28.7354 29.5356 28.4453 30.0412 28.0558 30.4657C27.6441 30.917 27.1422 31.277 26.5825 31.5225C26.0228 31.7681 25.4178 31.8937 24.8065 31.8913H4.73988Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.8618C12.5341 16.8618 10.4006 16.0366 8.79392 14.5356C7.18728 13.0347 6.28552 11.0215 6.26777 8.87703C6.22563 4.42524 10.0079 0.748825 14.6975 0.683472H14.8084C19.4009 0.682364 23.3418 4.35047 23.4217 8.69536C23.4594 10.749 22.6115 12.7384 21.0336 14.2975C19.3963 15.9089 17.2001 16.8281 14.9016 16.864L14.8012 16.8618Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.74932 31.8758C4.13221 31.8785 3.52162 31.7498 2.95826 31.4983C2.39489 31.2467 1.89171 30.8781 1.48225 30.417C1.08942 29.9775 0.800973 29.455 0.638406 28.8887C0.475838 28.3223 0.44334 27.7267 0.543333 27.146C1.053 24.199 2.54761 21.8214 4.98502 20.0784C7.84447 18.032 11.0217 16.9946 14.4274 16.9946C17.0268 16.9946 19.7381 17.6039 22.4867 18.8002C24.3839 19.627 25.9046 20.7907 27.006 22.2589C28.0536 23.6551 28.7324 25.341 29.0236 27.269C29.1061 27.8387 29.0608 28.4197 28.891 28.9698C28.7212 29.52 28.4313 30.0256 28.042 30.4502C27.6309 30.9014 27.1296 31.2614 26.5704 31.507C26.0113 31.7526 25.4068 31.8782 24.796 31.8758H4.74932Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.8463C12.5357 16.8463 10.4044 16.0211 8.79945 14.5201C7.19448 13.0192 6.29327 11.0077 6.27553 8.86152C6.23338 4.40973 10.0123 0.733322 14.6997 0.667969H14.8106C19.3948 0.667969 23.3335 4.33607 23.4117 8.6782C23.4494 10.7319 22.602 12.7213 21.027 14.2803C19.3918 15.8915 17.1973 16.8108 14.9005 16.8468L14.8012 16.8463Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.75818 31.8603C4.14157 31.863 3.53148 31.7342 2.96866 31.4827C2.40585 31.2311 1.90325 30.8625 1.49444 30.4015C1.10191 29.9618 0.813703 29.4393 0.651244 28.873C0.488785 28.3067 0.456256 27.7111 0.556077 27.1305C1.06519 24.1835 2.55869 21.8048 4.99555 20.0629C7.85222 18.0165 11.0267 16.9791 14.4291 16.9791C17.0262 16.9791 19.7354 17.5884 22.4811 18.7847C24.3767 19.6115 25.8963 20.7752 26.9971 22.2434C28.0431 23.6396 28.7213 25.3255 29.0125 27.2535C29.0949 27.8231 29.0496 28.404 28.8801 28.9541C28.7105 29.5042 28.4209 30.0099 28.032 30.4347C27.6214 30.8858 27.1205 31.2457 26.5619 31.4913C26.0032 31.7369 25.3991 31.8626 24.7887 31.8603H4.75818Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.8308C12.5379 16.8308 10.4083 16.0055 8.80445 14.5046C7.20058 13.0037 6.29938 10.9905 6.28163 8.84436C6.24115 4.39423 10.0168 0.720035 14.6997 0.652466H14.8106C19.3904 0.652466 23.3258 4.32057 23.4067 8.6627C23.4444 10.7163 22.5976 12.7058 21.022 14.2648C19.3888 15.8757 17.1962 16.795 14.901 16.8313L14.8012 16.8308Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.76705 31.8459C4.1509 31.8487 3.54128 31.72 2.97899 31.4684C2.41669 31.2168 1.91471 30.8481 1.50663 30.3871C1.11411 29.9472 0.825957 29.4245 0.663594 28.858C0.501231 28.2915 0.468839 27.6957 0.568825 27.115C1.07738 24.168 2.56978 21.7893 5.00552 20.0474C7.85998 17.9982 11.0317 16.9636 14.4335 16.9636C17.0284 16.9636 19.7348 17.5729 22.4783 18.7692C24.3722 19.596 25.8902 20.7597 26.9899 22.2279C28.0314 23.6241 28.7091 25.3106 28.9986 27.238C29.0808 27.8076 29.0356 28.3883 28.8661 28.9384C28.6967 29.4885 28.4073 29.9942 28.0187 30.4192C27.6089 30.8703 27.1088 31.2304 26.5507 31.4762C25.9927 31.722 25.3892 31.8479 24.7793 31.8459H4.76705Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.8153C12.5402 16.8153 10.4122 15.99 8.81167 14.4891C7.21113 12.9882 6.30882 10.975 6.29107 8.82886C6.24837 4.37873 10.0207 0.702316 14.6997 0.636963H14.8106C19.3865 0.636963 23.318 4.30507 23.3962 8.64719C23.4339 10.7008 22.5882 12.6902 21.0148 14.2493C19.3839 15.8591 17.1938 16.7781 14.901 16.8147L14.8012 16.8153Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.77594 31.8294C4.16026 31.8318 3.55117 31.7028 2.98939 31.4513C2.4276 31.1997 1.92606 30.8312 1.5183 30.3706C1.1263 29.9305 0.838555 29.408 0.676399 28.8418C0.514243 28.2755 0.481841 27.68 0.581598 27.0996C1.0896 24.1526 2.58088 21.7738 5.01275 20.032C7.86499 17.9855 11.0333 16.9476 14.4296 16.9476C17.0223 16.9476 19.7265 17.5569 22.4673 18.7532C24.3595 19.58 25.8763 20.7437 26.9749 22.2119C28.0192 23.6081 28.6942 25.294 28.987 27.222C29.0697 27.792 29.0248 28.3732 28.8555 28.9237C28.6863 29.4743 28.3969 29.9806 28.0081 30.406C27.5986 30.857 27.0988 31.2169 26.541 31.4625C25.9833 31.7081 25.38 31.8338 24.7705 31.8316L4.77594 31.8294Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.7998C12.5418 16.7998 10.4161 15.9745 8.81553 14.4736C7.215 12.9727 6.31601 10.9612 6.29826 8.81501C6.25611 4.36322 10.0251 0.686813 14.6997 0.62146H14.8106C19.3821 0.62146 23.3108 4.28956 23.3884 8.63169C23.4261 10.6853 22.5809 12.6747 21.0092 14.2338C19.3804 15.8433 17.1921 16.7624 14.901 16.7992L14.8012 16.7998Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.78481 31.8138C4.1696 31.8163 3.56097 31.6874 2.99971 31.4358C2.43845 31.1842 1.93752 30.8157 1.53049 30.355C1.13865 29.9147 0.85104 29.3919 0.688987 28.8255C0.526935 28.2591 0.49459 27.6635 0.594351 27.0829C1.10235 24.1359 2.59086 21.7572 5.02162 20.0153C7.87109 17.9661 11.0367 16.9315 14.4302 16.9315C17.0201 16.9315 19.7221 17.5407 22.4606 18.737C24.3512 19.5639 25.8663 20.7276 26.9638 22.1958C28.0076 23.592 28.6831 25.2779 28.9742 27.2059C29.0565 27.7754 29.0114 28.356 28.8422 28.906C28.6731 29.4561 28.3841 29.9619 27.9959 30.3871C27.5871 30.838 27.0879 31.198 26.5306 31.4436C25.9734 31.6892 25.3707 31.815 24.7616 31.8127L4.78481 31.8138Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.7843C12.544 16.7843 10.42 15.959 8.82109 14.4581C7.22221 12.9572 6.32545 10.944 6.30493 8.79785C6.26389 4.34772 10.0318 0.671311 14.6997 0.605957H14.8106C19.3782 0.605957 23.303 4.27406 23.3807 8.61619C23.4184 10.6698 22.5737 12.6592 21.0037 14.2183C19.3768 15.8269 17.1902 16.7453 14.901 16.7815L14.8012 16.7843Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.79424 31.7983C4.17937 31.8008 3.57108 31.6718 3.01018 31.4202C2.44928 31.1686 1.94874 30.8001 1.54215 30.3395C1.15072 29.8992 0.863457 29.3765 0.701606 28.8103C0.539754 28.2441 0.507461 27.6488 0.607114 27.0685C1.11456 24.1215 2.60363 21.7427 5.0305 20.0009C7.87719 17.9545 11.04 16.9171 14.4307 16.9171C17.0184 16.9171 19.7176 17.5263 22.4534 18.7226C24.3401 19.5506 25.8563 20.7137 26.9528 22.1814C27.9959 23.5776 28.672 25.2635 28.9615 27.1915C29.0435 27.7609 28.9985 28.3414 28.8296 28.8914C28.6606 29.4413 28.372 29.9472 27.9843 30.3727C27.5759 30.8235 27.0772 31.1833 26.5204 31.4289C25.9637 31.6745 25.3614 31.8004 24.7527 31.7983H4.79424Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.7688C12.5462 16.7688 10.4238 15.9435 8.82606 14.4426C7.2283 12.9417 6.33042 10.9279 6.31212 8.78235C6.27164 4.33222 10.0317 0.655808 14.6997 0.590454H14.8106C19.3743 0.590454 23.2958 4.25856 23.3729 8.60069C23.4106 10.6543 22.5671 12.6437 20.9981 14.2028C19.3728 15.8129 17.1867 16.7326 14.8977 16.7693L14.8012 16.7688Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.8031 31.7828C4.1887 31.7852 3.58091 31.6563 3.02055 31.4047C2.46019 31.1531 1.96025 30.7846 1.55433 30.324C1.1631 29.8835 0.876006 29.3609 0.714257 28.7947C0.552508 28.2285 0.520244 27.6333 0.619849 27.053C1.12674 24.106 2.61636 21.7272 5.03935 19.9854C7.88327 17.9362 11.0433 16.9016 14.4307 16.9016C17.0162 16.9016 19.7132 17.5108 22.4467 18.7071C24.3323 19.534 25.8463 20.6977 26.9422 22.1659C27.9837 23.5621 28.6586 25.248 28.9487 27.176C29.0307 27.7453 28.9857 28.3257 28.817 28.8756C28.6482 29.4256 28.3599 29.9315 27.9726 30.3572C27.5648 30.8078 27.0665 31.1676 26.5102 31.4133C25.9539 31.6589 25.3521 31.7848 24.7438 31.7828H4.8031Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.7532C12.5479 16.7532 10.4283 15.928 8.83162 14.4271C7.23496 12.9262 6.33597 10.9124 6.31989 8.76679C6.2783 4.315 10.0356 0.638596 14.6991 0.573242H14.8101C19.3693 0.573242 23.2869 4.24134 23.3646 8.58347C23.4023 10.6371 22.5593 12.6265 20.992 14.1856C19.3699 15.7947 17.1871 16.7146 14.901 16.7527L14.8012 16.7532Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.81199 31.7673C4.19802 31.7696 3.59068 31.6406 3.0308 31.389C2.47091 31.1373 1.97145 30.7689 1.56599 30.3085C1.17517 29.8678 0.888409 29.3451 0.726864 28.779C0.565319 28.2129 0.533114 27.6177 0.632624 27.0375C1.13896 24.0922 2.6247 21.7106 5.04769 19.9699C7.88939 17.9235 11.0467 16.8861 14.4313 16.8861C17.014 16.8861 19.7087 17.4953 22.4395 18.6916C24.3251 19.5185 25.8364 20.6821 26.9317 22.1504C27.9726 23.5466 28.647 25.2325 28.9365 27.1604C29.0186 27.7297 28.9737 28.3101 28.805 28.86C28.6364 29.41 28.3482 29.9159 27.961 30.3417C27.5538 30.7923 27.0561 31.1521 26.5004 31.3977C25.9446 31.6434 25.3433 31.7693 24.7355 31.7673H4.81199Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.7377C12.5501 16.7377 10.4322 15.9124 8.83662 14.4115C7.24108 12.9106 6.34653 10.8985 6.32878 8.75068C6.28663 4.29889 10.0445 0.622482 14.703 0.557129H14.814C19.3693 0.557129 23.2831 4.22523 23.3613 8.56736C23.3984 10.621 22.556 12.6104 20.9904 14.1695C19.3754 15.7756 17.1565 16.7116 14.9016 16.736L14.8012 16.7377Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.81864 31.7518C4.20563 31.754 3.59925 31.6251 3.04024 31.3739C2.48123 31.1226 1.98253 30.7548 1.57763 30.2952C1.18696 29.854 0.900391 29.3309 0.739046 28.7644C0.577701 28.1979 0.545689 27.6025 0.64537 27.022C1.15115 24.075 2.63578 21.6962 5.05655 19.9544C7.89548 17.9052 11.05 16.8706 14.4291 16.8706C17.0101 16.8706 19.7021 17.4798 22.4307 18.6761C24.3162 19.503 25.8242 20.6666 26.9178 22.1349C27.9577 23.5311 28.6315 25.217 28.921 27.1449C29.0028 27.7141 28.9579 28.2944 28.7895 28.8443C28.6211 29.3941 28.3333 29.9002 27.9466 30.3262C27.5403 30.7761 27.0439 31.1355 26.4894 31.3811C25.9349 31.6267 25.3349 31.753 24.7283 31.7518H4.81864Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.7221C12.5518 16.7221 10.436 15.8969 8.84215 14.396C7.24827 12.8951 6.35261 10.8813 6.33542 8.73571C6.29438 4.28559 10.0467 0.609177 14.7002 0.543823H14.8112C19.3621 0.543823 23.2725 4.21193 23.3518 8.55406C23.389 10.6077 22.5477 12.5971 20.9826 14.1562C19.3699 15.7623 17.1526 16.6983 14.8999 16.7227L14.8012 16.7221Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.82972 31.7351C4.21666 31.7373 3.61025 31.6083 3.05136 31.3566C2.49247 31.105 1.9941 30.7366 1.58983 30.2763C1.19992 29.8354 0.913939 29.3127 0.752896 28.7469C0.591853 28.181 0.559847 27.5863 0.659231 27.0064C1.16335 24.0594 2.64632 21.6807 5.06542 19.9383C7.90214 17.8918 11.055 16.8545 14.4318 16.8545C17.0101 16.8545 19.7004 17.4637 22.4262 18.66C24.3079 19.4869 25.8164 20.6505 26.9089 22.1188C27.9482 23.515 28.6215 25.2009 28.9104 27.1288C28.9922 27.698 28.9474 28.2782 28.7791 28.828C28.6107 29.3779 28.3231 29.8839 27.9366 30.3101C27.5304 30.7604 27.0337 31.1201 26.4788 31.3657C25.924 31.6112 25.3235 31.7371 24.7167 31.7351H4.82972Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.7066C12.554 16.7066 10.4399 15.8814 8.84771 14.3805C7.25549 12.8796 6.36039 10.8658 6.34264 8.72021C6.30216 4.27008 10.0506 0.593674 14.7003 0.52832H14.8112C19.3588 0.52832 23.2653 4.19642 23.3429 8.53855C23.3801 10.5922 22.5394 12.5816 20.976 14.1407C19.3649 15.7468 17.1493 16.6828 14.8988 16.7072L14.8012 16.7066Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.83859 31.7208C4.22603 31.7229 3.62016 31.5937 3.06183 31.342C2.5035 31.0904 2.00571 30.7221 1.60202 30.262C1.21203 29.8208 0.925959 29.298 0.764824 28.732C0.603689 28.1659 0.571584 27.571 0.670865 26.991C1.17554 24.044 2.6574 21.6652 5.07429 19.9234C7.90822 17.8742 11.0566 16.8396 14.4318 16.8396C17.0084 16.8396 19.6954 17.4488 22.4179 18.6451C24.2985 19.472 25.8053 20.6356 26.8973 22.1039C27.9355 23.5001 28.6076 25.186 28.8938 27.1139C28.9762 27.6827 28.9321 28.2626 28.7646 28.8125C28.5972 29.3623 28.3105 29.8686 27.9249 30.2952C27.5194 30.7455 27.0233 31.1052 26.469 31.3508C25.9147 31.5965 25.3147 31.7226 24.7083 31.7208H4.83859Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.6911C12.5562 16.6911 10.4438 15.8659 8.8527 14.365C7.26159 12.8641 6.37147 10.852 6.35151 8.70637C6.30991 4.25458 10.055 0.578171 14.7003 0.512817H14.8112C19.3538 0.512817 23.2575 4.18092 23.3352 8.52305C23.3723 10.5767 22.5327 12.5661 20.9704 14.1252C19.3621 15.7313 17.1477 16.6673 14.8988 16.6917L14.8012 16.6911Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.84802 31.7053C4.23577 31.7075 3.63019 31.5783 3.07221 31.3267C2.51422 31.075 2.01684 30.7066 1.61367 30.2465C1.22398 29.8052 0.938181 29.2823 0.777245 28.7163C0.616309 28.1503 0.584321 27.5555 0.68363 26.9755C1.18775 24.0285 2.6685 21.6497 5.08261 19.9079C7.91433 17.8614 11.06 16.8241 14.4324 16.8241C17.0062 16.8241 19.691 17.4333 22.4123 18.6296C24.2907 19.4565 25.7953 20.6201 26.8873 22.0884C27.9244 23.4846 28.5965 25.1705 28.8838 27.0984C28.9656 27.6674 28.921 28.2474 28.7531 28.7972C28.5852 29.347 28.2981 29.8532 27.9122 30.2797C27.5069 30.7302 27.0108 31.0901 26.4564 31.3358C25.9021 31.5815 25.302 31.7074 24.6956 31.7053H4.84802Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.6756C12.5579 16.6756 10.4477 15.8504 8.85825 14.3495C7.2688 12.8486 6.37536 10.8348 6.35817 8.6892C6.31602 4.23907 10.0595 0.562659 14.7003 0.498413H14.8112C19.3499 0.498413 23.2498 4.16652 23.3274 8.50865C23.3646 10.5623 22.5255 12.5517 20.9649 14.1108C19.3566 15.7169 17.1454 16.6529 14.8988 16.6773L14.8012 16.6756Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.85689 31.6898C4.24506 31.6919 3.63993 31.5627 3.08241 31.311C2.52489 31.0593 2.02799 30.691 1.62532 30.231C1.23599 29.7895 0.950502 29.2666 0.789769 28.7006C0.629035 28.1346 0.597127 27.5399 0.696383 26.96C1.19995 24.013 2.67903 21.6342 5.09148 19.8924C7.91988 17.8459 11.0633 16.8086 14.4324 16.8086C17.004 16.8086 19.6865 17.4178 22.4051 18.6141C24.2846 19.4399 25.7864 20.603 26.8768 22.0729C27.9127 23.4691 28.5843 25.155 28.8733 27.0829C28.9552 27.6518 28.9107 28.2318 28.743 28.7816C28.5752 29.3314 28.2884 29.8376 27.9028 30.2642C27.4982 30.7144 27.0029 31.074 26.4495 31.3197C25.896 31.5654 25.2968 31.6915 24.6911 31.6898H4.85689Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.6601C12.5601 16.6601 10.4516 15.8349 8.86322 14.334C7.27488 12.8331 6.38311 10.8193 6.36758 8.6737C6.32488 4.22358 10.0633 0.547165 14.7002 0.481812H14.8112C19.3455 0.481812 23.2409 4.14991 23.3196 8.49204C23.3568 10.5457 22.5188 12.5351 20.9593 14.0942C19.351 15.7003 17.1432 16.6363 14.8982 16.6607L14.8012 16.6601Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.86577 31.6743C4.25437 31.6761 3.64971 31.5468 3.09267 31.2952C2.53564 31.0435 2.03921 30.6753 1.63696 30.2155C1.248 29.7738 0.962824 29.2508 0.802292 28.6849C0.641761 28.1189 0.609933 27.5243 0.709136 26.9445C1.21381 23.9975 2.69012 21.6187 5.10036 19.8769C7.92875 17.8304 11.0666 16.7931 14.4329 16.7931C17.0023 16.7931 19.6821 17.4023 22.3985 18.5986C24.2735 19.4255 25.777 20.5891 26.8657 22.0574C27.9011 23.4536 28.5716 25.1395 28.8594 27.0674C28.9413 27.6363 28.8968 28.2162 28.7292 28.7659C28.5615 29.3157 28.2749 29.8219 27.8894 30.2487C27.4855 30.6987 26.9908 31.0584 26.4379 31.3041C25.885 31.5498 25.2863 31.6759 24.6812 31.6743H4.86577Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.6446C12.5618 16.6446 10.4555 15.8194 8.86713 14.3185C7.27879 12.8176 6.38868 10.8038 6.37149 8.6582C6.33266 4.20918 10.0678 0.531662 14.7003 0.466309H14.8112C19.3433 0.465201 23.2365 4.1333 23.3136 8.47377C23.3507 10.5274 22.5133 12.5168 20.9555 14.0759C19.3472 15.682 17.1427 16.618 14.8999 16.6424L14.8012 16.6446Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.87411 31.6588C4.26311 31.6607 3.65885 31.5314 3.10225 31.2797C2.54566 31.028 2.04973 30.6598 1.64807 30.2C1.25955 29.758 0.974726 29.235 0.8144 28.6691C0.654074 28.1032 0.622287 27.5087 0.721359 26.929C1.22437 23.9814 2.70068 21.6032 5.10925 19.8614C7.93265 17.8149 11.07 16.7776 14.4335 16.7776C17.0001 16.7776 19.6777 17.3868 22.3918 18.5831C24.2652 19.41 25.7671 20.5736 26.8546 22.0419C27.8889 23.4381 28.5594 25.124 28.8467 27.0519C28.9285 27.6207 28.8841 28.2005 28.7166 28.7502C28.5492 29.2999 28.2628 29.8062 27.8778 30.2332C27.4744 30.6831 26.9804 31.0426 26.428 31.2883C25.8757 31.534 25.2775 31.6603 24.6729 31.6588H4.87411Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.6292C12.5645 16.6292 10.4599 15.804 8.87488 14.303C7.28987 12.8021 6.39865 10.7884 6.37924 8.64277C6.3382 4.19098 10.07 0.514572 14.698 0.449219H14.809C19.3355 0.449219 23.2248 4.11732 23.3019 8.45945C23.3391 10.5131 22.5022 12.5025 20.946 14.0616C19.3416 15.6677 17.1366 16.6037 14.896 16.6281L14.8012 16.6292Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.88352 31.6433C4.27302 31.6451 3.66926 31.5158 3.11321 31.2641C2.55716 31.0124 2.06183 30.6442 1.66082 30.1845C1.27236 29.7425 0.987582 29.2195 0.827259 28.6536C0.666937 28.0877 0.635111 27.4932 0.734104 26.9135C1.23656 23.9665 2.71176 21.5877 5.11534 19.8459C7.93652 17.7994 11.071 16.7621 14.4324 16.7621C16.9974 16.7621 19.6721 17.3713 22.3835 18.5676C24.2558 19.3945 25.756 20.5581 26.8429 22.0264C27.8761 23.4226 28.5455 25.1085 28.8328 27.0364C28.9145 27.6051 28.8702 28.1848 28.703 28.7345C28.5358 29.2842 28.2497 29.7905 27.865 30.2177C27.4621 30.6675 26.9684 31.0271 26.4165 31.2728C25.8645 31.5185 25.2666 31.6448 24.6623 31.6433H4.88352Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.6153C12.5668 16.6153 10.4638 15.79 8.87988 14.2891C7.29598 12.7882 6.40642 10.7744 6.38867 8.62886C6.34763 4.17707 10.0761 0.498441 14.7008 0.435303H14.8117C19.3344 0.435303 23.2204 4.1034 23.2969 8.44553C23.3335 10.4992 22.4978 12.4886 20.9427 14.0477C19.3399 15.6538 17.1366 16.5898 14.8977 16.6142L14.8012 16.6153Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.89239 31.6278C4.28229 31.6296 3.67896 31.5002 3.12336 31.2485C2.56776 30.9968 2.07293 30.6286 1.67246 30.169C1.2844 29.7267 0.999952 29.2037 0.839834 28.6378C0.679716 28.072 0.647951 27.4776 0.746852 26.898C1.24875 23.951 2.72285 21.5722 5.12809 19.8304C7.9465 17.7839 11.0783 16.7466 14.4357 16.7466C16.9979 16.7466 19.6705 17.3558 22.3796 18.5521C24.2497 19.379 25.7487 20.5426 26.8346 22.0109C27.8673 23.4071 28.5361 25.093 28.8234 27.0209C28.9049 27.5896 28.8606 28.1691 28.6935 28.7188C28.5264 29.2685 28.2405 29.7748 27.8562 30.2022C27.4538 30.6519 26.9606 31.0115 26.409 31.2572C25.8575 31.5029 25.2601 31.6292 24.6562 31.6278H4.89239Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.5981C12.5684 16.5981 10.4676 15.7729 8.88541 14.272C7.30317 12.7711 6.41362 10.7573 6.39642 8.61169C6.35538 4.16156 10.0805 0.485153 14.7008 0.4198H14.8117C19.3305 0.4198 23.2132 4.0879 23.2902 8.43003C23.3269 10.4837 22.4916 12.4731 20.9382 14.0322C19.3372 15.6383 17.1354 16.5743 14.8993 16.5987L14.8012 16.5981Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.90128 31.6123C4.29169 31.6139 3.6889 31.4845 3.13386 31.2328C2.57883 30.9811 2.08457 30.6129 1.68468 30.1535C1.29678 29.7112 1.01248 29.1881 0.852463 28.6222C0.692445 28.0564 0.66073 27.4621 0.759626 26.8825C1.25875 23.9355 2.7334 21.5567 5.13532 19.8149C7.95151 17.7684 11.0799 16.7311 14.4341 16.7311C16.994 16.7311 19.6644 17.3403 22.3713 18.5366C24.2397 19.3635 25.7371 20.5271 26.8224 21.9954C27.854 23.3916 28.5222 25.0775 28.809 27.0054C28.8902 27.5737 28.8457 28.1528 28.6787 28.702C28.5117 29.2512 28.2262 29.7573 27.8423 30.1845C27.4406 30.6342 26.948 30.9937 26.397 31.2395C25.846 31.4852 25.2491 31.6115 24.6457 31.6101L4.90128 31.6123Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.5826C12.5707 16.5826 10.4715 15.7574 8.89042 14.2565C7.30929 12.7555 6.42195 10.7445 6.40421 8.59785C6.36317 4.14606 10.0872 0.46965 14.7008 0.404297H14.8117C19.3261 0.404297 23.2054 4.0724 23.2825 8.41453C23.3197 10.4682 22.485 12.4576 20.9327 14.0167C19.3327 15.6228 17.1338 16.5588 14.8988 16.5832L14.8012 16.5826Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.91014 31.5968C4.30094 31.5985 3.69853 31.4691 3.14393 31.2174C2.58933 30.9656 2.09558 30.5975 1.69631 30.138C1.30845 29.6956 1.0241 29.1725 0.863903 28.6068C0.703705 28.041 0.671677 27.4467 0.770147 26.867C1.26927 23.92 2.74281 21.5412 5.14195 19.7988C7.95593 17.7529 11.0816 16.7156 14.4329 16.7156C16.9907 16.7156 19.6583 17.3248 22.3635 18.5211C24.2292 19.348 25.7266 20.5116 26.8113 21.9799C27.8418 23.3761 28.51 25.062 28.7962 26.9899C28.8776 27.5584 28.8334 28.1378 28.6666 28.6875C28.4998 29.2371 28.2145 29.7435 27.8307 30.1712C27.4294 30.6207 26.9373 30.9802 26.3867 31.2259C25.8362 31.4716 25.2398 31.598 24.6368 31.5968H4.91014Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.5671C12.5723 16.5671 10.4754 15.7419 8.89594 14.241C7.31648 12.7401 6.42692 10.7279 6.41195 8.58457C6.37146 4.13057 10.0872 0.454156 14.7008 0.387695H14.8117C19.3222 0.387695 23.1976 4.0558 23.2747 8.39793C23.3119 10.4516 22.4778 12.441 20.9272 14C19.3288 15.6062 17.1316 16.5422 14.8988 16.5666L14.8012 16.5671Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.91901 31.5813C4.31023 31.5829 3.70826 31.4534 3.15413 31.2017C2.6 30.9499 2.10673 30.5818 1.70795 30.1225C1.32065 29.6798 1.03685 29.1566 0.877138 28.5909C0.717426 28.0251 0.685803 27.4309 0.784563 26.8515C1.28369 23.9045 2.75501 21.5257 5.15249 19.7833C7.96369 17.7374 11.0866 16.7001 14.4346 16.7001C16.9901 16.7001 19.656 17.3093 22.3574 18.5056C24.2225 19.3319 25.7177 20.4961 26.8008 21.9644C27.8301 23.3606 28.4995 25.0465 28.7834 26.9722C28.865 27.5406 28.821 28.12 28.6544 28.6696C28.4877 29.2192 28.2026 29.7257 27.819 30.1535C27.4182 30.6029 26.9266 30.9623 26.3766 31.208C25.8265 31.4538 25.2305 31.5802 24.6279 31.5791L4.91901 31.5813Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.5516C12.5745 16.5516 10.4793 15.7264 8.90093 14.2255C7.32258 12.7245 6.43635 10.7108 6.4186 8.56518C6.37812 4.11505 10.0933 0.438644 14.7008 0.373291H14.8117C19.3183 0.373291 23.1904 4.04139 23.267 8.38352C23.3041 10.4372 22.4711 12.4266 20.9216 13.9856C19.3244 15.5918 17.1293 16.5278 14.8988 16.5522L14.8012 16.5516Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.92955 31.5658C4.3209 31.5676 3.71904 31.4382 3.16507 31.1864C2.61109 30.9346 2.11804 30.5664 1.7196 30.107C1.33266 29.6641 1.04917 29.1409 0.889661 28.5752C0.73015 28.0094 0.698608 27.4154 0.797319 26.836C1.29645 23.889 2.7661 21.5102 5.16081 19.7678C7.96979 17.723 11.0899 16.6846 14.4352 16.6846C16.9863 16.6846 19.6516 17.2938 22.3508 18.4901C24.2142 19.3164 25.7077 20.4806 26.7875 21.9488C27.8162 23.3451 28.4828 25.031 28.769 26.9589C28.8504 27.5273 28.8063 28.1066 28.6398 28.6561C28.4732 29.2057 28.1884 29.7122 27.8051 30.1402C27.405 30.5894 26.9139 30.9488 26.3644 31.1945C25.8149 31.4403 25.2195 31.5668 24.6174 31.5658H4.92955Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.5361C12.5762 16.5361 10.4832 15.7103 8.90648 14.21C7.32979 12.7096 6.44411 10.6969 6.42692 8.55134C6.38533 4.09844 10.0972 0.423142 14.7008 0.357788H14.8117C19.3139 0.357788 23.1827 4.02589 23.2597 8.36802C23.2963 10.4217 22.4639 12.4111 20.9161 13.9701C19.3205 15.5763 17.1271 16.5123 14.8988 16.5366L14.8012 16.5361Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.93677 31.5502C4.32883 31.5517 3.72772 31.4221 3.17451 31.1703C2.62129 30.9186 2.129 30.5505 1.73126 30.0914C1.34459 29.6484 1.06134 29.1251 0.90203 28.5594C0.742716 27.9937 0.711312 27.3997 0.810092 26.8204C1.30922 23.8734 2.77666 21.4946 5.1697 19.7522C7.97591 17.7063 11.0932 16.6707 14.4352 16.6707C16.9863 16.6707 19.6483 17.2799 22.3436 18.4762C24.2059 19.3025 25.6977 20.4667 26.7803 21.9349C27.8079 23.3312 28.474 25.0171 28.7596 26.945C28.8409 27.5133 28.7968 28.0925 28.6305 28.642C28.4642 29.1915 28.1797 29.6981 27.7968 30.1263C27.3971 30.5755 26.9065 30.9349 26.3573 31.1806C25.8082 31.4264 25.2131 31.5529 24.6113 31.5519L4.93677 31.5502Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.5206C12.5784 16.5206 10.4871 15.6948 8.91148 14.1945C7.3359 12.6941 6.45133 10.6798 6.43359 8.53418C6.3931 4.08405 10.1016 0.407639 14.7008 0.342285H14.8117C19.31 0.342285 23.1749 4.01039 23.252 8.35252C23.2886 10.4062 22.4573 12.3956 20.9105 13.9546C19.3166 15.5608 17.1249 16.4968 14.8988 16.5211L14.8012 16.5206Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.94563 31.5348C4.33792 31.5359 3.73714 31.406 3.18438 31.1538C2.63161 30.9016 2.1399 30.5332 1.74289 30.0737C1.35702 29.6309 1.0743 29.1082 0.915108 28.5431C0.755915 27.9781 0.724209 27.3848 0.822274 26.8061C1.3214 23.8591 2.78773 21.4803 5.17855 19.7379C7.97977 17.6909 11.0966 16.6536 14.4357 16.6536C16.9868 16.6536 19.6427 17.2628 22.3369 18.4591C24.197 19.2854 25.6883 20.4496 26.7681 21.9178C27.7952 23.3168 28.4607 25 28.7457 26.9279C28.8271 27.4961 28.7832 28.0752 28.6171 28.6247C28.451 29.1742 28.1667 29.6808 27.7841 30.1092C27.3849 30.5583 26.8947 30.9177 26.346 31.1635C25.7974 31.4092 25.2026 31.5358 24.6013 31.5348H4.94563Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.5045C12.5829 16.5045 10.491 15.6788 8.91704 14.1784C7.34313 12.678 6.45856 10.6637 6.44137 8.51812C6.40088 4.06855 10.1055 0.392136 14.7014 0.326782H14.8123C19.3045 0.326782 23.1683 3.99488 23.2448 8.33701C23.2814 10.3907 22.4506 12.3801 20.9055 13.9391C19.3128 15.5453 17.1238 16.4813 14.8988 16.5056L14.8012 16.5045Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.95452 31.5191C4.3474 31.5205 3.74715 31.3908 3.19486 31.139C2.64256 30.8873 2.15125 30.5193 1.75455 30.0603C1.36857 29.617 1.08589 29.0936 0.926888 28.528C0.767884 27.9623 0.736517 27.3685 0.835046 26.7893C1.33417 23.8423 2.79828 21.4636 5.18689 19.7212C7.98811 17.6753 11.0999 16.6379 14.4363 16.6379C16.9824 16.6379 19.6383 17.2472 22.3303 18.4435C24.1887 19.2698 25.6783 20.434 26.7575 21.9022C27.7835 23.2985 28.4479 24.9844 28.7335 26.9123C28.8148 27.4805 28.7708 28.0596 28.6047 28.609C28.4386 29.1585 28.1544 29.6652 27.7719 30.0936C27.3734 30.5427 26.8838 30.902 26.3357 31.1478C25.7875 31.3936 25.1933 31.5202 24.5924 31.5191H4.95452Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.4896C12.5829 16.4896 10.4943 15.6638 8.92259 14.1634C7.35089 12.6631 6.46688 10.6488 6.44969 8.50317C6.40809 4.05304 10.11 0.376633 14.7014 0.311279H14.8123C19.3045 0.311279 23.1605 3.97938 23.237 8.32151C23.2742 10.3752 22.444 12.3646 20.9 13.9236C19.3089 15.5298 17.1216 16.4658 14.8988 16.4901L14.8012 16.4896Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.96337 31.5036C4.35703 31.5046 3.75763 31.3748 3.2062 31.123C2.65477 30.8712 2.16431 30.5034 1.76839 30.0448C1.38232 29.6015 1.09947 29.0782 0.940183 28.5126C0.780901 27.947 0.749153 27.3531 0.847223 26.7738C1.34635 23.8268 2.8088 21.4481 5.19574 19.7057C7.99418 17.6598 11.1032 16.6224 14.4363 16.6224C16.9802 16.6224 19.6339 17.2317 22.3236 18.428C24.1804 19.2543 25.6683 20.4185 26.7464 21.8867C27.7719 23.283 28.4357 24.9689 28.7208 26.8968C28.802 27.4649 28.7582 28.0439 28.5923 28.5933C28.4264 29.1428 28.1424 29.6495 27.7602 30.0781C27.3623 30.5271 26.8732 30.8865 26.3255 31.1323C25.7778 31.378 25.184 31.5046 24.5835 31.5036H4.96337Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.4741C12.5828 16.4741 10.4982 15.6483 8.92757 14.1479C7.35698 12.6476 6.47407 10.6338 6.45688 8.48933C6.41584 4.03754 10.1138 0.36113 14.7014 0.295777H14.8123C19.2983 0.294669 23.1527 3.96277 23.2309 8.30767C23.2675 10.3613 22.4384 12.3507 20.8956 13.9098C19.3061 15.5159 17.1205 16.4519 14.8999 16.4763L14.8012 16.4741Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.97225 31.4881C4.36602 31.4895 3.76667 31.3598 3.21536 31.108C2.66406 30.8562 2.17384 30.4882 1.77838 30.0293C1.3929 29.5857 1.11062 29.0623 0.951821 28.4967C0.793026 27.9311 0.761664 27.3374 0.859988 26.7583C1.35801 23.8113 2.82212 21.4337 5.20684 19.6902C8.00307 17.6443 11.1093 16.6069 14.4396 16.6069C16.9813 16.6069 19.6322 17.2162 22.3192 18.4125C24.1748 19.2388 25.6617 20.403 26.7387 21.8712C27.763 23.2674 28.4263 24.9533 28.7108 26.8813C28.7921 27.4493 28.7484 28.0282 28.5827 28.5777C28.417 29.1271 28.1333 29.6339 27.7514 30.0625C27.3539 30.5115 26.8653 30.8708 26.3181 31.1166C25.7709 31.3624 25.1775 31.4891 24.5774 31.4881H4.97225Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.4586C12.5862 16.4586 10.502 15.6328 8.93255 14.1324C7.36307 12.6321 6.48239 10.6194 6.46408 8.47383C6.42304 4.02204 10.1183 0.345629 14.7014 0.280276H14.8123C19.2933 0.276953 23.1455 3.94727 23.222 8.2894C23.2586 10.3431 22.4301 12.3325 20.8894 13.8915C19.3006 15.4977 17.1183 16.4337 14.8999 16.458L14.8012 16.4586Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.98114 31.4726C4.37532 31.4739 3.77641 31.3442 3.22556 31.0924C2.67471 30.8405 2.18499 30.4726 1.79005 30.0138C1.40483 29.57 1.12279 29.0466 0.96419 28.481C0.805591 27.9155 0.774368 27.3219 0.872762 26.7428C1.37189 23.7958 2.83045 21.4171 5.21296 19.6747C8.00641 17.6288 11.1099 16.5914 14.4368 16.5914C16.9763 16.5914 19.625 17.2007 22.312 18.397C24.1654 19.2233 25.6512 20.3875 26.727 21.8557C27.7503 23.2519 28.4135 24.9378 28.698 26.8658C28.7792 27.4338 28.7355 28.0126 28.5699 28.562C28.4043 29.1114 28.1208 29.6182 27.7392 30.047C27.3423 30.4959 26.8542 30.8552 26.3074 31.101C25.7606 31.3468 25.1677 31.4735 24.568 31.4726H4.98114Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.4431C12.5884 16.4431 10.5059 15.6173 8.93811 14.1169C7.37029 12.6166 6.4885 10.6023 6.47131 8.45666C6.43082 4.00598 10.1227 0.332339 14.7014 0.264771H14.8123C19.29 0.264771 23.1378 3.93287 23.2143 8.275C23.2509 10.3287 22.4234 12.3181 20.885 13.8771C19.2978 15.4833 17.1138 16.4193 14.8999 16.4436L14.8012 16.4431Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.98943 31.4582C4.38403 31.4593 3.78559 31.3293 3.23529 31.0773C2.68499 30.8252 2.1959 30.4572 1.80167 29.9983C1.41674 29.5544 1.13493 29.0309 0.976443 28.4654C0.817951 27.8998 0.786702 27.3063 0.884936 26.7273C1.38018 23.7803 2.84152 21.4016 5.22125 19.6614C8.01249 17.6122 11.1132 16.5759 14.4374 16.5759C16.9741 16.5759 19.6205 17.1852 22.3031 18.3815C24.1548 19.2078 25.6389 20.372 26.7143 21.8402C27.7364 23.2364 28.3991 24.9223 28.683 26.8503C28.7639 27.4183 28.7198 27.9972 28.5541 28.5465C28.3883 29.0959 28.1047 29.6027 27.7231 30.0315C27.327 30.4802 26.8398 30.8395 26.2939 31.0855C25.748 31.3315 25.1558 31.4586 24.5569 31.4582H4.98943Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.4276C12.5901 16.4276 10.5098 15.6018 8.94309 14.1014C7.37639 12.6011 6.49626 10.5867 6.47851 8.44116C6.43803 3.99048 10.1266 0.314621 14.7014 0.249268H14.8123C19.2861 0.249268 23.1311 3.91737 23.2071 8.2595C23.2437 10.3131 22.4162 12.3026 20.8778 13.8616C19.2922 15.4678 17.1127 16.4038 14.8977 16.4281L14.8012 16.4276Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.9983 31.4417C4.3934 31.4428 3.79546 31.3129 3.24563 31.0611C2.6958 30.8092 2.20715 30.4414 1.81331 29.9829C1.42878 29.5387 1.1473 29.0152 0.989016 28.4497C0.830727 27.8842 0.799541 27.2908 0.897689 26.7119C1.39682 23.7649 2.85205 21.3861 5.23012 19.6437C8.01858 17.5978 11.1165 16.5599 14.4374 16.5599C16.9724 16.5599 19.6167 17.1692 22.2964 18.3655C24.1465 19.1918 25.6295 20.356 26.7037 21.8242C27.7231 23.221 28.3886 24.9069 28.6708 26.8348C28.7521 27.403 28.7086 27.9821 28.5433 28.5319C28.378 29.0816 28.0949 29.5888 27.7136 30.0183C27.3176 30.4669 26.8305 30.826 26.2847 31.0718C25.7388 31.3176 25.1468 31.4445 24.548 31.4439L4.9983 31.4417Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.4121C12.5923 16.4121 10.5137 15.5863 8.94863 14.0859C7.38358 12.5856 6.50345 10.5712 6.48626 8.42566C6.44578 3.97497 10.131 0.299118 14.7014 0.233765H14.8123C19.2822 0.233765 23.1228 3.90187 23.1993 8.244C23.2359 10.2976 22.4096 12.2871 20.8728 13.8461C19.2883 15.4523 17.111 16.3883 14.8982 16.4126L14.8012 16.4121Z"
            fill="#1E1E1E"
        />
        <path
            d="M5.00719 31.4261C4.4025 31.4274 3.80475 31.2976 3.25516 31.0457C2.70558 30.7939 2.21725 30.4259 1.82386 29.9673C1.43966 29.5228 1.15852 28.999 1.00053 28.4333C0.842539 27.8677 0.81159 27.2742 0.909898 26.6952C1.40903 23.7482 2.8626 21.3695 5.23845 19.6271C8.02469 17.5812 11.1198 16.5438 14.4379 16.5438C16.9702 16.5438 19.6122 17.1531 22.2898 18.3494C24.1382 19.1757 25.6173 20.3399 26.6926 21.8081C27.7131 23.2043 28.3747 24.8902 28.6581 26.8182C28.7391 27.3859 28.6956 27.9645 28.5305 28.5138C28.3654 29.0632 28.0827 29.5701 27.702 29.9994C27.3069 30.4479 26.8205 30.807 26.2754 31.0528C25.7303 31.2986 25.139 31.4255 24.5408 31.425L5.00719 31.4261Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.3965C12.5939 16.3965 10.5176 15.5707 8.95362 14.0704C7.38969 12.57 6.51344 10.5557 6.49347 8.41011C6.45243 3.95776 10.1343 0.281906 14.7014 0.216553H14.8123C19.2778 0.216553 23.1156 3.88465 23.1915 8.22678C23.2281 10.2804 22.4029 12.2698 20.8673 13.8289C19.2845 15.4351 17.1088 16.371 14.8982 16.3954L14.8012 16.3965Z"
            fill="#1E1E1E"
        />
        <path
            d="M5.01606 31.4106C4.41197 31.4116 3.81486 31.2817 3.26594 31.0298C2.71702 30.778 2.22936 30.4102 1.83661 29.9518C1.45257 29.5074 1.17152 28.9838 1.01353 28.4183C0.855546 27.8529 0.824512 27.2596 0.922649 26.6808C1.41845 23.7338 2.87369 21.3551 5.24843 19.6127C8.0319 17.5668 11.127 16.5294 14.439 16.5294C16.9696 16.5294 19.6089 17.1386 22.2837 18.3349C24.131 19.1613 25.6112 20.3255 26.6832 21.7937C27.7025 23.1899 28.3636 24.8758 28.647 26.8038C28.728 27.3715 28.6845 27.95 28.5195 28.4993C28.3545 29.0486 28.072 29.5555 27.6914 29.985C27.2965 30.4337 26.8102 30.793 26.2651 31.0388C25.7199 31.2847 25.1285 31.4115 24.5303 31.4106H5.01606Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.381C12.5962 16.381 10.5214 15.5552 8.95918 14.0549C8.18953 13.3248 7.57518 12.4473 7.15286 11.4748C6.73054 10.5022 6.50892 9.45462 6.50125 8.3946C6.46076 3.94391 10.1393 0.268056 14.7014 0.202148H14.8123C19.2739 0.202148 23.1078 3.87025 23.1865 8.21238C23.2226 10.266 22.3979 12.2554 20.8639 13.8145C19.2828 15.4206 17.1088 16.3566 14.9005 16.381H14.8012Z"
            fill="#1E1E1E"
        />
        <path
            d="M5.02495 31.3951C4.42129 31.396 3.82462 31.266 3.27616 31.0142C2.72771 30.7623 2.24053 30.3945 1.84828 29.9363C1.4648 29.4916 1.18426 28.9679 1.02666 28.4025C0.86907 27.8371 0.838297 27.2439 0.936537 26.6653C1.43178 23.7183 2.88591 21.3395 5.25732 19.5972C8.03525 17.5513 11.1265 16.5139 14.4385 16.5139C16.9663 16.5139 19.6034 17.1231 22.2759 18.3194C24.121 19.1458 25.6001 20.31 26.6716 21.7782C27.6898 23.1744 28.3503 24.8603 28.6332 26.7883C28.7142 27.3559 28.6709 27.9344 28.5061 28.4837C28.3413 29.0329 28.059 29.5399 27.6787 29.9695C27.2846 30.418 26.799 30.7771 26.2546 31.023C25.7102 31.2688 25.1195 31.3957 24.522 31.3951H5.02495Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.3655C12.5984 16.3655 10.5253 15.5397 8.96416 14.0394C8.19511 13.309 7.58131 12.4314 7.15939 11.4589C6.73747 10.4864 6.51608 9.43892 6.50845 8.37909C6.46796 3.92841 10.1427 0.252553 14.7019 0.186646H14.8128C19.27 0.186092 23.1006 3.85419 23.1754 8.19688C23.2121 10.2505 22.3879 12.2399 20.8551 13.799C19.275 15.4051 17.1033 16.3411 14.8971 16.3655H14.8012Z"
            fill="#1E1E1E"
        />
        <path
            d="M5.03383 31.3796C4.43056 31.3806 3.83428 31.2506 3.28627 30.9987C2.73825 30.7468 2.25158 30.379 1.85993 29.9208C1.47658 29.476 1.19605 28.9523 1.03828 28.3869C0.880513 27.8216 0.849373 27.2285 0.947076 26.6498C1.44232 23.7045 2.89479 21.3229 5.26232 19.5817C8.04024 17.5358 11.1276 16.4984 14.4363 16.4984C16.9624 16.4984 19.594 17.1076 22.2671 18.3039C24.1105 19.1303 25.5879 20.2945 26.6583 21.7627C27.6765 23.1589 28.3359 24.8448 28.6187 26.7728C28.6998 27.3402 28.6567 27.9184 28.4925 28.4675C28.3283 29.0167 28.0469 29.5239 27.6676 29.954C27.2738 30.4023 26.7887 30.7614 26.2446 31.0073C25.7006 31.2531 25.1103 31.3801 24.5131 31.3796H5.03383Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.35C12.6001 16.35 10.5309 15.5242 8.96973 14.0239C8.20122 13.2931 7.58796 12.4153 7.16652 11.4427C6.74509 10.4701 6.52412 9.42274 6.51679 8.36303C6.47575 3.91291 10.1477 0.23705 14.7019 0.171143H14.8129C19.2667 0.171143 23.0939 3.83925 23.1699 8.18137C23.2065 10.235 22.3835 12.2244 20.8518 13.7835C19.2734 15.3896 17.1039 16.3256 14.8988 16.35H14.8012Z"
            fill="#1E1E1E"
        />
        <path
            d="M5.04048 31.3641C4.43817 31.3649 3.84286 31.2351 3.29573 30.9836C2.74859 30.7321 2.26267 30.365 1.87157 29.9075C1.48817 29.4623 1.20771 28.9382 1.05013 28.3725C0.892546 27.8067 0.861716 27.2133 0.959829 26.6343C1.45452 23.6873 2.90532 21.3085 5.27285 19.5662C8.04856 17.5203 11.1326 16.4829 14.4391 16.4829C16.9624 16.4829 19.5967 17.0921 22.2626 18.2884C24.1044 19.1148 25.5807 20.279 26.65 21.7472C27.6676 23.1434 28.3259 24.8293 28.6104 26.7573C28.6912 27.3247 28.6479 27.903 28.4836 28.4521C28.3192 29.0013 28.0376 29.5084 27.6582 29.9385C27.265 30.3868 26.7803 30.7459 26.2367 30.9917C25.6931 31.2376 25.1032 31.3646 24.5065 31.3641H5.04048Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.3345C12.6318 16.3453 10.5433 15.5124 8.97804 14.0122C8.2101 13.2812 7.59732 12.4034 7.17619 11.431C6.75507 10.4585 6.53421 9.41142 6.52677 8.35196C6.4824 3.8974 10.1515 0.221547 14.7019 0.15564H14.8128C19.2617 0.155086 23.0856 3.82153 23.1616 8.16532C23.1976 10.219 22.3752 12.2084 20.8456 13.7674C19.2684 15.3736 17.1005 16.3096 14.8977 16.3339L14.8012 16.3345Z"
            fill="#1E1E1E"
        />
        <path
            d="M5.051 31.3475C4.44865 31.3482 3.85334 31.2181 3.30635 30.9662C2.75935 30.7143 2.27375 30.3467 1.8832 29.8887C1.50031 29.4436 1.22019 28.9199 1.06272 28.3546C0.905247 27.7893 0.874279 27.1963 0.972013 26.6177C1.46615 23.6718 2.91639 21.293 5.28115 19.5507C8.05409 17.5048 11.1359 16.4674 14.4396 16.4674C16.9608 16.4674 19.5906 17.0766 22.2559 18.2729C24.0961 19.0993 25.5707 20.2634 26.6372 21.7317C27.6532 23.1279 28.3115 24.8138 28.5938 26.7417C28.6745 27.3092 28.6313 27.8874 28.467 28.4365C28.3027 28.9857 28.0213 29.4928 27.6421 29.923C27.2496 30.3707 26.7659 30.7293 26.2233 30.975C25.6806 31.2207 25.0917 31.3477 24.4959 31.3475H5.051Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.319C12.631 16.33 10.542 15.4955 8.97804 13.9929C8.21071 13.2615 7.59847 12.3836 7.17774 11.4112C6.75702 10.4388 6.53639 9.39184 6.52898 8.33258C6.49072 3.8819 10.156 0.206044 14.7019 0.140137H14.8128C19.259 0.140137 23.079 3.80824 23.1549 8.15037C23.1915 10.204 22.3696 12.1934 20.8412 13.7525C19.2651 15.3586 17.0994 16.2946 14.8988 16.319H14.8012Z"
            fill="#1E1E1E"
        />
        <path
            d="M5.05988 31.3326C4.45789 31.3333 3.86295 31.2033 3.31632 30.9515C2.76969 30.6997 2.28446 30.3321 1.8943 29.8743C1.51186 29.429 1.23216 28.9051 1.07498 28.3399C0.917805 27.7746 0.887009 27.1818 0.984773 26.6033C1.47836 23.6563 2.92583 21.2775 5.29003 19.5351C8.06297 17.4893 11.1393 16.4519 14.4407 16.4519C16.9596 16.4519 19.5873 17.0611 22.2504 18.2574C24.0889 19.0838 25.5624 20.2479 26.6316 21.7162C27.6465 23.1124 28.3043 24.7983 28.586 26.7262C28.6664 27.2938 28.6229 27.872 28.4583 28.4212C28.2937 28.9703 28.0121 29.4774 27.6327 29.9075C27.2405 30.3555 26.7569 30.7144 26.2144 30.9602C25.6718 31.206 25.0828 31.333 24.4871 31.3326H5.05988Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.3035C12.6332 16.3139 10.5467 15.4794 8.98525 13.9774C8.21848 13.2459 7.60671 12.368 7.18629 11.3959C6.76587 10.4237 6.54536 9.37711 6.53786 8.31819C6.49793 3.8664 10.1599 0.190541 14.7019 0.124634H14.8128C19.2551 0.124634 23.0718 3.79274 23.1477 8.13486C23.1838 10.1885 22.363 12.1779 20.8357 13.737C19.2612 15.3431 17.0972 16.2791 14.9016 16.3035H14.8012Z"
            fill="#1E1E1E"
        />
        <path
            d="M5.06874 31.3171C4.46745 31.3175 3.87324 31.1874 3.32737 30.9356C2.7815 30.6837 2.29704 30.3163 1.9076 29.8588C1.52546 29.4133 1.24598 28.8894 1.08891 28.3242C0.931842 27.759 0.901022 27.1662 0.998629 26.5878C1.49166 23.6408 2.93968 21.262 5.3 19.5196C8.06684 17.4738 11.1409 16.4364 14.4402 16.4364C16.9569 16.4364 19.5817 17.0456 22.2426 18.2419C24.0794 19.0683 25.5513 20.2324 26.6178 21.7007C27.6321 23.0969 28.2893 24.7828 28.571 26.7107C28.6517 27.2781 28.6085 27.8561 28.4446 28.4052C28.2806 28.9543 27.9996 29.4615 27.621 29.892C27.2294 30.3399 26.7463 30.6988 26.2042 30.9446C25.6621 31.1904 25.0736 31.3174 24.4782 31.3171H5.06874Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.2879C12.6347 16.2982 10.5498 15.4636 8.99023 13.9618C8.22396 13.2298 7.61273 12.3515 7.19279 11.3791C6.77286 10.4067 6.55278 9.35991 6.54561 8.30095C6.50568 3.85082 10.1643 0.174964 14.7019 0.110718H14.8128C19.2495 0.110718 23.064 3.77882 23.14 8.12095C23.176 10.1746 22.3558 12.164 20.8301 13.7231C19.2573 15.3292 17.0955 16.2652 14.8988 16.2896L14.8012 16.2879Z"
            fill="#1E1E1E"
        />
        <path
            d="M5.07708 31.3016C4.47573 31.3024 3.8814 31.1724 3.33548 30.9206C2.78955 30.6687 2.30513 30.3011 1.91594 29.8433C1.534 29.3977 1.25468 28.8738 1.09771 28.3086C0.940746 27.7434 0.909959 27.1507 1.00752 26.5723C1.50055 23.6253 2.94858 21.2465 5.30557 19.5041C8.0713 17.4582 11.1443 16.4209 14.4385 16.4209C16.953 16.4209 19.5756 17.0301 22.2338 18.2264C24.0695 19.0528 25.5402 20.2169 26.6056 21.6852C27.6205 23.0814 28.2777 24.7668 28.5588 26.6952C28.6398 27.2623 28.5972 27.8402 28.4338 28.3892C28.2704 28.9383 27.9901 29.4456 27.6122 29.8765C27.2211 30.3244 26.7385 30.6833 26.1969 30.9291C25.6552 31.1749 25.0671 31.3019 24.4721 31.3016H5.07708Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.2725C12.6364 16.2826 10.5532 15.448 8.99577 13.9463C8.22983 13.2142 7.61892 12.3358 7.19927 11.3634C6.77963 10.391 6.5598 9.34436 6.55281 8.28552C6.51288 3.83539 10.1682 0.159535 14.7019 0.0936279H14.8128C19.2468 0.0936279 23.0568 3.76173 23.1316 8.10386C23.1682 10.1575 22.3486 12.1469 20.824 13.706C19.2529 15.3121 17.0927 16.2481 14.8977 16.2725H14.8012Z"
            fill="#1E1E1E"
        />
        <path
            d="M5.08596 31.286C4.48529 31.2866 3.8917 31.1565 3.34654 30.9046C2.80137 30.6528 2.31771 30.2853 1.92925 29.8278C1.5476 29.382 1.26855 28.8581 1.11178 28.2929C0.955011 27.7277 0.924344 27.1351 1.02194 26.5568C1.51386 23.6098 2.95856 21.231 5.31777 19.4886C8.08128 17.4427 11.1515 16.4054 14.4429 16.4054C16.9547 16.4054 19.5751 17.0146 22.231 18.2109C24.065 19.0373 25.5347 20.2014 26.5989 21.6697C27.6111 23.0659 28.2671 24.7513 28.5483 26.6797C28.629 27.2469 28.586 27.8248 28.4223 28.3739C28.2587 28.923 27.9781 29.4302 27.6 29.861C27.2095 30.3086 26.7274 30.6674 26.1863 30.9132C25.6452 31.159 25.0577 31.2862 24.4632 31.286H5.08596Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.257C12.6381 16.2669 10.5567 15.4321 9.00132 13.9308C8.23605 13.1983 7.62577 12.3198 7.20662 11.3475C6.78747 10.3751 6.56798 9.32862 6.56113 8.27002C6.52009 3.82155 10.1726 0.144032 14.7019 0.0781251H14.8128C19.2418 0.0775712 23.0479 3.74567 23.1239 8.08614C23.1599 10.1398 22.3413 12.1292 20.8179 13.6877C19.2479 15.2939 17.09 16.2304 14.8971 16.2548L14.8012 16.257Z"
            fill="#1E1E1E"
        />
        <path
            d="M5.09592 31.2705C4.49568 31.271 3.90255 31.1408 3.35785 30.8889C2.81315 30.6371 2.32998 30.2697 1.94199 29.8123C1.56073 29.3663 1.28197 28.8423 1.12531 28.2772C0.968654 27.7121 0.937908 27.1195 1.03524 26.5413C1.52605 23.5937 2.96964 21.2155 5.32386 19.4731C8.08515 17.4272 11.1526 16.3899 14.4407 16.3899C16.9508 16.3899 19.569 16.9991 22.2221 18.1954C24.0523 19.0218 25.5225 20.1859 26.5862 21.6542C27.5972 23.0504 28.2527 24.7358 28.5339 26.6642C28.6143 27.2313 28.5714 27.8091 28.4079 28.3581C28.2444 28.9071 27.9643 29.4144 27.5866 29.8455C27.1965 30.2932 26.7147 30.6521 26.1737 30.898C25.6328 31.1438 25.0453 31.2709 24.451 31.2705H5.09592Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.2415C12.6396 16.2511 10.5599 15.4163 9.00631 13.9153C8.24124 13.1826 7.6311 12.3041 7.21196 11.3318C6.79283 10.3595 6.57322 9.31308 6.56612 8.25451C6.52786 3.80439 10.1765 0.128529 14.7025 0.0626221H14.8134C19.2395 0.0626221 23.0423 3.73072 23.1178 8.07285C23.1544 10.1265 22.3358 12.1159 20.8146 13.6744C19.2456 15.2806 17.0894 16.2171 14.8988 16.2415H14.8012Z"
            fill="#1E1E1E"
        />
        <path
            d="M5.10368 31.255C4.50384 31.2554 3.91113 31.1251 3.36688 30.8733C2.82264 30.6214 2.33996 30.254 1.95252 29.7967C1.57165 29.3506 1.29325 28.8265 1.13689 28.2614C0.980526 27.6963 0.949991 27.1039 1.04743 26.5257C1.53879 23.5787 2.98127 21.2 5.33383 19.4576C8.09235 17.4117 11.157 16.3738 14.4424 16.3738C16.9497 16.3738 19.5656 16.983 22.2166 18.1799C24.0467 19.0062 25.5141 20.1704 26.5767 21.6386C27.5872 23.0348 28.2405 24.7202 28.5228 26.6487C28.6032 27.2157 28.5603 27.7934 28.3969 28.3424C28.2335 28.8914 27.9536 29.3988 27.5761 29.8299C27.1866 30.2775 26.7055 30.6364 26.1652 30.8822C25.6249 31.128 25.0381 31.2552 24.4443 31.255H5.10368Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.2276C12.6413 16.2373 10.5633 15.4023 9.01188 13.9015C8.24767 13.1683 7.63834 12.2897 7.21989 11.3174C6.80144 10.3451 6.58236 9.29893 6.57558 8.24067C6.53787 3.78888 10.1809 0.110811 14.7025 0.0471191H14.8134C19.2351 0.0471191 23.0352 3.71522 23.1106 8.05735C23.1466 10.111 22.3292 12.1004 20.809 13.6589C19.2418 15.2651 17.0878 16.2016 14.8988 16.226L14.8012 16.2276Z"
            fill="#1E1E1E"
        />
        <path
            d="M5.11201 31.2395C4.5126 31.2397 3.92036 31.1094 3.37659 30.8575C2.83282 30.6057 2.35062 30.2384 1.96362 29.7812C1.58305 29.3349 1.30488 28.8108 1.14862 28.2458C0.992364 27.6807 0.961806 27.0883 1.05909 26.5102C1.5499 23.5632 2.9885 21.1845 5.34105 19.4399C8.0968 17.394 11.1592 16.3561 14.4413 16.3561C16.9469 16.3561 19.5601 16.9653 22.2088 18.1622C24.039 18.9885 25.5031 20.1527 26.5651 21.6209C27.5744 23.0171 28.2289 24.7025 28.5089 26.631C28.5893 27.1979 28.5464 27.7756 28.3832 28.3245C28.2201 28.8735 27.9404 29.3809 27.5634 29.8123C27.1745 30.2596 26.6939 30.6184 26.1542 30.8643C25.6145 31.1101 25.0282 31.2373 24.4349 31.2373L5.11201 31.2395Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.2105C12.6428 16.2199 10.5664 15.3849 9.01687 13.8843C8.25316 13.1509 7.64435 12.2722 7.22638 11.2999C6.80841 10.3276 6.58976 9.28157 6.58334 8.22351C6.54286 3.77338 10.1848 0.0975235 14.7025 0.0316162H14.8134C19.2313 0.0316162 23.0274 3.69972 23.1028 8.04185C23.1389 10.0955 22.3225 12.0849 20.8035 13.6434C19.2379 15.2496 17.0878 16.1861 14.8988 16.2105H14.8012Z"
            fill="#1E1E1E"
        />
        <path
            d="M5.12088 31.224C4.52187 31.2243 3.93001 31.0939 3.38668 30.842C2.84336 30.5902 2.36166 30.2228 1.97526 29.7657C1.59486 29.3193 1.31683 28.7952 1.16067 28.2302C1.00451 27.6651 0.974005 27.0728 1.07129 26.4947C1.56154 23.5477 3.00125 21.169 5.34937 19.4266C8.10289 17.3807 11.162 16.3428 14.4446 16.3428C16.9474 16.3428 19.5584 16.952 22.2049 18.1489C24.0317 18.9752 25.4964 20.1394 26.5573 21.6076C27.5656 23.0038 28.2211 24.6892 28.4984 26.6177C28.5791 27.1844 28.5367 27.7619 28.374 28.3109C28.2113 28.8598 27.9322 29.3673 27.5556 29.7989C27.1673 30.2463 26.6872 30.6051 26.1479 30.8509C25.6087 31.0968 25.0228 31.224 24.4299 31.224H5.12088Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.195C12.6442 16.2043 10.5695 15.3692 9.02184 13.8688C8.25959 13.135 7.65205 12.2562 7.23498 11.2843C6.81791 10.3124 6.59974 9.26703 6.59331 8.20967C6.55005 3.75788 10.1892 0.0820206 14.7025 0.0161133H14.8134C19.2274 0.0161133 23.0213 3.68422 23.0956 8.02635C23.1316 10.08 22.3158 12.0694 20.7979 13.6279C19.2335 15.2341 17.0822 16.1701 14.8982 16.195H14.8012Z"
            fill="#1E1E1E"
        />
        <path
            d="M5.12976 31.2085C4.53116 31.2087 3.93975 31.0783 3.39689 30.8264C2.85402 30.5745 2.37281 30.2072 1.98692 29.7502C1.60683 29.3036 1.32905 28.7795 1.173 28.2145C1.01695 27.6495 0.986402 27.0572 1.08349 26.4792C1.57375 23.5322 3.01179 21.1535 5.35769 19.4111C8.109 17.3652 11.1653 16.3273 14.4418 16.3273C16.943 16.3273 19.5518 16.9365 22.1955 18.1334C24.0207 18.9597 25.4837 20.1239 26.5435 21.5921C27.5512 22.9883 28.2045 24.6737 28.4845 26.6022C28.565 27.1689 28.5224 27.7463 28.3597 28.2952C28.197 28.8441 27.9181 29.3517 27.5417 29.7834C27.1538 30.2307 26.6742 30.5894 26.1353 30.8353C25.5963 31.0812 25.0108 31.2085 24.4183 31.2085H5.12976Z"
            fill="#1E1E1E"
        />
        <path
            d="M14.8012 16.1794C12.6459 16.1886 10.573 15.3534 9.02739 13.8533C8.26542 13.1197 7.65797 12.2414 7.24073 11.27C6.82349 10.2987 6.60488 9.25382 6.59775 8.19688C6.55726 3.74232 10.1931 0.0664612 14.7025 0H14.8134C19.2218 0 23.0091 3.6681 23.0862 8.01023C23.1222 10.0639 22.307 12.0533 20.7913 13.6118C19.2279 15.2179 17.0794 16.1539 14.8966 16.1789L14.8012 16.1794Z"
            fill="#1E1E1E"
        />
        <path
            d="M4.66778 31.2401H24.8791C26.9672 31.2401 28.6331 29.4922 28.3359 27.52C27.7979 23.9881 25.8774 21.2294 22.2144 19.646C16.5542 17.1985 10.6418 17.1138 5.39262 20.8401C2.95854 22.5653 1.68133 24.8277 1.2299 27.4147C0.87496 29.4191 2.53595 31.2401 4.66778 31.2401Z"
            fill="url(#paint0_linear_129_10)"
        />
        <path
            d="M14.8933 16.2099C19.1375 16.1645 22.7429 12.7423 22.6708 8.82998C22.5987 4.86778 18.9262 1.52811 14.7108 1.58294C10.4333 1.6422 6.98152 4.96415 7.01979 8.99502C7.05805 13.0259 10.5725 16.2559 14.8933 16.2099Z"
            fill="url(#paint1_linear_129_10)"
        />
        <path
            d="M4.67667 31.2246H24.8697C26.9566 31.2246 28.621 29.4767 28.3237 27.5044C27.7863 23.9726 25.8674 21.2122 22.2077 19.6304C16.5526 17.183 10.6418 17.1005 5.40096 20.8245C2.96909 22.5498 1.69299 24.8122 1.24155 27.3992C0.887173 29.4036 2.54483 31.2246 4.67667 31.2246Z"
            fill="url(#paint2_linear_129_10)"
        />
        <path
            d="M14.8932 16.1943C19.1331 16.1489 22.7357 12.7267 22.6636 8.81435C22.5915 4.85216 18.9223 1.51249 14.7108 1.56732C10.4371 1.62658 6.98872 4.94853 7.02699 8.9794C7.06525 13.0103 10.5763 16.2403 14.8932 16.1943Z"
            fill="url(#paint3_linear_129_10)"
        />
        <path
            d="M4.68553 31.209H24.8608C26.9455 31.209 28.6082 29.4611 28.3115 27.4889C27.7746 23.957 25.8574 21.1983 22.201 19.6149C16.5515 17.1691 10.6473 17.0849 5.40927 20.809C2.97962 22.5342 1.70518 24.7966 1.2543 27.3836C0.89992 29.388 2.55813 31.209 4.68553 31.209Z"
            fill="url(#paint4_linear_129_10)"
        />
        <path
            d="M14.8932 16.1788C19.1292 16.1334 22.7285 12.7112 22.6575 8.79887C22.5848 4.83668 18.9184 1.49534 14.7108 1.55073C10.4405 1.60999 6.99537 4.93194 7.03364 8.96281C7.07191 12.9937 10.5802 16.2276 14.8932 16.1788Z"
            fill="url(#paint5_linear_129_10)"
        />
        <path
            d="M4.69496 31.1936H24.852C26.9344 31.1936 28.596 29.4457 28.2993 27.4734C27.763 23.9416 25.8475 21.1829 22.1944 19.5994C16.5498 17.152 10.6507 17.0695 5.41758 20.7935C2.98849 22.5187 1.71683 24.779 1.26927 27.3682C0.91212 29.3726 2.56923 31.1936 4.69496 31.1936Z"
            fill="url(#paint6_linear_129_10)"
        />
        <path
            d="M14.8932 16.1633C19.127 16.1168 22.7212 12.6951 22.6491 8.78335C22.5776 4.82115 18.9146 1.48148 14.7108 1.53631C10.4449 1.59557 7.00203 4.91752 7.04085 8.94839C7.07967 12.9793 10.5863 16.2093 14.8932 16.1633Z"
            fill="url(#paint7_linear_129_10)"
        />
        <path
            d="M4.70384 31.1781H24.8426C26.9234 31.1781 28.5838 29.4302 28.2871 27.4579C27.7514 23.9261 25.8375 21.1674 22.1877 19.5839C16.5482 17.1365 10.6546 17.054 5.42869 20.778C3.00348 22.5032 1.73126 24.7657 1.28149 27.3527C0.924888 29.3571 2.57978 31.1781 4.70384 31.1781Z"
            fill="url(#paint8_linear_129_10)"
        />
        <path
            d="M14.8927 16.1478C19.1214 16.1024 22.7124 12.6796 22.6425 8.76784C22.5704 4.80565 18.9112 1.46598 14.7119 1.52081C10.4488 1.58007 7.00981 4.90202 7.04808 8.93289C7.08634 12.9638 10.5863 16.1938 14.8927 16.1478Z"
            fill="url(#paint9_linear_129_10)"
        />
        <path
            d="M4.71271 31.1626H24.8337C26.9128 31.1626 28.571 29.4147 28.2749 27.4424C27.7397 23.9106 25.8281 21.1519 22.1811 19.5684C16.5465 17.121 10.6584 17.0385 5.43257 20.7625C3.00902 22.4877 1.73791 24.7502 1.28869 27.3372C0.936532 29.3416 2.59086 31.1626 4.71271 31.1626Z"
            fill="url(#paint10_linear_129_10)"
        />
        <path
            d="M14.8927 16.1323C19.1175 16.0869 22.7068 12.6641 22.6353 8.75234C22.5654 4.79015 18.9051 1.45047 14.7114 1.5053C10.4527 1.56457 7.01646 4.88652 7.05473 8.91683C7.093 12.9471 10.5913 16.1783 14.8927 16.1323Z"
            fill="url(#paint11_linear_129_10)"
        />
        <path
            d="M4.72213 31.1471H24.8248C26.9017 31.1471 28.5588 29.3991 28.2632 27.4269C27.7281 23.8951 25.8181 21.1364 22.1772 19.5507C16.5448 17.1055 10.6623 17.023 5.44309 20.747C3.02232 22.4722 1.75176 24.7347 1.3031 27.3217C0.949828 29.326 2.60028 31.1471 4.72213 31.1471Z"
            fill="url(#paint12_linear_129_10)"
        />
        <path
            d="M14.8927 16.1168C19.1137 16.0714 22.6996 12.6486 22.6281 8.73684C22.5565 4.77464 18.9051 1.43497 14.7113 1.4898C10.4566 1.55072 7.02367 4.87378 7.06193 8.90188C7.1002 12.93 10.5952 16.1628 14.8927 16.1168Z"
            fill="url(#paint13_linear_129_10)"
        />
        <path
            d="M4.731 31.1315H24.8154C26.8906 31.1315 28.5461 29.3835 28.2505 27.4113C27.7164 23.8794 25.8103 21.1207 22.1678 19.5373C16.5431 17.0899 10.6656 17.0074 5.45141 20.7314C3.03285 22.4566 1.7634 24.7191 1.3153 27.3061C0.962025 29.3104 2.61303 31.1315 4.731 31.1315Z"
            fill="url(#paint14_linear_129_10)"
        />
        <path
            d="M14.8927 16.1013C19.1076 16.0559 22.6924 12.6331 22.6209 8.72133C22.5493 4.75914 18.9001 1.41947 14.7113 1.4743C10.4604 1.53356 7.03032 4.85551 7.06858 8.88638C7.10685 12.9172 10.5991 16.1473 14.8927 16.1013Z"
            fill="url(#paint15_linear_129_10)"
        />
        <path
            d="M4.73988 31.116H24.8065C26.8801 31.116 28.5339 29.3681 28.2383 27.3959C27.7048 23.864 25.7981 21.1053 22.1611 19.5219C16.5415 17.0745 10.6695 16.9919 5.45974 20.7138C3.04396 22.4412 1.77506 24.7014 1.32751 27.2906C0.974793 29.295 2.62358 31.116 4.73988 31.116Z"
            fill="url(#paint16_linear_129_10)"
        />
        <path
            d="M14.8927 16.0858C19.1076 16.0404 22.6858 12.6176 22.6142 8.70583C22.5427 4.74364 18.8985 1.40397 14.7113 1.4588C10.4643 1.51806 7.03698 4.84001 7.0758 8.87088C7.11462 12.9017 10.603 16.1318 14.8927 16.0858Z"
            fill="url(#paint17_linear_129_10)"
        />
        <path
            d="M4.7493 31.1005H24.7976C26.869 31.1005 28.5217 29.3525 28.2261 27.3803C27.6931 23.8484 25.7859 21.0897 22.1545 19.5063C16.5398 17.0589 10.6745 16.9763 5.46805 20.7004C3.05393 22.4256 1.78725 24.6881 1.33915 27.2751C0.986986 29.2794 2.63466 31.1005 4.7493 31.1005Z"
            fill="url(#paint18_linear_129_10)"
        />
        <path
            d="M14.8922 16.0703C19.102 16.0249 22.6786 12.6021 22.607 8.69033C22.5355 4.72814 18.8913 1.38846 14.7114 1.44329C10.4682 1.50255 7.0442 4.8245 7.08247 8.85537C7.12074 12.8862 10.6063 16.1168 14.8922 16.0703Z"
            fill="url(#paint19_linear_129_10)"
        />
        <path
            d="M4.75818 31.085H24.7887C26.8585 31.085 28.5095 29.337 28.2144 27.3648C27.6815 23.8329 25.7781 21.0742 22.1478 19.4908C16.5387 17.0434 10.6767 16.9608 5.47637 20.6849C3.06447 22.4101 1.7989 24.6726 1.35135 27.2596C0.99919 29.2639 2.64576 31.085 4.75818 31.085Z"
            fill="url(#paint20_linear_129_10)"
        />
        <path
            d="M14.8921 16.0548C19.0981 16.0094 22.6713 12.5866 22.6009 8.67483C22.5288 4.71263 18.8891 1.37296 14.7114 1.42779C10.4721 1.48705 7.0514 4.809 7.09244 8.83987C7.13348 12.8707 10.6102 16.1008 14.8921 16.0548Z"
            fill="url(#paint21_linear_129_10)"
        />
        <path
            d="M4.76707 31.0705H24.7793C26.8474 31.0705 28.4951 29.3226 28.2022 27.3504C27.6676 23.8152 25.7682 21.0587 22.1412 19.4753C16.5371 17.0278 10.6806 16.9475 5.48415 20.6694C3.07447 22.3946 1.81001 24.657 1.36301 27.244C1.01196 29.2484 2.65575 31.0705 4.76707 31.0705Z"
            fill="url(#paint22_linear_129_10)"
        />
        <path
            d="M14.8922 16.0393C19.0943 15.9939 22.6641 12.5711 22.5931 8.65932C22.5216 4.69713 18.8852 1.35746 14.7114 1.41229C10.476 1.47155 7.05806 4.7935 7.09633 8.82437C7.13459 12.8552 10.6141 16.0852 14.8922 16.0393Z"
            fill="url(#paint23_linear_129_10)"
        />
        <path
            d="M4.77594 31.0539H24.7705C26.8363 31.0539 28.4862 29.306 28.19 27.3338C27.6582 23.8019 25.7582 21.046 22.1345 19.4598C16.5332 17.0124 10.684 16.9298 5.49302 20.6539C3.08556 22.3791 1.82221 24.6416 1.37577 27.2286C1.02416 29.2329 2.6674 31.0539 4.77594 31.0539Z"
            fill="url(#paint24_linear_129_10)"
        />
        <path
            d="M14.8921 16.0238C19.0904 15.9784 22.6564 12.5556 22.5859 8.64382C22.5144 4.68163 18.8813 1.34195 14.7108 1.39678C10.4793 1.45605 7.06471 4.778 7.10242 8.80886C7.14013 12.8397 10.6179 16.0697 14.8921 16.0238Z"
            fill="url(#paint25_linear_129_10)"
        />
        <path
            d="M4.78481 31.0384H24.7616C26.8258 31.0384 28.4723 29.2905 28.1778 27.3183C27.6465 23.7864 25.7487 21.0272 22.1278 19.4443C16.5337 16.9969 10.6878 16.9143 5.50133 20.6384C3.09609 22.3636 1.83385 24.626 1.38741 27.213C1.03636 29.2174 2.67849 31.0384 4.78481 31.0384Z"
            fill="url(#paint26_linear_129_10)"
        />
        <path
            d="M14.8921 16.0061C19.0865 15.9607 22.6503 12.538 22.5787 8.62616C22.51 4.66618 18.878 1.32928 14.7119 1.38134C10.4837 1.44005 7.07192 4.7631 7.11018 8.79342C7.14845 12.8237 10.6213 16.0543 14.8921 16.0061Z"
            fill="url(#paint27_linear_129_10)"
        />
        <path
            d="M4.79422 31.0224H24.7527C26.8146 31.0224 28.4601 29.275 28.1662 27.3028C27.6349 23.7709 25.7387 21.0117 22.1212 19.4288C16.5315 16.9814 10.6911 16.8988 5.50908 20.6229C3.10605 22.3481 1.84492 24.6105 1.39904 27.1975C1.04743 29.2019 2.689 31.0224 4.79422 31.0224Z"
            fill="url(#paint28_linear_129_10)"
        />
        <path
            d="M14.8916 15.9928C19.0826 15.9474 22.643 12.5246 22.5721 8.61281C22.5005 4.65228 18.8741 1.31095 14.7119 1.36578C10.4876 1.42504 7.07911 4.74699 7.11683 8.77786C7.15454 12.8087 10.6251 16.0387 14.8916 15.9928Z"
            fill="url(#paint29_linear_129_10)"
        />
        <path
            d="M4.80311 31.0069H24.7438C26.8041 31.0069 28.4474 29.2595 28.154 27.2873C27.6233 23.7554 25.7266 20.9962 22.1151 19.4133C16.5304 16.9659 10.6973 16.8833 5.51797 20.6074C3.11661 22.3326 1.8577 24.595 1.41403 27.182C1.06131 29.1875 2.70012 31.0069 4.80311 31.0069Z"
            fill="url(#paint30_linear_129_10)"
        />
        <path
            d="M14.8916 15.9773C19.0787 15.9319 22.6358 12.5091 22.5648 8.59731C22.4939 4.63512 18.8702 1.29544 14.7119 1.35028C10.4915 1.40954 7.08577 4.73149 7.12404 8.76236C7.1623 12.7932 10.629 16.0232 14.8916 15.9773Z"
            fill="url(#paint31_linear_129_10)"
        />
        <path
            d="M4.81197 30.9914H24.7344C26.793 30.9914 28.4352 29.244 28.1418 27.2718C27.6121 23.7399 25.7188 20.9807 22.1084 19.3978C16.5287 16.9504 10.6972 16.8678 5.52628 20.5919C3.12713 22.3171 1.86878 24.5795 1.42233 27.1665C1.0735 29.1709 2.71119 30.9914 4.81197 30.9914Z"
            fill="url(#paint32_linear_129_10)"
        />
        <path
            d="M14.8916 15.9618C19.0749 15.9164 22.6286 12.4936 22.5577 8.58181C22.4867 4.61961 18.8669 1.27994 14.7119 1.33477C10.4971 1.39403 7.093 4.71598 7.13071 8.74685C7.16842 12.7777 10.6324 16.0061 14.8916 15.9618Z"
            fill="url(#paint33_linear_129_10)"
        />
        <path
            d="M4.81863 30.9759H24.7283C26.7853 30.9759 28.4257 29.2285 28.1324 27.2563C27.6005 23.7244 25.7094 20.9652 22.1018 19.3845C16.5276 16.9349 10.7028 16.8523 5.53461 20.5764C3.13768 22.3016 1.87932 24.5618 1.43565 27.151C1.08571 29.1554 2.72174 30.9759 4.81863 30.9759Z"
            fill="url(#paint34_linear_129_10)"
        />
        <path
            d="M14.8916 15.9463C19.0715 15.9008 22.6209 12.4781 22.551 8.5663C22.48 4.60411 18.863 1.26444 14.7119 1.31927C10.4971 1.37853 7.09966 4.70048 7.13792 8.73135C7.17619 12.7622 10.6363 15.9922 14.8916 15.9463Z"
            fill="url(#paint35_linear_129_10)"
        />
        <path
            d="M4.82973 30.9598H24.7167C26.7686 30.9598 28.4108 29.2124 28.1179 27.2402C27.5889 23.7083 25.6994 20.9491 22.0951 19.3662C16.526 16.9193 10.7061 16.8368 5.54292 20.5608C3.14822 22.2861 1.89152 24.5463 1.44786 27.1383C1.09791 29.1399 2.73283 30.9598 4.82973 30.9598Z"
            fill="url(#paint36_linear_129_10)"
        />
        <path
            d="M14.8916 15.9308C19.0671 15.8853 22.6148 12.4626 22.5449 8.5508C22.4728 4.58861 18.8591 1.24894 14.7119 1.30377C10.5031 1.36303 7.10686 4.68498 7.14457 8.71585C7.18228 12.7467 10.6418 15.9767 14.8916 15.9308Z"
            fill="url(#paint37_linear_129_10)"
        />
        <path
            d="M4.8386 30.9449H24.7078C26.7598 30.9449 28.3986 29.1975 28.1057 27.2253C27.5772 23.6934 25.6894 20.9353 22.0885 19.3513C16.5243 16.9039 10.71 16.8214 5.55124 20.5476C3.15876 22.2728 1.90317 24.5353 1.4595 27.1223C1.11067 29.1244 2.74337 30.9449 4.8386 30.9449Z"
            fill="url(#paint38_linear_129_10)"
        />
        <path
            d="M14.8916 15.9153C19.0632 15.8698 22.6075 12.4471 22.5371 8.5353C22.4661 4.57311 18.8558 1.23343 14.7119 1.28826C10.507 1.34752 7.11405 4.66947 7.15121 8.70034C7.18837 12.7312 10.6418 15.9612 14.8916 15.9153Z"
            fill="url(#paint39_linear_129_10)"
        />
        <path
            d="M4.84803 30.9294H24.6989C26.7509 30.9294 28.3864 29.182 28.0941 27.2098C27.5656 23.6779 25.6794 20.9187 22.0818 19.3358C16.5226 16.8883 10.7128 16.8058 5.55957 20.5299C3.1693 22.2551 1.91482 24.5175 1.47171 27.1045C1.12287 29.1089 2.75447 30.9294 4.84803 30.9294Z"
            fill="url(#paint40_linear_129_10)"
        />
        <path
            d="M14.891 15.8998C19.0593 15.8543 22.5998 12.4316 22.5299 8.5198C22.4589 4.55761 18.8497 1.21849 14.7119 1.27387C10.5109 1.33203 7.12071 4.65232 7.15842 8.68485C7.19614 12.7174 10.6473 15.9457 14.891 15.8998Z"
            fill="url(#paint41_linear_129_10)"
        />
        <path
            d="M4.85691 30.9139H24.6901C26.7392 30.9139 28.3742 29.1665 28.0819 27.1943C27.5567 23.6624 25.6711 20.9032 22.0752 19.3203C16.5204 16.8728 10.7172 16.7903 5.56789 20.5144C3.17984 22.2396 1.92648 24.502 1.48392 27.089C1.13508 29.0934 2.76668 30.9139 4.85691 30.9139Z"
            fill="url(#paint42_linear_129_10)"
        />
        <path
            d="M14.891 15.8842C19.0554 15.8388 22.5937 12.4161 22.5227 8.50429C22.4545 4.54155 18.8497 1.20243 14.7119 1.25726C10.5148 1.31652 7.12736 4.63847 7.16508 8.66934C7.20279 12.7002 10.6512 15.9302 14.891 15.8842Z"
            fill="url(#paint43_linear_129_10)"
        />
        <path
            d="M4.86579 30.8983H24.6812C26.7287 30.8983 28.362 29.151 28.0697 27.1787C27.5428 23.6469 25.66 20.8876 22.0691 19.3047C16.5204 16.8568 10.7211 16.7748 5.57621 20.4988C3.19149 22.2241 1.93812 24.4865 1.49556 27.0735C1.14728 29.0768 2.7761 30.8983 4.86579 30.8983Z"
            fill="url(#paint44_linear_129_10)"
        />
        <path
            d="M14.891 15.8687C19.0504 15.8233 22.5865 12.4006 22.5161 8.48879C22.4456 4.5266 18.8447 1.18692 14.7119 1.24175C10.5187 1.30102 7.13403 4.62297 7.16953 8.65384C7.20502 12.6847 10.6551 15.9147 14.891 15.8687Z"
            fill="url(#paint45_linear_129_10)"
        />
        <path
            d="M4.87408 30.8828H24.6728C26.7181 30.8828 28.3503 29.1355 28.0586 27.1632C27.5317 23.6314 25.6506 20.8721 22.0629 19.2892C16.5171 16.8413 10.725 16.7593 5.58506 20.4833C3.20034 22.2085 1.9503 24.471 1.50829 27.058C1.15835 29.0624 2.78661 30.8828 4.87408 30.8828Z"
            fill="url(#paint46_linear_129_10)"
        />
        <path
            d="M14.891 15.8532C19.0477 15.8073 22.5793 12.3851 22.51 8.47384C22.4384 4.51109 18.8408 1.17142 14.7125 1.22625C10.522 1.28551 7.14125 4.60746 7.17896 8.63999C7.21667 12.6725 10.6584 15.8992 14.891 15.8532Z"
            fill="url(#paint47_linear_129_10)"
        />
        <path
            d="M4.88351 30.8673H24.6634C26.7071 30.8673 28.3375 29.12 28.0464 27.1477C27.5206 23.6159 25.6411 20.8566 22.0568 19.2737C16.5165 16.8258 10.7283 16.7438 5.59504 20.4678C3.21309 22.193 1.96305 24.4555 1.5216 27.0425C1.17166 29.0469 2.79715 30.8673 4.88351 30.8673Z"
            fill="url(#paint48_linear_129_10)"
        />
        <path
            d="M14.891 15.84C19.0443 15.794 22.5726 12.3718 22.5022 8.46C22.4318 4.49559 18.8375 1.15592 14.7125 1.21075C10.5259 1.27001 7.1479 4.59196 7.18562 8.62283C7.22333 12.6537 10.6623 15.8837 14.891 15.84Z"
            fill="url(#paint49_linear_129_10)"
        />
        <path
            d="M4.8924 30.8518H24.6546C26.6965 30.8518 28.3254 29.1045 28.0342 27.1322C27.5085 23.6004 25.6306 20.8411 22.0491 19.2583C16.5149 16.8103 10.7316 16.7261 5.60116 20.4523C3.22143 22.1776 1.9725 24.4378 1.5316 27.0276C1.18388 29.0314 2.80826 30.8518 4.8924 30.8518Z"
            fill="url(#paint50_linear_129_10)"
        />
        <path
            d="M14.8905 15.8222C19.0405 15.7763 22.5654 12.3541 22.495 8.44228C22.4245 4.48009 18.8347 1.14042 14.7125 1.19525C10.5309 1.25451 7.1551 4.57646 7.19226 8.60733C7.22942 12.6382 10.6662 15.8682 14.8905 15.8222Z"
            fill="url(#paint51_linear_129_10)"
        />
        <path
            d="M4.90128 30.8363H24.6446C26.6843 30.8363 28.3121 29.089 28.0209 27.1167C27.4968 23.5849 25.6212 20.8245 22.0424 19.2427C16.5132 16.7947 10.7355 16.7128 5.60949 20.4368C3.23142 22.162 1.98415 24.4245 1.54326 27.0115C1.19608 29.0158 2.8188 30.8363 4.90128 30.8363Z"
            fill="url(#paint52_linear_129_10)"
        />
        <path
            d="M14.8905 15.8067C19.0366 15.7608 22.5588 12.3386 22.4883 8.42678C22.4179 4.46459 18.828 1.12491 14.7125 1.17974C10.5336 1.239 7.16176 4.56095 7.19947 8.59182C7.23718 12.6227 10.6695 15.8527 14.8905 15.8067Z"
            fill="url(#paint53_linear_129_10)"
        />
        <path
            d="M4.91013 30.8208H24.6368C26.6749 30.8208 28.3009 29.0735 28.0103 27.1012C27.4857 23.5694 25.6112 20.8101 22.0363 19.2272C16.5115 16.7792 10.7388 16.6973 5.62055 20.4213C3.24193 22.1465 1.99577 24.4062 1.55543 26.996C1.20826 28.9998 2.82987 30.8208 4.91013 30.8208Z"
            fill="url(#paint54_linear_129_10)"
        />
        <path
            d="M14.8905 15.7911C19.0327 15.7452 22.5515 12.323 22.4811 8.41118C22.4107 4.44898 18.8264 1.10765 14.7125 1.16303C10.5375 1.22229 7.17008 4.54424 7.20613 8.57511C7.24218 12.606 10.6734 15.8399 14.8905 15.7911Z"
            fill="url(#paint55_linear_129_10)"
        />
        <path
            d="M4.919 30.8053H24.6279C26.6644 30.8053 28.2882 29.0579 27.9981 27.0857C27.4741 23.5539 25.6012 20.7946 22.0297 19.2117C16.5104 16.7637 10.7427 16.6818 5.6261 20.4058C3.25247 22.131 2.00687 24.3907 1.56708 26.9805C1.22046 28.9843 2.84041 30.8053 4.919 30.8053Z"
            fill="url(#paint56_linear_129_10)"
        />
        <path
            d="M14.8905 15.7756C19.0288 15.7296 22.5438 12.3074 22.4745 8.39565C22.404 4.43346 18.8231 1.09378 14.7102 1.14861C10.5392 1.20788 7.17341 4.52983 7.21057 8.5607C7.24773 12.5916 10.6773 15.8216 14.8905 15.7756Z"
            fill="url(#paint57_linear_129_10)"
        />
        <path
            d="M4.92954 30.7898H24.6174C26.6516 30.7898 28.2777 29.0424 27.9848 27.0702C27.4624 23.5384 25.5918 20.7791 22.023 19.1962C16.5088 16.7482 10.7461 16.6663 5.63387 20.3903C3.26578 22.1155 2.01852 24.3752 1.57873 26.965C1.23267 28.9688 2.85095 30.7898 4.92954 30.7898Z"
            fill="url(#paint58_linear_129_10)"
        />
        <path
            d="M14.8905 15.7601C19.0249 15.7141 22.5377 12.2919 22.4673 8.38015C22.399 4.41795 18.8192 1.07828 14.7125 1.13311C10.5453 1.19237 7.18229 4.51432 7.21945 8.54464C7.25661 12.575 10.6806 15.8061 14.8905 15.7601Z"
            fill="url(#paint59_linear_129_10)"
        />
        <path
            d="M4.93676 30.7743H24.6102C26.6427 30.7743 28.2643 29.0269 27.9748 27.0547C27.4513 23.5229 25.5818 20.7636 22.0164 19.1807C16.5071 16.7327 10.7527 16.6508 5.64219 20.3748C3.273 22.0984 2.03017 24.3597 1.59094 26.9495C1.24487 28.9533 2.86205 30.7743 4.93676 30.7743Z"
            fill="url(#paint60_linear_129_10)"
        />
        <path
            d="M14.8899 15.7446C19.0211 15.6986 22.5305 12.2764 22.4606 8.36464C22.3902 4.40245 18.8153 1.06278 14.7125 1.11761C10.5492 1.17687 7.18894 4.49882 7.22555 8.52914C7.26215 12.5595 10.6845 15.7906 14.8899 15.7446Z"
            fill="url(#paint61_linear_129_10)"
        />
        <path
            d="M4.94563 30.7588H24.6013C26.6322 30.7588 28.2521 29.0115 27.9626 27.0392C27.4397 23.5074 25.5724 20.7481 22.0108 19.163C16.506 16.715 10.7527 16.6331 5.65051 20.3571C3.28353 22.0823 2.04181 24.342 1.60202 26.9318C1.25707 28.9378 2.87258 30.7588 4.94563 30.7588Z"
            fill="url(#paint62_linear_129_10)"
        />
        <path
            d="M14.8899 15.7291C19.0161 15.6831 22.5238 12.2609 22.4545 8.34914C22.3835 4.38695 18.812 1.04728 14.7125 1.10211C10.5525 1.16303 7.19615 4.48609 7.23331 8.51363C7.27046 12.5412 10.6884 15.7751 14.8899 15.7291Z"
            fill="url(#paint63_linear_129_10)"
        />
        <path
            d="M4.95449 30.7433H24.5924C26.6216 30.7433 28.2399 28.9959 27.951 27.0237C27.4286 23.4918 25.5602 20.7326 22.0036 19.1497C16.5038 16.7017 10.7571 16.6198 5.65882 20.3438C3.29351 22.069 2.0529 24.3287 1.61477 26.9168C1.26927 28.9223 2.88311 30.7433 4.95449 30.7433Z"
            fill="url(#paint64_linear_129_10)"
        />
        <path
            d="M14.8899 15.7136C19.0133 15.6676 22.5166 12.2454 22.4467 8.33364C22.3768 4.37145 18.8081 1.03177 14.7125 1.0866C10.5564 1.14586 7.20335 4.46781 7.23995 8.49813C7.27655 12.5284 10.6917 15.7596 14.8899 15.7136Z"
            fill="url(#paint65_linear_129_10)"
        />
        <path
            d="M4.96339 30.7278H24.5836C26.6106 30.7278 28.2278 28.9804 27.9388 27.0082C27.417 23.4763 25.5541 20.7171 21.997 19.1342C16.5021 16.6862 10.761 16.6042 5.66716 20.326C3.30407 22.0513 2.06235 24.3109 1.62644 26.9007C1.28149 28.9067 2.89423 30.7278 4.96339 30.7278Z"
            fill="url(#paint66_linear_129_10)"
        />
        <path
            d="M14.8899 15.6981C19.01 15.6521 22.5094 12.2299 22.4395 8.31814C22.3696 4.35594 18.8048 1.01627 14.713 1.0711C10.5603 1.13036 7.20946 4.45231 7.24661 8.48263C7.28377 12.5129 10.6973 15.7441 14.8899 15.6981Z"
            fill="url(#paint67_linear_129_10)"
        />
        <path
            d="M4.97227 30.7123H24.5747C26.6 30.7123 28.2161 28.9649 27.9272 26.9927C27.4059 23.4608 25.5424 20.7016 21.9931 19.1187C16.5027 16.6707 10.7671 16.5888 5.6777 20.3128C3.31738 22.0375 2.07843 24.2977 1.64086 26.8875C1.29369 28.8913 2.90477 30.7123 4.97227 30.7123Z"
            fill="url(#paint68_linear_129_10)"
        />
        <path
            d="M14.8899 15.6826C19.0061 15.6366 22.5028 12.2144 22.4323 8.30263C22.3624 4.34044 18.8003 1.00077 14.7125 1.0556C10.5636 1.11486 7.21556 4.43681 7.25328 8.46712C7.29099 12.4974 10.6973 15.7291 14.8899 15.6826Z"
            fill="url(#paint69_linear_129_10)"
        />
        <path
            d="M4.98114 30.6968H24.5658C26.5895 30.6968 28.2039 28.9494 27.9155 26.9772C27.3942 23.4453 25.5308 20.6861 21.9814 19.1032C16.4966 16.6552 10.7655 16.5733 5.68047 20.2973C3.32181 22.022 2.08453 24.2822 1.64751 26.872C1.30589 28.8758 2.9153 30.6968 4.98114 30.6968Z"
            fill="url(#paint70_linear_129_10)"
        />
        <path
            d="M14.8899 15.6671C19.0022 15.6211 22.4955 12.1989 22.4262 8.28713C22.3563 4.32494 18.7976 0.985264 14.713 1.04009C10.568 1.09936 7.22333 4.42131 7.25882 8.45162C7.29432 12.4819 10.7028 15.7131 14.8899 15.6671Z"
            fill="url(#paint71_linear_129_10)"
        />
        <path
            d="M4.98945 30.6829H24.5569C26.579 30.6829 28.1917 28.9356 27.9033 26.9633C27.3831 23.4276 25.5236 20.6706 21.9776 19.0877C16.4977 16.6397 10.7716 16.5599 5.69156 20.2818C3.33512 22.0064 2.10116 24.2667 1.66193 26.8564C1.31809 28.8602 2.92583 30.6829 4.98945 30.6829Z"
            fill="url(#paint72_linear_129_10)"
        />
        <path
            d="M14.8894 15.6516C18.9983 15.6056 22.4872 12.1834 22.419 8.27163C22.3491 4.30943 18.7942 0.969761 14.713 1.02459C10.5719 1.08385 7.22996 4.4058 7.26712 8.43612C7.30428 12.4664 10.7067 15.6976 14.8894 15.6516Z"
            fill="url(#paint73_linear_129_10)"
        />
        <path
            d="M4.99832 30.6657H24.5481C26.5684 30.6657 28.1795 28.9183 27.8917 26.9461C27.3715 23.4142 25.5142 20.655 21.9709 19.0721C16.496 16.6241 10.7755 16.5421 5.69987 20.2662C3.3451 21.9908 2.11003 24.2511 1.67357 26.8408C1.32973 28.8446 2.93692 30.6657 4.99832 30.6657Z"
            fill="url(#paint74_linear_129_10)"
        />
        <path
            d="M14.8894 15.6361C18.9933 15.5901 22.4817 12.1679 22.4123 8.25612C22.3436 4.29393 18.7903 0.954258 14.713 1.00909C10.5752 1.06835 7.23662 4.38975 7.27378 8.4184C7.31093 12.4471 10.71 15.6821 14.8894 15.6361Z"
            fill="url(#paint75_linear_129_10)"
        />
        <path
            d="M5.0072 30.6502H24.5397C26.5579 30.6502 28.1679 28.9028 27.88 26.9306C27.3604 23.3987 25.5047 20.6395 21.9648 19.0566C16.4949 16.6086 10.7788 16.5266 5.70598 20.2507C3.35564 21.9753 2.12168 24.2356 1.68522 26.8253C1.34193 28.8291 2.94746 30.6502 5.0072 30.6502Z"
            fill="url(#paint76_linear_129_10)"
        />
        <path
            d="M14.8894 15.6184C18.9933 15.5724 22.475 12.1502 22.4051 8.23844C22.3358 4.27624 18.7864 0.936017 14.713 0.991402C10.5791 1.05066 7.24328 4.36984 7.28155 8.40293C7.31981 12.436 10.7139 15.6666 14.8894 15.6184Z"
            fill="url(#paint77_linear_129_10)"
        />
        <path
            d="M5.01606 30.6347H24.5309C26.5473 30.6347 28.1556 28.8873 27.8684 26.9151C27.3493 23.3832 25.4947 20.624 21.9581 19.0411C16.4932 16.5931 10.7821 16.5111 5.71595 20.2352C3.36561 21.9598 2.13276 24.2201 1.69741 26.8098C1.35412 28.8136 2.95799 30.6347 5.01606 30.6347Z"
            fill="url(#paint78_linear_129_10)"
        />
        <path
            d="M14.8894 15.6051C18.9867 15.5591 22.4678 12.1369 22.3985 8.22513C22.3292 4.2646 18.7831 0.922711 14.713 0.978096C10.583 1.03736 7.2505 4.35654 7.2871 8.38962C7.32371 12.4227 10.7172 15.6511 14.8894 15.6051Z"
            fill="url(#paint79_linear_129_10)"
        />
        <path
            d="M5.02494 30.6192H24.522C26.5363 30.6192 28.1434 28.8718 27.8562 26.8996C27.3376 23.3677 25.4853 20.6085 21.9515 19.0256C16.4916 16.5776 10.786 16.4956 5.72427 20.2197C3.3767 21.9443 2.14441 24.2029 1.70906 26.7943C1.36633 28.7998 2.96853 30.6192 5.02494 30.6192Z"
            fill="url(#paint80_linear_129_10)"
        />
        <path
            d="M14.8894 15.5896C18.9833 15.5436 22.4612 12.1214 22.3918 8.20963C22.322 4.24744 18.7793 0.907209 14.713 0.962593C10.5863 1.02185 7.25882 4.34325 7.29432 8.37412C7.32981 12.405 10.7211 15.6356 14.8894 15.5896Z"
            fill="url(#paint81_linear_129_10)"
        />
        <path
            d="M5.03381 30.6037H24.5131C26.5257 30.6037 28.1312 28.8563 27.8445 26.8841C27.3265 23.3522 25.4759 20.593 21.9454 19.0101C16.4899 16.5599 10.7893 16.4802 5.73203 20.2042C3.38613 21.9289 2.15661 24.1891 1.7207 26.7789C1.38019 28.7827 2.97906 30.6037 5.03381 30.6037Z"
            fill="url(#paint82_linear_129_10)"
        />
        <path
            d="M14.8888 15.5741C18.9795 15.5281 22.4545 12.1059 22.3846 8.19689C22.3153 4.23193 18.7759 0.891706 14.713 0.94709C10.5908 1.00635 7.26381 4.32553 7.30097 8.35862C7.33812 12.3917 10.725 15.6184 14.8888 15.5741Z"
            fill="url(#paint83_linear_129_10)"
        />
        <path
            d="M5.04047 30.5881H24.5065C26.5174 30.5881 28.1218 28.8408 27.834 26.8685C27.3149 23.3367 25.4659 20.5774 21.9387 18.9968C16.4883 16.5488 10.7915 16.4668 5.74036 20.1908C3.39668 21.9155 2.16716 24.1785 1.73236 26.7655C1.39018 28.7671 2.9885 30.5881 5.04047 30.5881Z"
            fill="url(#paint84_linear_129_10)"
        />
        <path
            d="M14.8888 15.5586C18.9756 15.5126 22.4473 12.0904 22.3757 8.17862C22.3064 4.21643 18.7709 0.876203 14.7113 0.931587C10.5919 0.990848 7.26824 4.31003 7.30539 8.34311C7.34255 12.3762 10.7283 15.6046 14.8888 15.5586Z"
            fill="url(#paint85_linear_129_10)"
        />
        <path
            d="M5.05099 30.5722H24.4959C26.5046 30.5722 28.1074 28.8248 27.8234 26.8526C27.306 23.3207 25.4587 20.5615 21.9343 18.9786C16.4871 16.5312 10.7965 16.4492 5.74866 20.1732C3.40664 21.8979 2.17823 24.1581 1.74399 26.7507C1.40236 28.7517 3.00013 30.5722 5.05099 30.5722Z"
            fill="url(#paint86_linear_129_10)"
        />
        <path
            d="M14.8888 15.5431C18.9717 15.4971 22.4406 12.0738 22.3713 8.16312C22.302 4.20093 18.7665 0.8607 14.7136 0.916084C10.598 0.975345 7.27712 4.29453 7.31427 8.32761C7.35143 12.3607 10.7322 15.589 14.8888 15.5431Z"
            fill="url(#paint87_linear_129_10)"
        />
        <path
            d="M5.05989 30.5572H24.4871C26.4941 30.5572 28.0958 28.8098 27.8096 26.8376C27.2927 23.3057 25.4493 20.5476 21.926 18.9636C16.4855 16.5156 10.8004 16.4336 5.75645 20.1599C3.4172 21.8846 2.1899 24.1476 1.75566 26.7346C1.41459 28.7362 3.01124 30.5572 5.05989 30.5572Z"
            fill="url(#paint88_linear_129_10)"
        />
        <path
            d="M14.8888 15.5276C18.9678 15.4816 22.4312 12.0594 22.3641 8.14762C22.2953 4.18542 18.7648 0.845197 14.7136 0.900581C10.6019 0.959843 7.2821 4.27902 7.32092 8.31211C7.35975 12.3452 10.7355 15.5735 14.8888 15.5276Z"
            fill="url(#paint89_linear_129_10)"
        />
        <path
            d="M5.06876 30.5417H24.4793C26.4847 30.5417 28.0841 28.7937 27.7991 26.822C27.2822 23.2902 25.4382 20.5309 21.9204 18.9481C16.4855 16.5001 10.8049 16.4181 5.76588 20.1421C3.42718 21.8668 2.20099 24.127 1.76841 26.7168C1.42623 28.7206 3.02178 30.5417 5.06876 30.5417Z"
            fill="url(#paint90_linear_129_10)"
        />
        <path
            d="M14.8888 15.5121C18.9645 15.4661 22.4268 12.0439 22.3574 8.13211C22.2881 4.16991 18.7615 0.830793 14.7136 0.886177C10.6057 0.944331 7.29097 4.26462 7.32757 8.2966C7.36417 12.3286 10.7394 15.5575 14.8888 15.5121Z"
            fill="url(#paint91_linear_129_10)"
        />
        <path
            d="M5.07706 30.5261H24.4693C26.473 30.5261 28.0713 28.7782 27.7857 26.8065C27.27 23.2747 25.4276 20.5154 21.9132 18.9326C16.4821 16.4846 10.8076 16.4026 5.77473 20.1266C3.43936 21.8513 2.21373 24.1143 1.7817 26.7013C1.43841 28.7051 3.0323 30.5261 5.07706 30.5261Z"
            fill="url(#paint92_linear_129_10)"
        />
        <path
            d="M14.8888 15.4966C18.9606 15.4523 22.4195 12.0284 22.3508 8.11661C22.2814 4.15386 18.7576 0.814191 14.7136 0.869576C10.6096 0.928837 7.29762 4.24802 7.33422 8.2811C7.37083 12.3142 10.7427 15.542 14.8888 15.4966Z"
            fill="url(#paint93_linear_129_10)"
        />
        <path
            d="M5.08596 30.5106H24.461C26.4625 30.5106 28.0597 28.7627 27.7741 26.791C27.2589 23.2592 25.4182 20.4999 21.9066 18.9171C16.4811 16.4691 10.811 16.3871 5.78085 20.1111C3.44771 21.8358 2.22373 24.096 1.79116 26.6858C1.45064 28.6891 3.04397 30.5106 5.08596 30.5106Z"
            fill="url(#paint94_linear_129_10)"
        />
        <path
            d="M14.8883 15.4811C18.9567 15.4351 22.4129 12.0129 22.3436 8.10111C22.2748 4.13892 18.7543 0.798688 14.7136 0.854073C10.613 0.913334 7.3043 4.23251 7.34091 8.2656C7.37751 12.2987 10.7466 15.5265 14.8883 15.4811Z"
            fill="url(#paint95_linear_129_10)"
        />
        <path
            d="M5.09592 30.4951H24.451C26.4508 30.4951 28.0464 28.7472 27.7613 26.7755C27.2467 23.2437 25.4071 20.4844 21.8993 18.9015C16.4783 16.4536 10.8137 16.3716 5.78804 20.0956C3.45878 21.8203 2.2337 24.0833 1.80167 26.6703C1.46227 28.6741 3.05337 30.4951 5.09592 30.4951Z"
            fill="url(#paint96_linear_129_10)"
        />
        <path
            d="M14.8883 15.4656C18.9528 15.4196 22.4063 11.9974 22.3369 8.0856C22.2682 4.12341 18.7504 0.783185 14.7136 0.83857C10.6169 0.897831 7.31096 4.21923 7.34756 8.25231C7.38416 12.2854 10.7527 15.511 14.8883 15.4656Z"
            fill="url(#paint97_linear_129_10)"
        />
        <path
            d="M5.10368 30.4796H24.4432C26.4397 30.4796 28.0358 28.7317 27.7508 26.76C27.2361 23.2282 25.3988 20.4689 21.8938 18.886C16.4777 16.4381 10.8181 16.3561 5.79691 20.0801C3.46765 21.8048 2.24756 24.065 1.81442 26.6548C1.47446 28.6586 3.06391 30.4796 5.10368 30.4796Z"
            fill="url(#paint98_linear_129_10)"
        />
        <path
            d="M14.8883 15.4523C18.949 15.4063 22.399 11.9841 22.3303 8.07232C22.2615 4.10791 18.7471 0.767682 14.7136 0.823067C10.6207 0.882328 7.31761 4.20151 7.35421 8.23459C7.39082 12.2677 10.7527 15.4955 14.8883 15.4523Z"
            fill="url(#paint99_linear_129_10)"
        />
        <path
            d="M5.11202 30.4641H24.4349C26.4314 30.4641 28.0237 28.7162 27.7392 26.7445C27.2239 23.2121 25.3894 20.4534 21.8872 18.8705C16.4761 16.4226 10.8215 16.3406 5.80414 20.0646C3.47487 21.7893 2.25478 24.0523 1.82498 26.6393C1.48613 28.6431 3.07446 30.4641 5.11202 30.4641Z"
            fill="url(#paint100_linear_129_10)"
        />
        <path
            d="M14.8883 15.4344C18.9456 15.3885 22.3924 11.9663 22.3236 8.05448C22.2549 4.09228 18.7438 0.752057 14.7136 0.807442C10.6246 0.866703 7.32426 4.18588 7.36087 8.21897C7.39747 12.2521 10.7577 15.4798 14.8883 15.4344Z"
            fill="url(#paint101_linear_129_10)"
        />
        <path
            d="M5.12089 30.4486H24.4261C26.4226 30.4486 28.012 28.7007 27.7275 26.729C27.214 23.1966 25.3799 20.4368 21.8811 18.855C16.4744 16.4071 10.8254 16.3251 5.81301 20.0491C3.48763 21.7738 2.26754 24.0368 1.83773 26.6238C1.49832 28.6276 3.085 30.4486 5.12089 30.4486Z"
            fill="url(#paint102_linear_129_10)"
        />
        <path
            d="M14.8883 15.419C18.9417 15.373 22.3852 11.9508 22.3164 8.03903C22.2476 4.07683 18.7388 0.736607 14.7136 0.791437C10.6279 0.851252 7.3309 4.17265 7.36972 8.20352C7.40855 12.2344 10.761 15.4644 14.8883 15.419Z"
            fill="url(#paint103_linear_129_10)"
        />
        <path
            d="M5.12977 30.4332H24.4172C26.4104 30.4332 27.9998 28.6852 27.7159 26.7135C27.2029 23.1811 25.3705 20.4224 21.8744 18.8396C16.4733 16.3938 10.8287 16.309 5.82134 20.0336C3.49873 21.7583 2.27975 24.0185 1.84939 26.6083C1.50998 28.6121 3.09554 30.4332 5.12977 30.4332Z"
            fill="url(#paint104_linear_129_10)"
        />
        <path
            d="M14.8877 15.4034C18.9362 15.3575 22.3785 11.9353 22.3097 8.02348C22.241 4.06129 18.7388 0.719953 14.7141 0.775337C10.6318 0.835152 7.33812 4.15655 7.37416 8.18742C7.41021 12.2183 10.7649 15.4489 14.8877 15.4034Z"
            fill="url(#paint105_linear_129_10)"
        />
        <path
            d="M5.12977 30.4332H24.4172C26.4104 30.4332 27.9998 28.6852 27.7159 26.7135C27.2029 23.1811 25.3705 20.4224 21.8744 18.8396C16.4733 16.3938 10.8287 16.309 5.82134 20.0336C3.49873 21.7583 2.27975 24.0185 1.84939 26.6083C1.50998 28.6121 3.09554 30.4332 5.12977 30.4332Z"
            fill="url(#paint106_linear_129_10)"
        />
        <path
            d="M14.8877 15.4034C18.9362 15.3575 22.3785 11.9353 22.3097 8.02348C22.241 4.06129 18.7388 0.719953 14.7141 0.775337C10.6318 0.835152 7.33812 4.15655 7.37416 8.18742C7.41021 12.2183 10.7649 15.4489 14.8877 15.4034Z"
            fill="url(#paint107_linear_129_10)"
        />
        <path
            d="M5.12977 30.2918H24.4172C26.4104 30.2918 27.9998 28.5788 27.7159 26.6464C27.2029 23.1849 25.3705 20.4811 21.8744 18.9297C16.4733 16.531 10.8287 16.4491 5.82134 20.1C3.49873 21.7903 2.27975 24.0052 1.84939 26.5434C1.50998 28.5074 3.09554 30.2918 5.12977 30.2918Z"
            fill="url(#paint108_linear_129_10)"
        />
        <path
            d="M14.8877 15.5629C18.9362 15.5181 22.3785 12.1646 22.3097 8.33086C22.241 4.44731 18.7388 1.1741 14.7141 1.22782C10.6318 1.28321 7.33812 4.54147 7.37416 8.49148C7.41021 12.4415 10.7649 15.6072 14.8877 15.5629Z"
            fill="url(#paint109_linear_129_10)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_129_10"
                x1="14.8066"
                y1="1.58238"
                x2="14.8066"
                y2="31.2401"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_129_10"
                x1="14.8066"
                y1="1.58239"
                x2="14.8066"
                y2="31.2401"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_129_10"
                x1="14.8199"
                y1="1.56686"
                x2="14.8199"
                y2="31.2246"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_129_10"
                x1="14.8199"
                y1="1.56676"
                x2="14.8199"
                y2="31.2245"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint4_linear_129_10"
                x1="14.8338"
                y1="1.55074"
                x2="14.8338"
                y2="31.2085"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint5_linear_129_10"
                x1="14.8338"
                y1="1.55073"
                x2="14.8338"
                y2="31.2085"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint6_linear_129_10"
                x1="14.8471"
                y1="1.5353"
                x2="14.8471"
                y2="31.193"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint7_linear_129_10"
                x1="14.8471"
                y1="1.5352"
                x2="14.8471"
                y2="31.1929"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint8_linear_129_10"
                x1="14.717"
                y1="1.5198"
                x2="14.717"
                y2="31.1775"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint9_linear_129_10"
                x1="14.717"
                y1="1.5197"
                x2="14.717"
                y2="31.1774"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint10_linear_129_10"
                x1="14.7303"
                y1="1.5043"
                x2="14.7303"
                y2="31.162"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint11_linear_129_10"
                x1="14.7303"
                y1="1.5042"
                x2="14.7303"
                y2="31.1619"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint12_linear_129_10"
                x1="14.7436"
                y1="1.48879"
                x2="14.7436"
                y2="31.1465"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint13_linear_129_10"
                x1="14.7436"
                y1="1.48869"
                x2="14.7436"
                y2="31.1464"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint14_linear_129_10"
                x1="14.7575"
                y1="1.47317"
                x2="14.7575"
                y2="31.1309"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint15_linear_129_10"
                x1="14.7575"
                y1="1.47319"
                x2="14.7575"
                y2="31.1309"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint16_linear_129_10"
                x1="14.7708"
                y1="1.45775"
                x2="14.7708"
                y2="31.1155"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint17_linear_129_10"
                x1="14.7708"
                y1="1.45769"
                x2="14.7708"
                y2="31.1154"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint18_linear_129_10"
                x1="14.7841"
                y1="1.44216"
                x2="14.7841"
                y2="31.0999"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint19_linear_129_10"
                x1="14.7841"
                y1="1.44218"
                x2="14.7841"
                y2="31.0999"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint20_linear_129_10"
                x1="14.798"
                y1="1.42666"
                x2="14.798"
                y2="31.0844"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint21_linear_129_10"
                x1="14.798"
                y1="1.42668"
                x2="14.798"
                y2="31.0844"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint22_linear_129_10"
                x1="14.8113"
                y1="1.41114"
                x2="14.8113"
                y2="31.0689"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint23_linear_129_10"
                x1="14.8113"
                y1="1.41118"
                x2="14.8113"
                y2="31.0689"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint24_linear_129_10"
                x1="14.8246"
                y1="1.39565"
                x2="14.8246"
                y2="31.0534"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint25_linear_129_10"
                x1="14.8246"
                y1="1.39568"
                x2="14.8246"
                y2="31.0534"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint26_linear_129_10"
                x1="14.8379"
                y1="1.38015"
                x2="14.8379"
                y2="31.0379"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint27_linear_129_10"
                x1="14.8379"
                y1="1.38023"
                x2="14.8379"
                y2="31.038"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint28_linear_129_10"
                x1="14.7073"
                y1="1.36464"
                x2="14.7073"
                y2="31.0224"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint29_linear_129_10"
                x1="14.7073"
                y1="1.36467"
                x2="14.7073"
                y2="31.0224"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint30_linear_129_10"
                x1="14.7211"
                y1="1.34914"
                x2="14.7211"
                y2="31.0069"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint31_linear_129_10"
                x1="14.7211"
                y1="1.34917"
                x2="14.7211"
                y2="31.0069"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint32_linear_129_10"
                x1="14.7344"
                y1="1.33364"
                x2="14.7344"
                y2="30.9914"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint33_linear_129_10"
                x1="14.7345"
                y1="1.33366"
                x2="14.7345"
                y2="30.9914"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint34_linear_129_10"
                x1="14.7478"
                y1="1.31815"
                x2="14.7478"
                y2="30.9759"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint35_linear_129_10"
                x1="14.7478"
                y1="1.31816"
                x2="14.7478"
                y2="30.9759"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint36_linear_129_10"
                x1="14.7611"
                y1="1.3026"
                x2="14.7611"
                y2="30.9603"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint37_linear_129_10"
                x1="14.7611"
                y1="1.30266"
                x2="14.7611"
                y2="30.9604"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint38_linear_129_10"
                x1="14.7744"
                y1="1.28717"
                x2="14.7744"
                y2="30.9449"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint39_linear_129_10"
                x1="14.7744"
                y1="1.28716"
                x2="14.7744"
                y2="30.9449"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint40_linear_129_10"
                x1="14.7877"
                y1="1.27163"
                x2="14.7877"
                y2="30.9294"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint41_linear_129_10"
                x1="14.7877"
                y1="1.27166"
                x2="14.7877"
                y2="30.9294"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint42_linear_129_10"
                x1="14.801"
                y1="1.25613"
                x2="14.801"
                y2="30.9139"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint43_linear_129_10"
                x1="14.801"
                y1="1.25615"
                x2="14.801"
                y2="30.9139"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint44_linear_129_10"
                x1="14.8143"
                y1="1.2406"
                x2="14.8143"
                y2="30.8983"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint45_linear_129_10"
                x1="14.8143"
                y1="1.24065"
                x2="14.8143"
                y2="30.8984"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint46_linear_129_10"
                x1="14.8276"
                y1="1.2251"
                x2="14.8276"
                y2="30.8828"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint47_linear_129_10"
                x1="14.8276"
                y1="1.22514"
                x2="14.8276"
                y2="30.8829"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint48_linear_129_10"
                x1="14.8409"
                y1="1.2096"
                x2="14.8409"
                y2="30.8673"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint49_linear_129_10"
                x1="14.8409"
                y1="1.20964"
                x2="14.8409"
                y2="30.8674"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint50_linear_129_10"
                x1="14.7103"
                y1="1.19411"
                x2="14.7103"
                y2="30.8518"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint51_linear_129_10"
                x1="14.7103"
                y1="1.19414"
                x2="14.7103"
                y2="30.8519"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint52_linear_129_10"
                x1="14.7236"
                y1="1.17859"
                x2="14.7236"
                y2="30.8363"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint53_linear_129_10"
                x1="14.7236"
                y1="1.17864"
                x2="14.7236"
                y2="30.8364"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint54_linear_129_10"
                x1="14.7369"
                y1="1.16309"
                x2="14.7369"
                y2="30.8208"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint55_linear_129_10"
                x1="14.7369"
                y1="1.16303"
                x2="14.7369"
                y2="30.8208"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint56_linear_129_10"
                x1="14.7502"
                y1="1.14758"
                x2="14.7502"
                y2="30.8053"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint57_linear_129_10"
                x1="14.7502"
                y1="1.14751"
                x2="14.7502"
                y2="30.8052"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint58_linear_129_10"
                x1="14.763"
                y1="1.13208"
                x2="14.763"
                y2="30.7898"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint59_linear_129_10"
                x1="14.763"
                y1="1.132"
                x2="14.763"
                y2="30.7897"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint60_linear_129_10"
                x1="14.7763"
                y1="1.11658"
                x2="14.7763"
                y2="30.7743"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint61_linear_129_10"
                x1="14.7763"
                y1="1.1165"
                x2="14.7763"
                y2="30.7742"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint62_linear_129_10"
                x1="14.7896"
                y1="1.1011"
                x2="14.7896"
                y2="30.7588"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint63_linear_129_10"
                x1="14.7896"
                y1="1.101"
                x2="14.7896"
                y2="30.7587"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint64_linear_129_10"
                x1="14.8029"
                y1="1.08557"
                x2="14.8029"
                y2="30.7433"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint65_linear_129_10"
                x1="14.8029"
                y1="1.0855"
                x2="14.8029"
                y2="30.7432"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint66_linear_129_10"
                x1="14.8162"
                y1="1.07004"
                x2="14.8162"
                y2="30.7278"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint67_linear_129_10"
                x1="14.8162"
                y1="1.06999"
                x2="14.8162"
                y2="30.7277"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint68_linear_129_10"
                x1="14.829"
                y1="1.05457"
                x2="14.829"
                y2="30.7123"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint69_linear_129_10"
                x1="14.829"
                y1="1.05449"
                x2="14.829"
                y2="30.7122"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint70_linear_129_10"
                x1="14.8423"
                y1="1.03907"
                x2="14.8423"
                y2="30.6968"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint71_linear_129_10"
                x1="14.8423"
                y1="1.03899"
                x2="14.8423"
                y2="30.6967"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint72_linear_129_10"
                x1="14.7117"
                y1="1.02355"
                x2="14.7117"
                y2="30.6813"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint73_linear_129_10"
                x1="14.7116"
                y1="1.02348"
                x2="14.7116"
                y2="30.6812"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint74_linear_129_10"
                x1="14.7244"
                y1="1.00794"
                x2="14.7244"
                y2="30.6657"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint75_linear_129_10"
                x1="14.7244"
                y1="1.00798"
                x2="14.7244"
                y2="30.6657"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint76_linear_129_10"
                x1="14.7377"
                y1="0.992437"
                x2="14.7377"
                y2="30.6502"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint77_linear_129_10"
                x1="14.7377"
                y1="0.992509"
                x2="14.7377"
                y2="30.6502"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint78_linear_129_10"
                x1="14.751"
                y1="0.97693"
                x2="14.751"
                y2="30.6347"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint79_linear_129_10"
                x1="14.751"
                y1="0.976988"
                x2="14.751"
                y2="30.6347"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint80_linear_129_10"
                x1="14.7638"
                y1="0.96143"
                x2="14.7638"
                y2="30.6192"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint81_linear_129_10"
                x1="14.7638"
                y1="0.961485"
                x2="14.7638"
                y2="30.6192"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint82_linear_129_10"
                x1="14.7771"
                y1="0.945969"
                x2="14.7771"
                y2="30.6037"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint83_linear_129_10"
                x1="14.7771"
                y1="0.945982"
                x2="14.7771"
                y2="30.6037"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint84_linear_129_10"
                x1="14.7899"
                y1="0.930406"
                x2="14.7899"
                y2="30.5881"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint85_linear_129_10"
                x1="14.7898"
                y1="0.93048"
                x2="14.7898"
                y2="30.5882"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint86_linear_129_10"
                x1="14.8032"
                y1="0.91501"
                x2="14.8032"
                y2="30.5727"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint87_linear_129_10"
                x1="14.8032"
                y1="0.914977"
                x2="14.8032"
                y2="30.5727"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint88_linear_129_10"
                x1="14.8165"
                y1="0.899455"
                x2="14.8165"
                y2="30.5572"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint89_linear_129_10"
                x1="14.8165"
                y1="0.899474"
                x2="14.8165"
                y2="30.5572"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint90_linear_129_10"
                x1="14.8292"
                y1="0.883913"
                x2="14.8292"
                y2="30.5417"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint91_linear_129_10"
                x1="14.8292"
                y1="0.883962"
                x2="14.8292"
                y2="30.5417"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint92_linear_129_10"
                x1="14.8425"
                y1="0.868412"
                x2="14.8425"
                y2="30.5261"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint93_linear_129_10"
                x1="14.8425"
                y1="0.868468"
                x2="14.8425"
                y2="30.5262"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint94_linear_129_10"
                x1="14.7114"
                y1="0.852907"
                x2="14.7114"
                y2="30.5106"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint95_linear_129_10"
                x1="14.7114"
                y1="0.852965"
                x2="14.7114"
                y2="30.5107"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint96_linear_129_10"
                x1="14.7241"
                y1="0.837406"
                x2="14.7241"
                y2="30.4951"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint97_linear_129_10"
                x1="14.7241"
                y1="0.837462"
                x2="14.7241"
                y2="30.4952"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint98_linear_129_10"
                x1="14.7374"
                y1="0.821901"
                x2="14.7374"
                y2="30.4796"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint99_linear_129_10"
                x1="14.7374"
                y1="0.821959"
                x2="14.7374"
                y2="30.4797"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint100_linear_129_10"
                x1="14.7502"
                y1="0.8064"
                x2="14.7502"
                y2="30.4641"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint101_linear_129_10"
                x1="14.7502"
                y1="0.806334"
                x2="14.7502"
                y2="30.4641"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint102_linear_129_10"
                x1="14.7635"
                y1="0.790895"
                x2="14.7635"
                y2="30.4486"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint103_linear_129_10"
                x1="14.7635"
                y1="0.790883"
                x2="14.7635"
                y2="30.4486"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint104_linear_129_10"
                x1="14.7763"
                y1="0.775416"
                x2="14.7763"
                y2="30.4332"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint105_linear_129_10"
                x1="14.7762"
                y1="0.775337"
                x2="14.7762"
                y2="30.4331"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint106_linear_129_10"
                x1="1.80335"
                y1="15.6046"
                x2="27.7491"
                y2="15.6046"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F2F2F2" />
                <stop offset="1" stopColor="#D8D8D8" />
            </linearGradient>
            <linearGradient
                id="paint107_linear_129_10"
                x1="1.80334"
                y1="15.6045"
                x2="27.7491"
                y2="15.6045"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F2F2F2" />
                <stop offset="1" stopColor="#D8D8D8" />
            </linearGradient>
            <linearGradient
                id="paint108_linear_129_10"
                x1="14.7763"
                y1="1.22726"
                x2="14.7763"
                y2="30.2918"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#BCBCBC" />
                <stop offset="1" stopColor="#9E9E9E" />
            </linearGradient>
            <linearGradient
                id="paint109_linear_129_10"
                x1="14.7762"
                y1="1.22727"
                x2="14.7762"
                y2="30.2919"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#BCBCBC" />
                <stop offset="1" stopColor="#9E9E9E" />
            </linearGradient>
        </defs>
    </svg>,
    'ProfileIcon',
)
