import React, { FC } from 'react'
import { Stack, Box } from '@mui/material'
import UnderConstructionGif from './../../shared/images/under_construction.gif'
import { CustomText } from 'components/Text3D'
import { AndroidIcon, IosIcon } from 'shared/icons'
import LogoWithLinks from 'components/LogoWithLinks'

const SmallScreen: FC = () => (
    <Stack
        sx={{
            height: '100vh',
            width: '100vw',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0px 32px',
            textAlign: 'center',
            gap: '32px',
            overflow: 'hidden',
        }}
    >
        <Box
            component="img"
            src={UnderConstructionGif}
            sx={{
                height: '50%',
                width: 'auto',
            }}
        />
        <Stack
            sx={{
                width: '50%',
            }}
        >
            <CustomText
                fontSize="20px"
                color="#e7e7e7"
                text="Your screen is not supported but don't worry we are working on a mobile App for Android and iOS"
            />
        </Stack>
        <Stack
            sx={{
                flexDirection: 'row',
                gap: '16px',
                marginBottom: '32px',
                '>*': {
                    cursor: 'none',
                    height: '48px',
                    width: 'auto',
                    fill: 'rgba(255, 255, 255, 0.6)',
                },
            }}
        >
            <AndroidIcon />
            <IosIcon />
        </Stack>
        {/* <Stack
            sx={{
                position: 'absolute',
                top: '0',
                left: '50%',
                transform: 'translate(-50%, 0)',
            }}
        > */}
        <LogoWithLinks />
        {/* </Stack> */}
    </Stack>
)

export default SmallScreen
