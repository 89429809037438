import React from 'react'
import { createSvgIcon } from '@mui/material'

export const ExclamationIcon = createSvgIcon(
    <svg width="7" height="20" viewBox="0 0 7 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_1735_4)">
            <path
                d="M1.2551 12.7347L1.10204 5.41327V1H5.89796V5.41327L5.7449 12.7347H1.2551ZM1 17.4286V14.0357H6V17.4286H1Z"
                fill="#C60000"
            />
            <path
                d="M0.755211 12.7451L0.765446 13.2347H1.2551H5.7449H6.23455L6.24479 12.7451L6.39785 5.42372L6.39796 5.42372V5.41327V1V0.5H5.89796H1.10204H0.602041V1V5.41327H0.601932L0.60215 5.42372L0.755211 12.7451ZM0.5 17.4286V17.9286H1H6H6.5V17.4286V14.0357V13.5357H6H1H0.5V14.0357V17.4286Z"
                stroke="#181818"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_1735_4"
                x="0"
                y="0"
                width="7"
                height="19.4286"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.0941176 0 0 0 0 0.0941176 0 0 0 0 0.0941176 0 0 0 1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1735_4" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1735_4" result="shape" />
            </filter>
        </defs>
    </svg>,
    'ExclamationIcon',
)
