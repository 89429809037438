import React from 'react'
import { createSvgIcon } from '@mui/material'

export const PlusMedicIcon = createSvgIcon(
    <svg width={61} height={59} viewBox="0 0 61 59" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M53.1278 21.8589L38.6276 23.4409C38.1586 19.1429 37.6258 14.8709 37.0289 10.625C36.8617 9.73126 36.3543 8.93717 35.6133 8.40969C34.8723 7.88221 33.9555 7.66244 33.0557 7.7966L23.3511 8.85538C22.4435 8.91842 21.5957 9.33071 20.9859 10.0056C20.3761 10.6804 20.0518 11.5652 20.0813 12.474C20.4612 16.7436 20.8621 21.03 21.2838 25.3332L6.78357 26.9151C5.86773 26.9889 5.01395 27.4072 4.39452 28.0854C3.77509 28.7637 3.43615 29.6516 3.44608 30.5698C3.70399 33.546 3.98127 36.5249 4.27793 39.5064C4.44005 40.4189 4.95635 41.2302 5.71437 41.7639C6.47239 42.2975 7.41072 42.5101 8.32497 42.3554L22.8818 40.7673C23.3528 45.0841 23.8453 49.3796 24.3593 53.6536C24.5249 54.5643 25.042 55.3733 25.7991 55.9063C26.5562 56.4393 27.4926 56.6534 28.4063 56.5026L38.3938 55.4129C39.3186 55.3632 40.1868 54.9522 40.8112 54.2685C41.4356 53.5848 41.766 52.6832 41.7313 51.7583C41.2634 47.4697 40.7945 43.1717 40.3246 38.8642L55.0229 37.2606C55.4855 37.2392 55.9392 37.1262 56.3578 36.9282C56.7764 36.7303 57.1515 36.4513 57.4615 36.1074C57.7715 35.7636 58.0102 35.3617 58.1637 34.925C58.3172 34.4884 58.3824 34.0256 58.3557 33.5636C58.0303 30.5804 57.7063 27.6114 57.241 24.6578C57.0414 23.7552 56.5046 22.9629 55.7401 22.4427C54.9757 21.9225 54.0412 21.7137 53.1278 21.8589Z"
            fill="#008D2B"
        />
        <path
            d="M52.4616 17.2248L38.0603 18.7959C37.5359 14.5135 36.9904 10.2572 36.4239 6.02706C36.261 5.14043 35.7607 4.35142 35.028 3.82577C34.2953 3.30012 33.3873 3.07875 32.4946 3.20816L22.9032 4.25459C22.005 4.32415 21.1681 4.73713 20.5667 5.40763C19.9653 6.07812 19.6457 6.9545 19.6743 7.85446C20.0427 12.1062 20.4279 16.38 20.8298 20.6758L6.42855 22.247C5.49998 22.307 4.62954 22.7199 3.99579 23.4009C3.36204 24.0819 3.01302 24.9794 3.02032 25.9094C3.23108 28.8907 3.48427 31.8675 3.75161 34.8427C3.90898 35.7456 4.41882 36.5491 5.16895 37.0763C5.91909 37.6035 6.84808 37.8112 7.75158 37.6539L22.3792 36.058C22.8481 40.356 23.317 44.654 23.7859 48.9521C23.9519 49.8665 24.4709 50.679 25.2309 51.2143C25.991 51.7496 26.9311 51.9649 27.8486 51.8136L37.8927 50.7178C38.8213 50.6677 39.6929 50.2547 40.3196 49.5681C40.9463 48.8815 41.2779 47.9762 41.2428 47.0475C40.7739 42.7495 40.305 38.4514 39.8361 34.1534L54.4919 32.5544C54.9501 32.5334 55.3995 32.4216 55.8139 32.2253C56.2284 32.0291 56.5997 31.7525 56.9062 31.4115C57.2127 31.0705 57.4483 30.672 57.5992 30.2391C57.7502 29.8063 57.8135 29.3478 57.7854 28.8903C57.4615 25.9213 57.0574 22.961 56.5732 20.0094C56.3675 19.1115 55.8289 18.3249 55.0659 17.8081C54.303 17.2914 53.3723 17.0829 52.4616 17.2248Z"
            fill="#00C841"
        />
    </svg>,
    'PlusMedicIcon',
)
