import { createAsyncThunk } from '@reduxjs/toolkit'

import axios from 'axios'

import { APIEndpoint } from 'shared/types'

import { API_URL } from '../../../config'

import { RootState } from 'stores/store'

export const getUser = createAsyncThunk('admin/user', async ({ id }: { id: string }, { rejectWithValue, getState }) => {
    // check if token is expired
    const token = (getState() as RootState).auth.token
    if (!token) {
        return rejectWithValue('Token is not found')
    }
    try {
        const queryParams = new URLSearchParams({
            withProfile: 'true',
            withSelectedCharacter: 'true',
            withCharacters: 'true',
            withFights: 'true',
            fightsSize: '50',
            withFight: 'true',
            withQuests: 'true',
            withQuestsHistory: 'true',
            questsHistorySize: '90',
            withNotifications: 'true',
            notificationsSize: '50',
            withInventory: 'true',
        })
        const { data } = await axios.get(`${API_URL + APIEndpoint.ADMIN_USERS}/${id}?${queryParams.toString()}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        return data
    } catch (error) {
        return rejectWithValue(error)
    }
})
