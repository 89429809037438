import { createSlice } from '@reduxjs/toolkit'
import { arenas } from 'shared/constants'
import { Arena } from 'shared/types'

const initialState: Arena = arenas[0]

const arenaSlice = createSlice({
    name: 'arena',
    initialState,
    reducers: {
        setArena: (state, action) => {
            const arena = arenas.find((arena) => arena.id === action.payload)!
            state.id = arena.id
            state.name = arena.name
            state.type = arena.type
            state.fee = arena.fee
            state.prize_pool = arena.prize_pool
        },
        startLooking: (state, action) => {
            state.searching = true
            state.joinedAt = action.payload
        },
        stopLooking: (state) => {
            state.searching = false
        },
    },
})

export const { setArena, startLooking, stopLooking } = arenaSlice.actions
export default arenaSlice.reducer
