import { PayloadAction } from '@reduxjs/toolkit'
import { jwtDecode } from 'jwt-decode'
import { logout } from 'stores/auth/auth.slice'

const checkTokenExpiration: any =
    ({ dispatch, getState }: { dispatch: any; getState: any }) =>
    (next: (action: PayloadAction) => void) =>
    (action: PayloadAction) => {
        if (action.type !== 'auth/logout') {
            const token = getState().auth.token

            if (token) {
                const decodedToken = jwtDecode(token)
                const currentTime = Date.now() / 1000
                if (!decodedToken.exp) dispatch(logout())
                else {
                    if (decodedToken.exp < currentTime) {
                        dispatch(logout())
                    }
                }
            }
        }
        return next(action)
    }

export default checkTokenExpiration
