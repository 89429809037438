import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_URL } from 'config'
import { APIEndpoint } from 'shared/types'
import { signToken } from 'shared/utils'

export const register = createAsyncThunk(
    'auth/register',
    async ({ address, username }: { address: string; username: string }, { rejectWithValue }) => {
        try {
            if (!address) {
                throw new Error('Address is required')
            }

            // check if token is valid
            const token = await signToken(address)

            if (!token) {
                throw new Error('Token is required')
            }

            // send request to server
            const { data } = await axios.post(
                API_URL + APIEndpoint.REGISTER,
                {
                    address,
                    username,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            )
            return data.token
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }
    },
)
