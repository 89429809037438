/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import {
    getMe,
    getBalance,
    getCharacters,
    selectCharacter,
    rentCharacter,
    upgradeCharacter,
    withdraw,
    increaseCharacterEnergy,
    getQuests,
    claimQuest,
    getProfile,
    claimQuestBigReward,
    selectToken,
    getFights,
} from './hooks'
import { Account, CharacterOrderBy, FetchStatus } from 'shared/types'
import { AccountStatus } from 'shared/types/index'
import { sendErrorToast, sendNotificationToast } from 'shared/toasts'
import { RootState } from 'stores/store'

const initialState: {
    data: Account
    error: any
    status: FetchStatus
    isFetched: boolean
    isUpgradePending: boolean
    isWithdrawPending: boolean
} = {
    data: {
        id: '',
        username: '',
        address: '',
        token: '',
        status: AccountStatus.OFFLINE,
        lastLoggedInAt: '',
        roles: [],
        completedDaysStreak: 0,
        socketId: '',
        profile: {
            level: 0,
            xp: 0,
            matches: 0,
            wins: 0,
            losses: 0,
            monthly: {
                earnings: 0,
            },
            nextLevel: {
                xpRequired: 0,
                progress: 0,
            },
        },
        inventory: {
            id: '',
            items: [],
        },
        selectedCharacterId: '',
        selectedCharacter: undefined,
        characters: [],
        fightId: '',
        fight: undefined,
        quests: [],
        questsHistory: [],
        notifications: [],
        rents: [],
        createdAt: '',
    },
    error: null,
    status: FetchStatus.IDLE,
    isFetched: false,
    isUpgradePending: false,
    isWithdrawPending: false,
}

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        resetError: (state) => {
            state.error = null
        },
        setFight: (state, action) => {
            state.data.fight = action.payload
        },
        resetFight: (state) => {
            state.data.fight = initialState.data.fight
        },
        insertNotification: (state, action) => {
            if (state.data.notifications.length === 0) {
                state.data.notifications = [action.payload]
            } else if (state.data.notifications.length === 25) {
                state.data.notifications.splice(0, 1, action.payload)
            } else {
                state.data.notifications = [action.payload, ...state.data.notifications]
            }
        },
        filterCharacters: (state, action) => {
            if (!state.data.characters || state.data.characters?.length === 0) return
            const by: CharacterOrderBy = action.payload.by
            const order = action.payload.order
            if (order === 'DESC') {
                state.data.characters = state.data.characters.sort((a, b) => b.stats[by] - a.stats[by])
            } else {
                state.data.characters = state.data.characters.sort((a, b) => a.stats[by] - b.stats[by])
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getMe.pending, (state) => {
            state.status = FetchStatus.PENDING
        })
        builder.addCase(getMe.fulfilled, (state, action) => {
            state.data = {
                ...action.payload,
                characters: action.payload.characters ? action.payload.characters : [],
            }
            state.status = FetchStatus.SUCCESS
            state.isFetched = true
        })
        builder.addCase(getMe.rejected, (state, action) => {
            state.error = action.payload
            state.status = FetchStatus.FAIL
        })
        builder.addCase(getBalance.fulfilled, (state, action) => {
            const index = state.data.inventory.items.findIndex((item) => item.identifier === state.data.token)
            if (index > -1) {
                state.data.inventory.items[index].amount = action.payload
            }
        })
        builder.addCase(getProfile.fulfilled, (state, action) => {
            state.data.profile = action.payload
        })
        builder.addCase(getCharacters.fulfilled, (state, action) => {
            state.data.characters = action.payload
        })
        builder.addCase(getFights.fulfilled, (state, action) => {
            state.data.fights = action.payload
        })
        builder.addCase(getQuests.fulfilled, (state, action) => {
            state.data.quests = action.payload
        })
        builder.addCase(claimQuest.fulfilled, (state, action) => {
            const index = state.data.quests.findIndex((quest) => quest.id === action.payload.id)
            if (index > -1) {
                state.data.quests[index] = action.payload
            }
            const prize = action.payload.prize
            const token = state.data.token
            if (prize.identifier === 'BALANCE') {
                const itemIndex = state.data.inventory.items.findIndex((item) => item.identifier === token)
                state.data.inventory.items[itemIndex].amount += Number(prize.amount)
            } else if (prize.identifier === 'XP') {
                state.data.profile.xp += Number(prize.amount)
            }
            const quests = state.data.quests
            if (quests.length === 3 && !quests.find((quest) => !quest.isClaimed)) {
                state.data.completedDaysStreak += 1
            }
        })
        builder.addCase(claimQuest.rejected, (_, action: any) => {
            sendErrorToast({ title: 'Claim Reward', message: action.payload.message })
        })
        builder.addCase(claimQuestBigReward.fulfilled, (state, action) => {
            state.data.completedDaysStreak = action.payload[0].completedDaysStreak
            state.data.inventory.items = action.payload[1].items
        })
        builder.addCase(claimQuestBigReward.rejected, (_, action: any) => {
            sendErrorToast({ title: 'Claim Reward', message: action.payload.message })
        })
        builder.addCase(selectCharacter.fulfilled, (state, action) => {
            state.data.selectedCharacter = action.payload
        })
        builder.addCase(selectCharacter.rejected, (_, action: any) => {
            sendErrorToast({ title: 'Select Character', message: action.payload.message })
        })
        builder.addCase(upgradeCharacter.pending, (state) => {
            state.isUpgradePending = true
        })
        builder.addCase(rentCharacter.fulfilled, (state, { payload }) => {
            state.data.characters = [
                {
                    ...payload.character,
                    type: 'RENTED',
                    deadline: payload.end,
                },
                ...(state.data.characters || []),
            ]
        })
        builder.addCase(rentCharacter.rejected, (_, action: any) => {
            sendErrorToast({ title: 'Rent Character', message: action.payload.message })
        })
        builder.addCase(upgradeCharacter.fulfilled, (state, action) => {
            const upgrade = action.payload[1]
            state.data.inventory.items = action.payload[0].items
            if (!state.data.characters) return
            const index = state.data.characters.findIndex((character) => character.identifier === upgrade.identifier)
            if (index > -1) {
                state.data.characters[index].upgrades = [upgrade]
            }
            state.isUpgradePending = false
        })
        builder.addCase(upgradeCharacter.rejected, (state, action: any) => {
            sendErrorToast({ title: 'Upgrade', message: action.payload.message })
            state.isUpgradePending = false
        })
        builder.addCase(increaseCharacterEnergy.pending, (state) => {
            state.isUpgradePending = true
        })
        builder.addCase(increaseCharacterEnergy.fulfilled, (state, action) => {
            const characterUpdated = action.payload[1]
            state.data.inventory.items = action.payload[0].items
            if (!state.data.characters) return
            const index = state.data.characters.findIndex(
                (character) => character.identifier === characterUpdated.identifier,
            )
            if (index > -1) {
                state.data.characters[index].stats.energy = characterUpdated.stats.energy
            } else {
                const index = state.data.rents.findIndex(
                    (rent) => rent.character.identifier === characterUpdated.identifier,
                )
                if (index > -1) state.data.rents[index].character.stats.energy = characterUpdated.stats.energy
            }
            if (
                state.data.selectedCharacter &&
                characterUpdated.identifier === state.data.selectedCharacter?.identifier
            ) {
                state.data.selectedCharacter.stats.energy = characterUpdated.stats.energy
            }
            state.isUpgradePending = false
        })
        builder.addCase(increaseCharacterEnergy.rejected, (state, action: any) => {
            sendErrorToast({ title: 'Regenerate', message: action.payload.message })
            state.isUpgradePending = false
        })
        builder.addCase(withdraw.pending, (state) => {
            state.isWithdrawPending = true
        })
        builder.addCase(withdraw.fulfilled, (state, action) => {
            state.data.inventory = action.payload[0]
            state.isWithdrawPending = false
        })
        builder.addCase(withdraw.rejected, (state, action: any) => {
            sendErrorToast({ title: 'Withdraw', message: action.payload.message })
            state.isWithdrawPending = false
        })
        builder.addCase(selectToken.fulfilled, (state, action) => {
            state.data.token = action.payload.token
            sendNotificationToast({ title: 'Default Token', message: 'You have successfully selected the token!' })
        })
        builder.addCase(selectToken.rejected, (_, action: any) => {
            sendErrorToast({ title: 'Select Token', message: action.payload.message })
        })
    },
})

export const { resetError, setFight, resetFight, insertNotification, filterCharacters } = accountSlice.actions

export const selectBalance = (state: RootState) => {
    const items = state.account.data.inventory.items
    const item = items.find((item) => item.identifier === state.account.data.token && item.nonce === 0)

    return item ? item.amount : -1
}

export default accountSlice.reducer
