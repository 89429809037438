import React from 'react'
import { ThemeProvider } from '@mui/material'

import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { persistor, store } from 'stores/store'

import './index.css'
import { defaultTheme } from './theme/defaultTheme'
import App from 'App'
import { PersistGate } from 'redux-persist/integration/react'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <Provider store={store}>
        <ThemeProvider theme={defaultTheme}>
            <PersistGate persistor={persistor}>
                <App />
            </PersistGate>
        </ThemeProvider>
    </Provider>,
)
