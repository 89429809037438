import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    persistStore,
    PersistConfig,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import authReducer from './auth/auth.slice'
import accountReducer from './user/account.slice'
import checkTokenExpiration from './middleware/checkTokenExpiration'
import arenaReducer from './arena/arena.slice'
import adminReducer from './admin/admin.slice'
import { Arena, AuthSlice, EventSlice } from 'shared/types'
import eventReducer from './event/event.slice'
import configReducer from './config'

const authPersistConfig: PersistConfig<AuthSlice> = {
    key: 'auth',
    version: 1,
    storage,
}

const arenaPersistConfig: PersistConfig<Arena> = {
    key: 'arena',
    version: 1,
    storage,
    blacklist: ['searching', 'joinedAt'],
}

const eventPersistConfig: PersistConfig<EventSlice> = {
    key: 'event',
    version: 1,
    storage,
    whitelist: ['show'],
}

const rootReducer = combineReducers({
    config: configReducer,
    auth: persistReducer(authPersistConfig, authReducer),
    account: accountReducer,
    admin: adminReducer,
    arena: persistReducer(arenaPersistConfig, arenaReducer),
    event: persistReducer(eventPersistConfig, eventReducer),
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(checkTokenExpiration),
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
