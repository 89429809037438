import React from 'react'
import { createSvgIcon } from '@mui/material'

export const LogOutIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 334.98 334.27">
        <defs>
            <linearGradient
                id="linear-gradient"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-8.02 14.57) scale(1.05 1)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0} stopColor="#727272" />
                <stop offset={0.57} stopColor="#606060" />
                <stop offset={0.72} stopColor="#575757" />
                <stop offset={0.97} stopColor="#404040" />
                <stop offset={1} stopColor="#3d3d3d" />
            </linearGradient>
            <linearGradient
                id="linear-gradient-3"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-7.86 14.29) scale(1.05 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-5"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-7.71 14) scale(1.05 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-7"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-7.55 13.72) scale(1.05 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-9"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-7.39 13.44) scale(1.04 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-11"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-7.23 13.16) scale(1.04 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-13"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-7.08 12.88) scale(1.04 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-15"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-6.92 12.6) scale(1.04 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-17"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-6.76 12.32) scale(1.04 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-19"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-6.61 12.04) scale(1.04 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-21"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-6.45 11.76) scale(1.04 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-23"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-6.29 11.48) scale(1.04 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-25"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-6.14 11.2) scale(1.04 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-27"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-5.98 10.92) scale(1.04 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-29"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-5.82 10.64) scale(1.03 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-31"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-5.67 10.36) scale(1.03 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-33"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-5.51 10.08) scale(1.03 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-35"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-5.36 9.8) scale(1.03 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-37"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-5.2 9.52) scale(1.03 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-39"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-5.05 9.24) scale(1.03 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-41"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-4.89 8.96) scale(1.03 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-43"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-4.74 8.68) scale(1.03 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-45"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-4.58 8.4) scale(1.03 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-47"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-4.43 8.12) scale(1.03 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-49"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-4.27 7.84) scale(1.03 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-51"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-4.12 7.56) scale(1.02 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-53"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-3.96 7.28) scale(1.02 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-55"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-3.81 7) scale(1.02 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-57"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-3.66 6.72) scale(1.02 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-59"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-3.5 6.44) scale(1.02 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-61"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-3.35 6.16) scale(1.02 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-63"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-3.19 5.88) scale(1.02 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-65"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-3.04 5.6) scale(1.02 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-67"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-2.89 5.32) scale(1.02 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-69"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-2.73 5.04) scale(1.02 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-71"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-2.58 4.76) scale(1.02 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-73"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-2.43 4.48) scale(1.01 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-75"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-2.28 4.2) scale(1.01 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-77"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-2.12 3.92) scale(1.01 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-79"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-1.97 3.64) scale(1.01 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-81"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-1.82 3.36) scale(1.01 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-83"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-1.67 3.08) scale(1.01 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-85"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-1.51 2.8) scale(1.01 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-87"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-1.36 2.52) scale(1.01 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-89"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-1.21 2.24) scale(1.01 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-91"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-1.06 1.96) scale(1.01 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-93"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-0.91 1.68) scale(1.01 1)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-95"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-0.76 1.4)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-97"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-0.6 1.12)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-99"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-0.45 0.84)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-101"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-0.3 0.56)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-103"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(-0.15 0.28)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-105"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                xlinkHref="#linear-gradient"
            />
            <linearGradient
                id="linear-gradient-107"
                x1={21.5}
                y1={159.99}
                x2={313.47}
                y2={159.99}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0} stopColor="#f2f2f2" />
                <stop offset={1} stopColor="#d8d8d8" />
            </linearGradient>
            <linearGradient
                id="linear-gradient-109"
                x1={167.48}
                y1={14}
                x2={167.48}
                y2={305.99}
                gradientTransform="translate(0 6) scale(1 0.98)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0} stopColor="#bcbcbc" />
                <stop offset={1} stopColor="#9e9e9e" />
            </linearGradient>
        </defs>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M242.83,14a70.65,70.65,0,0,0-70.65,70.64V98.77h28.26V84.64a42.39,42.39,0,0,1,84.77,0v150.7a42.39,42.39,0,1,1-84.77,0V221.22H172.18v14.12a70.65,70.65,0,1,0,141.29,0V84.64A70.64,70.64,0,0,0,242.83,14Z" />
                <path d="M200.44,174.12V145.87H69.71l42.38-42.39-20-20L25.63,150a14.13,14.13,0,0,0,0,20l66.49,66.49,20-20L69.71,174.12Z" />
                <path
                    d="M246.36,334.27c-48.86,0-88.61-38-88.61-84.64V221.5h58.9v28.13c0,15.65,13.33,28.38,29.71,28.38s29.72-12.73,29.72-28.38V98.93c0-15.65-13.33-28.38-29.72-28.38s-29.71,12.73-29.71,28.38v28.13h-58.9V98.93c0-46.67,39.75-84.64,88.61-84.64S335,52.26,335,98.93v150.7C335,296.3,295.23,334.27,246.36,334.27Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M8.6,194.16a27.28,27.28,0,0,1,0-39.76l80-76.4,41.64,39.77-29.72,28.38H216.65v56.26H100.51l29.71,28.38L88.59,270.56Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M246.29,334c-48.82,0-88.54-38-88.54-84.64V221.22H216.6v28.13c0,15.65,13.32,28.38,29.69,28.38S276,265,276,249.35V98.65c0-15.65-13.32-28.38-29.69-28.38S216.6,83,216.6,98.65v28.13H157.75V98.65C157.75,52,197.47,14,246.29,14s88.54,38,88.54,84.65v150.7C334.83,296,295.11,334,246.29,334Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M8.74,193.88a27.3,27.3,0,0,1,0-39.76l79.92-76.4,41.6,39.77-29.69,28.38h116v56.26h-116l29.69,28.38-41.6,39.77Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M246.22,333.71c-48.78,0-88.46-38-88.46-84.64V220.94h58.8v28.13c0,15.65,13.31,28.38,29.66,28.38s29.66-12.73,29.66-28.38V98.37c0-15.65-13.3-28.38-29.66-28.38s-29.66,12.73-29.66,28.38V126.5h-58.8V98.37c0-46.67,39.68-84.65,88.46-84.65s88.46,38,88.46,84.65v150.7C334.68,295.74,295,333.71,246.22,333.71Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M8.89,193.6a27.31,27.31,0,0,1,0-39.76l79.85-76.4,41.56,39.77-29.66,28.38H216.56v56.26H100.63l29.66,28.38L88.73,270Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M246.15,333.43c-48.73,0-88.38-38-88.38-84.64V220.66h58.75v28.13c0,15.65,13.29,28.38,29.63,28.38s29.63-12.73,29.63-28.38V98.09c0-15.65-13.29-28.38-29.63-28.38s-29.63,12.73-29.63,28.38v28.13H157.77V98.09c0-46.67,39.65-84.65,88.38-84.65s88.38,38,88.38,84.65v150.7C334.53,295.46,294.88,333.43,246.15,333.43Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M9,193.32a27.33,27.33,0,0,1,0-39.76L88.8,77.16l41.53,39.77-29.64,28.38H216.52v56.26H100.69L130.33,230,88.8,269.72Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M246.08,333.15c-48.69,0-88.3-38-88.3-84.64V220.38h58.69v28.13c0,15.65,13.28,28.38,29.61,28.38s29.61-12.73,29.61-28.38V97.81c0-15.65-13.29-28.38-29.61-28.38s-29.61,12.73-29.61,28.38v28.13H157.78V97.81c0-46.67,39.61-84.65,88.3-84.65s88.3,38,88.3,84.65v150.7C334.38,295.18,294.77,333.15,246.08,333.15Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M9.17,193a27.35,27.35,0,0,1,0-39.76l79.7-76.4,41.49,39.77L100.75,145H216.47v56.26H100.75l29.61,28.38L88.87,269.44Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M246,332.87c-48.65,0-88.22-38-88.22-84.64V220.1h58.64v28.13c0,15.65,13.27,28.38,29.58,28.38s29.58-12.73,29.58-28.38V97.53c0-15.65-13.27-28.38-29.58-28.38s-29.58,12.73-29.58,28.38v28.13H157.79V97.53c0-46.67,39.57-84.65,88.22-84.65s88.22,38,88.22,84.65v150.7C334.23,294.9,294.65,332.87,246,332.87Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M9.31,192.76a27.37,27.37,0,0,1,0-39.76L88.94,76.6l41.45,39.77-29.58,28.38H216.43V201H100.81l29.58,28.38L88.94,269.16Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M245.94,332.59c-48.6,0-88.14-38-88.14-84.64V219.82h58.58V248c0,15.65,13.26,28.38,29.56,28.38S275.49,263.6,275.49,248V97.25c0-15.65-13.26-28.38-29.55-28.38S216.38,81.6,216.38,97.25v28.13H157.8V97.25c0-46.67,39.54-84.65,88.14-84.65s88.14,38,88.14,84.65V248C334.08,294.62,294.54,332.59,245.94,332.59Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M9.46,192.48a27.38,27.38,0,0,1,0-39.76L89,76.32l41.42,39.77-29.56,28.38H216.38v56.26H100.87l29.56,28.38L89,268.88Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M245.87,332.31c-48.56,0-88.06-38-88.06-84.64V219.54h58.53v28.13c0,15.65,13.25,28.38,29.53,28.38s29.52-12.73,29.52-28.38V97c0-15.65-13.24-28.38-29.52-28.38S216.34,81.32,216.34,97V125.1H157.81V97c0-46.67,39.5-84.65,88.06-84.65s88.06,38,88.06,84.65v150.7C333.93,294.34,294.42,332.31,245.87,332.31Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M9.6,192.2a27.4,27.4,0,0,1,0-39.76L89.08,76l41.38,39.77-29.53,28.38H216.34v56.26H100.93l29.53,28.38L89.08,268.6Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M245.8,332c-48.52,0-88-38-88-84.64V219.26H216.3v28.13c0,15.65,13.23,28.38,29.5,28.38s29.5-12.73,29.5-28.38V96.69c0-15.65-13.24-28.38-29.5-28.38S216.3,81,216.3,96.69v28.13H157.82V96.69C157.82,50,197.28,12,245.8,12s88,38,88,84.65v150.7C333.78,294.06,294.31,332,245.8,332Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M9.74,191.92a27.43,27.43,0,0,1,0-39.76l79.42-76.4,41.34,39.77L101,143.91H216.3v56.26H101l29.5,28.38L89.15,268.32Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M245.73,331.75c-48.47,0-87.91-38-87.91-84.64V219h58.43v28.13c0,15.65,13.22,28.38,29.48,28.38s29.47-12.73,29.47-28.38V96.41C275.2,80.76,262,68,245.73,68s-29.48,12.73-29.48,28.38v28.13H157.82V96.41c0-46.67,39.44-84.65,87.91-84.65s87.9,38,87.9,84.65v150.7C333.63,293.78,294.2,331.75,245.73,331.75Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M9.88,191.64a27.44,27.44,0,0,1,0-39.76l79.34-76.4,41.31,39.77-29.48,28.38h115.2v56.26H101.05l29.48,28.38L89.22,268Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M245.66,331.47c-48.43,0-87.83-38-87.83-84.64V218.7h58.38v28.13c0,15.65,13.21,28.38,29.45,28.38s29.44-12.73,29.44-28.38V96.13c0-15.65-13.21-28.38-29.44-28.38s-29.45,12.73-29.45,28.38v28.13H157.83V96.13c0-46.67,39.4-84.65,87.83-84.65s87.82,38,87.82,84.65v150.7C333.48,293.5,294.08,331.47,245.66,331.47Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M10,191.36a27.45,27.45,0,0,1,0-39.76L89.29,75.2,130.56,115l-29.45,28.38h115.1v56.26H101.11L130.56,228,89.29,267.76Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M245.59,331.19c-48.39,0-87.75-38-87.75-84.64V218.42h58.32v28.13c0,15.65,13.2,28.38,29.43,28.38S275,262.2,275,246.55V95.85c0-15.65-13.2-28.38-29.42-28.38S216.16,80.2,216.16,95.85V124H157.84V95.85c0-46.67,39.36-84.65,87.75-84.65s87.74,38,87.74,84.65v150.7C333.33,293.22,294,331.19,245.59,331.19Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M10.17,191.08a27.46,27.46,0,0,1,0-39.76l79.2-76.41,41.23,39.78-29.42,28.38h115v56.26h-115l29.42,28.38L89.36,267.48Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M245.52,330.91c-48.34,0-87.67-38-87.67-84.64V218.14h58.27v28.13c0,15.65,13.19,28.38,29.4,28.38s29.39-12.73,29.39-28.38V95.57c0-15.65-13.19-28.38-29.39-28.38s-29.4,12.73-29.4,28.38V123.7H157.85V95.57c0-46.67,39.33-84.65,87.67-84.65s87.66,38,87.66,84.65v150.7C333.18,292.94,293.85,330.91,245.52,330.91Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M10.31,190.8a27.48,27.48,0,0,1,0-39.76L89.43,74.63l41.2,39.78-29.4,28.38H216.12v56.26H101.23l29.39,28.38L89.43,267.2Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M245.45,330.63c-48.3,0-87.59-38-87.59-84.64V217.86h58.22V246c0,15.65,13.17,28.38,29.37,28.38s29.36-12.73,29.36-28.38V95.29c0-15.65-13.17-28.38-29.36-28.38s-29.37,12.73-29.37,28.38v28.13H157.86V95.29c0-46.68,39.29-84.65,87.59-84.65S333,48.61,333,95.29V246C333,292.66,293.74,330.63,245.45,330.63Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M10.45,190.52a27.5,27.5,0,0,1,0-39.76L89.5,74.35l41.16,39.78-29.37,28.38H216.08v56.26H101.29l29.37,28.38L89.5,266.92Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M245.38,330.35c-48.26,0-87.51-38-87.51-84.64V217.58H216v28.13c0,15.65,13.17,28.38,29.35,28.38s29.34-12.73,29.34-28.38V95c0-15.65-13.17-28.38-29.34-28.38S216,79.36,216,95v28.13H157.87V95c0-46.68,39.25-84.65,87.51-84.65s87.5,38,87.5,84.65v150.7C332.88,292.38,293.63,330.35,245.38,330.35Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M10.59,190.24a27.53,27.53,0,0,1,0-39.76l79-76.41,41.12,39.78-29.34,28.38H216v56.26H101.35l29.34,28.38L89.57,266.64Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M245.31,330.07c-48.21,0-87.43-38-87.43-84.64V217.3H216v28.13c0,15.65,13.15,28.38,29.32,28.38s29.31-12.73,29.31-28.38V94.73c0-15.65-13.15-28.38-29.31-28.38S216,79.08,216,94.73v28.13H157.88V94.73c0-46.68,39.22-84.65,87.43-84.65s87.42,38,87.42,84.65v150.7C332.73,292.1,293.51,330.07,245.31,330.07Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M10.73,190a27.55,27.55,0,0,1,0-39.76L89.64,73.79l41.08,39.78L101.41,142H216v56.26H101.41l29.31,28.38L89.64,266.36Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M245.24,329.79c-48.17,0-87.36-38-87.36-84.64V217H216v28.13c0,15.65,13.13,28.38,29.29,28.38s29.28-12.73,29.28-28.38V94.45c0-15.65-13.13-28.38-29.28-28.38S216,78.8,216,94.45v28.13H157.88V94.45c0-46.68,39.19-84.65,87.36-84.65s87.35,38,87.35,84.65v150.7C332.59,291.82,293.4,329.79,245.24,329.79Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M10.87,189.68a27.55,27.55,0,0,1,0-39.76L89.71,73.51l41.05,39.77-29.29,28.39H216v56.26H101.47l29.29,28.38-41,39.77Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M245.17,329.51c-48.13,0-87.28-38-87.28-84.64V216.74h58v28.13c0,15.65,13.13,28.38,29.27,28.38s29.26-12.73,29.26-28.38V94.17c0-15.65-13.13-28.38-29.26-28.38S215.9,78.52,215.9,94.17V122.3h-58V94.17c0-46.68,39.15-84.65,87.28-84.65s87.27,38,87.27,84.65v150.7C332.44,291.54,293.29,329.51,245.17,329.51Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M11,189.4a27.57,27.57,0,0,1,0-39.76L89.78,73.23l41,39.77-29.26,28.39H215.9v56.26H101.53L130.79,226l-41,39.77Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M245.1,329.23c-48.08,0-87.2-38-87.2-84.64V216.46h58v28.13C215.86,260.24,229,273,245.1,273s29.23-12.73,29.23-28.38V93.89c0-15.65-13.11-28.38-29.23-28.38s-29.24,12.73-29.24,28.38V122h-58V93.89c0-46.68,39.12-84.65,87.2-84.65s87.19,38,87.19,84.65v150.7C332.29,291.26,293.17,329.23,245.1,329.23Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M11.15,189.12a27.59,27.59,0,0,1,0-39.76L89.85,73l41,39.77-29.23,28.39H215.86v56.26H101.59l29.23,28.38-41,39.77Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M245,329c-48,0-87.12-38-87.12-84.64V216.18h57.9v28.13c0,15.65,13.11,28.38,29.22,28.38s29.21-12.73,29.21-28.38V93.61c0-15.65-13.11-28.39-29.21-28.39S215.81,78,215.81,93.61v28.13h-57.9V93.61C157.91,46.93,197,9,245,9s87.11,38,87.11,84.65v150.7C332.14,291,293.06,329,245,329Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M11.29,188.84a27.6,27.6,0,0,1,0-39.76L89.92,72.67l40.94,39.77-29.21,28.39H215.81v56.26H101.65l29.21,28.38L89.92,265.24Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M245,328.67c-48,0-87-38-87-84.64V215.9h57.85V244c0,15.65,13.09,28.38,29.19,28.38s29.18-12.73,29.18-28.38V93.33c0-15.65-13.09-28.39-29.18-28.39s-29.19,12.74-29.19,28.39v28.13H157.92V93.33c0-46.68,39-84.65,87-84.65s87,38,87,84.65V244C332,290.7,293,328.67,245,328.67Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M11.43,188.56a27.62,27.62,0,0,1,0-39.76L90,72.39l40.9,39.77L101.7,140.55H215.77v56.26H101.7l29.19,28.38L90,265Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M244.89,328.39c-47.95,0-87-38-87-84.64V215.62h57.8v28.13c0,15.65,13.08,28.38,29.16,28.38S274,259.4,274,243.75V93.05C274,77.4,261,64.66,244.89,64.66S215.73,77.4,215.73,93.05v28.13h-57.8V93.05c0-46.68,39-84.65,87-84.65s87,38,87,84.65v150.7C331.85,290.42,292.84,328.39,244.89,328.39Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M11.58,188.28a27.63,27.63,0,0,1,0-39.76L90.06,72.11l40.86,39.77-29.16,28.39h114v56.26h-114l29.16,28.38L90.06,264.68Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M244.82,328.11c-47.91,0-86.88-38-86.88-84.64V215.34h57.74v28.13c0,15.65,13.07,28.38,29.14,28.38S274,259.12,274,243.47V92.77c0-15.65-13.07-28.39-29.13-28.39s-29.14,12.74-29.14,28.39V120.9H157.94V92.77c0-46.68,39-84.65,86.88-84.65s86.88,38,86.88,84.65v150.7C331.7,290.14,292.72,328.11,244.82,328.11Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M11.72,188a27.65,27.65,0,0,1,0-39.76L90.13,71.83,131,111.6,101.82,140H215.68v56.26H101.82L131,224.63,90.13,264.4Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M244.75,327.83c-47.87,0-86.81-38-86.81-84.64V215.06h57.7v28.13c0,15.65,13.06,28.38,29.11,28.38s29.1-12.73,29.1-28.38V92.49c0-15.65-13.05-28.39-29.1-28.39s-29.11,12.74-29.11,28.39v28.13h-57.7V92.49c0-46.68,38.94-84.65,86.81-84.65s86.8,38,86.8,84.65v150.7C331.55,289.86,292.61,327.83,244.75,327.83Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M11.86,187.72a27.66,27.66,0,0,1,0-39.76L90.2,71.55,131,111.32l-29.11,28.39H215.64V196H101.88L131,224.35,90.2,264.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M244.68,327.55c-47.82,0-86.73-38-86.73-84.64V214.78H215.6v28.13c0,15.65,13,28.38,29.08,28.38s29.08-12.73,29.08-28.38V92.21c0-15.65-13.05-28.39-29.08-28.39S215.6,76.56,215.6,92.21v28.13H158V92.21c0-46.68,38.91-84.65,86.73-84.65s86.72,38,86.72,84.65v150.7C331.4,289.58,292.5,327.55,244.68,327.55Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M12,187.44a27.68,27.68,0,0,1,0-39.76L90.27,71.27,131,111l-29.08,28.39H215.6v56.26H101.94L131,224.07,90.27,263.84Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M244.61,327.27c-47.78,0-86.65-38-86.65-84.64V214.5h57.59v28.13c0,15.65,13,28.38,29.06,28.38s29.05-12.73,29.05-28.38V91.93c0-15.65-13-28.39-29.05-28.39s-29.06,12.74-29.06,28.39v28.13H158V91.93c0-46.68,38.87-84.65,86.65-84.65s86.64,38,86.64,84.65v150.7C331.25,289.3,292.39,327.27,244.61,327.27Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M12.14,187.16a27.7,27.7,0,0,1,0-39.76L90.34,71l40.71,39.77L102,139.15H215.55v56.26H102l29.05,28.38L90.34,263.56Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M244.54,327C196.8,327,158,289,158,242.35V214.22h57.54v28.13c0,15.65,13,28.38,29,28.38s29-12.73,29-28.38V91.65c0-15.65-13-28.39-29-28.39s-29,12.74-29,28.39v28.13H158V91.65C158,45,196.8,7,244.54,7s86.57,38,86.57,84.65v150.7C331.11,289,292.27,327,244.54,327Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M12.27,186.88a27.72,27.72,0,0,1,0-39.76L90.41,70.71l40.68,39.77-29,28.39H215.51v56.26H102.06l29,28.38L90.41,263.28Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M244.47,326.71c-47.69,0-86.49-38-86.49-84.64V213.94h57.49v28.13c0,15.65,13,28.38,29,28.38s29-12.73,29-28.38V91.37c0-15.65-13-28.39-29-28.39s-29,12.74-29,28.39V119.5H158V91.37c0-46.68,38.8-84.65,86.49-84.65S331,44.69,331,91.37v150.7C331,288.74,292.16,326.71,244.47,326.71Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M12.41,186.6a27.74,27.74,0,0,1,0-39.76L90.48,70.43l40.64,39.77-29,28.39H215.47v56.26H102.12l29,28.38L90.48,263Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M244.4,326.43c-47.65,0-86.41-38-86.41-84.64V213.66h57.44v28.13c0,15.65,13,28.38,29,28.38s29-12.73,29-28.38V91.09c0-15.65-13-28.39-29-28.39s-29,12.74-29,28.39v28.13H158V91.09c0-46.68,38.76-84.65,86.41-84.65s86.41,38,86.41,84.65v150.7C330.81,288.46,292.05,326.43,244.4,326.43Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M12.55,186.32a27.76,27.76,0,0,1,0-39.76l78-76.41,40.6,39.77-29,28.39H215.43v56.26H102.18l29,28.38-40.6,39.77Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M244.33,326.15c-47.6,0-86.33-38-86.33-84.64V213.38h57.38v28.13c0,15.65,13,28.38,29,28.38s28.95-12.73,28.95-28.38V90.81c0-15.65-13-28.39-28.95-28.39s-29,12.74-29,28.39v28.13H158V90.81c0-46.68,38.73-84.65,86.33-84.65s86.34,38,86.34,84.65v150.7C330.67,288.18,291.94,326.15,244.33,326.15Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M12.69,186a27.78,27.78,0,0,1,0-39.76L90.62,69.87l40.56,39.77L102.24,138H215.38v56.27H102.24l28.94,28.38L90.62,262.44Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M244.26,325.87c-47.56,0-86.26-38-86.26-84.64V213.1h57.34v28.13c0,15.65,13,28.38,28.92,28.38s28.93-12.73,28.93-28.38V90.53c0-15.65-13-28.39-28.93-28.39s-28.92,12.74-28.92,28.39v28.13H158V90.53c0-46.68,38.7-84.65,86.26-84.65s86.26,38,86.26,84.65v150.7C330.52,287.9,291.82,325.87,244.26,325.87Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M12.83,185.76a27.79,27.79,0,0,1,0-39.76L90.69,69.59l40.53,39.77-28.93,28.38H215.34V194h-113l28.93,28.38L90.69,262.16Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M244.19,325.59c-47.52,0-86.18-38-86.18-84.64V212.82H215.3V241c0,15.65,13,28.38,28.89,28.38s28.9-12.73,28.9-28.38V90.25c0-15.65-13-28.39-28.9-28.39S215.3,74.6,215.3,90.25v28.13H158V90.25C158,43.57,196.67,5.6,244.19,5.6s86.18,38,86.18,84.65V241C330.37,287.62,291.71,325.59,244.19,325.59Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M13,185.48a27.82,27.82,0,0,1,0-39.76l77.8-76.41,40.49,39.77-28.9,28.38h113v56.27h-113l28.9,28.38L90.76,261.88Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M244.12,325.31c-47.47,0-86.1-38-86.1-84.64V212.54h57.23v28.13c0,15.65,12.95,28.38,28.87,28.38S273,256.32,273,240.67V90c0-15.65-13-28.39-28.88-28.39S215.25,74.32,215.25,90V118.1H158V90c0-46.68,38.63-84.65,86.1-84.65s86.11,38,86.11,84.65v150.7C330.23,287.34,291.6,325.31,244.12,325.31Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M13.11,185.2a27.84,27.84,0,0,1,0-39.76L90.83,69l40.45,39.77-28.87,28.38H215.25v56.27H102.41l28.87,28.38L90.83,261.6Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M244.06,325c-47.44,0-86-38-86-84.64V212.26h57.18v28.13c0,15.65,12.94,28.38,28.85,28.38a28.65,28.65,0,0,0,28.84-28.38V89.69a28.85,28.85,0,0,0-57.69,0v28.13H158V89.69C158,43,196.62,5,244.06,5s86,38,86,84.65v150.7C330.08,287.06,291.49,325,244.06,325Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M13.25,184.92a27.86,27.86,0,0,1,0-39.76L90.89,68.75l40.43,39.77L102.47,136.9H215.21v56.27H102.47l28.84,28.38L90.89,261.32Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M244,324.75c-47.4,0-86-38-86-84.64V212h57.13v28.13a28.82,28.82,0,0,0,57.64,0V89.41a28.82,28.82,0,0,0-57.64,0v28.13H158V89.41c0-46.68,38.55-84.65,86-84.65s85.94,38,85.94,84.65v150.7C329.93,286.78,291.38,324.75,244,324.75Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M13.39,184.64a27.87,27.87,0,0,1,0-39.76L91,68.47l40.39,39.77-28.82,28.38H215.17v56.27H102.53l28.82,28.38L91,261Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M243.92,324.47c-47.35,0-85.87-38-85.87-84.64V211.69h57.07v28.14a28.8,28.8,0,0,0,57.59,0V89.13a28.8,28.8,0,0,0-57.59,0v28.13H158.05V89.13c0-46.68,38.52-84.65,85.87-84.65s85.87,38,85.87,84.65v150.7C329.79,286.5,291.27,324.47,243.92,324.47Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M13.53,184.36a27.87,27.87,0,0,1,0-39.76L91,68.19,131.38,108l-28.79,28.38H215.12v56.27H102.59L131.38,221,91,260.76Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M243.85,324.19c-47.31,0-85.79-38-85.79-84.64V211.41h57v28.14a28.77,28.77,0,0,0,57.54,0V88.85a28.77,28.77,0,0,0-57.54,0V117h-57V88.85c0-46.68,38.48-84.65,85.79-84.65s85.79,38,85.79,84.65v150.7C329.64,286.22,291.16,324.19,243.85,324.19Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M13.67,184.08a27.89,27.89,0,0,1,0-39.76L91.1,67.91l40.31,39.77-28.77,28.38H215.08v56.27H102.64l28.77,28.38L91.1,260.48Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M243.78,323.91c-47.26,0-85.72-38-85.72-84.64V211.13h57v28.14a28.74,28.74,0,0,0,57.48,0V88.56a28.74,28.74,0,0,0-57.48,0V116.7h-57V88.56c0-46.67,38.46-84.64,85.72-84.64s85.72,38,85.72,84.64V239.27C329.5,285.94,291,323.91,243.78,323.91Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M13.8,183.8a27.92,27.92,0,0,1,0-39.76L91.17,67.63l40.28,39.77L102.7,135.78H215v56.27H102.7l28.74,28.38L91.17,260.2Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M243.71,323.63c-47.22,0-85.64-38-85.64-84.64V210.85H215V239a28.72,28.72,0,0,0,57.43,0V88.28a28.72,28.72,0,0,0-57.43,0v28.14H158.07V88.28c0-46.67,38.42-84.64,85.64-84.64s85.64,38,85.64,84.64V239C329.35,285.66,290.93,323.63,243.71,323.63Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M13.94,183.52a27.94,27.94,0,0,1,0-39.76L91.24,67.35l40.24,39.77L102.76,135.5H215v56.27H102.76l28.72,28.38L91.24,259.92Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M243.64,323.35c-47.18,0-85.56-38-85.56-84.64V210.57H215v28.14a28.69,28.69,0,0,0,57.38,0V88A28.69,28.69,0,0,0,215,88v28.14H158.08V88c0-46.67,38.38-84.64,85.56-84.64s85.57,38,85.57,84.64V238.71C329.21,285.38,290.82,323.35,243.64,323.35Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M14.08,183.24a28,28,0,0,1,0-39.76L91.31,67.07l40.2,39.77-28.69,28.38H215v56.27H102.82l28.69,28.38-40.2,39.77Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M243.57,323.07c-47.13,0-85.48-38-85.48-84.64V210.29h56.82v28.14a28.67,28.67,0,0,0,57.33,0V87.72a28.67,28.67,0,0,0-57.33,0v28.14H158.09V87.72c0-46.67,38.35-84.64,85.48-84.64s85.49,38,85.49,84.64V238.43C329.06,285.1,290.71,323.07,243.57,323.07Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M14.22,183a28,28,0,0,1,0-39.76L91.38,66.79l40.16,39.77-28.66,28.38h112v56.27h-112l28.66,28.38L91.38,259.36Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M243.51,322.79c-47.1,0-85.41-38-85.41-84.64V210h56.77v28.14a28.64,28.64,0,0,0,57.27,0V87.44a28.64,28.64,0,0,0-57.27,0v28.14H158.1V87.44c0-46.67,38.31-84.64,85.41-84.64s85.4,38,85.4,84.64V238.15C328.91,284.82,290.6,322.79,243.51,322.79Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M14.36,182.68a28,28,0,0,1,0-39.76L91.44,66.51l40.13,39.77-28.63,28.38H214.87v56.27H102.94l28.63,28.38L91.44,259.08Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M243.44,322.51c-47.05,0-85.33-38-85.33-84.64V209.73h56.72v28.14a28.61,28.61,0,0,0,57.22,0V87.16a28.61,28.61,0,0,0-57.22,0V115.3H158.11V87.16c0-46.67,38.28-84.64,85.33-84.64s85.33,38,85.33,84.64V237.87C328.77,284.54,290.49,322.51,243.44,322.51Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M14.49,182.4a28,28,0,0,1,0-39.76l77-76.41L131.61,106,103,134.38H214.83v56.27H103L131.61,219,91.51,258.8Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M243.37,322.23c-47,0-85.25-38-85.25-84.64V209.45h56.66v28.14a28.59,28.59,0,0,0,57.18,0V86.88a28.59,28.59,0,0,0-57.18,0V115H158.12V86.88c0-46.67,38.24-84.64,85.25-84.64s85.25,38,85.25,84.64V237.59C328.62,284.26,290.38,322.23,243.37,322.23Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M14.63,182.12a28,28,0,0,1,0-39.76L91.58,66l40.06,39.77L103.05,134.1H214.78v56.27H103.05l28.59,28.38L91.58,258.52Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M243.3,322c-47,0-85.18-38-85.18-84.65V209.17h56.62V237.3a28.56,28.56,0,0,0,57.12,0V86.6a28.56,28.56,0,0,0-57.12,0v28.14H158.12V86.6C158.12,39.93,196.33,2,243.3,2s85.18,38,85.18,84.64V237.3C328.48,284,290.27,322,243.3,322Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M14.77,181.84a28.05,28.05,0,0,1,0-39.76L91.65,65.67l40,39.77-28.56,28.38H214.74v56.27H103.11l28.56,28.38-40,39.77Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M243.23,321.67c-46.92,0-85.1-38-85.1-84.65V208.89H214.7V237a28.54,28.54,0,0,0,57.07,0V86.32a28.54,28.54,0,0,0-57.07,0v28.14H158.13V86.32c0-46.67,38.18-84.64,85.1-84.64s85.1,38,85.1,84.64V237C328.33,283.7,290.16,321.67,243.23,321.67Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M14.91,181.56a28.05,28.05,0,0,1,0-39.76L91.72,65.39l40,39.77-28.53,28.38H214.7v56.27H103.17l28.53,28.38L91.72,258Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M243.17,321.39c-46.89,0-85-38-85-84.65V208.61h56.52v28.13a28.51,28.51,0,0,0,57,0V86a28.51,28.51,0,0,0-57,0v28.14H158.14V86c0-46.67,38.14-84.64,85-84.64s85,38,85,84.64v150.7C328.19,283.42,290.05,321.39,243.17,321.39Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M15,181.28a28.08,28.08,0,0,1,0-39.76L91.78,65.11l40,39.77-28.51,28.38H214.66v56.27H103.23l28.51,28.38-40,39.77Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M243.1,321.11c-46.84,0-84.95-38-84.95-84.65V208.33h56.46v28.13a28.49,28.49,0,0,0,57,0V85.76a28.49,28.49,0,0,0-57,0V113.9H158.15V85.76c0-46.67,38.11-84.64,84.95-84.64s85,38,85,84.64v150.7C328.05,283.14,289.94,321.11,243.1,321.11Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M15.18,181a28.1,28.1,0,0,1,0-39.76L91.85,64.83l39.92,39.77L103.28,133H214.61v56.27H103.28l28.49,28.38L91.85,257.4Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M243,320.83c-46.8,0-84.87-38-84.87-84.65V208.05h56.41v28.13a28.46,28.46,0,0,0,56.92,0V85.48a28.46,28.46,0,0,0-56.92,0v28.14H158.16V85.48C158.16,38.81,196.23.84,243,.84s84.87,38,84.87,84.64v150.7C327.9,282.86,289.83,320.83,243,320.83Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M15.32,180.72a28.1,28.1,0,0,1,0-39.76L91.92,64.55l39.88,39.77L103.34,132.7H214.57V189H103.34l28.46,28.38L91.92,257.12Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M243,320.55a84.81,84.81,0,0,1-84.79-84.65V207.77h56.36V235.9a28.43,28.43,0,0,0,56.86,0V85.2a28.43,28.43,0,0,0-56.86,0v28.14H158.17V85.2a84.8,84.8,0,0,1,169.59,0V235.9A84.82,84.82,0,0,1,243,320.55Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M15.45,180.44a28.14,28.14,0,0,1,0-39.77L92,64.27,131.83,104,103.4,132.42H214.53v56.27H103.4l28.43,28.38L92,256.84Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M242.89,320.27a84.78,84.78,0,0,1-84.72-84.65V207.49h56.32v28.13a28.41,28.41,0,0,0,56.81,0V84.92a28.41,28.41,0,0,0-56.81,0v28.14H158.17V84.92a84.72,84.72,0,0,1,169.44,0v150.7A84.78,84.78,0,0,1,242.89,320.27Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M15.59,180.16a28.17,28.17,0,0,1,0-39.77L92.06,64l39.8,39.77-28.4,28.38h111v56.27h-111l28.4,28.38-39.8,39.77Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M242.83,320a84.74,84.74,0,0,1-84.65-84.65V207.21h56.26v28.13a28.39,28.39,0,1,0,56.77,0V84.64a28.39,28.39,0,0,0-56.77,0v28.13H158.18V84.64a84.65,84.65,0,0,1,169.29,0v150.7A84.74,84.74,0,0,1,242.83,320Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M15.73,179.88a28.17,28.17,0,0,1,0-39.77l76.4-76.4,39.78,39.77-28.39,28.38H214.44v56.26H103.51l28.39,28.39L92.12,256.28Z"
                    style={{
                        fill: '#1e1e1e',
                    }}
                />
                <path
                    d="M246.43,28.57c-40.88,0-74,31.63-74,70.64v14.13H202V99.21c0-23.41,19.88-42.39,44.41-42.39s44.42,19,44.42,42.39v150.7c0,23.41-19.89,42.38-44.42,42.38S202,273.32,202,249.91V235.78H172.41v14.13c0,39,33.14,70.64,74,70.64s74-31.63,74-70.64V99.21C320.46,60.2,287.32,28.57,246.43,28.57Z"
                    style={{
                        fill: 'url(#linear-gradient)',
                    }}
                />
                <path
                    d="M202,188.69V160.43H65l44.41-42.38-20.92-20-69.69,66.5a13.67,13.67,0,0,0,0,20L88.52,251l20.92-20L65,188.69Z"
                    style={{
                        fill: 'url(#linear-gradient)',
                    }}
                />
                <path
                    d="M246.36,28.29c-40.84,0-74,31.62-74,70.64v14.13H202V98.93c0-23.41,19.87-42.39,44.37-42.39s44.38,19,44.38,42.39v150.7c0,23.41-19.87,42.38-44.38,42.38S202,273,202,249.63V235.5H172.41v14.13c0,39,33.11,70.64,74,70.64s74-31.63,74-70.64V98.93C320.32,59.91,287.21,28.29,246.36,28.29Z"
                    style={{
                        fill: 'url(#linear-gradient-3)',
                    }}
                />
                <path
                    d="M202,188.41V160.15H65.12l44.37-42.38-20.9-20L19,164.3a13.67,13.67,0,0,0,0,20l69.62,66.5,20.9-20L65.12,188.41Z"
                    style={{
                        fill: 'url(#linear-gradient-3)',
                    }}
                />
                <path
                    d="M246.29,28c-40.81,0-73.89,31.62-73.89,70.64v14.13H202V98.65c0-23.41,19.85-42.39,44.33-42.39s44.34,19,44.34,42.39v150.7c0,23.41-19.85,42.38-44.34,42.38S202,272.76,202,249.35V235.22H172.4v14.13c0,39,33.08,70.64,73.89,70.64s73.89-31.63,73.89-70.64V98.65C320.18,59.63,287.1,28,246.29,28Z"
                    style={{
                        fill: 'url(#linear-gradient-5)',
                    }}
                />
                <path
                    d="M202,188.13V159.87H65.21l44.33-42.38-20.88-20L19.1,164a13.69,13.69,0,0,0,0,20l69.56,66.5,20.88-20L65.21,188.13Z"
                    style={{
                        fill: 'url(#linear-gradient-5)',
                    }}
                />
                <path
                    d="M246.22,27.73c-40.77,0-73.82,31.62-73.82,70.64V112.5h29.53V98.37C201.93,75,221.76,56,246.22,56s44.3,19,44.3,42.39v150.7c0,23.41-19.84,42.38-44.3,42.38s-44.29-19-44.29-42.38V234.94H172.4v14.13c0,39,33,70.64,73.82,70.64s73.83-31.63,73.83-70.64V98.37C320.05,59.35,287,27.73,246.22,27.73Z"
                    style={{
                        fill: 'url(#linear-gradient-7)',
                    }}
                />
                <path
                    d="M201.93,187.85V159.59H65.3l44.3-42.38-20.87-20L19.24,163.73a13.7,13.7,0,0,0,0,20l69.49,66.5,20.87-20L65.3,187.85Z"
                    style={{
                        fill: 'url(#linear-gradient-7)',
                    }}
                />
                <path
                    d="M246.15,27.45c-40.74,0-73.76,31.62-73.76,70.64v14.13H201.9V98.09c0-23.41,19.81-42.39,44.25-42.39s44.25,19,44.25,42.39v150.7c0,23.41-19.81,42.38-44.25,42.38s-44.25-19-44.25-42.38V234.66H172.39v14.13c0,39,33,70.64,73.76,70.64s73.76-31.63,73.76-70.64V98.09C319.91,59.07,286.89,27.45,246.15,27.45Z"
                    style={{
                        fill: 'url(#linear-gradient-9)',
                    }}
                />
                <path
                    d="M201.9,187.57V159.31H65.39l44.26-42.38L88.8,97,19.37,163.45a13.72,13.72,0,0,0,0,20l69.43,66.5,20.85-20L65.39,187.57Z"
                    style={{
                        fill: 'url(#linear-gradient-9)',
                    }}
                />
                <path
                    d="M246.08,27.17c-40.7,0-73.69,31.62-73.69,70.64v14.13h29.47V97.81c0-23.41,19.8-42.39,44.22-42.39s44.21,19,44.21,42.39v150.7c0,23.41-19.79,42.38-44.21,42.38s-44.22-19-44.22-42.38V234.38H172.39v14.13c0,39,33,70.64,73.69,70.64s73.69-31.63,73.69-70.64V97.81C319.77,58.79,286.78,27.17,246.08,27.17Z"
                    style={{
                        fill: 'url(#linear-gradient-11)',
                    }}
                />
                <path
                    d="M201.86,187.29V159H65.49l44.21-42.38-20.83-20L19.5,163.17a13.72,13.72,0,0,0,0,20l69.37,66.5,20.83-20L65.49,187.29Z"
                    style={{
                        fill: 'url(#linear-gradient-11)',
                    }}
                />
                <path
                    d="M246,26.89c-40.66,0-73.63,31.62-73.63,70.64v14.13h29.45V97.53c0-23.41,19.78-42.39,44.18-42.39s44.17,19,44.17,42.39v150.7c0,23.41-19.77,42.38-44.17,42.38s-44.18-19-44.18-42.38V234.1H172.38v14.13c0,39,33,70.64,73.63,70.64s73.62-31.63,73.62-70.64V97.53C319.63,58.51,286.67,26.89,246,26.89Z"
                    style={{
                        fill: 'url(#linear-gradient-13)',
                    }}
                />
                <path
                    d="M201.83,187V158.75H65.58l44.17-42.38-20.81-20-69.3,66.49a13.72,13.72,0,0,0,0,20l69.3,66.5,20.81-20L65.58,187Z"
                    style={{
                        fill: 'url(#linear-gradient-13)',
                    }}
                />
                <path
                    d="M245.94,26.61c-40.63,0-73.56,31.62-73.56,70.64v14.13H201.8V97.25c0-23.41,19.76-42.39,44.14-42.39s44.13,19,44.13,42.39V248c0,23.41-19.76,42.38-44.13,42.38s-44.14-19-44.14-42.38V233.82H172.38V248c0,39,32.93,70.64,73.56,70.64S319.5,287,319.5,248V97.25C319.5,58.23,286.56,26.61,245.94,26.61Z"
                    style={{
                        fill: 'url(#linear-gradient-15)',
                    }}
                />
                <path
                    d="M201.8,186.73V158.47H65.67l44.13-42.38L89,96.12,19.77,162.61a13.73,13.73,0,0,0,0,20L89,249.08l20.79-20L65.67,186.73Z"
                    style={{
                        fill: 'url(#linear-gradient-15)',
                    }}
                />
                <path
                    d="M245.87,26.33c-40.59,0-73.5,31.62-73.5,70.64V111.1h29.4V97c0-23.41,19.74-42.39,44.1-42.39S290,73.56,290,97v150.7c0,23.41-19.74,42.38-44.09,42.38s-44.1-19-44.1-42.38V233.54h-29.4v14.13c0,39,32.91,70.64,73.5,70.64s73.49-31.63,73.49-70.64V97C319.36,58,286.46,26.33,245.87,26.33Z"
                    style={{
                        fill: 'url(#linear-gradient-17)',
                    }}
                />
                <path
                    d="M201.77,186.45V158.19h-136l44.1-42.38-20.78-20L19.9,162.33a13.75,13.75,0,0,0,0,20l69.18,66.5,20.78-20-44.1-42.38Z"
                    style={{
                        fill: 'url(#linear-gradient-17)',
                    }}
                />
                <path
                    d="M245.8,26.05c-40.56,0-73.43,31.62-73.43,70.64v14.13h29.37V96.69c0-23.41,19.73-42.39,44.06-42.39s44.05,19,44.05,42.39v150.7c0,23.41-19.72,42.38-44.05,42.38s-44.06-19-44.06-42.38V233.26H172.37v14.13c0,39,32.87,70.64,73.43,70.64s73.42-31.63,73.42-70.64V96.69C319.22,57.67,286.35,26.05,245.8,26.05Z"
                    style={{
                        fill: 'url(#linear-gradient-19)',
                    }}
                />
                <path
                    d="M201.74,186.17V157.91H65.85l44.06-42.38-20.76-20L20,162.05a13.77,13.77,0,0,0,0,20l69.12,66.5,20.76-20L65.85,186.17Z"
                    style={{
                        fill: 'url(#linear-gradient-19)',
                    }}
                />
                <path
                    d="M245.73,25.77c-40.52,0-73.36,31.62-73.36,70.64v14.13h29.34V96.41c0-23.41,19.71-42.39,44-42.39s44,19,44,42.39v150.7c0,23.41-19.7,42.38-44,42.38s-44-19-44-42.38V233H172.37v14.13c0,39,32.84,70.64,73.36,70.64s73.36-31.63,73.36-70.64V96.41C319.09,57.39,286.24,25.77,245.73,25.77Z"
                    style={{
                        fill: 'url(#linear-gradient-21)',
                    }}
                />
                <path
                    d="M201.71,185.89V157.63H65.94l44-42.38-20.74-20-69,66.49a13.75,13.75,0,0,0,0,20l69.05,66.5,20.74-20-44-42.38Z"
                    style={{
                        fill: 'url(#linear-gradient-21)',
                    }}
                />
                <path
                    d="M245.66,25.49c-40.48,0-73.3,31.62-73.3,70.64v14.13h29.32V96.13c0-23.41,19.69-42.39,44-42.39s44,19,44,42.39v150.7c0,23.41-19.69,42.38-44,42.38s-44-19-44-42.38V232.7H172.36v14.13c0,39,32.82,70.64,73.3,70.64S319,285.84,319,246.83V96.13C319,57.11,286.14,25.49,245.66,25.49Z"
                    style={{
                        fill: 'url(#linear-gradient-23)',
                    }}
                />
                <path
                    d="M201.68,185.61V157.35H66L110,115,89.29,95l-69,66.49a13.77,13.77,0,0,0,0,20l69,66.5L110,228,66,185.61Z"
                    style={{
                        fill: 'url(#linear-gradient-23)',
                    }}
                />
                <path
                    d="M245.59,25.21c-40.45,0-73.23,31.62-73.23,70.64V110h29.29V95.85c0-23.41,19.67-42.39,43.94-42.39s43.93,19,43.93,42.39v150.7c0,23.41-19.67,42.38-43.93,42.38s-43.94-19-43.94-42.38V232.42H172.36v14.13c0,39,32.78,70.64,73.23,70.64s73.23-31.63,73.23-70.64V95.85C318.82,56.83,286,25.21,245.59,25.21Z"
                    style={{
                        fill: 'url(#linear-gradient-25)',
                    }}
                />
                <path
                    d="M201.65,185.33V157.07H66.13l43.93-42.38-20.7-20L20.43,161.21a13.78,13.78,0,0,0,0,20l68.93,66.5,20.7-20L66.13,185.33Z"
                    style={{
                        fill: 'url(#linear-gradient-25)',
                    }}
                />
                <path
                    d="M245.52,24.93c-40.41,0-73.17,31.62-73.17,70.64V109.7h29.27V95.57c0-23.41,19.65-42.39,43.9-42.39s43.89,19,43.89,42.39v150.7c0,23.41-19.65,42.38-43.89,42.38s-43.9-19-43.9-42.38V232.14H172.35v14.13c0,39,32.76,70.64,73.17,70.64s73.16-31.63,73.16-70.64V95.57C318.68,56.55,285.92,24.93,245.52,24.93Z"
                    style={{
                        fill: 'url(#linear-gradient-27)',
                    }}
                />
                <path
                    d="M201.62,185.05V156.79H66.22l43.89-42.38-20.68-20L20.56,160.93a13.8,13.8,0,0,0,0,20l68.87,66.5,20.68-20L66.22,185.05Z"
                    style={{
                        fill: 'url(#linear-gradient-27)',
                    }}
                />
                <path
                    d="M245.45,24.65c-40.38,0-73.1,31.62-73.1,70.64v14.13h29.24V95.29c0-23.41,19.63-42.39,43.86-42.39s43.85,19,43.85,42.39V246c0,23.41-19.63,42.38-43.85,42.38s-43.86-19-43.86-42.38V231.86H172.35V246c0,39,32.72,70.64,73.1,70.64S318.54,285,318.54,246V95.29C318.54,56.27,285.82,24.65,245.45,24.65Z"
                    style={{
                        fill: 'url(#linear-gradient-29)',
                    }}
                />
                <path
                    d="M201.59,184.77V156.51H66.31l43.86-42.38-20.67-20L20.7,160.65a13.78,13.78,0,0,0,0,20l68.8,66.5,20.67-20L66.31,184.77Z"
                    style={{
                        fill: 'url(#linear-gradient-29)',
                    }}
                />
                <path
                    d="M245.38,24.37c-40.34,0-73,31.62-73,70.64v14.13h29.22V95c0-23.41,19.61-42.39,43.82-42.39s43.81,19,43.81,42.39v150.7c0,23.41-19.61,42.38-43.81,42.38s-43.82-19-43.82-42.38V231.58H172.34v14.13c0,39,32.7,70.64,73,70.64s73-31.63,73-70.64V95C318.41,56,285.71,24.37,245.38,24.37Z"
                    style={{
                        fill: 'url(#linear-gradient-31)',
                    }}
                />
                <path
                    d="M201.56,184.49V156.23H66.4l43.82-42.39-20.65-20L20.83,160.37a13.8,13.8,0,0,0,0,20l68.74,66.5,20.65-20L66.4,184.49Z"
                    style={{
                        fill: 'url(#linear-gradient-31)',
                    }}
                />
                <path
                    d="M245.31,24.09c-40.3,0-73,31.62-73,70.64v14.13h29.19V94.73c0-23.41,19.6-42.39,43.78-42.39s43.78,19,43.78,42.39v150.7c0,23.41-19.61,42.38-43.78,42.38s-43.78-19-43.78-42.38V231.3H172.34v14.13c0,39,32.67,70.64,73,70.64s73-31.63,73-70.64V94.73C318.27,55.71,285.6,24.09,245.31,24.09Z"
                    style={{
                        fill: 'url(#linear-gradient-33)',
                    }}
                />
                <path
                    d="M201.53,184.21V156h-135l43.78-42.39-20.63-20L21,160.09a13.82,13.82,0,0,0,0,20l68.68,66.5,20.63-20L66.49,184.21Z"
                    style={{
                        fill: 'url(#linear-gradient-33)',
                    }}
                />
                <path
                    d="M245.24,23.81c-40.27,0-72.91,31.62-72.91,70.64v14.13H201.5V94.45c0-23.41,19.58-42.39,43.74-42.39S289,71,289,94.45v150.7c0,23.41-19.59,42.38-43.74,42.38s-43.74-19-43.74-42.38V231H172.33v14.13c0,39,32.64,70.64,72.91,70.64s72.9-31.63,72.9-70.64V94.45C318.14,55.43,285.5,23.81,245.24,23.81Z"
                    style={{
                        fill: 'url(#linear-gradient-35)',
                    }}
                />
                <path
                    d="M201.5,183.93V155.67H66.58l43.74-42.39-20.61-20L21.09,159.81a13.83,13.83,0,0,0,0,20l68.62,66.5,20.61-20L66.58,183.93Z"
                    style={{
                        fill: 'url(#linear-gradient-35)',
                    }}
                />
                <path
                    d="M245.17,23.53c-40.23,0-72.84,31.62-72.84,70.64V108.3h29.13V94.17c0-23.41,19.57-42.39,43.71-42.39s43.7,19,43.7,42.39v150.7c0,23.41-19.57,42.38-43.7,42.38s-43.71-19-43.71-42.38V230.74H172.33v14.13c0,39,32.61,70.64,72.84,70.64S318,283.88,318,244.87V94.17C318,55.15,285.39,23.53,245.17,23.53Z"
                    style={{
                        fill: 'url(#linear-gradient-37)',
                    }}
                />
                <path
                    d="M201.46,183.65V155.39H66.67L110.37,113,89.78,93,21.22,159.53a13.83,13.83,0,0,0,0,20L89.78,246l20.59-20-43.7-42.38Z"
                    style={{
                        fill: 'url(#linear-gradient-37)',
                    }}
                />
                <path
                    d="M245.1,23.25c-40.19,0-72.77,31.62-72.77,70.64V108h29.1V93.89c0-23.41,19.55-42.39,43.67-42.39s43.66,19,43.66,42.39v150.7C288.76,268,269.21,287,245.1,287s-43.67-19-43.67-42.38V230.46h-29.1v14.13c0,39,32.58,70.64,72.77,70.64s72.77-31.63,72.77-70.64V93.89C317.87,54.87,285.29,23.25,245.1,23.25Z"
                    style={{
                        fill: 'url(#linear-gradient-39)',
                    }}
                />
                <path
                    d="M201.43,183.37V155.11H66.76l43.66-42.39-20.57-20-68.5,66.49a13.85,13.85,0,0,0,0,20l68.5,66.5,20.57-20L66.76,183.37Z"
                    style={{
                        fill: 'url(#linear-gradient-39)',
                    }}
                />
                <path
                    d="M245,23c-40.16,0-72.71,31.62-72.71,70.64v14.13H201.4V93.61c0-23.41,19.53-42.39,43.63-42.39s43.62,19,43.62,42.39v150.7c0,23.41-19.53,42.38-43.62,42.38s-43.63-19-43.63-42.38V230.18H172.32v14.13c0,39,32.55,70.64,72.71,70.64s72.7-31.63,72.7-70.64V93.61C317.73,54.59,285.18,23,245,23Z"
                    style={{
                        fill: 'url(#linear-gradient-41)',
                    }}
                />
                <path
                    d="M201.4,183.09V154.83H66.85l43.63-42.39-20.56-20L21.49,159a13.85,13.85,0,0,0,0,20l68.43,66.5,20.56-20L66.85,183.09Z"
                    style={{
                        fill: 'url(#linear-gradient-41)',
                    }}
                />
                <path
                    d="M245,22.69c-40.12,0-72.64,31.62-72.64,70.64v14.13h29.05V93.33c0-23.41,19.52-42.39,43.59-42.39s43.58,19,43.58,42.39V244c0,23.41-19.51,42.38-43.58,42.38s-43.59-19-43.59-42.38V229.9H172.32V244c0,39,32.52,70.64,72.64,70.64S317.59,283,317.59,244V93.33C317.59,54.31,285.07,22.69,245,22.69Z"
                    style={{
                        fill: 'url(#linear-gradient-43)',
                    }}
                />
                <path
                    d="M201.37,182.81V154.55H66.94l43.59-42.39L90,92.2,21.62,158.69a13.85,13.85,0,0,0,0,20L90,245.16l20.54-20L66.94,182.81Z"
                    style={{
                        fill: 'url(#linear-gradient-43)',
                    }}
                />
                <path
                    d="M244.89,22.41c-40.08,0-72.58,31.62-72.58,70.64v14.12h29V93.05c0-23.41,19.5-42.39,43.55-42.39s43.54,19,43.54,42.39v150.7c0,23.41-19.49,42.38-43.54,42.38s-43.55-19-43.55-42.38V229.62h-29v14.13c0,39,32.5,70.64,72.58,70.64s72.57-31.63,72.57-70.64V93.05C317.46,54,285,22.41,244.89,22.41Z"
                    style={{
                        fill: 'url(#linear-gradient-45)',
                    }}
                />
                <path
                    d="M201.34,182.53V154.27H67l43.55-42.39-20.52-20L21.75,158.41a13.87,13.87,0,0,0,0,20l68.31,66.5,20.52-20L67,182.53Z"
                    style={{
                        fill: 'url(#linear-gradient-45)',
                    }}
                />
                <path
                    d="M244.82,22.13c-40,0-72.51,31.62-72.51,70.64v14.12h29V92.77c0-23.41,19.48-42.39,43.51-42.39s43.5,19,43.5,42.39v150.7c0,23.41-19.48,42.38-43.5,42.38s-43.51-19-43.51-42.38V229.34h-29v14.13c0,39,32.46,70.64,72.51,70.64s72.5-31.63,72.5-70.64V92.77C317.32,53.75,284.86,22.13,244.82,22.13Z"
                    style={{
                        fill: 'url(#linear-gradient-47)',
                    }}
                />
                <path
                    d="M201.31,182.24V154H67.12l43.51-42.39-20.5-20L21.88,158.13a13.87,13.87,0,0,0,0,20l68.25,66.5,20.5-20L67.12,182.24Z"
                    style={{
                        fill: 'url(#linear-gradient-47)',
                    }}
                />
                <path
                    d="M244.75,21.85c-40,0-72.45,31.62-72.45,70.64v14.12h29V92.49c0-23.41,19.46-42.39,43.47-42.39s43.46,19,43.46,42.39v150.7c0,23.41-19.46,42.38-43.46,42.38s-43.47-19-43.47-42.38V229.06h-29v14.13c0,39,32.44,70.64,72.45,70.64s72.44-31.63,72.44-70.64V92.49C317.19,53.47,284.76,21.85,244.75,21.85Z"
                    style={{
                        fill: 'url(#linear-gradient-49)',
                    }}
                />
                <path
                    d="M201.28,182V153.71H67.21l43.47-42.39-20.48-20L22,157.85a13.88,13.88,0,0,0,0,20l68.19,66.5,20.48-20L67.21,182Z"
                    style={{
                        fill: 'url(#linear-gradient-49)',
                    }}
                />
                <path
                    d="M244.68,21.57c-40,0-72.38,31.62-72.38,70.64v14.12h28.95V92.21c0-23.41,19.44-42.39,43.43-42.39s43.42,19,43.42,42.39v150.7c0,23.41-19.44,42.38-43.42,42.38s-43.43-19-43.43-42.38V228.78H172.3v14.13c0,39,32.4,70.64,72.38,70.64s72.38-31.63,72.38-70.64V92.21C317.06,53.19,284.65,21.57,244.68,21.57Z"
                    style={{
                        fill: 'url(#linear-gradient-51)',
                    }}
                />
                <path
                    d="M201.25,181.68V153.43H67.3L110.73,111l-20.46-20L22.14,157.57a13.9,13.9,0,0,0,0,20L90.27,244l20.46-20L67.3,181.68Z"
                    style={{
                        fill: 'url(#linear-gradient-51)',
                    }}
                />
                <path
                    d="M244.61,21.29c-39.94,0-72.31,31.62-72.31,70.64v14.12h28.92V91.93c0-23.41,19.43-42.39,43.39-42.39S288,68.52,288,91.93v150.7C288,266,268.57,285,244.61,285s-43.39-19-43.39-42.38V228.5H172.3v14.13c0,39,32.37,70.64,72.31,70.64s72.31-31.63,72.31-70.64V91.93C316.92,52.91,284.55,21.29,244.61,21.29Z"
                    style={{
                        fill: 'url(#linear-gradient-53)',
                    }}
                />
                <path
                    d="M201.22,181.4V153.15H67.39l43.39-42.39-20.44-20L22.27,157.29a13.9,13.9,0,0,0,0,20l68.07,66.5,20.44-20L67.39,181.4Z"
                    style={{
                        fill: 'url(#linear-gradient-53)',
                    }}
                />
                <path
                    d="M244.54,21c-39.9,0-72.25,31.62-72.25,70.64v14.12h28.9V91.65c0-23.41,19.41-42.39,43.35-42.39s43.35,19,43.35,42.39v150.7c0,23.4-19.41,42.38-43.35,42.38s-43.35-19-43.35-42.38V228.22h-28.9v14.13c0,39,32.35,70.64,72.25,70.64s72.25-31.63,72.25-70.64V91.65C316.79,52.63,284.44,21,244.54,21Z"
                    style={{
                        fill: 'url(#linear-gradient-55)',
                    }}
                />
                <path
                    d="M201.19,181.12V152.87H67.48l43.35-42.39-20.42-20L22.4,157a13.92,13.92,0,0,0,0,20l68,66.5,20.42-20L67.48,181.12Z"
                    style={{
                        fill: 'url(#linear-gradient-55)',
                    }}
                />
                <path
                    d="M244.47,20.73c-39.87,0-72.18,31.62-72.18,70.64v14.12h28.87V91.37C201.16,68,220.55,49,244.47,49s43.31,19,43.31,42.39v150.7c0,23.4-19.39,42.38-43.31,42.38s-43.31-19-43.31-42.38V227.94H172.29v14.13c0,39,32.31,70.64,72.18,70.64s72.18-31.63,72.18-70.64V91.37C316.65,52.35,284.34,20.73,244.47,20.73Z"
                    style={{
                        fill: 'url(#linear-gradient-57)',
                    }}
                />
                <path
                    d="M201.16,180.84V152.59H67.57l43.31-42.39-20.4-20L22.53,156.73a13.92,13.92,0,0,0,0,20l68,66.5,20.4-20L67.57,180.84Z"
                    style={{
                        fill: 'url(#linear-gradient-57)',
                    }}
                />
                <path
                    d="M244.4,20.44c-39.83,0-72.12,31.63-72.12,70.65v14.12h28.85V91.09c0-23.41,19.37-42.39,43.27-42.39s43.27,19,43.27,42.39v150.7c0,23.4-19.37,42.38-43.27,42.38s-43.27-19-43.27-42.38V227.66H172.28v14.13c0,39,32.29,70.64,72.12,70.64s72.12-31.63,72.12-70.64V91.09C316.52,52.07,284.23,20.44,244.4,20.44Z"
                    style={{
                        fill: 'url(#linear-gradient-59)',
                    }}
                />
                <path
                    d="M201.13,180.56V152.31H67.66l43.27-42.39L90.55,90,22.66,156.45a13.93,13.93,0,0,0,0,20l67.89,66.5,20.38-20L67.66,180.56Z"
                    style={{
                        fill: 'url(#linear-gradient-59)',
                    }}
                />
                <path
                    d="M244.33,20.16c-39.79,0-72.05,31.63-72.05,70.65v14.12H201.1V90.81c0-23.41,19.36-42.39,43.23-42.39s43.23,19,43.23,42.39v150.7c0,23.4-19.35,42.38-43.23,42.38s-43.23-19-43.23-42.38V227.38H172.28v14.13c0,39,32.26,70.64,72.05,70.64s72-31.63,72-70.64V90.81C316.38,51.79,284.13,20.16,244.33,20.16Z"
                    style={{
                        fill: 'url(#linear-gradient-61)',
                    }}
                />
                <path
                    d="M201.1,180.28V152H67.75L111,109.64l-20.37-20L22.79,156.17a13.93,13.93,0,0,0,0,20l67.83,66.5,20.37-20L67.75,180.28Z"
                    style={{
                        fill: 'url(#linear-gradient-61)',
                    }}
                />
                <path
                    d="M244.26,19.88c-39.76,0-72,31.63-72,70.65v14.12h28.8V90.53c0-23.41,19.34-42.39,43.19-42.39s43.2,19,43.2,42.39v150.7c0,23.4-19.34,42.38-43.2,42.38s-43.19-19-43.19-42.38V227.1h-28.8v14.13c0,39,32.23,70.64,72,70.64s72-31.63,72-70.64V90.53C316.25,51.51,284,19.88,244.26,19.88Z"
                    style={{
                        fill: 'url(#linear-gradient-63)',
                    }}
                />
                <path
                    d="M201.07,180V151.75H67.84L111,109.36l-20.35-20L22.92,155.89a14,14,0,0,0,0,20l67.77,66.5,20.35-20L67.84,180Z"
                    style={{
                        fill: 'url(#linear-gradient-63)',
                    }}
                />
                <path
                    d="M244.19,19.6c-39.72,0-71.92,31.63-71.92,70.65v14.12H201V90.25c0-23.41,19.32-42.39,43.15-42.39s43.16,19,43.16,42.39V241c0,23.4-19.32,42.38-43.16,42.38S201,264.35,201,241V226.82H172.27V241c0,39,32.2,70.64,71.92,70.64S316.12,280,316.12,241V90.25C316.12,51.23,283.92,19.6,244.19,19.6Z"
                    style={{
                        fill: 'url(#linear-gradient-65)',
                    }}
                />
                <path
                    d="M201,179.72V151.47H67.93l43.16-42.39-20.33-20L23.05,155.61a14,14,0,0,0,0,20l67.71,66.5,20.33-20L67.93,179.72Z"
                    style={{
                        fill: 'url(#linear-gradient-65)',
                    }}
                />
                <path
                    d="M244.12,19.32C204.44,19.32,172.27,51,172.27,90v14.12H201V90c0-23.41,19.3-42.39,43.11-42.39s43.12,19,43.12,42.39v150.7c0,23.4-19.3,42.38-43.12,42.38S201,264.07,201,240.67V226.54H172.27v14.13c0,39,32.17,70.64,71.85,70.64S316,279.68,316,240.67V90C316,51,283.81,19.32,244.12,19.32Z"
                    style={{
                        fill: 'url(#linear-gradient-67)',
                    }}
                />
                <path
                    d="M201,179.44V151.19H68l43.12-42.39-20.31-20L23.18,155.33a14,14,0,0,0,0,20l67.65,66.5,20.31-20L68,179.44Z"
                    style={{
                        fill: 'url(#linear-gradient-67)',
                    }}
                />
                <path
                    d="M244.06,19c-39.66,0-71.8,31.63-71.8,70.65v14.12H201V89.69c0-23.41,19.28-42.39,43.08-42.39s43.07,19,43.07,42.39v150.7c0,23.4-19.28,42.38-43.07,42.38S201,263.79,201,240.39V226.26H172.26v14.13c0,39,32.14,70.64,71.8,70.64s71.79-31.63,71.79-70.64V89.69C315.85,50.67,283.71,19,244.06,19Z"
                    style={{
                        fill: 'url(#linear-gradient-69)',
                    }}
                />
                <path
                    d="M201,179.16V150.91H68.11l43.08-42.39-20.3-20L23.31,155.05a14,14,0,0,0,0,20l67.58,66.5,20.3-20L68.11,179.16Z"
                    style={{
                        fill: 'url(#linear-gradient-69)',
                    }}
                />
                <path
                    d="M244,18.76c-39.62,0-71.73,31.63-71.73,70.65v14.12H201V89.41C201,66,220.22,47,244,47s43,19,43,42.39v150.7c0,23.4-19.26,42.38-43,42.38s-43-19-43-42.38V226H172.26v14.13c0,39,32.11,70.64,71.73,70.64s71.73-31.63,71.73-70.64V89.41C315.72,50.39,283.6,18.76,244,18.76Z"
                    style={{
                        fill: 'url(#linear-gradient-71)',
                    }}
                />
                <path
                    d="M201,178.88V150.63H68.2l43-42.39L91,88.28,23.44,154.77a14,14,0,0,0,0,20L91,241.24l20.28-20-43-42.39Z"
                    style={{
                        fill: 'url(#linear-gradient-71)',
                    }}
                />
                <path
                    d="M243.92,18.48c-39.58,0-71.67,31.63-71.67,70.65v14.12h28.67V89.13c0-23.41,19.25-42.39,43-42.39s43,19,43,42.39v150.7c0,23.4-19.25,42.38-43,42.38s-43-19-43-42.38V225.7H172.25v14.13c0,39,32.09,70.64,71.67,70.64s71.66-31.63,71.66-70.64V89.13C315.58,50.11,283.5,18.48,243.92,18.48Z"
                    style={{
                        fill: 'url(#linear-gradient-73)',
                    }}
                />
                <path
                    d="M200.92,178.6V150.35H68.29l43-42.39L91,88,23.57,154.49a14,14,0,0,0,0,20L91,241l20.26-20-43-42.39Z"
                    style={{
                        fill: 'url(#linear-gradient-73)',
                    }}
                />
                <path
                    d="M243.85,18.2c-39.54,0-71.6,31.63-71.6,70.65V103h28.64V88.85c0-23.41,19.23-42.39,43-42.39s43,19,43,42.39v150.7c0,23.4-19.23,42.38-43,42.38s-43-19-43-42.38V225.42H172.25v14.13c0,39,32.06,70.64,71.6,70.64s71.6-31.63,71.6-70.64V88.85C315.45,49.83,283.39,18.2,243.85,18.2Z"
                    style={{
                        fill: 'url(#linear-gradient-75)',
                    }}
                />
                <path
                    d="M200.89,178.32V150.07H68.38l43-42.39-20.24-20-67.4,66.5a14,14,0,0,0,0,20l67.4,66.5,20.24-20-43-42.39Z"
                    style={{
                        fill: 'url(#linear-gradient-75)',
                    }}
                />
                <path
                    d="M243.78,17.92c-39.51,0-71.54,31.63-71.54,70.64v14.13h28.62V88.56c0-23.4,19.22-42.38,42.92-42.38s42.92,19,42.92,42.38V239.27c0,23.4-19.21,42.38-42.92,42.38s-42.92-19-42.92-42.38V225.14H172.24v14.13c0,39,32,70.64,71.54,70.64s71.54-31.63,71.54-70.64V88.56C315.32,49.55,283.29,17.92,243.78,17.92Z"
                    style={{
                        fill: 'url(#linear-gradient-77)',
                    }}
                />
                <path
                    d="M200.86,178V149.79H68.47l42.92-42.39-20.22-20-67.34,66.5a14,14,0,0,0,0,20l67.34,66.5,20.22-20L68.47,178Z"
                    style={{
                        fill: 'url(#linear-gradient-77)',
                    }}
                />
                <path
                    d="M243.71,17.64c-39.47,0-71.47,31.63-71.47,70.64v14.13h28.59V88.28c0-23.4,19.2-42.38,42.88-42.38s42.88,19,42.88,42.38V239c0,23.4-19.19,42.38-42.88,42.38s-42.88-19-42.88-42.38V224.86H172.24V239c0,39,32,70.64,71.47,70.64S315.18,278,315.18,239V88.28C315.18,49.27,283.18,17.64,243.71,17.64Z"
                    style={{
                        fill: 'url(#linear-gradient-79)',
                    }}
                />
                <path
                    d="M200.83,177.76V149.51H68.56l42.88-42.39-20.2-20L24,153.65a14,14,0,0,0,0,20l67.28,66.5,20.2-20L68.56,177.76Z"
                    style={{
                        fill: 'url(#linear-gradient-79)',
                    }}
                />
                <path
                    d="M243.64,17.36c-39.43,0-71.4,31.63-71.4,70.64v14.13H200.8V88c0-23.4,19.18-42.38,42.84-42.38s42.85,19,42.85,42.38V238.71c0,23.4-19.18,42.38-42.85,42.38s-42.84-19-42.84-42.38V224.58H172.24v14.13c0,39,32,70.64,71.4,70.64s71.41-31.63,71.41-70.64V88C315.05,49,283.08,17.36,243.64,17.36Z"
                    style={{
                        fill: 'url(#linear-gradient-81)',
                    }}
                />
                <path
                    d="M200.8,177.48V149.23H68.65l42.84-42.39-20.18-20-67.22,66.5a14,14,0,0,0,0,20l67.22,66.5,20.18-20L68.65,177.48Z"
                    style={{
                        fill: 'url(#linear-gradient-81)',
                    }}
                />
                <path
                    d="M243.57,17.08c-39.4,0-71.34,31.63-71.34,70.64v14.13h28.54V87.72a42.81,42.81,0,0,1,85.61,0V238.43a42.81,42.81,0,0,1-85.61,0V224.3H172.23v14.13c0,39,31.94,70.64,71.34,70.64s71.35-31.63,71.35-70.64V87.72C314.92,48.71,283,17.08,243.57,17.08Z"
                    style={{
                        fill: 'url(#linear-gradient-83)',
                    }}
                />
                <path
                    d="M200.77,177.2V149h-132l42.8-42.39-20.16-20-67.16,66.5a14,14,0,0,0,0,20l67.16,66.5,20.16-20L68.74,177.2Z"
                    style={{
                        fill: 'url(#linear-gradient-83)',
                    }}
                />
                <path
                    d="M243.51,16.8c-39.37,0-71.28,31.63-71.28,70.64v14.13h28.51V87.44a42.77,42.77,0,0,1,85.53,0V238.15a42.77,42.77,0,0,1-85.53,0V224H172.23v14.13c0,39,31.91,70.64,71.28,70.64s71.28-31.63,71.28-70.64V87.44C314.79,48.43,282.87,16.8,243.51,16.8Z"
                    style={{
                        fill: 'url(#linear-gradient-85)',
                    }}
                />
                <path
                    d="M200.74,176.92V148.67H68.82l42.77-42.39-20.15-20-67.09,66.5a14,14,0,0,0,0,20l67.09,66.5,20.15-20L68.82,176.92Z"
                    style={{
                        fill: 'url(#linear-gradient-85)',
                    }}
                />
                <path
                    d="M243.44,16.52c-39.33,0-71.22,31.63-71.22,70.64v14.13h28.49V87.16a42.73,42.73,0,0,1,85.46,0V237.87a42.73,42.73,0,0,1-85.46,0V223.74H172.22v14.13c0,39,31.89,70.64,71.22,70.64s71.21-31.63,71.21-70.64V87.16C314.65,48.15,282.77,16.52,243.44,16.52Z"
                    style={{
                        fill: 'url(#linear-gradient-87)',
                    }}
                />
                <path
                    d="M200.71,176.64V148.39H68.91L111.64,106,91.51,86l-67,66.5a14,14,0,0,0,0,20l67,66.5,20.13-20L68.91,176.64Z"
                    style={{
                        fill: 'url(#linear-gradient-87)',
                    }}
                />
                <path
                    d="M243.37,16.24c-39.3,0-71.15,31.63-71.15,70.64V101h28.46V86.88a42.69,42.69,0,0,1,85.38,0V237.59a42.69,42.69,0,0,1-85.38,0V223.46H172.22v14.13c0,39,31.85,70.64,71.15,70.64s71.15-31.63,71.15-70.64V86.88C314.52,47.87,282.67,16.24,243.37,16.24Z"
                    style={{
                        fill: 'url(#linear-gradient-89)',
                    }}
                />
                <path
                    d="M200.68,176.36V148.11H69l42.69-42.39-20.11-20-67,66.5a14.06,14.06,0,0,0,0,20l67,66.5,20.11-20L69,176.36Z"
                    style={{
                        fill: 'url(#linear-gradient-89)',
                    }}
                />
                <path
                    d="M243.3,16C204,16,172.21,47.59,172.21,86.6v14.13h28.44V86.6a42.65,42.65,0,0,1,85.3,0V237.3a42.65,42.65,0,0,1-85.3,0V223.18H172.21V237.3c0,39,31.83,70.65,71.09,70.65s71.09-31.63,71.09-70.65V86.6C314.39,47.59,282.56,16,243.3,16Z"
                    style={{
                        fill: 'url(#linear-gradient-91)',
                    }}
                />
                <path
                    d="M200.65,176.08V147.83H69.09l42.65-42.39-20.09-20L24.73,152a14.08,14.08,0,0,0,0,20l66.92,66.5,20.09-20L69.09,176.08Z"
                    style={{
                        fill: 'url(#linear-gradient-91)',
                    }}
                />
                <path
                    d="M243.23,15.68c-39.22,0-71,31.63-71,70.64v14.13h28.41V86.32a42.62,42.62,0,0,1,85.23,0V237a42.62,42.62,0,0,1-85.23,0V222.9H172.21V237c0,39,31.8,70.65,71,70.65s71-31.63,71-70.65V86.32C314.26,47.31,282.46,15.68,243.23,15.68Z"
                    style={{
                        fill: 'url(#linear-gradient-93)',
                    }}
                />
                <path
                    d="M200.62,175.8V147.55H69.18l42.61-42.39-20.07-20-66.86,66.5a14.08,14.08,0,0,0,0,20l66.86,66.49,20.07-20L69.18,175.8Z"
                    style={{
                        fill: 'url(#linear-gradient-93)',
                    }}
                />
                <path
                    d="M243.17,15.4a70.8,70.8,0,0,0-71,70.64v14.13h28.38V86a42.58,42.58,0,0,1,85.15,0v150.7a42.58,42.58,0,0,1-85.15,0V222.62H172.21v14.12a71,71,0,0,0,141.91,0V86A70.8,70.8,0,0,0,243.17,15.4Z"
                    style={{
                        fill: 'url(#linear-gradient-95)',
                    }}
                />
                <path
                    d="M200.59,175.52V147.27H69.27l42.57-42.39-20-20L25,151.41a14.08,14.08,0,0,0,0,20l66.8,66.49,20-20L69.27,175.52Z"
                    style={{
                        fill: 'url(#linear-gradient-95)',
                    }}
                />
                <path
                    d="M243.1,15.12a70.77,70.77,0,0,0-70.9,70.64V99.89h28.36V85.76a42.54,42.54,0,0,1,85.07,0v150.7a42.54,42.54,0,0,1-85.07,0V222.34H172.2v14.12a70.9,70.9,0,0,0,141.79,0V85.76A70.77,70.77,0,0,0,243.1,15.12Z"
                    style={{
                        fill: 'url(#linear-gradient-97)',
                    }}
                />
                <path
                    d="M200.56,175.24V147H69.36l42.53-42.39-20-20-66.73,66.5a14.09,14.09,0,0,0,0,20l66.73,66.49,20-20L69.36,175.24Z"
                    style={{
                        fill: 'url(#linear-gradient-97)',
                    }}
                />
                <path
                    d="M243,14.84A70.74,70.74,0,0,0,172.2,85.48V99.61h28.33V85.48a42.5,42.5,0,0,1,85,0v150.7a42.5,42.5,0,0,1-85,0V222.06H172.2v14.12a70.83,70.83,0,0,0,141.66,0V85.48A70.74,70.74,0,0,0,243,14.84Z"
                    style={{
                        fill: 'url(#linear-gradient-99)',
                    }}
                />
                <path
                    d="M200.53,175V146.71H69.44l42.5-42.39-20-20-66.68,66.5a14.11,14.11,0,0,0,0,20l66.68,66.49,20-20L69.44,175Z"
                    style={{
                        fill: 'url(#linear-gradient-99)',
                    }}
                />
                <path
                    d="M243,14.56A70.71,70.71,0,0,0,172.19,85.2V99.33H200.5V85.2a42.46,42.46,0,0,1,84.92,0V235.9a42.46,42.46,0,0,1-84.92,0V221.78H172.19V235.9a70.77,70.77,0,0,0,141.54,0V85.2A70.7,70.7,0,0,0,243,14.56Z"
                    style={{
                        fill: 'url(#linear-gradient-101)',
                    }}
                />
                <path
                    d="M200.5,174.68V146.43h-131L112,104l-20-20-66.62,66.5a14.11,14.11,0,0,0,0,20L92,237l20-20L69.53,174.68Z"
                    style={{
                        fill: 'url(#linear-gradient-101)',
                    }}
                />
                <path
                    d="M242.89,14.28a70.67,70.67,0,0,0-70.7,70.64V99.05h28.28V84.92a42.43,42.43,0,0,1,84.85,0v150.7a42.43,42.43,0,0,1-84.85,0V221.5H172.19v14.12a70.71,70.71,0,0,0,141.41,0V84.92A70.68,70.68,0,0,0,242.89,14.28Z"
                    style={{
                        fill: 'url(#linear-gradient-103)',
                    }}
                />
                <path
                    d="M200.47,174.4V146.15H69.62L112,103.76l-20-20L25.5,150.29a14.13,14.13,0,0,0,0,20l66.56,66.49,20-20L69.62,174.4Z"
                    style={{
                        fill: 'url(#linear-gradient-103)',
                    }}
                />
                <path
                    d="M242.83,14a70.65,70.65,0,0,0-70.65,70.64V98.77h28.26V84.64a42.39,42.39,0,0,1,84.77,0v150.7a42.39,42.39,0,1,1-84.77,0V221.22H172.18v14.12a70.65,70.65,0,1,0,141.29,0V84.64A70.64,70.64,0,0,0,242.83,14Z"
                    style={{
                        fill: 'url(#linear-gradient-105)',
                    }}
                />
                <path
                    d="M200.44,174.12V145.87H69.71l42.38-42.39-20-20L25.63,150a14.13,14.13,0,0,0,0,20l66.49,66.49,20-20L69.71,174.12Z"
                    style={{
                        fill: 'url(#linear-gradient-105)',
                    }}
                />
                <path
                    d="M242.83,14a70.65,70.65,0,0,0-70.65,70.64V98.77h28.26V84.64a42.39,42.39,0,0,1,84.77,0v150.7a42.39,42.39,0,1,1-84.77,0V221.22H172.18v14.12a70.65,70.65,0,1,0,141.29,0V84.64A70.64,70.64,0,0,0,242.83,14Z"
                    style={{
                        fill: 'url(#linear-gradient-107)',
                    }}
                />
                <path
                    d="M200.44,174.12V145.87H69.71l42.38-42.39-20-20L25.63,150a14.13,14.13,0,0,0,0,20l66.49,66.49,20-20L69.71,174.12Z"
                    style={{
                        fill: 'url(#linear-gradient-107)',
                    }}
                />
                <path
                    d="M242.83,19.72c-39,0-70.65,31-70.65,69.23V102.8h28.26V89c0-22.94,19-41.54,42.39-41.54S285.21,66,285.21,89V236.64c0,22.94-19,41.53-42.38,41.53s-42.39-18.59-42.39-41.53V222.79H172.18v13.85c0,38.23,31.63,69.23,70.65,69.23s70.64-31,70.64-69.23V89C313.47,50.72,281.84,19.72,242.83,19.72Z"
                    style={{
                        fill: 'url(#linear-gradient-109)',
                    }}
                />
                <path
                    d="M200.44,176.64V149H69.71l42.38-41.54-20-19.57L25.63,153a13.65,13.65,0,0,0,0,19.57l66.49,65.17,20-19.57L69.71,176.64Z"
                    style={{
                        fill: 'url(#linear-gradient-109)',
                    }}
                />
            </g>
        </g>
    </svg>,
    'LogOutIcon',
)
