import React from 'react'
import { createSvgIcon } from '@mui/material'

export const InventoryIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="68" height="64" viewBox="0 0 68 64" fill="none">
        <path
            d="M56.8712 16.9301C56.7074 14.7592 55.5799 13.8075 53.4176 13.9902C52.1413 14.0983 51.0306 14.6054 50.0294 15.3697C48.6923 16.3894 47.737 17.7252 46.9258 19.1727C45.2584 22.1508 44.2666 25.3881 43.3112 28.6365C42.2745 32.1599 41.3463 35.7206 39.59 38.9886C39.3907 39.3614 39.3093 39.8191 39.2737 40.246C38.8583 44.6782 38.4663 49.1123 38.0574 53.5445C38.0209 53.9388 38.2015 54.5391 37.5848 54.5754C37.4258 54.5847 37.1395 53.9779 37.0618 53.6209C36.3666 50.4573 35.7135 47.2825 35.0314 44.118C34.8442 43.24 34.3764 42.9734 33.4931 43.1533C33.1366 43.225 32.781 43.3033 32.4227 43.363C28.3674 44.0388 24.2869 43.8952 20.2092 43.7125C17.4087 43.5867 14.6521 43.1533 12.0116 42.1773C8.59358 40.9097 5.77436 38.9494 4.32312 35.4381C4.252 35.2657 3.98159 35.1744 3.80475 35.0457C3.67188 35.2536 3.45761 35.4484 3.41925 35.6721C2.79514 39.3204 2.58181 42.9827 3.24427 46.6468C3.8534 50.0239 5.20172 53.0197 7.96387 55.2326C10.129 56.9672 12.5833 58.1641 15.2014 59.0365C20.7556 60.8896 26.4988 61.3295 32.3141 61.3622C34.9528 61.1673 37.5961 61.0145 40.23 60.7675C43.8567 60.4272 47.1625 59.1829 50.138 57.0781C51.6257 56.0202 52.3556 54.5866 52.3939 52.7578C52.4585 49.3947 52.4585 46.0251 52.6896 42.6723C52.945 38.9848 53.2585 35.2853 53.8723 31.6454C54.3401 28.849 55.3507 26.1459 56.0646 23.384C56.6045 21.2709 57.0377 19.1345 56.8712 16.9301Z"
            fill="black"
        />
        <path
            d="M12.2026 38.1142C14.3996 38.8646 16.6789 39.1964 18.9835 39.3577C22.3052 39.5907 25.6269 39.751 28.9317 39.1349C33.7271 38.241 37.0647 35.645 38.53 30.909C38.9274 29.6413 39.2569 28.3534 39.5171 27.0509C40.9337 19.7804 44.524 13.9267 50.7182 9.72942C50.8857 9.6157 50.9989 9.41996 51.2394 9.14592L49.5954 8.90636C50.3233 6.31322 49.7114 4.46576 47.6014 3.00886C45.4222 1.5035 42.9782 0.995495 40.3892 1.48579C37.6102 2.01243 35.3646 3.57932 33.2976 5.4305C32.6277 6.03172 32.0307 6.89113 31.2391 7.13907C30.4279 7.39447 29.4258 7.04586 28.5069 6.96756C26.5532 6.80444 24.5752 6.39058 22.6392 6.53319C16.0792 7.01696 11.1088 10.147 8.01168 15.9867C5.78007 20.1952 5.25235 24.7551 5.5527 29.4409C5.83341 33.8209 8.04256 36.6946 12.2026 38.1142ZM44.16 5.12011C45.4147 5.64396 45.8723 6.50523 45.6496 7.83629C45.5626 8.35082 45.3829 8.57639 44.7991 8.51114C42.6769 8.27532 40.5482 8.08983 38.3644 7.8801C39.3524 5.35593 41.8919 4.17308 44.16 5.12011ZM14.6204 26.3929C16.9849 25.0879 20.3833 25.4766 22.3866 27.2858C24.4648 29.165 24.2093 31.924 21.8252 33.393C20.7819 34.0343 19.631 34.2711 18.4249 34.3028C16.8052 34.2534 15.3082 33.8423 14.0366 32.7984C11.6833 30.8717 11.964 27.8619 14.6204 26.3929Z"
            fill="black"
        />
        <path
            d="M64.9751 38.7908C64.2209 33.8599 63.0653 29.0259 61.6478 24.2498C61.324 23.1583 60.9619 22.0779 60.5979 20.9324C60.5044 21.0563 60.4595 21.0899 60.4492 21.1318C59.6407 24.411 58.8267 27.6902 58.0407 30.975C57.9687 31.277 58.0239 31.6331 58.1015 31.9435C58.5301 33.6511 59.0737 35.3354 59.4115 37.0608C59.9729 39.9503 59.71 42.7467 57.9949 45.2699C57.4512 46.0706 56.9469 46.8974 56.436 47.7186C56.3125 47.9181 56.1478 48.1436 56.146 48.358C56.1254 50.6771 56.1329 52.9962 56.1329 55.3629C56.3453 55.265 56.5661 55.1764 56.7785 55.0637C62.7238 51.9933 65.994 45.447 64.9751 38.7908Z"
            fill="black"
        />
        <path
            d="M32.2225 63.999C27.0921 63.971 20.3702 63.6737 13.8831 61.6063C10.7176 60.5987 8.10053 59.2825 5.87735 57.5805C2.99731 55.3779 1.22138 52.3121 0.450374 48.2099C-0.204605 44.7527 -0.145657 41.1258 0.630961 36.785C0.716109 36.3096 0.990264 35.9731 1.15307 35.7727C1.18021 35.7391 1.21764 35.6944 1.22886 35.6795L2.0139 34.5031L3.18725 35.3197C3.21064 35.3337 3.23591 35.3467 3.26117 35.3607C3.50819 35.494 3.96761 35.7419 4.19686 36.2751C5.3964 39.0481 7.7038 40.959 11.4559 42.2891C13.8419 43.1345 16.5086 43.6099 19.6207 43.7423C21.4041 43.8187 23.2989 43.8933 25.1487 43.8933C27.8332 43.8933 30.048 43.7395 32.1196 43.4095C32.3853 43.3676 32.6538 43.3163 32.9383 43.2585L33.1937 43.2082C33.4457 43.1573 33.702 43.1308 33.9591 43.1289C34.5598 43.1289 36.0017 43.3284 36.419 45.1796C36.7147 46.4846 37.0094 47.8193 37.2939 49.1066C37.3238 49.2418 37.3547 49.3788 37.3875 49.5149C37.423 49.142 37.4586 48.7692 37.4941 48.3963C37.7103 46.1229 37.9339 43.773 38.1613 41.4605C38.2062 41 38.3063 40.3158 38.6497 39.7081C40.2057 36.9471 41.104 33.9895 42.0556 30.8586C42.1804 30.4397 42.3076 30.0218 42.4374 29.6049C43.5293 26.0628 44.5773 22.8806 46.341 19.8727C47.4386 18.0019 48.5867 16.6662 49.9537 15.6697C51.2833 14.7003 52.7187 14.1467 54.2195 14.0255C54.4384 14.0078 54.6546 13.9994 54.8595 13.9994C57.5225 13.9994 59.1056 15.4796 59.319 18.1687C59.5117 20.6146 59.0065 22.9179 58.431 25.0431C58.1597 26.0442 57.8471 27.0434 57.5458 28.01C56.9901 29.7885 56.4651 31.4691 56.1601 33.1954C55.5154 36.8493 55.1917 40.5936 54.9381 44.0946C54.751 46.6794 54.7098 49.334 54.6696 51.902C54.6574 52.6402 54.6462 53.3785 54.6312 54.1167C54.5853 56.3892 53.5954 58.1938 51.6894 59.4792C48.4074 61.7079 44.6054 63.0589 40.6483 63.4025C38.7246 63.5749 36.7718 63.7035 34.8836 63.8275C34.034 63.8834 33.1834 63.9394 32.3338 63.9999H32.2786L32.2225 63.999Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.3017 42.2015C21.5698 42.2015 19.8172 42.1008 18.1826 41.9908C15.238 41.7941 12.8717 41.3775 10.7346 40.6802C5.83157 39.0807 3.17703 35.7755 2.8486 30.8529C2.47433 25.3134 3.37072 20.6854 5.57893 16.7072C7.29404 13.6172 9.57243 11.1853 12.3467 9.48606C15.0874 7.80825 18.3351 6.82114 22.0049 6.56295C22.3165 6.54151 22.6439 6.53032 22.9789 6.53032C24.1766 6.53032 25.3621 6.66921 26.5083 6.80343C27.1511 6.87893 27.7584 6.94978 28.3685 6.99918C28.7278 7.02714 29.0702 7.08307 29.3977 7.13806C29.7321 7.20241 30.0708 7.24228 30.4111 7.25737C30.5072 7.26002 30.6033 7.24936 30.6964 7.22568C30.9444 7.15111 31.3439 6.7531 31.6658 6.43245C31.8792 6.22086 32.099 6.00088 32.3433 5.79209C34.2689 4.14224 36.9281 2.16709 40.4294 1.53512C41.1626 1.40126 41.9063 1.33325 42.6517 1.33191C44.9191 1.32981 47.1351 2.00411 49.0143 3.26792C51.2796 4.7593 52.2481 6.69438 51.9468 9.14584L54.7314 9.5336L53.0734 11.3372C53.0069 11.409 52.9508 11.4771 52.8918 11.5479C52.7169 11.7778 52.5058 11.978 52.2668 12.1407C46.2382 16.0472 42.5909 21.4386 41.1228 28.6308C40.8414 29.9806 40.4847 31.3137 40.0542 32.624C38.4243 37.6574 34.6871 40.7269 28.9467 41.7485C27.2382 42.0533 25.3911 42.2005 23.3027 42.2005L23.3017 42.2015ZM17.0195 28.2579C16.102 28.2443 15.1949 28.4528 14.3762 28.8657C13.4406 29.3606 12.8791 30.0774 12.8034 30.8763C12.7276 31.6751 13.1383 32.4748 13.9645 33.1227C14.9348 33.8833 16.154 34.2775 17.6895 34.3288C18.8516 34.2953 19.7536 34.0678 20.5265 33.6139C21.3593 33.1245 21.858 32.4459 21.93 31.7049C22.0021 30.9639 21.6418 30.2135 20.9157 29.5834C19.9557 28.7538 18.4989 28.2579 17.0195 28.2579ZM41.0161 8.12424C42.2886 8.23516 43.5986 8.35354 44.8861 8.48217C44.8683 8.07297 44.6503 7.86791 44.1086 7.65166C43.7437 7.50489 43.354 7.42896 42.9605 7.42795C42.2526 7.43444 41.5676 7.67842 41.0161 8.12051V8.12424Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.798 56.6942C55.798 56.1834 55.798 55.6744 55.798 55.1674C55.798 53.3656 55.798 51.5013 55.812 49.6772C55.8176 49.1179 56.1049 48.6863 56.2799 48.4291L56.3248 48.362L56.5624 47.9966C57.0162 47.2966 57.4859 46.5732 57.9856 45.8704C59.4209 43.8505 59.8373 41.4895 59.2591 38.64C59.0429 37.5793 58.7426 36.5409 58.4254 35.4419C58.2504 34.8388 58.0698 34.2143 57.9061 33.5907C57.7423 32.9671 57.7245 32.4619 57.8387 32.0061C58.5386 29.2145 59.2703 26.3827 59.9786 23.6441L60.3632 22.1527C60.4251 21.9233 60.5423 21.7125 60.7047 21.5385L60.7552 21.4788L62.3562 19.4552L63.1543 21.8694C63.2747 22.2329 63.3948 22.5905 63.5146 22.9422C63.7775 23.7196 64.0254 24.4523 64.2631 25.211C65.8847 30.4309 67.0374 35.2434 67.7878 39.9254C68.9462 47.154 65.2044 54.238 58.4787 57.5536C58.2916 57.6468 58.1044 57.7241 57.9463 57.7931L57.7479 57.8779L55.7998 58.7374L55.798 56.6942Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2243 63.9728C27.0977 63.9448 20.3823 63.6475 13.897 61.58C10.7391 60.5724 8.12387 59.2563 5.90255 57.5542C3.02532 55.3516 1.25033 52.2859 0.475582 48.1836C-0.179398 44.7264 -0.120449 41.0996 0.656169 36.7587C0.741316 36.2833 1.01454 35.9468 1.17828 35.7464C1.20542 35.7129 1.24191 35.6681 1.25407 35.6532L2.03818 34.4769L3.21059 35.2934C3.23398 35.3074 3.25924 35.3204 3.28451 35.3344C3.53059 35.4677 3.99001 35.7157 4.22019 36.2488C5.42255 39.0191 7.72433 40.9299 11.4736 42.2601C13.8568 43.1055 16.5263 43.5809 19.6309 43.7132C21.4087 43.7897 23.3063 43.8642 25.1515 43.8642C27.8331 43.8642 30.046 43.7104 32.1158 43.3805C32.3806 43.3385 32.6491 43.2872 32.9335 43.2295L33.189 43.1791C33.4407 43.1283 33.6967 43.1017 33.9534 43.0999C34.5542 43.0999 35.9942 43.2994 36.4115 45.1505C36.7062 46.4555 37.001 47.7903 37.2854 49.0775C37.3154 49.2127 37.3453 49.3497 37.379 49.4858C37.4145 49.113 37.4501 48.7401 37.4857 48.3673C37.7018 46.0938 37.9254 43.744 38.1519 41.4314C38.1968 40.9709 38.2969 40.2868 38.6394 39.679C40.1945 36.9181 41.0918 33.9605 42.0424 30.8295C42.1672 30.4107 42.2945 29.9928 42.4242 29.5758C43.5152 26.0338 44.5622 22.8515 46.3241 19.8436C47.4208 17.9728 48.5698 16.6371 49.9331 15.6407C51.2627 14.6713 52.6961 14.1176 54.1951 13.9964C54.415 13.9787 54.6302 13.9703 54.8351 13.9703C57.4962 13.9703 59.0775 15.4505 59.2899 18.1397C59.4836 20.5856 58.9783 22.8888 58.4029 25.014C58.1315 26.0151 57.82 27.0144 57.5187 27.981C56.9638 29.7594 56.4389 31.4401 56.1348 33.1663C55.4901 36.8202 55.1664 40.5645 54.9137 44.0656C54.7266 46.6503 54.6854 49.305 54.6452 51.873C54.633 52.6112 54.6218 53.3494 54.6068 54.0877C54.561 56.3602 53.5776 58.1647 51.6678 59.4501C48.3903 61.6782 44.5927 63.0292 40.6398 63.3734C38.718 63.5459 36.767 63.6745 34.8816 63.7985C34.032 63.8544 33.1824 63.9103 32.3328 63.9709H32.2786L32.2243 63.9728Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.3111 42.1753C21.5801 42.1753 19.8303 42.0746 18.1966 41.9646C15.2558 41.768 12.8913 41.3513 10.7551 40.6541C5.85683 39.0555 3.20604 35.7493 2.87668 30.8287C2.50521 25.2891 3.39785 20.6611 5.60981 16.6847C7.32399 13.5929 9.59863 11.1629 12.3701 9.46177C15.1089 7.78396 18.3585 6.79685 22.0208 6.53865C22.3314 6.51721 22.6589 6.50603 22.993 6.50603C24.1906 6.50603 25.3743 6.64491 26.5196 6.77914C27.1624 6.85464 27.7687 6.92548 28.3788 6.97488C28.7371 7.00284 29.0796 7.05877 29.408 7.11377C29.7424 7.17811 30.0811 7.21799 30.4214 7.23308C30.5159 7.23542 30.6104 7.22475 30.7021 7.20139C30.95 7.12682 31.3496 6.7288 31.6705 6.40815C31.8838 6.19656 32.1037 5.97658 32.347 5.76779C34.2717 4.11795 36.9319 2.14279 40.4257 1.51082C41.1588 1.37601 41.9025 1.30707 42.6479 1.30482C44.9156 1.30174 47.132 1.97647 49.0106 3.24176C51.275 4.73314 52.2415 6.66821 51.9412 9.11968L54.7201 9.50744L53.064 11.3111C52.9976 11.3829 52.9414 11.4509 52.8825 11.5217C52.7076 11.7514 52.4969 11.9515 52.2584 12.1146C46.2269 16.0211 42.5825 21.4152 41.1162 28.6046C40.8352 29.9547 40.4788 31.2882 40.0486 32.5987C38.4196 37.6322 34.6862 40.7016 28.9514 41.7232C27.2447 42.028 25.3958 42.1753 23.312 42.1753H23.3111ZM17.0355 28.2318C16.1186 28.2182 15.2121 28.4268 14.394 28.8395C13.4583 29.3345 12.8969 30.0513 12.8221 30.8501C12.7472 31.6489 13.157 32.4487 13.9833 33.0965C14.9526 33.8571 16.1699 34.2514 17.7045 34.3027C18.8647 34.2691 19.763 34.0417 20.5387 33.5877C21.3705 33.0984 21.8683 32.4198 21.9422 31.6787C22.0161 30.9377 21.654 30.1874 20.9298 29.5573C19.9679 28.7277 18.5129 28.2318 17.0355 28.2318ZM41.0096 8.09808C42.284 8.21273 43.5883 8.33111 44.8768 8.46347C44.859 8.05427 44.64 7.84921 44.0992 7.63295C43.735 7.48611 43.3459 7.41017 42.953 7.40925C42.2463 7.4136 41.5617 7.65495 41.0096 8.09435V8.09808Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.7783 56.6727C55.7783 56.1619 55.7783 55.6529 55.7783 55.1459C55.7783 53.3441 55.7783 51.4799 55.7924 49.6557C55.798 49.0964 56.0852 48.6649 56.2602 48.4076L56.3042 48.3405L56.5418 47.9751C56.9956 47.2751 57.4654 46.5518 57.9641 45.8489C59.3985 43.8291 59.8139 41.468 59.2357 38.6185C59.0205 37.5578 58.721 36.5194 58.4029 35.4204C58.2289 34.8174 58.0483 34.1928 57.8845 33.5693C57.7245 32.9597 57.703 32.4405 57.8172 31.9847C58.5171 29.193 59.2478 26.3612 59.9552 23.6226L60.3398 22.1313C60.4011 21.9018 60.518 21.6908 60.6804 21.517C60.6935 21.5021 60.7103 21.4834 60.7309 21.4573L62.3309 19.4337L63.1328 21.8488C63.2532 22.2124 63.3733 22.57 63.493 22.9217C63.755 23.6991 64.003 24.4317 64.2416 25.1905C65.8622 30.4103 67.0131 35.2228 67.7635 39.9048C68.92 47.1334 65.1819 54.2175 58.4619 57.533C58.2747 57.6262 58.0923 57.7036 57.9304 57.7726L57.732 57.8574L55.7858 58.7168L55.7783 56.6727Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2252 63.9432C27.1042 63.9152 20.3944 63.6179 13.9148 61.5504C10.756 60.5466 8.14727 59.2304 5.92783 57.5284C3.05341 55.3258 1.28028 52.26 0.505536 48.1578C-0.149443 44.7006 -0.0895594 41.0737 0.686123 36.7319C0.77127 36.2575 1.04449 35.921 1.20824 35.7206C1.23443 35.687 1.27186 35.6423 1.28309 35.6274L2.06626 34.451L3.23774 35.2676C3.26113 35.2816 3.28639 35.2946 3.31165 35.3086C3.55774 35.4419 4.01716 35.6898 4.24734 36.223C5.44502 38.996 7.74774 40.9069 11.4942 42.237C13.8755 43.0824 16.5413 43.5578 19.644 43.6902C21.4218 43.7666 23.3157 43.8412 25.1646 43.8412C27.8434 43.8412 30.0554 43.6874 32.1233 43.3574C32.3881 43.3155 32.6557 43.2642 32.9401 43.2064L33.1946 43.1561C33.4463 43.1052 33.7023 43.0787 33.9591 43.0768C34.5588 43.0768 35.9979 43.2763 36.4143 45.1275C36.709 46.4325 37.0038 47.7672 37.2882 49.0545C37.3182 49.1897 37.3481 49.3267 37.3818 49.4628C37.4174 49.0899 37.4526 48.7171 37.4875 48.3442C37.7037 46.0708 37.9273 43.7209 38.1537 41.4083C38.1987 40.9479 38.2988 40.2637 38.6412 39.656C40.1945 36.895 41.0918 33.9374 42.0406 30.8065C42.1678 30.3879 42.2941 29.9676 42.4223 29.5528C43.5049 26.0061 44.5529 22.8238 46.312 19.8159C47.4077 17.9451 48.5576 16.6094 49.9181 15.613C51.2458 14.6436 52.6784 14.0899 54.1764 13.9687C54.3954 13.951 54.6106 13.9426 54.8155 13.9426C57.4738 13.9426 59.0541 15.4228 59.2665 18.112C59.4593 20.5579 58.9549 22.8611 58.3804 24.9863C58.1091 25.9874 57.7975 26.9867 57.4962 27.9533C56.9432 29.7345 56.4174 31.4123 56.1152 33.1386C55.4705 36.7925 55.1477 40.5368 54.8988 44.0378C54.7116 46.6226 54.6705 49.2773 54.6302 51.8452C54.619 52.5835 54.6062 53.3217 54.5919 54.06C54.5469 56.3325 53.5626 58.137 51.6557 59.4224C48.3818 61.6502 44.5876 63.0013 40.638 63.3457C38.717 63.5181 36.7689 63.6468 34.8845 63.7707C34.0358 63.8267 33.1862 63.8826 32.3385 63.9432H32.2842H32.2252Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.3213 42.1495C21.5912 42.1495 19.8425 42.0489 18.2106 41.9389C15.2726 41.7422 12.91 41.3255 10.7766 40.6283C5.88204 39.0288 3.23405 35.7235 2.90469 30.8029C2.53042 25.2633 3.42493 20.6354 5.63034 16.6571C7.34358 13.5653 9.61636 11.1352 12.385 9.43413C15.121 7.75632 18.365 6.76921 22.0226 6.51102C22.3332 6.48958 22.6607 6.47839 22.9948 6.47839C24.1906 6.47839 25.3742 6.61728 26.5176 6.7515C27.1595 6.82701 27.7658 6.89785 28.375 6.94725C28.7333 6.97521 29.0758 7.03114 29.4042 7.0852C29.7382 7.15027 30.0766 7.19046 30.4166 7.20544C30.5112 7.20778 30.6057 7.19712 30.6973 7.17375C30.9453 7.09918 31.3439 6.70117 31.6648 6.38052C31.8782 6.16893 32.0981 5.94895 32.3413 5.74016C34.2642 4.09031 36.9196 2.11516 40.4125 1.48319C41.1444 1.34908 41.8869 1.28076 42.6311 1.27905C44.9029 1.27905 47.0409 1.93153 48.9853 3.21506C51.2468 4.70644 52.2134 6.64245 51.913 9.09391L54.692 9.48167L53.049 11.2853C52.9825 11.3571 52.9273 11.4251 52.8684 11.496C52.6936 11.7256 52.4828 11.9258 52.2443 12.0888C46.2185 15.9953 42.5749 21.3895 41.1096 28.5789C40.8294 29.9291 40.4733 31.2625 40.0429 32.573C38.4158 37.6064 34.6852 40.6759 28.9551 41.6975C27.2503 42.0023 25.407 42.1495 23.3213 42.1495ZM17.0522 28.206C16.1362 28.1924 15.2307 28.401 14.4136 28.8138C13.4779 29.3087 12.9165 30.0255 12.8426 30.8243C12.7687 31.6231 13.1776 32.4229 14.0028 33.0707C14.9666 33.836 16.183 34.2256 17.7194 34.2769C18.8787 34.2433 19.7779 34.0159 20.5508 33.562C21.3817 33.0726 21.8794 32.394 21.9543 31.653C22.0291 30.9119 21.6661 30.1616 20.9419 29.5315C19.9809 28.7019 18.5269 28.206 17.0504 28.206H17.0522ZM41.0058 8.07231C42.2802 8.18696 43.5826 8.30534 44.8692 8.4377C44.8514 8.0285 44.6334 7.82344 44.0935 7.60719C43.7284 7.45869 43.338 7.38148 42.9436 7.37975C42.2374 7.38558 41.5537 7.62824 41.003 8.06858L41.0058 8.07231Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.7625 56.6419C55.7625 56.1311 55.7625 55.6222 55.7625 55.1151C55.7625 53.3133 55.7624 51.4491 55.7774 49.6249C55.7774 49.0657 56.0694 48.6341 56.2406 48.3768L56.2855 48.3097L56.5222 47.9443C56.976 47.2443 57.4448 46.521 57.9435 45.8182C59.3761 43.7983 59.7924 41.4372 59.2142 38.5878C58.999 37.527 58.6996 36.4886 58.3824 35.3897C58.2074 34.7866 58.0277 34.1621 57.864 33.5385C57.7003 32.9149 57.6825 32.4097 57.7976 31.9539C58.4956 29.1622 59.2264 26.3304 59.9328 23.5919L60.3164 22.1005C60.3783 21.8711 60.4956 21.6602 60.658 21.4862C60.6701 21.4713 60.687 21.4527 60.7075 21.4266L62.3057 19.403L63.1076 21.8181C63.2273 22.1816 63.348 22.5386 63.4669 22.8909C63.7289 23.6683 63.9768 24.401 64.2154 25.1597C65.8342 30.3795 66.9851 35.1921 67.7336 39.8741C68.8901 47.1026 65.1558 54.1867 58.4404 57.5023C58.2532 57.5955 58.0717 57.6728 57.9098 57.7418L57.7115 57.8266L55.7625 58.6851V56.6419Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2272 63.9208C27.1109 63.8928 20.4076 63.5955 13.9327 61.528C10.7804 60.5204 8.17074 59.2043 5.95317 57.5022C3.08342 55.2996 1.30562 52.2339 0.535551 48.1316C-0.119428 44.6744 -0.0586083 41.0476 0.716139 36.7058C0.801286 36.2313 1.07451 35.8948 1.23732 35.6944C1.26445 35.6609 1.30094 35.6161 1.31311 35.6012L2.09534 34.4249L3.27056 35.2386C3.29395 35.2526 3.31921 35.2656 3.34448 35.2796C3.59056 35.4129 4.04811 35.6609 4.28016 36.194C5.4769 38.9671 7.77681 40.8779 11.5196 42.208C13.899 43.0535 16.5629 43.5289 19.66 43.6612C21.4378 43.7377 23.3279 43.8122 25.1731 43.8122C27.8491 43.8122 30.0583 43.6584 32.1252 43.3285C32.3891 43.2865 32.6576 43.2352 32.9411 43.1775L33.1956 43.1271C33.447 43.0763 33.7027 43.0498 33.9591 43.0479C34.558 43.0479 35.9961 43.2474 36.4125 45.0985C36.7072 46.4035 37.0011 47.7383 37.2855 49.0255C37.3154 49.1607 37.3454 49.2977 37.3791 49.4338C37.4146 49.061 37.4502 48.6881 37.4857 48.3153C37.7019 46.0418 37.9246 43.692 38.151 41.3794C38.1959 40.9189 38.296 40.2348 38.6376 39.627C40.1899 36.8661 41.0863 33.9085 42.035 30.7775C42.1598 30.3587 42.2867 29.9408 42.4159 29.5238C43.505 25.9818 44.5492 22.7995 46.3092 19.7916C47.4031 17.9208 48.5474 16.5851 49.9116 15.5887C51.2375 14.6193 52.6691 14.0656 54.1653 13.9444C54.3851 13.9267 54.5994 13.9183 54.8043 13.9183C57.4598 13.9183 59.0392 15.3985 59.2516 18.0877C59.4444 20.5336 58.9401 22.8368 58.3655 24.962C58.0951 25.9631 57.7845 26.9624 57.4832 27.929C56.9293 29.7074 56.4053 31.3871 56.1012 33.1143C55.4584 36.7682 55.1356 40.5125 54.8848 44.0136C54.6977 46.5983 54.6565 49.253 54.6163 51.821C54.605 52.5592 54.5925 53.2974 54.5788 54.0357C54.533 56.3082 53.5496 58.1127 51.6445 59.3981C48.3743 61.6255 44.5835 62.9766 40.6371 63.3214C38.718 63.4939 36.7709 63.6225 34.8883 63.7465C34.0405 63.8024 33.1919 63.8583 32.3442 63.9189H32.2899L32.2272 63.9208Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.3306 42.1233C21.6024 42.1233 19.8555 42.0226 18.2246 41.9126C15.2894 41.716 12.9286 41.2993 10.7971 40.6021C5.90726 39.0026 3.26114 35.7001 2.93271 30.7766C2.55844 25.2371 3.45296 20.6091 5.65556 16.6309C7.36692 13.539 9.63783 11.109 12.4046 9.40789C15.1387 7.73008 18.379 6.74297 22.0422 6.48477C22.3519 6.46334 22.6785 6.45215 23.0134 6.45215C24.2074 6.45215 25.3901 6.59103 26.5335 6.72526C27.1744 6.80076 27.7798 6.8716 28.388 6.921C28.7464 6.94897 29.0879 7.00489 29.4173 7.05896C29.7501 7.1233 30.0872 7.16287 30.4259 7.17734C30.5205 7.17959 30.615 7.16893 30.7066 7.14564C30.9527 7.07107 31.3523 6.67306 31.6732 6.35241C31.8856 6.14082 32.1055 5.92084 32.3478 5.71205C34.2735 4.06407 36.9261 2.08892 40.4172 1.45694C41.1484 1.32279 41.8903 1.25447 42.6338 1.25281C44.9038 1.25281 47.04 1.90529 48.9824 3.18881C51.2412 4.68393 52.2068 6.61807 51.9055 9.06767L54.6836 9.45543L53.0293 11.2591C52.9628 11.3308 52.9076 11.3989 52.8487 11.4697C52.6742 11.6994 52.4638 11.8995 52.2255 12.0626C46.2053 15.9691 42.5692 21.3632 41.103 28.5526C40.823 29.9028 40.4672 31.2363 40.0373 32.5467C38.412 37.5802 34.6852 40.6496 28.9597 41.6712C27.2558 41.976 25.4144 42.1233 23.3306 42.1233ZM17.0616 28.1798C16.1464 28.1662 15.2418 28.3748 14.4257 28.7875C13.49 29.2825 12.9333 29.9993 12.8566 30.7981C12.7799 31.5969 13.1906 32.3967 14.015 33.0445C14.9825 33.8051 16.1979 34.1994 17.7296 34.2506C18.888 34.2171 19.7881 33.9896 20.5582 33.5357C21.3891 33.0463 21.8869 32.3678 21.9617 31.6267C22.0366 30.8857 21.6745 30.1353 20.9512 29.5052C19.9931 28.6757 18.5409 28.1798 17.0653 28.1798H17.0616ZM40.9926 8.04607C42.2661 8.16072 43.5676 8.2791 44.8533 8.41146C44.8355 8.00226 44.6175 7.79719 44.0776 7.58094C43.714 7.4342 43.3255 7.35826 42.9332 7.35723C42.2287 7.36262 41.5465 7.60393 40.9964 8.04234L40.9926 8.04607Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.7391 56.616C55.7391 56.1052 55.7391 55.5963 55.7391 55.0892C55.7391 53.2874 55.7391 51.4232 55.754 49.5991C55.7587 49.0398 56.046 48.6082 56.2219 48.351L56.2668 48.2839L56.5035 47.9185C56.9564 47.2184 57.4252 46.4951 57.923 45.7923C59.3546 43.7724 59.77 41.4114 59.1927 38.5619C58.9775 37.5011 58.6781 36.4628 58.3609 35.3638C58.1868 34.7607 58.0072 34.1362 57.8434 33.5126C57.6844 32.903 57.6619 32.3838 57.777 31.928C58.475 29.1363 59.2049 26.3046 59.9104 23.566L60.294 22.0746C60.3553 21.8451 60.4722 21.6342 60.6346 21.4604L60.6851 21.4007L62.2814 19.3771L63.0823 21.7922C63.2021 22.1557 63.3228 22.5127 63.4416 22.8651C63.7036 23.6415 63.9506 24.3751 64.1902 25.1338C65.807 30.3537 66.957 35.1662 67.7055 39.8482C68.8499 47.0721 65.1193 54.1562 58.4105 57.4754C58.2233 57.5687 58.0418 57.646 57.8799 57.715C57.8135 57.743 57.7471 57.7709 57.6825 57.7998L55.7391 58.6592V56.616Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.229 63.8946C27.1164 63.8666 20.4198 63.5693 13.9514 61.5019C10.8018 60.4942 8.19316 59.1781 5.97839 57.476C3.10865 55.2744 1.33833 52.2077 0.565457 48.1055C-0.0857797 44.6483 -0.0277673 41.0177 0.744173 36.6796C0.82932 36.2052 1.1016 35.8687 1.26535 35.6683C1.29155 35.6347 1.32898 35.59 1.3402 35.575L2.12431 34.395L3.29298 35.2115C3.31637 35.2255 3.34163 35.2386 3.3669 35.2525C3.61298 35.3858 4.07053 35.6338 4.30258 36.1669C5.49839 38.94 7.79736 40.8508 11.5364 42.181C13.913 43.0264 16.5741 43.5018 19.6768 43.6341C21.4546 43.7106 23.341 43.7851 25.1843 43.7851C27.8584 43.7851 30.0657 43.6313 32.1308 43.3014C32.3946 43.2594 32.6622 43.2081 32.9458 43.1504L33.2003 43.1C33.4513 43.0492 33.7067 43.0226 33.9628 43.0208C34.5617 43.0208 35.998 43.2203 36.4143 45.0714C36.7081 46.3764 37.002 47.7112 37.2864 48.9984L37.38 49.4067C37.4155 49.0339 37.4511 48.661 37.4866 48.2882C37.7018 46.0147 37.9245 43.6649 38.151 41.3523C38.1959 40.8918 38.2951 40.2077 38.6375 39.5999C40.188 36.839 41.0834 33.8814 42.0313 30.7504C42.156 30.3316 42.283 29.9137 42.4121 29.4967C43.5003 25.9547 44.5436 22.7724 46.3017 19.7645C47.3946 17.8937 48.5389 16.558 49.9004 15.5616C51.2262 14.5922 52.656 14.0385 54.1512 13.9173C54.3701 13.8996 54.5844 13.8912 54.7893 13.8912C57.4429 13.8912 59.0205 15.3714 59.2319 18.0606C59.4247 20.5065 58.9213 22.8097 58.3477 24.9349C58.0773 25.936 57.7667 26.9353 57.4654 27.9019C56.9124 29.6803 56.3893 31.36 56.0852 33.0872C55.4424 36.7411 55.1196 40.4854 54.8689 43.9865C54.6817 46.5712 54.6405 49.2259 54.6003 51.7939C54.5891 52.5321 54.5766 53.2703 54.5629 54.0086C54.517 56.2811 53.5336 58.0856 51.6314 59.371C48.361 61.5989 44.5691 62.9489 40.6221 63.2906C38.7049 63.463 36.7596 63.5917 34.8789 63.7156C34.0312 63.7716 33.1844 63.8275 32.3376 63.8881H32.2833L32.229 63.8946Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.34 42.0972C21.6137 42.0972 19.8686 41.9965 18.2387 41.8865C15.3053 41.6898 12.9474 41.2732 10.8178 40.576C5.93256 38.9764 3.28925 35.6712 2.95802 30.7505C2.58842 25.21 3.47826 20.583 5.67899 16.6047C7.38755 13.5157 9.65939 11.0829 12.4243 9.38177C15.1556 7.70396 18.3931 6.71685 22.0479 6.45865C22.3585 6.43721 22.6841 6.42603 23.0182 6.42603C24.2121 6.42603 25.3929 6.56491 26.5354 6.69914C27.1763 6.77464 27.7808 6.84548 28.389 6.89395C28.7464 6.92284 29.0879 6.97877 29.4182 7.03283C29.7516 7.09783 30.0894 7.13803 30.4288 7.15308C30.5234 7.15533 30.6178 7.14467 30.7095 7.12138C30.9556 7.04682 31.3542 6.6488 31.6751 6.32815C31.8875 6.11656 32.1065 5.89658 32.3497 5.68779C34.2688 4.03795 36.9196 2.06279 40.4069 1.43082C41.1375 1.29671 41.8788 1.22839 42.6217 1.22668C44.8888 1.22668 47.0194 1.87917 48.9638 3.16269C51.2253 4.6606 52.19 6.59008 51.8896 9.04154L54.6649 9.4293L53.0125 11.2329C52.946 11.3047 52.8899 11.3728 52.8309 11.4436C52.6571 11.6735 52.447 11.8737 52.2087 12.0364C46.1941 15.9392 42.5599 21.3371 41.0965 28.5228C40.8168 29.8729 40.4613 31.2064 40.0317 32.5169C38.4083 37.5503 34.6843 40.6198 28.9644 41.6414C27.2624 41.9462 25.4229 42.0934 23.341 42.0934L23.34 42.0972ZM17.0803 28.1499C16.1661 28.1363 15.2623 28.3449 14.4473 28.7577C13.5116 29.2526 12.9558 29.9694 12.8791 30.7682C12.8024 31.5671 13.2131 32.3668 14.0365 33.0146C15.004 33.7752 16.2185 34.1695 17.7484 34.2208C18.9058 34.1872 19.8069 33.9598 20.5742 33.5059C21.405 33.0165 21.901 32.3379 21.973 31.5969C22.045 30.8558 21.6857 30.1055 20.9625 29.4754C20.0062 28.6495 18.5549 28.1499 17.0803 28.1499ZM40.9908 8.01622C42.2624 8.13087 43.563 8.24924 44.8477 8.3816C44.8299 7.97241 44.6119 7.76734 44.072 7.55109C43.7088 7.40427 43.3206 7.32832 42.9286 7.32738C42.2232 7.33282 41.5403 7.57556 40.9908 8.01622Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.7194 56.5895C55.7194 56.0787 55.7194 55.5698 55.7194 55.0627C55.7194 53.261 55.7194 51.3967 55.7344 49.5726C55.7344 49.0133 56.0263 48.5817 56.1976 48.3245L56.2415 48.2574L56.4783 47.892C56.9311 47.1919 57.399 46.4686 57.8968 45.7658C59.3274 43.7459 59.7419 41.3802 59.1655 38.5354C58.9503 37.4746 58.6509 36.4363 58.3347 35.3373C58.1606 34.7342 57.981 34.1097 57.8172 33.4861C57.6535 32.8625 57.6357 32.3573 57.7508 31.9015C58.4479 29.1098 59.1768 26.2781 59.8823 23.5395L60.2659 22.0481C60.3273 21.8189 60.4439 21.6081 60.6056 21.4339C60.6187 21.419 60.6355 21.4003 60.6561 21.3742L62.2514 19.3506L63.0515 21.7657C63.1712 22.1292 63.2919 22.4862 63.4108 22.8386C63.6718 23.615 63.9188 24.3486 64.1537 25.1073C65.7696 30.3272 66.9177 35.1397 67.6653 39.8217C68.8199 47.0503 65.0922 54.1344 58.3899 57.4499C58.2027 57.5431 58.0156 57.6205 57.8593 57.6894C57.7929 57.7174 57.7265 57.7454 57.6619 57.7743L55.7194 58.6299V56.5895Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.23 63.8684C27.123 63.8404 20.4319 63.5431 13.9692 61.4757C10.8225 60.468 8.21659 59.1519 6.00276 57.4499C3.13675 55.2473 1.36831 52.1815 0.596371 48.0793C-0.0586081 44.6221 0.00221141 40.9952 0.776023 36.6534C0.86117 36.179 1.13345 35.8425 1.29626 35.6421C1.3234 35.6085 1.35989 35.5638 1.37205 35.5489L2.15241 34.3725L3.32108 35.1891C3.34448 35.203 3.36974 35.2161 3.395 35.2301C3.64015 35.3634 4.0977 35.6113 4.33069 36.1445C5.52462 38.9175 7.82173 40.8284 11.557 42.1585C13.9327 43.0039 16.591 43.4793 19.6853 43.6117C21.4631 43.6881 23.3475 43.7627 25.189 43.7627C27.8604 43.7627 30.0658 43.6089 32.128 43.2789C32.3928 43.2369 32.6595 43.1857 32.943 43.1279L33.1975 43.0776C33.4482 43.0267 33.7033 43.0002 33.9591 42.9983C34.557 42.9983 35.9924 43.1978 36.4078 45.049C36.7026 46.3539 36.9954 47.6887 37.279 48.976C37.3089 49.1111 37.3398 49.2482 37.3725 49.3843C37.4081 49.0114 37.4433 48.6386 37.4783 48.2657C37.6944 45.9923 37.9171 43.6424 38.1426 41.3298C38.1875 40.8694 38.2867 40.1852 38.6282 39.5775C40.1786 36.8165 41.0722 33.8589 42.0191 30.728C42.1439 30.3091 42.2708 29.8912 42.4 29.4743C43.4872 25.9322 44.5296 22.75 46.2859 19.742C47.3778 17.8713 48.5212 16.5356 49.8817 15.5391C51.2057 14.5697 52.6345 14.0161 54.1288 13.8949C54.3468 13.8772 54.562 13.8688 54.766 13.8688C57.4168 13.8688 58.9934 15.349 59.2048 18.0381C59.3976 20.484 58.8942 22.7873 58.3206 24.9125C58.0512 25.9136 57.7405 26.9128 57.4401 27.8794C56.8872 29.6579 56.3641 31.3376 56.061 33.0648C55.4181 36.7187 55.0963 40.463 54.8446 43.964C54.6574 46.5488 54.6172 49.2034 54.5779 51.7714C54.5657 52.5096 54.5545 53.2479 54.5395 53.9861C54.4946 56.2586 53.5103 58.0632 51.6108 59.3486C48.3478 61.5756 44.5634 62.9269 40.6231 63.2718C38.7077 63.4443 36.7643 63.5729 34.8845 63.6969C34.0377 63.7528 33.1919 63.8087 32.346 63.8693H32.2918L32.23 63.8684Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.3494 42.0711C21.6249 42.0711 19.8808 41.9695 18.2537 41.8605C15.3222 41.6638 12.9671 41.2471 10.8393 40.5499C5.95784 38.9504 3.3164 35.6451 2.98985 30.7245C2.61557 25.184 3.50728 20.557 5.70333 16.5787C7.41189 13.4869 9.67906 11.0568 12.4403 9.35572C15.1725 7.67418 18.4071 6.6908 22.0591 6.43167C22.3688 6.41023 22.6944 6.39905 23.0285 6.39905C24.2205 6.39905 25.4004 6.53794 26.542 6.67216C27.182 6.74766 27.7864 6.8185 28.3937 6.86697C28.7511 6.89587 29.0926 6.95179 29.4229 7.00586C29.756 7.07096 30.0935 7.11115 30.4325 7.1261C30.5271 7.12844 30.6216 7.11777 30.7132 7.09441C30.9593 7.01984 31.3579 6.62183 31.6779 6.30118C31.8903 6.08959 32.1093 5.86961 32.3526 5.66081C34.2698 4.01097 36.9178 2.03582 40.3995 1.40384C41.1291 1.26975 41.8694 1.20143 42.6114 1.19971C44.8776 1.19971 47.0091 1.85219 48.9488 3.13571C51.2047 4.6271 52.1676 6.5631 51.8681 9.01457L54.6406 9.40233L52.99 11.206C52.9236 11.2777 52.8675 11.3458 52.8085 11.4166C52.6348 11.6463 52.425 11.8464 52.1872 12.0095C46.1773 15.916 42.5497 21.3101 41.0844 28.4995C40.805 29.8497 40.4498 31.1831 40.0205 32.4936C38.398 37.5271 34.6777 40.5956 28.9635 41.6181C27.2624 41.9229 25.4248 42.0702 23.3494 42.0702V42.0711ZM17.0962 28.1276C16.1823 28.114 15.2789 28.3226 14.4641 28.7353C13.5285 29.2303 12.9755 29.9471 12.8978 30.7459C12.8201 31.5447 13.2319 32.3445 14.0543 32.997C15.0209 33.7576 16.2345 34.1519 17.7634 34.2031C18.9189 34.1696 19.8219 33.9421 20.5863 33.4882C21.4163 32.9988 21.9122 32.3203 21.9833 31.5792C22.0544 30.8382 21.697 30.0878 20.9746 29.4577C20.0193 28.6235 18.5681 28.1276 17.0962 28.1276ZM40.9843 7.9939C42.2549 8.10855 43.5536 8.22693 44.8374 8.35929C44.8196 7.95009 44.6026 7.74502 44.0636 7.52877C43.7003 7.38204 43.3122 7.3061 42.9202 7.30507C42.2158 7.30964 41.5337 7.55105 40.9843 7.99017V7.9939Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.6998 56.5635C55.6998 56.0527 55.6998 55.5437 55.6998 55.0367C55.6998 53.2349 55.6998 51.3706 55.7148 49.5465C55.7148 48.9872 56.0058 48.5557 56.177 48.2984L56.2219 48.2313L56.4587 47.8659C56.9106 47.1659 57.3784 46.4426 57.8753 45.7397C59.3041 43.7198 59.7186 41.3588 59.1431 38.5093C58.9279 37.4486 58.6295 36.4102 58.3123 35.3112C58.1392 34.7081 57.9595 34.0836 57.7958 33.46C57.632 32.8365 57.6152 32.3313 57.7293 31.8754C58.4264 29.0838 59.1544 26.252 59.8589 23.5134L60.2426 22.022C60.3038 21.793 60.4204 21.5825 60.5822 21.4087C60.5944 21.3938 60.6112 21.3752 60.6318 21.3491L62.2225 19.3254L63.0216 21.7406C63.1413 22.1041 63.262 22.4611 63.3809 22.8134C63.6419 23.5899 63.8889 24.3234 64.1229 25.0822C65.7369 30.302 66.885 35.1146 67.6317 39.7966C68.7844 47.0242 65.0604 54.1092 58.3684 57.4247C58.1813 57.518 57.9997 57.5953 57.8388 57.6643L57.6414 57.7491L55.7017 58.6085L55.6998 56.5635Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2318 63.8426C27.1286 63.8146 20.4422 63.5173 13.986 61.4498C10.8421 60.4422 8.239 59.1261 6.02704 57.424C3.16478 55.2214 1.39914 52.1557 0.626269 48.0535C-0.0240323 44.5962 0.0330445 40.9656 0.80592 36.6322C0.891067 36.1569 1.16335 35.8213 1.32522 35.6209C1.35236 35.5873 1.38885 35.5426 1.40102 35.5277L2.18138 34.3513L3.34818 35.1679C3.37157 35.1819 3.39683 35.1949 3.42209 35.2089C3.66724 35.3422 4.12479 35.5901 4.35778 36.1233C5.55171 38.8963 7.84601 40.8072 11.5785 42.1373C13.9514 42.9827 16.6078 43.4581 19.6993 43.5905C21.4771 43.6669 23.3578 43.7415 25.1973 43.7415C27.8669 43.7415 30.0704 43.5877 32.1308 43.2577C32.3946 43.2158 32.6622 43.1645 32.9448 43.1067L33.1984 43.0564C33.4492 43.0033 33.7046 42.9746 33.961 42.9706C34.5589 42.9706 35.9923 43.1701 36.4078 45.0213C36.7016 46.3262 36.9945 47.661 37.278 48.9483C37.3079 49.0834 37.3379 49.2205 37.3715 49.3565C37.4071 48.9837 37.4423 48.6109 37.4773 48.238C37.6925 45.9646 37.9152 43.6147 38.1407 41.3021C38.1856 40.8417 38.2848 40.1575 38.6263 39.5498C40.1749 36.7888 41.0675 33.8312 42.0135 30.7002C42.1382 30.2814 42.2652 29.8635 42.3943 29.4465C43.4806 25.9045 44.522 22.7223 46.2765 19.7143C47.3675 17.8436 48.5099 16.5078 49.8695 15.5114C51.1925 14.542 52.6195 13.9883 54.1119 13.8672C54.3308 13.8495 54.5451 13.8411 54.7491 13.8411C57.398 13.8411 58.9728 15.3213 59.1842 18.0104C59.3714 20.4563 58.8736 22.7595 58.3009 24.8848C58.0315 25.8859 57.7208 26.8851 57.4205 27.8517C56.8684 29.6302 56.3463 31.3098 56.0431 33.0371C55.4012 36.691 55.0794 40.4353 54.8267 43.9363C54.6396 46.521 54.5994 49.1757 54.5601 51.7437C54.5479 52.4819 54.5367 53.2202 54.5217 53.9584C54.4768 56.2309 53.4925 58.0355 51.5958 59.3209C48.3364 61.5477 44.5554 62.899 40.6184 63.2441C38.704 63.4166 36.7624 63.5452 34.8845 63.6692C34.0424 63.7251 33.1937 63.781 32.3479 63.8416H32.2936L32.2318 63.8426Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.3597 42.045C21.6362 42.045 19.8977 41.9434 18.2677 41.8344C15.339 41.6377 12.9858 41.2211 10.8599 40.5238C5.98404 38.9243 3.34447 35.619 3.01605 30.6984C2.64177 25.1579 3.53535 20.5309 5.72953 16.5526C7.43622 13.4608 9.70058 11.0308 12.4599 9.32964C15.1865 7.65183 18.4184 6.66472 22.0666 6.40653C22.3763 6.38509 22.7019 6.3739 23.035 6.3739C24.2271 6.3739 25.406 6.51279 26.5457 6.64701C27.1857 6.72251 27.7892 6.79335 28.3956 6.84183C28.753 6.87072 29.0936 6.92665 29.4248 6.98071C29.7576 7.04575 30.0947 7.08595 30.4335 7.10095C30.5281 7.10336 30.6225 7.0927 30.7142 7.06926C30.9603 6.99469 31.3579 6.59668 31.6779 6.27603C31.8903 6.06444 32.1093 5.84446 32.3516 5.63567C34.2679 3.98582 36.9131 2.01067 40.3985 1.37869C41.1276 1.24459 41.8673 1.17627 42.6086 1.17456C44.873 1.17456 47.0063 1.82704 48.9395 3.11057C51.1935 4.60195 52.1563 6.53796 51.8569 8.98942L54.6265 9.37718L52.9779 11.1855C52.9124 11.2573 52.8562 11.3253 52.7973 11.3961C52.6236 11.6258 52.4138 11.826 52.176 11.989C46.1726 15.8955 42.544 21.2896 41.0844 28.479C40.8045 29.829 40.4496 31.1624 40.0214 32.4731C38.3999 37.5066 34.688 40.5751 28.9738 41.5976C27.2746 41.9024 25.4379 42.0497 23.3597 42.0497V42.045ZM17.1112 28.1015C16.1982 28.0881 15.2957 28.2967 14.4819 28.7093C13.5462 29.2042 12.9942 29.921 12.9175 30.7198C12.8407 31.5187 13.2506 32.3184 14.073 32.9662C15.0377 33.7268 16.2504 34.1211 17.7774 34.1724C18.933 34.1388 19.8303 33.9114 20.5985 33.4575C21.4275 32.9681 21.9234 32.2895 21.9945 31.5485C22.0657 30.8075 21.7082 30.0571 20.9859 29.427C20.0305 28.5974 18.5821 28.1015 17.1112 28.1015ZM40.9786 7.96409C42.2484 8.07874 43.5462 8.19712 44.829 8.32948C44.8112 7.92028 44.5932 7.71522 44.0552 7.49897C43.6926 7.35224 43.305 7.2763 42.9136 7.27526C42.2091 7.28103 41.5273 7.52377 40.9786 7.96409Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.6801 56.5376V55.0108C55.6801 53.209 55.6801 51.3448 55.6941 49.5206C55.6997 48.9614 55.9851 48.5298 56.1563 48.2725L56.2003 48.2054L56.437 47.84C56.8852 47.14 57.3531 46.4195 57.8527 45.7139C59.2806 43.694 59.6951 41.3283 59.1187 38.4834C58.9044 37.4227 58.6059 36.3843 58.2897 35.2853C58.1166 34.6823 57.9369 34.0578 57.7741 33.4342C57.6113 32.8106 57.5926 32.3054 57.7067 31.8496C58.4029 29.0579 59.1309 26.2261 59.8345 23.4876L60.2172 21.9962C60.2788 21.7673 60.3954 21.5568 60.5568 21.3828C60.5699 21.3679 60.5868 21.3493 60.6074 21.3232L62.198 19.2996L62.9962 21.7147C63.1159 22.0782 63.2366 22.4352 63.3545 22.7875C63.6156 23.564 63.8626 24.2976 64.0965 25.0563C65.7097 30.2762 66.8559 35.0887 67.6025 39.7707C68.7544 46.9983 65.0332 54.0833 58.3393 57.3989C58.1521 57.4921 57.9706 57.5694 57.8097 57.6384L57.6122 57.7232L55.6744 58.5826L55.6801 56.5376Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2337 63.8164C27.1352 63.7884 20.4562 63.491 14.0047 61.4236C10.8636 60.416 8.26333 59.0998 6.05137 57.3978C3.19192 55.1952 1.42722 52.1295 0.656214 48.0272C0.00684918 44.57 0.0639259 40.9394 0.837737 36.6014C0.921949 36.126 1.19423 35.7904 1.35704 35.59C1.38418 35.5565 1.42067 35.5117 1.4319 35.4968L2.21132 34.3205L3.37812 35.137L3.4511 35.178C3.69625 35.3113 4.15287 35.5593 4.38679 36.0924C5.57885 38.8655 7.87128 40.7763 11.6009 42.1064C13.971 42.9519 16.6246 43.4272 19.7142 43.5596C21.4855 43.636 23.369 43.7106 25.2067 43.7106C27.8743 43.7106 30.0723 43.5568 32.1308 43.2268C32.3946 43.1849 32.6613 43.1336 32.9439 43.0758L33.1975 43.0255C33.4482 42.9747 33.7033 42.9481 33.9591 42.9463C34.5551 42.9463 35.9886 43.1458 36.4031 44.9969C36.696 46.3019 36.9888 47.6367 37.2724 48.9239C37.3023 49.0591 37.3322 49.1961 37.3659 49.3322C37.4015 48.9594 37.437 48.5865 37.4726 48.2137C37.6869 45.9402 37.9096 43.5904 38.1351 41.2778C38.18 40.8173 38.2792 40.1332 38.6197 39.5254C40.1674 36.7645 41.06 33.8069 42.0051 30.6759C42.1298 30.2571 42.2564 29.8392 42.3849 29.4222C43.4694 25.8802 44.5108 22.6979 46.2634 19.69C47.3544 17.8192 48.495 16.4835 49.8536 15.4871C51.1748 14.5177 52.6008 13.964 54.0922 13.8428C54.3102 13.8251 54.5245 13.8158 54.7285 13.8158C57.3746 13.8158 58.9484 15.2969 59.1599 17.9861C59.347 20.432 58.8493 22.7399 58.2775 24.8604C58.0081 25.8615 57.6974 26.8608 57.398 27.8274C56.846 29.6058 56.3238 31.2855 56.0216 33.0127C55.3797 36.6666 55.0588 40.4109 54.8052 43.912C54.6181 46.4967 54.5788 49.1514 54.5386 51.7193C54.5273 52.4576 54.5149 53.1958 54.5011 53.9341C54.4553 56.2066 53.4719 58.0111 51.5771 59.2965C48.3193 61.5223 44.5396 62.8721 40.6043 63.2151C38.6927 63.3876 36.7531 63.5162 34.8761 63.6402C34.034 63.6961 33.1862 63.752 32.3422 63.8126H32.288L32.2337 63.8164Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.369 42.0189C21.6474 42.0189 19.907 41.9173 18.2817 41.8083C15.3558 41.6116 13.0044 41.1949 10.8804 40.4977C6.00833 38.8982 3.37157 35.5929 3.04408 30.6723C2.67448 25.1318 3.56245 20.5048 5.75757 16.5265C7.46332 13.4347 9.7258 11.0046 12.4823 9.30352C15.2061 7.62571 18.4352 6.6386 22.0806 6.3804C22.3894 6.35896 22.715 6.34778 23.0481 6.34778C24.2383 6.34778 25.4163 6.48666 26.555 6.62089C27.1941 6.69639 27.7976 6.76723 28.4039 6.8157C28.7604 6.8446 29.101 6.90052 29.4332 6.95459C29.7656 7.01957 30.1025 7.05976 30.4409 7.07483C30.5355 7.07724 30.63 7.06657 30.7216 7.04314C30.9677 6.96857 31.3654 6.57056 31.6845 6.24991C31.8969 6.03832 32.1158 5.81834 32.3581 5.60954C34.2726 3.9597 36.9159 1.98455 40.3929 1.35257C41.1216 1.21847 41.861 1.15015 42.602 1.14844C44.8636 1.14844 46.9923 1.80092 48.9272 3.08444C51.1785 4.57583 52.1404 6.51183 51.8419 8.96236L54.6087 9.35106L52.961 11.1547C52.8946 11.2265 52.8393 11.2945 52.7804 11.3654C52.6073 11.5952 52.3978 11.7954 52.16 11.9582C46.1614 15.8647 42.5365 21.2588 41.0778 28.4482C40.7987 29.7983 40.4441 31.1317 40.0158 32.4424C38.3961 37.4758 34.6824 40.5443 28.9747 41.5668C27.2764 41.8716 25.4416 42.0189 23.3653 42.0189H23.369ZM17.1261 28.0754C16.2137 28.0617 15.3117 28.2703 14.4987 28.6831C13.563 29.1781 13.0119 29.8949 12.9352 30.6937C12.8585 31.4925 13.2683 32.2923 14.0898 32.9392C15.0536 33.7007 16.2653 34.095 17.7914 34.1463C18.9451 34.1127 19.8415 33.8853 20.6097 33.4313C21.4378 32.941 21.9327 32.2634 22.0039 31.5224C22.075 30.7813 21.7175 30.031 20.9971 29.4009C20.0436 28.5713 18.5961 28.0754 17.1261 28.0754ZM40.9711 7.9417C42.2399 8.05635 43.5368 8.17473 44.8177 8.30709C44.7999 7.89789 44.5829 7.69282 44.0448 7.47657C43.6826 7.32967 43.2953 7.25372 42.9042 7.25286C42.2008 7.25778 41.5196 7.49919 40.9711 7.93797V7.9417Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.6615 56.5114C55.6615 56.0006 55.6615 55.4917 55.6615 54.9846C55.6615 53.1828 55.6615 51.3186 55.6755 49.4944C55.6812 48.9352 55.9665 48.5036 56.1378 48.2463L56.1817 48.1792L56.4175 47.8176C56.8695 47.1175 57.3364 46.3942 57.8332 45.6914C59.2592 43.6715 59.6728 41.3105 59.0973 38.461C58.8831 37.4002 58.5846 36.3619 58.2693 35.2629C58.0952 34.6598 57.9165 34.0353 57.7537 33.4117C57.5909 32.7881 57.5722 32.2829 57.6863 31.8271C58.3825 29.0354 59.1095 26.2037 59.8122 23.4651L60.1949 21.9737C60.2564 21.7445 60.373 21.5337 60.5345 21.3594C60.5467 21.3455 60.5636 21.3268 60.5841 21.3007L62.1748 19.2771L62.972 21.6922C63.0918 22.0557 63.2112 22.4134 63.3304 22.7651C63.5914 23.5415 63.8375 24.2751 64.0714 25.0338C65.6827 30.2537 66.8289 35.0662 67.5737 39.7482C68.7246 46.9759 65.0071 54.0609 58.3226 57.3764C58.1354 57.4696 57.9549 57.547 57.7939 57.616C57.7275 57.6439 57.662 57.6719 57.5974 57.7008L55.6615 58.5602V56.5114Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2347 63.7902C27.1417 63.7623 20.4684 63.4649 14.0225 61.3975C10.8851 60.3899 8.28581 59.0737 6.07759 57.3717C3.22001 55.1691 1.45625 52.1052 0.686179 48.0039C0.0368144 44.5467 0.0938912 40.9198 0.865831 36.578C0.950043 36.1027 1.22233 35.7671 1.3842 35.5667C1.41134 35.5331 1.44783 35.4884 1.45999 35.4735L2.24129 34.2916L3.40621 35.1081C3.42961 35.1221 3.45487 35.1351 3.48013 35.1491C3.72528 35.2824 4.18096 35.5303 4.4102 36.0635C5.60133 38.8365 7.89189 40.7474 11.615 42.0775C13.9832 42.9229 16.6349 43.3983 19.7217 43.5307C21.4911 43.6071 23.3709 43.6817 25.2095 43.6817C27.8744 43.6817 30.0751 43.5279 32.1336 43.1979C32.3965 43.156 32.6632 43.1047 32.9458 43.0469L33.1993 42.9966C33.4497 42.9457 33.7045 42.9192 33.9601 42.9174C34.5561 42.9174 35.9877 43.1168 36.4013 44.968C36.6951 46.273 36.987 47.6078 37.2705 48.895C37.3005 49.0302 37.3304 49.1672 37.3641 49.3033C37.3996 48.9304 37.4349 48.5576 37.4698 48.1847C37.685 45.9113 37.9068 43.5614 38.1323 41.2489C38.1772 40.7884 38.2764 40.1042 38.617 39.4965C40.1627 36.7356 41.0544 33.778 41.9985 30.647C42.1248 30.2285 42.2502 29.8081 42.3784 29.3933C43.4619 25.8512 44.5024 22.669 46.2531 19.6611C47.3432 17.7903 48.4828 16.4546 49.8396 15.4581C51.1607 14.4887 52.5858 13.9351 54.0754 13.8139C54.2934 13.7962 54.5068 13.7869 54.7107 13.7869C57.355 13.7869 58.9269 15.268 59.1384 17.9572C59.3255 20.403 58.8278 22.7109 58.256 24.8315C57.9875 25.8326 57.6769 26.8318 57.3774 27.7975C56.8263 29.5769 56.3051 31.2566 56.002 32.9838C55.362 36.6377 55.0401 40.3811 54.7856 43.883C54.5985 46.4678 54.5592 49.1224 54.5189 51.6904C54.5077 52.4287 54.4952 53.1669 54.4815 53.9051C54.4366 56.1776 53.4522 57.9822 51.5603 59.2676C48.308 61.494 44.5334 62.8454 40.6025 63.1909C38.6918 63.3633 36.754 63.4919 34.8798 63.6159C34.0377 63.6718 33.1956 63.7278 32.3469 63.7884H32.2927L32.2347 63.7902Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.3784 41.9927C21.6586 41.9927 19.9164 41.8911 18.2958 41.7821C15.3727 41.5854 13.0232 41.1688 10.9039 40.4715C6.0383 38.872 3.40248 35.5667 3.07499 30.6461C2.70072 25.1056 3.59242 20.4786 5.78848 16.5003C7.49236 13.4085 9.75297 10.9785 12.5067 9.27734C15.2286 7.59953 18.4548 6.61242 22.0965 6.35423C22.4053 6.33279 22.7309 6.3216 23.0631 6.3216C24.2523 6.3216 25.4294 6.46049 26.5672 6.59471C27.2063 6.67021 27.8089 6.74105 28.4143 6.78952C28.7708 6.81842 29.1113 6.87435 29.4435 6.92841C29.7756 6.99349 30.1122 7.03369 30.4503 7.04865C30.5449 7.0511 30.6394 7.04043 30.731 7.01696C30.9771 6.94239 31.3748 6.54438 31.6938 6.22373C31.9053 6.01121 32.1243 5.79216 32.3657 5.58337C34.2782 3.93352 36.9196 1.95837 40.3938 1.32546C41.1194 1.19126 41.8556 1.12201 42.5936 1.11853C44.8542 1.11853 46.9801 1.77101 48.9142 3.05454C51.1598 4.54592 52.1245 6.48193 51.826 8.93246L54.59 9.32115L52.9442 11.1248C52.8777 11.1966 52.8225 11.2646 52.7636 11.3355C52.5905 11.5653 52.3809 11.7655 52.1432 11.9283C46.1502 15.8385 42.5291 21.2327 41.0722 28.4221C40.793 29.772 40.4388 31.1054 40.0111 32.4162C38.3933 37.4496 34.6824 40.5181 28.9832 41.5407C27.2877 41.8455 25.4547 41.9927 23.3803 41.9927H23.3784ZM17.1411 28.0492C16.2312 28.0363 15.3318 28.2449 14.5212 28.657C13.5902 29.1519 13.0354 29.8687 12.9586 30.6675C12.8819 31.4664 13.2917 32.2661 14.1123 32.913C15.0751 33.6745 16.2859 34.0688 17.8102 34.1201C18.9639 34.0865 19.8593 33.8591 20.6266 33.4052C21.4537 32.9149 21.9487 32.2372 22.0198 31.4962C22.0909 30.7552 21.7335 30.0048 21.013 29.3747C20.0558 28.5451 18.6102 28.0492 17.1411 28.0492ZM40.9655 7.91552C42.2325 8.03017 43.5284 8.14855 44.8084 8.28091C44.7906 7.87171 44.5735 7.66664 44.0365 7.45039C43.6745 7.30368 43.2876 7.22773 42.8968 7.22669C42.1939 7.23129 41.5132 7.47276 40.9655 7.91179V7.91552Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.6417 56.486C55.6417 55.9752 55.6417 55.4662 55.6417 54.9591C55.6417 53.1574 55.6417 51.2931 55.6558 49.469C55.6614 48.9097 55.9468 48.4781 56.117 48.2209L56.162 48.1538L56.3978 47.7884C56.8488 47.0884 57.3147 46.365 57.8116 45.6622C59.2357 43.6423 59.6493 41.2766 59.0748 38.4318C58.8605 37.3711 58.5629 36.3327 58.2467 35.2337C58.0736 34.6306 57.8948 34.0061 57.732 33.3825C57.5692 32.7589 57.5515 32.2537 57.6656 31.7979C58.3599 29.0062 59.0869 26.1745 59.7896 23.4359L60.1714 21.9445C60.233 21.7156 60.3492 21.5049 60.5101 21.3303C60.5232 21.3163 60.5391 21.2976 60.5606 21.2715L62.1513 19.2479L62.9475 21.663C63.0673 22.0266 63.1868 22.3842 63.3059 22.7359C63.566 23.5124 63.8131 24.2459 64.046 25.0047C65.6564 30.2245 66.8007 35.037 67.5455 39.7191C68.6955 46.9467 64.9808 54.0317 58.3028 57.3472C58.1157 57.4404 57.9351 57.5178 57.7741 57.5868C57.7077 57.6147 57.6422 57.6427 57.5777 57.6716L55.6436 58.531L55.6417 56.486Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2365 63.7642C27.1473 63.7363 20.4806 63.4389 14.0412 61.3715C10.9057 60.3639 8.3092 59.0477 6.10379 57.3457C3.24808 55.1431 1.48526 52.0774 0.716122 47.9751C0.0676929 44.5179 0.12477 40.891 0.895774 36.5492C0.979985 36.0739 1.25133 35.7383 1.41414 35.5379C1.44034 35.5043 1.47683 35.4596 1.489 35.4447L2.26749 34.2684L3.43148 35.0849L3.50446 35.1259C3.74961 35.2592 4.20529 35.5071 4.43453 36.0403C5.62472 38.8134 7.91247 40.7242 11.6393 42.0543C14.0057 42.8997 16.6546 43.3751 19.7377 43.5075C21.5061 43.5839 23.3868 43.6585 25.2208 43.6585C27.8837 43.6585 30.0807 43.5047 32.1364 43.1747C32.3993 43.1328 32.6651 43.0815 32.9477 43.0237L33.2012 42.9734C33.4513 42.9225 33.7058 42.896 33.961 42.8942C34.5561 42.8942 35.9868 43.0936 36.4003 44.9448C36.6932 46.2498 36.9861 47.5846 37.2686 48.8718L37.3622 49.2801C37.3978 48.9072 37.433 48.5344 37.4679 48.1615C37.6822 45.8881 37.9049 43.5382 38.1295 41.2257C38.1744 40.7652 38.2736 40.081 38.6142 39.4733C40.158 36.7124 41.0469 33.7548 41.992 30.6228C42.1183 30.2053 42.2437 29.7839 42.3719 29.3701C43.4545 25.828 44.494 22.6458 46.2437 19.6379C47.3319 17.7671 48.4707 16.4314 49.8265 15.435C51.1458 14.4656 52.5699 13.9119 54.0576 13.7907C54.2756 13.773 54.4899 13.7637 54.6939 13.7637C57.3344 13.7637 58.9045 15.2448 59.1159 17.934C59.3031 20.3798 58.8062 22.6877 58.2355 24.8083C57.966 25.8094 57.6572 26.8086 57.3578 27.7743C56.7992 29.5481 56.278 31.2259 55.9758 32.9559C55.3358 36.6098 55.0148 40.3532 54.7594 43.8552C54.5723 46.4399 54.533 49.0946 54.4937 51.6626C54.4815 52.4008 54.4703 53.139 54.4553 53.8773C54.4104 56.1498 53.426 57.9543 51.5369 59.2397C48.2883 61.4659 44.5171 62.8174 40.5894 63.163C38.6806 63.3354 36.7437 63.4641 34.8714 63.5881C34.0293 63.644 33.1872 63.6999 32.3451 63.7605H32.2908L32.2365 63.7642Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.3877 41.9668C21.6698 41.9668 19.9313 41.8652 18.3098 41.7561C15.3895 41.5595 13.0419 41.1428 10.9225 40.4456C6.05697 38.8461 3.42676 35.5408 3.09927 30.6201C2.73155 25.0797 3.61671 20.4526 5.80808 16.4744C7.51103 13.3825 9.76883 10.9525 12.5207 9.25139C15.2398 7.57359 18.4632 6.58647 22.1021 6.32828C22.4099 6.30684 22.7356 6.29566 23.0677 6.29566C24.256 6.29566 25.4313 6.43454 26.5691 6.56877C27.2063 6.64427 27.8088 6.71511 28.4142 6.76358C28.7698 6.79247 29.1104 6.8484 29.4388 6.90246C29.7706 6.96749 30.1068 7.00768 30.4447 7.02271C30.5392 7.02521 30.6337 7.01455 30.7254 6.99101C30.9714 6.91644 31.3682 6.51843 31.6872 6.19778C31.8987 5.98526 32.1167 5.76621 32.3591 5.55742C34.2697 3.90757 36.9084 1.93242 40.3798 1.29951C41.107 1.16618 41.8448 1.09817 42.5842 1.09631C44.842 1.09631 46.967 1.74879 48.8982 3.03232C51.1439 4.5237 52.1058 6.45971 51.8073 8.91024L54.5704 9.29893L52.9273 11.1026C52.8609 11.1743 52.8056 11.2424 52.7467 11.3132C52.5737 11.5429 52.3645 11.7431 52.1273 11.9061C46.1389 15.8126 42.5206 21.2067 41.0647 28.3961C40.7862 29.7461 40.4323 31.0796 40.0045 32.3902C38.3877 37.4237 34.6814 40.4922 28.9869 41.5147C27.2923 41.8195 25.4612 41.9668 23.3887 41.9668H23.3877ZM17.157 28.0233C16.247 28.0102 15.3476 28.2188 14.5371 28.631C13.6079 29.126 13.0531 29.8428 12.9764 30.6416C12.8996 31.4404 13.3095 32.2402 14.1291 32.8871C15.0919 33.6486 16.3008 34.0429 17.8241 34.0941C18.976 34.0606 19.8714 33.8331 20.6377 33.3792C21.464 32.8889 21.958 32.2113 22.03 31.4702C22.1021 30.7292 21.7437 29.9788 21.0242 29.3487C20.0688 28.5229 18.6242 28.0233 17.157 28.0233ZM40.9589 7.88957C42.2249 8.00422 43.5199 8.1226 44.7953 8.25496C44.7784 7.84576 44.5613 7.6407 44.0242 7.42445C43.6626 7.27765 43.276 7.20169 42.8855 7.20074C42.1842 7.20617 41.5052 7.44761 40.9589 7.88584V7.88957Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.6221 56.4591C55.6221 55.9483 55.6221 55.4394 55.6221 54.9323C55.6221 53.1305 55.6221 51.2663 55.6362 49.4421C55.6418 48.8829 55.9272 48.4513 56.0975 48.194L56.1414 48.1269L56.3772 47.7615C56.8282 47.0615 57.2942 46.3382 57.7901 45.6354C59.2133 43.6155 59.6259 41.2544 59.0523 38.4049C58.8381 37.3442 58.5405 36.3058 58.2252 35.2069C58.0521 34.6038 57.8734 33.9793 57.7106 33.3557C57.5478 32.7321 57.53 32.2259 57.6441 31.7711C58.3384 28.9794 59.0645 26.1476 59.7663 23.4091L60.148 21.9177C60.2097 21.6888 60.3259 21.4781 60.4868 21.3034C60.4989 21.2894 60.5158 21.2708 60.5363 21.2447L62.127 19.2211L62.9233 21.6362C63.043 21.9997 63.1625 22.3573 63.2816 22.709C63.5418 23.4855 63.7879 24.2191 64.0218 24.9778C65.6293 30.1977 66.7727 35.0102 67.5175 39.6922C68.6665 46.9198 64.9556 54.0048 58.2832 57.3204C58.0961 57.4136 57.9155 57.4909 57.7546 57.5599L57.5581 57.6447L55.6259 58.5042L55.6221 56.4591Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2384 63.738C27.1539 63.71 20.4927 63.4127 14.059 61.3452C10.9263 60.3376 8.33257 59.0215 6.12903 57.3194C3.27519 55.1168 1.51423 52.0511 0.744166 47.9489C0.0957371 44.4916 0.152814 40.8648 0.922882 36.523C1.00803 36.0476 1.27938 35.712 1.44125 35.5116C1.46745 35.4781 1.50488 35.4333 1.51611 35.4184L2.29366 34.2421L3.45765 35.0558L3.53063 35.0969C3.77578 35.2301 4.23053 35.4781 4.45977 36.0113C5.64902 38.7843 7.9349 40.6951 11.6542 42.0253C14.0187 42.8707 16.6658 43.3461 19.746 43.4784C21.5126 43.5549 23.3952 43.6294 25.2245 43.6294C27.8846 43.6294 30.0798 43.4756 32.1326 43.1457C32.3956 43.1037 32.6622 43.0525 32.9439 42.9947L33.1965 42.9443C33.4466 42.8935 33.7011 42.8669 33.9563 42.8651C34.5514 42.8651 35.9802 43.0646 36.3937 44.9158C36.6866 46.2207 36.9786 47.5555 37.2611 48.8428C37.2911 48.9779 37.321 49.1149 37.3547 49.251C37.3903 48.8782 37.4255 48.5053 37.4604 48.1325C37.6747 45.8591 37.8965 43.5092 38.121 41.1966C38.1659 40.7362 38.2651 40.052 38.6048 39.4442C40.1477 36.6833 41.0376 33.7257 41.9807 30.5938C42.1061 30.1762 42.2315 29.7549 42.3597 29.341C43.4413 25.799 44.4799 22.6168 46.2278 19.6088C47.3151 17.7381 48.4529 16.4023 49.8077 15.4059C51.1261 14.4365 52.5483 13.8828 54.0351 13.7616C54.2532 13.7439 54.4665 13.7346 54.6705 13.7346C57.3091 13.7346 58.8811 15.2158 59.0888 17.9049C59.2806 20.3508 58.7791 22.6587 58.2092 24.7793C57.9398 25.7804 57.631 26.7796 57.3316 27.7453C56.7814 29.5247 56.2611 31.2043 55.9589 32.9315C55.3198 36.5854 54.9989 40.3288 54.7425 43.8308C54.5554 46.4155 54.5161 49.0702 54.4768 51.6382C54.4656 52.3764 54.4531 53.1147 54.4394 53.8529C54.3935 56.1254 53.4101 57.93 51.5238 59.2154C48.2782 61.4412 44.5097 62.7927 40.5847 63.1386C38.6778 63.3111 36.7437 63.4397 34.8723 63.5637C34.0302 63.6196 33.1881 63.6755 32.346 63.7361H32.2926L32.2384 63.738Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.3971 41.9452C21.6801 41.9452 19.9444 41.8436 18.3238 41.7346C15.4063 41.5379 13.0606 41.1212 10.9431 40.424C6.08412 38.8198 3.45765 35.5136 3.12736 30.5939C2.75963 25.0534 3.64479 20.4264 5.83336 16.4481C7.53443 13.3563 9.79037 10.9263 12.5403 9.22515C15.2538 7.54734 18.4773 6.56023 22.1124 6.30203C22.4212 6.2806 22.7449 6.26941 23.0771 6.26941C24.2645 6.26941 25.4397 6.4083 26.5756 6.54252C27.2128 6.61802 27.8145 6.68886 28.4189 6.73733C28.7745 6.76623 29.1141 6.82215 29.4426 6.87622C29.7744 6.94124 30.1106 6.98144 30.4484 6.99646C30.543 6.99891 30.6375 6.98824 30.7291 6.96477C30.9743 6.8902 31.371 6.49219 31.6891 6.17154C31.9006 5.95902 32.1186 5.73997 32.36 5.53117C34.2698 3.88133 36.9056 1.90618 40.3742 1.27327C41.1008 1.1399 41.8379 1.07189 42.5768 1.07007C44.8327 1.07007 46.9548 1.72255 48.8851 3.00607C51.1308 4.49746 52.0899 6.43346 51.7914 8.884L54.5517 9.27269L52.9077 11.0763C52.8422 11.1481 52.787 11.2161 52.728 11.287C52.555 11.5166 52.3458 11.7168 52.1086 11.8798C46.1249 15.7863 42.5131 21.1805 41.0581 28.3699C40.7799 29.7197 40.4266 31.0532 39.9999 32.364C38.384 37.3974 34.6805 40.4659 28.9916 41.4885C27.2989 41.7923 25.4687 41.9406 23.398 41.9406L23.3971 41.9452ZM17.172 27.997C16.262 27.9835 15.3625 28.1922 14.5521 28.6048C13.6229 29.0997 13.069 29.8165 12.9932 30.6153C12.9174 31.4142 13.3254 32.213 14.1441 32.8608C15.106 33.6223 16.314 34.0166 17.8354 34.0679C18.9872 34.0343 19.8808 33.8069 20.6471 33.353C21.4724 32.8627 21.9664 32.185 22.0375 31.444C22.1087 30.703 21.7568 29.9526 21.0326 29.3225C20.081 28.4929 18.6373 27.997 17.172 27.997ZM40.9524 7.86333C42.2175 7.97798 43.5106 8.09636 44.7887 8.22872C44.7709 7.81952 44.5548 7.61445 44.0177 7.3982C43.6568 7.25142 43.2707 7.17546 42.8809 7.1745C42.1788 7.17929 41.4991 7.42077 40.9524 7.8596V7.86333Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.6024 56.4329C55.6024 55.9221 55.6024 55.4131 55.6024 54.906C55.6024 53.1043 55.6024 51.24 55.6174 49.4159C55.6174 48.8566 55.9075 48.425 56.0777 48.1678L56.1217 48.1007L56.3575 47.7353C56.8076 47.0353 57.2736 46.3119 57.7685 45.6091C59.1908 43.5892 59.6034 41.2235 59.0298 38.3787C58.8165 37.318 58.518 36.2796 58.2036 35.1806C58.0305 34.5775 57.8518 33.953 57.689 33.3294C57.5262 32.7058 57.5084 32.1997 57.6226 31.7448C58.3159 28.9531 59.0411 26.1214 59.7428 23.3828L60.1246 21.8914C60.1856 21.6625 60.3015 21.4517 60.4624 21.2772C60.4755 21.2632 60.4914 21.2445 60.5129 21.2184L62.1036 19.1948L62.8989 21.6099C63.0187 21.9735 63.1381 22.3311 63.2573 22.6828C63.5174 23.4593 63.7625 24.1928 63.9964 24.9516C65.603 30.1714 66.7455 34.9839 67.4894 39.666C68.6365 46.8936 64.9293 53.9786 58.2626 57.2941C58.0754 57.3873 57.8958 57.4647 57.7348 57.5337L57.5393 57.6185L55.608 58.477L55.6024 56.4329Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2402 63.7121C27.1595 63.6841 20.5049 63.3868 14.0767 61.3194C10.9431 60.308 8.35596 58.9956 6.15336 57.2936C3.30327 55.0882 1.54418 52.0252 0.775984 47.923C0.12849 44.462 0.18276 40.8352 0.9547 36.4971C1.03891 36.0217 1.31026 35.6862 1.47213 35.4858C1.49927 35.4522 1.53576 35.4075 1.54699 35.3926L2.32361 34.2162L3.48573 35.0328C3.50912 35.0467 3.53438 35.0598 3.55965 35.0738C3.80386 35.2071 4.2586 35.455 4.48691 35.9882C5.67523 38.7612 7.95923 40.6721 11.6758 42.0022C14.0374 42.8467 16.6817 43.323 19.7601 43.4554C21.5248 43.5318 23.4018 43.6064 25.2329 43.6064C27.8903 43.6064 30.0844 43.4526 32.1355 43.1226C32.3984 43.0807 32.6641 43.0294 32.9458 42.9716L33.1984 42.9213C33.4482 42.8704 33.7023 42.8439 33.9572 42.842C34.5514 42.842 35.9792 43.0415 36.39 44.8927C36.6819 46.1976 36.9739 47.5324 37.2565 48.8188C37.2855 48.9548 37.3154 49.0919 37.3453 49.228C37.3809 48.8551 37.4161 48.4823 37.4511 48.1094C37.6653 45.836 37.8871 43.4861 38.1117 41.1735C38.1566 40.7131 38.2558 40.0289 38.5954 39.4212C40.1365 36.6602 41.0282 33.7026 41.9639 30.5707C42.0886 30.1531 42.2134 29.7355 42.3382 29.318C43.4198 25.7759 44.4575 22.5937 46.2035 19.5857C47.2898 17.715 48.4267 16.3793 49.7806 15.3828C51.0971 14.4134 52.5184 13.8598 54.0043 13.7386C54.2214 13.7209 54.4347 13.7115 54.6387 13.7115C57.2754 13.7115 58.8427 15.1927 59.0532 17.8818C59.245 20.3277 58.7435 22.6356 58.1737 24.7562C57.9051 25.7573 57.5964 26.7565 57.2979 27.7222C56.7477 29.5016 56.2284 31.1813 55.9262 32.9085C55.2871 36.5624 54.9671 40.3057 54.7163 43.8077C54.5292 46.3925 54.4899 49.0471 54.4497 51.6151C54.4384 52.3533 54.426 53.0916 54.4122 53.8298C54.3673 56.1023 53.383 57.9069 51.4994 59.1923C48.2576 61.418 44.4925 62.7696 40.5706 63.1156C38.6656 63.288 36.7343 63.4166 34.863 63.5406C34.0209 63.5965 33.1787 63.6525 32.3366 63.713H32.2824L32.2402 63.7121Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.4075 41.9144C21.6914 41.9144 19.9566 41.8128 18.3379 41.7038C15.4233 41.5071 13.0803 41.0904 10.9638 40.3932C6.10851 38.7937 3.4783 35.4884 3.15549 30.5678C2.78777 25.0273 3.67199 20.4003 5.85869 16.422C7.55789 13.3302 9.81289 10.9001 12.5591 9.19903C15.2726 7.52122 18.4914 6.53411 22.1237 6.27591C22.4315 6.25447 22.7553 6.24329 23.0874 6.24329C24.273 6.24329 25.4472 6.38217 26.5822 6.5164C27.2185 6.5919 27.8201 6.66274 28.4237 6.71121C28.7792 6.7401 29.1189 6.79603 29.4473 6.85009C29.7785 6.91511 30.1141 6.9553 30.4513 6.97034C30.5459 6.97278 30.6404 6.96212 30.732 6.93865C30.9771 6.86408 31.3729 6.46606 31.6911 6.14541C31.9025 5.93289 32.1205 5.71385 32.362 5.50505C34.2698 3.85521 36.9028 1.88005 40.3686 1.24715C41.0946 1.11381 41.8311 1.04581 42.5693 1.04395C44.8225 1.04395 46.9437 1.69643 48.8721 2.97995C51.1178 4.47133 52.074 6.40734 51.7727 8.85787L54.5302 9.24656L52.8881 11.0502C52.8226 11.122 52.7674 11.19 52.7084 11.2609C52.5358 11.4905 52.3269 11.6907 52.0899 11.8537C46.1128 15.7602 42.501 21.1543 41.0489 28.3438C40.7706 29.6936 40.4174 31.0271 39.9906 32.3379C38.3765 37.3713 34.6759 40.4398 28.9926 41.4623C27.3008 41.7662 25.4725 41.9144 23.4037 41.9144H23.4075ZM17.187 27.9709C16.277 27.9569 15.3773 28.1656 14.5671 28.5786C13.6389 29.0736 13.085 29.7904 13.0092 30.5892C12.9334 31.388 13.3414 32.1869 14.1591 32.8347C15.1201 33.5962 16.3271 33.9905 17.8476 34.0418C18.9976 34.0082 19.8911 33.7808 20.6547 33.3268C21.4799 32.8365 21.973 32.1589 22.0442 31.4179C22.1153 30.6768 21.7635 29.9265 21.0411 29.2964C20.0933 28.4668 18.6514 27.9709 17.187 27.9709ZM40.9469 7.82975C42.21 7.9444 43.5022 8.06278 44.7832 8.19514C44.7654 7.78594 44.5492 7.58087 44.0131 7.36462C43.6521 7.21784 43.2661 7.14188 42.8762 7.14091C42.1733 7.14596 41.493 7.38886 40.9469 7.82975Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.5828 56.4069V54.8801C55.5828 53.0783 55.5828 51.2141 55.5969 49.3899C55.6025 48.8307 55.8869 48.3991 56.0572 48.1418L56.1012 48.0747L56.336 47.7093C56.7861 47.0093 57.2512 46.286 57.7461 45.5832C59.1674 43.5633 59.5791 41.2022 59.0065 38.3527C58.7932 37.292 58.4956 36.2536 58.1812 35.1547C58.0081 34.5516 57.8294 33.9271 57.6666 33.3035C57.5038 32.6799 57.4869 32.1737 57.6011 31.7189C58.2935 28.9272 59.0177 26.0954 59.7186 23.3569L60.1003 21.8655C60.1623 21.6366 60.2792 21.4261 60.4409 21.2521C60.4531 21.2382 60.4699 21.2195 60.4905 21.1934L62.0755 19.1698L62.8709 21.5849C62.9906 21.9484 63.1095 22.3061 63.2274 22.6578C63.4875 23.4342 63.7336 24.1678 63.9666 24.9265C65.5722 30.1464 66.7137 34.9589 67.4557 39.6409C68.6029 46.8686 64.8985 53.9536 58.2383 57.2691C58.0512 57.3623 57.8715 57.4397 57.7115 57.5087C57.6451 57.5366 57.5796 57.5646 57.515 57.5935L55.5856 58.452L55.5828 56.4069Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2412 63.685C27.1661 63.658 20.5171 63.3607 14.0946 61.2932C10.9675 60.2856 8.37845 58.9695 6.17866 57.2674C3.33044 55.0648 1.57322 51.9991 0.805958 47.8969C0.1594 44.4396 0.215541 40.8128 0.984674 36.471C1.06889 35.9956 1.3393 35.66 1.50117 35.4596C1.52831 35.4261 1.5648 35.3813 1.57603 35.3664L2.35265 34.1901L3.51383 35.0066L3.58681 35.0476C3.83103 35.1809 4.28577 35.4289 4.51408 35.962C5.70333 38.7323 7.98266 40.6403 11.6917 41.9733C14.0515 42.8178 16.6939 43.2941 19.7685 43.4264C21.5323 43.5029 23.4074 43.5774 25.2376 43.5774C27.8922 43.5774 30.0835 43.4236 32.1336 43.0937C32.3956 43.0517 32.6613 43.0005 32.943 42.9427L33.1956 42.8923C33.4451 42.8415 33.6989 42.8149 33.9535 42.8131C34.5477 42.8131 35.9737 43.0126 36.3863 44.8638C36.6782 46.1687 36.9702 47.5035 37.2518 48.7898L37.3454 49.199C37.3809 48.8262 37.4162 48.4533 37.4511 48.0805C37.6654 45.8071 37.8862 43.4572 38.1108 41.1446C38.1557 40.6841 38.2549 40 38.5936 39.3922C40.1337 36.6313 41.0264 33.6737 41.9621 30.5418C42.0868 30.1242 42.2116 29.7066 42.3363 29.289C43.417 25.747 44.4538 22.5647 46.1979 19.5568C47.2842 17.6861 48.4192 16.3503 49.7722 15.3539C51.0878 14.3845 52.5072 13.8308 53.9921 13.7096C54.2092 13.6919 54.4226 13.6826 54.6256 13.6826C57.2596 13.6826 58.8259 15.1637 59.0364 17.8529C59.2236 20.2988 58.7276 22.6067 58.1578 24.7273C57.8893 25.7284 57.5814 26.7276 57.282 27.6933C56.7328 29.4727 56.2135 31.1523 55.9122 32.8795C55.274 36.5334 54.954 40.2768 54.7033 43.7788C54.5161 46.3635 54.4768 49.0182 54.4366 51.5862C54.4254 52.3244 54.4129 53.0626 54.3992 53.8009C54.3543 56.0734 53.3755 57.878 51.4892 59.1633C48.251 61.3887 44.4893 62.7404 40.5707 63.0866C38.6666 63.2591 36.7344 63.3877 34.8677 63.5117C34.0256 63.5676 33.1835 63.6235 32.3413 63.6841H32.2871L32.2412 63.685Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.4167 41.8883C21.7025 41.8883 19.9697 41.7867 18.3519 41.6776C15.44 41.481 13.0989 41.0643 10.9843 40.3671C6.13369 38.7676 3.5091 35.4623 3.18254 30.5417C2.81576 25.0012 3.69904 20.3741 5.88386 16.3959C7.58213 13.304 9.83432 10.874 12.5787 9.1729C15.2922 7.4951 18.5053 6.50798 22.1339 6.24979C22.4417 6.22835 22.7655 6.21716 23.0967 6.21716C24.2822 6.21716 25.4546 6.35605 26.5887 6.49027C27.225 6.56578 27.8257 6.63662 28.4292 6.68509C28.7838 6.71398 29.1235 6.76991 29.451 6.82397C29.7818 6.88898 30.1171 6.92917 30.454 6.94421C30.5486 6.94672 30.6431 6.93606 30.7347 6.91252C30.9799 6.83795 31.3757 6.43994 31.6929 6.11929C31.9024 5.90677 32.1242 5.68586 32.3703 5.47893C34.2753 3.82908 36.9074 1.85393 40.3695 1.22102C41.0945 1.0877 41.8301 1.01969 42.5674 1.01782C44.8196 1.01782 46.938 1.6703 48.8645 2.95383C51.1102 4.44521 52.0636 6.38122 51.7652 8.83175L54.5198 9.22044L52.8796 11.0241C52.8141 11.0959 52.7589 11.1639 52.6999 11.2347C52.5274 11.4641 52.3189 11.6643 52.0824 11.8276C46.1071 15.7341 42.4981 21.1282 41.046 28.3176C40.7681 29.6675 40.4151 31.0009 39.9886 32.3117C38.3765 37.3452 34.6796 40.4137 29.0009 41.4362C27.311 41.7401 25.4837 41.8883 23.4167 41.8883ZM17.2019 27.9448C16.2934 27.9313 15.3954 28.14 14.5867 28.5525C13.6594 29.0475 13.1074 29.7643 13.0306 30.5631C12.9539 31.3619 13.3628 32.1579 14.1796 32.8104C15.1397 33.572 16.3458 33.9663 17.8644 34.0175C19.0134 33.984 19.906 33.7565 20.6714 33.3026C21.4958 32.8123 21.9889 32.1346 22.06 31.3936C22.1311 30.6526 21.7746 29.9022 21.0569 29.2721C20.1063 28.4407 18.6653 27.9448 17.2019 27.9448ZM40.9402 7.81108C42.2025 7.92573 43.4937 8.04411 44.7691 8.17647C44.7522 7.76727 44.5351 7.56221 43.9999 7.34596C43.6393 7.19917 43.2536 7.12321 42.864 7.12225C42.1634 7.12706 41.4851 7.3686 40.9402 7.80735V7.81108Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.564 56.381C55.564 55.8702 55.564 55.3612 55.564 54.8542C55.564 53.0524 55.564 51.1882 55.5781 49.364C55.5837 48.8047 55.8681 48.3732 56.0375 48.1159L56.0824 48.0488L56.3173 47.6834C56.7664 46.9834 57.2314 46.2601 57.7255 45.5572C59.1449 43.5373 59.5575 41.1716 58.9849 38.3268C58.7716 37.2661 58.474 36.2277 58.1596 35.1287C57.9874 34.5257 57.8087 33.9011 57.6469 33.2776C57.4878 32.6679 57.4663 32.1478 57.5804 31.693C58.2728 28.8966 58.9961 26.0695 59.6969 23.3309L60.0768 21.8396C60.1381 21.6105 60.2544 21.3997 60.4155 21.2253C60.4277 21.2113 60.4446 21.1927 60.4651 21.1666L62.0483 19.1429L62.8427 21.5581C62.9625 21.9216 63.0813 22.2792 63.1992 22.6309C63.4593 23.4074 63.7045 24.141 63.9375 24.8997C65.5412 30.1195 66.6818 34.9321 67.4238 39.6141C68.57 46.8417 64.8685 53.9267 58.2148 57.2422C58.0277 57.3355 57.848 57.4128 57.688 57.4818C57.6216 57.5098 57.5561 57.5377 57.4915 57.5666L55.565 58.4251L55.564 56.381Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2431 63.6634C27.1726 63.6364 20.5292 63.3391 14.1123 61.2716C10.989 60.264 8.40462 58.9479 6.20389 57.2458C3.35848 55.0386 1.6022 51.9729 0.837743 47.8706C0.191185 44.4134 0.248262 40.7865 1.01646 36.4447C1.10067 35.9693 1.37108 35.6338 1.53296 35.4334C1.55916 35.3998 1.59565 35.3551 1.60781 35.3402L2.38256 34.1638L3.54281 34.9804L3.61579 35.0214C3.86 35.1547 4.31381 35.4026 4.54212 35.9358C5.72857 38.7088 8.00415 40.6197 11.7169 41.9498C14.0749 42.7943 16.7144 43.2706 19.7872 43.403C21.5491 43.4794 23.4224 43.554 25.2507 43.554C27.9034 43.554 30.0929 43.4002 32.1411 43.0702C32.4031 43.0283 32.6679 42.977 32.9495 42.9192L33.2021 42.8689C33.4517 42.816 33.7059 42.7876 33.961 42.784C34.5542 42.784 35.9793 42.9835 36.3938 44.8347C36.6857 46.1397 36.9767 47.4745 37.2583 48.7608L37.3519 49.17C37.3875 48.7971 37.4227 48.4243 37.4576 48.0514C37.671 45.778 37.8927 43.4281 38.1126 41.1156C38.1575 40.6551 38.2558 39.9709 38.5954 39.3632C40.1337 36.6023 41.0217 33.6447 41.9639 30.5127C42.0886 30.0952 42.2134 29.6776 42.3382 29.26C43.4179 25.7179 44.4528 22.5357 46.1969 19.5278C47.2814 17.657 48.4154 16.3213 49.7666 15.3248C51.0765 14.3554 52.4997 13.8018 53.9828 13.6806C54.1998 13.6629 54.4132 13.6536 54.6162 13.6536C57.2483 13.6536 58.8137 15.1347 59.0233 17.8239C59.2104 20.2697 58.7145 22.5776 58.1456 24.6982C57.8771 25.6993 57.5692 26.6985 57.2708 27.6642C56.7215 29.4436 56.2031 31.1233 55.9018 32.8505C55.2637 36.5044 54.9437 40.2478 54.6939 43.7497C54.5067 46.3345 54.4674 48.9891 54.4281 51.5571C54.4169 52.2954 54.4044 53.0336 54.3907 53.7718C54.3449 56.0443 53.3671 57.8489 51.4826 59.1343C48.2478 61.3595 44.4892 62.7113 40.5735 63.0576C38.6722 63.23 36.7428 63.3586 34.8761 63.4826C34.034 63.5385 33.1918 63.5945 32.3553 63.6551H32.302L32.2431 63.6634Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.4261 41.8624C21.7138 41.8624 19.9818 41.7608 18.3659 41.6518C15.4569 41.4551 13.1176 41.0384 11.0049 40.3412C6.15896 38.7417 3.53624 35.4364 3.21062 30.5158C2.84383 24.9753 3.72618 20.3483 5.90913 16.37C7.60553 13.2782 9.85585 10.8481 12.5983 9.14702C15.3071 7.46922 18.5194 6.4821 22.1423 6.22391C22.4502 6.20247 22.7739 6.19129 23.1051 6.19129C24.2888 6.19129 25.4603 6.33017 26.5934 6.4644C27.2287 6.5399 27.8294 6.61074 28.432 6.65921C28.7866 6.6881 29.1253 6.74403 29.4538 6.79809C29.7843 6.86315 30.1193 6.90334 30.4559 6.91834C30.5505 6.92088 30.645 6.91021 30.7366 6.88664C30.9817 6.81207 31.3766 6.41406 31.6947 6.09341C31.9053 5.88089 32.1233 5.66184 32.3637 5.45305C34.2679 3.8032 36.8971 1.82805 40.3564 1.19514C41.0808 1.06181 41.8158 0.993801 42.5524 0.991943C44.7981 0.991943 46.9193 1.64442 48.844 2.92795C51.084 4.41933 52.0403 6.35534 51.7446 8.80587L54.4974 9.19456L52.8618 10.9991C52.7963 11.0709 52.7411 11.139 52.6821 11.2098C52.5096 11.4392 52.3011 11.6394 52.0646 11.8026C46.0987 15.7091 42.4935 21.1033 41.0422 28.2927C40.7647 29.6425 40.412 30.976 39.9858 32.2868C38.3755 37.3202 34.6814 40.3887 29.0074 41.4113C27.3232 41.7151 25.4949 41.8634 23.4289 41.8634L23.4261 41.8624ZM17.2169 27.9189C16.3096 27.9043 15.4125 28.1117 14.6045 28.5229C13.6781 29.0179 13.1261 29.7347 13.0493 30.5335C12.9726 31.3323 13.3815 32.1311 14.1974 32.779C15.1565 33.5405 16.3617 33.9348 17.8793 33.986C19.0274 33.9525 19.9191 33.7251 20.6864 33.2711C21.5098 32.7808 22.0029 32.1032 22.0731 31.3621C22.1433 30.6211 21.7924 29.8708 21.0719 29.2406C20.1184 28.4148 18.6794 27.9189 17.2169 27.9189ZM40.9337 7.7852C42.195 7.89612 43.4853 8.01637 44.7597 8.14686C44.7419 7.73767 44.5258 7.53167 43.9906 7.31635C43.6306 7.16958 43.2455 7.09361 42.8565 7.09264C42.1557 7.09821 41.4776 7.34113 40.9337 7.78148V7.7852Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.5444 56.3547C55.5444 55.8439 55.5444 55.335 55.5444 54.8279C55.5444 53.0261 55.5444 51.1619 55.5585 49.3378C55.5641 48.7785 55.8485 48.3469 56.0179 48.0896L56.0619 48.0225L56.2967 47.6572C56.7459 46.9571 57.2109 46.2338 57.7003 45.531C59.1188 43.5111 59.5304 41.1454 58.9587 38.3006C58.7454 37.2398 58.4488 36.2014 58.1344 35.1025C57.9622 34.4994 57.7835 33.8749 57.6217 33.2513C57.4598 32.6277 57.442 32.1216 57.5552 31.6667C58.2467 28.875 58.97 26.0433 59.6699 23.3047L60.0498 21.8133C60.1112 21.5845 60.2271 21.3738 60.3875 21.199C60.4006 21.1851 60.4165 21.1664 60.4371 21.1403L62.0194 19.1167L62.8156 21.5318C62.9354 21.8953 63.0542 22.253 63.1721 22.6047C63.4323 23.3811 63.6774 24.1147 63.9095 24.8734C65.5123 30.0933 66.6519 34.9058 67.393 39.5878C68.5383 46.8136 64.8386 53.8986 58.1952 57.2141C58.0081 57.3073 57.8294 57.3847 57.6694 57.4537L57.4738 57.5385L55.5482 58.397L55.5444 56.3547Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.245 63.6327C27.1783 63.6057 20.5414 63.3083 14.1301 61.2409C11.0096 60.2333 8.42245 58.9171 6.22921 57.2151C3.38566 55.0125 1.63125 51.9467 0.864928 47.8445C0.220242 44.3873 0.276383 40.7567 1.04364 36.4186C1.12786 35.9433 1.39827 35.6077 1.56014 35.4073C1.58634 35.3737 1.62283 35.329 1.635 35.3141L2.40974 34.1377L3.56812 34.9543L3.6411 34.9953C3.88532 35.1286 4.33913 35.3765 4.5665 35.9097C5.75201 38.6827 8.02853 40.5936 11.7357 41.9237C14.0908 42.7682 16.7285 43.2445 19.7985 43.3769C21.5585 43.4533 23.4299 43.5279 25.2564 43.5279C27.9072 43.5279 30.0948 43.3741 32.1411 43.0441C32.4022 43.0022 32.6679 42.9509 32.9486 42.8931L33.2013 42.8428C33.4501 42.7919 33.7033 42.7654 33.9573 42.7635C34.5505 42.7635 35.9737 42.963 36.3901 44.8142C36.6811 46.1192 36.9721 47.454 37.2537 48.7403L37.3473 49.1495C37.3828 48.7766 37.4178 48.4038 37.4521 48.0309C37.6663 45.7575 37.8872 43.4076 38.1071 41.0951C38.152 40.6346 38.2502 39.9504 38.5889 39.3427C40.1263 36.5818 41.0133 33.6241 41.9527 30.4922C42.0775 30.0746 42.2022 29.6571 42.327 29.2395C43.4058 25.6974 44.4398 22.5152 46.182 19.5073C47.2655 17.6365 48.3986 16.3008 49.7488 15.3043C51.0588 14.3349 52.4801 13.7813 53.9594 13.6601C54.1765 13.6424 54.3889 13.6331 54.5919 13.6331C57.2221 13.6331 58.7857 15.1142 58.9953 17.8033C59.1824 20.2492 58.6865 22.5571 58.1185 24.6777C57.8509 25.6788 57.5422 26.678 57.2446 27.6437C56.6954 29.4231 56.1779 31.1028 55.8766 32.83C55.2394 36.4839 54.9194 40.2273 54.6696 43.7292C54.4825 46.314 54.4441 48.9686 54.4039 51.5366C54.3926 52.2749 54.3802 53.0128 54.3664 53.7504C54.3215 56.0238 53.3437 57.8284 51.4611 59.1138C48.2301 61.3389 44.4748 62.6907 40.5623 63.0371C38.6619 63.2095 36.7344 63.3381 34.8705 63.4621C34.0284 63.518 33.1863 63.574 32.3517 63.6345H32.2974L32.245 63.6327Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.4355 41.8362C21.7251 41.8362 19.995 41.7346 18.3828 41.6255C15.4757 41.4289 13.143 41.0122 11.0284 40.315C6.18432 38.7155 3.56441 35.4102 3.23785 30.4895C2.872 24.949 3.75341 20.3202 5.93449 16.3437C7.62996 13.2519 9.87747 10.8219 12.6172 9.12078C15.3241 7.44297 18.5335 6.45586 22.1555 6.19766C22.4624 6.17623 22.7862 6.16504 23.1165 6.16504C24.2992 6.16504 25.4697 6.30393 26.6019 6.43815C27.2372 6.51365 27.837 6.58449 28.4386 6.63296C28.7933 6.66186 29.132 6.71779 29.4595 6.77185C29.7897 6.83685 30.1244 6.87704 30.4607 6.89209C30.5553 6.89464 30.6497 6.88397 30.7414 6.8604C30.9856 6.78583 31.3814 6.38782 31.6986 6.06717C31.9091 5.85465 32.1262 5.6356 32.3676 5.42681C34.2698 3.77696 36.8963 1.80181 40.3527 1.1689C41.0765 1.03553 41.8109 0.967521 42.5469 0.965698C44.7925 0.965698 46.9091 1.61818 48.8328 2.9017C51.07 4.39309 52.0263 6.32909 51.7288 8.77963L54.4788 9.16832L52.8413 10.972C52.7758 11.0437 52.7206 11.1118 52.6616 11.1826C52.4897 11.4122 52.2815 11.6125 52.045 11.7754C46.0838 15.6819 42.4823 21.0761 41.032 28.2655C40.7547 29.6152 40.4027 30.9486 39.9775 32.2596C38.3681 37.2921 34.6778 40.3606 29.0094 41.386C27.3252 41.6898 25.4997 41.8381 23.4365 41.8381L23.4355 41.8362ZM17.232 27.8927C16.3252 27.8792 15.4291 28.0879 14.6223 28.5004C13.696 28.9954 13.1449 29.7122 13.0682 30.511C12.9914 31.3098 13.3994 32.1086 14.2153 32.7564C15.1734 33.518 16.3777 33.9123 17.8935 33.9635C19.0406 33.93 19.9314 33.7025 20.6949 33.2486C21.5174 32.7583 22.0096 32.0807 22.0807 31.3396C22.1518 30.5986 21.8 29.8482 21.0795 29.2181C20.1316 28.3885 18.6926 27.8927 17.232 27.8927ZM40.9282 7.75896C42.1876 7.87361 43.477 7.99199 44.7504 8.12435C44.7327 7.71515 44.5165 7.50915 43.9822 7.29383C43.6231 7.14602 43.2386 7.06879 42.8501 7.0664C42.1495 7.07217 41.4718 7.31508 40.9282 7.75523V7.75896Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.5248 56.3288C55.5248 55.818 55.5248 55.3091 55.5248 54.802C55.5248 53.0003 55.5248 51.136 55.5398 49.3119C55.5398 48.7526 55.8289 48.321 55.9983 48.0638L56.0423 47.9967L56.2771 47.6313C56.7253 46.9313 57.1894 46.2079 57.6806 45.5042C59.0973 43.4852 59.509 41.1232 58.9373 38.2747C58.7239 37.2139 58.4273 36.1756 58.1139 35.0766C57.9417 34.4735 57.763 33.849 57.6011 33.2254C57.443 32.6158 57.4215 32.0957 57.5356 31.6408C58.2261 28.8445 58.9485 26.0174 59.6474 23.2788L60.0273 21.7874C60.0888 21.5586 60.2046 21.3479 60.3651 21.1732C60.3773 21.1592 60.3941 21.1405 60.4147 21.1144L61.996 19.0908L62.7885 21.5059C62.9071 21.8695 63.0256 22.2271 63.1441 22.5788C63.4033 23.3552 63.6484 24.0888 63.8814 24.8476C65.4824 30.0674 66.6202 34.8799 67.3612 39.5619C68.5065 46.7924 64.8124 53.8765 58.17 57.1883C57.9829 57.2815 57.8041 57.3579 57.6441 57.4278L57.4467 57.5117L55.5229 58.3702L55.5248 56.3288Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2459 63.6066C27.1848 63.5796 20.5498 63.2823 14.1479 61.2148C11.0302 60.2072 8.44767 58.8911 6.25349 57.189C3.41368 54.9864 1.66021 51.9188 0.894821 47.8175C0.250135 44.3603 0.307211 40.7334 1.07354 36.3917C1.15775 35.9163 1.42723 35.5807 1.5891 35.3803C1.6153 35.3468 1.65179 35.3011 1.66395 35.2871L2.43777 34.1108L3.59614 34.9273C3.6186 34.9413 3.64386 34.9543 3.66819 34.9683C3.9124 35.1016 4.36528 35.3495 4.59358 35.8827C5.77722 38.6558 8.05562 40.5666 11.7553 41.8967C14.1095 42.7412 16.7444 43.2175 19.8106 43.3499C21.5697 43.4263 23.4401 43.5009 25.2647 43.5009C27.9127 43.5009 30.0985 43.3471 32.142 43.0171C32.404 42.9752 32.6688 42.9239 32.9495 42.8661L33.2012 42.8158C33.45 42.765 33.7032 42.7384 33.9572 42.7366C34.5495 42.7366 35.9718 42.936 36.3835 44.7872C36.6754 46.0922 36.9655 47.427 37.2471 48.7133L37.3407 49.1225C37.3756 48.7496 37.4105 48.3768 37.4455 48.0039C37.6588 45.7305 37.8796 43.3807 38.1004 41.0681C38.1454 40.6076 38.2436 39.9234 38.5823 39.3157C40.1178 36.5548 41.0048 33.5972 41.9424 30.4653C42.0671 30.0477 42.1919 29.6301 42.3166 29.2125C43.3946 25.6705 44.4275 22.4882 46.1679 19.4803C47.2505 17.6095 48.3836 16.2738 49.7319 15.2774C51.0419 14.308 52.4604 13.7543 53.9425 13.6331C54.1596 13.6154 54.372 13.6061 54.575 13.6061C57.2015 13.6061 58.7641 15.0872 58.9728 17.7764C59.1599 20.2222 58.664 22.5302 58.097 24.6507C57.8294 25.6518 57.5215 26.651 57.223 27.6167C56.6757 29.3961 56.1573 31.0758 55.8569 32.803C55.2197 36.4569 54.9007 40.2003 54.6508 43.7022C54.4637 46.287 54.4253 48.9417 54.386 51.5096C54.3742 52.2479 54.3617 52.9858 54.3486 53.7234C54.3028 55.9968 53.3268 57.8014 51.448 59.0868C48.2201 61.312 44.4675 62.6642 40.5576 63.011C38.659 63.1835 36.7325 63.3121 34.8705 63.4361C34.0283 63.492 33.1928 63.5479 32.3535 63.6085H32.3001L32.2459 63.6066Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.4449 41.8101C21.7363 41.8101 20.0071 41.7085 18.3922 41.5994C15.4915 41.4027 13.1523 40.9861 11.0442 40.2888C6.20954 38.6893 3.5915 35.3841 3.27056 30.4634C2.89628 24.9229 3.7805 20.2959 5.95971 16.3176C7.6533 13.2258 9.89988 10.7958 12.6368 9.09466C15.3409 7.41312 18.5475 6.4316 22.1658 6.17154C22.4727 6.1501 22.7955 6.13892 23.1267 6.13892C24.3085 6.13892 25.4781 6.2778 26.6084 6.41203C27.2428 6.48753 27.8426 6.55837 28.4442 6.60684C28.7979 6.63574 29.1366 6.69166 29.4632 6.74573C29.7934 6.81077 30.1281 6.85097 30.4644 6.86597C30.559 6.86861 30.6535 6.85794 30.7451 6.83428C30.9893 6.75971 31.3841 6.36169 31.7013 6.04105C31.9119 5.82852 32.1289 5.60948 32.3694 5.40068C34.2698 3.75084 36.8944 1.77568 40.3471 1.14278C41.0705 1.00945 41.8046 0.941444 42.5403 0.939575C44.7859 0.939575 46.8987 1.59206 48.8206 2.87558C51.056 4.36697 52.0104 6.30297 51.7138 8.7535L54.4609 9.14219L52.8253 10.9458C52.7598 11.0176 52.7046 11.0857 52.6457 11.1565C52.4738 11.3861 52.2655 11.5863 52.0291 11.7493C46.0744 15.6595 42.4757 21.05 41.0273 28.2431C40.75 29.5928 40.398 30.9263 39.9728 32.2372C38.3643 37.2707 34.6777 40.3392 29.014 41.3617C27.3298 41.6656 25.5071 41.8138 23.4458 41.8138L23.4449 41.8101ZM17.2469 27.8703C16.3408 27.8569 15.4452 28.0656 14.6391 28.478C13.7147 28.973 13.1635 29.6898 13.0868 30.4886C13.0101 31.2874 13.4181 32.0862 14.233 32.734C15.1902 33.4956 16.3935 33.8899 17.9084 33.9411C19.0546 33.9076 19.9445 33.6801 20.707 33.2262C21.5286 32.7359 22.017 32.0583 22.0909 31.3172C22.1648 30.5762 21.8102 29.8258 21.0907 29.1957C20.1438 28.3624 18.7094 27.8703 17.2469 27.8703ZM40.9197 7.73656C42.1791 7.85121 43.4666 7.96959 44.7382 8.10195C44.7214 7.69275 44.5052 7.48676 43.971 7.27144C43.6116 7.12468 43.2271 7.04871 42.8388 7.04773C42.1408 7.05245 41.465 7.29263 40.9216 7.72911L40.9197 7.73656Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.5051 56.2998V54.7758C55.5051 52.974 55.5052 51.1098 55.5192 49.2856C55.5248 48.7264 55.8083 48.2948 55.9777 48.0375L56.0216 47.9704L56.2565 47.605C56.7047 46.905 57.1679 46.1817 57.66 45.4779C59.0757 43.459 59.4865 41.097 58.9157 38.2484C58.7024 37.1877 58.4067 36.1493 58.0932 35.0504C57.9211 34.4473 57.7424 33.8228 57.5814 33.1992C57.4205 32.5756 57.4018 32.0695 57.515 31.6146C58.2055 28.8229 58.9269 25.9911 59.6259 23.2526L60.0049 21.7612C60.0664 21.5326 60.1819 21.322 60.3417 21.1469C60.3548 21.1329 60.3707 21.1143 60.3913 21.0882L61.9717 19.0646L62.7633 21.4797C62.8821 21.8432 63.0019 22.2002 63.1188 22.5526C63.378 23.329 63.6231 24.0626 63.8552 24.8213C65.4543 30.0412 66.5921 34.8537 67.3322 39.5357C68.4747 46.7633 64.7834 53.8483 58.1475 57.1639C57.9604 57.2571 57.7826 57.3335 57.6226 57.4034L57.427 57.4883L55.5042 58.3467L55.5051 56.2998Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2478 63.5805C27.1904 63.5534 20.5649 63.2561 14.1666 61.1887C11.0508 60.181 8.4702 58.8649 6.27883 57.1628C3.4409 54.9603 1.68929 51.8945 0.924839 47.7923C0.281088 44.3351 0.337229 40.7082 1.10356 36.3664C1.18683 35.891 1.45724 35.5555 1.61818 35.3551C1.64532 35.3215 1.68181 35.2758 1.69304 35.2618L2.46591 34.0855L3.62335 34.9021C3.64581 34.916 3.67107 34.9291 3.69634 34.9431C3.93962 35.0764 4.39249 35.3243 4.61986 35.8575C5.80256 38.6305 8.07628 40.5414 11.776 41.8715C14.1273 42.716 16.7594 43.1923 19.8229 43.3246C21.5801 43.4011 23.4486 43.4757 25.2714 43.4757C27.9175 43.4757 30.1004 43.3219 32.143 42.9919C32.4041 42.9499 32.6689 42.8987 32.9496 42.8409L33.2013 42.7905C33.4498 42.7397 33.7027 42.7132 33.9564 42.7113C34.5477 42.7113 35.969 42.9108 36.3807 44.762C36.6717 46.0669 36.9627 47.4017 37.2434 48.688C37.2734 48.8241 37.3024 48.9612 37.337 49.0972C37.3726 48.7244 37.4078 48.3515 37.4427 47.9787C37.6561 45.7053 37.876 43.3554 38.0977 41.0428C38.1426 40.5814 38.2409 39.8982 38.5796 39.2905C40.1141 36.5295 40.9993 33.5719 41.9368 30.4354C42.0616 30.0178 42.1863 29.6002 42.3111 29.1826C43.3881 25.6405 44.4201 22.4583 46.1596 19.4504C47.2412 17.5796 48.3725 16.2439 49.7152 15.2475C51.0251 14.2781 52.4408 13.7244 53.9201 13.6032C54.1363 13.5855 54.3487 13.5762 54.5517 13.5762C57.1716 13.5762 58.737 15.0573 58.9494 17.7465C59.1366 20.1923 58.6416 22.5003 58.0737 24.6208C57.807 25.6219 57.4991 26.6211 57.2016 27.5868C56.6542 29.3662 56.1368 31.0459 55.8364 32.7731C55.2011 36.427 54.883 40.1741 54.6331 43.6723C54.446 46.2571 54.4076 48.9118 54.3683 51.4797C54.3571 52.218 54.3446 52.9559 54.3309 53.6935C54.286 55.9669 53.3101 57.7715 51.4303 59.0569C48.206 61.2815 44.4568 62.6334 40.5502 62.9802C38.6535 63.1526 36.7298 63.2812 34.8687 63.4052C34.0266 63.4611 33.1919 63.5171 32.3545 63.5777H32.3012L32.2478 63.5805Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.4542 41.7839C21.7466 41.7839 20.0202 41.6823 18.4081 41.5733C15.5074 41.3766 13.1738 40.9599 11.0667 40.2627C6.23386 38.6632 3.61862 35.3579 3.29394 30.4373C2.92715 24.8968 3.80763 20.2698 5.98403 16.2915C7.67575 13.1997 9.92046 10.7696 12.6545 9.06853C15.3568 7.39072 18.5596 6.40361 22.1761 6.14542C22.482 6.12398 22.8048 6.11279 23.1351 6.11279C24.316 6.11279 25.4846 6.25168 26.614 6.3859C27.2484 6.46141 27.8472 6.53225 28.4479 6.58072C28.8016 6.60961 29.1394 6.66554 29.4669 6.7196C29.7965 6.78464 30.1306 6.82484 30.4662 6.83984C30.5608 6.84255 30.6553 6.83188 30.7469 6.80815C30.9911 6.73358 31.386 6.33557 31.7023 6.01492C31.9128 5.8024 32.1299 5.58335 32.3703 5.37456C34.2698 3.72844 36.8916 1.74956 40.3414 1.11852C41.0639 0.984571 41.797 0.915936 42.5319 0.913452C44.7775 0.913452 46.8875 1.56593 48.8066 2.84946C51.0401 4.34084 51.9945 6.27685 51.6978 8.72738L54.4422 9.11607L52.8085 10.9197C52.743 10.9915 52.6878 11.0595 52.6288 11.1304C52.4573 11.36 52.2494 11.5602 52.0132 11.7232C46.0631 15.6297 42.4692 21.0239 41.0207 28.2133C40.7438 29.563 40.3921 30.8964 39.9672 32.2074C38.3606 37.2408 34.6768 40.3093 29.0197 41.3319C27.3354 41.6357 25.5155 41.7839 23.4561 41.7839H23.4542ZM17.2628 27.8404C16.3573 27.827 15.4623 28.0357 14.6569 28.4482C13.7324 28.9431 13.1823 29.6599 13.1055 30.4587C13.0288 31.2575 13.4368 32.0564 14.2508 32.7042C15.208 33.4657 16.4094 33.86 17.9224 33.9113C19.0677 33.8777 19.9566 33.6503 20.7183 33.1964C21.5398 32.7061 22.0282 32.0284 22.1021 31.2874C22.1761 30.5463 21.8214 29.796 21.1028 29.1659C20.1559 28.3363 18.7206 27.8404 17.2628 27.8404ZM40.915 7.70671C42.1735 7.82136 43.4601 7.93974 44.7307 8.0721C44.713 7.6629 44.4977 7.4569 43.9644 7.24159C43.6051 7.09489 43.2206 7.01892 42.8322 7.01788C42.1337 7.02306 41.4576 7.26464 40.915 7.70298V7.70671Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.4865 56.2764C55.4865 55.7656 55.4865 55.2566 55.4865 54.7495C55.4865 52.9478 55.4865 51.0835 55.5005 49.2594C55.5061 48.7001 55.7896 48.2686 55.959 48.0113L56.0029 47.9442L56.2369 47.5788C56.6851 46.8788 57.1482 46.1554 57.6404 45.4517C59.0552 43.4327 59.4659 41.0708 58.8951 38.2213C58.6827 37.1615 58.3861 36.1231 58.0736 35.0241C57.9015 34.421 57.7237 33.7965 57.5618 33.1729C57.4037 32.5633 57.3822 32.0432 57.4954 31.5883C58.185 28.792 58.9064 25.9649 59.6044 23.2263L59.9834 21.7349C60.0446 21.5062 60.1601 21.2956 60.3202 21.1207C60.3324 21.1067 60.3492 21.0881 60.3698 21.062L61.9483 19.0383L62.7389 21.4534C62.8578 21.817 62.9775 22.174 63.0945 22.5263C63.3537 23.3028 63.5979 24.0363 63.8299 24.7951C65.4281 30.0149 66.565 34.8274 67.3041 39.5095C68.4457 46.7371 64.7582 53.8221 58.1279 57.1376C57.9408 57.2308 57.763 57.3073 57.603 57.3772L57.4074 57.462L55.4874 58.3205L55.4865 56.2764Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2497 63.5546C27.197 63.5276 20.577 63.2302 14.1844 61.1628C11.0714 60.1552 8.49359 58.839 6.30408 57.1389C3.46802 54.9363 1.71923 51.8705 0.954776 47.7683C0.311025 44.3111 0.367166 40.6842 1.13256 36.3424C1.21677 35.867 1.48624 35.5315 1.64718 35.3311C1.67432 35.2975 1.71081 35.2519 1.72204 35.2379L2.49491 34.0615L3.65048 34.8781C3.67388 34.8921 3.6982 34.9051 3.72347 34.9181C3.96581 35.0524 4.41868 35.3003 4.64605 35.8335C5.82782 38.6065 8.09966 40.5174 11.7956 41.8475C14.1451 42.692 16.7753 43.1683 19.8369 43.3007C21.5922 43.3771 23.4589 43.4517 25.2807 43.4517C27.924 43.4517 30.1051 43.2979 32.1458 42.9679C32.4069 42.926 32.6717 42.8747 32.9514 42.8169L33.2031 42.7666C33.4513 42.7157 33.7039 42.6892 33.9573 42.6873C34.5487 42.6873 35.969 42.8868 36.3798 44.738C36.6699 46.0476 36.9609 47.3787 37.2416 48.665C37.2706 48.8011 37.3005 48.9381 37.3305 49.0742C37.366 48.7013 37.4009 48.3285 37.4352 47.9557C37.6486 45.6832 37.8694 43.3333 38.0902 41.0207C38.1351 40.5593 38.2334 39.8761 38.5712 39.2683C40.1048 36.5074 40.989 33.5498 41.9256 30.4179C42.051 30.0003 42.1754 29.579 42.2999 29.1651C43.3759 25.6231 44.407 22.4408 46.1446 19.4329C47.2253 17.5622 48.3556 16.2264 49.7002 15.23C51.0102 14.2606 52.423 13.7069 53.9014 13.5857C54.1176 13.568 54.33 13.5587 54.5321 13.5587C57.152 13.5587 58.7137 15.0398 58.9232 17.729C59.1104 20.1749 58.6154 22.4781 58.0484 24.6024C57.7817 25.6044 57.4739 26.6037 57.1773 27.5693C56.6299 29.3488 56.1134 31.0284 55.813 32.7556C55.1777 36.4095 54.8586 40.1529 54.6088 43.6549C54.4217 46.2396 54.3842 48.8943 54.344 51.4623C54.3328 52.2005 54.3203 52.9384 54.3066 53.676C54.2617 55.9495 53.2876 57.7541 51.406 59.0394C48.1853 61.2638 44.4395 62.6157 40.5361 62.9627C38.6413 63.1352 36.7185 63.2638 34.8593 63.3878C34.0219 63.4437 33.1844 63.4996 32.3479 63.5602H32.2937L32.2497 63.5546Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.4645 41.7587C21.7578 41.7587 20.0324 41.6571 18.4221 41.5481C15.5215 41.3514 13.1926 40.9348 11.0873 40.2375C6.25913 38.638 3.64576 35.3327 3.32108 30.4121C2.95523 24.8707 3.83196 20.2436 6.01024 16.2654C7.70008 13.1735 9.94011 10.7435 12.6751 9.04241C15.3746 7.3646 18.5746 6.37749 22.1873 6.1193C22.4933 6.09786 22.8161 6.08667 23.1454 6.08667C24.3253 6.08667 25.4931 6.22556 26.6215 6.35978C27.255 6.43528 27.8538 6.50612 28.4536 6.55459C28.8073 6.58349 29.145 6.63942 29.4716 6.69348C29.8009 6.75846 30.1346 6.79865 30.47 6.81372C30.5646 6.81627 30.6591 6.8056 30.7507 6.78203C30.994 6.70746 31.3879 6.30945 31.7051 5.9888C31.9147 5.77628 32.1317 5.55723 32.3713 5.34844C34.2689 3.69859 36.8888 1.72344 40.3349 1.09053C41.0568 0.957255 41.7893 0.889245 42.5235 0.887329C44.7691 0.887329 46.8744 1.53981 48.7925 2.82333C51.0242 4.31472 51.9739 6.25072 51.681 8.70126L54.4235 9.08995L52.7898 10.8936C52.7252 10.9654 52.67 11.0334 52.6111 11.1043C52.4396 11.3336 52.232 11.5338 51.9963 11.6971C46.0529 15.6036 42.4598 20.9977 41.0142 28.1871C40.738 29.5369 40.3866 30.8704 39.9616 32.1813C38.3569 37.2147 34.6768 40.2832 29.0234 41.3057C27.3392 41.6096 25.5221 41.7578 23.4645 41.7578V41.7587ZM17.2778 27.8143C16.3731 27.8008 15.4791 28.0095 14.6747 28.422C13.7512 28.917 13.201 29.6338 13.1252 30.4326C13.0494 31.2314 13.4555 32.0303 14.2686 32.6781C15.2249 33.4396 16.4207 33.8339 17.9374 33.8852C19.0808 33.8516 19.9697 33.6242 20.7304 33.1702C21.551 32.6799 22.0404 32.0023 22.1124 31.2613C22.1845 30.5202 21.8317 29.7699 21.1141 29.1398C20.1681 28.3102 18.7346 27.8143 17.2778 27.8143ZM40.9094 7.68059C42.166 7.79524 43.4517 7.91362 44.7214 8.04598C44.7036 7.63678 44.4884 7.43078 43.9551 7.21546C43.5964 7.06837 43.2126 6.99208 42.8248 6.99082C42.1266 6.9961 41.4511 7.23806 40.9094 7.67686V7.68059Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.4668 56.2502C55.4668 55.7394 55.4668 55.2305 55.4668 54.7234C55.4668 52.9217 55.4668 51.0574 55.4818 49.2333C55.4818 48.674 55.77 48.2424 55.9393 47.9852L55.9833 47.9181L56.2172 47.5527C56.6645 46.8527 57.1267 46.1293 57.6207 45.4256C59.0346 43.4066 59.4444 41.0446 58.8746 38.1952C58.6622 37.1353 58.3665 36.097 58.053 34.998C57.8818 34.3949 57.704 33.7704 57.5421 33.1468C57.3803 32.5232 57.3634 32.0171 57.4766 31.5622C58.1653 28.7705 58.8858 25.9388 59.5829 23.2002L59.9618 21.7088C60.023 21.4801 60.1386 21.2694 60.2987 21.0946C60.3108 21.0806 60.3277 21.0619 60.3483 21.0358L61.9221 19.015L62.7127 21.4301C62.8313 21.7937 62.9495 22.1513 63.0674 22.503C63.3265 23.2794 63.5708 24.013 63.8028 24.7718C65.4 29.986 66.5341 34.7995 67.2714 39.4815C68.412 46.7091 64.7273 53.7941 58.1017 57.1097C57.9145 57.2029 57.7377 57.2793 57.5777 57.3492C57.5122 57.3772 57.4467 57.4051 57.3831 57.434L55.464 58.2925L55.4668 56.2502Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2506 63.5284C27.1979 63.5014 20.5892 63.204 14.2022 61.1366C11.092 60.129 8.51699 58.8166 6.32842 57.1108C3.49611 54.9082 1.74357 51.8425 0.98473 47.7402C0.340979 44.283 0.398055 40.6562 1.16251 36.3144C1.24579 35.839 1.51526 35.5034 1.6762 35.303C1.70334 35.2695 1.73983 35.2238 1.75106 35.2098L2.52206 34.0307L3.67669 34.8472C3.70009 34.8612 3.72441 34.8742 3.74968 34.8873C3.99202 35.0215 4.44396 35.2695 4.67133 35.8026C5.85216 38.5757 8.12213 40.4865 11.8143 41.8166C14.162 42.6611 16.7894 43.1374 19.8481 43.2698C21.6025 43.3462 23.4674 43.4208 25.2873 43.4208C27.9278 43.4208 30.1079 43.267 32.1468 42.937C32.4078 42.8951 32.6717 42.8438 32.9515 42.786L33.2032 42.7357C33.4495 42.6852 33.7002 42.6586 33.9517 42.6565C34.5421 42.6565 35.9616 42.8559 36.3714 44.7071C36.6624 46.0168 36.9524 47.3478 37.2332 48.6341C37.2622 48.7702 37.2921 48.9072 37.3267 49.0433C37.3623 48.6705 37.3972 48.2976 37.4315 47.9248C37.6449 45.6523 37.8647 43.3024 38.0865 40.9899C38.1305 40.5285 38.2287 39.8452 38.5665 39.2375C40.0982 36.4765 40.9815 33.5189 41.9172 30.3824C42.0426 29.9648 42.167 29.5435 42.2915 29.1296C43.3666 25.5876 44.3968 22.4053 46.1325 19.3974C47.2122 17.5266 48.3416 16.1909 49.6881 15.1945C50.998 14.2251 52.409 13.6714 53.8855 13.5502C54.1017 13.5325 54.3131 13.5232 54.5153 13.5232C57.1352 13.5232 58.694 15.0043 58.9027 17.6935C59.0898 20.1394 58.5948 22.4426 58.0288 24.5669C57.7621 25.5689 57.4552 26.5682 57.1576 27.5338C56.6112 29.3132 56.0947 30.9929 55.7953 32.7201C55.1637 36.3749 54.8456 40.1183 54.5957 43.6231C54.4086 46.2078 54.3712 48.8625 54.3319 51.4305C54.3206 52.1687 54.3082 52.9066 54.2944 53.6443C54.2495 55.9177 53.2755 57.7223 51.3994 59.0077C48.1825 61.2317 44.4401 62.5837 40.5399 62.9309C38.646 63.1034 36.7251 63.232 34.8677 63.356C34.0312 63.4119 33.1947 63.4678 32.3582 63.5284H32.3049H32.2506Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.4739 41.7317C21.7691 41.7317 20.0455 41.6301 18.4362 41.521C15.5355 41.3243 13.2113 40.9077 11.1079 40.2104C6.28443 38.6109 3.67293 35.3056 3.34918 30.3869C2.98333 24.8464 3.86194 20.2194 6.03553 16.2411C7.71977 13.1493 9.96541 10.7192 12.6939 9.01812C15.3914 7.34031 18.5887 6.3532 22.1976 6.095C22.5036 6.07356 22.8264 6.06238 23.1558 6.06238C24.3347 6.06238 25.5006 6.20126 26.629 6.33549C27.2615 6.41099 27.8585 6.48183 28.4592 6.5303C28.812 6.5592 29.1498 6.61512 29.4754 6.66919C29.8047 6.73422 30.1384 6.77441 30.4737 6.78943C30.5683 6.79201 30.6628 6.78134 30.7545 6.75774C30.9977 6.68317 31.3917 6.28515 31.7079 5.96451C31.9175 5.75198 32.1346 5.53294 32.3741 5.32414C34.2698 3.6743 36.8869 1.69914 40.3274 1.06624C41.0487 0.932923 41.7806 0.864912 42.5141 0.863037C44.7542 0.863037 46.8613 1.51552 48.7776 2.79904C51.0073 4.29043 51.9589 6.22643 51.6633 8.67696L54.4029 9.06566L52.7711 10.8693C52.7065 10.9411 52.6513 11.0091 52.5924 11.08C52.4212 11.3096 52.2136 11.5098 51.9776 11.6728C46.0417 15.5774 42.4524 20.9725 41.0077 28.161C40.7314 29.5115 40.3806 30.8458 39.9569 32.1579C38.3531 37.1913 34.6759 40.2598 29.0272 41.2824C27.3429 41.5862 25.5296 41.7344 23.4739 41.7344V41.7317ZM17.2928 27.7881C16.3887 27.7746 15.4953 27.9834 14.6916 28.3959C13.769 28.8908 13.2197 29.6076 13.1439 30.4064C13.0681 31.2053 13.4733 32.0041 14.2864 32.6519C15.2417 33.4134 16.4385 33.8077 17.9515 33.859C19.0949 33.8254 19.9819 33.598 20.7426 33.1441C21.5623 32.6538 22.0526 31.9761 22.1237 31.2351C22.1948 30.4941 21.843 29.7437 21.1263 29.1136C20.1812 28.284 18.7477 27.7881 17.2928 27.7881ZM40.9029 7.65443C42.1586 7.76908 43.4433 7.88746 44.7111 8.01982C44.6943 7.61062 44.4791 7.40462 43.9457 7.18931C43.5874 7.04247 43.2038 6.96649 42.8164 6.9656C42.1189 6.97066 41.444 7.21231 40.9029 7.6507V7.65443Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.4481 56.2245C55.4481 55.7137 55.4481 55.2047 55.4481 54.6977C55.4481 52.8959 55.4481 51.0317 55.4622 49.2075C55.4678 48.6482 55.7503 48.2167 55.9197 47.9594L55.9637 47.8923L56.1967 47.5269C56.6439 46.8269 57.1062 46.1036 57.6002 45.3998C59.0121 43.3809 59.422 41.0189 58.8521 38.1694C58.6397 37.1096 58.345 36.0712 58.0315 34.9722C57.8603 34.3692 57.6825 33.7446 57.5216 33.1211C57.3635 32.5114 57.3419 31.9913 57.4552 31.5365C58.1438 28.7401 58.8643 25.913 59.5605 23.1744L59.9347 21.6831C59.9959 21.4544 60.1115 21.2437 60.2716 21.0688C60.2837 21.0548 60.2996 21.0362 60.3202 21.0101L61.8959 18.9865L62.6866 21.3997C62.8051 21.7632 62.9233 22.1208 63.0412 22.4726C63.2994 23.249 63.5437 23.9826 63.7757 24.7413C65.3711 29.9612 66.5051 34.7737 67.2434 39.4557C68.383 46.6833 64.702 53.7683 58.083 57.0839C57.8959 57.1771 57.719 57.2535 57.559 57.3234L57.3644 57.4083L55.4481 58.2667V56.2245Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2524 63.5023C27.2091 63.4753 20.6012 63.178 14.2199 61.1105C11.1125 60.1029 8.53934 58.7868 6.35825 57.0847C3.52313 54.8821 1.7734 51.8164 1.01362 47.7142C0.371744 44.2569 0.427885 40.6301 1.1914 36.2883C1.27562 35.8129 1.54509 35.4773 1.70509 35.2769C1.73223 35.2434 1.76872 35.1977 1.77995 35.1837L2.55095 34.0074L3.70465 34.8239C3.72804 34.8379 3.75331 34.851 3.77764 34.864C4.01998 34.9982 4.47191 35.2462 4.69835 35.7793C5.87825 38.5524 8.14635 40.4632 11.8358 41.7934C14.1806 42.6379 16.8061 43.1142 19.8621 43.2465C21.6146 43.323 23.4775 43.3975 25.2956 43.3975C27.9342 43.3975 30.1125 43.2437 32.1485 42.9138C32.4096 42.8718 32.6735 42.8205 32.9532 42.7628L33.204 42.7124C33.4503 42.6619 33.701 42.6354 33.9525 42.6332C34.5429 42.6332 35.9605 42.8327 36.3703 44.6838C36.6604 45.9935 36.9505 47.3245 37.2312 48.6109C37.2602 48.7469 37.2901 48.884 37.3201 49.0201C37.355 48.6472 37.3899 48.2744 37.4249 47.9015C37.6373 45.629 37.8571 43.2791 38.0798 40.9666C38.1238 40.5052 38.2221 39.8219 38.5589 39.2142C40.0953 36.4458 40.9786 33.4891 41.9143 30.3572C42.039 29.9396 42.1638 29.522 42.2885 29.1045C43.3627 25.5624 44.392 22.3802 46.1249 19.3722C47.2028 17.5015 48.3312 16.1658 49.6805 15.1693C50.9904 14.1999 52.3939 13.6463 53.8733 13.5251C54.0894 13.5074 54.3018 13.498 54.5039 13.498C57.1238 13.498 58.678 14.9792 58.8867 17.6683C59.0738 20.1142 58.5798 22.4175 58.0137 24.5418C57.747 25.5438 57.4401 26.543 57.1435 27.5087C56.598 29.2881 56.0815 30.9678 55.7821 32.695C55.1449 36.3526 54.8267 40.0922 54.5778 43.5942C54.3907 46.179 54.3533 48.8336 54.313 51.4016C54.3018 52.1398 54.2893 52.8778 54.2756 53.6154C54.2307 55.8888 53.2576 57.6934 51.3843 58.9788C48.1697 61.2032 44.429 62.5553 40.5304 62.9021C38.6394 63.0745 36.7203 63.2031 34.8639 63.3271C34.0283 63.383 33.1928 63.4389 32.3572 63.4995H32.3029L32.2524 63.5023Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.4813 41.7056C21.7784 41.7056 20.0558 41.604 18.4483 41.495C15.5542 41.2974 13.2281 40.8816 11.1266 40.1844C6.30873 38.5896 3.70098 35.2796 3.37629 30.359C3.01138 24.8185 3.88905 20.1915 6.06077 16.2132C7.74501 13.1214 9.99065 10.6913 12.7135 8.99022C15.4083 7.31241 18.6027 6.3253 22.2088 6.0671C22.5139 6.04567 22.8357 6.03448 23.1651 6.03448C24.3431 6.03448 25.5043 6.17337 26.6355 6.30759C27.2671 6.38309 27.8641 6.45393 28.4639 6.5024C28.8166 6.5313 29.1535 6.58723 29.48 6.64129C29.8087 6.70631 30.1418 6.74651 30.4765 6.76153C30.5711 6.76411 30.6656 6.75344 30.7572 6.72984C31.0005 6.65527 31.3935 6.25726 31.7098 5.93661C31.9194 5.72409 32.1355 5.50504 32.376 5.29625C34.2689 3.6464 36.8841 1.67125 40.3246 1.03834C41.0453 0.906276 41.7766 0.839513 42.5094 0.838867C44.7467 0.838867 46.8519 1.49135 48.7673 2.77487C50.9942 4.26626 51.9486 6.20226 51.6501 8.65279L54.3879 9.04149L52.757 10.8451C52.6925 10.9169 52.6373 10.9849 52.5793 11.0558C52.4078 11.2852 52.2002 11.4854 51.9645 11.6486C46.0313 15.5514 42.4449 20.9455 41.002 28.1349C40.7251 29.4846 40.3736 30.818 39.9494 32.1291C38.3466 37.1625 34.6731 40.231 29.03 41.2535C27.3504 41.5574 25.5361 41.7056 23.4813 41.7056ZM17.3058 27.7621C16.4027 27.7486 15.5102 27.9573 14.7074 28.3698C13.7858 28.8648 13.2365 29.5816 13.1607 30.3804C13.085 31.1792 13.4901 31.9781 14.3023 32.6259C15.2567 33.3874 16.4544 33.7817 17.9646 33.8376C19.1061 33.8041 19.9931 33.5766 20.7529 33.1227C21.5716 32.6324 22.0629 31.9548 22.1321 31.2137C22.2013 30.4727 21.8514 29.7223 21.1356 29.0922C20.1934 28.258 18.7618 27.7621 17.3058 27.7621ZM40.8944 7.6284C42.1492 7.74305 43.432 7.86143 44.6999 7.99379C44.6821 7.58459 44.4678 7.37859 43.9354 7.16327C43.5774 7.01653 43.1941 6.94055 42.807 6.93957C42.1105 6.94504 41.4366 7.18668 40.8963 7.62467L40.8944 7.6284Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.4284 56.1982C55.4284 55.6874 55.4284 55.1785 55.4284 54.6714C55.4284 52.8696 55.4285 51.0054 55.4425 49.1813C55.4481 48.622 55.7307 48.1904 55.9 47.9332L55.944 47.866L56.177 47.5007C56.6233 46.8006 57.0855 46.0773 57.5805 45.3736C58.9915 43.3546 59.4004 40.9926 58.8315 38.1431C58.6201 37.0833 58.3244 36.045 58.0119 34.946C57.8406 34.3429 57.6629 33.7184 57.5019 33.0948C57.341 32.4712 57.3232 31.9651 57.4364 31.5102C58.1242 28.7185 58.84 25.8868 59.5389 23.1482L59.9132 21.6568C59.9743 21.4283 60.0895 21.2177 60.2491 21.0426C60.2622 21.0286 60.2781 21.0099 60.2987 20.9838L61.8725 18.9602L62.6613 21.3753C62.7798 21.7388 62.898 22.0965 63.0159 22.4482C63.2742 23.2246 63.5184 23.9582 63.7495 24.717C65.3402 29.9368 66.477 34.7493 67.2115 39.4313C68.3503 46.659 64.6721 53.744 58.0596 57.0595C57.8725 57.1527 57.6956 57.2291 57.5365 57.2991C57.4711 57.327 57.4056 57.355 57.3419 57.3839L55.4266 58.2424L55.4284 56.1982Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2543 63.477C27.2147 63.45 20.6134 63.1526 14.2377 61.0852C11.1331 60.0776 8.56182 58.7614 6.37793 57.0594C3.55123 54.8559 1.80524 51.7901 1.04359 47.6879C0.401712 44.2307 0.457853 40.6038 1.22137 36.262C1.30465 35.7866 1.57413 35.4511 1.73413 35.2507C1.76126 35.2171 1.79775 35.1714 1.80898 35.1574L2.57905 33.9811L3.73181 34.7976C3.75521 34.8116 3.78047 34.8247 3.8048 34.8377C4.04714 34.972 4.49814 35.2199 4.72551 35.7531C5.90354 38.5261 8.16977 40.4369 11.8554 41.7671C14.1946 42.6116 16.8211 43.0879 19.8742 43.2202C21.6258 43.2967 23.4869 43.3712 25.3012 43.3712C27.937 43.3712 30.1125 43.2174 32.1476 42.8875C32.4087 42.8455 32.6716 42.7943 32.9514 42.7365L33.2021 42.6861C33.4485 42.6355 33.6992 42.609 33.9507 42.6069C34.5402 42.6069 35.9568 42.8064 36.3666 44.6576C36.6567 45.9672 36.9458 47.2983 37.2256 48.5846L37.3192 48.9938C37.3541 48.6209 37.3887 48.2481 37.423 47.8752C37.6363 45.6027 37.8562 43.2529 38.078 40.9403C38.122 40.4789 38.2202 39.7957 38.5571 39.1879C40.086 36.427 40.9683 33.4694 41.9021 30.3328C42.0269 29.9152 42.1517 29.4976 42.2764 29.08C43.3496 25.538 44.378 22.3558 46.1127 19.3478C47.1906 17.4771 48.3181 16.1413 49.6608 15.1449C50.9708 14.1755 52.3743 13.6218 53.8499 13.5007C54.066 13.483 54.2775 13.4736 54.4796 13.4736C57.0949 13.4736 58.65 14.9548 58.8586 17.6439C59.0458 20.0898 58.5517 22.393 57.9866 24.5173C57.7199 25.5194 57.4139 26.5186 57.1173 27.4843C56.5746 29.2618 56.0591 30.9461 55.7624 32.6687C55.129 36.3226 54.8108 40.066 54.562 43.5679C54.3748 46.1527 54.3374 48.8073 54.2981 51.3753C54.2869 52.1136 54.2744 52.8515 54.2607 53.5891C54.2158 55.8625 53.2436 57.6671 51.3713 58.9525C48.1611 61.1761 44.4248 62.5283 40.5304 62.8758C38.6403 63.0482 36.7231 63.1768 34.8686 63.3008C34.034 63.3567 33.1984 63.4127 32.3638 63.4733H32.3104L32.2543 63.477Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.4926 41.6796C21.7905 41.6796 20.0698 41.578 18.4642 41.4689C15.5729 41.2713 13.2487 40.8556 11.149 40.1583C6.33397 38.5588 3.72809 35.2536 3.40341 30.3329C3.03942 24.7943 3.91616 20.1654 6.08601 16.1871C7.77025 13.0953 10.0075 10.6653 12.7294 8.96416C15.4213 7.28635 18.6139 6.29924 22.2163 6.04105C22.5213 6.01961 22.8432 6.00842 23.1726 6.00842C24.3487 6.00842 25.5118 6.14731 26.6346 6.28153C27.2662 6.35704 27.8622 6.42788 28.461 6.47635C28.8138 6.50524 29.1506 6.56117 29.4763 6.61523C29.8049 6.68029 30.138 6.72049 30.4728 6.73547C30.5674 6.73815 30.6619 6.72748 30.7535 6.70378C30.9967 6.62921 31.3897 6.2312 31.7051 5.91055C31.9147 5.69803 32.1308 5.47898 32.3703 5.27019C34.2623 3.62034 36.8747 1.64519 40.3124 1.01228C41.0324 0.878929 41.7631 0.810917 42.4954 0.809082C44.7307 0.809082 46.8341 1.46156 48.7476 2.74509C50.9727 4.23647 51.9233 6.17248 51.6277 8.62301L54.3627 9.0117L52.7402 10.8125C52.6756 10.8843 52.6204 10.9524 52.5624 11.0232C52.3911 11.2524 52.1838 11.4525 51.9486 11.616C46.0201 15.5225 42.4374 20.9167 40.9955 28.1061C40.7208 29.4565 40.3715 30.7909 39.9494 32.103C38.3484 37.1364 34.6787 40.205 29.0402 41.2275C27.3625 41.5314 25.5492 41.6796 23.4963 41.6796H23.4926ZM17.3226 27.736C16.4201 27.7222 15.5281 27.9309 14.7261 28.3438C13.8054 28.8387 13.2571 29.5555 13.1813 30.3544C13.1055 31.1532 13.5107 31.952 14.3219 32.5998C15.2754 33.3614 16.474 33.7556 17.9804 33.8069C19.121 33.7734 20.0071 33.5459 20.766 33.092C21.5847 32.6017 22.0759 31.924 22.1442 31.183C22.2125 30.442 21.8635 29.6916 21.1487 29.0615C20.2064 28.2319 18.7748 27.736 17.3226 27.736ZM40.8907 7.60234C42.1436 7.71699 43.4254 7.83537 44.6924 7.96773C44.6746 7.5576 44.4603 7.35253 43.9279 7.13722C43.5717 6.99107 43.1904 6.9151 42.8051 6.91351C42.1072 6.91769 41.4317 7.15942 40.8907 7.59861V7.60234Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.4088 56.1721C55.4088 55.6613 55.4088 55.1524 55.4088 54.6453C55.4088 52.8436 55.4088 50.9793 55.4237 49.1552C55.4237 48.5959 55.711 48.1643 55.8803 47.9071L55.9234 47.84L56.1573 47.4746C56.6036 46.7746 57.0649 46.0512 57.5552 45.3475C58.9653 43.3285 59.3742 40.9665 58.8053 38.1171C58.5938 37.0572 58.2991 36.0189 57.9866 34.9199C57.8153 34.3168 57.6376 33.6923 57.4766 33.0687C57.3194 32.4591 57.2979 31.939 57.4111 31.4841C58.0988 28.6878 58.8146 25.8607 59.5127 23.1221L59.8869 21.6307C59.9474 21.4021 60.0623 21.1914 60.2219 21.0165C60.2341 21.0025 60.2509 20.9838 60.2715 20.9577L61.8444 18.9323L62.635 21.3455C62.7536 21.709 62.8718 22.0666 62.9897 22.4184C63.247 23.1948 63.4903 23.9284 63.7223 24.6871C65.313 29.907 66.447 34.7195 67.1843 39.4015C68.3221 46.6291 64.6477 53.7142 58.0408 57.0297C57.8537 57.1229 57.6778 57.1993 57.5178 57.2692L57.3232 57.3541L55.4097 58.2125L55.4088 56.1721Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2552 63.4501C27.2212 63.4231 20.6256 63.1258 14.2555 61.0583C11.1537 60.0507 8.58521 58.7346 6.40319 57.0325C3.57743 54.8299 1.83424 51.7642 1.06979 47.662C0.427911 44.2047 0.484988 40.5779 1.24664 36.2361C1.33085 35.7607 1.59939 35.4251 1.75939 35.2247C1.78653 35.1912 1.82302 35.1455 1.83424 35.1315L2.60431 33.9552L3.75895 34.768C3.78234 34.782 3.8076 34.795 3.83193 34.8081C4.07427 34.9423 4.52527 35.1903 4.75171 35.7234C5.9288 38.4965 8.19316 40.4082 11.8788 41.7384C14.218 42.5829 16.8379 43.0592 19.8911 43.1915C21.6408 43.268 23.5 43.3425 25.3181 43.3425C27.952 43.3425 30.1256 43.1887 32.1588 42.8588C32.419 42.8168 32.6828 42.7656 32.9617 42.7078L33.2124 42.6574C33.4588 42.6068 33.7095 42.5802 33.961 42.5782C34.5505 42.5782 35.9652 42.7777 36.375 44.6289C36.6642 45.9385 36.9533 47.2695 37.2331 48.5559C37.263 48.692 37.2929 48.829 37.322 48.9651C37.3575 48.5922 37.3924 48.2194 37.4268 47.8465C37.6392 45.574 37.859 43.2242 38.0817 40.9116C38.1257 40.4502 38.224 39.7669 38.5608 39.1592C40.0878 36.3983 40.9693 33.4407 41.9031 30.3088C42.0278 29.8912 42.1526 29.4736 42.2773 29.056C43.3487 25.514 44.3761 22.3317 46.1071 19.3238C47.1841 17.453 48.3106 16.1173 49.6524 15.1209C50.9568 14.1515 52.3659 13.5978 53.8377 13.4766C54.0539 13.4589 54.2653 13.4496 54.4665 13.4496C57.0799 13.4496 58.6331 14.9307 58.8418 17.6199C59.0289 20.0657 58.5349 22.369 57.9706 24.4933C57.704 25.4953 57.398 26.4945 57.1023 27.4602C56.5568 29.2396 56.0422 30.9193 55.7428 32.6465C55.1093 36.3004 54.7921 40.0438 54.5432 43.5457C54.3561 46.1305 54.3187 48.7852 54.2794 51.3531C54.2681 52.0914 54.2557 52.8293 54.2419 53.5669C54.198 55.8404 53.2258 57.6449 51.3554 58.9303C48.1489 61.1538 44.416 62.506 40.5248 62.8536C38.6366 63.026 36.7212 63.1547 34.8686 63.2786C34.034 63.3346 33.1993 63.3905 32.3656 63.4511H32.3123L32.2552 63.4501Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.502 41.6533C21.8018 41.6533 20.083 41.5517 18.4783 41.4427C15.5898 41.245 13.2675 40.8293 11.1696 40.1321C6.35836 38.5326 3.75528 35.2273 3.43154 30.3067C3.06662 24.7662 3.94336 20.1392 6.11134 16.1609C7.79557 13.0691 10.029 10.639 12.7547 8.93792C15.4439 7.26011 18.6327 6.273 22.2322 6.0148C22.5373 5.99336 22.8592 5.98218 23.1876 5.98218C24.3628 5.98218 25.5268 6.12106 26.6496 6.25529C27.2803 6.33079 27.8763 6.40163 28.4751 6.4501C28.827 6.479 29.1638 6.53492 29.4885 6.58899C29.8165 6.65458 30.149 6.6954 30.4831 6.71109C30.5777 6.71384 30.6722 6.70317 30.7638 6.6794C31.0071 6.60483 31.3991 6.20682 31.7145 5.88617C31.9241 5.67365 32.1402 5.4546 32.3797 5.24581C34.2698 3.59596 36.8795 1.62081 40.3143 0.987902C41.0333 0.853979 41.7631 0.785343 42.4945 0.782837C44.728 0.782837 46.8295 1.43532 48.7402 2.71884C50.9643 4.21023 51.914 6.14623 51.6184 8.59676L54.3506 8.98546L52.7234 10.7891C52.6588 10.8609 52.6036 10.9289 52.5456 10.9998C52.3747 11.2289 52.1677 11.4291 51.9327 11.5926C46.0089 15.4991 42.4299 20.8932 40.9852 28.0826C40.71 29.4323 40.3598 30.7657 39.9363 32.0768C38.3372 37.1102 34.6703 40.1787 29.0375 41.2012C27.3607 41.5051 25.5492 41.6533 23.4982 41.6533H23.502ZM17.3377 27.7098C16.436 27.6959 15.5449 27.9047 14.744 28.3175C13.8242 28.8125 13.2759 29.5293 13.2001 30.3281C13.1243 31.1269 13.5294 31.9258 14.3397 32.5736C15.2923 33.3351 16.4918 33.7294 17.9945 33.7853C19.1351 33.7518 20.0203 33.5243 20.7782 33.0704C21.596 32.5801 22.0882 31.9025 22.1555 31.1614C22.2229 30.4204 21.8748 29.67 21.1609 29.0399C20.2187 28.2057 18.7889 27.7098 17.3377 27.7098ZM40.8851 7.57237C42.138 7.68702 43.418 7.8054 44.684 7.93776C44.6662 7.52763 44.4519 7.32256 43.9205 7.10724C43.5644 6.96062 43.183 6.88463 42.7977 6.88353C42.0999 6.88897 41.425 7.13204 40.8851 7.57237Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.3882 56.1459C55.3882 55.6342 55.3882 55.1262 55.3882 54.6191C55.3882 52.8173 55.3882 50.9531 55.4022 49.129C55.4078 48.5697 55.6895 48.1381 55.8579 47.8809L55.9019 47.8137L56.1367 47.4446C56.5831 46.7446 57.0443 46.0213 57.5346 45.3175C58.9428 43.2986 59.3508 40.9366 58.7829 38.0871C58.5714 37.0273 58.2766 35.9889 57.9651 34.89C57.7938 34.2869 57.617 33.6624 57.456 33.0388C57.2951 32.4152 57.2773 31.9091 57.3906 31.4542C58.0764 28.6625 58.7941 25.8307 59.4893 23.0922L59.8636 21.6008C59.924 21.3722 60.0389 21.1615 60.1985 20.9865C60.2107 20.9725 60.2275 20.9539 60.2481 20.9278L61.8191 18.9042L62.6061 21.3193C62.7252 21.6828 62.8434 22.0404 62.9607 22.3922C63.218 23.1686 63.4613 23.9022 63.6924 24.6609C65.283 29.8808 66.4143 34.6933 67.1544 39.3753C68.2903 46.6057 64.6187 53.6898 58.0184 57.0053C57.8313 57.0986 57.6554 57.175 57.4963 57.2449C57.4308 57.2729 57.3653 57.3008 57.3017 57.3297L55.3882 58.1882V56.1459Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2571 63.4241C27.2269 63.397 20.6378 63.0997 14.2732 61.0322C11.1743 60.0284 8.60393 58.7085 6.42753 57.0064C3.60551 54.8085 1.867 51.7381 1.10255 47.6359C0.463478 44.1824 0.518683 40.5471 1.28033 36.21C1.36361 35.7346 1.63215 35.3991 1.79215 35.1987C1.81929 35.1651 1.85484 35.1194 1.867 35.1054L2.63614 33.9291L3.78609 34.7456C3.80949 34.7596 3.83475 34.7727 3.85908 34.7857C4.10048 34.92 4.55148 35.1679 4.77792 35.7011C5.95408 38.4741 8.21562 40.3849 11.8947 41.7141C14.2339 42.5596 16.8539 43.0359 19.9005 43.1682C21.6483 43.2447 23.5056 43.3192 25.319 43.3192C27.9511 43.3192 30.1228 43.1654 32.1542 42.8355C32.4143 42.7935 32.6772 42.7423 32.9561 42.6845L33.2068 42.6341C33.4531 42.5834 33.7039 42.5569 33.9554 42.5549C34.5439 42.5549 35.9577 42.7544 36.3666 44.6056C36.6567 45.9152 36.9449 47.2463 37.2247 48.5326C37.2546 48.6687 37.2836 48.8057 37.3182 48.9418C37.3538 48.5689 37.3887 48.1961 37.423 47.8232C37.6354 45.5507 37.8544 43.2009 38.078 40.8883C38.122 40.4269 38.2202 39.7437 38.5561 39.1359C40.0822 36.375 40.9636 33.4174 41.8956 30.2808C42.0203 29.8632 42.1451 29.4456 42.2699 29.028C43.3403 25.486 44.3667 22.3038 46.0968 19.2958C47.1719 17.4251 48.2975 16.0893 49.6384 15.0929C50.9418 14.1235 52.3519 13.5698 53.82 13.4487C54.0352 13.431 54.2466 13.4216 54.4478 13.4216C57.0593 13.4216 58.6116 14.9028 58.8193 17.5919C59.0065 20.0378 58.5133 22.341 57.9491 24.4653C57.6834 25.4674 57.3774 26.4666 57.0817 27.4323C56.5362 29.2117 56.0226 30.8913 55.7231 32.6186C55.0906 36.2724 54.7734 40.0158 54.5245 43.5178C54.3374 46.1025 54.3009 48.7572 54.2616 51.3252C54.2504 52.0634 54.2379 52.8013 54.2242 53.539C54.1793 55.8124 53.209 57.617 51.3395 58.9024C48.1358 61.1257 44.4052 62.4777 40.5164 62.8247C38.6301 62.9971 36.7156 63.1258 34.8649 63.2497C34.0312 63.3057 33.1975 63.3616 32.3638 63.4222H32.3104L32.2571 63.4241Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.5112 41.6273C21.8129 41.6273 20.095 41.5257 18.4922 41.4167C15.6056 41.219 13.286 40.8033 11.1939 40.1061C6.3835 38.5066 3.7823 35.2013 3.45761 30.2807C3.09363 24.7402 3.96943 20.1132 6.13461 16.1349C7.81884 13.0431 10.0495 10.613 12.7695 8.91192C15.4568 7.23411 18.6428 6.247 22.2396 5.9888C22.5437 5.96736 22.8656 5.95618 23.194 5.95618C24.3683 5.95618 25.5332 6.09506 26.656 6.22929C27.2867 6.30479 27.8818 6.37563 28.4797 6.4241C28.8315 6.453 29.1674 6.50892 29.493 6.56299C29.8207 6.62799 30.1529 6.66818 30.4867 6.68323C30.5813 6.68597 30.6758 6.6753 30.7674 6.65154C31.0098 6.57697 31.4028 6.17895 31.7172 5.85831C31.9268 5.64578 32.1429 5.42674 32.3815 5.21794C34.2706 3.5681 36.8784 1.59294 40.3096 0.960037C41.028 0.826755 41.7571 0.758742 42.4878 0.756836C44.7194 0.756836 46.8191 1.40932 48.7288 2.69284C50.9502 4.18423 51.8989 6.12023 51.6042 8.57076L54.3345 8.95945L52.7083 10.7631C52.6437 10.8349 52.5885 10.9029 52.5305 10.9738C52.3598 11.2031 52.1528 11.4033 51.9177 11.5666C45.9985 15.4731 42.4223 20.8672 40.9786 28.0566C40.7035 29.4062 40.354 30.7396 39.9315 32.0508C38.3334 37.0842 34.6692 40.1527 29.0411 41.1752C27.3672 41.4791 25.5566 41.6273 23.5084 41.6273H23.5112ZM17.3525 27.6838C16.4515 27.67 15.561 27.8788 14.7607 28.2915C13.8418 28.7865 13.2935 29.5033 13.2187 30.3021C13.1438 31.1009 13.5471 31.8998 14.3574 32.5476C15.309 33.3091 16.5039 33.7034 18.0066 33.7547C19.1453 33.7211 20.0295 33.4937 20.7874 33.0397C21.6043 32.5494 22.0927 31.8718 22.1629 31.1308C22.2331 30.3897 21.8822 29.6394 21.1692 29.0093C20.2307 28.1797 18.8028 27.6838 17.3525 27.6838ZM40.8775 7.5501C42.1295 7.66475 43.4085 7.78312 44.6726 7.91548C44.6558 7.50535 44.4415 7.30029 43.9101 7.08497C43.554 6.93811 43.1726 6.86211 42.7872 6.86126C42.0904 6.86718 41.4166 7.11023 40.8775 7.5501Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.3704 56.1199C55.3704 55.6081 55.3704 55.1001 55.3704 54.5931C55.3704 52.7913 55.3704 50.927 55.3854 49.1029C55.3854 48.5436 55.6717 48.1121 55.8401 47.8548L55.8841 47.7877L56.1171 47.4223C56.5625 46.7223 57.0228 45.9989 57.5131 45.2952C58.9167 43.2762 59.3284 40.9143 58.7613 38.0648C58.5499 37.005 58.2551 35.9666 57.9436 34.8676C57.7723 34.2645 57.5955 33.64 57.4345 33.0164C57.2783 32.4068 57.2568 31.8867 57.369 31.4318C58.0549 28.6402 58.7726 25.8084 59.4669 23.0698L59.8411 21.5784C59.9012 21.3497 60.0162 21.1389 60.1761 20.9642C60.1883 20.9502 60.2042 20.9316 60.2248 20.9055L61.7948 18.8818L62.5817 21.2969C62.6996 21.6605 62.8185 22.0175 62.9354 22.3698C63.1927 23.1463 63.436 23.8798 63.6671 24.6386C65.2578 29.8584 66.3862 34.6709 67.1217 39.353C68.2576 46.5806 64.5897 53.6656 57.995 56.9811C57.8079 57.0743 57.632 57.1508 57.4729 57.2207C57.4074 57.2487 57.3428 57.2766 57.2792 57.3055L55.3704 58.164V56.1199Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.259 63.3977C27.2334 63.3706 20.649 63.0733 14.2939 61.0058C11.1949 59.9982 8.6311 58.6821 6.45189 56.98C3.63173 54.7774 1.89136 51.7117 1.13158 47.6095C0.491574 44.1523 0.547715 40.5254 1.30843 36.1836C1.3917 35.7082 1.66024 35.3727 1.82025 35.1723C1.84738 35.1387 1.88294 35.093 1.8951 35.079L2.6633 33.9027L3.81325 34.7192C3.83571 34.7332 3.86097 34.7463 3.8853 34.7593C4.12671 34.8935 4.57677 35.1415 4.80321 35.6747C5.98404 38.4449 8.23904 40.3604 11.9153 41.6849C14.2546 42.5304 16.8679 43.0067 19.9126 43.139C21.6586 43.2155 23.516 43.29 25.3275 43.29C27.9567 43.29 30.1256 43.1362 32.158 42.8063C32.4171 42.7643 32.6801 42.7131 32.9589 42.6553L33.2087 42.6049C33.455 42.5543 33.7058 42.5277 33.9573 42.5257C34.5458 42.5257 35.9578 42.7252 36.3667 44.5764C36.6558 45.886 36.9449 47.2171 37.2237 48.5034C37.2537 48.6395 37.2827 48.7765 37.3126 48.9126C37.3476 48.5397 37.3825 48.1669 37.4174 47.794C37.6289 45.5215 37.8479 43.1717 38.0724 40.8591C38.1164 40.3977 38.2146 39.7145 38.5505 39.1067C40.0748 36.3458 40.9553 33.3882 41.8863 30.2563C42.011 29.8387 42.1358 29.4211 42.2605 29.0035C43.33 25.4615 44.3555 22.2792 46.0837 19.2713C47.1588 17.4005 48.2826 16.0648 49.6216 15.0684C50.925 14.099 52.3304 13.5453 53.8003 13.4241C54.0155 13.4064 54.227 13.3971 54.4282 13.3971C57.0359 13.3971 58.5873 14.8782 58.795 17.5674C58.9822 20.0133 58.4891 22.3165 57.9258 24.4408C57.66 25.4428 57.3541 26.4421 57.0584 27.4077C56.5148 29.1871 56.0001 30.8668 55.7016 32.594C55.0701 36.2479 54.7529 39.9913 54.5049 43.4932C54.3178 46.078 54.2803 48.7327 54.241 51.3007C54.2298 52.0389 54.2176 52.7768 54.2045 53.5144C54.1596 55.7879 53.1903 57.5924 51.3226 58.8778C48.1226 61.0996 44.3958 62.4506 40.5108 62.7974C38.6263 62.9698 36.7138 63.0984 34.8649 63.2224C34.0321 63.2783 33.1994 63.3343 32.3666 63.3949H32.3133L32.259 63.3977Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.5206 41.6013C21.8233 41.6013 20.1072 41.4997 18.5063 41.3907C15.6225 41.193 13.3048 40.7773 11.2079 40.0801C6.40506 38.4806 3.80667 35.1753 3.48386 30.2547C3.11988 24.7142 3.99474 20.0872 6.15804 16.1089C7.84228 13.0171 10.0692 10.587 12.7864 8.88592C15.4718 7.20811 18.655 6.221 22.2481 5.9628C22.5522 5.94136 22.8731 5.93018 23.2015 5.93018C24.3749 5.93018 25.5408 6.06906 26.6636 6.20329C27.2933 6.27879 27.8884 6.34963 28.4854 6.3981C28.8372 6.42699 29.1731 6.48292 29.4978 6.53698C29.8255 6.60204 30.1577 6.64224 30.4915 6.65723C30.5861 6.66006 30.6806 6.64939 30.7722 6.62554C31.0145 6.55097 31.4066 6.15295 31.7219 5.8323C31.9305 5.61978 32.1467 5.40073 32.3853 5.19194C34.2716 3.5421 36.8766 1.56694 40.3059 0.934036C41.0215 0.801122 41.7477 0.73311 42.4757 0.730835C44.7054 0.730835 46.8032 1.38332 48.7111 2.66684C50.9306 4.15822 51.8784 6.09423 51.5837 8.54476L54.3112 8.93345L52.6868 10.7371C52.6223 10.8089 52.5671 10.8769 52.5091 10.9478C52.3387 11.1771 52.132 11.3774 51.8971 11.5406C45.9873 15.4471 42.414 20.8412 40.9786 28.0306C40.7036 29.3802 40.354 30.7136 39.9316 32.0248C38.3353 37.0582 34.6749 40.1267 29.0514 41.1492C27.3784 41.4531 25.5698 41.6013 23.5234 41.6013H23.5206ZM17.3675 27.6578C16.4677 27.6441 15.5784 27.8529 14.7794 28.2655C13.8606 28.7605 13.3132 29.4773 13.2384 30.2761C13.1635 31.0749 13.5631 31.8784 14.3752 32.5216C15.3259 33.2831 16.5189 33.6774 18.0244 33.7333C19.1622 33.6998 20.0464 33.4723 20.8024 33.0184C21.6193 32.5281 22.1068 31.8505 22.1779 31.1094C22.249 30.3684 21.8972 29.618 21.1842 28.9879C20.2429 28.15 18.816 27.6578 17.3675 27.6578ZM40.8719 7.5241C42.122 7.63875 43.3983 7.75712 44.6633 7.88948C44.6456 7.47935 44.4313 7.27429 43.9017 7.05897C43.5457 6.91204 43.1642 6.83604 42.7789 6.83526C42.0834 6.84009 41.4104 7.08185 40.8719 7.52037V7.5241Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.3517 56.0939C55.3517 55.5821 55.3517 55.0741 55.3517 54.5671C55.3517 52.7653 55.3517 50.901 55.3658 49.0769C55.3714 48.5176 55.653 48.0861 55.8205 47.8288L55.8645 47.7617L56.0975 47.3963C56.5429 46.6963 57.0023 45.9729 57.4926 45.2692C58.8961 43.2502 59.3059 40.8883 58.7389 38.0388C58.5274 36.979 58.2336 35.9406 57.922 34.8416C57.7508 34.2385 57.574 33.614 57.414 32.9904C57.254 32.3669 57.2353 31.8607 57.3485 31.4058C58.0334 28.6142 58.752 25.7824 59.4435 23.0438L59.8177 21.5524C59.878 21.324 59.9926 21.1133 60.1518 20.9382C60.1649 20.9242 60.1808 20.9056 60.2014 20.8795L61.7696 18.8558L62.5556 21.2709C62.6741 21.6345 62.792 21.9921 62.9092 22.3438C63.1666 23.1203 63.4089 23.8538 63.64 24.6126C65.2307 29.8324 66.3535 34.6449 67.0918 39.327C68.2267 46.5546 64.5654 53.6396 57.9735 56.9551C57.7911 57.0484 57.6105 57.1248 57.4514 57.1947L57.2577 57.2795L55.3499 58.138L55.3517 56.0939Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2599 63.3717C27.2391 63.3447 20.6575 63.0473 14.3088 60.9799C11.2155 59.9723 8.65356 58.6561 6.47715 56.9522C3.65981 54.7487 1.92131 51.6839 1.16247 47.5817C0.522458 44.1245 0.578599 40.4976 1.33931 36.1558C1.42259 35.6804 1.69019 35.3449 1.85019 35.1445C1.87733 35.1109 1.91289 35.0652 1.92411 35.0512L2.69231 33.8749L3.84133 34.6914C3.86379 34.7054 3.88905 34.7185 3.91338 34.7315C4.15479 34.8657 4.60391 35.1137 4.83035 35.6469C6.00463 38.4199 8.26244 40.3307 11.935 41.6599C14.2742 42.5054 16.8829 42.9817 19.9258 43.114C21.6699 43.1905 23.5253 43.265 25.3349 43.265C27.9614 43.265 30.1294 43.1112 32.157 42.7813C32.4171 42.7393 32.6791 42.6881 32.958 42.6303L33.2078 42.5799C33.4541 42.5292 33.7048 42.5026 33.9563 42.5007C34.544 42.5007 35.955 42.7002 36.3639 44.5514C36.653 45.861 36.9412 47.1921 37.22 48.4784C37.249 48.6145 37.279 48.7515 37.3136 48.8876C37.3485 48.5147 37.3831 48.1419 37.4174 47.769C37.6298 45.4965 37.8488 43.1467 38.0724 40.8341C38.1164 40.3727 38.2146 39.6895 38.5496 39.0817C40.0738 36.3208 40.9525 33.3632 41.8835 30.2266C42.0079 29.809 42.1314 29.3877 42.2577 28.9738C43.3263 25.4318 44.3509 22.2496 46.0772 19.2416C47.1514 17.3709 48.2751 16.0351 49.6122 15.0387C50.9147 14.0693 52.3192 13.5156 53.7872 13.3945C54.0025 13.3768 54.213 13.3674 54.4142 13.3674C57.02 13.3674 58.5695 14.8486 58.7782 17.5377C58.9653 19.9836 58.4722 22.2868 57.9089 24.4111C57.6432 25.4132 57.3382 26.4124 57.0425 27.3781C56.4989 29.1575 55.9852 30.8371 55.6867 32.5644C55.0551 36.2183 54.7388 39.9616 54.4909 43.4636C54.3037 46.0483 54.2673 48.703 54.228 51.271C54.2167 52.0092 54.2043 52.7472 54.1905 53.4848C54.1456 55.7582 53.1772 57.5628 51.3114 58.8482C48.1155 61.071 44.392 62.4234 40.5099 62.7714C38.6273 62.9439 36.7166 63.0725 34.8686 63.1965C34.0368 63.2524 33.2041 63.3083 32.3732 63.3689H32.3189L32.2599 63.3717Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.5299 41.5724C21.8345 41.5724 20.1203 41.4708 18.5203 41.3617C15.6393 41.1641 13.3226 40.7484 11.2313 40.0512C6.43311 38.4517 3.83939 35.1464 3.51377 30.2257C3.14979 24.6853 4.02465 20.0582 6.18609 16.08C7.87032 12.9881 10.0935 10.5581 12.8089 8.857C15.4915 7.17919 18.6719 6.19208 22.2593 5.93388C22.5634 5.91244 22.8843 5.90126 23.2118 5.90126C24.3842 5.90126 25.5445 6.04014 26.6664 6.17437C27.2951 6.24987 27.8902 6.32071 28.4872 6.36918C28.8381 6.39808 29.174 6.454 29.4977 6.50806C29.8251 6.57326 30.157 6.61315 30.4905 6.62738C30.5851 6.63045 30.6796 6.62009 30.7712 6.59662C31.0135 6.52205 31.4056 6.12403 31.72 5.80338C31.9286 5.59086 32.1439 5.37182 32.3825 5.16302C34.2679 3.51318 36.8737 1.53802 40.2965 0.905117C41.0152 0.772425 41.7447 0.705345 42.4757 0.704712C44.7035 0.704712 46.7995 1.35719 48.7055 2.64072C50.923 4.1321 51.87 6.06811 51.5752 8.51864L54.3009 8.90733L52.6746 10.711C52.6101 10.7827 52.5549 10.8508 52.4969 10.9216C52.3266 11.1508 52.1203 11.351 51.8859 11.5145C45.9789 15.421 42.4083 20.8151 40.972 28.0045C40.6973 29.354 40.3481 30.6874 39.9259 31.9986C38.3353 37.0321 34.6739 40.1006 29.0561 41.1231C27.384 41.427 25.5772 41.5752 23.5356 41.5752L23.5299 41.5724ZM17.3825 27.6289C16.4824 27.6171 15.5934 27.8282 14.7953 28.2431C13.8783 28.7381 13.331 29.4549 13.2561 30.2537C13.1813 31.0525 13.5836 31.8514 14.393 32.4992C15.3427 33.2607 16.5348 33.655 18.0421 33.7063C19.1781 33.6727 20.0614 33.4453 20.8174 32.9913C21.6333 32.501 22.1208 31.8234 22.191 31.0824C22.2611 30.3413 21.9103 29.591 21.1991 28.9609C20.255 28.1276 18.83 27.6317 17.3825 27.6317V27.6289ZM40.8682 7.49518C42.1173 7.60983 43.3945 7.7282 44.6568 7.86057C44.639 7.45043 44.4247 7.24537 43.8951 7.03005C43.5392 6.88289 43.1577 6.80688 42.7723 6.80634C42.0764 6.81181 41.4034 7.05459 40.8654 7.49424L40.8682 7.49518Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.332 56.0677C55.332 55.556 55.332 55.048 55.332 54.5409C55.332 52.7391 55.332 50.8749 55.3461 49.0507C55.3517 48.4915 55.6333 48.0599 55.8017 47.8026L55.8448 47.7355L56.0778 47.3701C56.5222 46.6701 56.9816 45.9468 57.471 45.243C58.8745 43.2241 59.2825 40.8621 58.7164 38.0126C58.5059 36.9528 58.2111 35.9144 57.9005 34.8155C57.7293 34.2124 57.5533 33.5879 57.3924 32.9643C57.2361 32.3547 57.2146 31.8345 57.3269 31.3797C58.0118 28.588 58.7304 25.7562 59.421 23.0177L59.7952 21.5263C59.8555 21.2978 59.9701 21.0871 60.1293 20.912C60.1424 20.898 60.1583 20.8794 60.1789 20.8533L61.7508 18.8287L62.5359 21.2438C62.6538 21.6074 62.7726 21.9644 62.8886 22.3167C63.1459 23.0932 63.3883 23.8267 63.6194 24.5855C65.21 29.8053 66.3329 34.6178 67.0683 39.2999C68.2014 46.5275 64.542 53.6116 57.9576 56.928C57.7751 57.0212 57.5955 57.0977 57.4364 57.1676C57.3709 57.1956 57.3063 57.2235 57.2427 57.2524L55.3358 58.1109L55.332 56.0677Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2618 63.3458C27.2456 63.3188 20.6733 63.0214 14.3256 60.954C11.2379 59.9464 8.67597 58.6302 6.50238 56.9282C3.6869 54.7246 1.95027 51.6599 1.19143 47.5576C0.55236 44.1004 0.608501 40.4735 1.36828 36.1317C1.45155 35.6564 1.71916 35.3208 1.87916 35.1204C1.90536 35.0868 1.94185 35.0412 1.95308 35.0272L2.72034 33.8508L3.86749 34.6674C3.89088 34.6814 3.91615 34.6944 3.94047 34.7075C4.18188 34.8417 4.63101 35.0896 4.85651 35.6228C6.02986 38.3958 8.28579 40.3067 11.9546 41.6359C14.2873 42.4813 16.8988 42.9576 19.9379 43.09C21.6811 43.1664 23.5346 43.241 25.3424 43.241C27.967 43.241 30.1331 43.0872 32.1589 42.7572C32.418 42.7153 32.681 42.664 32.9589 42.6062L33.2087 42.5559C33.455 42.505 33.7057 42.4785 33.9572 42.4766C34.5448 42.4766 35.954 42.6761 36.3619 44.5273C36.6511 45.8369 36.9393 47.168 37.2181 48.4543C37.2471 48.5904 37.2771 48.7274 37.307 48.8635C37.3419 48.4907 37.3765 48.1178 37.4109 47.745C37.6223 45.4725 37.8413 43.1226 38.0658 40.81C38.1098 40.3486 38.2081 39.6654 38.543 39.0576C40.0654 36.2967 40.9431 33.3391 41.8731 30.2072C41.9976 29.7896 42.1211 29.3683 42.2474 28.9544C43.315 25.4124 44.3396 22.2302 46.0641 19.2222C47.1364 17.3515 48.2592 16.0157 49.5953 15.0193C50.8959 14.0499 52.2995 13.4962 53.7666 13.3751C53.9818 13.3573 54.1924 13.348 54.3935 13.348C56.9966 13.348 58.5452 14.8292 58.7529 17.5183C58.94 19.9642 58.4469 22.2674 57.8846 24.3917C57.6188 25.3938 57.3138 26.393 57.0191 27.3587C56.4764 29.1381 55.9627 30.8177 55.6642 32.5449C55.0335 36.1988 54.7173 39.9422 54.4693 43.4442C54.2869 46.0289 54.2457 48.6836 54.2064 51.2516C54.1952 51.9898 54.183 52.7277 54.1699 53.4654C54.125 55.7388 53.1575 57.5434 51.2936 58.8288C48.101 61.0513 44.3806 62.4037 40.5014 62.752C38.6198 62.9245 36.711 63.0531 34.8649 63.1771C34.034 63.233 33.2021 63.2889 32.3713 63.3495H32.3179L32.2618 63.3458Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.5394 41.5489C21.8458 41.5489 20.1325 41.4473 18.5344 41.3383C15.6553 41.1407 13.3413 40.725 11.252 40.0277C6.45752 38.4282 3.86006 35.1229 3.54099 30.2023C3.17701 24.6618 4.05094 20.0348 6.21143 16.0565C7.88911 12.9647 10.1151 10.5347 12.8277 8.83355C15.5075 7.15574 18.685 6.16863 22.2715 5.91043C22.5756 5.88899 22.8966 5.87781 23.2241 5.87781C24.3946 5.87781 25.5539 6.01669 26.6749 6.15092C27.3036 6.22642 27.8978 6.29726 28.4938 6.34573C28.8447 6.37463 29.1797 6.43055 29.5044 6.48462C29.8323 6.54999 30.1649 6.58988 30.499 6.60393C30.5936 6.607 30.6881 6.59664 30.7797 6.57317C31.022 6.4986 31.4132 6.10058 31.7276 5.77994C31.9362 5.56741 32.1514 5.34837 32.39 5.13957C34.2735 3.48973 36.8738 1.51457 40.2966 0.881668C41.0131 0.748325 41.7403 0.68031 42.4692 0.678467C44.6943 0.678467 46.7883 1.33095 48.6934 2.61447C50.9081 4.10586 51.8551 6.04186 51.5603 8.49239L54.2832 8.88109L52.656 10.6847C52.5914 10.7565 52.5372 10.8245 52.4792 10.8954C52.3089 11.1245 52.1026 11.3247 51.8682 11.4882C45.9659 15.3947 42.399 20.7861 40.9637 27.9783C40.6892 29.3274 40.3406 30.6604 39.9195 31.9715C38.3288 37.0049 34.6712 40.0734 29.059 41.0959C27.3888 41.3998 25.5829 41.548 23.5384 41.548L23.5394 41.5489ZM17.3994 27.6054C16.5007 27.5917 15.6127 27.8005 14.8151 28.2132C13.8981 28.7081 13.3516 29.4249 13.2759 30.2237C13.2001 31.0226 13.6043 31.8214 14.4118 32.4692C15.3606 33.2307 16.5526 33.625 18.0544 33.681C19.1903 33.6474 20.0717 33.42 20.8278 32.966C21.6418 32.4757 22.1293 31.7981 22.1995 31.0571C22.2697 30.316 21.9188 29.5657 21.2086 28.9356C20.2673 28.1004 18.8432 27.6054 17.3994 27.6054ZM40.8617 7.47173C42.109 7.58638 43.3881 7.70476 44.6456 7.83712C44.6288 7.42699 44.4145 7.22192 43.8858 7.0066C43.5299 6.85944 43.1484 6.78343 42.763 6.78289C42.0687 6.78782 41.397 7.02962 40.8598 7.468L40.8617 7.47173Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.3133 56.0417C55.3133 55.53 55.3133 55.022 55.3133 54.5149C55.3133 52.7132 55.3133 50.8489 55.3273 49.0248C55.333 48.4655 55.6137 48.0339 55.7821 47.7767L55.8251 47.7096L56.0572 47.3442C56.5016 46.6441 56.9611 45.9208 57.4495 45.2171C58.853 43.1981 59.26 40.8361 58.6939 37.9867C58.4834 36.9268 58.1896 35.8885 57.879 34.7895C57.7087 34.1864 57.5318 33.5619 57.3718 32.9383C57.2118 32.3147 57.194 31.8086 57.3063 31.3537C57.9903 28.562 58.7098 25.7303 59.3976 22.9917L59.7718 21.5003C59.8321 21.2718 59.9467 21.0611 60.1059 20.8861C60.1181 20.8721 60.1349 20.8534 60.1555 20.8264L61.7209 18.8037L62.505 21.2188C62.6235 21.5823 62.7414 21.94 62.8587 22.2917C63.1151 23.0681 63.3574 23.8017 63.5876 24.5605C65.1726 29.7803 66.3011 34.5928 67.0337 39.2748C67.5869 42.7948 67.0034 46.3989 65.3674 49.5672C63.7315 52.7355 61.1276 55.3043 57.9314 56.903C57.7442 56.9962 57.5692 57.0727 57.4102 57.1426L57.2165 57.2274L55.3124 58.0859L55.3133 56.0417Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2637 63.3197C27.2512 63.2926 20.6855 62.9953 14.3435 60.9279C11.2557 59.9202 8.69942 58.6041 6.52676 56.902C3.71409 54.6985 1.9784 51.6337 1.22143 47.5315C0.582357 44.0743 0.638498 40.4474 1.39734 36.1056C1.48061 35.6302 1.74822 35.2947 1.90822 35.0943C1.93442 35.0607 1.97091 35.015 1.98214 35.001L2.74847 33.8247L3.89468 34.6413C3.91807 34.6552 3.9424 34.6683 3.96766 34.6813C4.20814 34.8156 4.65726 35.0635 4.88276 35.5967C6.05518 38.3697 8.30831 40.2806 11.9743 41.6097C14.3051 42.4552 16.9138 42.9315 19.951 43.0639C21.6923 43.1403 23.5441 43.2149 25.3509 43.2149C27.9708 43.2149 30.1369 43.0611 32.1608 42.7311C32.42 42.6891 32.682 42.6379 32.9599 42.5801L33.2097 42.5297C33.456 42.4788 33.7067 42.4523 33.9582 42.4505C34.5449 42.4505 35.9531 42.65 36.3611 44.5012C36.6493 45.8108 36.9375 47.1419 37.2163 48.4282C37.2453 48.5643 37.2752 48.7013 37.3042 48.8374C37.3398 48.4645 37.3747 48.0917 37.409 47.7188C37.6205 45.4463 37.8385 43.0955 38.064 40.7839C38.108 40.3178 38.2062 39.6393 38.5412 39.0315C40.0617 36.2706 40.9394 33.313 41.8685 30.1811C41.9933 29.7635 42.118 29.3459 42.2428 28.9283C43.3095 25.3863 44.3331 22.204 46.0557 19.1961C47.128 17.3253 48.2489 15.9896 49.5842 14.9932C50.8838 14.0238 52.2864 13.4701 53.7517 13.3489C53.966 13.3312 54.1765 13.3219 54.3777 13.3219C56.9789 13.3219 58.5256 14.803 58.7333 17.4922C58.9204 19.9381 58.4283 22.2413 57.8659 24.3656C57.6011 25.3676 57.2961 26.3669 57.0013 27.3325C56.4586 29.1119 55.9459 30.7916 55.6483 32.5188C55.0139 36.1662 54.6958 39.9096 54.4525 43.4115C54.2654 45.9963 54.2289 48.651 54.1896 51.2189C54.1784 51.9572 54.1659 52.6951 54.1522 53.4327C54.1082 55.7061 53.1407 57.5107 51.2787 58.7961C48.0897 61.0183 44.3727 62.3708 40.4968 62.7194C38.617 62.8918 36.7101 63.0204 34.8649 63.1444C34.035 63.2003 33.2041 63.2563 32.3741 63.3169H32.3208L32.2637 63.3197Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.5487 41.5231C21.857 41.5231 20.1447 41.4215 18.5475 41.3124C15.6721 41.1148 13.36 40.6991 11.2725 40.0019C6.48277 38.4033 3.89092 35.0971 3.56811 30.1764C3.206 24.6359 4.07806 20.0089 6.23575 16.0325C7.91249 12.9407 10.1366 10.5106 12.8464 8.80953C15.5243 7.13172 18.6991 6.14461 22.2827 5.88642C22.5859 5.86498 22.9059 5.85379 23.2334 5.85379C24.4039 5.85379 25.5614 5.99268 26.6814 6.1269C27.3092 6.20241 27.9025 6.27325 28.4985 6.32172C28.8494 6.35061 29.1843 6.40654 29.5081 6.4606C29.8348 6.52521 30.1661 6.56448 30.499 6.57805C30.5935 6.58116 30.6881 6.5708 30.7797 6.54729C31.022 6.47272 31.4131 6.07471 31.7266 5.75406C31.9353 5.54153 32.1505 5.32249 32.3881 5.11369C34.2679 3.46385 36.8663 1.49149 40.2853 0.855789C41.0012 0.722446 41.7278 0.654431 42.4561 0.652588C44.6793 0.652588 46.7715 1.30507 48.6747 2.58859C50.8876 4.07998 51.8335 6.01598 51.5397 8.46651L54.2598 8.85521L52.6401 10.6589C52.5746 10.7306 52.5203 10.7987 52.4623 10.8695C52.2921 11.0981 52.0862 11.298 51.8522 11.4614C45.9574 15.3688 42.3915 20.763 40.9581 27.9524C40.6832 29.3017 40.3346 30.635 39.9138 31.9465C38.3232 36.9799 34.674 40.0485 29.0599 41.071C27.3916 41.3749 25.5876 41.5231 23.5468 41.5231H23.5487ZM17.4125 27.5795C16.5144 27.5659 15.627 27.7747 14.83 28.1873C13.914 28.6822 13.3685 29.399 13.2927 30.1979C13.2169 30.9967 13.6211 31.7955 14.4277 32.4433C15.3755 33.2049 16.5666 33.5992 18.0665 33.6495C19.2015 33.6169 20.0829 33.3894 20.8371 32.9355C21.6511 32.4452 22.1386 31.7675 22.2088 31.0265C22.279 30.2855 21.9281 29.5351 21.2179 28.905C20.2804 28.0745 18.8572 27.5795 17.4125 27.5795ZM40.8533 7.44585C42.0996 7.5605 43.3749 7.67888 44.6344 7.81124C44.6175 7.40111 44.4033 7.19604 43.8746 6.98072C43.5187 6.8334 43.1372 6.75738 42.7518 6.75701C42.059 6.76278 41.389 7.00454 40.8533 7.44212V7.44585Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.2946 56.0201C55.2946 55.5084 55.2946 55.0004 55.2946 54.4933C55.2946 52.6906 55.2946 50.8273 55.3087 49.0032C55.3143 48.4439 55.595 48.0123 55.7634 47.7551L55.8064 47.6879L56.0385 47.3226C56.4829 46.6225 56.9414 45.8992 57.4298 45.1955C58.8334 43.1765 59.2385 40.8145 58.6734 37.965C58.4619 36.9052 58.169 35.8669 57.8584 34.7679C57.6881 34.1648 57.5113 33.5403 57.3512 32.9167C57.195 32.3071 57.1735 31.787 57.2858 31.3321C57.9697 28.5404 58.6893 25.7087 59.3761 22.9701L59.7503 21.4787C59.81 21.2502 59.9243 21.0394 60.0834 20.8645C60.0956 20.8505 60.1125 20.8318 60.133 20.8048L61.6966 18.7821L62.4807 21.1972C62.5986 21.5607 62.7162 21.9184 62.8334 22.2701C63.0898 23.0465 63.3321 23.7801 63.5623 24.5389C65.1455 29.7587 66.2758 34.5712 67.0047 39.2532C67.5567 42.7708 66.9739 46.3722 65.3404 49.5388C63.7069 52.7055 61.1068 55.2741 57.9145 56.8749C57.7274 56.9681 57.5524 57.0445 57.3943 57.1144C57.3288 57.1424 57.2642 57.1704 57.2006 57.1993L55.2946 58.0577V56.0201Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2647 63.2907C27.2578 63.2636 20.6978 62.9663 14.3613 60.8979C11.2735 59.8912 8.72193 58.5751 6.55208 56.8731C3.73847 54.6723 2.00746 51.6075 1.25049 47.5053C0.612352 44.0481 0.668493 40.4212 1.42733 36.0794C1.51061 35.604 1.77728 35.2685 1.93728 35.0681C1.96348 35.0345 1.99997 34.9888 2.0112 34.9748L2.77659 33.7985L3.92561 34.6123C3.949 34.6262 3.97333 34.6393 3.99766 34.6523C4.23907 34.7866 4.68726 35.0345 4.91276 35.5677C6.08424 38.3407 8.33549 40.2516 11.9978 41.5808C14.3267 42.4262 16.9335 42.9025 19.967 43.0349C21.7074 43.1113 23.5572 43.1859 25.3621 43.1859C27.9821 43.1859 30.1435 43.0321 32.1664 42.7021C32.4247 42.6601 32.6867 42.6089 32.9646 42.5511L33.2125 42.5045C33.4588 42.4536 33.7096 42.4271 33.9611 42.4253C34.5478 42.4253 35.955 42.6247 36.3621 44.4759C36.6502 45.7855 36.9375 47.1166 37.2163 48.4029C37.2453 48.539 37.2753 48.676 37.3043 48.8121C37.3398 48.4393 37.3745 48.0664 37.4081 47.6936C37.6196 45.4211 37.8386 43.0703 38.0631 40.7586C38.1071 40.2972 38.2054 39.614 38.5394 39.0053C40.0589 36.2453 40.9357 33.2877 41.8639 30.1558C41.9886 29.7382 42.1134 29.3206 42.2382 28.903C43.3039 25.361 44.3266 22.1788 46.0483 19.1708C47.1187 17.3001 48.2387 15.9643 49.573 14.9679C50.8717 13.9976 52.2724 13.4448 53.7368 13.3237C53.9511 13.306 54.1616 13.2966 54.3618 13.2966C56.9612 13.2966 58.5069 14.7778 58.7137 17.4669C58.9008 19.9128 58.4087 22.216 57.8473 24.3403C57.5825 25.3424 57.2784 26.3416 56.9836 27.3073C56.4409 29.0867 55.9291 30.7663 55.6315 32.4936C55.0018 36.1475 54.6856 39.8908 54.4385 43.3928C54.2514 45.9775 54.2149 48.6322 54.1766 51.2002C54.1653 51.9384 54.1529 52.6763 54.1391 53.414C54.0942 55.6874 53.1286 57.492 51.2675 58.7774C48.0821 60.9997 44.3681 62.3523 40.495 62.7006C38.6171 62.8731 36.712 63.0017 34.8696 63.1257C34.0397 63.1816 33.2097 63.2375 32.3798 63.2981H32.3264L32.2647 63.2907Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.5581 41.4968C21.8673 41.4968 20.1578 41.3952 18.5615 41.2862C15.689 41.0886 13.3787 40.6728 11.2931 39.9756C6.50708 38.3761 3.91804 35.0708 3.59617 30.1502C3.23312 24.6078 4.10518 19.9827 6.26474 16.0044C7.93961 12.9126 10.1619 10.4825 12.8697 8.78142C15.5449 7.10361 18.7168 6.1165 22.2968 5.85831C22.5999 5.83594 22.9199 5.82568 23.2465 5.82568C24.4161 5.82568 25.5726 5.96457 26.6917 6.09879C27.3195 6.1743 27.9081 6.24514 28.5069 6.29361C28.8578 6.3225 29.1918 6.37843 29.5156 6.43249C29.8398 6.49739 30.1686 6.53728 30.499 6.5518C30.5935 6.55488 30.688 6.54452 30.7797 6.52104C31.0211 6.44647 31.4113 6.04753 31.7247 5.72781C31.9334 5.51529 32.1486 5.29624 32.3862 5.08745C34.267 3.4376 36.8616 1.46245 40.2797 0.829544C40.9949 0.696201 41.7209 0.628185 42.4486 0.626343C44.6699 0.626343 46.7602 1.27882 48.6615 2.56235C50.8726 4.05373 51.8176 5.98974 51.5238 8.44027L54.2373 8.82896L52.6195 10.6326C52.554 10.7044 52.4997 10.7724 52.4417 10.8433C52.272 11.0723 52.066 11.2722 51.8316 11.4352C45.9368 15.3426 42.3812 20.7367 40.9478 27.9261C40.6736 29.2755 40.3254 30.6089 39.9045 31.9203C38.3138 36.9537 34.6646 40.0222 29.0636 41.0447C27.3962 41.3486 25.5941 41.4968 23.5553 41.4968H23.5581ZM17.4275 27.5533C16.53 27.5396 15.6432 27.7484 14.8468 28.161C13.9327 28.656 13.3872 29.3728 13.3114 30.1716C13.2356 30.9704 13.6389 31.7693 14.4454 32.4171C15.3923 33.1786 16.5825 33.5729 18.0815 33.6232C19.2146 33.5906 20.0951 33.3632 20.8493 32.9083C21.6624 32.4189 22.1489 31.7413 22.2191 31.0003C22.2893 30.2592 21.9384 29.5089 21.2301 28.8788C20.2925 28.0483 18.8712 27.5533 17.4275 27.5533ZM40.8467 7.4196C42.093 7.53425 43.3665 7.65263 44.625 7.78499C44.6072 7.37486 44.3939 7.1698 43.8662 6.95448C43.5103 6.80715 43.1287 6.73114 42.7433 6.73077C42.0512 6.73674 41.3819 6.9785 40.8467 7.41587V7.4196Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.275 55.9892C55.275 55.4775 55.275 54.9695 55.275 54.4624C55.275 52.6597 55.275 50.7964 55.289 48.9722C55.2946 48.413 55.5744 47.9814 55.7428 47.7241L55.7858 47.657L56.0179 47.2916C56.4614 46.5916 56.9199 45.8683 57.4074 45.1645C58.8109 43.1456 59.2142 40.7836 58.65 37.9341C58.4394 36.8743 58.1466 35.8359 57.8359 34.737C57.6656 34.1339 57.4897 33.5094 57.3297 32.8848C57.1735 32.2762 57.1519 31.7561 57.2642 31.3002C57.9473 28.5086 58.6612 25.6768 59.3517 22.9382L59.726 21.4469C59.7856 21.2183 59.8999 21.0076 60.0591 20.8326C60.0713 20.8186 60.0881 20.8 60.1078 20.7729L61.6713 18.7502L62.4545 21.1654C62.5724 21.5289 62.6896 21.8865 62.8063 22.2382C63.0627 23.0147 63.305 23.7482 63.5343 24.507C65.1193 29.725 66.2421 34.5384 66.9748 39.2204C67.5283 42.7385 66.9469 46.3408 65.3141 49.5087C63.6814 52.6765 61.0817 55.2465 57.8893 56.8486C57.7021 56.9418 57.5281 57.0183 57.369 57.0882L57.1763 57.173L55.275 58.0315V55.9892Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2665 63.2675C27.2634 63.2405 20.7089 62.9432 14.379 60.8748C11.2969 59.8681 8.74432 58.552 6.57634 56.8499C3.76929 54.6464 2.0364 51.5816 1.28036 47.4793C0.642227 44.0221 0.698368 40.3953 1.45627 36.0535C1.53955 35.5781 1.80622 35.2425 1.96622 35.0421C1.99242 35.0086 2.02798 34.9629 2.04014 34.9489L2.80272 33.7698L3.94707 34.5863C3.97046 34.6003 3.99479 34.6134 4.01911 34.6264C4.25958 34.7606 4.70778 35.0086 4.93328 35.5417C6.10382 38.3148 8.3532 40.2256 12.0127 41.5548C14.3388 42.4002 16.9428 42.8766 19.9744 43.0089C21.7129 43.0854 23.5609 43.1599 25.364 43.1599C27.9839 43.1599 30.1416 43.0061 32.1617 42.6762C32.4209 42.6342 32.6829 42.5829 32.9598 42.5252L33.2087 42.4748C33.455 42.4239 33.7057 42.3973 33.9573 42.3956C34.543 42.3956 35.9494 42.5951 36.3564 44.4462C36.6436 45.7559 36.9318 47.0869 37.2097 48.3732C37.2387 48.5093 37.2677 48.6464 37.2977 48.7824C37.332 48.4096 37.3666 48.0368 37.4015 47.6639C37.613 45.3914 37.831 43.0406 38.0565 40.729C38.1005 40.2629 38.1987 39.5843 38.5328 38.9757C40.0505 36.2157 40.9263 33.2581 41.8545 30.1262C41.9792 29.7086 42.104 29.291 42.2287 28.8734C43.2935 25.3313 44.3153 22.1491 46.0351 19.1412C47.1055 17.2704 48.2246 15.9347 49.5579 14.9383C50.8548 13.9679 52.2537 13.4152 53.7171 13.294C53.9313 13.2763 54.1419 13.267 54.3421 13.267C56.9386 13.267 58.4825 14.7481 58.6902 17.4373C58.8774 19.8831 58.3852 22.1864 57.8238 24.3107C57.559 25.3127 57.2549 26.3119 56.9611 27.2776C56.4193 29.057 55.9075 30.7367 55.61 32.4639C54.9812 36.1169 54.6649 39.8612 54.4179 43.3631C54.2354 45.947 54.1952 48.6025 54.1559 51.1705C54.1447 51.9088 54.1325 52.6467 54.1194 53.3843C54.0745 55.6577 53.1089 57.4623 51.2506 58.7477C48.0692 60.969 44.3594 62.3217 40.4902 62.671C38.6142 62.8434 36.7101 62.972 34.8696 63.096C34.0406 63.1519 33.2106 63.2079 32.3825 63.2685H32.3292L32.2665 63.2675Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.5674 41.4707C21.8832 41.4707 20.1699 41.3691 18.5755 41.26C15.7048 41.0624 13.3974 40.6467 11.3127 39.9495C6.53233 38.35 3.94516 35.0475 3.62329 30.1241C3.26118 24.5836 4.1323 19.9565 6.28625 15.9783C7.95925 12.8864 10.1796 10.4564 12.8847 8.7553C15.5579 7.07749 18.7262 6.09038 22.3033 5.83219C22.6065 5.80981 22.9265 5.79956 23.253 5.79956C24.4207 5.79956 25.5763 5.93845 26.6945 6.07267C27.3214 6.14817 27.9109 6.21901 28.5088 6.26748C28.8587 6.29638 29.1927 6.35231 29.5165 6.40637C29.8426 6.47134 30.1733 6.51091 30.5055 6.52475C30.6001 6.52786 30.6946 6.5175 30.7862 6.49399C31.0276 6.41942 31.4178 6.02047 31.7313 5.70076C31.939 5.48823 32.1532 5.26919 32.3918 5.06039C34.267 3.41148 36.8616 1.43633 40.2731 0.803421C40.9881 0.670083 41.7137 0.602067 42.4411 0.60022C44.6605 0.60022 46.7453 1.2527 48.6475 2.53622C50.8576 4.02761 51.8008 5.96361 51.5079 8.41415L54.2214 8.80284L52.6045 10.6065C52.539 10.6783 52.4847 10.7463 52.4267 10.8171C52.2574 11.0462 52.0517 11.2461 51.8176 11.409C45.9378 15.3165 42.3765 20.7106 40.9449 27.9C40.6712 29.2494 40.3232 30.5828 39.9026 31.8941C38.3119 36.9276 34.6693 39.9961 29.072 41.0186C27.4065 41.3225 25.6053 41.4707 23.5683 41.4707H23.5674ZM17.4424 27.5272C16.5458 27.5135 15.6599 27.7223 14.8646 28.1349C13.9504 28.6299 13.4059 29.3467 13.3301 30.1455C13.2543 30.9443 13.6576 31.7431 14.4632 32.391C15.4092 33.1525 16.5975 33.5468 18.0955 33.5971C19.2277 33.5645 20.1072 33.3371 20.8614 32.8822C21.6736 32.3928 22.1592 31.7152 22.2294 30.9741C22.2996 30.2331 21.9487 29.4828 21.2413 28.8526C20.3047 28.0221 18.8843 27.5272 17.4424 27.5272ZM40.8411 7.39348C42.0855 7.50813 43.3581 7.62651 44.6156 7.75887C44.5979 7.34874 44.3845 7.14367 43.8568 6.92835C43.501 6.78072 43.1194 6.70469 42.734 6.70465C42.043 6.71086 41.3749 6.95265 40.8411 7.38975V7.39348Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.2554 55.9633C55.2554 55.4516 55.2554 54.9436 55.2554 54.4365C55.2554 52.6338 55.2553 50.7705 55.2694 48.9464C55.275 48.3871 55.5557 47.9555 55.7232 47.6983L55.7625 47.6311L55.9945 47.2658C56.4371 46.5657 56.8956 45.8424 57.3831 45.1387C58.7819 43.1197 59.188 40.7577 58.6238 37.9082C58.4133 36.8484 58.1214 35.81 57.8107 34.7111C57.6404 34.108 57.4645 33.4835 57.3054 32.859C57.1492 32.2503 57.1277 31.7302 57.2399 31.2744C57.922 28.4827 58.636 25.6509 59.3256 22.9124L59.6999 21.421C59.7599 21.1926 59.8741 20.9819 60.033 20.8067C60.0451 20.7927 60.062 20.7741 60.0816 20.7471L61.6433 18.7244L62.4255 21.1395C62.5434 21.503 62.6607 21.8606 62.7773 22.2123C63.0337 22.9888 63.2751 23.7224 63.5053 24.4811C65.0857 29.701 66.2103 34.5135 66.9421 39.1955C67.4955 42.7124 66.915 46.3136 65.2842 49.4812C63.6533 52.6488 61.056 55.2195 57.8659 56.8237C57.6844 56.9169 57.5047 56.9933 57.3466 57.0632L57.1539 57.148L55.2535 58.0065L55.2554 55.9633Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2683 63.2415C27.2699 63.2145 20.7211 62.9171 14.3968 60.8487C11.3174 59.8421 8.76769 58.5259 6.60065 56.8239C3.7964 54.6222 2.06538 51.5555 1.30561 47.4533C0.668406 43.9961 0.724547 40.3692 1.48152 36.0274C1.56479 35.552 1.83146 35.2165 1.99053 35.0161C2.01766 34.9825 2.05322 34.9369 2.06445 34.9229L2.83264 33.7428L3.97605 34.5593C3.99944 34.5733 4.02377 34.5864 4.0481 34.5994C4.28857 34.7337 4.73583 34.9816 4.96133 35.5148C6.13 38.2878 8.37751 40.1986 12.0342 41.5278C14.3584 42.3733 16.9596 42.8496 19.9875 42.9819C21.7241 43.0584 23.5712 43.1329 25.3733 43.1329C27.9876 43.1329 30.1453 42.9791 32.1645 42.6492C32.4227 42.6072 32.6847 42.556 32.9617 42.4982L33.2106 42.4478C33.4569 42.3968 33.7076 42.3703 33.9591 42.3686C34.5449 42.3686 35.9493 42.5681 36.3554 44.4193C36.6436 45.7289 36.9309 47.06 37.2088 48.3463C37.2378 48.4824 37.2668 48.6194 37.2967 48.7555C37.3316 48.3826 37.3663 48.0098 37.4006 47.6369C37.6111 45.3644 37.8291 43.0136 38.0499 40.702C38.0949 40.2406 38.1912 39.5574 38.5262 38.9487C40.0429 36.1887 40.9178 33.2311 41.8441 30.0992C41.9689 29.6816 42.0937 29.264 42.2184 28.8464C43.2823 25.3044 44.3031 22.1221 46.021 19.1142C47.0905 17.2434 48.2087 15.9077 49.5411 14.9113C50.8361 13.9409 52.2349 13.3882 53.6965 13.267C53.9107 13.2493 54.1203 13.24 54.3206 13.24C56.9152 13.24 58.4582 14.7211 58.6649 17.4103C58.8521 19.8561 58.3599 22.1594 57.7994 24.2837C57.5356 25.2857 57.2315 26.285 56.9377 27.2506C56.3959 29.03 55.885 30.7097 55.5875 32.4369C54.9587 36.0899 54.6443 39.8342 54.3973 43.3361C54.2101 45.92 54.1746 48.5756 54.1353 51.1436C54.1241 51.8818 54.1116 52.6197 54.0979 53.3573C54.0539 55.6308 53.0892 57.4353 51.2319 58.7207C48.0518 60.941 44.3429 62.2918 40.4752 62.6384C38.6039 62.8108 36.6988 62.9395 34.8611 63.0635C34.033 63.1194 33.204 63.1753 32.376 63.2359H32.3236L32.2683 63.2415Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.5749 41.4448C21.8907 41.4448 20.1802 41.3432 18.5877 41.2342C15.7198 41.0366 13.4143 40.6208 11.3315 39.9236C6.55947 38.3241 3.97043 35.0188 3.64856 30.0982C3.28645 24.5577 4.15663 19.9307 6.30871 15.9524C7.98265 12.8634 10.2012 10.4305 12.9081 8.72942C15.5785 7.05161 18.7449 6.0645 22.3183 5.80631C22.6214 5.78394 22.9405 5.77368 23.2671 5.77368C24.4339 5.77368 25.5894 5.91257 26.7057 6.04679C27.3326 6.12229 27.9221 6.19313 28.5181 6.2416C28.8681 6.2705 29.2021 6.32643 29.5249 6.38049C29.8507 6.4453 30.1811 6.48519 30.513 6.4998C30.6076 6.50291 30.7021 6.49255 30.7937 6.46904C31.0342 6.39447 31.4244 5.99553 31.7378 5.67581C31.9455 5.46329 32.1598 5.24424 32.3928 5.03545C34.267 3.3856 36.8616 1.41045 40.2675 0.777542C40.9815 0.64425 41.7063 0.576233 42.4327 0.574341C44.6503 0.574341 46.7368 1.22682 48.6344 2.51035C50.8426 4.00173 51.7849 5.9368 51.492 8.38827L54.2055 8.77696L52.5905 10.5806C52.5259 10.6524 52.4707 10.7195 52.4136 10.7913C52.2438 11.0199 52.0381 11.2197 51.8045 11.3832C45.9228 15.2869 42.3691 20.6847 40.9384 27.8704C40.6653 29.2198 40.3179 30.5532 39.8979 31.8645C38.3073 36.898 34.6693 39.9665 29.0767 40.989C27.4121 41.2929 25.6147 41.4411 23.5777 41.4411L23.5749 41.4448ZM17.4565 27.4976C16.5608 27.484 15.6758 27.6928 14.8815 28.1053C13.9682 28.6003 13.4237 29.3171 13.3488 30.1159C13.2739 30.9147 13.6754 31.7135 14.481 32.3614C15.426 33.1229 16.6134 33.5172 18.1096 33.5675C19.2408 33.5349 20.1203 33.3074 20.8726 32.8526C21.6848 32.3669 22.1714 31.6921 22.2406 30.9464C22.3099 30.2007 21.9599 29.455 21.2525 28.824C20.3168 27.9963 18.8965 27.4976 17.4565 27.4976ZM40.8345 7.36387C42.0781 7.47852 43.3497 7.5969 44.6063 7.72926C44.5885 7.31913 44.3752 7.11407 43.8484 6.89875C43.494 6.7517 43.1141 6.67568 42.7302 6.67504C42.0378 6.68159 41.3686 6.92476 40.8345 7.36387Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.2366 55.9371C55.2366 55.4253 55.2366 54.9173 55.2366 54.4103C55.2366 52.6076 55.2366 50.7443 55.2507 48.9201C55.2563 48.3608 55.5361 47.9293 55.7035 47.672L55.7466 47.6049L55.9786 47.2395C56.4212 46.5395 56.8788 45.8162 57.3653 45.1124C58.7688 43.0935 59.1693 40.7315 58.606 37.882C58.3955 36.8222 58.1026 35.7838 57.7929 34.6848C57.6236 34.0818 57.4477 33.4572 57.2877 32.8327C57.1314 32.2241 57.1108 31.7039 57.2222 31.2481C57.9043 28.4564 58.6173 25.6247 59.3069 22.8861L59.6811 21.3947C59.7411 21.1663 59.8554 20.9557 60.0142 20.7805C60.0264 20.7665 60.0432 20.7478 60.0629 20.7208L61.6227 18.6981L62.4058 21.1123C62.5237 21.4758 62.641 21.8334 62.7577 22.1852C63.0131 22.9616 63.2545 23.6952 63.4838 24.4539C65.0641 29.6738 66.1869 34.4863 66.9177 39.1683C67.4713 42.6845 66.8916 46.285 65.262 49.4524C63.6324 52.6198 61.0368 55.191 57.8481 56.7965C57.6666 56.8897 57.487 56.9661 57.3288 57.036L57.1361 57.1209L55.2366 57.9775V55.9371Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2693 63.2152C27.2756 63.1881 20.7333 62.8908 14.4146 60.8224C11.3381 59.8158 8.79112 58.4996 6.62594 56.7976C3.82357 54.594 2.09349 51.5292 1.33839 47.427C0.702124 43.9698 0.758265 40.3429 1.51523 36.0011C1.59757 35.5257 1.86424 35.1902 2.02331 34.9898C2.04951 34.9562 2.086 34.9105 2.09723 34.8966L2.86075 33.7202L4.00322 34.5368C4.02568 34.5507 4.05094 34.5638 4.07527 34.5768C4.31574 34.7111 4.763 34.959 4.98756 35.4922C6.15529 38.2652 8.40094 40.1761 12.0539 41.5053C14.3753 42.3507 16.9756 42.827 20.0016 42.9594C21.7363 43.0358 23.5815 43.1104 25.3817 43.1104C27.9942 43.1104 30.1537 42.9566 32.1673 42.6266C32.4256 42.5847 32.6866 42.5334 32.9636 42.4756L33.2125 42.4253C33.4587 42.3742 33.7095 42.3476 33.961 42.346C34.5458 42.346 35.9494 42.5455 36.3555 44.3967C36.6427 45.7063 36.93 47.0374 37.2069 48.3237C37.2369 48.4598 37.2659 48.5968 37.3005 48.7329C37.3354 48.36 37.37 47.9872 37.4044 47.6143C37.6149 45.3419 37.8329 42.9911 38.0537 40.6794C38.0977 40.2133 38.1941 39.5348 38.5281 38.9261C40.0439 36.1661 40.9179 33.2085 41.8432 30.0766C41.968 29.659 42.0928 29.2414 42.2175 28.8238C43.2805 25.2818 44.3004 22.0995 46.0173 19.0916C47.0849 17.2208 48.2022 15.8851 49.5336 14.8887C50.8277 13.9184 52.2247 13.3656 53.6853 13.2444C53.8995 13.2267 54.1091 13.2174 54.3094 13.2174C56.9012 13.2174 58.4423 14.6985 58.6491 17.3877C58.8362 19.8336 58.345 22.1368 57.7845 24.2611C57.5206 25.2631 57.2175 26.2624 56.9237 27.228C56.3828 29.0074 55.872 30.6871 55.5754 32.4143C54.9466 36.0673 54.6322 39.8116 54.3852 43.3136C54.2027 45.8974 54.1625 48.553 54.1232 51.121C54.1119 51.8592 54.0998 52.5971 54.0867 53.3347C54.0418 55.6082 53.0789 57.4128 51.2235 58.6981C48.0484 60.9194 44.3439 62.2721 40.4799 62.6214C38.6086 62.7939 36.7063 62.9225 34.8658 63.0465C34.0387 63.1024 33.2106 63.1583 32.3835 63.2189H32.3301L32.2693 63.2152Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.5862 41.4186C21.9019 41.4186 20.1952 41.317 18.6036 41.2079C15.7376 41.0103 13.434 40.5946 11.354 39.8974C6.58197 38.2979 3.99854 34.9926 3.68134 30.0719C3.32017 24.5314 4.18942 19.9044 6.33962 15.9261C8.00982 12.8343 10.2265 10.4043 12.9268 8.70318C15.5945 7.02537 18.758 6.03826 22.3286 5.78006C22.6308 5.75769 22.9499 5.74744 23.2755 5.74744C24.4423 5.74744 25.596 5.88632 26.7113 6.02055C27.3373 6.09605 27.9277 6.16689 28.5219 6.21536C28.8718 6.24426 29.2049 6.30018 29.5278 6.35424C29.8533 6.419 30.1833 6.45889 30.5149 6.47356C30.6095 6.47667 30.704 6.46631 30.7956 6.4428C31.0361 6.36823 31.4263 5.96928 31.7388 5.64957C31.9465 5.43704 32.1608 5.218 32.3984 5.0092C34.2698 3.35936 36.8626 1.3842 40.2685 0.751297C40.9819 0.618046 41.706 0.550029 42.4318 0.548096C44.6475 0.548096 46.7359 1.20058 48.6279 2.4841C50.8333 3.97549 51.7755 5.91056 51.4827 8.36202L54.1961 8.75071L52.5821 10.5544C52.5175 10.6261 52.4633 10.6932 52.4052 10.765C52.2361 10.9938 52.0306 11.1938 51.7971 11.3569C45.9219 15.2643 42.371 20.6585 40.9431 27.8479C40.6696 29.1972 40.3222 30.5305 39.9026 31.842C38.3166 36.8754 34.6787 39.944 29.0908 40.9665C27.4281 41.2704 25.6288 41.4186 23.5974 41.4186H23.5862ZM17.4715 27.4751C16.5767 27.4613 15.6926 27.6702 14.8993 28.0828C13.986 28.5777 13.4424 29.2945 13.3676 30.0934C13.2927 30.8922 13.6941 31.6919 14.4988 32.3388C15.4438 33.1004 16.6303 33.4947 18.1246 33.545C19.2558 33.5124 20.1335 33.2849 20.8858 32.8301C21.697 32.3407 22.1817 31.663 22.2519 30.922C22.3221 30.181 21.9712 29.4306 21.2647 28.7996C20.329 27.97 18.9115 27.4751 17.4715 27.4751ZM40.829 7.34136C42.0706 7.45601 43.3413 7.57438 44.596 7.70675C44.5792 7.29661 44.3658 7.09155 43.8391 6.87623C43.4851 6.72909 43.1054 6.65306 42.7219 6.65252C42.0307 6.65806 41.3625 6.89992 40.829 7.33763V7.34136Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.2169 55.9101V54.3843C55.2169 52.5816 55.2169 50.7183 55.231 48.8941C55.231 48.3348 55.5117 47.9033 55.6829 47.646L55.7269 47.5789L55.958 47.2135C56.3996 46.5135 56.8572 45.7902 57.3438 45.0864C58.7407 43.0675 59.1459 40.7055 58.5826 37.856C58.373 36.7962 58.0801 35.7578 57.7704 34.6588C57.6011 34.0558 57.4252 33.4312 57.2661 32.8067C57.1098 32.1981 57.0883 31.6779 57.2006 31.2221C57.8818 28.4304 58.5938 25.5987 59.2825 22.8601L59.6568 21.3687C59.7161 21.1403 59.8301 20.9295 59.9889 20.7545C60.0011 20.7405 60.0179 20.7218 60.0376 20.6948L61.5964 18.6721L62.3777 21.0872C62.4947 21.4508 62.6126 21.8078 62.7286 22.1601C62.9841 22.9365 63.2255 23.6701 63.4547 24.4289C65.0332 29.6487 66.1551 34.4612 66.8849 39.1432C67.438 42.6586 66.8587 46.2582 65.2302 49.4251C63.6018 52.5921 61.0079 55.1636 57.8209 56.7705C57.6394 56.8637 57.4607 56.9401 57.3026 57.01L57.1098 57.0949L55.2132 57.9533L55.2169 55.9101Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2711 63.1891C27.2821 63.1621 20.7454 62.8647 14.4323 60.7964C11.3586 59.7897 8.81257 58.4735 6.65021 56.7715C3.85064 54.568 2.12243 51.5032 1.36827 47.4009C0.731999 43.9437 0.78814 40.3168 1.54417 35.975C1.62651 35.4997 1.89318 35.1641 2.05225 34.9637C2.07845 34.9301 2.11401 34.8845 2.12617 34.8705L2.88875 33.6942L4.03029 34.5107C4.05274 34.5247 4.07801 34.5377 4.10234 34.5508C4.34187 34.685 4.78913 34.9329 5.01369 35.4661C6.18049 38.2364 8.42333 40.1472 12.0734 41.4792C14.393 42.3246 16.9905 42.8 20.0137 42.9333C21.7475 43.0097 23.5908 43.0843 25.3892 43.0843C27.9997 43.0843 30.1537 42.9305 32.1691 42.6005C32.4264 42.5586 32.6875 42.5073 32.9645 42.4495L33.2124 42.3992C33.4587 42.3482 33.7094 42.3216 33.961 42.32C34.5458 42.32 35.9474 42.5194 36.3535 44.3706C36.6408 45.6756 36.9271 47.0113 37.2041 48.2976C37.234 48.4337 37.263 48.5707 37.2929 48.7068C37.3279 48.334 37.3622 47.9611 37.3959 47.5883C37.6064 45.3158 37.8244 42.965 38.0452 40.6533C38.0892 40.1919 38.1856 39.5087 38.5196 38.9C40.0336 36.14 40.9075 33.1824 41.832 30.0505C41.9567 29.6329 42.0815 29.2153 42.2062 28.7978C43.2682 25.2557 44.2872 22.0735 46.0023 19.0655C47.0699 17.1938 48.1862 15.8591 49.5158 14.8626C50.8033 13.8886 52.1984 13.3293 53.6571 13.2118C53.8714 13.1941 54.081 13.1848 54.2803 13.1848C56.8703 13.1848 58.4104 14.6659 58.6163 17.3551C58.8034 19.801 58.3131 22.1042 57.7526 24.2285C57.4897 25.2305 57.1856 26.2298 56.8927 27.1954C56.3519 28.9749 55.842 30.6545 55.5453 32.3817C54.9203 36.0347 54.6031 39.779 54.3589 43.281C54.1774 45.8648 54.1371 48.5204 54.0978 51.0884C54.0866 51.8266 54.0744 52.5645 54.0613 53.3022C54.0164 55.5756 53.0536 57.3802 51.2 58.6646C48.0287 60.8865 44.3271 62.2397 40.4659 62.5888C38.5945 62.7613 36.6969 62.8899 34.8602 63.0139C34.0343 63.0698 33.2074 63.127 32.3797 63.1854H32.3273L32.2711 63.1891Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.5955 41.3925C21.9113 41.3925 20.2074 41.2909 18.6167 41.1818C15.7545 40.9842 13.4527 40.5685 11.3745 39.8712C6.60253 38.2717 4.02565 34.9664 3.70191 30.0458C3.34354 24.5053 4.21279 19.8783 6.35832 15.9C8.02758 12.8082 10.2423 10.3782 12.9399 8.67705C15.6057 6.99925 18.7655 6.01214 22.3333 5.75394C22.6355 5.73157 22.9536 5.72131 23.2802 5.72131C24.4451 5.72131 25.5979 5.8602 26.7123 5.99442C27.3373 6.06993 27.9286 6.14077 28.5209 6.18924C28.8709 6.21813 29.204 6.27406 29.5268 6.32812C29.852 6.39298 30.1817 6.43287 30.513 6.44743C30.6076 6.45065 30.7021 6.44029 30.7937 6.41667C31.0342 6.3421 31.4234 5.94316 31.736 5.62344C31.9437 5.41092 32.1579 5.19187 32.3909 4.98308C34.2623 3.33323 36.8513 1.35808 40.2507 0.725174C40.9634 0.591881 41.6869 0.523863 42.4121 0.521973C44.6259 0.521973 46.7088 1.17445 48.6026 2.45798C50.8061 3.94936 51.7474 5.88444 51.4546 8.3359L54.1681 8.72459L52.5559 10.5329C52.4913 10.6047 52.437 10.6718 52.379 10.7436C52.2099 10.9722 52.0049 11.172 51.7718 11.3354C45.9013 15.2429 42.3541 20.637 40.9262 27.8264C40.6535 29.1758 40.3064 30.5092 39.8867 31.8206C38.3017 36.854 34.6675 39.9225 29.0852 40.945C27.4243 41.2489 25.6287 41.3971 23.5964 41.3971L23.5955 41.3925ZM17.4864 27.4489C16.5922 27.4353 15.7088 27.6442 14.9161 28.0567C14.0047 28.5516 13.4611 29.2684 13.3862 30.0672C13.3114 30.8661 13.7119 31.6649 14.5156 32.3127C15.4597 33.0743 16.6443 33.4685 18.1386 33.5189C19.268 33.4862 20.1447 33.2588 20.897 32.8039C21.7064 32.3146 22.191 31.6369 22.2612 30.8959C22.3314 30.1549 21.9805 29.4045 21.2759 28.7735C20.3412 27.9439 18.9255 27.4489 17.4864 27.4489ZM40.8224 7.31523C42.064 7.42988 43.3328 7.54826 44.5866 7.68062C44.5698 7.27049 44.3565 7.06543 43.8306 6.85011C43.4769 6.70306 43.0976 6.62704 42.7143 6.6264C42.0235 6.632 41.3556 6.87387 40.8224 7.3115V7.31523Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.201 55.8841C55.201 55.3733 55.201 54.8647 55.201 54.3582C55.201 52.5555 55.201 50.6922 55.215 48.8681C55.2206 48.3088 55.4957 47.8772 55.667 47.6199L55.7109 47.5528L55.942 47.1874C56.3837 46.4874 56.8403 45.7641 57.3259 45.0604C58.722 43.0414 59.1271 40.6794 58.5638 37.8299C58.3542 36.7701 58.0623 35.7317 57.7535 34.6328C57.5832 34.0297 57.4083 33.4052 57.2483 32.7807C57.0929 32.1711 57.0714 31.6519 57.1837 31.1961C57.8639 28.4044 58.576 25.5726 59.2637 22.8341L59.638 21.3427C59.6974 21.1142 59.8114 20.9035 59.9702 20.7284C59.9823 20.7144 59.9992 20.6958 60.0188 20.6688L61.5721 18.6423L62.3524 21.0574C62.4703 21.421 62.5873 21.7786 62.7033 22.1303C62.9587 22.9068 63.2002 23.6403 63.4294 24.3991C65.006 29.6189 66.127 34.4314 66.8568 39.1135C67.4104 42.6278 66.8325 46.2265 65.206 49.3934C63.5795 52.5603 60.988 55.1327 57.8031 56.7416C57.6216 56.8348 57.4429 56.9113 57.2857 56.9812L57.0929 57.066L55.201 57.9273V55.8841Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2767 63.1629C27.2914 63.1359 20.7603 62.8385 14.4529 60.7702C11.3819 59.7635 8.83875 58.4473 6.67826 56.7453C3.87775 54.5418 2.14767 51.477 1.39912 47.3747C0.763793 43.9175 0.819934 40.2907 1.57503 35.9489C1.65737 35.4735 1.92404 35.1379 2.08311 34.9375C2.10931 34.904 2.14486 34.8583 2.15609 34.8443L2.91868 33.668L4.05927 34.4845C4.08173 34.4985 4.10606 34.5115 4.13039 34.5246C4.37086 34.6588 4.81718 34.9068 5.04081 35.4399C6.20761 38.213 8.44857 40.1238 12.0949 41.453C14.4126 42.2984 17.0073 42.7738 20.0277 42.9071C21.7606 42.9835 23.602 43.0581 25.3985 43.0581C28.0072 43.0581 30.1593 42.9043 32.1719 42.5743C32.4302 42.5324 32.6912 42.4811 32.9673 42.4233L33.2152 42.373C33.4599 42.3222 33.7091 42.2956 33.9591 42.2938C34.5429 42.2938 35.9437 42.4932 36.3498 44.3444C36.6361 45.6541 36.9224 46.9851 37.1994 48.2714C37.2293 48.4075 37.2583 48.5445 37.2873 48.6806C37.3222 48.3078 37.3569 47.9349 37.3912 47.5621C37.6017 45.2896 37.8188 42.9388 38.0396 40.6272C38.0836 40.1611 38.18 39.4825 38.514 38.8738C40.0242 36.1073 40.8972 33.1497 41.8207 30.0141C41.9455 29.5965 42.0702 29.1789 42.195 28.7613C43.256 25.2193 44.2741 22.037 45.9882 19.0291C47.054 17.1574 48.1693 15.8226 49.498 14.8262C50.7902 13.8559 52.1843 13.3031 53.6421 13.1819C53.8564 13.1642 54.0651 13.1549 54.2653 13.1549C56.8525 13.1549 58.3907 14.636 58.5975 17.3252C58.7847 19.7711 58.2934 22.079 57.7348 24.1986C57.471 25.2006 57.1678 26.1999 56.8749 27.1655C56.335 28.9449 55.8251 30.6246 55.5285 32.3518C54.9016 36.0048 54.5872 39.7491 54.3411 43.2511C54.1596 45.8349 54.1193 48.4905 54.08 51.0585C54.0688 51.7967 54.0563 52.5346 54.0426 53.2722C53.9986 55.5457 53.0368 57.3502 51.1841 58.6347C48.0162 60.8559 44.3182 62.2091 40.4602 62.5589C38.5888 62.7313 36.6941 62.86 34.8592 62.984C34.0339 63.0399 33.208 63.097 32.3815 63.1555H32.3282L32.2767 63.1629Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.6049 41.3662C21.9207 41.3662 20.2196 41.2646 18.6308 41.1555C15.7704 40.9579 13.4714 40.5422 11.3942 39.845C6.63064 38.2455 4.05283 34.9402 3.73189 30.0196C3.37165 24.4791 4.23903 19.8539 6.38549 15.8738C8.05288 12.7819 10.2648 10.3519 12.9606 8.65081C15.6244 6.973 18.7814 5.98589 22.3455 5.72769C22.6477 5.70532 22.9658 5.69507 23.2914 5.69507C24.4554 5.69507 25.6073 5.83395 26.7207 5.96818C27.3458 6.04368 27.9371 6.11452 28.5285 6.16299C28.8765 6.19189 29.2106 6.24781 29.5325 6.30188C29.8573 6.36668 30.1867 6.40656 30.5177 6.42119C30.6123 6.4244 30.7068 6.41404 30.7984 6.39043C31.0389 6.31586 31.4282 5.91691 31.7397 5.5972C31.9475 5.38467 32.1617 5.16563 32.3947 4.95683C34.2661 3.30699 36.8505 1.33184 40.2545 0.698929C40.9654 0.565867 41.687 0.49785 42.4103 0.495728C44.6222 0.495728 46.7032 1.14821 48.5952 2.43173C50.7968 3.92312 51.7372 5.85819 51.4453 8.30965L54.1503 8.69835L52.5391 10.502C52.4745 10.5738 52.4202 10.6409 52.3632 10.7126C52.1941 10.9413 51.989 11.1411 51.7559 11.3045C45.891 15.212 42.3467 20.6061 40.9197 27.7955C40.6477 29.1449 40.3012 30.4782 39.8821 31.7896C38.298 36.8231 34.6675 39.8916 29.0899 40.9141C27.43 41.218 25.6363 41.3662 23.6058 41.3662H23.6049ZM17.5014 27.4227C16.6079 27.4092 15.725 27.6181 14.933 28.0304C14.0226 28.5254 13.4799 29.2422 13.4041 30.041C13.3283 30.8398 13.7306 31.6386 14.5334 32.2865C15.4757 33.048 16.6602 33.4423 18.1527 33.4926C19.2811 33.46 20.1578 33.2326 20.9083 32.7777C21.7176 32.2883 22.2023 31.6107 22.2715 30.8696C22.3408 30.1286 21.9908 29.3783 21.2872 28.7472C20.3543 27.9176 18.9386 27.4227 17.5014 27.4227ZM40.8168 7.28899C42.0566 7.40364 43.3245 7.52202 44.5773 7.65438C44.5605 7.24425 44.3481 7.03918 43.8213 6.82386C43.468 6.67668 43.0889 6.60065 42.706 6.60015C42.016 6.60586 41.349 6.84776 40.8168 7.28526V7.28899Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.1796 55.858C55.1796 55.3472 55.1796 54.8386 55.1796 54.3321C55.1796 52.5294 55.1796 50.6661 55.1936 48.842C55.1983 48.2827 55.4743 47.8512 55.6446 47.5939L55.6886 47.5268L55.9188 47.1614C56.3604 46.4614 56.817 45.738 57.3026 45.0343C58.6968 43.0153 59.102 40.6534 58.5396 37.8039C58.33 36.7441 58.0381 35.7057 57.7293 34.6067C57.5599 34.0036 57.384 33.3791 57.225 32.7546C57.0696 32.145 57.0481 31.6258 57.1595 31.17C57.8397 28.3783 58.5508 25.5465 59.2386 22.808L59.6128 21.3166C59.673 21.0885 59.7869 20.8779 59.945 20.7023C59.9572 20.6884 59.974 20.6697 59.9937 20.6427L61.5497 18.62L62.3291 21.0351C62.447 21.3986 62.564 21.7563 62.68 22.108C62.9345 22.8844 63.1759 23.618 63.4052 24.3767C64.9799 29.5966 66.0999 34.4091 66.8288 39.0911C67.3825 42.6045 66.8056 46.2023 65.1807 49.3689C63.5559 52.5356 60.9665 55.1086 57.7836 56.7193C57.603 56.8125 57.4243 56.8889 57.2661 56.9589C57.2016 56.9868 57.137 57.0148 57.079 57.0437L55.1861 57.9022L55.1796 55.858Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2768 63.1369C27.2971 63.1099 20.7679 62.8126 14.4698 60.7442C11.4017 59.7375 8.86127 58.4214 6.70358 56.7193C3.90401 54.5158 2.17955 51.4529 1.42632 47.3516C0.791925 43.8943 0.848066 40.2675 1.60223 35.9257C1.6855 35.4503 1.95124 35.1147 2.10937 34.9143C2.13557 34.8808 2.17206 34.8351 2.18329 34.8211L2.94494 33.6448L4.08366 34.4613C4.10706 34.4753 4.13138 34.4884 4.15571 34.5014C4.39525 34.6347 4.84157 34.8836 5.0652 35.4167C6.23012 38.1898 8.47015 40.1006 12.1128 41.4298C14.4286 42.2752 17.0214 42.7506 20.039 42.8839C21.769 42.9604 23.6095 43.0349 25.4051 43.0349C28.0101 43.0349 30.1612 42.8811 32.172 42.5512C32.4293 42.5092 32.6903 42.4579 32.9664 42.4002L33.2143 42.3498C33.4587 42.299 33.7076 42.2725 33.9573 42.2706C34.5411 42.2706 35.94 42.4701 36.3451 44.3212C36.6324 45.6262 36.9178 46.9619 37.1947 48.2482C37.2237 48.3843 37.2537 48.5214 37.2827 48.6574C37.3176 48.2846 37.3522 47.9118 37.3866 47.5389C37.5961 45.2664 37.8132 42.9156 38.034 40.604C38.078 40.1426 38.1744 39.4593 38.5075 38.8507C40.0186 36.0907 40.8907 33.1331 41.8142 30.0011C41.9377 29.5836 42.0603 29.1622 42.1885 28.7484C43.2486 25.2063 44.2648 22.0241 45.9771 19.0162C47.0428 17.1445 48.1572 15.8097 49.484 14.8133C50.7753 13.8429 52.1685 13.2902 53.6254 13.169C53.8378 13.1513 54.0473 13.142 54.2466 13.142C56.8319 13.142 58.3693 14.6231 58.5751 17.3123C58.7623 19.7581 58.272 22.066 57.7134 24.1857C57.4495 25.1877 57.1473 26.1869 56.8544 27.1526C56.3145 28.932 55.8055 30.6117 55.5089 32.3389C54.8829 35.9919 54.5732 39.7362 54.3224 43.2381C54.1409 45.8219 54.1007 48.4775 54.0623 51.0455C54.0511 51.7838 54.0386 52.5217 54.0249 53.2593C53.9809 55.5327 53.02 57.3373 51.1692 58.6218C48.0047 60.8428 44.3097 62.1961 40.4547 62.546C38.5833 62.7184 36.6913 62.847 34.8593 62.971C34.0334 63.0269 33.2081 63.0841 32.3834 63.1425L32.3301 63.1472L32.2768 63.1369Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.6142 41.3402C21.93 41.3402 20.2317 41.2386 18.6448 41.1295C15.7872 40.9319 13.4901 40.5162 11.4147 39.8189C6.65584 38.2166 4.0799 34.9142 3.7599 29.9935C3.39873 24.453 4.26611 19.826 6.41069 15.8459C8.07621 12.755 10.2863 10.3249 12.9792 8.62382C15.6403 6.94601 18.7954 5.9589 22.3557 5.70071C22.6579 5.67833 22.9761 5.66808 23.3007 5.66808C24.4638 5.66808 25.6147 5.80697 26.7272 5.94119C27.3513 6.01669 27.9436 6.08753 28.5331 6.136C28.8812 6.1649 29.2143 6.22083 29.5361 6.27489C29.8607 6.33963 30.1898 6.37951 30.5205 6.3942C30.615 6.39741 30.7096 6.38705 30.8012 6.36344C31.0417 6.28887 31.43 5.88992 31.7415 5.57021C31.9493 5.35769 32.1626 5.13864 32.3965 4.92985C34.2679 3.28 36.8485 1.30485 40.246 0.67194C40.9571 0.537769 41.679 0.468815 42.4027 0.465942C44.6128 0.465942 46.691 1.11842 48.5783 2.40195C50.778 3.89333 51.7175 5.82841 51.4255 8.27987L54.1325 8.66856L52.5222 10.4722C52.4576 10.544 52.4033 10.6111 52.3463 10.6829C52.1776 10.9129 51.9725 11.114 51.739 11.2785C45.8788 15.1859 42.3382 20.5791 40.9131 27.7695C40.6406 29.1187 40.2942 30.4521 39.8755 31.7636C38.2932 36.797 34.6656 39.8656 29.0936 40.8881C27.4346 41.192 25.6428 41.3402 23.6142 41.3402ZM17.5163 27.3966C16.6236 27.383 15.7417 27.5919 14.9507 28.0044C14.0403 28.4993 13.4976 29.2161 13.4227 30.0149C13.3479 30.8138 13.7483 31.6126 14.5512 32.2604C15.4924 33.022 16.6761 33.4162 18.1666 33.4666C19.2941 33.434 20.1699 33.2065 20.9204 32.7516C21.7288 32.2623 22.2125 31.5846 22.2827 30.8436C22.3529 30.1026 22.002 29.3522 21.2984 28.7212C20.3664 27.8916 18.9526 27.3966 17.5163 27.3966ZM40.8149 7.26293C42.0537 7.37758 43.3206 7.49596 44.5726 7.62832C44.5548 7.21819 44.3433 7.01312 43.8175 6.79781C43.4645 6.65074 43.0857 6.57471 42.7031 6.5741C42.0118 6.57865 41.3432 6.82063 40.8102 7.2592L40.8149 7.26293Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.1599 55.8339C55.1599 55.3231 55.1599 54.8144 55.1599 54.308C55.1599 52.5053 55.1599 50.642 55.1739 48.8178C55.1795 48.2585 55.4546 47.827 55.6258 47.5697L55.6689 47.5026L55.899 47.1372C56.3407 46.4372 56.7964 45.7139 57.282 45.0101C58.6743 42.9912 59.0785 40.6292 58.5171 37.7797C58.3075 36.7199 58.0165 35.6815 57.7077 34.5825C57.5384 33.9785 57.3634 33.3549 57.2043 32.7304C57.049 32.1208 57.0275 31.6016 57.1388 31.1458C57.8181 28.3541 58.5283 25.5224 59.216 22.7838L59.5903 21.2924C59.6499 21.0643 59.7635 20.8536 59.9216 20.6782C59.9337 20.6642 59.9506 20.6455 59.9702 20.6185L61.5244 18.5958L62.3038 21.0109C62.4208 21.3745 62.5387 21.7315 62.6538 22.0838C62.9092 22.8603 63.1497 23.5938 63.378 24.3526C64.9518 29.5724 66.0709 34.3849 66.7998 39.067C67.3532 42.5794 66.777 46.1763 65.1536 49.3426C63.5302 52.509 60.9429 55.0827 57.762 56.6951C57.5814 56.7883 57.4027 56.8648 57.2455 56.9347L57.0527 57.0195L55.1617 57.878L55.1599 55.8339Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2768 63.1105C27.3008 63.0835 20.7819 62.7862 14.4857 60.7178C11.4194 59.7111 8.88466 58.395 6.72416 56.6929C3.93114 54.4894 2.20855 51.4246 1.45626 47.3224C0.821862 43.8652 0.878003 40.2383 1.63123 35.8965C1.7145 35.4211 1.9793 35.0856 2.13837 34.8851C2.16457 34.8516 2.20013 34.8059 2.21135 34.7919L2.973 33.6156L4.11266 34.4293C4.13512 34.4433 4.16038 34.4564 4.18471 34.4694C4.42425 34.6027 4.86963 34.8516 5.09326 35.3848C6.25725 38.1578 8.49448 40.0686 12.1343 41.3978C14.4482 42.2433 17.0382 42.7186 20.053 42.8519C21.7821 42.9284 23.6208 43.0029 25.4145 43.0029C28.0175 43.0029 30.1659 42.8491 32.1757 42.5192C32.433 42.4772 32.6932 42.426 32.9692 42.3682L33.2125 42.3178C33.4569 42.267 33.7057 42.2404 33.9554 42.2386C34.5374 42.2386 35.9353 42.4381 36.3405 44.2893C36.6268 45.5989 36.9131 46.93 37.1891 48.2163C37.2181 48.3524 37.2481 48.4894 37.2771 48.6255C37.3114 48.2526 37.346 47.8798 37.3809 47.5069C37.5905 45.2344 37.8076 42.8836 38.0275 40.572C38.0715 40.1059 38.1678 39.4273 38.5009 38.8187C40.0111 36.0587 40.8823 33.1011 41.8039 29.9692C41.9274 29.5516 42.05 29.1303 42.1782 28.7164C43.2374 25.1744 44.2526 21.9921 45.964 18.9842C47.0278 17.1125 48.1413 15.7777 49.4672 14.7813C50.7575 13.8109 52.1488 13.2582 53.6048 13.137C53.8172 13.1193 54.0268 13.11 54.2261 13.11C56.8086 13.11 58.3431 14.5911 58.5499 17.2803C58.737 19.7261 58.2476 22.0341 57.689 24.1537C57.4261 25.1557 57.1229 26.1549 56.831 27.1206C56.2921 28.9 55.783 30.5797 55.4874 32.3069C54.8614 35.9599 54.5517 39.7042 54.3019 43.2061C54.1203 45.79 54.0801 48.4456 54.0408 51.0135C54.0296 51.7518 54.0174 52.4897 54.0043 53.2273C53.9603 55.5008 53.0003 57.3053 51.1505 58.5898C47.9897 60.8106 44.2981 62.1639 40.4462 62.514C38.5795 62.6864 36.6867 62.8151 34.8555 62.939C34.0302 62.995 33.2056 63.0521 32.3816 63.1105L32.3292 63.1152L32.2768 63.1105Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.6235 41.3142C21.9393 41.3142 20.2448 41.2126 18.6588 41.1035C15.8031 40.9059 13.5078 40.4902 11.4353 39.793C6.68014 38.1935 4.10701 34.8882 3.787 29.9676C3.42677 24.4271 4.29227 19.8 6.43499 15.8208C8.09958 12.7299 10.3078 10.2999 13.0016 8.59881C15.6599 6.921 18.8113 5.93389 22.3688 5.67569C22.671 5.65332 22.9882 5.64307 23.3129 5.64307C24.475 5.64307 25.6249 5.78195 26.7365 5.91618C27.3606 5.99168 27.9529 6.06252 28.5405 6.11099C28.8886 6.13988 29.2217 6.19581 29.5427 6.24987C29.8672 6.31467 30.1963 6.35455 30.527 6.36919C30.6216 6.37249 30.7161 6.36213 30.8077 6.33843C31.0482 6.26386 31.4355 5.86491 31.7434 5.5452C31.9502 5.33267 32.1635 5.11363 32.3984 4.90483C34.2697 3.25499 36.8466 1.27983 40.2403 0.646927C40.95 0.513869 41.6703 0.445851 42.3924 0.443726C44.5997 0.443726 46.6769 1.09621 48.5679 2.37973C50.7659 3.87112 51.7043 5.80619 51.4124 8.25765L54.1128 8.64634L52.5062 10.45C52.4417 10.5218 52.3874 10.5889 52.3294 10.6606C52.161 10.8892 51.9566 11.0891 51.724 11.2525C45.8694 15.16 42.3316 20.5532 40.9075 27.7435C40.6353 29.0927 40.2892 30.4261 39.8707 31.7376C38.2904 36.7711 34.6655 39.8396 29.0982 40.8621C27.442 41.166 25.6511 41.3142 23.6245 41.3142H23.6235ZM17.5313 27.3707C16.6392 27.3571 15.7578 27.566 14.9675 27.9784C14.058 28.4734 13.5163 29.1902 13.4414 29.989C13.3665 30.7878 13.767 31.5866 14.5642 32.2345C15.5055 32.996 16.6873 33.3903 18.1769 33.4406C19.2997 33.408 20.1783 33.1806 20.9278 32.7257C21.7362 32.2363 22.2191 31.5587 22.2892 30.8176C22.3594 30.0766 22.0085 29.3263 21.3058 28.6952C20.3786 27.8703 18.9657 27.3707 17.5313 27.3707ZM40.8046 7.23699C42.0415 7.35164 43.3075 7.47001 44.5585 7.60237C44.5407 7.19224 44.3293 6.98718 43.8034 6.77186C43.4511 6.62482 43.0729 6.54879 42.6909 6.54815C42.0017 6.55344 41.3354 6.79542 40.8046 7.23326V7.23699Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.1412 55.8058C55.1412 55.295 55.1412 54.7864 55.1412 54.2799C55.1412 52.4772 55.1412 50.6139 55.1553 48.7897C55.1609 48.2305 55.436 47.7989 55.6063 47.5416L55.6493 47.4745L55.8795 47.1091C56.3202 46.4091 56.7759 45.6858 57.2606 44.982C58.6529 42.9631 59.0561 40.6011 58.4947 37.7516C58.2861 36.6918 57.9951 35.6534 57.6863 34.5545C57.5169 33.9505 57.342 33.3269 57.1829 32.7024C57.0276 32.0927 57.007 31.5736 57.1183 31.1178C57.7967 28.3261 58.5069 25.4943 59.1927 22.7557L59.567 21.2644C59.6266 21.0362 59.7402 20.8255 59.8983 20.6501C59.9104 20.6361 59.9273 20.6175 59.9469 20.5904L61.5002 18.5677L62.2786 20.9829C62.3959 21.3464 62.5126 21.704 62.6286 22.0557C62.8831 22.8322 63.1236 23.5658 63.3519 24.3245C64.9248 29.5443 66.0429 34.3569 66.7699 39.0389C67.3238 42.5504 66.7487 46.1464 65.1269 49.3127C63.5051 52.4789 60.9197 55.0531 57.7406 56.667C57.56 56.7603 57.3822 56.8367 57.225 56.9066L57.0323 56.9914L55.1431 57.8499L55.1412 55.8058Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2767 63.0847C27.3045 63.0576 20.7921 62.7603 14.4987 60.6919C11.4353 59.6852 8.89959 58.3691 6.74471 56.6671C3.95824 54.4635 2.24126 51.3987 1.48522 47.2956C0.851763 43.8393 0.907905 40.2115 1.66019 35.8706C1.74347 35.3952 2.00827 35.0597 2.16734 34.8593C2.19354 34.8257 2.22909 34.78 2.24032 34.7661L3.0001 33.5897L4.13789 34.4063C4.16034 34.4202 4.18467 34.4333 4.209 34.4463C4.44853 34.5796 4.89392 34.8285 5.11755 35.3617C6.28061 38.1347 8.51596 40.0456 12.152 41.3748C14.4641 42.2202 17.0513 42.6956 20.0642 42.8289C21.7915 42.9053 23.6282 42.9799 25.42 42.9799C28.0212 42.9799 30.1677 42.8261 32.1757 42.4961C32.4321 42.4542 32.6922 42.4029 32.9682 42.3451L33.2152 42.2948C33.4596 42.2439 33.7085 42.2174 33.9582 42.2155C34.5392 42.2155 35.9371 42.415 36.3413 44.2662C36.6277 45.5711 36.913 46.9059 37.1891 48.1932C37.2181 48.3293 37.248 48.4654 37.277 48.6024C37.312 48.2295 37.3463 47.8567 37.38 47.4839C37.5896 45.2114 37.8066 42.8606 38.0265 40.5489C38.0705 40.0875 38.1669 39.4043 38.5 38.7956C40.0083 36.0356 40.8785 33.078 41.8001 29.9461C41.9249 29.5285 42.0496 29.1109 42.1744 28.6933C43.2327 25.1513 44.2479 21.969 45.9564 18.9611C47.0203 17.0894 48.1319 15.7546 49.4568 14.7582C50.7462 13.7879 52.1366 13.2351 53.5907 13.1139C53.8031 13.0962 54.0127 13.0869 54.2111 13.0869C56.7917 13.0869 58.3281 14.568 58.532 17.2572C58.7192 19.7031 58.2289 22.011 57.6712 24.1306C57.4083 25.1326 57.1098 26.1319 56.8141 27.0975C56.2752 28.877 55.7671 30.5566 55.4714 32.2838C54.8464 35.9368 54.5357 39.6811 54.2868 43.1831C54.1063 45.7669 54.066 48.4225 54.0267 50.9905C54.0155 51.7287 54.0033 52.4666 53.9902 53.2042C53.9453 55.4777 52.9862 57.2823 51.1392 58.5667C47.9817 60.787 44.2932 62.1404 40.4443 62.4909C38.5795 62.6634 36.6875 62.792 34.8583 62.916C34.0343 62.9719 33.2105 63.0291 32.3871 63.0875H32.3338L32.2767 63.0847Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.6329 41.2927C21.9543 41.2927 20.257 41.1911 18.6738 41.0821C15.8209 40.8845 13.5275 40.4688 11.4569 39.7715C6.70452 38.1674 4.13326 34.8611 3.8142 29.9414C3.4577 24.4009 4.31946 19.7739 6.46031 15.7947C8.12302 12.7038 10.3294 10.2738 13.0176 8.57268C15.6731 6.89488 18.8188 5.90776 22.3744 5.64957C22.6757 5.6272 22.9929 5.61694 23.3176 5.61694C24.4779 5.61694 25.6269 5.75583 26.7375 5.89005C27.3607 5.96556 27.9492 6.0364 28.5397 6.08487C28.8877 6.11376 29.2199 6.16969 29.5418 6.22375C29.8657 6.28853 30.1942 6.32842 30.5242 6.34306C30.6173 6.34592 30.7102 6.33556 30.8003 6.3123C31.0407 6.23773 31.4281 5.83879 31.736 5.51907C31.9427 5.30655 32.1561 5.0875 32.3909 4.87871C34.2651 3.22886 36.8429 1.25371 40.2301 0.620804C40.9397 0.48759 41.6601 0.419571 42.3822 0.417603C44.5876 0.417603 46.6629 1.07008 48.5502 2.35361C50.7463 3.84499 51.6838 5.78006 51.3919 8.23153L54.0904 8.62022L52.4876 10.4239C52.423 10.4956 52.3687 10.5628 52.3117 10.6345C52.1431 10.8629 51.9387 11.0628 51.7063 11.2264C45.8573 15.1338 42.3223 20.5271 40.9001 27.7174C40.6273 29.0664 40.2815 30.3997 39.8643 31.7115C38.2848 36.745 34.6637 39.8135 29.1039 40.836C27.4487 41.1399 25.6596 41.2881 23.6348 41.2881L23.6329 41.2927ZM17.5463 27.3446C16.6548 27.3309 15.7741 27.5399 14.9844 27.9523C14.0758 28.4473 13.535 29.1641 13.4602 29.9629C13.3853 30.7617 13.7849 31.5605 14.583 32.2083C15.5187 32.9699 16.7042 33.3642 18.1919 33.4145C19.3147 33.3819 20.1924 33.1544 20.941 32.6996C21.7485 32.2102 22.2313 31.5326 22.3005 30.7915C22.3698 30.0505 22.0198 29.3001 21.318 28.6691C20.3936 27.8395 18.9788 27.3446 17.5463 27.3446ZM40.7981 7.21086C42.035 7.32551 43.2992 7.44389 44.5492 7.57625C44.5314 7.16612 44.32 6.96106 43.7951 6.74574C43.4427 6.5987 43.0646 6.52267 42.6825 6.52203C41.994 6.52775 41.3285 6.7697 40.7981 7.20713V7.21086Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.1224 55.7796C55.1224 55.2688 55.1224 54.7602 55.1224 54.2537C55.1224 52.451 55.1224 50.5877 55.1365 48.7636C55.1412 48.2043 55.4172 47.7727 55.5865 47.5155L55.6296 47.4484L55.8598 47.083C56.3005 46.3829 56.7552 45.6596 57.2399 44.9559C58.6303 42.936 59.0336 40.5749 58.4731 37.7255C58.2645 36.6656 57.9735 35.6273 57.6647 34.5283C57.4963 33.9243 57.3213 33.3007 57.1622 32.6762C57.0069 32.0666 56.9863 31.5474 57.0977 31.0916C57.7751 28.2999 58.4844 25.4681 59.1702 22.7296L59.5445 21.2382C59.6038 21.0101 59.7171 20.7995 59.8748 20.6239C59.8869 20.6099 59.9038 20.5913 59.9234 20.5643L61.4701 18.5406L62.2486 20.9558C62.3659 21.3193 62.4822 21.6769 62.5976 22.0286C62.8521 22.8051 63.0926 23.5387 63.3209 24.2974C64.8919 29.5172 66.0091 34.3298 66.7362 39.0118C67.2901 42.522 66.7162 46.1168 65.0967 49.2827C63.4771 52.4486 60.8946 55.0236 57.718 56.6399C57.5374 56.7332 57.3587 56.8096 57.2015 56.8786L57.0144 56.9643L55.1271 57.8228L55.1224 55.7796Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2768 63.0587C27.3102 63.0317 20.8025 62.7343 14.5175 60.666C11.4578 59.6593 8.92395 58.3431 6.77094 56.6411C3.98541 54.4357 2.26562 51.3728 1.51426 47.2696C0.88174 43.8133 0.936946 40.1855 1.68924 35.8446C1.77251 35.3693 2.03731 35.0337 2.19544 34.8333C2.22164 34.7997 2.2572 34.7541 2.26843 34.7401L3.0282 33.5638L4.16506 34.3803C4.18751 34.3943 4.21184 34.4073 4.23617 34.4204C4.47477 34.5537 4.92016 34.8025 5.14378 35.3357C6.30497 38.1088 8.53845 40.0196 12.1717 41.3488C14.481 42.1942 17.0672 42.6696 20.0764 42.8029C21.8027 42.8793 23.6376 42.9539 25.4276 42.9539C28.0269 42.9539 30.1715 42.8001 32.1766 42.4701C32.434 42.4282 32.6941 42.3769 32.9692 42.3191L33.2162 42.2688C33.4603 42.2179 33.7088 42.1914 33.9582 42.1896C34.5393 42.1896 35.9353 42.389 36.3395 44.2402C36.6249 45.5498 36.9103 46.88 37.1863 48.1672C37.2153 48.3033 37.2443 48.4394 37.2743 48.5764C37.3086 48.2036 37.3429 47.8307 37.3772 47.4579C37.5868 45.1854 37.8029 42.8346 38.0228 40.5229C38.0668 40.0615 38.1632 39.3783 38.4907 38.7696C39.998 36.0096 40.8673 33.052 41.788 29.9201C41.9115 29.5025 42.0341 29.0812 42.1623 28.6673C43.2196 25.1253 44.2339 21.9431 45.9415 18.9351C47.0044 17.0578 48.116 15.7221 49.4391 14.7276C50.7275 13.7572 52.117 13.2045 53.5692 13.0833C53.7816 13.0656 53.9903 13.0563 54.1896 13.0563C56.7674 13.0563 58.3 14.5374 58.5059 17.2266C58.693 19.6724 58.2037 21.9803 57.6469 24.1C57.384 25.102 57.0855 26.1012 56.7898 27.0669C56.2518 28.8463 55.7437 30.526 55.4481 32.2532C54.824 35.9062 54.5124 39.6505 54.2654 43.1524C54.0838 45.7363 54.0436 48.3919 54.0052 50.9598C53.994 51.6981 53.9815 52.436 53.9678 53.1736C53.9238 55.447 52.9657 57.2516 51.1205 58.5361C47.9662 60.7564 44.2804 62.1099 40.4341 62.4603C38.5721 62.6327 36.682 62.7614 34.8536 62.8853C34.0302 62.9412 33.2071 62.9984 32.3844 63.0568H32.332L32.2768 63.0587Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.6422 41.262C21.9655 41.262 20.2691 41.1604 18.6831 41.0513C15.833 40.8537 13.5425 40.438 11.4727 39.7407C6.73255 38.1412 4.16035 34.836 3.84128 29.9153C3.48104 24.3748 4.34655 19.7478 6.48459 15.7686C8.14637 12.6777 10.3508 10.2477 13.0344 8.54656C15.688 6.86875 18.8356 5.88164 22.3856 5.62345C22.686 5.60107 23.0032 5.58989 23.3278 5.58989C24.4881 5.58989 25.6352 5.72971 26.745 5.86393C27.3672 5.93943 27.9557 6.01027 28.5462 6.05874C28.8933 6.08764 29.2255 6.14357 29.5464 6.19763C29.87 6.2624 30.1982 6.30229 30.5279 6.31694C30.621 6.31986 30.7139 6.3095 30.804 6.28618C31.0435 6.21161 31.4309 5.81266 31.7397 5.49295C31.9464 5.28043 32.1598 5.06138 32.3946 4.85259C34.2651 3.20274 36.8401 1.22759 40.2301 0.594681C40.9396 0.461358 41.66 0.393337 42.3821 0.391479C44.5857 0.391479 46.6591 1.04396 48.5445 2.32748C50.7369 3.82167 51.6735 5.75394 51.3825 8.20261L54.0782 8.5941L52.4726 10.3977C52.408 10.4695 52.3537 10.5366 52.2966 10.6084C52.1287 10.837 51.9245 11.0369 51.6922 11.2003C45.8442 15.1077 42.3166 20.5009 40.8953 27.6913C40.6234 29.0404 40.2779 30.3737 39.8605 31.6854C38.2829 36.7188 34.6646 39.7874 29.1076 40.8099C27.4533 41.1138 25.6661 41.262 23.6432 41.262H23.6422ZM17.5603 27.3184C16.6697 27.305 15.7899 27.5139 15.0012 27.9262C14.0936 28.4211 13.5528 29.1379 13.4788 29.9368C13.4049 30.7356 13.8035 31.5344 14.6017 32.1822C15.5373 32.9438 16.721 33.338 18.2078 33.3884C19.3306 33.3558 20.2055 33.1283 20.9531 32.6734C21.7596 32.1841 22.2425 31.5064 22.3117 30.7654C22.3809 30.0244 22.031 29.274 21.3311 28.643C20.4029 27.8134 18.99 27.3184 17.5603 27.3184ZM40.7915 7.17728C42.0275 7.29193 43.2907 7.41031 44.5389 7.54267C44.522 7.13254 44.3106 6.92748 43.7903 6.71216C43.4383 6.56512 43.0605 6.48909 42.6787 6.48845C41.9888 6.49457 41.3221 6.7379 40.7915 7.17728Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.1075 55.7537C55.1075 55.2429 55.1075 54.7342 55.1075 54.2278C55.1075 52.4251 55.1075 50.5618 55.1216 48.7376C55.1272 48.1783 55.4023 47.7468 55.5716 47.4895L55.6156 47.4224L55.8449 47.057C56.2846 46.357 56.7394 45.6337 57.2241 44.9299C58.6126 42.91 59.0159 40.549 58.4554 37.6995C58.2468 36.6397 57.9567 35.6013 57.6489 34.5023C57.4795 33.8983 57.3045 33.2747 57.1464 32.6502C56.9911 32.0406 56.9705 31.5214 57.0809 31.0656C57.7583 28.2739 58.4676 25.4422 59.1525 22.7036L59.5268 21.2122C59.5861 20.9842 59.6994 20.7735 59.8571 20.598C59.8745 20.5791 59.8908 20.5592 59.9057 20.5383L61.4562 18.5156L62.2328 20.9298C62.3497 21.2943 62.4667 21.6513 62.5827 22.0036C62.8363 22.7801 63.0768 23.5136 63.3051 24.2724C64.8742 29.4922 65.9905 34.3047 66.7166 38.9868C67.2705 42.4963 66.6973 46.0905 65.0788 49.2562C63.4603 52.422 60.8793 54.9974 57.7041 56.6149C57.5235 56.7081 57.3457 56.7846 57.1885 56.8535L57.0014 56.9393L55.1075 57.7968V55.7537Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2768 63.0324C27.3176 63.0054 20.8127 62.708 14.5334 60.6397C11.4765 59.633 8.94452 58.3168 6.79338 56.6148C4.00878 54.4113 2.28993 51.3465 1.54045 47.2433C0.907925 43.787 0.96313 40.1592 1.71542 35.8184C1.79776 35.343 2.06256 35.0074 2.22069 34.807C2.24689 34.7735 2.28245 34.7278 2.29367 34.7138L3.05251 33.5375L4.1875 34.354C4.20996 34.368 4.23522 34.381 4.25955 34.3941C4.49815 34.5274 4.9426 34.7763 5.16529 35.3094C6.33022 38.0797 8.56183 39.9877 12.1913 41.3197C14.4987 42.1651 17.0822 42.6405 20.0885 42.7738C21.813 42.8502 23.6441 42.9248 25.436 42.9248C28.0316 42.9248 30.1743 42.771 32.1785 42.441C32.4349 42.3991 32.695 42.3478 32.9701 42.29L33.2171 42.2397C33.4609 42.1889 33.7091 42.1624 33.9582 42.1605C34.5383 42.1605 35.9334 42.36 36.3367 44.2111C36.623 45.5161 36.9075 46.8509 37.1835 48.1381C37.2125 48.2742 37.2415 48.4103 37.2714 48.5473C37.3064 48.1745 37.3407 47.8016 37.3744 47.4288C37.584 45.1563 37.8001 42.8055 38.0191 40.4939C38.063 40.0325 38.1594 39.3492 38.4916 38.7405C39.9971 35.9806 40.8663 33.0229 41.7861 29.891C41.9109 29.4734 42.0341 29.0559 42.1557 28.6383C43.2121 25.0962 44.2254 21.914 45.9312 18.9061C46.9932 17.0344 48.1029 15.6996 49.4251 14.7031C50.7116 13.7328 52.1002 13.1801 53.5514 13.0589C53.7638 13.0412 53.9725 13.0319 54.1718 13.0319C56.7468 13.0319 58.2785 14.513 58.4844 17.2021C58.6715 19.648 58.1821 21.9559 57.6254 24.0756C57.3625 25.0776 57.064 26.0768 56.7692 27.0425C56.2322 28.8219 55.7241 30.5016 55.4293 32.2288C54.8052 35.8817 54.4937 39.6261 54.2466 43.128C54.066 45.7118 54.0258 48.3674 53.9875 50.9354C53.9762 51.6737 53.9637 52.4116 53.95 53.1492C53.906 55.4226 52.9488 57.2272 51.1046 58.5117C47.9539 60.7317 44.2715 62.0853 40.4284 62.4359C38.5674 62.6083 36.6792 62.7369 34.8527 62.8609C34.0305 62.9168 33.2084 62.974 32.3862 63.0324H32.3329H32.2768Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.6517 41.236C21.9759 41.236 20.2832 41.1344 18.7 41.0253C15.8528 40.8277 13.5631 40.412 11.4953 39.7147C6.75322 38.1152 4.18757 34.81 3.86851 29.8893C3.5092 24.3488 4.37284 19.7218 6.50901 15.7426C8.16985 12.6517 10.3715 10.2217 13.0588 8.52056C15.7105 6.84275 18.8535 5.85564 22.4016 5.59744C22.702 5.57507 23.0192 5.56389 23.3429 5.56389C24.5022 5.56389 25.6484 5.70371 26.7572 5.83793C27.3795 5.91343 27.9671 5.98427 28.5565 6.03274C28.9037 6.06164 29.2349 6.11757 29.5559 6.17163C29.8795 6.2364 30.2076 6.27629 30.5374 6.29094C30.6301 6.29381 30.7227 6.28345 30.8125 6.26018C31.052 6.18561 31.4394 5.78666 31.7482 5.46695C31.954 5.25443 32.1674 5.03538 32.4031 4.82658C34.267 3.17674 36.8392 1.20159 40.2245 0.56868C40.9341 0.435289 41.6545 0.367268 42.3766 0.365479C44.5783 0.365479 46.6499 1.01796 48.5334 2.30148C50.7248 3.79287 51.6614 5.72794 51.3704 8.17941L54.0642 8.5681L52.4595 10.3717C52.3959 10.4435 52.3417 10.5106 52.2846 10.5824C52.1161 10.8106 51.912 11.0104 51.6801 11.1743C45.8377 15.0817 42.3092 20.4749 40.8851 27.6653C40.6151 29.0143 40.2715 30.3476 39.8559 31.6594C38.2792 36.6928 34.6647 39.7614 29.1114 40.7839C27.459 41.0878 25.6737 41.236 23.6526 41.236H23.6517ZM17.5754 27.2924C16.6856 27.2789 15.8067 27.4878 15.0191 27.9002C14.1124 28.3951 13.5716 29.1119 13.4967 29.9108C13.4218 30.7096 13.8214 31.5084 14.6195 32.1562C15.5552 32.9178 16.737 33.312 18.2228 33.3624C19.3457 33.3298 20.2187 33.1023 20.9663 32.6474C21.7719 32.1581 22.2538 31.4804 22.324 30.7394C22.3941 29.9984 22.0432 29.248 21.3434 28.617C20.4152 27.7874 19.006 27.2924 17.5754 27.2924ZM40.7859 7.15874C42.0201 7.27339 43.2824 7.39177 44.5287 7.52413C44.5118 7.114 44.3004 6.90893 43.7801 6.69361C43.4287 6.54661 43.0515 6.47057 42.6704 6.46991C41.9817 6.47493 41.316 6.71697 40.7859 7.15501V7.15874Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.0841 55.7277C55.0841 55.2169 55.0841 54.7082 55.0841 54.2018C55.0841 52.3991 55.0841 50.5358 55.0981 48.7116C55.1038 48.1523 55.3788 47.7208 55.5482 47.4635L55.5912 47.3964L55.8205 47.031C56.2603 46.331 56.715 45.6077 57.1978 44.9039C58.5864 42.884 58.9887 40.523 58.4292 37.6735C58.2205 36.6137 57.9305 35.5753 57.6226 34.4763C57.4542 33.8723 57.2792 33.2487 57.1211 32.6242C56.9658 32.0146 56.9442 31.4954 57.0556 31.0396C57.733 28.2433 58.4404 25.4162 59.1253 22.6776L59.4996 21.1862C59.5585 20.958 59.6718 20.7473 59.8299 20.572C59.8421 20.558 59.858 20.5393 59.8786 20.5123L61.4271 18.4896L62.2028 20.9038C62.3198 21.2683 62.4367 21.6253 62.5518 21.9776C62.8063 22.7541 63.0458 23.4876 63.2732 24.2454C64.8386 29.4644 65.954 34.2778 66.68 38.9626C67.2339 42.4713 66.6615 46.0646 65.0445 49.2301C63.4274 52.3956 60.8484 54.9716 57.675 56.5908C57.4954 56.684 57.3167 56.7604 57.1604 56.8294L56.9686 56.9152L55.0841 57.7736V55.7277Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2823 63.0109C27.3232 62.9839 20.8286 62.6866 14.5548 60.6182C11.5045 59.6069 8.97068 58.2907 6.82609 56.5887C4.0443 54.3851 2.32732 51.3203 1.57784 47.2172C0.946249 43.7609 1.00145 40.1331 1.75281 35.7922C1.83421 35.3168 2.09901 34.9813 2.25714 34.7809C2.28334 34.7473 2.3189 34.7017 2.33013 34.6877L3.08335 33.5085L4.2174 34.3251C4.23986 34.3391 4.26419 34.3521 4.28852 34.3652C4.52711 34.4985 4.97157 34.7473 5.19426 35.2805C6.35451 38.0535 8.58331 39.9644 12.2119 41.2936C14.5174 42.139 17.098 42.6144 20.1025 42.7477C21.8251 42.8241 23.6581 42.8987 25.4443 42.8987C28.0381 42.8987 30.1789 42.7449 32.1813 42.4149C32.4377 42.373 32.6968 42.3217 32.9719 42.2639L33.219 42.2136C33.4629 42.1617 33.7115 42.1342 33.9609 42.1315C34.5411 42.1315 35.9343 42.331 36.3376 44.1822C36.623 45.4918 36.9074 46.822 37.1797 48.1092L37.2667 48.5184C37.3017 48.1456 37.3363 47.7727 37.3706 47.3999C37.5792 45.1274 37.7954 42.7766 38.0143 40.4649C38.0583 40.0035 38.1547 39.3203 38.4869 38.7116C39.9905 35.9516 40.8588 32.994 41.7777 29.8621C41.9024 29.4445 42.0256 29.0269 42.1473 28.6093C43.2027 25.0673 44.2151 21.8851 45.9199 18.8771C46.9801 17.0054 48.0898 15.6706 49.411 14.6742C50.6957 13.7039 52.0833 13.1511 53.528 13.03C53.7404 13.0123 53.949 13.0029 54.1474 13.0029C56.7205 13.0029 58.2513 14.4841 58.4562 17.1732C58.6434 19.6191 58.154 21.927 57.5982 24.0466C57.3362 25.0487 57.0368 26.0479 56.743 27.0136C56.2059 28.793 55.6988 30.4726 55.404 32.1999C54.7809 35.8528 54.4683 39.5971 54.2232 43.0991C54.0426 45.6829 54.0024 48.3385 53.9631 50.9065C53.9519 51.6447 53.9397 52.3826 53.9266 53.1203C53.8826 55.3937 52.9263 57.1983 51.084 58.4827C47.9367 60.7025 44.2573 62.0561 40.4172 62.4069C38.558 62.5794 36.6744 62.708 34.847 62.832C34.0249 62.8879 33.203 62.9451 32.3815 63.0035L32.3291 63.0081L32.2823 63.0109Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.661 41.2098C21.9871 41.2098 20.2925 41.1082 18.714 40.9992C15.8686 40.8016 13.5818 40.3858 11.5168 39.6886C6.77845 38.0891 4.21841 34.7838 3.8956 29.8632C3.5363 24.3227 4.399 19.6957 6.53423 15.7165C8.19227 12.6256 10.393 10.1955 13.0737 8.49444C15.7227 6.81663 18.8637 5.82952 22.4081 5.57132C22.7085 5.54895 23.0257 5.53777 23.3485 5.53777C24.5069 5.53777 25.6521 5.67758 26.76 5.81181C27.3813 5.88731 27.968 5.95815 28.5574 6.00662C28.9046 6.03552 29.2358 6.09144 29.5558 6.1455C29.8791 6.21022 30.207 6.25011 30.5364 6.26482C30.6291 6.26769 30.7218 6.25733 30.8115 6.23406C31.051 6.15949 31.4375 5.76054 31.7472 5.44082C31.953 5.2283 32.1664 5.00926 32.4022 4.80046C34.2632 3.15062 36.8345 1.17546 40.2161 0.542557C40.9256 0.409008 41.646 0.340986 42.3681 0.339355C44.5679 0.339355 46.6377 0.991836 48.5193 2.27536C50.7088 3.76675 51.6445 5.70182 51.3535 8.15328L54.0446 8.54197L52.4399 10.3465C52.3753 10.4183 52.321 10.4854 52.264 10.5572C52.0961 10.7856 51.8923 10.9854 51.6604 11.1491C45.8264 15.0565 42.3036 20.4498 40.8832 27.6401C40.6127 28.989 40.2691 30.3223 39.854 31.6342C38.2792 36.6676 34.6675 39.7362 29.1198 40.7587C27.4692 41.0626 25.6849 41.2108 23.6657 41.2108L23.661 41.2098ZM17.5865 27.2663C16.6974 27.2527 15.8191 27.4617 15.0321 27.8741C14.1264 28.369 13.5865 29.0858 13.5116 29.8846C13.4368 30.6834 13.8354 31.4823 14.6345 32.1301C15.5701 32.8907 16.75 33.2859 18.234 33.3363C19.3569 33.3036 20.228 33.0762 20.9747 32.6213C21.7803 32.132 22.2622 31.4543 22.3314 30.7133C22.4006 29.9722 22.0507 29.2219 21.3517 28.5909C20.4273 27.7613 19.02 27.2663 17.5865 27.2663ZM40.7756 7.13262C42.0088 7.24727 43.2711 7.36564 44.5184 7.498C44.5015 7.08787 44.29 6.88281 43.7698 6.66749C43.4195 6.51957 43.0433 6.44227 42.6629 6.44005C41.974 6.44604 41.3085 6.68944 40.7794 7.12889L40.7756 7.13262Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.0654 55.7012C55.0654 55.1904 55.0654 54.6817 55.0654 54.1753C55.0654 52.3726 55.0654 50.5093 55.0794 48.6851C55.0794 48.1259 55.3601 47.6943 55.5285 47.437L55.5716 47.3699L55.8008 47.0045C56.2406 46.3045 56.6944 45.5812 57.1772 44.8774C58.5639 42.8575 58.9662 40.4965 58.4067 37.647C58.199 36.5872 57.9089 35.5488 57.6011 34.4499C57.4327 33.8458 57.2586 33.2223 57.0995 32.5977C56.9452 31.9881 56.9236 31.4689 57.035 31.0131C57.7115 28.2215 58.4189 25.3897 59.1029 22.6511L59.4771 21.1597C59.5364 20.9317 59.6497 20.7211 59.8074 20.5455C59.8196 20.5315 59.8355 20.5129 59.8561 20.4858L61.4028 18.4631L62.1784 20.8773C62.2954 21.2418 62.4124 21.5988 62.5275 21.9511C62.781 22.7276 63.0206 23.4611 63.2479 24.2189C64.8152 29.4388 65.9287 34.2522 66.6538 38.9343C67.208 42.442 66.6366 46.0344 65.0212 49.1997C63.4058 52.365 60.8287 54.9416 57.6572 56.5624C57.4776 56.6556 57.2998 56.7321 57.1426 56.8011L56.9555 56.8868L55.0729 57.7453L55.0654 55.7012Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2833 62.9804C27.3298 62.9534 20.8399 62.656 14.5727 60.5877C11.5205 59.581 8.99317 58.2648 6.84671 56.5628C4.06679 54.3593 2.35542 51.2945 1.60687 47.1913C0.970606 43.735 1.02488 40.1044 1.77342 35.7664C1.85483 35.291 2.11963 34.9554 2.27776 34.755C2.30396 34.7215 2.33951 34.6758 2.35074 34.6618L3.10771 33.4855L4.24457 34.302C4.26796 34.316 4.29229 34.329 4.31662 34.3421C4.55428 34.4754 4.99779 34.7243 5.22142 35.2574C6.3798 38.0305 8.60392 39.9385 12.2306 41.2677C14.5343 42.1131 17.1121 42.5885 20.1138 42.7218C21.8354 42.7982 23.6694 42.8728 25.4472 42.8728C28.039 42.8728 30.178 42.719 32.1785 42.389C32.4339 42.3471 32.6931 42.2958 32.9682 42.238L33.2143 42.1877C33.4578 42.1369 33.7057 42.1103 33.9544 42.1085C34.5336 42.1085 35.9259 42.3079 36.3292 44.1591C36.6099 45.4641 36.899 46.7989 37.1713 48.0861L37.2583 48.4953C37.2933 48.1225 37.3276 47.7496 37.3613 47.3768C37.5709 45.1043 37.787 42.7535 38.0059 40.4419C38.049 39.9805 38.1454 39.2972 38.4738 38.6885C39.9774 35.9285 40.8448 32.9709 41.7627 29.839C41.8875 29.4214 42.0104 29.0039 42.1314 28.5863C43.1859 25.0442 44.1974 21.862 45.9003 18.8541C46.9604 16.9824 48.0683 15.6476 49.3885 14.6511C50.6723 13.6808 52.0581 13.1281 53.5056 13.0069C53.717 12.9892 53.9257 12.9799 54.1241 12.9799C56.6953 12.9799 58.2233 14.461 58.4282 17.1501C58.6153 19.596 58.126 21.9039 57.5702 24.0236C57.3091 25.0256 57.0088 26.0248 56.7159 26.9905C56.1797 28.7699 55.6726 30.4496 55.3788 32.1768C54.7556 35.8297 54.4431 39.5741 54.198 43.076C54.0174 45.6598 53.9781 48.3154 53.9388 50.8834C53.9276 51.6217 53.9154 52.3596 53.9023 53.0972C53.8583 55.3706 52.902 57.1752 51.0616 58.4596C47.9211 60.676 44.2496 62.0283 40.4172 62.3801C38.5589 62.5526 36.6745 62.6812 34.8517 62.8052C34.0302 62.8611 33.2093 62.9183 32.389 62.9767H32.3366L32.2833 62.9804Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.6685 41.1836C21.9955 41.1836 20.3 41.082 18.7253 40.9729C15.8836 40.7753 13.5987 40.3596 11.5345 39.6624C6.80278 38.0629 4.24087 34.7576 3.92555 29.837C3.56344 24.2965 4.42614 19.6676 6.55856 15.6902C8.21566 12.5993 10.4145 10.1693 13.0953 8.46819C15.7423 6.79038 18.8797 5.80327 22.4212 5.54508C22.7216 5.52271 23.0378 5.51152 23.3616 5.51152C24.5181 5.51152 25.6624 5.65134 26.7694 5.78556C27.3897 5.86106 27.9764 5.93191 28.5649 5.98038C28.9121 6.00927 29.2433 6.0652 29.5633 6.11926C29.886 6.18402 30.2132 6.22391 30.542 6.23857C30.6347 6.24144 30.7274 6.23108 30.8171 6.20781C31.0567 6.13324 31.4431 5.7343 31.7528 5.41458C31.9587 5.20206 32.172 4.98301 32.4078 4.77422C34.2679 3.12437 36.8364 1.14922 40.2151 0.516312C40.9228 0.382995 41.6414 0.314974 42.3616 0.31311C44.5586 0.31311 46.6265 0.965591 48.5072 2.24911C50.6948 3.7405 51.6296 5.67557 51.3395 8.12704L54.0277 8.51573L52.423 10.3184C52.3585 10.3911 52.3051 10.4583 52.2471 10.53C52.0799 10.7586 51.8763 10.9585 51.6445 11.1219C45.8162 15.0294 42.2942 20.4226 40.8767 27.6129C40.6066 28.9619 40.2631 30.2953 39.8474 31.607C38.2708 36.6395 34.6628 39.7081 29.1207 40.7334C27.4711 41.0373 25.6886 41.1855 23.6713 41.1855L23.6685 41.1836ZM17.6053 27.2401C16.7168 27.2266 15.8391 27.4356 15.0527 27.8478C14.1479 28.3428 13.608 29.0596 13.5341 29.8584C13.4602 30.6572 13.8578 31.456 14.6569 32.1039C15.5926 32.8645 16.7706 33.2597 18.2537 33.31C19.3765 33.2774 20.2467 33.05 20.9924 32.5951C21.7971 32.1057 22.2781 31.4281 22.3473 30.687C22.4165 29.946 22.0666 29.1957 21.3686 28.5646C20.4394 27.735 19.0331 27.2401 17.6053 27.2401ZM40.7737 7.10637C42.006 7.22102 43.2664 7.3394 44.5109 7.47176C44.494 7.06163 44.2826 6.85656 43.7623 6.64125C43.4116 6.49415 43.035 6.4181 42.6545 6.41754C41.967 6.42274 41.3024 6.6648 40.7737 7.10264V7.10637Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.0457 55.6756V54.1497C55.0457 52.347 55.0457 50.4837 55.0597 48.6596C55.0597 48.1003 55.3405 47.6687 55.5079 47.4115L55.551 47.3434L55.7802 46.978C56.2191 46.2789 56.6729 45.5556 57.1557 44.8519C58.5414 42.832 58.9428 40.4709 58.3842 37.6215C58.1756 36.5616 57.8864 35.5233 57.5795 34.4243C57.4111 33.8203 57.2362 33.1967 57.078 32.5722C56.9236 31.9626 56.9021 31.4434 57.0135 30.9876C57.689 28.1912 58.3955 25.3641 59.0785 22.6256L59.4528 21.1342C59.5115 20.9061 59.6244 20.6954 59.7822 20.5199C59.7943 20.5059 59.8102 20.4873 59.8308 20.4603L61.3766 18.4366L62.1513 20.8508C62.2683 21.2153 62.3843 21.5723 62.4994 21.9246C62.7529 22.7011 62.9925 23.4347 63.2199 24.1925C64.7853 29.4123 65.8987 34.2258 66.6229 38.9078C67.7392 46.1401 64.1275 53.2241 57.6338 56.536C57.4542 56.6292 57.2773 56.7056 57.1201 56.7746L56.933 56.8603L55.0513 57.7188L55.0457 55.6756Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2851 62.9543C27.3363 62.9273 20.852 62.6299 14.5923 60.5616C11.5438 59.5549 9.01841 58.2387 6.87383 56.5367C4.09297 54.3332 2.37973 51.2665 1.63118 47.1652C1.00053 43.7089 1.05574 40.0811 1.80522 35.7402C1.88756 35.2649 2.15236 34.9293 2.30956 34.7289C2.33576 34.6953 2.37131 34.6497 2.38254 34.6357L3.13951 33.4594L4.27169 34.2759C4.29414 34.2899 4.31847 34.3029 4.3428 34.316C4.5814 34.4493 5.02398 34.6981 5.24667 35.2313C6.40505 38.0016 8.63011 39.9124 12.2531 41.2416C14.5539 42.087 17.1308 42.5624 20.1287 42.6957C21.8495 42.7721 23.6778 42.8467 25.4621 42.8467C28.0512 42.8467 30.1883 42.6929 32.1869 42.3629C32.4433 42.321 32.7025 42.2697 32.9766 42.2119L33.2227 42.1616C33.4658 42.1107 33.7135 42.0842 33.9619 42.0824C34.5411 42.0824 35.9268 42.2818 36.3348 44.133C36.6155 45.4426 36.9037 46.7728 37.1769 48.06L37.2639 48.4692C37.2989 48.0964 37.3332 47.7235 37.3668 47.3507C37.5755 45.0782 37.7916 42.7274 38.0106 40.4157C38.0536 39.9543 38.15 39.2711 38.4784 38.6624C39.9755 35.9024 40.8467 32.9448 41.7636 29.8129C41.8884 29.3953 42.0131 28.9777 42.1379 28.5602C43.1906 25.0181 44.1964 21.8359 45.9031 18.8279C46.9614 16.9562 48.0692 15.6215 49.3876 14.625C50.6704 13.6547 52.0552 13.1019 53.5046 12.9808C53.7161 12.9631 53.9247 12.9537 54.1222 12.9537C56.6915 12.9537 58.2186 14.4349 58.4263 17.124C58.6134 19.5699 58.125 21.8778 57.5702 23.9974C57.3082 24.9995 57.0087 25.9987 56.7159 26.9644C56.1807 28.7438 55.6745 30.4235 55.3797 32.1507C54.7575 35.8036 54.444 39.5479 54.2008 43.0499C54.0202 45.6337 53.9799 48.2893 53.9416 50.8573C53.9303 51.5955 53.9182 52.3335 53.9051 53.0711C53.8611 55.3445 52.9058 57.1491 51.0671 58.4335C47.9246 60.6545 44.2486 62.0086 40.4116 62.3587C38.5552 62.5311 36.6688 62.6597 34.8508 62.7837C34.0305 62.8396 33.2102 62.8968 32.3899 62.9552H32.3375L32.2851 62.9543Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.6797 41.1576C22.0086 41.1576 20.3187 41.056 18.7411 40.9469C15.9013 40.7493 13.6183 40.3336 11.557 39.6364C6.82613 38.0369 4.26704 34.7316 3.9489 29.811C3.59054 24.2705 4.4523 19.6434 6.58379 15.6642C8.23902 12.5733 10.4351 10.1433 13.1111 8.44126C15.7554 6.76345 18.8909 5.77727 22.4287 5.51908C22.729 5.49671 23.0453 5.48552 23.3644 5.48552C24.5199 5.48552 25.6633 5.62534 26.7693 5.75956C27.3897 5.83506 27.9754 5.9059 28.564 5.95437C28.9102 5.98327 29.2405 6.0392 29.5605 6.09326C29.8831 6.15802 30.2104 6.19791 30.5392 6.21257C30.6319 6.21533 30.7245 6.20497 30.8143 6.18181C31.0529 6.10724 31.4384 5.7083 31.75 5.38858C31.9549 5.17606 32.1682 4.95701 32.4049 4.74822C34.2632 3.09837 36.8289 1.12322 40.2048 0.490311C40.9118 0.35658 41.6298 0.288554 42.3494 0.287109C44.5445 0.287109 46.6105 0.939591 48.4894 2.22311C50.6751 3.7145 51.6089 5.64957 51.3189 8.10104L54.0052 8.48973L52.4052 10.2924C52.3416 10.3651 52.2873 10.4323 52.2302 10.504C52.0627 10.7324 51.8592 10.9323 51.6276 11.0959C45.8058 15.0071 42.2886 20.3966 40.8701 27.5907C40.5997 28.9396 40.2561 30.2729 39.8408 31.5848C38.2679 36.6182 34.6637 39.6867 29.1254 40.7092C27.4776 41.0131 25.697 41.1613 23.6806 41.1613L23.6797 41.1576ZM17.6202 27.2178C16.7323 27.2042 15.8552 27.4132 15.0695 27.8255C14.1656 28.3205 13.6267 29.0373 13.5518 29.8361C13.477 30.6349 13.8756 31.4338 14.6747 32.0816C15.6103 32.8422 16.7865 33.2374 18.2677 33.2877C19.3905 33.2551 20.2588 33.0277 21.0036 32.5728C21.8074 32.0835 22.2883 31.4058 22.3576 30.6648C22.4268 29.9237 22.0769 29.1734 21.3798 28.5423C20.4516 27.709 19.0471 27.2178 17.6202 27.2178ZM40.7672 7.0841C41.9985 7.19875 43.2579 7.31713 44.5015 7.44949C44.4846 7.03936 44.2732 6.83429 43.7529 6.61897C43.4026 6.47168 43.0263 6.39531 42.646 6.39433C41.9595 6.39902 41.2957 6.64008 40.7672 7.07664V7.0841Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.027 55.6472C55.027 55.1364 55.027 54.6278 55.027 54.1213C55.027 52.3186 55.027 50.4553 55.0411 48.6312C55.0467 48.0719 55.3218 47.6403 55.4893 47.3831L55.5332 47.315L55.7615 46.9496C56.2004 46.2505 56.6533 45.5272 57.1351 44.8235C58.5199 42.8036 58.9214 40.4425 58.3637 37.5931C58.155 36.5332 57.8659 35.4949 57.559 34.3959C57.3906 33.7919 57.2165 33.1683 57.0584 32.5438C56.904 31.9342 56.8825 31.415 56.9938 30.9592C57.6685 28.1675 58.3749 25.3357 59.057 22.5972L59.4313 21.1058C59.4904 20.8778 59.6033 20.6672 59.7607 20.4915C59.7728 20.4775 59.7887 20.4589 59.8093 20.4319L61.3541 18.4092L62.128 20.8234C62.2449 21.1878 62.3609 21.5448 62.476 21.8972C62.7296 22.6736 62.9682 23.4072 63.1956 24.165C64.76 29.3848 65.8716 34.1983 66.5958 38.8803C67.7131 46.1079 64.1041 53.192 57.6133 56.5085C57.4336 56.6017 57.2558 56.6781 57.0996 56.7471L56.9124 56.8329L55.0327 57.6913L55.027 55.6472Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2862 62.928C27.342 62.901 20.8643 62.6037 14.6073 60.5353C11.5608 59.5286 9.03815 58.2125 6.89543 56.5104C4.12019 54.3069 2.40414 51.2421 1.66027 47.1389C1.03056 43.6826 1.08576 40.0549 1.83431 35.714C1.91665 35.2386 2.18051 34.903 2.33864 34.7026C2.36391 34.6691 2.4004 34.6234 2.41163 34.6094L3.16766 33.4331L4.29984 34.2468C4.3223 34.2608 4.34662 34.2739 4.37095 34.2869C4.60862 34.4202 5.05119 34.6691 5.27389 35.2023C6.43039 37.9753 8.65358 39.8861 12.2709 41.2153C14.5699 42.0608 17.144 42.5361 20.14 42.6694C21.858 42.7459 23.6844 42.8204 25.4678 42.8204C28.0541 42.8204 30.1893 42.6666 32.186 42.3367C32.4415 42.2947 32.7007 42.2435 32.9748 42.1857L33.2209 42.1353C33.4637 42.0845 33.7111 42.058 33.9592 42.0561C34.5374 42.0561 35.9241 42.2556 36.3293 44.1068C36.61 45.4164 36.8982 46.7465 37.1714 48.0338L37.2584 48.443C37.2933 48.0701 37.3276 47.6973 37.3613 47.3244C37.57 45.0519 37.7852 42.7011 38.0041 40.3895C38.0481 39.9281 38.1436 39.2448 38.472 38.6362C39.9691 35.8762 40.8374 32.9186 41.7544 29.7867C41.8769 29.3691 41.9986 28.9478 42.123 28.5339C43.1757 24.9919 44.1815 21.8096 45.8845 18.8017C46.9427 16.93 48.0487 15.5952 49.3662 14.5978C50.648 13.6284 52.0319 13.0757 53.4776 12.9545C53.689 12.9368 53.8967 12.9275 54.0951 12.9275C56.6617 12.9275 58.1878 14.4086 58.3927 17.0978C58.5798 19.5436 58.0914 21.8516 57.5366 23.9712C57.2755 24.9732 56.9751 25.9725 56.6832 26.9381C56.148 28.7175 55.6427 30.3972 55.3489 32.1244C54.7332 35.7736 54.4189 39.5217 54.1718 43.0199C53.9912 45.6037 53.951 48.2593 53.9126 50.8273C53.9014 51.5656 53.8893 52.3035 53.8762 53.0411C53.8322 55.3145 52.8778 57.1191 51.0401 58.4036C47.9033 60.6228 44.2334 61.9766 40.4023 62.3278C38.5487 62.5002 36.667 62.6288 34.8471 62.7528C34.0275 62.8087 33.2081 62.8659 32.3891 62.9243H32.3367L32.2862 62.928Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.6891 41.1314C22.0189 41.1314 20.3309 41.0298 18.7552 40.9208C15.9173 40.7232 13.637 40.3074 11.5766 39.6102C6.85144 38.0107 4.29421 34.7054 3.97608 29.7848C3.61865 24.2443 4.47948 19.6173 6.60816 15.6381C8.26245 12.5472 10.4566 10.1171 13.1299 8.4151C15.7723 6.73729 18.904 5.75111 22.439 5.49292C22.7394 5.47055 23.0547 5.45936 23.3747 5.45936C24.5293 5.45936 25.6718 5.59918 26.7768 5.7334C27.3963 5.80891 27.982 5.87975 28.5696 5.92822C28.9158 5.95711 29.2461 6.01304 29.5652 6.0671C29.8875 6.13185 30.2144 6.17174 30.543 6.18641C30.6357 6.18922 30.7283 6.17886 30.8181 6.15565C31.0567 6.08108 31.4422 5.68214 31.7537 5.36242C31.9587 5.1499 32.1711 4.93085 32.4087 4.72206C34.2642 3.07594 36.828 1.09706 40.2002 0.466019C40.9065 0.332243 41.6239 0.264216 42.3429 0.262817C44.5361 0.262817 46.6003 0.915298 48.4772 2.19882C50.6611 3.69021 51.594 5.62528 51.3039 8.07674L53.9847 8.46357L52.394 10.2663C52.3304 10.339 52.2761 10.4061 52.219 10.4779C52.0521 10.7065 51.8489 10.9064 51.6174 11.0698C45.7993 14.9772 42.2839 20.3704 40.8692 27.5608C40.5988 28.9097 40.2552 30.243 39.8399 31.5549C38.2689 36.5883 34.6675 39.6568 29.1348 40.6794C27.4889 40.9832 25.7092 41.1314 23.6947 41.1314H23.6891ZM17.6343 27.1879C16.7476 27.1744 15.8717 27.3834 15.0873 27.7957C14.1835 28.2906 13.6445 29.0074 13.5706 29.8062C13.4967 30.605 13.8943 31.4039 14.6934 32.0517C15.6291 32.8123 16.8024 33.2075 18.2827 33.2579C19.4055 33.2252 20.272 32.9978 21.0168 32.5429C21.8196 32.0536 22.2996 31.3759 22.3688 30.6349C22.4381 29.8938 22.0881 29.1435 21.392 28.5125C20.4638 27.6838 19.0602 27.1879 17.6343 27.1879ZM40.7616 7.05421C41.9911 7.16886 43.2496 7.28724 44.4922 7.4196C44.4744 7.00947 44.2639 6.80441 43.7436 6.58909C43.3933 6.44179 43.017 6.36543 42.6367 6.36445C41.9509 6.37043 41.2883 6.61285 40.7616 7.05048V7.05421Z"
            fill="#1E1E1E"
        />
        <path
            d="M55.0083 55.623C55.0083 55.1122 55.0083 54.6036 55.0083 54.0972C55.0083 52.2945 55.0083 50.4311 55.0223 48.607C55.028 48.0477 55.303 47.6162 55.4705 47.3589L55.5136 47.2909L55.7428 46.9255C56.1798 46.2264 56.6327 45.5031 57.1145 44.7993C58.4975 42.7794 58.8989 40.4184 58.3412 37.5689C58.1335 36.5091 57.8444 35.4707 57.5375 34.3717C57.37 33.7677 57.1959 33.1441 57.0378 32.5196C56.8834 31.91 56.8619 31.3908 56.9732 30.935C57.6479 28.1387 58.3524 25.3116 59.0317 22.573L59.406 21.0816C59.4649 20.8537 59.5775 20.6432 59.7344 20.4674C59.7466 20.4534 59.7634 20.4347 59.7831 20.4077L61.326 18.385L62.0999 20.7992C62.2168 21.1636 62.3328 21.5197 62.4479 21.873C62.7006 22.6494 62.9392 23.383 63.1665 24.1408C64.7291 29.3607 65.8407 34.1741 66.5631 38.8561C67.6793 46.0838 64.0741 53.1678 57.5908 56.4843C57.4111 56.5775 57.2343 56.654 57.078 56.7229L56.8909 56.8087L55.013 57.6672L55.0083 55.623Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.288 62.9019C27.3476 62.8749 20.8727 62.5775 14.6242 60.5092C11.5813 59.5025 9.06057 58.1863 6.91973 56.4862C4.14729 54.2826 2.43593 51.2178 1.68925 47.1147C1.05953 43.6584 1.11568 40.0306 1.86329 35.6897C1.94563 35.2143 2.20949 34.8788 2.36669 34.6784C2.39289 34.6448 2.42844 34.5991 2.43967 34.5852L3.19477 33.4088L4.32507 34.2254C4.34753 34.2393 4.37186 34.2524 4.39619 34.2654C4.63385 34.3987 5.07643 34.6476 5.29912 35.1808C6.45469 37.9538 8.67601 39.8647 12.2896 41.1939C14.5867 42.0393 17.1552 42.5147 20.1494 42.648C21.8664 42.7244 23.6909 42.799 25.4725 42.799C28.0568 42.799 30.1902 42.6452 32.1851 42.3152C32.4405 42.2733 32.6988 42.222 32.9729 42.1642L33.219 42.1139C33.4615 42.063 33.7085 42.0365 33.9563 42.0346C34.5346 42.0346 35.9213 42.2341 36.3246 44.0853C36.6053 45.3949 36.8925 46.725 37.1667 48.0123L37.2537 48.4215C37.288 48.0486 37.3223 47.6758 37.3566 47.3029C37.5643 45.0304 37.7805 42.6797 37.9985 40.368C38.0425 39.9066 38.1379 39.2234 38.4663 38.6147C39.9634 35.8547 40.8308 32.8971 41.7468 29.7652C41.8716 29.3476 41.9942 28.93 42.1146 28.5124C43.1663 24.9704 44.1731 21.7881 45.8732 18.7802C46.9296 16.9085 48.0356 15.5737 49.3512 14.5764C50.6321 13.607 52.0141 13.0542 53.4588 12.933C53.6703 12.9153 53.878 12.906 54.0764 12.906C56.6401 12.906 58.1653 14.3871 58.3693 17.0763C58.5564 19.5222 58.0689 21.8254 57.5141 23.9497C57.253 24.9517 56.9527 25.951 56.6626 26.9166C56.1274 28.696 55.6221 30.3757 55.3283 32.1029C54.707 35.7559 54.3926 39.5002 54.1512 43.0022C53.9716 45.586 53.9313 48.2416 53.893 50.8096C53.8817 51.5478 53.8696 52.2857 53.8565 53.0233C53.8125 55.2968 52.859 57.1013 51.0232 58.3858C47.8897 60.6045 44.2229 61.9583 40.3948 62.31C38.5421 62.4825 36.6623 62.6111 34.8443 62.7351C34.0259 62.791 33.2072 62.8482 32.3881 62.9066L32.3357 62.9112L32.288 62.9019Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.6984 41.1063C22.0301 41.1063 20.343 41.0047 18.7683 40.8956C15.9341 40.698 13.6548 40.2814 11.5972 39.5851C6.87572 37.9856 4.3213 34.6803 4.00317 29.7596C3.6448 24.2182 4.50563 19.5912 6.63245 15.612C8.28486 12.5211 10.4753 10.0911 13.1486 8.38901C15.7881 6.71121 18.918 5.72503 22.4521 5.46683C22.7515 5.44446 23.0668 5.43327 23.3878 5.43327C24.5415 5.43327 25.683 5.57309 26.7871 5.70732C27.4056 5.78282 27.9913 5.85366 28.578 5.90213C28.9233 5.93102 29.2536 5.98695 29.5736 6.04101C29.8953 6.1058 30.2216 6.14569 30.5495 6.16032C30.6422 6.16319 30.7348 6.15284 30.8246 6.12956C31.0622 6.055 31.4477 5.65605 31.7603 5.33633C31.9652 5.12381 32.1776 4.90476 32.4152 4.69597C34.2698 3.04612 36.8317 1.07097 40.202 0.438065C40.9053 0.304651 41.6195 0.236625 42.3354 0.234863C44.5267 0.234863 46.589 0.887344 48.4641 2.17087C50.6461 3.66225 51.5781 5.59733 51.2889 8.04879L53.9697 8.43748L52.3734 10.2402C52.3088 10.3129 52.2555 10.38 52.1984 10.4518C52.031 10.6799 51.8279 10.8798 51.5968 11.0437C45.7843 14.9502 42.2717 20.3443 40.8579 27.5347C40.588 28.8837 40.2444 30.2171 39.8287 31.5288C38.2595 36.5622 34.6609 39.6307 29.1338 40.6533C27.4889 40.9571 25.7111 41.1054 23.6984 41.1054V41.1063ZM17.6492 27.1618C16.7631 27.1483 15.8878 27.3574 15.1041 27.7696C14.2012 28.2645 13.6632 28.9813 13.5893 29.7801C13.5153 30.579 13.9121 31.3778 14.7074 32.0256C15.6431 32.7862 16.8155 33.1814 18.2939 33.2318C19.4167 33.1991 20.2813 32.9717 21.0251 32.5168C21.828 32.0275 22.308 31.3498 22.3772 30.6088C22.4465 29.8678 22.0965 29.1174 21.4004 28.4864C20.4759 27.6568 19.0733 27.1618 17.6492 27.1618ZM40.755 7.02812C41.9845 7.14277 43.2411 7.26115 44.4828 7.39351C44.465 6.98338 44.2545 6.77832 43.7342 6.563C43.3842 6.41551 43.0082 6.33914 42.6282 6.33836C41.943 6.34431 41.281 6.58675 40.755 7.0244V7.02812Z"
            fill="#1E1E1E"
        />
        <path
            d="M54.9896 55.597C54.9896 55.0862 54.9896 54.5775 54.9896 54.0711C54.9896 52.2684 54.9896 50.4051 55.0037 48.5809C55.0037 48.0217 55.2844 47.5901 55.4509 47.3328L55.494 47.2648L55.7232 46.8994C56.1602 46.2003 56.613 45.477 57.094 44.7732C58.476 42.7533 58.8718 40.3923 58.3197 37.5428C58.112 36.483 57.8229 35.4446 57.5169 34.3457C57.3485 33.7417 57.1744 33.1181 57.0173 32.4935C56.8629 31.8839 56.8413 31.3648 56.9527 30.909C57.6264 28.1173 58.331 25.2855 59.0112 22.5469L59.3855 21.0556C59.4446 20.8276 59.5575 20.617 59.7148 20.4413C59.727 20.4273 59.7429 20.4087 59.7626 20.3816L61.3008 18.3627L62.0737 20.7769C62.1897 21.1413 62.3067 21.4974 62.4208 21.8506C62.6735 22.6271 62.913 23.3607 63.1394 24.1185C64.7011 29.3383 65.8108 34.1518 66.5332 38.8338C67.6457 46.0568 64.0461 53.1408 57.5684 56.4573C57.3897 56.5505 57.2128 56.627 57.0566 56.6959L56.8694 56.7817L54.998 57.6402L54.9896 55.597Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2898 62.8759C27.3541 62.8489 20.8875 62.5515 14.6418 60.4831C11.5981 59.4765 9.08294 58.164 6.94397 56.4583C4.17341 54.2547 2.46485 51.19 1.71817 47.0868C1.08939 43.6305 1.1446 40.0027 1.89221 35.6618C1.97455 35.1865 2.23747 34.8509 2.39467 34.6496C2.42087 34.6169 2.45643 34.5713 2.46765 34.5563L3.22275 33.38L4.35212 34.1965C4.37458 34.2105 4.39891 34.2236 4.42323 34.2366C4.65996 34.3699 5.10254 34.6188 5.3243 35.152C6.47893 37.925 8.69838 39.8358 12.3082 41.165C14.6035 42.0105 17.1738 42.4859 20.1633 42.6191C21.8794 42.6956 23.7021 42.7702 25.4818 42.7702C28.0642 42.7702 30.1948 42.6164 32.1887 42.2864C32.4432 42.2444 32.7015 42.1932 32.9756 42.1354L33.2208 42.085C33.4633 42.0342 33.7103 42.0077 33.9581 42.0058C34.5354 42.0058 35.9231 42.2053 36.3245 44.0565C36.6052 45.3661 36.8915 46.6962 37.1666 47.9835C37.1947 48.1196 37.2246 48.2556 37.2536 48.3927C37.2885 48.0198 37.3225 47.647 37.3556 47.2741C37.5642 45.0016 37.7795 42.6508 37.9975 40.3392C38.0414 39.8778 38.1369 39.1946 38.4653 38.5859C39.9624 35.8259 40.827 32.8683 41.7402 29.7364C41.8618 29.3188 41.9844 28.9012 42.1079 28.4836C43.1587 24.9416 44.1664 21.7593 45.8638 18.7514C46.9192 16.8797 48.0233 15.5449 49.3389 14.5475C50.618 13.5781 51.9991 13.0254 53.4428 12.9042C53.6543 12.8865 53.8611 12.8772 54.0585 12.8772C56.6204 12.8772 58.1437 14.3583 58.3486 17.0475C58.5357 19.4934 58.0483 21.7966 57.4943 23.9209C57.2333 24.9229 56.9329 25.9222 56.6429 26.8878C56.1086 28.6672 55.6033 30.3469 55.3104 32.0741C54.6891 35.7271 54.3748 39.4714 54.1343 42.9734C53.9537 45.5572 53.9144 48.2128 53.8751 50.7808C53.8645 51.519 53.8523 52.2569 53.8386 52.9945C53.7956 55.268 52.843 57.0725 51.0091 58.357C47.879 60.5756 44.2153 61.9295 40.39 62.2812C38.5392 62.4536 36.6613 62.5823 34.8442 62.7062C34.0264 62.7622 33.2086 62.8193 32.3908 62.8778H32.3384L32.2898 62.8759Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.7078 41.0792C22.0404 41.0792 20.3552 40.9776 18.7823 40.8686C15.95 40.671 13.6735 40.2543 11.6169 39.558C6.90008 37.9585 4.34753 34.6532 4.03033 29.7344C3.6729 24.1921 4.53186 19.5651 6.65774 15.5859C8.30828 12.495 10.4987 10.0649 13.1673 8.36289C15.805 6.68508 18.9311 5.6989 22.4605 5.44071C22.759 5.41833 23.0743 5.40715 23.3962 5.40715C24.548 5.40715 25.6886 5.54697 26.7918 5.68119C27.4103 5.75669 27.9942 5.82753 28.5808 5.876C28.9261 5.9049 29.2564 5.96083 29.5755 6.01489C29.8972 6.07968 30.2235 6.11957 30.5514 6.1342C30.6438 6.13702 30.7361 6.12666 30.8255 6.10344C31.0632 6.02887 31.4487 5.62993 31.7612 5.31021C31.9661 5.09769 32.1785 4.87864 32.4162 4.66985C34.2689 3.02 36.8279 1.04485 40.1955 0.411941C40.9003 0.27817 41.616 0.210142 42.3335 0.20874C44.523 0.20874 46.5834 0.861221 48.4576 2.14474C50.6312 3.6352 51.5622 5.5712 51.2712 8.02267L53.95 8.41136L52.3594 10.2141C52.2958 10.2868 52.2415 10.3539 52.1844 10.4257C52.0176 10.654 51.8148 10.8539 51.5837 11.0176C45.774 14.9241 42.2643 20.3201 40.8523 27.5085C40.5815 28.8574 40.238 30.1907 39.8231 31.5027C38.2549 36.5361 34.66 39.6046 29.1366 40.6271C27.4936 40.931 25.7176 41.0792 23.7097 41.0792H23.7078ZM17.6642 27.1357C16.7787 27.1222 15.904 27.3312 15.121 27.7434C14.219 28.2384 13.681 28.9552 13.6071 29.754C13.5331 30.5528 13.9299 31.3517 14.7252 31.9995C15.6609 32.7601 16.8314 33.1553 18.3089 33.2056C19.4261 33.173 20.2935 32.9446 21.0373 32.4907C21.8383 32.0013 22.3183 31.3237 22.3875 30.5827C22.4568 29.8416 22.1068 29.0913 21.4125 28.4602C20.4872 27.6307 19.0836 27.1357 17.6642 27.1357ZM40.7494 7.002C41.977 7.11665 43.2327 7.23503 44.4734 7.36739C44.4557 6.95726 44.2461 6.75219 43.7249 6.53687C43.3755 6.38958 43.0001 6.31321 42.6208 6.31223C41.936 6.31807 41.2746 6.56056 40.7494 6.99827V7.002Z"
            fill="#1E1E1E"
        />
        <path
            d="M54.9709 55.5709C54.9709 55.0601 54.9709 54.5515 54.9709 54.0451C54.9709 52.2424 54.9709 50.3791 54.9849 48.5549C54.9896 47.9956 55.2656 47.5641 55.4322 47.3068L55.4752 47.2388L55.7035 46.8734C56.1405 46.1743 56.5924 45.451 57.0734 44.7472C58.4535 42.7273 58.8512 40.3663 58.2972 37.5168C58.0905 36.457 57.8013 35.4186 57.4954 34.3196C57.3279 33.7156 57.1538 33.092 56.9966 32.4675C56.8423 31.8579 56.8207 31.3387 56.9321 30.8829C57.6048 28.0866 58.3094 25.2595 58.9906 22.5209L59.3649 21.0295C59.4228 20.8009 59.5351 20.5896 59.6924 20.4134C59.7045 20.3994 59.7204 20.3808 59.741 20.3537L61.2811 18.3311L62.0531 20.7452C62.1691 21.1097 62.2851 21.4658 62.4002 21.819C62.6529 22.5955 62.8915 23.3291 63.1179 24.0869C64.6777 29.3067 65.7865 34.1202 66.5088 38.8022C67.6232 46.0298 64.0236 53.1139 57.5524 56.4304C57.3728 56.5236 57.1959 56.6 57.0397 56.669L56.8526 56.7547L54.9812 57.6132L54.9709 55.5709Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2909 62.85C27.3598 62.823 20.8989 62.5256 14.6598 60.4573C11.6216 59.4506 9.10554 58.1344 6.96844 56.4324C4.20068 54.2289 2.49306 51.1641 1.74732 47.0609C1.11947 43.6046 1.17468 39.9768 1.92136 35.636C2.0037 35.1606 2.26662 34.825 2.42382 34.6237C2.45002 34.5911 2.48557 34.5454 2.4968 34.5305L3.25096 33.3541L4.37846 34.1707C4.40092 34.1847 4.42525 34.1977 4.44958 34.2108C4.68724 34.344 5.12888 34.5929 5.35064 35.1261C6.50434 37.8991 8.71911 39.81 12.328 41.1392C14.6214 41.9846 17.188 42.46 20.1766 42.5933C21.8898 42.6697 23.7116 42.7443 25.4894 42.7443C28.0691 42.7443 30.1987 42.5905 32.1898 42.2605C32.4453 42.2186 32.7035 42.1673 32.9767 42.1095L33.2219 42.0592C33.4641 42.0083 33.7108 41.9818 33.9583 41.9799C34.5356 41.9799 35.9232 42.1794 36.3227 44.0306C36.6034 45.3402 36.8898 46.6703 37.1649 47.9576C37.1929 48.0937 37.2219 48.2298 37.2519 48.3668C37.2856 47.9939 37.3196 47.6211 37.3539 47.2483C37.5616 44.9758 37.7768 42.625 37.9948 40.3133C38.0388 39.8519 38.1342 39.1687 38.4627 38.56C39.9597 35.8 40.8224 32.8424 41.7375 29.7105C41.8623 29.2929 41.9846 28.8753 42.1043 28.4577C43.1542 24.9157 44.1628 21.7334 45.8564 18.7255C46.9109 16.8538 48.0151 15.519 49.3288 14.5217C50.6069 13.5523 51.987 12.9995 53.4289 12.8784C53.6404 12.8606 53.8472 12.8513 54.0446 12.8513C56.6046 12.8513 58.1261 14.3325 58.33 17.0216C58.5172 19.4675 58.0297 21.7707 57.4767 23.895C57.2166 24.8971 56.9153 25.8963 56.6262 26.8619C56.0919 28.6414 55.5876 30.321 55.2947 32.0482C54.6743 35.7012 54.359 39.4455 54.1195 42.9475C53.9398 45.5313 53.9005 48.1869 53.8612 50.7549C53.8512 51.4931 53.8391 52.231 53.8247 52.9687C53.7807 55.2421 52.8301 57.0467 50.9971 58.3311C47.7942 60.5775 44.2255 61.8983 40.3883 62.2553C38.5384 62.4278 36.6624 62.5564 34.8481 62.6804C34.0303 62.7363 33.2131 62.7935 32.3966 62.8519H32.3442L32.2909 62.85Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.7171 41.053C22.0516 41.053 20.3674 40.9514 18.7963 40.8424C15.9659 40.6448 13.6913 40.2281 11.6374 39.5318C6.92438 37.937 4.37464 34.627 4.05744 29.7064C3.70001 24.1659 4.55897 19.5389 6.68204 15.5597C8.33165 12.4688 10.5183 10.0387 13.186 8.3367C15.8209 6.65889 18.9451 5.67271 22.4708 5.41452C22.7693 5.39215 23.0846 5.38096 23.4065 5.38096C24.5574 5.38096 25.697 5.52078 26.7993 5.655C27.4168 5.7305 28.0007 5.80134 28.5864 5.84981C28.9317 5.87871 29.261 5.93464 29.5801 5.9887C29.9015 6.05349 30.2275 6.09338 30.5551 6.10801C30.6475 6.11083 30.7398 6.10047 30.8293 6.07725C31.0669 6.00268 31.4515 5.60374 31.7649 5.28402C31.9699 5.0715 32.1823 4.85245 32.4199 4.64366C34.2716 2.99381 36.8279 1.01866 40.1926 0.385752C40.8942 0.25371 41.6065 0.186932 42.3204 0.186279C44.508 0.186279 46.5665 0.83876 48.4388 2.12228C50.6162 3.61367 51.5462 5.54874 51.2571 8.00021L53.9341 8.3889L52.3434 10.1916C52.2798 10.2643 52.2265 10.3314 52.1694 10.4032C52.0026 10.6316 51.7998 10.8315 51.5687 10.9951C45.7675 14.9016 42.2605 20.2957 40.8495 27.4861C40.5792 28.835 40.2356 30.1684 39.8202 31.4802C38.2539 36.5136 34.6618 39.5822 29.1441 40.6047C27.502 40.9086 25.7279 41.0568 23.7171 41.0568V41.053ZM17.6801 27.1095C16.7955 27.0961 15.9217 27.3052 15.1397 27.7172C14.2386 28.2122 13.7015 28.929 13.6276 29.7278C13.5537 30.5266 13.9495 31.3255 14.7439 31.9733C15.6796 32.7339 16.8483 33.1291 18.3248 33.1794C19.441 33.1468 20.3084 32.9185 21.0504 32.4645C21.8514 31.9752 22.3304 31.2975 22.3997 30.5565C22.4689 29.8154 22.119 29.0651 21.4256 28.434C20.5002 27.6045 19.1004 27.1095 17.6801 27.1095ZM40.7447 6.97581C41.9695 7.08394 43.2243 7.20511 44.464 7.33747C44.4463 6.92734 44.2367 6.72227 43.7155 6.50696C43.3661 6.35967 42.9908 6.2833 42.6114 6.28232C41.9271 6.28954 41.2666 6.53336 40.7428 6.97208L40.7447 6.97581Z"
            fill="#1E1E1E"
        />
        <path
            d="M54.9521 55.5447C54.9521 55.0339 54.9521 54.5252 54.9521 54.0188C54.9521 52.2161 54.9521 50.3528 54.9662 48.5286C54.9662 47.9694 55.2469 47.5378 55.4125 47.2805L55.4555 47.2125L55.6838 46.8471C56.1208 46.148 56.5718 45.4247 57.0528 44.7209C58.432 42.701 58.8306 40.34 58.2757 37.4905C58.068 36.4307 57.7798 35.3923 57.4738 34.2934C57.3063 33.6893 57.1332 33.0658 56.9751 32.4412C56.8216 31.8316 56.8011 31.3125 56.9115 30.8566C57.5833 28.065 58.2869 25.2332 58.97 22.4946L59.3443 21.0033C59.4036 20.7756 59.5161 20.5651 59.6727 20.389C59.6848 20.375 59.7007 20.3564 59.7213 20.3293L61.2605 18.3066L62.0315 20.7208C62.1476 21.0853 62.2636 21.4413 62.3787 21.7946C62.6304 22.5711 62.869 23.3046 63.0954 24.0625C64.6543 29.2823 65.7621 34.0957 66.4826 38.7778C67.5961 46.0054 64.0002 53.0895 57.5346 56.4059C57.3559 56.4992 57.1791 56.5756 57.0228 56.6446L56.8357 56.7303L54.9643 57.5888L54.9521 55.5447Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2926 62.8245C27.3653 62.7974 20.9109 62.5001 14.6765 60.4317C11.6411 59.425 9.12786 58.1079 6.99263 56.4068C4.22768 54.2024 2.52193 51.1376 1.77338 47.0344C1.14554 43.5781 1.20074 39.9503 1.94742 35.6095C2.02882 35.1341 2.29175 34.7985 2.44895 34.5972C2.47514 34.5646 2.5107 34.5189 2.52193 34.504L3.27048 33.3276L4.39798 34.1442C4.42043 34.1582 4.44476 34.1712 4.46815 34.1843C4.70582 34.3176 5.14652 34.5664 5.36828 35.0996C6.52105 37.8726 8.73675 39.7835 12.3401 41.1127C14.6306 41.9581 17.1963 42.4335 20.1811 42.5668C21.8934 42.6432 23.7133 42.7178 25.4892 42.7178C28.0671 42.7178 30.1948 42.564 32.1841 42.234C32.4386 42.1911 32.6968 42.1408 32.97 42.083L33.2152 42.0327C33.4571 41.9819 33.7035 41.9553 33.9506 41.9534C34.527 41.9534 35.9156 42.1529 36.3123 44.0041C36.593 45.3137 36.8793 46.6439 37.1544 47.9311L37.2414 48.3403C37.2764 47.9675 37.3107 47.5946 37.3444 47.2218C37.5521 44.9493 37.7673 42.5985 37.9853 40.2868C38.0284 39.8254 38.1238 39.1422 38.4532 38.5335C39.9503 35.7735 40.8101 32.8159 41.7234 29.684C41.8481 29.2664 41.9707 28.8488 42.0911 28.4312C43.1391 24.8892 44.1496 21.707 45.8395 18.699C46.893 16.8273 47.9915 15.4925 49.308 14.4952C50.5852 13.5258 51.9635 12.973 53.4045 12.8519C53.6159 12.8342 53.8227 12.8248 54.0201 12.8248C56.5774 12.8248 58.0979 14.306 58.3018 16.9951C58.489 19.441 58.0015 21.7442 57.4494 23.8685C57.1884 24.8706 56.888 25.8698 56.5989 26.8355C56.0655 28.6149 55.5621 30.2945 55.2693 32.0218C54.6489 35.6747 54.3383 39.419 54.095 42.921C53.9153 45.5048 53.876 48.1604 53.8367 50.7284C53.8255 51.4666 53.8152 52.2049 53.8003 52.9422C53.7563 55.2156 52.8066 57.0192 50.9754 58.3046C47.7754 60.551 44.2095 61.8718 40.376 62.2288C38.528 62.4013 36.6529 62.5299 34.8405 62.6539C34.0233 62.7098 33.2068 62.767 32.3909 62.8254H32.3385L32.2926 62.8245Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.7265 41.027C22.0619 41.027 20.3795 40.9254 18.8095 40.8163C15.9827 40.6187 13.71 40.202 11.6571 39.5058C6.94871 37.9062 4.40084 34.601 4.08365 29.6803C3.72715 24.1398 4.58517 19.5128 6.70356 15.5336C8.3513 12.4427 10.5399 10.0127 13.2019 8.31064C15.8349 6.63283 18.9554 5.64666 22.4783 5.38846C22.7768 5.36609 23.0911 5.3549 23.414 5.3549C24.5639 5.3549 25.7026 5.49472 26.803 5.62895C27.4206 5.70445 28.0044 5.77529 28.5902 5.82376C28.9345 5.85265 29.2639 5.90858 29.582 5.96264C29.9031 6.02737 30.2287 6.06726 30.556 6.08195C30.6484 6.08477 30.7407 6.07442 30.8302 6.05119C31.0679 5.97662 31.4515 5.57768 31.7603 5.25796C31.9652 5.04544 32.1766 4.82639 32.4152 4.6176C34.2651 2.96776 36.8195 0.992602 40.1814 0.359695C40.885 0.225951 41.5995 0.157922 42.3157 0.156494C44.5015 0.156494 46.5581 0.808975 48.4285 2.0925C50.604 3.58388 51.5341 5.51896 51.2449 7.97042L53.9191 8.35911L52.3285 10.1618C52.2649 10.2345 52.2106 10.3016 52.1535 10.3734C51.987 10.6017 51.7845 10.8016 51.5537 10.9653C45.7525 14.8718 42.254 20.266 40.8439 27.4563C40.5736 28.8053 40.23 30.1386 39.8146 31.4504C38.2492 36.4838 34.6599 39.5524 29.1478 40.5749C27.5076 40.8788 25.7335 41.027 23.7274 41.027H23.7265ZM17.6932 27.0835C16.8088 27.0699 15.9354 27.2789 15.1537 27.6912C14.2536 28.1861 13.7175 28.9029 13.6426 29.7018C13.5677 30.5006 13.9654 31.2994 14.7589 31.9472C15.6946 32.7078 16.8604 33.1031 18.336 33.1534C19.4513 33.1208 20.3178 32.8924 21.0598 32.4385C21.8598 31.9491 22.3379 31.2715 22.4072 30.5304C22.4764 29.7894 22.1265 29.039 21.434 28.408C20.5124 27.5784 19.1135 27.0835 17.6932 27.0835ZM40.7372 6.94975C41.963 7.0644 43.2158 7.18278 44.4547 7.31514C44.4369 6.90501 44.2273 6.69995 43.7061 6.48463C43.3574 6.33727 42.9827 6.26089 42.6039 6.25999C41.9207 6.26621 41.2608 6.50871 40.7372 6.94602V6.94975Z"
            fill="#1E1E1E"
        />
        <path
            d="M54.9325 55.5187V53.9928C54.9325 52.1901 54.9325 50.3268 54.9456 48.5026C54.9512 47.9434 55.2263 47.5118 55.3919 47.2545L55.435 47.1865L55.6633 46.8211C56.0993 46.122 56.5512 45.3987 57.0303 44.6949C58.4086 42.675 58.8081 40.314 58.2532 37.4645C58.0455 36.4047 57.7573 35.3663 57.4523 34.2674C57.2848 33.6633 57.1117 33.0398 56.9536 32.4152C56.8001 31.8056 56.7795 31.2865 56.8899 30.8306C57.5618 28.0343 58.2645 25.2072 58.9485 22.4686L59.3227 20.9773C59.3812 20.7492 59.4938 20.5386 59.6512 20.363C59.6633 20.349 59.6792 20.3304 59.6989 20.3033L61.2362 18.2806L62.0072 20.6948C62.1232 21.0583 62.2393 21.4153 62.3534 21.7686C62.606 22.5451 62.8437 23.2786 63.0701 24.0365C64.6271 29.2563 65.734 34.0697 66.4545 38.7518C67.567 45.9794 63.974 53.0635 57.514 56.3799C57.3353 56.4732 57.1594 56.5496 57.0032 56.6186L56.816 56.7043L54.9447 57.5628L54.9325 55.5187Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2946 62.7975C27.3719 62.7705 20.9232 62.4731 14.6944 60.4048C11.6618 59.3981 9.15042 58.081 7.02174 56.3799C4.25866 54.1764 2.55478 51.1116 1.80998 47.0084C1.18307 43.5521 1.23827 39.9243 1.98401 35.5835C2.06542 35.1081 2.32835 34.7725 2.48461 34.5712C2.5108 34.5386 2.54636 34.4929 2.55759 34.478L3.31082 33.3016L4.43364 34.1182C4.45609 34.1322 4.48042 34.1452 4.50475 34.1583C4.74148 34.2916 5.18218 34.5404 5.40394 35.0736C6.55483 37.8466 8.77241 39.7575 12.3692 41.0867C14.6579 41.9321 17.2207 42.4075 20.2027 42.5408C21.9132 42.6172 23.7321 42.6918 25.5062 42.6918C28.0821 42.6918 30.2071 42.538 32.1954 42.208C32.4499 42.1651 32.7072 42.1148 32.9804 42.057L33.2256 42.0067C33.4671 41.9558 33.7132 41.9293 33.9601 41.9274C34.5365 41.9274 35.9194 42.1269 36.3199 43.9781C36.6006 45.2877 36.886 46.6179 37.162 47.9051L37.249 48.3143C37.284 47.9415 37.3183 47.5686 37.352 47.1958C37.5597 44.9233 37.774 42.5725 37.992 40.2608C38.035 39.7994 38.1304 39.1162 38.4598 38.5075C39.9569 35.7475 40.8159 32.7899 41.7282 29.658C41.8498 29.2404 41.9721 28.8228 42.0949 28.4052C43.1429 24.8632 44.1478 21.681 45.8377 18.673C46.8913 16.8013 47.9898 15.4665 49.2997 14.4692C50.576 13.4998 51.9533 12.947 53.3933 12.8259C53.6039 12.8082 53.8107 12.7988 54.0081 12.7988C56.5625 12.7988 58.0821 14.28 58.286 16.9682C58.4732 19.415 57.9857 21.722 57.4336 23.8425C57.1735 24.8446 56.8722 25.8429 56.585 26.8095C56.0516 28.5889 55.5482 30.2685 55.2554 31.9948C54.6359 35.6487 54.3262 39.393 54.0829 42.895C53.9033 45.4788 53.8631 48.1344 53.8247 50.7024C53.8135 51.4406 53.8032 52.1789 53.7882 52.9162C53.7442 55.1896 52.7945 56.9932 50.9662 58.2786C47.7689 60.525 44.2059 61.8458 40.3761 62.2028C38.53 62.3753 36.6567 62.5039 34.8453 62.6279C34.0293 62.6838 33.2137 62.741 32.3984 62.7994H32.346L32.2946 62.7975Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.7349 41.0011C22.0732 41.0011 20.3917 40.8995 18.8235 40.7904C15.9987 40.5928 13.7278 40.1762 11.6777 39.4799C6.97308 37.8804 4.42708 34.5751 4.11269 29.6545C3.75619 24.114 4.61328 19.4869 6.73261 15.5077C8.37941 12.4168 10.5689 9.98681 13.2253 8.28476C15.8555 6.60696 18.9742 5.62078 22.4886 5.36258C22.7871 5.34021 23.1015 5.32902 23.4243 5.32902C24.5733 5.32902 25.7111 5.46884 26.8105 5.60307C27.4281 5.67857 28.011 5.74941 28.5958 5.79788C28.9401 5.82677 29.2695 5.8827 29.5876 5.93676C29.9083 6.00252 30.2337 6.04335 30.5608 6.05887C30.6531 6.06176 30.7455 6.0514 30.8349 6.02811C31.0726 5.95354 31.4562 5.5546 31.765 5.23488C31.969 5.02236 32.1804 4.80331 32.4143 4.59452C34.2623 2.94467 36.8121 0.969519 40.173 0.336613C40.8759 0.201911 41.5898 0.132945 42.3055 0.130615C44.4894 0.130615 46.5441 0.783096 48.4127 2.06662C50.5863 3.558 51.5154 5.49308 51.2263 7.94454L53.8986 8.33323L52.3079 10.1359C52.2443 10.2087 52.191 10.2758 52.1339 10.3475C51.9674 10.5758 51.7649 10.7757 51.5341 10.9394C45.7432 14.8459 42.2428 20.2401 40.8346 27.4304C40.5639 28.7793 40.2203 30.1126 39.8053 31.4245C38.2418 36.458 34.6591 39.5265 29.1479 40.549C27.5095 40.8529 25.7373 41.0011 23.7331 41.0011H23.7349ZM17.7082 27.0576C16.8248 27.0442 15.9522 27.2532 15.1715 27.6653C14.2724 28.1603 13.7362 28.8771 13.6623 29.6759C13.5884 30.4747 13.9842 31.2735 14.7767 31.9214C15.7077 32.682 16.8773 33.0772 18.351 33.1275C19.4654 33.0949 20.3309 32.8665 21.072 32.4126C21.871 31.9232 22.3501 31.2456 22.4184 30.5045C22.4867 29.7635 22.1424 29.0132 21.4462 28.3821C20.5246 27.5525 19.1276 27.0576 17.7082 27.0576ZM40.7307 6.92387C41.9555 7.03853 43.2075 7.1569 44.4454 7.28926C44.4276 6.87913 44.218 6.67407 43.6968 6.45875C43.3484 6.31149 42.974 6.23511 42.5955 6.23411C41.9129 6.24053 41.2537 6.48304 40.7307 6.92015V6.92387Z"
            fill="#1E1E1E"
        />
        <path
            d="M54.9138 55.4924C54.9138 54.9816 54.9138 54.473 54.9138 53.9665C54.9138 52.1638 54.9138 50.3005 54.9278 48.4764C54.9334 47.9171 55.2085 47.4855 55.3741 47.2283L55.4172 47.1602L55.6445 46.7948C56.0806 46.0958 56.5316 45.3724 57.0106 44.6687C58.388 42.6488 58.7884 40.2877 58.227 37.4383C58.0202 36.3785 57.732 35.3401 57.427 34.2411C57.2595 33.6371 57.0864 33.0135 56.9292 32.389C56.7758 31.7794 56.7552 31.2602 56.8656 30.8044C57.5365 28.0127 58.2382 25.1809 58.9175 22.4424L59.2918 20.951C59.3504 20.7232 59.4627 20.5127 59.6193 20.3367C59.6315 20.3228 59.6474 20.3041 59.667 20.2771L61.2034 18.2544L61.9735 20.6686C62.0895 21.0321 62.2055 21.3891 62.3197 21.7424C62.5714 22.5188 62.81 23.2524 63.0355 24.0102C64.5915 29.2301 65.6975 34.0435 66.4171 38.7246C67.5277 45.9531 63.9375 53.0372 57.4841 56.3528C57.3054 56.446 57.1285 56.5224 56.9732 56.5914L56.7861 56.6771L54.9147 57.5356L54.9138 55.4924Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2955 62.7715C27.3776 62.7445 20.9344 62.4471 14.7112 60.3788C11.6814 59.3721 9.17288 58.055 7.03858 56.3539C4.28112 54.1504 2.57817 51.0856 1.8343 46.9787C1.21207 43.5299 1.2626 39.8946 2.00741 35.5575C2.08975 35.0821 2.35174 34.7465 2.50893 34.5452C2.5342 34.5126 2.56975 34.4669 2.58098 34.452L3.32953 33.2756L4.45235 34.0922C4.47481 34.1062 4.50007 34.1192 4.52346 34.1323C4.76019 34.2656 5.2009 34.5144 5.42172 35.0476C6.57261 37.8206 8.78363 39.7315 12.3813 41.0607C14.6682 41.9061 17.2282 42.3815 20.2083 42.5148C21.9169 42.5912 23.734 42.6658 25.5071 42.6658C28.0793 42.6658 30.2033 42.512 32.1898 42.182C32.4434 42.1391 32.7007 42.0888 32.973 42.031L33.2181 41.9807C33.4597 41.9298 33.7057 41.9033 33.9526 41.9014C34.5281 41.9014 35.9101 42.1009 36.3105 43.9521C36.5912 45.2617 36.872 46.5919 37.1527 47.8791L37.2397 48.2883C37.2746 47.9155 37.3086 47.5426 37.3417 47.1698C37.5494 44.8973 37.7637 42.5465 37.9817 40.2348C38.0247 39.7734 38.1202 39.0902 38.4495 38.4815C39.9419 35.7215 40.8028 32.7639 41.7141 29.632C41.8389 29.2144 41.9611 28.7968 42.0809 28.3792C43.1279 24.8372 44.1319 21.655 45.8236 18.647C46.8754 16.7753 47.9757 15.4405 49.2857 14.4432C50.5601 13.4738 51.9365 12.921 53.3746 12.7999C53.5852 12.7821 53.7919 12.7728 53.9884 12.7728C56.541 12.7728 58.0596 14.254 58.2626 16.9422C58.4498 19.389 57.9632 21.696 57.4121 23.8165C57.152 24.8186 56.8507 25.8169 56.5634 26.7835C56.031 28.5629 55.5276 30.2425 55.2357 31.9688C54.6172 35.6227 54.3066 39.367 54.0642 42.869C53.8846 45.4528 53.8443 48.1084 53.806 50.6764C53.7947 51.4146 53.7826 52.1525 53.7695 52.8902C53.7255 55.1636 52.7767 56.9672 50.9493 58.2526C47.7577 60.4944 44.1974 61.8142 40.3714 62.1722C38.5262 62.3446 36.6549 62.4732 34.8453 62.5972C34.0306 62.6531 33.2156 62.7103 32.4003 62.7687H32.3479L32.2955 62.7715Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.7442 40.9748C22.0833 40.9748 20.4038 40.8732 18.8365 40.7642C16.0145 40.5666 13.7464 40.1499 11.6972 39.4536C6.99729 37.8541 4.4541 34.5488 4.13784 29.6282C3.78134 24.0877 4.63843 19.4607 6.75775 15.4815C8.40362 12.3906 10.5856 9.96056 13.2449 8.25852C15.8732 6.58071 18.9881 5.59453 22.5081 5.33634C22.8057 5.31397 23.1201 5.30278 23.4438 5.30278C24.5928 5.30278 25.7288 5.4426 26.8273 5.57682C27.4439 5.65232 28.0259 5.72316 28.6107 5.77163C28.955 5.80053 29.2834 5.85646 29.6016 5.91052C29.922 5.97529 30.2471 6.01518 30.5737 6.02983C30.6658 6.03266 30.7578 6.02231 30.847 5.99907C31.0837 5.9245 31.4673 5.52556 31.7761 5.20584C31.9801 4.99332 32.1915 4.77427 32.4255 4.56548C34.2716 2.91563 36.8232 0.940478 40.1767 0.307571C40.8759 0.174345 41.586 0.106317 42.2979 0.10437C44.4799 0.10437 46.5328 0.756851 48.3995 2.04037C50.5712 3.53176 51.4994 5.46683 51.2112 7.9183L53.8798 8.30699L52.2891 10.1097C52.2245 10.1824 52.1712 10.2495 52.1151 10.3213C51.9486 10.5496 51.746 10.7495 51.5153 10.9132C45.7309 14.8206 42.2342 20.2138 40.827 27.4042C40.5582 28.753 40.2161 30.0863 39.8024 31.3983C38.2407 36.4317 34.6561 39.5002 29.1552 40.5228C27.5178 40.8266 25.7475 40.9748 23.7451 40.9748H23.7442ZM17.7221 27.0313C16.8397 27.0182 15.9683 27.2269 15.1883 27.6381C14.29 28.1331 13.7539 28.8499 13.6799 29.6496C13.606 30.4494 14.0018 31.2473 14.7943 31.8951C15.7244 32.6557 16.8922 33.0509 18.3649 33.1013C19.4784 33.0686 20.343 32.8403 21.084 32.3863C21.8822 31.897 22.3603 31.2193 22.4286 30.4783C22.4969 29.7373 22.1526 28.9869 21.4574 28.3559C20.5366 27.5263 19.1406 27.0313 17.7221 27.0313ZM40.725 6.89763C41.9479 7.01228 43.1999 7.13066 44.435 7.26302C44.4181 6.85289 44.2085 6.64782 43.6864 6.4325C43.3383 6.28534 42.9642 6.20896 42.586 6.20786C41.9042 6.21581 41.2463 6.45964 40.725 6.89763Z"
            fill="#1E1E1E"
        />
        <path
            d="M54.8951 55.4665C54.8951 54.9557 54.8951 54.4471 54.8951 53.9407C54.8951 52.138 54.8951 50.2747 54.9091 48.4505C54.9147 47.8912 55.1898 47.4597 55.3545 47.2024L55.3976 47.1344L55.6249 46.769C56.061 46.0699 56.511 45.3466 56.9901 44.6428C58.3656 42.6229 58.7679 40.2619 58.2065 37.4124C57.9997 36.3526 57.7124 35.3142 57.4074 34.2152C57.2399 33.6112 57.0668 32.9876 56.9096 32.3631C56.7562 31.7535 56.7356 31.2343 56.846 30.7785C57.516 27.9868 58.2177 25.1551 58.8961 22.4165L59.2647 20.9251C59.3234 20.6974 59.4356 20.4868 59.5922 20.3109C59.6044 20.2969 59.6203 20.2782 59.6409 20.2512L61.1754 18.2285L61.9445 20.6427C62.0606 21.0062 62.1766 21.3632 62.2908 21.7165C62.5425 22.4929 62.7801 23.2265 63.0056 23.9843C64.5598 29.2042 65.6648 34.0176 66.3844 38.6987C67.495 45.9273 63.9086 53.0113 57.4598 56.3278C57.282 56.421 57.1052 56.4975 56.9499 56.5664L56.7627 56.6522L54.8914 57.5107L54.8951 55.4665Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2973 62.7454C27.384 62.7174 20.9465 62.421 14.7288 60.3526C11.7019 59.346 9.19521 58.0289 7.06559 56.3278C4.30813 54.1243 2.60612 51.0595 1.86693 46.9563C1.24095 43.5 1.29616 39.8722 2.04003 35.5313C2.12143 35.056 2.38436 34.7204 2.54062 34.5191C2.56682 34.4864 2.60237 34.4408 2.6136 34.4259L3.36215 33.2495L4.48497 34.0661C4.50743 34.08 4.53176 34.0931 4.55515 34.1061C4.79188 34.2394 5.23165 34.4883 5.45341 35.0215C6.60243 37.7945 8.81158 39.7054 12.4055 41.0346C14.6905 41.88 17.2486 42.3554 20.226 42.4887C21.9336 42.5651 23.7488 42.6397 25.5201 42.6397C28.0904 42.6397 30.2116 42.4859 32.1962 42.1559C32.4507 42.113 32.7071 42.0627 32.9794 42.0049L33.2245 41.9546C33.4658 41.9037 33.7115 41.8772 33.9581 41.8753C34.5335 41.8753 35.9146 42.0748 36.3142 43.926C36.5949 45.2356 36.8756 46.5657 37.1563 47.853L37.2433 48.2622C37.2782 47.8893 37.3122 47.5165 37.3453 47.1436C37.553 44.8711 37.7673 42.5203 37.9844 40.2087C38.0274 39.7473 38.1238 39.0641 38.4522 38.4554C39.9427 35.6954 40.8026 32.7378 41.714 29.6059C41.8356 29.1883 41.9576 28.7707 42.0798 28.3531C43.1259 24.8111 44.129 21.6288 45.8226 18.6209C46.8734 16.7492 47.9747 15.4144 49.2846 14.4171C50.559 13.4477 51.9335 12.8949 53.3698 12.7737C53.5803 12.756 53.7871 12.7467 53.9836 12.7467C56.5343 12.7467 58.051 14.2278 58.2541 16.9161C58.4412 19.3629 57.9547 21.6698 57.4035 23.7904C57.1434 24.7924 56.8421 25.7907 56.5558 26.7573C56.0243 28.5358 55.521 30.2164 55.229 31.9427C54.6115 35.5966 54.3018 39.3409 54.0585 42.8429C53.8798 45.4267 53.8395 48.0823 53.8012 50.6503C53.7899 51.3885 53.7778 52.1264 53.7647 52.864C53.7216 55.1375 52.7728 56.9411 50.9473 58.2265C47.7557 60.4729 44.1992 61.7937 40.3741 62.1507C38.5317 62.3231 36.6622 62.4518 34.8535 62.5757C34.0395 62.6317 33.2255 62.6888 32.4114 62.7473H32.359L32.2973 62.7454Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.7536 40.9487C22.0946 40.9487 20.417 40.8471 18.8506 40.7381C16.0314 40.5405 13.7642 40.1238 11.7179 39.4275C7.02169 37.828 4.48037 34.5227 4.16505 29.6021C3.80855 24.0616 4.6647 19.4346 6.78497 15.4554C8.42803 12.3645 10.6091 9.93444 13.2655 8.2324C15.891 6.55459 19.0031 5.56841 22.5166 5.31021C22.8142 5.28784 23.1286 5.27666 23.4523 5.27666C24.6004 5.27666 25.7354 5.41554 26.8329 5.5507C27.4486 5.6262 28.0306 5.69704 28.6107 5.74551C28.9551 5.77441 29.2835 5.83033 29.6007 5.8844C29.9209 5.94916 30.2456 5.98905 30.5719 6.00371C30.664 6.00654 30.756 5.99618 30.8452 5.97295C31.0819 5.89838 31.4655 5.49943 31.7734 5.17972C31.9773 4.96719 32.1888 4.74815 32.4218 4.53935C34.2623 2.88951 36.8101 0.914355 40.1618 0.279584C40.8629 0.146567 41.5749 0.0791607 42.2886 0.0782471C44.4678 0.0782471 46.5197 0.730728 48.3846 2.01425C50.5544 3.50564 51.4817 5.44071 51.1916 7.89217L53.8583 8.28087L52.2677 10.0836C52.204 10.1563 52.1507 10.2234 52.0936 10.2952C51.9277 10.5233 51.7258 10.7232 51.4957 10.8871C45.7141 14.7936 42.2203 20.1877 40.8149 27.3781C40.5461 28.7265 40.2044 30.0595 39.7912 31.3712C38.2305 36.4047 34.6506 39.4741 29.1534 40.4967C27.5169 40.8005 25.7485 40.9487 23.748 40.9487H23.7536ZM17.7372 27.0052C16.8554 26.992 15.9845 27.2007 15.2052 27.612C14.3079 28.107 13.7727 28.8238 13.6987 29.6235C13.6248 30.4233 14.0197 31.2212 14.8113 31.869C15.7404 32.6296 16.9081 33.0248 18.379 33.0751C19.4916 33.0425 20.3552 32.8142 21.0925 32.3602C21.8907 31.8709 22.3679 31.1932 22.4362 30.4522C22.5045 29.7111 22.1611 28.9608 21.4659 28.3297C20.5489 27.4974 19.1538 27.0052 17.7372 27.0052ZM40.7185 6.87151C41.9414 6.98616 43.1915 7.10454 44.4257 7.2369C44.4088 6.82677 44.1992 6.6217 43.6771 6.40638C43.3294 6.25905 42.9556 6.18266 42.5777 6.18174C41.8969 6.18884 41.2397 6.43134 40.7185 6.86778V6.87151Z"
            fill="#1E1E1E"
        />
        <path
            d="M54.8763 55.4405C54.8763 54.9297 54.8763 54.4211 54.8763 53.9147C54.8763 52.1119 54.8763 50.2487 54.8904 48.4245C54.896 47.8652 55.1711 47.4337 55.3358 47.1764L55.3779 47.1084L55.6062 46.743C56.0413 46.0439 56.4913 45.3206 56.9695 44.6168C58.344 42.5969 58.7473 40.2359 58.1859 37.3864C57.9791 36.3266 57.6918 35.2882 57.3868 34.1892C57.2202 33.5852 57.0471 32.9616 56.8899 32.3371C56.7365 31.7275 56.7159 31.2083 56.8263 30.7525C57.4963 27.9608 58.1971 25.1291 58.8745 22.3905L59.2432 20.8991C59.3018 20.6714 59.4141 20.4608 59.5707 20.2849C59.5828 20.2709 59.5987 20.2522 59.6184 20.2252L61.152 18.2025L61.9211 20.6167C62.0362 20.9802 62.1522 21.3372 62.2664 21.6905C62.5181 22.4669 62.7548 23.2005 62.9803 23.9583C64.5335 29.1782 65.6376 33.9916 66.3563 38.6727C67.4669 45.9003 63.8842 52.9844 57.4467 56.2999C57.268 56.3932 57.092 56.4696 56.9367 56.5386L56.7496 56.6243L54.8838 57.4828L54.8763 55.4405Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.2993 62.7192C27.3897 62.6912 20.9578 62.3948 14.7458 60.3264C11.7217 59.3197 9.21686 58.0027 7.08911 56.2997C4.33445 54.098 2.63431 51.0332 1.89232 46.9301C1.26634 43.4738 1.32155 39.846 2.06542 35.5051C2.14682 35.0297 2.40881 34.6942 2.56507 34.4928C2.59127 34.4602 2.62683 34.4145 2.63806 34.3996L3.38661 33.2233L4.50943 34.0398C4.53188 34.0538 4.55621 34.0669 4.58054 34.0799C4.81633 34.2132 5.2561 34.4621 5.47692 34.9952C6.62501 37.7683 8.83229 39.6791 12.4234 41.0083C14.7065 41.8538 17.2619 42.3291 20.2364 42.4624C21.9422 42.5389 23.7555 42.6134 25.5258 42.6134C28.0943 42.6134 30.2136 42.4596 32.1963 42.1297C32.4499 42.0868 32.7072 42.0364 32.9786 41.9787L33.2237 41.9283C33.4663 41.8781 33.7133 41.8525 33.961 41.8519C34.5355 41.8519 35.9157 42.0514 36.3143 43.9025C36.595 45.2122 36.8757 46.5423 37.1517 47.8295L37.2387 48.2387C37.2737 47.8659 37.3077 47.493 37.3407 47.1202C37.5475 44.8477 37.7618 42.4969 37.9789 40.1853C38.0219 39.7239 38.1183 39.0406 38.4467 38.432C39.9363 35.672 40.7962 32.7144 41.7057 29.5824C41.8273 29.1649 41.9493 28.7473 42.0716 28.3297C43.1167 24.7876 44.1188 21.6054 45.8068 18.5975C46.8566 16.7258 47.9589 15.391 49.2632 14.3936C50.5367 13.4242 51.9093 12.8715 53.3456 12.7503C53.5552 12.7326 53.762 12.7233 53.9585 12.7233C56.5064 12.7233 58.0212 14.2044 58.2252 16.8926C58.4123 19.3394 57.9258 21.6464 57.3756 23.767C57.1155 24.769 56.8142 25.7673 56.5288 26.7339C55.9964 28.5124 55.4996 30.193 55.203 31.9193C54.5854 35.5732 54.2757 39.3175 54.0333 42.8194C53.8546 45.4032 53.8144 48.0588 53.776 50.6268C53.7648 51.3651 53.7526 52.103 53.7395 52.8406C53.6965 55.114 52.7486 56.9177 50.925 58.2031C47.7362 60.4495 44.1825 61.7703 40.363 62.1273C38.5225 62.2997 36.6539 62.4283 34.8481 62.5523C34.034 62.6082 33.2203 62.6654 32.4069 62.7238H32.3545L32.2993 62.7192Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.762 40.9198C22.104 40.9198 20.4282 40.8182 18.8628 40.7092C16.0464 40.5115 13.782 40.0949 11.7366 39.3986C7.04604 37.8019 4.50753 34.4966 4.19126 29.576C3.83664 24.0355 4.69092 19.4084 6.80463 15.4292C8.44676 12.3383 10.625 9.90832 13.2824 8.20255C15.9023 6.52474 19.0153 5.53856 22.5251 5.28036C22.8226 5.25799 23.1361 5.24681 23.4608 5.24681C24.607 5.24681 25.741 5.38569 26.8376 5.52085C27.4533 5.59635 28.0344 5.66719 28.6154 5.71566C28.9588 5.74456 29.2873 5.80048 29.6045 5.85455C29.9243 5.91925 30.2487 5.95914 30.5748 5.97386C30.6668 5.97669 30.7589 5.96633 30.848 5.9431C31.0847 5.86853 31.4674 5.46958 31.7753 5.14987C31.9792 4.93734 32.1898 4.7183 32.4237 4.5095C34.267 2.85966 36.812 0.884504 40.1609 0.251597C40.8605 0.119381 41.5709 0.0525993 42.283 0.052124C44.4603 0.052124 46.5104 0.704605 48.3734 1.98813C50.5423 3.47951 51.4677 5.41459 51.1804 7.86605L53.8443 8.25474L52.2536 10.0575C52.19 10.1302 52.1367 10.1973 52.0805 10.269C51.9143 10.497 51.7125 10.6968 51.4826 10.8609C45.7067 14.7674 42.2194 20.1616 40.8158 27.3519C40.5474 28.7004 40.206 30.0334 39.7931 31.3451C38.2343 36.3785 34.6581 39.448 29.1647 40.4705C27.5291 40.7744 25.7625 40.9198 23.762 40.9198ZM17.7521 26.9791C16.8722 26.9666 16.0033 27.1753 15.2258 27.5859C14.3294 28.0809 13.7942 28.7976 13.7203 29.5974C13.6464 30.3972 14.0422 31.195 14.8319 31.8429C15.761 32.6035 16.9269 32.9987 18.3968 33.049C19.5084 33.0164 20.3711 32.788 21.1103 32.3341C21.9075 31.8447 22.3847 31.1671 22.454 30.4261C22.5232 29.685 22.1779 28.9347 21.4837 28.3036C20.5611 27.474 19.1669 26.9791 17.7521 26.9791ZM40.7129 6.84539C41.9293 6.96004 43.1831 7.07841 44.4164 7.21077C44.3995 6.80064 44.1909 6.59558 43.6725 6.38026C43.3253 6.23172 42.9518 6.15407 42.574 6.15189C41.8919 6.15898 41.2337 6.40292 40.7129 6.84166V6.84539Z"
            fill="#1E1E1E"
        />
        <path
            d="M54.8576 55.4141C54.8576 54.9033 54.8576 54.3946 54.8576 53.8882C54.8576 52.0855 54.8576 50.2222 54.8717 48.398C54.8717 47.8387 55.1524 47.4072 55.3161 47.1499L55.3592 47.0819L55.5865 46.7165C56.0207 46.0174 56.4707 45.2941 56.9489 44.5903C58.3225 42.5704 58.7201 40.2094 58.1653 37.3599C57.9585 36.3001 57.6712 35.2617 57.3671 34.1627C57.1996 33.5587 57.0275 32.9351 56.8703 32.3106C56.7178 31.701 56.6962 31.1818 56.8067 30.726C57.4757 27.9343 58.1765 25.1026 58.853 22.364L59.2217 20.8726C59.2801 20.645 59.392 20.4344 59.5482 20.2584C59.5604 20.2444 59.5763 20.2257 59.5969 20.1987L61.1295 18.176L61.8977 20.5902C62.0128 20.9537 62.1288 21.3107 62.243 21.664C62.4937 22.4405 62.7314 23.174 62.9569 23.9318C64.5083 29.1517 65.6105 33.9651 66.3254 38.6462C67.4332 45.8748 63.8533 52.9589 57.4167 56.2753C57.2389 56.3685 57.063 56.445 56.9077 56.514L56.7206 56.5997L54.8567 57.4582L54.8576 55.4141Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.3001 62.6934C27.3952 62.6654 20.9699 62.369 14.7635 60.3006C11.7422 59.294 9.24295 57.9769 7.11334 56.2758C4.36149 54.0723 2.66228 51.0075 1.92122 46.9043C1.29618 43.448 1.35139 39.8202 2.09432 35.4793C2.17573 35.004 2.43678 34.6684 2.59304 34.4671C2.61924 34.4344 2.6548 34.3888 2.66603 34.3738L3.41457 33.1975L4.5374 34.0141C4.55892 34.028 4.58324 34.0411 4.60757 34.0541C4.84337 34.1874 5.28314 34.4363 5.50396 34.9695C6.65111 37.7425 8.85652 39.6534 12.4439 40.9826C14.7242 41.828 17.2777 42.3034 20.2494 42.4367C21.9542 42.5131 23.7657 42.5877 25.5342 42.5877C28.0998 42.5877 30.2182 42.4339 32.199 42.1039C32.4526 42.061 32.709 42.0107 32.9803 41.9529L33.2255 41.9025C33.4661 41.8517 33.7112 41.8252 33.9572 41.8233C34.5317 41.8233 35.91 42.0228 36.3086 43.874C36.5893 45.1836 36.87 46.5137 37.1451 47.801L37.2321 48.2102C37.2658 47.8373 37.2998 47.4645 37.3341 47.0916C37.5409 44.8191 37.7551 42.4683 37.9713 40.1567C38.0153 39.6953 38.1107 39.0121 38.4391 38.4034C39.9278 35.6434 40.7858 32.6858 41.6953 29.5539C41.8169 29.1363 41.9376 28.715 42.0612 28.3011C43.1044 24.7591 44.1066 21.5768 45.7927 18.5689C46.8416 16.6972 47.9391 15.3615 49.2463 14.3651C50.5179 13.3957 51.8896 12.8429 53.324 12.7217C53.5345 12.704 53.7404 12.6947 53.9369 12.6947C56.4829 12.6947 57.9959 14.1758 58.1989 16.8641C58.386 19.3109 57.9004 21.6178 57.3502 23.7384C57.0911 24.7404 56.7888 25.7387 56.5081 26.7053C55.9767 28.4838 55.4789 30.1644 55.1841 31.8907C54.5666 35.5446 54.2578 39.2889 54.0155 42.7909C53.8368 45.3747 53.7965 48.0303 53.7582 50.5983C53.7469 51.3365 53.7348 52.0744 53.7217 52.812C53.6786 55.0855 52.7317 56.8891 50.909 58.1745C47.7277 60.4209 44.1721 61.7417 40.3573 62.0987C38.5177 62.2711 36.652 62.3998 34.847 62.5237C34.0361 62.5797 33.2233 62.6368 32.4086 62.6953H32.3562L32.3001 62.6934Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.7724 40.8966C22.1162 40.8966 20.4413 40.795 18.8778 40.6859C16.0633 40.4883 13.8008 40.0717 11.7572 39.3754C7.07039 37.7759 4.53374 34.4706 4.21842 29.5481C3.86286 24.0076 4.71714 19.3806 6.82898 15.4014C8.46923 12.3105 10.6466 9.88045 13.2983 8.17841C15.9182 6.5006 19.0256 5.51442 22.5326 5.25622C22.8301 5.23385 23.1426 5.22267 23.4636 5.22267C24.6089 5.22267 25.742 5.36155 26.8377 5.49671C27.4524 5.57221 28.0335 5.64305 28.6155 5.69152C28.9579 5.72042 29.2863 5.77635 29.6026 5.83041C29.9225 5.89517 30.2469 5.93506 30.5729 5.94972C30.6647 5.9525 30.7564 5.94214 30.8452 5.91896C31.0819 5.84439 31.4646 5.44544 31.7715 5.12573C31.9755 4.91321 32.186 4.69416 32.419 4.48536C34.2604 2.83552 36.8036 0.860367 40.1496 0.22746C40.8504 0.0942296 41.5621 0.0268216 42.2755 0.026123C44.451 0.026123 46.4992 0.678604 48.3575 1.96213C50.5236 3.45351 51.4452 5.38859 51.1645 7.84005L53.8265 8.22874L52.2415 10.0315C52.1779 10.1042 52.1245 10.1713 52.0675 10.243C51.9018 10.4711 51.7002 10.671 51.4705 10.8349C45.7001 14.7414 42.2128 20.1337 40.8093 27.3259C40.5407 28.6743 40.1996 30.0072 39.7875 31.3191C38.2287 36.3525 34.6562 39.422 29.1684 40.4445C27.5357 40.7484 25.77 40.8966 23.7724 40.8966ZM17.7662 26.9531C16.8862 26.9401 16.0172 27.1488 15.2398 27.5599C14.3444 28.0548 13.8101 28.7716 13.7362 29.5714C13.6623 30.3712 14.0571 31.169 14.8469 31.8169C15.7741 32.5775 16.94 32.9727 18.4081 33.023C19.5187 32.9904 20.3805 32.762 21.1216 32.3081C21.9178 31.8187 22.3941 31.1411 22.4633 30.4C22.5326 29.659 22.1826 28.9087 21.494 28.2776C20.5732 27.448 19.1772 26.9531 17.7662 26.9531ZM40.7073 6.81938C41.9237 6.93403 43.1747 7.05241 44.407 7.18384C44.3902 6.77464 44.1815 6.56958 43.6641 6.35426C43.3181 6.20611 42.9459 6.12846 42.5693 6.12589C41.8869 6.13237 41.2282 6.37637 40.7073 6.81565V6.81938Z"
            fill="#1E1E1E"
        />
        <path
            d="M54.839 55.388C54.839 54.8772 54.839 54.3686 54.839 53.8622C54.839 52.0595 54.839 50.1962 54.853 48.372C54.8577 47.8127 55.1337 47.3812 55.2975 47.1239L55.3396 47.0559L55.5669 46.6905C56.0011 45.9914 56.4502 45.2681 56.9284 44.5643C58.3001 42.5444 58.6987 40.1834 58.1448 37.3339C57.9389 36.2741 57.6517 35.2357 57.3476 34.1367C57.181 33.5327 57.0088 32.9091 56.8516 32.2846C56.6991 31.675 56.6776 31.1558 56.788 30.7C57.457 27.9083 58.156 25.0766 58.8334 22.338L59.2012 20.8466C59.2591 20.6188 59.3711 20.4082 59.5277 20.2324C59.5399 20.2184 59.5558 20.1997 59.5754 20.1727L61.1062 18.15L61.8735 20.5642C61.9886 20.9277 62.1046 21.2847 62.2187 21.638C62.4695 22.4145 62.7062 23.148 62.9317 23.9058C64.4812 29.1257 65.5835 33.9391 66.3002 38.6202C67.4071 45.8488 63.8309 52.9329 57.4 56.2493C57.2222 56.3425 57.0463 56.419 56.8909 56.4879L56.7038 56.5737L54.8409 57.4322L54.839 55.388Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.302 62.6673C27.4019 62.6393 20.9803 62.3429 14.7804 60.2746C11.7619 59.2679 9.25987 57.9508 7.13774 56.2497C4.38776 54.0462 2.69043 50.9814 1.9503 46.8782C1.32527 43.4219 1.38047 39.7941 2.12247 35.4533C2.20387 34.9779 2.46587 34.6423 2.62213 34.441C2.64739 34.4083 2.68294 34.3627 2.69417 34.3478L3.44272 33.1714L4.56554 33.988C4.588 34.0019 4.61233 34.015 4.63572 34.028C4.87151 34.1613 5.31035 34.4102 5.53117 34.9434C6.67645 37.7146 8.88466 39.6245 12.4646 40.9546C14.743 41.8 17.2937 42.2754 20.2626 42.4087C21.9655 42.4851 23.7761 42.5597 25.5427 42.5597C28.1064 42.5597 30.2211 42.4059 32.201 42.0759C32.4546 42.0331 32.7109 41.9827 32.9823 41.9249L33.2265 41.8746C33.4671 41.8238 33.7122 41.7972 33.9582 41.7954C34.5318 41.7954 35.9091 41.9948 36.3077 43.846C36.5884 45.1556 36.8691 46.4858 37.1433 47.773L37.2303 48.1822C37.264 47.8094 37.298 47.4365 37.3323 47.0637C37.5391 44.7912 37.7524 42.4404 37.9695 40.1287C38.0125 39.6627 38.108 38.9841 38.4373 38.3754C39.9241 35.6154 40.7822 32.6578 41.6907 29.5259C41.8117 29.1083 41.9334 28.6908 42.0556 28.2732C43.0989 24.7311 44.0992 21.5489 45.7843 18.5409C46.8323 16.6693 47.928 15.3335 49.2342 14.3371C50.5048 13.3677 51.8756 12.815 53.3091 12.6938C53.5187 12.6761 53.7245 12.6667 53.921 12.6667C56.4642 12.6667 57.9763 14.1479 58.1793 16.8361C58.3665 19.2829 57.8809 21.5899 57.3316 23.7105C57.0724 24.7125 56.7702 25.7108 56.4895 26.6774C55.959 28.4559 55.4602 30.1365 55.1664 31.8627C54.5498 35.5166 54.241 39.2609 53.9987 42.7629C53.82 45.3467 53.7807 48.0023 53.7423 50.5703C53.7311 51.3085 53.7208 52.0468 53.7058 52.7841C53.6628 55.0575 52.7168 56.8612 50.8988 58.1465C47.7175 60.3929 44.1693 61.7137 40.3564 62.0707C38.5187 62.2432 36.6539 62.3718 34.8508 62.4958C34.0399 62.5517 33.229 62.6089 32.4181 62.6673H32.3666H32.302Z"
            fill="#1E1E1E"
        />
        <path
            d="M23.7817 40.8705C22.1265 40.8705 20.4535 40.7689 18.8918 40.6598C16.0792 40.4622 13.8195 40.0456 11.7806 39.3493C7.10219 37.7498 4.56274 34.4445 4.24835 29.5238C3.88999 23.9833 4.74333 19.3563 6.8533 15.3799C8.49262 12.289 10.6671 9.85899 13.317 8.15695C15.9369 6.47914 19.0397 5.49296 22.5429 5.23476C22.8395 5.21239 23.1529 5.20121 23.4729 5.20121C24.6173 5.20121 25.7495 5.34009 26.8414 5.47525C27.4552 5.55075 28.0353 5.62159 28.6192 5.67006C28.9617 5.69896 29.2891 5.75488 29.6063 5.80895C29.9256 5.87375 30.2493 5.91364 30.5748 5.92826C30.6666 5.93116 30.7583 5.9208 30.8471 5.8975C31.0838 5.82293 31.4655 5.42398 31.7725 5.10427C31.9764 4.89174 32.187 4.6727 32.4199 4.4639C34.2614 2.81126 36.8018 0.838904 40.145 0.203201C40.8447 0.0694487 41.5555 0.00141663 42.268 0C44.4416 0 46.488 0.652481 48.35 1.936C50.5142 3.42739 51.4377 5.36246 51.1514 7.81393L53.8088 8.20262L52.2247 10.0053C52.162 10.078 52.1077 10.1451 52.0516 10.2169C51.8874 10.446 51.687 10.6472 51.4583 10.8125C45.6926 14.719 42.2091 20.1132 40.8065 27.3035C40.5387 28.652 40.1979 29.985 39.7857 31.2967C38.2287 36.3302 34.66 39.3996 29.1769 40.4221C27.545 40.726 25.7813 40.8742 23.7854 40.8742L23.7817 40.8705ZM17.7812 26.927C16.9011 26.9135 16.032 27.1222 15.2548 27.5338C14.3594 28.0287 13.8251 28.7455 13.7512 29.5453C13.6772 30.345 14.0721 31.1429 14.8609 31.7907C15.7882 32.5514 16.9521 32.9466 18.4165 32.9969C19.5253 32.9643 20.387 32.7359 21.13 32.282C21.9262 31.7926 22.4016 31.115 22.4708 30.3739C22.5401 29.6329 22.1957 28.8825 21.5024 28.2515C20.5854 27.4219 19.194 26.927 17.7812 26.927ZM40.698 6.78953C41.9143 6.90325 43.1635 7.02256 44.3948 7.15399C44.378 6.74479 44.1693 6.53973 43.6519 6.32441C43.3055 6.17705 42.9329 6.10066 42.5562 6.09977C41.875 6.10688 41.2177 6.35086 40.698 6.78953Z"
            fill="#1E1E1E"
        />
        <path
            d="M54.8202 55.3622C54.8202 54.8514 54.8202 54.3428 54.8202 53.8363C54.8202 52.0336 54.8202 50.1703 54.8342 48.3461C54.8342 47.7869 55.1149 47.3553 55.2777 47.098L55.3208 47.03L55.5472 46.6646C55.9814 45.9655 56.4305 45.2422 56.9077 44.5384C58.2785 42.5185 58.6761 40.1575 58.1241 37.308C57.9182 36.2482 57.631 35.2098 57.3278 34.1109C57.1613 33.5069 56.9891 32.8833 56.8319 32.2587C56.6794 31.6491 56.6588 31.13 56.7683 30.6742C57.4364 27.8825 58.1353 25.0507 58.8118 22.3121L59.1786 20.8208C59.237 20.5931 59.3489 20.3825 59.5052 20.2065C59.5173 20.1925 59.5332 20.1739 59.5529 20.1468L61.0827 18.1241L61.8491 20.5383C61.9641 20.9019 62.0802 21.2589 62.1934 21.6121C62.4441 22.3886 62.6809 23.1222 62.9064 23.88C64.4549 29.0998 65.5562 33.9133 66.2748 38.5943C67.3808 45.8229 63.8074 52.907 57.3858 56.2234C57.2081 56.3167 57.0321 56.3931 56.8778 56.4621L56.6906 56.5478L54.8296 57.4063L54.8202 55.3622Z"
            fill="#1E1E1E"
        />
        <path
            d="M57.967 18.288C57.7948 16.1171 56.613 15.1645 54.3477 15.3481C53.0106 15.4553 51.8466 15.9633 50.7921 16.7277C49.3886 17.7474 48.3902 19.0831 47.5406 20.5298C45.7928 23.5125 44.7542 26.7451 43.752 29.9935C42.6657 33.5179 41.6935 37.0776 39.853 40.3466C39.6444 40.7194 39.5639 41.1771 39.5218 41.604C39.0867 46.0353 38.6797 50.4693 38.2427 54.9015C38.2044 55.2958 38.3934 55.8961 37.7468 55.9325C37.5803 55.9427 37.279 55.335 37.1985 54.9789C36.4715 51.8097 35.7866 48.6405 35.0717 45.4713C34.8733 44.5933 34.3886 44.3258 33.4595 44.5057C33.0852 44.5784 32.711 44.6557 32.3367 44.7154C28.0877 45.3921 23.8117 45.2476 19.5384 45.0649C16.6041 44.94 13.7147 44.5103 10.9488 43.5307C7.367 42.263 4.41305 40.3018 2.89162 36.7915C2.81677 36.619 2.53419 36.5277 2.34799 36.3981C2.20951 36.6069 1.98401 36.8008 1.94377 37.0254C1.2888 40.6728 1.06704 44.336 1.75664 48.0002C2.39571 51.3772 3.80859 54.3721 6.70267 56.5849C8.9717 58.3196 11.543 59.5164 14.2873 60.3898C20.1073 62.242 26.1256 62.6819 32.2188 62.7145C34.9847 62.5197 37.7543 62.3669 40.5146 62.1199C44.2374 61.7998 47.8145 60.5292 50.9007 58.4305C52.4642 57.3772 53.2286 55.9399 53.2661 54.1083C53.3334 50.7452 53.3325 47.3747 53.5758 44.0219C53.8434 40.3354 54.1718 36.6358 54.8146 32.9959C55.3096 30.1995 56.3632 27.4964 57.1117 24.7336C57.6891 22.628 58.141 20.4916 57.967 18.288Z"
            fill="url(#paint0_linear_44_564)"
        />
        <path
            d="M11.1585 39.4712C13.4612 40.2216 15.85 40.5534 18.264 40.7147C21.7448 40.9477 25.2274 41.109 28.6894 40.4928C33.7141 39.5989 37.2117 37.0021 38.7471 32.2669C39.1634 31.0007 39.5086 29.7124 39.781 28.408C41.266 21.1374 45.0284 15.2838 51.5183 11.0874C51.6951 10.9737 51.813 10.7779 52.0647 10.5039L50.3421 10.2634C51.1047 7.67117 50.4637 5.82279 48.2527 4.36589C45.9697 2.86146 43.4115 2.35345 40.698 2.84281C37.7824 3.36946 35.4301 4.93728 33.2668 6.78846C32.5641 7.38967 31.9391 8.24815 31.1147 8.4961C30.2642 8.75243 29.2153 8.40288 28.2515 8.32552C26.1996 8.1624 24.127 7.74854 22.0985 7.89115C15.224 8.37492 10.015 11.504 6.77009 17.3447C4.43088 21.5532 3.87883 26.1131 4.19322 30.7979C4.48702 35.1779 6.80097 38.0516 11.1585 39.4712ZM44.6485 6.47434C45.964 6.99819 46.4431 7.85946 46.2092 9.18959C46.1156 9.70412 45.9285 9.93528 45.3184 9.86537C43.0943 9.62862 40.8646 9.44406 38.5759 9.23433C39.6089 6.71109 42.27 5.5301 44.6485 6.47807V6.47434ZM13.6923 27.7508C16.17 26.4458 19.7312 26.8345 21.8271 28.6428C24.0045 30.5229 23.7369 33.282 21.2358 34.7501C20.142 35.3923 18.9368 35.6291 17.6718 35.6607C15.9754 35.6113 14.4062 35.1993 13.0738 34.1554C10.6148 32.2287 10.9086 29.2189 13.6923 27.7508Z"
            fill="url(#paint1_linear_44_564)"
        />
        <path
            d="M66.4592 40.1479C65.6686 35.218 64.4569 30.3831 62.9719 25.6069C62.6332 24.5154 62.2533 23.4351 61.8716 22.2886C61.778 22.4135 61.7275 22.447 61.7162 22.489C60.8685 25.7682 60.0161 29.0464 59.1899 32.3312C59.1141 32.6332 59.1712 32.9893 59.2526 33.2997C59.7017 35.0082 60.2715 36.6926 60.6224 38.4179C61.211 41.3075 60.9359 44.1038 59.1384 46.6271C58.5695 47.4268 58.0409 48.2536 57.5057 49.0748C57.3756 49.2743 57.2034 49.4998 57.2016 49.7151C57.1791 52.0343 57.1875 54.3534 57.1875 56.72C57.4102 56.6268 57.6413 56.5336 57.864 56.4208C64.0994 53.3504 67.5268 46.8042 66.4592 40.1479Z"
            fill="url(#paint2_linear_44_564)"
        />
        <path
            d="M57.9454 18.2619C57.7732 16.091 56.5933 15.1384 54.3299 15.322C52.9928 15.4292 51.8307 15.9372 50.7818 16.7006C49.3783 17.7259 48.3817 19.057 47.5331 20.5036C45.7871 23.4864 44.7485 26.719 43.7482 29.9674C42.6628 33.4908 41.6953 37.0515 39.852 40.3204C39.6434 40.6933 39.5629 41.1509 39.5208 41.5779C39.0867 46.0091 38.6787 50.4432 38.2436 54.8754C38.2043 55.2697 38.3942 55.87 37.7486 55.9063C37.5811 55.9166 37.2808 55.3088 37.2003 54.9528C36.4733 51.7882 35.7893 48.6144 35.0754 45.4499C34.877 44.5718 34.3923 44.3043 33.4641 44.4842C33.0899 44.5569 32.7156 44.6343 32.3413 44.6939C28.0961 45.3706 23.8266 45.2262 19.5542 45.0435C16.6227 44.9186 13.7371 44.4888 10.9731 43.5092C7.39408 42.2406 4.44293 40.2803 2.92618 36.77C2.85133 36.5976 2.56875 36.5062 2.38349 36.3766C2.24407 36.5854 2.0195 36.7793 1.97927 37.004C1.32429 40.6513 1.10347 44.3145 1.79213 47.9787C2.43401 51.3595 3.84502 54.3506 6.73255 56.5635C8.99971 58.2981 11.5691 59.495 14.3116 60.3684C20.1259 62.2205 26.1387 62.6604 32.2272 62.6931C34.9902 62.4983 37.757 62.3454 40.5145 62.0984C44.2331 61.7777 47.8058 60.507 50.8875 58.4091C52.451 57.3558 53.2146 55.9185 53.251 54.0868C53.3184 50.7238 53.3184 47.3532 53.5608 44.0004C53.8274 40.3139 54.1559 36.6143 54.7987 32.9744C55.2927 30.1781 56.3463 27.4749 57.0939 24.7122C57.6684 22.6018 58.1194 20.4654 57.9454 18.2619Z"
            fill="url(#paint3_linear_44_564)"
        />
        <path
            d="M11.1789 39.4452C13.4798 40.1955 15.8658 40.5273 18.278 40.6886C21.7559 40.9216 25.2357 41.0829 28.694 40.4668C33.714 39.5729 37.2088 36.976 38.7423 32.2408C39.1575 30.9747 39.5011 29.6863 39.7716 28.3819C41.2556 21.1114 45.0114 15.2577 51.4995 11.0613C51.6754 10.9476 51.7923 10.7518 52.045 10.4778L50.3242 10.2373C51.0859 7.6451 50.445 5.79671 48.2367 4.33982C45.9583 2.83538 43.3993 2.33017 40.6895 2.81674C37.7767 3.34338 35.4272 4.9112 33.2648 6.76239C32.5631 7.3636 31.939 8.22208 31.1128 8.47002C30.2632 8.72635 29.2143 8.37681 28.2524 8.29945C26.2023 8.13632 24.1354 7.72247 22.1049 7.86508C15.2361 8.34885 10.0346 11.478 6.78965 17.3186C4.45044 21.5271 3.90119 26.087 4.21558 30.7718C4.51126 35.1518 6.82614 38.0302 11.1789 39.4452ZM44.639 6.44826C45.949 6.97211 46.4318 7.83339 46.1979 9.16352C46.1043 9.67804 45.9172 9.90921 45.308 9.8393C43.0858 9.60254 40.8579 9.41798 38.5711 9.20826C39.6032 6.68782 42.2624 5.50403 44.639 6.45199V6.44826ZM13.7109 27.7247C16.1858 26.4198 19.7442 26.8085 21.8373 28.6168C24.0137 30.4969 23.7461 33.2559 21.2469 34.724C20.155 35.3662 18.9498 35.603 17.6866 35.6347C15.9912 35.5853 14.4239 35.1733 13.0924 34.1293C10.6353 32.2026 10.9291 29.1928 13.7109 27.7247Z"
            fill="url(#paint4_linear_44_564)"
        />
        <path
            d="M66.4292 40.122C65.6386 35.1921 64.4287 30.3572 62.9447 25.581C62.606 24.4895 62.2271 23.4092 61.8463 22.2627C61.7471 22.3876 61.7012 22.4212 61.6909 22.4631C60.8488 25.7423 59.9917 29.0205 59.1646 32.3053C59.0888 32.6073 59.1468 32.9634 59.2282 33.2738C59.6773 34.9823 60.2462 36.6667 60.5971 38.392C61.1847 41.2816 60.9096 44.0779 59.1141 46.6012C58.5452 47.4009 58.0174 48.2277 57.4822 49.0489C57.3522 49.2484 57.18 49.474 57.1781 49.6893C57.166 52.0121 57.166 54.3275 57.166 56.6941C57.3877 56.5963 57.6198 56.5077 57.8415 56.3949C64.0732 53.3245 67.4968 46.7783 66.4292 40.122Z"
            fill="url(#paint5_linear_44_564)"
        />
        <path
            d="M57.9238 18.236C57.7517 16.0651 56.5727 15.1125 54.3112 15.2952C52.9759 15.4033 51.8138 15.9113 50.7668 16.6747C49.3633 17.7001 48.3686 19.0311 47.5209 20.4778C45.7758 23.4605 44.7391 26.6931 43.7398 29.9415C42.6553 33.4649 41.6878 37.0256 39.8473 40.2945C39.6387 40.6674 39.5582 41.1251 39.5161 41.552C39.0819 45.9832 38.674 50.4173 38.2398 54.8495C38.2005 55.2438 38.3905 55.8441 37.7449 55.8805C37.5783 55.8907 37.277 55.283 37.1975 54.9269C36.4714 51.7624 35.7874 48.5885 35.0735 45.424C34.876 44.5459 34.3923 44.2756 33.465 44.462C33.0908 44.5347 32.7165 44.6121 32.3422 44.6718C28.1008 45.3485 23.8275 45.204 19.5664 45.0213C16.6377 44.8964 13.7539 44.4667 10.9927 43.487C7.41745 42.2184 4.4691 40.2582 2.95049 36.7478C2.87563 36.5754 2.59306 36.4841 2.40779 36.3545C2.26931 36.5633 2.04474 36.7572 2.00451 36.9818C1.34953 40.6292 1.12965 44.2924 1.81737 47.9565C2.45551 51.3336 3.86559 54.3285 6.75405 56.5413C9.02589 58.2723 11.5981 59.4691 14.3322 60.3425C20.1418 62.1946 26.1489 62.6346 32.2318 62.6672C34.9921 62.4724 37.757 62.3195 40.5117 62.0725C44.2273 61.7516 47.797 60.4808 50.8753 58.3832C52.437 57.3299 53.1996 55.8926 53.237 54.061C53.3044 50.6979 53.3034 47.3274 53.5458 43.9745C53.8124 40.288 54.1409 36.5885 54.7827 32.9485C55.2768 30.1522 56.3285 27.4491 57.0761 24.6863C57.6469 22.576 58.1016 20.4395 57.9238 18.236Z"
            fill="url(#paint6_linear_44_564)"
        />
        <path
            d="M11.1996 39.4192C13.4986 40.1696 15.8827 40.5014 18.293 40.6627C21.7672 40.8957 25.2433 41.057 28.6988 40.4408C33.714 39.5469 37.206 36.9501 38.7387 32.2149C39.1529 30.9485 39.4965 29.6602 39.7679 28.3559C41.25 21.0854 45.0077 15.2318 51.4846 11.0354C51.6605 10.9217 51.7774 10.7259 52.0291 10.4519L50.3103 10.2114C51.071 7.61917 50.431 5.77079 48.2246 4.31389C45.9453 2.80945 43.3881 2.30145 40.6811 2.79081C37.7712 3.31746 35.4226 4.88528 33.263 6.73646C32.5613 7.33767 31.9381 8.19615 31.111 8.44409C30.2623 8.70043 29.2143 8.35088 28.2524 8.27352C26.2042 8.1104 24.1354 7.69654 22.1106 7.83915C15.2548 8.32292 10.0552 11.452 6.81684 17.2927C4.47763 21.5012 3.93025 26.0611 4.24371 30.7459C4.53751 35.1259 6.84959 37.9996 11.1996 39.4192ZM44.6278 6.42606C45.9378 6.94991 46.4197 7.81119 46.1867 9.14132C46.0931 9.65584 45.906 9.88701 45.2969 9.8171C43.0774 9.58034 40.8505 9.39578 38.5665 9.18606C39.5985 6.66096 42.255 5.4781 44.6278 6.42606ZM13.7287 27.6988C16.2018 26.3938 19.7574 26.7825 21.8486 28.5908C24.0231 30.4709 23.7546 33.23 21.2582 34.6981C20.1672 35.3403 18.9639 35.577 17.7026 35.6087C16.009 35.5593 14.4427 35.1473 13.1121 34.1034C10.6569 32.1767 10.9498 29.1669 13.7287 27.6988Z"
            fill="url(#paint7_linear_44_564)"
        />
        <path
            d="M66.4003 40.0962C65.6115 35.1662 64.4026 30.3313 62.9205 25.5551C62.5818 24.4636 62.2028 23.3833 61.822 22.2368C61.7228 22.3617 61.6769 22.3953 61.6667 22.4372C60.8245 25.7164 59.9693 28.9947 59.1469 32.2794C59.0711 32.5814 59.1291 32.9375 59.2105 33.2479C59.6587 34.9565 60.2276 36.6408 60.5775 38.3661C61.1651 41.2557 60.89 44.0521 59.0963 46.5753C58.5284 47.375 58.0006 48.2018 57.4654 49.023C57.3363 49.2225 57.1641 49.4481 57.1623 49.6634C57.1407 51.9825 57.1482 54.3016 57.1482 56.6682C57.3709 56.5704 57.602 56.4818 57.8238 56.369C64.0461 53.2986 67.466 46.7524 66.4003 40.0962Z"
            fill="url(#paint8_linear_44_564)"
        />
        <path
            d="M57.9024 18.2099C57.7311 16.039 56.5522 15.0864 54.2934 15.2691C52.9591 15.3772 51.798 15.8852 50.7519 16.6486C49.3549 17.6693 48.3565 19.005 47.5088 20.4516C45.7656 23.4344 44.7298 26.667 43.7305 29.9154C42.647 33.4388 41.6813 36.9995 39.8418 40.2684C39.6331 40.6413 39.5536 41.0989 39.5115 41.5259C39.0773 45.9571 38.6694 50.3912 38.2361 54.8234C38.1968 55.2177 38.3858 55.818 37.7421 55.8543C37.5746 55.8646 37.2743 55.2568 37.1947 54.9008C36.4686 51.7325 35.7856 48.5633 35.0726 45.3941C34.8752 44.5161 34.3914 44.2486 33.4651 44.4285C33.0908 44.5012 32.7212 44.5785 32.3469 44.6382C28.1092 45.3149 23.8444 45.1704 19.5823 44.9877C16.6564 44.8628 13.7755 44.4331 11.0161 43.4535C7.44369 42.1849 4.49816 40.2246 2.98141 36.7143C2.90656 36.5418 2.62492 36.4505 2.43872 36.3209C2.30023 36.5297 2.07567 36.7236 2.03637 36.9482C1.38139 40.5956 1.16151 44.2588 1.84923 47.923C2.48643 51.3 3.89558 54.2949 6.78216 56.5077C9.04465 58.2424 11.6094 59.4393 14.3453 60.3126C20.1465 62.1648 26.1518 62.6047 32.229 62.6373C34.9874 62.4425 37.7496 62.2897 40.5024 62.0427C44.2151 61.7215 47.7817 60.4507 50.8567 58.3533C52.4164 57.3 53.179 55.8627 53.2155 54.0311C53.2829 50.668 53.2829 47.2975 53.5252 43.9447C53.791 40.2582 54.1194 36.5586 54.7603 32.9187C55.2534 30.1223 56.3051 27.4192 57.0509 24.6564C57.6254 22.5498 58.0755 20.4134 57.9024 18.2099Z"
            fill="url(#paint9_linear_44_564)"
        />
        <path
            d="M11.2239 39.3931C13.52 40.1435 15.9023 40.4753 18.3108 40.6366C21.7821 40.8696 25.2545 41.0308 28.7071 40.4147C33.7187 39.5208 37.2069 36.9239 38.7377 32.1888C39.1524 30.9225 39.496 29.6342 39.7669 28.3298C41.2481 21.0593 45.0002 15.2056 51.4733 11.0093C51.6492 10.8955 51.7662 10.6998 52.0179 10.4257L50.2999 10.1853C51.0606 7.59305 50.4206 5.74466 48.2162 4.28777C45.9378 2.78333 43.3824 2.27533 40.6774 2.76469C37.7702 3.29133 35.4244 4.85915 33.2667 6.71033C32.5659 7.31155 31.9418 8.17003 31.1203 8.41797C30.2716 8.6743 29.2246 8.32476 28.2646 8.24739C26.2182 8.08427 24.1476 7.67041 22.1274 7.81303C15.2716 8.29586 10.0767 11.4259 6.84113 17.2665C4.50847 21.475 3.95735 26.035 4.2708 30.7198C4.56367 35.0998 6.87388 37.9735 11.2239 39.3931ZM44.6278 6.39621C45.9378 6.92006 46.4178 7.78134 46.1848 9.11147C46.0912 9.62599 45.9041 9.8525 45.2959 9.78725C43.0783 9.55049 40.8542 9.36593 38.5721 9.15621C39.5938 6.63483 42.2474 5.45198 44.6194 6.39994L44.6278 6.39621ZM13.7502 27.6727C16.2204 26.3677 19.7723 26.7564 21.8626 28.5647C24.0343 30.4448 23.7667 33.2039 21.2722 34.6719C20.1821 35.3142 18.9798 35.5509 17.7166 35.5826C16.0249 35.5332 14.4604 35.1212 13.1317 34.0773C10.6774 32.1506 10.9712 29.1408 13.7502 27.6727Z"
            fill="url(#paint10_linear_44_564)"
        />
        <path
            d="M66.3712 40.0695C65.5824 35.1387 64.3754 30.3047 62.8942 25.5285C62.5555 24.437 62.1775 23.3567 61.7966 22.2102C61.7031 22.3351 61.6526 22.3687 61.6413 22.4106C60.7992 25.6898 59.9459 28.968 59.1243 32.2528C59.0485 32.5548 59.1066 32.9109 59.188 33.2213C59.6352 34.9299 60.2041 36.6142 60.5541 38.3395C61.1407 41.2291 60.8656 44.0254 59.0738 46.5487C58.5058 47.3484 57.9791 48.1752 57.4448 48.9964C57.3147 49.1959 57.1435 49.4215 57.1416 49.6368C57.1192 51.9559 57.1276 54.275 57.1276 56.6416C57.3493 56.5484 57.5805 56.4552 57.8022 56.3424C64.0189 53.272 67.436 46.7258 66.3712 40.0695Z"
            fill="url(#paint11_linear_44_564)"
        />
        <path
            d="M57.8809 18.1835C57.7097 16.0126 56.5316 15.06 54.2748 15.2427C52.9423 15.3508 51.7821 15.8588 50.7369 16.6222C49.3409 17.6429 48.3435 18.9786 47.4967 20.4253C45.7553 23.408 44.7195 26.6406 43.7221 29.889C42.6395 33.4124 41.6748 36.9731 39.8362 40.2421C39.6285 40.6149 39.548 41.0716 39.5068 41.4995C39.0727 45.9308 38.6647 50.3648 38.2315 54.797C38.1931 55.1913 38.3822 55.7916 37.7384 55.828C37.5718 55.8382 37.2706 55.2305 37.192 54.8744C36.4668 51.7052 35.7884 48.536 35.0717 45.3668C34.8743 44.4888 34.3915 44.2213 33.4661 44.4012C33.0918 44.4739 32.7222 44.5512 32.3479 44.6109C28.114 45.2876 23.8538 45.1431 19.5955 44.9604C16.6715 44.8355 13.7942 44.4058 11.0368 43.4262C7.46807 42.1576 4.5244 40.1973 3.00953 36.687C2.93467 36.5145 2.65303 36.4232 2.46777 36.2936C2.32929 36.5024 2.10472 36.6963 2.06449 36.9209C1.40951 40.5683 1.19149 44.2315 1.88203 47.8957C2.52204 51.2774 3.92556 54.2723 6.81028 56.486C9.07089 58.2207 11.6337 59.4175 14.3669 60.2909C20.1681 62.143 26.163 62.583 32.2384 62.6156C34.994 62.4208 37.7534 62.2679 40.5043 62.0209C44.214 61.6994 47.7775 60.4286 50.8492 58.3316C52.4081 57.2783 53.1697 55.841 53.2062 54.0094C53.2736 50.6463 53.2726 47.2758 53.515 43.923C53.7807 40.2365 54.1082 36.5369 54.7491 32.897C55.2423 30.1006 56.293 27.3975 57.0378 24.6347C57.6049 22.5235 58.054 20.3871 57.8809 18.1835Z"
            fill="url(#paint12_linear_44_564)"
        />
        <path
            d="M11.2407 39.367C13.535 40.1174 15.9145 40.4492 18.321 40.6105C21.7887 40.8435 25.2591 41.0048 28.7071 40.3886C33.714 39.4947 37.1994 36.8979 38.7293 32.1627C39.1435 30.8963 39.4872 29.608 39.7585 28.3037C41.2378 21.0332 44.9871 15.1796 51.4546 10.9832C51.6305 10.8695 51.7474 10.6737 51.9991 10.3997L50.284 10.1601C51.0438 7.5679 50.4047 5.71952 48.2021 4.26262C45.9265 2.75818 43.374 2.25018 40.6717 2.73954C37.7711 3.26619 35.4226 4.83401 33.2667 6.68519C32.5668 7.28547 31.9437 8.14395 31.1221 8.3891C30.2753 8.64543 29.2293 8.29588 28.2692 8.21852C26.2248 8.0554 24.1597 7.64154 22.1386 7.78415C15.2885 8.27072 10.1011 11.3998 6.86546 17.2442C4.53466 21.449 3.98448 26.0061 4.29981 30.6937C4.59081 35.0737 6.89914 37.9474 11.2407 39.367ZM44.61 6.37013C45.92 6.89398 46.3981 7.75526 46.1651 9.08539C46.0716 9.59991 45.8844 9.82642 45.2772 9.76117C43.0615 9.52441 40.8392 9.33985 38.559 9.13013C39.5882 6.60875 42.2399 5.4259 44.61 6.37386V6.37013ZM13.7652 27.6466C16.2335 26.3416 19.7826 26.7303 21.8701 28.5386C24.0409 30.4187 23.7733 33.1778 21.2815 34.6459C20.1924 35.2881 18.991 35.5248 17.7259 35.5565C16.0352 35.5071 14.4716 35.0951 13.1411 34.0512C10.698 32.1245 10.9918 29.1147 13.7652 27.6466Z"
            fill="url(#paint13_linear_44_564)"
        />
        <path
            d="M66.3423 40.0437C65.5545 35.1128 64.3484 30.2788 62.8681 25.5027C62.5303 24.4112 62.1514 23.3308 61.7715 22.1843C61.6732 22.3092 61.6274 22.3428 61.6171 22.3847C60.775 25.6639 59.9226 28.9422 59.102 32.2269C59.0262 32.5289 59.0842 32.885 59.1656 33.1954C59.6129 34.904 60.1799 36.5883 60.5298 38.3137C61.1165 41.2032 60.8414 43.9996 59.0514 46.5228C58.4835 47.3226 57.9567 48.1493 57.4234 48.9705C57.2942 49.17 57.123 49.3956 57.1202 49.6109C57.0987 51.93 57.1071 54.2491 57.1071 56.6158C57.3288 56.5225 57.56 56.4293 57.7808 56.3165C63.9965 53.2462 67.4062 46.699 66.3423 40.0437Z"
            fill="url(#paint14_linear_44_564)"
        />
        <path
            d="M57.8593 18.1575C57.6881 15.9866 56.5119 15.034 54.2569 15.2167C52.9254 15.3248 51.7661 15.8328 50.7219 16.5962C49.3268 17.6169 48.3303 18.9526 47.4844 20.3993C45.7441 23.382 44.7101 26.6146 43.7136 29.863C42.632 33.3864 41.6673 36.9471 39.8315 40.2161C39.6238 40.5889 39.5433 41.0456 39.5012 41.4735C39.068 45.9048 38.6591 50.3388 38.2277 54.771C38.1894 55.1653 38.3784 55.7656 37.7346 55.802C37.569 55.8113 37.2668 55.2045 37.1891 54.8484C36.4649 51.6839 35.7856 48.51 35.0707 45.3455C34.8742 44.4674 34.3914 44.1999 33.466 44.3798C33.0917 44.4525 32.724 44.5299 32.3497 44.5895C28.1195 45.2663 23.8621 45.1218 19.6085 44.9391C16.6873 44.8142 13.8073 44.3845 11.0573 43.4048C7.49141 42.1362 4.55149 40.1741 3.03661 36.6656C2.9627 36.4932 2.68105 36.4018 2.49579 36.2723C2.35731 36.4811 2.13368 36.6749 2.09344 36.8996C1.43846 40.547 1.22045 44.2102 1.90631 47.8743C2.54257 51.2514 3.94797 54.2463 6.82988 56.4591C9.08862 58.1938 11.6487 59.3906 14.3799 60.264C20.1746 62.1161 26.1695 62.5561 32.2318 62.5887C34.9846 62.3939 37.7421 62.241 40.4893 61.994C44.1962 61.6728 47.7567 60.4019 50.8248 58.3047C52.3828 57.2514 53.1435 55.8141 53.18 53.9825C53.2473 50.6194 53.2473 47.2489 53.4887 43.896C53.7545 40.2095 54.082 36.51 54.722 32.8701C55.2151 30.0737 56.264 27.3706 57.0088 24.6078C57.5833 22.4975 58.0324 20.3611 57.8593 18.1575Z"
            fill="url(#paint15_linear_44_564)"
        />
        <path
            d="M11.2613 39.341C13.5537 40.0913 15.9313 40.4232 18.335 40.5844C21.7971 40.8175 25.2666 40.9787 28.7127 40.3626C33.7149 39.4687 37.1975 36.8718 38.7245 32.1367C39.1388 30.8702 39.4824 29.582 39.7538 28.2777C41.2322 21.0072 44.9777 15.1535 51.4386 10.9571C51.6145 10.8434 51.7315 10.6477 51.9822 10.3736L50.2681 10.1331C51.0269 7.54092 50.3888 5.69254 48.188 4.23564C45.9143 2.73121 43.3646 2.2232 40.6642 2.71256C37.7636 3.23921 35.4244 4.80703 33.2667 6.65821C32.5677 7.25943 31.9446 8.1179 31.124 8.36585C30.2772 8.62125 29.233 8.27263 28.2739 8.19527C26.2313 8.03215 24.1681 7.61829 22.1479 7.7609C15.3053 8.24467 10.1197 11.3719 6.88974 17.2144C4.56175 21.4229 4.01157 25.9828 4.32409 30.6667C4.61696 35.0477 6.91968 37.9214 11.2613 39.341ZM44.6006 6.34409C45.9106 6.86794 46.3869 7.72921 46.1539 9.05934C46.0603 9.57387 45.8732 9.80503 45.2678 9.73513C43.053 9.49837 40.8336 9.31381 38.5552 9.10408C39.5835 6.58271 42.2324 5.39985 44.6006 6.34782V6.34409ZM13.7839 27.6206C16.2494 26.3156 19.7956 26.7043 21.8813 28.5126C24.0493 30.3927 23.7826 33.1517 21.2927 34.6198C20.2045 35.262 19.004 35.4988 17.7455 35.5305C16.0557 35.4811 14.494 35.0691 13.1672 34.0251C10.7195 32.0984 11.0124 29.0886 13.7839 27.6206Z"
            fill="url(#paint16_linear_44_564)"
        />
        <path
            d="M66.3132 40.018C65.5263 35.0871 64.3202 30.2532 62.8418 25.477C62.504 24.3855 62.126 23.3052 61.747 22.1587C61.6488 22.2836 61.6029 22.3172 61.5917 22.3591C60.7496 25.6383 59.8991 28.9165 59.0794 32.2013C59.0036 32.5033 59.0616 32.8594 59.1421 33.1698C59.5894 34.8783 60.1564 36.5627 60.5063 38.288C61.0921 41.1776 60.817 43.9739 59.0279 46.4972C58.4665 47.2969 57.9351 48.1237 57.4027 48.9449C57.2735 49.1444 57.1014 49.37 57.0995 49.5853C57.078 51.9044 57.0864 54.2235 57.0864 56.5901C57.3072 56.4922 57.5383 56.4037 57.7592 56.2909C63.9646 53.2243 67.3761 46.6743 66.3132 40.018Z"
            fill="url(#paint17_linear_44_564)"
        />
        <path
            d="M57.8378 18.1314C57.6666 15.9605 56.4914 15.0069 54.2383 15.1934C52.9077 15.3015 51.7503 15.8095 50.707 16.5729C49.3128 17.5936 48.3173 18.9293 47.4723 20.3759C45.7338 23.3587 44.7008 26.5913 43.7043 29.8397C42.6246 33.3631 41.6608 36.9238 39.8259 40.1927C39.6182 40.5655 39.5387 41.0223 39.4966 41.4501C39.0633 45.8814 38.6544 50.3155 38.224 54.7477C38.1857 55.142 38.3747 55.7422 37.7319 55.7786C37.5653 55.7879 37.264 55.1811 37.1864 54.8251C36.4621 51.6605 35.7828 48.4867 35.0698 45.3175C34.8733 44.4403 34.3905 44.1728 33.467 44.3527C33.0927 44.4254 32.725 44.5028 32.3507 44.5625C28.1252 45.2392 23.8716 45.0947 19.6254 44.912C16.707 44.7871 13.8335 44.3574 11.0817 43.3777C7.51579 42.1101 4.58055 40.1498 3.06474 36.6395C2.99082 36.467 2.70918 36.3757 2.52391 36.2461C2.38637 36.4549 2.1618 36.6488 2.1225 36.8734C1.46753 40.5208 1.25045 44.184 1.94005 47.8482C2.57538 51.2252 3.97984 54.2201 6.85894 56.4329C9.11581 58.1639 11.674 59.3645 14.4052 60.2378C20.1943 62.09 26.1799 62.5299 32.2413 62.5625C34.9912 62.3677 37.7459 62.2149 40.4912 61.9678C44.1952 61.6463 47.7525 60.3753 50.8174 58.2785C52.3735 57.2252 53.1342 55.7879 53.1707 53.9563C53.238 50.5932 53.2371 47.2227 53.4785 43.8699C53.7442 40.1834 54.0717 36.4838 54.7117 32.8439C55.203 30.0475 56.2519 27.3444 56.9957 24.5816C57.5618 22.4713 58.0081 20.3349 57.8378 18.1314Z"
            fill="url(#paint18_linear_44_564)"
        />
        <path
            d="M11.282 39.3149C13.5716 40.0652 15.9473 40.3971 18.3492 40.5583C21.8112 40.7913 25.2733 40.9526 28.7175 40.3365C33.7159 39.4426 37.1948 36.8457 38.7219 32.1105C39.1357 30.844 39.4793 29.5557 39.7511 28.2516C41.2276 20.9811 44.9704 15.1274 51.4256 10.931C51.6006 10.8173 51.7176 10.6216 51.9683 10.3475L50.2551 10.107C51.0139 7.51481 50.3758 5.66643 48.1769 4.20953C45.9051 2.70323 43.3572 2.19709 40.6597 2.68645C37.759 3.2131 35.4198 4.78092 33.2677 6.6321C32.5688 7.23331 31.9466 8.09179 31.1269 8.33973C30.2848 8.59513 29.2368 8.24652 28.2787 8.16916C26.2379 8.00604 24.1766 7.59218 22.1593 7.73479C15.3222 8.21856 10.1414 11.3477 6.91419 17.1883C4.58808 21.3968 4.03789 25.9567 4.35135 30.6415C4.64328 35.0215 6.94787 37.8953 11.282 39.3149ZM44.5923 6.31797C45.9023 6.84182 46.3767 7.7031 46.1446 9.03323C46.0511 9.54776 45.8639 9.77892 45.2585 9.70901C43.0466 9.47226 40.8281 9.2877 38.5516 9.07797C39.5752 6.5566 42.2251 5.37374 44.5914 6.3217L44.5923 6.31797ZM13.8018 27.5907C16.2654 26.2858 19.8079 26.6745 21.8926 28.4828C24.0587 30.3628 23.7921 33.1219 21.3041 34.59C20.2168 35.2322 19.0173 35.469 17.7597 35.5007C16.0755 35.4513 14.5119 35.0393 13.1861 33.9953C10.7402 32.0723 11.0368 29.0625 13.8018 27.5907Z"
            fill="url(#paint19_linear_44_564)"
        />
        <path
            d="M66.2842 39.9875C65.4982 35.0566 64.2931 30.2227 62.8166 25.4465C62.4788 24.355 62.1008 23.2747 61.7218 22.1282C61.6236 22.2531 61.5777 22.2866 61.5674 22.3286C60.7253 25.6078 59.8766 28.886 59.057 32.1708C58.9812 32.4728 59.0383 32.8289 59.1197 33.1393C59.566 34.8478 60.133 36.5322 60.482 38.2575C61.0678 41.1471 60.7936 43.9434 59.0055 46.4667C58.4441 47.2664 57.9136 48.0932 57.3812 48.9144C57.252 49.1139 57.0808 49.3394 57.0789 49.5548C57.0574 51.8739 57.0658 54.193 57.0658 56.5596C57.2867 56.4617 57.5168 56.3732 57.7386 56.2604C63.9375 53.1937 67.3462 46.6475 66.2842 39.9875Z"
            fill="url(#paint20_linear_44_564)"
        />
        <path
            d="M57.8209 18.1054C57.6497 15.9345 56.4763 14.9819 54.2251 15.1646C52.8955 15.2727 51.7389 15.7807 50.6957 16.5441C49.3043 17.5648 48.3087 18.9005 47.4647 20.3471C45.7281 23.3299 44.6961 26.5625 43.7005 29.8109C42.6207 33.3343 41.6588 36.895 39.8258 40.1639C39.6181 40.5368 39.5376 40.9935 39.4965 41.4214C39.0632 45.8526 38.6543 50.2867 38.2249 54.7189C38.1865 55.1132 38.3755 55.7135 37.7327 55.7498C37.5671 55.7592 37.2695 55.1524 37.1881 54.7954C36.4648 51.6318 35.7846 48.457 35.0735 45.2924C34.877 44.4153 34.3951 44.1478 33.4725 44.3277C33.0982 44.4004 32.7305 44.4778 32.3572 44.5374C28.1344 45.2141 23.8855 45.0697 19.6394 44.887C16.7228 44.7621 13.8521 44.3324 11.1031 43.3527C7.5475 42.0841 4.60851 40.1238 3.09738 36.6135C3.02253 36.4411 2.74182 36.3497 2.55749 36.2201C2.41901 36.4289 2.19538 36.6228 2.15515 36.8475C1.50578 40.4948 1.28403 44.158 1.97363 47.8222C2.60802 51.1992 4.01155 54.1941 6.88784 56.407C9.13816 58.1417 11.6916 59.3385 14.4201 60.2147C20.2064 62.064 26.1835 62.5039 32.2393 62.5366C34.9874 62.3418 37.7402 62.1889 40.4827 61.9419C44.1845 61.6221 47.7402 60.3527 50.8033 58.2572C52.3584 57.2039 53.1172 55.7666 53.1537 53.935C53.2211 50.5719 53.2211 47.2014 53.4625 43.8486C53.7273 40.1621 54.0538 36.4625 54.6938 32.8226C55.1851 30.0262 56.2321 27.3231 56.976 24.5603C57.5402 22.4453 57.9893 20.3089 57.8209 18.1054Z"
            fill="url(#paint21_linear_44_564)"
        />
        <path
            d="M11.3024 39.2886C13.5901 40.039 15.963 40.3708 18.3631 40.5321C21.8251 40.7651 25.2825 40.9263 28.722 40.3102C33.7158 39.4163 37.1919 36.8194 38.717 32.0843C39.1313 30.8179 39.475 29.5296 39.7463 28.2253C41.2219 20.9548 44.9608 15.1011 51.4105 10.9048C51.5855 10.791 51.7025 10.5953 51.9532 10.3213L50.2419 10.0808C50.9988 7.48856 50.3616 5.64017 48.1656 4.18327C45.8956 2.67884 43.3505 2.17084 40.6558 2.6602C37.7551 3.18684 35.4206 4.75466 33.2713 6.60584C32.5724 7.20706 31.9511 8.06554 31.1324 8.31348C30.2902 8.56888 29.2442 8.22027 28.2869 8.1429C26.2472 7.97978 24.1877 7.56592 22.1722 7.70854C15.3408 8.1923 10.1627 11.3214 6.93837 17.1621C4.61413 21.3706 4.06488 25.9305 4.3774 30.6153C4.67401 34.9953 6.97205 37.869 11.3024 39.2886ZM44.581 6.29172C45.8909 6.81557 46.3644 7.67684 46.1323 9.00697C46.0388 9.5215 45.8516 9.75267 45.2472 9.68276C43.0371 9.44973 40.8214 9.26517 38.5458 9.05544C39.575 6.53034 42.2184 5.34749 44.581 6.29545V6.29172ZM13.8203 27.5682C16.2812 26.2632 19.8209 26.6519 21.9028 28.4602C24.067 30.3403 23.8013 33.0994 21.3152 34.5675C20.2288 35.2097 19.0312 35.4464 17.7745 35.4781C16.0903 35.4287 14.5296 35.0167 13.2046 33.9728C10.7616 32.0461 11.0535 29.0363 13.8203 27.5682Z"
            fill="url(#paint22_linear_44_564)"
        />
        <path
            d="M66.2552 39.9655C65.4693 35.0346 64.266 30.2007 62.7932 25.4245C62.4554 24.333 62.0783 23.2527 61.6994 22.1062C61.6058 22.2311 61.5562 22.2647 61.545 22.3066C60.7029 25.5858 59.8608 28.864 59.0374 32.1488C58.9616 32.4508 59.0186 32.8069 59.1 33.1173C59.5464 34.8259 60.1125 36.5102 60.4615 38.2355C61.0463 41.1251 60.7721 43.9214 58.9859 46.4447C58.4245 47.2444 57.8949 48.0712 57.3634 48.8924C57.2343 49.0919 57.0631 49.3175 57.0612 49.5328C57.0397 51.8519 57.0472 54.171 57.0472 56.5376C57.2689 56.4444 57.4991 56.3512 57.7199 56.2384C63.9113 53.168 67.3163 46.6218 66.2552 39.9655Z"
            fill="url(#paint23_linear_44_564)"
        />
        <path
            d="M57.7948 18.0829C57.6245 15.9121 56.4511 14.9594 54.2017 15.1421C52.874 15.2502 51.7184 15.7583 50.6761 16.5217C49.2856 17.5423 48.2919 18.878 47.448 20.3247C45.7123 23.3075 44.6811 26.54 43.6874 29.7885C42.6086 33.3119 41.6467 36.8725 39.8156 40.1415C39.6078 40.5143 39.5283 40.9711 39.4862 41.3989C39.0549 45.8302 38.6441 50.2642 38.2165 54.6965C38.1781 55.0907 38.3662 55.691 37.7253 55.7274C37.5587 55.7367 37.2574 55.1299 37.1807 54.7729C36.4583 51.6093 35.7772 48.4345 35.0679 45.2653C34.8714 44.3882 34.3905 44.1207 33.4679 44.3006C33.0936 44.3733 32.7268 44.4506 32.3535 44.5103C28.1354 45.187 23.8902 45.0425 19.6469 44.8598C16.7341 44.7349 13.8662 44.3052 11.1191 43.3256C7.56346 42.057 4.63009 40.0967 3.12083 36.5864C3.04597 36.4139 2.76527 36.3226 2.58094 36.193C2.44246 36.4018 2.21976 36.5957 2.17953 36.8203C1.53016 40.4677 1.30934 44.1309 1.99707 47.7951C2.6324 51.1721 4.03406 54.167 6.90754 56.3799C9.16067 58.1145 11.7132 59.3114 14.4379 60.1848C20.2158 62.0378 26.1948 62.4778 32.2412 62.5104C34.9865 62.3156 37.7365 62.1627 40.4752 61.9157C44.174 61.5942 47.726 60.3231 50.7846 58.2264C52.3378 57.1731 53.0967 55.7358 53.1332 53.9042C53.2005 50.5411 53.1996 47.1706 53.441 43.8177C53.7058 40.1312 54.0324 36.4316 54.6714 32.7917C55.1617 29.9954 56.2088 27.2923 56.9517 24.5295C57.5197 22.4192 57.9679 20.2827 57.7948 18.0829Z"
            fill="url(#paint24_linear_44_564)"
        />
        <path
            d="M11.322 39.2626C13.6079 40.0083 15.9799 40.3448 18.3771 40.5061C21.8326 40.7391 25.2899 40.9004 28.7276 40.2842C33.7158 39.3903 37.189 36.7935 38.7133 32.0583C39.1272 30.7918 39.4708 29.5035 39.7425 28.1994C41.2172 20.9289 44.9524 15.0752 51.3955 10.8788C51.5705 10.7651 51.6875 10.5693 51.9382 10.2953L50.2278 10.0548C50.9848 7.46258 50.3485 5.61419 48.1534 4.1573C45.8834 2.65286 43.3402 2.14393 40.6473 2.63422C37.7532 3.16087 35.4178 4.72868 33.2694 6.57987C32.5724 7.18108 31.9511 8.03956 31.1323 8.2875C30.2902 8.5429 29.246 8.19429 28.2897 8.11693C26.2527 7.9538 24.1952 7.53995 22.1806 7.68256C15.3558 8.16633 10.1842 11.2954 6.96267 17.1361C4.64124 21.3455 4.09199 25.9036 4.40451 30.5893C4.69551 34.9693 6.99636 37.844 11.322 39.2626ZM44.5716 6.26574C45.8815 6.78959 46.3494 7.65087 46.1211 8.981C46.0322 9.49552 45.8451 9.72669 45.2369 9.65678C43.0286 9.42002 40.8148 9.23546 38.543 9.02574C39.5685 6.50436 42.2108 5.32151 44.5716 6.26947V6.26574ZM13.8381 27.5422C16.2971 26.2373 19.834 26.6259 21.914 28.4343C24.0763 30.3143 23.8097 33.0734 21.3264 34.5415C20.2419 35.1837 19.0442 35.4205 17.7885 35.4522C16.1043 35.4028 14.5464 34.9908 13.2233 33.9468C10.7821 32.0201 11.0741 29.0103 13.8381 27.5422Z"
            fill="url(#paint25_linear_44_564)"
        />
        <path
            d="M66.2262 39.9394C65.4411 35.0085 64.2388 30.1746 62.7641 25.3984C62.4273 24.3069 62.0502 23.2266 61.6722 22.0801C61.5786 22.205 61.5281 22.2385 61.5178 22.2805C60.6757 25.5597 59.8336 28.8379 59.012 32.1227C58.9362 32.4247 58.9933 32.7808 59.0747 33.0912C59.5201 34.7997 60.0862 36.4841 60.4343 38.2094C61.0191 41.099 60.7449 43.8953 58.9606 46.4186C58.3992 47.2183 57.8714 48.0451 57.339 48.8663C57.2108 49.0658 57.0396 49.2913 57.0377 49.5067C57.0153 51.8258 57.0237 54.1449 57.0237 56.5115C57.2445 56.4183 57.4747 56.3251 57.6955 56.2123C63.8841 53.1419 67.2863 46.5957 66.2262 39.9394Z"
            fill="url(#paint26_linear_44_564)"
        />
        <path
            d="M57.7732 18.0533C57.6029 15.8824 56.4305 14.9298 54.1839 15.1125C52.8571 15.2206 51.7025 15.7277 50.6611 16.492C49.2716 17.5127 48.277 18.8484 47.4349 20.295C45.701 23.2778 44.6708 26.5104 43.6771 29.7588C42.6002 33.2822 41.6392 36.8429 39.809 40.1118C39.6022 40.4847 39.5227 40.9414 39.4806 41.3692C39.0493 45.8005 38.6385 50.2346 38.2118 54.6668C38.1735 55.0611 38.3615 55.6614 37.7206 55.6977C37.555 55.707 37.2574 55.1002 37.1769 54.7432C36.4546 51.5796 35.7734 48.4048 35.066 45.2403C34.8695 44.3632 34.3886 44.0957 33.4679 44.2756C33.0936 44.3483 32.7268 44.4256 32.3544 44.4853C28.1438 45.1611 23.8977 45.0175 19.6591 44.8348C16.7491 44.7099 13.8831 44.2802 11.1387 43.3006C7.58311 42.032 4.65629 40.0717 3.14703 36.5614C3.07311 36.3889 2.79241 36.2976 2.60808 36.168C2.47053 36.3768 2.2469 36.5707 2.2076 36.7953C1.55824 40.4427 1.33742 44.1059 2.02515 47.7701C2.65954 51.1471 4.06026 54.142 6.93094 56.3549C9.1822 58.0895 11.7329 59.2864 14.4548 60.1598C20.2289 62.0119 26.1948 62.4518 32.2431 62.4844C34.9856 62.2896 37.7327 62.1368 40.4715 61.8898C44.1667 61.5677 47.7149 60.2966 50.7696 58.2004C52.321 57.1472 53.0798 55.7089 53.1163 53.8782C53.1828 50.5152 53.1828 47.1446 53.4232 43.7918C53.688 40.1053 54.0146 36.4048 54.6527 32.7658C55.143 29.9695 56.1891 27.2663 56.9311 24.5035C57.4981 22.3932 57.9463 20.2568 57.7732 18.0533Z"
            fill="url(#paint27_linear_44_564)"
        />
        <path
            d="M11.3427 39.2365C13.6267 39.9878 15.9959 40.3187 18.3912 40.48C21.8439 40.713 25.2984 40.8733 28.7324 40.2581C33.7159 39.3643 37.1863 36.7674 38.7087 32.0322C39.123 30.7658 39.4666 29.4775 39.7379 28.1733C41.2116 20.9028 44.9431 15.0491 51.3806 10.8527C51.5556 10.739 51.6726 10.5432 51.9224 10.2692L50.2139 10.0287C50.9671 7.43649 50.3355 5.59276 48.1385 4.1312C45.8723 2.62677 43.3319 2.11877 40.6418 2.60999C37.7505 3.13664 35.4169 4.70446 33.2705 6.55564C32.5734 7.15499 31.953 8.01626 31.1352 8.26141C30.2931 8.51681 29.2508 8.1682 28.2954 8.09083C26.2594 7.92771 24.2037 7.51385 22.192 7.65647C15.3727 8.14023 10.2059 11.2693 6.9871 17.11C4.66753 21.3185 4.11922 25.8775 4.43081 30.5632C4.72274 34.9432 7.01984 37.8169 11.3427 39.2365ZM44.5595 6.23965C45.8639 6.7635 46.3373 7.62477 46.1081 8.9549C46.0145 9.46943 45.8274 9.7006 45.2238 9.63069C43.0184 9.39393 40.8055 9.20937 38.5356 8.99965C39.5639 6.47827 42.2035 5.29542 44.5623 6.24524L44.5595 6.23965ZM13.856 27.5152C16.3131 26.2102 19.8443 26.5999 21.9253 28.4082C24.0849 30.2873 23.8191 33.0473 21.3386 34.5154C20.2542 35.1576 19.0584 35.3944 17.8036 35.4261C16.1194 35.3767 14.5643 34.9647 13.2421 33.9207C10.8028 31.994 11.0948 28.9889 13.856 27.5152Z"
            fill="url(#paint28_linear_44_564)"
        />
        <path
            d="M66.1972 39.9133C65.4131 34.9824 64.2117 30.1485 62.7352 25.3723C62.3983 24.2808 62.0212 23.2005 61.6432 22.054C61.5496 22.1789 61.5001 22.2124 61.4888 22.2544C60.6467 25.5335 59.8046 28.8118 58.9849 32.0966C58.9101 32.3986 58.9672 32.7546 59.0486 33.065C59.4939 34.7736 60.0591 36.4579 60.4072 38.1833C60.991 41.0728 60.7178 43.8692 58.9344 46.3924C58.373 47.1922 57.8462 48.019 57.3147 48.8402C57.1856 49.0396 57.0153 49.2652 57.0134 49.4805C56.991 51.7996 56.9994 54.1187 56.9994 56.4854C57.2202 56.3922 57.4504 56.299 57.6703 56.1862C63.857 53.1158 67.2564 46.5695 66.1972 39.9133Z"
            fill="url(#paint29_linear_44_564)"
        />
        <path
            d="M57.7516 18.0269C57.5814 15.856 56.4108 14.9034 54.1652 15.0861C52.8402 15.1942 51.6865 15.7013 50.6461 16.4656C49.2584 17.4863 48.2657 18.822 47.4245 20.2687C45.6916 23.2514 44.6624 26.484 43.6696 29.7324C42.5926 33.2558 41.6326 36.8165 39.8052 40.0854C39.5984 40.4583 39.518 40.915 39.4768 41.3429C39.0454 45.7741 38.6347 50.2082 38.2089 54.6404C38.1706 55.0347 38.3587 55.635 37.7186 55.6713C37.5521 55.6807 37.2508 55.0739 37.1741 54.7169C36.4536 51.5533 35.7705 48.3785 35.066 45.2139C34.8695 44.3368 34.3895 44.0693 33.4688 44.2492C33.0945 44.3219 32.7296 44.3993 32.3572 44.4589C28.1466 45.1347 23.9079 44.9912 19.673 44.8085C16.7649 44.6836 13.9017 44.2492 11.1583 43.2742C7.61018 42.0056 4.68336 40.0454 3.17691 36.5387C3.10299 36.3663 2.82228 36.2749 2.63795 36.1454C2.50041 36.3542 2.27771 36.5481 2.23748 36.7727C1.58624 40.4163 1.36729 44.0796 2.05409 47.7437C2.68754 51.1208 4.08733 54.1156 6.9552 56.3285C9.20084 58.0632 11.7525 59.26 14.4716 60.1334C20.2401 61.9855 26.2041 62.4255 32.243 62.4581C34.9836 62.2633 37.7289 62.1104 40.4639 61.8634C44.1575 61.5417 47.704 60.2705 50.7565 58.1741C52.3069 57.1208 53.0639 55.6825 53.0957 53.8519C53.163 50.4888 53.1621 47.1183 53.4035 43.7654C53.6683 40.0789 53.9939 36.3784 54.6311 32.7394C55.1214 29.9431 56.1657 27.24 56.9077 24.4772C57.4775 22.3706 57.9247 20.2267 57.7516 18.0269Z"
            fill="url(#paint30_linear_44_564)"
        />
        <path
            d="M11.3633 39.2105C13.6454 39.9561 16.0118 40.2926 18.4052 40.4539C21.8542 40.6869 25.3059 40.8472 28.7371 40.2321C33.7168 39.3382 37.1835 36.7413 38.7049 32.0061C39.1172 30.7404 39.4592 29.4531 39.7295 28.15C41.2013 20.8795 44.93 15.0258 51.3619 10.8294C51.536 10.7157 51.6529 10.5199 51.9028 10.2459L50.1961 10.0054C50.9512 7.41319 50.3158 5.5648 48.1254 4.1079C45.862 2.60067 43.3235 2.09267 40.6352 2.58203C37.7468 3.10868 35.4151 4.67556 33.2714 6.52768C32.5743 7.12889 31.9549 7.98737 31.138 8.23531C30.2959 8.49071 29.2545 8.1421 28.3001 8.06474C26.2669 7.90162 24.213 7.48682 22.2023 7.63037C15.3895 8.11414 10.2255 11.2433 7.01142 17.0839C4.69373 21.2924 4.14542 25.8514 4.457 30.5371C4.74893 34.9171 7.04417 37.7908 11.3633 39.2105ZM44.553 6.21728C45.8564 6.74113 46.3308 7.60241 46.0996 8.93254C46.0061 9.44706 45.8189 9.67823 45.2173 9.60832C43.0128 9.37156 40.8027 9.187 38.5346 8.97728C39.5583 6.45218 42.195 5.26932 44.553 6.21728ZM13.8747 27.4891C16.329 26.1841 19.863 26.5738 21.9356 28.3821C24.0942 30.2612 23.8285 33.0212 21.3498 34.4893C20.2663 35.1315 19.0715 35.3683 17.8186 35.4C16.1343 35.3506 14.582 34.9386 13.2609 33.8946C10.8234 31.9716 11.1153 28.9581 13.8747 27.4891Z"
            fill="url(#paint31_linear_44_564)"
        />
        <path
            d="M66.1682 39.8872C65.385 34.9563 64.1845 30.1223 62.7127 25.3462C62.3768 24.2547 61.9997 23.1743 61.6226 22.0278C61.5291 22.1527 61.4785 22.1863 61.4683 22.2282C60.6261 25.5074 59.784 28.7857 58.9662 32.0704C58.8914 32.3725 58.9485 32.7285 59.0299 33.0389C59.4743 34.7475 60.0395 36.4318 60.3875 38.1572C60.9705 41.0467 60.6973 43.8431 58.9157 46.3663C58.3543 47.1661 57.8284 47.9929 57.2979 48.814C57.1688 49.0135 56.9985 49.2391 56.9957 49.4544C56.9742 51.7735 56.9826 54.0926 56.9826 56.4593C57.2034 56.3614 57.4326 56.2728 57.6525 56.16C63.8299 53.0897 67.2265 46.5434 66.1682 39.8872Z"
            fill="url(#paint32_linear_44_564)"
        />
        <path
            d="M57.7273 18.0009C57.558 15.83 56.3874 14.8774 54.1446 15.0601C52.8206 15.1682 51.6678 15.6753 50.6283 16.4396C49.2416 17.4603 48.2507 18.796 47.4095 20.2427C45.6785 23.2254 44.6493 26.458 43.6584 29.7064C42.5823 33.2298 41.6233 36.7905 39.7968 40.0594C39.59 40.4323 39.5105 40.889 39.4693 41.3169C39.038 45.7481 38.6319 50.1822 38.2024 54.6144C38.164 55.0087 38.3521 55.609 37.7121 55.6453C37.5465 55.6547 37.2499 55.0479 37.1685 54.6909C36.448 51.5273 35.7705 48.3525 35.0622 45.1879C34.8657 44.3108 34.3857 44.0433 33.4669 44.2232C33.0926 44.2959 32.7277 44.3733 32.3553 44.4329C28.1447 45.1087 23.9145 44.9652 19.6833 44.7825C16.778 44.6576 13.9176 44.2279 11.1779 43.2482C7.6317 41.9796 4.70675 40.0194 3.20124 36.509C3.12638 36.3366 2.84661 36.2452 2.66228 36.1157C2.52474 36.3244 2.30204 36.5183 2.26274 36.743C1.61431 40.3903 1.39443 44.0536 2.08122 47.7177C2.71655 51.0948 4.11259 54.0896 6.98047 56.2997C9.22611 58.0344 11.774 59.2312 14.4903 60.1046C20.2532 61.9567 26.2116 62.3967 32.2458 62.4293C34.9836 62.2345 37.7261 62.0816 40.4593 61.8346C44.1489 61.5123 47.6913 60.241 50.7396 58.1453C52.2882 57.092 53.0452 55.6537 53.0788 53.8231C53.1453 50.46 53.1453 47.0895 53.3857 43.7366C53.6505 40.0501 53.9752 36.3496 54.6124 32.7106C55.1018 29.9143 56.146 27.2112 56.8871 24.4484C57.456 22.3408 57.9032 20.2044 57.7273 18.0009Z"
            fill="url(#paint33_linear_44_564)"
        />
        <path
            d="M11.3839 39.1844C13.6632 39.9347 16.0286 40.2666 18.4193 40.4278C21.8654 40.6608 25.3134 40.8212 28.7418 40.206C33.7168 39.3121 37.1807 36.7152 38.7003 31.98C39.1127 30.7135 39.4544 29.4252 39.7239 28.1211C41.1948 20.8506 44.9198 14.9969 51.346 10.8005C51.521 10.6868 51.637 10.491 51.8869 10.217L50.1811 9.97372C50.9362 7.3815 50.3018 5.53312 48.1123 4.07622C45.8517 2.57178 43.3151 2.06378 40.6268 2.55314C37.7402 3.07979 35.4113 4.64667 33.2686 6.49879C32.5734 7.1 31.954 7.95848 31.1381 8.20642C30.2959 8.46182 29.2564 8.11321 28.3029 8.03585C26.2706 7.87273 24.2187 7.45794 22.2098 7.60148C15.4064 8.08805 10.2489 11.2172 7.03575 17.0578C4.71993 21.2663 4.17256 25.8253 4.48695 30.511C4.77514 34.891 7.0685 37.7648 11.3839 39.1844ZM44.5436 6.18746C45.8461 6.71131 46.3214 7.57259 46.0884 8.90271C45.9995 9.41724 45.8133 9.64841 45.207 9.5785C43.0044 9.34174 40.7962 9.15718 38.5309 8.94746C39.5536 6.42608 42.1885 5.24323 44.5436 6.19119V6.18746ZM13.8925 27.463C16.3449 26.158 19.8715 26.5477 21.9468 28.356C24.0989 30.2351 23.8378 32.9951 21.3611 34.4632C20.2785 35.1054 19.0846 35.3422 17.8326 35.3739C16.1531 35.3245 14.5989 34.9125 13.2796 33.8685C10.8496 31.9418 11.1359 28.932 13.8925 27.463Z"
            fill="url(#paint34_linear_44_564)"
        />
        <path
            d="M66.1391 39.861C65.356 34.9302 64.1574 30.0962 62.6865 25.32C62.3506 24.2285 61.9753 23.1482 61.5973 22.0017C61.5038 22.1266 61.4542 22.1602 61.4429 22.2021C60.6008 25.4813 59.7587 28.7595 58.9437 32.0443C58.8689 32.3463 58.926 32.7024 59.0074 33.0128C59.4518 34.7214 60.016 36.4057 60.3632 38.131C60.9461 41.0206 60.6729 43.8169 58.8932 46.3402C58.3318 47.1399 57.8069 47.9667 57.2763 48.7879C57.1481 48.9874 56.9779 49.213 56.9751 49.4283C56.9535 51.7474 56.9619 54.0665 56.9619 56.4331C57.1818 56.3399 57.412 56.2467 57.6319 56.1339C63.8037 53.0635 67.1965 46.5173 66.1391 39.861Z"
            fill="url(#paint35_linear_44_564)"
        />
        <path
            d="M57.7096 17.9749C57.5393 15.804 56.3706 14.8514 54.1297 15.0341C52.8066 15.1422 51.6548 15.6493 50.6162 16.4136C49.2304 17.4343 48.2405 18.77 47.4002 20.2167C45.6701 23.1994 44.6428 26.432 43.6519 29.6804C42.5777 33.2038 41.6196 36.7645 39.795 40.0334C39.5882 40.4063 39.5087 40.863 39.4665 41.2909C39.0361 45.7221 38.63 50.1562 38.2015 54.5884C38.1631 54.9827 38.3512 55.583 37.7112 55.6194C37.5465 55.6287 37.2499 55.0219 37.1685 54.6649C36.449 51.5013 35.7715 48.3265 35.0641 45.1573C34.8686 44.2802 34.3886 44.0126 33.4735 44.1925C33.1039 44.2652 32.7352 44.3426 32.3638 44.4023C28.1607 45.078 23.9295 44.9345 19.7021 44.7518C16.8015 44.6269 13.942 44.1925 11.2051 43.2175C7.66169 41.9489 4.73955 39.9887 3.23496 36.4783C3.16105 36.3059 2.88128 36.2146 2.69695 36.085C2.5594 36.2938 2.33764 36.4877 2.29741 36.7123C1.65085 40.3597 1.43003 44.0229 2.11589 47.687C2.74841 51.0641 4.14539 54.059 7.00858 56.2718C9.25422 58.0065 11.7974 59.2033 14.5118 60.0767C20.2691 61.9288 26.2229 62.3688 32.2515 62.4014C34.9865 62.2066 37.7271 62.0537 40.4574 61.8067C44.1441 61.4841 47.6834 60.2128 50.7284 58.1174C52.2751 57.0641 53.0321 55.6259 53.0677 53.7952C53.1341 50.4321 53.1332 47.0616 53.3736 43.7088C53.6384 40.0223 53.9631 36.3218 54.5994 32.6828C55.0887 29.8864 56.132 27.1833 56.8722 24.4205C57.4354 22.3148 57.8818 20.1784 57.7096 17.9749Z"
            fill="url(#paint36_linear_44_564)"
        />
        <path
            d="M11.4035 39.1584C13.6819 39.9041 16.0445 40.2406 18.4333 40.4018C21.8767 40.6348 25.3218 40.7952 28.7465 40.18C33.7168 39.2861 37.1779 36.6892 38.6956 31.9531C39.1082 30.687 39.4499 29.399 39.7192 28.0951C41.1883 20.8246 44.9104 14.9709 51.3301 10.7745C51.5051 10.6608 51.6211 10.465 51.871 10.191L50.1671 9.95052C50.9212 7.35831 50.2868 5.50992 48.1002 4.05302C45.8442 2.54859 43.3066 2.04059 40.624 2.52995C37.7403 3.05659 35.4132 4.62348 33.2724 6.47559C32.5771 7.07681 31.9624 7.93529 31.1427 8.18323C30.3006 8.43863 29.2639 8.09002 28.3104 8.01265C26.2809 7.84953 24.2308 7.43474 22.2238 7.57829C15.4232 8.06205 10.2704 11.1912 7.06009 17.0318C4.74614 21.2403 4.1997 25.7993 4.51035 30.485C4.80135 34.8613 7.09284 37.7388 11.4035 39.1584ZM44.5343 6.16427C45.8349 6.68905 46.3121 7.55032 46.0781 8.88045C45.9846 9.39498 45.8021 9.62614 45.1967 9.55624C42.9969 9.32134 40.7915 9.13492 38.5262 8.92519C39.548 6.40009 42.181 5.22003 44.5343 6.16427ZM13.9102 27.437C16.3608 26.1321 19.8846 26.5217 21.9571 28.33C24.1092 30.2091 23.8472 32.9691 21.3723 34.4372C20.2907 35.0794 19.0986 35.3162 17.8476 35.3479C16.169 35.2985 14.6167 34.8818 13.2983 33.8425C10.8655 31.9158 11.1565 28.906 13.9102 27.437Z"
            fill="url(#paint37_linear_44_564)"
        />
        <path
            d="M66.1102 39.8349C65.3279 34.904 64.1302 30.0701 62.6612 25.2939C62.3253 24.2024 61.9501 23.1221 61.573 21.9756C61.4748 22.1005 61.4289 22.134 61.4186 22.176C60.5765 25.4552 59.7344 28.7334 58.9213 32.0182C58.8464 32.3202 58.9035 32.6763 58.9849 32.9867C59.4284 34.6952 59.9926 36.3796 60.3398 38.1049C60.9218 40.9945 60.6495 43.7908 58.8717 46.3141C58.3103 47.1138 57.7854 47.9406 57.2558 48.7618C57.1276 48.9613 56.9564 49.1868 56.9545 49.4022C56.933 51.7213 56.9414 54.0404 56.9414 56.407C57.1613 56.3138 57.3905 56.2206 57.6104 56.1078C63.7766 53.0374 67.1665 46.4912 66.1102 39.8349Z"
            fill="url(#paint38_linear_44_564)"
        />
        <path
            d="M57.6881 17.9488C57.5178 15.7779 56.3501 14.8253 54.111 15.008C52.7889 15.1161 51.6389 15.6232 50.6013 16.3875C49.2174 17.4082 48.2274 18.7439 47.3881 20.1905C45.6599 23.1686 44.6335 26.4059 43.6454 29.6543C42.5722 33.1777 41.614 36.7384 39.7913 40.0073C39.5845 40.3802 39.505 40.8369 39.4638 41.2647C39.0334 45.696 38.6282 50.1301 38.1997 54.5623C38.1613 54.9566 38.3485 55.5569 37.7103 55.5932C37.5447 55.6025 37.249 54.9957 37.1676 54.6387C36.449 51.4751 35.7716 48.3004 35.0652 45.1358C34.8696 44.2587 34.3905 43.9912 33.4745 44.1711C33.1049 44.2438 32.7362 44.3211 32.3648 44.3808C28.1654 45.0566 23.9436 44.913 19.7153 44.7303C16.8146 44.6054 13.9599 44.1757 11.2258 43.1961C7.68515 41.9275 4.76956 39.9672 3.26311 36.4569C3.18919 36.2844 2.90942 36.1931 2.72509 36.0635C2.58848 36.2723 2.36579 36.4662 2.32649 36.6908C1.67993 40.3382 1.45537 44.0014 2.14122 47.6656C2.77281 51.0426 4.16885 54.0375 7.02924 56.2504C9.27488 57.985 11.8134 59.1819 14.5259 60.0553C20.2776 61.9074 26.222 62.3473 32.2488 62.38C34.9819 62.1851 37.7197 62.0323 40.4482 61.7853C44.132 61.4627 47.6682 60.1913 50.7098 58.096C52.2565 57.0427 53.0116 55.6044 53.049 53.7737C53.1164 50.4107 53.1155 47.0401 53.3559 43.6873C53.6198 40.0008 53.9445 36.3003 54.5807 32.6613C55.0692 29.865 56.1106 27.1618 56.8507 24.399C57.414 22.2887 57.8603 20.1523 57.6881 17.9488Z"
            fill="url(#paint39_linear_44_564)"
        />
        <path
            d="M11.4241 39.1321C13.6996 39.8825 16.0604 40.2143 18.4473 40.3756C21.8869 40.6086 25.3293 40.7689 28.7511 40.1537C33.7168 39.2598 37.1722 36.6629 38.6918 31.9269C39.1033 30.6605 39.4444 29.3726 39.7136 28.0688C41.1816 20.7983 44.9001 14.9447 51.316 10.7483C51.4901 10.6345 51.6061 10.4388 51.8559 10.1648L50.1483 9.92428C50.9062 7.33206 50.2718 5.48368 48.0898 4.02678C45.8329 2.52234 43.301 2.01434 40.6202 2.5037C37.7393 3.03035 35.4141 4.59723 33.276 6.44935C32.5818 7.05056 31.9661 7.90904 31.1483 8.15698C30.3062 8.41238 29.2704 8.06377 28.3188 7.98641C26.2902 7.82329 24.242 7.40849 22.2368 7.55204C15.4391 8.03581 10.2881 11.1649 7.08436 17.0056C4.77228 21.2141 4.22584 25.773 4.53742 30.4588C4.82749 34.8388 7.11711 37.7125 11.4241 39.1321ZM44.5248 6.13523C45.8245 6.66001 46.3026 7.52128 46.0669 8.85141C45.978 9.36594 45.7918 9.5971 45.1864 9.52719C42.9884 9.29044 40.784 9.10588 38.5224 8.89615C39.5433 6.37385 42.1735 5.19099 44.5248 6.13802V6.13523ZM13.928 27.408C16.3757 26.103 19.8967 26.4926 21.9674 28.3009C24.1194 30.1801 23.8546 32.9401 21.3826 34.4082C20.3028 35.0504 19.1107 35.2871 17.8606 35.3188C16.1839 35.2694 14.6335 34.8574 13.316 33.8135C10.886 31.8896 11.177 28.8798 13.9289 27.4108L13.928 27.408Z"
            fill="url(#paint40_linear_44_564)"
        />
        <path
            d="M66.0812 39.809C65.2999 34.8782 64.1031 30.0442 62.635 25.268C62.3001 24.1765 61.9249 23.0962 61.5478 21.9497C61.4542 22.0746 61.4046 22.1082 61.3943 22.1492C60.5522 25.4293 59.7101 28.7075 58.8998 31.9923C58.824 32.2943 58.8811 32.6504 58.9625 32.9608C59.406 34.6694 59.9693 36.3537 60.3164 38.079C60.8975 40.9686 60.6252 43.7649 58.8493 46.2882C58.2878 47.0879 57.7639 47.9147 57.2343 48.7359C57.1061 48.9354 56.9358 49.161 56.9339 49.3763C56.9124 51.6954 56.9208 54.0145 56.9208 56.3811C57.1407 56.2879 57.3699 56.1947 57.5889 56.0819C63.7494 53.0115 67.1366 46.4653 66.0812 39.809Z"
            fill="url(#paint41_linear_44_564)"
        />
        <path
            d="M57.6665 17.9228C57.4972 15.7519 56.3304 14.7993 54.0932 14.982C52.772 15.0901 51.623 15.5972 50.5862 16.3615C49.2033 17.3822 48.2143 18.7179 47.3759 20.1645C45.6495 23.1426 44.624 26.3799 43.6331 29.6283C42.5599 33.1517 41.6036 36.7124 39.7828 39.9813C39.576 40.3542 39.4965 40.8109 39.4553 41.2387C39.0249 45.67 38.6197 50.1041 38.1921 54.5363C38.1538 54.9306 38.3409 55.5309 37.7028 55.5672C37.5381 55.5765 37.2424 54.9697 37.161 54.6127C36.4424 51.4491 35.7668 48.2744 35.0604 45.1098C34.8648 44.2327 34.3858 43.9652 33.4697 44.1451C33.1011 44.2178 32.7324 44.2952 32.3619 44.3548C28.1663 45.0306 23.9407 44.887 19.7236 44.7043C16.823 44.5794 13.9738 44.1451 11.2416 43.1701C7.70473 41.9015 4.78539 39.9412 3.28829 36.4309C3.21438 36.2584 2.93554 36.1671 2.75215 36.0375C2.6146 36.2463 2.39191 36.4402 2.35261 36.6648C1.70699 40.3122 1.4871 43.9754 2.17202 47.6396C2.80267 51.0166 4.19684 54.0115 7.05536 56.2244C9.29633 57.959 11.8358 59.1559 14.5408 60.0293C20.2906 61.8814 26.2332 62.3213 32.2505 62.3586C34.9818 62.1638 37.7159 62.0109 40.4415 61.7639C44.1228 61.4402 47.6561 60.1674 50.6938 58.0709C52.2386 57.0176 52.9937 55.5793 53.033 53.7487C53.0995 50.3856 53.0995 47.0151 53.339 43.6622C53.6029 39.9757 53.9276 36.2752 54.5629 32.6362C55.0513 29.8399 56.0918 27.1368 56.831 24.374C57.3933 22.2627 57.8387 20.1263 57.6665 17.9228Z"
            fill="url(#paint42_linear_44_564)"
        />
        <path
            d="M11.4447 39.106C13.7184 39.8517 16.0763 40.1882 18.4623 40.3494C21.8991 40.5825 25.3377 40.7428 28.7549 40.1276C33.714 39.2337 37.1704 36.6368 38.6862 31.9007C39.0977 30.6344 39.4388 29.3465 39.708 28.0427C41.1742 20.7722 44.8898 14.9185 51.2983 10.7221C51.4723 10.6084 51.5884 10.4127 51.8373 10.1386L50.1371 9.89815C50.8857 7.30594 50.2569 5.45755 48.0786 4.00066C45.8227 2.49622 43.2935 1.98822 40.6156 2.47758C37.7337 3.00422 35.4104 4.56738 33.2733 6.42323C32.5799 7.02444 31.9633 7.88292 31.1483 8.13086C30.3062 8.38626 29.2723 8.03765 28.3207 7.96028C26.294 7.79716 24.2477 7.38237 22.2444 7.52592C15.456 8.00969 10.3125 11.1388 7.10686 16.9794C4.79852 21.1879 4.25302 25.7469 4.56367 30.4327C4.85373 34.8127 7.14148 37.6864 11.4447 39.106ZM44.5146 6.1091C45.8143 6.63388 46.2868 7.49516 46.0566 8.82529C45.963 9.33982 45.7815 9.57098 45.1771 9.50107C42.9801 9.26431 40.7793 9.07976 38.5187 8.87003C39.5386 6.34772 42.166 5.16487 44.5146 6.1119V6.1091ZM13.9467 27.3846C16.3926 26.0797 19.9098 26.4693 21.9796 28.2776C24.1316 30.1568 23.865 32.9168 21.3948 34.3848C20.3159 35.0271 19.1248 35.2638 17.8766 35.2955C16.2008 35.2461 14.6513 34.8295 13.3357 33.7901C10.9067 31.8635 11.1977 28.8537 13.9467 27.3846Z"
            fill="url(#paint43_linear_44_564)"
        />
        <path
            d="M66.0549 39.7824C65.2736 34.8515 64.0778 30.0176 62.6116 25.2414C62.2766 24.1499 61.9014 23.0696 61.5253 21.9231C61.427 22.048 61.3821 22.0816 61.3709 22.1226C60.5344 25.4027 59.6923 28.6809 58.8773 31.9657C58.8024 32.2677 58.8595 32.6238 58.94 32.9342C59.3826 34.6427 59.9458 36.3271 60.292 38.0524C60.8731 40.942 60.6008 43.7383 58.8268 46.2616C58.2653 47.0613 57.7423 47.8881 57.2136 48.7093C57.0855 48.9088 56.9152 49.1344 56.9133 49.3497C56.8918 51.6678 56.9002 53.9879 56.9002 56.3545C57.1201 56.2567 57.3484 56.1681 57.5683 56.0553C63.7232 52.9849 67.1066 46.4387 66.0549 39.7824Z"
            fill="url(#paint44_linear_44_564)"
        />
        <path
            d="M57.645 17.8965C57.4757 15.7256 56.3098 14.773 54.0754 14.9557C52.7551 15.0638 51.607 15.5709 50.5722 16.3353C49.1892 17.3559 48.2021 18.6916 47.3637 20.1383C45.6392 23.1164 44.6147 26.3536 43.6266 29.6021C42.5543 33.1255 41.599 36.6861 39.779 39.9551C39.5732 40.3279 39.4937 40.7847 39.4525 41.2125C39.023 45.6438 38.6179 50.0778 38.1903 54.5101C38.1519 54.9043 38.339 55.5046 37.7018 55.541C37.5362 55.5503 37.2415 54.9435 37.1601 54.5865C36.4424 51.4229 35.7668 48.2481 35.0613 45.0836C34.8658 44.2064 34.3876 43.9389 33.4707 44.1188C33.102 44.1915 32.7343 44.2689 32.3628 44.3286C28.1709 45.0043 23.9519 44.8608 19.7367 44.6781C16.8417 44.5532 13.9916 44.1235 11.2622 43.1438C7.72811 41.8752 4.81345 39.915 3.31355 36.4046C3.23963 36.2322 2.96079 36.1408 2.7774 36.0113C2.63985 36.2201 2.4181 36.414 2.37786 36.6386C1.73318 40.286 1.51329 43.9492 2.19728 47.6133C2.82793 50.9904 4.22116 53.9853 7.07687 56.1981C9.31503 57.9328 11.8489 59.1296 14.5623 60.003C20.3 61.8551 26.2397 62.2951 32.2524 62.3277C34.9809 62.1329 37.7131 61.98 40.4359 61.733C44.1139 61.4102 47.6441 60.1387 50.6788 58.0437C52.2227 56.9904 52.9769 55.5522 53.0181 53.7215C53.0845 50.3584 53.0836 46.9879 53.324 43.6351C53.587 39.9485 53.9116 36.248 54.546 32.6091C55.0335 29.8127 56.074 27.1096 56.8123 24.3468C57.3718 22.2365 57.8209 20.1001 57.645 17.8965Z"
            fill="url(#paint45_linear_44_564)"
        />
        <path
            d="M11.4652 39.0799C13.7361 39.8302 16.0931 40.1621 18.4753 40.3233C21.9093 40.5564 25.3451 40.7167 28.7604 40.1015C33.7195 39.2076 37.1684 36.6107 38.6833 31.8746C39.0942 30.6083 39.4347 29.3203 39.7032 28.0166C41.1685 20.7461 44.8804 14.8924 51.2832 10.696C51.4573 10.5823 51.5733 10.3866 51.8222 10.1125L50.123 9.87203C50.8715 7.27982 50.2428 5.43143 48.0645 3.97453C45.8085 2.4701 43.2813 1.9621 40.6043 2.45146C37.7299 2.98276 35.4084 4.54499 33.2741 6.3971C32.5808 6.99832 31.9642 7.8568 31.1511 8.10474C30.3089 8.36014 29.2797 8.01153 28.3262 7.93416C26.3014 7.77104 24.2569 7.35625 22.2555 7.4998C15.4727 7.98356 10.3339 11.1127 7.13203 16.9533C4.82556 21.159 4.28006 25.7208 4.58977 30.4065C4.87983 34.7866 7.16571 37.6575 11.4652 39.0799ZM44.5051 6.08298C45.8029 6.60776 46.2755 7.46904 46.0453 8.79917C45.9564 9.31369 45.7702 9.5402 45.1667 9.47495C42.9725 9.23819 40.769 9.05363 38.514 8.84391C39.5329 6.3216 42.1594 5.13875 44.5051 6.08578V6.08298ZM13.9644 27.3585C16.4084 26.0536 19.9228 26.4432 21.9898 28.2515C24.1418 30.1306 23.8742 32.8906 21.4059 34.3587C20.328 35.0009 19.1387 35.2377 17.8905 35.2694C16.2166 35.22 14.669 34.808 13.3543 33.764C10.9281 31.8373 11.2182 28.8275 13.9644 27.3585Z"
            fill="url(#paint46_linear_44_564)"
        />
        <path
            d="M66.0241 39.7566C65.2438 34.8257 64.0489 29.9917 62.5836 25.2155C62.2486 24.124 61.8744 23.0437 61.4982 21.8972C61.4046 22.0221 61.3551 22.0557 61.3448 22.0967C60.5083 25.3768 59.6671 28.6551 58.8549 31.9398C58.78 32.2418 58.8371 32.5979 58.9176 32.9083C59.3602 34.6169 59.9225 36.3012 60.2687 38.0265C60.8498 40.9161 60.5775 43.7125 58.8044 46.2357C58.243 47.0354 57.7209 47.8622 57.1931 48.6834C57.0649 48.8829 56.8946 49.1085 56.8928 49.3238C56.8713 51.642 56.8797 53.962 56.8797 56.3286C57.0986 56.2354 57.3279 56.1422 57.5468 56.0294C63.6961 52.959 67.0777 46.4128 66.0241 39.7566Z"
            fill="url(#paint47_linear_44_564)"
        />
        <path
            d="M57.6245 17.8707C57.4551 15.6998 56.2902 14.7471 54.0567 14.9298C52.7383 15.038 51.5912 15.545 50.5572 16.3094C49.1762 17.33 48.189 18.6658 47.3516 20.1124C45.629 23.0905 44.6044 26.3278 43.6173 29.5762C42.5469 33.0996 41.5925 36.6603 39.7735 39.9292C39.5676 40.302 39.4928 40.7588 39.4469 41.1866C39.0184 45.6179 38.6132 50.052 38.1866 54.4842C38.1482 54.8785 38.3353 55.4787 37.6981 55.5151C37.5335 55.5244 37.2387 54.9176 37.1573 54.5606C36.4406 51.397 35.765 48.2222 35.0604 45.053C34.8649 44.1759 34.3877 43.9084 33.4698 44.0883C33.102 44.161 32.7343 44.2384 32.3638 44.298C28.1757 44.9738 23.9604 44.8303 19.748 44.6476C16.8558 44.5227 14.0085 44.0883 11.2809 43.1133C7.75248 41.8521 4.84063 39.8891 3.34166 36.3788C3.27055 36.2063 2.98985 36.115 2.80271 35.9854C2.66516 36.1942 2.44341 36.3881 2.40411 36.6127C1.75942 40.2601 1.53954 43.9224 2.22352 47.5875C2.85324 50.9645 4.24554 53.9594 7.09844 56.1722C9.33472 57.9069 11.8704 59.1037 14.5746 59.9771C20.3112 61.8293 26.2435 62.2692 32.2496 62.3018C34.9762 62.107 37.7056 61.9542 40.4266 61.7071C44.1016 61.3841 47.6287 60.1125 50.6602 58.0178C52.2022 56.9645 52.9563 55.5263 52.9919 53.6956C53.0593 50.3325 53.0583 46.962 53.2979 43.6092C53.5608 39.9227 53.8855 36.2222 54.5143 32.5832C55.0017 29.7868 56.0413 27.0837 56.7786 24.3209C57.3531 22.2106 57.7957 20.0742 57.6245 17.8707Z"
            fill="url(#paint48_linear_44_564)"
        />
        <path
            d="M11.4849 39.0556C13.7549 39.8013 16.1091 40.1377 18.4895 40.299C21.9197 40.532 25.3527 40.6924 28.7652 40.0772C33.7177 39.1833 37.1657 36.5864 38.6834 31.8503C39.0943 30.584 39.4348 29.296 39.7033 27.9923C41.1658 20.7236 44.8711 14.8662 51.2712 10.6698C51.4452 10.5561 51.5603 10.3604 51.8092 10.0863L50.1119 9.84585C50.8604 7.25364 50.2307 5.40525 48.0534 3.94836C45.7984 2.44392 43.2739 1.93592 40.6044 2.42342C37.7309 2.95006 35.4123 4.51695 33.2798 6.36906C32.5874 6.97028 31.9699 7.82876 31.1577 8.0767C30.3203 8.3321 29.2863 7.98349 28.3357 7.90612C26.3127 7.743 24.2701 7.32821 22.2706 7.47176C15.4897 7.95739 10.3556 11.0865 7.15647 16.9271C4.85188 21.1356 4.30637 25.6946 4.61609 30.3804C4.90615 34.7604 7.18922 37.6341 11.4849 39.0556ZM44.4959 6.05867C45.7928 6.58345 46.2643 7.44472 46.0342 8.77485C45.9453 9.28938 45.76 9.51588 45.1565 9.45064C42.9642 9.21388 40.7663 9.02932 38.5131 8.81959C39.5284 6.29542 42.152 5.11257 44.4959 6.05867ZM13.9823 27.3323C16.4244 26.0274 19.9351 26.417 22.0011 28.2253C24.1476 30.1045 23.8828 32.8645 21.4182 34.3325C20.3403 34.9748 19.1519 35.2115 17.9056 35.2432C16.2326 35.1938 14.6859 34.7772 13.3722 33.7378C10.9488 31.8112 11.2389 28.8023 13.9823 27.3323Z"
            fill="url(#paint49_linear_44_564)"
        />
        <path
            d="M65.9951 39.7309C65.2157 34.8 64.0218 29.9661 62.5584 25.1899C62.2234 24.0984 61.8491 23.0181 61.4739 21.8716C61.3803 21.9965 61.3307 22.03 61.3205 22.0711C60.4849 25.3512 59.6446 28.6294 58.8325 31.9142C58.7576 32.2162 58.8147 32.5723 58.8952 32.8827C59.3377 34.5912 59.8991 36.2756 60.2453 38C60.8255 40.8895 60.5532 43.6859 58.7819 46.2101C58.2205 47.0098 57.7003 47.8366 57.1716 48.6578C57.0444 48.8573 56.8741 49.0828 56.8722 49.2982C56.8507 51.6163 56.8591 53.9364 56.8591 56.303C57.0781 56.2098 57.3064 56.1166 57.5253 56.0038C63.67 52.9334 67.0478 46.3872 65.9951 39.7309Z"
            fill="url(#paint50_linear_44_564)"
        />
        <path
            d="M57.6029 17.8443C57.4336 15.6734 56.2696 14.7208 54.0389 14.9035C52.7215 15.0116 51.5753 15.5187 50.5423 16.283C49.1621 17.3037 48.1759 18.6422 47.3413 20.086C45.6196 23.0642 44.5969 26.3014 43.6107 29.5498C42.5412 33.0732 41.5868 36.6339 39.7744 39.9028C39.5686 40.2757 39.489 40.7324 39.4479 41.1602C39.0193 45.5915 38.6151 50.0256 38.1884 54.4578C38.1501 54.8521 38.3372 55.4524 37.7009 55.4887C37.5363 55.4981 37.2406 54.8912 37.1601 54.5342C36.4434 51.3706 35.7687 48.1959 35.0651 45.0313C34.8695 44.1542 34.3923 43.8867 33.4744 44.0666C33.1058 44.1393 32.739 44.2167 32.3694 44.2763C28.185 44.9521 23.9735 44.8085 19.7648 44.6259C16.8754 44.5009 14.03 44.0712 11.3053 43.0916C7.77774 41.823 4.86869 39.8627 3.3716 36.3524C3.29768 36.18 3.01884 36.0886 2.83639 35.959C2.69884 36.1678 2.47708 36.3617 2.43778 36.5864C1.79403 40.2337 1.57415 43.896 2.2572 47.5611C2.88691 50.9381 4.27734 53.933 7.12837 56.1459C9.36092 57.8843 11.8929 59.0774 14.5923 59.9508C20.3243 61.8029 26.251 62.2428 32.2524 62.2755C34.9762 62.0807 37.7037 61.9278 40.4219 61.6808C44.094 61.3575 47.618 60.0859 50.6461 57.9915C52.1872 56.9382 52.9395 55.4999 52.976 53.6692C53.0424 50.3062 53.0424 46.9356 53.281 43.5828C53.5449 39.8963 53.8677 36.1958 54.4974 32.5568C54.984 29.7605 56.0226 27.0573 56.759 24.2945C57.3297 22.1842 57.7742 20.0478 57.6029 17.8443Z"
            fill="url(#paint51_linear_44_564)"
        />
        <path
            d="M11.5046 39.0277C13.7718 39.7781 16.1241 40.1099 18.5026 40.2712C21.93 40.5042 25.3593 40.6645 28.7689 40.0493C33.7168 39.1554 37.162 36.5585 38.6741 31.8225C39.0843 30.5557 39.4244 29.2674 39.693 27.9635C41.1555 20.693 44.8608 14.8393 51.2525 10.6429C51.4265 10.5292 51.5425 10.3335 51.7905 10.0594L50.0941 9.81893C50.8427 7.22672 50.2139 5.37833 48.0356 3.92143C45.79 2.417 43.2636 1.90899 40.5932 2.39836C37.7225 2.925 35.4057 4.49189 33.2752 6.344C32.5828 6.94522 31.9652 7.8037 31.1549 8.05164C30.3175 8.30704 29.2835 7.95843 28.3348 7.88106C26.3146 7.71794 24.2729 7.30315 22.2753 7.44669C15.5056 7.93046 10.3752 11.0596 7.17986 16.9002C4.87714 21.1087 4.33257 25.6677 4.64229 30.3534C4.93235 34.7344 7.21355 37.6081 11.5046 39.0277ZM44.4856 6.03081C45.7815 6.55559 46.2531 7.41687 46.0229 8.747C45.9341 9.26152 45.7488 9.48803 45.1453 9.42278C42.9558 9.18602 40.7588 9.00146 38.5019 8.79174C39.5237 6.26943 42.1445 5.08658 44.4865 6.03361L44.4856 6.03081ZM14.001 27.311C16.4403 26.0061 19.9482 26.3957 22.0114 28.204C24.156 30.0831 23.8921 32.8431 21.4294 34.3112C20.3524 34.9534 19.165 35.1902 17.9197 35.2209C16.2485 35.1725 14.7037 34.7605 13.3909 33.7165C10.9694 31.7852 11.2594 28.7754 14.001 27.311Z"
            fill="url(#paint52_linear_44_564)"
        />
        <path
            d="M65.9661 39.7085C65.1876 34.7776 63.9947 29.9436 62.5322 25.1675C62.1981 24.075 61.8248 22.9956 61.4487 21.8491C61.3551 21.974 61.3064 22.0076 61.2961 22.0486C60.4615 25.3287 59.6213 28.607 58.81 31.8917C58.7352 32.1937 58.7922 32.5498 58.8727 32.8602C59.3144 34.5688 59.8758 36.2531 60.222 37.9775C60.8012 40.8671 60.5298 43.6634 58.7595 46.1876C58.1981 46.9874 57.6788 47.8141 57.1511 48.6353C57.0238 48.8348 56.8535 49.0604 56.8516 49.2757C56.8301 51.5939 56.8385 53.9139 56.8385 56.2805C57.0575 56.1873 57.2858 56.0941 57.5047 55.9813C63.6428 52.9072 67.0178 46.361 65.9661 39.7085Z"
            fill="url(#paint53_linear_44_564)"
        />
        <path
            d="M57.5813 17.8184C57.4129 15.6475 56.2498 14.6949 54.021 14.8776C52.7045 14.9857 51.5592 15.4928 50.5272 16.2571C49.1489 17.2825 48.1636 18.6135 47.3281 20.0602C45.6073 23.0383 44.5856 26.2755 43.6003 29.5239C42.5317 33.0473 41.5783 36.608 39.764 39.8769C39.5591 40.2498 39.4833 40.7065 39.4384 41.1344C39.0098 45.5656 38.6056 49.9997 38.1799 54.4319C38.1415 54.8262 38.3277 55.4265 37.6924 55.4629C37.5277 55.4722 37.233 54.8654 37.1525 54.5084C36.4367 51.3448 35.763 48.17 35.0594 45.0054C34.8647 44.1283 34.3875 43.8608 33.4687 44.0407C33.101 44.1134 32.7342 44.1908 32.3646 44.2504C28.184 44.9262 23.9762 44.7827 19.7722 44.6C16.8846 44.4751 14.042 44.0407 11.3201 43.0657C7.79632 41.7971 4.88915 39.8369 3.39299 36.3265C3.31907 36.1531 3.04117 36.0627 2.85778 35.9332C2.72117 36.142 2.50035 36.3358 2.46011 36.5605C1.8173 40.2078 1.59741 43.8701 2.28046 47.5352C2.90924 50.9123 4.29873 53.9072 7.14602 56.12C9.37857 57.8547 11.9096 59.0515 14.609 59.9249C20.3354 61.777 26.2574 62.217 32.2532 62.2496C34.9742 62.0548 37.6989 61.9019 40.4152 61.6549C44.0844 61.3314 47.6053 60.0597 50.6301 57.9656C52.1693 56.9123 52.9216 55.474 52.9581 53.6434C53.0245 50.2803 53.0236 46.9098 53.2631 43.5569C53.526 39.8704 53.8488 36.1699 54.4795 32.5309C54.966 29.7346 56.0028 27.0315 56.7392 24.2687C57.309 22.1584 57.7525 20.0219 57.5813 17.8184Z"
            fill="url(#paint54_linear_44_564)"
        />
        <path
            d="M11.5252 39.0016C13.7914 39.752 16.1409 40.0838 18.5166 40.2451C21.9422 40.4781 25.3687 40.6384 28.7745 40.0232C33.7187 39.1293 37.1601 36.5325 38.6703 31.7964C39.0802 30.5299 39.4201 29.2419 39.6884 27.9384C41.149 20.6678 44.8515 14.8142 51.2375 10.6178C51.4106 10.5041 51.5267 10.3083 51.7746 10.0343L50.0801 9.79378C50.8286 7.20157 50.1989 5.35318 48.0216 3.89629C45.7759 2.39185 43.2543 1.88385 40.5857 2.37321C37.7169 2.89985 35.402 4.46674 33.2733 6.31886C32.5818 6.92007 31.9634 7.77855 31.1559 8.02649C30.3194 8.28189 29.2845 7.93328 28.3385 7.85591C26.3193 7.69279 24.2805 7.278 22.2837 7.42155C15.5224 7.90532 10.3977 11.0344 7.20515 16.8713C4.9043 21.0836 4.35973 25.6425 4.67412 30.3283C4.95482 34.7083 7.2379 37.582 11.5252 39.0016ZM44.4772 6.00473C45.7713 6.52951 46.2428 7.39079 46.0127 8.72092C45.9238 9.23545 45.7385 9.46661 45.1369 9.3967C42.9483 9.15994 40.7541 8.97539 38.5019 8.76566C39.519 6.24522 42.1371 5.0605 44.4772 6.00753V6.00473ZM14.0188 27.2803C16.4516 25.9753 19.9604 26.3649 22.0226 28.1732C24.1654 30.0524 23.9015 32.8124 21.4407 34.2805C20.3646 34.9218 19.1782 35.1595 17.9337 35.1902C16.2644 35.1417 14.7206 34.7297 13.4097 33.6858C10.99 31.7591 11.2791 28.7493 14.0188 27.2803Z"
            fill="url(#paint55_linear_44_564)"
        />
        <path
            d="M65.938 39.6784C65.1595 34.7475 63.9731 29.9136 62.5069 25.1374C62.1728 24.045 61.7995 22.9656 61.4243 21.8191C61.3307 21.944 61.2821 21.9776 61.2708 22.0186C60.4409 25.2987 59.5988 28.5769 58.7875 31.8617C58.7127 32.1637 58.7697 32.5198 58.8502 32.8302C59.2919 34.5387 59.8523 36.2231 60.1976 37.9475C60.7768 40.837 60.5054 43.6334 58.737 46.1576C58.1756 46.9573 57.6572 47.7841 57.1304 48.6053C57.0022 48.8048 56.8329 49.0303 56.831 49.2457C56.8095 51.5638 56.8179 53.8839 56.8179 56.2505C57.0369 56.1526 57.2642 56.0641 57.4832 55.9513C63.6166 52.8809 66.9907 46.3347 65.938 39.6784Z"
            fill="url(#paint56_linear_44_564)"
        />
        <path
            d="M57.5599 17.7923C57.3915 15.6214 56.2303 14.6688 54.0043 14.8515C52.6887 14.9596 51.5444 15.4667 50.5132 16.231C49.1359 17.2563 48.1516 18.5874 47.3169 20.034C45.5981 23.0121 44.5772 26.2494 43.5929 29.4978C42.5244 33.0212 41.5728 36.5819 39.7566 39.8508C39.5507 40.2237 39.4759 40.6804 39.431 41.1082C39.0024 45.5395 38.5992 49.9736 38.1734 54.4058C38.1351 54.8001 38.3213 55.4004 37.6859 55.4367C37.5222 55.446 37.2274 54.8392 37.147 54.4822C36.4321 51.3186 35.7584 48.1439 35.0557 44.9793C34.8611 44.1022 34.3839 43.8347 33.4716 44.0146C33.1039 44.0873 32.738 44.1647 32.3684 44.2243C28.1915 44.9001 23.9875 44.7565 19.7863 44.5739C16.9025 44.4489 14.0627 44.0192 11.3427 43.0396C7.82168 41.771 4.91732 39.8107 3.42303 36.3004C3.34911 36.127 3.07121 36.0366 2.88782 35.907C2.75121 36.1158 2.53039 36.3097 2.49109 36.5344C1.84827 40.1817 1.62932 43.844 2.3105 47.5091C2.93928 50.8861 4.3269 53.881 7.17606 56.0939C9.40673 57.8285 11.935 59.0254 14.6325 59.8988C20.3533 61.7509 26.2696 62.1908 32.2599 62.2235C34.979 62.0286 37.7018 61.8758 40.4153 61.6288C44.08 61.3044 47.5962 60.0327 50.6162 57.9395C52.1544 56.8862 52.9058 55.4479 52.9423 53.6172C53.0087 50.2542 53.0078 46.8836 53.2464 43.5308C53.5093 39.8443 53.8321 36.1438 54.4628 32.5048C54.9493 29.7085 55.9851 27.0053 56.7206 24.2425C57.2876 22.1322 57.732 19.9958 57.5599 17.7923Z"
            fill="url(#paint57_linear_44_564)"
        />
        <path
            d="M11.5457 38.9755C13.8091 39.7259 16.1568 40.0577 18.5306 40.219C21.9524 40.452 25.3761 40.6123 28.7792 39.9971C33.7186 39.1032 37.1573 36.5063 38.6665 31.7703C39.0762 30.5038 39.4155 29.2159 39.6827 27.9122C41.1433 20.6417 44.8411 14.788 51.2216 10.5917C51.3947 10.4779 51.5107 10.2822 51.7586 10.0081L50.0651 9.76766C50.8136 7.17545 50.1848 5.32706 48.0065 3.87016C45.7609 2.36573 43.2439 1.85773 40.5772 2.34709C37.7121 2.87373 35.3991 4.44062 33.2723 6.29273C32.5818 6.89395 31.967 7.75243 31.1558 8.00037C30.3202 8.25577 29.2844 7.90716 28.3413 7.82979C26.3239 7.66667 24.2869 7.25188 22.293 7.39542C15.5345 7.87919 10.4145 11.0083 7.22847 16.8489C4.9295 21.0574 4.3868 25.6164 4.69558 30.3022C4.9847 34.6822 7.26216 37.5559 11.5457 38.9755ZM44.4678 5.97861C45.7609 6.50339 46.2316 7.36467 46.0023 8.69479C45.9134 9.20932 45.7282 9.44049 45.1265 9.37058C42.9436 9.13475 40.7475 8.94833 38.4981 8.74233C39.5133 6.21723 42.1295 5.03344 44.4678 5.98141V5.97861ZM14.0365 27.2542C16.4693 25.9492 19.9734 26.3388 22.0329 28.1471C24.1737 30.0263 23.9108 32.7863 21.4518 34.2543C20.3767 34.8956 19.1921 35.1333 17.9486 35.1641C16.2803 35.1156 14.7383 34.7036 13.4283 33.6597C11.0105 31.733 11.2996 28.7232 14.0365 27.2542Z"
            fill="url(#paint58_linear_44_564)"
        />
        <path
            d="M65.9089 39.6523C65.1314 34.7214 63.944 29.8875 62.4815 25.1113C62.1475 24.0189 61.7741 22.9395 61.3998 21.793C61.3063 21.9179 61.2567 21.9514 61.2464 21.9924C60.4136 25.2726 59.5753 28.5508 58.765 31.8356C58.691 32.1376 58.7472 32.4937 58.8277 32.8041C59.2693 34.5126 59.8288 36.197 60.1741 37.9214C60.7533 40.8109 60.4819 43.6073 58.7154 46.1314C58.154 46.9312 57.6356 47.758 57.1097 48.5792C56.9815 48.7787 56.8122 49.0042 56.8103 49.2195C56.7888 51.5377 56.7972 53.8578 56.7972 56.2244C57.0162 56.1265 57.2435 56.038 57.4625 55.9252C63.5893 52.8511 66.9588 46.3085 65.9089 39.6523Z"
            fill="url(#paint59_linear_44_564)"
        />
        <path
            d="M57.5403 17.7662C57.3719 15.5953 56.2107 14.6427 53.9847 14.8254C52.6747 14.9335 51.5285 15.4405 50.4974 16.2049C49.1219 17.2302 48.1385 18.5613 47.3039 20.0079C45.5869 22.986 44.567 26.2233 43.5827 29.4717C42.516 32.9951 41.5644 36.5558 39.7529 39.8247C39.548 40.1975 39.4722 40.6543 39.4273 41.0812C38.9997 45.5134 38.5974 49.9475 38.1716 54.3797C38.1333 54.774 38.3195 55.3743 37.6851 55.4106C37.5204 55.4199 37.2266 54.8131 37.1461 54.4561C36.4313 51.2925 35.7585 48.1177 35.0567 44.9485C34.8621 44.0714 34.3858 43.8039 33.4736 43.9838C33.1068 44.0565 32.7409 44.1339 32.3713 44.1935C28.1982 44.8693 23.9988 44.7258 19.8013 44.5431C16.9194 44.4182 14.0824 43.9885 11.3652 43.0088C7.84702 41.7402 4.94547 39.78 3.45211 36.2696C3.37819 36.0962 3.10123 36.0058 2.91784 35.8763C2.78123 36.085 2.56041 36.2789 2.52111 36.5036C1.87923 40.1509 1.66028 43.8132 2.34146 47.4783C2.96836 50.8554 4.35598 53.8502 7.19859 56.0631C9.4274 57.7978 11.9528 58.9946 14.6476 59.868C20.3646 61.7201 26.2744 62.1601 32.26 62.1927C34.9735 61.9979 37.6963 61.845 40.407 61.598C44.0713 61.2747 47.5872 60.0029 50.6059 57.9087C52.1423 56.8554 52.8937 55.4171 52.9292 53.5865C52.9957 50.2234 52.9957 46.8529 53.2343 43.5C53.4963 39.8135 53.8191 36.113 54.4507 32.474C54.9363 29.6777 55.9712 26.9745 56.7057 24.2118C57.2671 22.1061 57.7106 19.9697 57.5403 17.7662Z"
            fill="url(#paint60_linear_44_564)"
        />
        <path
            d="M11.5663 38.9493C13.8269 39.6996 16.1727 40.0314 18.5446 40.1927C21.9636 40.4257 25.3835 40.5861 28.7838 39.9709C33.7186 39.077 37.1545 36.4801 38.6618 31.744C39.0715 30.4776 39.4108 29.1896 39.678 27.886C41.1367 20.6155 44.8317 14.7618 51.2066 10.5654C51.3797 10.4517 51.4948 10.2559 51.7427 9.9819L50.0547 9.74141C50.8033 7.1492 50.1736 5.30082 48.0028 3.84392C45.7571 2.33948 43.2439 1.83148 40.58 2.32084C37.7168 2.84749 35.4066 4.41437 33.2817 6.26649C32.5911 6.8677 31.9773 7.72618 31.167 7.97412C30.3324 8.22952 29.3013 7.88091 28.3553 7.80355C26.3398 7.64043 24.3038 7.22563 22.3117 7.36918C15.5598 7.85202 10.4435 10.9821 7.25653 16.8227C4.95474 21.0312 4.41298 25.5902 4.72176 30.2759C5.01088 34.6559 7.28553 37.5296 11.5663 38.9493ZM44.4584 5.95236C45.7506 6.47994 46.2184 7.34122 45.9911 8.66855C45.9022 9.18308 45.7178 9.41424 45.1162 9.34433C42.9323 9.10758 40.7419 8.92302 38.4944 8.71329C39.5086 6.19099 42.122 5.00813 44.4584 5.95516V5.95236ZM14.0543 27.2279C16.4871 25.9229 19.9856 26.3126 22.0441 28.1209C24.1831 30 23.9155 32.76 21.463 34.2281C20.3935 34.8694 19.2052 35.1071 17.9608 35.1406C16.2943 35.0922 14.7532 34.6802 13.4451 33.6362C11.0311 31.7067 11.3174 28.6969 14.0543 27.2279Z"
            fill="url(#paint61_linear_44_564)"
        />
        <path
            d="M65.8801 39.6262C65.1034 34.6953 63.9151 29.8613 62.4555 25.0852C62.1252 23.9927 61.7509 22.9133 61.3766 21.7668C61.283 21.8917 61.2344 21.9253 61.2241 21.9663C60.3913 25.2464 59.5539 28.5247 58.7445 31.8095C58.6706 32.1115 58.7268 32.4675 58.8072 32.7779C59.2479 34.4865 59.8075 36.1708 60.1527 37.8952C60.731 40.7848 60.4596 43.5812 58.6949 46.1053C58.1335 46.9051 57.617 47.7319 57.0912 48.5531C56.963 48.7525 56.7936 48.9781 56.7918 49.1934C56.7702 51.5116 56.7787 53.8316 56.7787 56.1983C56.9967 56.1051 57.225 56.0118 57.443 55.8991C63.5633 52.8287 66.929 46.2824 65.8801 39.6262Z"
            fill="url(#paint62_linear_44_564)"
        />
        <path
            d="M57.5178 17.7403C57.3493 15.5694 56.19 14.6168 53.9622 14.7995C52.6522 14.9076 51.5079 15.4147 50.4777 16.179C49.1032 17.2043 48.1207 18.5354 47.287 19.982C45.5719 22.9601 44.552 26.1974 43.5695 29.4458C42.5038 32.9692 41.5531 36.5299 39.7435 39.7988C39.5386 40.1717 39.4628 40.6284 39.4179 41.0553C38.9959 45.4875 38.5926 49.9216 38.1715 54.3538C38.1332 54.7481 38.3194 55.3484 37.685 55.3847C37.5212 55.394 37.2274 54.7872 37.147 54.4302C36.4293 51.2666 35.7575 48.0974 35.0557 44.9283C34.8611 44.0511 34.3858 43.7836 33.4744 43.9635C33.1076 44.0362 32.7418 44.1136 32.3731 44.1732C28.2037 44.849 24.0072 44.7055 19.8143 44.5228C16.9352 44.3979 14.1001 43.9682 11.3848 42.9885C7.87033 41.7199 4.97158 39.7597 3.47917 36.2493C3.40618 36.0759 3.12828 35.9855 2.94582 35.856C2.80921 36.0648 2.58839 36.2586 2.54909 36.4833C1.90721 40.1306 1.6892 43.7929 2.36944 47.458C2.99635 50.8351 4.3821 53.83 7.22284 56.0428C9.44603 57.7765 11.9724 58.9734 14.6653 59.8468C20.373 61.6989 26.2818 62.1388 32.2618 62.1715C34.9752 61.9766 37.6887 61.8238 40.4022 61.5768C44.0629 61.252 47.5747 59.9792 50.589 57.8847C52.1245 56.8314 52.8749 55.3931 52.9105 53.5624C52.9769 50.1994 52.9769 46.8288 53.2146 43.476C53.4765 39.7895 53.7994 36.089 54.4309 32.45C54.9156 29.6537 55.9505 26.9505 56.685 24.1877C57.2464 22.0802 57.689 19.9475 57.5178 17.7403Z"
            fill="url(#paint63_linear_44_564)"
        />
        <path
            d="M11.5859 38.9231C13.8456 39.6735 16.1895 40.0053 18.5586 40.1666C21.9739 40.3996 25.3891 40.5599 28.7884 39.9447C33.7195 39.0508 37.1516 36.454 38.6581 31.7179C39.0659 30.4514 39.4032 29.1634 39.6686 27.8599C41.1264 20.5894 44.8149 14.7357 51.1869 10.5393C51.36 10.4256 51.4751 10.2298 51.723 9.95578L50.0322 9.71529C50.7808 7.12308 50.1511 5.27469 47.9821 3.81779C45.7412 2.31336 43.2279 1.80536 40.5668 2.29472C37.7093 2.82136 35.4009 4.38825 33.2779 6.24502C32.5883 6.84624 31.9754 7.70472 31.166 7.95266C30.3314 8.20806 29.3012 7.85945 28.359 7.78208C26.3454 7.61896 24.3121 7.20417 22.321 7.34772C15.5728 7.82962 10.4612 10.9559 7.27706 16.7966C4.98183 21.0051 4.44007 25.5641 4.75071 30.2498C5.03703 34.6298 7.30981 37.5035 11.5859 38.9231ZM44.449 5.92624C45.7393 6.45102 46.21 7.3123 45.9807 8.64243C45.8918 9.15695 45.7075 9.38812 45.1068 9.31821C42.9238 9.08145 40.7353 8.89689 38.4896 8.68717C39.5039 6.16486 42.1154 4.98201 44.449 5.92904V5.92624ZM14.072 27.2018C16.5048 25.8968 19.9986 26.2864 22.0543 28.0948C24.1914 29.9739 23.9257 32.7339 21.4742 34.202C20.401 34.8433 19.2183 35.081 17.9775 35.1117C16.312 35.0623 14.7728 34.6513 13.4647 33.6073C11.0516 31.6806 11.3407 28.6708 14.072 27.2018Z"
            fill="url(#paint64_linear_44_564)"
        />
        <path
            d="M65.852 39.5998C65.0754 34.6689 63.8871 29.835 62.4302 25.0588C62.0971 23.9664 61.7247 22.886 61.3504 21.7405C61.2568 21.8654 61.2082 21.8989 61.1979 21.94C60.3661 25.2201 59.5296 28.4983 58.7211 31.7831C58.6463 32.0851 58.7034 32.4412 58.7829 32.7516C59.2236 34.4601 59.7831 36.1445 60.1275 37.8689C60.7048 40.7584 60.4344 43.5548 58.6706 46.079C58.1092 46.8787 57.5936 47.7055 57.0678 48.5267C56.9405 48.7262 56.7721 48.9517 56.7693 49.1671C56.7478 51.4852 56.7562 53.8053 56.7562 56.1719C56.9742 56.0787 57.2016 55.9855 57.4205 55.8727C63.5362 52.8023 66.8972 46.2561 65.852 39.5998Z"
            fill="url(#paint65_linear_44_564)"
        />
        <path
            d="M57.4972 17.7101C57.3287 15.5392 56.1704 14.5866 53.949 14.7693C52.6391 14.8774 51.4966 15.3845 50.4674 16.1489C49.0947 17.1742 48.1122 18.5052 47.2795 19.9519C45.5634 22.93 44.5473 26.171 43.5657 29.4194C42.5009 32.9428 41.5512 36.5035 39.7425 39.7724C39.5376 40.1452 39.4618 40.602 39.4179 41.0289C38.9912 45.4611 38.5888 49.8952 38.164 54.3274C38.1257 54.7217 38.3119 55.322 37.6784 55.3583C37.5147 55.3676 37.2209 54.7608 37.1404 54.4038C36.4274 51.2402 35.7556 48.0654 35.0548 44.9009C34.8601 44.0238 34.3848 43.7563 33.4744 43.9362C33.1085 44.0089 32.7427 44.0862 32.374 44.1459C28.2084 44.8217 24.0165 44.6781 19.8265 44.4954C16.9502 44.3705 14.1188 43.9408 11.4053 42.9612C7.8937 41.6926 4.99776 39.7323 3.50721 36.222C3.4333 36.0486 3.15633 35.9582 2.97387 35.8286C2.83726 36.0374 2.61738 36.2313 2.57808 36.4559C1.9362 40.1033 1.71819 43.7656 2.39749 47.4307C3.0244 50.8077 4.40921 53.8026 7.24621 56.0154C9.47127 57.7501 11.992 58.9469 14.6821 59.8203C20.3898 61.6725 26.2846 62.1124 32.2627 62.145C34.9762 61.9502 37.6896 61.7974 40.3956 61.5504C44.0535 61.2263 47.5625 59.9544 50.574 57.861C52.1132 56.8077 52.8618 55.3676 52.8936 53.5388C52.96 50.1757 52.9591 46.8052 53.1977 43.4524C53.4597 39.7659 53.7816 36.0654 54.4141 32.4264C54.8988 29.63 55.9327 26.9269 56.6663 24.1641C57.2249 22.0538 57.6674 19.9174 57.4972 17.7101Z"
            fill="url(#paint66_linear_44_564)"
        />
        <path
            d="M11.6066 38.8972C13.8634 39.6475 16.2055 39.9794 18.5727 40.1406C21.9852 40.3737 25.4032 40.534 28.7932 39.9188C33.7196 39.0249 37.1489 36.428 38.6544 31.6919C39.0626 30.4252 39.4012 29.1373 39.6687 27.8339C41.1246 20.5634 44.815 14.7097 51.1776 10.5133C51.3507 10.3996 51.4658 10.2039 51.7138 9.92983L50.022 9.694C50.7706 7.10179 50.1409 5.2534 47.9738 3.79651C45.7347 2.28741 43.2243 1.77941 40.5641 2.26877C37.7038 2.79635 35.4001 4.3623 33.2789 6.21442C32.5902 6.81563 31.9774 7.67411 31.1689 7.92298C30.3352 8.17838 29.306 7.82977 28.3619 7.75241C26.3492 7.58929 24.3178 7.1745 22.3286 7.31804C15.5917 7.80088 10.4828 10.9309 7.30055 16.7716C5.00812 20.9801 4.46636 25.539 4.7742 30.2248C5.06239 34.6039 7.33423 37.4776 11.6066 38.8972ZM44.4407 5.90309C45.7301 6.42787 46.1998 7.28915 45.9705 8.61928C45.8826 9.1338 45.6973 9.36497 45.0975 9.29506C42.9174 9.0583 40.7307 8.87374 38.4869 8.66402C39.4984 6.13891 42.108 4.95606 44.4407 5.90309ZM14.0908 27.1758C16.5236 25.8709 20.0109 26.2605 22.0657 28.0688C24.2009 29.9479 23.937 32.7079 21.4855 34.176C20.4142 34.8173 19.2315 35.055 17.9917 35.0858C16.328 35.0364 14.7898 34.6253 13.4836 33.5813C11.0723 31.6547 11.3614 28.6448 14.0908 27.1758Z"
            fill="url(#paint67_linear_44_564)"
        />
        <path
            d="M65.8229 39.5739C65.0473 34.643 63.858 29.8091 62.4039 25.0329C62.0718 23.9405 61.6994 22.8602 61.326 21.7146C61.2325 21.8395 61.1838 21.8731 61.1735 21.9141C60.3473 25.1942 59.5052 28.4724 58.6986 31.7572C58.6238 32.0592 58.6809 32.4153 58.7604 32.7257C59.2011 34.4343 59.7597 36.1186 60.104 37.843C60.6804 40.7326 60.41 43.5289 58.6481 46.0531C58.0867 46.8528 57.5721 47.6796 57.0472 48.5008C56.9199 48.7003 56.7515 48.9259 56.7496 49.1412C56.7281 51.4593 56.7356 53.7794 56.7356 56.146C56.9536 56.0481 57.181 55.9596 57.399 55.8459C63.5099 52.7764 66.87 46.233 65.8229 39.5739Z"
            fill="url(#paint68_linear_44_564)"
        />
        <path
            d="M57.4757 17.6878C57.3073 15.5169 56.1498 14.5643 53.9313 14.747C52.6214 14.8551 51.4808 15.3622 50.4525 16.1265C49.0807 17.1518 48.1002 18.4829 47.2711 19.9295C45.5588 22.9077 44.5408 26.1449 43.5602 29.3933C42.4963 32.9167 41.5476 36.4774 39.7407 39.7463C39.5358 40.1192 39.46 40.5759 39.4161 41.0028C38.9894 45.435 38.587 49.8691 38.1632 54.3013C38.1248 54.6956 38.311 55.2959 37.6776 55.3322C37.5138 55.3416 37.2209 54.7347 37.1405 54.3778C36.4275 51.2141 35.7566 48.0394 35.0567 44.8748C34.8621 43.9977 34.3877 43.7302 33.4782 43.9101C33.1124 43.9828 32.7465 44.0602 32.3788 44.1198C28.2169 44.7956 24.0278 44.6521 19.8406 44.4694C16.9662 44.3445 14.1329 43.9147 11.4269 42.9351C7.91717 41.6656 5.02403 39.7081 3.53442 36.1959C3.46144 36.0225 3.18448 35.9321 3.00202 35.8025C2.86541 36.0113 2.64552 36.2052 2.60622 36.4299C1.96528 40.081 1.74726 43.7395 2.42844 47.4046C3.05442 50.7816 4.43736 53.7765 7.27248 55.9894C9.49567 57.724 12.0145 58.9209 14.7018 59.7943C20.4029 61.6464 26.2978 62.0863 32.2665 62.119C34.9753 61.9242 37.6879 61.7713 40.392 61.5243C44.0469 61.2002 47.5529 59.9282 50.561 57.835C52.0936 56.7817 52.8422 55.3434 52.8787 53.5127C52.9451 50.1487 52.9442 46.7791 53.1819 43.4263C53.4438 39.7398 53.7657 36.0393 54.3982 32.4003C54.882 29.604 55.915 26.9008 56.6439 24.138C57.2044 22.0277 57.6469 19.8913 57.4757 17.6878Z"
            fill="url(#paint69_linear_44_564)"
        />
        <path
            d="M11.6262 38.8693C13.8812 39.6197 16.2213 39.9515 18.5867 40.1127C21.9954 40.3458 25.4069 40.5061 28.7978 39.8909C33.7195 38.997 37.146 36.4001 38.6497 31.6641C39.0577 30.3974 39.3956 29.1095 39.6621 27.806C41.1171 20.5355 44.8027 14.6818 51.1598 10.4854C51.3319 10.3717 51.448 10.176 51.695 9.90194L50.0107 9.66146C50.7593 7.06924 50.1286 5.22086 47.9635 3.76396C45.7272 2.25953 43.2177 1.75152 40.5613 2.24088C37.7037 2.76939 35.3991 4.33628 33.2798 6.1884C32.5911 6.78961 31.9792 7.64809 31.1707 7.89603C30.338 8.15143 29.3097 7.80282 28.3637 7.72545C26.3538 7.56233 24.3234 7.14754 22.3369 7.29109C15.6056 7.77392 10.5033 10.9058 7.32481 16.7446C5.03425 20.9531 4.49249 25.5121 4.80033 30.2006C5.08852 34.5816 7.35756 37.4516 11.6262 38.8693ZM44.4303 5.87241C45.7188 6.39719 46.1875 7.25846 45.9592 8.58859C45.8657 9.10312 45.686 9.33429 45.0862 9.26438C42.908 9.02762 40.7231 8.84306 38.4822 8.63334C39.4936 6.11289 42.1014 4.93004 44.4303 5.87707V5.87241ZM14.1085 27.1498C16.5348 25.8449 20.023 26.2345 22.0759 28.0428C24.2092 29.9219 23.9472 32.6819 21.4967 34.15C20.4263 34.7913 19.2445 35.029 18.0066 35.0597C16.3439 35.0103 14.8075 34.5993 13.5022 33.5553C11.0928 31.6286 11.381 28.616 14.1085 27.1498Z"
            fill="url(#paint70_linear_44_564)"
        />
        <path
            d="M65.794 39.5478C65.0201 34.6169 63.829 29.783 62.3787 25.0068C62.0465 23.9144 61.6751 22.834 61.3008 21.6885C61.2072 21.8134 61.1595 21.8469 61.1492 21.888C60.3183 25.1681 59.4837 28.4463 58.6762 31.7311C58.6023 32.0331 58.6584 32.3892 58.7389 32.6996C59.1787 34.4081 59.7363 36.0925 60.0807 37.8169C60.657 40.7064 60.3866 43.5028 58.6266 46.027C58.0652 46.8267 57.5515 47.6535 57.0266 48.4747C56.8993 48.6742 56.7309 48.8997 56.729 49.1151C56.7075 51.4332 56.715 53.7523 56.715 56.1199C56.933 56.022 57.1604 55.9335 57.3784 55.8198C63.4837 52.7503 66.8401 46.2041 65.794 39.5478Z"
            fill="url(#paint71_linear_44_564)"
        />
        <path
            d="M57.4541 17.6617C57.2866 15.4908 56.1301 14.5382 53.9135 14.7209C52.6035 14.829 51.4648 15.3361 50.4384 16.1004C49.0676 17.1257 48.087 18.4568 47.257 19.9034C45.5466 22.8815 44.5295 26.1188 43.5499 29.3672C42.486 32.8906 41.5381 36.4513 39.7332 39.7202C39.5292 40.0931 39.4525 40.5498 39.4094 40.9767C38.9828 45.4089 38.5814 49.843 38.1575 54.2752C38.1191 54.6695 38.3053 55.2698 37.6728 55.3061C37.5091 55.3154 37.2162 54.7086 37.1357 54.3516C36.4237 51.188 35.7537 48.0132 35.0538 44.844C34.8601 43.9669 34.3858 43.6994 33.4763 43.8793C33.1104 43.952 32.7464 44.0294 32.3787 44.089C28.2205 44.7648 24.0352 44.6213 19.8536 44.4386C16.982 44.3137 14.1544 43.884 11.4474 42.9043C7.9405 41.6404 5.0483 39.6801 3.56149 36.166C3.48851 35.9927 3.21155 35.9023 3.03002 35.7727C2.89341 35.9806 2.67353 36.1754 2.63423 36.4C1.99422 40.0511 1.7734 43.7162 2.45458 47.3785C3.08336 50.7546 4.46256 53.7504 7.29394 55.9633C9.51432 57.6979 12.0313 58.8948 14.7158 59.7682C20.4122 61.6203 26.3014 62.0602 32.2655 62.0928C34.9715 61.898 37.6822 61.7452 40.3835 61.4982C44.0357 61.1737 47.5389 59.9017 50.5441 57.8088C52.0749 56.7556 52.8225 55.3173 52.859 53.4866C52.9254 50.1226 52.9245 46.753 53.1621 43.4002C53.4232 39.7137 53.7451 36.0132 54.3785 32.3742C54.8623 29.5779 55.8943 26.8747 56.6242 24.1119C57.1837 21.9979 57.6254 19.8652 57.4541 17.6617Z"
            fill="url(#paint72_linear_44_564)"
        />
        <path
            d="M11.6468 38.8449C13.8999 39.5952 16.2372 39.9271 18.5999 40.0883C22.0067 40.3214 25.4144 40.4817 28.7988 39.8665C33.7158 38.9726 37.1395 36.3757 38.6422 31.6396C39.0495 30.3729 39.3871 29.085 39.6537 27.7816C41.1068 20.5111 44.7887 14.6574 51.1402 10.461C51.3133 10.3473 51.4283 10.1516 51.6754 9.87753L49.9911 9.63704C50.7397 7.04483 50.11 5.19644 47.9467 3.73955C45.7113 2.23511 43.2046 1.72711 40.5548 2.21647C37.7019 2.74312 35.3991 4.31 33.2817 6.16212C32.594 6.76333 31.983 7.62181 31.1755 7.86975C30.3436 8.12515 29.3162 7.77654 28.3731 7.69918C26.3651 7.53606 24.3375 7.12126 22.3519 7.26481C15.6216 7.74765 10.5249 10.8777 7.34918 16.7183C5.06049 20.9268 4.51967 25.4858 4.82751 30.1716C5.11476 34.5516 7.38193 37.4253 11.6468 38.8449ZM44.421 5.84799C45.7076 6.37278 46.1763 7.23405 45.948 8.56418C45.8601 9.07871 45.6758 9.30987 45.0769 9.23996C42.8996 9.00321 40.7176 8.81865 38.4775 8.60892C39.489 6.08662 42.093 4.90376 44.421 5.85079V5.84799ZM14.1245 27.1245C16.5488 25.8195 20.0343 26.2091 22.0853 28.0174C24.2158 29.8966 23.9566 32.6566 21.5061 34.1247C20.4366 34.766 19.2604 35.0036 18.0188 35.0344C16.358 34.985 14.8225 34.5739 13.5181 33.53C11.1134 31.5986 11.4016 28.5926 14.1245 27.1245Z"
            fill="url(#paint73_linear_44_564)"
        />
        <path
            d="M65.7658 39.5217C64.992 34.5908 63.8065 29.7568 62.3534 24.9807C62.0212 23.8882 61.6497 22.8079 61.2764 21.6624C61.1828 21.7873 61.1351 21.8208 61.1239 21.8618C60.2949 25.1419 59.4602 28.4202 58.6537 31.705C58.5798 32.007 58.6368 32.363 58.7164 32.6734C59.1552 34.382 59.7129 36.0663 60.0572 37.7908C60.6327 40.6803 60.3632 43.4767 58.6041 46.0008C58.0427 46.8006 57.5299 47.6274 57.0059 48.4486C56.8787 48.648 56.7103 48.8736 56.7084 49.0889C56.6869 51.4071 56.6953 53.7262 56.6953 56.0938C56.9124 56.0006 57.1397 55.9074 57.3568 55.7936C60.3103 54.3211 62.7169 51.9508 64.2286 49.0256C65.7403 46.1003 66.2787 42.7719 65.7658 39.5217Z"
            fill="url(#paint74_linear_44_564)"
        />
        <path
            d="M57.4337 17.6357C57.2652 15.4648 56.1106 14.5122 53.8958 14.6949C52.5859 14.803 51.45 15.3101 50.4235 16.0744C49.0537 17.0997 48.0749 18.4308 47.2422 19.8774C45.5327 22.8555 44.5175 26.0928 43.5378 29.3412C42.4758 32.8646 41.5289 36.4253 39.7258 39.6942C39.5209 40.0671 39.4451 40.5238 39.4011 40.9507C38.9754 45.3829 38.5749 49.817 38.1511 54.2492C38.1127 54.6435 38.298 55.2438 37.6664 55.2801C37.5026 55.2894 37.2107 54.6826 37.1302 54.3256C36.4191 51.162 35.7492 47.9872 35.0502 44.818C34.8565 43.9409 34.3821 43.6734 33.4745 43.8533C33.1087 43.926 32.7447 44.0034 32.377 44.063C28.2225 44.7388 24.0409 44.5953 19.8631 44.4126C16.9943 44.2877 14.1695 43.858 11.4644 42.8783C7.96213 41.6097 5.07461 39.6495 3.58687 36.1391C3.51389 35.9657 3.23786 35.8753 3.0554 35.7458C2.91973 35.9536 2.69984 36.1484 2.66054 36.3731C2.02334 40.0251 1.80533 43.6874 2.48276 47.3515C3.1078 50.7277 4.48887 53.7235 7.31932 55.9363C9.53783 57.671 12.052 58.8678 14.7346 59.7412C20.4255 61.5933 26.309 62.0333 32.2675 62.0659C34.9716 61.8711 37.6795 61.7182 40.3789 61.4712C44.0281 61.1461 47.5281 59.8741 50.5302 57.7819C52.0591 56.7286 52.8067 55.2904 52.8432 53.4597C52.9087 50.0957 52.9087 46.7261 53.1463 43.3733C53.4074 39.6867 53.7283 35.9862 54.3571 32.3473C54.8409 29.5509 55.872 26.8478 56.6028 24.085C57.166 21.9756 57.6039 19.8392 57.4337 17.6357Z"
            fill="url(#paint75_linear_44_564)"
        />
        <path
            d="M11.6664 38.819C13.9177 39.5694 16.2513 39.9012 18.6139 40.0625C22.017 40.2955 25.4229 40.4558 28.8073 39.8406C33.7205 38.9467 37.1423 36.3526 38.6394 31.6138C39.0467 30.347 39.3844 29.0591 39.6509 27.7557C41.1021 20.4852 44.7813 14.6315 51.1271 10.4352C51.2992 10.3205 51.4143 10.1257 51.6613 9.85165L49.9771 9.61117C50.7257 7.01895 50.095 5.17057 47.9345 3.71367C45.701 2.20923 43.1971 1.70123 40.5426 2.19059C37.6925 2.71724 35.3963 4.28412 33.277 6.13624C32.5902 6.73745 31.9792 7.59593 31.1727 7.84387C30.3408 8.09927 29.3144 7.75066 28.3731 7.6733C26.367 7.51018 24.3403 7.09539 22.3566 7.238C15.6347 7.72177 10.5418 10.8518 7.36885 16.6924C5.08297 20.901 4.54214 25.4599 4.84998 30.1457C5.14192 34.5257 7.40534 37.3994 11.6664 38.819ZM44.4154 5.82211C45.701 6.3469 46.1698 7.20817 45.9415 8.5383C45.8526 9.05283 45.6692 9.28399 45.0704 9.21408C42.8959 8.97733 40.7148 8.79277 38.4775 8.58304C39.4815 6.05887 42.0855 4.87788 44.4117 5.82491L44.4154 5.82211ZM14.1441 27.0977C16.5657 25.7927 20.0483 26.1823 22.0975 27.9906C24.2271 29.8698 23.9688 32.6298 21.5192 34.0978C20.4507 34.7391 19.2736 34.9768 18.0347 35.0076C16.3757 34.9582 14.8422 34.5471 13.5434 33.5032C11.1303 31.5765 11.4222 28.5667 14.1441 27.0977Z"
            fill="url(#paint76_linear_44_564)"
        />
        <path
            d="M65.7368 39.4956C64.9639 34.5647 63.7803 29.7307 62.3281 24.9546C61.996 23.8621 61.6245 22.7818 61.2521 21.6362C61.1585 21.7611 61.1108 21.7947 61.0996 21.8357C60.2715 25.1158 59.4378 28.3941 58.6322 31.6788C58.5582 31.9809 58.6144 32.3369 58.6939 32.6473C59.1328 34.3559 59.6904 36.0402 60.0338 37.7646C60.6093 40.6542 60.3389 43.4505 58.5816 45.9747C58.0258 46.7745 57.5084 47.6012 56.9854 48.4224C56.8581 48.6219 56.6897 48.8475 56.6878 49.0628C56.6663 51.381 56.6747 53.7001 56.6747 56.0677C56.8918 55.9689 57.1182 55.8812 57.3362 55.7675C60.2879 54.2934 62.6925 51.9225 64.2026 48.9974C65.7126 46.0724 66.2499 42.7448 65.7368 39.4956Z"
            fill="url(#paint77_linear_44_564)"
        />
        <path
            d="M57.412 17.6097C57.2445 15.4388 56.0899 14.4862 53.877 14.6689C52.5671 14.777 51.4339 15.2841 50.4084 16.0484C49.0405 17.0737 48.0617 18.4048 47.2318 19.8514C45.5242 22.8295 44.5099 26.0668 43.5311 29.3152C42.4701 32.8386 41.5241 36.3993 39.722 39.6682C39.518 40.041 39.4413 40.4978 39.3982 40.9247C38.9734 45.3569 38.572 49.791 38.1491 54.2232C38.1107 54.6175 38.296 55.2178 37.6653 55.2541C37.5016 55.2634 37.2097 54.6566 37.1292 54.2996C36.4181 51.136 35.7491 47.9612 35.051 44.7967C34.8573 43.9196 34.3839 43.6521 33.4763 43.832C33.1114 43.9047 32.7474 43.982 32.3806 44.0417C28.2299 44.7175 24.053 44.5739 19.878 44.3912C17.011 44.2663 14.189 43.8366 11.4868 42.857C7.9873 41.5884 5.10258 39.6281 3.61671 36.1178C3.54373 35.9444 3.26677 35.854 3.08524 35.7244C2.94957 35.9323 2.72968 36.1271 2.69039 36.3517C2.05131 39.9991 1.83423 43.6614 2.51167 47.3265C3.13577 50.7026 4.51497 53.6984 7.34261 55.9113C9.55925 57.6459 12.0716 58.8428 14.7514 59.7161C20.4375 61.5683 26.3164 62.0082 32.2692 62.0408C34.9706 61.846 37.6766 61.6932 40.3723 61.4462C44.0189 61.121 47.5161 59.8489 50.5151 57.7568C52.0431 56.7035 52.7897 55.2653 52.8262 53.4346C52.8917 50.0706 52.8917 46.701 53.1285 43.3482C53.3895 39.6617 53.7105 35.9612 54.3392 32.3222C54.822 29.5259 55.8522 26.8227 56.5849 24.0599C57.1416 21.9496 57.5823 19.8132 57.412 17.6097Z"
            fill="url(#paint78_linear_44_564)"
        />
        <path
            d="M11.6917 38.7929C13.9373 39.5433 16.2765 39.8751 18.6326 40.0364C22.0329 40.2694 25.435 40.4297 28.8166 39.8145C33.7252 38.9206 37.1442 36.3238 38.6413 31.5877C39.0479 30.3209 39.385 29.033 39.6509 27.7297C41.1021 20.4592 44.7775 14.6055 51.1177 10.4091C51.2899 10.2944 51.404 10.0996 51.651 9.82558L49.9668 9.58509C50.7154 6.99288 50.0856 5.14449 47.9261 3.6876C45.6926 2.18316 43.1906 1.67795 40.5407 2.16452C37.6934 2.69116 35.3945 4.25805 33.2817 6.11016C32.5949 6.71138 31.9839 7.56986 31.1783 7.8178C30.3483 8.0732 29.3228 7.72459 28.3815 7.64722C26.3773 7.4841 24.3524 7.06931 22.3707 7.21193C15.6552 7.69569 10.5689 10.8257 7.39689 16.6664C5.11195 20.8795 4.57206 25.4339 4.8799 30.1196C5.16622 34.4996 7.42964 37.378 11.6917 38.7929ZM44.407 5.79604C45.6917 6.32082 46.1595 7.1821 45.9312 8.51223C45.8432 9.02675 45.6589 9.25792 45.061 9.18801C42.8884 8.95125 40.7101 8.76669 38.4747 8.55697C39.4815 6.03466 42.079 4.85181 44.4023 5.79884L44.407 5.79604ZM14.1619 27.0716C16.5816 25.7666 20.0567 26.1562 22.1077 27.9646C24.2355 29.8437 23.9735 32.6037 21.5304 34.0718C20.4628 34.7131 19.2848 34.9508 18.0497 34.9815C16.3916 34.9321 14.859 34.5211 13.5584 33.4771C11.1546 31.5504 11.4428 28.5406 14.1619 27.0716Z"
            fill="url(#paint79_linear_44_564)"
        />
        <path
            d="M65.7088 39.4695C64.9359 34.5386 63.7532 29.7046 62.302 24.9285C61.9707 23.837 61.6002 22.7567 61.2278 21.6111C61.1342 21.736 61.0865 21.7695 61.0753 21.8106C60.2472 25.0907 59.4144 28.3689 58.6098 31.6537C58.5358 31.9557 58.592 32.3118 58.6715 32.6222C59.1103 34.3307 59.6671 36.0151 60.0105 37.7395C60.585 40.629 60.3155 43.4254 58.5602 45.9496C58.0044 46.7493 57.4879 47.5761 56.9695 48.3973C56.8422 48.5968 56.6738 48.8223 56.6719 49.0377C56.6504 51.3558 56.6589 53.6749 56.6589 56.0425C56.8759 55.9437 57.1024 55.8561 57.3194 55.7424C60.2688 54.266 62.6708 51.8941 64.1787 48.9692C65.6867 46.0442 66.2225 42.7176 65.7088 39.4695Z"
            fill="url(#paint80_linear_44_564)"
        />
        <path
            d="M57.3915 17.5836C57.224 15.4127 56.0703 14.46 53.8593 14.6427C52.554 14.7509 51.4181 15.2579 50.3972 16.0223C49.0302 17.0476 48.058 18.3787 47.2243 19.8253C45.5176 22.8034 44.5043 26.0406 43.5265 29.2891C42.4664 32.8125 41.5213 36.3731 39.7211 39.6421C39.5171 40.0149 39.4404 40.4717 39.3973 40.8986C38.9725 45.3308 38.5721 49.7649 38.1491 54.1971C38.1117 54.5914 38.296 55.1916 37.6654 55.228C37.5026 55.2373 37.2097 54.6305 37.1302 54.2735C36.42 51.1099 35.7519 47.9351 35.0539 44.7706C34.8602 43.8935 34.3867 43.6259 33.481 43.8058C33.1161 43.8785 32.7521 43.9559 32.3853 44.0156C28.2384 44.6913 24.0652 44.5478 19.8939 44.3651C17.0251 44.2402 14.2068 43.8096 11.5046 42.8308C8.00885 41.5622 5.126 39.602 3.64482 36.0917C3.57183 35.9183 3.2958 35.8279 3.11428 35.6983C2.97861 35.9062 2.75872 36.101 2.71942 36.3256C2.08129 39.973 1.86421 43.6353 2.54071 47.3003C3.16481 50.6765 4.54307 53.6723 7.3679 55.8851C9.58267 57.6198 12.0922 58.8166 14.7701 59.69C20.4497 61.5421 26.323 61.9858 32.2712 62.0147C34.9697 61.8199 37.6729 61.667 40.3667 61.42C44.0104 61.0948 47.5046 59.8227 50.5002 57.7307C52.0272 56.6774 52.7729 55.2392 52.8085 53.4085C52.8749 50.0445 52.874 46.6749 53.1117 43.3221C53.3718 39.6356 53.6927 35.9351 54.3206 32.2961C54.8034 29.4997 55.8326 26.7966 56.5662 24.0338C57.1211 21.9235 57.5618 19.7871 57.3915 17.5836Z"
            fill="url(#paint81_linear_44_564)"
        />
        <path
            d="M11.7066 38.7666C13.9523 39.517 16.2849 39.8488 18.6419 40.0101C22.0385 40.2431 25.4378 40.4034 28.8166 39.7882C33.7205 38.8943 37.1348 36.2975 38.6328 31.5614C39.0395 30.2946 39.3765 29.0067 39.6424 27.7034C41.0918 20.4329 44.7634 14.5792 51.098 10.3828C51.2702 10.2681 51.3853 10.0733 51.6314 9.79928L49.9471 9.5588C50.6957 6.96658 50.066 5.1182 47.9083 3.6613C45.6795 2.15687 43.1793 1.64886 40.5323 2.13822C37.6869 2.66487 35.3907 4.23176 33.2788 6.08387C32.5939 6.68508 31.9829 7.54356 31.1782 7.79151C30.3483 8.04691 29.3237 7.69829 28.3843 7.62093C26.381 7.45781 24.3608 7.04302 22.3781 7.18563C15.6693 7.6694 10.5885 10.7994 7.41839 16.6401C5.14187 20.8486 4.59917 25.4076 4.90607 30.0933C5.19239 34.4733 7.45394 37.347 11.7066 38.7666ZM44.3929 5.76975C45.6767 6.29453 46.1436 7.1558 45.9162 8.48593C45.8282 9.00046 45.6439 9.23162 45.0469 9.16172C42.8762 8.92496 40.6998 8.7404 38.4653 8.53067C39.474 6.00837 42.0715 4.82551 44.3929 5.77254V5.76975ZM14.1796 27.0453C16.5975 25.7403 20.0745 26.13 22.1189 27.9383C24.2448 29.8174 23.9828 32.5774 21.5416 34.0455C20.4749 34.6868 19.296 34.9245 18.0637 34.9552C16.4075 34.9058 14.8767 34.4948 13.5724 33.4508C11.1752 31.5241 11.4624 28.5143 14.1796 27.0453Z"
            fill="url(#paint82_linear_44_564)"
        />
        <path
            d="M65.6807 39.4434C64.9088 34.5125 63.727 29.6785 62.2776 24.9024C61.9464 23.8109 61.5759 22.7305 61.2044 21.585C61.1108 21.7099 61.0631 21.7434 61.0519 21.7844C60.2247 25.0645 59.3929 28.3428 58.5882 31.6276C58.5143 31.9296 58.5714 32.2857 58.6509 32.596C59.0888 34.3046 59.6446 35.9889 59.988 37.7134C60.5616 40.6029 60.293 43.3993 58.5386 45.9234C57.9838 46.7232 57.4673 47.55 56.948 48.3712C56.8207 48.5706 56.6532 48.7962 56.6504 49.0115C56.6298 51.3297 56.6373 53.6488 56.6373 56.0164C56.8544 55.9176 57.0808 55.83 57.2979 55.7162C60.2456 54.2384 62.6459 51.8661 64.1526 48.9413C65.6592 46.0166 66.1943 42.6907 65.6807 39.4434Z"
            fill="url(#paint83_linear_44_564)"
        />
        <path
            d="M57.3699 17.5572C57.2024 15.3863 56.0506 14.4337 53.8415 14.6164C52.5371 14.7245 51.4021 15.2316 50.3794 15.9959C49.0133 17.0166 48.0402 18.3523 47.2084 19.7989C45.5036 22.7817 44.4949 26.0143 43.5143 29.2627C42.4551 32.7861 41.5101 36.3468 39.7117 39.6157C39.5077 39.9886 39.431 40.4453 39.3889 40.8722C38.9641 45.3044 38.5636 49.7385 38.1416 54.1707C38.1042 54.565 38.2885 55.1653 37.6588 55.2016C37.495 55.2109 37.2031 54.6041 37.1236 54.2471C36.4143 51.0835 35.7463 47.9087 35.0492 44.7396C34.8555 43.8624 34.383 43.5949 33.4772 43.7748C33.1132 43.8475 32.7493 43.9249 32.3834 43.9845C28.2402 44.6603 24.0698 44.5168 19.9032 44.3341C17.0419 44.2092 14.2246 43.7795 11.527 42.7998C8.03408 41.5312 5.15404 39.571 3.66724 36.0606C3.59426 35.8872 3.31823 35.7968 3.13671 35.6673C3.00197 35.8751 2.78208 36.0699 2.74278 36.2937C2.10558 39.942 1.8885 43.6042 2.56407 47.2693C3.18723 50.6454 4.56456 53.6413 7.38659 55.8541C9.59948 57.5888 12.1071 58.7856 14.7785 59.659C20.4618 61.5195 26.3305 61.9557 32.2767 61.9856C34.9734 61.7907 37.6747 61.6379 40.3657 61.3909C44.0065 61.0655 47.4977 59.7933 50.4898 57.7016C52.015 56.6483 52.7607 55.21 52.7963 53.3793C52.8627 50.0153 52.8618 46.6457 53.0985 43.2929C53.3596 39.6064 53.6796 35.9059 54.3074 32.2669C54.7893 29.4706 55.8176 26.7674 56.5465 24.0046C57.1004 21.8971 57.5402 19.7607 57.3699 17.5572Z"
            fill="url(#paint84_linear_44_564)"
        />
        <path
            d="M11.7272 38.7408C13.9729 39.4911 16.3008 39.823 18.656 39.9842C22.0497 40.2172 25.4453 40.3776 28.8212 39.7624C33.7205 38.8685 37.132 36.2716 38.6291 31.5355C39.0351 30.2687 39.3715 28.9808 39.6368 27.6775C41.0853 20.407 44.7541 14.5533 51.083 10.3569C51.2543 10.2423 51.3694 10.0474 51.6154 9.7734L49.9359 9.53292C50.6788 6.9407 50.0547 5.09232 47.9026 3.63542C45.6757 2.13099 43.1784 1.62298 40.5341 2.11234C37.6868 2.63899 35.3925 4.20588 33.2826 6.05892C32.5977 6.66014 31.9876 7.51862 31.1838 7.76656C30.3548 8.02196 29.3312 7.67335 28.3918 7.59598C26.3903 7.43286 24.3683 7.01807 22.3912 7.16068C15.688 7.64352 10.6091 10.7736 7.44459 16.6142C5.16432 20.8227 4.62537 25.3817 4.93227 30.0674C5.21859 34.4474 7.48108 37.3212 11.7272 38.7408ZM44.3826 5.74387C45.6645 6.26865 46.1323 7.12992 45.904 8.46005C45.8161 8.97458 45.6327 9.20109 45.0357 9.13584C42.8677 8.90188 40.6979 8.71732 38.4616 8.50759C39.4693 5.98249 42.064 4.79963 44.3835 5.74666L44.3826 5.74387ZM14.1974 27.0194C16.6134 25.7144 20.0857 26.1041 22.1292 27.9124C24.2532 29.7915 23.9922 32.5515 21.5528 34.0196C20.4871 34.6609 19.3072 34.8986 18.0777 34.9293C16.4234 34.8799 14.8964 34.4689 13.5939 33.4249C11.1957 31.4982 11.483 28.4884 14.1974 27.0194Z"
            fill="url(#paint85_linear_44_564)"
        />
        <path
            d="M65.6517 39.4175C64.8807 34.4866 63.6998 29.6526 62.2514 24.8765C61.9202 23.785 61.5506 22.7047 61.1782 21.5591C61.0846 21.684 61.0378 21.7175 61.0266 21.7586C60.2004 25.0387 59.3695 28.3169 58.5658 31.6017C58.4918 31.9037 58.548 32.2598 58.6275 32.5702C59.0654 34.2787 59.6212 35.9631 59.9637 37.6875C60.5372 40.577 60.2678 43.3734 58.5162 45.8976C57.9613 46.6973 57.4457 47.5241 56.9255 48.3453C56.7982 48.5448 56.6308 48.7703 56.6289 48.9857C56.6074 51.3038 56.6148 53.6229 56.6148 55.9905C56.8319 55.8917 57.0584 55.8041 57.2698 55.6904C60.2171 54.2121 62.6169 51.8396 64.1234 48.9149C65.6298 45.9903 66.165 42.6647 65.6517 39.4175Z"
            fill="url(#paint86_linear_44_564)"
        />
        <path
            d="M57.3531 17.5312C57.1856 15.3603 56.0347 14.4077 53.8274 14.5904C52.524 14.6985 51.3947 15.2056 50.3654 15.9699C49.0003 16.9906 48.0262 18.3263 47.1972 19.7729C45.4942 22.7557 44.4837 25.9883 43.5069 29.2367C42.4486 32.7601 41.5045 36.3208 39.708 39.5897C39.504 39.9626 39.4273 40.4193 39.3852 40.8462C38.9604 45.2784 38.5608 49.7125 38.1388 54.1447C38.1014 54.539 38.2857 55.1393 37.656 55.1756C37.4932 55.1849 37.2013 54.5781 37.1217 54.2211C36.4125 51.0575 35.7453 47.8827 35.0492 44.7136C34.8564 43.8364 34.3839 43.5689 33.4791 43.7488C33.1151 43.8215 32.7521 43.8989 32.3853 43.9585C28.2458 44.6343 24.0802 44.4908 19.9164 44.3081C17.0578 44.1822 14.2433 43.7488 11.5485 42.7738C8.05844 41.5052 5.18588 39.545 3.69908 36.0346C3.6261 35.8612 3.35101 35.7708 3.16948 35.6413C3.03381 35.8491 2.81486 36.0439 2.77556 36.2676C2.13836 39.916 1.92128 43.5782 2.59684 47.2433C3.22001 50.6194 4.59547 53.6153 7.41562 55.8281C9.62664 57.5628 12.1315 58.7596 14.8075 59.633C20.4778 61.4851 26.3408 61.9251 32.2777 61.9577C34.9715 61.7629 37.6701 61.61 40.3592 61.363C43.9971 61.0374 47.4851 59.7651 50.474 57.6737C51.9982 56.6204 52.743 55.1821 52.7785 53.3515C52.845 49.9875 52.844 46.6179 53.0808 43.265C53.3409 39.5785 53.6609 35.878 54.2878 32.239C54.7697 29.4427 55.7971 26.7396 56.526 23.9768C57.079 21.8711 57.5187 19.7347 57.3531 17.5312Z"
            fill="url(#paint87_linear_44_564)"
        />
        <path
            d="M11.7469 38.7145C13.9925 39.4649 16.3168 39.7967 18.671 39.958C22.0619 40.191 25.4547 40.3513 28.8278 39.7361C33.7233 38.8422 37.1311 36.2453 38.6263 31.5093C39.0323 30.2425 39.3688 28.9545 39.634 27.6512C41.0806 20.3807 44.7466 14.527 51.069 10.3307C51.2412 10.216 51.3554 10.0203 51.6014 9.74715L49.921 9.50759C50.6639 6.91538 50.0398 5.06699 47.8858 3.6101C45.657 2.10473 43.1653 1.59673 40.5229 2.08609C37.6831 2.60994 35.3916 4.17962 33.2835 6.03173C32.5986 6.63295 31.9895 7.49143 31.1867 7.73937C30.3577 7.99477 29.335 7.64616 28.3965 7.56879C26.3978 7.40567 24.3786 6.99088 22.4025 7.13349C15.7048 7.61726 10.6297 10.7473 7.46894 16.5917C5.19055 20.7965 4.6516 25.3536 4.95476 30.0412C5.24388 34.4212 7.50169 37.2949 11.7469 38.7145ZM44.3742 5.71761C45.657 6.24519 46.1249 7.10646 45.8947 8.43659C45.8068 8.95112 45.6234 9.17762 45.0264 9.11238C42.8603 8.87562 40.6876 8.69106 38.4579 8.48133C39.4637 5.95623 42.0565 4.77338 44.3742 5.72041V5.71761ZM14.218 26.9932C16.6312 25.6882 20.1016 26.0778 22.1433 27.8861C24.2645 29.7653 24.0034 32.5253 21.5669 33.9933C20.5021 34.6346 19.3269 34.8723 18.0955 34.9031C16.4422 34.8537 14.9142 34.4426 13.6155 33.3987C11.2164 31.472 11.5045 28.4622 14.218 26.9932Z"
            fill="url(#paint88_linear_44_564)"
        />
        <path
            d="M65.6227 39.3914C64.8526 34.4605 63.6727 29.6265 62.2261 24.8504C61.8958 23.7589 61.5253 22.6785 61.1538 21.533C61.0603 21.6579 61.0135 21.6914 61.0022 21.7324C60.177 25.0125 59.3461 28.2908 58.5433 31.5756C58.4693 31.8776 58.5255 32.2337 58.605 32.544C59.0429 34.2526 59.5978 35.9369 59.9402 37.6614C60.5129 40.5509 60.2443 43.3473 58.4937 45.8714C57.9388 46.6712 57.4242 47.498 56.903 48.3192C56.7758 48.5186 56.6083 48.7442 56.6064 48.9595C56.5849 51.2777 56.5933 53.5968 56.5933 55.9644C56.8094 55.8656 57.0359 55.778 57.2483 55.6642C60.1938 54.1844 62.5917 51.8113 64.0967 48.8869C65.6016 45.9625 66.136 42.6377 65.6227 39.3914Z"
            fill="url(#paint89_linear_44_564)"
        />
        <path
            d="M57.3288 17.5052C57.1613 15.3343 56.0114 14.3817 53.806 14.5644C52.5044 14.6725 51.3732 15.1796 50.3495 15.9439C48.9862 16.9646 48.0103 18.3003 47.185 19.7469C45.483 22.7297 44.4716 25.9623 43.4975 29.2107C42.4402 32.7341 41.497 36.2948 39.7014 39.5637C39.4984 39.9366 39.4207 40.3933 39.3786 40.8202C38.9557 45.2524 38.5562 49.6865 38.1342 54.1187C38.0967 54.513 38.2811 55.1133 37.6523 55.1496C37.4895 55.1589 37.1975 54.5521 37.118 54.1951C36.4097 51.0315 35.7435 47.8567 35.0473 44.6922C34.8546 43.8151 34.3821 43.5476 33.4782 43.7275C33.1152 43.8002 32.7521 43.8775 32.3863 43.9372C28.2505 44.613 24.0877 44.4694 19.9285 44.2867C17.0728 44.1609 14.2602 43.7275 11.5673 42.7525C8.08091 41.4839 5.20461 39.5236 3.7253 36.0133C3.65231 35.8399 3.37722 35.7495 3.19664 35.6199C3.06096 35.8278 2.84201 36.0226 2.80271 36.2463C2.16645 39.8946 1.94937 43.5569 2.62493 47.222C3.24623 50.5981 4.62168 53.5939 7.43809 55.8068C9.64725 57.5414 12.1502 58.7383 14.8206 59.6117C20.4872 61.4638 26.3436 61.9037 32.2768 61.9364C34.9678 61.7415 37.6645 61.5887 40.3508 61.3417C43.9858 61.016 47.4708 59.7437 50.4562 57.6524C51.9795 56.5991 52.7234 55.1608 52.7589 53.3301C52.8254 49.9661 52.8244 46.5965 53.0611 43.2437C53.3213 39.5572 53.6403 35.8567 54.2672 32.2177C54.7482 29.4214 55.7746 26.7182 56.5026 23.9554C57.0584 21.8451 57.4982 19.7087 57.3288 17.5052Z"
            fill="url(#paint90_linear_44_564)"
        />
        <path
            d="M11.7666 38.6884C14.0122 39.4388 16.3327 39.7706 18.6832 39.9319C22.0713 40.1649 25.4613 40.3252 28.8307 39.71C33.7215 38.8161 37.1264 36.2192 38.6207 31.4832C39.0262 30.2163 39.3619 28.9284 39.6266 27.6251C41.0722 20.3546 44.7345 14.501 51.0522 10.3046C51.2235 10.1899 51.3386 9.99417 51.5837 9.72106L49.907 9.48057C50.649 6.88836 50.0249 5.03998 47.8737 3.58308C45.6505 2.07864 43.1569 1.56971 40.5174 2.06C37.6794 2.58665 35.3898 4.15353 33.2845 6.00565C32.6005 6.60686 31.9914 7.46534 31.1886 7.71328C30.3614 7.96868 29.3397 7.62007 28.4021 7.54271C26.4044 7.37959 24.3871 6.96479 22.4137 7.10741C15.7208 7.59118 10.6512 10.7194 7.49237 16.5619C5.21585 20.7704 4.67409 25.3293 4.9838 30.0142C5.27012 34.3951 7.52512 37.2688 11.7666 38.6884ZM44.3649 5.69152C45.6449 6.21537 46.1109 7.07758 45.8835 8.40771C45.7965 8.92224 45.6131 9.1534 45.0171 9.08349C42.85 8.84953 40.6811 8.6687 38.4523 8.45525C39.4591 5.93015 42.05 4.74729 44.3649 5.69432V5.69152ZM14.2359 26.9643C16.6471 25.6593 20.1138 26.0489 22.1536 27.8572C24.2739 29.7364 24.0128 32.4964 21.5782 33.9645C20.5143 34.6058 19.34 34.8434 18.1096 34.8742C16.4581 34.8248 14.9283 34.4137 13.6333 33.3698C11.237 31.4459 11.5233 28.4361 14.233 26.9671L14.2359 26.9643Z"
            fill="url(#paint91_linear_44_564)"
        />
        <path
            d="M65.5947 39.3652C64.8246 34.4344 63.6466 29.6004 62.201 24.8242C61.8707 23.7327 61.5011 22.6524 61.1296 21.5068C61.036 21.6317 60.9892 21.6653 60.978 21.7063C60.1537 24.9864 59.3237 28.2647 58.5218 31.5495C58.4479 31.8515 58.5041 32.2075 58.5836 32.5179C59.0206 34.2265 59.5745 35.9108 59.9169 37.6352C60.4896 40.5248 60.221 43.3211 58.4723 45.8453C57.9174 46.6451 57.4037 47.4719 56.8816 48.293C56.7543 48.4925 56.5868 48.7181 56.585 48.9334C56.5635 51.2516 56.5719 53.5707 56.5719 55.9383C56.788 55.8395 57.0135 55.7518 57.2269 55.6381C60.1707 54.1568 62.567 51.7832 64.0706 48.859C65.5743 45.9348 66.1079 42.6108 65.5947 39.3652Z"
            fill="url(#paint92_linear_44_564)"
        />
        <path
            d="M57.3073 17.4792C57.1407 15.3083 55.9917 14.3547 53.7882 14.5411C52.4876 14.6493 51.3554 15.1563 50.3345 15.9207C48.9722 16.9413 47.9953 18.2771 47.1729 19.7237C45.4727 22.7065 44.4594 25.939 43.4891 29.1875C42.4318 32.7109 41.4905 36.2715 39.6968 39.5405C39.4937 39.9133 39.416 40.3701 39.3739 40.797C38.951 45.2292 38.5515 49.6632 38.1304 54.0955C38.093 54.4897 38.2773 55.09 37.6485 55.1264C37.4857 55.1357 37.1947 54.5289 37.1152 54.1719C36.4078 51.0083 35.7416 47.8335 35.0464 44.669C34.8536 43.7919 34.3821 43.5243 33.4791 43.7042C33.1151 43.7769 32.753 43.8543 32.3872 43.914C28.2552 44.5897 24.097 44.4462 19.9426 44.2635C17.0897 44.1377 14.2798 43.7042 11.5897 42.7292C8.10429 41.4578 5.23548 39.4976 3.75242 35.9872C3.68037 35.8139 3.40528 35.7235 3.22376 35.5939C3.08902 35.8018 2.87007 35.9966 2.83077 36.2203C2.19544 39.8686 1.97836 43.5309 2.65299 47.1959C3.27429 50.5721 4.64787 53.5679 7.46241 55.7807C9.66876 57.5154 12.1698 58.7122 14.8384 59.5856C20.4984 61.4377 26.3473 61.8777 32.2768 61.9103C34.9669 61.7155 37.6598 61.5626 40.3442 61.3156C43.9765 60.9895 47.4587 59.7172 50.4412 57.6263C51.9617 56.573 52.7056 55.1348 52.7411 53.3041C52.8076 49.9401 52.8066 46.5705 53.0424 43.2177C53.3025 39.5312 53.6225 35.8307 54.2476 32.1917C54.7285 29.3953 55.754 26.6922 56.4811 23.9294C57.0378 21.8191 57.4766 19.6827 57.3073 17.4792Z"
            fill="url(#paint93_linear_44_564)"
        />
        <path
            d="M11.7853 38.6623C14.0244 39.4127 16.3467 39.7445 18.6953 39.9058C22.0797 40.1388 25.4669 40.2991 28.8334 39.6839C33.7196 38.79 37.1217 36.1931 38.6142 31.4571C39.0196 30.1902 39.3554 28.9023 39.62 27.599C41.0647 20.3285 44.7232 14.4748 51.0354 10.2785C51.2075 10.1638 51.3217 9.96806 51.5668 9.69495L49.892 9.45446C50.633 6.86225 50.0099 5.01386 47.8597 3.55697C45.6402 2.05067 43.1494 1.5436 40.5108 2.03389C37.6757 2.56053 35.3879 4.12742 33.2836 5.97954C32.6005 6.58075 31.9923 7.43923 31.1904 7.68717C30.3633 7.94257 29.3425 7.59396 28.4058 7.51659C26.41 7.35347 24.3946 6.93868 22.4221 7.0813C15.7376 7.56506 10.6718 10.6951 7.51668 16.5357C5.24203 20.7442 4.70401 25.3032 5.00998 29.989C5.2963 34.369 7.54849 37.2427 11.7853 38.6623ZM44.3536 5.66541C45.6327 6.18926 46.0987 7.05147 45.8713 8.3816C45.7834 8.89612 45.6 9.12729 45.0049 9.05738C42.8425 8.82062 40.6736 8.63607 38.4476 8.42634C39.4544 5.90403 42.0425 4.72118 44.3555 5.66821L44.3536 5.66541ZM14.2508 26.9382C16.6602 25.6332 20.1241 26.0228 22.1611 27.8311C24.2795 29.7103 24.0184 32.4703 21.5866 33.9384C20.5236 34.5796 19.3512 34.8173 18.1245 34.8481C16.4749 34.7987 14.9488 34.3876 13.6529 33.3437C11.2575 31.4198 11.5439 28.41 14.2508 26.9382Z"
            fill="url(#paint94_linear_44_564)"
        />
        <path
            d="M65.5665 39.3355C64.7974 34.4046 63.6193 29.5706 62.1756 24.7945C61.8453 23.7029 61.4757 22.6226 61.1051 21.4771C61.0116 21.602 60.9648 21.6355 60.9536 21.6765C60.1302 24.9566 59.3002 28.2349 58.4993 31.5197C58.4254 31.8217 58.4815 32.1777 58.561 32.4881C58.998 34.1967 59.5519 35.881 59.8934 37.6055C60.4651 40.495 60.1975 43.2914 58.4497 45.8155C57.8958 46.6153 57.3821 47.4421 56.859 48.2633C56.7327 48.4627 56.5652 48.6883 56.5624 48.9036C56.5418 51.2218 56.5493 53.5409 56.5493 55.9075C56.7654 55.8097 56.9909 55.7211 57.2043 55.6083C60.1466 54.1256 62.5413 51.7516 64.0438 48.8276C65.5464 45.9036 66.0795 42.5803 65.5665 39.3355Z"
            fill="url(#paint95_linear_44_564)"
        />
        <path
            d="M57.2867 17.4531C57.1192 15.2822 55.9711 14.3295 53.7704 14.5122C52.4707 14.6204 51.3376 15.1274 50.3205 15.8918C48.9591 16.9124 47.9813 18.2482 47.1607 19.6948C45.4624 22.6776 44.4528 25.9102 43.4797 29.1586C42.4242 32.682 41.4839 36.2427 39.6911 39.5116C39.4881 39.8844 39.4104 40.3412 39.3692 40.7681C38.9463 45.2003 38.5458 49.6344 38.1266 54.0629C38.0892 54.4571 38.2735 55.0574 37.6457 55.0938C37.4829 55.1031 37.1919 54.4963 37.1124 54.1393C36.405 50.9757 35.7397 47.8009 35.0454 44.6364C34.8527 43.7592 34.382 43.4917 33.4791 43.6716C33.1161 43.7443 32.7539 43.8217 32.389 43.8813C28.2608 44.5571 24.1054 44.4136 19.9538 44.2309C17.1027 44.1051 14.2957 43.6716 11.6075 42.6966C8.12766 41.428 5.25792 39.4678 3.77954 35.9574C3.70749 35.7841 3.4324 35.6936 3.25181 35.5641C3.11707 35.7719 2.89812 35.9668 2.85882 36.1905C2.22349 39.8388 2.00735 43.501 2.68104 47.1661C3.3014 50.5422 4.67405 53.5381 7.48578 55.7509C9.69119 57.4856 12.1895 58.6824 14.8552 59.5558C20.5105 61.4079 26.3576 61.8479 32.2786 61.8805C34.9659 61.6857 37.6569 61.5328 40.3386 61.2858C43.9681 60.9625 47.4482 59.693 50.429 57.6049C51.9486 56.5507 52.6915 55.1133 52.7271 53.2827C52.7926 49.9187 52.7926 46.5491 53.0284 43.1962C53.2875 39.5097 53.6076 35.8092 54.2326 32.1702C54.7126 29.3739 55.7372 26.6708 56.4642 23.908C57.0172 21.793 57.456 19.6566 57.2867 17.4531Z"
            fill="url(#paint96_linear_44_564)"
        />
        <path
            d="M11.8067 38.6362C14.044 39.3865 16.3645 39.7184 18.7102 39.8796C22.0927 40.1127 25.4761 40.273 28.8399 39.6578C33.7214 38.763 37.1207 36.167 38.6122 31.4309C39.0169 30.1639 39.3524 28.876 39.6171 27.5729C41.06 20.3024 44.7157 14.4487 51.0213 10.2523C51.1925 10.1377 51.302 9.94193 51.5518 9.66882L49.8788 9.42833C50.6189 6.83612 49.9958 4.98773 47.8484 3.53084C45.6299 2.02547 43.1409 1.51747 40.5051 2.00776C37.6728 2.53441 35.3879 4.10129 33.2854 5.95341C32.6023 6.55462 31.9951 7.4131 31.1941 7.66104C30.3679 7.91644 29.348 7.56783 28.4123 7.49047C26.4184 7.32641 24.4048 6.91255 22.4333 7.05517C15.7534 7.53894 10.6933 10.669 7.54001 16.5096C5.26817 20.7181 4.73295 25.2771 5.03612 29.9628C5.3215 34.3429 7.57463 37.2166 11.8067 38.6362ZM44.347 5.64208C45.6252 6.16593 46.0902 7.02814 45.8638 8.35826C45.7758 8.87279 45.5924 9.09836 44.9983 9.03405C42.8368 8.79729 40.6698 8.61273 38.4485 8.40207C39.4497 5.8779 42.035 4.69505 44.347 5.64208ZM14.2685 26.9148C16.6751 25.6099 20.1362 25.9995 22.1722 27.8078C24.2878 29.6869 24.0277 32.4469 21.5977 33.915C20.5357 34.5563 19.3643 34.794 18.1357 34.8248C16.487 34.7754 14.9628 34.3643 13.6678 33.3203C11.2781 31.3936 11.5635 28.3838 14.2685 26.9148Z"
            fill="url(#paint97_linear_44_564)"
        />
        <path
            d="M65.5376 39.3128C64.7694 34.3819 63.5932 29.5479 62.1504 24.7717C61.8201 23.6802 61.4514 22.5999 61.0809 21.4543C60.9873 21.5792 60.9406 21.6128 60.9303 21.6538C60.1059 24.9339 59.2778 28.2122 58.4769 31.497C58.4039 31.799 58.4601 32.155 58.5387 32.4654C58.9756 34.174 59.5286 35.8583 59.8701 37.5827C60.4418 40.4723 60.1742 43.2687 58.4273 45.7928C57.8753 46.5926 57.3616 47.4194 56.8366 48.2406C56.7103 48.44 56.5428 48.6656 56.541 48.8809C56.5194 51.1991 56.5279 53.5182 56.5279 55.8848C56.744 55.7916 56.9686 55.6984 57.1828 55.5856C60.1236 54.1015 62.5165 51.727 64.0176 48.8032C65.5188 45.8793 66.0509 42.5567 65.5376 39.3128Z"
            fill="url(#paint98_linear_44_564)"
        />
        <path
            d="M57.2652 17.4306C57.0986 15.2597 55.9552 14.3071 53.7526 14.4898C52.4539 14.5979 51.3245 15.105 50.3055 15.8693C48.946 16.8862 47.9738 18.222 47.1541 19.6677C45.4568 22.6505 44.4481 25.883 43.476 29.1315C42.4214 32.6549 41.4811 36.2155 39.6911 39.4845C39.4881 39.8573 39.4104 40.314 39.3692 40.741C38.9463 45.1732 38.5477 49.6072 38.1276 54.0395C38.0902 54.4337 38.2736 55.034 37.6467 55.0704C37.4848 55.0797 37.1938 54.4729 37.1142 54.1159C36.4078 50.9523 35.7425 47.7775 35.0492 44.6083C34.8564 43.7312 34.3858 43.4637 33.4847 43.6436C33.1217 43.7163 32.7596 43.7936 32.3947 43.8533C28.2702 44.5291 24.1195 44.3855 19.9706 44.2028C17.1224 44.077 14.3182 43.6436 11.6327 42.6686C8.15574 41.4 5.28787 39.4397 3.81136 35.9294C3.73931 35.756 3.46422 35.6656 3.28363 35.536C3.1489 35.7439 2.93088 35.9387 2.89158 36.1624C2.25719 39.8107 2.04104 43.473 2.7138 47.1381C3.33416 50.5142 4.70494 53.51 7.5148 55.7229C9.7174 57.4575 12.2138 58.6544 14.8777 59.5278C20.5274 61.3799 26.3688 61.8198 32.2814 61.8525C34.9659 61.6576 37.6541 61.5048 40.3339 61.2578C43.9604 60.9316 47.4366 59.6591 50.4122 57.5684C51.9261 56.5198 52.6747 55.0825 52.7074 53.2518C52.7729 49.8878 52.7729 46.5182 53.0087 43.1654C53.2679 39.4789 53.587 35.7784 54.2111 32.1394C54.6911 29.343 55.7147 26.6399 56.4408 23.8771C56.9966 21.7668 57.4345 19.6304 57.2652 17.4306Z"
            fill="url(#paint99_linear_44_564)"
        />
        <path
            d="M11.8265 38.6102C14.0618 39.3559 16.3804 39.6924 18.7243 39.8536C22.1031 40.0867 25.4837 40.247 28.8447 39.6318C33.7224 38.7369 37.118 36.141 38.6076 31.4049C39.0125 30.1381 39.3476 28.8501 39.6116 27.5469C41.0535 20.2764 44.7055 14.4227 51.0064 10.2263C51.1776 10.1117 51.2918 9.91593 51.536 9.64282L49.8677 9.40233C50.6078 6.81012 49.9856 4.96173 47.84 3.50484C45.6187 1.99947 43.1307 1.49147 40.4996 1.98176C37.6701 2.5084 35.3861 4.07529 33.2864 5.92741C32.6043 6.52489 31.9961 7.3871 31.1961 7.63504C30.3708 7.89044 29.3518 7.54183 28.4171 7.46446C26.425 7.30041 24.4133 6.88655 22.4437 7.02917C15.7704 7.51294 10.7139 10.643 7.5663 16.4836C5.29352 20.6931 4.75737 25.2511 5.0624 29.9368C5.34779 34.3169 7.59624 37.1915 11.8265 38.6102ZM44.3368 5.61328C45.614 6.13713 46.0791 6.99934 45.8517 8.32947C45.7647 8.844 45.5813 9.06957 44.9871 9.00525C42.8285 8.7685 40.6633 8.58394 38.442 8.37328C39.4441 5.8519 42.0276 4.66905 44.3368 5.61608V5.61328ZM14.2864 26.8888C16.6911 25.5839 20.1484 25.9735 22.1826 27.7818C24.2973 29.6609 24.0372 32.4209 21.6091 33.889C20.5471 34.5303 19.3774 34.768 18.147 34.7988C16.4993 34.7494 14.9769 34.3383 13.6829 33.2943C11.2987 31.3676 11.5841 28.3578 14.2864 26.8888Z"
            fill="url(#paint100_linear_44_564)"
        />
        <path
            d="M65.5094 39.2869C64.7422 34.356 63.566 29.522 62.125 24.7459C61.7948 23.6544 61.427 22.574 61.0565 21.4285C60.9629 21.5534 60.9161 21.5869 60.9058 21.6279C60.0824 24.9081 59.2553 28.1863 58.4553 31.4711C58.3814 31.7731 58.4375 32.1292 58.517 32.4396C58.9531 34.1481 59.5061 35.8325 59.8467 37.5569C60.4184 40.4464 60.1498 43.2428 58.4057 45.7669C57.8536 46.5667 57.34 47.3935 56.8207 48.2147C56.6943 48.4142 56.5268 48.6397 56.525 48.8541C56.5035 51.1732 56.5119 53.4923 56.5119 55.859C56.728 55.7658 56.9526 55.6725 57.1669 55.5597C60.1048 54.0731 62.4946 51.6977 63.9934 48.7742C65.4922 45.8507 66.0229 42.5295 65.5094 39.2869Z"
            fill="url(#paint101_linear_44_564)"
        />
        <path
            d="M57.2446 17.4006C57.078 15.2297 55.9346 14.2771 53.7348 14.4598C52.438 14.5679 51.3086 15.0749 50.2906 15.8393C48.932 16.8599 47.9617 18.1957 47.1373 19.6423C45.4419 22.6251 44.4341 25.8577 43.4629 29.1061C42.4093 32.6295 41.4699 36.1902 39.6809 39.4591C39.4787 39.8319 39.4001 40.2887 39.3599 40.7156C38.9379 45.1478 38.5393 49.5819 38.1192 54.0141C38.0818 54.4084 38.2652 55.0087 37.6392 55.045C37.4764 55.0543 37.1863 54.4475 37.1068 54.0905C36.4013 50.9269 35.7369 47.7521 35.0436 44.5876C34.8508 43.7105 34.3811 43.443 33.4801 43.6229C33.118 43.6956 32.7568 43.7729 32.3919 43.8326C28.2749 44.5084 24.1232 44.3648 19.9791 44.1821C17.1337 44.0563 14.3313 43.6229 11.6515 42.6479C8.17448 41.3793 5.30942 39.419 3.83197 35.9087C3.75992 35.7353 3.48577 35.6449 3.30518 35.5153C3.17044 35.7232 2.95243 35.918 2.91313 36.1417C2.27873 39.79 2.06259 43.4523 2.73535 47.1174C3.35477 50.4935 4.72461 53.4893 7.53167 55.7021C9.7324 57.4368 12.2269 58.6336 14.888 59.507C20.533 61.3592 26.3698 61.7991 32.2796 61.8317C34.9622 61.6369 37.6485 61.4841 40.3265 61.237C43.95 60.9106 47.4231 59.6381 50.3954 57.5477C51.9131 56.4935 52.6541 55.0562 52.6897 53.2255C52.7552 49.8615 52.7552 46.4919 52.991 43.1391C53.2492 39.4526 53.5683 35.7521 54.1924 32.1131C54.6715 29.3167 55.6895 26.6136 56.4203 23.8508C56.9789 21.7405 57.4139 19.6041 57.2446 17.4006Z"
            fill="url(#paint102_linear_44_564)"
        />
        <path
            d="M11.8471 38.5839C14.0796 39.3342 16.3964 39.6661 18.7384 39.8273C22.1143 40.0603 25.4912 40.2207 28.8494 39.6055C33.7224 38.7106 37.1152 36.1147 38.6039 31.3786C39.008 30.1116 39.3428 28.8237 39.6069 27.5206C41.047 20.2501 44.6961 14.3964 50.9905 10.2C51.1617 10.0854 51.2712 9.88962 51.5201 9.61651L49.8499 9.37602C50.5891 6.78381 49.9678 4.93542 47.8232 3.47852C45.6084 1.97316 43.1242 1.46515 40.494 1.95731C37.6663 2.48396 35.3851 4.05084 33.2892 5.90203C32.6052 6.50231 31.9961 7.36358 31.1989 7.60873C30.3745 7.86413 29.3565 7.51552 28.4218 7.43815C26.4316 7.27037 24.4217 6.86024 22.4521 7.00285C15.7872 7.48662 10.7355 10.6167 7.58782 16.4573C5.31972 20.6658 4.78077 25.2248 5.08861 29.9105C5.37306 34.2905 7.62057 37.1643 11.8471 38.5839ZM44.3275 5.59256C45.6028 6.11641 46.0678 6.97862 45.8414 8.30875C45.7535 8.82328 45.571 9.04885 44.9768 8.98453C42.8201 8.74777 40.6577 8.56322 38.4373 8.35256C39.4395 5.82559 42.021 4.64274 44.3275 5.59256ZM14.3042 26.8625C16.707 25.5576 20.1616 25.9472 22.1939 27.7555C24.3057 29.6346 24.0456 32.3946 21.6193 33.8627C20.5592 34.504 19.3905 34.7417 18.1639 34.7724C16.518 34.723 14.9966 34.312 13.7035 33.268C11.3175 31.3413 11.6038 28.3362 14.3042 26.8625Z"
            fill="url(#paint103_linear_44_564)"
        />
        <path
            d="M65.4814 39.2608C64.7141 34.3299 63.5398 29.4959 62.0989 24.7197C61.7704 23.6282 61.4018 22.5479 61.0322 21.4023C60.9386 21.5272 60.8918 21.5608 60.8816 21.6018C60.0591 24.8819 59.2329 28.1602 58.4329 31.445C58.3599 31.747 58.416 32.103 58.4946 32.4134C58.9306 34.1211 59.4827 35.8063 59.8233 37.5307C60.3941 40.4203 60.1265 43.2167 58.3842 45.7408C57.8322 46.5406 57.3194 47.3674 56.8001 48.1886C56.6738 48.388 56.5072 48.6136 56.5044 48.828C56.4838 51.1471 56.4913 53.4662 56.4913 55.8328C56.7075 55.735 56.932 55.6464 57.1463 55.5336C60.0824 54.0453 62.4702 51.6693 63.9675 48.7461C65.4648 45.8228 65.9947 42.5024 65.4814 39.2608Z"
            fill="url(#paint104_linear_44_564)"
        />
        <path
            d="M57.224 17.3747C57.0575 15.2038 55.9141 14.2512 53.7171 14.4339C52.4211 14.542 51.2927 15.0491 50.2766 15.8134C48.9189 16.8341 47.9486 18.1698 47.1252 19.6164C45.4316 22.5992 44.4248 25.8318 43.4545 29.0802C42.4018 32.6036 41.4633 36.1643 39.6762 39.4332C39.4731 39.8061 39.3955 40.2628 39.3552 40.6897C38.9333 45.1219 38.5356 49.556 38.1155 53.9882C38.078 54.3825 38.2614 54.9828 37.6355 55.0191C37.4736 55.0284 37.1835 54.4216 37.104 54.0646C36.3985 50.901 35.7351 47.7262 35.0455 44.5617C34.8537 43.6846 34.383 43.4171 33.4838 43.597C33.1217 43.6697 32.7605 43.747 32.3966 43.8067C28.2795 44.4825 24.1354 44.3389 19.9941 44.1562C17.1515 44.0304 14.3519 43.597 11.6711 42.622C8.19789 41.3534 5.33563 39.3931 3.86099 35.8865C3.78894 35.7131 3.51478 35.6218 3.3342 35.4932C3.20039 35.701 2.98238 35.8958 2.94308 36.1195C2.30962 39.7678 2.09348 43.4301 2.7653 47.0952C3.38472 50.4713 4.75269 53.4671 7.55788 55.68C9.75674 57.4147 12.2485 58.6115 14.9067 59.4849C20.5471 61.3333 26.382 61.7732 32.2833 61.8059C34.9631 61.611 37.6467 61.4582 40.3218 61.2112C43.9425 60.8847 47.4125 59.6121 50.3814 57.5219C51.8981 56.4676 52.6382 55.0303 52.6738 53.1996C52.7393 49.8356 52.7393 46.466 52.9741 43.1132C53.2361 39.4286 53.5515 35.7262 54.1718 32.0872C54.6509 29.2909 55.6689 26.5877 56.3969 23.8249C56.9555 21.7184 57.3924 19.5745 57.224 17.3747Z"
            fill="url(#paint105_linear_44_564)"
        />
        <path
            d="M11.8668 38.5579C14.0974 39.3036 16.4123 39.6401 18.7525 39.8013C22.1209 40.0344 25.4997 40.1947 28.8579 39.5785C33.7234 38.6846 37.1162 36.0887 38.604 31.3526C39.0061 30.0864 39.339 28.7995 39.6014 27.4974C41.0405 20.2269 44.6869 14.3732 50.9756 10.1768C51.1468 10.0622 51.2563 9.86642 51.5052 9.59331L49.8359 9.35283C50.5751 6.75689 49.9529 4.90943 47.8092 3.44881C45.5963 1.94344 43.1149 1.43544 40.4866 1.92573C37.6617 2.45237 35.3824 4.01926 33.2818 5.87044C32.6006 6.47259 31.9952 7.33107 31.1961 7.57901C30.3718 7.83441 29.3547 7.4858 28.4218 7.40843C26.4335 7.24438 24.4246 6.83052 22.4587 6.97314C15.8032 7.4569 10.7561 10.5907 7.61128 16.4313C5.34505 20.6398 4.80984 25.1988 5.11487 29.8845C5.39932 34.2646 7.64403 37.1383 11.8668 38.5579ZM44.3182 5.56098C45.5926 6.08483 46.0567 6.94704 45.8303 8.27717C45.7432 8.79169 45.5608 9.01727 44.9676 8.95295C42.8155 8.71619 40.6512 8.53163 38.4337 8.32098C39.4348 5.7996 42.0136 4.61675 44.3182 5.56378V5.56098ZM14.322 26.8365C16.722 25.5316 20.1738 25.9212 22.2042 27.7295C24.3142 29.6086 24.055 32.3686 21.6306 33.8367C20.5714 34.478 19.4037 34.7157 18.178 34.7465C16.534 34.6971 15.0135 34.286 13.7222 33.242C11.339 31.3191 11.6244 28.3055 14.322 26.8365Z"
            fill="url(#paint106_linear_44_564)"
        />
        <path
            d="M65.4534 39.2346C64.687 34.3037 63.5127 29.4698 62.0737 24.6936C61.7452 23.6021 61.3775 22.5218 61.0079 21.3762C60.9143 21.5011 60.8676 21.5337 60.8573 21.5757C60.0357 24.8558 59.2095 28.1341 58.4114 31.4188C58.3375 31.7208 58.3936 32.0769 58.4731 32.3873C58.9082 34.0949 59.4603 35.7802 59.7999 37.5046C60.3707 40.3942 60.1031 43.1905 58.3618 45.7147C57.8107 46.5145 57.2979 47.3412 56.7796 48.1624C56.6532 48.3619 56.4867 48.5875 56.4848 48.8019C56.4633 51.121 56.4717 53.4401 56.4717 55.8067C56.6869 55.7088 56.9115 55.6203 57.1267 55.5075C60.0609 54.0175 62.4465 51.6408 63.9422 48.7178C65.4379 45.7948 65.9668 42.4754 65.4534 39.2346Z"
            fill="url(#paint107_linear_44_564)"
        />
        <path
            d="M57.2025 17.3486C57.0368 15.1777 55.8925 14.2251 53.6992 14.4077C52.4043 14.5159 51.2777 15.0229 50.2615 15.7873C48.9057 16.8079 47.9364 18.1437 47.1139 19.5903C45.4212 22.5731 44.4154 25.8057 43.446 29.0541C42.3943 32.5775 41.4567 36.1382 39.6705 39.4071C39.4684 39.7799 39.3898 40.2367 39.3496 40.6636C38.9285 45.0958 38.5309 49.5299 38.1117 53.9621C38.0742 54.3564 38.2576 54.9566 37.6326 54.993C37.4707 55.0023 37.1807 54.3955 37.1011 54.0385C36.3966 50.8749 35.7332 47.7001 35.0426 44.5356C34.8508 43.6585 34.3811 43.391 33.4819 43.5709C33.1207 43.6436 32.7596 43.7209 32.3956 43.7806C28.2786 44.4564 24.1419 44.3128 20.0052 44.1301C17.1645 44.0043 14.3677 43.5709 11.6889 42.5959C8.22124 41.3273 5.36178 39.367 3.88902 35.8567C3.81697 35.6833 3.54281 35.5919 3.36316 35.4633C3.22842 35.6712 3.01041 35.866 2.97204 36.0897C2.33859 39.738 2.12338 43.4003 2.79426 47.0654C3.41275 50.4415 4.78072 53.4373 7.58216 55.6501C9.77915 57.3848 12.269 58.5816 14.9254 59.455C20.5601 61.3072 26.3857 61.7471 32.2861 61.7797C34.9631 61.5849 37.6438 61.4321 40.3162 61.185C43.9345 60.8584 47.402 59.5857 50.3682 57.4957C51.8821 56.4415 52.6223 55.0042 52.6578 53.1726C52.7233 49.8095 52.7233 46.4399 52.9582 43.0871C53.2164 39.3996 53.5346 35.7001 54.1577 32.0611C54.6368 29.2647 55.6548 26.5616 56.3819 23.7988C56.9348 21.6885 57.3718 19.5521 57.2025 17.3486Z"
            fill="url(#paint108_linear_44_564)"
        />
        <path
            d="M11.8864 38.5318C14.1152 39.2822 16.4282 39.614 18.7655 39.7752C22.134 40.0083 25.5024 40.1686 28.8587 39.5525C33.7243 38.6586 37.1096 36.0626 38.5955 31.3265C38.9989 30.0595 39.3332 28.7716 39.5967 27.4685C41.0348 20.198 44.6765 14.3443 50.9606 10.1479C51.1308 10.0333 51.2413 9.83755 51.4892 9.56444L49.8218 9.32116C50.5601 6.72801 49.9388 4.88056 47.7989 3.42366C45.5879 1.91829 43.1083 1.41029 40.4818 1.90058C37.6598 2.42723 35.3823 3.99411 33.2883 5.8453C32.608 6.44744 32.0026 7.30592 31.2036 7.55386C30.3811 7.80926 29.365 7.46065 28.4321 7.38329C26.4456 7.21924 24.4386 6.80538 22.4746 6.94799C15.82 7.43176 10.7785 10.5618 7.63555 16.4024C5.37119 20.6137 4.83598 25.1727 5.14195 29.8585C5.42265 34.2385 7.6683 37.1122 11.8864 38.5318ZM44.3088 5.5349C45.5822 6.05875 46.0463 6.92375 45.8199 8.25388C45.7329 8.76841 45.5504 8.99398 44.9572 8.92967C42.8051 8.69291 40.6456 8.50835 38.4299 8.29769C39.4301 5.77352 42.0079 4.59067 44.3088 5.5377V5.5349ZM14.3397 26.8104C16.7379 25.5055 20.1859 25.8951 22.2144 27.7034C24.3235 29.5826 24.0634 32.3426 21.6418 33.8106C20.5835 34.4519 19.4168 34.6896 18.1919 34.7204C16.5498 34.671 15.0303 34.2599 13.74 33.2159C11.3596 31.2893 11.644 28.2795 14.3397 26.8104Z"
            fill="url(#paint109_linear_44_564)"
        />
        <path
            d="M65.4244 39.2083C64.659 34.2774 63.4866 29.4434 62.0484 24.6673C61.72 23.5757 61.3532 22.4954 60.9836 21.3499C60.89 21.4748 60.8433 21.5074 60.833 21.5493C60.0124 24.8294 59.1871 28.1077 58.3899 31.3925C58.316 31.6945 58.3721 32.0505 58.4507 32.3609C58.8858 34.0686 59.4369 35.7538 59.7775 37.4783C60.3473 40.3678 60.0797 43.1642 58.3403 45.6883C57.7892 46.4881 57.2774 47.3149 56.759 48.1361C56.6336 48.3355 56.4661 48.5611 56.4643 48.7755C56.4437 51.0946 56.4512 53.4137 56.4512 55.7803C56.6664 55.6871 56.8909 55.5939 57.1061 55.4811C60.0385 53.9896 62.4223 51.6124 63.9164 48.6896C65.4104 45.7667 65.9383 42.448 65.4244 39.2083Z"
            fill="url(#paint110_linear_44_564)"
        />
        <path
            d="M57.1819 17.3225C57.0154 15.1516 55.872 14.1989 53.6815 14.3816C52.3884 14.4898 51.2619 14.9968 50.2466 15.7612C48.8918 16.7818 47.9233 18.1166 47.1018 19.5642C45.411 22.547 44.4061 25.7795 43.4377 29.028C42.3869 32.5514 41.4503 36.112 39.6659 39.381C39.4638 39.7538 39.3852 40.2106 39.345 40.6375C38.9239 45.0697 38.5272 49.5037 38.108 53.936C38.0706 54.3302 38.254 54.9305 37.6289 54.9669C37.4671 54.9762 37.1779 54.3694 37.0984 54.0124C36.3948 50.8488 35.7323 47.674 35.0399 44.5048C34.8481 43.6277 34.3793 43.3602 33.481 43.5401C33.1189 43.6128 32.7587 43.6901 32.3956 43.7498C28.2852 44.4256 24.1495 44.282 20.0156 44.0993C17.1777 43.9735 14.3837 43.5401 11.7077 42.5651C8.24562 41.2965 5.38617 39.3362 3.91434 35.8259C3.84229 35.6525 3.56907 35.5612 3.38848 35.4325C3.25468 35.6404 3.03667 35.8352 2.9983 36.0589C2.36578 39.7081 2.14777 43.3742 2.82146 47.0392C3.43995 50.4154 4.80605 53.4112 7.60561 55.624C9.7998 57.3587 12.284 58.5555 14.9414 59.4289C20.5714 61.281 26.3923 61.721 32.2862 61.7536C34.9613 61.5588 37.6402 61.4059 40.3097 61.1589C43.986 60.8187 47.3367 59.5743 50.3524 57.4696C51.8654 56.4154 52.6046 54.9781 52.6401 53.1465C52.7056 49.7834 52.7056 46.4138 52.9405 43.061C53.1987 39.3735 53.5169 35.6739 54.1391 32.035C54.6172 29.2386 55.6362 26.5355 56.3604 23.7727C56.9143 21.6624 57.3532 19.526 57.1819 17.3225Z"
            fill="url(#paint111_linear_44_564)"
        />
        <path
            d="M11.9059 38.5057C14.1338 39.2514 16.444 39.5879 18.7795 39.7491C22.1479 39.9822 25.5164 40.1425 28.8634 39.5264C33.7233 38.6325 37.1067 36.0365 38.5945 31.3004C38.9972 30.0333 39.3311 28.7454 39.5947 27.4424C41.031 20.1719 44.6699 14.3182 50.9474 10.1218C51.1186 10.0072 51.2281 9.81144 51.476 9.5374L49.8096 9.29784C50.5478 6.70469 49.9275 4.85724 47.7894 3.40034C45.5803 1.89498 43.1026 1.38697 40.4789 1.87727C37.6597 2.40391 35.3841 3.9708 33.291 5.82198C32.6117 6.42413 32.0073 7.28261 31.2091 7.53055C30.3866 7.78595 29.3714 7.43734 28.4395 7.35997C26.4549 7.19592 24.4507 6.78206 22.4876 6.92467C15.8386 7.40844 10.8009 10.5385 7.66168 16.3791C5.39638 20.5876 4.86117 25.1466 5.16621 29.8324C5.45065 34.2086 7.69162 37.0861 11.9059 38.5057ZM44.2993 5.50879C45.5719 6.03544 46.0313 6.89764 45.8095 8.22777C45.7216 8.7423 45.5401 8.96787 44.9478 8.90356C42.7957 8.6668 40.6389 8.48224 38.4261 8.27158C39.4254 5.74741 41.9994 4.56735 44.2993 5.51159V5.50879ZM14.3574 26.7815C16.7537 25.4766 20.198 25.8653 22.2256 27.6745C24.3318 29.5537 24.0726 32.3136 21.653 33.7817C20.5956 34.423 19.4298 34.6607 18.2059 34.6915C16.5647 34.6421 15.048 34.231 13.7586 33.187C11.3801 31.2632 11.6645 28.2534 14.3574 26.7843V26.7815Z"
            fill="url(#paint112_linear_44_564)"
        />
        <path
            d="M65.4 39.1824C64.6356 34.2515 63.4641 29.4175 62.0316 24.6414C61.7031 23.5499 61.3354 22.4695 60.9667 21.324C60.8732 21.4489 60.8264 21.4815 60.8161 21.5234C59.9964 24.8036 59.1721 28.0818 58.3749 31.3666C58.3019 31.6686 58.3571 32.0247 58.4367 32.3351C58.8708 34.0427 59.4219 35.728 59.7616 37.4524C60.3305 40.3419 60.0638 43.1383 58.3253 45.6625C57.7751 46.4622 57.2633 47.289 56.7459 48.1102C56.6205 48.3097 56.4539 48.5352 56.4521 48.7496C56.4305 51.0687 56.438 53.3878 56.438 55.7545C56.6532 55.6613 56.8778 55.568 57.093 55.4553C63.1141 52.3849 66.4293 45.8386 65.4 39.1824Z"
            fill="url(#paint113_linear_44_564)"
        />
        <path
            d="M57.166 17.2966C56.9994 15.1257 55.856 14.1731 53.6684 14.3557C52.3762 14.4639 51.2515 14.9709 50.2373 15.7353C48.8833 16.7559 47.9158 18.0907 47.0952 19.5383C45.4054 22.5164 44.4014 25.7537 43.4339 29.0021C42.384 32.5255 41.4474 36.0862 39.665 39.3551C39.4638 39.7279 39.3842 40.1847 39.3449 40.6116C38.9248 45.0438 38.5281 49.4779 38.1089 53.9101C38.0715 54.3044 38.2549 54.9046 37.6308 54.941C37.4689 54.9503 37.1798 54.3435 37.1002 53.9865C36.3975 50.8229 35.7351 47.6481 35.0417 44.4836C34.8546 43.6065 34.3811 43.339 33.4838 43.5189C33.1226 43.5916 32.7624 43.6689 32.3994 43.7286C28.2936 44.4044 24.1607 44.2608 20.0315 44.0781C17.1954 43.9523 14.4043 43.5189 11.7301 42.5439C8.26805 41.2753 5.41422 39.315 3.94425 35.8047C3.87221 35.6313 3.59899 35.5399 3.41934 35.4113C3.2846 35.6192 3.06752 35.814 3.02822 36.0377C2.39663 39.686 2.1861 43.3483 2.85138 47.0134C3.46893 50.3895 4.83409 53.3853 7.63085 55.5981C9.8241 57.3328 12.3093 58.5296 14.9601 59.403C20.5854 61.2551 26.4007 61.6951 32.2899 61.7277C34.9622 61.5329 37.6383 61.3801 40.3059 61.133C43.9794 60.7928 47.3235 59.5438 50.3392 57.4437C51.8513 56.3895 52.5896 54.9522 52.6261 53.1206C52.6906 49.7575 52.6906 46.3879 52.9255 43.0351C53.1837 39.3476 53.5009 35.6481 54.1222 32.0091C54.6003 29.2127 55.6193 26.5096 56.3426 23.7468C56.8937 21.6365 57.3297 19.5001 57.166 17.2966Z"
            fill="url(#paint114_linear_44_564)"
        />
        <path
            d="M11.9265 38.4796C14.1516 39.2299 16.459 39.5618 18.7926 39.723C22.161 39.956 25.522 40.1164 28.868 39.5002C33.7233 38.6063 37.1021 36.0104 38.587 31.2743C38.9903 30.0074 39.3239 28.7194 39.5863 27.4163C41.0216 20.1458 44.6577 14.2921 50.9296 10.0957C51.0999 9.98106 51.2103 9.78531 51.4573 9.51127L49.7927 9.27172C50.5301 6.67857 49.9097 4.83112 47.7735 3.37422C45.5672 1.86885 43.0951 1.36085 40.4705 1.85114C37.6532 2.37779 35.3804 3.94468 33.2891 5.79586C32.6098 6.39801 32.0063 7.25648 31.2091 7.50443C30.3876 7.75983 29.3724 7.41122 28.4423 7.33385C26.4586 7.1698 24.4563 6.75594 22.496 6.89855C15.8527 7.38232 10.8187 10.5124 7.68225 16.353C5.42258 20.5606 4.8883 25.1205 5.1924 29.8062C5.47591 34.1862 7.715 37.06 11.9265 38.4796ZM44.29 5.48267C45.5634 6.00931 46.0247 6.87152 45.7983 8.20258C45.7113 8.71711 45.5298 8.94268 44.9375 8.87837C42.7854 8.64161 40.6333 8.45705 38.4214 8.24639C39.4197 5.72129 41.9919 4.53843 44.29 5.48546V5.48267ZM14.3752 26.7582C16.7687 25.4533 20.2111 25.8419 22.235 27.6512C24.3393 29.5303 24.0801 32.2903 21.6633 33.7584C20.6069 34.3997 19.4419 34.6374 18.2199 34.6681C16.5797 34.6187 15.0639 34.2077 13.7754 33.1637C11.3997 31.237 11.6842 28.2272 14.3752 26.7582Z"
            fill="url(#paint115_linear_44_564)"
        />
        <path
            d="M65.3681 39.1565C64.6037 34.2256 63.4332 29.3917 61.9997 24.6155C61.6713 23.524 61.3045 22.4437 60.9358 21.2981C60.8422 21.423 60.7964 21.4556 60.7861 21.4976C59.9664 24.7777 59.143 28.0559 58.3468 31.3407C58.2729 31.6427 58.329 31.9988 58.4076 32.3092C58.8418 34.0168 59.3919 35.7021 59.7316 37.4265C60.3005 40.3161 60.0338 43.1124 58.2972 45.6366C57.747 46.4363 57.2361 47.2631 56.7187 48.0843C56.5933 48.2838 56.4268 48.5094 56.4249 48.7237C56.4034 51.0428 56.4118 53.3619 56.4118 55.7286C56.627 55.6307 56.8506 55.5422 57.0668 55.4294C63.0879 52.359 66.4002 45.8127 65.3681 39.1565Z"
            fill="url(#paint116_linear_44_564)"
        />
        <path
            d="M57.1398 17.2702C56.9742 15.0993 55.8299 14.1467 53.646 14.3294C52.3547 14.4375 51.231 14.9446 50.2176 15.7089C48.8656 16.7296 47.899 18.0644 47.0784 19.5119C45.3942 22.4901 44.3874 25.7273 43.4208 28.9757C42.3719 32.4991 41.4362 36.0598 39.6556 39.3287C39.4535 39.7016 39.3749 40.1583 39.3356 40.5852C38.9155 45.0174 38.5188 49.4515 38.1005 53.8837C38.0631 54.278 38.2465 54.8783 37.6224 54.9146C37.4614 54.924 37.1723 54.3172 37.0928 53.9601C36.3901 50.7965 35.7285 47.6218 35.0343 44.4572C34.8471 43.5792 34.3793 43.3126 33.4782 43.4925C33.1171 43.5652 32.7578 43.6426 32.3947 43.7022C28.2917 44.378 24.1607 44.2345 20.0371 44.0518C17.2039 43.9259 14.4146 43.4925 11.7441 42.5175C8.28211 41.2489 5.43389 39.2886 3.96486 35.7774C3.89282 35.6049 3.6196 35.5136 3.43995 35.3849C3.30614 35.5928 3.08906 35.7876 3.04977 36.0113C2.41818 39.6596 2.20765 43.3219 2.87292 46.987C3.48954 50.3631 4.85377 53.3589 7.64491 55.5718C9.83629 57.3064 12.3233 58.5033 14.9685 59.3767C20.5873 61.2288 26.3979 61.6687 32.2787 61.7014C34.9482 61.5066 37.6224 61.3537 40.2872 61.1067C43.957 60.7665 47.3048 59.5174 50.3121 57.4174C51.8223 56.3631 52.5578 54.9258 52.5961 53.0942C52.6607 49.7311 52.6607 46.3615 52.8946 43.0087C53.1529 39.3213 53.4701 35.6217 54.0914 31.9827C54.5686 29.1864 55.5885 26.4832 56.3089 23.7204C56.8731 21.6101 57.3082 19.4737 57.1398 17.2702Z"
            fill="url(#paint117_linear_44_564)"
        />
        <path
            d="M11.9462 38.4534C14.1694 39.1991 16.4749 39.5356 18.8067 39.6969C22.1676 39.9299 25.5296 40.0902 28.8718 39.4741C33.7233 38.5802 37.1012 35.9843 38.5833 31.2482C38.9858 29.9811 39.3191 28.6932 39.5817 27.3902C41.0151 20.1197 44.6484 14.266 50.9147 10.0696C51.085 9.95494 51.1954 9.75919 51.4415 9.48515L49.7788 9.2456C50.5151 6.65245 49.8957 4.805 47.7614 3.3481C45.556 1.84273 43.083 1.33473 40.4631 1.82502C37.6504 2.35167 35.3786 3.91482 33.2901 5.76974C32.6118 6.37188 32.0073 7.23036 31.212 7.4783C30.3904 7.7337 29.3771 7.38509 28.447 7.30773C26.4662 7.14367 24.4647 6.72982 22.5064 6.87243C15.8686 7.3562 10.8393 10.4862 7.7057 16.3259C5.44789 20.5344 4.91455 25.0944 5.21772 29.7801C5.50216 34.1601 7.73845 37.0338 11.9462 38.4534ZM44.2807 5.45654C45.5513 5.98039 46.0136 6.8426 45.7881 8.17273C45.701 8.68726 45.5186 8.91283 44.9282 8.84851C42.7808 8.61176 40.624 8.4272 38.4177 8.21654C39.4151 5.69517 41.9845 4.51231 44.2807 5.45934V5.45654ZM14.3921 26.7321C16.7846 25.4271 20.2233 25.8158 22.2462 27.6251C24.3497 29.5042 24.0905 32.2633 21.6755 33.7323C20.62 34.3736 19.456 34.6113 18.235 34.642C16.5966 34.5926 15.0817 34.1816 13.7951 33.1376C11.4204 31.2109 11.7048 28.2011 14.3921 26.7321Z"
            fill="url(#paint118_linear_44_564)"
        />
        <path
            d="M65.3401 39.1301C64.5765 34.1992 63.4069 29.3653 61.9716 24.5891C61.6432 23.4976 61.2773 22.4173 60.9087 21.2717C60.8151 21.3966 60.7692 21.4293 60.759 21.4712C59.9402 24.7513 59.1178 28.0296 58.3262 31.3143C58.2532 31.6164 58.3093 31.9724 58.3879 32.2828C58.8212 33.9904 59.3713 35.6757 59.7101 37.4001C60.2789 40.2897 60.0123 43.086 58.2775 45.6102C57.7273 46.41 57.2174 47.2367 56.7 48.0579C56.5746 48.2574 56.409 48.483 56.4062 48.6974C56.3856 51.0165 56.3931 53.3356 56.3931 55.7022C56.6083 55.6044 56.8319 55.5158 57.048 55.403C63.0607 52.3326 66.3703 45.7864 65.3401 39.1301Z"
            fill="url(#paint119_linear_44_564)"
        />
        <path
            d="M57.1192 17.2441C56.9536 15.0732 55.8092 14.1206 53.6291 14.3033C52.3388 14.4114 51.2159 14.9185 50.2035 15.6828C48.8515 16.7035 47.8858 18.0382 47.0662 19.4858C45.3763 22.4639 44.378 25.7012 43.4114 28.9496C42.3634 32.473 41.4287 36.0337 39.6499 39.3026C39.4478 39.6754 39.3692 40.1322 39.3299 40.5591C38.9098 44.9913 38.514 49.4254 38.0958 53.8576C38.0583 54.2519 38.2417 54.8522 37.6186 54.8885C37.4567 54.8978 37.1685 54.291 37.089 53.934C36.3872 50.7704 35.7266 47.5956 35.037 44.4311C34.8452 43.5531 34.382 43.2865 33.4819 43.4664C33.1217 43.5391 32.7624 43.6164 32.3993 43.6761C28.301 44.3519 24.1756 44.2083 20.0539 44.0256C17.2235 43.8998 14.4398 43.4664 11.7675 42.4914C8.31199 41.2228 5.46283 39.2625 3.99568 35.7512C3.92363 35.5788 3.65041 35.4875 3.4717 35.3588C3.33696 35.5667 3.11988 35.7615 3.08152 35.9852C2.45086 39.6335 2.2394 43.2958 2.90467 46.9609C3.52129 50.337 4.88364 53.3328 7.67666 55.5457C9.86523 57.2803 12.3457 58.4772 14.9928 59.3505C20.6069 61.2027 26.4081 61.6426 32.2908 61.6752C34.9584 61.4804 37.6298 61.3276 40.2928 61.0806C43.9597 60.7403 47.301 59.496 50.3046 57.3912C51.8138 56.337 52.5502 54.8997 52.5867 53.0681C52.6513 49.705 52.6513 46.3354 52.8852 42.9826C53.1435 39.2951 53.4597 35.5956 54.081 31.9566C54.5582 29.1602 55.5781 26.4571 56.2967 23.6943C56.8525 21.584 57.2876 19.4476 57.1192 17.2441Z"
            fill="url(#paint120_linear_44_564)"
        />
        <path
            d="M11.9659 38.4275C14.1872 39.1779 16.4908 39.5097 18.8207 39.671C22.1779 39.904 25.537 40.0643 28.8765 39.4482C33.7243 38.5543 37.0983 35.9584 38.5795 31.2223C38.9803 29.9549 39.3126 28.667 39.5751 27.3643C41.0076 20.0938 44.6362 14.2401 50.8969 10.0437C51.0662 9.92903 51.1776 9.73329 51.4237 9.45925L49.7647 9.21969C50.5002 6.62655 49.8817 4.77909 47.7492 3.3222C45.5457 1.81683 43.0755 1.3051 40.4593 1.79912C37.6467 2.33043 35.3776 3.89265 33.2901 5.74383C32.6127 6.34505 32.0092 7.20446 31.2138 7.4524C30.3942 7.7078 29.3808 7.35919 28.4517 7.28183C26.4727 7.11777 24.4731 6.70391 22.5166 6.84653C15.8845 7.33029 10.8599 10.4603 7.73282 16.3C5.47407 20.5067 4.94073 25.0685 5.24389 29.7542C5.52741 34.1342 7.76182 37.0051 11.9659 38.4275ZM44.2722 5.43064C45.541 5.95449 46.0023 6.8167 45.7778 8.14683C45.6907 8.66136 45.5083 8.88693 44.9179 8.82261C42.7723 8.58586 40.6212 8.4013 38.4139 8.19064C39.4104 5.66926 41.9779 4.48641 44.2722 5.43344V5.43064ZM14.4099 26.7062C16.7996 25.4012 20.2354 25.7899 22.2565 27.5992C24.3581 29.4783 24.0989 32.2374 21.6867 33.7064C20.6322 34.3477 19.4691 34.5854 18.249 34.6161C16.6125 34.5667 15.0985 34.1557 13.8129 33.1117C11.4409 31.185 11.7244 28.1752 14.4099 26.7062Z"
            fill="url(#paint121_linear_44_564)"
        />
        <path
            d="M65.312 39.1043C64.5485 34.1734 63.3807 29.3394 61.9482 24.5632C61.6207 23.4717 61.2549 22.3914 60.8872 21.2458C60.7936 21.3698 60.7468 21.4034 60.7365 21.4453C59.9187 24.7254 59.0962 28.0037 58.3037 31.2885C58.2307 31.5905 58.2859 31.9465 58.3645 32.2569C58.7978 33.9646 59.348 35.6498 59.6867 37.3743C60.2537 40.2638 59.988 43.0602 58.2541 45.5843C57.7058 46.3841 57.1949 47.2109 56.6784 48.0321C56.554 48.2315 56.3874 48.4571 56.3856 48.6715C56.364 50.9906 56.3725 53.3097 56.3725 55.6763C56.5867 55.5831 56.8104 55.4899 57.0275 55.3771C63.0355 52.3067 66.3413 45.7605 65.312 39.1043Z"
            fill="url(#paint122_linear_44_564)"
        />
        <path
            d="M57.0985 17.218C56.9329 15.0471 55.7942 14.0944 53.6112 14.2771C52.3218 14.3853 51.2 14.8923 50.1885 15.6567C48.8383 16.6773 47.8736 18.0121 47.0549 19.4597C45.3706 22.4378 44.3685 25.675 43.4057 28.9235C42.3587 32.4469 41.4249 36.0075 39.6471 39.2755C39.4459 39.6484 39.3664 40.1061 39.328 40.533C38.9079 44.9652 38.513 49.3993 38.0948 53.8315C38.0583 54.2258 38.2407 54.826 37.6176 54.8624C37.4566 54.8717 37.1684 54.2649 37.0889 53.9079C36.3881 50.7443 35.7275 47.5695 35.0388 44.4003C34.8517 43.5223 34.3838 43.2557 33.4856 43.4356C33.1253 43.5083 32.766 43.5856 32.4039 43.6453C28.3084 44.3211 24.1877 44.1775 20.0688 43.9948C17.2412 43.869 14.4547 43.4356 11.7899 42.4606C8.33812 41.192 5.49083 39.2317 4.02367 35.7205C3.95163 35.548 3.67841 35.4567 3.49969 35.328C3.36589 35.5359 3.14881 35.7307 3.11045 35.9544C2.47979 39.6027 2.26833 43.265 2.9336 46.9301C3.54928 50.3062 4.9107 53.302 7.69998 55.5149C9.88667 57.2495 12.3653 58.4464 15.0095 59.3198C20.619 61.1765 26.4193 61.6128 32.2926 61.6491C34.9574 61.4543 37.626 61.3014 40.2871 61.0544C43.9503 60.7142 47.2888 59.4698 50.2933 57.3651C51.8016 56.3109 52.5389 54.8736 52.5735 53.042C52.6381 49.6789 52.6381 46.3093 52.872 42.9565C53.1293 39.269 53.4465 35.5695 54.066 31.9305C54.5432 29.1341 55.5631 26.431 56.2798 23.6682C56.8319 21.5579 57.2669 19.4215 57.0985 17.218Z"
            fill="url(#paint123_linear_44_564)"
        />
        <path
            d="M11.9855 38.4033C14.2049 39.1489 16.5067 39.4854 18.8337 39.6467C22.1882 39.8797 25.5445 40.0401 28.8811 39.4239C33.7242 38.53 37.0955 35.9341 38.5748 31.198C38.9776 29.9302 39.3115 28.6417 39.5751 27.3381C41.0067 20.0676 44.6324 14.2139 50.8875 10.0175C51.0578 9.90288 51.1682 9.70714 51.4143 9.43309L49.7544 9.19354C50.4851 6.60039 49.8676 4.75387 47.737 3.29604C45.5354 1.79068 43.067 1.28267 40.4527 1.7711C37.6457 2.29775 35.3766 3.86463 33.291 5.71582C32.6136 6.31889 32.011 7.17737 31.2166 7.42625C30.3969 7.68165 29.3855 7.33304 28.4573 7.25567C26.4792 7.09162 24.4815 6.67776 22.5269 6.82037C15.9022 7.30414 10.8813 10.4342 7.75336 16.2739C5.49929 20.4824 4.96689 25.0423 5.27005 29.7281C5.55262 34.1081 7.7861 36.9818 11.9855 38.4033ZM44.2666 5.40635C45.5335 5.9302 45.9957 6.79241 45.7702 8.12254C45.6832 8.63707 45.5017 8.86264 44.9122 8.79832C42.7686 8.56157 40.6193 8.37701 38.4139 8.16635C39.4057 5.64311 41.9704 4.46026 44.2628 5.40635H44.2666ZM14.4276 26.68C16.8155 25.3751 20.2476 25.7638 22.2677 27.573C24.3664 29.4521 24.1082 32.2112 21.6979 33.6802C20.6443 34.3215 19.4822 34.5592 18.263 34.59C16.6274 34.5406 15.1153 34.1295 13.8316 33.0855C11.4615 31.1588 11.745 28.15 14.4276 26.68Z"
            fill="url(#paint124_linear_44_564)"
        />
        <path
            d="M65.284 39.0781C64.5214 34.1472 63.3546 29.3133 61.923 24.5371C61.5955 23.4456 61.2296 22.3653 60.8628 21.2197C60.7693 21.3437 60.7234 21.3773 60.7122 21.4192C59.8954 24.6993 59.0738 27.9776 58.2794 31.2623C58.2055 31.5643 58.2616 31.9204 58.3402 32.2308C58.7725 33.9384 59.3218 35.6228 59.6605 37.3481C60.2275 40.2377 59.9618 43.034 58.2298 45.5582C57.6815 46.358 57.1716 47.1847 56.656 48.0059C56.5306 48.2054 56.365 48.431 56.3622 48.6454C56.3416 50.9645 56.3491 53.2836 56.3491 55.6502C56.5634 55.557 56.787 55.4638 57.0041 55.351C63.0093 52.2806 66.3123 45.7344 65.284 39.0781Z"
            fill="url(#paint125_linear_44_564)"
        />
        <path
            d="M57.078 17.1921C56.9124 15.0212 55.7746 14.0686 53.5935 14.2513C52.305 14.3594 51.1841 14.8665 50.1736 15.6308C48.8252 16.6505 47.8605 17.9862 47.0428 19.4338C45.3585 22.4119 44.3592 25.6492 43.3936 28.8976C42.3475 32.421 41.4146 35.9817 39.6387 39.2497C39.4375 39.6225 39.358 40.0802 39.3196 40.5071C38.9004 44.9393 38.5046 49.3734 38.0873 53.8056C38.0508 54.1999 38.2333 54.8002 37.6111 54.8365C37.4501 54.8458 37.161 54.239 37.0824 53.882C36.3816 50.7184 35.7219 47.5436 35.0342 44.3791C34.847 43.501 34.3792 43.2345 33.4819 43.4144C33.1226 43.4871 32.7633 43.5644 32.4012 43.6241C28.3103 44.2999 24.1924 44.1563 20.0773 43.9736C17.2524 43.8478 14.4706 43.4144 11.8067 42.4394C8.35968 41.1708 5.51614 39.2105 4.05085 35.7002C3.97881 35.5277 3.70652 35.4364 3.52687 35.3078C3.39307 35.5137 3.17692 35.7095 3.13763 35.9332C2.50791 39.5815 2.29551 43.2438 2.96172 46.9079C3.57646 50.285 4.93695 53.2808 7.72342 55.4936C9.90824 57.2283 12.385 58.4252 15.0218 59.2985C20.6265 61.1507 26.4212 61.5906 32.2889 61.6232C34.9518 61.4284 37.6223 61.2756 40.2768 61.0285C43.9363 60.6883 47.272 59.4393 50.2746 57.3392C51.7801 56.285 52.5203 54.8477 52.5521 53.0161C52.6176 49.653 52.6166 46.2834 52.8505 42.9306C53.1079 39.2431 53.4241 35.5436 54.0435 31.9046C54.5198 29.1082 55.535 26.4051 56.2555 23.6423C56.8113 21.532 57.2455 19.3956 57.078 17.1921Z"
            fill="url(#paint126_linear_44_564)"
        />
        <path
            d="M12.0052 38.3751C14.2227 39.1254 16.5226 39.4573 18.8478 39.6185C22.1994 39.8515 25.5529 40.0119 28.8858 39.3957C33.7243 38.5018 37.0927 35.9059 38.5711 31.1698C38.9719 29.9021 39.3042 28.614 39.5667 27.3109C40.9964 20.0404 44.6194 14.1867 50.8688 9.99028C51.0382 9.87563 51.1495 9.67989 51.3947 9.40585L49.7366 9.16723C50.4702 6.57408 49.8527 4.72663 47.724 3.26973C45.5251 1.76436 43.0587 1.25636 40.4472 1.74665C37.6401 2.2733 35.3748 3.84018 33.292 5.69137C32.6155 6.29258 32.0129 7.15199 31.2194 7.39993C30.4007 7.65533 29.3892 7.30672 28.462 7.22936C26.4858 7.0653 24.49 6.65145 22.5372 6.79406C15.9173 7.27783 10.902 10.4079 7.7768 16.2476C5.52554 20.4561 4.99314 25.016 5.29536 29.7017C5.57888 34.0817 7.80955 36.9555 12.0052 38.3751ZM44.2535 5.38097C45.5195 5.90482 45.9808 6.76703 45.7562 8.09716C45.6692 8.61169 45.4877 8.83726 44.8982 8.77294C42.7564 8.53618 40.61 8.35162 38.4055 8.14097C39.4011 5.6168 41.963 4.43394 44.2535 5.38097ZM14.4454 26.6584C16.8305 25.3534 20.2607 25.7421 22.278 27.5513C24.3749 29.4305 24.1167 32.1896 21.7082 33.6586C20.6556 34.2999 19.4953 34.5376 18.2771 34.5683C16.6433 34.5189 15.1322 34.1078 13.8494 33.0639C11.4812 31.1325 11.7647 28.1227 14.4454 26.6584Z"
            fill="url(#paint127_linear_44_564)"
        />
        <path
            d="M65.2549 39.0557C64.4942 34.1248 63.3274 29.2908 61.8977 24.5147C61.5711 23.4232 61.2053 22.3428 60.8385 21.1973C60.7449 21.3212 60.6991 21.3548 60.6888 21.3967C59.8728 24.6769 59.0513 27.9551 58.256 31.2399C58.183 31.5419 58.2391 31.898 58.3177 32.2084C58.75 33.916 59.2983 35.6003 59.6361 37.3257C60.2031 40.2152 59.9374 43.0116 58.2073 45.5348C57.659 46.3355 57.15 47.1623 56.6344 47.9835C56.5091 48.183 56.3434 48.4085 56.3416 48.6229C56.32 50.942 56.3285 53.2611 56.3285 55.6278C56.5427 55.5345 56.7654 55.4413 56.9834 55.3286C62.984 52.2544 66.2832 45.7082 65.2549 39.0557Z"
            fill="url(#paint128_linear_44_564)"
        />
        <path
            d="M57.0574 17.166C56.8918 14.9951 55.755 14.0424 53.5758 14.2251C52.2892 14.3333 51.1692 14.8403 50.1596 15.6047C48.8122 16.6244 47.8484 17.9601 47.0316 19.4077C45.3474 22.3858 44.3499 25.623 43.3871 28.8715C42.3419 32.3949 41.41 35.9555 39.635 39.2235C39.4338 39.5964 39.3543 40.0541 39.3169 40.481C38.8977 44.9132 38.5028 49.3473 38.0855 53.7795C38.049 54.1738 38.2315 54.774 37.6092 54.8104C37.4483 54.8197 37.1601 54.2129 37.0815 53.8559C36.3816 50.6923 35.7229 47.5175 35.0352 44.353C34.848 43.4749 34.3802 43.2083 33.4847 43.3882C33.1254 43.4609 32.7661 43.5383 32.405 43.598C28.317 44.2737 24.2028 44.1302 20.0923 43.9475C17.2693 43.8217 14.4903 43.3882 11.8283 42.4132C8.3822 41.1446 5.54052 39.1844 4.07711 35.6731C4.00506 35.5007 3.73372 35.4093 3.55406 35.2807C3.4212 35.4886 3.20412 35.6834 3.16576 35.9071C2.53604 39.5554 2.32364 43.2177 2.98891 46.8818C3.60459 50.2589 4.96321 53.2547 7.74687 55.4675C9.92982 57.2022 12.4047 58.399 15.0452 59.2724C20.6443 61.1245 26.4334 61.5645 32.2964 61.5971C34.9566 61.4023 37.6214 61.2494 40.2769 61.0024C43.9335 60.6622 47.2655 59.4132 50.2653 57.3131C51.7699 56.2589 52.5053 54.8216 52.5409 52.99C52.6064 49.6269 52.6055 46.2573 52.8394 42.9045C53.0958 39.217 53.412 35.5175 54.0314 31.8785C54.5068 29.0821 55.5211 26.379 56.2406 23.6162C56.7917 21.5059 57.2249 19.3695 57.0574 17.166Z"
            fill="url(#paint129_linear_44_564)"
        />
        <path
            d="M12.0257 38.3491C14.2405 39.0994 16.5385 39.4313 18.8609 39.5925C22.2097 39.8255 25.5604 39.9859 28.8905 39.3697C33.7243 38.4758 37.0899 35.8799 38.5664 31.1438C38.9677 29.8767 39.2998 28.5888 39.5611 27.2858C40.9898 20.0153 44.6138 14.1616 50.8538 9.96522C51.0232 9.85057 51.1345 9.65482 51.3788 9.38078L49.7217 9.14122C50.4552 6.54808 49.8386 4.70063 47.7118 3.24373C45.5148 1.73836 43.0512 1.23036 40.4415 1.72065C37.6345 2.2473 35.3739 3.81418 33.292 5.66537C32.6164 6.26658 32.0148 7.12599 31.2213 7.37393C30.4035 7.62933 29.3939 7.28072 28.4666 7.20336C26.4923 7.0393 24.4993 6.62544 22.5475 6.76806C15.9331 7.25183 10.9226 10.3819 7.80018 16.2188C5.55453 20.4273 5.01932 24.9872 5.32155 29.6729C5.60413 34.0557 7.83293 36.9295 12.0257 38.3491ZM44.2442 5.35217C45.5092 5.87602 45.9705 6.7373 45.7459 8.06836C45.6589 8.58289 45.4774 8.80846 44.8888 8.74414C42.7489 8.50739 40.6034 8.32283 38.4017 8.11217C39.3954 5.59266 41.9564 4.40794 44.2442 5.35497V5.35217ZM14.4632 26.6277C16.8464 25.3228 20.2729 25.7114 22.2883 27.5207C24.3843 29.3998 24.126 32.1589 21.7194 33.6279C20.6677 34.2692 19.5084 34.5069 18.2911 34.5377C16.6592 34.4883 15.15 34.0772 13.8672 33.0332C11.5046 31.1065 11.7853 28.0967 14.4632 26.6277Z"
            fill="url(#paint130_linear_44_564)"
        />
        <path
            d="M65.2269 39.0259C64.4662 34.095 63.3013 29.261 61.8725 24.4849C61.546 23.3934 61.181 22.3131 60.8142 21.1675C60.7207 21.2915 60.6748 21.325 60.6645 21.367C59.8496 24.6471 59.029 27.9253 58.2364 31.2101C58.1635 31.5121 58.2187 31.8682 58.2973 32.1786C58.7295 33.8862 59.2779 35.5705 59.6156 37.2959C60.1817 40.1854 59.916 42.9818 58.1868 45.505C57.6395 46.3057 57.1314 47.1325 56.6158 47.9537C56.4914 48.1532 56.3258 48.3787 56.323 48.5931C56.3024 50.9122 56.3099 53.2313 56.3099 55.598C56.5241 55.5001 56.7468 55.4116 56.9648 55.2988C62.9579 52.2284 66.2543 45.6821 65.2269 39.0259Z"
            fill="url(#paint131_linear_44_564)"
        />
        <path
            d="M57.0359 17.1396C56.8713 14.9687 55.7353 14.0161 53.558 14.1988C52.2724 14.3069 51.1533 14.814 50.1446 15.5783C48.7982 16.598 47.8363 17.9338 47.0194 19.3813C45.3352 22.3594 44.3406 25.5967 43.3787 28.8451C42.3345 32.3685 41.4035 35.9292 39.6303 39.1972C39.4292 39.57 39.3496 40.0277 39.3113 40.4546C38.893 44.8868 38.4991 49.3209 38.0818 53.7531C38.0453 54.1474 38.2268 54.7477 37.6064 54.784C37.4455 54.7933 37.1573 54.1865 37.0787 53.8295C36.3798 50.6659 35.721 47.4911 35.0343 44.3266C34.8471 43.4486 34.3793 43.182 33.4848 43.3619C33.1255 43.4346 32.7671 43.5119 32.4059 43.5716C28.3226 44.2474 24.2121 44.1038 20.1045 43.9211C17.2843 43.7953 14.5072 43.3619 11.848 42.3859C8.40561 41.1183 5.56674 39.158 4.10427 35.6467C4.03222 35.4743 3.76087 35.383 3.58215 35.2543C3.44835 35.4622 3.23221 35.657 3.19384 35.8807C2.56506 39.529 2.35173 43.1913 3.017 46.8554C3.63174 50.2325 4.98849 53.2283 7.77121 55.4412C9.95229 57.1758 12.4234 58.3727 15.0611 59.2461C20.6556 61.0982 26.44 61.5381 32.2974 61.5708C34.9557 61.3759 37.6177 61.2231 40.2703 60.9761C43.9195 60.6358 47.2534 59.3915 50.2494 57.2868C51.7531 56.2325 52.4885 54.7952 52.5231 52.9636C52.5886 49.6005 52.5877 46.2309 52.8216 42.8781C53.078 39.1907 53.3943 35.4911 54.0127 31.8512C54.4881 29.0548 55.5005 26.3517 56.22 23.5898C56.7702 21.4795 57.2044 19.3431 57.0359 17.1396Z"
            fill="url(#paint132_linear_44_564)"
        />
        <path
            d="M12.0454 38.3231C14.2583 39.0734 16.5535 39.4053 18.8759 39.5665C22.221 39.7995 25.5688 39.9599 28.8961 39.3437C33.7261 38.4498 37.088 35.8539 38.5636 31.1178C38.9642 29.8505 39.2959 28.5627 39.5573 27.2598C40.9852 19.9893 44.6016 14.1356 50.8398 9.93921C51.0092 9.82456 51.1205 9.62882 51.3647 9.35478L49.7086 9.11522C50.4421 6.52208 49.8255 4.67462 47.7006 3.21773C45.5055 1.71236 43.0437 1.20436 40.4369 1.69465C37.6345 2.2213 35.3739 3.78818 33.2938 5.63936C32.6183 6.24058 32.0176 7.09999 31.225 7.34793C30.4073 7.60333 29.3986 7.25472 28.4732 7.17736C26.5008 7.0133 24.5087 6.59944 22.5587 6.74206C15.95 7.22583 10.9432 10.3559 7.82451 16.1956C5.57606 20.4041 5.04834 24.964 5.34682 29.6497C5.6294 34.0297 7.85539 36.9035 12.0454 38.3231ZM44.2329 5.32617C45.497 5.85002 45.9574 6.7113 45.73 8.04236C45.6439 8.55689 45.4624 8.78246 44.8739 8.71814C42.7415 8.48232 40.5978 8.29589 38.398 8.08896C39.388 5.56479 41.9489 4.38101 44.2348 5.32897L44.2329 5.32617ZM14.4782 26.5989C16.8585 25.294 20.2794 25.6827 22.2958 27.4919C24.3899 29.371 24.1316 32.1301 21.7278 33.5991C20.6771 34.2404 19.5178 34.4781 18.3023 34.5089C16.6714 34.4595 15.164 34.0484 13.8831 33.0044C11.5214 31.0805 11.8049 28.0707 14.481 26.6017L14.4782 26.5989Z"
            fill="url(#paint133_linear_44_564)"
        />
        <path
            d="M65.1988 38.9995C64.439 34.0686 63.275 29.2347 61.8481 24.4585C61.5216 23.367 61.1566 22.2867 60.7898 21.1411C60.6963 21.2651 60.6514 21.2986 60.6401 21.3406C59.8261 24.6207 59.0064 27.899 58.2148 31.1837C58.1419 31.4857 58.1971 31.8418 58.2757 32.1522C58.707 33.8598 59.2544 35.5442 59.5922 37.2695C60.1583 40.1591 59.8925 42.9554 58.1653 45.4787C57.6188 46.2793 57.1098 47.1061 56.5952 47.9273C56.4707 48.1268 56.3051 48.3524 56.3032 48.5668C56.2827 50.8859 56.2901 53.205 56.2901 55.5716C56.5035 55.4784 56.7271 55.3852 56.9451 55.2724C62.9316 52.1983 66.2252 45.6558 65.1988 38.9995Z"
            fill="url(#paint134_linear_44_564)"
        />
        <path
            d="M57.0153 17.1125C56.8506 14.9426 55.7156 13.9899 53.5401 14.1726C52.2564 14.2808 51.1382 14.7878 50.1305 15.5522C48.785 16.5719 47.8241 17.9076 47.0081 19.3552C45.3295 22.3333 44.3311 25.5706 43.3702 28.819C42.3269 32.3424 41.3968 35.9031 39.6275 39.1711C39.4272 39.5439 39.3467 40.0016 39.3093 40.4285C38.892 44.8607 38.4971 49.2948 38.0817 53.727C38.0443 54.1213 38.2258 54.7215 37.6054 54.7579C37.4454 54.7672 37.1572 54.1604 37.0786 53.8034C36.3797 50.6398 35.7219 47.465 35.036 44.2958C34.8489 43.4178 34.3811 43.1512 33.4884 43.3311C33.1291 43.4038 32.7717 43.4812 32.4105 43.5408C28.33 44.2166 24.2233 44.0731 20.1194 43.8904C17.302 43.7645 14.5277 43.3311 11.8713 42.3552C8.43079 41.0875 5.59473 39.1272 4.13413 35.616C4.06208 35.4435 3.79073 35.3522 3.61202 35.2235C3.47822 35.4314 3.26207 35.6262 3.22371 35.8499C2.59587 39.4982 2.38159 43.1605 3.0478 46.8247C3.66161 50.2017 5.01274 53.1975 7.79734 55.4104C9.97374 57.1497 12.4402 58.3503 15.0779 59.219C20.6677 61.072 26.4465 61.512 32.2945 61.5446C34.9509 61.3498 37.6101 61.197 40.2609 60.9499C43.9101 60.6097 47.2374 59.3653 50.2316 57.2606C51.7333 56.2064 52.4679 54.7691 52.5034 52.9375C52.568 49.5744 52.567 46.2048 52.801 42.852C53.0573 39.1645 53.3727 35.465 53.9902 31.825C54.4655 29.0287 55.478 26.3256 56.1966 23.5637C56.7495 21.4534 57.1828 19.317 57.0153 17.1125Z"
            fill="url(#paint135_linear_44_564)"
        />
        <path
            d="M12.066 38.297C14.277 39.0473 16.5704 39.3791 18.889 39.5404C22.2322 39.7734 25.5763 39.9337 28.9008 39.3176C33.7262 38.4237 37.0853 35.8278 38.5599 31.0917C38.9597 29.8243 39.2911 28.5364 39.5527 27.2337C40.9786 19.9632 44.5913 14.1095 50.8249 9.91309C50.9933 9.79844 51.1056 9.6027 51.3488 9.32866L49.6946 9.0891C50.4272 6.49596 49.8115 4.6485 47.6884 3.1916C45.4943 1.68624 43.0372 1.17823 40.4303 1.66853C37.6308 2.19517 35.3711 3.76206 33.2939 5.61324C32.6192 6.21446 32.0185 7.07387 31.2269 7.32181C30.4101 7.57721 29.4014 7.2286 28.477 7.15123C26.5064 6.98718 24.5162 6.57332 22.5681 6.71593C15.9659 7.1997 10.9656 10.3297 7.84792 16.1695C5.60228 20.378 5.07081 24.9379 5.37303 29.6236C5.65561 34.0036 7.87973 36.8773 12.066 38.297ZM44.2264 5.30005C45.4896 5.8239 45.9499 6.68517 45.7235 8.01624C45.6374 8.53076 45.4559 8.75634 44.8683 8.69202C42.7321 8.45526 40.5903 8.2707 38.3933 8.06005C39.388 5.53867 41.9415 4.35582 44.2255 5.30285L44.2264 5.30005ZM14.4988 26.5756C16.8782 25.2706 20.3 25.6593 22.3108 27.4686C24.4021 29.3477 24.1447 32.1068 21.7428 33.5758C20.693 34.2171 19.5346 34.4548 18.3201 34.4855C16.6911 34.4361 15.1846 34.0251 13.9046 32.9811C11.542 31.0544 11.8246 28.0446 14.4988 26.5756Z"
            fill="url(#paint136_linear_44_564)"
        />
        <path
            d="M65.1707 38.9736C64.4119 34.0427 63.2488 29.2088 61.8228 24.4326C61.4963 23.3411 61.1323 22.2608 60.7664 21.1152C60.6729 21.2392 60.627 21.2728 60.6167 21.3147C59.8027 24.5939 58.9839 27.8731 58.1924 31.1578C58.1203 31.4599 58.1755 31.8159 58.2541 32.1263C58.6845 33.834 59.2319 35.5183 59.5641 37.2436C60.1255 40.1332 59.8644 42.9295 58.139 45.4528C57.5926 46.2535 57.0845 47.0803 56.5708 47.9014C56.4454 48.1009 56.2808 48.3265 56.2789 48.5409C56.2574 50.86 56.2658 53.1791 56.2658 55.5457C56.4791 55.4525 56.7018 55.3593 56.9151 55.2465C62.9054 52.1761 66.1962 45.6299 65.1707 38.9736Z"
            fill="url(#paint137_linear_44_564)"
        />
        <path
            d="M56.9947 17.0867C56.83 14.9167 55.696 13.9641 53.5233 14.1468C52.2396 14.2549 51.1223 14.762 50.1156 15.5263C48.7719 16.546 47.811 17.8817 46.996 19.3293C45.3192 22.3074 44.3227 25.5447 43.3618 28.7931C42.3194 32.3165 41.3894 35.8772 39.619 39.1452C39.4188 39.518 39.3383 39.9757 39.3009 40.4026C38.8845 44.8348 38.4906 49.2689 38.078 53.7011C38.0405 54.0954 38.2221 54.6957 37.6026 54.732C37.4417 54.7413 37.1544 54.1345 37.0758 53.7775C36.3778 50.6139 35.721 47.4391 35.0351 44.2699C34.848 43.3919 34.3801 43.1253 33.4884 43.3052C33.1301 43.3779 32.7726 43.4553 32.4115 43.5149C28.3356 44.1907 24.2326 44.0472 20.1325 43.8645C17.317 43.7386 14.5455 43.3052 11.891 42.3293C8.45419 41.0616 5.62187 39.1014 4.16127 35.5901C4.08922 35.4176 3.81787 35.3263 3.63916 35.1977C3.50629 35.4055 3.29015 35.6003 3.25179 35.824C2.62394 39.4723 2.40967 43.1346 3.07588 46.7988C3.68875 50.1758 5.04081 53.1717 7.82073 55.3845C9.99807 57.1192 12.4655 58.316 15.0976 59.1885C20.6817 61.0415 26.4558 61.4815 32.3029 61.5187C34.9565 61.3239 37.6139 61.1711 40.2619 60.9241C43.911 60.5838 47.2318 59.3348 50.2232 57.2347C51.7202 56.1805 52.4576 54.7432 52.4931 52.9116C52.5577 49.5485 52.5577 46.1789 52.7907 42.8261C53.0461 39.1387 53.3614 35.4391 53.979 31.7992C54.4534 29.0028 55.4649 26.2997 56.1825 23.5378C56.729 21.4275 57.1659 19.2948 56.9947 17.0867Z"
            fill="url(#paint138_linear_44_564)"
        />
        <path
            d="M12.0847 38.2708C14.2939 39.0212 16.5853 39.353 18.9021 39.5143C22.2416 39.7473 25.5829 39.9076 28.9046 39.2915C33.7252 38.3976 37.0815 35.8017 38.5543 31.0656C38.9542 29.7982 39.2853 28.5104 39.5461 27.2075C40.9712 19.937 44.581 14.0834 50.808 9.88697C50.9774 9.77232 51.0887 9.57657 51.332 9.30253L49.6805 9.06298C50.4122 6.46983 49.7965 4.62238 47.6763 3.16548C45.4849 1.66011 43.0278 1.15211 40.4247 1.6424C37.628 2.16905 35.372 3.73594 33.2948 5.58712C32.6211 6.18833 32.0213 7.04774 31.2297 7.29569C30.4138 7.55109 29.407 7.20248 28.4826 7.12511C26.5176 6.96106 24.5256 6.5472 22.5793 6.68981C15.9818 7.17731 10.9843 10.3036 7.87131 16.1433C5.62567 20.3518 5.09701 24.9117 5.3983 29.5975C5.68088 33.9775 7.90313 36.8512 12.0847 38.2708ZM44.2161 5.27393C45.4784 5.79778 45.9378 6.65905 45.7132 7.99011C45.6262 8.50464 45.4456 8.73021 44.858 8.6659C42.7246 8.42914 40.5847 8.24458 38.3887 8.03392C39.3814 5.51255 41.9349 4.32969 44.2161 5.27672V5.27393ZM14.5156 26.5495C16.8922 25.2445 20.3094 25.6332 22.3202 27.4424C24.4095 29.3216 24.1522 32.0806 21.7531 33.5497C20.7042 34.191 19.5468 34.4286 18.3341 34.4594C16.706 34.41 15.2005 33.9989 13.9215 32.955C11.5626 31.0283 11.8442 28.0185 14.5156 26.5495Z"
            fill="url(#paint139_linear_44_564)"
        />
        <path
            d="M65.1427 38.9478C64.3848 34.0169 63.2226 29.1829 61.7976 24.4068C61.472 23.3152 61.108 22.2349 60.7421 21.0894C60.6486 21.2133 60.6027 21.2469 60.5924 21.2888C59.7793 24.568 58.9615 27.8472 58.1709 31.132C58.0979 31.434 58.154 31.79 58.2317 32.1004C58.6631 33.8081 59.2095 35.4924 59.5463 37.2178C60.1078 40.1073 59.8467 42.9037 58.1222 45.4269C57.5758 46.2276 57.0686 47.0544 56.555 47.8756C56.4305 48.075 56.2649 48.3006 56.263 48.515C56.2424 50.8341 56.2499 53.1532 56.2499 55.5199C56.4633 55.4266 56.686 55.3334 56.8993 55.2206C62.8793 52.1502 66.1672 45.604 65.1427 38.9478Z"
            fill="url(#paint140_linear_44_564)"
        />
        <path
            d="M56.9788 17.0576C56.8141 14.8876 55.6819 13.935 53.5102 14.1177C52.2274 14.2258 51.112 14.7329 50.1062 15.4972C48.7635 16.517 47.8035 17.8527 46.9894 19.3003C45.3136 22.2784 44.318 25.5156 43.358 28.764C42.3166 32.2874 41.3875 35.8481 39.62 39.1161C39.4197 39.489 39.3393 39.9466 39.3018 40.3736C38.8845 44.8058 38.4915 49.2398 38.0761 53.672C38.0387 54.0663 38.2202 54.6666 37.6008 54.703C37.4408 54.7123 37.1535 54.1055 37.0749 53.7485C36.3778 50.5849 35.721 47.4101 35.036 44.2456C34.8489 43.3675 34.3811 43.1009 33.4912 43.2808C33.1329 43.3535 32.7754 43.4309 32.4152 43.4905C28.3422 44.1663 24.2429 44.0228 20.1465 43.8401C17.3338 43.7143 14.5651 43.2808 11.9125 42.3049C8.47945 41.0372 5.64339 39.077 4.18934 35.5657C4.11823 35.3933 3.84688 35.3019 3.66816 35.1733C3.5353 35.3811 3.31915 35.5759 3.28079 35.7997C2.65388 39.448 2.43867 43.1102 3.10488 46.7744C3.71775 50.1514 5.06982 53.1473 7.84599 55.3601C10.0215 57.0948 12.4861 58.2916 15.1163 59.1641C20.6957 61.0171 26.4642 61.4571 32.3067 61.4944C34.9575 61.2995 37.612 61.1467 40.26 60.8997C43.9035 60.5594 47.2233 59.3104 50.2119 57.2104C51.709 56.1561 52.4445 54.7188 52.48 52.8872C52.5446 49.5241 52.5446 46.1545 52.7776 42.8017C53.033 39.1143 53.3483 35.4147 53.965 31.7748C54.4393 28.9784 55.4499 26.2753 56.1666 23.5134C56.7084 21.4013 57.1416 19.2649 56.9788 17.0576Z"
            fill="url(#paint141_linear_44_564)"
        />
        <path
            d="M12.1044 38.2448C14.3116 38.9951 16.6013 39.3269 18.9152 39.4882C22.2519 39.7212 25.5904 39.8815 28.9093 39.2654C33.7252 38.3715 37.0787 35.7756 38.5468 31.0395C38.9464 29.7722 39.2772 28.4843 39.5377 27.1815C40.9618 19.911 44.5679 14.0573 50.7893 9.8609C50.9587 9.74625 51.07 9.5505 51.3133 9.27646L49.6656 9.04157C50.3963 6.44842 49.7816 4.60097 47.6632 3.14407C45.4699 1.63404 43.0184 1.12604 40.4172 1.61633C37.6233 2.14298 35.3645 3.70987 33.2939 5.56105C32.6202 6.16226 32.0213 7.02167 31.2307 7.26962C30.4157 7.52502 29.4089 7.1764 28.4863 7.09904C26.5214 6.93499 24.5321 6.52113 22.5915 6.66374C16.0015 7.14751 11.0087 10.2776 7.90127 16.1173C5.65282 20.3258 5.12322 24.8875 5.42264 29.5714C5.70334 33.9514 7.92653 36.8251 12.1044 38.2448ZM44.2077 5.24786C45.4699 5.77916 45.9275 6.63578 45.7039 7.96684C45.6168 8.48137 45.4363 8.70694 44.8496 8.64169C42.7181 8.40587 40.5801 8.22131 38.3859 8.01065C39.3768 5.48648 41.9274 4.30362 44.2077 5.25065V5.24786ZM14.5334 26.5234C16.9082 25.2184 20.3215 25.6071 22.3305 27.4164C24.418 29.2955 24.1616 32.0546 21.7634 33.5236C20.7155 34.1649 19.5599 34.4026 18.3482 34.4333C16.721 34.3839 15.2174 33.9729 13.9402 32.9289C11.5822 31.0022 11.8648 27.9924 14.5334 26.5234Z"
            fill="url(#paint142_linear_44_564)"
        />
        <path
            d="M65.1193 38.9212C64.3605 33.9903 63.2002 29.1563 61.7761 24.3801C61.4514 23.2886 61.0874 22.2083 60.7216 21.0627C60.628 21.1867 60.5831 21.2203 60.5728 21.2622C59.7597 24.5414 58.9428 27.8206 58.1531 31.1054C58.0801 31.4074 58.1354 31.7634 58.214 32.0738C58.6444 33.7815 59.1908 35.4658 59.5239 37.1911C60.0853 40.0807 59.8243 42.8771 58.1017 45.4003C57.5552 46.201 57.049 47.0278 56.5353 47.849C56.4109 48.0484 56.2462 48.274 56.2443 48.4884C56.2228 50.8075 56.2312 53.1266 56.2312 55.4932C56.4446 55.3954 56.6663 55.3068 56.8797 55.194C62.854 52.1236 66.1383 45.5802 65.1193 38.9212Z"
            fill="url(#paint143_linear_44_564)"
        />
        <path
            d="M56.9536 17.0344C56.7889 14.8644 55.6577 13.9118 53.4915 14.0945C52.2106 14.2026 51.0962 14.7097 50.0903 15.474C48.7448 16.4984 47.7867 17.8295 46.9726 19.2771C45.2987 22.2552 44.3041 25.4924 43.345 28.7409C42.3045 32.2642 41.38 35.8249 39.6097 39.0929C39.4095 39.4658 39.329 39.9234 39.2925 40.3504C38.8752 44.7826 38.4822 49.2166 38.0677 53.6488C38.0303 54.0431 38.2118 54.6434 37.5933 54.6798C37.4333 54.6891 37.1461 54.0823 37.0675 53.7253C36.3713 50.5617 35.7154 47.3869 35.0305 44.2224C34.8433 43.3443 34.3755 43.0777 33.4866 43.2576C33.1292 43.3303 32.7717 43.4077 32.4115 43.4673C28.3422 44.1431 24.2467 43.9996 20.154 43.8169C17.347 43.6911 14.5774 43.2576 11.9275 42.2817C8.49728 41.0131 5.6687 39.0556 4.21184 35.5425C4.13979 35.3701 3.86938 35.2787 3.69067 35.1501C3.5578 35.3579 3.34259 35.5528 3.30423 35.7765C2.67732 39.4248 2.46211 43.087 3.12832 46.7512C3.74026 50.1282 5.09326 53.1241 7.86475 55.3369C10.0383 57.0716 12.5011 58.2684 15.1294 59.1409C20.7033 60.9939 26.4671 61.4339 32.303 61.4665C34.9519 61.2717 37.6046 61.1188 40.2479 60.8718C43.8877 60.5316 47.2056 59.2872 50.1914 57.1825C51.6885 56.1283 52.4221 54.691 52.4567 52.8593C52.5212 49.4963 52.5212 46.1267 52.7533 42.7739C53.0097 39.0864 53.3241 35.3868 53.9407 31.7469C54.4141 28.9506 55.4237 26.2474 56.1405 23.4856C56.6878 21.3753 57.1201 19.2389 56.9536 17.0344Z"
            fill="url(#paint144_linear_44_564)"
        />
        <path
            d="M12.124 38.2167C14.3294 38.9671 16.6153 39.2989 18.9292 39.4602C22.2631 39.6932 25.5978 39.8535 28.9139 39.2374C33.7261 38.3435 37.0759 35.7475 38.5459 31.0115C38.9454 29.7441 39.2762 28.4562 39.5367 27.1534C40.959 19.8829 44.5623 14.0292 50.7781 9.83286C50.9465 9.71821 51.0588 9.52246 51.3011 9.24842L49.6515 9.00887C50.3823 6.41759 49.7675 4.56734 47.6501 3.11324C45.4634 1.60787 43.01 1.09986 40.4172 1.59016C37.6205 2.1168 35.3645 3.68369 33.2957 5.53487C32.623 6.13609 32.0241 6.9955 31.2372 7.24344C30.4222 7.49884 29.4173 7.15023 28.4947 7.07193C26.5298 6.90881 24.5443 6.49495 22.5999 6.63756C16.0146 7.12133 11.0264 10.2532 7.91809 16.0911C5.67806 20.2996 5.14939 24.8595 5.44975 29.548C5.73139 33.929 7.94896 36.799 12.124 38.2167ZM44.1983 5.21982C45.4578 5.74367 45.9162 6.60494 45.6954 7.936C45.6093 8.45053 45.4287 8.6761 44.843 8.61085C42.7125 8.37503 40.5763 8.18954 38.3868 7.97981C39.3711 5.4603 41.9199 4.27745 44.1983 5.22448V5.21982ZM14.5502 26.4972C16.9231 25.1923 20.3346 25.581 22.3407 27.3902C24.4273 29.2693 24.17 32.0284 21.7746 33.4974C20.7276 34.1387 19.573 34.3764 18.3622 34.4072C16.7369 34.3578 15.2342 33.9467 13.9579 32.9027C11.6028 30.976 11.8845 27.9634 14.5502 26.4972Z"
            fill="url(#paint145_linear_44_564)"
        />
        <path
            d="M65.0875 38.8953C64.3296 33.9644 63.1703 29.1304 61.7481 24.3543C61.4224 23.2628 61.0594 22.1824 60.6935 21.0369C60.6 21.1608 60.5551 21.1944 60.5448 21.2363C59.7335 24.5155 58.9167 27.7947 58.1279 31.0795C58.0549 31.3815 58.1101 31.7376 58.1887 32.0479C58.6182 33.7556 59.1637 35.4399 59.4987 37.1653C60.0601 40.0548 59.7981 42.8512 58.0764 45.3744C57.5319 46.1751 57.0257 47.0019 56.5129 47.8231C56.3885 48.0226 56.2228 48.2481 56.221 48.4625C56.2004 50.7816 56.2079 53.1007 56.2079 55.4674C56.4212 55.3741 56.643 55.2809 56.8563 55.1681C62.8278 52.0978 66.1093 45.5515 65.0875 38.8953Z"
            fill="url(#paint146_linear_44_564)"
        />
        <path
            d="M56.933 17.0085C56.7684 14.8386 55.638 13.8859 53.471 14.0686C52.191 14.1768 51.0775 14.6838 50.0735 15.4482C48.7327 16.4679 47.7746 17.8036 46.9624 19.2512C45.2894 22.2293 44.2957 25.4665 43.3375 28.715C42.298 32.2384 41.3726 35.7991 39.606 39.067C39.4058 39.4399 39.3253 39.8976 39.2888 40.3245C38.8725 44.7567 38.4795 49.1908 38.065 53.623C38.0275 54.0173 38.209 54.6175 37.5906 54.6539C37.4306 54.6632 37.1442 54.0564 37.0656 53.6994C36.3695 50.5358 35.7145 47.361 35.0305 44.1918C34.8434 43.3138 34.3755 43.0472 33.4876 43.2271C33.1311 43.2998 32.7737 43.3772 32.4144 43.4368C28.3488 44.1126 24.2571 43.969 20.1681 43.7864C17.3611 43.6605 14.5961 43.2271 11.9491 42.2512C8.52071 40.9881 5.69401 39.0279 4.23902 35.5138C4.16697 35.3414 3.89656 35.25 3.71878 35.1214C3.58498 35.3293 3.36977 35.5241 3.33141 35.7478C2.70543 39.3961 2.48929 43.0584 3.15643 46.7225C3.76744 50.0996 5.12137 53.0954 7.88819 55.3082C10.0599 57.0429 12.5208 58.2397 15.1463 59.1122C20.7155 60.968 26.4756 61.408 32.3049 61.4406C34.951 61.2458 37.6009 61.0929 40.2423 60.8459C43.8793 60.5057 47.1935 59.2613 50.1765 57.1566C51.6735 56.1024 52.4053 54.6651 52.4399 52.8335C52.5054 49.4704 52.5044 46.1008 52.7365 42.748C52.9929 39.0605 53.3073 35.361 53.9229 31.721C54.3908 28.9247 55.4041 26.2216 56.1209 23.4597C56.6682 21.3457 57.0996 19.213 56.933 17.0085Z"
            fill="url(#paint147_linear_44_564)"
        />
        <path
            d="M12.1436 38.1927C14.3462 38.943 16.6349 39.2749 18.9423 39.4361C22.2733 39.6692 25.6053 39.8295 28.9176 39.2134C33.7261 38.3195 37.073 35.7235 38.5421 30.9874C38.941 29.72 39.2711 28.4322 39.5311 27.1294C40.9524 19.8589 44.5529 14.0052 50.7593 9.8079C50.9277 9.69418 51.04 9.49843 51.2814 9.22439L49.6337 8.98484C50.3635 6.39169 49.7497 4.54424 47.6341 3.08734C45.453 1.58477 43.0025 1.07397 40.4069 1.56426C37.6176 2.09091 35.3664 3.6578 33.2957 5.50898C32.6239 6.11019 32.026 6.9696 31.2372 7.21755C30.4241 7.47295 29.4191 7.12433 28.4975 7.04604C26.5326 6.88292 24.5508 6.46906 22.6102 6.61167C16.0304 7.09544 11.047 10.2255 7.94144 16.0652C5.70328 20.2737 5.17462 24.8336 5.47591 29.5193C5.75661 33.8994 7.97325 36.7731 12.1436 38.1927ZM44.1889 5.19579C45.4474 5.71964 45.9059 6.58091 45.686 7.91197C45.5999 8.4265 45.4193 8.65207 44.8336 8.58682C42.7059 8.351 40.5716 8.16551 38.3774 7.95578C39.3664 5.43441 41.9143 4.25155 44.1889 5.19858V5.19579ZM14.568 26.4723C16.938 25.1673 20.3467 25.556 22.3519 27.3652C24.4357 29.2444 24.1793 32.0034 21.7858 33.4725C20.7397 34.1137 19.586 34.3514 18.3762 34.3822C16.7518 34.3328 15.251 33.9217 13.9785 32.8778C11.6224 30.9464 11.9041 27.9403 14.568 26.4723Z"
            fill="url(#paint148_linear_44_564)"
        />
        <path
            d="M65.0594 38.8696C64.3024 33.9387 63.144 29.1048 61.7227 24.3286C61.398 23.2371 61.035 22.1568 60.6701 21.0112C60.5765 21.1352 60.5316 21.1688 60.5213 21.2107C59.7101 24.4899 58.8941 27.7691 58.1054 31.0538C58.0333 31.3559 58.0885 31.7119 58.1662 32.0223C58.5957 33.73 59.1412 35.4143 59.4761 37.1396C60.0375 40.0292 59.7756 42.8255 58.0558 45.3488C57.5112 46.1495 57.0059 46.9763 56.4932 47.7974C56.3687 47.9969 56.204 48.2225 56.2022 48.4369C56.1816 50.756 56.1891 53.0751 56.1891 55.4417C56.4024 55.3485 56.6242 55.2553 56.8366 55.1425C62.8016 52.0721 66.0811 45.5259 65.0594 38.8696Z"
            fill="url(#paint149_linear_44_564)"
        />
        <path
            d="M56.9124 16.982C56.7477 14.8121 55.6184 13.8595 53.4504 14.0421C52.1713 14.1503 51.0588 14.6573 50.0548 15.4217C48.7158 16.4414 47.7586 17.7771 46.9464 19.2247C45.2753 22.2028 44.2825 25.4401 43.3253 28.6885C42.2867 32.2119 41.3604 35.7726 39.5966 39.0406C39.3973 39.4134 39.3206 39.8711 39.2804 40.298C38.864 44.7302 38.4719 49.1643 38.0574 53.5965C38.0209 53.9908 38.2015 54.591 37.584 54.6274C37.424 54.6367 37.1377 54.0299 37.0591 53.6729C36.3638 50.5093 35.7089 47.3345 35.0258 44.1653C34.8387 43.2873 34.3708 43.0207 33.4847 43.2006C33.1273 43.2733 32.7718 43.3507 32.4125 43.4103C28.3497 44.0861 24.2617 43.9426 20.1765 43.7599C17.3695 43.634 14.6092 43.2006 11.9649 42.2247C8.54033 40.957 5.71644 38.9968 4.26238 35.4855C4.19127 35.313 3.92086 35.2217 3.74308 35.0931C3.61021 35.3009 3.395 35.4957 3.35664 35.7194C2.73441 39.3724 2.52201 43.0347 3.18354 46.6988C3.79454 50.0759 5.14848 53.0717 7.91249 55.2845C10.0814 57.0192 12.5394 58.216 15.1631 59.0885C20.7267 60.9416 26.4849 61.3815 32.3067 61.4188C34.95 61.224 37.598 61.0711 40.2366 60.8241C43.8699 60.4839 47.1813 59.2395 50.1624 57.1348C51.6595 56.0806 52.3884 54.6432 52.4239 52.8116C52.4885 49.4486 52.4876 46.079 52.7196 42.7261C52.9751 39.0387 53.2895 35.3391 53.9042 31.6992C54.3777 28.9029 55.3854 26.1997 56.1002 23.4379C56.6476 21.3229 57.079 19.1865 56.9124 16.982Z"
            fill="url(#paint150_linear_44_564)"
        />
        <path
            d="M12.1596 38.1665C14.3603 38.9168 16.6443 39.2486 18.9526 39.4099C22.2799 39.6429 25.6091 39.8033 28.9186 39.1871C33.7224 38.2932 37.0666 35.6973 38.5347 30.9612C38.9324 29.6935 39.2622 28.4057 39.5227 27.1032C40.9431 19.8327 44.5399 13.979 50.7444 9.78167C50.9128 9.66795 51.0251 9.4722 51.2665 9.19816L49.6197 8.95861C50.3486 6.36546 49.7357 4.51801 47.622 3.06111C45.4391 1.55574 42.9904 1.04774 40.3976 1.53803C37.6102 2.06468 35.3646 3.63529 33.2967 5.48275C32.6249 6.08396 32.027 6.94337 31.2382 7.19132C30.4251 7.44672 29.422 7.0981 28.5004 7.01981C26.5354 6.85669 24.5574 6.44283 22.6187 6.58544C16.0464 7.06921 11.0667 10.1993 7.96397 16.039C5.72862 20.2475 5.19995 24.8074 5.50031 29.4931C5.78289 33.8731 7.99671 36.7468 12.1596 38.1665ZM44.1759 5.16956C45.4335 5.69341 45.8919 6.55468 45.673 7.88574C45.5869 8.40027 45.4063 8.62584 44.8215 8.56059C42.6947 8.32477 40.5632 8.13928 38.3756 7.92955C39.3618 5.40631 41.9059 4.22532 44.1796 5.17235L44.1759 5.16956ZM14.5858 26.4451C16.954 25.1401 20.359 25.5288 22.3623 27.3381C24.4442 29.2172 24.1878 31.9763 21.8009 33.4453C20.7557 34.0866 19.6029 34.3243 18.394 34.355C16.7716 34.3056 15.2717 33.8946 13.9963 32.8506C11.6431 30.9239 11.9238 27.9141 14.5858 26.4451Z"
            fill="url(#paint151_linear_44_564)"
        />
        <path
            d="M65.0313 38.843C64.2753 33.9121 63.1178 29.0782 61.6975 24.302C61.3737 23.2105 61.0107 22.1302 60.6458 20.9846C60.5522 21.1086 60.5073 21.1421 60.497 21.1841C59.6867 24.4633 58.8717 27.7425 58.0839 31.0272C58.0118 31.3292 58.067 31.6853 58.1447 31.9957C58.5742 33.7033 59.1187 35.3877 59.4546 37.113C60.0161 40.0026 59.7531 42.7989 58.0352 45.3222C57.4916 46.1229 56.9854 46.9496 56.4735 47.7708C56.35 47.9703 56.1854 48.1959 56.1835 48.4103C56.162 50.7294 56.1704 53.0485 56.1704 55.4151C56.3828 55.3219 56.6045 55.2287 56.8169 55.1159C62.7801 52.0455 66.055 45.4993 65.0313 38.843Z"
            fill="url(#paint152_linear_44_564)"
        />
        <path
            d="M56.8917 16.956C56.728 14.7861 55.5986 13.8335 53.4353 14.0161C52.1581 14.1243 51.0456 14.6313 50.0435 15.3957C48.7054 16.4154 47.7492 17.7511 46.9379 19.1987C45.2677 22.1768 44.2759 25.4141 43.3178 28.6625C42.2801 32.1859 41.3528 35.7466 39.5928 39.0146C39.3935 39.3874 39.3168 39.8451 39.2765 40.272C38.8602 44.7042 38.4681 49.1383 38.0545 53.5705C38.018 53.9648 38.1986 54.5651 37.5867 54.6014C37.4267 54.6107 37.1404 54.0039 37.0618 53.6469C36.3675 50.4833 35.7135 47.3085 35.0304 44.144C34.8433 43.2659 34.3754 42.9994 33.4903 43.1793C33.1338 43.251 32.7782 43.3293 32.4189 43.389C28.3608 44.0648 24.2785 43.9212 20.1942 43.7385C17.3871 43.6127 14.6325 43.1793 11.9901 42.2033C8.56924 40.9357 5.74722 38.9754 4.29504 35.4641C4.22299 35.2917 3.95351 35.2003 3.77573 35.0717C3.64286 35.2796 3.42766 35.4744 3.38929 35.6981C2.76519 39.3464 2.54718 43.0087 3.21432 46.6728C3.82439 50.0499 5.17364 53.0457 7.93859 55.2585C10.1056 56.9932 12.5618 58.1901 15.1827 59.0625C20.7416 60.9156 26.4895 61.3555 32.3057 61.3928C34.9471 61.198 37.5923 61.0451 40.2291 60.7981C43.8595 60.4579 47.1681 59.2089 50.1473 57.1088C51.6444 56.0546 52.3714 54.6173 52.407 52.7856C52.4716 49.4226 52.4706 46.053 52.7027 42.7001C52.9581 39.0127 53.2725 35.3131 53.8863 31.6732C54.3542 28.8769 55.3656 26.1737 56.0805 23.4119C56.6269 21.2969 57.0583 19.1605 56.8917 16.956Z"
            fill="url(#paint153_linear_44_564)"
        />
        <path
            d="M12.1829 38.1404C14.3818 38.8907 16.663 39.2225 18.9694 39.3838C22.2939 39.6168 25.6203 39.7771 28.927 39.161C33.7261 38.2671 37.0675 35.6712 38.5337 30.9351C38.9319 29.6676 39.2617 28.3797 39.5218 27.0771C40.9403 19.8066 44.5333 13.9529 50.7331 9.75557C50.9016 9.64185 51.0138 9.4461 51.2543 9.17206L49.6094 8.93251C50.3355 6.33843 49.7254 4.49191 47.6136 3.03501C45.4325 1.52965 42.9857 1.02537 40.3957 1.51193C37.6102 2.03858 35.3645 3.60547 33.2976 5.45665C32.6267 6.05786 32.0288 6.91727 31.2391 7.16522C30.4269 7.42062 29.4248 7.072 28.505 6.99371C26.5448 6.83059 24.5648 6.41673 22.627 6.55934C16.0613 7.04311 11.0863 10.1732 7.98638 16.0129C5.7529 20.2214 5.22518 24.7813 5.52553 29.467C5.80811 33.847 8.02007 36.7254 12.1829 38.1404ZM44.1702 5.14346C45.4269 5.66731 45.8844 6.52858 45.6617 7.85964C45.5756 8.37417 45.3951 8.59974 44.8103 8.53449C42.6862 8.29867 40.5566 8.11318 38.3699 7.90345C39.3571 5.38208 41.8993 4.19922 44.1702 5.14625V5.14346ZM14.6026 26.4162C16.969 25.1112 20.3711 25.4999 22.3688 27.3092C24.4488 29.1883 24.1934 31.9474 21.8074 33.4164C20.7632 34.0577 19.6123 34.2954 18.4043 34.3261C16.7837 34.2767 15.2847 33.8657 14.0122 32.8217C11.6627 30.8978 11.9443 27.888 14.6026 26.419V26.4162Z"
            fill="url(#paint154_linear_44_564)"
        />
        <path
            d="M65.0032 38.8171C64.2481 33.8863 63.0916 29.0523 61.6731 24.2761C61.3484 23.1846 60.9863 22.1043 60.6224 20.9587C60.5288 21.0827 60.4839 21.1163 60.4736 21.1582C59.6642 24.4374 58.8492 27.7166 58.0623 31.0014C57.9903 31.3034 58.0455 31.6594 58.1231 31.9698C58.5517 33.6775 59.0963 35.3618 59.4331 37.0871C59.9945 39.9767 59.7325 42.773 58.0155 45.2963C57.4719 46.097 56.9666 46.9238 56.4558 47.7449C56.3313 47.9444 56.1666 48.17 56.1648 48.3844C56.1442 50.7035 56.1517 53.0226 56.1517 55.3892C56.3641 55.2914 56.5858 55.2028 56.7982 55.09C62.7501 52.0196 66.0231 45.4734 65.0032 38.8171Z"
            fill="url(#paint155_linear_44_564)"
        />
        <path
            d="M56.8712 16.9301C56.7074 14.7592 55.5799 13.8075 53.4176 13.9902C52.1413 14.0983 51.0306 14.6054 50.0294 15.3697C48.6923 16.3894 47.737 17.7252 46.9258 19.1727C45.2584 22.1508 44.2666 25.3881 43.3112 28.6365C42.2745 32.1599 41.3463 35.7206 39.59 38.9886C39.3907 39.3614 39.3093 39.8191 39.2737 40.246C38.8583 44.6782 38.4663 49.1123 38.0574 53.5445C38.0209 53.9388 38.2015 54.5391 37.5848 54.5754C37.4258 54.5847 37.1395 53.9779 37.0618 53.6209C36.3666 50.4573 35.7135 47.2825 35.0314 44.118C34.8442 43.24 34.3764 42.9734 33.4931 43.1533C33.1366 43.225 32.781 43.3033 32.4227 43.363C28.3674 44.0388 24.2869 43.8952 20.2092 43.7125C17.4087 43.5867 14.6521 43.1533 12.0116 42.1773C8.59358 40.9097 5.77436 38.9494 4.32312 35.4381C4.252 35.2657 3.98159 35.1744 3.80475 35.0457C3.67188 35.2536 3.45761 35.4484 3.41925 35.6721C2.79514 39.3204 2.58181 42.9827 3.24427 46.6468C3.8534 50.0239 5.20172 53.0197 7.96387 55.2326C10.129 56.9672 12.5833 58.1641 15.2014 59.0365C20.7556 60.8896 26.4988 61.3295 32.3141 61.3622C34.9528 61.1673 37.5961 61.0145 40.23 60.7675C43.8567 60.4272 47.1625 59.1829 50.138 57.0781C51.6257 56.0202 52.3556 54.5866 52.3939 52.7578C52.4585 49.3947 52.4585 46.0251 52.6896 42.6723C52.945 38.9848 53.2585 35.2853 53.8723 31.6454C54.3401 28.849 55.3507 26.1459 56.0646 23.384C56.6045 21.2709 57.0377 19.1345 56.8712 16.9301Z"
            fill="url(#paint156_linear_44_564)"
        />
        <path
            d="M12.2026 38.1142C14.3996 38.8646 16.6789 39.1964 18.9835 39.3577C22.3052 39.5907 25.6269 39.751 28.9317 39.1349C33.7271 38.241 37.0647 35.645 38.53 30.909C38.9274 29.6413 39.2569 28.3534 39.5171 27.0509C40.9337 19.7804 44.524 13.9267 50.7182 9.72942C50.8857 9.6157 50.9989 9.41996 51.2394 9.14592L49.5954 8.90636C50.3233 6.31322 49.7114 4.46576 47.6014 3.00886C45.4222 1.5035 42.9782 0.995495 40.3892 1.48579C37.6102 2.01243 35.3646 3.57932 33.2976 5.4305C32.6277 6.03172 32.0307 6.89113 31.2391 7.13907C30.4279 7.39447 29.4258 7.04586 28.5069 6.96756C26.5532 6.80444 24.5752 6.39058 22.6392 6.53319C16.0792 7.01696 11.1088 10.147 8.01168 15.9867C5.78007 20.1952 5.25235 24.7551 5.5527 29.4409C5.83341 33.8209 8.04256 36.6946 12.2026 38.1142ZM44.16 5.12011C45.4147 5.64396 45.8723 6.50523 45.6496 7.83629C45.5626 8.35082 45.3829 8.57639 44.7991 8.51114C42.6769 8.27532 40.5482 8.08983 38.3644 7.8801C39.3524 5.35593 41.8919 4.17308 44.16 5.12011ZM14.6204 26.3929C16.9849 25.0879 20.3833 25.4766 22.3866 27.2858C24.4648 29.165 24.2093 31.924 21.8252 33.393C20.7819 34.0343 19.631 34.2711 18.4249 34.3028C16.8052 34.2534 15.3082 33.8423 14.0366 32.7984C11.6833 30.8717 11.964 27.8619 14.6204 26.3929Z"
            fill="url(#paint157_linear_44_564)"
        />
        <path
            d="M64.9751 38.7908C64.2209 33.8599 63.0653 29.0259 61.6478 24.2498C61.324 23.1583 60.9619 22.0779 60.5979 20.9324C60.5044 21.0563 60.4595 21.0899 60.4492 21.1318C59.6407 24.411 58.8267 27.6902 58.0407 30.975C57.9687 31.277 58.0239 31.6331 58.1015 31.9435C58.5301 33.6511 59.0737 35.3354 59.4115 37.0608C59.9729 39.9503 59.71 42.7467 57.9949 45.2699C57.4512 46.0706 56.9469 46.8974 56.436 47.7186C56.3125 47.9181 56.1478 48.1436 56.146 48.358C56.1254 50.6771 56.1329 52.9962 56.1329 55.3629C56.3453 55.265 56.5661 55.1764 56.7785 55.0637C62.7238 51.9933 65.994 45.447 64.9751 38.7908Z"
            fill="url(#paint158_linear_44_564)"
        />
        <path
            d="M56.8712 16.9301C56.7074 14.7592 55.5799 13.8075 53.4176 13.9902C52.1413 14.0983 51.0306 14.6054 50.0294 15.3697C48.6923 16.3894 47.737 17.7252 46.9258 19.1727C45.2584 22.1508 44.2666 25.3881 43.3112 28.6365C42.2745 32.1599 41.3463 35.7206 39.59 38.9886C39.3907 39.3614 39.3093 39.8191 39.2737 40.246C38.8583 44.6782 38.4663 49.1123 38.0574 53.5445C38.0209 53.9388 38.2015 54.5391 37.5848 54.5754C37.4258 54.5847 37.1395 53.9779 37.0618 53.6209C36.3666 50.4573 35.7135 47.2825 35.0314 44.118C34.8442 43.24 34.3764 42.9734 33.4931 43.1533C33.1366 43.225 32.781 43.3033 32.4227 43.363C28.3674 44.0388 24.2869 43.8952 20.2092 43.7125C17.4087 43.5867 14.6521 43.1533 12.0116 42.1773C8.59358 40.9097 5.77436 38.9494 4.32312 35.4381C4.252 35.2657 3.98159 35.1744 3.80475 35.0457C3.67188 35.2536 3.45761 35.4484 3.41925 35.6721C2.79514 39.3204 2.58181 42.9827 3.24427 46.6468C3.8534 50.0239 5.20172 53.0197 7.96387 55.2326C10.129 56.9672 12.5833 58.1641 15.2014 59.0365C20.7556 60.8896 26.4988 61.3295 32.3141 61.3622C34.9528 61.1673 37.5961 61.0145 40.23 60.7675C43.8567 60.4272 47.1625 59.1829 50.138 57.0781C51.6257 56.0202 52.3556 54.5866 52.3939 52.7578C52.4585 49.3947 52.4585 46.0251 52.6896 42.6723C52.945 38.9848 53.2585 35.2853 53.8723 31.6454C54.3401 28.849 55.3507 26.1459 56.0646 23.384C56.6045 21.2709 57.0377 19.1345 56.8712 16.9301Z"
            fill="url(#paint159_linear_44_564)"
        />
        <path
            d="M12.2026 38.1142C14.3996 38.8646 16.6789 39.1964 18.9835 39.3577C22.3052 39.5907 25.6269 39.751 28.9317 39.1349C33.7271 38.241 37.0647 35.645 38.53 30.909C38.9274 29.6413 39.2569 28.3534 39.5171 27.0509C40.9337 19.7804 44.524 13.9267 50.7182 9.72942C50.8857 9.6157 50.9989 9.41996 51.2394 9.14592L49.5954 8.90636C50.3233 6.31322 49.7114 4.46576 47.6014 3.00886C45.4222 1.5035 42.9782 0.995495 40.3892 1.48579C37.6102 2.01243 35.3646 3.57932 33.2976 5.4305C32.6277 6.03172 32.0307 6.89113 31.2391 7.13907C30.4279 7.39447 29.4258 7.04586 28.5069 6.96756C26.5532 6.80444 24.5752 6.39058 22.6392 6.53319C16.0792 7.01696 11.1088 10.147 8.01168 15.9867C5.78007 20.1952 5.25235 24.7551 5.5527 29.4409C5.83341 33.8209 8.04256 36.6946 12.2026 38.1142ZM44.16 5.12011C45.4147 5.64396 45.8723 6.50523 45.6496 7.83629C45.5626 8.35082 45.3829 8.57639 44.7991 8.51114C42.6769 8.27532 40.5482 8.08983 38.3644 7.8801C39.3524 5.35593 41.8919 4.17308 44.16 5.12011ZM14.6204 26.3929C16.9849 25.0879 20.3833 25.4766 22.3866 27.2858C24.4648 29.165 24.2093 31.924 21.8252 33.393C20.7819 34.0343 19.631 34.2711 18.4249 34.3028C16.8052 34.2534 15.3082 33.8423 14.0366 32.7984C11.6833 30.8717 11.964 27.8619 14.6204 26.3929Z"
            fill="url(#paint160_linear_44_564)"
        />
        <path
            d="M64.9751 38.7908C64.2209 33.8599 63.0653 29.0259 61.6478 24.2498C61.324 23.1583 60.9619 22.0779 60.5979 20.9324C60.5044 21.0563 60.4595 21.0899 60.4492 21.1318C59.6407 24.411 58.8267 27.6902 58.0407 30.975C57.9687 31.277 58.0239 31.6331 58.1015 31.9435C58.5301 33.6511 59.0737 35.3354 59.4115 37.0608C59.9729 39.9503 59.71 42.7467 57.9949 45.2699C57.4512 46.0706 56.9469 46.8974 56.436 47.7186C56.3125 47.9181 56.1478 48.1436 56.146 48.358C56.1254 50.6771 56.1329 52.9962 56.1329 55.3629C56.3453 55.265 56.5661 55.1764 56.7785 55.0637C62.7238 51.9933 65.994 45.447 64.9751 38.7908Z"
            fill="url(#paint161_linear_44_564)"
        />
        <path
            d="M56.8712 17.4798C56.7075 15.3518 55.58 14.4188 53.4176 14.5977C52.1413 14.704 51.0307 15.2008 50.0295 15.9502C48.6924 16.9495 47.7371 18.2581 46.9258 19.6787C45.2584 22.5971 44.2666 25.7691 43.3113 28.9532C42.2745 32.4021 41.3463 35.8956 39.59 39.0984C39.3907 39.461 39.3093 39.9121 39.2738 40.3307C38.8583 44.6734 38.4663 49.0189 38.0574 53.3626C38.0209 53.7494 38.2015 54.3376 37.5849 54.373C37.4258 54.3823 37.1395 53.7876 37.0618 53.4409C36.3666 50.3406 35.7135 47.2311 35.0314 44.1281C34.8443 43.2677 34.3764 43.0096 33.4931 43.182C33.1366 43.2528 32.7811 43.3293 32.4227 43.388C28.3628 44.0461 24.2822 43.9025 20.2064 43.7254C17.4059 43.6024 14.6494 43.182 12.0089 42.2219C8.58894 40.9794 5.76973 39.0555 4.31848 35.6169C4.24737 35.4482 3.97695 35.3587 3.80011 35.232C3.66724 35.4361 3.45297 35.6263 3.41461 35.8462C2.79051 39.4218 2.57717 43.0105 3.23963 46.6019C3.84876 49.9109 5.19709 52.8471 7.95923 55.0152C10.1244 56.7154 12.5787 57.888 15.1967 58.7437C20.751 60.5594 26.4942 60.9901 32.3095 61.0227C34.9481 60.8363 37.5914 60.6815 40.2254 60.4392C43.7891 60.1202 47.206 58.8733 50.1333 56.8235C51.6258 55.7917 52.3556 54.3823 52.3902 52.588C52.4548 49.292 52.4548 45.9895 52.6859 42.7075C52.9413 39.0947 53.2548 35.4687 53.8686 31.9025C54.3364 29.1611 55.347 26.5102 56.0609 23.8052C56.6045 21.7331 57.0378 19.6386 56.8712 17.4798Z"
            fill="url(#paint162_linear_44_564)"
        />
        <path
            d="M12.2026 38.24C14.3996 38.9745 16.6789 39.2998 18.9835 39.4583C22.3052 39.6857 25.6269 39.8442 28.9317 39.2402C33.7271 38.364 37.0647 35.8193 38.5299 31.1783C38.9272 29.9367 39.2567 28.6747 39.5171 27.3977C40.9337 20.2754 44.5239 14.5345 50.7182 10.4229C50.8857 10.311 50.9989 10.119 51.2394 9.85057L49.5954 9.61568C50.3233 7.07473 49.7114 5.26363 47.6014 3.83656C45.4222 2.36102 42.9782 1.86327 40.3892 2.34518C37.6102 2.85971 35.3645 4.39583 33.2976 6.20973C32.6277 6.79883 32.0307 7.64053 31.2391 7.88754C30.4279 8.13828 29.4257 7.79433 28.5069 7.71976C26.5485 7.56037 24.5705 7.15396 22.6345 7.29378C16.0745 7.76823 11.1041 10.8358 8.00698 16.559C5.77537 20.6827 5.24765 25.1513 5.548 29.7429C5.83339 34.0315 8.04254 36.8484 12.2026 38.24ZM44.16 5.90586C45.4147 6.41946 45.8723 7.26302 45.6496 8.56705C45.5626 9.07133 45.3829 9.29317 44.799 9.22885C42.6769 8.99676 40.5482 8.81593 38.3643 8.61086C39.3524 6.13609 41.8919 4.97654 44.16 5.90586ZM14.6204 26.7517C16.9849 25.471 20.3833 25.8541 22.3866 27.626C24.4647 29.4688 24.2093 32.1729 21.8252 33.6121C20.7819 34.2403 19.631 34.4733 18.4249 34.5032C16.8052 34.4556 15.3081 34.052 14.0365 33.0286C11.6833 31.142 11.964 28.1918 14.6204 26.7517Z"
            fill="url(#paint163_linear_44_564)"
        />
        <path
            d="M64.9751 38.903C64.2209 34.0709 63.0653 29.3329 61.6478 24.6519C61.324 23.5827 60.9619 22.5238 60.5979 21.4006C60.5044 21.5227 60.4595 21.5554 60.4492 21.5964C59.6407 24.8103 58.8267 28.0233 58.0407 31.2419C57.9687 31.5383 58.0239 31.8869 58.1015 32.1917C58.5301 33.8695 59.0737 35.5166 59.4115 37.2065C59.9729 40.0402 59.71 42.7759 57.9949 45.2516C57.4512 46.0365 56.9469 46.8465 56.436 47.6509C56.3125 47.8466 56.1478 48.0675 56.146 48.2782C56.1254 50.5507 56.1329 52.8232 56.1329 55.1432C56.3453 55.05 56.5661 54.9568 56.7785 54.8496C59.6627 53.3975 62.0102 51.0713 63.4831 48.2057C64.956 45.3402 65.4783 42.083 64.9751 38.903Z"
            fill="url(#paint164_linear_44_564)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_44_564"
                x1="34.0685"
                y1="2.66298"
                x2="34.0685"
                y2="62.7201"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_44_564"
                x1="34.0686"
                y1="2.66292"
                x2="34.0686"
                y2="62.7201"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_44_564"
                x1="34.0685"
                y1="2.66288"
                x2="34.0685"
                y2="62.72"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_44_564"
                x1="34.1003"
                y1="2.63686"
                x2="34.1003"
                y2="62.694"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint4_linear_44_564"
                x1="34.1003"
                y1="2.63684"
                x2="34.1003"
                y2="62.694"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint5_linear_44_564"
                x1="34.1003"
                y1="2.637"
                x2="34.1003"
                y2="62.6942"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint6_linear_44_564"
                x1="34.1321"
                y1="2.61004"
                x2="34.1321"
                y2="62.6672"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint7_linear_44_564"
                x1="34.1322"
                y1="2.60998"
                x2="34.1322"
                y2="62.6671"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint8_linear_44_564"
                x1="34.1322"
                y1="2.61018"
                x2="34.1322"
                y2="62.6673"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint9_linear_44_564"
                x1="34.1639"
                y1="2.58391"
                x2="34.1639"
                y2="62.6411"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint10_linear_44_564"
                x1="34.164"
                y1="2.58386"
                x2="34.164"
                y2="62.641"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint11_linear_44_564"
                x1="34.1639"
                y1="2.58357"
                x2="34.1639"
                y2="62.6407"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint12_linear_44_564"
                x1="33.8567"
                y1="2.55755"
                x2="33.8567"
                y2="62.6147"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint13_linear_44_564"
                x1="33.8567"
                y1="2.55778"
                x2="33.8567"
                y2="62.6149"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint14_linear_44_564"
                x1="33.8567"
                y1="2.55769"
                x2="33.8567"
                y2="62.6149"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint15_linear_44_564"
                x1="33.8885"
                y1="2.53155"
                x2="33.8885"
                y2="62.5887"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint16_linear_44_564"
                x1="33.8885"
                y1="2.53173"
                x2="33.8885"
                y2="62.5889"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint17_linear_44_564"
                x1="33.8885"
                y1="2.53206"
                x2="33.8885"
                y2="62.5892"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint18_linear_44_564"
                x1="33.9204"
                y1="2.5054"
                x2="33.9204"
                y2="62.5625"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint19_linear_44_564"
                x1="33.9204"
                y1="2.50562"
                x2="33.9204"
                y2="62.5628"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint20_linear_44_564"
                x1="33.9203"
                y1="2.50527"
                x2="33.9203"
                y2="62.5624"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint21_linear_44_564"
                x1="33.9521"
                y1="2.47942"
                x2="33.9521"
                y2="62.5366"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint22_linear_44_564"
                x1="33.9521"
                y1="2.47937"
                x2="33.9521"
                y2="62.5365"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint23_linear_44_564"
                x1="33.9521"
                y1="2.47957"
                x2="33.9521"
                y2="62.5367"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint24_linear_44_564"
                x1="33.9839"
                y1="2.45323"
                x2="33.9839"
                y2="62.5104"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint25_linear_44_564"
                x1="33.9839"
                y1="2.45339"
                x2="33.9839"
                y2="62.5105"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint26_linear_44_564"
                x1="33.9839"
                y1="2.45345"
                x2="33.9839"
                y2="62.5106"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint27_linear_44_564"
                x1="34.0158"
                y1="2.4273"
                x2="34.0158"
                y2="62.4844"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint28_linear_44_564"
                x1="34.0158"
                y1="2.4273"
                x2="34.0158"
                y2="62.4844"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint29_linear_44_564"
                x1="34.0157"
                y1="2.42732"
                x2="34.0157"
                y2="62.4845"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint30_linear_44_564"
                x1="34.0475"
                y1="2.40093"
                x2="34.0475"
                y2="62.4581"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint31_linear_44_564"
                x1="34.0476"
                y1="2.4012"
                x2="34.0476"
                y2="62.4583"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint32_linear_44_564"
                x1="34.0476"
                y1="2.4012"
                x2="34.0476"
                y2="62.4584"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint33_linear_44_564"
                x1="34.0793"
                y1="2.37493"
                x2="34.0793"
                y2="62.4321"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint34_linear_44_564"
                x1="34.0794"
                y1="2.37511"
                x2="34.0794"
                y2="62.4323"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint35_linear_44_564"
                x1="34.0793"
                y1="2.37508"
                x2="34.0793"
                y2="62.4322"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint36_linear_44_564"
                x1="34.1112"
                y1="2.34893"
                x2="34.1112"
                y2="62.4061"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint37_linear_44_564"
                x1="34.1112"
                y1="2.34912"
                x2="34.1112"
                y2="62.4063"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint38_linear_44_564"
                x1="34.1112"
                y1="2.34895"
                x2="34.1112"
                y2="62.4061"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint39_linear_44_564"
                x1="34.1421"
                y1="2.32281"
                x2="34.1421"
                y2="62.38"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint40_linear_44_564"
                x1="34.142"
                y1="2.32287"
                x2="34.142"
                y2="62.38"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint41_linear_44_564"
                x1="34.142"
                y1="2.32308"
                x2="34.142"
                y2="62.3802"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint42_linear_44_564"
                x1="33.8338"
                y1="2.2968"
                x2="33.8338"
                y2="62.354"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint43_linear_44_564"
                x1="33.8339"
                y1="2.29675"
                x2="33.8339"
                y2="62.3539"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint44_linear_44_564"
                x1="33.8338"
                y1="2.29646"
                x2="33.8338"
                y2="62.3536"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint45_linear_44_564"
                x1="33.8657"
                y1="2.27056"
                x2="33.8657"
                y2="62.3277"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint46_linear_44_564"
                x1="33.8656"
                y1="2.27063"
                x2="33.8656"
                y2="62.3278"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint47_linear_44_564"
                x1="33.8657"
                y1="2.27059"
                x2="33.8657"
                y2="62.3277"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint48_linear_44_564"
                x1="33.8975"
                y1="2.24468"
                x2="33.8975"
                y2="62.3018"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint49_linear_44_564"
                x1="33.8975"
                y1="2.24445"
                x2="33.8975"
                y2="62.3016"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint50_linear_44_564"
                x1="33.8975"
                y1="2.24495"
                x2="33.8975"
                y2="62.3021"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint51_linear_44_564"
                x1="33.9293"
                y1="2.21831"
                x2="33.9293"
                y2="62.2755"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint52_linear_44_564"
                x1="33.9293"
                y1="2.21846"
                x2="33.9293"
                y2="62.2756"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint53_linear_44_564"
                x1="33.9294"
                y1="2.21876"
                x2="33.9294"
                y2="62.2759"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint54_linear_44_564"
                x1="33.9601"
                y1="2.19243"
                x2="33.9601"
                y2="62.2496"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint55_linear_44_564"
                x1="33.9602"
                y1="2.19238"
                x2="33.9602"
                y2="62.2495"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint56_linear_44_564"
                x1="33.9602"
                y1="2.19246"
                x2="33.9602"
                y2="62.2496"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint57_linear_44_564"
                x1="33.992"
                y1="2.16631"
                x2="33.992"
                y2="62.2235"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint58_linear_44_564"
                x1="33.992"
                y1="2.16626"
                x2="33.992"
                y2="62.2234"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint59_linear_44_564"
                x1="33.9919"
                y1="2.16634"
                x2="33.9919"
                y2="62.2235"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint60_linear_44_564"
                x1="34.0239"
                y1="2.14019"
                x2="34.0239"
                y2="62.1973"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint61_linear_44_564"
                x1="34.0238"
                y1="2.14001"
                x2="34.0238"
                y2="62.1972"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint62_linear_44_564"
                x1="34.0239"
                y1="2.14022"
                x2="34.0239"
                y2="62.1974"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint63_linear_44_564"
                x1="34.0547"
                y1="2.11431"
                x2="34.0547"
                y2="62.1715"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint64_linear_44_564"
                x1="34.0546"
                y1="2.11389"
                x2="34.0546"
                y2="62.171"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint65_linear_44_564"
                x1="34.0547"
                y1="2.11385"
                x2="34.0547"
                y2="62.171"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint66_linear_44_564"
                x1="34.0865"
                y1="2.08789"
                x2="34.0865"
                y2="62.145"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint67_linear_44_564"
                x1="34.0865"
                y1="2.08794"
                x2="34.0865"
                y2="62.1451"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint68_linear_44_564"
                x1="34.0865"
                y1="2.08797"
                x2="34.0865"
                y2="62.1451"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint69_linear_44_564"
                x1="34.1183"
                y1="2.06182"
                x2="34.1183"
                y2="62.119"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint70_linear_44_564"
                x1="34.1183"
                y1="2.06192"
                x2="34.1183"
                y2="62.1191"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint71_linear_44_564"
                x1="34.1183"
                y1="2.06184"
                x2="34.1183"
                y2="62.119"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint72_linear_44_564"
                x1="34.1492"
                y1="2.03569"
                x2="34.1492"
                y2="62.0929"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint73_linear_44_564"
                x1="34.1492"
                y1="2.03564"
                x2="34.1492"
                y2="62.0928"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint74_linear_44_564"
                x1="34.1492"
                y1="2.03572"
                x2="34.1492"
                y2="62.0929"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint75_linear_44_564"
                x1="33.8411"
                y1="2.00969"
                x2="33.8411"
                y2="62.0668"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint76_linear_44_564"
                x1="33.841"
                y1="2.00976"
                x2="33.841"
                y2="62.0669"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint77_linear_44_564"
                x1="33.841"
                y1="2.0096"
                x2="33.841"
                y2="62.0668"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint78_linear_44_564"
                x1="33.8719"
                y1="1.98369"
                x2="33.8719"
                y2="62.0408"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint79_linear_44_564"
                x1="33.8719"
                y1="1.98369"
                x2="33.8719"
                y2="62.0408"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint80_linear_44_564"
                x1="33.8719"
                y1="1.98352"
                x2="33.8719"
                y2="62.0407"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint81_linear_44_564"
                x1="33.9028"
                y1="1.95757"
                x2="33.9028"
                y2="62.0147"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint82_linear_44_564"
                x1="33.9027"
                y1="1.95739"
                x2="33.9027"
                y2="62.0145"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint83_linear_44_564"
                x1="33.9028"
                y1="1.9574"
                x2="33.9028"
                y2="62.0146"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint84_linear_44_564"
                x1="33.9345"
                y1="1.9312"
                x2="33.9345"
                y2="61.9884"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint85_linear_44_564"
                x1="33.9345"
                y1="1.93151"
                x2="33.9345"
                y2="61.9887"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint86_linear_44_564"
                x1="33.9346"
                y1="1.93152"
                x2="33.9346"
                y2="61.9887"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint87_linear_44_564"
                x1="33.9654"
                y1="1.9052"
                x2="33.9654"
                y2="61.9624"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint88_linear_44_564"
                x1="33.9654"
                y1="1.90526"
                x2="33.9654"
                y2="61.9624"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint89_linear_44_564"
                x1="33.9654"
                y1="1.90539"
                x2="33.9654"
                y2="61.9626"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint90_linear_44_564"
                x1="33.9973"
                y1="1.8792"
                x2="33.9973"
                y2="61.9364"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint91_linear_44_564"
                x1="33.9973"
                y1="1.87917"
                x2="33.9973"
                y2="61.9363"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint92_linear_44_564"
                x1="33.9973"
                y1="1.87927"
                x2="33.9973"
                y2="61.9364"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint93_linear_44_564"
                x1="34.0281"
                y1="1.85317"
                x2="34.0281"
                y2="61.9103"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint94_linear_44_564"
                x1="34.0281"
                y1="1.85306"
                x2="34.0281"
                y2="61.9102"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint95_linear_44_564"
                x1="34.0281"
                y1="1.85322"
                x2="34.0281"
                y2="61.9104"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint96_linear_44_564"
                x1="34.059"
                y1="1.82708"
                x2="34.059"
                y2="61.8842"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint97_linear_44_564"
                x1="34.059"
                y1="1.82693"
                x2="34.059"
                y2="61.8841"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint98_linear_44_564"
                x1="34.0591"
                y1="1.82678"
                x2="34.0591"
                y2="61.8839"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint99_linear_44_564"
                x1="34.0908"
                y1="1.80089"
                x2="34.0908"
                y2="61.858"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint100_linear_44_564"
                x1="34.0909"
                y1="1.80093"
                x2="34.0909"
                y2="61.8581"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint101_linear_44_564"
                x1="34.0908"
                y1="1.8009"
                x2="34.0908"
                y2="61.8581"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint102_linear_44_564"
                x1="34.1217"
                y1="1.77459"
                x2="34.1217"
                y2="61.8317"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint103_linear_44_564"
                x1="34.1217"
                y1="1.77462"
                x2="34.1217"
                y2="61.8318"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint104_linear_44_564"
                x1="34.1217"
                y1="1.77478"
                x2="34.1217"
                y2="61.8319"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint105_linear_44_564"
                x1="34.1526"
                y1="1.74871"
                x2="34.1526"
                y2="61.8059"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint106_linear_44_564"
                x1="34.1527"
                y1="1.74863"
                x2="34.1527"
                y2="61.8058"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint107_linear_44_564"
                x1="34.1526"
                y1="1.74866"
                x2="34.1526"
                y2="61.8058"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint108_linear_44_564"
                x1="33.8434"
                y1="1.72259"
                x2="33.8434"
                y2="61.7797"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint109_linear_44_564"
                x1="33.8435"
                y1="1.72255"
                x2="33.8435"
                y2="61.7797"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint110_linear_44_564"
                x1="33.8435"
                y1="1.72229"
                x2="33.8435"
                y2="61.7794"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint111_linear_44_564"
                x1="33.8744"
                y1="1.69646"
                x2="33.8744"
                y2="61.7536"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint112_linear_44_564"
                x1="33.8743"
                y1="1.69644"
                x2="33.8743"
                y2="61.7536"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint113_linear_44_564"
                x1="33.8743"
                y1="1.69641"
                x2="33.8743"
                y2="61.7536"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint114_linear_44_564"
                x1="33.9052"
                y1="1.67058"
                x2="33.9052"
                y2="61.7277"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint115_linear_44_564"
                x1="33.9052"
                y1="1.67031"
                x2="33.9052"
                y2="61.7275"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint116_linear_44_564"
                x1="33.9052"
                y1="1.67053"
                x2="33.9052"
                y2="61.7277"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint117_linear_44_564"
                x1="33.9371"
                y1="1.64422"
                x2="33.9371"
                y2="61.7014"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint118_linear_44_564"
                x1="33.937"
                y1="1.64419"
                x2="33.937"
                y2="61.7013"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint119_linear_44_564"
                x1="33.937"
                y1="1.64416"
                x2="33.937"
                y2="61.7013"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint120_linear_44_564"
                x1="33.9679"
                y1="1.61809"
                x2="33.9679"
                y2="61.6752"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint121_linear_44_564"
                x1="33.9679"
                y1="1.61829"
                x2="33.9679"
                y2="61.6754"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint122_linear_44_564"
                x1="33.9679"
                y1="1.61829"
                x2="33.9679"
                y2="61.6754"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint123_linear_44_564"
                x1="33.9987"
                y1="1.59197"
                x2="33.9987"
                y2="61.6491"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint124_linear_44_564"
                x1="33.9987"
                y1="1.59214"
                x2="33.9987"
                y2="61.6493"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint125_linear_44_564"
                x1="33.9988"
                y1="1.59216"
                x2="33.9988"
                y2="61.6493"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint126_linear_44_564"
                x1="34.0296"
                y1="1.56609"
                x2="34.0296"
                y2="61.6232"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint127_linear_44_564"
                x1="34.0297"
                y1="1.56582"
                x2="34.0297"
                y2="61.623"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint128_linear_44_564"
                x1="34.0296"
                y1="1.56598"
                x2="34.0296"
                y2="61.6231"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint129_linear_44_564"
                x1="34.0605"
                y1="1.53997"
                x2="34.0605"
                y2="61.5971"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint130_linear_44_564"
                x1="34.0605"
                y1="1.53982"
                x2="34.0605"
                y2="61.597"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint131_linear_44_564"
                x1="34.0606"
                y1="1.53992"
                x2="34.0606"
                y2="61.5971"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint132_linear_44_564"
                x1="34.0914"
                y1="1.5136"
                x2="34.0914"
                y2="61.5708"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint133_linear_44_564"
                x1="34.0914"
                y1="1.51382"
                x2="34.0914"
                y2="61.571"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint134_linear_44_564"
                x1="34.0914"
                y1="1.51355"
                x2="34.0914"
                y2="61.5707"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint135_linear_44_564"
                x1="34.1213"
                y1="1.48748"
                x2="34.1213"
                y2="61.5446"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint136_linear_44_564"
                x1="34.1214"
                y1="1.4877"
                x2="34.1214"
                y2="61.5449"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint137_linear_44_564"
                x1="34.1213"
                y1="1.48767"
                x2="34.1213"
                y2="61.5448"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint138_linear_44_564"
                x1="34.1522"
                y1="1.4616"
                x2="34.1522"
                y2="61.5188"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint139_linear_44_564"
                x1="34.1522"
                y1="1.46157"
                x2="34.1522"
                y2="61.5187"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint140_linear_44_564"
                x1="34.1522"
                y1="1.46179"
                x2="34.1522"
                y2="61.5189"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint141_linear_44_564"
                x1="33.8431"
                y1="1.43534"
                x2="33.8431"
                y2="61.4925"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint142_linear_44_564"
                x1="33.8431"
                y1="1.4355"
                x2="33.8431"
                y2="61.4927"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint143_linear_44_564"
                x1="33.8431"
                y1="1.43518"
                x2="33.8431"
                y2="61.4923"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint144_linear_44_564"
                x1="33.874"
                y1="1.40935"
                x2="33.874"
                y2="61.4665"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint145_linear_44_564"
                x1="33.874"
                y1="1.40933"
                x2="33.874"
                y2="61.4665"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint146_linear_44_564"
                x1="33.874"
                y1="1.4093"
                x2="33.874"
                y2="61.4665"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint147_linear_44_564"
                x1="33.9049"
                y1="1.38347"
                x2="33.9049"
                y2="61.4406"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint148_linear_44_564"
                x1="33.9048"
                y1="1.38343"
                x2="33.9048"
                y2="61.4406"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint149_linear_44_564"
                x1="33.9048"
                y1="1.38367"
                x2="33.9048"
                y2="61.4408"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint150_linear_44_564"
                x1="33.9357"
                y1="1.35698"
                x2="33.9357"
                y2="61.4141"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint151_linear_44_564"
                x1="33.9358"
                y1="1.3572"
                x2="33.9358"
                y2="61.4144"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint152_linear_44_564"
                x1="33.9357"
                y1="1.35705"
                x2="33.9357"
                y2="61.4142"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint153_linear_44_564"
                x1="33.9656"
                y1="1.33098"
                x2="33.9656"
                y2="61.3881"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint154_linear_44_564"
                x1="33.9657"
                y1="1.3311"
                x2="33.9657"
                y2="61.3883"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint155_linear_44_564"
                x1="33.9656"
                y1="1.33118"
                x2="33.9656"
                y2="61.3883"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint156_linear_44_564"
                x1="33.9965"
                y1="1.305"
                x2="33.9965"
                y2="61.3622"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint157_linear_44_564"
                x1="33.9966"
                y1="1.30496"
                x2="33.9966"
                y2="61.3621"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint158_linear_44_564"
                x1="33.9964"
                y1="1.30481"
                x2="33.9964"
                y2="61.362"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint159_linear_44_564"
                x1="2.83351"
                y1="31.334"
                x2="65.1595"
                y2="31.334"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F2F2F2" />
                <stop offset="1" stopColor="#D8D8D8" />
            </linearGradient>
            <linearGradient
                id="paint160_linear_44_564"
                x1="2.8336"
                y1="31.334"
                x2="65.1596"
                y2="31.334"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F2F2F2" />
                <stop offset="1" stopColor="#D8D8D8" />
            </linearGradient>
            <linearGradient
                id="paint161_linear_44_564"
                x1="2.83344"
                y1="31.3339"
                x2="65.1594"
                y2="31.3339"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F2F2F2" />
                <stop offset="1" stopColor="#D8D8D8" />
            </linearGradient>
            <linearGradient
                id="paint162_linear_44_564"
                x1="33.9965"
                y1="2.1661"
                x2="33.9965"
                y2="61.0221"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#BCBCBC" />
                <stop offset="1" stopColor="#9E9E9E" />
            </linearGradient>
            <linearGradient
                id="paint163_linear_44_564"
                x1="33.9966"
                y1="2.16621"
                x2="33.9966"
                y2="61.0222"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#BCBCBC" />
                <stop offset="1" stopColor="#9E9E9E" />
            </linearGradient>
            <linearGradient
                id="paint164_linear_44_564"
                x1="33.9964"
                y1="2.16642"
                x2="33.9964"
                y2="61.0224"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#BCBCBC" />
                <stop offset="1" stopColor="#9E9E9E" />
            </linearGradient>
        </defs>
    </svg>,
    'InventoryIcon',
)
