import React from 'react'
import { createSvgIcon } from '@mui/material'

export const PowerBar = createSvgIcon(
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 207.2 17.92"
        preserveAspectRatio="none"
    >
        <defs>
            <linearGradient id="linear-gradient" x1={103.6} y1={17.92} x2={103.6} gradientUnits="userSpaceOnUse">
                <stop offset={0} stopColor="#7f909c" />
                <stop offset={0.37} stopColor="#a0adb7" />
                <stop offset={0.77} stopColor="#bec9d0" />
                <stop offset={1} stopColor="#cad3d9" />
            </linearGradient>
            <linearGradient
                id="linear-gradient-2"
                x1={103.6}
                y1={17.15}
                x2={103.6}
                y2={0.77}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0} stopColor="#656668" />
                <stop offset={1} stopColor="#3f3f3f" />
            </linearGradient>
            <linearGradient
                id="linear-gradient-3"
                x1={-4.03}
                y1={268.12}
                x2={201.23}
                y2={268.12}
                gradientTransform="translate(202.2 277.08) rotate(180)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0} stopColor="#007dcf" />
                <stop offset={0.5} stopColor="#fff12c" />
                <stop offset={1} stopColor="#f20015" />
            </linearGradient>
        </defs>
        <g id="Layer_2" data-name="Layer 2">
            <g id="_1" data-name={1}>
                <polygon
                    points="200.3 0 6.9 0 0 8.96 6.9 17.92 200.3 17.92 207.2 8.96 200.3 0"
                    style={{
                        fill: 'url(#linear-gradient)',
                    }}
                />
                <polygon
                    points="7.27 17.15 0.97 8.96 7.27 0.77 199.93 0.77 206.23 8.96 199.93 17.15 7.27 17.15"
                    style={{
                        fill: 'url(#linear-gradient-2)',
                    }}
                />
                <polygon
                    points="199.93 0.77 206.23 8.96 199.93 17.15 7.27 17.15 0.97 8.96 7.27 0.77 199.93 0.77"
                    style={{
                        fill: 'url(#linear-gradient-3)',
                    }}
                />
            </g>
        </g>
    </svg>,
    'PowerBar',
)
