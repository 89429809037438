import React, { Suspense } from 'react'
import { AxiosInterceptorContext, DappProvider } from '@multiversx/sdk-dapp/wrappers'
import { NotificationModal, SignTransactionsModals, TransactionsToastList } from '@multiversx/sdk-dapp/UI'
import { RouterProvider } from 'react-router-dom'
import router from 'routes'
import { CssBaseline, useMediaQuery } from '@mui/material'
import { API_TIMEOUT, ENVIROMENT, WALLET_CONNECT_PROJECT_V2 } from 'config'
import LazyLoading from 'components/LazyLoading'
import { SocketProvider } from 'providers/SocketProvider'
import { Toaster } from 'sonner'
import SmallScreen from 'components/SmallScreen'
import NoConnection from 'components/NoConnection'

function App() {
    const isSmallScreen = useMediaQuery('(max-width: 349px)')

    if (isSmallScreen) return <SmallScreen />
    return (
        <AxiosInterceptorContext.Provider>
            <AxiosInterceptorContext.Interceptor authenticatedDomanis={[]}>
                <DappProvider
                    environment={ENVIROMENT}
                    dappConfig={{
                        shouldUseWebViewProvider: true,
                    }}
                    customNetworkConfig={{
                        name: 'customConfig',
                        apiTimeout: API_TIMEOUT,
                        walletConnectV2ProjectId: WALLET_CONNECT_PROJECT_V2,
                    }}
                >
                    <SocketProvider>
                        <NoConnection />
                        <Toaster visibleToasts={1} position="top-center" />
                        <TransactionsToastList />
                        <NotificationModal />
                        <SignTransactionsModals />
                        <AxiosInterceptorContext.Listener />
                        <CssBaseline />
                        <Suspense fallback={<LazyLoading />}>
                            <RouterProvider router={router} />
                        </Suspense>
                    </SocketProvider>
                </DappProvider>
            </AxiosInterceptorContext.Interceptor>
        </AxiosInterceptorContext.Provider>
    )
}

export default App
