import React from 'react'
import { createSvgIcon } from '@mui/material'

export const InfoIcon = createSvgIcon(
    <svg width="7" height="24" viewBox="0 0 7 24" fill="#FFF" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.81524 8.14955L0.771691 8.90697L0.555286 9.90983L1.74288 10.1289C2.51878 10.3136 2.67185 10.5934 2.50294 11.3666L0.555286 20.519C0.0432996 22.8863 0.832392 24 2.68768 24C4.12599 24 5.79654 23.3349 6.55397 22.4218L6.78621 21.3239C6.25839 21.7884 5.48777 21.9732 4.97578 21.9732C4.25003 21.9732 3.98612 21.4638 4.17349 20.5665L6.81524 8.14955ZM6.99997 2.6391C6.99997 3.33904 6.72193 4.0103 6.227 4.50523C5.73207 5.00016 5.0608 5.27821 4.36087 5.27821C3.66094 5.27821 2.98967 5.00016 2.49474 4.50523C1.99982 4.0103 1.72177 3.33904 1.72177 2.6391C1.72177 1.93917 1.99982 1.2679 2.49474 0.772975C2.98967 0.278047 3.66094 0 4.36087 0C5.0608 0 5.73207 0.278047 6.227 0.772975C6.72193 1.2679 6.99997 1.93917 6.99997 2.6391Z"
            fill="#FFF"
        />
    </svg>,
    'InfoIcon',
)
