import { createAsyncThunk } from '@reduxjs/toolkit'

import axios from 'axios'

import { APIEndpoint } from 'shared/types'

import { API_URL } from '../../../config'

import { RootState } from 'stores/store'

export const getFightsMonthly = createAsyncThunk('admin/fights/monthly', async (arg, { rejectWithValue, getState }) => {
    const token = (getState() as RootState).auth.token
    if (!token) {
        return rejectWithValue('Token is not found')
    }
    try {
        const response = await axios.get(`${API_URL}${APIEndpoint.ADMIN_OVERVIEW_MONTHLY}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        return response.data
    } catch (error) {
        return rejectWithValue(error)
    }
})
