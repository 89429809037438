import React, { FC } from 'react'
import { Stack } from '@mui/material'
import LoadingAnimation from 'components/LoadingAnimation'

const LazyLoading: FC = () => (
    <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'center'} height={'100vh'} spacing={2}>
        <LoadingAnimation />
    </Stack>
)
export default LazyLoading
