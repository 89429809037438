import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { APIEndpoint } from 'shared/types'
import { API_URL } from '../../../config'
import { RootState } from 'stores/store'

export const getBalance = createAsyncThunk(
    'user/me/balance',
    async ({ identifier }: { identifier: string }, { rejectWithValue, getState }) => {
        // check if token is expired
        const token = (getState() as RootState).auth.token
        if (!token) {
            return rejectWithValue('Token is not found')
        }
        try {
            const { data: balance } = await axios.get(API_URL + APIEndpoint.ME_BALANCE + identifier, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            return balance
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    },
)
