import React, { FC } from 'react'
import { Stack, Link } from '@mui/material'
import { MultigersIcon, TwitterIcon, TelegramIcon, DiscordIcon, FrameItIcon } from 'shared/icons'

const LogoWithLinks: FC = () => (
    <Stack
        marginTop="64px"
        marginBottom="32px"
        alignItems="center"
        justifyContent="center"
        sx={{
            width: '100%',
        }}
    >
        <Stack direction="row" gap="32px" alignItems="center">
            <Link href="https://www.multi-fights.com/" target="_blank">
                <MultigersIcon
                    sx={{
                        height: '64px',
                        width: 'auto',
                        fill: '#e7e7e7',
                        opacity: '40%',
                        cursor: 'pointer',
                        transition: 'all 0.2s ease-in-out',
                        '&:hover': {
                            opacity: '100%',
                            scale: '1.1',
                        },
                    }}
                />
            </Link>
            <Stack
                sx={{
                    height: '82px',
                    width: '1px',
                    background: '#919191',
                }}
            />
            <Stack
                direction="row"
                gap="16px"
                sx={{
                    '&>*': {
                        '&>*': {
                            fill: '#e7e7e7',
                            opacity: '40%',
                            height: '24px',
                            width: 'auto',
                            cursor: 'pointer',
                            transition: 'all 0.2s ease-in-out',
                            '&:hover': {
                                opacity: '100%',
                                scale: '1.1',
                            },
                        },
                    },
                }}
            >
                <Link href="https://twitter.com/MultiFights" target="_blank">
                    <TwitterIcon />
                </Link>
                <Link href="https://t.me/multifights" target="_blank">
                    <TelegramIcon />
                </Link>
                <Link href="https://discord.com/invite/du8urb6pd6" target="_blank">
                    <DiscordIcon />
                </Link>
                <Link href="https://www.frameit.gg/marketplace/MULTIGERS-5529a5/mint?sp=true" target="_blank">
                    <FrameItIcon />
                </Link>
            </Stack>
        </Stack>
    </Stack>
)

export default LogoWithLinks
