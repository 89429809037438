import React from 'react'
import { createSvgIcon } from '@mui/material'

export const PotionTestIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width={41} height={64} viewBox="0 0 41 64" fill="none">
        <path
            d="M40.4139 41.3536C40.1026 38.5639 38.9966 35.9225 37.2275 33.7435C30.2172 26.3942 25.2444 27.1668 24.7712 25.2256C24.6152 24.7152 24.6246 24.1685 24.7981 23.6637C24.9715 23.159 25.3001 22.722 25.7368 22.4153L19.6535 23.0527L13.5316 22.7919C13.9843 23.0747 14.3369 23.4924 14.5396 23.9862C14.7422 24.48 14.7849 25.025 14.6614 25.5443C14.3138 27.4758 9.3023 27.0509 2.77484 34.8251C1.07538 36.8725 -0.141277 40.8804 0.0132194 45.2842C0.331868 53.464 5.42058 63.0249 20.9185 63.981C38.0868 61.8564 41.6982 49.9004 40.4139 41.3536ZM2.34032 42.0779C4.47429 30.4889 14.6228 28.3063 15.6559 26.8674C16.276 25.7437 16.4778 24.4366 16.2257 23.1782L22.5117 22.9851C22.3438 24.2521 22.6276 25.5377 23.3132 26.6163C24.4236 27.9876 34.6976 29.5135 37.5461 40.9576C40.3946 52.4017 31.3373 59.3068 20.5419 60.3691C9.66922 59.9732 0.225651 53.7151 2.34032 42.0779Z"
            fill="url(#paint0_linear_840_4)"
        />
        <path
            d="M8.6264 15.0174C8.08566 15.8576 9.03195 20.4063 9.84306 21.5942C11.6777 24.2693 27.1466 22.9559 28.6143 21.3141C29.7441 20.0586 29.9372 14.9498 29.5799 14.1965C29.2227 13.4433 8.6264 15.0174 8.6264 15.0174Z"
            fill="url(#paint1_linear_840_4)"
        />
        <path
            d="M8.62636 15.0172C8.18218 16.9487 29.3964 17.4992 29.5799 14.1963C29.7633 10.8935 9.59196 10.8066 8.62636 15.0172Z"
            fill="#EBC3D3"
        />
        <g
            style={{
                mixBlendMode: 'multiply',
            }}
            opacity={0.5}
        >
            <path
                d="M11.9094 14.7566C11.6101 16.07 26.0941 16.0024 26.2293 13.7908C26.3645 11.5793 12.566 11.869 11.9094 14.7566Z"
                fill="#9A376D"
            />
        </g>
        <g
            style={{
                mixBlendMode: 'multiply',
            }}
            opacity={0.3}
        >
            <path
                d="M11.417 16.4856C9.25405 16.0317 10.3355 19.2862 11.5618 21.1115C12.7881 22.9367 26.4031 22.4635 27.5329 19.6725C28.6626 16.8815 27.4556 17.2292 22.28 17.2099C17.1044 17.1905 11.417 16.4856 11.417 16.4856Z"
                fill="#9A376D"
            />
        </g>
        <path
            opacity={0.45}
            d="M13.184 16.756C12.4212 16.6015 14.3331 20.2327 17.1719 21.5847C20.0108 22.9368 24.8967 19.943 25.592 17.2775C25.592 17.2968 17.6161 17.123 13.184 16.756Z"
            fill="url(#paint2_linear_840_4)"
        />
        <path
            d="M20.3584 15.5485C16.216 16.0603 23.0428 16.5818 25.1864 16.1473C27.3301 15.7127 29.7151 14.6214 29.4254 14.1095C29.242 13.7908 25.8044 14.631 23.7091 15.0753C22.6085 15.3325 21.4872 15.4909 20.3584 15.5485Z"
            fill="white"
        />
        <path
            d="M28.9233 18.5617C28.2281 18.4265 26.6252 20.6864 25.7272 21.6038C24.8292 22.5213 27.8612 21.768 28.7012 20.6381C29.5413 19.5082 29.2806 18.6293 28.9233 18.5617Z"
            fill="white"
        />
        <path
            d="M9.11881 14.4862C8.62635 15.0077 9.19605 15.6354 10.2003 15.8576C11.2045 16.0797 10.4224 14.4476 10.0458 14.3413C9.72931 14.3012 9.40793 14.3514 9.11881 14.4862Z"
            fill="white"
        />
        <g
            style={{
                mixBlendMode: 'soft-light',
            }}
        >
            <path
                d="M14.0144 22.9847C12.3826 22.7529 11.8515 18.6292 12.4501 17.8856C13.0488 17.1419 19.4218 22.2121 18.6493 22.8398C17.8768 23.4676 14.0144 22.9847 14.0144 22.9847Z"
                fill="white"
            />
        </g>
        <g
            style={{
                mixBlendMode: 'soft-light',
            }}
        >
            <path
                d="M8.98368 16.3502C8.93968 17.8805 9.20233 19.4041 9.75617 20.8313C9.86667 21.1871 10.0744 21.505 10.3559 21.749C10.6374 21.993 10.9815 22.1535 11.3494 22.2123C11.6681 22.2896 10.2486 19.373 9.78513 17.9244C9.14784 15.935 9.14784 15.935 8.98368 16.3502Z"
                fill="white"
            />
        </g>
        <g
            style={{
                mixBlendMode: 'multiply',
            }}
            opacity={0.3}
        >
            <path
                d="M10.123 13.4624C8.91603 14.4281 8.44288 14.8337 13.4737 13.4624C18.5044 12.091 24.3656 12.3325 25.6306 12.796C26.8955 13.2596 26.5962 14.4474 28.8943 13.9259C31.1924 13.4044 25.4664 11.5116 18.9196 11.811C12.3729 12.1103 10.123 13.4624 10.123 13.4624Z"
                fill="#9A376D"
            />
        </g>
        <path
            d="M12.9619 15.3165C12.2506 10.9792 11.3127 6.68219 10.152 2.44306C10.152 2.44306 23.2263 -2.38567 29.7634 4.37455C29.7634 4.37455 26.1424 9.70547 25.8237 14.5825C25.8237 14.6115 21.0247 16.2822 12.9619 15.3165Z"
            fill="url(#paint3_linear_840_4)"
        />
        <path
            d="M29.4833 4.82869C26.2292 8.56613 8.37528 3.6698 10.2679 2.06666C12.1604 0.46352 15.54 -0.077298 18.9583 0.00961918C19.3928 0.270871 19.8756 0.441681 20.3777 0.511807C20.8102 0.472055 21.2338 0.364509 21.633 0.193111H21.9902C21.9902 0.193111 21.9902 1.77693 23.458 0.42489C27.8225 1.2844 31.0669 3.02275 29.4833 4.82869Z"
            fill="#FF7A00"
        />
        <g
            style={{
                mixBlendMode: 'multiply',
            }}
            opacity={0.5}
        >
            <path
                d="M14.7097 15.452C14.7097 15.452 13.2709 5.60134 14.0917 4.42313C14.9125 3.24492 20.6674 1.52589 24.8968 2.0474C29.1261 2.5689 29.6765 4.53902 29.6765 4.53902C28.9161 3.73874 27.9808 3.12529 26.9439 2.74665C25.907 2.36801 24.7965 2.23446 23.6994 2.35644C19.7694 2.67513 15.3953 3.45739 14.9028 5.07984C14.4103 6.70229 15.7718 15.5196 15.7718 15.5196L14.7097 15.452Z"
                fill="#B10053"
            />
        </g>
        <g
            style={{
                mixBlendMode: 'multiply',
            }}
            opacity={0.5}
        >
            <path
                d="M17.6258 15.6352C17.6258 15.6352 17.1333 7.41666 18.3596 5.54312C19.5859 3.66957 28.7978 5.36928 28.7978 5.36928C28.7978 5.36928 20.4453 4.40354 18.9197 5.63969C17.394 6.87585 18.9197 15.5869 18.9197 15.5869L17.6258 15.6352Z"
                fill="#B10053"
            />
        </g>
        <g
            style={{
                mixBlendMode: 'multiply',
            }}
            opacity={0.5}
        >
            <path
                d="M21.2565 15.452C21.2565 15.452 20.5323 10.6233 23.458 8.69181C26.3838 6.76032 27.1949 9.2616 27.1949 9.2616L26.6735 10.6233C26.6735 10.6233 25.8914 8.06408 23.6125 9.28092C21.3337 10.4978 21.8069 15.452 21.8069 15.452H21.2565Z"
                fill="#B10053"
            />
        </g>
        <path
            d="M15.0476 9.92765C15.0476 9.92765 13.7634 5.89083 14.082 4.46152C14.4006 3.03222 22.193 2.53003 22.193 2.53003C22.193 2.53003 16.3994 3.24468 15.0766 4.74159C14.1592 5.79425 15.0476 9.92765 15.0476 9.92765Z"
            fill="#97001A"
        />
        <path
            d="M18.2824 9.23253C18.2824 9.23253 17.7706 5.88139 18.7749 5.18605C19.7791 4.49071 23.0428 5.00256 23.0428 5.00256C23.0428 5.00256 19.8756 4.78044 18.9487 5.63995C18.0217 6.49947 18.2824 9.23253 18.2824 9.23253Z"
            fill="#97001A"
        />
        <g
            style={{
                mixBlendMode: 'soft-light',
            }}
            opacity={0.5}
        >
            <path
                d="M10.9052 1.85449C9.93962 2.09593 12.8364 3.85359 13.522 4.01777C14.2076 4.18194 16.5347 2.8975 17.8189 2.67538C19.1032 2.45326 12.1991 1.50682 10.9052 1.85449Z"
                fill="white"
            />
        </g>
        <g
            style={{
                mixBlendMode: 'soft-light',
            }}
            opacity={0.5}
        >
            <path
                d="M15.2794 4.57769C16.1755 4.97417 17.1108 5.27521 18.0699 5.47583C18.4079 5.3696 19.4218 4.83844 20.0011 4.56803C20.5805 4.29762 18.2244 2.89729 15.2794 4.57769Z"
                fill="white"
            />
        </g>
        <g
            style={{
                mixBlendMode: 'soft-light',
            }}
            opacity={0.5}
        >
            <path
                d="M19.3735 5.71726C21.3543 5.99668 23.3504 6.15471 25.3505 6.19048C26.2146 6.22216 27.0767 6.08774 27.8901 5.79452C27.8901 5.79452 23.3035 4.2107 19.3735 5.71726Z"
                fill="white"
            />
        </g>
        <path
            d="M18.6493 6.03602C18.9801 6.03044 19.3073 5.965 19.6149 5.84288C19.7007 5.77403 19.7684 5.68525 19.8122 5.58427C19.856 5.48329 19.8744 5.37316 19.8659 5.26343C19.3818 5.36898 18.9507 5.64272 18.6493 6.03602Z"
            fill="#FFA457"
        />
        <path
            d="M14.7773 5.41776C14.7773 5.41776 15.5594 5.2729 15.627 4.90591C15.6946 4.53893 15.4629 4.46167 15.4629 4.46167C15.4629 4.46167 15.038 4.55824 14.7773 5.41776Z"
            fill="#FFA457"
        />
        <g
            style={{
                mixBlendMode: 'multiply',
            }}
            opacity={0.3}
        >
            <path
                d="M19.248 15.394C19.248 15.394 18.4948 8.7303 18.7845 7.42654C19.0742 6.12279 22.6469 6.4608 24.8485 6.4608C27.0501 6.4608 24.4719 6.51874 22.7049 8.87516C20.9378 11.2316 21.1792 14.7276 20.8799 15.0849C20.5805 15.4422 19.248 15.394 19.248 15.394Z"
                fill="#D42F55"
            />
        </g>
        <g
            style={{
                mixBlendMode: 'multiply',
            }}
            opacity={0.3}
        >
            <path
                d="M15.6656 5.55298C15.318 5.64955 15.0862 10.3044 15.9166 13.4238C16.747 16.5432 17.3167 13.9839 17.423 13.3272C17.5292 12.6705 17.5388 7.03057 17.7416 6.42215C17.9444 5.81373 15.6656 5.55298 15.6656 5.55298Z"
                fill="#D42F55"
            />
        </g>
        <g
            style={{
                mixBlendMode: 'multiply',
            }}
            opacity={0.3}
        >
            <path
                d="M13.773 4.51965C13.773 4.20095 10.7603 3.49596 10.8183 3.5539C10.8762 3.61185 12.7495 12.6995 13.2226 13.7425C13.6958 14.7855 13.5992 9.90851 13.773 4.51965Z"
                fill="#D42F55"
            />
        </g>
        <path
            d="M21.8261 13.1147C22.0439 11.9396 22.5079 10.8238 23.1876 9.84085C23.3974 9.57592 23.6668 9.36433 23.9739 9.22335C24.281 9.08237 24.617 9.01599 24.9547 9.02963C24.9547 9.02963 24.0856 8.68196 22.9559 9.8312C22.2299 10.7724 21.833 11.926 21.8261 13.1147Z"
            fill="#FF6500"
        />
        <path
            d="M18.5624 12.7285C18.5624 12.7285 18.4755 8.48888 18.5624 7.48451C18.6493 6.48013 18.6686 6.03589 18.6686 6.03589C18.6686 6.03589 17.7706 7.40725 18.5624 12.7285Z"
            fill="#FF6500"
        />
        <path d="M21.9903 0.241455L22.6662 0.98508C22.6662 0.98508 21.971 1.28446 21.9903 0.241455Z" fill="#E23100" />
        <path
            d="M18.9969 0C19.8464 0.279907 20.7518 0.346244 21.633 0.193149C21.2319 0.450684 20.7581 0.571083 20.2827 0.536253C19.8074 0.501424 19.3562 0.313247 18.9969 0Z"
            fill="#E23100"
        />
        <path
            d="M29.1067 3.93091C29.2423 4.26063 29.327 4.60907 29.3578 4.96426C29.2586 5.18672 29.1458 5.40282 29.0198 5.61131C29.0198 5.61131 29.6571 4.64556 29.6764 4.57796C29.6958 4.51036 29.1067 3.93091 29.1067 3.93091Z"
            fill="#97001A"
        />
        <g
            style={{
                mixBlendMode: 'multiply',
            }}
            opacity={0.8}
        >
            <path
                d="M12.6916 13.6652C12.6916 13.6652 13.2999 15.4132 19.2384 15.2877C25.1768 15.1621 25.9976 13.53 25.9976 13.53L25.872 14.6117C25.872 14.6117 21.044 16.379 13.0102 15.3167L12.6916 13.6652Z"
                fill="#D42F55"
            />
        </g>
        <path
            opacity={0.5}
            d="M20.5419 60.3783C9.68851 59.9727 0.264249 53.7147 2.34029 42.0291C2.78239 39.4319 3.78762 36.963 5.28537 34.7957C9.08984 29.3392 14.9414 27.8906 15.6559 26.8186C16.2646 25.7086 16.4661 24.4207 16.2256 23.1778L22.5117 22.9846C22.3438 24.2516 22.6276 25.5373 23.3131 26.6158C24.1725 27.6685 30.4779 28.8081 34.63 34.5349C36.0013 36.4684 36.985 38.6494 37.5268 40.9572C40.3946 52.4495 31.3373 59.2967 20.5419 60.3783Z"
            fill="url(#paint4_radial_840_4)"
        />
        <path
            d="M20.5419 60.3785C9.68851 59.9729 0.264249 53.7149 2.34029 42.0293C2.78239 39.4321 3.78762 36.9632 5.28537 34.7959L20.4839 39.5956L34.63 34.5544C36.0013 36.4879 36.985 38.6689 37.5268 40.9767C40.3946 52.4497 31.3373 59.2969 20.5419 60.3785Z"
            fill="url(#paint5_linear_840_4)"
        />
        <g
            style={{
                mixBlendMode: 'multiply',
            }}
            opacity={0.2}
        >
            <path
                d="M3.47974 38.1662C5.28541 34.8441 13.6668 40.5613 19.9722 48.5866C26.2776 56.612 29.7054 50.2863 32.1677 45.0713C34.63 39.8563 34.3693 51.4646 30.9897 55.2503C27.6101 59.036 36.4067 54.526 37.8261 47.7464C39.2456 40.9669 34.63 34.5447 34.63 34.5447C34.63 34.5447 36.3198 39.2479 33.4906 40.6482C30.6614 42.0485 17.5775 39.8466 12.4695 37.8862C7.36145 35.9257 5.28541 34.8441 5.28541 34.8441C4.56157 35.8807 3.95592 36.995 3.47974 38.1662Z"
                fill="#9A7AFF"
            />
        </g>
        <path
            d="M6.25095 34.3421C1.59676 34.2359 13.773 40.6291 26.3548 41.5369C38.9365 42.4447 35.4604 35.1437 33.7223 33.3957C31.9842 31.6477 20.0301 36.4957 14.7965 36.9979C9.56296 37.5001 8.02766 34.3807 6.25095 34.3421Z"
            fill="url(#paint6_linear_840_4)"
        />
        <g
            style={{
                mixBlendMode: 'soft-light',
            }}
            opacity={0.3}
        >
            <path
                d="M5.06332 53.8694C0.129101 42.2321 17.8865 55.8685 23.7573 56.8439C29.6282 57.8193 32.1677 56.2741 32.1677 56.2741C32.1677 56.2741 25.2733 61.1028 17.8672 60.1371C10.461 59.1713 5.06332 53.8694 5.06332 53.8694Z"
                fill="white"
            />
        </g>
        <g
            style={{
                mixBlendMode: 'multiply',
            }}
            opacity={0.3}
        >
            <path
                d="M2.04095 45.2162C2.04095 45.2162 3.66316 60.533 20.2908 60.0115C36.9184 59.49 38.0868 44.4243 38.0868 44.4243C38.0868 44.4243 38.9559 60.2046 20.5515 60.7841C2.14717 61.3635 2.04095 45.2162 2.04095 45.2162Z"
                fill="#9A3792"
            />
        </g>
        <path
            d="M23.5642 62.9378C21.8937 62.5225 29.7054 59.6832 33.9734 56.0713C38.2414 52.4595 34.7748 62.1652 23.5642 62.9378Z"
            fill="white"
        />
        <path
            d="M0.978833 40.3585C0.650529 43.7289 4.52259 35.4525 5.89374 33.154C7.26489 30.8555 1.48095 34.873 0.978833 40.3585Z"
            fill="white"
        />
        <path
            d="M6.64688 32.256C6.08684 32.256 7.30349 30.7011 7.80561 30.6818C8.30772 30.6625 7.35177 32.3139 6.64688 32.256Z"
            fill="white"
        />
        <path
            d="M34.8521 32.5844C33.9927 32.7196 36.6964 36.7082 37.7489 39.7309C38.8014 42.7537 39.6801 40.32 39.4193 38.1375C39.1586 35.9549 36.3294 32.3333 34.8521 32.5844Z"
            fill="white"
        />
        <path
            style={{
                mixBlendMode: 'screen',
            }}
            d="M7.21657 54.7577C7.21657 54.7577 0.177338 49.929 3.60522 39.808C6.13509 32.2945 11.6004 29.9381 11.6004 29.9381C11.6004 29.9381 13.8213 29.687 14.3524 38.0793C14.8834 46.4716 11.0114 54.8253 7.21657 54.7577Z"
            fill="url(#paint7_linear_840_4)"
        />
        <path
            d="M8.77118 37.1812C13.6989 39.5336 18.9582 41.1165 24.3656 41.8747C33.8285 43.304 35.1321 39.1899 35.1321 39.1899C35.1321 39.1899 32.8436 41.4304 25.1188 41.0055C17.394 40.5806 8.77118 37.1812 8.77118 37.1812Z"
            fill="#59C7FF"
        />
        <g
            style={{
                mixBlendMode: 'soft-light',
            }}
        >
            <path
                d="M20.7833 41.2182C20.1267 40.8126 23.9795 40.7257 25.9396 41.2182C27.8998 41.7107 26.6252 42.1839 25.8624 42.0777C25.0995 41.9715 20.7833 41.2182 20.7833 41.2182Z"
                fill="white"
            />
        </g>
        <g
            style={{
                mixBlendMode: 'multiply',
            }}
            opacity={0.2}
        >
            <path
                d="M13.184 39.0452C11.6004 38.4561 19.3252 47.4568 22.9752 51.3488C26.6252 55.2407 29.6475 48.2294 31.3663 45.1004C32.2193 43.4667 33.2351 41.9233 34.3982 40.4938C31.4371 41.6099 28.2801 42.1126 25.1188 41.9714C20.2908 41.614 13.184 39.0452 13.184 39.0452Z"
                fill="#9A7AFF"
            />
        </g>
        <g
            style={{
                mixBlendMode: 'multiply',
            }}
            opacity={0.5}
        >
            <path
                d="M20.9378 64C5.43992 63.0439 0.351205 53.483 0.0132446 45.3032C0.0808367 45.9792 1.10437 62.8508 20.4647 63.3723C40.2208 63.8841 40.3947 41.3533 40.3947 41.3533C41.6982 49.9001 38.0869 61.8561 20.9378 64Z"
                fill="#9A376D"
            />
        </g>
        <g
            style={{
                mixBlendMode: 'multiply',
            }}
            opacity={0.2}
        >
            <path
                d="M14.9221 40.8317C7.09104 36.6983 8.64566 45.1872 11.6004 50.2187C14.5551 55.2503 14.0144 48.0072 16.9691 48.2873C19.9239 48.5673 19.8659 52.7683 23.0428 54.9509C26.2196 57.1335 18.4272 59.1809 16.4284 56.5154C14.4296 53.85 17.6064 53.9079 17.9058 54.4294C18.2051 54.9509 22.2027 57.0659 19.7018 53.0194C17.2009 48.9729 15.7718 51.397 14.333 53.8596C12.8943 56.3223 9.39882 54.2652 6.15441 47.2539C2.90999 40.2426 4.34873 36.2154 5.80679 36.0706C7.26485 35.9257 12.566 39.1996 19.7404 41.3146C26.9148 43.4296 18.9196 45.1486 14.9221 40.8317Z"
                fill="#9A7AFF"
            />
        </g>
        <g
            style={{
                mixBlendMode: 'soft-light',
            }}
        >
            <path
                d="M14.3621 59.5768C9.59199 56.3126 17.5872 56.3126 24.8195 57.2687C32.0519 58.2248 23.6898 61.5276 14.3621 59.5768Z"
                fill="white"
            />
        </g>
        <path
            d="M25.9782 30.1119C22.4248 30.8845 25.5534 36.8721 29.2613 39.3444C32.9692 41.8167 33.8092 35.7325 32.3512 33.2602C30.8931 30.7879 26.9245 29.938 25.9782 30.1119Z"
            fill="white"
        />
        <path
            d="M12.7785 31.3288C12.7785 29.3297 9.47612 31.7247 9.30231 33.0478C9.1285 34.3709 12.7978 33.154 12.7785 31.3288Z"
            fill="white"
        />
        <g
            style={{
                mixBlendMode: 'soft-light',
            }}
        >
            <path
                d="M26.1907 27.0408C25.2251 27.2726 29.6379 28.895 31.9167 30.701C34.1955 32.5069 31.0187 27.6202 26.1907 27.0408Z"
                fill="white"
            />
        </g>
        <g
            style={{
                mixBlendMode: 'soft-light',
            }}
        >
            <path
                d="M3.70179 51.8894C0.621529 47.5822 3.5473 57.8674 10.3162 61.064C17.085 64.2606 22.1061 61.74 22.3862 61.1702C22.6662 60.6004 16.1677 60.9191 11.5232 59.1422C6.87862 57.3652 3.70179 51.8894 3.70179 51.8894Z"
                fill="#75FFFF"
            />
        </g>
        <g
            style={{
                mixBlendMode: 'multiply',
            }}
            opacity={0.4}
        >
            <path
                d="M33.5678 41.5272C34.7867 40.3872 35.6997 38.9593 36.2232 37.3745C36.2908 37.2393 39.4773 43.0918 37.4013 47.6404C35.3252 52.1891 34.4176 52.7685 33.8672 51.3103C33.3168 49.852 34.8328 43.4201 33.0657 43.5843C31.2987 43.7485 29.6958 50.7405 26.799 51.3103C23.9022 51.88 23.9698 47.3217 25.7079 45.0039C27.4459 42.6861 26.7893 46.3656 28.3633 45.4675C29.9372 44.5693 30.111 42.4254 23.9601 42.3095C17.8092 42.1936 20.6095 46.8002 22.8304 45.0908C25.0512 43.3815 22.8883 49.7361 22.0386 49.9196C21.1888 50.1031 19.7404 47.7853 15.9746 43.3911C12.2088 38.997 10.6831 39.1419 9.2154 41.054C7.74768 42.9662 11.7839 49.6975 9.49542 50.0065C7.20695 50.3155 4.73501 45.3902 4.53223 41.0444C4.32946 36.6985 4.33911 36.3315 5.29506 36.0804C6.251 35.8293 14.6614 40.9671 22.6759 41.6431C30.6904 42.3192 33.5678 41.5272 33.5678 41.5272Z"
                fill="#9A7AFF"
            />
        </g>
        <path
            d="M4.34875 45.2645C2.67827 44.1539 2.80379 48.21 3.78871 49.1275C4.77362 50.0449 5.86475 46.2785 4.34875 45.2645Z"
            fill="white"
        />
        <defs>
            <linearGradient
                id="paint0_linear_840_4"
                x1={20.8578}
                y1={64.0578}
                x2={19.615}
                y2={22.6369}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E8E5ED" />
                <stop offset={0.05} stopColor="#E5DDEA" />
                <stop offset={0.28} stopColor="#DABBDC" />
                <stop offset={0.46} stopColor="#D3A7D4" />
                <stop offset={0.59} stopColor="#D19FD1" />
                <stop offset={0.64} stopColor="#BE92C3" />
                <stop offset={0.72} stopColor="#A57FB0" />
                <stop offset={0.81} stopColor="#9272A3" />
                <stop offset={0.9} stopColor="#886B9B" />
                <stop offset={1} stopColor="#846898" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_840_4"
                x1={8.53708}
                y1={19.006}
                x2={29.8189}
                y2={18.3676}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#9082A9" />
                <stop offset={0.22} stopColor="#9386AB" />
                <stop offset={0.42} stopColor="#9D90B3" />
                <stop offset={0.6} stopColor="#ADA3C0" />
                <stop offset={0.79} stopColor="#C4BCD1" />
                <stop offset={0.96} stopColor="#E1DDE8" />
                <stop offset={1} stopColor="#E8E5ED" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_840_4"
                x1={13.0644}
                y1={19.4697}
                x2={25.6365}
                y2={19.0925}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#A71100" />
                <stop offset={0.39} stopColor="#D83303" />
                <stop offset={1} stopColor="#EC5C11" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_840_4"
                x1={10.3284}
                y1={8.48341}
                x2={29.8721}
                y2={7.89719}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#A71100" />
                <stop offset={0.39} stopColor="#D83303" />
                <stop offset={1} stopColor="#EC5C11" />
            </linearGradient>
            <radialGradient
                id="paint4_radial_840_4"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(20.1358 46.1096) rotate(-1.71862) scale(19.5236 19.5265)"
            >
                <stop stopColor="#E8E5ED" />
                <stop offset={0.14} stopColor="#E3DDE3" />
                <stop offset={0.42} stopColor="#DBD1D5" />
                <stop offset={0.66} stopColor="#D8CDD0" />
                <stop offset={0.68} stopColor="#D4C5CE" />
                <stop offset={0.75} stopColor="#C7ACC9" />
                <stop offset={0.83} stopColor="#BE99C5" />
                <stop offset={0.91} stopColor="#B98FC3" />
                <stop offset={1} stopColor="#B78BC2" />
            </radialGradient>
            <linearGradient
                id="paint5_linear_840_4"
                x1={20.4592}
                y1={60.435}
                x2={19.6789}
                y2={34.4275}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#3DFFFF" />
                <stop offset={0.32} stopColor="#4A9EFF" />
                <stop offset={1} stopColor="#6540FF" />
            </linearGradient>
            <linearGradient
                id="paint6_linear_840_4"
                x1={5.29298}
                y1={38.1264}
                x2={35.468}
                y2={37.2213}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#3D9DFF" />
                <stop offset={1} stopColor="#65B8FF" />
            </linearGradient>
            <linearGradient
                id="paint7_linear_840_4"
                x1={2.50781}
                y1={42.5788}
                x2={14.4813}
                y2={42.2197}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.02} stopColor="#008DFE" />
                <stop offset={1} />
            </linearGradient>
        </defs>
    </svg>,
    'PotionTestIcon',
)
