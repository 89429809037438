import React from 'react'
import { createSvgIcon } from '@mui/material'

export const LinktreeIcon = createSvgIcon(
    <svg width="53" height="64" viewBox="0 0 53 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1958_4)">
            <path d="M21.3993 43.0977H30.6563V64H21.3993V43.0977ZM0 21.6734H15.7512L4.55363 10.9984L10.7496 4.62822L21.4245 15.6017V0H30.681V15.6017L41.3558 4.65321L47.5469 10.9984L36.3546 21.6484H52.1008V30.4571H36.2646L47.5323 41.4056L41.3558 47.6269L26.0528 32.2488L10.7496 47.6269L4.55363 41.4309L15.8256 30.4821H0V21.6734Z" />
        </g>
        <defs>
            <clipPath id="clip0_1958_4">
                <rect width="52.1008" height="64" fill="white" />
            </clipPath>
        </defs>
    </svg>,
    'LinktreeIcon',
)
