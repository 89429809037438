import { createSlice } from '@reduxjs/toolkit'
import { EventPrizeType, EventProjectPrize, FetchStatus } from 'shared/types'
import { getEventInfo } from './hooks'
import { EventSlice } from 'shared/types'
import { RootState } from 'stores/store'

const initialState: EventSlice = {
    show: true,
    status: FetchStatus.IDLE,
    start: 0,
    end: 0,
    participants: [],
    projects: [],
}

const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        closeModal: (state) => {
            state.show = false
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getEventInfo.pending, (state) => {
            state.status = FetchStatus.PENDING
        }),
            builder.addCase(getEventInfo.fulfilled, (state, { payload }: { payload: EventSlice }) => {
                if (!payload) {
                    state = {
                        ...initialState,
                        status: FetchStatus.SUCCESS,
                    }
                    return
                }
                state.start = payload.start
                state.end = payload.end
                state.participants = payload.participants
                state.projects = payload.projects
                state.status = FetchStatus.SUCCESS
            }),
            builder.addCase(getEventInfo.rejected, (state) => {
                state.status = FetchStatus.FAIL
            })
    },
})

export const getPoolPrizes = (state: RootState): EventProjectPrize[] => {
    const projects = state.event.projects
    const prizes = []

    for (const project of projects) {
        for (const prize of project.prizes) {
            if (prize.type === EventPrizeType.POOL) {
                prizes.push(prize)
            }
        }
    }
    return prizes
}

export const getTotalPoints = (state: RootState): number => {
    const participants = state.event.participants
    let points = 0

    participants.forEach((participant) => (points += participant.points))

    return points
}

export const { closeModal } = eventSlice.actions
export default eventSlice.reducer
