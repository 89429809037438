import React, { FC } from 'react'
import { Typography } from '@mui/material'

interface Props {
    text: string
    fontSize: string
    color?: string
    textTransform?: string
    align?: string
}

const CustomText: FC<Props> = ({ text, fontSize, color = '#e7e7e7', textTransform, align }) => (
    <Typography
        sx={{
            fontSize,
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
            color,
            display: 'inline-block',
            position: 'relative',
            textShadow: `-1px -1px 0 #181818, 1px -1px 0 #181818, -1px 1px 0 #181818, 1px 1px 0 #181818`,
            filter: `drop-shadow(0px 1px 0px #181818)`,
            zIndex: '3',
            textTransform: textTransform ? textTransform : 'unset',
            textAlign: align ? align : 'unset',
        }}
    >
        {text}
    </Typography>
)

export default CustomText
