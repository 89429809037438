import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { APIEndpoint } from 'shared/types'
import { API_URL } from '../../../config'
import { RootState } from 'stores/store'

export const getQuests = createAsyncThunk('user/me/quests', async (arg, { rejectWithValue, getState }) => {
    // check if token is expired
    const token = (getState() as RootState).auth.token
    if (!token) {
        return rejectWithValue('Token is not found')
    }
    try {
        const { data: quests } = await axios.get(API_URL + APIEndpoint.ME_QUESTS, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        return quests
    } catch (err: any) {
        return rejectWithValue(err.response.data)
    }
})
