import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { APIEndpoint, CharacterAbility } from 'shared/types'
import { API_URL } from '../../../config'
import { RootState } from 'stores/store'

export const upgradeCharacter = createAsyncThunk(
    'user/me/upgrade-character',
    async (
        { identifier, ability }: { identifier: string; ability: CharacterAbility },
        { rejectWithValue, getState },
    ) => {
        // check if token is expired
        const token = (getState() as RootState).auth.token
        if (!token) {
            return rejectWithValue('Token is not found')
        }
        try {
            const { data: upgrade } = await axios.post(
                API_URL + APIEndpoint.UPGRADE_CHARACTER + identifier,
                {
                    ability,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            )
            return upgrade
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    },
)
