export const GAME_NAME = 'Multifights'
export const ENVIROMENT = 'devnet'
export const API_TIMEOUT = 10000
export const WALLET_CONNECT_PROJECT_V2 = 'c7abf993e449e768fa89064b77cc2230'
export const STORAGE_OBJECT_URL = 'https://eu2.contabostorage.com/7811067c674344eb87ba45ae0ddb2fa6:characters'
export const API_URL = 'https://devnet-api.multi-fights.com'
export const WS_URL = 'https://devnet-api.multi-fights.com'
export const MULTIVERSX_API_URL = 'https://devnet-api.multiversx.com'
export const JWT_SECRET = process.env.REACT_APP_JWT_SECRET

export const WHITELIST_ADDRESSES = [
    'erd10y2gyuq6djnxe24wafynhw8zrh22yxpmf8zpafdetq54y94jcl3qpqs2xf',
    'erd13hjcqwf8x7lxfzvdt3tcr4l45mhrcvrxk9xdu9al52lycxa0vqvsphfy8s',
    'erd1368tpzxwzsgz3vtd58s2dyukfwwcmn0skpajt5qp0tk9u8y284zql5fmlg',
    'erd10x0a7x47s8gj9f4tupw045tw5cxd45czlpazcceh8pyg8t33el7q9448fm',
    'erd1q07w9xm8avd7kwj3cgn3xrnhzg5da7e3vg7dv6gs3npyql0jpq9ss35a20',
]

// WEB3
export const CHAIN_ID = 'D'

// Token
export const TOKEN_IDENTIFIER = 'MUF-6f4080'
export const TOKEN_DECIMALS = 18

// SC
export const GAME_SC_ADDRESS = 'erd1qqqqqqqqqqqqqpgq76g3ndvkcexfptzq8vhy2ldxdy673jcuvqvsr89u82'

// Deposit
export const DEPOSIT_TOKEN = 'depositToken'

// Wheel
export const DEFAULT_WHEEL_BLUR = '0px'
export const FREE_SPIN = 'freeSpin'
export const PREMIUM_SPIN = 'premiumSpin'
export const PREMIUM_SPIN_FEE = 400
