import { MedicalEnergyPack } from 'shared/types'

const defaultAmounts = [20, 50, 75, 100]

export const pricePerEnergy = 0.002

export const medicalEnergyPacks: MedicalEnergyPack[] = [
    ...defaultAmounts.map((amount) => ({
        amount,
        price: amount * pricePerEnergy,
    })),
]
