import React from 'react'
import { createSvgIcon } from '@mui/material'

export const DamageIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path d="M22.2902 0.432668C22.0972 0.240183 21.8356 0.13208 21.5629 0.13208C21.2902 0.13208 21.0286 0.240183 20.8356 0.432668L19.9712 1.29642C19.9062 1.36144 19.8696 1.44959 19.8696 1.54151C19.8696 1.63342 19.9062 1.72158 19.9712 1.7866L22.3434 4.15649C22.3756 4.1887 22.4137 4.21426 22.4558 4.2317C22.4978 4.24914 22.5429 4.25812 22.5884 4.25812C22.634 4.25812 22.679 4.24914 22.7211 4.2317C22.7631 4.21426 22.8013 4.1887 22.8335 4.15649L23.7005 3.29325C23.8934 3.10041 24.0018 2.83891 24.0018 2.56624C24.0018 2.29357 23.8934 2.03207 23.7005 1.83923L22.2902 0.432668Z" />
        <path d="M21.0561 4.70196L19.4249 3.07301C19.3501 2.99843 19.2488 2.95654 19.1432 2.95654C19.0376 2.95654 18.9363 2.99843 18.8615 3.07301L16.6581 5.27418L15.9135 4.53014C15.7953 4.4127 15.6353 4.34679 15.4686 4.34679C15.3019 4.34679 15.142 4.4127 15.0238 4.53014L14.559 4.99452C14.4879 5.06563 14.448 5.16204 14.448 5.26257C14.448 5.36309 14.4879 5.45951 14.559 5.53062L18.5951 9.56402C18.6663 9.63511 18.7629 9.67503 18.8636 9.67503C18.9643 9.67503 19.0608 9.63511 19.1321 9.56402L19.5968 9.09964C19.6553 9.04132 19.7016 8.97207 19.7332 8.89584C19.7649 8.81961 19.7811 8.7379 19.7811 8.65539C19.7811 8.57287 19.7649 8.49116 19.7332 8.41493C19.7016 8.3387 19.6553 8.26945 19.5968 8.21113L18.8527 7.4676L21.0561 5.26541C21.0933 5.22879 21.1228 5.18523 21.1431 5.13719C21.1634 5.08916 21.174 5.0376 21.1744 4.98546C21.1747 4.93333 21.1647 4.88164 21.1451 4.83334C21.1254 4.78505 21.0964 4.74111 21.0597 4.70402C21.0593 4.70342 21.0588 4.70292 21.0582 4.70256C21.0575 4.7022 21.0568 4.702 21.0561 4.70196Z" />
        <path d="M6.15014 18.4088C10.6044 17.155 15.5746 12.9059 17.6654 10.9834C17.7603 10.8961 17.8167 10.7748 17.8222 10.646C17.8276 10.5172 17.7816 10.3915 17.6943 10.2966L17.6793 10.2811L16.5211 9.12377C16.4959 9.09859 16.4661 9.07861 16.4332 9.06498C16.4003 9.05135 16.3651 9.04434 16.3295 9.04434C16.2939 9.04434 16.2587 9.05135 16.2258 9.06498C16.1929 9.07861 16.1631 9.09859 16.1379 9.12377C13.1527 12.1453 10.8709 13.8408 9.61813 14.7479C9.58449 14.7719 9.54343 14.7832 9.50225 14.7797C9.46108 14.7763 9.42247 14.7583 9.39329 14.7291C9.36411 14.6999 9.34624 14.6613 9.34288 14.6201C9.33952 14.579 9.35087 14.538 9.37492 14.5044C10.2812 13.2542 11.9775 10.973 15.0034 7.99017C15.0544 7.93918 15.0831 7.87005 15.0831 7.79796C15.0831 7.72588 15.0544 7.65675 15.0034 7.60576L13.8416 6.44739C13.7501 6.35602 13.626 6.30469 13.4966 6.30469C13.3673 6.30469 13.2432 6.35602 13.1517 6.44739C13.147 6.4516 13.1425 6.45608 13.1383 6.46081C11.2122 8.54897 6.9624 13.5178 5.7076 17.9671C5.69026 18.0284 5.6896 18.0932 5.70571 18.1548C5.72181 18.2164 5.75409 18.2726 5.7992 18.3176C5.84431 18.3626 5.90063 18.3947 5.96232 18.4106C6.02402 18.4266 6.08886 18.4258 6.15014 18.4083V18.4088Z" />
        <path d="M4.54974 11.4164C4.58831 11.4801 4.64567 11.5304 4.71396 11.5603C4.78225 11.5902 4.85812 11.5983 4.93118 11.5835C5.00424 11.5686 5.07092 11.5315 5.12207 11.4773C5.17322 11.4231 5.20635 11.3544 5.2169 11.2807L5.70695 8.24879C5.77046 7.85768 5.25098 7.6616 5.03979 7.99699L4.22649 9.28694C4.0739 9.52886 3.99292 9.80899 3.99292 10.095C3.99292 10.3809 4.0739 10.6611 4.22649 10.903L4.54974 11.4164Z" />
        <path d="M0.761742 24.1322H15.1331C15.4863 24.1322 15.5735 23.6421 15.2425 23.5198L10.4841 21.7685C10.3884 21.7331 10.3024 21.6756 10.2331 21.6006C10.1639 21.5257 10.1133 21.4354 10.0856 21.3372C10.0578 21.2391 10.0537 21.1357 10.0735 21.0356C10.0933 20.9356 10.1365 20.8416 10.1996 20.7613L10.8425 19.9456C10.8983 19.8753 10.9326 19.7903 10.9412 19.701C10.9497 19.6116 10.9322 19.5217 10.8906 19.4421C10.8491 19.3626 10.7854 19.2967 10.7072 19.2525C10.629 19.2084 10.5397 19.1878 10.45 19.1933L5.51449 19.476C5.29614 19.4876 5.08212 19.4122 4.91931 19.2663C4.75651 19.1205 4.65821 18.9161 4.64594 18.698C4.64432 18.6667 4.64432 18.6353 4.64594 18.604L4.94234 13.6697C4.94807 13.5801 4.92763 13.4907 4.88352 13.4124C4.8394 13.3341 4.7735 13.2702 4.69381 13.2286C4.61411 13.187 4.52404 13.1694 4.43452 13.1779C4.345 13.1864 4.25987 13.2207 4.18946 13.2766L3.37358 13.9184C3.30686 13.9711 3.2304 14.01 3.14858 14.0331C3.06677 14.0562 2.9812 14.0629 2.89679 14.0529C2.81237 14.0428 2.73076 14.0163 2.65663 13.9747C2.58249 13.9332 2.51729 13.8774 2.46476 13.8106C2.4227 13.7574 2.3894 13.6978 2.36613 13.6341L0.611476 8.88146C0.489611 8.5502 -0.00146484 8.63792 -0.00146484 8.99085V23.3696C-0.00146484 23.5719 0.0789442 23.7659 0.222073 23.9089C0.365202 24.0519 0.559327 24.1322 0.761742 24.1322Z" />
        <path d="M12.7055 19.5843L13.2177 19.9068C13.4599 20.0592 13.7402 20.1402 14.0264 20.1402C14.3126 20.1402 14.5929 20.0592 14.835 19.9068L16.126 19.0941C16.4616 18.8831 16.2654 18.364 15.874 18.4275L12.8397 18.9171C12.7659 18.9278 12.6972 18.961 12.643 19.0123C12.5888 19.0635 12.5518 19.1303 12.5371 19.2034C12.5224 19.2765 12.5307 19.3523 12.5608 19.4205C12.591 19.4887 12.6415 19.5459 12.7055 19.5843Z" />
    </svg>,
    'DamageIcon',
)
