import React from 'react'
import { createSvgIcon } from '@mui/material'

export const HouseIcon = createSvgIcon(
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17 8V17C17 18.1046 16.1046 19 15 19H5C3.89543 19 3 18.1046 3 17V8M19 10L10 1L1 10"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>,
    'HouseIcon',
)
