import React from 'react'
import { createSvgIcon } from '@mui/material'

export const DailyWheelCenter = createSvgIcon(
    <svg width={644} height={644} viewBox="0 0 644 644" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M644 321.968C644.003 384.999 625.508 446.644 590.806 499.262C556.105 551.88 506.724 593.157 448.785 617.975C390.845 642.792 326.897 650.06 264.866 638.876C202.836 627.692 145.452 598.549 99.828 555.06C54.2044 511.57 22.3477 455.647 8.20749 394.223C-5.93274 332.799 -1.73471 268.575 20.2811 209.515C42.297 150.454 81.1624 99.1533 132.059 61.9729C182.956 24.7925 243.645 3.36731 306.604 0.353564C311.811 0 316.857 0 322.032 0C327.207 0 332.318 0 337.428 0.353564C420.059 4.31728 497.995 39.9316 555.074 99.8113C612.153 159.691 643.996 239.242 644 321.968Z"
            fill="url(#paint0_linear_1706_45)"
        />
        <path
            d="M322.032 638.986C497.116 638.986 639.05 497.052 639.05 321.968C639.05 146.884 497.116 4.94995 322.032 4.94995C146.948 4.94995 5.01367 146.884 5.01367 321.968C5.01367 497.052 146.948 638.986 322.032 638.986Z"
            fill="url(#paint1_radial_1706_45)"
        />
        <path
            d="M511.644 511.606C535.912 487.394 555.292 458.738 568.728 427.201L322.038 321.967L427.239 568.69C458.77 555.243 487.424 535.864 511.644 511.606ZM132.367 511.606C156.587 535.864 185.241 555.243 216.772 568.69L322.005 321.967L75.3145 427.201C88.7507 458.738 108.131 487.394 132.399 511.606H132.367ZM132.367 132.329C108.115 156.547 88.746 185.202 75.3145 216.734L322.038 321.967L216.804 75.2763C185.267 88.7125 156.611 108.093 132.399 132.361L132.367 132.329ZM511.644 132.329C487.432 108.061 458.776 88.6803 427.239 75.2441L322.038 321.935L568.728 216.702C555.28 185.19 535.9 156.557 511.644 132.361V132.329Z"
            fill="url(#paint2_radial_1706_45)"
        />
        <path
            d="M426.737 570.008L322.05 324.635L217.331 570.008L216.398 569.622C152.542 542.276 101.682 491.371 74.3944 427.49L73.9766 426.557L319.446 321.87L73.9766 217.28L74.3944 216.348C101.709 152.515 152.566 101.659 216.398 74.3437L217.331 73.9259L322.05 319.395L426.737 73.9259L427.669 74.3437C491.501 101.659 542.358 152.515 569.673 216.348L570.091 217.28L569.158 217.666L324.685 321.967L570.155 426.654L569.737 427.586C542.383 491.466 491.451 542.34 427.54 569.622L426.737 570.008ZM323.882 323.895L427.733 567.34C490.351 540.284 540.288 490.358 567.358 427.747L323.882 323.895ZM76.6444 427.747C103.715 490.358 153.652 540.284 216.27 567.34L320.121 323.895L76.6444 427.747ZM76.6444 216.219L320.121 320.038L216.27 76.5937C153.643 103.652 103.703 153.592 76.6444 216.219ZM323.946 320.038L567.423 216.219C540.364 153.592 490.425 103.652 427.797 76.5937L323.946 320.038Z"
            fill="url(#paint3_linear_1706_45)"
        />
        <path
            d="M563.547 438.578C581.086 402.206 590.194 362.346 590.194 321.967C590.194 281.587 581.086 241.727 563.547 205.355C565.379 209.115 567.115 212.908 568.722 216.733L322.031 321.967L568.722 427.2C567.115 431.025 565.379 434.818 563.547 438.578Z"
            fill="url(#paint4_radial_1706_45)"
        />
        <path
            d="M80.4097 205.355C62.8917 241.733 53.7949 281.59 53.7949 321.967C53.7949 362.343 62.8917 402.2 80.4097 438.578C78.5776 434.818 76.874 431.025 75.2348 427.2L321.958 321.967L75.3312 216.733C76.9705 212.908 78.674 209.115 80.4097 205.355Z"
            fill="url(#paint5_radial_1706_45)"
        />
        <path
            d="M438.607 80.3563C402.235 62.8388 362.383 53.7422 322.012 53.7422C281.641 53.7422 241.788 62.8388 205.416 80.3563C209.177 78.5563 212.969 76.8206 216.794 75.2135L322.028 321.905L427.229 75.2135C431.054 76.8849 434.846 78.6206 438.607 80.3563Z"
            fill="url(#paint6_radial_1706_45)"
        />
        <path
            d="M427.229 568.692L322.028 321.969L216.794 568.692C212.969 567.052 209.177 565.478 205.416 563.517C241.788 581.034 281.641 590.131 322.012 590.131C362.383 590.131 402.235 581.034 438.607 563.517C434.846 565.349 431.054 567.052 427.229 568.692Z"
            fill="url(#paint7_radial_1706_45)"
        />
        <path
            d="M255.032 609.544C255.417 607.627 256.362 605.867 257.746 604.486C259.131 603.105 260.894 602.165 262.812 601.785C264.73 601.404 266.718 601.6 268.525 602.348C270.332 603.097 271.876 604.363 272.964 605.988C274.052 607.613 274.634 609.524 274.637 611.48C274.64 613.435 274.064 615.348 272.982 616.976C271.899 618.605 270.358 619.876 268.554 620.63C266.749 621.384 264.762 621.586 262.843 621.212C261.56 620.966 260.339 620.468 259.25 619.747C258.161 619.025 257.226 618.095 256.5 617.01C255.773 615.925 255.269 614.706 255.017 613.424C254.765 612.143 254.77 610.824 255.032 609.544Z"
            fill="url(#paint8_radial_1706_45)"
        />
        <path
            d="M370.144 613.303C369.757 611.377 369.949 609.38 370.697 607.564C371.445 605.747 372.714 604.194 374.345 603.099C375.976 602.004 377.895 601.418 379.859 601.413C381.823 601.409 383.744 601.987 385.38 603.074C387.016 604.162 388.292 605.71 389.048 607.522C389.804 609.335 390.005 611.331 389.627 613.259C389.248 615.186 388.307 616.958 386.922 618.35C385.536 619.743 383.769 620.693 381.844 621.081C380.565 621.339 379.248 621.342 377.967 621.091C376.687 620.839 375.469 620.338 374.382 619.616C373.296 618.893 372.362 617.964 371.635 616.881C370.908 615.798 370.401 614.582 370.144 613.303Z"
            fill="url(#paint9_radial_1706_45)"
        />
        <path
            d="M166.062 71.227C167.161 72.8567 167.752 74.7761 167.76 76.742C167.768 78.708 167.192 80.632 166.106 82.2704C165.019 83.9089 163.471 85.188 161.657 85.9458C159.843 86.7037 157.845 86.9061 155.916 86.5275C153.986 86.1489 152.213 85.2062 150.82 83.819C149.427 82.4317 148.477 80.6623 148.09 78.7347C147.704 76.8072 147.898 74.8082 148.648 72.9911C149.398 71.1739 150.671 69.6203 152.305 68.527C154.489 67.0657 157.163 66.5294 159.741 67.0355C162.32 67.5415 164.592 69.0487 166.062 71.227Z"
            fill="url(#paint10_radial_1706_45)"
        />
        <path
            d="M81.9665 150.007C83.6037 151.094 84.8816 152.643 85.6385 154.456C86.3955 156.27 86.5974 158.267 86.2187 160.195C85.84 162.124 84.8978 163.896 83.5113 165.289C82.1247 166.682 80.3562 167.632 78.4295 168.019C76.5028 168.406 74.5046 168.213 72.6877 167.464C70.8708 166.715 69.317 165.444 68.2228 163.811C67.1287 162.179 66.5434 160.259 66.541 158.293C66.5387 156.328 67.1194 154.407 68.2096 152.771C69.6691 150.583 71.9373 149.063 74.5164 148.544C77.0955 148.026 79.7749 148.552 81.9665 150.007Z"
            fill="url(#paint11_radial_1706_45)"
        />
        <path
            d="M34.418 254.952C36.3454 255.333 38.1165 256.277 39.5074 257.665C40.8982 259.052 41.8462 260.821 42.2315 262.748C42.6168 264.674 42.4221 266.672 41.672 268.488C40.9219 270.304 39.6501 271.856 38.0174 272.949C36.3848 274.042 34.4647 274.626 32.5001 274.628C30.5354 274.629 28.6144 274.048 26.9801 272.958C25.3458 271.867 24.0717 270.317 23.3188 268.502C22.5659 266.687 22.3681 264.69 22.7505 262.763C23.2628 260.18 24.7797 257.907 26.9675 256.442C29.1553 254.978 31.8352 254.442 34.418 254.952Z"
            fill="url(#paint12_radial_1706_45)"
        />
        <path
            d="M30.6696 370.117C32.5965 369.729 34.5955 369.921 36.4133 370.669C38.2311 371.417 39.786 372.688 40.8812 374.32C41.9764 375.952 42.5625 377.873 42.5653 379.839C42.5682 381.805 41.9877 383.727 40.8973 385.363C39.8069 386.998 38.2557 388.273 36.44 389.027C34.6244 389.78 32.626 389.978 30.698 389.595C28.7699 389.212 26.9989 388.265 25.6092 386.875C24.2195 385.485 23.2735 383.713 22.8912 381.785C22.3887 379.208 22.924 376.536 24.3805 374.351C25.8371 372.167 28.0972 370.645 30.6696 370.117Z"
            fill="url(#paint13_radial_1706_45)"
        />
        <path
            d="M71.2766 477.954C72.909 476.859 74.8296 476.273 76.7951 476.27C78.7607 476.267 80.6829 476.848 82.3183 477.938C83.9537 479.029 85.2289 480.58 85.9823 482.395C86.7357 484.211 86.9334 486.209 86.5506 488.137C86.1677 490.065 85.2214 491.836 83.8314 493.225C82.4415 494.615 80.6704 495.561 78.7424 495.944C76.8145 496.327 74.8163 496.128 73.0009 495.375C71.1856 494.621 69.6346 493.346 68.5445 491.71C67.0863 489.523 66.5551 486.846 67.0672 484.267C67.5794 481.689 69.0931 479.418 71.2766 477.954Z"
            fill="url(#paint14_radial_1706_45)"
        />
        <path
            d="M150.04 562.038C151.127 560.401 152.676 559.123 154.489 558.366C156.303 557.609 158.3 557.407 160.229 557.786C162.157 558.164 163.93 559.106 165.322 560.493C166.715 561.88 167.665 563.648 168.052 565.575C168.439 567.501 168.246 569.5 167.497 571.317C166.748 573.133 165.477 574.687 163.845 575.781C162.212 576.876 160.292 577.461 158.327 577.463C156.361 577.466 154.44 576.885 152.805 575.795C150.616 574.335 149.096 572.067 148.577 569.488C148.059 566.909 148.585 564.229 150.04 562.038Z"
            fill="url(#paint15_radial_1706_45)"
        />
        <path
            d="M477.989 572.709C476.889 571.083 476.294 569.168 476.281 567.206C476.268 565.243 476.837 563.32 477.916 561.68C478.995 560.041 480.536 558.757 482.344 557.993C484.152 557.228 486.145 557.016 488.074 557.384C490.002 557.752 491.778 558.682 493.177 560.059C494.577 561.435 495.537 563.195 495.937 565.117C496.337 567.039 496.158 569.036 495.424 570.856C494.689 572.676 493.432 574.238 491.81 575.344C490.732 576.085 489.517 576.605 488.237 576.874C486.956 577.142 485.635 577.154 484.35 576.909C483.065 576.664 481.841 576.167 480.749 575.446C479.657 574.725 478.719 573.794 477.989 572.709Z"
            fill="url(#paint16_radial_1706_45)"
        />
        <path
            d="M562.066 493.929C560.431 492.839 559.155 491.288 558.402 489.472C557.648 487.657 557.45 485.659 557.833 483.731C558.215 481.803 559.161 480.032 560.551 478.642C561.941 477.252 563.712 476.306 565.64 475.923C567.568 475.54 569.566 475.738 571.381 476.491C573.197 477.245 574.748 478.52 575.838 480.155C576.929 481.791 577.509 483.713 577.506 485.678C577.504 487.644 576.918 489.564 575.823 491.197C574.358 493.38 572.088 494.894 569.509 495.406C566.93 495.918 564.254 495.387 562.066 493.929Z"
            fill="url(#paint17_radial_1706_45)"
        />
        <path
            d="M609.602 388.92C607.675 388.539 605.904 387.595 604.513 386.207C603.122 384.82 602.174 383.051 601.789 381.124C601.403 379.198 601.598 377.2 602.348 375.384C603.098 373.569 604.37 372.016 606.003 370.923C607.635 369.83 609.555 369.246 611.52 369.245C613.485 369.243 615.406 369.824 617.04 370.915C618.674 372.005 619.948 373.556 620.701 375.37C621.454 377.185 621.652 379.182 621.27 381.109C620.757 383.692 619.24 385.965 617.053 387.43C614.865 388.894 612.185 389.43 609.602 388.92Z"
            fill="url(#paint18_radial_1706_45)"
        />
        <path
            d="M613.312 273.82C611.393 274.194 609.406 273.991 607.601 273.238C605.797 272.484 604.256 271.213 603.173 269.584C602.091 267.956 601.515 266.043 601.518 264.087C601.521 262.132 602.103 260.221 603.191 258.596C604.279 256.971 605.823 255.704 607.63 254.956C609.437 254.208 611.425 254.012 613.343 254.392C615.261 254.773 617.024 255.713 618.408 257.094C619.793 258.475 620.738 260.235 621.123 262.152C621.38 263.431 621.382 264.749 621.128 266.029C620.874 267.309 620.37 268.526 619.644 269.61C618.918 270.694 617.985 271.624 616.898 272.347C615.812 273.069 614.593 273.57 613.312 273.82Z"
            fill="url(#paint19_radial_1706_45)"
        />
        <path
            d="M572.746 166.013C571.113 167.104 569.194 167.687 567.23 167.687C565.267 167.687 563.347 167.105 561.714 166.015C560.081 164.925 558.807 163.375 558.055 161.561C557.302 159.747 557.105 157.751 557.486 155.825C557.868 153.898 558.812 152.128 560.199 150.739C561.586 149.349 563.355 148.401 565.28 148.016C567.206 147.631 569.202 147.825 571.017 148.574C572.832 149.323 574.385 150.593 575.478 152.224C576.21 153.307 576.719 154.525 576.978 155.806C577.236 157.086 577.238 158.406 576.984 159.688C576.73 160.97 576.225 162.189 575.498 163.274C574.77 164.36 573.835 165.291 572.746 166.013Z"
            fill="url(#paint20_radial_1706_45)"
        />
        <path
            d="M493.984 81.9304C492.897 83.5676 491.348 84.8455 489.535 85.6025C487.721 86.3594 485.724 86.5613 483.795 86.1826C481.867 85.8039 480.094 84.8617 478.702 83.4752C477.309 82.0886 476.359 80.3201 475.972 78.3934C475.585 76.4667 475.778 74.4685 476.527 72.6516C477.276 70.8347 478.547 69.2809 480.179 68.1867C481.812 67.0925 483.732 66.5072 485.697 66.5049C487.663 66.5025 489.584 67.0832 491.219 68.1735C493.408 69.633 494.928 71.9011 495.447 74.4803C495.965 77.0594 495.439 79.7388 493.984 81.9304Z"
            fill="url(#paint21_radial_1706_45)"
        />
        <path
            d="M388.955 34.3925C388.57 36.3098 387.625 38.0699 386.241 39.4509C384.856 40.8319 383.093 41.7719 381.175 42.1523C379.257 42.5327 377.269 42.3365 375.462 41.5884C373.655 40.8403 372.111 39.5738 371.023 37.9487C369.935 36.3237 369.353 34.4128 369.35 32.4573C369.347 30.5017 369.923 28.589 371.005 26.9605C372.088 25.332 373.629 24.0607 375.433 23.3069C377.238 22.5531 379.225 22.3506 381.144 22.725C383.727 23.2373 386 24.7542 387.465 26.942C388.93 29.1298 389.465 31.8097 388.955 34.3925Z"
            fill="url(#paint22_radial_1706_45)"
        />
        <path
            d="M273.882 30.6304C274.269 32.556 274.077 34.5531 273.329 36.3694C272.581 38.1856 271.311 39.7393 269.681 40.8341C268.05 41.9289 266.131 42.5156 264.167 42.52C262.203 42.5244 260.282 41.9463 258.646 40.8589C257.01 39.7715 255.734 38.2235 254.978 36.4107C254.222 34.5978 254.021 32.6016 254.399 30.6742C254.777 28.7469 255.719 26.9751 257.104 25.5828C258.49 24.1904 260.257 23.2401 262.182 22.852C263.461 22.5942 264.778 22.5909 266.059 22.8423C267.339 23.0937 268.557 23.5949 269.644 24.3172C270.73 25.0396 271.664 25.9689 272.391 27.0521C273.118 28.1354 273.625 29.3513 273.882 30.6304Z"
            fill="url(#paint23_radial_1706_45)"
        />
        <path
            d="M322.04 343.6C333.987 343.6 343.672 333.915 343.672 321.968C343.672 310.021 333.987 300.336 322.04 300.336C310.093 300.336 300.408 310.021 300.408 321.968C300.408 333.915 310.093 343.6 322.04 343.6Z"
            fill="url(#paint24_linear_1706_45)"
        />
        <path
            d="M339.307 321.968C339.314 325.398 338.302 328.752 336.401 331.606C334.5 334.46 331.794 336.685 328.627 338C325.461 339.316 321.974 339.661 318.611 338.994C315.247 338.326 312.157 336.676 309.732 334.251C307.308 331.826 305.657 328.736 304.99 325.373C304.322 322.009 304.668 318.523 305.983 315.356C307.298 312.189 309.524 309.484 312.378 307.582C315.232 305.681 318.586 304.67 322.015 304.676C326.601 304.676 331 306.498 334.243 309.741C337.486 312.984 339.307 317.382 339.307 321.968Z"
            fill="url(#paint25_radial_1706_45)"
        />
        <path
            d="M146 332.666V330.047C146.556 328.993 147.252 328.02 148.069 327.154C148.891 326.301 149.774 325.449 150.778 324.535L152.787 322.677C153.236 322.278 153.625 321.817 153.943 321.307C154.216 320.847 154.353 320.319 154.339 319.784C154.339 318.353 153.395 317.622 151.508 317.622C150.648 317.63 149.79 317.732 148.952 317.927C148.164 318.126 147.391 318.38 146.639 318.688V313.816C147.625 313.46 148.645 313.205 149.682 313.054C150.797 312.848 151.927 312.746 153.06 312.75C155.034 312.591 156.99 313.215 158.508 314.486C159.076 315.112 159.507 315.85 159.774 316.652C160.041 317.454 160.139 318.303 160.06 319.145C160.071 319.949 159.958 320.75 159.725 321.52C159.517 322.175 159.186 322.785 158.752 323.317C158.221 323.951 157.641 324.542 157.017 325.083L155.16 326.728C154.714 327.205 154.317 327.725 153.973 328.281H160.364V332.666H146Z"
            fill="#181818"
        />
        <path
            d="M168.155 332.97H166.146C165.385 332.97 164.655 332.787 163.894 332.635C163.235 332.5 162.592 332.296 161.977 332.026V327.336C162.511 327.593 163.072 327.787 163.651 327.915C164.287 328.095 164.939 328.217 165.598 328.28C166.145 328.319 166.695 328.319 167.242 328.28C168.087 328.354 168.934 328.174 169.676 327.762C169.941 327.582 170.153 327.335 170.292 327.046C170.431 326.757 170.491 326.437 170.468 326.118C170.495 325.839 170.46 325.558 170.365 325.295C170.27 325.032 170.118 324.793 169.92 324.595C169.311 324.189 168.576 324.016 167.85 324.108H161.764V321.489L162.403 313.054H175.093L174.698 317.439H167.303L167.12 320.241H169.342C171.232 320.093 173.11 320.658 174.606 321.824C175.197 322.434 175.654 323.161 175.948 323.958C176.241 324.755 176.365 325.605 176.311 326.453C176.335 327.341 176.166 328.223 175.813 329.039C175.461 329.854 174.935 330.583 174.272 331.173C172.526 332.511 170.346 333.151 168.155 332.97Z"
            fill="#181818"
        />
        <path
            d="M184.072 332.665L191.315 322.738L184.407 313.054H191.224L194.815 318.2L198.376 313.054H205.193L198.224 322.738L205.467 332.665H198.558L194.754 327.336L190.981 332.665H184.072Z"
            fill="#181818"
        />
        <path
            d="M206.988 332.665V313.053H217.092C218.476 313.001 219.847 313.339 221.048 314.028C222.075 314.631 222.892 315.535 223.392 316.616C223.915 317.762 224.175 319.011 224.153 320.27C224.175 321.554 223.871 322.821 223.27 323.955C222.708 325.027 221.839 325.907 220.775 326.483C219.608 327.115 218.296 327.43 216.97 327.396H212.71V332.665H206.988ZM212.71 323.011H215.753C216.119 323.042 216.487 322.989 216.829 322.857C217.172 322.725 217.48 322.518 217.731 322.25C218.201 321.701 218.441 320.992 218.401 320.27C218.432 319.532 218.218 318.804 217.792 318.2C217.556 317.937 217.264 317.732 216.937 317.6C216.61 317.468 216.257 317.413 215.905 317.438H212.862L212.71 323.011Z"
            fill="#181818"
        />
        <path d="M428.278 332.343L434.73 317.117H427V312.732H441.243V316.02L434.365 332.343H428.278Z" fill="#181818" />
        <path
            d="M448.729 332.647C448.07 332.687 447.409 332.687 446.751 332.647C445.994 332.583 445.242 332.471 444.499 332.312C443.83 332.193 443.177 331.999 442.551 331.734V327.136C443.101 327.379 443.671 327.572 444.255 327.714L446.172 328.08C446.72 328.117 447.269 328.117 447.816 328.08C448.669 328.137 449.522 327.958 450.281 327.562C450.542 327.38 450.75 327.132 450.884 326.843C451.017 326.554 451.072 326.234 451.042 325.917C451.065 325.635 451.029 325.35 450.934 325.082C450.84 324.815 450.69 324.57 450.494 324.364C449.872 323.992 449.146 323.832 448.425 323.908H442.338V321.289L442.855 312.732H455.668L455.272 317.117H447.877L447.694 319.918H449.916C451.808 319.756 453.691 320.322 455.181 321.502C455.772 322.112 456.229 322.839 456.522 323.636C456.816 324.433 456.939 325.283 456.885 326.131C456.913 327.016 456.748 327.897 456.402 328.713C456.055 329.528 455.534 330.258 454.876 330.851C453.111 332.173 450.928 332.811 448.729 332.647Z"
            fill="#181818"
        />
        <path
            d="M458.406 332.343L465.649 322.415L458.711 312.732H465.528L469.119 317.878L472.74 312.732H479.557L472.588 322.415L479.831 332.343H472.923L469.119 327.014L465.345 332.343H458.406Z"
            fill="#181818"
        />
        <path
            d="M481.322 332.344V312.732H491.426C492.816 312.675 494.196 313.001 495.413 313.676C496.433 314.293 497.24 315.207 497.726 316.295C498.249 317.441 498.509 318.69 498.487 319.949C498.52 321.234 498.215 322.504 497.604 323.634C497.042 324.706 496.173 325.586 495.108 326.162C493.942 326.794 492.63 327.109 491.304 327.075H487.044V332.344H481.322ZM487.044 322.69H490.087C490.457 322.721 490.83 322.668 491.177 322.537C491.525 322.405 491.839 322.197 492.096 321.929C492.534 321.365 492.76 320.664 492.735 319.949C492.791 319.208 492.575 318.471 492.126 317.879C491.895 317.611 491.603 317.402 491.275 317.269C490.947 317.137 490.592 317.085 490.239 317.117H487.196L487.044 322.69Z"
            fill="#181818"
        />
        <path
            d="M334.267 205.603C334.267 206.151 334.267 206.821 334.267 207.581C334.203 208.338 334.091 209.09 333.932 209.833C333.813 210.502 333.619 211.155 333.354 211.781H328.756C328.985 211.226 329.178 210.657 329.334 210.077C329.497 209.428 329.618 208.789 329.7 208.16C329.7 207.49 329.7 206.942 329.7 206.516C329.757 205.663 329.578 204.81 329.182 204.051C328.996 203.794 328.747 203.59 328.459 203.457C328.171 203.323 327.854 203.266 327.537 203.29C327.254 203.261 326.968 203.294 326.699 203.389C326.43 203.484 326.186 203.637 325.984 203.838C325.612 204.46 325.452 205.186 325.528 205.908V211.994H322.909L314.352 211.477V198.786L318.737 199.182V206.577L321.538 206.76V204.538C321.376 202.646 321.942 200.762 323.122 199.273C323.725 198.672 324.451 198.209 325.25 197.915C326.049 197.621 326.902 197.503 327.751 197.569C328.636 197.54 329.517 197.705 330.333 198.052C331.148 198.399 331.878 198.92 332.471 199.577C333.765 201.31 334.401 203.445 334.267 205.603Z"
            fill="#181818"
        />
        <path
            d="M334.267 186.553C334.353 188.431 333.943 190.299 333.08 191.97C332.316 193.337 331.115 194.409 329.669 195.013C327.909 195.676 326.038 195.996 324.157 195.957C322.276 196.001 320.404 195.681 318.645 195.013C317.186 194.419 315.972 193.345 315.204 191.97C314.353 190.296 313.954 188.429 314.047 186.553C313.911 185.234 314.072 183.901 314.52 182.652C314.968 181.404 315.691 180.272 316.635 179.34C318.884 177.927 321.486 177.177 324.142 177.177C326.798 177.177 329.4 177.927 331.649 179.34C332.598 180.269 333.327 181.4 333.78 182.649C334.233 183.897 334.4 185.231 334.267 186.553ZM329.395 186.553C329.438 186.014 329.336 185.473 329.101 184.987C328.865 184.5 328.504 184.085 328.055 183.784C326.842 183.202 325.5 182.94 324.157 183.023C322.804 182.93 321.45 183.193 320.229 183.784C319.821 184.118 319.492 184.538 319.267 185.014C319.041 185.49 318.924 186.011 318.924 186.538C318.924 187.065 319.041 187.585 319.267 188.061C319.492 188.538 319.821 188.958 320.229 189.292C321.446 189.896 322.801 190.169 324.157 190.083C325.503 190.158 326.845 189.886 328.055 189.292C328.5 188.994 328.858 188.583 329.093 188.102C329.328 187.621 329.432 187.087 329.395 186.553Z"
            fill="#181818"
        />
        <path
            d="M333.963 169.42L324.035 162.177L314.352 169.146V162.451L319.498 158.86L314.352 155.238V148.421L324.035 155.39L333.963 148.147V155.056L328.634 158.86L333.963 162.451V169.42Z"
            fill="#181818"
        />
        <path
            d="M333.962 146.502H314.35V136.398C314.293 135.008 314.619 133.629 315.294 132.411C315.911 131.392 316.825 130.585 317.913 130.098C319.059 129.575 320.308 129.315 321.567 129.338C322.852 129.307 324.121 129.611 325.252 130.22C326.324 130.783 327.204 131.651 327.78 132.716C328.412 133.882 328.727 135.194 328.693 136.52V140.781H333.962V146.502ZM324.308 140.781V137.737C324.333 137.367 324.277 136.997 324.146 136.65C324.015 136.304 323.81 135.989 323.547 135.729C322.95 135.322 322.244 135.104 321.522 135.104C320.799 135.104 320.094 135.322 319.497 135.729C319.229 135.96 319.02 136.252 318.887 136.58C318.755 136.908 318.703 137.263 318.735 137.615V140.659L324.308 140.781Z"
            fill="#181818"
        />
        <path
            d="M334.284 506.709C334.32 508.359 334.008 509.997 333.37 511.518C332.825 512.895 331.862 514.066 330.617 514.869C329.372 515.671 327.907 516.064 326.427 515.992H314.246V510.27H325.666C326.158 510.375 326.669 510.356 327.152 510.216C327.636 510.075 328.077 509.817 328.436 509.464C328.795 509.111 329.061 508.675 329.21 508.194C329.359 507.713 329.386 507.203 329.29 506.709C329.323 506.245 329.254 505.78 329.089 505.345C328.923 504.91 328.665 504.517 328.331 504.192C327.998 503.867 327.597 503.619 327.158 503.466C326.719 503.312 326.251 503.255 325.788 503.301H314.368V497.58H326.549C328.023 497.494 329.487 497.874 330.733 498.666C331.98 499.458 332.945 500.622 333.492 501.992C334.066 503.497 334.335 505.1 334.284 506.709Z"
            fill="#181818"
        />
        <path
            d="M333.979 494.871H314.367V490.002L324.751 482.607H314.367V476.885H333.979V481.785L323.625 489.15H333.979V494.871Z"
            fill="#181818"
        />
        <path d="M333.979 474.146H314.367V468.425H329.593V459.599H333.979V474.146Z" fill="#181818" />
        <path
            d="M334.284 448.948C334.32 450.597 334.008 452.235 333.37 453.756C332.825 455.133 331.862 456.305 330.617 457.107C329.372 457.909 327.907 458.302 326.427 458.23H314.246V452.508H325.666C326.158 452.613 326.669 452.595 327.152 452.454C327.636 452.313 328.077 452.055 328.436 451.702C328.795 451.349 329.061 450.913 329.21 450.432C329.359 449.952 329.386 449.442 329.29 448.948C329.332 448.479 329.271 448.006 329.109 447.564C328.948 447.121 328.691 446.72 328.356 446.389C328.022 446.057 327.618 445.804 327.174 445.646C326.73 445.489 326.256 445.432 325.788 445.478H314.368V439.696H326.549C328.023 439.61 329.487 439.99 330.733 440.782C331.98 441.574 332.945 442.738 333.492 444.109C334.082 445.651 334.351 447.298 334.284 448.948Z"
            fill="#181818"
        />
        <path
            d="M334.283 426.792C334.489 429.736 333.551 432.647 331.664 434.918C330.695 435.876 329.536 436.62 328.261 437.102C326.987 437.584 325.625 437.794 324.264 437.718C322.88 437.811 321.49 437.616 320.185 437.144C318.88 436.673 317.687 435.935 316.682 434.979C314.76 432.714 313.808 429.784 314.032 426.823C313.988 424.746 314.339 422.681 315.068 420.736H320.123C319.362 422.504 318.978 424.411 318.996 426.336C318.863 427.815 319.311 429.288 320.245 430.444C320.798 430.933 321.445 431.304 322.147 431.535C322.848 431.765 323.59 431.85 324.325 431.783C325.045 431.851 325.771 431.758 326.451 431.51C327.13 431.263 327.746 430.868 328.254 430.353C329.137 429.184 329.57 427.736 329.472 426.275C329.473 425.315 329.381 424.357 329.198 423.414C329.001 422.472 328.705 421.554 328.315 420.675H333.339C333.647 421.57 333.891 422.485 334.07 423.414C334.248 424.531 334.319 425.662 334.283 426.792Z"
            fill="#181818"
        />
        <path
            d="M333.979 418.637H314.367V412.915H321.28L314.367 406.829V400.316L322.407 407.224L333.979 399.829V405.916L326.03 410.755L328.314 412.763H333.979V418.637Z"
            fill="#181818"
        />
        <path
            d="M333.979 392.463H326.792L314.367 399.523V393.437L321.767 389.48L314.367 385.524V379.438L326.792 386.498H333.979V392.463Z"
            fill="#181818"
        />
        <path
            d="M181.774 205.915C181.291 205.421 180.834 204.902 180.404 204.362C179.917 203.722 179.43 203.052 179.004 202.382C178.611 201.829 178.274 201.237 178 200.616L181.226 197.388C181.573 198.295 182.033 199.154 182.595 199.946C183.113 200.695 183.693 201.398 184.33 202.047C184.908 202.698 185.626 203.208 186.43 203.539C186.733 203.639 187.059 203.642 187.364 203.549C187.669 203.457 187.938 203.273 188.134 203.022C188.956 202.23 188.712 201.194 187.465 199.976L185.121 197.632L188.165 195.195L190.143 197.144C190.573 197.654 191.145 198.025 191.786 198.21C192.045 198.236 192.305 198.197 192.545 198.096C192.784 197.994 192.994 197.835 193.156 197.632C193.357 197.453 193.504 197.221 193.579 196.962C193.654 196.703 193.655 196.429 193.582 196.17C193.32 195.377 192.846 194.672 192.212 194.13C191.563 193.488 190.85 192.917 190.082 192.424C189.38 191.986 188.636 191.618 187.86 191.328L191.056 188.13C191.946 188.518 192.792 188.998 193.582 189.562C194.51 190.188 195.376 190.902 196.169 191.693C197.241 192.706 198.109 193.916 198.725 195.256C199.184 196.298 199.342 197.448 199.182 198.576C199.023 199.608 198.531 200.56 197.782 201.286C197.169 201.923 196.42 202.413 195.59 202.717C194.826 203.019 193.988 203.083 193.186 202.9C193.525 203.697 193.595 204.582 193.387 205.423C193.178 206.264 192.702 207.013 192.03 207.559C191.231 208.442 190.159 209.032 188.986 209.234C187.777 209.339 186.562 209.096 185.487 208.534C184.11 207.876 182.855 206.991 181.774 205.915Z"
            fill="#FF8400"
        />
        <path
            d="M195.409 222.847L197.113 221.141C196.449 220.423 195.848 219.649 195.317 218.827C194.853 218.135 194.475 217.388 194.191 216.604L197.417 213.376C197.792 214.436 198.271 215.456 198.848 216.421C199.4 217.325 200.053 218.163 200.795 218.918C201.313 219.493 201.932 219.968 202.621 220.319C202.861 220.415 203.124 220.434 203.375 220.374C203.625 220.314 203.851 220.178 204.021 219.984C204.242 219.766 204.388 219.483 204.437 219.176C204.486 218.87 204.437 218.555 204.295 218.279C203.872 217.234 203.384 216.217 202.834 215.233C202.294 214.274 201.875 213.251 201.587 212.188C201.404 211.358 201.457 210.493 201.739 209.691C202.084 208.835 202.614 208.065 203.291 207.438C203.805 206.902 204.422 206.477 205.104 206.186C205.787 205.895 206.521 205.745 207.262 205.745C208.004 205.745 208.738 205.895 209.421 206.186C210.103 206.477 210.72 206.902 211.234 207.438L212.969 205.671L216.316 208.991L214.612 210.726C215.069 211.336 215.525 211.914 215.921 212.493C216.285 213.006 216.611 213.545 216.895 214.107L213.638 217.365C213.279 216.479 212.831 215.632 212.299 214.838C211.827 214.155 211.297 213.513 210.717 212.919C210.183 212.365 209.58 211.884 208.921 211.488C208.712 211.312 208.448 211.215 208.175 211.215C207.903 211.215 207.638 211.312 207.43 211.488C207.258 211.666 207.143 211.892 207.1 212.136C207.056 212.38 207.086 212.631 207.186 212.858C207.581 213.792 208.038 214.697 208.556 215.568C209.278 216.696 209.793 217.944 210.078 219.253C210.249 220.167 210.143 221.111 209.773 221.963C209.387 222.805 208.851 223.568 208.191 224.217C207.289 225.169 206.06 225.746 204.752 225.831C203.27 225.866 201.817 225.417 200.613 224.552L198.756 226.409L195.409 222.847Z"
            fill="#FF8400"
        />
        <path
            d="M205.725 229.454L219.602 215.568L223.528 219.527L219.937 230.002L230.375 226.378L234.301 230.307L220.454 244.163L216.407 240.113L223.345 233.2L216.559 235.545L214.368 233.352L216.711 226.561L209.772 233.504L205.725 229.454Z"
            fill="#FF8400"
        />
        <path
            d="M228.642 252.781C227.443 251.636 226.49 250.258 225.842 248.731C225.298 247.411 225.15 245.961 225.416 244.559C225.769 243.06 226.564 241.702 227.699 240.661L236.22 232.134L240.268 236.184L232.172 244.285C231.758 244.563 231.419 244.94 231.184 245.38C230.949 245.821 230.827 246.313 230.827 246.812C230.827 247.312 230.949 247.804 231.184 248.244C231.419 248.685 231.758 249.061 232.172 249.34C232.452 249.748 232.827 250.082 233.264 250.313C233.702 250.544 234.189 250.664 234.683 250.664C235.178 250.664 235.665 250.544 236.102 250.313C236.54 250.082 236.914 249.748 237.194 249.34L245.289 241.24L249.337 245.29L240.815 253.816C239.771 254.918 238.428 255.691 236.95 256.039C235.536 256.31 234.074 256.151 232.751 255.583C231.193 254.96 229.791 254.003 228.642 252.781Z"
            fill="#FF8400"
        />
        <path
            d="M237.225 260.943L251.072 247.087L261.48 257.502L258.437 260.547L252.046 254.061L249.641 256.436L255.211 262.009L252.167 265.054L246.628 259.512L241.242 264.902L237.225 260.943Z"
            fill="#FF8400"
        />
        <path
            d="M393.301 267.658L387.215 252.431L381.524 257.882L378.48 254.837L388.523 244.757L390.867 247.102L397.501 263.516L393.301 267.658Z"
            fill="#FF8400"
        />
        <path
            d="M413.447 251.243L411.743 249.538C411.025 250.202 410.251 250.803 409.43 251.334C408.738 251.8 407.992 252.178 407.209 252.461L403.983 249.386C405.042 249.011 406.061 248.531 407.026 247.954C407.929 247.402 408.766 246.748 409.521 246.005C410.096 245.487 410.57 244.868 410.921 244.178C411.017 243.939 411.036 243.675 410.976 243.425C410.916 243.174 410.78 242.948 410.587 242.777C410.37 242.555 410.089 242.407 409.783 242.352C409.478 242.298 409.163 242.34 408.882 242.473C408.213 242.747 407.209 243.234 405.839 243.965C404.826 244.504 403.754 244.923 402.644 245.214C401.814 245.396 400.949 245.343 400.148 245.061C399.292 244.716 398.523 244.185 397.896 243.508C397.362 242.997 396.937 242.382 396.647 241.702C396.357 241.021 396.207 240.289 396.207 239.549C396.207 238.81 396.357 238.078 396.647 237.397C396.937 236.717 397.362 236.102 397.896 235.591L396.131 233.824L399.448 230.475L401.183 232.18C401.791 231.723 402.37 231.266 402.948 230.87C403.46 230.506 404 230.18 404.561 229.896L407.787 233.154C406.127 233.864 404.626 234.899 403.374 236.2C402.82 236.733 402.34 237.337 401.944 237.996C401.788 238.223 401.717 238.497 401.745 238.77C401.773 239.044 401.897 239.298 402.096 239.488C402.274 239.661 402.5 239.776 402.743 239.819C402.987 239.862 403.239 239.832 403.465 239.732C404.398 239.337 405.303 238.88 406.174 238.362C407.301 237.639 408.548 237.124 409.856 236.839C410.769 236.668 411.712 236.774 412.565 237.144C413.405 237.53 414.169 238.067 414.817 238.727C415.769 239.63 416.345 240.859 416.43 242.168C416.465 243.651 416.016 245.105 415.152 246.31L417.008 248.167L413.447 251.243Z"
            fill="#FF8400"
        />
        <path
            d="M420.051 240.921L406.174 227.034L410.13 223.106L420.599 226.699L416.978 216.224L420.903 212.326L434.751 226.182L430.703 230.232L423.795 223.289L426.138 230.079L423.947 232.272L417.16 229.927L424.099 236.87L420.051 240.921Z"
            fill="#FF8400"
        />
        <path
            d="M443.364 217.989C442.22 219.189 440.842 220.142 439.317 220.79C437.998 221.335 436.549 221.484 435.147 221.217C433.66 220.889 432.304 220.126 431.252 219.024L422.73 210.498L426.778 206.447L434.873 214.548C435.152 214.962 435.528 215.302 435.968 215.537C436.409 215.772 436.9 215.894 437.399 215.894C437.898 215.894 438.39 215.772 438.83 215.537C439.271 215.302 439.647 214.962 439.925 214.548C440.333 214.268 440.667 213.893 440.898 213.455C441.128 213.018 441.249 212.53 441.249 212.035C441.249 211.541 441.128 211.053 440.898 210.615C440.667 210.178 440.333 209.803 439.925 209.523L431.83 201.423L435.878 197.373L444.399 205.899C445.491 206.952 446.261 208.293 446.621 209.767C446.876 211.182 446.718 212.642 446.164 213.969C445.53 215.495 444.575 216.866 443.364 217.989Z"
            fill="#FF8400"
        />
        <path
            d="M451.519 209.401L437.672 195.545L448.08 185.13L451.123 188.176L444.732 194.571L447.106 196.946L452.675 191.404L455.719 194.449L450.18 200.022L455.567 205.412L451.519 209.401Z"
            fill="#FF8400"
        />
        <path
            d="M376.973 391.6L387.076 381.49L384.429 379.511L387.807 376.13L395.08 381.551L381.02 395.65L376.973 391.6Z"
            fill="#FF8400"
        />
        <path
            d="M382.451 397.142L384.308 395.315C385.44 394.948 386.618 394.743 387.807 394.706C388.994 394.706 390.242 394.706 391.581 394.706H394.32C394.923 394.74 395.527 394.689 396.116 394.553C396.623 394.41 397.084 394.137 397.455 393.762C398.459 392.757 398.307 391.569 396.968 390.229C396.369 389.618 395.694 389.085 394.959 388.646C394.257 388.226 393.525 387.859 392.768 387.549L396.237 384.078C397.149 384.515 398.024 385.024 398.855 385.6C399.765 386.272 400.629 387.005 401.441 387.793C402.939 389.085 403.877 390.908 404.059 392.878C404.061 393.726 403.886 394.565 403.545 395.341C403.204 396.117 402.706 396.813 402.081 397.385C401.531 397.968 400.894 398.461 400.194 398.847C399.575 399.159 398.905 399.355 398.216 399.426C397.385 399.489 396.551 399.489 395.72 399.426L393.255 399.274C392.538 399.177 391.811 399.177 391.094 399.274L395.72 403.902L392.677 406.948L382.451 397.142Z"
            fill="#FF8400"
        />
        <path
            d="M400.223 418.58L401.897 416.905C401.251 416.161 400.651 415.378 400.101 414.56C399.639 413.882 399.271 413.144 399.006 412.368L402.232 409.109C402.589 410.171 403.058 411.192 403.632 412.154C404.186 413.057 404.839 413.894 405.579 414.652C406.103 415.233 406.733 415.709 407.436 416.052C407.674 416.153 407.939 416.174 408.191 416.114C408.442 416.054 408.668 415.915 408.836 415.717C409.05 415.501 409.191 415.224 409.241 414.924C409.29 414.624 409.244 414.315 409.11 414.042C408.674 412.999 408.176 411.982 407.618 410.997C407.09 410.036 406.681 409.013 406.401 407.952C406.154 407.138 406.154 406.269 406.401 405.455C406.753 404.61 407.27 403.843 407.923 403.201C408.439 402.67 409.056 402.248 409.739 401.959C410.421 401.671 411.154 401.522 411.894 401.522C412.635 401.522 413.368 401.671 414.05 401.959C414.732 402.248 415.35 402.67 415.866 403.201L417.631 401.435L420.979 404.785L419.244 406.49C419.731 407.099 420.187 407.678 420.583 408.287C420.936 408.797 421.261 409.326 421.557 409.87L418.3 413.129C417.937 412.239 417.478 411.392 416.931 410.601C416.478 409.912 415.958 409.27 415.379 408.683C414.844 408.127 414.228 407.655 413.553 407.282C413.352 407.102 413.092 407.003 412.823 407.003C412.553 407.003 412.293 407.102 412.092 407.282C411.91 407.461 411.789 407.692 411.745 407.943C411.701 408.195 411.738 408.453 411.849 408.683C412.232 409.612 412.69 410.508 413.218 411.363C413.928 412.494 414.433 413.741 414.709 415.047C414.904 415.967 414.808 416.925 414.435 417.788C414.046 418.557 413.52 419.248 412.883 419.828C412.002 420.792 410.778 421.371 409.475 421.442C407.988 421.476 406.529 421.039 405.306 420.194L403.48 422.051L400.223 418.58Z"
            fill="#FF8400"
        />
        <path
            d="M410.541 425.189L424.388 411.334L428.344 415.262L424.723 425.738L435.192 422.144L439.118 425.92L425.24 439.807L421.223 435.756L428.101 428.966L421.314 431.31L419.154 429.148L421.467 422.327L414.558 429.27L410.541 425.189Z"
            fill="#FF8400"
        />
        <path
            d="M433.426 448.516C432.236 447.381 431.293 446.012 430.656 444.496C430.06 443.132 429.91 441.614 430.227 440.159C430.544 438.705 431.312 437.387 432.421 436.395L440.943 427.869L444.99 432.01L436.895 440.111C436.481 440.389 436.141 440.765 435.907 441.206C435.672 441.647 435.549 442.139 435.549 442.638C435.549 443.138 435.672 443.629 435.907 444.07C436.141 444.511 436.481 444.887 436.895 445.166C437.533 445.891 438.428 446.339 439.391 446.414C440.376 446.337 441.293 445.878 441.947 445.135L450.042 437.035L454.029 441.146L445.508 449.673C444.477 450.792 443.128 451.568 441.643 451.896C440.241 452.162 438.792 452.014 437.473 451.469C435.934 450.776 434.556 449.77 433.426 448.516Z"
            fill="#FF8400"
        />
        <path
            d="M442.01 456.676L455.887 442.82L466.295 453.326L463.252 456.371L456.892 450.007L454.487 452.412L460.057 457.955L457.013 461L451.474 455.458L446.057 460.848L442.01 456.676Z"
            fill="#FF8400"
        />
        <path
            d="M222.933 422.389L215.768 409.971L213.201 412.026L210.813 407.886L217.961 402.141L227.893 419.399L222.933 422.389Z"
            fill="#FF8400"
        />
        <path
            d="M235.658 414.987L225.885 397.96L230.098 395.526L241.694 400.809L236.509 391.822L241.467 388.957L251.256 405.925L247.007 408.38L235.469 403.113L240.632 412.063L235.658 414.987Z"
            fill="#FF8400"
        />
        <path
            d="M253.619 404.635L243.808 387.63L256.557 380.264L258.709 383.993L250.918 388.494L252.617 391.44L259.41 387.491L261.561 391.22L254.747 395.132L258.577 401.77L253.619 404.635Z"
            fill="#FF8400"
        />
        <path
            d="M271.393 394.39L263.734 381.114L258.978 383.812L256.827 380.083L271.298 371.697L273.45 375.426L268.678 378.183L276.359 391.496L271.393 394.39Z"
            fill="#FF8400"
        />
        <defs>
            <linearGradient
                id="paint0_linear_1706_45"
                x1={540.984}
                y1={103.016}
                x2={78.3946}
                y2={565.573}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FEEC5D" />
                <stop offset={0.2} stopColor="#FD8B06" />
                <stop offset={0.27} stopColor="#FD8F09" />
                <stop offset={0.33} stopColor="#FD9A14" />
                <stop offset={0.39} stopColor="#FDAE25" />
                <stop offset={0.45} stopColor="#FEC93E" />
                <stop offset={0.51} stopColor="#FEEC5D" />
                <stop offset={0.52} stopColor="#BCAA5A" />
                <stop offset={0.59} stopColor="#FEEC5D" />
                <stop offset={0.84} stopColor="#FD8B06" />
                <stop offset={0.94} stopColor="#FECD41" />
                <stop offset={1} stopColor="#FEEC5D" />
            </linearGradient>
            <radialGradient
                id="paint1_radial_1706_45"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(322.032 321.968) scale(317.018 317.018)"
            >
                <stop stopColor="#737575" />
                <stop offset={0.85} stopColor="#0B0A08" />
                <stop offset={1} stopColor="#2D2D2D" />
            </radialGradient>
            <radialGradient
                id="paint2_radial_1706_45"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(322.038 321.967) scale(292.879 292.879)"
            >
                <stop stopColor="#737575" />
                <stop offset={0.85} stopColor="#0B0A08" />
                <stop offset={1} stopColor="#737575" />
            </radialGradient>
            <linearGradient
                id="paint3_linear_1706_45"
                x1={73.9766}
                y1={321.967}
                x2={570.091}
                y2={321.967}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FEEC5D" />
                <stop offset={0.2} stopColor="#FD8B06" />
                <stop offset={0.27} stopColor="#FD8F09" />
                <stop offset={0.33} stopColor="#FD9A14" />
                <stop offset={0.39} stopColor="#FDAE25" />
                <stop offset={0.45} stopColor="#FEC93E" />
                <stop offset={0.51} stopColor="#FEEC5D" />
                <stop offset={0.52} stopColor="#BCAA5A" />
                <stop offset={0.59} stopColor="#FEEC5D" />
                <stop offset={0.84} stopColor="#FD8B06" />
                <stop offset={0.94} stopColor="#FECD41" />
                <stop offset={1} stopColor="#FEEC5D" />
            </linearGradient>
            <radialGradient
                id="paint4_radial_1706_45"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(322.224 320.134) scale(314.639 314.639)"
            >
                <stop stopColor="#FDDF22" />
                <stop offset={0.85} stopColor="#FD8403" />
                <stop offset={1} stopColor="#FDDF22" />
            </radialGradient>
            <radialGradient
                id="paint5_radial_1706_45"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(322.247 320.134) scale(314.64 314.639)"
            >
                <stop stopColor="#FDDF22" />
                <stop offset={0.85} stopColor="#FD8403" />
                <stop offset={1} stopColor="#FDDF22" />
            </radialGradient>
            <radialGradient
                id="paint6_radial_1706_45"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(322.028 320.137) scale(315.153 315.154)"
            >
                <stop stopColor="#FDDF22" />
                <stop offset={0.85} stopColor="#FD8403" />
                <stop offset={1} stopColor="#FDDF22" />
            </radialGradient>
            <radialGradient
                id="paint7_radial_1706_45"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(321.995 320.136) scale(314.736 314.736)"
            >
                <stop stopColor="#FDDF22" />
                <stop offset={0.85} stopColor="#FD8403" />
                <stop offset={1} stopColor="#FDDF22" />
            </radialGradient>
            <radialGradient
                id="paint8_radial_1706_45"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(278.883 593.616) rotate(-169.028) scale(15.1124 15.1124)"
            >
                <stop stopColor="#FDDF22" />
                <stop offset={0.85} stopColor="#FD8403" />
                <stop offset={1} stopColor="#FDDF22" />
            </radialGradient>
            <radialGradient
                id="paint9_radial_1706_45"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(377.02 614.95) rotate(168.69) scale(15.1389 15.1389)"
            >
                <stop stopColor="#FDDF22" />
                <stop offset={0.85} stopColor="#FD8403" />
                <stop offset={1} stopColor="#FDDF22" />
            </radialGradient>
            <radialGradient
                id="paint10_radial_1706_45"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(161.869 77.9656) rotate(-34.0076) scale(15.1579 15.1579)"
            >
                <stop stopColor="#FDDF22" />
                <stop offset={0.85} stopColor="#FD8403" />
                <stop offset={1} stopColor="#FDDF22" />
            </radialGradient>
            <radialGradient
                id="paint11_radial_1706_45"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(89.5173 147.564) rotate(-56.4696) scale(15.0737 15.0737)"
            >
                <stop stopColor="#FDDF22" />
                <stop offset={0.85} stopColor="#FD8403" />
                <stop offset={1} stopColor="#FDDF22" />
            </radialGradient>
            <radialGradient
                id="paint12_radial_1706_45"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(46.9908 259.707) rotate(-79.0278) scale(15.1125 15.1124)"
            >
                <stop stopColor="#FDDF22" />
                <stop offset={0.85} stopColor="#FD8403" />
                <stop offset={1} stopColor="#FDDF22" />
            </radialGradient>
            <radialGradient
                id="paint13_radial_1706_45"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(28.9843 376.885) rotate(-101.31) scale(15.139 15.1389)"
            >
                <stop stopColor="#FDDF22" />
                <stop offset={0.85} stopColor="#FD8403" />
                <stop offset={1} stopColor="#FDDF22" />
            </radialGradient>
            <radialGradient
                id="paint14_radial_1706_45"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(92.0442 491.844) rotate(-124.007) scale(15.1579 15.1579)"
            >
                <stop stopColor="#FDDF22" />
                <stop offset={0.85} stopColor="#FD8403" />
                <stop offset={1} stopColor="#FDDF22" />
            </radialGradient>
            <radialGradient
                id="paint15_radial_1706_45"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(165.141 538.759) rotate(-146.469) scale(15.0737 15.0737)"
            >
                <stop stopColor="#FDDF22" />
                <stop offset={0.85} stopColor="#FD8403" />
                <stop offset={1} stopColor="#FDDF22" />
            </radialGradient>
            <radialGradient
                id="paint16_radial_1706_45"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(505.936 561.621) rotate(145.992) scale(15.1579 15.1579)"
            >
                <stop stopColor="#FDDF22" />
                <stop offset={0.85} stopColor="#FD8403" />
                <stop offset={1} stopColor="#FDDF22" />
            </radialGradient>
            <radialGradient
                id="paint17_radial_1706_45"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(556.307 463.209) rotate(123.53) scale(15.0737 15.0737)"
            >
                <stop stopColor="#FDDF22" />
                <stop offset={0.85} stopColor="#FD8403" />
                <stop offset={1} stopColor="#FDDF22" />
            </radialGradient>
            <radialGradient
                id="paint18_radial_1706_45"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(612.77 361.745) rotate(100.972) scale(15.1125 15.1124)"
            >
                <stop stopColor="#FDDF22" />
                <stop offset={0.85} stopColor="#FD8403" />
                <stop offset={1} stopColor="#FDDF22" />
            </radialGradient>
            <radialGradient
                id="paint19_radial_1706_45"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(615.1 266.9) rotate(78.69) scale(15.1389 15.139)"
            >
                <stop stopColor="#FDDF22" />
                <stop offset={0.85} stopColor="#FD8403" />
                <stop offset={1} stopColor="#FDDF22" />
            </radialGradient>
            <radialGradient
                id="paint20_radial_1706_45"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(575.714 147.747) rotate(55.9926) scale(15.1579 15.1579)"
            >
                <stop stopColor="#FDDF22" />
                <stop offset={0.85} stopColor="#FD8403" />
                <stop offset={1} stopColor="#FDDF22" />
            </radialGradient>
            <radialGradient
                id="paint21_radial_1706_45"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(480.743 71.9936) rotate(33.5305) scale(15.0737 15.0737)"
            >
                <stop stopColor="#FDDF22" />
                <stop offset={0.85} stopColor="#FD8403" />
                <stop offset={1} stopColor="#FDDF22" />
            </radialGradient>
            <radialGradient
                id="paint22_radial_1706_45"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(380.909 27.8626) rotate(10.9722) scale(15.1124 15.1124)"
            >
                <stop stopColor="#F5D919" />
                <stop offset={0.85} stopColor="#F16922" />
                <stop offset={1} stopColor="#F5D919" />
            </radialGradient>
            <radialGradient
                id="paint23_radial_1706_45"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(267.039 28.8049) rotate(-11.31) scale(15.1389 15.139)"
            >
                <stop stopColor="#F5D919" />
                <stop offset={0.85} stopColor="#F16922" />
                <stop offset={1} stopColor="#F5D919" />
            </radialGradient>
            <linearGradient
                id="paint24_linear_1706_45"
                x1={307.994}
                y1={307.954}
                x2={340.811}
                y2={340.771}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FEEC5D" />
                <stop offset={0.2} stopColor="#FD8B06" />
                <stop offset={0.27} stopColor="#FD8F09" />
                <stop offset={0.33} stopColor="#FD9A14" />
                <stop offset={0.39} stopColor="#FDAE25" />
                <stop offset={0.45} stopColor="#FEC93E" />
                <stop offset={0.51} stopColor="#FEEC5D" />
                <stop offset={0.52} stopColor="#BCAA5A" />
                <stop offset={0.59} stopColor="#FEEC5D" />
                <stop offset={0.84} stopColor="#FD8B06" />
                <stop offset={0.94} stopColor="#FECD41" />
                <stop offset={1} stopColor="#FEEC5D" />
            </linearGradient>
            <radialGradient
                id="paint25_radial_1706_45"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(322.015 321.969) scale(17.2925 17.2925)"
            >
                <stop stopColor="#737575" />
                <stop offset={0.85} stopColor="#0B0908" />
                <stop offset={1} stopColor="#2D2D2D" />
            </radialGradient>
        </defs>
    </svg>,
    'DailyWheelCenter',
)
