import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { APIEndpoint } from 'shared/types'
import { API_URL } from '../../../config'
import { RootState } from 'stores/store'

export const selectCharacter = createAsyncThunk(
    'user/me/select-character',
    async (identifier: string, { rejectWithValue, getState }) => {
        // check if token is expired
        const token = (getState() as RootState).auth.token
        if (!token) {
            return rejectWithValue('Token is not found')
        }
        try {
            const { data: selectedCharacter } = await axios.patch(
                API_URL + APIEndpoint.ME_SELECT_CHARACTER,
                {
                    identifier,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            )
            return selectedCharacter
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    },
)
