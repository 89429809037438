import React from 'react'
import { createSvgIcon } from '@mui/material'

export const QuestsIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
        <path
            d="M40.8502 14.7538V20.1888L30.9689 30.3529C30.823 30.49 30.7053 30.6558 30.6229 30.8405C30.5406 31.0253 30.4952 31.2252 30.4895 31.4284C30.4838 31.6316 30.5179 31.8339 30.5898 32.0232C30.6617 32.2125 30.7699 32.385 30.908 32.5305C31.046 32.6759 31.2111 32.7913 31.3934 32.8698C31.5758 32.9482 31.7716 32.9882 31.9692 32.9872C32.1668 32.9862 32.3622 32.9444 32.5438 32.8641C32.7254 32.7839 32.8894 32.6669 33.0261 32.5201L33.053 32.4932L42.9359 22.3314H48.2196C48.6103 22.3314 48.985 22.1717 49.2613 21.8875L58.1024 12.7947C58.3083 12.5827 58.4485 12.3127 58.5053 12.0188C58.562 11.7249 58.5329 11.4202 58.4214 11.1434C58.3099 10.8665 58.1212 10.6299 57.879 10.4633C57.6368 10.2967 57.3521 10.2076 57.0607 10.2074H52.6402V5.66095C52.6399 5.3613 52.5533 5.06845 52.3914 4.81938C52.2294 4.57031 51.9993 4.3762 51.7301 4.26157C51.4609 4.14693 51.1647 4.11691 50.8789 4.1753C50.5931 4.23369 50.3306 4.37787 50.1245 4.58962L41.2833 13.6825C41.1462 13.823 41.0374 13.99 40.9631 14.1738C40.8888 14.3576 40.8504 14.5547 40.8502 14.7538Z"
            fill="black"
        />
        <path
            d="M58.0146 17.1708L53.6033 21.7077C58.8347 33.9845 53.3956 48.2979 41.4586 53.6735C29.5217 59.0491 15.6045 53.4599 10.3777 41.1831C5.15089 28.9064 10.5854 14.593 22.5224 9.21736C25.5079 7.87185 28.7321 7.17719 31.9917 7.17719C35.2512 7.17719 38.4754 7.87185 41.461 9.21736L45.8723 4.68044C31.502 -3.19786 13.6451 2.39378 5.98484 17.1708C-1.67538 31.9479 3.76148 50.3124 18.1295 58.1914C32.4975 66.0705 50.3536 60.4773 58.0146 45.7011C60.2907 41.3106 61.4812 36.4115 61.4812 31.4359C61.4812 26.4604 60.2907 21.5613 58.0146 17.1708Z"
            fill="black"
        />
        <path
            d="M37.9051 14.7542C37.9037 14.16 38.0164 13.5714 38.2366 13.022C38.4568 12.4727 38.7802 11.9734 39.1883 11.5529C28.4808 7.43848 16.5577 13.0309 12.5572 24.0425C8.5567 35.054 13.9928 47.3166 24.6996 51.431C35.4063 55.5454 47.3302 49.9545 51.3307 38.9429C53.0765 34.1381 53.0765 28.8465 51.3307 24.0417C50.922 24.4614 50.4368 24.794 49.903 25.0206C49.3691 25.2472 48.797 25.3633 48.2195 25.3622H45.5015C48.7796 33.0475 45.3776 42.0106 37.9051 45.3804C30.4326 48.7503 21.7169 45.2522 18.4411 37.567C15.1652 29.8818 18.5649 20.9179 26.0374 17.5488C27.9084 16.7051 29.9291 16.2694 31.972 16.2694C34.0149 16.2694 36.0357 16.7051 37.9066 17.5488L37.9051 14.7542Z"
            fill="black"
        />
        <path
            d="M36.6134 20.2612C34.0958 19.1638 31.2855 19.0011 28.6641 19.8009C26.0427 20.6007 23.7733 22.3132 22.2449 24.6451C20.7165 26.9769 20.0242 29.7829 20.2867 32.5821C20.5491 35.3813 21.75 37.9995 23.6834 39.988C25.6169 41.9765 28.1626 43.2116 30.8844 43.4815C33.6061 43.7514 36.3343 43.0394 38.6016 41.4675C40.8689 39.8956 42.5341 37.5616 43.3118 34.8655C44.0894 32.1695 43.9312 29.2792 42.8642 26.69L35.1363 34.6387C34.3044 35.4665 33.1901 35.9258 32.0326 35.9178C30.8751 35.9099 29.7668 35.4353 28.9458 34.596C28.1249 33.7567 27.6567 32.6198 27.642 31.4294C27.6272 30.239 28.0671 29.0902 28.867 28.2297L28.8847 28.2107L36.6134 20.2612Z"
            fill="black"
        />
        <path
            d="M31.9974 35.1919C31.2838 35.1969 30.5969 34.9127 30.0856 34.4006C29.8426 34.1599 29.6501 33.8707 29.5198 33.5506C29.3896 33.2305 29.3244 32.8865 29.3283 32.5395C29.3322 32.1926 29.4051 31.8501 29.5425 31.5332C29.6798 31.2163 29.8788 30.9317 30.1272 30.6969L40.1392 20.8603V15.885C40.1396 15.5371 40.2092 15.193 40.3439 14.8738C40.4786 14.5547 40.6755 14.2674 40.9224 14.0296L50.1782 4.93752C50.6898 4.44421 51.3648 4.16968 52.0661 4.16968C52.7674 4.16968 53.4425 4.44421 53.9541 4.93752C54.2006 5.17545 54.3971 5.46272 54.5317 5.78167C54.6662 6.10061 54.7359 6.44449 54.7365 6.79217V10.2309H58.2369C58.9392 10.2264 59.6158 10.5017 60.1249 10.9991C60.3714 11.237 60.5679 11.5243 60.7022 11.8433C60.8365 12.1623 60.9058 12.5062 60.9058 12.8538C60.9058 13.2014 60.8365 13.5453 60.7022 13.8643C60.5679 14.1833 60.3714 14.4706 60.1249 14.7084L50.8691 23.8013C50.3602 24.2992 49.6835 24.5749 48.9811 24.5704H43.9159L33.9046 34.4046L33.8615 34.4473C33.3545 34.9298 32.6881 35.1959 31.9974 35.1919Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9728 63.9998C26.7514 63.9956 21.6043 62.7276 16.9478 60.2984C13.1365 58.3021 9.83764 55.6301 7.14114 52.3623C4.55769 49.242 2.60386 45.6241 1.39347 41.7195C0.165329 37.7757 -0.257661 33.6136 0.151006 29.494C0.559673 25.3743 1.79119 21.3858 3.76839 17.7784C9.34142 7.50977 20.1697 1.13086 32.0267 1.13086C37.2481 1.13444 42.3953 2.40245 47.0517 4.83224L48.3949 5.53881L42.158 11.6645L41.4525 11.3615C38.4543 10.0756 35.2381 9.4136 31.9897 9.4136C28.7413 9.4136 25.5251 10.0756 22.527 11.3615C10.6193 16.4863 5.17627 30.1722 10.3931 41.8706C14.1543 50.2988 22.6339 55.744 32.0028 55.7433C35.247 55.7463 38.459 55.0824 41.4502 53.7905C53.3579 48.6657 58.8016 34.9798 53.5848 23.2822L53.2771 22.5891L59.5148 16.4578L60.2311 17.7776C65.2563 27.035 65.2563 38.0917 60.2311 47.3499C54.6573 57.6208 43.8305 63.9998 31.9751 63.9998H31.9728Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9382 55.0218C29.2199 55.0218 26.5218 54.54 23.9626 53.5976C18.2588 51.5096 13.7266 47.3556 11.2002 41.9135C8.67368 36.4714 8.45673 30.3876 10.5847 24.7849C12.2434 20.4252 15.1822 16.7397 19.0835 14.1278C22.1377 12.0964 25.6058 10.8159 29.2189 10.3855C32.832 9.95524 36.4929 10.3867 39.9177 11.6465L41.5587 12.2487L40.3208 13.4671C40.0012 13.7775 39.7468 14.1518 39.5732 14.5672C39.3996 14.9825 39.3105 15.4299 39.3115 15.882V20.3746L37.7305 19.6934C35.9056 18.9104 33.9479 18.5073 31.9705 18.5073C29.9932 18.5073 28.0355 18.9104 26.2106 19.6934C18.9689 22.814 15.6607 31.1401 18.8373 38.2541C21.1245 43.3773 26.2829 46.6878 31.979 46.6878C33.9539 46.6906 35.9095 46.2871 37.7305 45.501C41.2386 43.9889 43.9382 41.2283 45.3307 37.7208C46.0199 36.0054 46.3557 34.1617 46.3167 32.3061C46.2778 30.4505 45.865 28.6234 45.1045 26.9402L44.4121 25.3878H48.9835L48.9927 26.4956V25.3878C49.9042 25.3937 50.7825 25.0368 51.4438 24.3917L52.684 23.1756L53.2994 24.7881C55.222 29.8263 55.222 35.4257 53.2994 40.464C51.6361 44.8205 48.6973 48.5029 44.7968 51.1179C40.9701 53.6756 36.5021 55.0322 31.9382 55.0218Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0105 45.7797C30.2111 45.7813 28.4286 45.4242 26.7613 44.7282C23.4532 43.349 20.8837 40.7862 19.5342 37.5113C18.8667 35.9098 18.5293 34.1834 18.5435 32.441C18.5576 30.6985 18.923 28.9781 19.6166 27.3883C21.7315 22.4866 26.5998 19.3178 32.0189 19.3178C33.8183 19.3162 35.6009 19.6733 37.2681 20.3693L38.8067 21.0102L29.5209 30.1386C29.198 30.451 28.941 30.8283 28.7656 31.247C28.5903 31.6658 28.5005 32.1171 28.5017 32.573C28.5029 33.0289 28.5952 33.4797 28.7728 33.8975C28.9503 34.3152 29.2094 34.691 29.534 35.0015C30.206 35.6468 31.0918 36.0046 32.0111 36.0021C32.9305 35.9996 33.8144 35.637 34.4831 34.9881L43.7605 25.8739L44.4128 27.3875C45.1129 29.0131 45.4745 30.7713 45.4745 32.5491C45.4745 34.327 45.1129 36.0852 44.4128 37.7107C42.298 42.6132 37.4304 45.7797 32.0105 45.7797Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9974 35.1687C31.2842 35.1738 30.5978 34.8895 30.0871 34.3775C29.8444 34.1366 29.6521 33.8473 29.5221 33.5273C29.392 33.2072 29.327 32.8632 29.3309 32.5164C29.3347 32.1695 29.4075 31.8272 29.5447 31.5103C29.6819 31.1934 29.8806 30.9088 30.1287 30.6737L40.13 20.8379V15.8619C40.1304 15.5137 40.2003 15.1693 40.3351 14.8499C40.4699 14.5305 40.6669 14.2429 40.9139 14.0048L50.1589 4.91439C50.6701 4.42106 51.3448 4.14648 52.0457 4.14648C52.7467 4.14648 53.4214 4.42106 53.9325 4.91439C54.1789 5.15254 54.3753 5.43998 54.5097 5.75905C54.6441 6.07812 54.7137 6.42209 54.7141 6.76982V10.2069H58.2107C58.9123 10.2026 59.5884 10.4776 60.0971 10.9744C60.3435 11.2125 60.5398 11.4998 60.674 11.8189C60.8082 12.1379 60.8774 12.4819 60.8774 12.8295C60.8774 13.1771 60.8082 13.521 60.674 13.8401C60.5398 14.1591 60.3435 14.4465 60.0971 14.6845L50.8513 23.7781C50.3432 24.2762 49.6669 24.5519 48.9649 24.5472H43.9051L33.9038 34.3814L33.8607 34.4242C33.3543 34.9066 32.6884 35.1728 31.9981 35.1687H31.9974Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9728 63.9788C26.7556 63.9745 21.6128 62.7065 16.9608 60.2774C13.1534 58.2812 9.85762 55.6092 7.16342 52.3414C4.58225 49.2206 2.63029 45.6031 1.42115 41.6993C0.194257 37.7554 -0.228277 33.5935 0.179966 29.4739C0.588209 25.3544 1.81845 21.3657 3.79376 17.7574C9.36217 7.48883 20.1805 1.10913 32.0259 1.10913C37.2432 1.11291 42.3862 2.38125 47.0378 4.8113L48.3803 5.51471L42.1487 11.6436L41.444 11.3405C38.4493 10.055 35.2364 9.39312 31.9912 9.39312C28.7461 9.39312 25.5332 10.055 22.5385 11.3405C10.6385 16.4654 5.2001 30.1513 10.4123 41.8497C14.1674 50.2779 22.6446 55.7231 32.002 55.7223C35.2437 55.7253 38.4532 55.0614 41.4417 53.7696C53.3386 48.6447 58.7755 34.9588 53.5656 23.2636L53.2578 22.5713L59.4894 16.4416L60.2049 17.7614C65.2255 27.0188 65.2255 38.0755 60.2049 47.3337C54.6365 57.6031 43.8181 63.982 31.9705 63.982L31.9728 63.9788Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9381 55.0004C29.222 55.0005 26.5263 54.5187 23.9694 53.5762C18.271 51.4826 13.7427 47.3326 11.2185 41.8905C8.69435 36.4484 8.4774 30.3647 10.6031 24.7619C12.2602 20.4023 15.196 16.7167 19.0942 14.1048C22.1448 12.0735 25.6097 10.793 29.2197 10.3626C32.8297 9.93228 36.4876 10.3638 39.9091 11.6235L41.5494 12.2257L40.3123 13.4442C39.9921 13.7543 39.737 14.1285 39.5627 14.5438C39.3885 14.9591 39.2988 15.4067 39.2991 15.859V20.3516L37.7196 19.6704C35.8965 18.8875 33.9404 18.4843 31.9647 18.4843C29.9889 18.4843 28.0329 18.8875 26.2097 19.6704C18.978 22.791 15.6699 31.1171 18.8426 38.2311C21.1283 43.3543 26.282 46.6648 31.9727 46.6648C33.9462 46.6675 35.9002 46.264 37.7196 45.478C41.2239 43.9659 43.9212 41.2053 45.3121 37.6978C46.0009 35.9824 46.3365 34.1387 46.2977 32.2832C46.2589 30.4277 45.8466 28.6006 45.0867 26.9173L44.3943 25.3649H48.9618L48.9711 26.4726V25.3649C49.8828 25.3724 50.7619 25.0162 51.4237 24.3711L52.6631 23.155L53.2786 24.7675C55.1995 29.806 55.1995 35.4048 53.2786 40.4434C51.6222 44.8031 48.6857 48.4886 44.7882 51.1005C40.9644 53.6572 36.499 55.0125 31.9381 55.0004Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0105 45.7575C30.2129 45.7588 28.4321 45.4014 26.7668 44.7052C23.4587 43.3261 20.8937 40.7633 19.5459 37.4884C18.879 35.8866 18.5422 34.1601 18.5568 32.4178C18.5714 30.6754 18.9369 28.9551 19.6305 27.3654C21.7431 22.4637 26.6068 19.2948 32.0213 19.2948C33.8192 19.2932 35.6002 19.6503 37.2658 20.3464L38.8045 20.9872L29.5233 30.1165C29.2008 30.4291 28.9441 30.8064 28.769 31.2252C28.594 31.6439 28.5043 32.0951 28.5055 32.5508C28.5067 33.0066 28.5989 33.4573 28.7762 33.875C28.9535 34.2927 29.2122 34.6686 29.5364 34.9794C30.2082 35.6235 31.0931 35.9806 32.0116 35.9781C32.93 35.9756 33.8132 35.6137 34.4816 34.9659L43.7497 25.8525L44.4021 27.3662C45.1014 28.9919 45.4627 30.75 45.4627 32.5278C45.4627 34.3055 45.1014 36.0636 44.4021 37.6894C42.2888 42.591 37.4251 45.7575 32.0105 45.7575Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9974 35.1471C31.2847 35.1521 30.5989 34.8678 30.0887 34.3559C29.8462 34.1149 29.654 33.8256 29.5241 33.5055C29.3942 33.1854 29.3292 32.8415 29.3332 32.4946C29.3371 32.1478 29.41 31.8055 29.5472 31.4887C29.6844 31.1718 29.883 30.8872 30.131 30.6522L40.1246 20.8163V15.8403C40.125 15.4926 40.1946 15.1486 40.329 14.8295C40.4634 14.5104 40.6599 14.223 40.9063 13.9849L50.1452 4.89281C50.6556 4.39958 51.3296 4.125 52.03 4.125C52.7304 4.125 53.4044 4.39958 53.9149 4.89281C54.1615 5.1307 54.3581 5.41795 54.4928 5.73689C54.6275 6.05583 54.6973 6.39973 54.698 6.74746V10.1862H58.1923C58.8932 10.1819 59.5685 10.457 60.0764 10.9536C60.3227 11.1917 60.5189 11.4791 60.653 11.7982C60.7871 12.1172 60.8563 12.4611 60.8563 12.8087C60.8563 13.1563 60.7871 13.5002 60.653 13.8192C60.5189 14.1382 60.3227 14.4256 60.0764 14.6637L50.8352 23.7566C50.3276 24.2544 49.6518 24.5302 48.9504 24.5256H43.8943L33.9015 34.3599L33.8584 34.4018C33.3528 34.8846 32.6874 35.1511 31.9974 35.1471Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9727 63.9562C26.7586 63.9527 21.6189 62.6846 16.9707 60.2549C13.2275 58.3065 9.9006 55.6083 7.18564 52.3188C4.60627 49.1974 2.65616 45.5796 1.44874 41.6759C0.222861 37.7321 -0.199347 33.5706 0.208478 29.4514C0.616303 25.3322 1.8454 21.3437 3.81905 17.7349C9.38438 7.46625 20.1904 1.08655 32.0258 1.08655C37.2399 1.09004 42.3797 2.35844 47.0277 4.78872L48.3686 5.49213L42.1371 11.621L41.4331 11.3156C38.4413 10.0302 35.2312 9.36836 31.9888 9.36836C28.7465 9.36836 25.5364 10.0302 22.5445 11.3156C10.6576 16.4428 5.22384 30.1287 10.4314 41.8271C14.1835 50.2553 22.6507 55.7037 32.0027 55.7037C35.2416 55.7067 38.4484 55.0428 41.4339 53.7509C53.32 48.6222 58.7538 34.9362 53.5439 23.2379L53.2362 22.5455L59.4678 16.4159L60.1825 17.7356C65.1985 26.9931 65.1985 38.0497 60.1825 47.3079C54.6164 57.5773 43.8088 63.9562 31.975 63.9562H31.9727Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9382 54.9788C29.2246 54.9787 26.5314 54.4969 23.9772 53.5546C18.2841 51.461 13.7589 47.311 11.2378 41.8689C8.71675 36.4268 8.49903 30.343 10.6224 24.7403C12.2818 20.3822 15.2153 16.6966 19.1096 14.0856C22.1568 12.0543 25.6187 10.7737 29.2257 10.3433C32.8328 9.91298 36.4877 10.3445 39.9062 11.6042L41.5425 12.2056L40.3116 13.4241C39.9915 13.7342 39.7365 14.1084 39.5624 14.5238C39.3883 14.9391 39.2987 15.3866 39.2992 15.8389V20.3315L37.7213 19.6503C35.9 18.8674 33.9456 18.4642 31.9717 18.4642C29.9977 18.4642 28.0434 18.8674 26.2221 19.6503C18.9912 22.7709 15.693 31.097 18.8588 38.211C21.1422 43.3342 26.2913 46.6447 31.9774 46.6447C33.9491 46.6475 35.9014 46.2439 37.719 45.4579C41.2209 43.9458 43.9151 41.1852 45.3053 37.6777C45.9942 35.9618 46.33 34.1177 46.2915 32.2617C46.2529 30.4058 45.8409 28.5781 45.0814 26.894L44.389 25.3416H48.9519L48.9612 26.4493V25.3416C49.8714 25.3473 50.7485 24.9903 51.4084 24.3455L52.6463 23.1293L53.2617 24.7419C55.1812 29.7807 55.1812 35.3789 53.2617 40.4177C51.6015 44.7774 48.6681 48.463 44.7737 51.0748C40.9554 53.6322 36.4948 54.9889 31.9382 54.9788Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0104 45.7345C30.2144 45.7365 28.4352 45.3799 26.7713 44.6846C23.4632 43.3055 20.9036 40.7427 19.5573 37.4678C18.8909 35.8659 18.5543 34.1395 18.5687 32.3972C18.5831 30.655 18.9483 28.9347 19.6411 27.3447C21.7522 22.4431 26.612 19.2742 32.0212 19.2742C33.8175 19.2727 35.5971 19.6298 37.2611 20.3257L38.7998 20.9666L29.5255 30.0951C29.2034 30.408 28.947 30.7855 28.7723 31.2043C28.5975 31.623 28.5081 32.0742 28.5096 32.5299C28.511 32.9857 28.6033 33.4362 28.7807 33.8538C28.958 34.2714 29.2168 34.6472 29.5409 34.958C30.2114 35.6028 31.0958 35.9605 32.0138 35.958C32.9318 35.9555 33.8143 35.593 34.4815 34.9445L43.7389 25.8311L44.3889 27.3447C45.0879 28.9706 45.4489 30.7287 45.4489 32.5063C45.4489 34.284 45.0879 36.0421 44.3889 37.6679C42.2794 42.5696 37.4204 45.7345 32.0104 45.7345Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9975 35.1251C31.2853 35.1301 30.5999 34.8457 30.0903 34.3339C29.848 34.0928 29.656 33.8034 29.5262 33.4833C29.3964 33.1633 29.3315 32.8194 29.3355 32.4726C29.3395 32.1259 29.4122 31.7836 29.5493 31.4668C29.6863 31.15 29.8849 30.8653 30.1326 30.6301L40.1178 20.7935V15.8183C40.1181 15.4707 40.1874 15.1268 40.3216 14.8077C40.4557 14.4886 40.6518 14.2011 40.8979 13.9628L50.1298 4.87078C50.6393 4.37711 51.3128 4.10217 52.0128 4.10217C52.7127 4.10217 53.3863 4.37711 53.8957 4.87078C54.1417 5.10915 54.3378 5.39667 54.4719 5.71572C54.606 6.03476 54.6754 6.37862 54.6758 6.72622V10.1649H58.1663C58.867 10.1604 59.5421 10.4355 60.0496 10.9324C60.2955 11.1707 60.4915 11.4581 60.6254 11.7772C60.7593 12.0962 60.8284 12.44 60.8284 12.7874C60.8284 13.1349 60.7593 13.4787 60.6254 13.7977C60.4915 14.1168 60.2955 14.4042 60.0496 14.6425L50.8184 23.7377C50.3114 24.2356 49.6361 24.5113 48.9351 24.5068H43.8837L33.8993 34.3402L33.857 34.3821C33.352 34.8649 32.687 35.1315 31.9975 35.1275V35.1251Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9731 63.9326C26.7645 63.9281 21.6305 62.66 16.9881 60.2312C13.2464 58.2833 9.92134 55.5856 7.20834 52.2967C4.63093 49.1747 2.68244 45.557 1.47606 41.6538C0.251307 37.7098 -0.170377 33.5486 0.237296 29.4296C0.644969 25.3106 1.87326 21.3221 3.8456 17.7128C9.40324 7.44415 20.2015 1.06445 32.0261 1.06445C37.2348 1.06847 42.369 2.33692 47.0112 4.76663L48.3513 5.47003L42.1282 11.5989L41.4251 11.2959C38.4362 10.0105 35.2288 9.34863 31.9892 9.34863C28.7496 9.34863 25.5422 10.0105 22.5534 11.2959C10.6772 16.4207 5.24886 30.1066 10.4518 41.805C14.2 50.2324 22.6596 55.6784 32.0031 55.6776C35.239 55.6808 38.4427 55.0171 41.4251 53.7257C53.3012 48.6001 58.7296 34.9141 53.5266 23.2158L53.2189 22.5234L59.4412 16.3938L60.1552 17.7135C65.1666 26.971 65.1666 38.0276 60.1552 47.2858C54.5967 57.5552 43.7984 63.9326 31.9754 63.9326H31.9731Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9384 54.9557C29.227 54.9556 26.5361 54.4738 23.9843 53.5315C18.2958 51.4379 13.776 47.2879 11.2565 41.845C8.7369 36.4022 8.51995 30.32 10.6456 24.7173C12.2997 20.3576 15.2308 16.672 19.1213 14.0602C22.1651 12.029 25.6238 10.7484 29.2279 10.318C32.832 9.88757 36.4841 10.3191 39.8994 11.5788L41.5357 12.1857L40.3048 13.4042C39.985 13.7145 39.7302 14.0888 39.5562 14.5041C39.3823 14.9194 39.2927 15.3669 39.2931 15.8191V20.3117L37.7168 19.6304C35.8974 18.8475 33.9448 18.4444 31.9726 18.4444C30.0004 18.4444 28.0478 18.8475 26.2284 19.6304C19.0013 22.7479 15.704 31.074 18.8713 38.188C21.1554 43.3112 26.2969 46.6217 31.9776 46.6217C33.9476 46.6244 35.898 46.2209 37.7137 45.4349C41.2126 43.9228 43.9045 41.1622 45.2932 37.6547C45.9807 35.9389 46.3156 34.0955 46.2768 32.2402C46.238 30.3849 45.8264 28.5579 45.0677 26.8741L44.3753 25.3218H48.9344L48.9436 26.4295V25.3202C49.8539 25.3258 50.7309 24.9681 51.3901 24.3224L52.6264 23.1047L53.2365 24.7188C55.1544 29.758 55.1544 35.3556 53.2365 40.3947C51.5832 44.7544 48.6521 48.44 44.7615 51.0518C40.9476 53.6087 36.4911 54.9655 31.9384 54.9557Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0105 45.7132C30.2169 45.7143 28.4402 45.3571 26.779 44.6616C23.4763 43.2825 20.9168 40.7197 19.5712 37.4448C18.9055 35.8428 18.5693 34.1164 18.5839 32.3742C18.5985 30.632 18.9634 28.9118 19.6558 27.3218C21.7646 22.4201 26.6198 19.2512 32.0243 19.2512C33.8192 19.2498 35.5972 19.6069 37.2596 20.3028L38.7983 20.9437L29.5279 30.0721C29.2058 30.385 28.9494 30.7624 28.7746 31.1811C28.5998 31.5998 28.5103 32.0508 28.5115 32.5065C28.5127 32.9621 28.6047 33.4127 28.7818 33.8304C28.9588 34.248 29.2172 34.624 29.5409 34.935C30.2105 35.5799 31.0942 35.9376 32.0115 35.9351C32.9289 35.9326 33.8107 35.5701 34.477 34.9216L43.7282 25.8082L44.3798 27.3218C45.078 28.9478 45.4386 30.7058 45.4386 32.4834C45.4386 34.2609 45.078 36.0189 44.3798 37.645C42.2703 42.5459 37.4158 45.7132 32.0105 45.7132Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9973 35.103C31.2857 35.1079 30.6011 34.8235 30.0924 34.3118C29.8502 34.0706 29.6584 33.7812 29.5286 33.4612C29.3989 33.1412 29.334 32.7974 29.3379 32.4507C29.3418 32.104 29.4144 31.7618 29.5512 31.445C29.6881 31.1281 29.8864 30.8433 30.1339 30.608L40.1098 20.7714V15.7962C40.1101 15.4485 40.1795 15.1047 40.3136 14.7856C40.4478 14.4665 40.6439 14.179 40.8899 13.9407L50.1127 4.84867C50.6215 4.35504 51.2946 4.08008 51.9941 4.08008C52.6936 4.08008 53.3666 4.35504 53.8755 4.84867C54.1212 5.08717 54.3171 5.37473 54.4511 5.69376C54.5851 6.01279 54.6544 6.35659 54.6548 6.70411V10.1428H58.1429C58.8429 10.1384 59.5173 10.4135 60.0239 10.9103C60.2698 11.1486 60.4656 11.4361 60.5995 11.7551C60.7334 12.0741 60.8024 12.4179 60.8024 12.7653C60.8024 13.1128 60.7334 13.4566 60.5995 13.7756C60.4656 14.0946 60.2698 14.3821 60.0239 14.6204L50.8012 23.7124C50.295 24.2101 49.6205 24.4858 48.9202 24.4815H43.8734L33.8975 34.3157L33.8544 34.3577C33.3504 34.8405 32.6861 35.1071 31.9973 35.103Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9726 63.9121C26.7685 63.9086 21.6387 62.6415 17.0007 60.2138C13.2616 58.2641 9.93956 55.5647 7.23018 52.2746C4.65468 49.1521 2.70803 45.5345 1.50329 41.6318C0.279496 37.6877 -0.141953 33.5268 0.265168 29.4081C0.672289 25.2893 1.89927 21.3007 3.86975 17.6907C9.42277 7.42206 20.2119 1.04236 32.0257 1.04236C37.2305 1.04772 42.3604 2.31759 46.9976 4.74849L48.3363 5.45189L42.1193 11.5768L41.4161 11.2738C38.4302 9.98848 35.2256 9.32659 31.9888 9.32659C28.7519 9.32659 25.5473 9.98848 22.5614 11.2738C10.696 16.3986 5.27224 30.0845 10.4706 41.7829C14.2157 50.2103 22.6668 55.6563 32.0026 55.6556C35.2361 55.6586 38.4373 54.9945 41.4169 53.7028C53.2815 48.578 58.7053 34.8944 53.5069 23.1937L53.1992 22.5014L59.4161 16.3717L60.1301 17.6915C65.1361 26.9489 65.1361 38.0055 60.1301 47.2637C54.5763 57.5331 43.788 63.9121 31.9726 63.9121Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9381 54.9335C29.229 54.9334 26.5404 54.4515 23.9909 53.5093C18.3086 51.4173 13.7919 47.2673 11.2747 41.8244C8.75741 36.3815 8.53815 30.2993 10.6646 24.6966C12.3171 20.3369 15.2452 16.6513 19.1326 14.0395C22.1729 12.0084 25.6286 10.7277 29.2297 10.2972C32.8309 9.8668 36.48 10.2983 39.8922 11.5582L41.527 12.1603L40.2961 13.3788C39.9764 13.6892 39.7219 14.0635 39.5482 14.4788C39.3745 14.8941 39.2853 15.3415 39.286 15.7936V20.2863L37.7104 19.605C35.8927 18.8221 33.9418 18.4189 31.9712 18.4189C30.0006 18.4189 28.0496 18.8221 26.232 19.605C19.0164 22.7256 15.7206 31.0518 18.8849 38.1657C21.1644 43.2889 26.3043 46.5995 31.9796 46.5995C33.9479 46.6022 35.8966 46.1986 37.7104 45.4126C41.2062 43.9006 43.8958 41.14 45.2837 37.6324C45.9702 35.9165 46.3045 34.0731 46.2657 32.218C46.227 30.3628 45.8159 28.5359 45.0582 26.8519L44.3658 25.2995H48.9203L48.9295 26.4072V25.2995C49.8384 25.3052 50.714 24.9481 51.3721 24.3033L52.6077 23.0872L53.2193 24.6997C55.1349 29.7393 55.1349 35.336 53.2193 40.3756C51.5668 44.7353 48.6387 48.4209 44.7513 51.0327C40.9408 53.5887 36.4874 54.9444 31.9381 54.9335Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0104 45.6921C30.2168 45.6937 28.44 45.3366 26.779 44.6405C23.4809 43.2614 20.9244 40.6986 19.5796 37.4269C18.9137 35.8241 18.5773 34.0969 18.5918 32.354C18.6062 30.611 18.9711 28.89 19.6635 27.2991C21.7707 22.3974 26.6213 19.2285 32.0212 19.2285C33.8148 19.2268 35.5916 19.584 37.2527 20.2801L38.7913 20.921L29.5301 30.051C29.2082 30.364 28.9521 30.7414 28.7774 31.1601C28.6027 31.5788 28.5132 32.0298 28.5145 32.4854C28.5157 32.9409 28.6076 33.3914 28.7845 33.8091C28.9614 34.2268 29.2196 34.6027 29.5432 34.9139C30.2118 35.5588 31.0948 35.9166 32.0115 35.9141C32.9282 35.9116 33.8093 35.549 34.4746 34.9004L43.7181 25.787L44.3682 27.2991C45.0656 28.9253 45.4258 30.6833 45.4258 32.4607C45.4258 34.2381 45.0656 35.996 44.3682 37.6223C42.261 42.5248 37.4104 45.6921 32.0104 45.6921Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9975 35.0814C31.2863 35.0865 30.6021 34.8021 30.0941 34.2902C29.8521 34.0489 29.6605 33.7595 29.5309 33.4395C29.4013 33.1195 29.3364 32.7757 29.3403 32.4291C29.3442 32.0825 29.4167 31.7404 29.5535 31.4235C29.6902 31.1067 29.8883 30.8219 30.1357 30.5864L40.1031 20.7498V15.7746C40.1033 15.427 40.1725 15.0832 40.3065 14.7641C40.4405 14.445 40.6365 14.1575 40.8824 13.9191L50.0967 4.82789C50.6047 4.33416 51.2772 4.05908 51.9762 4.05908C52.6751 4.05908 53.3476 4.33416 53.8557 4.82789C54.1014 5.06639 54.2973 5.35395 54.4313 5.67298C54.5653 5.99201 54.6346 6.3358 54.635 6.68333V10.122H58.1193C58.8188 10.1175 59.4927 10.3927 59.9988 10.8895C60.2443 11.128 60.4399 11.4156 60.5735 11.7345C60.7072 12.0535 60.7762 12.3972 60.7762 12.7446C60.7762 13.0919 60.7072 13.4356 60.5735 13.7546C60.4399 14.0735 60.2443 14.3611 59.9988 14.5996L50.7845 23.6909C50.279 24.1887 49.6049 24.4645 48.905 24.4599H43.8628L33.8962 34.2941L33.8531 34.3361C33.3493 34.8184 32.6857 35.085 31.9975 35.0814Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9728 63.8898C26.7729 63.8852 21.6476 62.6169 17.0147 60.1885C13.2786 58.2391 9.95951 55.5408 7.25268 52.2524C4.67937 49.1292 2.7346 45.5117 1.53117 41.6095C0.308271 37.6655 -0.112913 33.5049 0.293791 29.3863C0.700496 25.2677 1.9264 21.2791 3.89532 17.6684C9.44373 7.39984 20.2228 1.02014 32.0259 1.02014C37.2259 1.0244 42.3513 2.29296 46.9839 4.72231L48.3218 5.42572L42.1102 11.553L41.4078 11.25C38.4249 9.96469 35.2231 9.30277 31.9889 9.30277C28.7548 9.30277 25.553 9.96469 22.57 11.25C10.7154 16.3795 5.29627 30.0623 10.49 41.7607C14.2313 50.1881 22.6754 55.6341 32.0028 55.6333C35.2335 55.6362 38.432 54.9722 41.4086 53.6806C53.2632 48.5558 58.6824 34.8698 53.4871 23.1715L53.1794 22.4791L59.3909 16.3495L60.1041 17.6692C65.1048 26.9267 65.1048 37.9833 60.1041 47.2415C54.5557 57.5109 43.7774 63.8898 31.9751 63.8898H31.9728Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9389 54.9122C29.2332 54.9118 26.5478 54.43 24.0018 53.488C18.3233 51.3944 13.8112 47.2444 11.2963 41.8015C8.78135 36.3587 8.56516 30.2765 10.687 24.6737C12.338 20.3141 15.2637 16.6285 19.1496 14.0166C22.1863 11.9857 25.6388 10.7049 29.2368 10.2744C32.8349 9.84396 36.481 10.2755 39.89 11.5353L41.524 12.1375L40.2931 13.356C39.9736 13.6664 39.7192 14.0408 39.5455 14.4561C39.3718 14.8713 39.2824 15.3187 39.283 15.7708V20.2642L37.7097 19.583C35.8938 18.8001 33.9445 18.3969 31.9755 18.3969C30.0065 18.3969 28.0572 18.8001 26.2413 19.583C19.0319 22.7036 15.7392 31.0297 18.9003 38.1437C21.1776 43.2669 26.3128 46.5774 31.9836 46.5774C33.9503 46.5801 35.8975 46.1765 37.7097 45.3906C41.2017 43.8785 43.8889 41.1179 45.2753 37.6104C45.9614 35.893 46.2949 34.0483 46.2551 32.1921C46.2152 30.3358 45.8028 28.5081 45.0437 26.8235L44.3513 25.2711H48.905L48.9134 26.3788V25.2711C49.8218 25.2766 50.6969 24.9195 51.3545 24.275L52.5854 23.0588L53.1963 24.6714C55.1104 29.7113 55.1104 35.3074 53.1963 40.3473C51.5453 44.7069 48.6195 48.3925 44.7337 51.0043C40.9301 53.5613 36.483 54.9195 31.9389 54.9122Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0106 45.6686C30.219 45.6701 28.4442 45.3129 26.7853 44.6171C23.4887 43.238 20.9338 40.6752 19.5905 37.4002C18.9261 35.7979 18.5906 34.0717 18.6051 32.3297C18.6195 30.5878 18.9835 28.8677 19.6744 27.2772C21.78 22.3755 26.626 19.2067 32.0213 19.2067C33.813 19.2055 35.5877 19.5629 37.2466 20.259L38.7807 20.8999L29.5326 30.0276C29.211 30.3407 28.9551 30.7182 28.7806 31.1369C28.6061 31.5555 28.5167 32.0064 28.518 32.4619C28.5192 32.9174 28.611 33.3678 28.7877 33.7854C28.9644 34.2031 29.2224 34.5791 29.5456 34.8905C30.2133 35.5343 31.0948 35.8915 32.0101 35.889C32.9253 35.8865 33.8051 35.5245 34.4693 34.877L43.7013 25.7636L44.3514 27.2772C45.0481 28.9037 45.4079 30.6615 45.4079 32.4388C45.4079 34.2161 45.0481 35.974 44.3514 37.6004C42.2519 42.5013 37.4059 45.6686 32.0106 45.6686Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9974 35.0587C31.2868 35.0634 30.6032 34.7789 30.0957 34.2674C29.8539 34.026 29.6626 33.7365 29.5331 33.4165C29.4037 33.0966 29.339 32.7529 29.3429 32.4064C29.3468 32.0599 29.4192 31.7178 29.5558 31.401C29.6923 31.0842 29.8901 30.7993 30.1372 30.5637L40.0954 20.731V15.7518C40.0957 15.4044 40.1649 15.0606 40.2988 14.7416C40.4327 14.4226 40.6284 14.135 40.874 13.8964L50.0821 4.80434C50.5897 4.31077 51.2618 4.03577 51.9604 4.03577C52.659 4.03577 53.3311 4.31077 53.8387 4.80434C54.0841 5.04304 54.2797 5.33067 54.4134 5.64968C54.5471 5.96869 54.6162 6.31239 54.6165 6.65978V10.0985H58.0977C58.7968 10.0939 59.4703 10.3691 59.9757 10.866C60.2211 11.1045 60.4166 11.3921 60.5502 11.7111C60.6838 12.03 60.7527 12.3737 60.7527 12.721C60.7527 13.0683 60.6838 13.412 60.5502 13.7309C60.4166 14.0499 60.2211 14.3375 59.9757 14.5761L50.7675 23.6681C50.2624 24.1655 49.5889 24.4414 48.8896 24.4372H43.8505L33.8946 34.2714L33.8515 34.3133C33.3484 34.7956 32.6852 35.0622 31.9974 35.0587Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.973 63.8677C26.7775 63.8629 21.6568 62.5946 17.0288 60.1664C13.2954 58.2167 9.9791 55.5183 7.27521 52.2303C4.70382 49.1065 2.76066 45.4891 1.55832 41.5874C0.33723 37.6429 -0.0830039 33.4825 0.323671 29.3641C0.730346 25.2457 1.95526 21.2571 3.92247 17.6456C9.46164 7.37775 20.2323 0.998047 32.0261 0.998047C37.222 1.00242 42.3431 2.27103 46.9711 4.70022L48.3066 5.40363L42.1012 11.5325L41.3996 11.2295C38.4196 9.94419 35.2206 9.28224 31.9892 9.28224C28.7577 9.28224 25.5587 9.94419 22.5787 11.2295C10.7349 16.3543 5.32034 30.0402 10.5095 41.7386C14.2476 50.1652 22.6841 55.612 32.003 55.6112C35.231 55.6141 38.4267 54.9501 41.4004 53.6585C53.2481 48.5337 58.6588 34.8477 53.4696 23.1494L53.1619 22.457L59.3681 16.3274L60.0835 17.6456C65.0842 26.903 65.0842 37.9596 60.0835 47.2179C54.5405 57.4872 43.7738 63.8662 31.9799 63.8662L31.973 63.8677Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9389 54.89C29.2344 54.89 26.5502 54.4081 24.0056 53.4658C18.3326 51.3722 13.8243 47.2222 11.3117 41.7793C8.79903 36.3364 8.58285 30.2542 10.7023 24.6515C12.3518 20.2918 15.2745 16.6063 19.155 13.9944C22.1883 11.9636 25.6376 10.6827 29.2327 10.2522C32.8279 9.82172 36.4711 10.2533 39.8769 11.5131L41.5094 12.1153L40.2785 13.3338C39.9595 13.6442 39.7056 14.0184 39.5323 14.4334C39.359 14.8483 39.2699 15.2954 39.2706 15.747V20.2396L37.6958 19.5615C35.8818 18.7787 33.9343 18.3755 31.967 18.3755C29.9998 18.3755 28.0522 18.7787 26.2382 19.5615C19.035 22.6822 15.7453 31.0083 18.9042 38.1223C21.1791 43.2455 26.3097 46.556 31.9751 46.556C33.94 46.5583 35.8854 46.1547 37.6958 45.3691C41.1855 43.8571 43.8697 41.0965 45.2553 37.589C45.9406 35.8727 46.2744 34.0295 46.2356 32.1745C46.1968 30.3196 45.7863 28.4928 45.0298 26.8084L44.3374 25.256H48.8842L48.8934 26.3638V25.256C49.8009 25.2615 50.675 24.9043 51.3314 24.2599L52.5624 23.0437L53.1724 24.6563C55.085 29.6965 55.085 35.292 53.1724 40.3322C51.523 44.6919 48.6003 48.3774 44.7198 50.9893C40.9203 53.543 36.4778 54.8988 31.9389 54.89Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0105 45.6471C30.2204 45.6486 28.4471 45.2914 26.7898 44.5956C23.4963 43.2165 20.9429 40.6537 19.602 37.3788C18.938 35.7763 18.6028 34.0501 18.6172 32.3083C18.6316 30.5664 18.9954 28.8464 19.6858 27.2557C21.7892 22.3541 26.6313 19.1852 32.0212 19.1852C33.8114 19.184 35.5846 19.5414 37.2419 20.2375L38.7744 20.8784L29.5348 30.0061C29.2134 30.3194 28.9578 30.6969 28.7834 31.1155C28.609 31.5341 28.5197 31.9849 28.5208 32.4403C28.522 32.8956 28.6136 33.346 28.7901 33.7636C28.9666 34.1813 29.2242 34.5574 29.5471 34.869C30.2142 35.5139 31.096 35.8718 32.0115 35.8693C32.9271 35.8668 33.807 35.5041 34.4708 34.8555L43.6966 25.7421L44.3459 27.2557C45.0422 28.8823 45.4018 30.6401 45.4018 32.4173C45.4018 34.1946 45.0422 35.9524 44.3459 37.5789C42.2426 42.4798 37.4012 45.6471 32.0105 45.6471Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9976 35.0367C31.2875 35.041 30.6045 34.7566 30.0973 34.2455C29.8558 34.004 29.6646 33.7144 29.5353 33.3945C29.406 33.0745 29.3414 32.7309 29.3453 32.3845C29.3491 32.038 29.4215 31.696 29.5579 31.3792C29.6944 31.0624 29.892 30.7775 30.1389 30.5418L40.0886 20.7052V15.7299C40.0888 15.3825 40.1579 15.0388 40.2916 14.7197C40.4253 14.4007 40.6209 14.1131 40.8664 13.8745L50.0638 4.78162C50.5708 4.28832 51.2422 4.01343 51.9401 4.01343C52.6381 4.01343 53.3095 4.28832 53.8165 4.78162C54.062 5.02032 54.2575 5.30795 54.3912 5.62696C54.525 5.94597 54.5941 6.28967 54.5943 6.63706V10.0758H58.0725C58.7711 10.0716 59.4439 10.3468 59.9489 10.8432C60.194 11.082 60.3892 11.3696 60.5227 11.6886C60.6561 12.0075 60.7249 12.3511 60.7249 12.6983C60.7249 13.0455 60.6561 13.3891 60.5227 13.708C60.3892 14.027 60.194 14.3146 59.9489 14.5533L50.7508 23.6462C50.2463 24.1437 49.5733 24.4196 48.8744 24.4152H43.8414L33.8924 34.2495C33.8786 34.2637 33.8647 34.2779 33.8501 34.2914C33.3475 34.7735 32.6849 35.0401 31.9976 35.0367Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9729 63.8443C26.7816 63.8394 21.6651 62.571 17.0417 60.1429C13.3114 58.1926 9.99821 55.4943 7.29738 52.2069C4.72791 49.0826 2.7866 45.4652 1.58587 41.564C0.365204 37.6198 -0.055213 33.4598 0.350651 29.3417C0.756514 25.2236 1.98002 21.235 3.94541 17.6229C9.48381 7.35827 20.2437 0.974609 32.0259 0.974609C37.2174 0.979016 42.334 2.24769 46.9571 4.67678L48.2919 5.38019L42.0919 11.5091L41.3887 11.206C38.4117 9.92076 35.2154 9.25878 31.9867 9.25878C28.758 9.25878 25.5617 9.92076 22.5847 11.206C10.754 16.3308 5.34482 30.0168 10.5286 41.7151C14.2637 50.1425 22.694 55.5886 32.0029 55.5878C35.2271 55.5902 38.4189 54.9261 41.3887 53.635C53.2241 48.5102 58.634 34.8243 53.4495 23.1259L53.1417 22.4336L59.3418 16.3039L60.0534 17.6237C65.0464 26.8811 65.0464 37.9378 60.0534 47.196C54.5142 57.4654 43.7559 63.8443 31.9729 63.8443Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9391 54.867C29.2376 54.8665 26.5566 54.3846 24.015 53.4428C18.345 51.3515 13.8414 47.2007 11.3311 41.7578C8.82073 36.315 8.60455 30.2328 10.7225 24.6301C12.3704 20.2704 15.2908 16.5848 19.1675 13.973C22.1973 11.9422 25.6436 10.6613 29.2357 10.2307C32.8279 9.80022 36.4682 10.2318 39.8709 11.4916L41.5019 12.0938L40.2709 13.3123C39.9522 13.6232 39.6984 13.9977 39.5251 14.4129C39.3518 14.8281 39.2627 15.2753 39.2631 15.7271V20.2197L37.696 19.5385C35.8838 18.7557 33.9381 18.3525 31.9726 18.3525C30.0071 18.3525 28.0613 18.7557 26.2491 19.5385C19.0521 22.6591 15.7655 30.9844 18.9213 38.0992C21.1916 43.2224 26.3176 46.5329 31.9776 46.5329C33.9407 46.5349 35.8843 46.1313 37.6929 45.3461C41.1795 43.834 43.8614 41.0734 45.2454 37.5659C45.9304 35.8496 46.264 34.0064 46.2253 32.1516C46.1867 30.2967 45.7766 28.4699 45.0208 26.7854L44.3284 25.233H48.8674L48.8767 26.3407V25.233C49.7836 25.2379 50.657 24.8808 51.3131 24.2368L52.5441 23.0207L53.1595 24.6301C55.0705 29.6705 55.0705 35.2655 53.1595 40.3059C51.5116 44.6656 48.5912 48.3512 44.7146 50.963C40.9178 53.5188 36.4768 54.8759 31.9391 54.867Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0103 45.6247C30.2217 45.6261 28.4499 45.2689 26.7942 44.5731C23.5038 43.194 20.9535 40.6312 19.6125 37.3563C18.9488 35.7539 18.6134 34.0279 18.6273 32.2861C18.6412 30.5444 19.0041 28.8243 19.6933 27.2333C21.7951 22.3316 26.6327 19.1627 32.018 19.1627C33.8067 19.162 35.5785 19.52 37.234 20.2166L38.765 20.8575L29.533 29.9836C29.2121 30.2971 28.9567 30.6748 28.7826 31.0934C28.6085 31.5119 28.5193 31.9627 28.5206 32.418C28.5218 32.8732 28.6134 33.3235 28.7897 33.7411C28.9661 34.1587 29.2235 34.5348 29.5461 34.8465C30.2122 35.4913 31.0932 35.8491 32.0079 35.8466C32.9226 35.8441 33.8017 35.4814 34.4644 34.8331L43.6864 25.7197L44.3349 27.2333C45.0305 28.8601 45.3897 30.6178 45.3897 32.3949C45.3897 34.172 45.0305 35.9297 44.3349 37.5565C42.2347 42.4574 37.3956 45.6247 32.0103 45.6247Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9976 35.0135C31.2881 35.0182 30.6057 34.7337 30.0997 34.2223C29.8582 33.9808 29.6669 33.6912 29.5377 33.3713C29.4084 33.0513 29.3437 32.7077 29.3476 32.3613C29.3515 32.0148 29.4238 31.6728 29.5603 31.356C29.6967 31.0392 29.8943 30.7543 30.1412 30.5185L40.0809 20.682V15.7067C40.0808 15.3594 40.1496 15.0157 40.2831 14.6967C40.4166 14.3776 40.6119 14.09 40.8572 13.8513L50.0476 4.75842C50.554 4.26515 51.225 3.99023 51.9225 3.99023C52.62 3.99023 53.2909 4.26515 53.7973 4.75842C54.0424 4.99731 54.2376 5.28501 54.3711 5.604C54.5045 5.923 54.5734 6.26659 54.5736 6.61386V10.0526H58.0486C58.7468 10.0482 59.4192 10.3234 59.9235 10.82C60.1684 11.0589 60.3635 11.3466 60.4968 11.6655C60.6301 11.9844 60.6988 12.3279 60.6988 12.6751C60.6988 13.0222 60.6301 13.3657 60.4968 13.6847C60.3635 14.0036 60.1684 14.2913 59.9235 14.5301L50.7339 23.623C50.2302 24.1203 49.558 24.3962 48.8598 24.392H43.8307L33.8909 34.2263C33.8771 34.2405 33.8632 34.2547 33.8486 34.2682C33.3466 34.7504 32.6844 35.017 31.9976 35.0135Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9727 63.8238C26.7858 63.8188 21.6738 62.5504 17.0554 60.1224C13.3276 58.1719 10.0172 55.4735 7.31951 52.1863C4.7523 49.0606 2.81313 45.4425 1.61416 41.5411C0.394868 37.5972 -0.0251012 33.438 0.380207 29.3206C0.785514 25.2032 2.00749 21.215 3.97062 17.6024C9.50441 7.33379 20.2543 0.950928 32.0258 0.950928C37.2133 0.956457 42.3257 2.22627 46.9439 4.65627L48.2771 5.35967L42.0809 11.4886L41.3808 11.1855C38.4068 9.90029 35.2132 9.2383 31.9873 9.2383C28.7614 9.2383 25.5679 9.90029 22.5938 11.1855C10.773 16.3103 5.36849 29.9963 10.5476 41.6946C14.2796 50.122 22.703 55.5681 32.0019 55.5673C35.2245 55.5702 38.4146 54.9061 41.3824 53.6145C53.2047 48.4897 58.6092 34.8038 53.4301 23.1054L53.1223 22.4131L59.317 16.2834L60.0278 17.6032C65.017 26.8606 65.017 37.9173 60.0278 47.1755C54.4948 57.4449 43.745 63.8238 31.975 63.8238H31.9727Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9389 54.8445C29.2391 54.8443 26.5598 54.3624 24.0202 53.4202C18.3572 51.3266 13.8574 47.1766 11.3494 41.7337C8.84134 36.2909 8.62516 30.2102 10.7416 24.6075C12.3849 20.2478 15.3083 16.5623 19.1788 13.9504C22.2056 11.9205 25.6489 10.6402 29.2381 10.2104C32.8273 9.7805 36.4645 10.2127 39.8638 11.4731L41.4932 12.0752L40.2623 13.2937C39.9436 13.6046 39.6899 13.9791 39.5169 14.3943C39.3439 14.8096 39.2551 15.2568 39.256 15.7085V20.2012L37.6866 19.5199C35.8761 18.7371 33.9318 18.3339 31.9678 18.3339C30.0038 18.3339 28.0595 18.7371 26.249 19.5199C19.0596 22.6405 15.7753 30.9659 18.928 38.0806C21.1991 43.2039 26.3205 46.5144 31.9759 46.5144C33.9375 46.5162 35.8795 46.1126 37.6866 45.3275C41.1693 43.8155 43.8489 41.0549 45.2322 37.5473C45.9164 35.8308 46.2496 33.9878 46.2109 32.133C46.1723 30.2783 45.7626 28.4516 45.0075 26.7668L44.319 25.2144H48.858L48.8673 26.3221V25.2144C49.7746 25.2178 50.6478 24.8584 51.3022 24.2119L52.5331 22.9958L53.1424 24.6075C55.051 29.6485 55.051 35.2425 53.1424 40.2834C51.4953 44.6431 48.578 48.3287 44.7044 50.9405C40.9114 53.4965 36.4735 54.8537 31.9389 54.8445Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0105 45.6026C30.2234 45.6039 28.4532 45.2467 26.799 44.551C23.5117 43.1719 20.9637 40.6091 19.6243 37.3342C18.9614 35.7314 18.6267 34.0054 18.6411 32.2637C18.6556 30.522 19.0188 28.8021 19.7081 27.2112C21.8076 22.3095 26.6406 19.1406 32.0213 19.1406C33.809 19.1397 35.5798 19.4977 37.2342 20.1945L38.7637 20.8354L29.5409 29.9615C29.2202 30.2752 28.965 30.6528 28.791 31.0714C28.617 31.49 28.5279 31.9407 28.5292 32.3959C28.5304 32.8511 28.6219 33.3012 28.7981 33.7188C28.9743 34.1364 29.2315 34.5126 29.554 34.8244C30.2186 35.4686 31.0979 35.8266 32.0112 35.8249C32.9244 35.8231 33.8024 35.4617 34.4646 34.8149L43.6758 25.6976L44.3244 27.2112C45.0192 28.8382 45.378 30.5958 45.378 32.3728C45.378 34.1498 45.0192 35.9074 44.3244 37.5344C42.2241 42.4353 37.3881 45.6026 32.0105 45.6026Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9974 34.9923C31.2884 34.997 30.6064 34.7124 30.101 34.201C29.8598 33.9593 29.6688 33.6697 29.5397 33.3498C29.4106 33.0299 29.3461 32.6864 29.35 32.34C29.3538 31.9937 29.4261 31.6517 29.5623 31.335C29.6986 31.0182 29.896 30.7332 30.1425 30.4973L40.0738 20.6607V15.6854C40.0739 15.3382 40.1427 14.9945 40.2762 14.6755C40.4097 14.3565 40.6049 14.0688 40.8501 13.83L50.0313 4.73716C50.5368 4.24379 51.2072 3.96875 51.9042 3.96875C52.6011 3.96875 53.2715 4.24379 53.7771 4.73716C54.0222 4.97604 54.2174 5.26375 54.3509 5.58274C54.4843 5.90173 54.5532 6.24533 54.5534 6.59259V10.0313H58.0254C58.7228 10.027 59.3945 10.3023 59.8979 10.7988C60.1426 11.0378 60.3375 11.3255 60.4707 11.6444C60.6039 11.9633 60.6725 12.3067 60.6725 12.6538C60.6725 13.0009 60.6039 13.3444 60.4707 13.6633C60.3375 13.9822 60.1426 14.2699 59.8979 14.5089L50.7167 23.6017C50.2137 24.099 49.5419 24.3749 48.8442 24.3708H43.8197L33.8892 34.205L33.8469 34.2469C33.346 34.728 32.6857 34.9945 32.0005 34.9923H31.9974Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9727 63.8013C26.7901 63.7963 21.6824 62.5278 17.0685 60.1C13.3437 58.1488 10.0365 55.4505 7.34187 52.1639C4.77628 49.0386 2.83844 45.4213 1.64037 41.521C0.422276 37.5764 0.00314003 33.4169 0.408829 29.2992C0.814519 25.1816 2.03643 21.1931 3.99914 17.5799C9.52446 7.31134 20.2651 0.931641 32.0258 0.931641C37.2079 0.936681 42.3149 2.20545 46.9277 4.63381L48.2602 5.33722L42.074 11.4661L41.374 11.1631C38.4026 9.87763 35.2116 9.21548 31.9881 9.21548C28.7646 9.21548 25.5736 9.87763 22.6023 11.1631C10.7923 16.2879 5.39239 29.9738 10.5669 41.6722C14.2958 50.0996 22.7092 55.5456 32.0027 55.5456C35.2225 55.5484 38.4099 54.8843 41.3747 53.5929C53.187 48.4681 58.5862 34.7821 53.4117 23.0838L53.1039 22.3914L59.2932 16.2618L60.0064 17.5807C64.9909 26.8382 64.9909 37.8948 60.0064 47.153C54.4749 57.4216 43.735 63.8013 31.9751 63.8013H31.9727Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.939 54.8232C29.2415 54.823 26.5645 54.3411 24.0272 53.3989C18.3696 51.3053 13.8721 47.1553 11.3679 41.7124C8.86375 36.2696 8.6468 30.1874 10.7609 24.5846C12.4057 20.2265 15.3207 16.5378 19.1905 13.9268C22.2135 11.8962 25.6535 10.6152 29.2398 10.1846C32.826 9.75406 36.4605 10.1857 39.857 11.4454L41.4841 12.0476L40.2532 13.2661C39.9347 13.5771 39.6812 13.9517 39.5082 14.3669C39.3351 14.7821 39.2462 15.2292 39.2469 15.6809V20.1775L37.679 19.4962C35.8704 18.7134 33.9279 18.3102 31.9656 18.3102C30.0033 18.3102 28.0608 18.7134 26.2521 19.4962C19.0728 22.6153 15.7916 30.9382 18.942 38.0593C21.2107 43.1825 26.3275 46.4931 31.9775 46.4931C33.9374 46.4948 35.8777 46.0912 37.6829 45.3062C41.1625 43.7942 43.8375 41.0336 45.2223 37.526C45.9061 35.8094 46.239 33.9665 46.2005 32.1118C46.162 30.2572 45.7528 28.4305 44.9984 26.7455L44.3122 25.186H48.8466L48.8558 26.2937V25.186C49.7614 25.191 50.6335 24.8338 51.2877 24.1898L52.5186 22.9737L53.1272 24.5862C55.0343 29.6274 55.0343 35.2209 53.1272 40.2621C51.4785 44.6218 48.5635 48.3074 44.6968 50.9192C40.907 53.4757 36.4713 54.833 31.939 54.8232Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0105 45.5811C30.2249 45.5824 28.4562 45.2252 26.8037 44.5296C23.5194 43.1504 20.9737 40.5876 19.635 37.3127C18.9729 35.7098 18.6387 33.9838 18.6531 32.2422C18.6675 30.5007 19.0303 28.7808 19.7189 27.1897C21.8169 22.288 26.6429 19.1191 32.0213 19.1191C33.8069 19.118 35.5756 19.4755 37.2281 20.1715L38.756 20.8124L29.5409 29.94C29.2205 30.2539 28.9655 30.6316 28.7918 31.0501C28.618 31.4687 28.529 31.9193 28.5302 32.3744C28.5314 32.8295 28.6229 33.2796 28.7989 33.6971C28.9749 34.1147 29.2319 34.4909 29.554 34.8029C30.2183 35.4476 31.0978 35.8056 32.0112 35.8031C32.9245 35.8005 33.8021 35.4378 34.4631 34.7895L43.6651 25.6761L44.3121 27.1897C45.0065 28.8168 45.3651 30.5744 45.3651 32.3513C45.3651 34.1282 45.0065 35.8858 44.3121 37.5129C42.2157 42.4114 37.3881 45.5811 32.0105 45.5811Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9974 34.9739C31.2889 34.9785 30.6075 34.694 30.1026 34.1827C29.8615 33.9408 29.6707 33.6511 29.5418 33.3312C29.4128 33.0112 29.3483 32.6676 29.3522 32.3213C29.356 31.975 29.4282 31.633 29.5644 31.3162C29.7005 30.9994 29.8977 30.7143 30.1441 30.4782L40.0685 20.6432V15.6679C40.0678 15.3201 40.1362 14.9759 40.2692 14.6562C40.4023 14.3365 40.5973 14.0481 40.8424 13.8085L50.0151 4.71567C50.5201 4.22233 51.19 3.94727 51.8865 3.94727C52.583 3.94727 53.2529 4.22233 53.7579 4.71567C54.0025 4.95438 54.1974 5.24173 54.3307 5.56028C54.464 5.87883 54.533 6.22193 54.5334 6.56873V10.0074H58.0062C58.7031 10.0033 59.3742 10.2786 59.8772 10.7749C60.1216 11.0141 60.3162 11.3018 60.4492 11.6207C60.5822 11.9396 60.6507 12.283 60.6507 12.63C60.6507 12.9769 60.5822 13.3203 60.4492 13.6392C60.3162 13.9581 60.1216 14.2458 59.8772 14.485L50.6975 23.5802C50.1951 24.0775 49.5238 24.3535 48.8265 24.3493H43.8097L33.8877 34.1827L33.8446 34.2247C33.3443 34.7066 32.6836 34.9733 31.9982 34.97L31.9974 34.9739Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9728 63.7781C26.7945 63.773 21.6913 62.5045 17.0824 60.0768C13.3604 58.1257 10.056 55.4279 7.3642 52.1423C4.80195 49.016 2.86705 45.3988 1.67116 41.4994C0.453872 37.5549 0.0347545 33.3958 0.439626 29.2784C0.844497 25.1609 2.06478 21.1723 4.02531 17.5583C9.54448 7.28815 20.2759 0.908447 32.0258 0.908447C37.2045 0.913131 42.3081 2.18197 46.917 4.61062L48.2479 5.31403L42.0648 11.4429L41.3655 11.1399C38.3974 9.8547 35.2094 9.19266 31.9889 9.19266C28.7684 9.19266 25.5805 9.8547 22.6123 11.1399C10.8108 16.2647 5.41626 29.9506 10.5862 41.649C14.3082 50.0764 22.7177 55.5224 32.0028 55.5216C35.2197 55.5236 38.404 54.8586 41.3655 53.5665C53.167 48.4441 58.5616 34.7581 53.3901 23.0598L53.0824 22.3674L59.2655 16.2378L59.9756 17.5575C64.9555 26.815 64.9547 37.8708 59.9756 47.1298C54.4518 57.3984 43.722 63.7781 31.972 63.7781H31.9728Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9389 54.8005C29.2436 54.8002 26.5689 54.3183 24.034 53.3762C18.3856 51.2842 13.8896 47.1342 11.3847 41.6913C8.87977 36.2484 8.66589 30.1662 10.7777 24.5635C12.4218 20.2039 15.3337 16.5183 19.2003 13.9064C22.22 11.8756 25.6573 10.5944 29.2409 10.1637C32.8245 9.73313 36.4564 10.165 39.8499 11.4251L41.4755 12.0273L40.2492 13.2458C39.931 13.5569 39.6777 13.9316 39.505 14.3468C39.3322 14.7619 39.2436 15.209 39.2444 15.6606V20.1532L37.6781 19.472C35.8712 18.6892 33.9303 18.2859 31.9697 18.2859C30.009 18.2859 28.0681 18.6892 26.2612 19.472C19.0842 22.5926 15.806 30.9179 18.9526 38.0327C21.219 43.1559 26.332 46.4664 31.9766 46.4664C33.935 46.4683 35.8738 46.0646 37.6773 45.2796C41.1539 43.7675 43.832 41.0069 45.2091 37.4994C45.8922 35.7826 46.2247 33.9397 46.1861 32.0851C46.1474 30.2305 45.7384 28.404 44.9844 26.7189L44.2982 25.1617H48.8288L48.838 26.2694V25.1617C49.7428 25.1665 50.6141 24.8093 51.2675 24.1655L52.4985 22.9494L53.1062 24.562C55.0118 29.6035 55.0118 35.1963 53.1062 40.2378C51.4629 44.5975 48.5503 48.2831 44.6844 50.8949C40.899 53.4513 36.4674 54.8092 31.9389 54.8005Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0106 45.5584C30.2265 45.5598 28.4593 45.2025 26.8084 44.5068C23.5272 43.1277 20.983 40.5649 19.6467 37.29C18.985 35.687 18.6509 33.9611 18.6652 32.2196C18.6795 30.4782 19.0418 28.7583 19.7298 27.167C21.8262 22.2653 26.6537 19.0964 32.0213 19.0964C33.8055 19.0953 35.5727 19.4528 37.2236 20.1488L38.7499 20.7897L29.541 29.9173C29.2207 30.2313 28.966 30.609 28.7923 31.0276C28.6187 31.4461 28.5298 31.8966 28.531 32.3517C28.5322 32.8067 28.6236 33.2567 28.7995 33.6743C28.9754 34.0918 29.2321 34.4681 29.5541 34.7802C30.2177 35.4248 31.0966 35.7828 32.0093 35.7803C32.9221 35.7778 33.7991 35.415 34.4594 34.7668L43.6528 25.6534L44.2999 27.167C44.9936 28.7943 45.3517 30.5518 45.3517 32.3286C45.3517 34.1054 44.9936 35.8629 44.2999 37.4902C42.2065 42.3911 37.3813 45.5584 32.0106 45.5584Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9975 34.9475C31.2894 34.9519 30.6086 34.6673 30.1041 34.1563C29.8634 33.9142 29.6729 33.6244 29.5441 33.3045C29.4153 32.9846 29.3509 32.6411 29.3548 32.2949C29.3587 31.9486 29.4308 31.6068 29.5667 31.29C29.7026 30.9732 29.8996 30.688 30.1457 30.4517L40.0593 20.6167V15.6407C40.0594 15.2935 40.1282 14.9501 40.2613 14.6311C40.3945 14.3122 40.5893 14.0244 40.834 13.7852L49.999 4.6924C50.5033 4.19914 51.1726 3.92407 51.8685 3.92407C52.5644 3.92407 53.2337 4.19914 53.738 4.6924C53.9828 4.93142 54.1777 5.21919 54.3109 5.53817C54.4441 5.85715 54.5127 6.20068 54.5127 6.54784V9.98574H57.9747C58.6712 9.98219 59.3418 10.2578 59.8441 10.754C60.0885 10.9932 60.283 11.281 60.4159 11.5999C60.5488 11.9188 60.6174 12.2621 60.6174 12.6091C60.6174 12.956 60.5488 13.2993 60.4159 13.6182C60.283 13.9371 60.0885 14.2249 59.8441 14.4641L50.6837 23.5569C50.1818 24.0541 49.511 24.33 48.8143 24.326H43.799L33.8862 34.1602L33.8431 34.2022C33.3429 34.6843 32.6822 34.951 31.9967 34.9475H31.9975Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9726 63.7569C26.7986 63.7517 21.6996 62.4831 17.0953 60.0555C13.3755 58.1036 10.0734 55.4052 7.38407 52.1195C4.82177 48.9932 2.88686 45.376 1.69103 41.4766C0.475313 37.5318 0.0570835 33.3729 0.462065 29.2557C0.867046 25.1385 2.08668 21.15 4.04595 17.5355C9.56436 7.26691 20.2857 0.887207 32.0257 0.887207C37.2 0.892187 42.2991 2.16135 46.903 4.59017L48.2355 5.29279L42.0578 11.4217L41.3592 11.1186C38.394 9.83346 35.2088 9.17138 31.9911 9.17138C28.7734 9.17138 25.5881 9.83346 22.623 11.1186C10.8299 16.2434 5.43998 29.9294 10.6053 41.6277C14.3273 50.0551 22.7261 55.5012 32.0026 55.5004C35.2169 55.5032 38.3988 54.839 41.3577 53.5476C53.1477 48.4244 58.5376 34.7361 53.3723 23.0385L53.0646 22.3462L59.2423 16.2165L59.9516 17.5363C64.9269 26.7937 64.9269 37.8496 59.9516 47.1086C54.434 57.3772 43.7134 63.7569 31.9749 63.7569H31.9726Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9388 54.7791C29.2461 54.7788 26.5739 54.2969 24.0417 53.3548C18.394 51.2612 13.9057 47.1112 11.4046 41.6683C8.90356 36.2255 8.69199 30.1472 10.7984 24.5421C12.4409 20.1824 15.3505 16.4969 19.2133 13.885C22.2293 11.8547 25.6631 10.5736 29.2433 10.1429C32.8235 9.71228 36.4521 10.1439 39.8422 11.4037L41.4655 12.0059L40.2407 13.2244C39.9222 13.5354 39.6687 13.91 39.4956 14.3252C39.3226 14.7404 39.2337 15.1875 39.2344 15.6392V20.1318L37.6696 19.4506C35.8646 18.6678 33.9255 18.2645 31.9665 18.2645C30.0076 18.2645 28.0685 18.6678 26.2635 19.4506C19.0926 22.5712 15.8175 30.8965 18.9618 38.0113C21.2321 43.1345 26.3373 46.4466 31.9773 46.4466C33.9332 46.448 35.8694 46.0444 37.6703 45.2598C41.1439 43.7477 43.8165 40.9871 45.1952 37.4796C45.8778 35.7627 46.2102 33.9199 46.1717 32.0654C46.1332 30.2109 45.7246 28.3844 44.9713 26.699L44.285 25.1466H48.811L48.8202 26.2544V25.1466C49.7244 25.1515 50.595 24.7943 51.2475 24.1505L52.4784 22.9344L53.0823 24.5421C54.9864 29.584 54.9864 35.1762 53.0823 40.218C51.4429 44.5777 48.5333 48.2664 44.6705 50.8775C40.8895 53.4319 36.4626 54.7885 31.9388 54.7791Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0105 45.5368C30.2279 45.5381 28.4622 45.1809 26.8129 44.4852C23.5348 43.1061 20.9929 40.5433 19.6582 37.2684C18.9972 35.6652 18.6635 33.9393 18.6779 32.1979C18.6924 30.4566 19.0545 28.7368 19.742 27.1454C21.8354 22.2437 26.656 19.0748 32.0221 19.0748C33.8047 19.0738 35.5704 19.4313 37.2196 20.1272L38.7445 20.7681L29.5456 29.8957C29.2255 30.2098 28.9709 30.5876 28.7974 31.0061C28.6239 31.4246 28.535 31.8751 28.5362 32.3301C28.5375 32.785 28.6287 33.235 28.8045 33.6525C28.9803 34.07 29.2369 34.4464 29.5587 34.7586C30.2213 35.4032 31.0995 35.7613 32.0116 35.7588C32.9237 35.7563 33.8 35.3934 34.4593 34.7452L43.6443 25.6318L44.2906 27.1454C44.9839 28.7728 45.3419 30.5302 45.3419 32.307C45.3419 34.0837 44.9839 35.8412 44.2906 37.4686C42.1972 42.3695 37.3766 45.5368 32.0105 45.5368Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9975 34.9257C31.2899 34.9301 30.6095 34.6455 30.1057 34.1344C29.8652 33.8922 29.6748 33.6024 29.5462 33.2825C29.4175 32.9626 29.3532 32.6192 29.357 32.273C29.3609 31.9268 29.4329 31.5851 29.5688 31.2683C29.7046 30.9515 29.9014 30.6663 30.1473 30.4299L40.0524 20.5949V15.6188C40.0524 15.2717 40.121 14.9283 40.254 14.6093C40.3871 14.2904 40.5818 14.0026 40.8263 13.7634L49.9829 4.66896C50.4866 4.17573 51.1554 3.90063 51.8508 3.90063C52.5463 3.90063 53.2151 4.17573 53.7188 4.66896C53.9633 4.90813 54.158 5.19595 54.2911 5.51491C54.4241 5.83387 54.4927 6.17732 54.4927 6.5244V9.9623H57.9547C58.6508 9.95891 59.3208 10.2345 59.8226 10.7306C60.0668 10.9699 60.2611 11.2577 60.3939 11.5766C60.5267 11.8955 60.5952 12.2387 60.5952 12.5856C60.5952 12.9325 60.5267 13.2758 60.3939 13.5946C60.2611 13.9135 60.0668 14.2013 59.8226 14.4407L50.6676 23.5335C50.1663 24.0305 49.496 24.3065 48.7997 24.3026H43.7883L33.8839 34.1384C33.8701 34.1526 33.8562 34.1669 33.8416 34.1803C33.3416 34.6627 32.6807 34.9294 31.9952 34.9257H31.9975Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9727 63.7342C26.803 63.729 21.7086 62.4603 17.1092 60.0328C13.3924 58.0806 10.0937 55.3821 7.40798 52.0968C4.84874 48.9695 2.91628 45.3525 1.72187 41.4539C0.50683 37.5092 0.0884884 33.3508 0.49252 29.2338C0.896551 25.1168 2.11443 21.1282 4.0714 17.5128C9.58519 7.2442 20.2966 0.864502 32.0258 0.864502C37.1958 0.869343 42.2906 2.13829 46.89 4.56668L48.2186 5.27008L42.0463 11.399L41.3485 11.0959C38.3863 9.8107 35.2039 9.14857 31.9888 9.14857C28.7738 9.14857 25.5914 9.8107 22.6292 11.0959C10.8461 16.2207 5.46081 29.909 10.6246 41.605C14.3435 50.0324 22.7338 55.4785 32.0027 55.4777C35.2143 55.4804 38.3933 54.8162 41.3493 53.5249C53.1293 48.4001 58.5146 34.7142 53.3508 23.0158L53.0431 22.3235L59.2154 16.1938L59.9294 17.5136C64.9008 26.771 64.9 37.8269 59.9294 47.0859C54.414 57.3545 43.7034 63.7342 31.975 63.7342H31.9727Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.939 54.7539C29.2485 54.7536 26.5785 54.2717 24.0487 53.3297C18.4065 51.24 13.9236 47.09 11.4233 41.6471C8.92293 36.2042 8.70982 30.122 10.8178 24.5193C12.4618 20.1596 15.3653 16.4741 19.225 13.8622C22.2375 11.832 25.6682 10.5508 29.2455 10.1201C32.8228 9.68943 36.4484 10.1211 39.8354 11.3809L41.4587 11.9831L40.2347 13.2016C39.9175 13.5133 39.6653 13.8882 39.4933 14.3033C39.3214 14.7184 39.2333 15.1652 39.2345 15.6164V20.109L37.6705 19.4278C35.8672 18.645 33.9297 18.2417 31.9724 18.2417C30.0151 18.2417 28.0777 18.645 26.2744 19.4278C19.1073 22.5507 15.8346 30.8737 18.9765 37.9885C21.2399 43.1117 26.3429 46.4222 31.9782 46.4222C33.9342 46.4244 35.8705 46.0208 37.6712 45.2354C41.1417 43.7233 43.812 40.9627 45.1899 37.4552C45.8718 35.7381 46.2038 33.8954 46.1653 32.041C46.1268 30.1867 45.7186 28.3602 44.966 26.6746L44.2806 25.1223H48.8027L48.8111 26.23V25.1223C49.715 25.1259 50.5848 24.7675 51.2361 24.1229L52.4593 22.9068L53.0663 24.5193C54.9681 29.5616 54.9681 35.153 53.0663 40.1952C51.4253 44.5549 48.5188 48.2405 44.6591 50.8523C40.8823 53.4065 36.4591 54.7632 31.939 54.7539Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0104 45.5141C30.2296 45.5154 28.4657 45.1582 26.8182 44.4625C23.5401 43.0834 21.0013 40.5206 19.6681 37.2457C19.0078 35.6423 18.6746 33.9165 18.689 32.1753C18.7035 30.434 19.0652 28.7143 19.7519 27.1227C21.8437 22.221 26.6598 19.0521 32.0212 19.0521C33.8024 19.0511 35.5665 19.4086 37.2142 20.1045L38.7375 20.7454L29.5478 29.873C29.2279 30.1872 28.9735 30.565 28.8001 30.9835C28.6267 31.402 28.538 31.8524 28.5392 32.3074C28.5404 32.7623 28.6316 33.2122 28.8073 33.6297C28.9829 34.0472 29.2393 34.4236 29.5609 34.7359C30.2227 35.3804 31.1001 35.7385 32.0115 35.736C32.9229 35.7334 33.7985 35.3706 34.4569 34.7225L43.6335 25.6091L44.2797 27.1227C44.9723 28.7503 45.3298 30.5077 45.3298 32.2843C45.3298 34.0609 44.9723 35.8183 44.2797 37.4459C42.1879 42.3468 37.3719 45.5141 32.0104 45.5141Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9974 34.9036C31.6474 34.9058 31.3004 34.837 30.9762 34.7013C30.652 34.5655 30.3569 34.3654 30.1079 34.1123C29.8675 33.8701 29.6771 33.5804 29.5485 33.2605C29.4198 32.9406 29.3554 32.5973 29.3592 32.2511C29.363 31.905 29.435 31.5632 29.5706 31.2464C29.7063 30.9296 29.903 30.6443 30.1487 30.4078L40.0446 20.5728V15.5967C40.0446 15.2496 40.1132 14.9062 40.2463 14.5872C40.3793 14.2683 40.574 13.9805 40.8186 13.7413L49.9667 4.64845C50.4697 4.15526 51.1381 3.88013 51.8331 3.88013C52.5281 3.88013 53.1964 4.15526 53.6995 4.64845C53.9437 4.88778 54.1382 5.17564 54.2711 5.49458C54.4041 5.81352 54.4726 6.15689 54.4726 6.50389V9.94179H57.9346C58.63 9.93844 59.2993 10.214 59.8002 10.7101C60.0442 10.9495 60.2383 11.2374 60.371 11.5562C60.5037 11.8751 60.5721 12.2183 60.5721 12.5651C60.5721 12.9119 60.5037 13.2551 60.371 13.574C60.2383 13.8928 60.0442 14.1807 59.8002 14.4202L50.6498 23.513C50.1491 24.01 49.4793 24.286 48.7834 24.2821H43.7774L33.8823 34.1163C33.8684 34.1305 33.8546 34.1448 33.84 34.1582C33.3411 34.64 32.6817 34.9067 31.9974 34.9036Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9727 63.712C26.808 63.7062 21.7187 62.4375 17.1247 60.0106C13.4104 58.0579 10.1143 55.3594 7.4311 52.0745C4.8732 48.9471 2.94228 45.33 1.74959 41.4317C0.53543 37.487 0.117344 33.3288 0.520959 29.212C0.924575 25.0952 2.14139 21.1066 4.09682 17.4906C9.60522 7.22199 20.3089 0.842285 32.0258 0.842285C37.1906 0.847763 42.2801 2.11675 46.8739 4.54446L48.201 5.24786L42.0348 11.3767L41.3401 11.0777C38.3808 9.79257 35.2011 9.13047 31.9889 9.13047C28.7766 9.13047 25.5969 9.79257 22.6377 11.0777C10.8669 16.2025 5.48777 29.8884 10.6438 41.586C14.3589 50.0102 22.7423 55.4563 32.0027 55.4555C35.2116 55.4582 38.3878 54.794 41.3409 53.5027C53.1116 48.3779 58.4908 34.692 53.3347 22.9936L53.027 22.3005L59.1932 16.1716L59.9017 17.4914C64.8677 26.7488 64.8677 37.8047 59.9017 47.0637C54.3933 57.3323 43.6919 63.712 31.975 63.712H31.9727Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.939 54.7341C29.2507 54.7339 26.5831 54.2519 24.0556 53.3099C18.418 51.2163 13.939 47.0663 11.4386 41.6234C8.93832 36.1805 8.7306 30.0999 10.837 24.4972C12.4757 20.1375 15.3807 16.4519 19.2366 13.8401C22.2456 11.8099 25.6732 10.5287 29.2475 10.098C32.8219 9.66733 36.4446 10.099 39.8284 11.3588L41.4502 11.9609L40.227 13.1794C39.9096 13.491 39.6572 13.8658 39.4853 14.281C39.3133 14.6961 39.2254 15.143 39.2268 15.5942V20.0869L37.6643 19.4056C35.8628 18.6229 33.927 18.2196 31.9713 18.2196C30.0156 18.2196 28.0798 18.6229 26.2782 19.4056C19.1235 22.5262 15.8507 30.8516 18.9904 37.9663C21.2507 43.0895 26.349 46.4001 31.979 46.4001C33.9324 46.4018 35.8661 45.9981 37.6643 45.2132C41.1317 43.7012 43.799 40.9406 45.1753 37.433C45.8568 35.7159 46.1885 33.8732 46.1502 32.019C46.1118 30.1647 45.7041 28.3383 44.9522 26.6525L44.2667 25.1001H48.785L48.7942 26.2078V25.1001C49.697 25.1048 50.566 24.7474 51.2168 24.1039L52.4424 22.8878L53.0486 24.5003C54.9488 29.5429 54.9488 35.1337 53.0486 40.1762C51.4099 44.5359 48.505 48.2215 44.6491 50.8333C40.876 53.3874 36.4559 54.7439 31.939 54.7341Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0105 45.492C30.2311 45.4934 28.4687 45.1362 26.8229 44.4404C23.5494 43.0613 21.0129 40.4985 19.6797 37.2236C19.0198 35.6201 18.6868 33.8944 18.7011 32.1532C18.7154 30.4121 19.0767 28.6924 19.7627 27.1006C21.853 22.1989 26.6652 19.03 32.0212 19.03C33.8007 19.029 35.5631 19.3865 37.2088 20.0824L38.7313 20.7233L29.5502 29.8509C29.2305 30.1652 28.9763 30.5431 28.8031 30.9615C28.6298 31.38 28.541 31.8303 28.5422 32.2851C28.5433 32.7399 28.6344 33.1897 28.8098 33.6072C28.9852 34.0248 29.2412 34.4012 29.5625 34.7138C30.2234 35.3584 31.1003 35.7166 32.0112 35.7141C32.922 35.7116 33.797 35.3486 34.4546 34.7004L43.6235 25.587L44.269 27.1006C44.9608 28.7284 45.318 30.4857 45.318 32.2622C45.318 34.0387 44.9608 35.796 44.269 37.4238C42.1787 42.3247 37.3665 45.492 32.0105 45.492Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9974 34.8813C31.6476 34.8835 31.3008 34.8147 30.9768 34.679C30.6529 34.5432 30.3581 34.3431 30.1095 34.09C29.8692 33.8477 29.6791 33.5578 29.5506 33.2379C29.4222 32.9181 29.3579 32.5747 29.3618 32.2287C29.3657 31.8826 29.4376 31.5409 29.5732 31.2241C29.7089 30.9074 29.9054 30.6221 30.151 30.3855L40.0377 20.5505V15.5744C40.0373 15.2277 40.1053 14.8846 40.2375 14.5657C40.3698 14.2468 40.5635 13.9587 40.807 13.719L49.9505 4.62616C50.4528 4.13305 51.1205 3.85791 51.8149 3.85791C52.5094 3.85791 53.1771 4.13305 53.6794 4.62616C53.923 4.8658 54.1169 5.15381 54.2491 5.47274C54.3813 5.79166 54.4493 6.13488 54.4487 6.4816V9.9195H57.9053C58.6024 9.91474 59.2736 10.1905 59.7756 10.6878C60.0197 10.9275 60.214 11.2156 60.3468 11.5347C60.4796 11.8538 60.548 12.1973 60.548 12.5444C60.548 12.8915 60.4796 13.235 60.3468 13.5541C60.214 13.8733 60.0197 14.1614 59.7756 14.401L50.6329 23.4907C50.1328 23.9872 49.4639 24.2632 48.7688 24.2598H43.7681L33.8807 34.094L33.8384 34.1359C33.3401 34.6175 32.6812 34.8843 31.9974 34.8813Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9729 63.6895C26.8116 63.684 21.7257 62.4153 17.1356 59.9881C13.4248 58.0349 10.1323 55.3363 7.4528 52.0521C4.89714 48.9239 2.96811 45.307 1.77668 41.4092C0.563883 37.4644 0.14631 33.3066 0.549502 29.1901C0.952694 25.0736 2.16817 21.0849 4.1216 17.4681C9.62538 7.20032 20.3175 0.819824 32.026 0.819824C37.1874 0.824816 42.2734 2.09388 46.8633 4.522L48.1896 5.22224L42.0288 11.3543L41.3318 11.0512C38.3755 9.76614 35.1986 9.104 31.989 9.104C28.7795 9.104 25.6026 9.76614 22.6463 11.0512C10.8871 16.1761 5.51178 29.862 10.6632 41.5596C14.3752 49.9878 22.7509 55.4338 32.0029 55.433C35.209 55.4357 38.3825 54.7714 41.3326 53.4803C53.091 48.3554 58.4678 34.6695 53.3133 22.9712L53.0056 22.278L59.1672 16.1491L59.8742 17.4689C64.8363 26.7263 64.8363 37.7822 59.8742 47.0412C54.3742 57.3098 43.6821 63.6895 31.9752 63.6895H31.9729Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.939 54.7118C29.253 54.7115 26.5876 54.2295 24.0626 53.2876C18.4303 51.1932 13.9552 47.0456 11.4618 41.6027C8.96836 36.1598 8.75294 30.0776 10.8578 24.4749C12.495 20.1152 15.3969 16.4296 19.2497 13.8178C22.2553 11.7877 25.6798 10.5064 29.2512 10.0757C32.8225 9.64497 36.4424 10.0767 39.8231 11.3365L41.4433 11.9386L40.2216 13.1571C39.9045 13.4689 39.6523 13.8438 39.4804 14.2589C39.3085 14.674 39.2204 15.1207 39.2215 15.5719V20.0646L37.6574 19.3857C35.8578 18.6029 33.9237 18.1996 31.9698 18.1996C30.0158 18.1996 28.0818 18.6029 26.2821 19.3857C19.1274 22.5063 15.8639 30.8316 19.0004 37.9464C21.2592 43.0696 26.3529 46.3801 31.9775 46.3801C33.9292 46.3819 35.8612 45.9782 37.6574 45.1933C41.1194 43.6812 43.7867 40.9206 45.1623 37.4131C45.8432 35.6958 46.1747 33.8532 46.1363 31.9991C46.0979 30.1449 45.6905 28.3185 44.9392 26.6326L44.2544 25.0802H48.7681L48.7773 26.1879V25.0802C49.6796 25.0847 50.5481 24.7273 51.1984 24.084L52.4232 22.8679L53.0287 24.4804C54.9274 29.5233 54.9274 35.1135 53.0287 40.1563C51.3908 44.516 48.4896 48.2015 44.636 50.8134C40.8673 53.366 36.4516 54.7217 31.939 54.7118Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0105 45.4693C30.2326 45.4706 28.4717 45.1134 26.8275 44.4177C23.5571 43.0386 21.0229 40.4758 19.6904 37.2009C19.0313 35.5972 18.6987 33.8715 18.7131 32.1305C18.7275 30.3894 19.0887 28.6698 19.7743 27.0779C21.8622 22.1723 26.6698 19.0073 32.0212 19.0073C33.7992 19.0063 35.56 19.3638 37.2042 20.0597L38.7259 20.7006L29.5524 29.8298C29.2331 30.1443 28.9792 30.5222 28.8061 30.9406C28.633 31.359 28.5444 31.8092 28.5455 32.264C28.5467 32.7187 28.6376 33.1684 28.8128 33.5859C28.988 34.0034 29.2438 34.3799 29.5648 34.6927C30.2248 35.3373 31.1009 35.6956 32.0112 35.6931C32.9214 35.6906 33.7956 35.3275 34.4523 34.6793L43.6127 25.5643L44.2574 27.0779C44.9487 28.7058 45.3055 30.4631 45.3055 32.2395C45.3055 34.0159 44.9487 35.7732 44.2574 37.4011C42.1695 42.302 37.3619 45.4693 32.0105 45.4693Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9975 34.8589C31.6479 34.861 31.3014 34.7922 30.9777 34.6564C30.654 34.5207 30.3595 34.3206 30.1111 34.0676C29.871 33.8252 29.6811 33.5353 29.5528 33.2154C29.4244 32.8955 29.3602 32.5523 29.3641 32.2063C29.368 31.8602 29.4398 31.5186 29.5754 31.2019C29.7109 30.8851 29.9072 30.5998 30.1526 30.3631L40.0308 20.5281V15.552C40.0303 15.2053 40.0982 14.8621 40.2304 14.5431C40.3627 14.2242 40.5565 13.9362 40.8002 13.6966L49.9344 4.60376C50.4357 4.10976 51.1031 3.83398 51.7973 3.83398C52.4916 3.83398 53.159 4.10976 53.6603 4.60376C53.9038 4.84348 54.0975 5.1315 54.2298 5.45041C54.362 5.76932 54.43 6.1125 54.4296 6.4592V9.8971H57.8831C58.5776 9.89373 59.2459 10.1694 59.7457 10.6654C59.9892 10.9051 60.183 11.193 60.3155 11.5118C60.4479 11.8306 60.5162 12.1737 60.5162 12.5204C60.5162 12.8671 60.4479 13.2102 60.3155 13.529C60.183 13.8478 59.9892 14.1358 59.7457 14.3755L50.616 23.4683C50.1166 23.9649 49.4482 24.241 48.7535 24.2374H43.7528L33.8793 34.0716L33.837 34.1135C33.3393 34.5952 32.6808 34.862 31.9975 34.8589Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9729 63.668C26.816 63.6625 21.7346 62.3937 17.1495 59.9667C13.4412 58.0128 10.1514 55.3143 7.47436 52.0306C4.92089 48.9018 2.99374 45.2849 1.80363 41.3877C0.592085 37.4428 0.175035 33.2852 0.577939 29.169C0.980843 25.0527 2.19525 21.0641 4.14701 17.4466C9.64541 7.17804 20.3283 0.79834 32.026 0.79834C37.1835 0.803337 42.2655 2.07246 46.851 4.50051L48.1765 5.20392L42.0219 11.3328L41.3256 11.0298C38.3723 9.74469 35.1982 9.08254 31.9914 9.08254C28.7846 9.08254 25.6104 9.74469 22.6571 11.0298C10.9063 16.1546 5.53795 29.8405 10.6825 41.5404C14.3914 49.9686 22.7609 55.4147 32.0021 55.4139C35.2055 55.4165 38.3762 54.7523 41.3233 53.4611C53.0718 48.334 58.4425 34.648 53.2956 22.9465L52.9879 22.2534L59.1426 16.1245L59.8526 17.4474C64.8102 26.7048 64.8102 37.7607 59.8526 47.0197C54.3535 57.286 43.6721 63.668 31.9752 63.668H31.9729Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9389 54.6892C29.2554 54.689 26.5925 54.207 24.0702 53.265C18.4425 51.1714 13.9712 47.0214 11.4785 41.5785C8.9859 36.1356 8.76895 30.055 10.8746 24.4491C12.511 20.0895 15.4137 16.4039 19.2603 13.792C22.2627 11.7629 25.6842 10.4823 29.2524 10.0523C32.8206 9.6222 36.4372 10.0545 39.8145 11.3147L41.4301 11.9168L40.2122 13.1345C39.8951 13.4463 39.6428 13.8211 39.4709 14.2363C39.299 14.6514 39.2109 15.0982 39.2121 15.5494V20.042L37.6519 19.3607C35.854 18.578 33.9216 18.1747 31.9692 18.1747C30.0169 18.1747 28.0845 18.578 26.2866 19.3607C19.1418 22.4814 15.8783 30.8067 19.0118 37.9215C21.2683 43.0431 26.3581 46.3552 31.9773 46.3552C33.9273 46.3569 35.8575 45.9532 37.6519 45.1683C41.1139 43.6563 43.7765 40.8957 45.1506 37.3882C45.8312 35.6702 46.1625 33.8272 46.1241 31.9726C46.0857 30.1179 45.6785 28.291 44.9275 26.6045L44.2435 25.0521H48.7533L48.7626 26.1598V25.0521C49.6642 25.0558 50.5318 24.6975 51.1806 24.0535L52.4046 22.8374L53.0093 24.4491C54.9064 29.4923 54.9064 35.0819 53.0093 40.125C51.3729 44.4847 48.4741 48.1703 44.6236 50.7821C40.8601 53.3372 36.4484 54.6961 31.9389 54.6892Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0106 45.4474C30.2342 45.4488 28.4748 45.0915 26.8323 44.3959C23.5649 43.0168 21.033 40.454 19.7021 37.1791C19.0434 35.5752 18.7109 33.8497 18.7252 32.1087C18.7395 30.3677 19.1002 28.6482 19.7852 27.0561C21.8716 22.1552 26.6745 18.9902 32.0214 18.9902C33.7978 18.9892 35.5572 19.3467 37.1997 20.0426L38.7199 20.6835L29.5541 29.8064C29.2351 30.1211 28.9815 30.4991 28.8087 30.9175C28.6359 31.3359 28.5474 31.7861 28.5486 32.2407C28.5498 32.6953 28.6407 33.145 28.8158 33.5624C28.9909 33.9798 29.2465 34.3564 29.5672 34.6693C30.2265 35.3136 31.1019 35.6717 32.0113 35.6692C32.9208 35.6667 33.7943 35.3038 34.4502 34.6558L43.6052 25.5424L44.2491 27.0561C44.9398 28.6841 45.2964 30.4413 45.2964 32.2177C45.2964 33.994 44.9398 35.7512 44.2491 37.3793C42.1581 42.2801 37.3575 45.4474 32.0106 45.4474Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9975 34.8373C31.6482 34.8394 31.3019 34.7706 30.9784 34.6348C30.655 34.499 30.3608 34.299 30.1126 34.0461C29.8729 33.8035 29.6832 33.5135 29.555 33.1937C29.4269 32.8738 29.3628 32.5307 29.3667 32.1847C29.3706 31.8388 29.4423 31.4973 29.5776 31.1805C29.7129 30.8638 29.909 30.5785 30.1542 30.3416L40.0231 20.5065V15.5305C40.0228 15.1838 40.0907 14.8406 40.223 14.5217C40.3552 14.2028 40.5489 13.9148 40.7925 13.6751L49.9183 4.58222C50.4194 4.08916 51.0861 3.81396 51.7797 3.81396C52.4732 3.81396 53.1399 4.08916 53.641 4.58222C53.8845 4.82203 54.0781 5.11007 54.2104 5.42896C54.3426 5.74785 54.4106 6.09099 54.4104 6.43766V9.87556H57.8608C58.5548 9.87227 59.2226 10.148 59.7218 10.6438C59.9652 10.8837 60.1588 11.1716 60.2911 11.4904C60.4234 11.8092 60.4916 12.1523 60.4916 12.4989C60.4916 12.8455 60.4234 13.1886 60.2911 13.5074C60.1588 13.8262 59.9652 14.1141 59.7218 14.3539L50.5999 23.4468C50.1005 23.9421 49.4326 24.2169 48.7389 24.2127H43.7459L33.8777 34.0501L33.8354 34.092C33.3383 34.5735 32.6804 34.8403 31.9975 34.8373Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9728 63.6458C26.8201 63.6402 21.743 62.3713 17.1624 59.9444C13.4571 57.9902 10.1703 55.2916 7.49657 52.0084C4.94501 48.8791 3.0197 45.2623 1.83123 41.3655C0.620256 37.4206 0.203236 33.2634 0.605594 29.1473C1.00795 25.0312 2.22126 21.0425 4.17153 17.4244C9.66531 7.15583 20.339 0.776123 32.0259 0.776123C37.1787 0.78138 42.256 2.05055 46.8363 4.4783L48.1587 5.1817L42.0079 11.3106L41.3155 11.0075C38.3652 9.72251 35.1938 9.06033 31.9897 9.06033C28.7857 9.06033 25.6143 9.72251 22.6639 11.0075C10.9232 16.1323 5.5594 29.8183 10.7016 41.5159C14.4067 49.9441 22.7708 55.3869 32.0028 55.3869C35.2021 55.389 38.3687 54.7248 41.3117 53.4342C53.0501 48.3094 58.4154 34.6234 53.2732 22.9251L52.9655 22.232L59.1155 16.1031L59.8218 17.4228C64.7747 26.6803 64.7747 37.7361 59.8218 46.9951C54.328 57.2637 43.6558 63.6434 31.972 63.6434L31.9728 63.6458Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9389 54.6678C29.2577 54.6675 26.5971 54.1855 24.0771 53.2436C18.4549 51.1516 13.9874 47 11.497 41.5587C9.00673 36.1174 8.79285 30.0336 10.8939 24.4309C12.5287 20.0712 15.4252 16.3857 19.2711 13.7738C22.2697 11.744 25.6879 10.4626 29.2533 10.0318C32.8186 9.60099 36.4325 10.0327 39.8069 11.2925L41.4224 11.8946L40.2023 13.1131C39.8854 13.4251 39.6333 13.8 39.4614 14.2151C39.2895 14.6302 39.2013 15.0768 39.2022 15.528V20.019L37.6443 19.3378C35.8481 18.555 33.9173 18.1517 31.9666 18.1517C30.0159 18.1517 28.0851 18.555 26.289 19.3378C19.155 22.46 15.893 30.7853 19.0234 37.9009C21.2783 43.0241 26.3628 46.3346 31.9774 46.3346C33.9262 46.3354 35.8549 45.9303 37.6473 45.1438C41.1093 43.6325 43.7658 40.8711 45.1383 37.3644C45.8178 35.6472 46.1485 33.8051 46.1101 31.9516C46.0718 30.098 45.6651 28.2722 44.9152 26.5862L44.2351 25.0307H48.7411L48.7503 26.1384V25.0307C49.6512 25.0351 50.5183 24.6777 51.1668 24.0345L52.39 22.8184L52.9939 24.4309C54.8896 29.4744 54.8896 35.0634 52.9939 40.1068C51.356 44.4681 48.4587 48.152 44.6136 50.7639C40.8536 53.318 36.4451 54.6759 31.9389 54.6678Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0105 45.4255C30.2356 45.4268 28.4778 45.0695 26.8368 44.3739C23.5725 42.9948 21.0429 40.432 19.7128 37.1571C19.0548 35.5531 18.7228 33.8276 18.7371 32.0867C18.7514 30.3459 19.1117 28.6264 19.7959 27.0341C21.8807 22.1284 26.6791 18.9635 32.0213 18.9635C33.7964 18.9635 35.5543 19.3221 37.195 20.019L38.7137 20.6599L29.5587 29.786C29.2399 30.1008 28.9864 30.4788 28.8137 30.8972C28.641 31.3157 28.5526 31.7657 28.5538 32.2203C28.555 32.6749 28.6459 33.1244 28.8208 33.5419C28.9958 33.9593 29.2512 34.3359 29.5717 34.6489C30.23 35.2932 31.1047 35.6515 32.0135 35.649C32.9223 35.6465 33.7951 35.2834 34.4501 34.6354L43.592 25.5204L44.2352 27.0341C44.9252 28.6623 45.2813 30.4195 45.2813 32.1957C45.2813 33.9719 44.9252 35.729 44.2352 37.3573C42.1511 42.2582 37.3527 45.4255 32.0105 45.4255Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9975 34.8151C31.6484 34.8171 31.3023 34.7483 30.9791 34.6125C30.6559 34.4767 30.362 34.2767 30.1141 34.0238C29.8746 33.7811 29.6851 33.4911 29.5571 33.1713C29.429 32.8514 29.365 32.5083 29.3689 32.1625C29.3728 31.8166 29.4444 31.4751 29.5796 31.1584C29.7148 30.8417 29.9108 30.5563 30.1557 30.3193L40.0162 20.4843V15.509C40.0158 15.1623 40.0837 14.8191 40.216 14.5002C40.3482 14.1813 40.542 13.8933 40.7855 13.6536L49.9021 4.55994C50.4025 4.06695 51.0686 3.79175 51.7615 3.79175C52.4545 3.79175 53.1206 4.06695 53.621 4.55994C53.8644 4.79975 54.0581 5.08778 54.1903 5.40667C54.3226 5.72557 54.3906 6.0687 54.3903 6.41538V9.85328H57.8369C58.5313 9.84919 59.1997 10.125 59.6987 10.6216C59.9418 10.8615 60.1352 11.1496 60.2673 11.4684C60.3994 11.7871 60.4675 12.1301 60.4675 12.4766C60.4675 12.8231 60.3994 13.1661 60.2673 13.4849C60.1352 13.8036 59.9418 14.0917 59.6987 14.3316L50.5829 23.4213C50.0846 23.9177 49.4172 24.1937 48.7235 24.1904H43.7351L33.8754 34.0278C33.8615 34.042 33.8477 34.0563 33.8331 34.0697C33.3369 34.5513 32.6797 34.8182 31.9975 34.8151Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9736 63.6231C26.825 63.6176 21.752 62.3484 17.1763 59.9209C13.4732 57.9666 10.1892 55.2677 7.51892 51.9841C4.96943 48.8547 3.04574 45.2385 1.85819 41.3428C0.648377 37.3978 0.231829 33.2408 0.633901 29.1249C1.03597 25.0091 2.24826 21.0204 4.19695 17.4017C9.68535 7.13312 20.349 0.753418 32.0259 0.753418C37.1745 0.758773 42.2475 2.028 46.8232 4.45559L48.1465 5.159L42.0041 11.2879L41.3117 10.9848C38.3642 9.69981 35.1954 9.03761 31.994 9.03761C28.7926 9.03761 25.6238 9.69981 22.6763 10.9848C10.944 16.1096 5.58328 29.7956 10.7209 41.4931C14.4229 49.9213 22.7763 55.3674 32.0028 55.3666C35.2023 55.3697 38.3691 54.7054 41.3117 53.4138C53.034 48.289 58.3947 34.6031 53.2571 22.9047L52.9532 22.2116L59.0971 16.0827L59.8026 17.4025C64.7517 26.6599 64.7509 37.7158 59.8026 46.9748C54.3134 57.2434 43.6505 63.6231 31.9752 63.6231H31.9736Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.939 54.6455C29.26 54.6451 26.6017 54.1631 24.0841 53.2212C18.468 51.1276 14.0059 46.9776 11.5156 41.5348C9.02526 36.0919 8.81369 30.0097 10.9132 24.407C12.5457 20.0473 15.4399 16.3617 19.282 13.7499C22.2773 11.7201 25.6925 10.4387 29.255 10.0079C32.8175 9.57706 36.4286 10.0088 39.8 11.2686L41.4156 11.8707L40.197 13.0892C39.8799 13.401 39.6277 13.7758 39.4558 14.191C39.2838 14.6061 39.1957 15.0528 39.1968 15.504V19.9966L37.6397 19.3154C35.8455 18.5327 33.9164 18.1293 31.9674 18.1293C30.0185 18.1293 28.0894 18.5327 26.2952 19.3154C19.1627 22.4352 15.9092 30.7613 19.0327 37.8761C21.2853 42.9993 26.366 46.3098 31.9751 46.3098C33.9219 46.3119 35.8489 45.9081 37.6397 45.123C41.0948 43.6117 43.7528 40.8503 45.1245 37.3436C45.8034 35.6258 46.1338 33.7835 46.0954 31.9296C46.0571 30.0758 45.6507 28.2496 44.9014 26.5631L44.219 25.0107H48.7211L48.7296 26.1184V25.0107C49.6301 25.015 50.4966 24.6576 51.1445 24.0145L52.3655 22.7984L52.9694 24.4109C54.8627 29.4548 54.8627 35.0429 52.9694 40.0868C51.3361 44.4465 48.4419 48.132 44.5998 50.7439C40.8444 53.2965 36.4405 54.6536 31.939 54.6455Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0104 45.403C30.2371 45.4042 28.4807 45.047 26.8413 44.3514C23.5801 42.9723 21.0521 40.4095 19.7235 37.1346C19.0661 35.5305 18.7344 33.8051 18.7487 32.0643C18.763 30.3235 19.123 28.6041 19.8066 27.0116C21.8899 22.1099 26.6836 18.941 32.0212 18.941C33.7947 18.9401 35.551 19.2976 37.1903 19.9934L38.7075 20.6343L29.5586 29.7619C29.24 30.0769 28.9867 30.455 28.8142 30.8733C28.6416 31.2917 28.5532 31.7418 28.5545 32.1963C28.5557 32.6507 28.6464 33.1003 28.8213 33.5177C28.9961 33.9351 29.2514 34.3117 29.5717 34.6248C30.2291 35.2691 31.1031 35.6273 32.0112 35.6248C32.9192 35.6223 33.7913 35.2592 34.4454 34.6114L43.5819 25.498L44.2243 27.0116C44.9139 28.64 45.2698 30.3971 45.2698 32.1732C45.2698 33.9493 44.9139 35.7064 44.2243 37.3348C42.1418 42.2357 37.3473 45.403 32.0104 45.403Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9974 34.7924C31.6487 34.7944 31.303 34.7256 30.9802 34.5898C30.6573 34.454 30.3638 34.254 30.1164 34.0011C29.8769 33.7584 29.6875 33.4684 29.5594 33.1486C29.4314 32.8288 29.3673 32.4858 29.3711 32.1399C29.3749 31.7941 29.4465 31.4526 29.5816 31.1359C29.7166 30.8192 29.9124 30.5337 30.1572 30.2966L40.0046 20.4616V15.4855C40.0045 15.1389 40.0726 14.7958 40.2048 14.4769C40.3371 14.158 40.5307 13.87 40.774 13.6301L49.8859 4.53724C50.3857 4.04428 51.0514 3.76904 51.7438 3.76904C52.4363 3.76904 53.102 4.04428 53.6018 4.53724C53.8451 4.77713 54.0387 5.06519 54.1709 5.38406C54.3031 5.70293 54.3712 6.04602 54.3711 6.39268V9.83058H57.8146C58.5085 9.8283 59.1757 10.1055 59.6733 10.6028C59.9163 10.8429 60.1096 11.1309 60.2416 11.4497C60.3737 11.7684 60.4417 12.1114 60.4417 12.4579C60.4417 12.8044 60.3737 13.1473 60.2416 13.4661C60.1096 13.7848 59.9163 14.0729 59.6733 14.3129L50.566 23.4018C50.0684 23.8983 49.4014 24.1744 48.7081 24.1709H43.7243L33.8769 34.0051C33.8631 34.0193 33.8492 34.0336 33.8346 34.047C33.339 34.5284 32.6823 34.7953 32.0005 34.7924H31.9974Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9736 63.6019C26.829 63.5955 21.7603 62.3254 17.1894 59.8973C13.4886 57.9438 10.2067 55.2462 7.53814 51.9644C4.99156 48.8337 3.07054 45.217 1.88511 41.3216C0.676694 37.3764 0.260676 33.2198 0.662325 29.1042C1.06397 24.9886 2.2749 20.9999 4.22156 17.3805C9.70611 7.11188 20.3598 0.732178 32.0259 0.732178C37.1701 0.737018 42.2388 2.00548 46.8101 4.43198L48.1318 5.13538L41.9926 11.2643L41.3002 10.9612C38.3557 9.67634 35.1898 9.01417 31.9913 9.01417C28.7927 9.01417 25.6269 9.67634 22.6824 10.9612C10.9632 16.0884 5.60712 29.7743 10.7401 41.4719C14.4383 49.9001 22.7839 55.3462 32.0028 55.3454C35.1982 55.3479 38.3609 54.6836 41.2994 53.3926C53.0155 48.2662 58.3716 34.5819 53.2363 22.8835L52.9324 22.1904L59.0732 16.0631L59.7756 17.3813C64.7193 26.6387 64.7193 37.6946 59.7756 46.9536C54.2934 57.2222 43.6405 63.6019 31.9751 63.6019H31.9736Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.939 54.6239C29.2625 54.6236 26.6067 54.1415 24.0918 53.1997C18.4757 51.1061 14.0197 46.9561 11.534 41.5132C9.04831 36.0703 8.83444 29.9889 10.9316 24.387C12.5634 20.0273 15.4553 16.3418 19.2935 13.7299C22.2853 11.7002 25.6975 10.4187 29.257 9.98787C32.8166 9.55702 36.4248 9.98877 39.793 11.2486L41.4086 11.8507L40.1908 13.0692C39.8741 13.3813 39.6222 13.7563 39.4503 14.1713C39.2784 14.5863 39.1901 15.0329 39.1907 15.4841V19.9767L37.6358 19.2954C35.8432 18.5127 33.9157 18.1093 31.9682 18.1093C30.0207 18.1093 28.0932 18.5127 26.3005 19.2954C19.1765 22.4152 15.9238 30.7414 19.0465 37.8561C21.2976 42.9794 26.3729 46.2883 31.9774 46.2883C33.9227 46.2903 35.8481 45.8865 37.6374 45.1014C41.0886 43.5902 43.7443 40.8288 45.1145 37.3221C45.7931 35.6042 46.1234 33.762 46.0852 31.9082C46.0469 30.0544 45.641 28.2282 44.8922 26.5415L44.2105 24.9891H48.708L48.7173 26.0968V24.9891C49.6168 24.9934 50.4823 24.6359 51.1291 23.993L52.3493 22.7768L52.9524 24.3894C54.8442 29.4335 54.8442 35.0211 52.9524 40.0653C51.3207 44.4226 48.4295 48.1081 44.5906 50.7192C40.839 53.2731 36.4379 54.6314 31.939 54.6239Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0105 45.3815C30.2394 45.3824 28.4854 45.0251 26.8483 44.33C23.5902 42.9508 21.0645 40.388 19.7374 37.1131C19.0804 35.5089 18.7489 33.7835 18.7631 32.0428C18.7774 30.3021 19.1372 28.5827 19.8205 26.9901C21.8977 22.0845 26.6914 18.9196 32.0236 18.9196C33.7955 18.9186 35.5504 19.2762 37.1881 19.9719L38.7037 20.6128L29.561 29.7404C29.2427 30.0556 28.9897 30.4337 28.8173 30.8521C28.6449 31.2704 28.5566 31.7204 28.5579 32.1748C28.5591 32.6292 28.6497 33.0786 28.8244 33.496C28.999 33.9134 29.254 34.2901 29.574 34.6034C30.2307 35.2477 31.104 35.6061 32.0116 35.6036C32.9192 35.6011 33.7907 35.2379 34.4439 34.5899L43.5712 25.4789L44.2136 26.9925C44.9025 28.6211 45.258 30.3781 45.258 32.1541C45.258 33.9301 44.9025 35.6871 44.2136 37.3157C42.1326 42.2142 37.3427 45.3815 32.0105 45.3815Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9973 34.7711C31.6488 34.7731 31.3033 34.7043 30.9807 34.5685C30.6582 34.4327 30.3649 34.2327 30.1178 33.9799C29.8786 33.737 29.6894 33.447 29.5615 33.1272C29.4336 32.8074 29.3697 32.4645 29.3735 32.1187C29.3773 31.773 29.4488 31.4316 29.5837 31.1149C29.7186 30.7982 29.9141 30.5127 30.1586 30.2753L40.0037 20.4395V15.4643C40.0037 15.1179 40.0719 14.7751 40.2041 14.4565C40.3363 14.1379 40.5299 13.8501 40.773 13.6104L49.8696 4.51601C50.3687 4.02307 51.034 3.7478 51.726 3.7478C52.418 3.7478 53.0832 4.02307 53.5824 4.51601C53.8254 4.7561 54.0189 5.04419 54.1511 5.36302C54.2833 5.68184 54.3515 6.02484 54.3517 6.37145V9.81251H57.7921C58.4847 9.80954 59.151 10.0853 59.6485 10.5808C59.8912 10.821 60.0842 11.1091 60.2161 11.4279C60.348 11.7466 60.4159 12.0894 60.4159 12.4358C60.4159 12.7822 60.348 13.1251 60.2161 13.4438C60.0842 13.7626 59.8912 14.0507 59.6485 14.2909L50.5489 23.3806C50.053 23.8758 49.3883 24.1518 48.6971 24.1496H43.7142L33.8721 33.9839C33.8583 33.9981 33.8444 34.0123 33.8298 34.0258C33.3347 34.507 32.6785 34.7739 31.9973 34.7711Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9734 63.5797C26.8332 63.5739 21.7687 62.3046 17.2023 59.8775C13.5054 57.922 10.2275 55.2237 7.56256 51.9422C5.01756 48.8123 3.09739 45.197 1.91183 41.3033C0.703629 37.3575 0.287588 33.2004 0.688962 29.0843C1.09034 24.9681 2.30075 20.9787 4.24675 17.3583C9.72592 7.08967 20.3696 0.713135 32.0257 0.713135C37.166 0.718528 42.2305 1.98788 46.7969 4.41531L48.1178 5.11871L41.9832 11.2444L41.2908 10.9414C38.3491 9.65642 35.1859 8.99417 31.9899 8.99417C28.794 8.99417 25.6308 9.65642 22.6891 10.9414C10.9815 16.063 5.63077 29.7513 10.7591 41.4497C14.4542 49.8779 22.7922 55.3239 32.0026 55.3232C35.1953 55.3256 38.3552 54.6612 41.2908 53.3704C52.9969 48.2456 58.3483 34.5597 53.22 22.8613L52.9161 22.1682L59.0492 16.0393L59.7531 17.3591C64.693 26.6165 64.693 37.6724 59.7531 46.9314C54.2732 57.2 43.6295 63.5797 31.9749 63.5797H31.9734Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9397 54.6013C29.2652 54.601 26.6114 54.119 24.0987 53.1771C18.4918 51.0835 14.0358 46.9335 11.5524 41.4906C9.06903 36.0477 8.85593 29.9655 10.9508 24.3628C12.581 20.0047 15.4699 16.3191 19.3088 13.7073C22.2972 11.6777 25.7063 10.3962 29.2629 9.96531C32.8195 9.53442 36.4247 9.96618 39.7899 11.226L41.4055 11.8281L40.1892 13.0466C39.8725 13.3587 39.6205 13.7336 39.4486 14.1487C39.2767 14.5637 39.1884 15.0103 39.1891 15.4614V19.9541L37.635 19.2728C35.8443 18.4901 33.9185 18.0867 31.9728 18.0867C30.027 18.0867 28.1012 18.4901 26.3105 19.2728C19.188 22.3926 15.9361 30.7188 19.0588 37.8335C21.3091 42.9567 26.3782 46.2673 31.9774 46.2673C33.9209 46.2692 35.8446 45.8655 37.6319 45.0804C41.0809 43.5692 43.7343 40.8078 45.1029 37.301C45.7807 35.583 46.1106 33.7408 46.0724 31.8872C46.0342 30.0335 45.6286 28.2074 44.8806 26.5205L44.199 24.9681H48.6972L48.7057 26.0758V24.9681C49.6048 24.9724 50.4698 24.6149 51.116 23.9719L52.3346 22.7558L52.9378 24.3683C54.828 29.4128 54.828 34.9997 52.9378 40.0442C51.3076 44.4039 48.4187 48.0895 44.5836 50.7013C40.8346 53.254 36.4359 54.6107 31.9397 54.6013Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0103 45.3588C30.2393 45.361 28.4852 45.0045 26.8481 44.3096C23.5931 42.9305 21.0697 40.3677 19.7434 37.0928C19.0871 35.4883 18.756 33.763 18.7703 32.0224C18.7846 30.2819 19.144 28.5626 19.8265 26.9697C21.9037 22.0681 26.6912 18.8992 32.0188 18.8992C33.7893 18.8983 35.5426 19.2559 37.1787 19.9515L38.6927 20.5924L29.5631 29.7177C29.245 30.0329 28.9923 30.4111 28.82 30.8294C28.6477 31.2477 28.5595 31.6975 28.5606 32.1518C28.5618 32.6061 28.6523 33.0555 28.8267 33.4729C29.0011 33.8903 29.2558 34.2671 29.5754 34.5806C30.2313 35.2249 31.1041 35.5832 32.011 35.5807C32.918 35.5782 33.7889 35.215 34.4414 34.5671L43.5611 25.4537L44.2027 26.9674C44.8908 28.5962 45.2459 30.3531 45.2459 32.129C45.2459 33.9049 44.8908 35.6618 44.2027 37.2906C42.124 42.1915 37.338 45.3588 32.0103 45.3588Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9974 34.7479C31.6491 34.75 31.3039 34.6811 30.9816 34.5453C30.6593 34.4095 30.3663 34.2095 30.1195 33.9567C29.8804 33.7137 29.6914 33.4236 29.5636 33.1038C29.4359 32.784 29.372 32.4411 29.3759 32.0954C29.3798 31.7497 29.4513 31.4083 29.5862 31.0916C29.7211 30.775 29.9166 30.4895 30.161 30.2522L39.9946 20.414V15.4411C39.9944 15.0944 40.0624 14.7513 40.1947 14.4324C40.3269 14.1135 40.5205 13.8255 40.7639 13.5857L49.8512 4.49282C50.3498 3.99991 51.0145 3.72461 51.7061 3.72461C52.3977 3.72461 53.0624 3.99991 53.5609 4.49282C53.8042 4.73274 53.9978 5.0208 54.13 5.33967C54.2622 5.65853 54.3303 6.00161 54.3303 6.34826V9.78616H57.7684C58.4603 9.78305 59.1259 10.0589 59.6225 10.5544C59.8651 10.7947 60.058 11.0828 60.1898 11.4016C60.3216 11.7203 60.3896 12.0631 60.3896 12.4095C60.3896 12.7559 60.3216 13.0987 60.1898 13.4174C60.058 13.7361 59.8651 14.0243 59.6225 14.2645L50.5329 23.3574C50.0363 23.8535 49.3703 24.1296 48.678 24.1264H43.7035L33.8707 33.9607C33.8569 33.9749 33.843 33.9891 33.8284 34.0026C33.334 34.484 32.6782 34.7509 31.9974 34.7479Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9735 63.5572C26.8375 63.5513 21.7773 62.282 17.2154 59.855C13.5212 57.8993 10.2461 55.2009 7.5842 51.9198C5.04091 48.7881 3.12281 45.1715 1.93963 41.2769C0.73293 37.3317 0.317407 33.1756 0.718225 29.0604C1.11904 24.9452 2.32786 20.9565 4.27147 17.3358C9.74602 7.0672 20.3805 0.6875 32.0258 0.6875C37.1616 0.693143 42.2216 1.96254 46.7831 4.38967L48.1025 5.09308L41.9748 11.222L41.2824 10.9189C38.3431 9.63364 35.182 8.97126 31.988 8.97139C28.7941 8.97153 25.633 9.63418 22.6938 10.9197C11 16.0437 5.65395 29.7297 10.7785 41.4272C14.4697 49.8554 22.8008 55.3015 32.0027 55.3007C35.1926 55.3031 38.3498 54.6387 41.2824 53.3479C52.9762 48.2231 58.3246 34.5372 53.2009 22.8388L52.897 22.1457L59.0255 16.0168L59.7286 17.3366C64.6639 26.594 64.6639 37.6499 59.7286 46.9089C54.2533 57.1775 43.6196 63.5572 31.975 63.5572H31.9735Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9398 54.5789C29.2676 54.5785 26.6161 54.0965 24.1057 53.1547C18.5035 51.0611 14.0521 46.9111 11.571 41.4682C9.08994 36.0253 8.87837 29.9431 10.9694 24.3404C12.5989 19.9823 15.4846 16.2999 19.3166 13.6888C22.3018 11.6591 25.708 10.3774 29.262 9.9465C32.8159 9.51558 36.4185 9.94748 39.7808 11.2075L41.3887 11.8057L40.1732 13.0242C39.8572 13.3366 39.6061 13.7117 39.435 14.1268C39.2639 14.5418 39.1763 14.9882 39.1777 15.439V19.9316L37.6244 19.2504C35.8355 18.4678 33.9115 18.0644 31.9675 18.0644C30.0235 18.0644 28.0995 18.4678 26.3106 19.2504C19.1997 22.3702 15.9508 30.7003 19.0705 37.8111C21.3169 42.9343 26.3791 46.2449 31.9775 46.2449C33.9196 46.2456 35.8415 45.8405 37.6267 45.054C41.0725 43.5428 43.7236 40.7814 45.0892 37.2747C45.7663 35.5565 46.0958 33.7144 46.0575 31.8608C46.0193 30.0073 45.6142 28.1812 44.8669 26.4941L44.186 24.9417H48.6758L48.685 26.0495V24.9417C49.5834 24.9458 50.4477 24.5883 51.093 23.9456L52.3132 22.7294L52.9148 24.342C54.8035 29.3867 54.8035 34.9731 52.9148 40.0179C51.2861 44.3775 48.4004 48.0631 44.5684 50.675C40.825 53.228 36.4314 54.5862 31.9398 54.5789Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0105 45.3382C30.2418 45.3393 28.4903 44.982 26.856 44.2866C23.6033 42.9075 21.086 40.3447 19.7574 37.0698C19.1017 35.4652 18.7709 33.74 18.7852 31.9995C18.7995 30.259 19.1585 28.5398 19.8405 26.9468C21.9177 22.0411 26.699 18.8762 32.022 18.8762C33.7907 18.8754 35.5423 19.233 37.1765 19.9286L38.6898 20.5695L29.5656 29.6955C29.2477 30.0109 28.9951 30.3891 28.8229 30.8074C28.6508 31.2257 28.5626 31.6755 28.5638 32.1297C28.5649 32.5839 28.6554 33.0332 28.8296 33.4506C29.0039 33.868 29.2584 34.2448 29.5779 34.5584C30.2329 35.2026 31.1049 35.5609 32.0112 35.5584C32.9175 35.5559 33.7876 35.1927 34.4393 34.545L43.5504 25.4316L44.1921 26.9452C44.8795 28.5742 45.2342 30.331 45.2342 32.1068C45.2342 33.8826 44.8795 35.6394 44.1921 37.2684C42.1149 42.1732 37.3335 45.3382 32.0105 45.3382Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9974 34.7264C31.6493 34.7283 31.3043 34.6594 30.9823 34.5236C30.6602 34.3878 30.3675 34.1878 30.121 33.9352C29.8822 33.692 29.6934 33.4018 29.5658 33.0821C29.4383 32.7623 29.3745 32.4194 29.3784 32.0738C29.3823 31.7282 29.4537 31.387 29.5884 31.0703C29.7231 30.7537 29.9183 30.4681 30.1625 30.2306L39.9868 20.3956V15.4195C39.9868 15.0729 40.055 14.7298 40.1872 14.411C40.3194 14.0921 40.5129 13.8041 40.7562 13.5641L49.8373 4.47128C50.3352 3.97844 50.9993 3.70313 51.6903 3.70313C52.3813 3.70312 53.0454 3.97844 53.5432 4.47128C53.7871 4.71159 53.9811 5.00026 54.1134 5.31985C54.2458 5.63943 54.3138 5.9833 54.3133 6.33067V9.76462H57.7484C58.4399 9.76116 59.1051 10.037 59.6009 10.5329C59.8433 10.7733 60.0361 11.0614 60.1677 11.3802C60.2994 11.6989 60.3673 12.0416 60.3673 12.3879C60.3673 12.7342 60.2994 13.077 60.1677 13.3957C60.0361 13.7144 59.8433 14.0026 59.6009 14.243L50.5159 23.3358C50.0201 23.8317 49.3549 24.1076 48.6633 24.1041H43.6966L33.8691 33.9391L33.8268 33.9811C33.3329 34.4622 32.6777 34.7292 31.9974 34.7264Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9737 63.5373C26.8427 63.5305 21.7879 62.2606 17.2318 59.8335C13.5393 57.8776 10.2663 55.1792 7.60668 51.8983C5.06506 48.7662 3.14856 45.1497 1.96672 41.2554C0.761304 37.3101 0.346318 33.1543 0.746848 29.0393C1.14738 24.9243 2.3551 20.9356 4.29702 17.3143C9.76926 7.04334 20.3914 0.666016 32.026 0.666016C37.1589 0.67115 42.216 1.94062 46.774 4.36819L48.0919 5.07159L41.9657 11.2005L41.2733 10.8974C38.3375 9.61249 35.1797 8.95018 31.989 8.95018C28.7984 8.95018 25.6406 9.61249 22.7048 10.8974C11.0202 16.0222 5.67797 29.7082 10.7971 41.4057C14.486 49.8339 22.8094 55.28 32.0029 55.2792C35.1895 55.2827 38.3436 54.6197 41.2733 53.3304C52.9587 48.2056 58.3001 34.5197 53.181 22.8213L52.8779 22.1282L59.0002 15.9993L59.7034 17.3191C64.634 26.5765 64.634 37.6324 59.7034 46.8914C54.2365 57.156 43.609 63.5373 31.9752 63.5373H31.9737Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9395 54.5572C29.2696 54.5567 26.6204 54.0747 24.1124 53.133C18.5155 51.0394 14.068 46.8893 11.5892 41.4465C9.11044 36.0036 8.89657 29.9214 10.9884 24.3187C12.6155 19.959 15.4997 16.2734 19.3279 13.6616C22.3097 11.6323 25.7127 10.3509 29.2635 9.9202C32.8144 9.48952 36.4139 9.92165 39.7729 11.1818L41.3831 11.784L40.1691 13.0025C39.8532 13.315 39.6022 13.6901 39.4311 14.1051C39.26 14.5202 39.1724 14.9665 39.1736 15.4173V19.9099L37.6187 19.2279C35.8315 18.4452 33.909 18.0418 31.9665 18.0418C30.024 18.0418 28.1014 18.4452 26.3142 19.2279C19.2079 22.3477 15.9621 30.6738 19.0787 37.7886C21.3228 42.9118 26.3873 46.2223 31.9742 46.2215C33.9145 46.2239 35.835 45.8201 37.6187 45.0347C41.0607 43.5234 43.7095 40.762 45.0766 37.2553C45.7532 35.537 46.0824 33.6949 46.0441 31.8415C46.0059 29.9881 45.6011 28.162 44.8543 26.4748L44.1742 24.9224H48.6602L48.6686 26.0301V24.9224C49.5663 24.9266 50.4299 24.569 51.0743 23.9262L52.2945 22.7093L52.8961 24.3218C54.7825 29.367 54.7825 34.9525 52.8961 39.9977C51.269 44.3574 48.3847 48.043 44.5566 50.6548C40.8173 53.2072 36.4274 54.5649 31.9395 54.5572Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0106 45.3155C30.2419 45.3172 28.4903 44.9599 26.8561 44.2639C23.6065 42.8848 21.0861 40.322 19.7644 37.0471C19.1093 35.4424 18.7788 33.7172 18.7931 31.9768C18.8074 30.2364 19.1662 28.5172 19.8475 26.9241C21.9247 22.0224 26.6999 18.8535 32.0183 18.8535C33.7858 18.8527 35.536 19.2103 37.169 19.9059L38.6807 20.5468L29.568 29.6728C29.2504 29.9883 28.9981 30.3666 28.8261 30.7849C28.6541 31.2031 28.5661 31.6528 28.5672 32.107C28.5684 32.5611 28.6587 33.0103 28.8328 33.4277C29.0069 33.845 29.2611 34.2219 29.5803 34.5357C30.2344 35.1799 31.1057 35.5384 32.0113 35.5359C32.917 35.5334 33.7864 35.1701 34.4371 34.5223L43.5429 25.4105L44.183 26.9241C44.8703 28.5531 45.225 30.3099 45.225 32.0857C45.225 33.8615 44.8703 35.6183 44.183 37.2473C42.1058 42.1482 37.3282 45.3155 32.0106 45.3155Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9975 34.7052C31.6496 34.7071 31.3049 34.6381 30.9831 34.5023C30.6613 34.3665 30.3689 34.1665 30.1226 33.9139C29.884 33.6706 29.6954 33.3805 29.568 33.0607C29.4405 32.741 29.3768 32.3981 29.3807 32.0526C29.3846 31.7071 29.4559 31.3658 29.5905 31.0492C29.7251 30.7326 29.9202 30.447 30.1641 30.2094L39.98 20.3744V15.3983C39.9801 15.0517 40.0483 14.7086 40.1805 14.3898C40.3127 14.071 40.5062 13.7829 40.7494 13.5429L49.8275 4.45004C50.3245 3.95709 50.988 3.68164 51.6785 3.68164C52.3689 3.68164 53.0325 3.95709 53.5295 4.45004C53.7726 4.69004 53.9661 4.97812 54.0984 5.29697C54.2306 5.61581 54.2987 5.95885 54.2988 6.30548V9.74338H57.7308C58.4218 9.74008 59.0865 10.016 59.5818 10.5117C59.824 10.7522 60.0166 11.0404 60.1481 11.3591C60.2797 11.6778 60.3475 12.0205 60.3475 12.3667C60.3475 12.7129 60.2797 13.0556 60.1481 13.3743C60.0166 13.6931 59.824 13.9813 59.5818 14.2217L50.5037 23.3146C50.0086 23.8105 49.3438 24.0864 48.6527 24.0829H43.6828L33.8669 33.9179L33.8254 33.9598C33.3321 34.441 32.6773 34.7079 31.9975 34.7052Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9736 63.5125C26.8462 63.5065 21.7948 62.237 17.2425 59.8103C13.5528 57.854 10.2826 55.1556 7.62589 51.8751C5.08705 48.7423 3.17318 45.1258 1.99362 41.2322C0.789058 37.2868 0.374317 33.1313 0.774431 29.0166C1.17455 24.9018 2.38122 20.913 4.32161 17.2911C9.78616 7.02252 20.4014 0.642822 32.026 0.642822C37.1534 0.648709 42.2048 1.91821 46.7571 4.345L48.0742 5.0484L41.9565 11.1773L41.2641 10.8742C38.3312 9.58932 35.1762 8.92698 31.9883 8.92698C28.8004 8.92698 25.6453 9.58932 22.7124 10.8742C11.0386 15.999 5.70179 29.685 10.8163 41.3818C14.5014 49.81 22.8178 55.256 32.0021 55.2552C35.1865 55.2576 38.3381 54.5932 41.2648 53.3025C52.9394 48.1784 58.277 34.4925 53.1595 22.7941L52.8563 22.101L58.9733 15.9721L59.6757 17.2919C64.5994 26.5493 64.5994 37.6052 59.6757 46.8642C54.2134 57.1328 43.5967 63.5125 31.9729 63.5125H31.9736Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9396 54.5341C29.2719 54.5337 26.625 54.0516 24.1194 53.1099C18.5279 51.0163 14.0843 46.8663 11.6078 41.4234C9.13131 35.9805 8.91513 29.8983 11.0077 24.2956C12.6339 19.9355 15.5477 16.2091 19.3396 13.6401C22.3174 11.6105 25.7171 10.3283 29.2649 9.89693C32.8126 9.46554 36.4093 9.89695 39.7653 11.1564L41.3739 11.7586L40.1576 12.9763C39.842 13.2889 39.5912 13.664 39.4204 14.0791C39.2495 14.4941 39.1622 14.9404 39.1636 15.3911V19.8837L37.6188 19.2056C35.8335 18.423 33.9127 18.0196 31.972 18.0196C30.0312 18.0196 28.1104 18.423 26.3251 19.2056C19.2249 22.3255 15.9822 30.6516 19.0934 37.7663C21.336 42.8896 26.3935 46.2001 31.9773 46.1993C33.9159 46.2017 35.8347 45.7979 37.6165 45.0124C41.0562 43.5012 43.7019 40.7398 45.0675 37.233C45.7436 35.5146 46.0726 33.6727 46.0345 31.8193C45.9964 29.966 45.592 28.14 44.8459 26.4525L44.1666 24.9001H48.6479L48.6572 26.0078V24.9001C49.5541 24.9041 50.4169 24.5465 51.0606 23.904L52.2761 22.6878L52.8777 24.3004C54.7626 29.3459 54.7626 34.9307 52.8777 39.9762C51.2497 44.3358 48.335 48.0615 44.5428 50.6302C40.8085 53.1823 36.4233 54.5406 31.9396 54.5341Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0105 45.292C30.2446 45.2932 28.4958 44.9358 26.8644 44.2405C23.6179 42.8614 21.1014 40.2986 19.7789 37.0237C19.1245 35.419 18.7944 33.6942 18.8087 31.9542C18.823 30.2141 19.1814 28.4953 19.862 26.9022C21.9353 21.9966 26.709 18.8317 32.0213 18.8317C33.7872 18.8308 35.536 19.1884 37.1673 19.884L38.6775 20.5249L29.5694 29.651C29.2522 29.9667 29.0001 30.3451 28.8284 30.7633C28.6567 31.1816 28.5688 31.6312 28.57 32.0853C28.5713 32.5393 28.6616 32.9884 28.8355 33.4057C29.0095 33.823 29.2635 34.1999 29.5825 34.5139C30.2357 35.158 31.1063 35.5164 32.0112 35.5139C32.9161 35.5114 33.7848 35.1481 34.4346 34.5004L43.5297 25.387L44.1697 26.9022C44.8565 28.5314 45.2109 30.2882 45.2109 32.0638C45.2109 33.8395 44.8565 35.5963 44.1697 37.2254C42.0964 42.1247 37.3235 45.292 32.0105 45.292Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9974 34.6817C31.6499 34.6836 31.3055 34.6147 30.9841 34.4789C30.6627 34.3431 30.3707 34.1431 30.1248 33.8905C29.8863 33.6472 29.6977 33.357 29.5703 33.0373C29.4428 32.7176 29.3791 32.3749 29.3829 32.0294C29.3867 31.6838 29.458 31.3426 29.5924 31.026C29.7269 30.7093 29.9218 30.4237 30.1656 30.186L39.973 20.3502V15.3749C39.9731 15.0283 40.0412 14.6852 40.1734 14.3663C40.3056 14.0475 40.4992 13.7594 40.7424 13.5194L49.8058 4.42662C50.3022 3.93369 50.9653 3.6582 51.6553 3.6582C52.3453 3.6582 53.0084 3.93369 53.5048 4.42662C53.7478 4.6667 53.9413 4.9548 54.0735 5.27362C54.2057 5.59245 54.2739 5.93545 54.2741 6.28205V9.71995H57.6984C58.3879 9.71765 59.0508 9.99348 59.5448 10.4882C59.7866 10.729 59.9789 11.0172 60.1102 11.3359C60.2415 11.6546 60.3091 11.9972 60.3091 12.3433C60.3091 12.6894 60.2415 13.032 60.1102 13.3507C59.9789 13.6693 59.7866 13.9576 59.5448 14.1983L50.4829 23.2912C49.989 23.7861 49.326 24.0619 48.6365 24.0594H43.672L33.8653 33.8945L33.8238 33.9364C33.331 34.4174 32.6768 34.6844 31.9974 34.6817Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9735 63.4913C26.8503 63.4851 21.8032 62.2156 17.2555 59.7891C13.5696 57.8319 10.3034 55.1335 7.65039 51.8538C5.11293 48.7205 3.19992 45.1042 2.02044 41.211C0.81726 37.2655 0.40304 33.1103 0.802732 28.9958C1.20242 24.8813 2.40775 20.8926 4.34612 17.2699C9.80606 7.00128 20.412 0.621582 32.0258 0.621582C37.1489 0.627529 42.1958 1.8971 46.7431 4.32376L48.0594 5.02716L41.9479 11.1576L41.2555 10.8546C38.3256 9.56972 35.1733 8.90739 31.9881 8.90739C28.803 8.90739 25.6507 9.56972 22.7208 10.8546C11.0578 15.9778 5.72553 29.6637 10.8354 41.3613C14.5174 49.7895 22.8254 55.2356 32.0028 55.2348C35.1845 55.2371 38.3333 54.5727 41.257 53.282C52.9208 48.1572 58.2531 34.4713 53.1432 22.7729L52.8401 22.0766L58.9516 15.9477L59.6532 17.2675C64.577 26.5249 64.577 37.5808 59.6532 46.8398C54.191 57.1084 43.5889 63.4881 31.9758 63.4881L31.9735 63.4913Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9398 54.5174C29.2746 54.517 26.6302 54.0349 24.1272 53.0932C18.5396 50.9972 14.1005 46.8472 11.6264 41.4043C9.1522 35.9614 8.9391 29.8792 11.0263 24.2757C12.6506 19.9165 15.5615 16.19 19.3504 13.6194C22.3252 11.5902 25.722 10.3085 29.2669 9.87748C32.8118 9.44647 36.4056 9.87828 39.7585 11.1381L41.3656 11.7402L40.1531 12.9579C39.8377 13.2709 39.5869 13.6462 39.4158 14.0613C39.2448 14.4764 39.1569 14.9227 39.1576 15.3736V19.8662L37.6097 19.1849C35.8261 18.4023 33.907 17.9989 31.9678 17.9989C30.0287 17.9989 28.1096 18.4023 26.326 19.1849C19.232 22.3048 15.9939 30.6309 19.105 37.7433C21.3453 42.8665 26.3983 46.177 31.9775 46.1762C33.9146 46.1785 35.8318 45.7747 37.612 44.9894C41.0479 43.4781 43.6897 40.7167 45.0561 37.21C45.7315 35.4913 46.0602 33.6493 46.0221 31.7959C45.984 29.9425 45.58 28.1165 44.8345 26.4286L44.1583 24.8763H48.6365L48.645 25.984V24.8763C49.5415 24.8801 50.4038 24.5225 51.0468 23.8801L52.2608 22.664L52.8617 24.2757C54.745 29.3218 54.745 34.9063 52.8617 39.9524C51.2377 44.3119 48.3267 48.0388 44.5375 50.6095C40.806 53.1638 36.4222 54.5237 31.9398 54.5174Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0104 45.2701C30.2459 45.2712 28.4987 44.9138 26.8689 44.2185C23.6254 42.8394 21.1113 40.2766 19.7911 37.0017C19.1372 35.3966 18.8075 33.6716 18.8217 31.9314C18.836 30.1912 19.1941 28.4722 19.8742 26.8787C21.9452 21.977 26.7143 18.8081 32.0227 18.8081C33.7866 18.8075 35.5333 19.1652 37.1626 19.8604L38.6712 20.5013L29.5716 29.629C29.2548 29.945 29.0032 30.3235 28.8318 30.7417C28.6604 31.16 28.5727 31.6094 28.5739 32.0633C28.5751 32.5172 28.6653 32.9662 28.8389 33.3834C29.0126 33.8007 29.2662 34.1777 29.5847 34.4919C30.2369 35.1358 31.1066 35.4942 32.0107 35.4917C32.9148 35.4892 33.7826 35.126 34.4314 34.4785L43.5195 25.365L44.1581 26.8787C44.844 28.5081 45.1978 30.2647 45.1978 32.0403C45.1978 33.8158 44.844 35.5725 44.1581 37.2019C42.087 42.1028 37.3187 45.2701 32.0104 45.2701Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9975 34.6573C31.6502 34.6592 31.3061 34.5902 30.9849 34.4544C30.6637 34.3186 30.372 34.1186 30.1265 33.866C29.8882 33.6226 29.6999 33.3324 29.5726 33.0127C29.4454 32.693 29.3817 32.3503 29.3855 32.0049C29.3893 31.6595 29.4605 31.3184 29.5948 31.0017C29.729 30.6851 29.9237 30.3994 30.1672 30.1615L39.9654 20.3289V15.3512C39.9656 15.0046 40.0339 14.6616 40.1661 14.3428C40.2983 14.024 40.4917 13.7359 40.7348 13.4958L49.7898 4.40533C50.2854 3.91336 50.9473 3.63843 51.6362 3.63843C52.325 3.63843 52.9869 3.91336 53.4825 4.40533C53.7257 4.64539 53.9191 4.93348 54.0513 5.25231C54.1835 5.57114 54.2517 5.91415 54.2519 6.26077V9.69867H57.6777C58.3674 9.6956 59.0305 9.97154 59.5241 10.467C59.7659 10.7077 59.9582 10.996 60.0895 11.3146C60.2208 11.6333 60.2884 11.9759 60.2884 12.322C60.2884 12.6681 60.2208 13.0107 60.0895 13.3294C59.9582 13.648 59.7659 13.9363 59.5241 14.177L50.4668 23.2699C49.9732 23.7653 49.31 24.0412 48.6204 24.0382H43.6613L33.8639 33.8732C33.85 33.8874 33.8362 33.9017 33.8216 33.9151C33.3292 34.395 32.6758 34.6609 31.9975 34.6573Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9735 63.4686C26.8544 63.4623 21.8115 62.1927 17.2685 59.7664C13.5854 57.8086 10.3221 55.1102 7.67186 51.8311C5.13632 48.6973 3.22515 45.081 2.04729 41.1883C0.845175 37.2431 0.431378 33.0886 0.830788 28.9747C1.2302 24.8607 2.43456 20.8724 4.37143 17.2496C9.82598 6.97858 20.422 0.598877 32.0258 0.598877C37.1446 0.604942 42.1873 1.87456 46.73 4.30105L48.0448 5.00446L41.9386 11.1333L41.2462 10.8295C38.319 9.54491 35.1694 8.88268 31.9869 8.88268C28.8044 8.88268 25.6548 9.54491 22.7276 10.8295C11.0769 15.9551 5.74854 29.641 10.8546 41.3386C14.5327 49.7692 22.8338 55.2129 32.0027 55.2121C35.1819 55.2143 38.3282 54.5498 41.2493 53.2593C52.9015 48.1345 58.2291 34.4486 53.1239 22.7502L52.8215 22.0571L58.9292 15.9282L59.6286 17.2496C62.048 21.7806 63.3164 26.8664 63.3164 32.0357C63.3164 37.205 62.048 42.2908 59.6286 46.8218C54.174 57.0905 43.578 63.4701 31.9758 63.4701L31.9735 63.4686Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9399 54.4906C29.277 54.4901 26.6349 54.008 24.1343 53.0664C18.552 50.9743 14.1169 46.8243 11.645 41.3822C10.4228 38.7176 9.73945 35.8255 9.63643 32.8812C9.53341 29.9369 10.0129 27.0017 11.0457 24.2537C12.6673 19.8952 15.5756 16.1686 19.3622 13.5973C22.3332 11.5687 25.7265 10.2871 29.268 9.85605C32.8096 9.42501 36.4 9.85663 39.7494 11.116L41.355 11.7182L40.1441 12.9359C39.8303 13.2496 39.5813 13.6251 39.4118 14.04C39.2424 14.4549 39.156 14.9007 39.1578 15.3507V19.8433L37.6107 19.1621C35.8288 18.3795 33.9113 17.976 31.9738 17.976C30.0362 17.976 28.1188 18.3795 26.3369 19.1621C19.2498 22.2819 16.0125 30.608 19.1206 37.7228C21.3593 42.846 26.4077 46.1565 31.9815 46.1557C33.9168 46.1581 35.8323 45.7542 37.6107 44.9689C41.0442 43.4576 43.6884 40.6962 45.0485 37.1895C45.7232 35.4707 46.0515 33.629 46.0134 31.7758C45.9753 29.9227 45.5717 28.0969 44.827 26.409L44.1484 24.8558H48.6205L48.6298 25.9635V24.8558C49.5253 24.8593 50.3865 24.5017 51.0285 23.8596L52.2425 22.6435L52.8426 24.2552C54.7244 29.3016 54.7244 34.8855 52.8426 39.9319C51.2195 44.29 48.3102 48.0159 44.5231 50.5866C40.7962 53.1388 36.4174 54.4974 31.9399 54.4906Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0106 45.2482C30.2477 45.2493 28.502 44.892 26.8738 44.1967C23.6334 42.8175 21.1215 40.2547 19.8014 36.9798C19.1477 35.3747 18.818 33.6498 18.8322 31.9096C18.8463 30.1695 19.2041 28.4505 19.8837 26.8568C21.9532 21.9551 26.7176 18.7863 32.0214 18.7863C33.7842 18.7861 35.5297 19.1443 37.1574 19.8402L38.6646 20.4811L29.5742 29.6071C29.2576 29.9233 29.0062 30.3018 28.8349 30.72C28.6636 31.1382 28.576 31.5876 28.5772 32.0414C28.5784 32.4953 28.6685 32.9442 28.842 33.3614C29.0155 33.7787 29.269 34.1557 29.5872 34.4701C30.2389 35.1151 31.1088 35.4742 32.0133 35.4717C32.9177 35.4692 33.7857 35.1052 34.434 34.4566L43.5121 25.3432L44.1514 26.8568C44.8365 28.4864 45.19 30.243 45.19 32.0184C45.19 33.7938 44.8365 35.5504 44.1514 37.18C42.0812 42.0809 37.3113 45.2482 32.0106 45.2482Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9974 34.6378C31.6504 34.6397 31.3064 34.5707 30.9855 34.4349C30.6646 34.2991 30.3731 34.0991 30.1279 33.8465C29.8899 33.6029 29.7017 33.3127 29.5746 32.993C29.4474 32.6734 29.3839 32.3307 29.3877 31.9854C29.3915 31.64 29.4625 31.299 29.5967 30.9824C29.7308 30.6657 29.9253 30.38 30.1687 30.142L39.9584 20.307V15.3309C39.9586 14.9843 40.0268 14.6413 40.159 14.3225C40.2912 14.0036 40.4847 13.7155 40.7278 13.4755L49.7743 4.38264C50.2696 3.88994 50.9317 3.6145 51.6207 3.6145C52.3098 3.6145 52.9718 3.88994 53.4671 4.38264C53.7093 4.62319 53.9018 4.91147 54.0332 5.23026C54.1646 5.54906 54.2321 5.89182 54.2318 6.23808V9.67598H57.6546C58.3443 9.67265 59.0075 9.94864 59.5009 10.4443C59.7426 10.6851 59.9347 10.9734 60.0658 11.2921C60.197 11.6107 60.2646 11.9533 60.2646 12.2993C60.2646 12.6454 60.197 12.9879 60.0658 13.3065C59.9347 13.6252 59.7426 13.9135 59.5009 14.1543L50.4498 23.2472C49.9563 23.7427 49.2931 24.0187 48.6034 24.0155H43.6512L33.8623 33.8505C33.8484 33.8647 33.8346 33.879 33.8199 33.8924C33.3287 34.3734 32.6757 34.6405 31.9974 34.6378Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9737 63.4464C26.8581 63.4406 21.8186 62.1709 17.2795 59.7442C13.5992 57.7863 10.3388 55.0878 7.69216 51.8089C5.15995 48.6742 3.25173 45.058 2.07605 41.1661C0.874759 37.2205 0.461056 33.066 0.859781 28.9519C1.25851 24.8378 2.46143 20.849 4.39635 17.225C9.84628 6.95636 20.433 0.57666 32.0261 0.57666C37.1418 0.582334 42.1813 1.85203 46.7203 4.27883L48.0304 4.9854L41.9273 11.1111L41.2349 10.8073C38.3108 9.5227 35.1639 8.86045 31.9841 8.86045C28.8044 8.86045 25.6575 9.5227 22.7333 10.8073C11.0949 15.9329 5.77268 29.6188 10.8733 41.3164C14.5492 49.7446 22.8418 55.1906 32.003 55.1898C35.1795 55.1921 38.323 54.5276 41.2411 53.2371C52.8841 48.1123 58.2064 34.4263 53.1057 22.728L52.8034 22.0349L58.9034 15.9044L59.6035 17.2242C62.0209 21.7559 63.2882 26.8414 63.2882 32.0103C63.2882 37.1793 62.0209 42.2647 59.6035 46.7965C54.1535 57.0667 43.5676 63.4464 31.9753 63.4464H31.9737Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9397 54.4685C29.279 54.468 26.6392 53.9859 24.141 53.0443C18.5641 50.9562 14.1328 46.8022 11.6624 41.3593C9.19212 35.9165 8.97978 29.8343 11.0639 24.2308C12.6837 19.8735 15.5891 16.1472 19.3727 13.5745C22.3406 11.5455 25.7312 10.2637 29.2702 9.83259C32.8092 9.40152 36.3971 9.83336 39.7437 11.0932L41.3478 11.6953L40.1376 12.913C39.8229 13.2263 39.5729 13.6018 39.4026 14.0169C39.2324 14.4319 39.1453 14.8781 39.1467 15.3286V19.8212L37.6012 19.14C35.8211 18.3574 33.9052 17.9539 31.9693 17.9539C30.0334 17.9539 28.1175 18.3574 26.3374 19.14C19.2596 22.2598 16.0222 30.5859 19.1272 37.7007C21.3637 42.8239 26.4074 46.1344 31.9751 46.1336C33.9089 46.1359 35.8229 45.732 37.5996 44.9468C41.0293 43.4355 43.6681 40.6741 45.0298 37.1674C45.7042 35.4486 46.0323 33.6069 45.9944 31.7538C45.9564 29.9008 45.5532 28.0749 44.809 26.3869L44.1312 24.8337H48.601L48.6095 25.9414V24.8337C49.5047 24.8373 50.3656 24.4793 51.0067 23.8367L52.2192 22.6214L52.8185 24.2331C54.6988 29.2798 54.6988 34.8632 52.8185 39.9098C51.1991 44.2675 48.2936 47.9942 44.5098 50.5669C40.7872 53.1176 36.4127 54.4753 31.9397 54.4685Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0104 45.2255C30.249 45.2266 28.5047 44.8692 26.8782 44.174C23.6401 42.7948 21.1305 40.232 19.8119 36.9571C19.1589 35.3519 18.8294 33.627 18.8436 31.8869C18.8577 30.1469 19.2152 28.4279 19.8942 26.8341C21.9622 21.9324 26.722 18.7636 32.0212 18.7636C33.784 18.7628 35.5296 19.121 37.1572 19.8175L38.6628 20.4584L29.5762 29.5844C29.2599 29.9007 29.0087 30.2792 28.8375 30.6974C28.6663 31.1155 28.5787 31.5649 28.5799 32.0186C28.581 32.4723 28.6709 32.9211 28.8442 33.3384C29.0175 33.7556 29.2706 34.1328 29.5885 34.4473C30.2393 35.0914 31.1081 35.45 32.0113 35.4474C32.9144 35.4447 33.7812 35.081 34.4284 34.4331L43.4988 25.3197L44.1366 26.8333C44.8213 28.463 45.1746 30.2196 45.1746 31.9949C45.1746 33.7703 44.8213 35.5268 44.1366 37.1565C42.0694 42.0582 37.3111 45.2255 32.0104 45.2255Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9975 34.6153C31.6507 34.6171 31.307 34.5481 30.9863 34.4123C30.6657 34.2765 30.3745 34.0765 30.1295 33.824C29.8917 33.5803 29.7037 33.2901 29.5766 32.9704C29.4496 32.6508 29.3861 32.3082 29.3899 31.9629C29.3937 31.6176 29.4647 31.2766 29.5988 30.96C29.7328 30.6434 29.9271 30.3576 30.1703 30.1195L39.9516 20.2845V15.3084C39.9511 14.962 40.0185 14.619 40.1497 14.2999C40.281 13.9809 40.4734 13.6923 40.7155 13.4514L49.7574 4.36014C50.2522 3.86652 50.9145 3.59045 51.6038 3.59045C52.2932 3.59045 52.9554 3.86652 53.4502 4.36014C53.6921 4.60084 53.8844 4.88917 54.0157 5.20794C54.1469 5.52672 54.2144 5.8694 54.2142 6.21558V9.65348H57.6338C58.3236 9.64981 58.987 9.92585 59.4802 10.4218C59.7215 10.6628 59.9134 10.9511 60.0444 11.2698C60.1754 11.5884 60.2429 11.9309 60.2429 12.2768C60.2429 12.6228 60.1754 12.9652 60.0444 13.2838C59.9134 13.6025 59.7215 13.8908 59.4802 14.1319L50.4329 23.2247C49.9398 23.7208 49.2763 23.9968 48.5865 23.993H43.6405L33.8608 33.828C33.8469 33.8422 33.8331 33.8565 33.8185 33.8699C33.3278 34.3509 32.6753 34.6179 31.9975 34.6153Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9736 63.4249C26.8631 63.4185 21.829 62.1487 17.2955 59.7227C13.6178 57.7641 10.3601 55.0656 7.71585 51.7874C5.18456 48.6526 3.2769 45.0367 2.10128 41.1454C0.900954 37.1996 0.487691 33.0451 0.886266 28.9311C1.28484 24.817 2.48705 20.8281 4.42081 17.2035C9.86536 6.93488 20.4429 0.555176 32.0259 0.555176C37.1364 0.561502 42.1705 1.83123 46.704 4.25735L48.0164 4.96075L41.921 11.0896L41.2286 10.7858C38.3074 9.50123 35.1633 8.83894 31.9863 8.83894C28.8092 8.83894 25.6652 9.50123 22.7439 10.7858C11.114 15.9114 5.79637 29.5973 10.8924 41.2949C14.5644 49.7231 22.8478 55.1692 32.0028 55.1684C35.1773 55.1707 38.3189 54.5062 41.2348 53.2156C52.8647 48.0908 58.1824 34.4049 53.0825 22.7097L52.7809 22.0165L58.8755 15.8877L59.5756 17.2074C61.991 21.7398 63.2572 26.8251 63.2572 31.9936C63.2572 37.1621 61.991 42.2473 59.5756 46.7797C54.1334 57.0491 43.5566 63.4249 31.9751 63.4249H31.9736Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9397 54.447C29.2813 54.4464 26.6437 53.9643 24.1479 53.0228C18.5764 50.9292 14.1466 46.7792 11.6848 41.3363C10.4646 38.6721 9.78214 35.7812 9.67874 32.8381C9.57533 29.8951 10.0531 26.961 11.0831 24.2132C12.7 19.8551 15.6034 16.1271 19.3858 13.553C22.3502 11.5241 25.7377 10.2422 29.2738 9.81112C32.8098 9.38002 36.3949 9.81187 39.7384 11.0717L41.3409 11.6738L40.1323 12.8915C39.8177 13.2049 39.5678 13.5804 39.3975 13.9955C39.2273 14.4105 39.1401 14.8566 39.1414 15.3071V19.7997L37.5973 19.1185C35.819 18.3359 33.9048 17.9324 31.9704 17.9324C30.0361 17.9324 28.1219 18.3359 26.3436 19.1185C19.2657 22.2383 16.0346 30.5644 19.1403 37.6792C21.3752 42.8024 26.4144 46.1129 31.9781 46.1122C33.9092 46.114 35.8203 45.7102 37.5943 44.9253C41.0216 43.4141 43.6581 40.6527 45.0183 37.1459C45.6921 35.427 46.0199 33.5853 45.982 31.7324C45.944 29.8794 45.5411 28.0536 44.7975 26.3654L44.1205 24.8122H48.5826L48.5918 25.9199V24.8122C49.4863 24.8156 50.3464 24.4576 50.9867 23.8152L52.1977 22.5999L52.8024 24.2132C54.681 29.2602 54.681 34.843 52.8024 39.8899C51.1841 44.2451 48.2812 47.9703 44.5005 50.543C40.7818 53.0948 36.4101 54.4536 31.9397 54.447Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0105 45.2036C30.2505 45.2044 28.5078 44.8468 26.8829 44.1513C23.6479 42.7721 21.1406 40.2093 19.8228 36.9344C19.1705 35.329 18.8415 33.6042 18.8556 31.8642C18.8697 30.1243 19.2268 28.4054 19.9051 26.8114C21.9715 21.9097 26.7267 18.7408 32.0213 18.7408C33.7812 18.7401 35.524 19.0977 37.1489 19.7932L38.6537 20.4341L29.5756 29.5601C29.2594 29.8765 29.0084 30.255 28.8373 30.6732C28.6663 31.0914 28.5787 31.5406 28.5799 31.9943C28.581 32.4479 28.6709 32.8967 28.844 33.314C29.0172 33.7312 29.2701 34.1084 29.5879 34.4231C30.2377 35.0672 31.1058 35.4259 32.0083 35.4233C32.9108 35.4206 33.7769 35.0568 34.4231 34.4088L43.4881 25.2986L44.1259 26.8122C44.8099 28.4421 45.1628 30.1986 45.1628 31.9738C45.1628 33.749 44.8099 35.5055 44.1259 37.1354C42.0603 42.0363 37.3043 45.2036 32.0105 45.2036Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9975 34.5933C31.6509 34.5952 31.3075 34.5262 30.9871 34.3904C30.6667 34.2545 30.3757 34.0546 30.1311 33.8021C29.8935 33.5582 29.7057 33.2679 29.5789 32.9483C29.452 32.6286 29.3887 32.2861 29.3926 31.9408C29.3964 31.5956 29.4674 31.2547 29.6014 30.9381C29.7354 30.6215 29.9296 30.3357 30.1726 30.0976L39.9431 20.2626V15.2865C39.9425 14.9404 40.0098 14.5976 40.1409 14.2788C40.2721 13.96 40.4644 13.6717 40.7063 13.4311L49.7421 4.33824C50.2359 3.84544 50.8969 3.56982 51.585 3.56982C52.2731 3.56982 52.9341 3.84544 53.4279 4.33824C53.6698 4.57897 53.862 4.86733 53.9931 5.1861C54.1242 5.50488 54.1915 5.84755 54.1911 6.19367V9.63158H57.6077C58.2963 9.62826 58.9583 9.90432 59.4503 10.3999C59.6915 10.6409 59.8832 10.9293 60.0142 11.2479C60.1451 11.5666 60.2126 11.909 60.2126 12.2549C60.2126 12.6008 60.1451 12.9432 60.0142 13.2619C59.8832 13.5805 59.6915 13.8689 59.4503 14.1099L50.4168 23.2028C49.9237 23.6989 49.2602 23.975 48.5704 23.9711H43.6305L33.86 33.8061L33.8177 33.848C33.3276 34.3288 32.6756 34.5959 31.9983 34.5933H31.9975Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9736 63.4024C26.8673 63.3959 21.8375 62.1261 17.3086 59.7002C13.6338 57.7412 10.3792 55.0427 7.73818 51.765C5.2082 48.6297 3.30209 45.0135 2.12823 41.1221C0.928893 37.1764 0.515947 33.0224 0.914106 28.9087C1.31226 24.795 2.51334 20.8062 4.44545 17.181C9.88538 6.91242 20.4537 0.532715 32.0259 0.532715C37.1322 0.539154 42.1621 1.80894 46.6909 4.23489L47.9988 4.93829L41.9118 11.0672L41.2233 10.7618C38.3049 9.47722 35.1634 8.81492 31.989 8.81492C28.8146 8.81492 25.6731 9.47722 22.7547 10.7618C11.1325 15.8889 5.81947 29.5749 10.9117 41.2724C14.5798 49.7006 22.8586 55.1499 32.0028 55.1499C35.1739 55.152 38.3118 54.4875 41.224 53.1971C52.8455 48.0683 58.1601 34.3824 53.0671 22.684L52.7655 21.9909L58.8525 15.862L59.5518 17.1818C61.965 21.7149 63.2299 26.7999 63.2299 31.968C63.2299 37.136 61.965 42.221 59.5518 46.7541C54.1134 57.0227 43.5466 63.4024 31.9752 63.4024H31.9736Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9398 54.4241C29.2836 54.4235 26.6483 53.9414 24.1549 52.9999C18.588 50.9063 14.1644 46.7563 11.6995 41.3134C10.4802 38.6478 9.79864 35.7559 9.6959 32.812C9.59316 29.8682 10.0714 26.9334 11.1017 24.1848C12.7175 19.83 15.6181 16.1046 19.3966 13.5309C22.3576 11.5021 25.742 10.2202 29.2751 9.78904C32.8082 9.35791 36.3904 9.78977 39.7308 11.0496L41.3317 11.6517L40.1239 12.8694C39.8096 13.183 39.5601 13.5586 39.3901 13.9736C39.2201 14.3887 39.1332 14.8347 39.1345 15.285V19.7776L37.5912 19.0964C35.8148 18.3138 33.9023 17.9104 31.9698 17.9104C30.0372 17.9104 28.1247 18.3138 26.3483 19.0964C19.2804 22.2154 16.0515 30.5424 19.155 37.6563C21.3861 42.7795 26.4221 46.0901 31.9813 46.0893C33.9124 46.0918 35.8237 45.6879 37.5974 44.9024C41.0217 43.3912 43.6559 40.6298 45.0153 37.123C45.6883 35.4039 46.0158 33.5624 45.9778 31.7095C45.9399 29.8567 45.5373 28.0309 44.7945 26.3425L44.1182 24.7893H48.5804L48.5896 25.897V24.7893C49.4834 24.7927 50.3427 24.4346 50.9822 23.7924L52.1924 22.577L52.7909 24.1888C54.6673 29.2361 54.6673 34.8181 52.7909 39.8654C51.1733 44.2214 48.2702 47.9473 44.4891 50.5202C40.7744 53.0715 36.4066 54.4303 31.9398 54.4241Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0105 45.1805C30.252 45.1816 28.5108 44.8242 26.8875 44.1289C23.6563 42.7498 21.1506 40.187 19.8335 36.9121C19.182 35.3064 18.8534 33.5817 18.8676 31.8419C18.8817 30.1021 19.2383 28.3833 19.9158 26.7891C21.98 21.8874 26.7313 18.7185 32.0212 18.7185C33.7797 18.7178 35.5209 19.0754 37.1442 19.7708L38.6475 20.4117L29.5809 29.541C29.2651 29.8575 29.0143 30.2361 28.8434 30.6542C28.6726 31.0724 28.5851 31.5215 28.5863 31.9751C28.5874 32.4287 28.6771 32.8774 28.8501 33.2946C29.0231 33.7118 29.2758 34.089 29.5932 34.4039C30.2422 35.0479 31.1095 35.4066 32.0114 35.404C32.9132 35.4013 33.7785 35.0375 34.4239 34.3896L43.4781 25.277L44.1151 26.7907C44.7988 28.4206 45.1514 30.1771 45.1514 31.9523C45.1514 33.7274 44.7988 35.4839 44.1151 37.1139C42.051 42.0155 37.2996 45.1805 32.0105 45.1805Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9975 34.5707C31.6513 34.5725 31.3082 34.5034 30.9882 34.3676C30.6681 34.2318 30.3776 34.0318 30.1334 33.7794C29.8959 33.5355 29.7082 33.2452 29.5815 32.9256C29.4547 32.606 29.3913 32.2635 29.3951 31.9183C29.3989 31.5732 29.4698 31.2323 29.6036 30.9157C29.7374 30.5991 29.9314 30.3132 30.1742 30.0749L39.9369 20.2399V15.2638C39.9365 14.9177 40.0039 14.575 40.135 14.2563C40.2661 13.9375 40.4583 13.6491 40.7001 13.4084L49.7259 4.31555C50.2191 3.82277 50.8797 3.54712 51.5673 3.54712C52.2549 3.54712 52.9155 3.82277 53.4087 4.31555C53.6503 4.55653 53.8424 4.84506 53.9734 5.16396C54.1043 5.48286 54.1716 5.8256 54.1711 6.17178V9.60968H57.5846C58.2726 9.60679 58.9341 9.88282 59.4256 10.378C59.6666 10.6191 59.8582 10.9076 59.9891 11.2262C60.1199 11.5448 60.1873 11.8872 60.1873 12.233C60.1873 12.5789 60.1199 12.9212 59.9891 13.2398C59.8582 13.5585 59.6666 13.8469 59.4256 14.0881L50.3998 23.1833C49.9083 23.6784 49.2469 23.9544 48.5588 23.9515H43.6197L33.8569 33.7858L33.8154 33.8277C33.3258 34.3083 32.6743 34.5754 31.9975 34.573V34.5707Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9735 63.3786C26.8715 63.3719 21.8459 62.1021 17.3217 59.6764C13.6497 57.7175 10.3979 55.0195 7.75966 51.7427C5.23134 48.6068 3.32634 44.9908 2.15278 41.0999C0.954711 37.154 0.542292 33.0003 0.940164 28.8868C1.33804 24.7734 2.53803 20.7846 4.46846 17.1588C9.90532 6.8902 20.4628 0.510498 32.0259 0.510498C37.1277 0.516896 42.1531 1.78675 46.677 4.21267L47.9848 4.91608L41.901 11.045L41.2086 10.7411C38.2931 9.45664 35.1545 8.79433 31.9828 8.79433C28.8111 8.79433 25.6724 9.45664 22.757 10.7411C11.1539 15.8667 5.84556 29.5527 10.9301 41.2502C14.5959 49.6784 22.867 55.1245 32.0028 55.1237C35.1707 55.126 38.3057 54.4618 41.2147 53.1717C52.827 48.0461 58.1354 34.3602 53.0478 22.6618L52.7462 21.9687L58.8309 15.8398L59.5294 17.1596C61.9404 21.6934 63.2041 26.7781 63.2041 31.9457C63.2041 37.1134 61.9404 42.198 59.5294 46.7319C54.0941 57.0005 43.5358 63.3786 31.9751 63.3786H31.9735Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9396 54.4022C29.286 54.4016 26.6532 53.9195 24.1625 52.978C18.6002 50.8844 14.1804 46.7344 11.7178 41.2915C10.4997 38.6256 9.81872 35.7337 9.71599 32.7901C9.61326 29.8464 10.0909 26.9117 11.12 24.1629C12.7336 19.8089 15.6314 16.0832 19.4073 13.5082C22.3648 11.4795 25.7461 10.1975 29.2763 9.76635C32.8064 9.33519 36.3857 9.76707 39.7229 11.0269L41.3239 11.6322L40.1168 12.8499C39.8027 13.1635 39.5532 13.5391 39.3834 13.9542C39.2135 14.3692 39.1268 14.8152 39.1283 15.2655V19.7581L37.5896 19.0769C35.815 18.2944 33.9043 17.891 31.9735 17.891C30.0427 17.891 28.132 18.2944 26.3574 19.0769C19.2949 22.1967 16.0699 30.5228 19.1665 37.6368C21.3975 42.7608 26.4274 46.0713 31.9812 46.0705C33.9101 46.0728 35.819 45.6688 37.5904 44.8837C41.0116 43.3724 43.6435 40.611 45.0013 37.1043C45.6734 35.3836 45.9997 33.5408 45.9605 31.6869C45.9214 29.833 45.5176 28.0065 44.7736 26.3174L44.1004 24.7666H48.5625L48.571 25.8743V24.7666C49.4644 24.77 50.3232 24.4119 50.9621 23.7697L52.1669 22.5512L52.7654 24.1629C54.6402 29.2106 54.6402 34.7919 52.7654 39.8396C51.1524 44.195 48.2546 47.9221 44.4782 50.4983C40.7676 53.0496 36.4031 54.4085 31.9396 54.4022Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0103 45.1595C30.2533 45.1606 28.5136 44.8031 26.8919 44.1079C23.6607 42.7288 21.1596 40.166 19.8441 36.8911C19.1929 35.2854 18.8642 33.5609 18.878 31.8212C18.8917 30.0815 19.2476 28.3626 19.9241 26.7681C21.9859 21.8664 26.7327 18.6975 32.018 18.6975C33.7749 18.6968 35.5146 19.0544 37.1363 19.7498L38.638 20.3907L29.5823 29.5184C29.2666 29.8351 29.0159 30.2137 28.8452 30.6318C28.6744 31.05 28.5871 31.4992 28.5883 31.9527C28.5895 32.4062 28.6793 32.8549 28.8523 33.2721C29.0253 33.6892 29.2779 34.0664 29.5953 34.3813C30.2433 35.0254 31.11 35.3842 32.0111 35.3816C32.9123 35.3789 33.7769 35.015 34.4213 34.3671L43.4656 25.2544L44.1026 26.7681C44.7855 28.3983 45.1377 30.1546 45.1377 31.9297C45.1377 33.7047 44.7855 35.4611 44.1026 37.0913C42.0416 41.9922 37.2948 45.1595 32.0103 45.1595Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9974 34.5494C31.6515 34.5511 31.3086 34.482 30.9889 34.3462C30.6691 34.2104 30.3788 34.0105 30.1349 33.7582C29.8975 33.5142 29.7099 33.2238 29.5832 32.9042C29.4565 32.5846 29.3932 32.2422 29.397 31.897C29.4007 31.5519 29.4716 31.211 29.6053 30.8945C29.739 30.5779 29.933 30.292 30.1757 30.0536L39.9269 20.2178V15.2425C39.9274 14.896 39.9958 14.5531 40.128 14.2343C40.2602 13.9155 40.4534 13.6274 40.6962 13.3871L49.7097 4.29427C50.2024 3.80169 50.8624 3.52612 51.5496 3.52612C52.2367 3.52612 52.8967 3.80169 53.3894 4.29427C53.6307 4.5353 53.8225 4.82375 53.9533 5.14248C54.0842 5.46121 54.1514 5.80373 54.151 6.1497V9.58761H57.5615C58.249 9.58513 58.9098 9.86113 59.401 10.3559C59.6417 10.5972 59.833 10.8857 59.9637 11.2043C60.0943 11.5229 60.1616 11.8652 60.1616 12.2109C60.1616 12.5567 60.0943 12.899 59.9637 13.2176C59.833 13.5362 59.6417 13.8246 59.401 14.066L50.3829 23.1588C49.8923 23.654 49.2316 23.9301 48.5442 23.9271H43.6089L33.8554 33.7621L33.8138 33.804C33.3248 34.2847 32.6738 34.5518 31.9974 34.5494Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9737 63.3575C26.8761 63.3519 21.8548 62.0834 17.3349 59.6593C13.6653 57.6979 10.4165 54.998 7.78133 51.7201C5.25576 48.5834 3.3534 44.9675 2.18215 41.0772C0.98487 37.1313 0.572665 32.9778 0.970123 28.8645C1.36758 24.7513 2.56656 20.7625 4.49552 17.1361C9.92315 6.8675 20.4745 0.487793 32.026 0.487793C37.1236 0.494398 42.1446 1.76402 46.664 4.18918L47.9719 4.89337L41.8942 11.0223L41.2072 10.7184C38.2945 9.43419 35.1585 8.77198 31.9895 8.77198C28.8204 8.77198 25.6844 9.43419 22.7717 10.7184C11.1702 15.844 5.86724 29.53 10.9494 41.2275C14.6114 49.6557 22.874 55.1018 32.0021 55.101C35.1679 55.103 38.3006 54.4385 41.2072 53.1482C52.8087 48.0234 58.1117 34.3399 53.0295 22.6391L52.7279 21.946L58.8056 15.8171L59.5034 17.1369C61.9126 21.6714 63.1751 26.7558 63.1751 31.923C63.1751 37.0903 61.9126 42.1747 59.5034 46.7092C54.0742 56.9778 43.5259 63.3575 31.9752 63.3575H31.9737Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9398 54.3794C29.2884 54.3788 26.6579 53.8966 24.1695 52.9551C18.6165 50.8631 14.1967 46.7131 11.7356 41.2702C10.5186 38.604 9.83847 35.7121 9.73601 32.7686C9.63355 29.8251 10.1109 26.8906 11.1394 24.1417C12.7503 19.7879 15.6454 16.0617 19.4189 13.4853C22.373 11.4568 25.7513 10.1747 29.2785 9.74354C32.8057 9.31235 36.382 9.74424 39.7162 11.004L41.3156 11.6062L40.1093 12.8239C39.7955 13.1378 39.5464 13.5135 39.3767 13.9284C39.207 14.3434 39.1202 14.7893 39.1215 15.2395V19.7321L37.5828 19.0509C35.8098 18.2685 33.9006 17.8651 31.9713 17.8651C30.042 17.8651 28.1328 18.2685 26.3598 19.0509C19.3028 22.1707 16.0777 30.4968 19.1743 37.6108C21.4053 42.7348 26.4283 46.0453 31.9775 46.0445C33.9046 46.0468 35.8118 45.6429 37.5813 44.8577C40.9994 43.3464 43.629 40.585 44.9861 37.0783C45.6578 35.3589 45.9845 33.5175 45.9466 31.6648C45.9086 29.8122 45.5068 27.9866 44.7653 26.2978L44.0898 24.7446H48.5434L48.5527 25.8523V24.7446C49.444 24.7477 50.3008 24.3899 50.9376 23.7484L52.1462 22.5331L52.7432 24.1448C54.6165 29.1928 54.6165 34.7735 52.7432 39.8215C51.1325 44.174 48.2383 47.8992 44.466 50.4754C40.7598 53.0263 36.3994 54.3853 31.9398 54.3794Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0105 45.1375C30.2551 45.1385 28.5169 44.7811 26.8968 44.0859C23.6702 42.7068 21.1699 40.144 19.8559 36.8691C19.2048 35.2631 18.8764 33.5381 18.8906 31.7982C18.9047 30.0582 19.2611 28.3391 19.9382 26.7445C22.0015 21.8444 26.7414 18.674 32.0213 18.674C33.7768 18.6733 35.5149 19.031 37.135 19.7263L38.6352 20.3672L29.5848 29.4964C29.2693 29.8132 29.0188 30.1919 28.8482 30.61C28.6776 31.0281 28.5903 31.4773 28.5915 31.9307C28.5927 32.3842 28.6824 32.8328 28.8553 33.25C29.0282 33.6671 29.2807 34.0444 29.5979 34.3593C30.245 35.0033 31.1109 35.3621 32.0114 35.3595C32.9118 35.3568 33.7757 34.9929 34.4193 34.3451L43.4574 25.2325L44.0936 26.7445C44.7759 28.3749 45.1278 30.1312 45.1278 31.9061C45.1278 33.6811 44.7759 35.4374 44.0936 37.0677C42.0333 41.9702 37.2904 45.1375 32.0105 45.1375Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9975 34.5271C31.6518 34.5288 31.3092 34.4598 30.9896 34.3239C30.6701 34.1881 30.3801 33.9882 30.1365 33.7358C29.8994 33.4917 29.7121 33.2013 29.5856 32.8817C29.459 32.5621 29.3958 32.2198 29.3996 31.8747C29.4034 31.5297 29.4741 31.1889 29.6077 30.8724C29.7412 30.5558 29.9349 30.2699 30.1773 30.0313L39.927 20.1955V15.2194C39.9266 14.8736 39.9938 14.5311 40.1246 14.2125C40.2555 13.8939 40.4473 13.6056 40.6886 13.3648L49.6975 4.27353C50.1894 3.78084 50.8488 3.50513 51.5354 3.50513C52.222 3.50513 52.8815 3.78084 53.3734 4.27353C53.6146 4.51461 53.8062 4.80308 53.9369 5.12182C54.0676 5.44055 54.1347 5.78305 54.1342 6.12897V9.56687H57.5416C58.2287 9.56396 58.8892 9.84005 59.3795 10.3352C59.6201 10.5766 59.8112 10.8651 59.9418 11.1837C60.0723 11.5023 60.1395 11.8445 60.1395 12.1902C60.1395 12.5359 60.0723 12.8781 59.9418 13.1967C59.8112 13.5153 59.6201 13.8038 59.3795 14.0452L50.3668 23.1365C49.8765 23.6317 49.216 23.9078 48.5289 23.9048H43.599L33.8539 33.7398L33.8124 33.7817C33.3238 34.262 32.6734 34.5291 31.9975 34.5271Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9736 63.3362C26.8801 63.3294 21.8633 62.0595 17.3486 59.6341C13.6818 57.6739 10.4357 54.9753 7.80351 51.6988C5.27967 48.5616 3.37867 44.9457 2.20817 41.056C1.01224 37.1099 0.600605 32.9567 0.997775 28.8437C1.39494 24.7307 2.5928 20.7419 4.52001 17.1149C9.94532 6.84625 20.4844 0.466553 32.0259 0.466553C37.1193 0.473298 42.136 1.74297 46.6508 4.16793L47.9587 4.87213L41.8856 10.9994L41.1986 10.6956C38.2889 9.41142 35.1556 8.7492 31.9893 8.7492C28.823 8.7492 25.6898 9.41142 22.7801 10.6956C11.1886 15.8259 5.89019 29.5087 10.9685 41.2063C14.6267 49.6345 22.8831 55.0805 32.0028 55.0797C35.1658 55.0818 38.2957 54.4172 41.1994 53.127C52.7901 48.0022 58.0892 34.3162 53.0109 22.6179L52.7101 21.9248L58.7832 15.7959L59.4802 17.1157C61.8874 21.6507 63.1489 26.7349 63.1489 31.9018C63.1489 37.0687 61.8874 42.1528 59.4802 46.6879C54.0541 56.9565 43.515 63.3362 31.9751 63.3362H31.9736Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9398 54.3591C29.2906 54.3585 26.6624 53.8763 24.1765 52.9348C18.6242 50.842 14.2129 46.692 11.7541 41.2491C10.5382 38.5825 9.85868 35.6908 9.75623 32.7474C9.65377 29.8041 10.1305 26.8698 11.1579 24.1206C12.7669 19.7678 15.6595 16.0417 19.4305 13.4642C22.3811 11.4358 25.7563 10.1537 29.2805 9.72244C32.8048 9.29121 36.3782 9.72313 39.7092 10.9829L41.3071 11.5851L40.1024 12.8028C39.7887 13.1167 39.5397 13.4924 39.3701 13.9074C39.2005 14.3224 39.1139 14.7683 39.1153 15.2184V19.711L37.5767 19.0298C35.8054 18.2473 33.8979 17.8437 31.9702 17.8437C30.0425 17.8437 28.1349 18.2473 26.3637 19.0298C19.3143 22.1496 16.0947 30.4757 19.1858 37.5897C21.4123 42.7137 26.4337 46.0242 31.9775 46.0234C33.9031 46.0257 35.8088 45.6217 37.5767 44.8366C40.991 43.3253 43.6182 40.5639 44.9738 37.0572C45.6461 35.3368 45.9732 33.4944 45.9354 31.6406C45.8976 29.7869 45.4956 27.9602 44.7537 26.2703L44.0813 24.7203H48.5312L48.5404 25.828V24.7203C49.4319 24.7242 50.2889 24.3662 50.9253 23.7241L52.1324 22.5088L52.7294 24.1206C54.6014 29.1688 54.6014 34.749 52.7294 39.7972C51.1203 44.1504 48.2274 47.8768 44.456 50.4543C40.7536 53.0054 36.3963 54.3647 31.9398 54.3591Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0105 45.1149C30.2565 45.1159 28.5198 44.7585 26.9013 44.0634C23.6778 42.6842 21.1798 40.1214 19.8666 36.8465C19.2165 35.2405 18.8888 33.5159 18.9029 31.7764C18.9171 30.0368 19.2728 28.3181 19.9489 26.7235C22.0069 21.8218 26.7459 18.653 32.0212 18.653C33.7752 18.6523 35.5119 19.01 37.1303 19.7053L38.6298 20.3462L29.5871 29.4738C29.2719 29.7908 29.0217 30.1695 28.8513 30.5876C28.6808 31.0056 28.5936 31.4546 28.5947 31.908C28.5959 32.3613 28.6854 32.8098 28.8579 33.227C29.0305 33.6441 29.2826 34.0215 29.5994 34.3367C30.2459 34.9807 31.1113 35.3396 32.0113 35.3369C32.9114 35.3343 33.7748 34.9703 34.4177 34.3225L43.4473 25.2099L44.0812 26.7235C44.7626 28.3541 45.114 30.1103 45.114 31.8851C45.114 33.6599 44.7626 35.4161 44.0812 37.0467C42.024 41.9476 37.2857 45.1149 32.0105 45.1149Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9974 34.5046C31.6519 34.5063 31.3096 34.4372 30.9903 34.3014C30.6711 34.1655 30.3813 33.9657 30.138 33.7134C29.9011 33.4691 29.7141 33.1787 29.5877 32.8591C29.4614 32.5395 29.3982 32.1973 29.402 31.8523C29.4058 31.5074 29.4765 31.1667 29.6098 30.8501C29.7432 30.5336 29.9366 30.2476 30.1787 30.0089L39.9154 20.177V15.197C39.9151 14.8512 39.9824 14.5088 40.1132 14.1902C40.2441 13.8716 40.4358 13.5833 40.677 13.3423L49.6782 4.24951C50.1694 3.75683 50.8284 3.48108 51.5146 3.48108C52.2007 3.48108 52.8597 3.75683 53.3509 4.24951C53.5918 4.49071 53.7832 4.7791 53.9139 5.09764C54.0446 5.41618 54.1119 5.75842 54.1118 6.10416V9.54285H57.5153C58.202 9.54001 58.862 9.81613 59.3517 10.3111C59.5921 10.5527 59.7831 10.8413 59.9135 11.1598C60.0439 11.4784 60.1111 11.8205 60.1111 12.1662C60.1111 12.5118 60.0439 12.854 59.9135 13.1725C59.7831 13.4911 59.5921 13.7797 59.3517 14.0212L50.3506 23.1141C49.8608 23.6091 49.2008 23.8852 48.5142 23.8823H43.5905L33.8492 33.7174L33.8077 33.7593C33.3198 34.2398 32.6698 34.507 31.9944 34.5046H31.9974Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9738 63.3131C26.8844 63.3062 21.8719 62.0362 17.3619 59.6109C13.6968 57.6504 10.4525 54.9518 7.82218 51.6756C5.30164 48.5375 3.40357 44.9217 2.2353 41.0328C1.04062 37.0865 0.629438 32.9335 1.02619 28.8206C1.42293 24.7077 2.61952 20.7188 4.54483 17.0909C9.96476 6.82306 20.4953 0.443359 32.0261 0.443359C37.1153 0.450211 42.1278 1.71994 46.6379 4.14474L47.9458 4.84894L41.8781 10.9778L41.1919 10.674C38.2851 9.38976 35.1547 8.72748 31.9911 8.72748C28.8275 8.72748 25.697 9.38976 22.7903 10.674C11.208 15.7996 5.91424 29.4855 10.9872 41.1831C14.6431 49.6105 22.8918 55.0573 32.003 55.0566C35.1633 55.0586 38.2904 54.394 41.1911 53.1038C52.7749 47.979 58.0648 34.293 52.9911 22.5947L52.691 21.9016L58.7587 15.7727L59.455 17.0909C61.8599 21.6267 63.1201 26.7106 63.1201 31.877C63.1201 37.0434 61.8599 42.1273 59.455 46.6632C54.0343 56.9334 43.5052 63.3131 31.9753 63.3131H31.9738Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9397 54.3351C29.2928 54.3345 26.6669 53.8523 24.1833 52.9109C18.6364 50.8189 14.2313 46.6688 11.7694 41.2236C10.5544 38.5567 9.87537 35.665 9.77305 32.7218C9.67073 29.7785 10.1472 26.8443 11.1739 24.095C12.7817 19.7437 15.6723 16.0185 19.4412 13.4411C22.3885 11.4127 25.7607 10.1305 29.2821 9.69926C32.8035 9.268 36.3742 9.69994 39.7022 10.9598L41.2986 11.5619L40.0946 12.7796C39.7817 13.0934 39.5332 13.4686 39.3639 13.883C39.1946 14.2974 39.108 14.7426 39.1091 15.1921V19.6847L37.5704 19.0034C35.801 18.2209 33.895 17.8174 31.9689 17.8174C30.0428 17.8174 28.1369 18.2209 26.3674 19.0034C19.325 22.1233 16.1084 30.4494 19.1965 37.5634C21.4214 42.6874 26.4375 45.9979 31.9774 45.9971C33.9011 45.9992 35.8047 45.5953 37.5704 44.8102C40.9824 43.299 43.6074 40.5376 44.9614 37.0309C45.6319 35.3112 45.9581 33.4699 45.9203 31.6175C45.8825 29.7651 45.4815 27.9396 44.7413 26.2503L44.0674 24.6971H48.5134L48.5218 25.8049V24.6971C49.4133 24.701 50.2703 24.3431 50.9068 23.701L52.1131 22.4856L52.7093 24.0974C54.5788 29.1461 54.5788 34.7254 52.7093 39.7741C51.1025 44.126 48.2127 47.8523 44.4444 50.4312C40.746 52.9817 36.3925 54.3409 31.9397 54.3351Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0104 45.0928C30.2579 45.0939 28.5227 44.7362 26.9059 44.0405C23.6855 42.6621 21.1891 40.0993 19.8773 36.8244C19.228 35.2182 18.9007 33.4937 18.9148 31.7543C18.929 30.0149 19.2843 28.2962 19.9597 26.7014C22.0161 21.7997 26.7505 18.6309 32.0212 18.6309C33.7737 18.6302 35.5089 18.9879 37.1257 19.6832L38.6236 20.3241L29.5894 29.4517C29.2744 29.7688 29.0244 30.1475 28.854 30.5656C28.6837 30.9837 28.5966 31.4326 28.5977 31.8859C28.5989 32.3392 28.6883 32.7876 28.8607 33.2047C29.0332 33.6219 29.2851 33.9993 29.6017 34.3147C30.2473 34.9585 31.112 35.3174 32.0113 35.3147C32.9106 35.312 33.7733 34.9481 34.4154 34.3004L43.4365 25.1878L44.072 26.7014C44.7531 28.3321 45.1043 30.0883 45.1043 31.863C45.1043 33.6378 44.7531 35.3939 44.072 37.0246C42.0148 41.9255 37.2804 45.0928 32.0104 45.0928Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9975 34.4823C31.6522 34.484 31.31 34.4149 30.991 34.279C30.672 34.1432 30.3826 33.9433 30.1395 33.691C29.9028 33.4467 29.7158 33.1562 29.5895 32.8367C29.4632 32.5171 29.4001 32.1749 29.4039 31.8299C29.4077 31.485 29.4783 31.1443 29.6116 30.8278C29.7449 30.5113 29.9383 30.2253 30.1803 29.9865L39.9085 20.1515V15.1746C39.9081 14.8288 39.9752 14.4865 40.1059 14.1679C40.2366 13.8493 40.4282 13.5609 40.6693 13.32L49.662 4.22713C50.1528 3.73465 50.8112 3.45898 51.4969 3.45898C52.1825 3.45898 52.841 3.73465 53.3317 4.22713C53.5726 4.46826 53.764 4.75665 53.8946 5.07522C54.0251 5.39379 54.0922 5.73606 54.0918 6.08178V9.52047H57.4923C58.1782 9.51698 58.8376 9.79226 59.3271 10.2864C59.5673 10.5281 59.7581 10.8166 59.8884 11.1352C60.0187 11.4537 60.0858 11.7958 60.0858 12.1414C60.0858 12.487 60.0187 12.8291 59.8884 13.1477C59.7581 13.4662 59.5673 13.7548 59.3271 13.9965L50.3337 23.0917C49.8449 23.5868 49.1856 23.863 48.4996 23.86H43.5759L33.8492 33.695C33.8354 33.7092 33.8215 33.7235 33.8069 33.7369C33.32 34.2173 32.6707 34.4846 31.9959 34.4823H31.9975Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9737 63.2916C26.8886 63.2842 21.8804 62.0142 17.3749 59.5894C13.7133 57.6283 10.4727 54.9297 7.84597 51.6541C5.32653 48.5158 3.42952 44.9 2.26217 41.0113C1.06846 37.0651 0.657644 32.9125 1.05411 28.7999C1.45058 24.6873 2.64624 20.6985 4.57016 17.0702C9.98471 6.80553 20.5053 0.421875 32.026 0.421875C37.1111 0.42877 42.1193 1.69856 46.6248 4.12326L47.9281 4.82745L41.8657 10.9563L41.1803 10.6525C38.2761 9.36812 35.148 8.70573 31.9868 8.70573C28.8255 8.70573 25.6974 9.36812 22.7933 10.6525C11.2264 15.7781 5.93726 29.464 11.0064 41.1616C14.6584 49.5898 22.8995 55.0359 32.0029 55.0351C35.1605 55.0371 38.2849 54.3724 41.1826 53.0823C52.7533 47.9575 58.0425 34.2716 52.9733 22.5732L52.6733 21.8801L58.7348 15.7512L59.4272 17.071C61.8302 21.6075 63.0892 26.6911 63.0892 31.8571C63.0892 37.0231 61.8302 42.1067 59.4272 46.6433C54.0142 56.9119 43.4944 63.2916 31.9752 63.2916H31.9737Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9399 54.3136C29.2953 54.3129 26.6716 53.8307 24.1905 52.8894C18.6513 50.7958 14.2453 46.6457 11.7904 41.2029C10.5766 38.5356 9.89834 35.644 9.79616 32.7009C9.69397 29.7578 10.17 26.8238 11.1957 24.0743C12.8004 19.724 15.6875 15.9986 19.4529 13.4196C22.3969 11.3912 25.7661 10.1088 29.2847 9.67753C32.8034 9.24624 36.3713 9.67827 39.6963 10.9383L41.2911 11.5404L40.0887 12.7581C39.7755 13.0724 39.5269 13.4481 39.3576 13.8631C39.1883 14.278 39.1018 14.7237 39.1032 15.1737V19.6663L37.5645 18.9851C35.7968 18.2026 33.8925 17.799 31.968 17.799C30.0435 17.799 28.1392 18.2026 26.3715 18.9851C19.3375 22.1049 16.1233 30.431 19.209 37.545C21.4316 42.669 26.4407 45.9795 31.9799 45.9787C33.9021 45.9808 35.8042 45.5768 37.5683 44.7919C40.9765 43.2806 43.5991 40.5192 44.9539 37.0125C45.624 35.2927 45.95 33.4516 45.9123 31.5993C45.8746 29.7469 45.4741 27.9214 44.7346 26.232L44.0607 24.6788H48.5021L48.5113 25.7865V24.6788C49.4029 24.6835 50.2602 24.3254 50.8962 23.6826L52.101 22.4673L52.6972 24.079C54.5651 29.128 54.5651 34.7067 52.6972 39.7557C51.0903 44.1065 48.2007 47.8317 44.4331 50.4096C40.739 52.96 36.3892 54.3193 31.9399 54.3136Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0105 45.0709C30.2594 45.072 28.5257 44.7143 26.9106 44.0186C23.6932 42.6402 21.1991 40.0774 19.8881 36.8025C19.2392 35.1961 18.912 33.4715 18.9262 31.732C18.9403 29.9925 19.2955 28.2738 19.9704 26.6787C22.0253 21.7778 26.7552 18.6082 32.0212 18.6082C33.7723 18.6071 35.506 18.9649 37.1211 19.6605L38.619 20.3038L29.5917 29.4298C29.2769 29.747 29.027 30.1258 28.8568 30.5438C28.6866 30.9619 28.5996 31.4107 28.6007 31.864C28.6018 32.3172 28.6912 32.7656 28.8635 33.1827C29.0358 33.5998 29.2876 33.9773 29.604 34.2927C30.2487 34.9367 31.1127 35.2957 32.0114 35.293C32.91 35.2903 33.772 34.9262 34.4131 34.2785L43.4265 25.1659L44.0605 26.6787C44.7411 28.3096 45.0922 30.0659 45.0922 31.8407C45.0922 33.6155 44.7411 35.3718 44.0605 37.0027C42.0041 41.9036 37.2758 45.0709 32.0105 45.0709Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9973 34.4608C31.6523 34.4624 31.3104 34.3933 30.9916 34.2574C30.6729 34.1216 30.3837 33.9217 30.1409 33.6696C29.9045 33.425 29.7178 33.1345 29.5916 32.815C29.4655 32.4955 29.4025 32.1533 29.4063 31.8085C29.4101 31.4637 29.4806 31.1231 29.6137 30.8066C29.7469 30.49 29.9399 30.204 30.1817 29.965L39.9014 20.13V15.1532C39.9009 14.8075 39.9678 14.4652 40.0983 14.1467C40.2287 13.8281 40.42 13.5397 40.6607 13.2985L49.6465 4.20567C50.1364 3.71307 50.7943 3.43726 51.4794 3.43726C52.1646 3.43726 52.8225 3.71307 53.3124 4.20567C53.553 4.44695 53.7442 4.73539 53.8746 5.05394C54.005 5.37248 54.072 5.71468 54.0717 6.06032V9.49585H57.4675C58.1532 9.49315 58.8122 9.76931 59.3008 10.2641C59.5408 10.5059 59.7315 10.7945 59.8616 11.1131C59.9918 11.4316 60.0588 11.7737 60.0588 12.1192C60.0588 12.4647 59.9918 12.8067 59.8616 13.1253C59.7315 13.4438 59.5408 13.7324 59.3008 13.9742L50.3174 23.0702C49.8288 23.5652 49.1698 23.8414 48.484 23.8385H43.5673L33.8491 33.6735C33.8352 33.6878 33.8214 33.702 33.8068 33.7155C33.3204 34.1959 32.6716 34.4631 31.9973 34.4608Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9735 63.2691C26.8918 63.262 21.8871 61.9919 17.3855 59.5669C13.7277 57.6051 10.491 54.9064 7.86812 51.6317C4.0246 46.8636 1.6584 41.0204 1.07478 34.856C0.491161 28.6916 1.71689 22.4888 4.59385 17.0477C10.0045 6.77911 20.5159 0.39624 32.0251 0.39624C37.1062 0.403174 42.1104 1.67302 46.6116 4.09762L47.9194 4.80182L41.8625 10.9307L41.1778 10.6269C38.2767 9.34278 35.1515 8.6805 31.9932 8.6805C28.8348 8.6805 25.7096 9.34278 22.8085 10.6269C11.2447 15.7556 5.96095 29.4416 11.0247 41.1391C14.6736 49.5673 22.9077 55.0134 32.0028 55.0126C35.1578 55.0145 38.2797 54.3499 41.1747 53.0598C52.7331 47.935 58.0177 34.2491 52.9532 22.5507L52.6531 21.8576L58.7101 15.7287L59.4025 17.0485C61.8029 21.5858 63.0605 26.6691 63.0605 31.8346C63.0605 37.0002 61.8029 42.0834 59.4025 46.6208C53.9948 56.8902 43.4842 63.2691 31.9751 63.2691H31.9735Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9398 54.2913C29.2974 54.2905 26.6761 53.8083 24.1972 52.867C18.6581 50.7734 14.2606 46.6234 11.811 41.1805C10.5981 38.5135 9.92009 35.6224 9.81752 32.6799C9.71494 29.7374 10.1899 26.8039 11.214 24.0543C12.816 19.7044 15.7001 15.9782 19.4628 13.3972C22.4037 11.3695 25.7702 10.0878 29.286 9.65713C32.8018 9.2265 36.3668 9.65925 39.6885 10.9199L41.2817 11.522L40.0808 12.7397C39.7678 13.054 39.5193 13.4298 39.3501 13.8448C39.181 14.2597 39.0946 14.7054 39.0961 15.1553V19.648L37.5613 18.9667C35.7954 18.1842 33.8929 17.7807 31.9702 17.7807C30.0474 17.7807 28.1449 18.1842 26.3791 18.9667C19.3489 22.0865 16.1377 30.4127 19.2243 37.5266C21.4446 42.6506 26.456 45.9612 31.9821 45.9604C33.9025 45.9625 35.8029 45.5585 37.5651 44.7735C40.9709 43.2623 43.5913 40.5009 44.943 36.9941C45.6127 35.2729 45.9381 33.4305 45.8998 31.5769C45.8614 29.7234 45.4601 27.8969 44.7199 26.2065L44.0467 24.6533H48.4842L48.4934 25.761V24.6533C49.3831 24.6574 50.2383 24.2999 50.873 23.6587L52.077 22.4434L52.6717 24.0543C54.5381 29.1036 54.5381 34.6817 52.6717 39.731C51.0695 44.0809 48.1852 47.8068 44.4222 50.3873C40.732 52.9375 36.3857 54.2968 31.9398 54.2913Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0104 45.049C30.2606 45.0492 28.5284 44.6904 26.9151 43.9935C23.7001 42.6152 21.209 40.0524 19.8988 36.7775C19.2507 35.1709 18.9239 33.4464 18.9381 31.707C18.9522 29.9676 19.307 28.249 19.9812 26.6537C22.0337 21.7528 26.7597 18.5831 32.0212 18.5831C33.7707 18.5822 35.5029 18.9399 37.1164 19.6355L38.6112 20.2764L29.5932 29.408C29.2788 29.7254 29.0293 30.1043 28.8595 30.5223C28.6896 30.9404 28.6027 31.3892 28.6039 31.8423C28.6051 32.2954 28.6944 32.7436 28.8665 33.1607C29.0387 33.5778 29.2902 33.9552 29.6062 34.2709C30.25 34.9147 31.1133 35.2737 32.0113 35.271C32.9093 35.2684 33.7705 34.9042 34.4107 34.2566L43.4157 25.144L44.0496 26.6569C44.7296 28.288 45.0802 30.0442 45.0802 31.8189C45.0802 33.5935 44.7296 35.3497 44.0496 36.9809C41.9971 41.8817 37.2711 45.049 32.0104 45.049Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9975 34.4383C31.6528 34.4399 31.3112 34.3708 30.9929 34.2349C30.6745 34.0991 30.3857 33.8992 30.1434 33.6471C29.9071 33.4025 29.7205 33.112 29.5944 32.7925C29.4683 32.4731 29.4053 32.131 29.409 31.7862C29.4128 31.4414 29.4831 31.1009 29.6161 30.7844C29.749 30.4678 29.9419 30.1817 30.1834 29.9426L39.8946 20.1075V15.1307C39.8942 14.7851 39.961 14.4429 40.0913 14.1244C40.2216 13.8058 40.4127 13.5174 40.6532 13.276L49.6305 4.18322C50.1195 3.69006 50.777 3.41382 51.4619 3.41382C52.1468 3.41382 52.8043 3.69006 53.2933 4.18322C53.5338 4.42453 53.7249 4.71299 53.8552 5.03154C53.9855 5.3501 54.0523 5.69228 54.0518 6.03786V9.47656H57.4469C58.1319 9.47389 58.7902 9.75008 59.2779 10.2448C59.5178 10.4867 59.7084 10.7753 59.8385 11.0938C59.9686 11.4124 60.0356 11.7544 60.0356 12.0999C60.0356 12.4454 59.9686 12.7874 59.8385 13.1059C59.7084 13.4244 59.5178 13.7131 59.2779 13.9549L50.3006 23.0478C49.8123 23.5437 49.1528 23.8203 48.4665 23.8168H43.5574L33.8492 33.6511C33.8362 33.6653 33.8223 33.6795 33.8077 33.693C33.322 34.1734 32.6736 34.4407 31.9998 34.4383H31.9975Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9737 63.2469C26.897 63.2393 21.8974 61.9691 17.4011 59.5447C13.7455 57.5825 10.5112 54.8838 7.89064 51.6095C4.04997 46.8399 1.68578 40.9968 1.10263 34.8331C0.519482 28.6693 1.74408 22.4671 4.61868 17.0255C10.0248 6.7569 20.5261 0.377197 32.0253 0.377197C37.1022 0.384265 42.1022 1.65416 46.5987 4.07858L47.9012 4.78278L41.8504 10.9117L41.1665 10.6078C38.2683 9.32373 35.1459 8.66142 31.9903 8.66142C28.8347 8.66142 25.7122 9.32373 22.8141 10.6078C11.2649 15.7326 5.98501 29.4194 11.0434 41.1169C14.6931 49.5451 22.9157 54.9912 32.003 54.9912C35.1551 54.9927 38.2739 54.3278 41.1657 53.0376C52.7149 47.9128 57.9948 34.2261 52.9364 22.5285L52.6364 21.8354L58.688 15.7065L59.3803 17.0263C61.7791 21.5642 63.0357 26.6473 63.0357 31.8124C63.0357 36.9776 61.7791 42.0607 59.3803 46.5986C53.975 56.8672 43.4737 63.2469 31.9761 63.2469H31.9737Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9405 54.2691C29.3001 54.2684 26.6808 53.7862 24.2041 52.8449C18.6727 50.7513 14.2798 46.6013 11.8264 41.1584C10.6147 38.4908 9.93758 35.5996 9.83528 32.6572C9.73298 29.7147 10.2076 26.7812 11.2309 24.0314C12.831 19.6812 15.7135 15.9545 19.4751 13.3727C22.4122 11.3447 25.7752 10.0623 29.2879 9.63095C32.8006 9.1996 36.3627 9.63158 39.6815 10.8914L41.2733 11.4935L40.0731 12.7112C39.7603 13.0256 39.512 13.4014 39.3431 13.8164C39.1742 14.2314 39.0881 14.677 39.0899 15.1269V19.6234L37.5566 18.9422C35.7924 18.1597 33.8914 17.7561 31.9701 17.7561C30.0489 17.7561 28.1479 18.1597 26.3836 18.9422C19.3597 22.0604 16.1546 30.3842 19.232 37.5005C21.4507 42.6245 26.4537 45.9351 31.9782 45.9343C33.8972 45.9363 35.796 45.5323 37.5566 44.7474C40.9586 43.2362 43.5766 40.4748 44.9276 36.968C45.5964 35.2479 45.9216 33.4069 45.8839 31.5548C45.8463 29.7027 45.4464 27.8773 44.7083 26.1875L44.0359 24.6343H48.4665L48.475 25.742V24.6343C49.3644 24.638 50.2191 24.2799 50.853 23.6381L52.0554 22.4228L52.6501 24.0345C54.515 29.0841 54.515 34.6617 52.6501 39.7112C51.0505 44.059 48.1697 47.784 44.4106 50.3652C40.7249 52.9151 36.3825 54.2745 31.9405 54.2691Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0104 45.0265C30.2624 45.0276 28.5317 44.6698 26.9198 43.9741C23.7078 42.5958 21.2183 40.033 19.9104 36.7581C19.2627 35.1514 18.9361 33.427 18.9501 31.6877C18.9641 29.9484 19.3185 28.2298 19.992 26.6343C22.043 21.7334 26.7621 18.5637 32.0212 18.5637C33.7693 18.5628 35.4999 18.9205 37.1119 19.6161L38.6059 20.257L29.5955 29.3854C29.2816 29.7031 29.0326 30.0821 28.863 30.5001C28.6934 30.9181 28.6067 31.3667 28.6079 31.8197C28.6091 32.2726 28.6983 32.7207 28.8701 33.1378C29.0419 33.5548 29.293 33.9324 29.6086 34.2483C30.2515 34.892 31.1141 35.251 32.0114 35.2483C32.9086 35.2457 33.7691 34.8816 34.4084 34.2341L43.4096 25.1214L44.0428 26.6343C44.722 28.2656 45.0722 30.0217 45.0722 31.7963C45.0722 33.5709 44.722 35.327 44.0428 36.9583C41.9879 41.8568 37.2665 45.0265 32.0104 45.0265Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9974 34.419C31.6529 34.4205 31.3116 34.3514 30.9935 34.2155C30.6754 34.0796 30.3868 33.8798 30.1448 33.6277C29.9087 33.383 29.7222 33.0925 29.5962 32.773C29.4703 32.4535 29.4074 32.1114 29.4112 31.7667C29.415 31.4219 29.4854 31.0814 29.6183 30.7649C29.7513 30.4484 29.9441 30.1623 30.1856 29.9232L39.8868 20.0882V15.1082C39.8864 14.7626 39.9532 14.4204 40.0835 14.1018C40.2138 13.7833 40.4049 13.4948 40.6454 13.2535L49.6142 4.16067C50.1031 3.66847 50.76 3.39282 51.4441 3.39282C52.1282 3.39282 52.7851 3.66847 53.274 4.16067C53.5141 4.40195 53.7048 4.6902 53.8349 5.00844C53.9651 5.32668 54.032 5.66849 54.0317 6.01373V9.45243H57.4237C58.1083 9.44974 58.7661 9.72598 59.2532 10.2207C59.4929 10.4627 59.6833 10.7513 59.8133 11.0699C59.9433 11.3884 60.0102 11.7303 60.0102 12.0758C60.0102 12.4212 59.9433 12.7631 59.8133 13.0817C59.6833 13.4002 59.4929 13.6888 59.2532 13.9308L50.2843 23.0252C49.7972 23.52 49.1395 23.7962 48.4549 23.7935H43.5465L33.8491 33.6277L33.8076 33.6697C33.3221 34.1523 32.6726 34.4212 31.9974 34.419Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9736 63.2257C26.9011 63.2179 21.9057 61.9477 17.414 59.5235C13.7613 57.5613 10.5298 54.8632 7.91205 51.5898C4.0742 46.8183 1.71209 40.9751 1.12955 34.8117C0.547014 28.6483 1.77068 22.4466 4.64317 17.0043C10.0439 6.73566 20.5398 0.355957 32.0252 0.355957C37.0979 0.363156 42.0936 1.63311 46.5855 4.05734L47.8872 4.76154L41.841 10.8904L41.1579 10.5866C38.2627 9.30248 35.143 8.64013 31.9901 8.64013C28.8372 8.64013 25.7176 9.30248 22.8224 10.5866C11.2825 15.7114 6.00796 29.3981 11.0632 41.0957C14.7052 49.5239 22.9248 54.9699 32.0028 54.9692C35.1526 54.97 38.2688 54.3045 41.1579 53.014C52.6978 47.8892 57.9716 34.2025 52.9163 22.5049L52.617 21.8118L58.6632 15.6829L59.3556 17.0027C61.7523 21.5413 63.0077 26.6241 63.0077 31.7888C63.0077 36.9536 61.7523 42.0364 59.3556 46.575C53.9549 56.846 43.4659 63.2257 31.9759 63.2257H31.9736Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9404 54.2466C29.3025 54.246 26.6857 53.7638 24.2117 52.8224C18.6841 50.7304 14.2928 46.5804 11.8463 41.1375C10.6355 38.4693 9.95905 35.5778 9.85715 32.6352C9.75525 29.6926 10.23 26.7589 11.2531 24.0089C12.8503 19.6613 15.7283 15.9357 19.485 13.3526C22.4186 11.3246 25.7786 10.0422 29.2883 9.61082C32.7981 9.17944 36.3573 9.61144 39.673 10.8713L41.2647 11.4734L40.0661 12.6911C39.7531 13.0055 39.5046 13.3813 39.3353 13.7962C39.166 14.2112 39.0794 14.6568 39.0806 15.1068V19.5994L37.5481 18.9181C35.7858 18.1355 33.8865 17.7318 31.967 17.7318C30.0474 17.7318 28.1481 18.1355 26.3859 18.9181C19.368 22.0379 16.163 30.3641 19.2403 37.478C21.4568 42.6021 26.4551 45.9126 31.975 45.9118C33.8922 45.9139 35.7893 45.5099 37.5481 44.7249C40.9477 43.2137 43.5635 40.4523 44.9129 36.9455C45.582 35.2251 45.9077 33.3837 45.8705 31.5311C45.8334 29.6785 45.4342 27.8525 44.6967 26.1618L44.0251 24.6079H48.4541L48.4633 25.7156V24.6079C49.3521 24.6115 50.2061 24.2534 50.839 23.6117L52.0407 22.3964L52.6346 24.0081C54.3565 28.7085 54.4801 33.87 52.9853 38.6519C51.4905 43.4337 48.4654 47.5541 44.4028 50.3419C40.7202 52.8923 36.3802 54.2521 31.9404 54.2466Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0104 45.0044C30.2641 45.0052 28.5352 44.6474 26.9251 43.952C25.3522 43.2851 23.9236 42.3039 22.7219 41.0653C21.5201 39.8266 20.5693 38.3551 19.9242 36.736C19.2761 35.1295 18.949 33.4053 18.9622 31.6661C18.9754 29.9268 19.3287 28.2081 20.0012 26.6122C22.0507 21.7113 26.7713 18.5416 32.0196 18.5416C33.7662 18.5407 35.4954 18.8985 37.1057 19.594L38.5982 20.2349L29.597 29.3609C29.2831 29.6786 29.034 30.0575 28.8643 30.4755C28.6947 30.8935 28.6079 31.3421 28.609 31.795C28.6102 32.248 28.6992 32.6961 28.871 33.1132C29.0428 33.5303 29.2938 33.9079 29.6093 34.2238C30.2515 34.8675 31.1135 35.2265 32.0102 35.2238C32.9068 35.2212 33.7668 34.857 34.4053 34.2096L43.3957 25.0994L44.0281 26.6122C44.7069 28.2436 45.0569 29.9997 45.0569 31.7742C45.0569 33.5487 44.7069 35.3048 44.0281 36.9362C41.9794 41.8371 37.2619 45.0044 32.0104 45.0044Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9976 34.394C31.6533 34.3956 31.3123 34.3265 30.9944 34.1906C30.6765 34.0547 30.3883 33.8549 30.1465 33.6028C29.9106 33.358 29.7243 33.0674 29.5984 32.748C29.4726 32.4285 29.4098 32.0864 29.4136 31.7418C29.4173 31.3971 29.4877 31.0566 29.6205 30.7401C29.7534 30.4237 29.946 30.1375 30.1873 29.8983L39.8809 20.0633V15.0864C39.8804 14.7409 39.9472 14.3988 40.0774 14.0803C40.2075 13.7617 40.3984 13.4732 40.6387 13.2318L49.5991 4.13893C50.087 3.64641 50.7433 3.37048 51.427 3.37048C52.1106 3.37048 52.767 3.64641 53.2549 4.13893C53.4951 4.3805 53.6858 4.66903 53.8159 4.98753C53.9461 5.30603 54.013 5.64809 54.0127 5.99357V9.43227H57.3978C58.0819 9.42948 58.7392 9.70576 59.2257 10.2006C59.4651 10.4427 59.6553 10.7314 59.7851 11.0499C59.9149 11.3684 59.9817 11.7103 59.9817 12.0556C59.9817 12.4009 59.9149 12.7428 59.7851 13.0613C59.6553 13.3798 59.4651 13.6685 59.2257 13.9106L50.2676 23.0035C49.7814 23.4981 49.1243 23.7744 48.4404 23.7718H43.5367L33.8432 33.6068L33.8016 33.6487C33.317 34.1288 32.6697 34.3961 31.9968 34.394H31.9976Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9737 63.2029C26.9054 63.1953 21.9143 61.9253 17.4272 59.5016C13.777 57.5381 10.5483 54.8392 7.93364 51.5655C4.09936 46.7924 1.73977 40.9496 1.15785 34.7872C0.575929 28.6249 1.79824 22.4242 4.66784 16.9816C10.0639 6.71295 20.5468 0.333252 32.0252 0.333252C37.0939 0.34107 42.0854 1.61162 46.5725 4.03622L47.8726 4.74041L41.8319 10.8693L41.1495 10.5655C38.257 9.2814 35.1399 8.61903 31.9894 8.61903C28.839 8.61903 25.7219 9.2814 22.8294 10.5655C11.301 15.6887 6.03109 29.3754 11.0817 41.073C14.7207 49.5012 22.9294 54.9472 32.0021 54.9464C35.1492 54.9479 38.2629 54.2832 41.1495 52.9937C52.6779 47.8705 57.9478 34.1821 52.8971 22.4846L52.5979 21.7915L58.6386 15.6626L59.331 16.9824C61.7255 21.5217 62.9797 26.6042 62.9797 31.7685C62.9797 36.9328 61.7255 42.0153 59.331 46.5546C53.9357 56.8232 43.4536 63.2029 31.976 63.2029H31.9737Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9406 54.2243C29.305 54.2237 26.6904 53.7415 24.2188 52.8001C18.6935 50.7081 14.3083 46.5581 11.8634 41.1152C10.6537 38.4467 9.97786 35.5553 9.87596 32.6129C9.77407 29.6704 10.2482 26.7369 11.2702 23.9866C12.8671 19.6389 15.7452 15.9132 19.5021 13.3303C22.4323 11.3025 25.7891 10.02 29.2959 9.58862C32.8027 9.15721 36.359 9.58921 39.6716 10.849L41.261 11.4511L40.0624 12.6689C39.7502 12.9836 39.5025 13.3596 39.3338 13.7745C39.1652 14.1894 39.0792 14.6348 39.0807 15.0845V19.5771L37.5505 18.8958C35.7895 18.1131 33.8914 17.7093 31.9729 17.7093C30.0544 17.7093 28.1563 18.1131 26.3953 18.8958C19.3859 22.0157 16.1801 30.3418 19.2551 37.4558C21.4692 42.5798 26.4637 45.8919 31.9783 45.8919C33.894 45.8939 35.7895 45.4899 37.5467 44.705C39.2124 43.9732 40.7205 42.9089 41.9835 41.5735C43.2465 40.2382 44.2393 38.6584 44.9046 36.9256C45.5721 35.2052 45.8966 33.3644 45.8589 31.5125C45.8213 29.6606 45.4222 27.8353 44.6853 26.1451L44.0145 24.5919H48.4396L48.4489 25.6996V24.5919C49.3369 24.5953 50.1901 24.2372 50.8223 23.5958L52.0224 22.3804L52.6163 23.9922C54.3368 28.6918 54.4601 33.8518 52.9663 38.6327C51.4725 43.4135 48.4494 47.5337 44.3891 50.3228C40.711 52.8715 36.3756 54.2303 31.9406 54.2243Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0103 44.9819C30.264 44.9833 28.535 44.6255 26.9251 43.9296C25.3533 43.2622 23.9259 42.2808 22.7255 41.0421C21.5251 39.8035 20.5756 38.3322 19.9319 36.7135C19.2851 35.1066 18.959 33.3823 18.973 31.6431C18.987 29.904 19.3408 28.1855 20.0134 26.5897C22.0606 21.6888 26.7743 18.5192 32.0211 18.5192C33.7662 18.5182 35.4938 18.876 37.1025 19.5715L38.5935 20.2124L29.6 29.3408C29.2861 29.6585 29.037 30.0374 28.8673 30.4554C28.6977 30.8734 28.6109 31.322 28.612 31.775C28.6132 32.2279 28.7022 32.676 28.874 33.0931C29.0458 33.5102 29.2968 33.8878 29.6123 34.2037C30.2538 34.8473 31.1151 35.2064 32.0112 35.2037C32.9074 35.201 33.7667 34.8369 34.4045 34.1895L43.3887 25.0769L44.0211 26.5897C44.6992 28.2213 45.0487 29.9773 45.0487 31.7517C45.0487 33.5261 44.6992 35.2821 44.0211 36.9137C41.9701 41.8146 37.2572 44.9819 32.0103 44.9819Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9975 34.3712C31.654 34.3723 31.3138 34.3029 30.9967 34.1671C30.6797 34.0312 30.3922 33.8316 30.1511 33.58C29.9154 33.335 29.7294 33.0444 29.6037 32.7249C29.4781 32.4054 29.4153 32.0635 29.4191 31.7189C29.4229 31.3743 29.4932 31.0339 29.6258 30.7175C29.7585 30.401 29.9509 30.1148 30.1919 29.8754L39.8731 20.0396V15.0636C39.8725 14.7181 39.9392 14.376 40.0692 14.0575C40.1993 13.7389 40.39 13.4504 40.6302 13.2089L49.5829 4.11449C50.0703 3.62194 50.7263 3.34595 51.4096 3.34595C52.093 3.34595 52.749 3.62194 53.2364 4.11449C53.4764 4.35611 53.6671 4.64466 53.7971 4.96317C53.9271 5.28167 53.9939 5.62371 53.9934 5.96914V9.40783H57.3785C58.0619 9.40533 58.7184 9.68162 59.2041 10.1761C59.4434 10.4183 59.6335 10.707 59.7633 11.0255C59.893 11.344 59.9598 11.6859 59.9598 12.0312C59.9598 12.3765 59.893 12.7183 59.7633 13.0368C59.6335 13.3553 59.4434 13.644 59.2041 13.8862L50.2514 22.9806C49.7655 23.4752 49.1087 23.7515 48.425 23.7489H43.5259L33.8424 33.5839L33.8008 33.6258C33.3168 34.1059 32.67 34.3733 31.9975 34.3712Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9735 63.1807C26.9093 63.1728 21.9225 61.9025 17.4401 59.4786C13.7927 57.5149 10.5669 54.8162 7.95506 51.5433C4.12388 46.7685 1.76648 40.9259 1.18504 34.7642C0.603595 28.6025 1.82461 22.4027 4.69156 16.9593C10.0838 6.68995 20.5567 0.311035 32.0251 0.311035C37.0893 0.318401 42.0762 1.58848 46.5585 4.01242L47.8587 4.71661L41.8233 10.8455L41.1409 10.5417C38.2514 9.25764 35.137 8.59525 31.9893 8.59525C28.8416 8.59525 25.7272 9.25764 22.8377 10.5417C11.3193 15.6673 6.05405 29.3556 11.1001 41.0508C14.7359 49.479 22.9401 54.925 32.0028 54.9242C35.1471 54.9256 38.2581 54.2609 41.1416 52.9715C52.6593 47.8467 57.9246 34.1599 52.8785 22.4624L52.5793 21.7692L58.6139 15.6404L59.3063 16.9601C61.6987 21.5001 62.9517 26.5824 62.9517 31.7463C62.9517 36.9102 61.6987 41.9924 59.3063 46.5324C53.9156 56.801 43.4427 63.1807 31.9758 63.1807H31.9735Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9406 54.1999C29.3072 54.1993 26.6949 53.717 24.2257 52.7757C18.7089 50.6821 14.3245 46.5321 11.8811 41.0892C10.6728 38.4202 9.99778 35.5289 9.89602 32.5867C9.79427 29.6445 10.2678 26.7112 11.2887 23.9606C12.8831 19.616 15.757 15.8918 19.509 13.3083C22.436 11.2805 25.79 9.99798 29.2941 9.56654C32.7982 9.13509 36.3519 9.56713 39.6616 10.827L41.2487 11.4291L40.0516 12.6468C39.7395 12.9616 39.4919 13.3376 39.3234 13.7525C39.155 14.1674 39.069 14.6128 39.0707 15.0624V19.5551L37.5421 18.8738C35.7833 18.0914 33.8873 17.6878 31.971 17.6878C30.0547 17.6878 28.1587 18.0914 26.3999 18.8738C19.3944 21.9968 16.1947 30.3198 19.2666 37.4337C21.4792 42.5577 26.4683 45.8683 31.9783 45.8675C33.8922 45.8695 35.786 45.4654 37.5413 44.6806C39.2061 43.9485 40.7132 42.884 41.9751 41.5486C43.2371 40.2132 44.2289 38.6336 44.893 36.9012C45.5601 35.1807 45.8845 33.3399 45.8468 31.4881C45.8091 29.6362 45.4102 27.811 44.6738 26.1207L44.0045 24.5643H48.4258L48.435 25.6721V24.5643C49.3223 24.5676 50.1748 24.2094 50.8061 23.5682L52.0055 22.3528L52.5986 23.9646C54.3185 28.6641 54.4421 33.8237 52.9496 38.6047C51.457 43.3857 48.436 47.5069 44.3776 50.2984C40.7036 52.8466 36.372 54.2054 31.9406 54.1999Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0104 44.9608C30.2665 44.9618 28.5401 44.604 26.9328 43.9085C25.3615 43.241 23.9346 42.2594 22.7347 41.0208C21.5349 39.7821 20.5859 38.311 19.9427 36.6925C19.2961 35.0848 18.9701 33.3598 18.9841 31.6201C18.9981 29.8803 19.3518 28.1612 20.0242 26.5647C22.0699 21.6638 26.7789 18.4941 32.0204 18.4941C33.7642 18.4931 35.4906 18.8509 37.0979 19.5465L38.5881 20.1874L29.6024 29.3198C29.2891 29.6379 29.0407 30.0171 28.8716 30.4352C28.7025 30.8532 28.6161 31.3018 28.6175 31.7546C28.619 32.2073 28.7082 32.6553 28.8799 33.0722C29.0516 33.4891 29.3025 33.8666 29.6178 34.1827C30.2582 34.8263 31.1189 35.1855 32.0144 35.1828C32.9098 35.1802 33.7684 34.8159 34.4053 34.1684L43.3749 25.0558L44.0042 26.5687C44.6815 28.2005 45.0307 29.9564 45.0307 31.7307C45.0307 33.5049 44.6815 35.2608 44.0042 36.8927C41.9609 41.7935 37.2518 44.9608 32.0104 44.9608Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9975 34.3497C31.654 34.351 31.3138 34.2817 30.9967 34.1458C30.6796 34.0099 30.3921 33.8102 30.1511 33.5585C29.9156 33.3134 29.7298 33.0228 29.6042 32.7033C29.4787 32.3839 29.416 32.042 29.4198 31.6975C29.4236 31.3529 29.4938 31.0126 29.6263 30.6962C29.7588 30.3797 29.9511 30.0935 30.1919 29.854L39.8662 20.0189V15.0421C39.8653 14.6969 39.9314 14.3551 40.0607 14.0366C40.1901 13.7181 40.38 13.4294 40.6194 13.1874L49.5675 4.09461C50.054 3.60217 50.7093 3.32617 51.392 3.32617C52.0746 3.32617 52.7299 3.60217 53.2164 4.09461C53.4564 4.33627 53.6469 4.62484 53.7768 4.94335C53.9066 5.26186 53.9732 5.60388 53.9727 5.94926V9.38795H57.3577C58.0409 9.38549 58.6972 9.66178 59.1826 10.1562C59.4216 10.3986 59.6115 10.6874 59.741 11.0058C59.8706 11.3243 59.9373 11.6661 59.9373 12.0113C59.9373 12.3565 59.8706 12.6983 59.741 13.0167C59.6115 13.3352 59.4216 13.624 59.1826 13.8663L50.236 22.9592C49.7507 23.4538 49.0944 23.7301 48.4112 23.7274H43.5159L33.8409 33.5625L33.7993 33.6044C33.3158 34.0843 32.6695 34.3517 31.9975 34.3497Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9736 63.1583C26.9136 63.1502 21.931 61.8798 17.4533 59.4561C13.8085 57.492 10.5856 54.7933 7.97668 51.5208C4.14933 46.744 1.79468 40.9014 1.214 34.7406C0.633324 28.5797 1.85301 22.3808 4.71702 16.9369C10.1023 6.66749 20.5652 0.288574 32.0282 0.288574C37.0864 0.296869 42.0672 1.56698 46.5432 3.98996L47.8418 4.69415L41.8118 10.823L41.1302 10.5192C38.2437 9.23521 35.132 8.5728 31.9871 8.5728C28.8421 8.5728 25.7305 9.23521 22.844 10.5192C11.3379 15.644 6.07643 29.3307 11.1194 41.0283C14.7514 49.4565 22.9486 54.9026 32.0029 54.9018C35.1445 54.9033 38.2527 54.2386 41.1333 52.949C52.6409 47.8242 57.9047 34.1375 52.8602 22.4399L52.5617 21.7468L58.5909 15.6179L59.2833 16.9377C61.6735 21.4784 62.9253 26.5604 62.9253 31.7238C62.9253 36.8873 61.6735 41.9692 59.2833 46.51C53.898 56.7786 43.4351 63.1583 31.9767 63.1583H31.9736Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9405 54.1801C29.3094 54.1794 26.6994 53.6971 24.2326 52.7559C18.7203 50.6623 14.3405 46.5123 11.8995 41.0694C10.6919 38.4005 10.0173 35.5095 9.91571 32.5676C9.81409 29.6257 10.2875 26.6928 11.3078 23.9424C12.8991 19.5972 15.7704 15.8716 19.5204 13.2861C22.444 11.2585 25.795 9.97594 29.2961 9.54446C32.7973 9.11299 36.348 9.54502 39.6546 10.8048L41.2402 11.407L40.0439 12.6247C39.7321 12.9397 39.4849 13.3158 39.3167 13.7306C39.1485 14.1455 39.0627 14.5908 39.0645 15.0403V19.5329L37.5366 18.8517C35.7794 18.0693 33.8849 17.6656 31.9701 17.6656C30.0553 17.6656 28.1608 18.0693 26.4036 18.8517C19.4027 21.9715 16.2085 30.2976 19.2781 37.4116C21.4884 42.5356 26.4736 45.8461 31.9782 45.8453C33.8907 45.8473 35.783 45.4432 37.5366 44.6585C39.2003 43.9259 40.706 42.8611 41.9667 41.5257C43.2273 40.1904 44.2178 38.611 44.8806 36.8791C45.5471 35.1584 45.8712 33.3178 45.8337 31.466C45.7961 29.6143 45.3978 27.7891 44.6621 26.0986L43.9928 24.5454H48.4103L48.4188 25.6531V24.5454C49.3057 24.5488 50.1577 24.1906 50.7883 23.5492L51.9862 22.3339L52.5793 23.9456C54.2974 28.6441 54.421 33.8018 52.93 38.5816C51.439 43.3614 48.4211 47.4826 44.366 50.2762C40.6965 52.8253 36.3686 54.185 31.9405 54.1801Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0104 44.938C30.2681 44.9389 28.5432 44.5811 26.9375 43.8856C25.3672 43.2176 23.9414 42.2359 22.7426 40.9972C21.5438 39.7586 20.5958 38.2876 19.9535 36.6696C19.308 35.0623 18.9826 33.3381 18.9966 31.5992C19.0107 29.8602 19.3638 28.1419 20.035 26.5458C22.0784 21.6449 26.7836 18.4752 32.0204 18.4752C33.7628 18.4742 35.4877 18.832 37.0934 19.5276L38.5821 20.1685L29.6047 29.2969C29.2916 29.615 29.0431 29.9941 28.874 30.412C28.7048 30.8299 28.6182 31.2783 28.6194 31.731C28.6205 32.1837 28.7093 32.6316 28.8806 33.0486C29.0519 33.4656 29.3023 33.8434 29.6171 34.1598C30.2566 34.8033 31.1166 35.1625 32.0113 35.1599C32.9061 35.1572 33.764 34.7929 34.4 34.1456L43.365 25.0329L43.9958 26.5458C44.6728 28.1777 45.0217 29.9336 45.0217 31.7078C45.0217 33.482 44.6728 35.2379 43.9958 36.8698C41.9525 41.7707 37.2473 44.938 32.0104 44.938Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9974 34.3275C31.6539 34.329 31.3136 34.2597 30.9965 34.1238C30.6794 33.9879 30.3919 33.7882 30.151 33.5363C29.9162 33.2909 29.7309 33.0001 29.6057 32.6807C29.4806 32.3613 29.4181 32.0196 29.4219 31.6753C29.4257 31.331 29.4957 30.9908 29.6278 30.6745C29.7599 30.3581 29.9516 30.0717 30.1918 29.8318L39.8592 19.9968V15.0199C39.8583 14.6746 39.9247 14.3325 40.0545 14.014C40.1842 13.6954 40.3747 13.4068 40.6147 13.1653L49.5512 4.07243C50.0374 3.58013 50.6923 3.3042 51.3745 3.3042C52.0568 3.3042 52.7117 3.58013 53.1978 4.07243C53.4374 4.31427 53.6276 4.60291 53.7572 4.92141C53.8868 5.2399 53.9532 5.58182 53.9525 5.92708V9.36577H57.3322C58.0149 9.36329 58.6707 9.63962 59.1555 10.1341C59.3944 10.3765 59.584 10.6653 59.7135 10.9838C59.8429 11.3022 59.9096 11.6439 59.9096 11.9891C59.9096 12.3343 59.8429 12.676 59.7135 12.9944C59.584 13.3129 59.3944 13.6017 59.1555 13.8441L50.2182 22.937C49.9784 23.1818 49.694 23.3757 49.3812 23.5076C49.0685 23.6394 48.7336 23.7066 48.3957 23.7053H43.505L33.8392 33.5403L33.7976 33.5822C33.3147 34.0621 32.6689 34.3295 31.9974 34.3275Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9735 63.136C26.9177 63.1285 21.9393 61.8583 17.4663 59.4346C13.8946 57.5109 10.7286 54.8788 8.15112 51.6901C5.57363 48.5015 3.63563 44.8194 2.44897 40.8565C1.26259 36.9098 0.854273 32.7593 1.24795 28.6482C1.64163 24.5371 2.82938 20.5482 4.74157 16.9154C10.123 6.64758 20.5775 0.26709 32.0251 0.26709C37.0809 0.274614 42.0593 1.54481 46.5323 3.96847L47.8294 4.6695L41.8056 10.7984L41.124 10.4946C38.2403 9.21057 35.1313 8.54813 31.9889 8.54813C28.8466 8.54813 25.7376 9.21057 22.8539 10.4946C11.357 15.6225 6.10098 29.3092 11.1378 41.0068C14.7698 49.435 22.9562 54.8811 32.0028 54.8803C35.1417 54.8816 38.2471 54.2169 41.1247 52.9275C52.6208 47.8027 57.8776 34.116 52.8408 22.4184L52.5439 21.7253L58.5677 15.5964L59.2601 16.9162C61.6482 21.4576 62.8987 26.5393 62.8987 31.7023C62.8987 36.8653 61.6482 41.9471 59.2601 46.4885C53.8787 56.7571 43.425 63.136 31.9782 63.136H31.9735Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9405 54.1575C29.3116 54.1568 26.7039 53.6745 24.2395 52.7333C18.7326 50.6389 14.3559 46.4913 11.9171 41.0484C10.7108 38.3788 10.0369 35.4876 9.93546 32.5456C9.83398 29.6037 10.3069 26.6706 11.3263 23.9198C12.9154 19.5756 15.7839 15.8501 19.5312 13.2635C22.4514 11.236 25.7994 9.9534 29.2977 9.5219C32.7961 9.09039 36.344 9.52243 39.6476 10.7822L41.2347 11.3843L40.04 12.602C39.7278 12.9169 39.4801 13.2929 39.3114 13.7077C39.1426 14.1226 39.0563 14.568 39.0575 15.0177V19.5103L37.5312 18.8314C35.7759 18.049 33.8831 17.6453 31.9701 17.6453C30.057 17.6453 28.1643 18.049 26.409 18.8314C19.4165 21.9512 16.223 30.2774 19.2896 37.3913C21.4976 42.5154 26.4782 45.8259 31.9782 45.8251C33.8889 45.827 35.7794 45.423 37.5312 44.6382C39.1939 43.9053 40.6986 42.8403 41.9582 41.5049C43.2178 40.1695 44.2072 38.5903 44.869 36.8588C45.5351 35.1381 45.8589 33.2976 45.8215 31.4459C45.7841 29.5943 45.3862 27.7691 44.6513 26.0783L43.982 24.5251H48.3957L48.4041 25.6328V24.5251C48.8438 24.5265 49.2795 24.4387 49.6862 24.2669C50.0929 24.095 50.4627 23.8424 50.7744 23.5234L51.9715 22.3081L52.5639 23.9198C54.2812 28.6184 54.4049 33.7758 52.9149 38.5558C51.4249 43.3357 48.4086 47.4578 44.3551 50.2536C40.6895 52.8023 36.3651 54.1621 31.9405 54.1575Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0105 44.9165C30.2697 44.9171 28.5464 44.5593 26.9421 43.8642C25.3728 43.1958 23.948 42.2139 22.7502 40.9752C21.5525 39.7366 20.6055 38.2658 19.9643 36.6482C19.319 35.0401 18.9938 33.3153 19.0078 31.5758C19.0218 29.8362 19.3748 28.1172 20.0458 26.5204C22.0877 21.6195 26.7883 18.4498 32.0205 18.4498C33.7613 18.4489 35.4847 18.8067 37.0888 19.5022L38.5759 20.1431L29.6063 29.277C29.2935 29.5954 29.0453 29.9745 28.8764 30.3925C28.7075 30.8104 28.6211 31.2587 28.6223 31.7113C28.6235 32.1639 28.7123 32.6118 28.8835 33.0287C29.0547 33.4457 29.3048 33.8234 29.6194 34.14C30.2581 34.7834 31.1173 35.1426 32.0114 35.1399C32.9055 35.1372 33.7626 34.7729 34.3977 34.1257L43.355 25.0115L43.9851 26.5243C44.6613 28.1565 45.0098 29.9123 45.0098 31.6863C45.0098 33.4604 44.6613 35.2162 43.9851 36.8483C41.9433 41.7492 37.2427 44.9165 32.0105 44.9165Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9973 34.3048C31.6537 34.3066 31.3133 34.2375 30.9961 34.1016C30.679 33.9657 30.3916 33.7658 30.1509 33.5136C29.916 33.2682 29.7306 32.9775 29.6054 32.6581C29.4802 32.3387 29.4177 31.9971 29.4214 31.6527C29.4251 31.3084 29.495 30.9683 29.6271 30.6518C29.7592 30.3354 29.9508 30.049 30.1909 29.809L39.8498 19.9732V14.9972C39.8493 14.6519 39.9159 14.3099 40.0456 13.9914C40.1753 13.6729 40.3656 13.3843 40.6053 13.1425L49.5357 4.04969C50.0211 3.55745 50.6754 3.28149 51.3571 3.28149C52.0388 3.28149 52.6931 3.55745 53.1785 4.04969C53.4179 4.29164 53.608 4.58031 53.7376 4.89877C53.8672 5.21724 53.9337 5.55911 53.9332 5.90434V9.34303H57.3136C57.6514 9.34182 57.986 9.40904 58.2985 9.54087C58.6109 9.67269 58.895 9.86653 59.1346 10.1113C59.3729 10.3541 59.5621 10.643 59.6912 10.9614C59.8203 11.2798 59.8868 11.6214 59.8868 11.9664C59.8868 12.3113 59.8203 12.6529 59.6912 12.9713C59.5621 13.2897 59.3729 13.5786 59.1346 13.8214L50.202 22.9142C49.9624 23.159 49.6782 23.3529 49.3658 23.4847C49.0533 23.6165 48.7187 23.6837 48.3809 23.6825H43.4949L33.8368 33.5175C33.8237 33.5318 33.8098 33.546 33.796 33.5595C33.3137 34.0393 32.6684 34.3068 31.9973 34.3048Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9735 63.1138C26.9219 63.1061 21.9477 61.8359 17.4794 59.4124C13.9104 57.4872 10.7473 54.8544 8.17245 51.6658C5.59764 48.4773 3.66209 44.796 2.47742 40.8343C1.2918 36.8875 0.88374 32.7372 1.27714 28.6263C1.67054 24.5153 2.85749 20.5265 4.76848 16.8932C10.1423 6.62378 20.5874 0.244873 32.0251 0.244873C37.0767 0.252521 42.0509 1.52277 46.5193 3.94625L47.8156 4.65045L41.7963 10.7793L41.1163 10.4755C38.2354 9.19153 35.129 8.52905 31.9893 8.52905C28.8496 8.52905 25.7432 9.19153 22.8624 10.4755C11.3755 15.6003 6.12404 29.287 11.1539 40.987C14.7805 49.4152 22.9624 54.8604 32.0028 54.8604C35.1392 54.8617 38.242 54.1969 41.117 52.9077C52.6031 47.7805 57.8523 34.0938 52.8216 22.393L52.5239 21.6999L58.5447 15.5742L59.237 16.894C61.6233 21.436 62.8727 26.5175 62.8727 31.6801C62.8727 36.8428 61.6233 41.9242 59.237 46.4663C53.8564 56.7325 43.4119 63.1138 31.9758 63.1138H31.9735Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9404 54.1363C29.3141 54.1354 26.7089 53.6531 24.2471 52.7121C18.7449 50.6185 14.3728 46.4685 11.9379 41.0256C10.732 38.3558 10.0582 35.4646 9.95633 32.5226C9.85446 29.5806 10.3265 26.6475 11.3447 23.8962C12.9314 19.5541 15.7962 15.8296 19.5396 13.2423C22.457 11.2148 25.8024 9.9322 29.2983 9.5008C32.7942 9.0694 36.3397 9.50167 39.6407 10.7618L41.2232 11.3647L40.0299 12.5816C39.7185 12.8968 39.4714 13.2729 39.3033 13.6877C39.1353 14.1026 39.0496 14.5478 39.0514 14.9972V19.489L37.5265 18.8078C35.7729 18.0254 33.8816 17.6217 31.9701 17.6217C30.0585 17.6217 28.1673 18.0254 26.4136 18.8078C19.4281 21.9276 16.2369 30.2538 19.3003 37.3677C21.5076 42.4917 26.4836 45.8022 31.9781 45.8015C33.8874 45.8034 35.7764 45.3994 37.5265 44.6146C39.1881 43.8811 40.6916 42.8158 41.95 41.4805C43.2084 40.1451 44.1966 38.5662 44.8575 36.8352C45.5229 35.1144 45.8465 33.2739 45.8091 31.4223C45.7717 29.5707 45.3741 27.7456 44.6398 26.0547L43.9712 24.5015H48.3802L48.3895 25.6092V24.5015C49.2756 24.5034 50.1263 24.1434 50.7544 23.5006L51.9507 22.2853L52.5439 23.8962C54.2607 28.5943 54.3851 33.7508 52.897 38.5307C51.4088 43.3105 48.3952 47.4337 44.3443 50.2323C40.6826 52.7808 36.3617 54.1407 31.9404 54.1363Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0105 44.8935C30.2712 44.8941 28.5494 44.5363 26.9467 43.8411C25.3784 43.1723 23.9547 42.1901 22.758 40.9514C21.5613 39.7128 20.6154 38.2423 19.9751 36.6251C19.3309 35.0175 19.0063 33.2935 19.0203 31.5547C19.0343 29.816 19.3867 28.0977 20.0566 26.5013C22.0969 21.6012 26.7929 18.4363 32.0205 18.4363C33.7597 18.4354 35.4815 18.793 37.0842 19.4878L38.5698 20.1295L29.6086 29.2524C29.2961 29.5709 29.0483 29.9501 28.8795 30.368C28.7107 30.7858 28.6244 31.234 28.6256 31.6865C28.6267 32.139 28.7153 32.5867 28.8862 33.0037C29.057 33.4207 29.3068 33.7985 29.6209 34.1153C30.2586 34.7591 31.1171 35.1187 32.0107 35.1164C32.9043 35.114 33.761 34.7498 34.3954 34.1027L43.3442 24.9885L43.9743 26.5013C44.6501 28.1335 44.9985 29.8893 44.9985 31.6633C44.9985 33.4373 44.6501 35.1931 43.9743 36.8253C41.9341 41.7262 37.2381 44.8935 32.0105 44.8935Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9974 34.2834C31.6544 34.2848 31.3147 34.2155 30.9982 34.0796C30.6817 33.9437 30.395 33.7439 30.1548 33.4921C29.9199 33.2468 29.7345 32.956 29.6093 32.6367C29.4841 32.3173 29.4216 31.9756 29.4253 31.6313C29.429 31.287 29.4989 30.9468 29.631 30.6304C29.7631 30.314 29.9547 30.0275 30.1948 29.7876L39.8445 19.9526V14.9757C39.8438 14.6305 39.9103 14.2886 40.0399 13.9701C40.1695 13.6516 40.3596 13.3629 40.5992 13.1211L49.5235 4.02825C50.0082 3.53602 50.6621 3.26001 51.3433 3.26001C52.0246 3.26001 52.6784 3.53602 53.1632 4.02825C53.4025 4.27024 53.5925 4.55892 53.7219 4.8774C53.8514 5.19587 53.9178 5.53771 53.9171 5.8829V9.32159H57.2906C57.6281 9.32032 57.9625 9.38753 58.2747 9.51936C58.587 9.6512 58.8708 9.84507 59.1101 10.0899C59.3484 10.3326 59.5376 10.6216 59.6666 10.94C59.7957 11.2584 59.8622 11.5999 59.8622 11.9449C59.8622 12.2899 59.7957 12.6314 59.6666 12.9499C59.5376 13.2683 59.3484 13.5572 59.1101 13.8L50.1859 22.8928C49.9464 23.1371 49.6624 23.3305 49.3502 23.4619C49.038 23.5933 48.7037 23.6602 48.3664 23.6587H43.4842L33.8353 33.4961L33.7945 33.538C33.3127 34.0177 32.6679 34.2852 31.9974 34.2834Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9737 63.0909C26.9262 63.0833 21.9563 61.813 17.4926 59.3896C13.9254 57.4636 10.7643 54.8304 8.19146 51.6418C5.61864 48.4532 3.68505 44.7723 2.50218 40.8114C1.31742 36.8647 0.909603 32.7146 1.30259 28.6039C1.69557 24.4931 2.88147 20.5042 4.79094 16.8704C10.1624 6.60096 20.5984 0.222046 32.0252 0.222046C37.0729 0.22991 42.043 1.50021 46.5071 3.92343L47.8011 4.62762L41.788 10.7565L41.1079 10.4527C38.2306 9.16928 35.1277 8.50708 31.9914 8.50708C28.8551 8.50708 25.7522 9.16928 22.8748 10.4527C11.3979 15.5775 6.15111 29.2642 11.181 40.9618C14.7984 49.39 22.9733 54.8329 32.0029 54.8329C35.1366 54.8341 38.2367 54.1693 41.1087 52.8801C52.5856 47.7553 57.8324 34.0686 52.8025 22.371L52.5056 21.6803L58.5186 15.5514L59.211 16.8711C61.595 21.4139 62.8432 26.4951 62.8432 31.6573C62.8432 36.8195 61.595 41.9007 59.211 46.4434C53.8396 56.712 43.4044 63.0909 31.9767 63.0909H31.9737Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9406 54.1138C29.3162 54.113 26.7131 53.6307 24.2534 52.6896C18.7565 50.5975 14.3852 46.446 11.9534 41.0047C9.80968 36.1988 9.39719 30.7656 10.7896 25.6757C12.182 20.5859 15.2886 16.171 19.5543 13.2198C22.4678 11.1927 25.8095 9.90994 29.3019 9.47824C32.7943 9.04653 36.3364 9.47831 39.6339 10.7377L41.2156 11.3406L40.0224 12.5583C39.7114 12.8738 39.4647 13.25 39.297 13.6648C39.1292 14.0796 39.0436 14.5247 39.0453 14.9739V19.465L37.522 18.7837C35.7701 18.0014 33.8805 17.5977 31.9706 17.5977C30.0606 17.5977 28.171 18.0014 26.4191 18.7837C19.4397 21.9051 16.2516 30.2312 19.3089 37.3468C21.5138 42.4708 26.4852 45.7813 31.9752 45.7805C33.8829 45.7824 35.7704 45.3783 37.519 44.5937C39.1794 43.8596 40.6817 42.7941 41.9389 41.4587C43.1961 40.1234 44.1832 38.5447 44.843 36.8143C45.5086 35.0933 45.8325 33.2527 45.7956 31.4009C45.7588 29.5492 45.3619 27.7237 44.6283 26.0322L43.9606 24.479H48.3657L48.375 25.5867V24.479C49.2598 24.4822 50.1096 24.1239 50.7376 23.4828L51.9324 22.2675L52.524 23.8792C54.2386 28.5758 54.3627 33.73 52.8763 38.5081C51.3899 43.2863 48.3799 47.4091 44.3329 50.2098C40.6755 52.7581 36.3583 54.1181 31.9406 54.1138Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0104 44.8717C30.2726 44.8722 28.5523 44.5143 26.9513 43.8193C25.3838 43.15 23.9611 42.1675 22.7654 40.9287C21.5698 39.6899 20.625 38.2195 19.9857 36.6025C19.3421 34.9949 19.0177 33.2711 19.0317 31.5325C19.0458 29.794 19.3978 28.0759 20.0673 26.4795C22.1052 21.5786 26.7974 18.4089 32.0204 18.4089C33.7587 18.4095 35.4793 18.7687 37.0802 19.4652L38.5651 20.1069L29.6108 29.2306C29.2985 29.5492 29.0508 29.9284 28.8822 30.3463C28.7135 30.7641 28.6273 31.2123 28.6284 31.6647C28.6295 32.1171 28.7181 32.5648 28.8888 32.9818C29.0596 33.3987 29.3092 33.7766 29.6231 34.0935C30.2602 34.7368 31.1181 35.096 32.0109 35.0933C32.9037 35.0907 33.7595 34.7263 34.393 34.0793L43.3341 24.9667L43.9634 26.4795C44.6385 28.1119 44.9865 29.8676 44.9865 31.6415C44.9865 33.4154 44.6385 35.1711 43.9634 36.8035C41.927 41.7044 37.2341 44.8717 32.0104 44.8717Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9973 34.261C31.4895 34.264 30.9923 34.1109 30.5695 33.8216C30.1467 33.5322 29.8176 33.1196 29.6241 32.6366C29.4307 32.1537 29.3818 31.6224 29.4837 31.1107C29.5857 30.599 29.8338 30.1302 30.1963 29.7644L39.8376 19.9294V14.9549C39.8369 14.6098 39.9033 14.2679 40.0327 13.9494C40.1622 13.631 40.3522 13.3423 40.5915 13.1003L49.5042 4.00588C49.9882 3.5137 50.6415 3.23767 51.3221 3.23767C52.0028 3.23767 52.656 3.5137 53.1401 4.00588C53.3793 4.24798 53.5692 4.53669 53.6986 4.85513C53.8281 5.17358 53.8945 5.51537 53.894 5.86053V9.29922H57.2644C57.6018 9.2981 57.9361 9.36538 58.2481 9.49721C58.5602 9.62904 58.8439 9.82283 59.0831 10.0675C59.3214 10.3103 59.5106 10.5992 59.6397 10.9176C59.7688 11.236 59.8353 11.5776 59.8353 11.9226C59.8353 12.2675 59.7688 12.6091 59.6397 12.9275C59.5106 13.2459 59.3214 13.5348 59.0831 13.7776L50.1697 22.8673C49.9306 23.1119 49.647 23.3057 49.3351 23.4375C49.0231 23.5694 48.689 23.6367 48.3518 23.6355H43.4742L33.8337 33.4737C33.8199 33.488 33.8068 33.5022 33.7922 33.5157C33.3114 33.9954 32.6673 34.2629 31.9973 34.261Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9736 63.0687C26.9303 63.061 21.9647 61.7906 17.5056 59.3673C13.9408 57.4402 10.782 54.8064 8.21148 51.6179C5.64095 48.4293 3.70944 44.7491 2.52829 40.7892C1.34494 36.8424 0.937657 32.6926 1.33022 28.5822C1.72278 24.4717 2.90733 20.4828 4.81474 16.8481C10.1816 6.57874 20.6083 0.199829 32.0252 0.199829C37.0687 0.207719 42.0346 1.47808 46.494 3.90121L47.7872 4.60541L41.7795 10.7343L41.1002 10.4305C38.225 9.14654 35.1239 8.48402 31.9894 8.48402C28.8549 8.48402 25.7538 9.14654 22.8786 10.4305C11.4156 15.5553 6.1703 29.242 11.194 40.9396C14.8099 49.3678 22.9809 54.813 32.0021 54.813C35.1333 54.8143 38.2309 54.1494 41.1002 52.8603C52.5632 47.7354 57.8077 34.0487 52.7848 22.3512L52.487 21.658L58.4947 15.5292L59.1871 16.8489C61.5691 21.3923 62.8162 26.4733 62.8162 31.6351C62.8162 36.7969 61.5691 41.8778 59.1871 46.4212C53.8172 56.6898 43.3889 63.0687 31.9759 63.0687H31.9736Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9404 54.0915C29.3184 54.0907 26.7175 53.6084 24.2602 52.6673C18.7703 50.5753 14.4036 46.4252 11.9709 40.9863C9.82897 36.1808 9.41667 30.749 10.8076 25.6598C12.1986 20.5707 15.3023 16.1553 19.565 13.2014C22.4748 11.1738 25.8134 9.89032 29.303 9.45789C32.7925 9.02547 36.332 9.45659 39.6268 10.7154L41.207 11.3151L40.0153 12.5328C39.7035 12.849 39.4565 13.2262 39.2888 13.6422C39.1212 14.0582 39.0362 14.5045 39.0391 14.9548V19.4474L37.5165 18.7662C35.7664 17.9838 33.8784 17.5801 31.9701 17.5801C30.0617 17.5801 28.1738 17.9838 26.4236 18.7662C19.4504 21.886 16.2684 30.2121 19.3234 37.3261C21.526 42.4501 26.4936 45.7606 31.9781 45.7598C33.8844 45.7618 35.7704 45.3577 37.5173 44.573C39.1768 43.8385 40.678 42.7728 41.9342 41.4374C43.1903 40.1021 44.1764 38.5236 44.8352 36.7936C45.5007 35.0717 45.8246 33.2303 45.7876 31.3778C45.7506 29.5252 45.3535 27.699 44.6198 26.0067L43.9528 24.4535H48.351L48.3603 25.5612V24.4535C49.2443 24.4565 50.0934 24.0982 50.7206 23.4574L51.9146 22.242L52.5054 23.8538C54.2196 28.5501 54.3443 33.7037 52.8594 38.482C51.3746 43.2602 48.3668 47.3842 44.322 50.1875C40.6685 52.7356 36.3548 54.0957 31.9404 54.0915Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0105 44.8497C30.2741 44.8502 28.5554 44.4924 26.956 43.7973C25.3895 43.1276 23.9678 42.1449 22.7732 40.9061C21.5786 39.6673 20.6348 38.1971 19.9966 36.5805C19.3536 34.9727 19.0295 33.249 19.0435 31.5105C19.0575 29.7721 19.4093 28.0541 20.0782 26.4575C21.44 23.22 23.9898 20.6665 27.1728 19.3525C30.3558 18.0384 33.9146 18.0702 37.075 19.4409L38.5582 20.0825L29.6132 29.2086C29.3011 29.5273 29.0536 29.9066 28.885 30.3244C28.7165 30.7423 28.6303 31.1904 28.6315 31.6427C28.6326 32.0951 28.7211 32.5427 28.8917 32.9596C29.0623 33.3766 29.3118 33.7545 29.6255 34.0715C30.2618 34.7149 31.1191 35.0743 32.0114 35.0716C32.9037 35.069 33.7589 34.7045 34.3915 34.0573L43.3242 24.9447L43.9528 26.4575C44.7849 28.477 45.1158 30.6776 44.9157 32.8613C44.7156 35.045 43.9908 37.1432 42.8064 38.9673C41.622 40.7913 40.0153 42.2839 38.1307 43.3107C36.2462 44.3376 34.1431 44.8664 32.0105 44.8497Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9973 34.2389C31.4895 34.2415 30.9926 34.0883 30.57 33.7988C30.1475 33.5093 29.8185 33.0967 29.6253 32.6138C29.432 32.1309 29.3833 31.5997 29.4853 31.0882C29.5873 30.5766 29.8354 30.108 30.1978 29.7423L39.8306 19.9073V14.9313C39.8298 14.5861 39.896 14.2443 40.0253 13.9258C40.1546 13.6074 40.3445 13.3186 40.5837 13.0766L49.488 3.98378C49.9715 3.49156 50.6244 3.21545 51.3047 3.21545C51.9851 3.21545 52.638 3.49156 53.1215 3.98378C53.3603 4.22608 53.5499 4.51487 53.679 4.83329C53.8082 5.15171 53.8745 5.4934 53.8739 5.83842V9.27712H57.2413C57.7468 9.27469 58.2416 9.42677 58.6629 9.71405C59.0842 10.0013 59.4131 10.4109 59.6077 10.8907C59.8023 11.3705 59.8539 11.899 59.756 12.4091C59.6581 12.9191 59.415 13.3877 59.0577 13.7555L50.1527 22.8483C49.914 23.0931 49.6307 23.2869 49.319 23.4188C49.0073 23.5506 48.6733 23.6178 48.3363 23.6166H43.4657L33.8321 33.4516L33.7906 33.4936C33.3103 33.9732 32.6667 34.2407 31.9973 34.2389Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9735 63.0464C26.9342 63.0378 21.9728 61.7666 17.5186 59.3426C12.1911 56.4586 7.81209 52.0182 4.93277 46.5804C2.05345 41.1426 0.802469 34.9503 1.33727 28.7829C1.7057 24.5945 2.89709 20.5266 4.83926 16.8258C10.2015 6.5564 20.6167 0.17749 32.0251 0.17749C37.0643 0.184948 42.0259 1.45482 46.4808 3.87729L47.7733 4.58149L41.7725 10.7104L41.0939 10.4065C38.2217 9.12264 35.1234 8.4601 31.9916 8.4601C28.8598 8.4601 25.7615 9.12264 22.8893 10.4065C11.4332 15.5313 6.19482 29.2181 11.2155 40.9156C14.8313 49.3438 22.9916 54.7891 32.0043 54.7891C35.1327 54.7903 38.2275 54.1254 41.0939 52.8363C52.5477 47.7115 57.7845 34.0232 52.7654 22.3288L52.4669 21.6357L58.4677 15.5084L59.1601 16.8282C61.5399 21.3723 62.7857 26.4529 62.7857 31.6143C62.7857 36.7757 61.5399 41.8563 59.1601 46.4005C53.7979 56.6675 43.3811 63.0464 31.9758 63.0464H31.9735Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9406 54.0698C29.3207 54.069 26.7221 53.5867 24.2673 52.6456C18.7804 50.552 14.4199 46.402 11.9896 40.9591C9.84996 36.154 9.43821 30.7239 10.8277 25.6357C12.2171 20.5476 15.3175 16.1319 19.5767 13.175C22.4834 11.1486 25.819 9.86612 29.3054 9.4345C32.7918 9.00288 36.328 9.43461 39.6193 10.6937L41.1979 11.2966L40.0078 12.5143C39.6969 12.8299 39.4504 13.2061 39.2827 13.6209C39.1151 14.0357 39.0297 14.4808 39.0315 14.93V19.4226L37.5113 18.7413C35.7629 17.959 33.8766 17.5553 31.9698 17.5553C30.0631 17.5553 28.1768 17.959 26.4283 18.7413C19.4628 21.8612 16.2793 30.1873 19.3351 37.3013C21.5354 42.4253 26.4983 45.7342 31.9783 45.7342C34.7469 45.7343 37.4518 44.8794 39.7425 43.2802C42.0332 41.681 43.8042 39.4112 44.8261 36.7648C45.4898 35.0436 45.8125 33.2033 45.7752 31.352C45.738 29.5008 45.3415 27.6758 44.6091 25.9843L43.9421 24.4311H48.3396L48.3489 25.5388V24.4311C48.7863 24.4326 49.2197 24.3453 49.6243 24.1744C50.0289 24.0035 50.3967 23.7522 50.7068 23.4349L51.9001 22.2196L52.4902 23.8313C54.2032 28.5273 54.3279 33.6799 52.8441 38.4577C51.3604 43.2355 48.3547 47.3598 44.3122 50.1651C40.6625 52.7134 36.3519 54.0739 31.9406 54.0698Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0104 44.8273C30.2756 44.8279 28.5583 44.47 26.9605 43.775C24.2108 42.5953 21.9453 40.4694 20.5516 37.7611C19.1579 35.0528 18.7227 31.9306 19.3205 28.9287C19.9183 25.9269 21.5119 23.232 23.8287 21.3053C26.1454 19.3786 29.0412 18.3399 32.0204 18.367C33.7552 18.3661 35.4724 18.7237 37.0703 19.4185L38.552 20.0602L29.6178 29.1863C29.3061 29.5052 29.0589 29.8846 28.8907 30.3025C28.7225 30.7203 28.6365 31.1683 28.6377 31.6205C28.6389 32.0728 28.7273 32.5203 28.8978 32.9372C29.0683 33.354 29.3174 33.732 29.6309 34.0492C30.2664 34.6921 31.1229 35.0512 32.0144 35.0486C32.9059 35.0459 33.7603 34.6817 34.3922 34.0349L43.3119 24.9247L43.9396 26.4375C44.7708 28.4559 45.1014 30.655 44.9017 32.8373C44.702 35.0195 43.9782 37.1166 42.7954 38.9399C41.6126 40.7633 40.0079 42.2559 38.1254 43.2835C36.2429 44.3111 34.1416 44.8416 32.0104 44.8273Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9975 34.2165C31.4898 34.2191 30.9929 34.0657 30.5704 33.7761C30.148 33.4865 29.8192 33.0738 29.6262 32.5908C29.4332 32.1079 29.3847 31.5767 29.487 31.0652C29.5893 30.5538 29.8376 30.0854 30.2003 29.72L39.8239 19.885V14.9089C39.8233 14.5641 39.8895 14.2226 40.0187 13.9045C40.1479 13.5863 40.3374 13.2978 40.5763 13.0558L49.4728 3.96141C49.9556 3.46925 50.6079 3.19312 51.2876 3.19312C51.9674 3.19312 52.6197 3.46925 53.1025 3.96141C53.3408 4.20396 53.5298 4.49285 53.6584 4.81127C53.7871 5.12969 53.8528 5.47125 53.8518 5.81606V9.25792H57.2161C57.7215 9.25565 58.2162 9.40784 58.6374 9.69519C59.0586 9.98254 59.3873 10.3921 59.5819 10.8719C59.7764 11.3517 59.8279 11.8801 59.73 12.3901C59.632 12.9 59.389 13.3686 59.0317 13.7363L50.1367 22.826C49.8983 23.0706 49.6153 23.2644 49.3038 23.3963C48.9924 23.5281 48.6587 23.5954 48.3219 23.5942H43.4536L33.8308 33.4293L33.7892 33.4712C33.3096 33.9508 32.6665 34.2184 31.9975 34.2165Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9736 63.0242C26.9387 63.0162 21.9816 61.7457 17.5318 59.3228C12.2072 56.4357 7.8315 51.9939 4.95494 46.556C2.07839 41.118 0.829413 34.9267 1.36508 28.7607C1.73279 24.5726 2.92312 20.5048 4.86399 16.8036C10.2208 6.53419 20.6291 0.158447 32.0252 0.158447C37.0596 0.165792 42.0164 1.43517 46.4663 3.85666L47.7572 4.56086L41.7618 10.6897L41.081 10.3859C38.2117 9.10203 35.1162 8.43946 31.9871 8.43946C28.858 8.43946 25.7625 9.10203 22.8932 10.3859C11.4495 15.5083 6.21725 29.1967 11.231 40.895C14.8468 49.3232 22.9963 54.7685 32.0029 54.7685C35.1275 54.7692 38.2185 54.1043 41.081 52.8157C52.5271 47.6909 57.7593 34.0042 52.7448 22.3066L52.4478 21.6135L58.4486 15.4846L59.1371 16.8044C61.5149 21.3492 62.7595 26.4296 62.7595 31.5905C62.7595 36.7515 61.5149 41.8319 59.1371 46.3767C53.7795 56.6453 43.3728 63.0242 31.9798 63.0242H31.9736Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9405 54.0476C29.323 54.0467 26.7266 53.5644 24.2742 52.6234C18.7919 50.5298 14.4352 46.3798 12.0072 40.9369C9.86826 36.1322 9.45615 30.7027 10.8442 25.6148C12.2322 20.5268 15.3304 16.1104 19.5874 13.1512C22.4909 11.1247 25.8236 9.84211 29.3074 9.41045C32.7911 8.97879 36.3247 9.41064 39.6131 10.6699L41.1902 11.2729L40.0039 12.4921C39.6935 12.808 39.4474 13.1843 39.28 13.599C39.1127 14.0138 39.0274 14.4587 39.0292 14.9078V19.4004L37.5097 18.7191C35.763 17.9368 33.8784 17.5331 31.9732 17.5331C30.0681 17.5331 28.1834 17.9368 26.4368 18.7191C19.4736 21.839 16.2939 30.1651 19.3466 37.2791C20.8303 40.7079 23.5725 43.3939 26.9747 44.751C30.377 46.1081 34.163 46.0261 37.5066 44.5228C39.1608 43.7877 40.6573 42.7233 41.91 41.3909C43.1626 40.0586 44.1466 38.4846 44.8054 36.7596C45.4641 35.0345 45.7847 33.1925 45.7485 31.3395C45.7123 29.4866 45.3201 27.6593 44.5945 25.9629L43.9275 24.4097H48.3211L48.3296 25.5174V24.4097C48.7668 24.4112 49.1999 24.324 49.6043 24.1531C50.0087 23.9821 50.3763 23.7308 50.686 23.4135L51.8785 22.1982L52.467 23.8099C54.179 28.5049 54.3043 33.656 52.8227 38.4331C51.3411 43.2103 48.3389 47.3353 44.2998 50.1437C40.6543 52.6911 36.3479 54.0514 31.9405 54.0476Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0104 44.8044C29.4397 44.8036 26.9272 44.0171 24.792 42.5448C22.6567 41.0725 20.9951 38.9807 20.0181 36.5352C19.3762 34.9272 19.0527 33.2035 19.0667 31.4653C19.0807 29.727 19.4319 28.0091 20.0996 26.4122C21.4541 23.176 23.9981 20.622 27.1765 19.3075C30.3549 17.993 33.9099 18.0246 37.0657 19.3956L38.5466 20.0373L29.6178 29.1633C29.3063 29.4824 29.0594 29.8618 28.8912 30.2796C28.7231 30.6974 28.6372 31.1453 28.6383 31.5974C28.6394 32.0496 28.7277 32.497 28.8979 32.9139C29.0681 33.3308 29.317 33.7088 29.6301 34.0262C30.2647 34.6695 31.1207 35.0289 32.0117 35.0262C32.9027 35.0236 33.7567 34.659 34.3876 34.012L43.3042 24.8994L43.9319 26.4122C44.7632 28.43 45.0941 30.6286 44.8949 32.8105C44.6957 34.9924 43.9726 37.0892 42.7907 38.9126C41.6088 40.7361 40.005 42.229 38.1234 43.2574C36.2417 44.2858 34.1412 44.8173 32.0104 44.8044Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9974 34.1951C31.4898 34.1973 30.9932 34.0437 30.5709 33.754C30.1487 33.4643 29.8202 33.0515 29.6274 32.5687C29.4345 32.0858 29.3862 31.5547 29.4885 31.0434C29.5908 30.5321 29.8392 30.0638 30.2018 29.6985L39.8184 19.8635V14.8875C39.8174 14.5425 39.8833 14.2008 40.0122 13.8824C40.1411 13.5639 40.3305 13.2751 40.5693 13.0328L49.4566 3.93998C49.9387 3.44781 50.5905 3.17163 51.2699 3.17163C51.9492 3.17163 52.601 3.44781 53.0832 3.93998C53.3219 4.18232 53.5113 4.47113 53.6404 4.78956C53.7694 5.10798 53.8355 5.44965 53.8348 5.79462V9.23332H57.196C57.701 9.23215 58.195 9.38513 58.6153 9.67291C59.0357 9.96068 59.3636 10.3703 59.5574 10.8499C59.7513 11.3294 59.8024 11.8574 59.7042 12.3668C59.6061 12.8763 59.3632 13.3443 59.0062 13.7117L50.1205 22.8045C49.8824 23.0492 49.5996 23.243 49.2884 23.3749C48.9772 23.5067 48.6437 23.574 48.3072 23.5728H43.4435L33.8268 33.4078L33.7853 33.4498C33.3062 33.9292 32.6636 34.1968 31.9951 34.1951H31.9974Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9735 63.0023C26.9428 62.9943 21.9899 61.7237 17.5447 59.3009C13.986 57.378 10.833 54.7489 8.2677 51.5656C5.70243 48.3824 3.77579 44.7079 2.59903 40.7546C1.42227 36.8012 1.01872 32.6472 1.41168 28.5326C1.80465 24.4179 2.98633 20.4241 4.8885 16.7817C10.2407 6.51233 20.639 0.133423 32.0251 0.133423C37.0561 0.141793 42.0091 1.41232 46.4546 3.8348L47.7448 4.539L41.7525 10.6679L41.0755 10.364C38.2089 9.0802 35.1159 8.4176 31.9893 8.4176C28.8627 8.4176 25.7697 9.0802 22.9031 10.364C11.4678 15.4889 6.24022 29.1756 11.2501 40.8732C14.8598 49.3014 23.0055 54.7466 32.0028 54.7466C35.126 54.7477 38.2155 54.0828 41.0762 52.7938C52.5108 47.669 57.7384 33.9823 52.7285 22.2847L52.4315 21.5916L58.4231 15.4627L59.1109 16.7825C61.4868 21.3279 62.7304 26.4081 62.7304 31.5687C62.7304 36.7293 61.4868 41.8094 59.1109 46.3548C53.7586 56.6234 43.3611 63.0023 31.9758 63.0023H31.9735Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9404 54.0255C29.3251 54.0246 26.731 53.5422 24.281 52.6013C18.8033 50.5077 14.4512 46.3569 12.0255 40.9148C9.88781 36.1105 9.47563 30.6823 10.8622 25.5949C12.2489 20.5075 15.3444 16.0906 19.5988 13.1291C22.4988 11.1027 25.8285 9.82006 29.3093 9.38837C32.7901 8.95668 36.3208 9.38854 39.606 10.6478L41.1824 11.2507L39.9937 12.4685C39.6834 12.7843 39.4375 13.1606 39.2702 13.5754C39.103 13.9902 39.0179 14.4351 39.0198 14.8841V19.3783L37.5019 18.697C35.757 17.9147 33.8739 17.511 31.9704 17.511C30.0669 17.511 28.1838 17.9147 26.4389 18.697C19.4842 21.8169 16.3084 30.1469 19.3572 37.2569C20.8372 40.6852 23.5765 43.3716 26.9764 44.7289C30.3764 46.0863 34.1606 46.0042 37.5019 44.5007C39.1548 43.7641 40.6499 42.6987 41.9011 41.3659C43.1523 40.033 44.1351 38.459 44.7928 36.7342C45.4506 35.0094 45.7703 33.1679 45.7338 31.3156C45.6972 29.4633 45.305 27.6367 44.5797 25.9408L43.9143 24.3876H48.3041L48.3125 25.4953V24.3876C48.7495 24.389 49.1824 24.3018 49.5865 24.1308C49.9906 23.9599 50.3579 23.7086 50.6674 23.3914L51.8576 22.1761L52.4469 23.7878C54.158 28.482 54.2837 33.6317 52.804 38.4083C51.3242 43.1849 48.325 47.3106 44.2889 50.1216C40.6474 52.6688 36.3444 54.0292 31.9404 54.0255Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0097 44.7844C29.4404 44.7829 26.9295 43.9958 24.7962 42.5231C22.6629 41.0505 21.0035 38.9587 20.029 36.5137C19.3876 34.9055 19.0644 33.1819 19.0784 31.4437C19.0924 29.7056 19.4433 27.9877 20.1105 26.3907C21.4614 23.1551 24.0024 20.601 27.1785 19.2863C30.3546 17.9715 33.9076 18.0031 37.0612 19.374L38.5421 20.0189L29.6179 29.1418C29.3067 29.461 29.06 29.8405 28.892 30.2582C28.7241 30.676 28.6382 31.1238 28.6394 31.5759C28.6405 32.0279 28.7287 32.4753 28.8987 32.8921C29.0688 33.309 29.3174 33.6871 29.6302 34.0047C30.2638 34.648 31.1191 35.0076 32.0095 35.0049C32.8999 35.0022 33.7531 34.6376 34.3831 33.9904L43.292 24.8778L43.919 26.3907C44.7501 28.4076 45.0813 30.6052 44.8829 32.7863C44.6845 34.9673 43.9626 37.0636 42.782 38.8869C41.6015 40.7103 39.9993 42.2036 38.1191 43.233C36.2389 44.2623 34.1397 44.7954 32.0097 44.7844Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9974 34.1723C31.4901 34.1738 30.9939 34.0197 30.5722 33.7298C30.1505 33.4399 29.8224 33.0272 29.6298 32.5446C29.4372 32.0619 29.389 31.5312 29.4911 31.0202C29.5933 30.5091 29.8412 30.0411 30.2033 29.6757L39.8099 19.8407V14.8646C39.809 14.5197 39.8748 14.1781 40.0036 13.8597C40.1324 13.5413 40.3215 13.2524 40.56 13.01L49.4412 3.91714C49.799 3.54907 50.2547 3.29821 50.7509 3.19623C51.2472 3.09424 51.7617 3.14568 52.2295 3.34407C52.6973 3.54246 53.0976 3.8789 53.3797 4.31096C53.6619 4.74302 53.8133 5.25133 53.8148 5.77178V9.21048H57.1737C57.6788 9.20899 58.173 9.36175 58.5936 9.64941C59.0142 9.93706 59.3423 10.3467 59.5364 10.8263C59.7304 11.3059 59.7816 11.834 59.6836 12.3436C59.5855 12.8532 59.3425 13.3214 58.9855 13.6888L50.1043 22.7817C49.8665 23.0263 49.584 23.22 49.2731 23.3519C48.9621 23.4837 48.6289 23.5511 48.2926 23.55H43.4327L33.8276 33.385L33.7861 33.4269C33.3076 33.9064 32.6655 34.174 31.9974 34.1723Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9736 62.9826C26.947 62.9745 21.9984 61.7039 17.5579 59.2812C14.0015 57.3554 10.851 54.7245 8.28802 51.5404C5.72504 48.3563 3.80028 44.6818 2.62474 40.7289C1.44919 36.7761 1.04611 32.623 1.43875 28.5091C1.83138 24.3952 3.01195 20.402 4.91239 16.7596C10.26 6.48866 20.649 0.111328 32.0251 0.111328C37.0519 0.119558 42.0008 1.39016 46.4416 3.81271L47.7302 4.51691L41.7441 10.6458L41.067 10.342C38.2033 9.05801 35.1129 8.39532 31.989 8.39532C28.865 8.39532 25.7746 9.05801 22.9109 10.342C11.4863 15.4668 6.26333 29.1535 11.2686 40.8511C14.8752 49.2793 23.0132 54.7245 32.0028 54.7245C35.1228 54.7268 38.2092 54.0632 41.067 52.7757C52.4924 47.6509 57.7153 33.9642 52.7101 22.2666L52.4131 21.5735L58.3993 15.4446L59.0871 16.7644C61.4608 21.3105 62.703 26.3904 62.703 31.5505C62.703 36.7107 61.4608 41.7905 59.0871 46.3367C53.7387 56.6013 43.3504 62.9826 31.9759 62.9826H31.9736Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9406 54.0031C27.753 54.0009 23.6536 52.7651 20.1275 50.4419C16.6014 48.1187 13.7961 44.8054 12.0434 40.8939C9.90741 36.09 9.49541 30.6631 10.8806 25.5764C12.2657 20.4898 15.3584 16.0724 19.6098 13.1082C22.5065 11.082 25.8332 9.79924 29.3112 9.36751C32.7891 8.93579 36.317 9.36767 39.5993 10.6269L41.1741 11.2299L39.9863 12.4476C39.6763 12.7636 39.4307 13.14 39.2637 13.5547C39.0968 13.9695 39.0118 14.4143 39.0138 14.8632V19.3558L37.4967 18.6738C35.7536 17.8915 33.8721 17.4877 31.9702 17.4877C30.0683 17.4877 28.1869 17.8915 26.4438 18.6738C19.4959 21.7936 16.3217 30.1197 19.3659 37.2337C20.4663 39.7884 22.2763 41.9515 24.5685 43.4514C26.8606 44.9513 29.5327 45.721 32.2489 45.6638C34.9651 45.6065 37.6043 44.7249 39.8348 43.1297C42.0653 41.5345 43.7876 39.2969 44.7854 36.698C45.4472 34.9763 45.7689 33.1363 45.7316 31.2853C45.6944 29.4343 45.2989 27.6095 44.5684 25.9175L43.9037 24.3643H48.2889L48.2973 25.472V24.3643C48.7339 24.3657 49.1665 24.2785 49.5702 24.1075C49.974 23.9366 50.3409 23.6853 50.65 23.3681L51.8432 22.1552L52.4317 23.7669C53.6564 27.1625 54.0636 30.8125 53.6185 34.405C53.1734 37.9976 51.8892 41.426 49.8756 44.3971C47.8621 47.3682 45.1791 49.7938 42.0558 51.4666C38.9326 53.1394 35.462 54.0097 31.9406 54.0031Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0096 44.7605C29.4419 44.7589 26.9326 43.9718 24.8011 42.4993C22.6695 41.0268 21.0121 38.9354 20.0396 36.4913C19.3988 34.883 19.076 33.1594 19.09 31.4213C19.104 29.6833 19.4545 27.9655 20.1211 26.3683C21.4683 23.1335 24.0065 20.5791 27.1803 19.2642C30.3541 17.9492 33.9051 17.9806 37.0564 19.3516L38.5343 19.9933L29.6216 29.1178C29.3106 29.4372 29.064 29.8166 28.8962 30.2344C28.7284 30.6521 28.6426 31.0999 28.6437 31.5519C28.6449 32.0039 28.7329 32.4512 28.9029 32.868C29.0728 33.2849 29.3213 33.663 29.6339 33.9807C30.2668 34.624 31.1215 34.9836 32.0113 34.9809C32.9011 34.9783 33.7538 34.6136 34.383 33.9665L43.2834 24.8554L43.9096 26.3683C44.7401 28.3844 45.0711 30.581 44.873 32.761C44.6748 34.9411 43.9537 37.0365 42.7743 38.8594C41.5948 40.6823 39.994 42.1756 38.1152 43.2055C36.2365 44.2355 34.1386 44.7697 32.0096 44.7605Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9974 34.1509C31.4903 34.1521 30.9943 33.9979 30.5728 33.7078C30.1514 33.4178 29.8235 33.0051 29.6311 32.5225C29.4387 32.0399 29.3906 31.5093 29.4928 30.9985C29.595 30.4876 29.8429 30.0196 30.2049 29.6544L39.8023 19.8194V14.8433C39.8015 14.4984 39.8674 14.1569 39.9962 13.8385C40.1249 13.5201 40.314 13.2312 40.5524 12.9887L49.4251 3.89582C49.7827 3.52806 50.2383 3.27748 50.7342 3.17568C51.2302 3.07388 51.7444 3.12543 52.2119 3.32382C52.6794 3.5222 53.0793 3.85855 53.3611 4.29041C53.643 4.72228 53.7942 5.23033 53.7956 5.75047V9.18916H57.1507C57.6557 9.18799 58.1496 9.34098 58.57 9.62875C58.9904 9.91653 59.3182 10.3261 59.5121 10.8057C59.7059 11.2853 59.757 11.8132 59.6589 12.3227C59.5608 12.8321 59.3179 13.3002 58.9609 13.6675L50.0882 22.7604C49.8507 23.0049 49.5684 23.1987 49.2578 23.3305C48.9471 23.4624 48.6141 23.5297 48.278 23.5287H43.4228L33.8254 33.3637L33.7846 33.4056C33.3066 33.8849 32.665 34.1525 31.9974 34.1509Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9737 62.9581C26.9513 62.9499 22.007 61.6792 17.5711 59.2567C14.0173 57.3285 10.8694 54.6963 8.3088 51.5117C5.74817 48.3272 3.82535 44.6531 2.65107 40.701C1.47678 36.749 1.07423 32.5971 1.46658 28.4844C1.85893 24.3717 3.03844 20.3795 4.93719 16.7375C10.2802 6.46814 20.6592 0.0892334 32.0253 0.0892334C37.0479 0.0976079 41.9925 1.36826 46.4287 3.79062L47.7165 4.49481L41.735 10.6237L41.0588 10.3199C38.1981 9.03608 35.1106 8.37345 31.9895 8.37345C28.8684 8.37345 25.7809 9.03608 22.9203 10.3199C11.505 15.4447 6.28659 29.1314 11.288 40.8282C14.8908 49.2572 23.0218 54.7024 32.003 54.7024C35.1207 54.7034 38.2047 54.0385 41.0595 52.7497C52.4741 47.6249 57.6924 33.9381 52.6918 22.2406L52.3948 21.5474L58.3764 15.4186L59.0626 16.7383C61.4341 21.2852 62.6752 26.3648 62.6752 31.5245C62.6752 36.6842 61.4341 41.7638 59.0626 46.3106C53.7196 56.5792 43.3406 62.9581 31.9761 62.9581H31.9737Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9406 53.9809C27.7553 53.9782 23.6585 52.742 20.1353 50.4187C16.6121 48.0954 13.8101 44.7823 12.0611 40.8717C9.9265 36.0682 9.51463 30.6424 10.8985 25.5564C12.2824 20.4703 15.3726 16.0525 19.6213 13.0861C22.5144 11.0596 25.8379 9.77657 29.313 9.34454C32.788 8.91251 36.3131 9.34413 39.5923 10.6032L41.1656 11.2061L39.9793 12.423C39.6695 12.7391 39.424 13.1155 39.2572 13.5303C39.0903 13.945 39.0055 14.3898 39.0076 14.8386V19.3313L37.4921 18.65C35.7506 17.8677 33.8709 17.464 31.9706 17.464C30.0703 17.464 28.1905 17.8677 26.4491 18.65C19.5067 21.7698 16.3363 30.096 19.3805 37.2099C20.4786 39.7631 22.2863 41.9253 24.5761 43.4247C26.866 44.9241 29.5358 45.6937 32.2497 45.6367C34.9637 45.5797 37.6007 44.6987 39.8291 43.1044C42.0576 41.5101 43.7779 39.2738 44.7738 36.6766C45.4352 34.9549 45.7567 33.1149 45.7196 31.264C45.6825 29.4131 45.2875 27.5884 44.5576 25.8961L43.8929 24.3429H48.2781L48.2865 25.4506V24.3429C48.7228 24.3445 49.1551 24.2572 49.5584 24.0863C49.9618 23.9153 50.3283 23.664 50.6368 23.3468L51.8254 22.1314L52.4132 23.7432C53.6394 27.1372 54.0483 30.7862 53.605 34.3782C53.1617 37.9702 51.8794 41.3983 49.8676 44.3697C47.8558 47.3411 45.1743 49.7673 42.0525 51.4411C38.9306 53.1148 35.4611 53.9862 31.9406 53.9809Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0096 44.7384C29.4432 44.737 26.9353 43.9498 24.8055 42.4771C22.6756 41.0045 21.0203 38.913 20.0504 36.4693C19.4106 34.8611 19.0882 33.1381 19.1021 31.4006C19.1159 29.6631 19.4658 27.9457 20.1312 26.3486C21.4754 23.1149 24.0108 20.5608 27.1823 19.2456C30.3537 17.9305 33.9026 17.9616 37.0518 19.332L38.5289 19.9737L29.6239 29.0974C29.0218 29.7507 28.6922 30.6207 28.706 31.5208C28.7197 32.421 29.0756 33.2798 29.6974 33.9135C30.3193 34.5471 31.1576 34.9052 32.0329 34.911C32.9083 34.9167 33.751 34.5698 34.3807 33.9444L43.2734 24.8334L43.8989 26.3486C44.7285 28.3638 45.0592 30.5591 44.8613 32.7379C44.6635 34.9168 43.9432 37.0111 42.7651 38.8334C41.587 40.6557 39.9878 42.149 38.1107 43.1795C36.2337 44.2101 34.1374 44.7457 32.0096 44.7384Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9975 34.1275C31.4905 34.1286 30.9947 33.9742 30.5734 33.6842C30.152 33.3941 29.8243 32.9815 29.6321 32.499C29.4399 32.0164 29.3919 31.486 29.4942 30.9752C29.5965 30.4645 29.8445 29.9967 30.2065 29.6317L39.7954 19.7967V14.8206C39.7944 14.476 39.8598 14.1346 39.9881 13.8162C40.1163 13.4978 40.3048 13.2088 40.5425 12.966L49.4098 3.87313C49.7673 3.50612 50.2224 3.25617 50.7178 3.15481C51.2133 3.05345 51.7268 3.10521 52.1936 3.30356C52.6605 3.50192 53.0598 3.83799 53.3412 4.26941C53.6226 4.70083 53.7735 5.20827 53.7749 5.72778V9.16647H57.1277C57.6325 9.16562 58.1263 9.31883 58.5464 9.60673C58.9665 9.89462 59.2942 10.3042 59.4878 10.7837C59.6815 11.2632 59.7325 11.7911 59.6343 12.3004C59.5361 12.8097 59.2932 13.2776 58.9364 13.6448L50.0722 22.7377C49.8349 22.9821 49.5529 23.1758 49.2425 23.3077C48.932 23.4396 48.5993 23.5069 48.2635 23.506H43.4128L33.8239 33.341C33.8108 33.3552 33.797 33.3695 33.7831 33.3829C33.3056 33.8619 32.6645 34.1292 31.9975 34.1275Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9735 62.9359C26.9563 62.9267 22.0174 61.656 17.587 59.2345C14.0356 57.3037 10.8902 54.6701 8.3318 51.4849C5.77335 48.2997 3.85227 44.6259 2.67898 40.6746C1.50569 36.7232 1.10335 32.5724 1.49507 28.4606C1.8868 24.3489 3.06486 20.3573 4.96155 16.7153C10.2992 6.44593 20.6697 0.0670166 32.025 0.0670166C37.0435 0.0755663 41.9838 1.34627 46.4154 3.7684L47.7024 4.47259L41.7263 10.6031L41.0508 10.2992C38.1929 9.01546 35.1079 8.35281 31.9893 8.35281C28.8706 8.35281 25.7856 9.01546 22.9277 10.2992C11.5232 15.4224 6.30711 29.1092 11.3078 40.8067C13.7196 46.4398 18.2059 50.8587 23.7821 53.0936C29.3583 55.3284 35.5689 55.1968 41.0508 52.7274C52.4554 47.6026 57.6683 33.9159 52.6723 22.2183L52.3769 21.5221L58.3523 15.3932L59.0378 16.7129C61.4074 21.2604 62.6474 26.3397 62.6474 31.4991C62.6474 36.6584 61.4074 41.7378 59.0378 46.2852C53.6978 56.557 43.3303 62.9359 31.9758 62.9359H31.9735Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9412 53.9624C27.7575 53.9595 23.6624 52.7225 20.1415 50.3983C16.6207 48.0741 13.8219 44.7601 12.0771 40.8493C9.94416 36.046 9.53262 30.6214 10.9153 25.536C12.298 20.4506 15.3858 16.0324 19.6319 13.0636C22.5219 11.0378 25.8425 9.75504 29.3147 9.32325C32.7868 8.89147 36.309 9.32327 39.5852 10.5823L41.1577 11.1853L39.973 12.403C39.6637 12.7194 39.4187 13.0959 39.2523 13.5106C39.0859 13.9253 39.0014 14.3699 39.0036 14.8186V19.3112L37.4896 18.63C35.7499 17.8477 33.8718 17.4439 31.9731 17.4439C30.0744 17.4439 28.1963 17.8477 26.4566 18.63C19.518 21.7498 16.3499 30.0759 19.3919 37.1883C20.4874 39.7417 22.2932 41.9045 24.5818 43.4044C26.8704 44.9043 29.5393 45.674 32.2524 45.6168C34.9656 45.5595 37.6016 44.6777 39.8283 43.0825C42.0551 41.4874 43.7731 39.2501 44.7659 36.6526C45.426 34.9308 45.7468 33.0912 45.7095 31.2408C45.6723 29.3903 45.2778 27.5659 44.5489 25.8737L43.885 24.3205H48.2633L48.2717 25.4282V24.3205C48.7077 24.3218 49.1397 24.2345 49.5428 24.0636C49.9459 23.8926 50.3121 23.6414 50.6205 23.3243L51.8083 22.109L52.3953 23.7207C53.6235 27.1135 54.0345 30.7617 53.5932 34.3534C53.1519 37.9451 51.8715 41.3734 49.8614 44.3454C47.8513 47.3173 45.1713 49.7444 42.0506 51.4192C38.9299 53.094 35.4612 53.9666 31.9412 53.9624Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0099 44.7162C30.1037 44.7141 28.2219 44.2762 26.5004 43.4344C24.7789 42.5927 23.2609 41.3679 22.056 39.8488C20.8511 38.3297 19.9896 36.5542 19.5336 34.6507C19.0776 32.7472 19.0387 30.7633 19.4196 28.8424C19.8005 26.9216 20.5916 25.1117 21.7359 23.5439C22.8802 21.976 24.349 20.6892 26.036 19.7767C27.723 18.8641 29.5862 18.3485 31.4907 18.2672C33.3952 18.1859 35.2935 18.541 37.0482 19.3066L38.523 19.9483L29.6257 29.0752C29.0087 29.7251 28.6665 30.5995 28.6736 31.5078C28.6807 32.4161 29.0365 33.2847 29.6635 33.9243C30.2906 34.564 31.138 34.9229 32.0212 34.9228C32.9044 34.9227 33.7517 34.5636 34.3786 33.9238L43.2636 24.8112L43.8883 26.324C44.7181 28.3384 45.0493 30.533 44.8522 32.7113C44.6551 34.8896 43.9358 36.9836 42.7589 38.8059C41.582 40.6282 39.9841 42.122 38.1082 43.1534C36.2323 44.1849 34.137 44.7218 32.0099 44.7162Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9974 34.1026C31.4906 34.1034 30.9949 33.9488 30.5739 33.6586C30.1528 33.3684 29.8253 32.9558 29.6333 32.4733C29.4413 31.9909 29.3934 31.4605 29.4957 30.95C29.5981 30.4394 29.8461 29.9718 30.208 29.6068L39.7884 19.7742V14.7965C39.7877 14.4518 39.8535 14.1104 39.982 13.792C40.1105 13.4737 40.2991 13.1847 40.537 12.9419L49.3943 3.85061C49.7515 3.48352 50.2065 3.23351 50.7018 3.13212C51.197 3.03073 51.7104 3.08251 52.1771 3.28093C52.6438 3.47935 53.0428 3.81551 53.3239 4.24698C53.605 4.67846 53.7555 5.1859 53.7564 5.70526V9.14395H57.1053C57.61 9.14341 58.1035 9.29685 58.5234 9.58487C58.9433 9.87288 59.2707 10.2825 59.4642 10.762C59.6577 11.2414 59.7085 11.7691 59.6103 12.2782C59.5121 12.7874 59.2692 13.2552 58.9124 13.6223L50.0551 22.7152C49.8184 22.9596 49.5368 23.1534 49.2267 23.2853C48.9167 23.4171 48.5843 23.4845 48.2488 23.4834H43.402L33.8223 33.3185C33.8092 33.3327 33.7954 33.3461 33.7815 33.3604C33.3042 33.8385 32.6637 34.105 31.9974 34.1026Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9736 62.9139C26.9593 62.9055 22.0232 61.6347 17.5964 59.2125C11.5734 55.9156 6.79667 50.6403 4.02718 44.2272C1.2577 37.8142 0.654831 30.6323 2.31465 23.826C3.97446 17.0196 7.80146 10.9805 13.1859 6.67065C18.5703 2.36085 25.2023 0.0284265 32.0251 0.0450097C37.0395 0.0535407 41.9755 1.32432 46.4024 3.74639L47.6879 4.45059L41.7179 10.5795L41.0425 10.2756C38.1875 8.99188 35.1053 8.3292 31.9894 8.3292C28.8735 8.3292 25.7912 8.99188 22.9363 10.2756C11.5387 15.4004 6.3326 29.0872 11.3248 40.7847C13.7302 46.4171 18.2117 50.8367 23.7842 53.072C29.3568 55.3074 35.5644 55.1755 41.0425 52.7054C52.437 47.5806 57.6461 33.8939 52.654 22.1963L52.3585 21.5032L58.3285 15.3743L59.014 16.6957C61.4984 21.4904 62.732 26.8655 62.5946 32.2967C62.4572 37.7279 60.9535 43.0298 58.23 47.6852C55.5066 52.3405 51.6565 56.1905 47.0552 58.8595C42.4539 61.5284 37.2585 62.9253 31.9759 62.9139H31.9736Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9413 53.9366C28.7527 53.9363 25.6031 53.216 22.7149 51.8264C19.8267 50.4369 17.2705 48.412 15.2267 45.8949C13.1828 43.3778 11.7014 40.4299 10.8869 37.2594C10.0724 34.0888 9.94469 30.773 10.5129 27.5462C11.0811 24.3193 12.3313 21.2601 14.1752 18.5847C16.0191 15.9093 18.4117 13.6829 21.184 12.0629C23.9563 10.4428 27.0407 9.46856 30.2192 9.20897C33.3978 8.94937 36.593 9.41075 39.5792 10.5605L41.1494 11.1634L39.9646 12.3811C39.6554 12.6976 39.4104 13.0742 39.2439 13.4889C39.0773 13.9035 38.9926 14.3481 38.9945 14.7968V19.2894L37.482 18.6081C35.7441 17.8259 33.8675 17.4221 31.9705 17.4221C30.0734 17.4221 28.1969 17.8259 26.459 18.6081C19.5297 21.728 16.3639 30.0541 19.4035 37.1681C20.4964 39.721 22.2998 41.8838 24.5864 43.3839C26.873 44.884 29.5403 45.6541 32.2519 45.597C34.9635 45.54 37.598 44.6584 39.823 43.0634C42.048 41.4684 43.764 39.2315 44.7545 36.6348C45.4144 34.9126 45.7351 33.0728 45.6979 31.2221C45.6606 29.3714 45.2662 27.5468 44.5375 25.8542L43.8743 24.301H48.2487L48.2572 25.4088V24.301C48.6928 24.3024 49.1243 24.2151 49.5269 24.0442C49.9295 23.8732 50.2952 23.6219 50.6029 23.3049L51.79 22.0896L52.3762 23.7013C53.604 27.0921 54.0154 30.7383 53.5751 34.3282C53.1349 37.918 51.8561 41.3449 49.8481 44.316C47.84 47.2871 45.1624 49.714 42.0441 51.3893C38.9258 53.0647 35.4594 53.9387 31.9413 53.9366Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0096 44.6945C30.1031 44.6933 28.2207 44.2563 26.4986 43.4151C24.7764 42.5739 23.2576 41.3495 22.052 39.8306C20.8464 38.3116 19.9842 36.536 19.5277 34.6323C19.0712 32.7285 19.0318 30.7443 19.4124 28.8229C19.7931 26.9016 20.5842 25.0914 21.7285 23.5231C22.8729 21.9548 24.3418 20.6678 26.0292 19.755C27.7165 18.8423 29.58 18.3266 31.4849 18.2455C33.3898 18.1643 35.2884 18.5196 37.0433 19.2857L38.5174 19.9274L29.6278 29.0526C29.0025 29.7002 28.6529 30.5768 28.656 31.4894C28.6591 32.402 29.0146 33.276 29.6443 33.9191C30.274 34.5622 31.1262 34.9217 32.0136 34.9185C32.901 34.9153 33.7508 34.5497 34.3761 33.9021L43.2526 24.7895L43.8773 26.3023C44.7067 28.3158 45.0379 30.5094 44.8414 32.6868C44.6448 34.8642 43.9265 36.9575 42.7508 38.7796C41.5751 40.6016 39.9787 42.0955 38.1042 43.1278C36.2298 44.16 34.1358 44.6983 32.0096 44.6945Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9974 34.0828C31.4906 34.0833 30.9952 33.9285 30.5744 33.6382C30.1535 33.3479 29.8263 32.9352 29.6345 32.4528C29.4426 31.9704 29.3949 31.4402 29.4973 30.9298C29.5997 30.4194 29.8476 29.9519 30.2094 29.587L39.7814 19.752V14.7759C39.7805 14.4312 39.8462 14.0897 39.9747 13.7713C40.1032 13.453 40.292 13.164 40.53 12.9213L49.3773 3.82846C49.7343 3.4613 50.189 3.21121 50.6842 3.10979C51.1793 3.00837 51.6925 3.06018 52.159 3.25866C52.6255 3.45714 53.0243 3.7934 53.3051 4.22493C53.5858 4.65646 53.7359 5.16389 53.7363 5.68311V9.1218H57.0829C57.5875 9.12157 58.0808 9.27524 58.5005 9.56338C58.9201 9.85151 59.2473 10.2612 59.4406 10.7405C59.6338 11.2199 59.6845 11.7475 59.5863 12.2565C59.488 12.7655 59.2452 13.2331 58.8885 13.6002L50.0412 22.693C49.8045 22.9374 49.5231 23.1311 49.2132 23.263C48.9032 23.3948 48.571 23.4622 48.2356 23.4613H43.3888L33.8207 33.2963L33.7799 33.3382C33.3037 33.8173 32.6635 34.0847 31.9974 34.0828Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9744 62.8917C27.7298 62.8883 23.5324 61.9764 19.6491 60.2141C15.7658 58.4517 12.2816 55.8775 9.41807 52.6551C6.55457 49.4327 4.37446 45.6327 3.0164 41.4968C1.65834 37.3608 1.15205 32.9795 1.52973 28.6314C2.22514 20.8092 5.73931 13.5371 11.3819 8.24358C17.0245 2.9501 24.3878 0.0178496 32.0252 0.0228335C37.0353 0.0314081 41.9672 1.30225 46.3894 3.72422L47.6741 4.43158L41.7088 10.5612L41.0348 10.2574C38.1826 8.97368 35.1028 8.31096 31.9894 8.31096C28.876 8.31096 25.7963 8.97368 22.944 10.2574C11.5579 15.3822 6.35573 29.0689 11.3433 40.7665C12.5316 43.5544 14.2424 46.0743 16.3778 48.182C18.5132 50.2898 21.0314 51.9443 23.7887 53.0509C26.546 54.1576 29.4882 54.6947 32.4473 54.6316C35.4065 54.5685 38.3245 53.9064 41.0348 52.6833C52.4209 47.5584 57.6231 33.8717 52.6355 22.1742L52.3401 21.481L58.3047 15.3506L58.9894 16.6703C61.4738 21.4633 62.7082 26.8364 62.5724 32.2661C62.4366 37.6957 60.9352 42.9965 58.2145 47.6517C55.4939 52.3069 51.6468 56.1576 47.0484 58.8285C42.4499 61.4994 37.257 62.8993 31.976 62.8917H31.9744Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9412 53.9145C28.7524 53.9153 25.6022 53.1958 22.7134 51.8069C19.8246 50.4181 17.2676 48.3938 15.223 45.8769C13.1784 43.3601 11.6962 40.4122 10.881 37.2416C10.0658 34.0709 9.93756 30.7548 10.5053 27.5276C11.0731 24.3003 12.323 21.2407 14.1667 18.5648C16.0105 15.8889 18.4031 13.6621 21.1755 12.0417C23.948 10.4212 27.0326 9.44669 30.2114 9.18694C33.3902 8.92719 36.5858 9.38855 39.5722 10.5384L41.1409 11.1413L39.9576 12.359C39.6485 12.6756 39.4037 13.0521 39.2373 13.4668C39.0709 13.8815 38.9862 14.3261 38.9883 14.7747V19.2673L37.4773 18.586C35.741 17.8038 33.866 17.4 31.9705 17.4C30.0749 17.4 28.1999 17.8038 26.4636 18.586C19.5396 21.7059 16.3784 30.032 19.4142 37.146C20.1273 38.8561 21.1625 40.4041 22.46 41.7008C23.7576 42.9975 25.2919 44.0172 26.9745 44.7011C28.657 45.3851 30.4546 45.7197 32.2635 45.6857C34.0725 45.6518 35.857 45.2499 37.5141 44.5032C39.1713 43.7566 40.6684 42.6799 41.9191 41.3354C43.1697 39.9908 44.1492 38.4049 44.8011 36.6692C45.453 34.9335 45.7644 33.0822 45.7173 31.2221C45.6702 29.362 45.2655 27.5298 44.5267 25.8313L43.8635 24.2782H48.2356L48.2441 25.3859V24.2782C48.6794 24.2794 49.1107 24.192 49.513 24.021C49.9153 23.8501 50.2807 23.5989 50.5882 23.282L51.7745 22.0651L52.3608 23.6768C53.5901 27.0665 54.0032 30.7119 53.5646 34.3015C53.126 37.8911 51.8488 41.318 49.8421 44.2895C47.8355 47.2609 45.1592 49.6884 42.0418 51.3646C38.9245 53.0407 35.4589 53.9156 31.9412 53.9145Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0097 44.672C30.103 44.6716 28.2203 44.2352 26.4977 43.3944C24.7751 42.5537 23.2558 41.3296 22.0497 39.8109C20.8436 38.2921 19.9808 36.5165 19.5238 34.6127C19.0669 32.7089 19.0271 30.7244 19.4074 28.8028C19.7878 26.8812 20.5787 25.0707 21.7229 23.5021C22.8672 21.9335 24.3362 20.6461 26.0236 19.7331C27.7111 18.8201 29.5747 18.3043 31.4798 18.223C33.3849 18.1418 35.2837 18.4971 37.0388 19.2632L38.5113 19.9049L29.6302 29.0302C29.0094 29.6781 28.6635 30.5525 28.6681 31.4621C28.6727 32.3717 29.0274 33.2424 29.6547 33.8837C30.2819 34.5249 31.1306 34.8845 32.0151 34.8837C32.8995 34.883 33.7476 34.522 34.3739 33.8796L43.2427 24.7662L43.8674 26.2791C44.6966 28.2918 45.0281 30.4845 44.8321 32.6612C44.636 34.8379 43.9187 36.9307 42.7442 38.7526C41.5696 40.5745 39.9744 42.0687 38.1012 43.1016C36.228 44.1346 34.1352 44.6742 32.0097 44.672Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9973 34.0608C31.4907 34.0609 30.9955 33.9059 30.5749 33.6155C30.1543 33.3251 29.8273 32.9124 29.6357 32.4301C29.444 31.9477 29.3964 31.4177 29.4989 30.9074C29.6013 30.3971 29.8492 29.9298 30.211 29.565L39.773 19.73V14.7539C39.7721 14.4089 39.8378 14.0671 39.9665 13.7485C40.0951 13.4298 40.284 13.1406 40.5223 12.8977L49.3627 3.80644C49.7194 3.43975 50.1739 3.19006 50.6686 3.08892C51.1633 2.98779 51.6761 3.03974 52.1421 3.23823C52.6081 3.43671 53.0064 3.77281 53.2868 4.20406C53.5671 4.63531 53.7168 5.14234 53.7171 5.66109V9.09978H57.0606C57.565 9.10002 58.058 9.25403 58.4773 9.54234C58.8966 9.83066 59.2234 10.2403 59.4164 10.7196C59.6094 11.1989 59.6599 11.7262 59.5615 12.235C59.4632 12.7438 59.2204 13.2112 58.8639 13.5782L50.0227 22.671C49.7865 22.9153 49.5055 23.109 49.1959 23.2409C48.8864 23.3728 48.5545 23.4402 48.2194 23.4393H43.3819L33.8191 33.2743L33.7783 33.3162C33.3027 33.7947 32.6635 34.0621 31.9981 34.0608H31.9973Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9743 62.8697C27.3296 62.8678 22.7465 61.7773 18.5729 59.6809C14.3994 57.5846 10.7453 54.5375 7.88826 50.7713C5.0312 47.005 3.04629 42.6186 2.08434 37.9453C1.12238 33.2719 1.20868 28.4346 2.33667 23.8007C3.46466 19.1668 5.60467 14.8582 8.59414 11.2023C11.5836 7.54639 15.3439 4.63926 19.5894 2.70176C23.8349 0.76426 28.4539 -0.152641 33.0955 0.0207065C37.7371 0.194054 42.2793 1.45309 46.3769 3.70217L47.6602 4.40558L41.7002 10.5352L41.0262 10.2314C38.177 8.94773 35.1 8.285 31.9893 8.285C28.8786 8.285 25.8017 8.94773 22.9524 10.2314C11.5786 15.3562 6.37871 29.043 11.3617 40.7405C12.5464 43.5282 14.254 46.0483 16.3867 48.1565C18.5193 50.2648 21.0353 51.9199 23.7906 53.0271C26.5458 54.1343 29.4863 54.6719 32.4438 54.6091C35.4013 54.5463 38.3177 53.8844 41.0262 52.6612C52.4 47.5364 57.5999 33.8497 52.6208 22.1553L52.3254 21.4622L58.2846 15.3333L58.9693 16.6531C61.4518 21.4443 62.6853 26.8151 62.5499 32.2423C62.4144 37.6695 60.9146 42.9681 58.1964 47.622C55.4782 52.2759 51.6344 56.1265 47.0393 58.7986C42.4442 61.4707 37.2546 62.8733 31.9758 62.8697H31.9743Z"
            fill="#1E1E1E"
        />
        <path
            d="M31.9414 53.8917C28.7521 53.8938 25.6012 53.1754 22.7115 51.7874C19.8218 50.3994 17.2638 48.3756 15.2182 45.859C13.1726 43.3424 11.6894 40.3945 10.8734 37.2235C10.0575 34.0526 9.92858 30.736 10.4959 27.5082C11.0633 24.2804 12.313 21.2201 14.1568 18.5437C16.0006 15.8672 18.3934 13.6399 21.1662 12.0191C23.939 10.3984 27.0241 9.42372 30.2034 9.16405C33.3827 8.90438 36.5787 9.36603 39.5655 10.5164L41.1326 11.1193L39.9509 12.337C39.642 12.6537 39.3974 13.0303 39.2311 13.445C39.0649 13.8596 38.9803 14.3041 38.9823 14.7526V19.2453L37.4721 18.564C35.7379 17.782 33.865 17.3783 31.9714 17.3783C30.0779 17.3783 28.2049 17.782 26.4707 18.564C19.5537 21.6838 16.3925 30.01 19.4275 37.1239C20.1432 38.8284 21.1795 40.3706 22.4767 41.6619C23.7739 42.9531 25.3065 43.9679 26.9864 44.6478C28.6662 45.3278 30.4602 45.6595 32.2651 45.624C34.0701 45.5884 35.8504 45.1863 37.5037 44.4407C39.157 43.6951 40.6507 42.6207 41.8989 41.2794C43.1471 39.9381 44.1252 38.3562 44.777 36.6247C45.4287 34.8933 45.7413 33.0464 45.6966 31.1903C45.652 29.3342 45.2511 27.5055 44.5169 25.8093L43.8506 24.2545H48.2174L48.2258 25.3623V24.2545C48.6608 24.2559 49.0917 24.1685 49.4937 23.9975C49.8956 23.8265 50.2607 23.5753 50.5677 23.2584L51.7517 22.043L52.3402 23.6587C53.5701 27.0464 53.9842 30.69 53.547 34.2781C53.1099 37.8662 51.8346 41.292 49.8301 44.2629C47.8256 47.2338 45.1514 49.6614 42.0362 51.3382C38.9211 53.015 35.4574 53.8911 31.9414 53.8917Z"
            fill="#1E1E1E"
        />
        <path
            d="M32.0098 44.6499C30.1029 44.6502 28.2198 44.2144 26.4968 43.3741C24.7738 42.5338 23.254 41.3101 22.0474 39.7915C20.8407 38.2729 19.9775 36.4974 19.52 34.5935C19.0626 32.6896 19.0224 30.7049 19.4025 28.783C19.7825 26.8612 20.5732 25.0503 21.7174 23.4814C22.8616 21.9125 24.3306 20.6248 26.0181 19.7115C27.7056 18.7983 29.5695 18.2823 31.4747 18.201C33.38 18.1196 35.279 18.4749 37.0343 19.2411L38.5053 19.8828L29.6326 29.0081C29.0073 29.6545 28.6573 30.5299 28.6596 31.4418C28.6619 32.3536 29.0163 33.2271 29.6449 33.8702C30.2734 34.5133 31.1246 34.8732 32.0112 34.8709C32.8978 34.8685 33.7471 34.504 34.3724 33.8575L43.2351 24.7449L43.859 26.2578C44.6878 28.2697 45.0191 30.4614 44.8235 32.6371C44.6279 34.8128 43.9115 36.9049 42.7381 38.7264C41.5647 40.5479 39.971 42.0422 38.0992 43.0759C36.2274 44.1095 34.1359 44.6503 32.0113 44.6499H32.0098Z"
            fill="#1E1E1E"
        />
        <path
            d="M41.2755 15.9045V21.3387L30.9195 31.5052C30.775 31.6406 30.6591 31.805 30.579 31.9883C30.4988 32.1716 30.4562 32.3698 30.4536 32.5708C30.451 32.7717 30.4885 32.9711 30.5639 33.1565C30.6393 33.3419 30.7509 33.5094 30.8918 33.6487C31.1844 33.9374 31.5729 34.1008 31.9783 34.1058C32.3838 34.1108 32.7759 33.957 33.0752 33.6756L33.1029 33.6487L43.4596 23.4837H48.9988C49.4046 23.4857 49.7956 23.3268 50.0904 23.0398L59.3524 13.947C59.4952 13.8098 59.609 13.644 59.6868 13.4597C59.7646 13.2754 59.8048 13.0766 59.8048 12.8757C59.8048 12.6748 59.7646 12.476 59.6868 12.2917C59.609 12.1074 59.4952 11.9415 59.3524 11.8043C59.0572 11.5181 58.6665 11.3592 58.2607 11.3605H53.6286V6.81405C53.6286 5.97693 52.9362 5.29884 52.0845 5.29884C51.6787 5.29722 51.2879 5.45615 50.9929 5.74272L41.7278 14.8356C41.5861 14.973 41.4729 15.1386 41.3951 15.3224C41.3174 15.5061 41.2767 15.7041 41.2755 15.9045Z"
            fill="url(#paint0_linear_44_129)"
        />
        <path
            d="M59.2608 18.3234L54.6379 22.8604C60.1186 35.1371 54.421 49.4497 41.9117 54.8261C29.4024 60.2025 14.8197 54.6125 9.33978 42.3357C3.85984 30.059 9.5575 15.7464 22.066 10.366C25.2109 9.01917 28.5838 8.32576 31.9904 8.32576C35.397 8.32576 38.7699 9.01917 41.9148 10.366L46.5338 5.83226C31.4788 -2.04604 12.7695 3.54639 4.73919 18.3226C-3.29108 33.0989 2.40966 51.465 17.4624 59.3433C32.5151 67.2215 51.236 61.6299 59.2601 46.8529C64.1038 37.9373 64.1038 27.239 59.2608 18.3234Z"
            fill="url(#paint1_linear_44_129)"
        />
        <path
            d="M38.1875 15.9054C38.1888 15.3061 38.3086 14.7134 38.5398 14.1633C38.771 13.6132 39.1087 13.1172 39.5323 12.7057C28.3131 8.59126 15.8184 14.1821 11.6264 25.1937C7.43429 36.2053 13.1304 48.4678 24.3503 52.583C35.5702 56.6982 48.0641 51.1065 52.257 40.0942C54.0857 35.3054 54.0857 29.9825 52.257 25.1937C51.3751 26.0476 50.2078 26.5202 48.9966 26.5135H46.1501C49.5843 34.1995 46.02 43.1626 38.189 46.5316C30.358 49.9007 21.2261 46.4042 17.7918 38.7182C14.3575 31.0322 17.921 22.0691 25.752 18.7C27.7224 17.8555 29.8359 17.4206 31.9705 17.4206C34.1052 17.4206 36.2187 17.8555 38.189 18.7L38.1875 15.9054Z"
            fill="url(#paint2_linear_44_129)"
        />
        <path
            d="M36.835 21.4139C30.5557 18.802 23.3094 21.6805 20.6475 27.8426C17.9856 34.0048 20.9191 41.1179 27.1976 43.7306C33.4761 46.3432 40.7239 43.4639 43.3889 37.3018C44.0344 35.8134 44.3681 34.202 44.3681 32.5722C44.3681 30.9425 44.0344 29.331 43.3889 27.8426L35.2863 35.7914C33.483 37.5716 30.5503 37.5803 28.7362 35.8096C28.3069 35.3992 27.9642 34.9026 27.7292 34.3504C27.4942 33.7982 27.372 33.2022 27.3702 32.5995C27.3684 31.9967 27.487 31.4 27.7187 30.8463C27.9504 30.2927 28.2902 29.7938 28.717 29.3808L28.7362 29.3626L36.835 21.4139Z"
            fill="url(#paint3_linear_44_129)"
        />
        <path
            d="M41.2678 15.8848V21.319L30.9203 31.4831C30.7759 31.6185 30.6601 31.783 30.58 31.9663C30.4999 32.1496 30.4573 32.3479 30.4548 32.5488C30.4522 32.7498 30.4899 32.9491 30.5653 33.1345C30.6408 33.3199 30.7524 33.4874 30.8934 33.6266C31.1855 33.9153 31.5736 34.0788 31.9788 34.0837C32.3839 34.0887 32.7757 33.9349 33.0745 33.6535L33.1022 33.6266L43.4489 23.4616H48.9811C49.3868 23.4638 49.7776 23.3047 50.072 23.0177L59.3278 13.9265C59.4704 13.7892 59.584 13.6233 59.6617 13.439C59.7394 13.2547 59.7795 13.056 59.7795 12.8552C59.7795 12.6543 59.7394 12.4556 59.6617 12.2713C59.584 12.087 59.4704 11.9211 59.3278 11.7838C59.0333 11.4969 58.6427 11.3374 58.2369 11.3384H53.6094V6.79195C53.6094 5.95483 52.917 5.27674 52.0661 5.27674C51.6606 5.27533 51.27 5.43424 50.9752 5.72062L41.7194 14.8135C41.5775 14.9512 41.4643 15.1173 41.3866 15.3014C41.309 15.4856 41.2686 15.6841 41.2678 15.8848Z"
            fill="url(#paint4_linear_44_129)"
        />
        <path
            d="M59.2371 18.301L54.6212 22.8379C60.0965 35.1146 54.4034 49.4233 41.9057 54.8036C29.4079 60.184 14.8383 54.59 9.36302 42.3133C3.8877 30.0365 9.58074 15.7231 22.0785 10.3475C25.2197 9.00123 28.5891 8.30809 31.9921 8.30809C35.3951 8.30809 38.7645 9.00123 41.9057 10.3475L46.5217 5.81057C31.479 -2.06851 12.7835 3.52391 4.76397 18.3002C-3.25553 33.0764 2.43598 51.4425 17.4787 59.3208C32.5214 67.1991 51.2161 61.6074 59.2371 46.8304C64.0747 37.9148 64.0747 27.2189 59.2371 18.301Z"
            fill="url(#paint5_linear_44_129)"
        />
        <path
            d="M38.1822 15.885C38.1833 15.2858 38.3031 14.6931 38.5341 14.1429C38.7652 13.5928 39.1028 13.0969 39.5262 12.6853C28.3163 8.57088 15.8309 14.1617 11.6449 25.1733C7.45901 36.1849 13.1474 48.4474 24.3574 52.5626C35.5673 56.6778 48.0527 51.0861 52.2386 40.0738C54.0666 35.2848 54.0666 29.9622 52.2386 25.1733C51.358 26.0272 50.1916 26.4998 48.9813 26.4931H46.1348C49.566 34.1791 46.0048 43.1422 38.1807 46.5112C30.3566 49.8803 21.2323 46.3838 17.8011 38.6978C14.3699 31.0118 17.9311 22.0487 25.7552 18.6797C27.7236 17.8351 29.8352 17.4003 31.9679 17.4003C34.1007 17.4003 36.2123 17.8351 38.1807 18.6797L38.1822 15.885Z"
            fill="url(#paint6_linear_44_129)"
        />
        <path
            d="M36.8303 21.3913C30.5572 18.7803 23.3162 21.658 20.6574 27.8201C17.9986 33.9822 20.929 41.0954 27.2021 43.708C33.4753 46.3207 40.7162 43.439 43.375 37.2785C44.0198 35.79 44.353 34.1788 44.353 32.5493C44.353 30.9198 44.0198 29.3085 43.375 27.8201L35.2832 35.7688C33.4814 37.5491 30.551 37.5578 28.7393 35.787C28.3103 35.3765 27.9678 34.8798 27.733 34.3276C27.4982 33.7754 27.3761 33.1796 27.3743 32.5769C27.3725 31.9743 27.491 31.3776 27.7225 30.824C27.954 30.2704 28.2935 29.7715 28.72 29.3582L28.7393 29.34L36.8303 21.3913Z"
            fill="url(#paint7_linear_44_129)"
        />
        <path
            d="M41.2594 15.8626V21.2968L30.9258 31.4609C30.7814 31.5963 30.6656 31.7608 30.5855 31.944C30.5055 32.1273 30.4628 32.3256 30.4602 32.5265C30.4576 32.7274 30.4951 32.9267 30.5705 33.1121C30.6458 33.2975 30.7573 33.4651 30.8981 33.6044C31.19 33.893 31.5778 34.0564 31.9827 34.0614C32.3876 34.0664 32.7791 33.9126 33.0776 33.6313L33.1053 33.6044L43.439 23.4394H48.9658C49.371 23.4412 49.7612 23.2822 50.0552 22.9955L59.3033 13.9027C59.4458 13.7654 59.5593 13.5994 59.6369 13.4151C59.7145 13.2309 59.7546 13.0322 59.7546 12.8314C59.7546 12.6306 59.7145 12.4319 59.6369 12.2476C59.5593 12.0633 59.4458 11.8974 59.3033 11.76C59.0091 11.4735 58.6191 11.3143 58.2139 11.3154H53.5895V6.76975C53.5895 5.93263 52.8971 5.25454 52.0508 5.25454C51.6448 5.25226 51.2536 5.41095 50.9584 5.69763L41.711 14.7913C41.5691 14.929 41.4559 15.0951 41.3783 15.2792C41.3006 15.4634 41.2602 15.6619 41.2594 15.8626Z"
            fill="url(#paint8_linear_44_129)"
        />
        <path
            d="M59.2117 18.2778L54.5957 22.8147C60.0656 35.0914 54.378 49.4001 41.8918 54.7804C29.4055 60.1608 14.8506 54.57 9.38448 42.2932C3.91839 30.0165 9.60143 15.7031 22.0877 10.3275C25.2259 8.9811 28.5925 8.28787 31.9928 8.28787C35.3931 8.28787 38.7597 8.9811 41.8979 10.3275L46.5139 5.79055C31.4796 -2.09091 12.8011 3.50152 4.78774 18.2778C-3.2256 33.054 2.46051 51.4201 17.4909 59.2984C32.5213 67.1767 51.1983 61.585 59.2109 46.808C64.0461 37.8924 64.0461 27.1942 59.2117 18.2778Z"
            fill="url(#paint9_linear_44_129)"
        />
        <path
            d="M38.1767 15.8632C38.1776 15.2637 38.2971 14.6707 38.5279 14.1202C38.7587 13.5697 39.0959 13.0732 39.5192 12.6611C28.3193 8.54665 15.8469 14.1399 11.6625 25.1514C7.47815 36.163 13.1643 48.4247 24.3642 52.5391C35.5641 56.6535 48.0357 51.0643 52.2201 40.0519C54.0465 35.2627 54.0465 29.9407 52.2201 25.1514C51.3407 26.0054 50.1752 26.478 48.9658 26.4712H46.1193C49.5474 34.1572 45.99 43.1203 38.1729 46.4894C30.3557 49.8584 21.2438 46.3675 17.8156 38.6775C14.3875 30.9876 17.9449 22.0284 25.762 18.6594C27.7285 17.8149 29.8384 17.38 31.9694 17.38C34.1004 17.38 36.2103 17.8149 38.1767 18.6594V15.8632Z"
            fill="url(#paint10_linear_44_129)"
        />
        <path
            d="M36.8264 21.3696C30.5587 18.7585 23.3239 21.6363 20.6705 27.7984C18.017 33.9605 20.942 41.0736 27.2098 43.6863C33.4775 46.2989 40.7115 43.4196 43.3657 37.2567C44.0101 35.7682 44.3432 34.157 44.3432 32.5276C44.3432 30.8981 44.0101 29.2869 43.3657 27.7984L35.28 35.7471C33.4798 37.5273 30.5525 37.5361 28.7407 35.7653C28.3122 35.3545 27.9701 34.8576 27.7356 34.3054C27.5012 33.7533 27.3793 33.1575 27.3776 32.555C27.3759 31.9525 27.4943 31.356 27.7256 30.8024C27.9569 30.2488 28.2961 29.7499 28.7223 29.3365L28.7407 29.3183L36.8264 21.3696Z"
            fill="url(#paint11_linear_44_129)"
        />
        <path
            d="M41.251 15.8405V21.2747L30.9258 31.4388C30.7816 31.5742 30.6659 31.7386 30.5859 31.9218C30.5059 32.105 30.4632 32.3032 30.4606 32.504C30.458 32.7048 30.4955 32.904 30.5708 33.0894C30.646 33.2747 30.7574 33.4422 30.8981 33.5815C31.1895 33.8704 31.577 34.0342 31.9818 34.0393C32.3865 34.0445 32.7779 33.8906 33.0761 33.6092L33.103 33.5815L43.4282 23.4213H48.9504C49.3554 23.423 49.7453 23.264 50.0391 22.9774L59.2787 13.8846C59.4211 13.7472 59.5347 13.5813 59.6123 13.397C59.6899 13.2127 59.73 13.014 59.73 12.8132C59.73 12.6124 59.6899 12.4137 59.6123 12.2295C59.5347 12.0452 59.4211 11.8792 59.2787 11.7419C58.9848 11.4555 58.595 11.2966 58.1901 11.298H53.5741V6.74764C53.5741 5.91052 52.8817 5.23243 52.0355 5.23243C51.6306 5.23098 51.2408 5.38994 50.9469 5.67631L41.7018 14.7691C41.5601 14.9071 41.4471 15.0731 41.3696 15.2573C41.2922 15.4414 41.2518 15.6398 41.251 15.8405Z"
            fill="url(#paint12_linear_44_129)"
        />
        <path
            d="M59.1872 18.2567L54.5774 22.7936C60.0396 35.0703 54.3605 49.3829 41.8835 54.7593C29.4065 60.1358 14.8646 54.5457 9.39928 42.269C3.93396 29.9922 9.61623 15.6788 22.0932 10.3032C25.2282 8.95685 28.5918 8.2636 31.9891 8.2636C35.3864 8.2636 38.75 8.95685 41.885 10.3032L46.4956 5.76628C31.4798 -2.1128 12.8182 3.48199 4.81253 18.2559C-3.19311 33.0298 2.48916 51.3982 17.5065 59.2765C32.5238 67.1548 51.1839 61.5631 59.1895 46.7861C64.0171 37.8705 64.0178 27.1723 59.1872 18.2567Z"
            fill="url(#paint13_linear_44_129)"
        />
        <path
            d="M38.1713 15.8402C38.1724 15.2413 38.2918 14.6488 38.5223 14.0987C38.7528 13.5487 39.0896 13.0526 39.5122 12.6405C28.3223 8.52608 15.8623 14.1169 11.681 25.1285C7.49965 36.1401 13.1812 48.4026 24.3749 52.5178C35.5687 56.633 48.021 51.0413 52.2023 40.029C54.0265 35.2393 54.0265 29.9182 52.2023 25.1285C51.3238 25.9823 50.1591 26.4549 48.9504 26.4483H46.1108C49.5358 34.1343 45.9808 43.0974 38.1713 46.4664C30.3618 49.8355 21.253 46.339 17.8279 38.653C14.4029 30.967 17.9579 22.0039 25.7674 18.6349C27.7319 17.7902 29.84 17.3552 31.9693 17.3552C34.0987 17.3552 36.2068 17.7902 38.1713 18.6349V15.8402Z"
            fill="url(#paint14_linear_44_129)"
        />
        <path
            d="M36.8219 21.3471C30.5596 18.7361 23.3317 21.6138 20.6783 27.7759C18.0249 33.938 20.9483 41.0512 27.2107 43.6638C33.473 46.2765 40.7001 43.3972 43.3543 37.2343C43.998 35.7455 44.3306 34.1344 44.3306 32.5051C44.3306 30.8758 43.998 29.2646 43.3543 27.7759L35.2763 35.7246C33.4769 37.5049 30.5526 37.5136 28.7432 35.7428C28.3149 35.3318 27.9731 34.8349 27.7389 34.2828C27.5046 33.7306 27.3828 33.135 27.3811 32.5325C27.3794 31.9301 27.4977 31.3337 27.7288 30.7802C27.9599 30.2266 28.2988 29.7277 28.7247 29.3141L28.7432 29.2959L36.8219 21.3471Z"
            fill="url(#paint15_linear_44_129)"
        />
        <path
            d="M41.2424 15.8187V21.2529L30.9257 31.417C30.7816 31.5525 30.6661 31.717 30.5862 31.9002C30.5063 32.0834 30.4638 32.2814 30.4612 32.4822C30.4586 32.6829 30.496 32.8821 30.5711 33.0674C30.6462 33.2527 30.7575 33.4203 30.898 33.5597C31.1889 33.8485 31.5761 34.0122 31.9805 34.0173C32.3848 34.0225 32.7758 33.8687 33.0736 33.5874L33.1013 33.5597L43.418 23.3955H48.9349C49.3396 23.3973 49.7293 23.2383 50.0227 22.9516L59.2547 13.8588C59.3969 13.7213 59.5103 13.5554 59.5878 13.3711C59.6653 13.1868 59.7053 12.9882 59.7053 12.7875C59.7053 12.5867 59.6653 12.3881 59.5878 12.2038C59.5103 12.0196 59.3969 11.8536 59.2547 11.7161C58.9611 11.4298 58.5715 11.2708 58.1669 11.2723H53.5509V6.72664C53.5509 5.88951 52.8585 5.21143 52.0122 5.21143C51.609 5.21082 51.221 5.36943 50.9282 5.65452L41.6963 14.7474C41.554 14.885 41.4405 15.051 41.3624 15.2351C41.2844 15.4193 41.2436 15.6178 41.2424 15.8187Z"
            fill="url(#paint16_linear_44_129)"
        />
        <path
            d="M59.16 18.2344L54.5548 22.7713C60.0171 35.0481 54.3379 49.3607 41.874 54.7371C29.4101 60.1135 14.8813 54.5258 9.42132 42.2522C3.96139 29.9787 9.6375 15.6621 22.1022 10.2865C25.2338 8.94049 28.594 8.24739 31.9881 8.24739C35.3821 8.24739 38.7424 8.94049 41.874 10.2865L46.4799 5.74956C31.4803 -2.13586 12.8356 3.45736 4.83688 18.2336C-3.16184 33.0099 2.51582 51.3759 17.517 59.2542C32.5181 67.1325 51.1636 61.5409 59.16 46.7623C63.9884 37.8482 63.9884 27.15 59.16 18.2344Z"
            fill="url(#paint17_linear_44_129)"
        />
        <path
            d="M38.1653 15.8178C38.1664 15.2189 38.2857 14.6265 38.5161 14.0765C38.7465 13.5264 39.083 13.0303 39.5054 12.618C28.3263 8.50362 15.877 14.0945 11.6995 25.106C7.52207 36.1176 13.1982 48.3801 24.3781 52.4953C35.558 56.6105 48.005 51.0189 52.184 40.0065C54.0065 35.2165 54.0065 29.896 52.184 25.106C51.3067 25.9601 50.1431 26.4334 48.9351 26.4274H46.0978C49.5198 34.1134 45.9686 43.0765 38.1653 46.4456C30.3619 49.8146 21.2631 46.3182 17.8411 38.6321C14.4191 30.9461 17.9703 21.983 25.7737 18.614C27.7361 17.7695 29.8422 17.3346 31.9695 17.3346C34.0967 17.3346 36.2028 17.7695 38.1653 18.614V15.8178Z"
            fill="url(#paint18_linear_44_129)"
        />
        <path
            d="M36.8172 21.3254C30.561 18.7144 23.3401 21.5921 20.6882 27.7542C18.0364 33.9163 20.9583 41.0295 27.2145 43.6421C33.4706 46.2547 40.6962 43.3755 43.3442 37.2126C43.9872 35.7236 44.3194 34.1125 44.3194 32.4834C44.3194 30.8542 43.9872 29.2431 43.3442 27.7542L35.2747 35.7029C33.4776 37.4832 30.5549 37.4919 28.7477 35.7211C28.3198 35.3099 27.9783 34.8129 27.7442 34.2608C27.5101 33.7087 27.3885 33.1131 27.3867 32.5108C27.385 31.9085 27.5032 31.3122 27.7341 30.7587C27.965 30.2051 28.3037 29.7061 28.7293 29.2923L28.7477 29.2741L36.8172 21.3254Z"
            fill="url(#paint19_linear_44_129)"
        />
        <path
            d="M41.2348 15.7958V21.23L30.9258 31.3941C30.7819 31.5298 30.6665 31.6943 30.5868 31.8775C30.507 32.0607 30.4646 32.2588 30.462 32.4595C30.4595 32.6602 30.497 32.8593 30.5721 33.0446C30.6472 33.2299 30.7584 33.3974 30.8988 33.5368C31.1893 33.8256 31.5762 33.9893 31.9802 33.9945C32.3842 33.9996 32.7748 33.8458 33.0722 33.5645L33.0999 33.5368L43.4089 23.3726H48.9204C49.3241 23.3738 49.7126 23.2148 50.0051 22.9287L59.2294 13.8359C59.3717 13.6984 59.485 13.5325 59.5625 13.3482C59.64 13.164 59.68 12.9653 59.68 12.7646C59.68 12.5638 59.64 12.3652 59.5625 12.181C59.485 11.9967 59.3717 11.8307 59.2294 11.6933C58.9363 11.4071 58.5473 11.2481 58.1431 11.2494H53.5318V6.70296C53.5318 5.86584 52.8394 5.18775 51.9931 5.18775C51.589 5.1865 51.1999 5.34548 50.9068 5.63163L41.6848 14.7245C41.5433 14.8625 41.4304 15.0286 41.3531 15.2127C41.2757 15.3968 41.2355 15.5952 41.2348 15.7958Z"
            fill="url(#paint20_linear_44_129)"
        />
        <path
            d="M59.1386 18.213L54.5365 22.7499C59.9926 35.0266 54.3203 49.3353 41.8672 54.7156C29.414 60.096 14.8968 54.502 9.44146 42.2245C3.98614 29.9469 9.65841 15.6391 22.1108 10.2587C25.2396 8.91239 28.5974 8.21908 31.989 8.21908C35.3806 8.21908 38.7384 8.91239 41.8672 10.2587L46.4662 5.72178C31.4781 -2.15651 12.8465 3.43592 4.85856 18.2122C-3.12939 32.9884 2.54211 51.3521 17.5302 59.2328C32.5183 67.1134 51.1499 61.5194 59.1379 46.7424C63.96 37.8268 63.96 27.1286 59.1386 18.213Z"
            fill="url(#paint21_linear_44_129)"
        />
        <path
            d="M38.1576 15.7967C38.1589 15.1979 38.2781 14.6056 38.5082 14.0555C38.7383 13.5054 39.0744 13.009 39.4962 12.5962C28.3271 8.48179 15.8894 14.0734 11.7157 25.085C7.54212 36.0966 13.2152 48.3591 24.3866 52.4743C35.558 56.5895 47.9935 50.9978 52.1671 39.9855C53.9889 35.1953 53.9889 29.8751 52.1671 25.085C51.2909 25.9386 50.1282 26.4114 48.9213 26.4048H46.0856C49.5045 34.0908 45.9563 43.0539 38.1615 46.4229C30.3666 49.792 21.2731 46.2971 17.8542 38.6135C14.4353 30.9298 17.9835 21.9644 25.7783 18.5953C27.7386 17.751 29.8427 17.3162 31.968 17.3162C34.0932 17.3162 36.1973 17.751 38.1576 18.5953V15.7967Z"
            fill="url(#paint22_linear_44_129)"
        />
        <path
            d="M36.8135 21.303C30.5627 18.6919 23.3502 21.5696 20.6983 27.7317C18.0464 33.8938 20.9684 41.007 27.2192 43.6196C33.47 46.2323 40.6824 43.353 43.3343 37.1901C43.9769 35.701 44.3089 34.09 44.3089 32.4609C44.3089 30.8318 43.9769 29.2208 43.3343 27.7317L35.2717 35.6804C33.4761 37.4607 30.5565 37.4694 28.7509 35.6986C28.3233 35.2872 27.9821 34.7902 27.7482 34.2382C27.5143 33.6861 27.3927 33.0907 27.3909 32.4885C27.3891 31.8863 27.5072 31.2901 27.7377 30.7366C27.9683 30.1831 28.3065 29.6839 28.7317 29.2699L28.7509 29.2517L36.8135 21.303Z"
            fill="url(#paint23_linear_44_129)"
        />
        <path
            d="M41.2264 15.7743V21.2061L30.9258 31.3727C30.782 31.5083 30.6666 31.6728 30.5869 31.8559C30.5071 32.0391 30.4646 32.2371 30.462 32.4378C30.4594 32.6385 30.4968 32.8376 30.5718 33.0229C30.6468 33.2082 30.7578 33.3758 30.8981 33.5153C31.1883 33.8042 31.5749 33.968 31.9787 33.9731C32.3825 33.9783 32.7729 33.8244 33.0699 33.543L33.0976 33.5153L43.3974 23.3511H48.905C49.309 23.3528 49.698 23.1937 49.9906 22.9073L59.2056 13.8144C59.3477 13.6768 59.4608 13.5108 59.5382 13.3266C59.6156 13.1423 59.6555 12.9438 59.6555 12.7431C59.6555 12.5424 59.6156 12.3439 59.5382 12.1596C59.4608 11.9754 59.3477 11.8094 59.2056 11.6718C58.9128 11.3856 58.524 11.2266 58.1201 11.2279H53.5118V6.68148C53.5118 5.84435 52.824 5.16627 51.9731 5.16627C51.5692 5.16498 51.1804 5.32397 50.8876 5.61015L41.6757 14.703C41.5342 14.841 41.4214 15.0071 41.3442 15.1913C41.267 15.3754 41.2269 15.5738 41.2264 15.7743Z"
            fill="url(#paint24_linear_44_129)"
        />
        <path
            d="M59.1139 18.19L54.5163 22.727C59.9671 35.0037 54.3002 49.3124 41.8586 54.6927C29.417 60.0731 14.912 54.4815 9.46131 42.2039C4.01061 29.9264 9.67826 15.6185 22.1199 10.2382C25.2456 8.89188 28.6005 8.19856 31.9892 8.19856C35.378 8.19856 38.7328 8.89188 41.8586 10.2382L46.4561 5.69729C31.4811 -2.17942 12.8694 3.413 4.88534 18.1893C-3.09877 32.9655 2.56888 51.3316 17.5431 59.2099C32.5174 67.0882 51.1298 61.4965 59.1131 46.7195C63.9299 37.8039 63.9299 27.1056 59.1139 18.19Z"
            fill="url(#paint25_linear_44_129)"
        />
        <path
            d="M38.1574 15.7737C38.1586 15.175 38.2777 14.5827 38.5077 14.0326C38.7376 13.4825 39.0736 12.986 39.4953 12.5732C28.3354 8.45881 15.9092 14.0497 11.7386 25.062C7.56809 36.0744 13.2311 48.3361 24.3918 52.4513C35.5525 56.5665 47.9779 50.9749 52.1477 39.9625C53.9672 35.1719 53.9672 29.8526 52.1477 25.062C51.2725 25.9153 50.1108 26.3881 48.905 26.3818H46.0723C49.4881 34.0678 45.943 43.0309 38.1543 46.4C30.3656 49.769 21.2822 46.2726 17.8663 38.5865C14.4505 30.9005 17.9956 21.9374 25.7843 18.5684C27.7429 17.7239 29.8455 17.2889 31.9693 17.2889C34.0931 17.2889 36.1957 17.7239 38.1543 18.5684L38.1574 15.7737Z"
            fill="url(#paint26_linear_44_129)"
        />
        <path
            d="M36.8088 21.2846C30.5634 18.6736 23.3556 21.5513 20.7083 27.7134C18.061 33.8755 20.9783 40.9863 27.2238 43.5974C33.4692 46.2084 40.677 43.3315 43.3235 37.1686C43.9657 35.6795 44.2975 34.0685 44.2975 32.4394C44.2975 30.8104 43.9657 29.1994 43.3235 27.7102L35.2686 35.6582C33.4746 37.4384 30.558 37.4471 28.754 35.6764C28.3266 35.2649 27.9855 34.7678 27.7518 34.2158C27.518 33.6637 27.3965 33.0684 27.3947 32.4662C27.3929 31.8641 27.5108 31.268 27.7413 30.7145C27.9717 30.161 28.3098 29.6618 28.7347 29.2476L28.754 29.2294L36.8088 21.2846Z"
            fill="url(#paint27_linear_44_129)"
        />
        <path
            d="M41.2179 15.752V21.1862L30.9258 31.3503C30.7821 31.486 30.6668 31.6505 30.5871 31.8337C30.5074 32.0169 30.465 32.2148 30.4624 32.4155C30.4598 32.6162 30.4971 32.8152 30.5721 33.0005C30.647 33.1858 30.7579 33.3534 30.8981 33.493C31.188 33.7817 31.5744 33.9455 31.9779 33.9507C32.3815 33.9558 32.7716 33.802 33.0684 33.5207C33.0776 33.5112 33.0869 33.5025 33.0953 33.493L43.389 23.3288H48.8897C49.2934 23.3304 49.6821 23.1713 49.9744 22.8849L59.181 13.7921C59.3228 13.6544 59.4358 13.4883 59.5131 13.3041C59.5903 13.1199 59.6302 12.9214 59.6302 12.7208C59.6302 12.5201 59.5903 12.3216 59.5131 12.1374C59.4358 11.9532 59.3228 11.7871 59.181 11.6494C58.8884 11.3634 58.4999 11.2045 58.0962 11.2056H53.4926V6.65914C53.4926 5.82201 52.8056 5.14393 51.9585 5.14393C51.5549 5.14268 51.1663 5.30168 50.8738 5.58781L41.6672 14.6806C41.5258 14.8187 41.4131 14.9849 41.3359 15.169C41.2587 15.3531 41.2186 15.5514 41.2179 15.752Z"
            fill="url(#paint28_linear_44_129)"
        />
        <path
            d="M59.0894 18.1684L54.4965 22.7093C59.9418 34.9821 54.2803 49.2947 41.8502 54.675C29.4202 60.0554 14.9283 54.4614 9.48298 42.1839C4.03766 29.9063 9.69916 15.5985 22.1292 10.2181C25.2518 8.87185 28.6038 8.17851 31.9897 8.17851C35.3756 8.17851 38.7276 8.87185 41.8502 10.2181L46.4431 5.67723C31.482 -2.20107 12.8873 3.39136 4.91008 18.1676C-3.06711 32.9439 2.59516 51.3099 17.5563 59.1851C32.5175 67.0602 51.1122 61.4717 59.0894 46.6947C61.4559 42.3349 62.6982 37.4247 62.6982 32.4315C62.6982 27.4384 61.4559 22.5282 59.0894 18.1684Z"
            fill="url(#paint29_linear_44_129)"
        />
        <path
            d="M38.149 15.7525C38.15 15.1539 38.2689 14.5616 38.4986 14.0115C38.7282 13.4614 39.0639 12.9649 39.4853 12.552C28.3362 8.43757 15.9238 14.0284 11.7541 25.0408C7.58431 36.0531 13.2489 48.3149 24.398 52.43C35.5471 56.5452 47.9634 50.9568 52.1293 39.9412C53.9472 35.1504 53.9472 29.8316 52.1293 25.0408C51.2554 25.8941 50.0946 26.367 48.8896 26.3606H46.06C49.4728 34.0466 45.9308 43.0096 38.149 46.3787C30.3672 49.7478 21.2922 46.2513 17.8795 38.5653C14.4667 30.8793 18.008 21.9162 25.7897 18.5471C27.7465 17.7026 29.8473 17.2677 31.9694 17.2677C34.0914 17.2677 36.1922 17.7026 38.149 18.5471V15.7525Z"
            fill="url(#paint30_linear_44_129)"
        />
        <path
            d="M36.8043 21.2585C30.565 18.6475 23.3633 21.5252 20.7184 27.6873C18.0734 33.8494 20.9884 40.9626 27.2277 43.5744C33.4669 46.1863 40.6694 43.3086 43.312 37.1457C43.9535 35.6563 44.2849 34.0454 44.2849 32.4165C44.2849 30.7875 43.9535 29.1766 43.312 27.6873L35.2656 35.636C33.4738 37.4163 30.5596 37.425 28.7563 35.6542C28.3294 35.2424 27.9887 34.7452 27.7553 34.1932C27.5219 33.6412 27.4006 33.0459 27.3988 32.4439C27.3971 31.8419 27.515 31.2459 27.7452 30.6925C27.9755 30.139 28.3133 29.6398 28.7378 29.2254L28.7563 29.2072L36.8043 21.2585Z"
            fill="url(#paint31_linear_44_129)"
        />
        <path
            d="M41.2094 15.7298V21.1639L30.9258 31.3281C30.7822 31.4639 30.6671 31.6285 30.5876 31.8117C30.508 31.9949 30.4657 32.1928 30.4631 32.3935C30.4606 32.5941 30.498 32.7931 30.5729 32.9784C30.6478 33.1636 30.7587 33.3312 30.8989 33.4708C31.1882 33.7595 31.574 33.9233 31.9771 33.9284C32.3802 33.9336 32.7699 33.7797 33.0661 33.4984L33.0938 33.4708L43.3774 23.3066H48.8743C49.2778 23.3084 49.6663 23.1493 49.9583 22.8627L59.1602 13.7683C59.302 13.6305 59.4149 13.4644 59.492 13.2802C59.5692 13.096 59.609 12.8975 59.609 12.697C59.609 12.4964 59.5692 12.2979 59.492 12.1137C59.4149 11.9295 59.302 11.7634 59.1602 11.6256C58.8679 11.3396 58.4797 11.1806 58.0762 11.1818H53.4733V6.63692C53.4733 5.7998 52.7871 5.12171 51.9401 5.12171C51.5368 5.12046 51.1487 5.27949 50.8568 5.56559L41.6587 14.6584C41.5174 14.7965 41.4047 14.9627 41.3274 15.1468C41.2502 15.3309 41.2101 15.5292 41.2094 15.7298Z"
            fill="url(#paint32_linear_44_129)"
        />
        <path
            d="M59.0655 18.1455L54.4756 22.6824C59.9171 34.9592 54.2602 49.2718 41.8409 54.6482C29.4216 60.0246 14.9428 54.437 9.50214 42.1594C4.06144 29.8819 9.71833 15.5701 22.1376 10.1936C25.2572 8.84759 28.6063 8.15432 31.9893 8.15432C35.3723 8.15432 38.7214 8.84759 41.8409 10.1936L46.43 5.65672C31.4819 -2.22395 12.9041 3.36848 4.93463 18.1447C-3.03486 32.921 2.62203 51.2871 17.5693 59.1654C32.5166 67.0436 51.0952 61.452 59.0647 46.675C61.4298 42.3141 62.6714 37.4037 62.6716 32.4103C62.6717 27.417 61.4303 22.5065 59.0655 18.1455Z"
            fill="url(#paint33_linear_44_129)"
        />
        <path
            d="M38.1436 15.7298C38.1443 15.1313 38.263 14.5391 38.4924 13.989C38.7218 13.4389 39.0572 12.9424 39.4784 12.5293C28.3392 8.41486 15.9369 14.0057 11.7725 25.0181C7.60809 36.0304 13.2657 48.2922 24.4049 52.4074C35.544 56.5226 47.9464 50.9309 52.1115 39.9185C53.9272 35.1272 53.9272 29.8094 52.1115 25.0181C51.2384 25.8712 50.0785 26.3441 48.8742 26.3378H46.0469C49.4566 34.0239 45.9184 42.9869 38.1436 46.356C30.3687 49.7251 21.3014 46.2286 17.8917 38.5426C14.4821 30.8566 18.021 21.8935 25.7958 18.5244C27.7506 17.68 29.8495 17.245 31.9697 17.245C34.0899 17.245 36.1888 17.68 38.1436 18.5244V15.7298Z"
            fill="url(#paint34_linear_44_129)"
        />
        <path
            d="M36.8005 21.2363C30.5689 18.6252 23.3711 21.5029 20.7292 27.6651C18.0873 33.8272 21.0015 40.9403 27.2331 43.5522C33.4647 46.1641 40.6618 43.2863 43.3044 37.1234C43.9451 35.6339 44.2761 34.0231 44.2761 32.3943C44.2761 30.7654 43.9451 29.1546 43.3044 27.6651L35.2626 35.6138C33.4724 37.394 30.5605 37.4027 28.7595 35.632C28.3329 35.2199 27.9926 34.7227 27.7595 34.1707C27.5263 33.6187 27.4051 33.0235 27.4034 32.4217C27.4017 31.8198 27.5194 31.224 27.7494 30.6706C27.9794 30.1171 28.3168 29.6178 28.741 29.2032L28.7595 29.185L36.8005 21.2363Z"
            fill="url(#paint35_linear_44_129)"
        />
        <path
            d="M41.2008 15.7074V21.1416L30.9257 31.3058C30.7823 31.4417 30.6673 31.6063 30.5879 31.7895C30.5085 31.9726 30.4662 32.1706 30.4637 32.3711C30.4612 32.5717 30.4984 32.7707 30.5732 32.9559C30.648 33.1411 30.7588 33.3087 30.8987 33.4484C31.1877 33.7372 31.5733 33.9011 31.9762 33.9062C32.3791 33.9114 32.7686 33.7574 33.0644 33.4761L33.0921 33.4484L43.3665 23.2842H48.8587C49.262 23.2858 49.6502 23.1267 49.9419 22.8404L59.1316 13.7475C59.2733 13.6098 59.3863 13.4437 59.4634 13.2595C59.5406 13.0753 59.5804 12.8768 59.5804 12.6762C59.5804 12.4756 59.5406 12.2772 59.4634 12.0929C59.3863 11.9087 59.2733 11.7427 59.1316 11.6049C58.8399 11.319 58.4521 11.1599 58.0491 11.161H53.4539V6.61458C53.4539 5.77746 52.7677 5.09937 51.9222 5.09937C51.5192 5.09816 51.1314 5.2572 50.8398 5.54325L41.6493 14.6385C41.5085 14.7764 41.3962 14.9422 41.3191 15.1259C41.242 15.3096 41.2018 15.5074 41.2008 15.7074Z"
            fill="url(#paint36_linear_44_129)"
        />
        <path
            d="M59.041 18.1237L54.4558 22.6598C59.8918 34.9374 54.2396 49.25 41.8318 54.6256C29.4241 60.0012 14.9584 54.412 9.51997 42.1344C4.08157 29.8569 9.73615 15.549 22.1439 10.1687C25.2603 8.82248 28.6065 8.12909 31.9867 8.12909C35.3669 8.12909 38.7131 8.82248 41.8295 10.1687L46.4147 5.63175C31.4828 -2.24575 12.9235 3.34667 4.95861 18.1198C-3.00627 32.8928 2.65062 51.2621 17.5825 59.1404C32.5145 67.0187 51.0822 61.4302 59.0402 46.6532C61.4031 42.2916 62.6435 37.3815 62.6436 32.3885C62.6437 27.3956 61.4037 22.4854 59.041 18.1237Z"
            fill="url(#paint37_linear_44_129)"
        />
        <path
            d="M38.1374 15.7077C38.1383 15.1093 38.257 14.5172 38.4863 13.9672C38.7155 13.4171 39.0506 12.9205 39.4714 12.5072C28.3423 8.39277 15.9492 13.9836 11.7902 24.996C7.63116 36.0083 13.2827 48.2701 24.4118 52.3852C35.5409 56.5004 47.9348 50.9088 52.093 39.8964C53.9079 35.105 53.9079 29.7874 52.093 24.996C51.222 25.8483 50.0642 26.3212 48.8619 26.3157H46.0346C49.4412 34.0018 45.9053 42.9648 38.1374 46.3339C30.3695 49.703 21.3091 46.2089 17.9048 38.5221C14.5005 30.8353 18.0333 21.873 25.8012 18.5039C27.7539 17.6595 29.851 17.2245 31.9693 17.2245C34.0876 17.2245 36.1847 17.6595 38.1374 18.5039V15.7077Z"
            fill="url(#paint38_linear_44_129)"
        />
        <path
            d="M36.7958 21.2138C30.5681 18.6028 23.3787 21.4805 20.7391 27.6426C18.0996 33.8047 21.0084 40.9179 27.2361 43.5297C33.4639 46.1416 40.6532 43.2639 43.2936 37.101C43.9339 35.6113 44.2647 34.0005 44.2647 32.3718C44.2647 30.7431 43.9339 29.1323 43.2936 27.6426L35.2602 35.5913C33.4708 37.3716 30.5619 37.3803 28.7625 35.6095C28.3362 35.1973 27.9962 34.7 27.7632 34.148C27.5303 33.596 27.4092 33.001 27.4075 32.3992C27.4058 31.7975 27.5234 31.2017 27.7532 30.6483C27.983 30.0949 28.3202 29.5956 28.744 29.1808L28.7625 29.1626L36.7958 21.2138Z"
            fill="url(#paint39_linear_44_129)"
        />
        <path
            d="M41.1924 15.6859V21.1201L30.9295 31.2843C30.7862 31.4202 30.6713 31.5848 30.5919 31.7679C30.5124 31.9511 30.4701 32.149 30.4675 32.3495C30.4649 32.55 30.5021 32.749 30.5768 32.9342C30.6515 33.1195 30.7621 33.2871 30.9018 33.4269C31.1905 33.7156 31.5759 33.8794 31.9785 33.8846C32.3812 33.8897 32.7704 33.7359 33.066 33.4546L33.0929 33.4269L43.3565 23.2636H48.8442C49.2479 23.2654 49.6365 23.106 49.9281 22.8189L59.1078 13.7261C59.2493 13.5882 59.3621 13.422 59.4391 13.2378C59.5162 13.0537 59.5559 12.8552 59.5559 12.6547C59.5559 12.4542 59.5162 12.2558 59.4391 12.0716C59.3621 11.8874 59.2493 11.7213 59.1078 11.5834C58.8165 11.2973 58.4289 11.1383 58.0261 11.1395H53.4348V6.5931C53.4348 5.75597 52.7493 5.07789 51.9046 5.07789C51.5018 5.07655 51.1141 5.23563 50.8229 5.52177L41.6409 14.6146C41.4998 14.7529 41.3873 14.919 41.3102 15.1031C41.2332 15.2872 41.1931 15.4855 41.1924 15.6859Z"
            fill="url(#paint40_linear_44_129)"
        />
        <path
            d="M59.0164 18.1013L54.4358 22.6374C59.8665 34.915 54.2196 49.2276 41.8234 54.6032C29.4272 59.9788 14.9738 54.3896 9.54306 42.112C4.11236 29.8345 9.75847 15.5266 22.1555 10.1463C25.2688 8.80009 28.6121 8.10668 31.9894 8.10668C35.3667 8.10668 38.71 8.80009 41.8234 10.1463L46.404 5.61012C31.4836 -2.26817 12.9389 3.32346 4.98324 18.1005C-2.9724 32.8776 2.67525 51.2428 17.5956 59.1211C32.516 66.9994 51.0615 61.4078 59.0164 46.6307C61.3772 42.2684 62.6163 37.3585 62.6163 32.366C62.6163 27.3735 61.3772 22.4636 59.0164 18.1013Z"
            fill="url(#paint41_linear_44_129)"
        />
        <path
            d="M38.1321 15.685C38.1327 15.0866 38.2511 14.4945 38.4802 13.9444C38.7094 13.3943 39.0445 12.8977 39.4653 12.4845C28.3454 8.37006 15.9638 13.9609 11.8087 24.9733C7.65354 35.9856 13.2997 48.2474 24.4188 52.3626C35.5379 56.4778 47.9203 50.8861 52.0754 39.8737C53.8879 35.0818 53.8879 29.7652 52.0754 24.9733C51.2046 25.826 50.0466 26.299 48.8442 26.293H46.0216C49.4258 33.9791 45.8931 42.9421 38.1321 46.3112C30.3711 49.6803 21.3207 46.1838 17.9172 38.4978C14.5137 30.8118 18.0456 21.8487 25.8066 18.4796C27.7575 17.6352 29.8528 17.2002 31.9694 17.2002C34.086 17.2002 36.1812 17.6352 38.1321 18.4796V15.685Z"
            fill="url(#paint42_linear_44_129)"
        />
        <path
            d="M36.791 21.1924C30.5687 18.5813 23.3862 21.459 20.7489 27.6211C18.1117 33.7832 21.0182 40.8964 27.2405 43.5083C33.4629 46.1201 40.6454 43.2424 43.2826 37.0795C43.9226 35.5897 44.2531 33.979 44.2531 32.3503C44.2531 30.7216 43.9226 29.1109 43.2826 27.6211L35.257 35.5698C33.469 37.3501 30.5641 37.3588 28.7654 35.588C28.3399 35.1754 28.0005 34.678 27.7679 34.1261C27.5354 33.5742 27.4146 32.9794 27.4128 32.3779C27.411 31.7764 27.5282 31.1809 27.7575 30.6275C27.9867 30.0742 28.3231 29.5746 28.7461 29.1593L28.7654 29.1411L36.791 21.1924Z"
            fill="url(#paint43_linear_44_129)"
        />
        <path
            d="M41.1848 15.6669V21.101L30.9304 31.2612C30.7872 31.3973 30.6724 31.5618 30.593 31.745C30.5136 31.9281 30.4713 32.126 30.4688 32.3265C30.4662 32.5269 30.5033 32.7259 30.5779 32.9111C30.6525 33.0964 30.763 33.264 30.9027 33.4039C31.1911 33.6927 31.5762 33.8566 31.9786 33.8617C32.3811 33.8669 32.7701 33.7129 33.0653 33.4316L33.0922 33.4039L43.3466 23.2397H48.8289C49.2301 23.2403 49.6161 23.0812 49.9059 22.7959L59.0833 13.703C59.2247 13.5651 59.3374 13.3989 59.4143 13.2148C59.4913 13.0306 59.531 12.8322 59.531 12.6317C59.531 12.4312 59.4913 12.2328 59.4143 12.0486C59.3374 11.8645 59.2247 11.6983 59.0833 11.5604C58.7931 11.2754 58.4074 11.1165 58.0062 11.1165H53.4156V6.5677C53.4156 5.73058 52.7309 5.05249 51.8862 5.05249C51.485 5.05213 51.0991 5.21116 50.8091 5.49637L41.6326 14.5908C41.4911 14.7297 41.3785 14.8967 41.3016 15.0817C41.2246 15.2666 41.1849 15.4657 41.1848 15.6669Z"
            fill="url(#paint44_linear_44_129)"
        />
        <path
            d="M58.9925 18.0799L54.4158 22.616C59.8419 34.8936 54.2004 49.2062 41.8142 54.5818C29.428 59.9574 14.9892 54.3713 9.56308 42.0946C4.137 29.8178 9.7785 15.5092 22.1639 10.1288C25.2742 8.78267 28.6146 8.08924 31.9891 8.08924C35.3635 8.08924 38.7039 8.78267 41.8142 10.1288L46.3894 5.58873C31.4836 -2.28957 12.9558 3.30286 5.00788 18.0791C-2.94006 32.8554 2.69989 51.2214 17.6087 59.0997C32.5176 66.978 51.0438 61.3864 58.9918 46.6093C61.3506 42.2464 62.5886 37.3368 62.5887 32.3447C62.5889 27.3526 61.3511 22.443 58.9925 18.0799Z"
            fill="url(#paint45_linear_44_129)"
        />
        <path
            d="M38.1267 15.6673C38.1276 15.0691 38.246 14.4772 38.4748 13.9272C38.7037 13.3772 39.0382 12.8804 39.4584 12.4668C28.3493 8.35236 15.9777 13.9432 11.8272 24.9556C7.67661 35.9679 13.3166 48.2257 24.4257 52.3409C35.5348 56.4561 47.9056 50.8644 52.057 39.8521C53.8687 35.06 53.8687 29.7437 52.057 24.9516C51.1866 25.8051 50.0283 26.2782 48.8258 26.2714H46.0093C49.4097 33.9574 45.8808 42.9205 38.1267 46.2895C30.3726 49.6586 21.3307 46.1622 17.9295 38.4761C14.5283 30.7901 18.0587 21.827 25.812 18.458C27.761 17.6136 29.8545 17.1786 31.9694 17.1786C34.0842 17.1786 36.1777 17.6136 38.1267 18.458V15.6673Z"
            fill="url(#paint46_linear_44_129)"
        />
        <path
            d="M36.7873 21.1699C30.5704 18.5588 23.394 21.4365 20.7591 27.5987C18.1241 33.7608 21.0276 40.8739 27.2445 43.4858C33.4615 46.0977 40.6378 43.2199 43.2728 37.057C43.912 35.5671 44.2421 33.9564 44.2421 32.3278C44.2421 30.6993 43.912 29.0886 43.2728 27.5987L35.254 35.5474C33.4684 37.3276 30.5611 37.3363 28.7686 35.5656C28.3431 35.1529 28.0037 34.6555 27.7712 34.1036C27.5386 33.5517 27.4178 32.9569 27.416 32.3554C27.4142 31.754 27.5315 31.1584 27.7607 30.605C27.9899 30.0517 28.3263 29.5521 28.7494 29.1368L28.7717 29.1178L36.7873 21.1699Z"
            fill="url(#paint47_linear_44_129)"
        />
        <path
            d="M41.1764 15.6412V21.0754L30.9312 31.2396C30.7881 31.3757 30.6734 31.5404 30.5942 31.7235C30.515 31.9067 30.4728 32.1045 30.4703 32.305C30.4678 32.5054 30.5049 32.7043 30.5795 32.8895C30.6542 33.0747 30.7646 33.2424 30.9043 33.3822C31.192 33.671 31.5767 33.8349 31.9786 33.8401C32.3806 33.8452 32.7691 33.6912 33.0638 33.4099L33.0915 33.3822L43.3366 23.2181H48.8135C49.2148 23.2189 49.6008 23.0598 49.8906 22.7742L59.0587 13.6814C59.2 13.5434 59.3126 13.3772 59.3895 13.193C59.4664 13.0088 59.5061 12.8105 59.5061 12.61C59.5061 12.4096 59.4664 12.2112 59.3895 12.0271C59.3126 11.8429 59.2 11.6767 59.0587 11.5387C58.7687 11.2535 58.3828 11.0945 57.9816 11.0948H53.3964V6.54841C53.3964 5.71129 52.7125 5.0332 51.8685 5.0332C51.4673 5.03276 51.0814 5.1918 50.7914 5.47709L41.6241 14.5691C41.4831 14.7076 41.3708 14.874 41.2939 15.0582C41.2169 15.2424 41.177 15.4407 41.1764 15.6412Z"
            fill="url(#paint48_linear_44_129)"
        />
        <path
            d="M58.9677 18.0575L54.3948 22.5936C59.8163 34.8711 54.1802 49.1837 41.8055 54.5593C29.4309 59.935 15.0044 54.3457 9.58292 42.0682C4.16145 29.7906 9.79833 15.4788 22.173 10.1024C25.2802 8.75629 28.6177 8.06284 31.9893 8.06284C35.3608 8.06284 38.6984 8.75629 41.8055 10.1024L46.3784 5.56549C31.4842 -2.31201 12.9726 3.28042 5.03156 18.0567C-2.90946 32.8329 2.72742 51.199 17.6209 59.0773C32.5143 66.9556 51.0267 61.3639 58.9677 46.5869C61.3246 42.2232 62.5613 37.3139 62.5613 32.3222C62.5613 27.3305 61.3246 22.4211 58.9677 18.0575Z"
            fill="url(#paint49_linear_44_129)"
        />
        <path
            d="M38.1213 15.6412C38.122 15.0431 38.2402 14.4513 38.4688 13.9013C38.6974 13.3513 39.0316 12.8545 39.4514 12.4406C28.3523 8.32624 15.9922 13.9171 11.8448 24.9294C7.69734 35.9418 13.3334 48.2035 24.4326 52.3187C35.5317 56.4339 47.8917 50.8423 52.0392 39.8299C53.8486 35.0374 53.8486 29.722 52.0392 24.9294C51.1705 25.782 50.0142 26.2551 48.8134 26.2492H45.9969C49.3942 33.9352 45.8684 42.8983 38.1213 46.2674C30.3741 49.6364 21.3399 46.1416 17.9425 38.4548C14.5451 30.7679 18.071 21.8057 25.8181 18.4366C27.7651 17.5922 29.8567 17.1571 31.9697 17.1571C34.0827 17.1571 36.1743 17.5922 38.1213 18.4366V15.6412Z"
            fill="url(#paint50_linear_44_129)"
        />
        <path
            d="M36.7826 21.1479C30.5718 18.5369 23.4017 21.4146 20.769 27.5767C18.1364 33.7388 21.0375 40.852 27.2491 43.4638C33.4606 46.0757 40.63 43.2019 43.2627 37.0351C43.9015 35.545 44.2315 33.9344 44.2315 32.3059C44.2315 30.6774 43.9015 29.0667 43.2627 27.5767L35.2509 35.527C33.4668 37.3073 30.5657 37.316 28.7708 35.5452C28.3454 35.1325 28.0061 34.635 27.7736 34.0831C27.5411 33.5312 27.4203 32.9364 27.4186 32.3349C27.4169 31.7334 27.5342 31.1379 27.7635 30.5845C27.9928 30.0312 28.3293 29.5317 28.7523 29.1164L28.7708 29.0982L36.7826 21.1479Z"
            fill="url(#paint51_linear_44_129)"
        />
        <path
            d="M41.1679 15.6192V21.0533L30.9358 31.2175C30.7929 31.3537 30.6784 31.5184 30.5993 31.7016C30.5202 31.8847 30.4781 32.0825 30.4756 32.2829C30.4731 32.4833 30.5102 32.6821 30.5847 32.8673C30.6591 33.0525 30.7695 33.2202 30.9089 33.3601C31.1962 33.6476 31.5798 33.8108 31.9806 33.8159C32.3814 33.8211 32.7688 33.6679 33.063 33.3878L33.0907 33.3601L43.3228 23.196H48.7958C49.1972 23.197 49.5833 23.0379 49.8729 22.7521L59.0279 13.6593C59.1691 13.5211 59.2814 13.355 59.3582 13.1708C59.435 12.9866 59.4746 12.7883 59.4746 12.5879C59.4746 12.3876 59.435 12.1893 59.3582 12.0051C59.2814 11.8209 59.1691 11.6547 59.0279 11.5166C58.7381 11.2312 58.3521 11.0721 57.9508 11.0727H53.3772V6.52632C53.3772 5.6892 52.694 5.01111 51.8508 5.01111C51.4495 5.01051 51.0636 5.16956 50.7738 5.45499L41.6187 14.547C41.4773 14.6853 41.3645 14.8516 41.287 15.0358C41.2096 15.22 41.169 15.4184 41.1679 15.6192Z"
            fill="url(#paint52_linear_44_129)"
        />
        <path
            d="M58.944 18.0348L54.375 22.5709C59.7919 34.8484 54.1604 49.161 41.7965 54.5366C29.4325 59.9123 15.0238 54.323 9.60307 42.0455C4.18237 29.7679 9.81848 15.4601 22.1816 10.0797C25.2861 8.73317 28.6213 8.03946 31.9906 8.03946C35.3599 8.03946 38.695 8.73317 41.7995 10.0797L46.3686 5.54279C31.4844 -2.33471 12.9897 3.25771 5.05633 18.034C-2.877 32.8102 2.75372 51.1763 17.6341 59.0546C32.5145 66.9329 51.0092 61.3412 58.9433 46.5642C61.2982 42.1999 62.5339 37.2908 62.534 32.2996C62.5342 27.3083 61.2987 22.3992 58.944 18.0348Z"
            fill="url(#paint53_linear_44_129)"
        />
        <path
            d="M38.1158 15.6185C38.1164 15.0204 38.2345 14.4286 38.463 13.8787C38.6914 13.3287 39.0255 12.8318 39.4452 12.4179C28.3554 8.30354 16.0068 13.8944 11.8632 24.9067C7.71962 35.9191 13.3496 48.1808 24.4395 52.296C35.5294 56.4112 47.8771 50.8196 52.0207 39.8072C53.8294 35.0146 53.8294 29.6994 52.0207 24.9067C51.1535 25.7592 49.9984 26.2324 48.7988 26.2265H45.9838C49.3788 33.9125 45.8561 42.8756 38.1158 46.2447C30.3756 49.6137 21.3498 46.1173 17.9548 38.4313C14.5597 30.7452 18.0833 21.7822 25.8235 18.4131C27.7686 17.5687 29.8584 17.1337 31.9697 17.1337C34.0809 17.1337 36.1708 17.5687 38.1158 18.4131V15.6185Z"
            fill="url(#paint54_linear_44_129)"
        />
        <path
            d="M36.7788 21.1264C30.5726 18.5154 23.4094 21.3931 20.7767 27.5552C18.1441 33.7173 21.0475 40.8281 27.2537 43.4392C33.4599 46.0502 40.6224 43.1733 43.2558 37.0104C43.8939 35.5201 44.2234 33.9096 44.2234 32.2812C44.2234 30.6529 43.8939 29.0423 43.2558 27.552L35.2547 35.5007C33.4722 37.281 30.5741 37.2897 28.7808 35.5189C28.3561 35.1058 28.0174 34.6082 27.7854 34.0564C27.5533 33.5045 27.4328 32.9099 27.4311 32.3086C27.4293 31.7074 27.5465 31.1121 27.7753 30.5588C28.0042 30.0055 28.34 29.5059 28.7624 29.0902L28.7808 29.072L36.7788 21.1264Z"
            fill="url(#paint55_linear_44_129)"
        />
        <path
            d="M41.1578 15.5969V21.0311L30.9334 31.1953C30.7905 31.3315 30.676 31.4962 30.5969 31.6793C30.5178 31.8624 30.4757 32.0601 30.4731 32.2605C30.4705 32.4609 30.5075 32.6597 30.5819 32.8449C30.6562 33.0301 30.7664 33.1978 30.9057 33.3379C31.1927 33.626 31.5764 33.7895 31.9773 33.7946C32.3783 33.7998 32.7659 33.6462 33.0598 33.3656L33.0867 33.3379L43.3157 23.1738H48.7834C49.1848 23.1751 49.571 23.016 49.8604 22.7299L59.0101 13.6371C59.1512 13.4989 59.2636 13.3327 59.3404 13.1486C59.4172 12.9644 59.4568 12.7661 59.4568 12.5657C59.4568 12.3653 59.4172 12.1671 59.3404 11.9829C59.2636 11.7987 59.1512 11.6325 59.0101 11.4944C58.7203 11.2089 58.3343 11.0498 57.933 11.0505H53.3578V6.50411C53.3578 5.66698 52.6746 4.9889 51.833 4.9889C51.4316 4.98788 51.0455 5.147 50.7559 5.43278L41.6063 14.5248C41.4651 14.6632 41.3526 14.8295 41.2755 15.0137C41.1984 15.198 41.1584 15.3964 41.1578 15.5969Z"
            fill="url(#paint56_linear_44_129)"
        />
        <path
            d="M58.9193 18.0132L54.3548 22.551C59.7663 34.8269 54.1402 49.1363 41.7878 54.5167C29.4355 59.8971 15.0351 54.3031 9.62291 42.0255C4.21067 29.748 9.83832 15.4402 22.1907 10.0598C25.2916 8.71369 28.6234 8.02018 31.9892 8.02018C35.3551 8.02018 38.6869 8.71369 41.7878 10.0598L46.3523 5.52285C31.485 -2.35624 13.0072 3.23619 5.08001 18.0124C-2.84716 32.7887 2.77972 51.1548 17.647 59.0331C32.5143 66.9114 50.9921 61.3213 58.9193 46.5427C61.272 42.1776 62.5064 37.2688 62.5064 32.278C62.5064 27.2871 61.272 22.3783 58.9193 18.0132Z"
            fill="url(#paint57_linear_44_129)"
        />
        <path
            d="M38.1107 15.5972C38.1113 14.9993 38.2293 14.4077 38.4574 13.8578C38.6856 13.3078 39.0193 12.8109 39.4385 12.3967C28.3602 8.2823 16.0217 13.8731 11.8811 24.8855C7.74059 35.8979 13.3667 48.1596 24.4458 52.2748C35.5249 56.39 47.8635 50.7983 52.0055 39.786C53.8119 34.9929 53.8119 29.6786 52.0055 24.8855C51.1392 25.7379 49.9848 26.2111 48.7859 26.2053H45.9717C49.3629 33.8913 45.8432 42.8544 38.1107 46.2234C30.3781 49.5925 21.3593 46.096 17.9681 38.41C14.5769 30.724 18.0958 21.7609 25.8291 18.3919C27.7723 17.5475 29.8604 17.1124 31.9699 17.1124C34.0794 17.1124 36.1675 17.5475 38.1107 18.3919V15.5972Z"
            fill="url(#paint58_linear_44_129)"
        />
        <path
            d="M36.7727 21.104C30.5727 18.4929 23.4156 21.3706 20.7883 27.5327C18.1611 33.6948 21.0561 40.808 27.2561 43.4199C33.4561 46.0317 40.6132 43.154 43.2412 36.9911C43.8786 35.5006 44.2077 33.8902 44.2077 32.2619C44.2077 30.6337 43.8786 29.0232 43.2412 27.5327L35.2402 35.4815C33.4584 37.2617 30.5627 37.2704 28.7717 35.4997C28.3471 35.0864 28.0086 34.5888 27.7767 34.0369C27.5448 33.4851 27.4243 32.8905 27.4226 32.2893C27.4209 31.6882 27.5379 31.0929 27.7666 30.5396C27.9954 29.9864 28.331 29.4867 28.7532 29.0709L28.7717 29.0527L36.7727 21.104Z"
            fill="url(#paint59_linear_44_129)"
        />
        <path
            d="M41.1518 15.5749V21.009L30.9343 31.1756C30.7915 31.3119 30.6771 31.4766 30.5981 31.6597C30.519 31.8429 30.4769 32.0406 30.4744 32.241C30.4719 32.4413 30.509 32.6401 30.5834 32.8253C30.6578 33.0105 30.7681 33.1782 30.9074 33.3182C31.1941 33.6068 31.5779 33.7707 31.9791 33.7758C32.3802 33.781 32.7678 33.627 33.0615 33.3459L33.0884 33.3182L43.3059 23.1517H48.7681C49.1695 23.1529 49.5557 22.9937 49.8452 22.7078L58.9848 13.615C59.126 13.4768 59.2384 13.3106 59.3152 13.1265C59.392 12.9423 59.4316 12.744 59.4316 12.5436C59.4316 12.3433 59.392 12.145 59.3152 11.9608C59.2384 11.7766 59.126 11.6104 58.9848 11.4723C58.6952 11.1865 58.3091 11.0274 57.9078 11.0284H53.338V6.48202C53.338 5.64489 52.6556 4.96681 51.8147 4.96681C51.4133 4.96537 51.027 5.12455 50.7376 5.41069L41.598 14.5027C41.4574 14.6414 41.3454 14.8078 41.2687 14.9921C41.192 15.1763 41.1523 15.3745 41.1518 15.5749Z"
            fill="url(#paint60_linear_44_129)"
        />
        <path
            d="M58.8956 17.9901L54.335 22.5262C59.7419 34.8037 54.1204 49.1163 41.7788 54.492C29.4372 59.8676 15.05 54.2783 9.64312 42.0039C4.23627 29.7296 9.85853 15.4186 22.1994 10.0382C25.2973 8.69224 28.6261 7.99877 31.9891 7.99877C35.3521 7.99877 38.6809 8.69224 41.7788 10.0382L46.3394 5.49888C31.4859 -2.37941 13.0274 3.21302 5.1033 17.9893C-2.8208 32.7655 2.80608 51.1316 17.6603 59.0099C32.5145 66.8882 50.9723 61.2965 58.8964 46.5195C61.2469 42.1537 62.4799 37.2451 62.4798 32.2547C62.4796 27.2643 61.2463 22.3558 58.8956 17.9901Z"
            fill="url(#paint61_linear_44_129)"
        />
        <path
            d="M38.1052 15.5748C38.1055 14.977 38.2232 14.3854 38.4511 13.8355C38.679 13.2855 39.0124 12.7885 39.4315 12.3742C28.3624 8.25984 16.0354 13.8507 11.8995 24.863C7.76355 35.8754 13.385 48.1371 24.4526 52.2523C35.5202 56.3675 47.851 50.7759 51.9854 39.7635C53.7902 34.9701 53.7902 29.6564 51.9854 24.863C51.1198 25.7152 49.9663 26.1884 48.7681 26.1828H45.9592C49.3443 33.8688 45.8308 42.8319 38.1052 46.201C30.3795 49.57 21.3684 46.0736 17.9802 38.3876C14.5921 30.7015 18.1087 21.7385 25.8343 18.3694C27.7756 17.5251 29.862 17.09 31.9697 17.09C34.0775 17.09 36.1638 17.5251 38.1052 18.3694V15.5748Z"
            fill="url(#paint62_linear_44_129)"
        />
        <path
            d="M36.7727 21.0815C30.578 18.4705 23.4271 21.3482 20.8022 27.5103C18.1772 33.6724 21.0699 40.7856 27.2645 43.3974C33.4592 46.0093 40.607 43.1316 43.235 36.9687C43.872 35.4781 44.2009 33.8677 44.2009 32.2395C44.2009 30.6113 43.872 29.0009 43.235 27.5103L35.2425 35.459C33.463 37.2393 30.5696 37.248 28.7801 35.4772C28.3559 35.0638 28.0177 34.5661 27.7859 34.0143C27.5542 33.4625 27.4338 32.8681 27.432 32.267C27.4302 31.666 27.5471 31.0708 27.7755 30.5176C28.0039 29.9643 28.3391 29.4645 28.7609 29.0484L28.7801 29.0302L36.7727 21.0815Z"
            fill="url(#paint63_linear_44_129)"
        />
        <path
            d="M41.1433 15.5529V20.9871L30.9351 31.1512C30.7925 31.2876 30.6783 31.4524 30.5993 31.6355C30.5204 31.8187 30.4784 32.0163 30.4759 32.2166C30.4734 32.4169 30.5104 32.6157 30.5847 32.8008C30.659 32.986 30.769 33.1537 30.9082 33.2939C31.1945 33.5832 31.5784 33.7475 31.9798 33.7527C32.3812 33.7578 32.769 33.6034 33.0623 33.3216L33.09 33.2939L43.2959 23.1297H48.7535C49.1549 23.131 49.5411 22.9718 49.8306 22.6858L58.9625 13.593C59.1033 13.4546 59.2153 13.2884 59.2919 13.1042C59.3684 12.9201 59.4079 12.7219 59.4079 12.5217C59.4079 12.3214 59.3684 12.1232 59.2919 11.9391C59.2153 11.755 59.1033 11.5887 58.9625 11.4503C58.6731 11.1644 58.2869 11.0052 57.8855 11.0065H53.3195V6.46005C53.3195 5.62292 52.6379 4.94484 51.797 4.94484C51.3955 4.94316 51.0092 5.10237 50.7199 5.38872L41.5895 14.4808C41.4491 14.6196 41.3372 14.786 41.2605 14.9702C41.1838 15.1544 41.144 15.3525 41.1433 15.5529Z"
            fill="url(#paint64_linear_44_129)"
        />
        <path
            d="M58.8708 17.9688L54.3133 22.505C59.7147 34.7825 54.0986 49.0951 41.7686 54.4707C29.4385 59.8463 15.0636 54.2571 9.66212 41.9795C4.26065 29.702 9.87676 15.3902 22.2068 10.0138C25.3016 8.66774 28.6275 7.97416 31.9877 7.97416C35.3478 7.97416 38.6738 8.66774 41.7686 10.0138L46.3245 5.47687C31.4857 -2.40063 13.041 3.19179 5.12846 17.968C-2.7841 32.7443 2.83201 51.1143 17.6724 58.9887C32.5128 66.863 50.9582 61.2753 58.8708 46.4983C61.2193 42.1318 62.4513 37.2236 62.4513 32.2336C62.4513 27.2436 61.2193 22.3353 58.8708 17.9688Z"
            fill="url(#paint65_linear_44_129)"
        />
        <path
            d="M38.0989 15.5527C38.0995 14.955 38.2172 14.3635 38.4449 13.8136C38.6727 13.2638 39.0058 12.7667 39.4245 12.3521C28.3653 8.23774 16.0499 13.8286 11.9171 24.8409C7.78422 35.8533 13.4003 48.115 24.4633 52.2302C35.5263 56.3454 47.8348 50.7538 51.9668 39.7414C53.7698 34.9477 53.7698 29.6347 51.9668 24.8409C51.1026 25.6928 49.9504 26.166 48.7533 26.1607H45.9461C49.3311 33.8467 45.8184 42.8098 38.0989 46.1789C30.3794 49.5479 21.3783 46.0507 17.9932 38.3671C14.6082 30.6834 18.1209 21.718 25.8404 18.3489C27.7797 17.5046 29.8641 17.0695 31.97 17.0695C34.0759 17.0695 36.1604 17.5046 38.0997 18.3489L38.0989 15.5527Z"
            fill="url(#paint66_linear_44_129)"
        />
        <path
            d="M36.7657 21.0598C30.5764 18.4487 23.4324 21.3264 20.8098 27.4885C18.1871 33.6507 21.0783 40.7638 27.266 43.3757C33.4537 45.9875 40.5993 43.1098 43.2219 36.9469C43.8585 35.4563 44.1872 33.8459 44.1872 32.2177C44.1872 30.5896 43.8585 28.9792 43.2219 27.4885L35.2393 35.4373C33.4614 37.2175 30.571 37.2262 28.7823 35.4555C28.3586 35.0418 28.0208 34.544 27.7894 33.9922C27.5579 33.4404 27.4377 32.8461 27.436 32.2451C27.4343 31.6442 27.5511 31.0492 27.7793 30.496C28.0075 29.9428 28.3425 29.443 28.7639 29.0267L28.7823 29.0085L36.7657 21.0598Z"
            fill="url(#paint67_linear_44_129)"
        />
        <path
            d="M41.1348 15.5302V20.9683L30.9358 31.1285C30.7934 31.2651 30.6793 31.4299 30.6005 31.613C30.5217 31.7961 30.4798 31.9937 30.4773 32.1939C30.4748 32.3942 30.5117 32.5928 30.5859 32.778C30.6601 32.9631 30.7699 33.1309 30.9089 33.2712C31.195 33.561 31.579 33.7257 31.9805 33.7309C32.382 33.7361 32.77 33.5812 33.063 33.2989C33.0722 33.2894 33.0815 33.2807 33.0899 33.2712L43.2851 23.1046H48.7381C49.1395 23.1061 49.5258 22.9469 49.8152 22.6608L58.9371 13.5703C59.0779 13.4319 59.1899 13.2657 59.2664 13.0815C59.343 12.8974 59.3824 12.6992 59.3824 12.499C59.3824 12.2987 59.343 12.1005 59.2664 11.9164C59.1899 11.7323 59.0779 11.566 58.9371 11.4276C58.6477 11.1414 58.2615 10.9823 57.86 10.9838H53.2979V6.43735C53.2979 5.60023 52.6171 4.92214 51.7769 4.92214C51.3754 4.91987 50.9888 5.07917 50.6999 5.36602L41.5802 14.4581C41.44 14.597 41.3283 14.7635 41.2518 14.9477C41.1753 15.1318 41.1355 15.3299 41.1348 15.5302Z"
            fill="url(#paint68_linear_44_129)"
        />
        <path
            d="M58.847 17.9471L54.2949 22.4832C59.6917 34.7608 54.0802 49.0734 41.761 54.449C29.4417 59.8246 15.0775 54.2385 9.68375 41.961C4.28997 29.6834 9.89762 15.3756 22.2169 9.99521C25.3082 8.64965 28.6307 7.9563 31.9874 7.9563C35.3441 7.9563 38.6666 8.64965 41.7579 9.99521L46.3123 5.45592C31.4866 -2.42238 13.058 3.17005 5.15316 17.9463C-2.7517 32.7226 2.85825 51.0886 17.6855 58.9669C32.5128 66.8452 50.9414 61.2528 58.8447 46.4765C61.1916 42.1096 62.4229 37.2017 62.4233 32.2121C62.4237 27.2225 61.1932 22.3144 58.847 17.9471Z"
            fill="url(#paint69_linear_44_129)"
        />
        <path
            d="M38.0935 15.5312C38.0941 14.9336 38.2117 14.3422 38.4393 13.7923C38.6669 13.2425 38.9999 12.7454 39.4183 12.3307C28.3684 8.21626 16.0637 13.8071 11.9355 24.8195C7.80728 35.8318 13.4165 48.0935 24.4633 52.2087C35.5101 56.3239 47.8201 50.7323 51.9491 39.7215C53.7509 34.9275 53.7509 29.615 51.9491 24.821C51.086 25.6731 49.9344 26.1464 48.7379 26.1408H45.9337C49.3188 33.8268 45.806 42.7899 38.0935 46.159C30.381 49.528 21.386 46.0316 18.0055 38.3456C14.6251 30.6595 18.1332 21.6965 25.8458 18.3274C27.783 17.483 29.8656 17.0479 31.9696 17.0479C34.0737 17.0479 36.1562 17.483 38.0935 18.3274V15.5312Z"
            fill="url(#paint70_linear_44_129)"
        />
        <path
            d="M36.7613 21.0373C30.5782 18.4263 23.4404 21.304 20.82 27.4661C18.1997 33.6282 21.087 40.7414 27.2709 43.3532C33.4547 45.9651 40.5918 43.0874 43.2121 36.9245C43.848 35.4336 44.1763 33.8233 44.1763 32.1953C44.1763 30.5673 43.848 28.957 43.2121 27.4661L35.2342 35.4148C33.4578 37.1951 30.5697 37.2038 28.7834 35.433C28.3599 35.0191 28.0223 34.5213 27.7911 33.9695C27.5599 33.4177 27.4398 32.8235 27.4381 32.2227C27.4363 31.6219 27.553 31.0269 27.7811 30.4737C28.0091 29.9206 28.3438 29.4207 28.7649 29.0042L28.7834 28.986L36.7613 21.0373Z"
            fill="url(#paint71_linear_44_129)"
        />
        <path
            d="M41.1271 15.5092V20.9434L30.9374 31.1068C30.795 31.2433 30.681 31.4081 30.6022 31.5912C30.5234 31.7743 30.4814 31.9719 30.4788 32.172C30.4762 32.3722 30.5131 32.5709 30.5871 32.7561C30.6612 32.9412 30.7709 33.1091 30.9097 33.2495C31.1953 33.538 31.5783 33.702 31.9786 33.7071C32.379 33.7123 32.7658 33.5582 33.0584 33.2771L33.0853 33.2495L43.2751 23.0853H48.7235C49.125 23.087 49.5113 22.9278 49.8006 22.6414L58.9133 13.5486C59.0539 13.4102 59.1659 13.2439 59.2424 13.0597C59.3189 12.8756 59.3583 12.6775 59.3583 12.4772C59.3583 12.277 59.3189 12.0789 59.2424 11.8948C59.1659 11.7106 59.0539 11.5443 58.9133 11.4059C58.6239 11.1197 58.2377 10.9605 57.8362 10.962H53.281V6.41562C53.281 5.5785 52.6009 4.90041 51.7616 4.90041C51.36 4.89815 50.9735 5.05745 50.6845 5.3443L41.5718 14.4363C41.4316 14.5754 41.3199 14.742 41.2435 14.9263C41.1671 15.1107 41.1276 15.3089 41.1271 15.5092Z"
            fill="url(#paint72_linear_44_129)"
        />
        <path
            d="M58.8223 17.9239L54.2748 22.46C59.667 34.7376 54.0601 49.0502 41.7524 54.4258C29.4447 59.8014 15.0959 54.2153 9.70366 41.9354C4.31142 29.6555 9.91753 15.35 22.226 9.96965C25.3146 8.62365 28.6348 7.93004 31.9892 7.93004C35.3436 7.93004 38.6638 8.62365 41.7524 9.96965L46.3007 5.43272C31.4865 -2.44557 13.0772 3.14685 5.17692 17.9231C-2.72333 32.6994 2.88432 51.0654 17.6985 58.9469C32.5128 66.8284 50.9282 61.2296 58.8223 46.4533C61.167 42.0856 62.3968 37.1778 62.3968 32.1886C62.3968 27.1994 61.167 22.2916 58.8223 17.9239Z"
            fill="url(#paint73_linear_44_129)"
        />
        <path
            d="M38.0883 15.5085C38.0887 14.911 38.2061 14.3197 38.4334 13.7699C38.6607 13.22 38.9934 12.7228 39.4115 12.308C28.3716 8.19281 16.0777 13.7837 11.9534 24.796C7.82897 35.8084 13.4335 48.0701 24.4734 52.1853C35.5133 56.3005 47.8064 50.7081 51.9285 39.6965C53.728 34.9021 53.728 29.5904 51.9285 24.796C51.0664 25.6478 49.9158 26.1211 48.7204 26.1158H45.9216C49.3005 33.8018 45.7939 42.7649 38.0883 46.134C30.3827 49.503 21.3938 46.0066 18.018 38.3205C14.6422 30.6345 18.1457 21.6714 25.8513 18.3024C27.7867 17.4581 29.8675 17.0229 31.9698 17.0229C34.0721 17.0229 36.1529 17.4581 38.0883 18.3024V15.5085Z"
            fill="url(#paint74_linear_44_129)"
        />
        <path
            d="M36.7573 21.0156C30.5788 18.4045 23.4479 21.2822 20.8322 27.4444C18.2164 33.6065 21.0991 40.7196 27.2768 43.3315C33.4546 45.9434 40.5862 43.0656 43.202 36.9027C43.8374 35.4118 44.1655 33.8015 44.1655 32.1736C44.1655 30.5456 43.8374 28.9353 43.202 27.4444L35.234 35.3931C33.4599 37.1733 30.5742 37.182 28.7893 35.4113C28.3661 34.9972 28.0289 34.4993 27.7978 33.9476C27.5668 33.3958 27.4468 32.8017 27.4451 32.2009C27.4434 31.6002 27.5599 31.0054 27.7878 30.4522C28.0156 29.8991 28.3501 29.3991 28.7709 28.9825L28.7893 28.9643L36.7573 21.0156Z"
            fill="url(#paint75_linear_44_129)"
        />
        <path
            d="M41.1185 15.4853V20.9194L30.938 31.0836C30.7956 31.2202 30.6817 31.385 30.6029 31.5681C30.5242 31.7512 30.4823 31.9488 30.4798 32.149C30.4773 32.3492 30.5142 32.5479 30.5883 32.733C30.6624 32.9181 30.7722 33.0859 30.911 33.2263C31.1962 33.5148 31.5788 33.6789 31.9788 33.684C32.3789 33.6892 32.7653 33.5351 33.0575 33.254L33.0844 33.2263L43.2649 23.0621H48.7079C49.1095 23.0644 49.4961 22.9051 49.785 22.6182L58.8885 13.5254C59.0291 13.387 59.1411 13.2207 59.2176 13.0365C59.294 12.8524 59.3335 12.6543 59.3335 12.4541C59.3335 12.2538 59.294 12.0557 59.2176 11.8716C59.1411 11.6874 59.0291 11.5212 58.8885 11.3827C58.5995 11.0959 58.213 10.9366 57.8114 10.9388H53.2616V6.39243C53.2616 5.55531 52.5823 4.87722 51.7437 4.87722C51.3421 4.87495 50.9556 5.03425 50.6667 5.3211L41.5632 14.4131C41.423 14.5521 41.3114 14.7186 41.235 14.9028C41.1586 15.087 41.119 15.285 41.1185 15.4853Z"
            fill="url(#paint76_linear_44_129)"
        />
        <path
            d="M58.7986 17.9029L54.255 22.4391C59.6403 34.7166 54.0411 49.0292 41.7441 54.4048C29.4472 59.7804 15.1107 54.1912 9.72385 41.9136C4.337 29.6361 9.93772 15.3283 22.2347 9.94788C25.3203 8.60191 28.6377 7.90828 31.9894 7.90828C35.3411 7.90828 38.6585 8.60191 41.7441 9.94788L46.2878 5.41095C31.4874 -2.46655 13.092 3.12588 5.20095 17.9021C-2.69006 32.6784 2.91066 51.0444 17.7118 58.9227C32.5129 66.801 50.9068 61.2086 58.7986 46.4324C61.1411 42.0639 62.3697 37.1564 62.3697 32.1676C62.3697 27.1789 61.1411 22.2714 58.7986 17.9029Z"
            fill="url(#paint77_linear_44_129)"
        />
        <path
            d="M38.0805 15.4858C38.0808 14.8884 38.198 14.2972 38.4251 13.7474C38.6521 13.1975 38.9844 12.7003 39.4022 12.2853C28.3731 8.17085 16.0915 13.7617 11.9695 24.7741C7.84738 35.7864 13.4481 48.0481 24.478 52.1625C35.5079 56.2769 47.7887 50.6861 51.9108 39.6745C53.7094 34.88 53.7094 29.5686 51.9108 24.7741C51.0499 25.6258 49.9003 26.0992 48.7057 26.0938H45.9084C49.285 33.7799 45.7815 42.7429 38.0828 46.112C30.3841 49.4811 21.4068 45.9846 18.031 38.2986C14.6552 30.6126 18.1587 21.6495 25.8566 18.2804C27.7896 17.4361 29.8682 17.0007 31.9683 17.0003C34.0685 16.9999 36.1472 17.4345 38.0805 18.278V15.4858Z"
            fill="url(#paint78_linear_44_129)"
        />
        <path
            d="M36.7525 20.9929C30.5802 18.3818 23.4554 21.2595 20.8397 27.4217C18.224 33.5838 21.1066 40.6969 27.279 43.3088C33.4513 45.9207 40.5761 43.0437 43.1918 36.88C43.8267 35.3889 44.1545 33.7787 44.1545 32.1508C44.1545 30.523 43.8267 28.9128 43.1918 27.4217L35.2338 35.3688C34.3701 36.2255 33.2178 36.7058 32.0178 36.7092C30.8177 36.7126 29.6629 36.2388 28.7946 35.387C28.3717 34.973 28.0346 34.4754 27.8036 33.924C27.5725 33.3726 27.4523 32.7789 27.4502 32.1785C27.448 31.5781 27.5639 30.9834 27.791 30.4303C28.0181 29.8771 28.3516 29.377 28.7715 28.9598L28.7899 28.9416L36.7525 20.9929Z"
            fill="url(#paint79_linear_44_129)"
        />
        <path
            d="M41.1102 15.464V20.8982L30.9389 31.0623C30.7967 31.1991 30.683 31.3639 30.6043 31.547C30.5257 31.7301 30.4839 31.9276 30.4814 32.1278C30.4789 32.3279 30.5157 32.5265 30.5897 32.7116C30.6637 32.8967 30.7733 33.0645 30.9119 33.205C31.1966 33.4936 31.5789 33.6577 31.9786 33.6628C32.3783 33.668 32.7644 33.5138 33.0561 33.2327L33.0838 33.205L43.2551 23.0408H48.6973C49.097 23.0417 49.4814 22.8825 49.769 22.597L58.8648 13.5041C59.0052 13.3656 59.117 13.1993 59.1934 13.0151C59.2697 12.831 59.3091 12.6329 59.3091 12.4328C59.3091 12.2326 59.2697 12.0345 59.1934 11.8504C59.117 11.6663 59.0052 11.5 58.8648 11.3615C58.5766 11.0766 58.192 10.9183 57.7923 10.92H53.2425V6.37117C53.2425 5.53404 52.564 4.85596 51.7262 4.85596C51.3258 4.85556 50.9411 5.01564 50.6537 5.30221L41.5548 14.3919C41.4147 14.5308 41.3031 14.6974 41.2267 14.8815C41.1503 15.0657 41.1107 15.2638 41.1102 15.464Z"
            fill="url(#paint80_linear_44_129)"
        />
        <path
            d="M58.7756 17.8825L54.2365 22.4162C59.6218 34.6938 54.0226 49.0064 41.7364 54.382C29.4502 59.7576 15.1276 54.1684 9.74538 41.8908C4.36314 29.6133 9.95925 15.3015 22.2447 9.92508C25.3272 8.57912 28.6417 7.88545 31.9906 7.88545C35.3394 7.88545 38.6539 8.57912 41.7364 9.92508L46.2755 5.38816C31.4882 -2.48935 13.1089 3.10308 5.22556 17.8825C-2.65776 32.6619 2.9368 51.0216 17.7241 58.8999C32.5114 66.7782 50.8899 61.1858 58.7756 46.4095C61.1156 42.0407 62.3428 37.1339 62.3428 32.146C62.3428 27.1581 61.1156 22.2513 58.7756 17.8825Z"
            fill="url(#paint81_linear_44_129)"
        />
        <path
            d="M38.0804 15.4638C38.0803 14.867 38.197 14.2762 38.4234 13.7267C38.6498 13.1771 38.9813 12.68 39.3982 12.2649C28.3784 8.15046 16.1068 13.7413 11.9893 24.7537C7.87186 35.766 13.4664 48.0285 24.4863 52.1429C35.5062 56.2574 47.7778 50.6665 51.8952 39.6549C53.6916 34.8599 53.6916 29.5495 51.8952 24.7545C51.0361 25.6042 49.8891 26.0767 48.6971 26.0719H45.896C49.2695 33.7579 45.7683 42.721 38.0773 46.09C30.3863 49.4591 21.4159 45.9626 18.0432 38.2766C14.6704 30.5906 18.1709 21.6275 25.8619 18.2584C27.7935 17.4143 29.8708 16.9792 31.9696 16.9792C34.0684 16.9792 36.1457 17.4143 38.0773 18.2584L38.0804 15.4638Z"
            fill="url(#paint82_linear_44_129)"
        />
        <path
            d="M36.7489 20.9686C30.5819 18.3575 23.4633 21.2376 20.8476 27.3997C18.2319 33.5618 21.1138 40.675 27.2807 43.2869C33.4477 45.8987 40.5663 43.021 43.1797 36.8581C43.8141 35.3668 44.1416 33.7567 44.1416 32.1289C44.1416 30.5012 43.8141 28.891 43.1797 27.3997L35.2279 35.3484C34.3652 36.2052 33.2137 36.6855 32.0145 36.6889C30.8153 36.6923 29.6612 36.2185 28.794 35.3666C28.3716 34.9521 28.035 34.4541 27.8045 33.9024C27.5739 33.3507 27.4542 32.7568 27.4525 32.1563C27.4507 31.5558 27.5671 30.9612 27.7944 30.4081C28.0218 29.8551 28.3555 29.355 28.7755 28.9379L28.794 28.9197L36.7489 20.9686Z"
            fill="url(#paint83_linear_44_129)"
        />
        <path
            d="M41.1024 15.4414V20.8756L30.9395 31.0398C30.7976 31.1766 30.684 31.3415 30.6055 31.5246C30.527 31.7077 30.4852 31.9052 30.4827 32.1052C30.4802 32.3053 30.517 32.5038 30.5909 32.6889C30.6647 32.874 30.7741 33.0419 30.9126 33.1824C31.197 33.4709 31.579 33.635 31.9785 33.6401C32.3779 33.6453 32.7638 33.4912 33.0552 33.2101C33.0644 33.2006 33.0736 33.1919 33.0821 33.1824L43.245 23.0183H48.678C49.0777 23.0191 49.462 22.8599 49.7496 22.5744L58.8408 13.4816C58.9812 13.343 59.0929 13.1766 59.1691 12.9925C59.2454 12.8084 59.2848 12.6104 59.2848 12.4102C59.2848 12.2101 59.2454 12.0121 59.1691 11.828C59.0929 11.6439 58.9812 11.4775 58.8408 11.3389C58.5537 11.0531 58.1695 10.8938 57.7699 10.895H53.2239V6.34861C53.2183 5.941 53.0554 5.5524 52.7712 5.26826C52.487 4.98411 52.1047 4.8277 51.7083 4.8334C51.3088 4.8322 50.9245 4.99146 50.6374 5.27728L41.5463 14.3693C41.4062 14.5083 41.2947 14.6749 41.2185 14.859C41.1422 15.0432 41.1027 15.2413 41.1024 15.4414Z"
            fill="url(#paint84_linear_44_129)"
        />
        <path
            d="M58.75 17.8583L54.2148 22.3928C59.5924 34.6704 54.0009 48.983 41.7263 54.3586C29.4516 59.7342 15.1413 54.145 9.76367 41.8674C4.38606 29.5899 9.97755 15.2781 22.2522 9.90165C25.3316 8.55574 28.6433 7.86206 31.9892 7.86206C35.3352 7.86206 38.6468 8.55574 41.7263 9.90165L46.2653 5.36473C31.4942 -2.51356 13.1295 3.07886 5.25232 17.8551C-2.62485 32.6314 2.9628 50.9998 17.737 58.8781C32.5112 66.7564 50.8728 61.1632 58.75 46.3877C61.0885 42.0179 62.3147 37.1109 62.3147 32.123C62.3147 27.1351 61.0885 22.2281 58.75 17.8583Z"
            fill="url(#paint85_linear_44_129)"
        />
        <path
            d="M38.0719 15.4412C38.0717 14.8443 38.1881 14.2534 38.4143 13.7036C38.6404 13.1538 38.9716 12.6563 39.3882 12.2407C28.3783 8.12629 16.1183 13.7171 12.0039 24.7295C7.88953 35.7419 13.4802 48.0036 24.4901 52.118C35.5 56.2324 47.7601 50.6415 51.8783 39.63C53.6731 34.8347 53.6731 29.5248 51.8783 24.7295C51.0193 25.5809 49.8715 26.0543 48.6786 26.0493H45.8837C49.2533 33.7353 45.7559 42.6984 38.0719 46.0674C30.3878 49.4365 21.4259 45.94 18.0555 38.254C14.685 30.568 18.1832 21.6049 25.8672 18.2359C27.797 17.3916 29.8725 16.9564 31.9696 16.9564C34.0666 16.9564 36.1422 17.3916 38.0719 18.2359V15.4412Z"
            fill="url(#paint86_linear_44_129)"
        />
        <path
            d="M36.7442 20.9482C30.5827 18.3372 23.471 21.2149 20.8599 27.377C18.2488 33.5391 21.1261 40.6523 27.2876 43.2641C33.4492 45.876 40.5609 42.9983 43.172 36.8354C43.8056 35.3439 44.1327 33.7338 44.1327 32.1062C44.1327 30.4785 43.8056 28.8685 43.172 27.377L35.2248 35.3257C34.3633 36.1824 33.2128 36.6628 32.0145 36.6662C30.8162 36.6696 29.6631 36.1957 28.7971 35.3439C28.3749 34.9292 28.0386 34.4311 27.8083 33.8794C27.5779 33.3278 27.4583 32.7339 27.4566 32.1336C27.4548 31.5332 27.571 30.9386 27.7982 30.3856C28.0254 29.8325 28.3589 29.3324 28.7786 28.9151L28.7971 28.8969L36.7442 20.9482Z"
            fill="url(#paint87_linear_44_129)"
        />
        <path
            d="M41.0939 15.4193V20.8534L30.941 31.0168C30.799 31.1536 30.6854 31.3184 30.6068 31.5015C30.5282 31.6845 30.4864 31.882 30.4838 32.0821C30.4812 32.2821 30.5179 32.4807 30.5917 32.6658C30.6655 32.8509 30.7749 33.0188 30.9133 33.1595C31.1974 33.448 31.5792 33.6121 31.9784 33.6173C32.3777 33.6225 32.7633 33.4683 33.0544 33.1872C33.0636 33.1777 33.0728 33.169 33.0813 33.1595L43.2349 22.9961H48.6633C49.0627 22.9961 49.4465 22.8363 49.7334 22.5506L58.8161 13.4594C58.9563 13.3207 59.0678 13.1543 59.144 12.9702C59.2202 12.7861 59.2594 12.5881 59.2594 12.388C59.2594 12.188 59.2202 11.99 59.144 11.8059C59.0678 11.6218 58.9563 11.4554 58.8161 11.3167C58.5293 11.0309 58.1453 10.8717 57.746 10.8728H53.207V6.33037C53.2048 6.12811 53.1637 5.92829 53.0863 5.74237C53.0088 5.55644 52.8964 5.38808 52.7555 5.24694C52.6146 5.1058 52.4481 4.99465 52.2653 4.91988C52.0826 4.8451 51.8873 4.80817 51.6906 4.81121C51.2914 4.81008 50.9075 4.96903 50.6205 5.2543L41.5378 14.3471C41.3977 14.4861 41.2862 14.6527 41.21 14.8368C41.1337 15.021 41.0942 15.2191 41.0939 15.4193Z"
            fill="url(#paint88_linear_44_129)"
        />
        <path
            d="M58.7261 17.8361L54.1947 22.3722C59.5669 34.6498 53.9808 48.9624 41.7169 54.338C29.4531 59.7136 15.1543 54.1275 9.7836 41.85C4.41291 29.5725 9.99671 15.2646 22.2606 9.88424C25.3371 8.53835 28.6459 7.84465 31.9892 7.84465C35.3324 7.84465 38.6412 8.53835 41.7177 9.88424L46.2491 5.34732C31.4887 -2.53097 13.1433 3.05908 5.27302 17.8353C-2.59722 32.6116 2.98888 50.9776 17.75 58.8559C32.5111 66.7342 50.8558 61.1418 58.7261 46.3671C61.0626 41.9964 62.2877 37.0894 62.2877 32.1016C62.2877 27.1138 61.0626 22.2069 58.7261 17.8361Z"
            fill="url(#paint89_linear_44_129)"
        />
        <path
            d="M38.0666 15.4193C38.0671 14.8219 38.1844 14.2308 38.4114 13.681C38.6385 13.1311 38.9707 12.6339 39.3883 12.2187C28.3869 8.10432 16.1391 13.6952 12.0294 24.7075C7.91963 35.7199 13.5003 47.9816 24.5002 52.096C35.5001 56.2104 47.7494 50.6196 51.8599 39.608C53.6532 34.8124 53.6532 29.5031 51.8599 24.7075C51.0021 25.5587 49.8553 26.0322 48.6633 26.0273H45.8714C49.238 33.7133 45.7437 42.6764 38.0666 46.0455C30.3894 49.4145 21.4352 45.9181 18.0686 38.232C14.702 30.546 18.1956 21.583 25.8735 18.2139C27.8012 17.3696 29.8748 16.9344 31.97 16.9344C34.0652 16.9344 36.1389 17.3696 38.0666 18.2139V15.4193Z"
            fill="url(#paint90_linear_44_129)"
        />
        <path
            d="M36.7402 20.9267C30.5856 18.3157 23.4785 21.1934 20.8697 27.3555C18.2609 33.5176 21.1359 40.6308 27.2913 43.2426C33.4467 45.8545 40.553 42.9768 43.1618 36.8139C43.7947 35.3222 44.1214 33.7122 44.1214 32.0847C44.1214 30.4572 43.7947 28.8472 43.1618 27.3555L35.2216 35.3042C34.3613 36.1608 33.2118 36.6413 32.0144 36.6447C30.8169 36.6481 29.6648 36.1742 28.8 35.3224C28.3782 34.9075 28.0421 34.4094 27.8119 33.8577C27.5818 33.3061 27.4622 32.7123 27.4605 32.1121C27.4588 31.5118 27.5749 30.9173 27.8019 30.3643C28.0289 29.8113 28.3621 29.3111 28.7815 28.8936L28.8 28.8754L36.7402 20.9267Z"
            fill="url(#paint91_linear_44_129)"
        />
        <path
            d="M41.0855 15.397V20.8312L30.9419 30.9953C30.8001 31.1322 30.6866 31.2972 30.6081 31.4802C30.5297 31.6633 30.488 31.8607 30.4855 32.0608C30.483 32.2608 30.5197 32.4593 30.5935 32.6444C30.6673 32.8295 30.7767 32.9974 30.915 33.138C31.1984 33.427 31.5799 33.5914 31.9789 33.5965C32.378 33.6017 32.7634 33.4472 33.0537 33.1657L33.0807 33.138L43.2243 22.9738H48.6481C49.0471 22.9748 49.4308 22.8155 49.7174 22.53L58.7955 13.4371C58.9356 13.2984 59.047 13.132 59.1231 12.9479C59.1992 12.7638 59.2385 12.5658 59.2385 12.3658C59.2385 12.1658 59.1992 11.9678 59.1231 11.7837C59.047 11.5996 58.9356 11.4332 58.7955 11.2945C58.5089 11.0088 58.1252 10.8495 57.7261 10.8506H53.1871V6.30417C53.1846 6.10252 53.1435 5.90335 53.0661 5.71805C52.9887 5.53275 52.8766 5.36495 52.7361 5.22424C52.5957 5.08352 52.4296 4.97266 52.2475 4.89797C52.0653 4.82328 51.8707 4.78624 51.6746 4.78896C51.2757 4.78795 50.8921 4.9469 50.6052 5.23205L41.5287 14.3217C41.3888 14.4615 41.2775 14.6286 41.2014 14.8133C41.1253 14.998 41.0859 15.1964 41.0855 15.397Z"
            fill="url(#paint92_linear_44_129)"
        />
        <path
            d="M58.7023 17.8138L54.1748 22.3499C59.5424 34.6275 53.9617 48.9401 41.7117 54.3157C29.4617 59.6913 15.1744 54.1021 9.80681 41.8245C4.4392 29.547 10.0199 15.2391 22.27 9.85875C25.3432 8.51287 28.649 7.81914 31.9893 7.81914C35.3295 7.81914 38.6354 8.51287 41.7086 9.85875L46.2353 5.32262C31.4888 -2.55568 13.1603 3.03675 5.297 17.813C-2.56632 32.5893 3.01517 50.9561 17.7625 58.8336C32.5097 66.7111 50.839 61.1195 58.6985 46.3432C61.0334 41.9724 62.2578 37.0661 62.2585 32.079C62.2592 27.0919 61.036 22.1853 58.7023 17.8138Z"
            fill="url(#paint93_linear_44_129)"
        />
        <path
            d="M38.0612 15.3967C38.0613 14.7997 38.1779 14.2088 38.4041 13.659C38.6304 13.1092 38.9616 12.6118 39.3783 12.1961C28.3869 8.08172 16.1499 13.6726 12.0432 24.6865C7.93654 35.7004 13.5165 47.9606 24.5064 52.075C35.4962 56.1894 47.734 50.5985 51.8407 39.587C53.6325 34.7911 53.6325 29.4824 51.8407 24.6865C50.9841 25.5377 49.8382 26.0113 48.6472 26.0063H45.8584C49.2226 33.6923 45.7314 42.6554 38.0612 46.0244C30.391 49.3935 21.4437 45.897 18.0809 38.211C14.7182 30.525 18.2079 21.5619 25.8789 18.1929C27.8047 17.3486 29.8766 16.9134 31.97 16.9134C34.0635 16.9134 36.1354 17.3486 38.0612 18.1929V15.3967Z"
            fill="url(#paint94_linear_44_129)"
        />
        <path
            d="M36.7358 20.9044C30.5858 18.2933 23.4864 21.171 20.8799 27.3332C18.2734 33.4953 21.1461 40.6084 27.2961 43.2203C33.4461 45.8321 40.5424 42.9544 43.152 36.7931C43.7845 35.3013 44.111 33.6914 44.111 32.0639C44.111 30.4365 43.7845 28.8265 43.152 27.3347L35.2187 35.2819C34.3596 36.1385 33.211 36.619 32.0145 36.6224C30.818 36.6258 29.6669 36.1518 28.8032 35.3001C28.3817 34.885 28.0459 34.3868 27.8159 33.8352C27.5859 33.2836 27.4665 32.6899 27.4648 32.0897C27.4631 31.4895 27.5791 30.8952 27.8059 30.3422C28.0327 29.7892 28.3656 29.2889 28.7848 28.8713L28.8032 28.8531L36.7358 20.9044Z"
            fill="url(#paint95_linear_44_129)"
        />
        <path
            d="M41.0809 15.3753V20.8102L30.9427 30.9736C30.8009 31.1106 30.6875 31.2755 30.6091 31.4586C30.5308 31.6416 30.4891 31.8391 30.4866 32.0391C30.4841 32.2391 30.5208 32.4375 30.5945 32.6226C30.6683 32.8077 30.7775 32.9756 30.9158 33.1163C31.1991 33.4045 31.5801 33.5685 31.9785 33.5737C32.377 33.5788 32.7619 33.4248 33.0522 33.144L33.0799 33.1163L43.2143 22.9521H48.6334C49.032 22.9529 49.4152 22.7936 49.7013 22.5082L58.7686 13.4154C58.9085 13.2765 59.0197 13.1101 59.0957 12.926C59.1717 12.7419 59.2108 12.544 59.2108 12.3441C59.2108 12.1441 59.1717 11.9462 59.0957 11.7621C59.0197 11.578 58.9085 11.4116 58.7686 11.2727C58.4821 10.9865 58.0979 10.8271 57.6984 10.8288H53.1663V6.28243C53.1639 6.08088 53.1229 5.8818 53.0457 5.69656C52.9684 5.51132 52.8564 5.34354 52.7161 5.20283C52.5758 5.06212 52.4099 4.95122 52.2279 4.87647C52.0459 4.80173 51.8513 4.7646 51.6554 4.76722C51.2567 4.76625 50.8733 4.92521 50.5868 5.21031L41.5202 14.3031C41.3812 14.4427 41.2707 14.6095 41.1953 14.7936C41.1198 14.9777 41.0809 15.1755 41.0809 15.3753Z"
            fill="url(#paint96_linear_44_129)"
        />
        <path
            d="M58.6786 17.7917L54.1549 22.3278C59.5179 34.6053 53.9418 48.9179 41.7002 54.2936C29.4587 59.6692 15.1868 54.0799 9.82383 41.8024C4.46083 29.5248 10.0369 15.217 22.2785 9.83661C25.3488 8.49076 28.6519 7.797 31.9894 7.797C35.3269 7.797 38.63 8.49076 41.7002 9.83661L46.2239 5.30207C31.4897 -2.57781 13.1773 3.01461 5.32171 17.7909C-2.53392 32.5671 3.04141 50.9332 17.7756 58.8115C32.5098 66.6898 50.8222 61.0974 58.6778 46.3211C61.0101 41.9493 62.2329 37.0431 62.2331 32.0565C62.2332 27.0698 61.0107 22.1636 58.6786 17.7917Z"
            fill="url(#paint97_linear_44_129)"
        />
        <path
            d="M38.0551 15.3752C38.0552 14.7783 38.1718 14.1875 38.3979 13.6377C38.624 13.088 38.955 12.5904 39.3715 12.1747C28.3916 8.06025 16.1639 13.6511 12.0603 24.6635C7.95665 35.6758 13.5328 47.9375 24.5126 52.0519C35.4925 56.1663 47.7202 50.5755 51.8238 39.5639C53.6141 34.7677 53.6141 29.4596 51.8238 24.6635C50.9684 25.5145 49.8236 25.9881 48.6334 25.9832H45.8461C49.2074 33.6693 45.7192 42.6323 38.0551 46.0014C30.3911 49.3705 21.4545 45.874 18.0933 38.188C14.7321 30.502 18.2203 21.5389 25.8843 18.1698C27.8081 17.3256 29.8781 16.8904 31.9697 16.8904C34.0614 16.8904 36.1313 17.3256 38.0551 18.1698V15.3752Z"
            fill="url(#paint98_linear_44_129)"
        />
        <path
            d="M36.7313 20.8822C30.5867 18.2711 23.4942 21.1488 20.8901 27.3109C18.2859 33.473 21.1555 40.5862 27.3001 43.202C33.4447 45.8178 40.538 42.9362 43.1422 36.7733C43.7739 35.2813 44.0999 33.6714 44.0999 32.0441C44.0999 30.4168 43.7739 28.8069 43.1422 27.3149L35.2181 35.2636C34.3599 36.1201 33.212 36.6006 32.0162 36.604C30.8204 36.6074 29.67 36.1334 28.8072 35.2818C28.3862 34.8665 28.0508 34.3681 27.8211 33.8166C27.5915 33.265 27.4722 32.6715 27.4705 32.0715C27.4688 31.4714 27.5846 30.8772 27.8111 30.3242C28.0376 29.7713 28.3701 29.2709 28.7888 28.853L28.8072 28.8348L36.7313 20.8822Z"
            fill="url(#paint99_linear_44_129)"
        />
        <path
            d="M41.0693 15.3507V20.7848L30.9434 30.9514C30.8018 31.0885 30.6885 31.2534 30.6103 31.4365C30.532 31.6195 30.4904 31.8169 30.4879 32.0168C30.4854 32.2168 30.522 32.4152 30.5957 32.6003C30.6693 32.7853 30.7784 32.9533 30.9164 33.094C31.1998 33.3817 31.5805 33.5453 31.9786 33.5503C32.3767 33.5553 32.7612 33.4014 33.0513 33.1209C33.0605 33.1122 33.0698 33.1035 33.0782 33.094L43.2042 22.9299H48.6202C49.0188 22.9307 49.402 22.7714 49.6881 22.486L58.7439 13.3932C58.8837 13.2543 58.995 13.0878 59.071 12.9038C59.147 12.7197 59.1861 12.5218 59.1861 12.3218C59.1861 12.1219 59.147 11.924 59.071 11.7399C58.995 11.5558 58.8837 11.3894 58.7439 11.2505C58.4582 10.9649 58.0752 10.8056 57.6768 10.8066H53.147V6.26021C53.1415 5.85393 52.9795 5.46649 52.6965 5.18299C52.4134 4.89949 52.0326 4.7431 51.6376 4.74817C51.2393 4.7472 50.8564 4.90618 50.5705 5.19126L41.5116 14.2809C41.3723 14.4199 41.2615 14.5862 41.1855 14.7699C41.1095 14.9536 41.07 15.151 41.0693 15.3507Z"
            fill="url(#paint100_linear_44_129)"
        />
        <path
            d="M58.6547 17.7689L54.1349 22.305C59.4933 34.5825 53.9218 48.8951 41.691 54.2708C29.4602 59.6464 15.2022 54.0571 9.84074 41.7796C4.47928 29.502 10.0562 15.1918 22.287 9.81145C25.3541 8.46563 28.6543 7.77186 31.989 7.77186C35.3236 7.77186 38.6238 8.46563 41.691 9.81145L46.2108 5.27769C31.4904 -2.6006 13.1942 2.99182 5.34554 17.7681C-2.50316 32.5443 3.06756 50.9104 17.7887 58.7887C32.5098 66.667 50.8052 61.0746 58.6539 46.2983C60.9843 41.9258 62.2059 37.02 62.2061 32.0337C62.2062 27.0474 60.9848 22.1415 58.6547 17.7689Z"
            fill="url(#paint101_linear_44_129)"
        />
        <path
            d="M38.0498 15.3509C38.0498 14.754 38.1662 14.1632 38.3922 13.6135C38.6181 13.0637 38.9491 12.5662 39.3654 12.1504C28.3948 8.03596 16.1786 13.6268 12.0796 24.6392C7.98058 35.6515 13.5505 47.9132 24.5204 52.0277C35.4903 56.1421 47.7072 50.5512 51.8062 39.5396C53.5949 34.7432 53.5949 29.4356 51.8062 24.6392C50.9526 25.4904 49.8094 25.9649 48.6204 25.9613H45.8339C49.192 33.6473 45.707 42.6104 38.0498 45.9795C30.3927 49.3485 21.4631 45.8537 18.1057 38.1676C14.7484 30.4816 18.2319 21.5225 25.8898 18.1479C27.8117 17.3037 29.88 16.8685 31.9698 16.8685C34.0597 16.8685 36.1279 17.3037 38.0498 18.1479V15.3509Z"
            fill="url(#paint102_linear_44_129)"
        />
        <path
            d="M36.7274 20.8601C30.5881 18.249 23.5018 21.1267 20.9 27.2888C18.2981 33.451 21.1654 40.5641 27.3046 43.176C33.4439 45.7878 40.5302 42.9101 43.1321 36.7472C43.7635 35.2551 44.0893 33.6453 44.0893 32.018C44.0893 30.3908 43.7635 28.7809 43.1321 27.2888L35.2134 35.2376C34.3564 36.094 33.2095 36.5746 32.0146 36.578C30.8197 36.5814 29.6702 36.1073 28.8087 35.2558C28.388 34.8402 28.053 34.3418 27.8236 33.7902C27.5941 33.2387 27.475 32.6453 27.4733 32.0454C27.4716 31.4455 27.5873 30.8514 27.8135 30.2985C28.0398 29.7455 28.3719 29.2451 28.7902 28.827L28.8087 28.8088L36.7274 20.8601Z"
            fill="url(#paint103_linear_44_129)"
        />
        <path
            d="M41.0614 15.3303V20.7645L30.9448 30.9287C30.8032 31.0657 30.69 31.2307 30.6118 31.4137C30.5335 31.5967 30.4918 31.794 30.4893 31.9939C30.4867 32.1938 30.5232 32.3922 30.5967 32.5773C30.6702 32.7624 30.7791 32.9304 30.9171 33.0713C31.1996 33.3598 31.5803 33.5239 31.9784 33.5289C32.3766 33.534 32.761 33.3795 33.0504 33.0982C33.0596 33.0895 33.0689 33.0808 33.0773 33.0713L43.194 22.9071H48.6032C49.0013 22.9079 49.384 22.7487 49.6695 22.4633L58.7199 13.372C58.8596 13.233 58.9706 13.0665 59.0465 12.8825C59.1224 12.6984 59.1614 12.5006 59.1614 12.3007C59.1614 12.1008 59.1224 11.9029 59.0465 11.7189C58.9706 11.5348 58.8596 11.3684 58.7199 11.2294C58.4345 10.9438 58.0517 10.7845 57.6536 10.7855H53.1284V6.23748C53.1237 5.83092 52.9622 5.44291 52.6795 5.15877C52.3967 4.87463 52.0158 4.71762 51.6205 4.72227C51.2225 4.72117 50.8398 4.88019 50.5542 5.16536L41.503 14.2582C41.3636 14.3975 41.2526 14.5642 41.1768 14.7483C41.101 14.9324 41.0617 15.1303 41.0614 15.3303Z"
            fill="url(#paint104_linear_44_129)"
        />
        <path
            d="M58.6302 17.7479L54.115 22.284C59.4687 34.5616 53.9026 48.8742 41.6826 54.2498C29.4626 59.6254 15.2177 54.0361 9.86389 41.7586C4.51012 29.4811 10.0762 15.1732 22.2963 9.79285C25.3604 8.44696 28.6579 7.7531 31.9898 7.7531C35.3218 7.7531 38.6192 8.44696 41.6834 9.79285L46.1993 5.25592C31.4905 -2.62158 13.2112 2.97085 5.36946 17.7471C-2.47232 32.5233 3.09378 50.8894 17.8011 58.7677C32.5083 66.646 50.7884 61.0536 58.6302 46.2773C60.9583 41.9041 62.1787 36.9985 62.1787 32.0126C62.1787 27.0268 60.9583 22.1211 58.6302 17.7479Z"
            fill="url(#paint105_linear_44_129)"
        />
        <path
            d="M38.0444 15.3314C38.0444 14.7347 38.1607 14.144 38.3864 13.5943C38.6121 13.0446 38.9426 12.547 39.3584 12.1308C28.3977 8.01643 16.1923 13.6104 12.0972 24.6196C8.00203 35.6288 13.5666 47.8937 24.5272 52.0081C35.4879 56.1225 47.6925 50.5317 51.7884 39.5201C53.5756 34.7233 53.5756 29.4164 51.7884 24.6196C50.935 25.4704 49.7919 25.9441 48.6034 25.9394H45.8215C49.1766 33.6254 45.6946 42.5885 38.0444 45.9576C30.3941 49.3266 21.473 45.8302 18.1187 38.1442C14.7644 30.4581 18.2449 21.4951 25.8951 18.126C27.8151 17.2818 29.8816 16.8466 31.9697 16.8466C34.0579 16.8466 36.1243 17.2818 38.0444 18.126V15.3314Z"
            fill="url(#paint106_linear_44_129)"
        />
        <path
            d="M36.7227 20.8384C30.5896 18.2273 23.5094 21.105 20.9099 27.2671C18.3103 33.4292 21.1753 40.5424 27.3099 43.1543C33.4446 45.7661 40.5224 42.886 43.122 36.7255C43.7524 35.2331 44.0777 33.6234 44.0777 31.9963C44.0777 30.3692 43.7524 28.7595 43.122 27.2671L35.2102 35.2158C34.3544 36.0722 33.2085 36.5529 32.0145 36.5563C30.8205 36.5597 29.672 36.0855 28.8117 35.234C28.3912 34.8183 28.0563 34.3199 27.827 33.7684C27.5977 33.2168 27.4787 32.6235 27.4769 32.0237C27.4752 31.4238 27.5909 30.8298 27.817 30.2769C28.0431 29.724 28.3751 29.2235 28.7932 28.8053L28.8117 28.7871L36.7227 20.8384Z"
            fill="url(#paint107_linear_44_129)"
        />
        <path
            d="M41.0532 15.3091V20.7433L30.9458 30.9074C30.8043 31.0446 30.6911 31.2095 30.6129 31.3926C30.5348 31.5756 30.4932 31.773 30.4907 31.9729C30.4881 32.1728 30.5248 32.3712 30.5983 32.5562C30.6719 32.7413 30.7809 32.9092 30.9188 33.0501C31.2009 33.3386 31.5812 33.5028 31.9791 33.5078C32.3769 33.5128 32.761 33.3583 33.0499 33.077L33.0768 33.0501L43.1843 22.8859H48.588C48.9859 22.8867 49.3683 22.7274 49.6536 22.442L58.6986 13.3492C58.8383 13.2101 58.9494 13.0436 59.0252 12.8595C59.1011 12.6754 59.1402 12.4774 59.1402 12.2775C59.1402 12.0775 59.1011 11.8796 59.0252 11.6955C58.9494 11.5114 58.8383 11.3448 58.6986 11.2058C58.4135 10.9207 58.0314 10.7617 57.6338 10.7627H53.1094V6.21625C53.1073 6.01495 53.0666 5.81605 52.9897 5.63092C52.9128 5.4458 52.8012 5.27808 52.6612 5.13736C52.5212 4.99663 52.3557 4.88567 52.174 4.8108C51.9923 4.73593 51.798 4.69863 51.6023 4.70104C51.2045 4.70014 50.8221 4.85915 50.5367 5.14413L41.4948 14.237C41.3553 14.3763 41.2444 14.5429 41.1686 14.7271C41.0927 14.9112 41.0535 15.1091 41.0532 15.3091Z"
            fill="url(#paint108_linear_44_129)"
        />
        <path
            d="M58.6063 17.7244L54.095 22.2605C59.4441 34.538 53.8826 48.8506 41.6741 54.2263C29.4656 59.6019 15.2315 54.0166 9.88312 41.739C4.53474 29.4615 10.0962 15.1536 22.3047 9.77327C25.3658 8.4275 28.6604 7.73367 31.9894 7.73367C35.3185 7.73367 38.613 8.4275 41.6741 9.77327L46.1855 5.23634C31.4913 -2.64433 13.2312 2.94731 5.39332 17.7244C-2.44462 32.5014 3.11995 50.8667 17.8141 58.745C32.5083 66.6233 50.7745 61.0308 58.6063 46.2546C60.9326 41.8806 62.1519 36.9751 62.1519 31.9895C62.1519 27.0039 60.9326 22.0984 58.6063 17.7244Z"
            fill="url(#paint109_linear_44_129)"
        />
        <path
            d="M38.0388 15.3091C38.0385 14.7123 38.1545 14.1215 38.3799 13.5715C38.6054 13.0215 38.9356 12.5236 39.3513 12.107C28.4006 7.99262 16.2067 13.5835 12.117 24.5958C8.02723 35.6082 13.5833 47.8707 24.534 51.9851C35.4846 56.0995 47.6785 50.5087 51.7682 39.4971C53.5531 34.6999 53.5531 29.3938 51.7682 24.5966C50.9157 25.4473 49.7733 25.921 48.5855 25.9164H45.809C49.161 33.6024 45.6821 42.5655 38.0388 45.9346C30.3955 49.3036 21.4828 45.8135 18.1308 38.1235C14.7789 30.4335 18.257 21.4744 25.9011 18.1054C27.8192 17.2612 29.8839 16.8259 31.9703 16.8259C34.0567 16.8259 36.1214 17.2612 38.0396 18.1054L38.0388 15.3091Z"
            fill="url(#paint110_linear_44_129)"
        />
        <path
            d="M36.7188 20.8159C30.5903 18.2048 23.5171 21.0825 20.9198 27.2447C18.3226 33.4068 21.1845 40.5199 27.3099 43.1318C33.4353 45.7436 40.5116 42.8659 43.1089 36.703C43.7393 35.2107 44.0646 33.6009 44.0646 31.9738C44.0646 30.3467 43.7393 28.737 43.1089 27.2447L35.2071 35.1934C34.3524 36.0498 33.2073 36.5305 32.0141 36.5339C30.8209 36.5373 29.6732 36.0631 28.8139 35.2116C28.3939 34.7956 28.0594 34.297 27.8304 33.7455C27.6014 33.194 27.4826 32.6007 27.4809 32.001C27.4793 31.4013 27.5949 30.8074 27.8208 30.2546C28.0468 29.7017 28.3785 29.2012 28.7962 28.7828L28.8139 28.7646L36.7188 20.8159Z"
            fill="url(#paint111_linear_44_129)"
        />
        <path
            d="M41.0448 15.2859V20.7201L30.9466 30.8842C30.8054 31.0215 30.6923 31.1865 30.6143 31.3695C30.5362 31.5526 30.4947 31.7498 30.4922 31.9497C30.4897 32.1495 30.5262 32.3479 30.5997 32.5329C30.6731 32.7179 30.7819 32.8859 30.9197 33.0269C31.2015 33.3153 31.5816 33.4794 31.9791 33.4845C32.3767 33.4895 32.7605 33.335 33.0492 33.0538L33.0761 33.0269L43.1743 22.8667H48.5735C48.9711 22.8673 49.3533 22.708 49.6382 22.4228L58.6725 13.326C58.812 13.1868 58.9229 13.0202 58.9987 12.8361C59.0744 12.652 59.1135 12.4542 59.1135 12.2543C59.1135 12.0544 59.0744 11.8565 58.9987 11.6724C58.9229 11.4883 58.812 11.3217 58.6725 11.1826C58.3877 10.8976 58.0058 10.7386 57.6085 10.7395H53.0902V6.19304C53.0882 5.99185 53.0477 5.79303 52.9709 5.60796C52.8941 5.4229 52.7826 5.25521 52.6428 5.11448C52.503 4.97376 52.3376 4.86277 52.156 4.78784C51.9744 4.71292 51.7803 4.67554 51.5847 4.67783C51.1871 4.6769 50.805 4.83592 50.5199 5.12092L41.4864 14.2138C41.3472 14.3533 41.2364 14.52 41.1606 14.7041C41.0848 14.8882 41.0454 15.086 41.0448 15.2859Z"
            fill="url(#paint112_linear_44_129)"
        />
        <path
            d="M58.5824 17.7032L54.0749 22.2393C59.4194 34.5168 53.8633 48.8294 41.6648 54.2051C29.4663 59.5807 15.2468 53.9946 9.90303 41.717C4.55926 29.4395 10.1154 15.1317 22.3131 9.75128C25.3711 8.40554 28.6628 7.71169 31.989 7.71169C35.3152 7.71169 38.6068 8.40554 41.6648 9.75128L46.1723 5.21436C31.4912 -2.66631 13.245 2.92849 5.41706 17.7024C-2.41087 32.4762 3.14523 50.8447 17.8264 58.723C32.5075 66.6013 50.7537 61.0089 58.5824 46.2326C60.9064 41.858 62.1244 36.9529 62.1244 31.9679C62.1244 26.9829 60.9064 22.0778 58.5824 17.7032Z"
            fill="url(#paint113_linear_44_129)"
        />
        <path
            d="M38.0336 15.2868C38.0334 14.6903 38.1494 14.0998 38.3747 13.5501C38.6 13.0004 38.93 12.5027 39.3453 12.0863C28.4047 7.97187 16.2208 13.5627 12.1326 24.5751C8.04439 35.5874 13.5997 47.8492 24.5404 51.9636C35.481 56.078 47.6649 50.4871 51.7531 39.4756C53.5364 34.678 53.5364 29.3726 51.7531 24.5751C50.9018 25.4256 49.7604 25.8994 48.5735 25.8949H45.7969C49.1458 33.5809 45.67 42.544 38.0336 45.913C30.3973 49.2821 21.493 45.7856 18.1434 38.0996C14.7937 30.4136 18.2703 21.4505 25.9059 18.0814C27.8222 17.2373 29.8851 16.802 31.9698 16.802C34.0544 16.802 36.1174 17.2373 38.0336 18.0814V15.2868Z"
            fill="url(#paint114_linear_44_129)"
        />
        <path
            d="M36.7141 20.794C30.5918 18.183 23.5247 21.0607 20.9297 27.2228C18.3348 33.3849 21.1944 40.4981 27.3152 43.1099C33.436 45.7218 40.5053 42.8441 43.1003 36.6812C43.7298 35.1886 44.0547 33.579 44.0547 31.952C44.0547 30.325 43.7298 28.7154 43.1003 27.2228L35.2047 35.1715C34.3511 36.027 33.2074 36.5073 32.0155 36.5107C30.8237 36.5141 29.6774 36.0404 28.8192 35.1897C28.3995 34.7736 28.0653 34.275 27.8365 33.7235C27.6077 33.1721 27.4889 32.5789 27.4872 31.9794C27.4855 31.3798 27.6009 30.7859 27.8265 30.2331C28.0521 29.6802 28.3834 29.1796 28.8008 28.761L28.8192 28.7428L36.7141 20.794Z"
            fill="url(#paint115_linear_44_129)"
        />
        <path
            d="M41.037 15.2644V20.6986L30.9472 30.8627C30.806 31 30.6931 31.1651 30.6151 31.3481C30.5371 31.5311 30.4956 31.7284 30.4931 31.9282C30.4906 32.128 30.5271 32.3263 30.6005 32.5113C30.6739 32.6964 30.7826 32.8644 30.9203 33.0054C31.2016 33.2938 31.5813 33.458 31.9786 33.4631C32.3758 33.4681 32.7593 33.3135 33.0475 33.0323C33.0567 33.0236 33.0659 33.0149 33.0744 33.0054L43.1642 22.8412H48.5587C48.9554 22.8415 49.3364 22.6822 49.6204 22.3973L58.6477 13.3045C58.7872 13.1653 58.8981 12.9987 58.9739 12.8146C59.0496 12.6305 59.0887 12.4327 59.0887 12.2328C59.0887 12.0329 59.0496 11.835 58.9739 11.6509C58.8981 11.4668 58.7872 11.3002 58.6477 11.1611C58.3631 10.8762 57.9815 10.7172 57.5845 10.718H53.0716V6.17234C53.0697 5.97115 53.0292 5.77231 52.9525 5.5872C52.8759 5.4021 52.7645 5.23435 52.6247 5.09355C52.485 4.95276 52.3196 4.84167 52.1381 4.76665C51.9565 4.69163 51.7624 4.65415 51.5668 4.65634C51.1697 4.65539 50.788 4.81445 50.5036 5.09943L41.4778 14.1923C41.3387 14.3319 41.2281 14.4986 41.1524 14.6827C41.0767 14.8668 41.0374 15.0645 41.037 15.2644Z"
            fill="url(#paint116_linear_44_129)"
        />
        <path
            d="M58.5586 17.6806L54.0557 22.2168C59.3949 34.4943 53.8434 48.8069 41.6564 54.1825C29.4695 59.5581 15.2622 53.9721 9.9231 41.6985C4.58395 29.4249 10.1354 15.1091 22.3224 9.73271C25.3773 8.387 28.6661 7.69312 31.9894 7.69312C35.3127 7.69312 38.6015 8.387 41.6564 9.73271L46.1585 5.18945C31.492 -2.68963 13.2612 2.90279 5.44098 17.6798C-2.37925 32.4569 3.17146 50.8222 17.8395 58.7005C32.5075 66.5787 50.7376 60.9863 58.5586 46.2085C60.8803 41.8334 62.0969 36.9288 62.0969 31.9446C62.0969 26.9603 60.8803 22.0557 58.5586 17.6806Z"
            fill="url(#paint117_linear_44_129)"
        />
        <path
            d="M38.0283 15.2642C38.0279 14.668 38.1434 14.0777 38.368 13.5281C38.5926 12.9785 38.9218 12.4806 39.3362 12.0637C28.4056 7.94929 16.2332 13.5401 12.1488 24.5525C8.06447 35.5649 13.6106 47.8266 24.5451 51.941C35.4795 56.0554 47.648 50.4645 51.7324 39.453C53.5149 34.6553 53.5149 29.3501 51.7324 24.5525C50.8833 25.4028 49.7441 25.8772 48.5589 25.8739H45.7839C49.1305 33.5599 45.6578 42.523 38.0283 45.892C30.3989 49.2611 21.5016 45.7646 18.155 38.0786C14.8084 30.3926 18.2819 21.4295 25.9114 18.0604C27.8256 17.2163 29.8867 16.781 31.9695 16.781C34.0523 16.781 36.1133 17.2163 38.0276 18.0604L38.0283 15.2642Z"
            fill="url(#paint118_linear_44_129)"
        />
        <path
            d="M36.7104 20.7717C30.5934 18.1606 23.5325 21.0384 20.9391 27.2005C18.3457 33.3626 21.203 40.4757 27.3207 43.0876C33.4384 45.6995 40.4985 42.8217 43.0919 36.6588C43.7211 35.1662 44.0457 33.5566 44.0457 31.9297C44.0457 30.3027 43.7211 28.6932 43.0919 27.2005L35.2017 35.1492C34.3493 36.0054 33.206 36.4862 32.0145 36.4896C30.823 36.493 29.6771 36.0187 28.8201 35.1674C28.4008 34.751 28.0669 34.2523 27.8384 33.7009C27.6098 33.1495 27.4911 32.5565 27.4894 31.957C27.4877 31.3576 27.6029 30.7638 27.8283 30.211C28.0537 29.6582 28.3847 29.1575 28.8016 28.7386L28.8201 28.7204L36.7104 20.7717Z"
            fill="url(#paint119_linear_44_129)"
        />
        <path
            d="M41.0285 15.2429V20.6771L30.9502 30.8412C30.8092 30.9786 30.6964 31.1438 30.6186 31.3268C30.5407 31.5098 30.4993 31.7071 30.4969 31.9069C30.4944 32.1067 30.531 32.3049 30.6044 32.4899C30.6778 32.6749 30.7865 32.8429 30.9241 32.9839C31.205 33.2724 31.5843 33.4366 31.9812 33.4417C32.3781 33.4467 32.7613 33.2921 33.049 33.0108C33.0582 33.0021 33.0674 32.9934 33.0759 32.9839L43.158 22.8197H48.5433C48.9404 22.8202 49.322 22.6609 49.6065 22.3758L58.6215 13.283C58.7608 13.1437 58.8716 12.9771 58.9472 12.793C59.0228 12.6089 59.0618 12.4111 59.0618 12.2113C59.0618 12.0114 59.0228 11.8136 58.9472 11.6296C58.8716 11.4455 58.7608 11.2789 58.6215 11.1396C58.3373 10.8546 57.9559 10.6956 57.5591 10.6965H53.0523V6.15006C53.0504 5.94903 53.01 5.75036 52.9334 5.56541C52.8568 5.38045 52.7456 5.21282 52.6059 5.07211C52.4663 4.9314 52.3012 4.82036 52.1198 4.74533C51.9385 4.6703 51.7445 4.63276 51.5491 4.63485C51.1522 4.63411 50.7709 4.79315 50.4866 5.07794L41.4693 14.1708C41.3302 14.3104 41.2196 14.4771 41.1439 14.6612C41.0682 14.8453 41.029 15.043 41.0285 15.2429Z"
            fill="url(#paint120_linear_44_129)"
        />
        <path
            d="M58.5346 17.6584L54.0356 22.1946C59.3701 34.4721 53.8233 48.7847 41.6494 54.1603C29.4755 59.5359 15.2791 53.9467 9.94453 41.6691C4.60999 29.3916 10.1561 15.0838 22.3307 9.70338C25.3827 8.35768 28.6687 7.66377 31.9893 7.66377C35.3099 7.66377 38.5959 8.35768 41.6478 9.70338L46.1469 5.16725C31.4927 -2.71104 13.278 2.88059 5.46472 17.6576C-2.34859 32.4347 3.1975 50.7976 17.8517 58.6782C32.5059 66.5589 50.7205 60.9641 58.5346 46.1879C60.8547 41.8119 62.0704 36.9074 62.0704 31.9231C62.0704 26.9389 60.8547 22.0343 58.5346 17.6584Z"
            fill="url(#paint121_linear_44_129)"
        />
        <path
            d="M38.0228 15.2421C38.0223 14.6459 38.1378 14.0556 38.3624 13.506C38.587 12.9563 38.9162 12.4584 39.3307 12.0416C28.4062 7.92719 16.2508 13.518 12.1672 24.5304C8.08359 35.5428 13.6289 47.8045 24.5534 51.9189C35.4779 56.0333 47.6333 50.4424 51.7161 39.4309C53.4972 34.6329 53.4972 29.3283 51.7161 24.5304C50.8674 25.3806 49.7282 25.8545 48.5434 25.8502H45.7738C49.1173 33.5362 45.6477 42.4993 38.0251 45.8683C30.4026 49.2374 21.5115 45.7425 18.168 38.0589C14.8245 30.3752 18.2949 21.4098 25.9174 18.0407C27.8298 17.1966 29.8891 16.7613 31.9701 16.7613C34.0512 16.7613 36.1105 17.1966 38.0228 18.0407V15.2421Z"
            fill="url(#paint122_linear_44_129)"
        />
        <path
            d="M36.7058 20.7499C30.5942 18.1388 23.5402 21.0165 20.9499 27.1786C18.3596 33.3407 21.2138 40.4539 27.3254 43.0658C33.4369 45.6776 40.4917 42.7999 43.0812 36.6354C43.7096 35.1425 44.0338 33.533 44.0338 31.9062C44.0338 30.2794 43.7096 28.6699 43.0812 27.177L35.1987 35.1273C34.3475 35.9835 33.2051 36.4644 32.0145 36.4678C30.824 36.4712 29.679 35.9968 28.8232 35.1455C28.4042 34.729 28.0706 34.2303 27.8422 33.6789C27.6138 33.1274 27.4953 32.5345 27.4935 31.9352C27.4918 31.3358 27.607 30.7422 27.8322 30.1894C28.0574 29.6366 28.3881 29.1358 28.8048 28.7168L28.8232 28.6986L36.7058 20.7499Z"
            fill="url(#paint123_linear_44_129)"
        />
        <path
            d="M41.0209 15.2197V20.6515L30.9496 30.818C30.8087 30.9555 30.6959 31.1205 30.618 31.3035C30.5401 31.4866 30.4987 31.6838 30.4962 31.8835C30.4937 32.0833 30.5302 32.2815 30.6034 32.4665C30.6767 32.6515 30.7852 32.8196 30.9227 32.9607C31.2033 33.2491 31.5824 33.4133 31.9791 33.4183C32.3757 33.4233 32.7586 33.2688 33.0461 32.9876L33.073 32.9607L43.1443 22.7965H48.5296C48.9263 22.7971 49.3075 22.6377 49.5913 22.3526L58.6001 13.2598C58.7393 13.1204 58.8498 12.9538 58.9254 12.7697C59.0009 12.5856 59.0398 12.3879 59.0398 12.1881C59.0398 11.9883 59.0009 11.7906 58.9254 11.6065C58.8498 11.4224 58.7393 11.2558 58.6001 11.1164C58.3162 10.8315 57.9351 10.6724 57.5385 10.6733H53.0333V6.12686C53.0314 5.92594 52.9912 5.72735 52.9147 5.54245C52.8382 5.35755 52.7271 5.18995 52.5876 5.04924C52.4482 4.90853 52.2831 4.79746 52.1019 4.72237C51.9207 4.64729 51.7269 4.60966 51.5315 4.61165C51.1351 4.6109 50.7543 4.76997 50.4706 5.05474L41.461 14.1476C41.322 14.2872 41.2116 14.454 41.136 14.6381C41.0604 14.8221 41.0213 15.0199 41.0209 15.2197Z"
            fill="url(#paint124_linear_44_129)"
        />
        <path
            d="M58.5108 17.6366L54.0156 22.1727C59.3455 34.4503 53.804 48.7629 41.6394 54.1385C29.4748 59.5141 15.2922 53.9249 9.96301 41.6473C4.63386 29.3698 10.1746 15.0619 22.3392 9.68157C25.3882 8.33592 28.6714 7.642 31.9893 7.642C35.3072 7.642 38.5904 8.33592 41.6394 9.68157L46.1346 5.14386C31.4927 -2.73286 13.295 2.85878 5.48858 17.6358C-2.31781 32.4129 3.22291 50.7782 17.8648 58.6564C32.5067 66.5347 50.7044 60.9423 58.5108 46.1661C60.8287 41.7894 62.0432 36.8851 62.0432 31.9013C62.0432 26.9176 60.8287 22.0133 58.5108 17.6366Z"
            fill="url(#paint125_linear_44_129)"
        />
        <path
            d="M38.0175 15.22C38.0172 14.6239 38.1328 14.0337 38.3574 13.484C38.582 12.9344 38.9111 12.4365 39.3253 12.0195C28.4139 7.9051 16.2639 13.4959 12.1865 24.5083C8.109 35.5207 13.6482 47.7824 24.5604 51.8968C35.4725 56.0112 47.6203 50.4204 51.6977 39.4088C53.4771 34.6105 53.4771 29.3065 51.6977 24.5083C50.8499 25.3585 49.7114 25.8324 48.5273 25.8281H45.7577C49.0981 33.5141 45.6315 42.4772 38.0159 45.8462C30.4003 49.2153 21.5192 45.7189 18.1795 38.0328C14.8399 30.3468 18.3088 21.3853 25.9251 18.0147C27.8356 17.1706 29.8932 16.7352 31.9725 16.7352C34.0518 16.7352 36.1093 17.1706 38.0198 18.0147L38.0175 15.22Z"
            fill="url(#paint126_linear_44_129)"
        />
        <path
            d="M36.7019 20.7315C30.5957 18.1205 23.5463 20.9982 20.9598 27.1603C18.3734 33.3224 21.2237 40.4332 27.3299 43.0443C33.4361 45.6553 40.4854 42.7784 43.0719 36.6155C43.7 35.1225 44.024 33.5131 44.024 31.8863C44.024 30.2596 43.7 28.6501 43.0719 27.1571L35.1955 35.1051C34.3454 35.9612 33.2039 36.4422 32.0141 36.4456C30.8243 36.449 29.6802 35.9746 28.8255 35.1233C28.4068 34.7065 28.0735 34.2077 27.8454 33.6563C27.6172 33.1048 27.4988 32.512 27.4972 31.9127C27.4955 31.3135 27.6107 30.7199 27.8358 30.1672C28.0609 29.6144 28.3914 29.1137 28.8078 28.6945L28.8255 28.6763L36.7019 20.7315Z"
            fill="url(#paint127_linear_44_129)"
        />
        <path
            d="M41.0126 15.1984V20.6326L30.9505 30.7968C30.8097 30.9343 30.6971 31.0994 30.6194 31.2824C30.5416 31.4654 30.5003 31.6626 30.4977 31.8623C30.4952 32.062 30.5316 32.2601 30.6047 32.4451C30.6779 32.6301 30.7863 32.7982 30.9236 32.9394C31.2039 33.2277 31.5827 33.3919 31.9792 33.3969C32.3756 33.402 32.7582 33.2474 33.0454 32.9663L33.0715 32.9394L43.1344 22.7753H48.5143C48.9106 22.7759 49.2912 22.6165 49.5744 22.3314L58.5756 13.2386C58.7145 13.099 58.825 12.9323 58.9003 12.7483C58.9757 12.5642 59.0146 12.3665 59.0146 12.1668C59.0146 11.9671 58.9757 11.7694 58.9003 11.5854C58.825 11.4013 58.7145 11.2346 58.5756 11.0951C58.292 10.8103 57.9111 10.6513 57.5147 10.652H53.0141V6.1056C53.0124 5.90485 52.9723 5.70642 52.896 5.52162C52.8197 5.33683 52.7088 5.1693 52.5695 5.0286C52.4303 4.8879 52.2655 4.77678 52.0845 4.70159C51.9035 4.6264 51.7099 4.58862 51.5147 4.59039C51.1183 4.58964 50.7374 4.74872 50.4538 5.03348L41.4526 14.1263C41.3137 14.266 41.2032 14.4327 41.1276 14.6168C41.0521 14.8009 41.013 14.9986 41.0126 15.1984Z"
            fill="url(#paint128_linear_44_129)"
        />
        <path
            d="M58.4869 17.6142L53.9955 22.1503C59.3208 34.4278 53.784 48.7404 41.6301 54.1208C29.4762 59.5012 15.3082 53.9056 9.98217 41.6281C4.65609 29.3505 10.1937 15.0427 22.3476 9.6623C25.3935 8.31667 28.6738 7.62273 31.9888 7.62273C35.3039 7.62273 38.5842 8.31667 41.6301 9.6623L46.1214 5.12538C31.4934 -2.75529 13.3118 2.83635 5.51236 17.6134C-2.28711 32.3904 3.24899 50.7557 17.8778 58.6308C32.5066 66.506 50.6874 60.9167 58.4869 46.1404C60.8024 41.7636 62.0156 36.8601 62.0156 31.8773C62.0156 26.8945 60.8024 21.991 58.4869 17.6142Z"
            fill="url(#paint129_linear_44_129)"
        />
        <path
            d="M38.012 15.1982C38.0119 14.602 38.1275 14.0118 38.3521 13.4622C38.5767 12.9127 38.9057 12.4147 39.3199 11.9977C28.4185 7.88325 16.2792 13.4741 12.2056 24.4865C8.13204 35.4988 13.6674 47.7605 24.5688 51.8749C35.4702 55.9893 47.6079 50.4025 51.6815 39.3869C53.4594 34.5884 53.4594 29.285 51.6815 24.4865C50.8348 25.3365 49.6974 25.8104 48.5142 25.8062H45.7446C49.0819 33.4923 45.6184 42.4553 38.0097 45.8244C30.4011 49.1935 21.5276 45.697 18.191 38.011C14.8544 30.325 18.3195 21.3643 25.9251 17.9928C27.8337 17.1487 29.8895 16.7134 31.967 16.7134C34.0446 16.7134 36.1004 17.1487 38.009 17.9928L38.012 15.1982Z"
            fill="url(#paint130_linear_44_129)"
        />
        <path
            d="M36.6956 20.7046C30.5956 18.0935 23.5539 20.9704 20.9682 27.1333C18.3825 33.2962 21.2321 40.4086 27.3336 43.0205C33.4352 45.6323 40.4761 42.7546 43.0618 36.5917C43.6895 35.0986 44.0133 33.4892 44.0133 31.8625C44.0133 30.2358 43.6895 28.6264 43.0618 27.1333L35.1931 35.082C34.344 35.938 33.2034 36.419 32.0144 36.4224C30.8254 36.4258 29.6821 35.9514 28.8284 35.1002C28.4101 34.6833 28.0771 34.1845 27.8491 33.6331C27.6211 33.0817 27.5028 32.489 27.5011 31.8899C27.4994 31.2907 27.6143 30.6973 27.8391 30.1445C28.0639 29.5918 28.394 29.0909 28.81 28.6715L28.8284 28.6533L36.6956 20.7046Z"
            fill="url(#paint131_linear_44_129)"
        />
        <path
            d="M41.0039 15.1757V20.6099L30.9511 30.7741C30.8106 30.9118 30.6983 31.0769 30.6208 31.2599C30.5433 31.4429 30.5022 31.6399 30.4998 31.8395C30.4974 32.0391 30.5339 32.2371 30.607 32.422C30.6802 32.6068 30.7885 32.7748 30.9257 32.9159C31.2055 33.2043 31.584 33.3685 31.9801 33.3735C32.3762 33.3786 32.7585 33.224 33.0452 32.9428C33.0544 32.9341 33.0636 32.9254 33.0721 32.9159L43.1242 22.7526H48.4987C48.895 22.7531 49.2756 22.5938 49.5588 22.3087L58.5523 13.2143C58.6913 13.0747 58.8017 12.9081 58.8771 12.724C58.9524 12.54 58.9913 12.3423 58.9913 12.1425C58.9913 11.9428 58.9524 11.7451 58.8771 11.5611C58.8017 11.377 58.6913 11.2103 58.5523 11.0708C58.2689 10.7861 57.8883 10.627 57.4922 10.6277H52.9954V6.0829C52.9938 5.88218 52.9538 5.68376 52.8776 5.49898C52.8014 5.31419 52.6905 5.14665 52.5514 5.00594C52.4122 4.86523 52.2474 4.7541 52.0665 4.6789C51.8855 4.6037 51.6919 4.56591 51.4968 4.56769C51.1006 4.56698 50.72 4.72606 50.4366 5.01078L41.4432 14.1036C41.3044 14.2433 41.194 14.4101 41.1186 14.5942C41.0432 14.7783 41.0042 14.976 41.0039 15.1757Z"
            fill="url(#paint132_linear_44_129)"
        />
        <path
            d="M58.4632 17.5927L53.9764 22.1288C59.2964 34.4064 53.7649 48.719 41.6218 54.0946C29.4787 59.4702 15.3222 53.8841 10 41.6066C4.67779 29.329 10.2116 15.0172 22.3547 9.64082C25.3975 8.29539 28.6749 7.60152 31.9871 7.60152C35.2992 7.60152 38.5766 8.29539 41.6195 9.64082L46.107 5.1039C31.4936 -2.77677 13.3289 2.81486 5.5379 17.5919C-2.25311 32.369 3.2753 50.7342 17.8903 58.6125C32.5052 66.4908 50.6706 60.8984 58.4632 46.1221C60.777 41.7442 61.9892 36.8404 61.9892 31.8574C61.9892 26.8745 60.777 21.9707 58.4632 17.5927Z"
            fill="url(#paint133_linear_44_129)"
        />
        <path
            d="M38.0037 15.1757C38.0035 14.5796 38.1191 13.9894 38.3437 13.4398C38.5683 12.8902 38.8973 12.3922 39.3116 11.9752C28.4209 7.86079 16.2917 13.4516 12.2219 24.464C8.15216 35.4764 13.6836 47.7381 24.5743 51.8525C35.4649 55.9669 47.5942 50.376 51.6639 39.3645C53.4403 34.5656 53.4403 29.2628 51.6639 24.464C50.8182 25.3141 49.6814 25.7881 48.4989 25.7838H45.7347C49.069 33.4698 45.6085 42.4329 38.0068 45.8019C30.405 49.171 21.5431 45.6745 18.2058 37.9885C14.8684 30.3025 18.3319 21.3394 25.9337 17.9704C27.8404 17.1263 29.8944 16.6909 31.9702 16.6909C34.0461 16.6909 36.1001 17.1263 38.0068 17.9704L38.0037 15.1757Z"
            fill="url(#paint134_linear_44_129)"
        />
        <path
            d="M36.6958 20.6825C30.6004 18.0714 23.5648 20.9483 20.9814 27.1112C18.398 33.2741 21.2453 40.3865 27.3407 42.9984C33.4361 45.6102 40.4655 42.7325 43.052 36.5696C43.6789 35.0763 44.0023 33.467 44.0023 31.8404C44.0023 30.2139 43.6789 28.6045 43.052 27.1112L35.1902 35.0599C34.3423 35.9159 33.2026 36.397 32.0145 36.4004C30.8264 36.4038 29.6841 35.9292 28.8317 35.0781C28.4136 34.661 28.0808 34.1621 27.8531 33.6108C27.6253 33.0594 27.507 32.4668 27.5053 31.8678C27.5036 31.2687 27.6184 30.6754 27.843 30.1226C28.0676 29.5699 28.3975 29.069 28.8132 28.6494L28.8317 28.6312L36.6958 20.6825Z"
            fill="url(#paint135_linear_44_129)"
        />
        <path
            d="M40.9961 15.1527V20.5877L30.9518 30.7518C30.8112 30.8896 30.6988 31.0548 30.6213 31.2378C30.5437 31.4208 30.5025 31.6179 30.5001 31.8175C30.4976 32.0171 30.534 32.2153 30.6071 32.4002C30.6802 32.5852 30.7885 32.7532 30.9256 32.8945C31.205 33.1829 31.5831 33.3472 31.9789 33.3522C32.3746 33.3572 32.7566 33.2026 33.0428 32.9214C33.052 32.9127 33.0613 32.904 33.0697 32.8945L43.1141 22.7303H48.484C48.8801 22.731 49.2605 22.5716 49.5434 22.2865L58.5292 13.1936C58.668 13.0541 58.7783 12.8873 58.8537 12.7033C58.929 12.5193 58.9678 12.3216 58.9678 12.1219C58.9678 11.9222 58.929 11.7245 58.8537 11.5405C58.7783 11.3565 58.668 11.1898 58.5292 11.0502C58.2464 10.7655 57.8663 10.6064 57.4706 10.6071H52.9777V6.06067C52.9761 5.86003 52.936 5.66167 52.8599 5.47694C52.7837 5.2922 52.673 5.1247 52.5339 4.984C52.3948 4.8433 52.2301 4.73215 52.0492 4.65691C51.8684 4.58166 51.6749 4.54379 51.4798 4.54546C51.0843 4.54369 50.7039 4.70166 50.4204 4.98539L41.4346 14.0846C41.2964 14.2239 41.1865 14.39 41.1112 14.5734C41.036 14.7568 40.9968 14.9537 40.9961 15.1527Z"
            fill="url(#paint136_linear_44_129)"
        />
        <path
            d="M58.4393 17.57L53.9564 22.1062C59.2717 34.3837 53.7456 48.6963 41.6133 54.0719C29.481 59.4476 15.3376 53.8583 10.0223 41.5808C4.70702 29.3032 10.2331 14.9954 22.3654 9.61499C25.4053 8.26938 28.6799 7.57537 31.9894 7.57537C35.2988 7.57537 38.5735 8.26938 41.6133 9.61499L46.0962 5.07807C31.4943 -2.79943 13.3458 2.7922 5.5602 17.5661C-2.22542 32.34 3.30068 50.7116 17.9033 58.5899C32.506 66.4682 50.6537 60.8757 58.4393 46.0995C60.7514 41.7209 61.9625 36.8173 61.9625 31.8348C61.9625 26.8522 60.7514 21.9486 58.4393 17.57Z"
            fill="url(#paint137_linear_44_129)"
        />
        <path
            d="M38.0035 15.1528C38.0032 14.5571 38.1184 13.9673 38.3423 13.4179C38.5662 12.8685 38.8943 12.3705 39.3076 11.9531C28.4262 7.83869 16.3046 13.4295 12.2418 24.4419C8.17893 35.4543 13.7035 47.716 24.5826 51.8304C35.4617 55.9448 47.5856 50.3539 51.6484 39.3424C53.4222 34.5431 53.4222 29.2412 51.6484 24.4419C50.8038 25.2917 49.668 25.7658 48.4864 25.7617H45.7245C49.0557 33.4477 45.5984 42.4108 38.0035 45.7798C30.4087 49.1489 21.5491 45.6548 18.2179 37.968C14.8867 30.2812 18.3441 21.3189 25.9389 17.9498C27.8437 17.1058 29.896 16.6704 31.9701 16.6704C34.0442 16.6704 36.0964 17.1058 38.0012 17.9498L38.0035 15.1528Z"
            fill="url(#paint138_linear_44_129)"
        />
        <path
            d="M36.6889 20.661C30.5996 18.0499 23.5702 20.9268 20.9891 27.0897C18.408 33.2526 21.2522 40.365 27.3423 42.9769C33.4323 45.5887 40.4609 42.711 43.042 36.5481C43.6685 35.0547 43.9918 33.4454 43.9918 31.8189C43.9918 30.1924 43.6685 28.5831 43.042 27.0897L35.1871 35.0384C34.3404 35.8945 33.2015 36.3756 32.0142 36.379C30.8268 36.3824 29.6854 35.9078 28.834 35.0566C28.4164 34.6392 28.0842 34.1402 27.8568 33.5888C27.6293 33.0375 27.5113 32.445 27.5097 31.8461C27.508 31.2472 27.6228 30.654 27.8472 30.1014C28.0715 29.5487 28.401 29.0477 28.8163 28.6279L28.834 28.6097L36.6889 20.661Z"
            fill="url(#paint139_linear_44_129)"
        />
        <path
            d="M40.9885 15.131V20.566L30.9533 30.7302C30.8127 30.8678 30.7003 31.033 30.6227 31.216C30.545 31.3989 30.5037 31.596 30.5012 31.7957C30.4987 31.9953 30.535 32.1934 30.608 32.3784C30.6811 32.5634 30.7893 32.7315 30.9264 32.8728C31.2055 33.1611 31.5834 33.3253 31.9789 33.3304C32.3744 33.3354 32.7561 33.1808 33.042 32.8997L33.069 32.8728L43.1041 22.7095H48.4663C48.8619 22.7098 49.2418 22.5504 49.5242 22.2656L58.5046 13.1719C58.6428 13.032 58.7527 12.8652 58.8276 12.6812C58.9026 12.4972 58.9412 12.2997 58.9412 12.1002C58.9412 11.9007 58.9026 11.7032 58.8276 11.5192C58.7527 11.3352 58.6428 11.1684 58.5046 11.0285C58.222 10.7439 57.8422 10.5848 57.4467 10.5854H52.9577V6.03899C52.9546 5.63391 52.7953 5.24665 52.5147 4.96237C52.2341 4.67808 51.8552 4.52004 51.4613 4.52299C51.0659 4.52311 50.6862 4.68244 50.4035 4.96687L41.4262 14.0597C41.2878 14.1995 41.1778 14.3662 41.1026 14.5501C41.0274 14.734 40.9886 14.9315 40.9885 15.131Z"
            fill="url(#paint140_linear_44_129)"
        />
        <path
            d="M58.4155 17.5483L53.9365 22.0844C59.2448 34.362 53.7257 48.6746 41.6049 54.0502C29.4841 59.4258 15.3522 53.8366 10.0423 41.559C4.73241 29.2815 10.2531 14.9737 22.3739 9.59328C25.4111 8.24737 28.6834 7.55316 31.9905 7.55316C35.2977 7.55316 38.57 8.24737 41.6072 9.59328L46.0816 5.05714C31.4951 -2.82115 13.3628 2.77049 5.58406 17.5475C-2.19464 32.3246 3.32685 50.6898 17.9157 58.5681C32.5045 66.4464 50.6368 60.854 58.4155 46.0778C60.7253 41.6984 61.9351 36.7952 61.9351 31.813C61.9351 26.8309 60.7253 21.9276 58.4155 17.5483Z"
            fill="url(#paint141_linear_44_129)"
        />
        <path
            d="M37.9959 15.1303C37.9954 14.5346 38.1105 13.9448 38.3342 13.3954C38.558 12.846 38.886 12.348 39.2992 11.9305C28.427 7.81611 16.32 13.407 12.258 24.4193C8.19593 35.4317 13.7151 47.6934 24.5873 51.8078C35.4594 55.9222 47.5664 50.3314 51.6284 39.3198C53.401 34.5202 53.401 29.2189 51.6284 24.4193C50.7842 25.2697 49.6482 25.7439 48.4665 25.7391H45.71C49.0381 33.4251 45.5838 42.3882 37.9959 45.7573C30.408 49.1263 21.5584 45.6315 18.2318 37.9454C14.9053 30.2594 18.358 21.2963 25.9459 17.9273C27.8488 17.0832 29.8993 16.6478 31.9717 16.6478C34.044 16.6478 36.0945 17.0832 37.9975 17.9273L37.9959 15.1303Z"
            fill="url(#paint142_linear_44_129)"
        />
        <path
            d="M36.6848 20.6383C30.6002 18.0272 23.5777 20.9041 20.9989 27.067C18.4201 33.2299 21.2621 40.3423 27.3459 42.9542C33.4298 45.566 40.453 42.6883 43.0318 36.5254C43.6577 35.0319 43.9806 33.4226 43.9806 31.7962C43.9806 30.1698 43.6577 28.5606 43.0318 27.067L35.1846 35.0157C34.3388 35.8714 33.2008 36.3524 32.0144 36.3558C30.8279 36.3592 29.6874 35.8847 28.8369 35.0339C28.4195 34.6164 28.0875 34.1174 27.8602 33.5661C27.6329 33.0148 27.5149 32.4224 27.5132 31.8236C27.5115 31.2248 27.626 30.6316 27.8502 30.079C28.0743 29.5263 28.4035 29.0253 28.8184 28.6052L28.8369 28.587L36.6848 20.6383Z"
            fill="url(#paint143_linear_44_129)"
        />
        <path
            d="M40.9801 15.1083V20.5433L30.9542 30.7075C30.8143 30.8455 30.7025 31.0108 30.6252 31.1937C30.548 31.3767 30.507 31.5736 30.5045 31.773C30.5019 31.9724 30.538 32.1703 30.6106 32.3553C30.6832 32.5402 30.7909 32.7084 30.9273 32.8501C31.2061 33.1385 31.5837 33.3028 31.979 33.3078C32.3744 33.3129 32.7558 33.1582 33.0414 32.877C33.0499 32.8683 33.0591 32.8596 33.0676 32.8501L43.0943 22.686H48.455C48.8501 22.6863 49.2295 22.5269 49.5112 22.2421L58.4809 13.1492C58.6191 13.0093 58.729 12.8425 58.8039 12.6585C58.8789 12.4745 58.9175 12.277 58.9175 12.0775C58.9175 11.878 58.8789 11.6805 58.8039 11.4965C58.729 11.3125 58.6191 11.1457 58.4809 11.0058C58.1986 10.7213 57.819 10.5621 57.4238 10.5627H52.9394V6.01391C52.9359 5.60931 52.7765 5.22267 52.4961 4.93887C52.2157 4.65508 51.8372 4.49733 51.4438 4.50028C51.0496 4.50085 50.6712 4.66019 50.3898 4.94417L41.4179 14.037C41.2795 14.1768 41.1695 14.3435 41.0943 14.5274C41.0191 14.7113 40.9803 14.9088 40.9801 15.1083Z"
            fill="url(#paint144_linear_44_129)"
        />
        <path
            d="M58.3908 17.5257L53.9164 22.0618C59.2248 34.3393 53.7056 48.6519 41.5948 54.0275C29.484 59.4032 15.3676 53.8171 10.0615 41.5403C4.75543 29.2636 10.2723 14.9549 22.3831 9.57457C25.4167 8.22903 28.6856 7.53499 31.9893 7.53499C35.293 7.53499 38.5619 8.22903 41.5956 9.57457L46.0708 5.03765C31.495 -2.84381 13.3789 2.74783 5.60785 17.5249C-2.16315 32.3019 3.35217 50.6672 17.9279 58.5455C32.5036 66.4238 50.6206 60.8313 58.3908 46.0551C60.6988 41.6752 61.9076 36.7722 61.9076 31.7904C61.9076 26.8086 60.6988 21.9056 58.3908 17.5257Z"
            fill="url(#paint145_linear_44_129)"
        />
        <path
            d="M37.9905 15.1088C37.9901 14.5133 38.105 13.9236 38.3285 13.3743C38.552 12.8249 38.8796 12.3268 39.2922 11.909C28.4301 7.79462 16.3339 13.3855 12.2757 24.3978C8.21747 35.4102 13.732 47.6719 24.5942 51.7863C35.4564 55.9007 47.5525 50.3099 51.6108 39.2983C53.3817 34.4985 53.3817 29.1977 51.6108 24.3978C50.7688 25.247 49.6357 25.7211 48.4565 25.7176H45.6969C49.022 33.4036 45.5715 42.3667 37.9898 45.7358C30.408 49.1048 21.5669 45.6084 18.2418 37.9224C14.9168 30.2363 18.3672 21.2733 25.949 17.9042C27.85 17.0601 29.8988 16.6247 31.9694 16.6247C34.04 16.6247 36.0887 17.0601 37.9898 17.9042L37.9905 15.1088Z"
            fill="url(#paint146_linear_44_129)"
        />
        <path
            d="M36.6801 20.6167C30.6024 18.0056 23.5853 20.8825 21.0089 27.0454C18.4324 33.2083 21.272 40.3207 27.3504 42.9326C33.4289 45.5444 40.4452 42.6667 43.0217 36.5038C43.6471 35.0101 43.9697 33.4009 43.9697 31.7746C43.9697 30.1483 43.6471 28.5391 43.0217 27.0454L35.1815 34.9941C34.3369 35.8499 33.1999 36.3312 32.0143 36.3346C30.8287 36.338 29.689 35.8633 28.8399 35.0123C28.4228 34.5946 28.091 34.0955 27.8639 33.5443C27.6367 32.993 27.5189 32.4007 27.5172 31.8019C27.5154 31.2032 27.6299 30.6102 27.8539 30.0576C28.0778 29.5049 28.4068 29.0038 28.8214 28.5836L28.8399 28.5654L36.6801 20.6167Z"
            fill="url(#paint147_linear_44_129)"
        />
        <path
            d="M40.9726 15.0868V20.5218L30.9552 30.686C30.8152 30.824 30.7034 30.9893 30.6262 31.1722C30.5489 31.3552 30.5079 31.5521 30.5054 31.7515C30.5029 31.9509 30.539 32.1488 30.6116 32.3338C30.6842 32.5187 30.7918 32.6869 30.9282 32.8286C31.2067 33.1169 31.5841 33.2812 31.9792 33.2862C32.3742 33.2912 32.7555 33.1366 33.0408 32.8555C33.0493 32.8468 33.0585 32.8381 33.067 32.8286L43.0813 22.6645H48.4366C48.8317 22.6649 49.2111 22.5054 49.4929 22.2206L58.4572 13.1277C58.5954 12.9878 58.7053 12.821 58.7802 12.637C58.8552 12.453 58.8938 12.2555 58.8938 12.056C58.8938 11.8565 58.8552 11.659 58.7802 11.475C58.7053 11.291 58.5954 11.1242 58.4572 10.9843C58.1753 10.6997 57.7959 10.5405 57.4009 10.5412H52.9203V5.99479C52.9177 5.59013 52.7589 5.20311 52.4788 4.91883C52.1988 4.63454 51.8205 4.47626 51.427 4.47879C51.032 4.47873 50.6527 4.63812 50.3707 4.92267L41.4096 14.0155C41.2713 14.1553 41.1614 14.3221 41.0864 14.506C41.0114 14.6899 40.9727 14.8874 40.9726 15.0868Z"
            fill="url(#paint148_linear_44_129)"
        />
        <path
            d="M58.3678 17.5034L53.8972 22.0395C59.1979 34.3171 53.6864 48.6297 41.5879 54.0053C29.4894 59.3809 15.3853 53.7948 10.0815 41.5173C4.77779 29.2397 10.2923 14.9279 22.3908 9.55152C25.4215 8.20602 28.6876 7.51195 31.9886 7.51195C35.2896 7.51195 38.5557 8.20602 41.5864 9.55152L46.0585 5.01222C31.4958 -2.86607 13.3958 2.72556 5.63175 17.5026C-2.13233 32.2796 3.37838 50.6441 17.941 58.5232C32.5037 66.4023 50.6037 60.8091 58.3678 46.0328C60.674 41.6523 61.8817 36.7495 61.8817 31.7681C61.8817 26.7867 60.674 21.8839 58.3678 17.5034Z"
            fill="url(#paint149_linear_44_129)"
        />
        <path
            d="M37.9852 15.0864C37.9846 14.491 38.0994 13.9014 38.3227 13.352C38.5461 12.8026 38.8736 12.3045 39.2861 11.8867C28.4332 7.77229 16.3485 13.3631 12.2934 24.3755C8.23826 35.3879 13.7482 47.6496 24.6027 51.764C35.4572 55.8784 47.5403 50.2875 51.5962 39.276C53.3649 34.4757 53.3649 29.1758 51.5962 24.3755C50.7544 25.2248 49.6212 25.699 48.4419 25.6953H45.6877C49.0097 33.3813 45.5623 42.3444 37.9875 45.7134C30.4127 49.0825 21.5769 45.5876 18.2549 37.9008C14.933 30.214 18.3803 21.2517 25.9552 17.8826C27.8543 17.0386 29.9013 16.6032 31.9702 16.6032C34.039 16.6032 36.086 17.0386 37.9852 17.8826V15.0864Z"
            fill="url(#paint150_linear_44_129)"
        />
        <path
            d="M36.6763 20.594C30.6033 17.9829 23.5931 20.8598 21.0189 27.0227C18.4448 33.1856 21.2813 40.298 27.3544 42.9099C33.4275 45.5217 40.4376 42.648 43.0118 36.4811C43.6368 34.9873 43.9592 33.3781 43.9592 31.7519C43.9592 30.1257 43.6368 28.5165 43.0118 27.0227L35.1785 34.973C34.3352 35.8288 33.1991 36.3101 32.0144 36.3135C30.8297 36.3169 29.691 35.8421 28.843 34.9912C28.4263 34.5733 28.0947 34.0742 27.8678 33.5229C27.6408 32.9717 27.523 32.3794 27.5213 31.7808C27.5196 31.1822 27.634 30.5892 27.8577 30.0366C28.0815 29.484 28.4102 28.9829 28.8246 28.5625L28.843 28.5443L36.6763 20.594Z"
            fill="url(#paint151_linear_44_129)"
        />
        <path
            d="M40.9639 15.0645V20.4995L30.9557 30.6636C30.8158 30.8017 30.704 30.9671 30.6269 31.15C30.5497 31.333 30.5087 31.5299 30.5063 31.7293C30.5038 31.9287 30.54 32.1266 30.6127 32.3115C30.6853 32.4964 30.793 32.6646 30.9295 32.8063C31.2074 33.0945 31.5843 33.2589 31.9789 33.2639C32.3735 33.2689 32.7543 33.1142 33.039 32.8332C33.0483 32.8245 33.0575 32.8158 33.066 32.8063L43.0742 22.6421H48.4249C48.8195 22.6425 49.1984 22.4831 49.4796 22.1982L58.4331 13.1054C58.5714 12.9655 58.6812 12.7987 58.7561 12.6147C58.8311 12.4307 58.8697 12.2332 58.8697 12.0337C58.8697 11.8342 58.8311 11.6367 58.7561 11.4527C58.6812 11.2687 58.5714 11.1019 58.4331 10.962C58.1517 10.6775 57.7728 10.5183 57.3783 10.5189H52.9016V5.97245C52.8989 5.56792 52.7403 5.18102 52.4604 4.89676C52.1805 4.6125 51.8024 4.45413 51.4091 4.45645C51.0145 4.45655 50.6358 4.61594 50.3543 4.90033L41.4009 13.9932C41.2626 14.133 41.1527 14.2997 41.0777 14.4837C41.0027 14.6676 40.964 14.865 40.9639 15.0645Z"
            fill="url(#paint152_linear_44_129)"
        />
        <path
            d="M58.3447 17.4817L53.8772 22.0179C59.1732 34.2954 53.6671 48.608 41.5787 53.9836C29.4902 59.3592 15.3968 53.77 10.1008 41.4924C4.80469 29.2149 10.3116 14.9071 22.4 9.52668C25.4277 8.18117 28.6911 7.48706 31.9893 7.48706C35.2876 7.48706 38.551 8.18117 41.5787 9.52668L46.0454 4.98976C31.4958 -2.88853 13.4127 2.7031 5.65557 17.4801C-2.10158 32.2572 3.40374 50.6225 17.9533 58.5015C32.5029 66.3806 50.5867 60.7874 58.3431 46.0112C60.6471 41.63 61.8538 36.7276 61.854 31.7466C61.8543 26.7657 60.6482 21.8632 58.3447 17.4817Z"
            fill="url(#paint153_linear_44_129)"
        />
        <path
            d="M37.9797 15.0642C37.9792 14.4689 38.0939 13.8795 38.317 13.3301C38.54 12.7808 38.8671 12.2826 39.2791 11.8645C28.4362 7.75007 16.3623 13.3409 12.3079 24.3533C8.25357 35.3656 13.7612 47.6274 24.6041 51.7418C35.4471 55.8562 47.524 50.2653 51.5761 39.2538C53.3432 34.4532 53.3432 29.1538 51.5761 24.3533C50.7345 25.2029 49.6011 25.6771 48.4218 25.673H45.673C48.9919 33.3583 45.5476 42.3222 37.9797 45.6912C30.4118 49.0603 21.5861 45.5638 18.2672 37.8778C14.9483 30.1918 18.3926 21.2287 25.9605 17.8596C27.8577 17.0157 29.903 16.5802 31.9701 16.5802C34.0372 16.5802 36.0824 17.0157 37.9797 17.8596V15.0642Z"
            fill="url(#paint154_linear_44_129)"
        />
        <path
            d="M36.6719 20.5728C30.6042 17.9618 23.601 20.8387 21.0291 27.0016C18.4572 33.1645 21.2915 40.2745 27.3592 42.8856C33.4269 45.4966 40.4308 42.6221 43.0043 36.4592C43.6286 34.9652 43.9505 33.3561 43.9505 31.73C43.9505 30.1039 43.6286 28.4948 43.0043 27.0008L35.1755 34.9495C34.3333 35.8052 33.198 36.2865 32.0141 36.2899C30.8303 36.2933 29.6924 35.8185 28.8455 34.9677C28.4297 34.5492 28.0991 34.0499 27.8728 33.4987C27.6465 32.9475 27.5291 32.3555 27.5274 31.7572C27.5258 31.1588 27.6399 30.5662 27.8632 30.0137C28.0864 29.4612 28.4143 28.9599 28.8278 28.539L28.8455 28.5208L36.6719 20.5728Z"
            fill="url(#paint155_linear_44_129)"
        />
        <path
            d="M40.9562 15.0423V20.4773L30.9549 30.6415C30.815 30.7796 30.7032 30.9449 30.6261 31.1279C30.5489 31.3108 30.5079 31.5077 30.5055 31.7072C30.5031 31.9066 30.5392 32.1045 30.6119 32.2894C30.6845 32.4743 30.7922 32.6425 30.9287 32.7841C31.2063 33.0723 31.583 33.2366 31.9773 33.2416C32.3717 33.2466 32.7522 33.092 33.0367 32.811L33.0636 32.7841L43.0649 22.62H48.411C48.8054 22.6203 49.1841 22.4609 49.465 22.1761L58.4092 13.0832C58.5475 12.9433 58.6573 12.7765 58.7323 12.5925C58.8072 12.4085 58.8458 12.211 58.8458 12.0115C58.8458 11.812 58.8072 11.6145 58.7323 11.4305C58.6573 11.2465 58.5475 11.0797 58.4092 10.9398C58.1281 10.6553 57.7496 10.4959 57.3552 10.4959H52.8823V5.95029C52.8815 5.74973 52.8422 5.5513 52.7668 5.36638C52.6913 5.18145 52.581 5.01366 52.4424 4.87261C52.3038 4.73156 52.1394 4.62003 51.9588 4.54441C51.7782 4.46879 51.5848 4.43056 51.3898 4.43192C50.9955 4.43223 50.6171 4.59161 50.3358 4.8758L41.3924 13.971C41.2542 14.1109 41.1445 14.2777 41.0696 14.4616C40.9947 14.6455 40.9562 14.8429 40.9562 15.0423Z"
            fill="url(#paint156_linear_44_129)"
        />
        <path
            d="M58.3209 17.4587L53.8588 21.9948C59.1495 34.2724 53.648 48.5818 41.5703 53.9622C29.4926 59.3426 15.4131 53.7485 10.1209 41.471C4.82867 29.1935 10.3309 14.8856 22.4086 9.50524C25.4334 8.15976 28.694 7.46563 31.9895 7.46563C35.285 7.46563 38.5456 8.15976 41.5703 9.50524L46.0324 4.96832C31.4967 -2.91077 13.4298 2.68087 5.67878 17.4579C-2.07222 32.235 3.43003 50.5994 17.9665 58.4785C32.503 66.3576 50.57 60.7668 58.3202 45.9881C60.6223 41.6063 61.8277 36.7041 61.8279 31.7235C61.828 26.743 60.6228 21.8407 58.3209 17.4587Z"
            fill="url(#paint157_linear_44_129)"
        />
        <path
            d="M37.9744 15.0421C37.9738 14.4469 38.0883 13.8574 38.3112 13.3081C38.5342 12.7588 38.8611 12.2606 39.273 11.8424C28.4401 7.72798 16.3762 13.3188 12.3288 24.3304C8.28131 35.342 13.7812 47.6053 24.6173 51.7197C35.4533 55.8341 47.5102 50.244 51.5584 39.2317C53.3241 34.4308 53.3241 29.132 51.5584 24.3312C50.7188 25.1799 49.5877 25.6541 48.4103 25.651H45.6607C48.9766 33.3362 45.5353 42.3001 37.9744 45.6691C30.4134 49.0382 21.5953 45.5417 18.2795 37.8557C14.9637 30.1697 18.4049 21.2066 25.9659 17.8375C27.8613 16.9936 29.9047 16.5581 31.9701 16.5581C34.0355 16.5581 36.079 16.9936 37.9744 17.8375V15.0421Z"
            fill="url(#paint158_linear_44_129)"
        />
        <path
            d="M36.6679 20.5504C30.6056 17.9393 23.6077 20.8162 21.0382 26.9791C18.4686 33.142 21.3005 40.2544 27.3628 42.8663C33.4251 45.4781 40.423 42.6004 42.9925 36.4375C43.6161 34.9433 43.9377 33.3343 43.9377 31.7083C43.9377 30.0823 43.6161 28.4733 42.9925 26.9791L35.1731 34.9279C34.3319 35.7835 33.1975 36.2649 32.0144 36.2683C30.8312 36.2717 29.6942 35.7969 28.8484 34.9461C28.4326 34.5276 28.1018 34.0283 27.8755 33.4771C27.6491 32.9259 27.5317 32.334 27.53 31.7357C27.5282 31.1373 27.6423 30.5447 27.8655 29.9921C28.0887 29.4396 28.4165 28.9382 28.8299 28.5173L28.8484 28.4991L36.6679 20.5504Z"
            fill="url(#paint159_linear_44_129)"
        />
        <path
            d="M40.9478 15.0199V20.4549L30.958 30.6214C30.8181 30.7595 30.7062 30.9248 30.629 31.1077C30.5518 31.2906 30.5107 31.4876 30.5082 31.687C30.5057 31.8864 30.5418 32.0843 30.6144 32.2692C30.687 32.4542 30.7947 32.6224 30.9311 32.7641C31.2084 33.0521 31.5848 33.2164 31.979 33.2214C32.3731 33.2265 32.7533 33.0719 33.0375 32.791L33.0637 32.7641L43.0542 22.5976H48.3957C48.7896 22.5982 49.168 22.4393 49.4489 22.1553L58.3854 13.0608C58.5237 12.9209 58.6335 12.7541 58.7085 12.5701C58.7835 12.3861 58.8221 12.1886 58.8221 11.9891C58.8221 11.7896 58.7835 11.5921 58.7085 11.4081C58.6335 11.2241 58.5237 11.0573 58.3854 10.9174C58.1046 10.633 57.7263 10.4735 57.3322 10.4735H52.8639V5.92789C52.8629 5.72776 52.8236 5.5298 52.7482 5.34531C52.6728 5.16082 52.5628 4.9934 52.4245 4.85263C52.2862 4.71185 52.1223 4.60048 51.9421 4.52485C51.762 4.44923 51.5691 4.41084 51.3745 4.41189C50.9804 4.4119 50.6021 4.57133 50.3213 4.85577L41.384 13.9486C41.2458 14.0885 41.1361 14.2553 41.0612 14.4392C40.9863 14.6231 40.9478 14.8205 40.9478 15.0199Z"
            fill="url(#paint160_linear_44_129)"
        />
        <path
            d="M58.2968 17.4369L53.8347 21.9731C59.1215 34.2506 53.6247 48.5632 41.5585 53.9388C29.4923 59.3145 15.4267 53.7252 10.1406 41.4508C4.85453 29.1764 10.3506 14.8654 22.4168 9.48506C25.4386 8.13961 28.6964 7.44545 31.9892 7.44545C35.282 7.44545 38.5398 8.13961 41.5616 9.48506L46.0237 4.94814C31.4964 -2.93253 13.4456 2.6591 5.70233 17.4361C-2.04098 32.2132 3.45512 50.5777 17.9785 58.4568C32.5019 66.3359 50.5527 60.7426 58.2968 45.9664C60.5968 41.5838 61.8009 36.6818 61.8009 31.7017C61.8009 26.7215 60.5968 21.8195 58.2968 17.4369Z"
            fill="url(#paint161_linear_44_129)"
        />
        <path
            d="M37.9689 15.0197C37.9684 14.4246 38.0827 13.8353 38.3054 13.286C38.5281 12.7367 38.8546 12.2384 39.266 11.8199C28.4431 7.70552 16.3907 13.2932 12.3464 24.3079C8.30201 35.3227 13.7973 47.5828 24.6172 51.6972C35.437 55.8116 47.4963 50.2208 51.5437 39.2092C53.3086 34.4079 53.3086 29.1092 51.5437 24.3079C50.7057 25.1574 49.5753 25.632 48.3987 25.6285H45.6484C48.9611 33.3137 45.523 42.2776 37.9689 45.6467C30.4149 49.0157 21.6053 45.5193 18.2918 37.8333C14.9783 30.1472 18.4172 21.1842 25.9712 17.8151C27.8652 16.9706 29.9076 16.5348 31.972 16.5348C34.0363 16.5348 36.0787 16.9706 37.9728 17.8151L37.9689 15.0197Z"
            fill="url(#paint162_linear_44_129)"
        />
        <path
            d="M36.664 20.5274C30.607 17.9164 23.6154 20.7933 21.0466 26.9562C18.4778 33.1191 21.3089 40.2315 27.3674 42.8433C33.4259 45.4552 40.4152 42.5775 42.9848 36.4146C43.608 34.9203 43.9293 33.3113 43.9293 31.6854C43.9293 30.0594 43.608 28.4505 42.9848 26.9562L35.1699 34.9049C34.33 35.7605 33.1965 36.2421 32.0143 36.2455C30.8321 36.2489 29.696 35.7739 28.8514 34.9231C28.4356 34.5047 28.1049 34.0053 27.8785 33.4542C27.6522 32.903 27.5347 32.311 27.533 31.7127C27.5313 31.1144 27.6453 30.5217 27.8685 29.9692C28.0917 29.4166 28.4195 28.9153 28.833 28.4943L28.8514 28.4761L36.664 20.5274Z"
            fill="url(#paint163_linear_44_129)"
        />
        <path
            d="M40.9403 14.9977V20.4327L30.959 30.5968C30.8191 30.7349 30.7072 30.9002 30.63 31.0831C30.5528 31.266 30.5117 31.4629 30.5092 31.6623C30.5067 31.8618 30.5428 32.0597 30.6154 32.2446C30.688 32.4295 30.7956 32.5977 30.9321 32.7395C31.2092 33.0274 31.5853 33.1916 31.9792 33.1967C32.373 33.2017 32.753 33.0471 33.037 32.7664C33.0454 32.7577 33.0547 32.749 33.0631 32.7395L43.0444 22.5753H48.3812C48.775 22.5756 49.1529 22.4161 49.4329 22.1314L58.3618 13.0386C58.5 12.8987 58.6099 12.7319 58.6848 12.5479C58.7598 12.3639 58.7984 12.1664 58.7984 11.9669C58.7984 11.7674 58.7598 11.5699 58.6848 11.3859C58.6099 11.2019 58.5 11.0351 58.3618 10.8951C58.0824 10.6118 57.7062 10.4525 57.3139 10.4513H52.8518V5.90564C52.8483 5.60475 52.7587 5.31159 52.5943 5.06267C52.4299 4.81376 52.1979 4.62009 51.9273 4.50579C51.6566 4.3915 51.3593 4.36162 51.0722 4.4199C50.7852 4.47818 50.5212 4.62202 50.313 4.83352L41.3757 13.9264C41.2377 14.0663 41.1281 14.2331 41.0534 14.417C40.9786 14.601 40.9402 14.7983 40.9403 14.9977Z"
            fill="url(#paint164_linear_44_129)"
        />
        <path
            d="M58.273 17.4147L53.8186 21.9508C59.1001 34.2283 53.6086 48.5409 41.5524 53.9165C29.4963 59.2922 15.4421 53.7029 10.1638 41.4254C4.8854 29.1478 10.3738 14.836 22.43 9.45961C25.4485 8.11418 28.7033 7.42 31.9931 7.42C35.2829 7.42 38.5378 8.11418 41.5563 9.45961L46.0045 4.92348C31.4973 -2.95482 13.4619 2.63682 5.72628 17.4139C-2.00933 32.1909 3.48061 50.5554 17.9917 58.4345C32.5028 66.3136 50.5344 60.7203 58.273 45.9441C60.5711 41.5609 61.7743 36.6592 61.7743 31.6794C61.7743 26.6996 60.5711 21.7978 58.273 17.4147Z"
            fill="url(#paint165_linear_44_129)"
        />
        <path
            d="M37.9635 14.9978C37.9628 14.4028 38.0771 13.8135 38.2996 13.2642C38.5221 12.7149 38.8485 12.2166 39.2598 11.7981C28.4461 7.68367 16.4046 13.2745 12.3641 24.2861C8.32355 35.2977 13.8135 47.561 24.6272 51.6754C35.4409 55.7898 47.4824 50.1989 51.523 39.1874C53.2862 34.3858 53.2862 29.0876 51.523 24.2861C50.6857 25.1348 49.5566 25.6093 48.381 25.6067H45.636C48.9442 33.2919 45.5106 42.2558 37.9635 45.6248C30.4164 48.9939 21.6168 45.4966 18.3041 37.813C14.9913 30.1293 18.4295 21.1639 25.9766 17.7948C27.8682 16.9509 29.9081 16.5154 31.9701 16.5154C34.032 16.5154 36.0719 16.9509 37.9635 17.7948V14.9978Z"
            fill="url(#paint166_linear_44_129)"
        />
        <path
            d="M36.6595 20.5053C30.608 17.8943 23.6232 20.7712 21.0583 26.9341C18.4934 33.097 21.3199 40.2094 27.3714 42.8212C33.423 45.4331 40.4077 42.5554 42.9727 36.3925C43.5951 34.898 43.916 33.2891 43.916 31.6633C43.916 30.0375 43.5951 28.4286 42.9727 26.9341L35.167 34.8828C34.3281 35.7383 33.1955 36.2198 32.0141 36.2232C30.8327 36.2266 29.6975 35.7516 28.8539 34.901C28.4386 34.4823 28.1084 33.9828 27.8824 33.4316C27.6564 32.8805 27.5391 32.2886 27.5375 31.6904C27.5359 31.0923 27.6498 30.4997 27.8728 29.9473C28.0957 29.3948 28.4232 28.8934 28.8362 28.4722L28.8539 28.4541L36.6595 20.5053Z"
            fill="url(#paint167_linear_44_129)"
        />
        <path
            d="M40.9315 14.9757V20.4146L30.9595 30.5748C30.8196 30.7129 30.7078 30.8783 30.6306 31.0612C30.5535 31.2442 30.5125 31.4411 30.5101 31.6405C30.5076 31.8399 30.5438 32.0378 30.6165 32.2227C30.6891 32.4076 30.7968 32.5758 30.9333 32.7175C31.2098 33.0054 31.5854 33.1697 31.9788 33.1747C32.3723 33.1798 32.7518 33.0251 33.0351 32.7444C33.0443 32.7357 33.0536 32.727 33.062 32.7175L43.0341 22.5509H48.3656C48.7593 22.5513 49.1372 22.3918 49.4172 22.1071L58.3415 13.0142C58.4797 12.8743 58.5896 12.7075 58.6645 12.5235C58.7395 12.3395 58.7781 12.142 58.7781 11.9425C58.7781 11.743 58.7395 11.5455 58.6645 11.3615C58.5896 11.1775 58.4797 11.0107 58.3415 10.8708C58.0616 10.5864 57.684 10.4269 57.2905 10.4269H52.8284V5.88364C52.8256 5.58242 52.7365 5.28875 52.5722 5.03936C52.4079 4.78996 52.1758 4.59592 51.9048 4.4815C51.6339 4.36708 51.3361 4.33736 51.0489 4.39606C50.7616 4.45476 50.4975 4.59927 50.2896 4.81152L41.3654 13.9044C41.2279 14.0446 41.1188 14.2115 41.0443 14.3954C40.9698 14.5793 40.9315 14.7765 40.9315 14.9757Z"
            fill="url(#paint168_linear_44_129)"
        />
        <path
            d="M58.25 17.393L53.7987 21.9291C59.0755 34.2066 53.5894 48.5192 41.544 53.8948C29.4986 59.2705 15.4568 53.6812 10.18 41.4037C4.90313 29.1261 10.3892 14.8183 22.4346 9.43791C25.4502 8.09251 28.7022 7.3983 31.9893 7.3983C35.2764 7.3983 38.5285 8.09251 41.544 9.43791L45.9953 4.90099C31.4981 -2.97651 13.4796 2.61195 5.74939 17.3922C-1.98084 32.1724 3.50679 50.5337 18.001 58.4128C32.4951 66.2919 50.5167 60.6986 58.2462 45.9224C60.5426 41.5388 61.7452 36.6375 61.7458 31.6582C61.7465 26.6788 60.5453 21.7772 58.25 17.393Z"
            fill="url(#paint169_linear_44_129)"
        />
        <path
            d="M37.9581 14.9757C37.9575 14.3808 38.0716 13.7917 38.2938 13.2425C38.5161 12.6932 38.8421 12.1948 39.2529 11.776C28.4492 7.66158 16.4184 13.2524 12.3848 24.264C8.35123 35.2756 13.8327 47.5389 24.6372 51.6533C35.4417 55.7677 47.4663 50.1768 51.5053 39.1668C53.267 34.365 53.267 29.0674 51.5053 24.2656C50.6691 25.1145 49.5406 25.5891 48.3656 25.5861H45.6237C48.9319 33.2714 45.4991 42.2352 37.9581 45.6043C30.4172 48.9734 21.6237 45.4769 18.3164 37.7909C15.009 30.1049 18.4418 21.1418 25.982 17.7727C27.8717 16.9288 29.9099 16.4933 31.97 16.4933C34.0302 16.4933 36.0684 16.9288 37.9581 17.7727V14.9757Z"
            fill="url(#paint170_linear_44_129)"
        />
        <path
            d="M36.6557 20.4829C30.6096 17.8718 23.631 20.7487 21.0683 26.9116C18.5057 33.0745 21.3299 40.1869 27.3768 42.7988C33.4238 45.4106 40.4016 42.5329 42.9642 36.37C43.5863 34.8754 43.9071 33.2666 43.9071 31.6408C43.9071 30.0151 43.5863 28.4062 42.9642 26.9116L35.1648 34.8603C34.3269 35.7161 33.1949 36.1979 32.0141 36.2013C30.8333 36.2047 29.6988 35.7295 28.8562 34.8785C28.4413 34.4596 28.1113 33.9601 27.8855 33.409C27.6596 32.8579 27.5425 32.2662 27.5407 31.6681C27.539 31.0701 27.6528 30.4777 27.8754 29.9252C28.0981 29.3727 28.4252 28.8712 28.8378 28.4498L28.8562 28.4308L36.6557 20.4829Z"
            fill="url(#paint171_linear_44_129)"
        />
        <path
            d="M40.927 14.955V20.39L30.9604 30.5526C30.8205 30.6907 30.7087 30.856 30.6316 31.039C30.5544 31.222 30.5134 31.4189 30.511 31.6183C30.5085 31.8177 30.5447 32.0156 30.6174 32.2005C30.69 32.3854 30.7977 32.5536 30.9342 32.6953C31.2103 32.9833 31.5857 33.1476 31.979 33.1527C32.3722 33.1577 32.7515 33.003 33.0345 32.7222L33.0614 32.6953L43.0242 22.5311H48.3511C48.7443 22.5313 49.1217 22.3717 49.4012 22.0872L58.3139 12.9944C58.4522 12.8545 58.562 12.6877 58.637 12.5037C58.712 12.3197 58.7506 12.1222 58.7506 11.9227C58.7506 11.7232 58.712 11.5256 58.637 11.3416C58.562 11.1576 58.4522 10.9908 58.3139 10.8509C58.0342 10.5667 57.6569 10.4072 57.2638 10.407H52.8078V5.86142C52.8057 5.55986 52.717 5.26568 52.5528 5.0158C52.3886 4.76593 52.1564 4.57151 51.8851 4.45697C51.6139 4.34243 51.3158 4.31286 51.0282 4.37199C50.7407 4.43111 50.4766 4.5763 50.269 4.7893L41.3586 13.8821C41.2213 14.0228 41.1125 14.19 41.0384 14.3742C40.9643 14.5583 40.9265 14.7558 40.927 14.955Z"
            fill="url(#paint172_linear_44_129)"
        />
        <path
            d="M58.2262 17.3705L53.7795 21.9066C59.0517 34.1841 53.5703 48.4967 41.5356 53.8724C29.501 59.248 15.4715 53.6611 10.1993 41.382C4.92707 29.1028 10.4085 14.7966 22.4432 9.41621C25.4558 8.07083 28.7051 7.3766 31.9894 7.3766C35.2737 7.3766 38.523 8.07083 41.5356 9.41621L45.9824 4.87929C31.4982 -2.99901 13.4959 2.59263 5.77333 17.3697C-1.9492 32.1467 3.53228 50.5112 18.0164 58.3935C32.5006 66.2757 50.5029 60.6762 58.2262 45.8999C60.5199 41.5153 61.7206 36.6141 61.7206 31.6352C61.7206 26.6563 60.5199 21.7551 58.2262 17.3705Z"
            fill="url(#paint173_linear_44_129)"
        />
        <path
            d="M37.9528 14.9548C37.952 14.36 38.0659 13.7709 38.2881 13.2216C38.5102 12.6724 38.836 12.1739 39.2468 11.7551C28.4523 7.6407 16.4323 13.2315 12.3972 24.2431C8.36202 35.2547 13.8466 47.5164 24.6403 51.6308C35.434 55.7452 47.4547 50.1544 51.4899 39.1428C53.2501 34.3407 53.2501 29.0437 51.4899 24.2415C50.655 25.0905 49.5274 25.5653 48.3533 25.5621H45.6114C48.9196 33.2473 45.4868 42.2112 37.9528 45.5803C30.4187 48.9493 21.633 45.4529 18.3287 37.7668C15.0244 30.0808 18.4541 21.1178 25.9874 17.7487C27.8752 16.9048 29.9116 16.4693 31.9701 16.4693C34.0285 16.4693 36.0649 16.9048 37.9528 17.7487V14.9548Z"
            fill="url(#paint174_linear_44_129)"
        />
        <path
            d="M36.6512 20.4612C30.6104 17.8493 23.6388 20.731 21.0784 26.8899C18.5181 33.0489 21.3392 40.1652 27.38 42.7771C33.4208 45.3889 40.3925 42.5104 42.9528 36.3483C43.5743 34.8536 43.8948 33.2448 43.8948 31.6191C43.8948 29.9934 43.5743 28.3846 42.9528 26.8899L35.1618 34.8386C34.3251 35.6939 33.1943 36.1755 32.0146 36.1789C30.835 36.1823 29.7015 35.7073 28.8602 34.8568C28.4455 34.4378 28.1158 33.9382 27.8901 33.3871C27.6645 32.836 27.5474 32.2444 27.5457 31.6464C27.544 31.0485 27.6576 30.4561 27.8801 29.9037C28.1026 29.3512 28.4294 28.8497 28.8417 28.4281L28.8602 28.4091L36.6512 20.4612Z"
            fill="url(#paint175_linear_44_129)"
        />
        <path
            d="M40.9154 14.9312V20.3662L30.961 30.5303C30.8217 30.6688 30.7104 30.8342 30.6336 31.0171C30.5568 31.2001 30.516 31.3968 30.5136 31.5961C30.5112 31.7953 30.5472 31.993 30.6195 32.1779C30.6918 32.3627 30.799 32.531 30.9349 32.673C31.2107 32.9609 31.5859 33.1252 31.9789 33.1303C32.3719 33.1353 32.7509 32.9806 33.0336 32.6999C33.0429 32.6912 33.0513 32.6825 33.0598 32.673L43.0141 22.5088H48.3364C48.7307 22.51 49.1094 22.3504 49.3896 22.0649L58.2907 12.9721C58.4284 12.8318 58.5376 12.6649 58.6122 12.4809C58.6867 12.297 58.7251 12.0997 58.7251 11.9004C58.7251 11.7011 58.6867 11.5038 58.6122 11.3198C58.5376 11.1359 58.4284 10.9689 58.2907 10.8287C58.0113 10.5445 57.6343 10.385 57.2414 10.3848H52.7892V5.83916C52.7866 5.5384 52.6978 5.24513 52.5339 4.99602C52.37 4.74691 52.1384 4.55302 51.8679 4.43861C51.5975 4.32419 51.3002 4.29432 51.0134 4.35274C50.7265 4.41115 50.4627 4.55526 50.2551 4.76704L41.3508 13.8599C41.2131 14 41.1037 14.1669 41.0289 14.3507C40.9542 14.5346 40.9156 14.7319 40.9154 14.9312Z"
            fill="url(#paint176_linear_44_129)"
        />
        <path
            d="M58.2022 17.3485L53.7593 21.8846C59.0269 34.1622 53.55 48.4748 41.5269 53.8504C29.5039 59.226 15.4867 53.636 10.2191 41.3592C4.95146 29.0825 10.4283 14.7738 22.4514 9.39347C25.461 8.04806 28.7076 7.35377 31.9892 7.35377C35.2708 7.35377 38.5173 8.04806 41.5269 9.39347L45.9698 4.85654C31.4987 -3.02096 13.5126 2.57068 5.79696 17.3477C-1.91865 32.1248 3.55744 50.4892 18.0293 58.3683C32.5012 66.2474 50.4866 60.6542 58.2022 45.8779C60.494 41.4927 61.6936 36.5918 61.6936 31.6132C61.6936 26.6347 60.494 21.7337 58.2022 17.3485Z"
            fill="url(#paint177_linear_44_129)"
        />
        <path
            d="M37.9473 14.9314C37.9466 14.3367 38.0605 13.7478 38.2823 13.1986C38.5042 12.6494 38.8296 12.1508 39.2398 11.7317C28.4553 7.61726 16.4461 13.2081 12.4163 24.2197C8.38658 35.2313 13.8627 47.4946 24.6487 51.609C35.4347 55.7234 47.4424 50.1325 51.4721 39.121C53.2301 34.3184 53.2301 29.0222 51.4721 24.2197C50.638 25.0683 49.5112 25.5431 48.3379 25.5402H45.5991C48.9003 33.2255 45.4744 42.1893 37.9473 45.5584C30.4202 48.9275 21.6421 45.431 18.3409 37.745C15.0397 30.059 18.4663 21.0959 25.9935 17.7268C27.8793 16.8829 29.9138 16.4474 31.9704 16.4474C34.027 16.4474 36.0615 16.8829 37.9473 17.7268V14.9314Z"
            fill="url(#paint178_linear_44_129)"
        />
        <path
            d="M36.6473 20.4389C30.6119 17.8279 23.6456 20.7048 21.0876 26.8677C18.5295 33.0306 21.3484 40.143 27.3868 42.7548C33.4253 45.3667 40.3885 42.4898 42.9427 36.3261C43.5636 34.8312 43.8838 33.2225 43.8838 31.5969C43.8838 29.9713 43.5636 28.3626 42.9427 26.8677L35.1571 34.8148C34.3215 35.6702 33.1915 36.1519 32.0126 36.1553C30.8337 36.1587 29.7011 35.6835 28.8609 34.833C28.4472 34.4133 28.1184 33.9135 27.8937 33.3623C27.669 32.8112 27.5526 32.2197 27.5515 31.6221C27.5504 31.0244 27.6644 30.4325 27.887 29.8804C28.1097 29.3284 28.4365 28.8272 28.8486 28.4058L28.8663 28.3869L36.6473 20.4389Z"
            fill="url(#paint179_linear_44_129)"
        />
        <path
            d="M40.9078 14.9091V20.3441L30.9626 30.5083C30.8234 30.6467 30.7121 30.8121 30.6353 30.995C30.5585 31.1779 30.5176 31.3746 30.5151 31.5738C30.5126 31.773 30.5485 31.9708 30.6207 32.1556C30.6929 32.3405 30.7999 32.5088 30.9357 32.6509C31.2112 32.9389 31.5861 33.1034 31.9789 33.1084C32.3717 33.1134 32.7505 32.9587 33.0329 32.6778L33.0591 32.6509L43.0042 22.4868H48.3218C48.7146 22.4868 49.0915 22.3273 49.3704 22.0429L58.2669 12.9501C58.4046 12.8098 58.5138 12.6428 58.5884 12.4589C58.6629 12.2749 58.7013 12.0776 58.7013 11.8783C58.7013 11.679 58.6629 11.4817 58.5884 11.2978C58.5138 11.1138 58.4046 10.9469 58.2669 10.8066C57.9879 10.5233 57.6112 10.3649 57.2191 10.3659H52.7701V5.81631C52.7677 5.51539 52.679 5.22188 52.515 4.97265C52.3511 4.72342 52.1191 4.52958 51.8484 4.41546C51.5776 4.30134 51.2801 4.27203 50.9933 4.33122C50.7064 4.39041 50.4429 4.53546 50.2359 4.74814L41.3424 13.8378C41.2048 13.978 41.0955 14.1449 41.0209 14.3287C40.9463 14.5126 40.9078 14.7099 40.9078 14.9091Z"
            fill="url(#paint180_linear_44_129)"
        />
        <path
            d="M58.1793 17.3294L53.7402 21.8655C59.0032 34.143 53.531 48.4556 41.5187 53.8312C29.5063 59.2069 15.5015 53.6176 10.2385 41.3409C4.97549 29.0641 10.4477 14.7515 22.4601 9.3751C25.4666 8.02939 28.7104 7.3349 31.9894 7.3349C35.2683 7.3349 38.5121 8.02939 41.5187 9.3751L45.9577 4.83818C31.4989 -3.04249 13.5289 2.54915 5.82021 17.3294C-1.88847 32.1096 3.58377 50.4677 18.0418 58.3468C32.4998 66.2259 50.4667 60.6327 58.1785 45.8564C60.4681 41.4709 61.6664 36.5707 61.6665 31.593C61.6667 26.6153 60.4686 21.715 58.1793 17.3294Z"
            fill="url(#paint181_linear_44_129)"
        />
        <path
            d="M37.9419 14.9094C37.9414 14.315 38.0553 13.7263 38.2772 13.1774C38.499 12.6285 38.8243 12.1302 39.2344 11.7113C28.4638 7.59688 16.4622 13.1877 12.4348 24.1993C8.40736 35.2109 13.8788 47.475 24.6533 51.5894C35.4278 55.7038 47.4255 50.1129 51.4529 39.1014C53.2093 34.2985 53.2093 29.0029 51.4529 24.2001C50.6199 25.0486 49.4941 25.5234 48.3217 25.5207H45.5868C48.8849 33.2059 45.4621 42.1698 37.9419 45.5388C30.4217 48.9079 21.6514 45.4114 18.3533 37.7254C15.0551 30.0394 18.4779 21.0763 25.9989 17.7072C27.8828 16.8633 29.9156 16.4278 31.9704 16.4278C34.0252 16.4278 36.058 16.8633 37.9419 17.7072V14.9094Z"
            fill="url(#paint182_linear_44_129)"
        />
        <path
            d="M36.6427 20.4145C30.6135 17.8035 23.6534 20.6804 21.0977 26.8433C18.542 33.0062 21.3585 40.1209 27.3869 42.7328C33.4154 45.3446 40.3763 42.4661 42.932 36.304C43.5522 34.809 43.872 33.2003 43.872 31.5748C43.872 29.9494 43.5522 28.3407 42.932 26.8456L35.1572 34.7944C34.3229 35.6497 33.1938 36.1315 32.0158 36.1349C30.8378 36.1383 29.7061 35.6631 28.8671 34.8126C28.4532 34.393 28.1241 33.8933 27.8989 33.3423C27.6737 32.7913 27.5569 32.1999 27.5552 31.6021C27.5535 31.0044 27.6669 30.4123 27.8889 29.8599C28.1109 29.3075 28.4371 28.8058 28.8487 28.3838L28.8671 28.3648L36.6427 20.4145Z"
            fill="url(#paint183_linear_44_129)"
        />
        <path
            d="M40.9 14.8869V20.3219L30.9634 30.486C30.8241 30.6245 30.7128 30.7899 30.636 30.9728C30.5592 31.1557 30.5184 31.3524 30.5159 31.5516C30.5134 31.7508 30.5492 31.9485 30.6214 32.1334C30.6936 32.3183 30.8007 32.4866 30.9365 32.6287C31.2117 32.9166 31.5864 33.081 31.9789 33.086C32.3714 33.0911 32.75 32.9364 33.0321 32.6556L33.0583 32.6287L42.9949 22.4645H48.3033C48.6956 22.4647 49.0721 22.3051 49.3504 22.0207L58.2438 12.9278C58.3814 12.7875 58.4905 12.6205 58.565 12.4366C58.6395 12.2526 58.6779 12.0554 58.6779 11.8561C58.6779 11.6568 58.6395 11.4595 58.565 11.2756C58.4905 11.0917 58.3814 10.9247 58.2438 10.7844C57.9655 10.5 57.5891 10.3404 57.1968 10.3405H52.7516V5.79408C52.7495 5.49369 52.6613 5.20061 52.4979 4.95159C52.3346 4.70257 52.1033 4.5087 51.8333 4.39428C51.5632 4.27987 51.2663 4.25 50.9798 4.30844C50.6933 4.36687 50.43 4.511 50.2228 4.72275L41.3339 13.8156C41.1962 13.9557 41.0869 14.1225 41.0124 14.3064C40.9379 14.4904 40.8997 14.6877 40.9 14.8869Z"
            fill="url(#paint184_linear_44_129)"
        />
        <path
            d="M58.1555 17.3046L53.7203 21.8391C58.9787 34.1167 53.5118 48.4293 41.5103 53.8049C29.5087 59.1805 15.5161 53.5913 10.2585 41.3145C5.00093 29.0378 10.467 14.7244 22.4686 9.34875C25.4722 8.00306 28.7132 7.30853 31.9894 7.30853C35.2656 7.30853 38.5067 8.00306 41.5103 9.34875L45.9447 4.81183C31.4997 -3.06489 13.5459 2.52675 5.84566 17.3038C-1.85457 32.0808 3.60922 50.4453 18.0542 58.3244C32.4991 66.2035 50.4537 60.6095 58.1555 45.834C60.4433 41.4475 61.6405 36.5471 61.6405 31.5693C61.6405 26.5916 60.4433 21.6911 58.1555 17.3046Z"
            fill="url(#paint185_linear_44_129)"
        />
        <path
            d="M37.9366 14.8872C37.936 14.2927 38.0496 13.704 38.271 13.1549C38.4925 12.6057 38.8173 12.1071 39.2267 11.6875C28.4623 7.57308 16.4746 13.1639 12.4541 24.1755C8.43353 35.1871 13.8973 47.4504 24.6625 51.5648C35.4278 55.6792 47.4155 50.0883 51.4352 39.0768C53.1901 34.2736 53.1901 28.9786 51.4352 24.1755C50.6034 25.0238 49.4786 25.4987 48.3071 25.4961H45.5745C48.8703 33.1813 45.4506 42.1452 37.9366 45.5142C30.4225 48.8833 21.6606 45.3868 18.3656 37.7008C15.0705 30.0148 18.4902 21.0517 26.0043 17.6826C27.8862 16.8386 29.9173 16.4029 31.9704 16.4029C34.0236 16.4029 36.0546 16.8386 37.9366 17.6826V14.8872Z"
            fill="url(#paint186_linear_44_129)"
        />
        <path
            d="M36.6388 20.3954C30.6142 17.7843 23.661 20.6612 21.1076 26.8241C18.5542 32.987 21.3676 40.0994 27.3922 42.7112C33.4168 45.3231 40.37 42.4446 42.9273 36.2825C43.5471 34.7873 43.8666 33.1787 43.8666 31.5533C43.8666 29.9279 43.5471 28.3193 42.9273 26.8241L35.1571 34.7728C34.3237 35.6278 33.1955 36.1095 32.0184 36.1129C30.8412 36.1163 29.7105 35.6412 28.8724 34.791C28.4587 34.3713 28.1299 33.8716 27.9049 33.3206C27.6799 32.7696 27.5632 32.1782 27.5614 31.5806C27.5597 30.983 27.6731 30.3909 27.8949 29.8385C28.1167 29.2862 28.4427 28.7844 28.8539 28.3623L28.8724 28.3433L36.6388 20.3954Z"
            fill="url(#paint187_linear_44_129)"
        />
        <path
            d="M40.8917 14.8641V20.2991L30.9642 30.4625C30.8251 30.601 30.714 30.7665 30.6373 30.9494C30.5606 31.1323 30.5199 31.329 30.5174 31.5282C30.515 31.7273 30.5509 31.925 30.6231 32.1099C30.6953 32.2947 30.8024 32.463 30.9381 32.6051C31.2127 32.893 31.5869 33.0574 31.979 33.0625C32.3711 33.0675 32.7492 32.9128 33.0307 32.632L33.0576 32.6051L42.982 22.4409H48.2903C48.6824 22.4411 49.0587 22.2815 49.3366 21.9971L58.2201 12.905C58.4262 12.6927 58.5665 12.4223 58.6231 12.1279C58.6798 11.8336 58.6502 11.5286 58.5382 11.2515C58.4261 10.9745 58.2367 10.7379 57.9938 10.5716C57.7509 10.4054 57.4655 10.317 57.1738 10.3177H52.7332V5.77128C52.7316 5.47087 52.6437 5.17767 52.4805 4.9285C52.3173 4.67934 52.0862 4.48533 51.8161 4.37085C51.5461 4.25637 51.2492 4.22653 50.9627 4.28509C50.6762 4.34364 50.4129 4.48797 50.206 4.69995L41.3256 13.7928C41.1882 13.9331 41.0791 14.1 41.0047 14.2839C40.9302 14.4678 40.8918 14.6649 40.8917 14.8641Z"
            fill="url(#paint188_linear_44_129)"
        />
        <path
            d="M58.1324 17.2821L53.698 21.8183C58.9517 34.095 53.4895 48.4084 41.4979 53.784C29.5064 59.1597 15.5277 53.5704 10.2747 41.2936C5.0217 29.0169 10.4832 14.7035 22.4747 9.32788C25.4752 7.98222 28.7134 7.28768 31.9867 7.28768C35.2601 7.28768 38.4982 7.98222 41.4987 9.32788L45.9293 4.79096C31.5005 -3.08496 13.5628 2.5043 5.86796 17.2813C-1.82688 32.0584 3.6369 50.4229 18.0665 58.302C32.496 66.181 50.4368 60.5878 58.1316 45.8132C60.4178 41.4258 61.6141 36.5254 61.6143 31.5477C61.6144 26.5701 60.4183 21.6696 58.1324 17.2821Z"
            fill="url(#paint189_linear_44_129)"
        />
        <path
            d="M37.9313 14.8646C37.9306 14.2702 38.0441 13.6815 38.2654 13.1324C38.4867 12.5833 38.8113 12.0845 39.2207 11.6649C28.4639 7.55049 16.4885 13.1413 12.4695 24.1529C8.45056 35.1645 13.9113 47.4278 24.6673 51.5422C35.4233 55.6566 47.3994 50.0657 51.4176 39.0542C53.1717 34.2509 53.1717 28.9562 51.4176 24.1529C50.587 25.0011 49.4632 25.476 48.2926 25.4735H45.563C48.855 33.1587 45.4384 42.1218 37.9313 45.4916C30.4242 48.8615 21.67 45.3643 18.378 37.6782C15.086 29.9922 18.5026 21.0291 26.0097 17.6601C27.8903 16.8157 29.9201 16.3799 31.9721 16.3799C34.024 16.3799 36.0538 16.8157 37.9344 17.6601L37.9313 14.8646Z"
            fill="url(#paint190_linear_44_129)"
        />
        <path
            d="M36.634 20.3726C30.6155 17.7616 23.6685 20.6385 21.1174 26.8014C18.5663 32.9643 21.3774 40.0767 27.3959 42.6885C33.4143 45.3004 40.3621 42.4219 42.9132 36.2598C43.5327 34.7645 43.852 33.1559 43.852 31.5306C43.852 29.9053 43.5327 28.2967 42.9132 26.8014L35.1499 34.7501C34.3177 35.6053 33.1904 36.0873 32.014 36.0907C30.8375 36.0941 29.7075 35.6187 28.8707 34.7683C28.4574 34.3484 28.129 33.8485 27.9043 33.2975C27.6796 32.7465 27.563 32.1552 27.5614 31.5577C27.5597 30.9602 27.673 30.3683 27.8947 29.816C28.1163 29.2637 28.442 28.7619 28.853 28.3396L28.8707 28.3206L36.634 20.3726Z"
            fill="url(#paint191_linear_44_129)"
        />
        <path
            d="M40.8838 14.8428V20.2778L30.9648 30.4419C30.8263 30.5814 30.7159 30.7475 30.6398 30.9309C30.5637 31.1142 30.5235 31.3112 30.5214 31.5105C30.5193 31.7098 30.5554 31.9076 30.6277 32.0926C30.6999 32.2776 30.8069 32.4461 30.9425 32.5885C31.0781 32.731 31.2396 32.8446 31.4179 32.9228C31.5962 33.0011 31.7877 33.0425 31.9815 33.0446C32.1753 33.0467 32.3676 33.0096 32.5475 32.9353C32.7273 32.8609 32.8912 32.7509 33.0297 32.6115L33.0566 32.5846L42.9748 22.4204H48.2778C48.6697 22.4206 49.0457 22.2609 49.3233 21.9765L58.196 12.8837C58.4021 12.6715 58.5424 12.4011 58.5991 12.1068C58.6557 11.8125 58.6262 11.5076 58.5143 11.2306C58.4023 10.9536 58.213 10.7169 57.9702 10.5506C57.7275 10.3843 57.4422 10.2959 57.1505 10.2964H52.7138V5.74997C52.7126 5.44971 52.6251 5.15651 52.4623 4.90729C52.2995 4.65807 52.0686 4.46397 51.7987 4.34943C51.5288 4.23489 51.2321 4.20503 50.9457 4.26362C50.6594 4.3222 50.3963 4.4666 50.1896 4.67864L41.3169 13.7683C41.1793 13.9091 41.0701 14.0765 40.9958 14.261C40.9214 14.4454 40.8834 14.6431 40.8838 14.8428Z"
            fill="url(#paint192_linear_44_129)"
        />
        <path
            d="M58.1085 17.26L53.6818 21.7961C58.9301 34.0729 53.4733 48.3862 41.4925 53.7619C29.5117 59.1375 15.5461 53.5482 10.2969 41.2715C5.0478 28.9947 10.5054 14.6814 22.4862 9.30574C25.4836 7.96011 28.7189 7.26554 31.9894 7.26554C35.2599 7.26554 38.4951 7.96011 41.4925 9.30574L45.92 4.76881C31.5004 -3.10948 13.5789 2.48216 5.89099 17.2592C-1.79693 32.0362 3.65993 50.4023 18.0779 58.2798C32.496 66.1573 50.4198 60.5657 58.1085 45.7894C60.3923 41.4016 61.5873 36.5017 61.5873 31.5247C61.5873 26.5477 60.3923 21.6478 58.1085 17.26Z"
            fill="url(#paint193_linear_44_129)"
        />
        <path
            d="M37.9266 14.8431C37.9257 14.2488 38.039 13.6602 38.26 13.1111C38.481 12.5619 38.8054 12.0632 39.2145 11.6434C28.4685 7.52898 16.5023 13.1198 12.4872 24.1338C8.47205 35.1477 13.9281 47.4086 24.6734 51.523C35.4186 55.6374 47.3894 50.0442 51.4007 39.0327C53.1524 34.2289 53.1524 28.9351 51.4007 24.1314C50.5708 24.9795 49.4478 25.4544 48.278 25.452H45.5507C48.8396 33.1372 45.426 42.1003 37.9258 45.4701C30.4256 48.84 21.6799 45.3427 18.3903 37.6567C15.1006 29.9707 18.5149 21.0076 26.0151 17.6385C27.8933 16.7947 29.9208 16.3592 31.9705 16.3592C34.0201 16.3592 36.0476 16.7947 37.9258 17.6385L37.9266 14.8431Z"
            fill="url(#paint194_linear_44_129)"
        />
        <path
            d="M36.6303 20.351C30.6172 17.74 23.6756 20.6169 21.1268 26.7798C18.578 32.9427 21.386 40.0551 27.4006 42.6669C33.4153 45.2788 40.3546 42.4003 42.9034 36.2398C43.5221 34.7443 43.841 33.1358 43.841 31.5106C43.841 29.8854 43.5221 28.2769 42.9034 26.7814L35.147 34.7285C34.3159 35.5633 33.1991 36.0288 32.0374 36.0247C30.8757 36.0205 29.7621 35.5469 28.9367 34.7061C28.1113 33.8654 27.6402 32.7246 27.625 31.5299C27.6098 30.3352 28.0517 29.1823 28.8554 28.3195L28.8739 28.3005L36.6303 20.351Z"
            fill="url(#paint195_linear_44_129)"
        />
        <path
            d="M40.8755 14.8212V20.2569L30.9665 30.4203C30.828 30.5596 30.7175 30.7256 30.6414 30.9088C30.5652 31.092 30.5249 31.2888 30.5227 31.488C30.5206 31.6872 30.5566 31.885 30.6287 32.0699C30.7008 32.2548 30.8076 32.4233 30.943 32.5657C31.2166 32.8534 31.59 33.0176 31.9812 33.0221C32.1749 33.0244 32.3671 32.9874 32.5469 32.9132C32.7267 32.839 32.8906 32.7292 33.0291 32.5899L33.0552 32.563L42.965 22.3988H48.2633C48.655 22.3988 49.0307 22.2392 49.3081 21.9549L58.1731 12.8621C58.3792 12.6499 58.5194 12.3796 58.5761 12.0854C58.6328 11.7912 58.6033 11.4863 58.4915 11.2093C58.3796 10.9323 58.1904 10.6957 57.9478 10.5293C57.7051 10.363 57.42 10.2744 57.1283 10.2748H52.6978V5.72834C52.6969 5.42824 52.6097 5.13511 52.4471 4.88591C52.2845 4.63671 52.0538 4.44259 51.7841 4.32802C51.5144 4.21344 51.2178 4.18355 50.9316 4.2421C50.6454 4.30064 50.3824 4.44502 50.1759 4.65701L41.3117 13.7498C41.174 13.8901 41.0645 14.0569 40.9897 14.2408C40.9148 14.4246 40.876 14.6219 40.8755 14.8212Z"
            fill="url(#paint196_linear_44_129)"
        />
        <path
            d="M58.083 17.2379L53.6602 21.774C58.9047 34.0508 53.4524 48.3642 41.4824 53.7398C29.5124 59.1154 15.556 53.5262 10.3153 41.2494C5.07466 28.9727 10.5231 14.6593 22.4931 9.28365C25.4875 7.93803 28.72 7.24343 31.9877 7.24343C35.2555 7.24343 38.488 7.93803 41.4824 9.28365L45.9076 4.74831C31.5011 -3.12999 13.5958 2.46165 5.91477 17.2387C-1.76622 32.0157 3.68371 50.3802 18.0917 58.2593C32.4997 66.1384 50.4036 60.5452 58.0846 45.7689C60.3666 41.3802 61.5603 36.4801 61.5601 31.5032C61.5598 26.5263 60.3655 21.6264 58.083 17.2379Z"
            fill="url(#paint197_linear_44_129)"
        />
        <path
            d="M37.9206 14.8208C37.9195 14.2266 38.0327 13.638 38.2536 13.0888C38.4745 12.5397 38.7987 12.0409 39.2076 11.6211C28.4716 7.50667 16.5163 13.0975 12.505 24.1091C8.49368 35.1207 13.9444 47.384 24.6804 51.4984C35.4164 55.6128 47.3756 50.0219 51.3831 39.0104C53.1333 34.2063 53.1333 28.9131 51.3831 24.1091C50.5544 24.9571 49.4324 25.432 48.2634 25.4297H45.5385C48.825 33.1149 45.4138 42.078 37.9221 45.4478C30.4304 48.8177 21.6892 45.3204 18.4042 37.6344C15.1192 29.9484 18.5281 20.9853 26.0206 17.6162C27.8969 16.7722 29.9226 16.3365 31.9706 16.3365C34.0185 16.3365 36.0443 16.7722 37.9206 17.6162V14.8208Z"
            fill="url(#paint198_linear_44_129)"
        />
        <path
            d="M36.6265 20.3283C30.618 17.7173 23.6833 20.5942 21.1368 26.7571C18.5903 32.92 21.3969 40.0324 27.4046 42.6482C33.4122 45.264 40.3478 42.3815 42.8942 36.2194C43.5122 34.7238 43.8307 33.1153 43.8307 31.4902C43.8307 29.8651 43.5122 28.2567 42.8942 26.761L35.1448 34.7058C34.3156 35.5493 33.1962 36.0222 32.0299 36.0215C30.8636 36.0208 29.7447 35.5467 28.9164 34.7023C28.0881 33.8578 27.6174 32.7112 27.6066 31.5118C27.5957 30.3123 28.0457 29.157 28.8586 28.2968L28.877 28.2778L36.6265 20.3283Z"
            fill="url(#paint199_linear_44_129)"
        />
        <path
            d="M40.8678 14.797V20.232L30.9673 30.3977C30.8251 30.536 30.7111 30.702 30.6319 30.8861C30.5527 31.0701 30.5099 31.2686 30.506 31.4698C30.5021 31.6711 30.5373 31.8711 30.6093 32.0582C30.6813 32.2454 30.7889 32.4159 30.9256 32.5599C31.0624 32.7038 31.2257 32.8184 31.4059 32.8968C31.5862 32.9752 31.7798 33.016 31.9755 33.0167C32.1712 33.0173 32.3651 32.978 32.5458 32.9008C32.7266 32.8237 32.8906 32.7103 33.0284 32.5673L33.0545 32.5404L42.955 22.3762H48.2488C48.64 22.3763 49.0152 22.2167 49.292 21.9323L58.1493 12.8395C58.3553 12.6273 58.4956 12.3571 58.5522 12.063C58.6089 11.7689 58.5796 11.4641 58.4678 11.1871C58.3561 10.9102 58.167 10.6735 57.9244 10.5071C57.6819 10.3407 57.3969 10.252 57.1053 10.2522H52.677V5.70575C52.6761 5.40612 52.5891 5.11346 52.4268 4.86462C52.2645 4.61577 52.0343 4.42188 51.7651 4.30734C51.4959 4.1928 51.1997 4.16274 50.9139 4.22094C50.6281 4.27914 50.3655 4.42301 50.159 4.63443L41.3002 13.7273C41.1633 13.8676 41.0547 14.0343 40.9805 14.2179C40.9063 14.4014 40.868 14.5982 40.8678 14.797Z"
            fill="url(#paint200_linear_44_129)"
        />
        <path
            d="M58.0615 17.2146L53.6425 21.7516C58.8816 34.0283 53.4348 48.3417 41.4755 53.7173C29.5163 59.093 15.5753 53.5037 10.3361 41.2269C5.09699 28.9502 10.5446 14.6384 22.5031 9.25802C25.4945 7.91245 28.7243 7.21784 31.9893 7.21784C35.2543 7.21784 38.4841 7.91245 41.4755 9.25802L45.8946 4.72426C31.5012 -3.15403 13.6158 2.4376 5.93787 17.2146C-1.74005 31.9917 3.71066 50.3562 18.104 58.2353C32.4974 66.1143 50.3897 60.5211 58.0615 45.7449C60.3412 41.3556 61.5339 36.456 61.5339 31.4798C61.5339 26.5035 60.3412 21.6039 58.0615 17.2146Z"
            fill="url(#paint201_linear_44_129)"
        />
        <path
            d="M37.915 14.7968C37.9139 14.2026 38.027 13.614 38.2477 13.0649C38.4685 12.5158 38.7926 12.0169 39.2014 11.597C28.4746 7.48262 16.53 13.0735 12.5218 24.085C8.51355 35.0966 13.9604 47.3599 24.6864 51.4743C35.4124 55.5887 47.3578 49.9979 51.3652 38.9863C53.1147 34.1822 53.1147 28.8892 51.3652 24.085C50.9555 24.5049 50.4694 24.8376 49.9347 25.0641C49.3999 25.2907 48.827 25.4067 48.2487 25.4056H45.526C48.8095 33.0908 45.4022 42.0539 37.915 45.4238C30.4279 48.7936 21.6983 45.2956 18.4148 37.6104C15.1313 29.9251 18.5395 20.9684 26.0258 17.5938C27.9003 16.75 29.9243 16.3144 31.9704 16.3144C34.0166 16.3144 36.0406 16.75 37.915 17.5938V14.7968Z"
            fill="url(#paint202_linear_44_129)"
        />
        <path
            d="M36.6189 20.3062C30.6181 17.6952 23.688 20.5721 21.1438 26.735C18.5996 32.8979 21.4031 40.0103 27.4054 42.6221C33.4077 45.234 40.337 42.3555 42.8812 36.1934C43.4988 34.6976 43.8171 33.0892 43.8171 31.4642C43.8171 29.8392 43.4988 28.2308 42.8812 26.735L35.1418 34.6837C34.3098 35.5126 33.195 35.973 32.0367 35.9658C30.8784 35.9587 29.769 35.4847 28.9468 34.6456C28.1246 33.8065 27.6551 32.6692 27.6392 31.478C27.6233 30.2868 28.0622 29.1367 28.8617 28.2747L28.8794 28.2557L36.6189 20.3062Z"
            fill="url(#paint203_linear_44_129)"
        />
        <path
            d="M40.8601 14.7763V20.2112L30.9681 30.3754C30.8296 30.5145 30.719 30.6802 30.6428 30.8633C30.5666 31.0463 30.5262 31.2429 30.5238 31.442C30.5215 31.6411 30.5574 31.8387 30.6293 32.0235C30.7012 32.2083 30.8078 32.3768 30.9431 32.5192C31.0783 32.6617 31.2395 32.7754 31.4174 32.8537C31.5954 32.9321 31.7866 32.9737 31.9801 32.9761C32.1737 32.9785 32.3658 32.9416 32.5455 32.8677C32.7253 32.7937 32.889 32.684 33.0276 32.5449L33.0537 32.518L42.945 22.3539H48.2357C48.6266 22.3539 49.0016 22.1942 49.2781 21.91L58.1254 12.8188C58.3319 12.6068 58.4726 12.3366 58.5296 12.0423C58.5867 11.7481 58.5575 11.443 58.4459 11.1658C58.3343 10.8886 58.1452 10.6517 57.9026 10.4851C57.6599 10.3185 57.3747 10.2296 57.083 10.2298H52.6586V5.68343C52.658 5.38363 52.5712 5.0907 52.409 4.84162C52.2467 4.59253 52.0164 4.39844 51.7471 4.28384C51.4777 4.16923 51.1814 4.13925 50.8954 4.19767C50.6095 4.25609 50.3468 4.4003 50.1405 4.6121L41.2932 13.7049C41.1562 13.8455 41.0473 14.0124 40.973 14.1963C40.8987 14.3801 40.8603 14.5772 40.8601 14.7763Z"
            fill="url(#paint204_linear_44_129)"
        />
        <path
            d="M58.0376 17.1918L53.6209 21.7287C58.8523 34.0054 53.4124 48.3188 41.4655 53.6945C29.5186 59.0701 15.5898 53.4871 10.3553 41.2064C5.1208 28.9257 10.5638 14.6163 22.5107 9.24067C25.4992 7.89512 28.7262 7.20047 31.9885 7.20047C35.2508 7.20047 38.4778 7.89512 41.4663 9.24067L45.8814 4.70375C31.5019 -3.17692 13.6288 2.41472 5.96168 17.1918C-1.70547 31.9688 3.736 50.3333 18.1171 58.2124C32.4982 66.0915 50.3705 60.4983 58.0376 45.722C60.3157 41.3322 61.5074 36.4328 61.5074 31.4569C61.5074 26.4809 60.3157 21.5816 58.0376 17.1918Z"
            fill="url(#paint205_linear_44_129)"
        />
        <path
            d="M37.9107 14.7761C37.9094 14.1821 38.0222 13.5937 38.2426 13.0447C38.4629 12.4956 38.7865 11.9966 39.1947 11.5764C28.4779 7.46199 16.5441 13.056 12.5389 24.0644C8.53379 35.0728 13.976 47.3393 24.6943 51.4537C35.4126 55.5681 47.3449 49.9772 51.3493 38.9657C53.0965 34.1611 53.0965 28.869 51.3493 24.0644C50.9401 24.484 50.4545 24.8166 49.9203 25.0432C49.3861 25.2698 48.8137 25.3859 48.2358 25.385H45.5139C48.7944 33.0702 45.3901 42.0333 37.9107 45.4031C30.4312 48.773 21.7078 45.275 18.4274 37.5897C15.1469 29.9045 18.5512 20.9406 26.0314 17.5716C27.9041 16.7278 29.9265 16.2922 31.971 16.2922C34.0156 16.2922 36.0379 16.7278 37.9107 17.5716V14.7761Z"
            fill="url(#paint206_linear_44_129)"
        />
        <path
            d="M36.6188 20.2835C30.618 17.6725 23.6986 20.5494 21.1552 26.7123C20.551 28.1779 20.2336 29.7516 20.221 31.3434C20.2085 32.9353 20.5012 34.5141 21.0822 35.9895C21.6633 37.465 22.5215 38.8082 23.6076 39.9424C24.6937 41.0765 25.9866 41.9794 27.4122 42.5994C33.413 45.2105 40.3316 42.3328 42.8757 36.1707C43.493 34.6748 43.8111 33.0665 43.8111 31.4415C43.8111 29.8165 43.493 28.2081 42.8757 26.7123L35.1386 34.661C34.3081 35.4965 33.1913 35.9623 32.0296 35.9578C30.8679 35.9533 29.7545 35.4787 28.9302 34.6368C28.1059 33.7949 27.6369 32.6531 27.6245 31.4584C27.6121 30.2637 28.0573 29.1119 28.8639 28.252L28.8824 28.233L36.6188 20.2835Z"
            fill="url(#paint207_linear_44_129)"
        />
        <path
            d="M40.8502 14.7538V20.1888L30.9689 30.3529C30.823 30.49 30.7053 30.6558 30.6229 30.8405C30.5406 31.0253 30.4952 31.2252 30.4895 31.4284C30.4838 31.6316 30.5179 31.8339 30.5898 32.0232C30.6617 32.2125 30.7699 32.385 30.908 32.5305C31.046 32.6759 31.2111 32.7913 31.3934 32.8698C31.5758 32.9482 31.7716 32.9882 31.9692 32.9872C32.1668 32.9862 32.3622 32.9444 32.5438 32.8641C32.7254 32.7839 32.8894 32.6669 33.0261 32.5201L33.053 32.4932L42.9359 22.3314H48.2196C48.6103 22.3314 48.985 22.1717 49.2613 21.8875L58.1024 12.7947C58.3083 12.5827 58.4485 12.3127 58.5053 12.0188C58.562 11.7249 58.5329 11.4202 58.4214 11.1434C58.3099 10.8665 58.1212 10.6299 57.879 10.4633C57.6368 10.2967 57.3521 10.2076 57.0607 10.2074H52.6402V5.66095C52.6399 5.3613 52.5533 5.06845 52.3914 4.81938C52.2294 4.57031 51.9993 4.3762 51.7301 4.26157C51.4609 4.14693 51.1647 4.11691 50.8789 4.1753C50.5931 4.23369 50.3306 4.37787 50.1245 4.58962L41.2833 13.6825C41.1462 13.823 41.0374 13.99 40.9631 14.1738C40.8888 14.3576 40.8504 14.5547 40.8502 14.7538Z"
            fill="url(#paint208_linear_44_129)"
        />
        <path
            d="M58.0146 17.1708L53.6033 21.7077C58.8347 33.9845 53.3956 48.2979 41.4586 53.6735C29.5217 59.0491 15.6045 53.4599 10.3777 41.1831C5.15089 28.9064 10.5854 14.593 22.5224 9.21736C25.5079 7.87185 28.7321 7.17719 31.9917 7.17719C35.2512 7.17719 38.4754 7.87185 41.461 9.21736L45.8723 4.68044C31.502 -3.19786 13.6451 2.39378 5.98484 17.1708C-1.67538 31.9479 3.76148 50.3124 18.1295 58.1914C32.4975 66.0705 50.3536 60.4773 58.0146 45.7011C60.2907 41.3106 61.4812 36.4115 61.4812 31.4359C61.4812 26.4604 60.2907 21.5613 58.0146 17.1708Z"
            fill="url(#paint209_linear_44_129)"
        />
        <path
            d="M37.9051 14.7542C37.9037 14.16 38.0164 13.5714 38.2366 13.022C38.4568 12.4727 38.7802 11.9734 39.1883 11.5529C28.4808 7.43848 16.5577 13.0309 12.5572 24.0425C8.5567 35.054 13.9928 47.3166 24.6996 51.431C35.4063 55.5454 47.3302 49.9545 51.3307 38.9429C53.0765 34.1381 53.0765 28.8465 51.3307 24.0417C50.922 24.4614 50.4368 24.794 49.903 25.0206C49.3691 25.2472 48.797 25.3633 48.2195 25.3622H45.5015C48.7796 33.0475 45.3776 42.0106 37.9051 45.3804C30.4326 48.7503 21.7169 45.2522 18.4411 37.567C15.1652 29.8818 18.5649 20.9179 26.0374 17.5488C27.9084 16.7051 29.9291 16.2694 31.972 16.2694C34.0149 16.2694 36.0357 16.7051 37.9066 17.5488L37.9051 14.7542Z"
            fill="url(#paint210_linear_44_129)"
        />
        <path
            d="M36.6134 20.2612C34.0958 19.1638 31.2855 19.0011 28.6641 19.8009C26.0427 20.6007 23.7733 22.3132 22.2449 24.6451C20.7165 26.9769 20.0242 29.7829 20.2867 32.5821C20.5491 35.3813 21.75 37.9995 23.6834 39.988C25.6169 41.9765 28.1626 43.2116 30.8844 43.4815C33.6061 43.7514 36.3343 43.0394 38.6016 41.4675C40.8689 39.8956 42.5341 37.5616 43.3118 34.8655C44.0894 32.1695 43.9312 29.2792 42.8642 26.69L35.1363 34.6387C34.3044 35.4665 33.1901 35.9258 32.0326 35.9178C30.8751 35.9099 29.7668 35.4353 28.9458 34.596C28.1249 33.7567 27.6567 32.6198 27.642 31.4294C27.6272 30.239 28.0671 29.0902 28.867 28.2297L28.8847 28.2107L36.6134 20.2612Z"
            fill="url(#paint211_linear_44_129)"
        />
        <path
            d="M40.8502 14.7538V20.1888L30.9689 30.3529C30.823 30.49 30.7053 30.6558 30.6229 30.8405C30.5406 31.0253 30.4952 31.2252 30.4895 31.4284C30.4838 31.6316 30.5179 31.8339 30.5898 32.0232C30.6617 32.2125 30.7699 32.385 30.908 32.5305C31.046 32.6759 31.2111 32.7913 31.3934 32.8698C31.5758 32.9482 31.7716 32.9882 31.9692 32.9872C32.1668 32.9862 32.3622 32.9444 32.5438 32.8641C32.7254 32.7839 32.8894 32.6669 33.0261 32.5201L33.053 32.4932L42.9359 22.3314H48.2196C48.6103 22.3314 48.985 22.1717 49.2613 21.8875L58.1024 12.7947C58.3083 12.5827 58.4485 12.3127 58.5053 12.0188C58.562 11.7249 58.5329 11.4202 58.4214 11.1434C58.3099 10.8665 58.1212 10.6299 57.879 10.4633C57.6368 10.2967 57.3521 10.2076 57.0607 10.2074H52.6402V5.66095C52.6399 5.3613 52.5533 5.06845 52.3914 4.81938C52.2294 4.57031 51.9993 4.3762 51.7301 4.26157C51.4609 4.14693 51.1647 4.11691 50.8789 4.1753C50.5931 4.23369 50.3306 4.37787 50.1245 4.58962L41.2833 13.6825C41.1462 13.823 41.0374 13.99 40.9631 14.1738C40.8888 14.3576 40.8504 14.5547 40.8502 14.7538Z"
            fill="url(#paint212_linear_44_129)"
        />
        <path
            d="M58.0146 17.1708L53.6033 21.7077C58.8347 33.9845 53.3956 48.2979 41.4586 53.6735C29.5217 59.0491 15.6045 53.4599 10.3777 41.1831C5.15089 28.9064 10.5854 14.593 22.5224 9.21736C25.5079 7.87185 28.7321 7.17719 31.9917 7.17719C35.2512 7.17719 38.4754 7.87185 41.461 9.21736L45.8723 4.68044C31.502 -3.19786 13.6451 2.39378 5.98484 17.1708C-1.67538 31.9479 3.76148 50.3124 18.1295 58.1914C32.4975 66.0705 50.3536 60.4773 58.0146 45.7011C60.2907 41.3106 61.4812 36.4115 61.4812 31.4359C61.4812 26.4604 60.2907 21.5613 58.0146 17.1708Z"
            fill="url(#paint213_linear_44_129)"
        />
        <path
            d="M37.9051 14.7542C37.9037 14.16 38.0164 13.5714 38.2366 13.022C38.4568 12.4727 38.7802 11.9734 39.1883 11.5529C28.4808 7.43848 16.5577 13.0309 12.5572 24.0425C8.5567 35.054 13.9928 47.3166 24.6996 51.431C35.4063 55.5454 47.3302 49.9545 51.3307 38.9429C53.0765 34.1381 53.0765 28.8465 51.3307 24.0417C50.922 24.4614 50.4368 24.794 49.903 25.0206C49.3691 25.2472 48.797 25.3633 48.2195 25.3622H45.5015C48.7796 33.0475 45.3776 42.0106 37.9051 45.3804C30.4326 48.7503 21.7169 45.2522 18.4411 37.567C15.1652 29.8818 18.5649 20.9179 26.0374 17.5488C27.9084 16.7051 29.9291 16.2694 31.972 16.2694C34.0149 16.2694 36.0357 16.7051 37.9066 17.5488L37.9051 14.7542Z"
            fill="url(#paint214_linear_44_129)"
        />
        <path
            d="M36.6134 20.2612C34.0958 19.1638 31.2855 19.0011 28.6641 19.8009C26.0427 20.6007 23.7733 22.3132 22.2449 24.6451C20.7165 26.9769 20.0242 29.7829 20.2867 32.5821C20.5491 35.3813 21.75 37.9995 23.6834 39.988C25.6169 41.9765 28.1626 43.2116 30.8844 43.4815C33.6061 43.7514 36.3343 43.0394 38.6016 41.4675C40.8689 39.8956 42.5341 37.5616 43.3118 34.8655C44.0894 32.1695 43.9312 29.2792 42.8642 26.69L35.1363 34.6387C34.3044 35.4665 33.1901 35.9258 32.0326 35.9178C30.8751 35.9099 29.7668 35.4353 28.9458 34.596C28.1249 33.7567 27.6567 32.6198 27.642 31.4294C27.6272 30.239 28.0671 29.0902 28.867 28.2297L28.8847 28.2107L36.6134 20.2612Z"
            fill="url(#paint215_linear_44_129)"
        />
        <path
            d="M40.85 15.3096V20.6354L30.9688 30.5962C30.8305 30.7308 30.7199 30.8926 30.6435 31.072C30.5671 31.2514 30.5265 31.4447 30.524 31.6405C30.5216 31.8363 30.5574 32.0306 30.6293 32.2119C30.7012 32.3933 30.8077 32.5579 30.9426 32.6961C31.2182 32.9786 31.5899 33.1394 31.979 33.1444C32.368 33.1494 32.7436 32.9983 33.0259 32.723L33.0529 32.6961L42.9357 22.7353H48.2194C48.6086 22.736 48.9826 22.5797 49.2611 22.3002L58.1022 13.3893C58.2388 13.2529 58.3474 13.0897 58.4215 12.9093C58.4956 12.729 58.5338 12.5351 58.5338 12.3393C58.5338 12.1435 58.4956 11.9497 58.4215 11.7694C58.3474 11.589 58.2388 11.4258 58.1022 11.2894C57.8237 11.0098 57.4498 10.8536 57.0606 10.8542H52.64V6.39877C52.6353 6.00102 52.4776 5.62138 52.2013 5.34302C51.925 5.06465 51.5527 4.91025 51.166 4.91363C50.7768 4.91299 50.4028 5.06921 50.1243 5.34881L41.2832 14.2597C41.1466 14.3962 41.0379 14.5594 40.9635 14.7397C40.8892 14.92 40.8506 15.1138 40.85 15.3096Z"
            fill="url(#paint216_linear_44_129)"
        />
        <path
            d="M58.0146 17.6783L53.6033 22.1242C58.8347 34.1557 53.3956 48.1819 41.4586 53.4531C29.5217 58.7242 15.6045 53.2442 10.3777 41.2127C5.15089 29.1812 10.5854 15.1543 22.5224 9.87993C25.5147 8.56123 28.7362 7.88128 31.9917 7.88128C35.2471 7.88128 38.4686 8.56123 41.4609 9.87993L45.87 5.43716C31.502 -2.28368 13.6451 3.19639 5.98484 17.6775C-1.67538 32.1586 3.76148 50.1568 18.1295 57.8776C32.4975 65.5985 50.3536 60.1184 58.0146 45.6373C60.2893 41.3492 61.4812 36.5425 61.4812 31.6578C61.4812 26.773 60.2893 21.9664 58.0146 17.6783Z"
            fill="url(#paint217_linear_44_129)"
        />
        <path
            d="M37.9051 15.3092C37.9048 14.7249 38.0182 14.1465 38.2386 13.608C38.459 13.0695 38.7819 12.5817 39.1883 12.1735C28.4808 8.13822 16.5577 13.6199 12.5572 24.4115C8.5567 35.2031 13.9928 47.2203 24.6995 51.2532C35.4063 55.2861 47.3302 49.8061 51.3307 39.0145C53.0763 34.3123 53.0763 29.1136 51.3307 24.4115C50.4983 25.245 49.3812 25.7095 48.2195 25.7051H45.5015C48.7796 33.2377 45.3776 42.0211 37.9051 45.3277C30.4326 48.6342 21.7169 45.2027 18.4411 37.6709C15.1652 30.1392 18.5649 21.355 26.0374 18.0484C27.9125 17.2211 29.9316 16.7945 31.972 16.7945C34.0124 16.7945 36.0315 17.2211 37.9066 18.0484L37.9051 15.3092Z"
            fill="url(#paint218_linear_44_129)"
        />
        <path
            d="M36.6134 20.707C30.6219 18.1474 23.7056 20.9681 21.1661 27.0076C18.6265 33.0471 21.4253 40.017 27.4169 42.5767C33.4084 45.1363 40.3247 42.3163 42.8643 36.2769C43.4809 34.814 43.799 33.2366 43.799 31.6422C43.799 30.0478 43.4809 28.4704 42.8643 27.0076L35.1363 34.7973C34.3015 35.6364 33.1815 36.1077 32.0141 36.1111C30.8468 36.1145 29.7242 35.6497 28.8848 34.8155C28.474 34.4073 28.147 33.9185 27.9231 33.378C27.6993 32.8376 27.5831 32.2565 27.5814 31.669C27.5798 31.0816 27.6927 30.4998 27.9135 29.958C28.1344 29.4162 28.4586 28.9255 28.8671 28.5149L28.8848 28.4967L36.6134 20.707Z"
            fill="url(#paint219_linear_44_129)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_44_129"
                x1="32.064"
                y1="2.2613"
                x2="32.064"
                y2="62.9148"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_44_129"
                x1="32.0639"
                y1="2.26143"
                x2="32.0639"
                y2="62.9149"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_44_129"
                x1="32.0641"
                y1="2.2622"
                x2="32.0641"
                y2="62.9156"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_44_129"
                x1="32.064"
                y1="2.26133"
                x2="32.064"
                y2="62.9148"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint4_linear_44_129"
                x1="32.094"
                y1="2.2392"
                x2="32.094"
                y2="62.8926"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint5_linear_44_129"
                x1="32.0941"
                y1="2.23895"
                x2="32.0941"
                y2="62.8924"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint6_linear_44_129"
                x1="32.0942"
                y1="2.23944"
                x2="32.0942"
                y2="62.8929"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint7_linear_44_129"
                x1="32.094"
                y1="2.23878"
                x2="32.094"
                y2="62.8922"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint8_linear_44_129"
                x1="32.1241"
                y1="2.21621"
                x2="32.1241"
                y2="62.8697"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint9_linear_44_129"
                x1="32.124"
                y1="2.21576"
                x2="32.124"
                y2="62.8692"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint10_linear_44_129"
                x1="32.1241"
                y1="2.2168"
                x2="32.1241"
                y2="62.8702"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint11_linear_44_129"
                x1="32.1239"
                y1="2.21626"
                x2="32.1239"
                y2="62.8697"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint12_linear_44_129"
                x1="32.1549"
                y1="2.1941"
                x2="32.1549"
                y2="62.8476"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint13_linear_44_129"
                x1="32.155"
                y1="2.19387"
                x2="32.155"
                y2="62.8473"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint14_linear_44_129"
                x1="32.1548"
                y1="2.19385"
                x2="32.1548"
                y2="62.8473"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint15_linear_44_129"
                x1="32.1548"
                y1="2.1938"
                x2="32.1548"
                y2="62.8472"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint16_linear_44_129"
                x1="31.8648"
                y1="2.17231"
                x2="31.8648"
                y2="62.8258"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint17_linear_44_129"
                x1="31.8647"
                y1="2.17161"
                x2="31.8647"
                y2="62.8251"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint18_linear_44_129"
                x1="31.865"
                y1="2.17139"
                x2="31.865"
                y2="62.8248"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint19_linear_44_129"
                x1="31.8648"
                y1="2.17207"
                x2="31.8648"
                y2="62.8255"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint20_linear_44_129"
                x1="31.8949"
                y1="2.14942"
                x2="31.8949"
                y2="62.8029"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint21_linear_44_129"
                x1="31.8949"
                y1="2.15016"
                x2="31.8949"
                y2="62.8036"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint22_linear_44_129"
                x1="31.895"
                y1="2.15035"
                x2="31.895"
                y2="62.8038"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint23_linear_44_129"
                x1="31.8949"
                y1="2.14961"
                x2="31.8949"
                y2="62.8031"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint24_linear_44_129"
                x1="31.9249"
                y1="2.12794"
                x2="31.9249"
                y2="62.7814"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint25_linear_44_129"
                x1="31.9247"
                y1="2.12725"
                x2="31.9247"
                y2="62.7807"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint26_linear_44_129"
                x1="31.9248"
                y1="2.12737"
                x2="31.9248"
                y2="62.7808"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint27_linear_44_129"
                x1="31.9249"
                y1="2.12734"
                x2="31.9249"
                y2="62.7808"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint28_linear_44_129"
                x1="31.9549"
                y1="2.1056"
                x2="31.9549"
                y2="62.7591"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint29_linear_44_129"
                x1="31.9549"
                y1="2.10561"
                x2="31.9549"
                y2="62.7591"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint30_linear_44_129"
                x1="31.9549"
                y1="2.10613"
                x2="31.9549"
                y2="62.7596"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint31_linear_44_129"
                x1="31.9549"
                y1="2.10518"
                x2="31.9549"
                y2="62.7586"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint32_linear_44_129"
                x1="31.9849"
                y1="2.08338"
                x2="31.9849"
                y2="62.7368"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint33_linear_44_129"
                x1="31.9848"
                y1="2.08273"
                x2="31.9848"
                y2="62.7362"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint34_linear_44_129"
                x1="31.9848"
                y1="2.08343"
                x2="31.9848"
                y2="62.7369"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint35_linear_44_129"
                x1="31.985"
                y1="2.08296"
                x2="31.985"
                y2="62.7364"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint36_linear_44_129"
                x1="32.0148"
                y1="2.06104"
                x2="32.0148"
                y2="62.7145"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint37_linear_44_129"
                x1="32.0149"
                y1="2.06092"
                x2="32.0149"
                y2="62.7144"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint38_linear_44_129"
                x1="32.0148"
                y1="2.06133"
                x2="32.0148"
                y2="62.7148"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint39_linear_44_129"
                x1="32.015"
                y1="2.0605"
                x2="32.015"
                y2="62.7139"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint40_linear_44_129"
                x1="32.0441"
                y1="2.03956"
                x2="32.0441"
                y2="62.693"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint41_linear_44_129"
                x1="32.0442"
                y1="2.0385"
                x2="32.0442"
                y2="62.692"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint42_linear_44_129"
                x1="32.0441"
                y1="2.03863"
                x2="32.0441"
                y2="62.6921"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint43_linear_44_129"
                x1="32.044"
                y1="2.03901"
                x2="32.044"
                y2="62.6925"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint44_linear_44_129"
                x1="32.0742"
                y1="2.01654"
                x2="32.0742"
                y2="62.67"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint45_linear_44_129"
                x1="32.0742"
                y1="2.01711"
                x2="32.0742"
                y2="62.6706"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint46_linear_44_129"
                x1="32.0741"
                y1="2.01697"
                x2="32.0741"
                y2="62.6704"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint47_linear_44_129"
                x1="32.0741"
                y1="2.01655"
                x2="32.0741"
                y2="62.67"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint48_linear_44_129"
                x1="32.1042"
                y1="1.99487"
                x2="32.1042"
                y2="62.6483"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint49_linear_44_129"
                x1="32.104"
                y1="1.99467"
                x2="32.104"
                y2="62.6481"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint50_linear_44_129"
                x1="32.1041"
                y1="1.99481"
                x2="32.1041"
                y2="62.6482"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint51_linear_44_129"
                x1="32.1041"
                y1="1.99458"
                x2="32.1041"
                y2="62.648"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint52_linear_44_129"
                x1="32.1342"
                y1="1.97278"
                x2="32.1342"
                y2="62.6262"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint53_linear_44_129"
                x1="32.1342"
                y1="1.97196"
                x2="32.1342"
                y2="62.6254"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint54_linear_44_129"
                x1="32.1341"
                y1="1.9721"
                x2="32.1341"
                y2="62.6255"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint55_linear_44_129"
                x1="32.1341"
                y1="1.9723"
                x2="32.1341"
                y2="62.6258"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint56_linear_44_129"
                x1="31.8441"
                y1="1.95057"
                x2="31.8441"
                y2="62.604"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint57_linear_44_129"
                x1="31.844"
                y1="1.95044"
                x2="31.844"
                y2="62.6039"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint58_linear_44_129"
                x1="31.8443"
                y1="1.95086"
                x2="31.8443"
                y2="62.6043"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint59_linear_44_129"
                x1="31.8442"
                y1="1.95063"
                x2="31.8442"
                y2="62.6041"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint60_linear_44_129"
                x1="31.8735"
                y1="1.92848"
                x2="31.8735"
                y2="62.5819"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint61_linear_44_129"
                x1="31.8735"
                y1="1.92726"
                x2="31.8735"
                y2="62.5807"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint62_linear_44_129"
                x1="31.8734"
                y1="1.9284"
                x2="31.8734"
                y2="62.5818"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint63_linear_44_129"
                x1="31.8734"
                y1="1.92818"
                x2="31.8734"
                y2="62.5816"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint64_linear_44_129"
                x1="31.9035"
                y1="1.90651"
                x2="31.9035"
                y2="62.56"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint65_linear_44_129"
                x1="31.9033"
                y1="1.90604"
                x2="31.9033"
                y2="62.5595"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint66_linear_44_129"
                x1="31.9033"
                y1="1.90631"
                x2="31.9033"
                y2="62.5597"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint67_linear_44_129"
                x1="31.9033"
                y1="1.90645"
                x2="31.9033"
                y2="62.5599"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint68_linear_44_129"
                x1="31.9334"
                y1="1.88381"
                x2="31.9334"
                y2="62.5373"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint69_linear_44_129"
                x1="31.9334"
                y1="1.8843"
                x2="31.9334"
                y2="62.5377"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint70_linear_44_129"
                x1="31.9333"
                y1="1.88482"
                x2="31.9333"
                y2="62.5383"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint71_linear_44_129"
                x1="31.9336"
                y1="1.88399"
                x2="31.9336"
                y2="62.5374"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint72_linear_44_129"
                x1="31.9627"
                y1="1.86208"
                x2="31.9627"
                y2="62.5155"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint73_linear_44_129"
                x1="31.9625"
                y1="1.8611"
                x2="31.9625"
                y2="62.5146"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint74_linear_44_129"
                x1="31.9627"
                y1="1.86138"
                x2="31.9627"
                y2="62.5148"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint75_linear_44_129"
                x1="31.9626"
                y1="1.86226"
                x2="31.9626"
                y2="62.5157"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint76_linear_44_129"
                x1="31.9925"
                y1="1.83889"
                x2="31.9925"
                y2="62.4923"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint77_linear_44_129"
                x1="31.9927"
                y1="1.84012"
                x2="31.9927"
                y2="62.4936"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint78_linear_44_129"
                x1="31.9926"
                y1="1.83941"
                x2="31.9926"
                y2="62.4929"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint79_linear_44_129"
                x1="31.9925"
                y1="1.83955"
                x2="31.9925"
                y2="62.493"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint80_linear_44_129"
                x1="32.0219"
                y1="1.81763"
                x2="32.0219"
                y2="62.4711"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint81_linear_44_129"
                x1="32.0219"
                y1="1.81733"
                x2="32.0219"
                y2="62.4708"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint82_linear_44_129"
                x1="32.0217"
                y1="1.81744"
                x2="32.0217"
                y2="62.4709"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint83_linear_44_129"
                x1="32.0219"
                y1="1.81762"
                x2="32.0219"
                y2="62.4711"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint84_linear_44_129"
                x1="32.0518"
                y1="1.79507"
                x2="32.0518"
                y2="62.4485"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint85_linear_44_129"
                x1="32.0517"
                y1="1.79548"
                x2="32.0517"
                y2="62.4489"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint86_linear_44_129"
                x1="32.0517"
                y1="1.79485"
                x2="32.0517"
                y2="62.4483"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint87_linear_44_129"
                x1="32.0519"
                y1="1.79488"
                x2="32.0519"
                y2="62.4483"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint88_linear_44_129"
                x1="32.081"
                y1="1.77288"
                x2="32.081"
                y2="62.4263"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint89_linear_44_129"
                x1="32.0809"
                y1="1.77333"
                x2="32.0809"
                y2="62.4268"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint90_linear_44_129"
                x1="32.081"
                y1="1.77288"
                x2="32.081"
                y2="62.4263"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint91_linear_44_129"
                x1="32.081"
                y1="1.77339"
                x2="32.081"
                y2="62.4268"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint92_linear_44_129"
                x1="32.1111"
                y1="1.75063"
                x2="32.1111"
                y2="62.4041"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint93_linear_44_129"
                x1="32.111"
                y1="1.75099"
                x2="32.111"
                y2="62.4045"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint94_linear_44_129"
                x1="32.111"
                y1="1.75028"
                x2="32.111"
                y2="62.4037"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint95_linear_44_129"
                x1="32.1112"
                y1="1.75105"
                x2="32.1112"
                y2="62.4045"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint96_linear_44_129"
                x1="32.1403"
                y1="1.72889"
                x2="32.1403"
                y2="62.3823"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint97_linear_44_129"
                x1="32.1404"
                y1="1.72886"
                x2="32.1404"
                y2="62.3823"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint98_linear_44_129"
                x1="32.1404"
                y1="1.72881"
                x2="32.1404"
                y2="62.3823"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint99_linear_44_129"
                x1="32.1405"
                y1="1.72883"
                x2="32.1405"
                y2="62.3823"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint100_linear_44_129"
                x1="31.8495"
                y1="1.70667"
                x2="31.8495"
                y2="62.3601"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint101_linear_44_129"
                x1="31.8496"
                y1="1.70607"
                x2="31.8496"
                y2="62.3595"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint102_linear_44_129"
                x1="31.8497"
                y1="1.70689"
                x2="31.8497"
                y2="62.3603"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint103_linear_44_129"
                x1="31.8497"
                y1="1.70674"
                x2="31.8497"
                y2="62.3602"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint104_linear_44_129"
                x1="31.8794"
                y1="1.68394"
                x2="31.8794"
                y2="62.3374"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint105_linear_44_129"
                x1="31.8797"
                y1="1.6851"
                x2="31.8797"
                y2="62.3385"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint106_linear_44_129"
                x1="31.8796"
                y1="1.68499"
                x2="31.8796"
                y2="62.3384"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint107_linear_44_129"
                x1="31.8796"
                y1="1.68501"
                x2="31.8796"
                y2="62.3385"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint108_linear_44_129"
                x1="31.9088"
                y1="1.66271"
                x2="31.9088"
                y2="62.3162"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint109_linear_44_129"
                x1="31.9089"
                y1="1.66235"
                x2="31.9089"
                y2="62.3158"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint110_linear_44_129"
                x1="31.9086"
                y1="1.66277"
                x2="31.9086"
                y2="62.3162"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint111_linear_44_129"
                x1="31.9088"
                y1="1.66255"
                x2="31.9088"
                y2="62.316"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint112_linear_44_129"
                x1="31.9382"
                y1="1.6395"
                x2="31.9382"
                y2="62.293"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint113_linear_44_129"
                x1="31.938"
                y1="1.64037"
                x2="31.938"
                y2="62.2938"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint114_linear_44_129"
                x1="31.9381"
                y1="1.64043"
                x2="31.9381"
                y2="62.2939"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint115_linear_44_129"
                x1="31.938"
                y1="1.6407"
                x2="31.938"
                y2="62.2941"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint116_linear_44_129"
                x1="31.9672"
                y1="1.61801"
                x2="31.9672"
                y2="62.2715"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint117_linear_44_129"
                x1="31.9674"
                y1="1.61783"
                x2="31.9674"
                y2="62.2713"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint118_linear_44_129"
                x1="31.9674"
                y1="1.61785"
                x2="31.9674"
                y2="62.2713"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint119_linear_44_129"
                x1="31.9673"
                y1="1.61836"
                x2="31.9673"
                y2="62.2718"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint120_linear_44_129"
                x1="31.9972"
                y1="1.59652"
                x2="31.9972"
                y2="62.25"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint121_linear_44_129"
                x1="31.9972"
                y1="1.59563"
                x2="31.9972"
                y2="62.2491"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint122_linear_44_129"
                x1="31.9973"
                y1="1.59576"
                x2="31.9973"
                y2="62.2492"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint123_linear_44_129"
                x1="31.9974"
                y1="1.59651"
                x2="31.9974"
                y2="62.25"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint124_linear_44_129"
                x1="32.0266"
                y1="1.57332"
                x2="32.0266"
                y2="62.2268"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint125_linear_44_129"
                x1="32.0265"
                y1="1.57382"
                x2="32.0265"
                y2="62.2273"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint126_linear_44_129"
                x1="32.0266"
                y1="1.57366"
                x2="32.0266"
                y2="62.2271"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint127_linear_44_129"
                x1="32.0265"
                y1="1.57424"
                x2="32.0265"
                y2="62.2277"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint128_linear_44_129"
                x1="32.0559"
                y1="1.55206"
                x2="32.0559"
                y2="62.2055"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint129_linear_44_129"
                x1="32.0557"
                y1="1.55139"
                x2="32.0557"
                y2="62.2048"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint130_linear_44_129"
                x1="32.0558"
                y1="1.55181"
                x2="32.0558"
                y2="62.2053"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint131_linear_44_129"
                x1="32.0557"
                y1="1.55121"
                x2="32.0557"
                y2="62.2047"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint132_linear_44_129"
                x1="32.0849"
                y1="1.52936"
                x2="32.0849"
                y2="62.1828"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint133_linear_44_129"
                x1="32.085"
                y1="1.5299"
                x2="32.085"
                y2="62.1834"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint134_linear_44_129"
                x1="32.0851"
                y1="1.52935"
                x2="32.0851"
                y2="62.1828"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint135_linear_44_129"
                x1="32.085"
                y1="1.52912"
                x2="32.085"
                y2="62.1826"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint136_linear_44_129"
                x1="32.1141"
                y1="1.50713"
                x2="32.1141"
                y2="62.1606"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint137_linear_44_129"
                x1="32.1143"
                y1="1.50724"
                x2="32.1143"
                y2="62.1607"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint138_linear_44_129"
                x1="32.1142"
                y1="1.50725"
                x2="32.1142"
                y2="62.1607"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint139_linear_44_129"
                x1="32.1143"
                y1="1.50763"
                x2="32.1143"
                y2="62.1611"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint140_linear_44_129"
                x1="32.1433"
                y1="1.48545"
                x2="32.1433"
                y2="62.1389"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint141_linear_44_129"
                x1="32.1435"
                y1="1.48553"
                x2="32.1435"
                y2="62.139"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint142_linear_44_129"
                x1="32.1435"
                y1="1.48467"
                x2="32.1435"
                y2="62.1381"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint143_linear_44_129"
                x1="32.1433"
                y1="1.48493"
                x2="32.1433"
                y2="62.1384"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint144_linear_44_129"
                x1="31.8528"
                y1="1.46275"
                x2="31.8528"
                y2="62.1162"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint145_linear_44_129"
                x1="31.8527"
                y1="1.46287"
                x2="31.8527"
                y2="62.1163"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint146_linear_44_129"
                x1="31.8528"
                y1="1.46319"
                x2="31.8528"
                y2="62.1166"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint147_linear_44_129"
                x1="31.8525"
                y1="1.46332"
                x2="31.8525"
                y2="62.1168"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint148_linear_44_129"
                x1="31.8821"
                y1="1.44125"
                x2="31.8821"
                y2="62.0947"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint149_linear_44_129"
                x1="31.882"
                y1="1.4406"
                x2="31.882"
                y2="62.0941"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint150_linear_44_129"
                x1="31.882"
                y1="1.44085"
                x2="31.882"
                y2="62.0943"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint151_linear_44_129"
                x1="31.8818"
                y1="1.44062"
                x2="31.8818"
                y2="62.0941"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint152_linear_44_129"
                x1="31.9111"
                y1="1.41891"
                x2="31.9111"
                y2="62.0724"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint153_linear_44_129"
                x1="31.9112"
                y1="1.41893"
                x2="31.9112"
                y2="62.0724"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint154_linear_44_129"
                x1="31.9112"
                y1="1.41863"
                x2="31.9112"
                y2="62.0721"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint155_linear_44_129"
                x1="31.9112"
                y1="1.4187"
                x2="31.9112"
                y2="62.0722"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint156_linear_44_129"
                x1="31.9403"
                y1="1.39675"
                x2="31.9403"
                y2="62.0502"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint157_linear_44_129"
                x1="31.9406"
                y1="1.39591"
                x2="31.9406"
                y2="62.0494"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint158_linear_44_129"
                x1="31.9405"
                y1="1.39655"
                x2="31.9405"
                y2="62.05"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint159_linear_44_129"
                x1="31.9403"
                y1="1.39704"
                x2="31.9403"
                y2="62.0505"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint160_linear_44_129"
                x1="31.9696"
                y1="1.37435"
                x2="31.9696"
                y2="62.0278"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint161_linear_44_129"
                x1="31.9695"
                y1="1.37414"
                x2="31.9695"
                y2="62.0276"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint162_linear_44_129"
                x1="31.9696"
                y1="1.37409"
                x2="31.9696"
                y2="62.0275"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint163_linear_44_129"
                x1="31.9695"
                y1="1.37409"
                x2="31.9695"
                y2="62.0275"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint164_linear_44_129"
                x1="31.9983"
                y1="1.3521"
                x2="31.9983"
                y2="62.0055"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint165_linear_44_129"
                x1="31.9981"
                y1="1.35186"
                x2="31.9981"
                y2="62.0053"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint166_linear_44_129"
                x1="31.9981"
                y1="1.35223"
                x2="31.9981"
                y2="62.0057"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint167_linear_44_129"
                x1="31.9981"
                y1="1.35199"
                x2="31.9981"
                y2="62.0054"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint168_linear_44_129"
                x1="32.0272"
                y1="1.3301"
                x2="32.0272"
                y2="61.9835"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint169_linear_44_129"
                x1="32.0273"
                y1="1.33016"
                x2="32.0273"
                y2="61.9836"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint170_linear_44_129"
                x1="32.0273"
                y1="1.33014"
                x2="32.0273"
                y2="61.9836"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint171_linear_44_129"
                x1="32.0274"
                y1="1.32953"
                x2="32.0274"
                y2="61.983"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint172_linear_44_129"
                x1="32.0566"
                y1="1.30788"
                x2="32.0566"
                y2="61.9613"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint173_linear_44_129"
                x1="32.0567"
                y1="1.30767"
                x2="32.0567"
                y2="61.9611"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint174_linear_44_129"
                x1="32.0565"
                y1="1.30768"
                x2="32.0565"
                y2="61.9611"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint175_linear_44_129"
                x1="32.0567"
                y1="1.30782"
                x2="32.0567"
                y2="61.9613"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint176_linear_44_129"
                x1="32.0857"
                y1="1.28562"
                x2="32.0857"
                y2="61.9391"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint177_linear_44_129"
                x1="32.0857"
                y1="1.28571"
                x2="32.0857"
                y2="61.9392"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint178_linear_44_129"
                x1="32.0857"
                y1="1.28583"
                x2="32.0857"
                y2="61.9393"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint179_linear_44_129"
                x1="32.0858"
                y1="1.28559"
                x2="32.0858"
                y2="61.939"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint180_linear_44_129"
                x1="32.1143"
                y1="1.26356"
                x2="32.1143"
                y2="61.917"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint181_linear_44_129"
                x1="32.1143"
                y1="1.26419"
                x2="32.1143"
                y2="61.9176"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint182_linear_44_129"
                x1="32.1142"
                y1="1.26386"
                x2="32.1142"
                y2="61.9173"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint183_linear_44_129"
                x1="32.1144"
                y1="1.26355"
                x2="32.1144"
                y2="61.917"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint184_linear_44_129"
                x1="32.1435"
                y1="1.24133"
                x2="32.1435"
                y2="61.8948"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint185_linear_44_129"
                x1="32.1436"
                y1="1.24179"
                x2="32.1436"
                y2="61.8952"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint186_linear_44_129"
                x1="32.1435"
                y1="1.24164"
                x2="32.1435"
                y2="61.8951"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint187_linear_44_129"
                x1="32.1435"
                y1="1.24201"
                x2="32.1435"
                y2="61.8954"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint188_linear_44_129"
                x1="31.8521"
                y1="1.21853"
                x2="31.8521"
                y2="61.872"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint189_linear_44_129"
                x1="31.8521"
                y1="1.21934"
                x2="31.8521"
                y2="61.8728"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint190_linear_44_129"
                x1="31.852"
                y1="1.21906"
                x2="31.852"
                y2="61.8725"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint191_linear_44_129"
                x1="31.8518"
                y1="1.2193"
                x2="31.8518"
                y2="61.8727"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint192_linear_44_129"
                x1="31.8811"
                y1="1.19722"
                x2="31.8811"
                y2="61.8507"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint193_linear_44_129"
                x1="31.8813"
                y1="1.1972"
                x2="31.8813"
                y2="61.8506"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint194_linear_44_129"
                x1="31.8812"
                y1="1.19755"
                x2="31.8812"
                y2="61.851"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint195_linear_44_129"
                x1="31.8812"
                y1="1.1977"
                x2="31.8812"
                y2="61.8511"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint196_linear_44_129"
                x1="31.9105"
                y1="1.17559"
                x2="31.9105"
                y2="61.829"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint197_linear_44_129"
                x1="31.9104"
                y1="1.1751"
                x2="31.9104"
                y2="61.8286"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint198_linear_44_129"
                x1="31.9105"
                y1="1.17523"
                x2="31.9105"
                y2="61.8287"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint199_linear_44_129"
                x1="31.9105"
                y1="1.17499"
                x2="31.9105"
                y2="61.8284"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint200_linear_44_129"
                x1="31.939"
                y1="1.15301"
                x2="31.939"
                y2="61.8065"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint201_linear_44_129"
                x1="31.9389"
                y1="1.15264"
                x2="31.9389"
                y2="61.8061"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint202_linear_44_129"
                x1="31.9389"
                y1="1.15277"
                x2="31.9389"
                y2="61.8062"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint203_linear_44_129"
                x1="31.939"
                y1="1.1529"
                x2="31.939"
                y2="61.8063"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint204_linear_44_129"
                x1="31.9682"
                y1="1.13068"
                x2="31.9682"
                y2="61.7841"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint205_linear_44_129"
                x1="31.9681"
                y1="1.12976"
                x2="31.9681"
                y2="61.7832"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint206_linear_44_129"
                x1="31.9683"
                y1="1.13056"
                x2="31.9683"
                y2="61.784"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint207_linear_44_129"
                x1="31.9682"
                y1="1.13019"
                x2="31.9682"
                y2="61.7836"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint208_linear_44_129"
                x1="31.9967"
                y1="1.1082"
                x2="31.9967"
                y2="61.7617"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint209_linear_44_129"
                x1="31.9967"
                y1="1.10882"
                x2="31.9967"
                y2="61.7623"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint210_linear_44_129"
                x1="31.9966"
                y1="1.10862"
                x2="31.9966"
                y2="61.7621"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint211_linear_44_129"
                x1="31.9966"
                y1="1.10785"
                x2="31.9966"
                y2="61.7613"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint212_linear_44_129"
                x1="2.51218"
                y1="31.4353"
                x2="61.4813"
                y2="31.4353"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F2F2F2" />
                <stop offset="1" stopColor="#D8D8D8" />
            </linearGradient>
            <linearGradient
                id="paint213_linear_44_129"
                x1="2.51208"
                y1="31.4359"
                x2="61.4812"
                y2="31.4359"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F2F2F2" />
                <stop offset="1" stopColor="#D8D8D8" />
            </linearGradient>
            <linearGradient
                id="paint214_linear_44_129"
                x1="2.51208"
                y1="31.4357"
                x2="61.4812"
                y2="31.4357"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F2F2F2" />
                <stop offset="1" stopColor="#D8D8D8" />
            </linearGradient>
            <linearGradient
                id="paint215_linear_44_129"
                x1="2.51207"
                y1="31.435"
                x2="61.4812"
                y2="31.435"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F2F2F2" />
                <stop offset="1" stopColor="#D8D8D8" />
            </linearGradient>
            <linearGradient
                id="paint216_linear_44_129"
                x1="31.9966"
                y1="1.93702"
                x2="31.9966"
                y2="61.3774"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FF8400" />
                <stop offset="1" stopColor="#801A01" />
            </linearGradient>
            <linearGradient
                id="paint217_linear_44_129"
                x1="31.9967"
                y1="1.93754"
                x2="31.9967"
                y2="61.3779"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#BCBCBC" />
                <stop offset="1" stopColor="#9E9E9E" />
            </linearGradient>
            <linearGradient
                id="paint218_linear_44_129"
                x1="31.9966"
                y1="1.93654"
                x2="31.9966"
                y2="61.3769"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#BCBCBC" />
                <stop offset="1" stopColor="#9E9E9E" />
            </linearGradient>
            <linearGradient
                id="paint219_linear_44_129"
                x1="31.9967"
                y1="1.93742"
                x2="31.9967"
                y2="61.3778"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#BCBCBC" />
                <stop offset="1" stopColor="#9E9E9E" />
            </linearGradient>
        </defs>
    </svg>,
    'QuestsIcon',
)
