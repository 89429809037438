import React from 'react'
import { Stack } from '@mui/material'
import { toast } from 'sonner'
import { BellIcon } from 'shared/icons'
import { CustomText } from 'components/Text3D'

export const sendNotificationToast = ({
    title,
    message,
    duration = 5_000,
}: {
    title: string
    message: string
    duration?: number
}) =>
    toast(
        <Stack
            sx={{
                width: '100%',
                height: '100%',
                position: 'relative',
                background: `linear-gradient(180deg, #404040 0%, #1E1E1E 100%)`,
                boxShadow: `inset 0px 0px 6px rgba(0, 0, 0, 0.4)`,
                borderRadius: '10px',
                border: '1px solid #1E1E1E',
                padding: '8px 0',
            }}
        >
            <Stack
                sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: `0px 4px 0px #1E1E1E`,
                    background: `linear-gradient(180deg, #606060 0%, #404040 100%)`,
                    border: `1px solid #1E1E1E`,
                    borderRadius: `50%`,
                    padding: `4px`,
                    width: 'max-content',
                    position: 'absolute',
                    top: '50%',
                    left: '0',
                    transform: 'translate(-36px, -50%)',
                }}
            >
                <Stack
                    sx={{
                        background: `linear-gradient(180deg, #404040 0%, #1E1E1E 100%)`,
                        boxShadow: `inset 0px 0px 6px rgba(0, 0, 0, 0.4)`,
                        borderRadius: '50%',
                        width: '48px',
                        height: '48px',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <BellIcon
                        sx={{
                            height: '24px',
                            width: 'auto',
                            fill: '#ff8400',
                            filter: ` drop-shadow(-1px -1px 0 #1e1e1e) drop-shadow(1px -1px 0 #1e1e1e) drop-shadow(-1px 1px 0 #1e1e1e) drop-shadow(1px 1px 0 #1e1e1e)`,
                        }}
                    />
                </Stack>
            </Stack>
            <Stack marginLeft="32px">
                <CustomText fontSize="20px" color="#e7e7e7" text={title} />
                <CustomText fontSize="14px" color="#919191" text={message} />
            </Stack>
        </Stack>,

        {
            style: {
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: `0px 4px 0px #1E1E1E`,
                background: `linear-gradient(180deg, #606060 0%, #404040 100%)`,
                border: `1px solid #1E1E1E`,
                borderRadius: `10px`,
                padding: '4px',
                position: 'relative',
            },
            duration,
        },
    )
