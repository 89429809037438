const nftLevels = new Map<number, number>()

nftLevels.set(2, 100)
nftLevels.set(3, 200)
nftLevels.set(4, 300)
nftLevels.set(5, 400)
nftLevels.set(6, 500)
nftLevels.set(7, 600)
nftLevels.set(8, 700)
nftLevels.set(9, 800)
nftLevels.set(10, 900)
nftLevels.set(11, 1000)

nftLevels.set(12, 1000)
nftLevels.set(13, 1200)
nftLevels.set(14, 1300)
nftLevels.set(15, 1400)
nftLevels.set(16, 1500)
nftLevels.set(17, 1600)
nftLevels.set(18, 1700)
nftLevels.set(19, 1800)
nftLevels.set(20, 1900)
nftLevels.set(21, 2000)

nftLevels.set(22, 2100)
nftLevels.set(23, 2200)
nftLevels.set(24, 2300)
nftLevels.set(25, 2400)
nftLevels.set(26, 2500)
nftLevels.set(27, 2600)
nftLevels.set(28, 2700)
nftLevels.set(29, 2800)
nftLevels.set(30, 2900)
nftLevels.set(31, 3000)

nftLevels.set(32, 3100)
nftLevels.set(33, 3200)
nftLevels.set(34, 3300)
nftLevels.set(35, 3400)
nftLevels.set(36, 3500)
nftLevels.set(37, 3600)
nftLevels.set(38, 3700)
nftLevels.set(39, 3800)
nftLevels.set(40, 3900)
nftLevels.set(41, 4000)

nftLevels.set(42, 4100)
nftLevels.set(43, 4200)
nftLevels.set(44, 4300)
nftLevels.set(45, 4400)
nftLevels.set(46, 4500)
nftLevels.set(47, 4600)
nftLevels.set(48, 4700)
nftLevels.set(49, 4800)
nftLevels.set(50, 4900)
nftLevels.set(51, 5000)

nftLevels.set(52, 5100)
nftLevels.set(53, 5200)
nftLevels.set(54, 5300)
nftLevels.set(55, 5400)
nftLevels.set(56, 5500)
nftLevels.set(57, 5600)
nftLevels.set(58, 5700)
nftLevels.set(59, 5800)
nftLevels.set(60, 5900)
nftLevels.set(61, 6000)

nftLevels.set(62, 6100)
nftLevels.set(63, 6200)
nftLevels.set(64, 6300)
nftLevels.set(65, 6400)
nftLevels.set(66, 6500)
nftLevels.set(67, 6600)
nftLevels.set(68, 6700)
nftLevels.set(69, 6800)
nftLevels.set(70, 6900)
nftLevels.set(71, 7000)

nftLevels.set(72, 7100)
nftLevels.set(73, 7200)
nftLevels.set(74, 7300)
nftLevels.set(75, 7400)
nftLevels.set(76, 7500)
nftLevels.set(77, 7600)
nftLevels.set(78, 7700)
nftLevels.set(79, 7800)
nftLevels.set(80, 7900)
nftLevels.set(81, 8000)

nftLevels.set(82, 8100)
nftLevels.set(83, 8200)
nftLevels.set(84, 8300)
nftLevels.set(85, 8400)
nftLevels.set(86, 8500)
nftLevels.set(87, 8600)
nftLevels.set(88, 8700)
nftLevels.set(89, 8800)
nftLevels.set(90, 8900)
nftLevels.set(91, 9000)

nftLevels.set(92, 9100)
nftLevels.set(93, 9200)
nftLevels.set(94, 9300)
nftLevels.set(95, 9400)
nftLevels.set(96, 9500)
nftLevels.set(97, 9600)
nftLevels.set(98, 9700)
nftLevels.set(99, 9800)
nftLevels.set(100, 9900)

const nftDefaultFee = 30
const nftLevelUpRatio = 10

const DEFENSE_UPGRADE_POINTS = 0.3
const DAMAGE_UPGRADE_POINTS = 0.12

const XP_FOR_FIGHT_WON = {
    FREE: 5,
    CUSTOM: 10,
}
const XP_FOR_FIGHT_DEFEAT = {
    FREE: 3,
    CUSTOM: 7,
}

export {
    nftLevels,
    nftDefaultFee,
    nftLevelUpRatio,
    DAMAGE_UPGRADE_POINTS,
    DEFENSE_UPGRADE_POINTS,
    XP_FOR_FIGHT_WON,
    XP_FOR_FIGHT_DEFEAT,
}
