import React from 'react'
import { createSvgIcon } from '@mui/material'

export const GemIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="69" height="89" viewBox="0 0 69 89" fill="none">
        <path d="M37.1608 22.6399C41.8947 29.0308 46.6665 35.3743 51.4052 41.751C51.5485 41.966 51.7466 42.1384 51.979 42.2504C52.2113 42.3624 52.4693 42.4098 52.7261 42.3877C57.6181 42.3664 62.5125 42.3759 67.4068 42.3759H68.506C68.0343 41.5948 67.7205 40.9415 67.2983 40.364C62.3969 33.6638 57.4923 26.9692 52.5845 20.28C48.3954 14.563 44.2055 8.8459 40.0148 3.12883C39.1633 1.9619 38.1609 1.01273 36.6348 0.501465V1.51217C36.6348 8.0498 36.6348 14.5874 36.6584 21.1251C36.6706 21.6692 36.8456 22.197 37.1608 22.6399Z" />
        <path d="M52.4382 46.6579C52.2477 46.6833 52.0642 46.7464 51.8982 46.8435C51.7322 46.9406 51.587 47.0698 51.4711 47.2236C46.6593 53.6192 41.8679 60.029 37.097 66.453C36.8182 66.8516 36.6645 67.3248 36.6559 67.8117C36.6252 72.9599 36.6347 78.1057 36.6347 83.2516V88.4992C37.9222 88.0769 39.0381 87.2462 39.8142 86.1322C49.0369 73.5587 58.2548 60.9829 67.4681 48.4047C67.8239 47.8515 68.1483 47.2785 68.4399 46.6886C68.1427 46.6555 68.0271 46.6318 67.9139 46.6318C62.7553 46.6366 57.5873 46.6271 52.4382 46.6579Z" />
        <path d="M16.5218 42.3476C16.725 42.3202 16.9208 42.2525 17.0978 42.1485C17.2748 42.0445 17.4293 41.9062 17.5526 41.7417C22.3644 35.3508 27.155 28.9441 31.9243 22.5217C32.2066 22.1237 32.362 21.6491 32.3701 21.1607C32.3984 14.597 32.3914 8.03097 32.3914 1.4673V0.520508C30.8676 0.993906 29.8699 1.9407 29.0184 3.10763C25.4661 7.97416 21.9076 12.8344 18.3427 17.6883C12.7934 25.2627 7.24567 32.837 1.69949 40.4114C1.28436 40.9771 0.97772 41.6257 0.564941 42.3263C0.906958 42.3547 1.03669 42.3736 1.14047 42.3736C6.26836 42.376 11.3963 42.3973 16.5218 42.3476Z" />
        <path d="M32.0988 66.7062C27.2147 60.1481 22.3172 53.5994 17.4063 47.0602C17.3216 46.9483 17.2153 46.8548 17.0937 46.7853C16.9721 46.7157 16.8377 46.6715 16.6987 46.6554C11.3962 46.6341 6.08434 46.6389 0.494141 46.6389C0.98004 47.4413 1.31262 48.0898 1.73719 48.6698C6.76916 55.5593 11.8106 62.4425 16.8614 69.3194C20.9121 74.8424 24.9613 80.3653 29.0089 85.8883C29.8604 87.0529 30.8605 88.002 32.33 88.4754C32.3512 88.2719 32.3772 88.1346 32.3772 88.002C32.3772 81.1993 32.3772 74.3974 32.3772 67.5962C32.3758 67.2782 32.2788 66.968 32.0988 66.7062Z" />
        <path d="M34.5259 26.2544C34.3726 26.4177 34.3066 26.4911 34.2547 26.5479C29.9052 32.3612 25.5502 38.1722 21.1897 43.9808C20.8217 44.4708 20.9915 44.7406 21.2746 45.1169C25.5203 50.7883 29.766 56.4619 34.0117 62.138C34.1603 62.3368 34.3396 62.512 34.5448 62.7439L48.1854 44.5039L34.5259 26.2544Z" />
    </svg>,
    'GemIcon',
)
