export enum APIEndpoint {
    GET_CONFIG = '/config',
    LOGIN = '/auth/login',
    REGISTER = '/auth/register',
    LOGOUT = '/auth/logout',
    ME = '/users/me',
    ME_BALANCE = '/users/me/balance/',
    ME_PROFILE = '/users/me/profile',
    ME_NOTIFICATIONS = '/users/me/notifications',
    ME_QUESTS = '/users/me/quests',
    ME_NOTIFICATIONS_MARK_ALL_AS_READ = '/users/me/notifications/markAllAsRead',
    ME_CHARACTERS = '/users/me/characters',
    ME_FIGHTS = '/users/me/fights',
    ME_ACTIVE_NFT_UPGRADE = '/users/me/nfts/upgrades/active',
    ME_SELECT_CHARACTER = '/users/me/select-character',
    ME_WITHDRAW = '/users/me/withdraw',
    ME_CLAIM_QUEST = '/users/me/claim-quest-reward',
    ME_CLAIM_QUEST_BIG_REWARD = '/users/me/claim-days-streak-reward',
    RENT_CHARACTER = '/rents/',
    UPGRADE_CHARACTER = '/characters/',
    ADMIN_USERS = '/admin/users',
    ADMIN_ONLINE_USERS = '/admin/users/online',
    ADMIN_FIGHTS = '/admin/fights',
    ADMIN_FIGHTS_COUNT = '/admin/fights/count',
    ADMIN_FIGHTS_ONGOING = '/admin/fights/ongoing',
    ADMIN_OVERVIEW_MONTHLY = '/admin/overview',
    SELECT_TOKEN = '/users/me/token',
    // REMOVE AFTER EVENT
    GET_EVENT_INFO = '/event',
}
