import React from 'react'
import { createSvgIcon } from '@mui/material'

export const PremiumWheelArrow = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 41.05 36.93">
        <defs>
            <radialGradient id="radial-gradient" cx={32.01} cy={3.97} r={27.49} gradientUnits="userSpaceOnUse">
                <stop offset={0} stopColor="#45b5ff" />
                <stop offset={0.57} stopColor="#1b99ff" />
                <stop offset={1} stopColor="#08f" />
            </radialGradient>
            <radialGradient id="radial-gradient-2" cx={10.11} cy={9.17} r={30.22} gradientUnits="userSpaceOnUse">
                <stop offset={0} stopColor="#bff2ff" />
                <stop offset={0.38} stopColor="#7ed0ff" />
                <stop offset={0.8} stopColor="#3aacff" />
                <stop offset={1} stopColor="#1f9eff" />
            </radialGradient>
            <radialGradient id="radial-gradient-3" cx={17.56} cy={10.33} r={24.66} gradientUnits="userSpaceOnUse">
                <stop offset={0} stopColor="#2679ff" />
                <stop offset={0.65} stopColor="#153ec5" />
                <stop offset={1} stopColor="#0e23ab" />
            </radialGradient>
            <radialGradient id="radial-gradient-4" cx={19.74} cy={10.38} r={30.48} gradientUnits="userSpaceOnUse">
                <stop offset={0} stopColor="#2b95ff" />
                <stop offset={0.04} stopColor="#298df0" />
                <stop offset={0.25} stopColor="#1c65a9" />
                <stop offset={0.45} stopColor="#12446c" />
                <stop offset={0.63} stopColor="#0a2a3e" />
                <stop offset={0.78} stopColor="#05171c" />
                <stop offset={0.91} stopColor="#010c07" />
                <stop offset={1} stopColor="#000800" />
            </radialGradient>
            <radialGradient id="radial-gradient-5" cx={2.05} cy={-2.06} r={51.74} gradientUnits="userSpaceOnUse">
                <stop offset={0} stopColor="#fff59a" />
                <stop offset={1} stopColor="#f28700" />
            </radialGradient>
            <radialGradient id="radial-gradient-6" cx={26.47} cy={-14.96} r={54.66} gradientUnits="userSpaceOnUse">
                <stop offset={0} stopColor="#ffb627" />
                <stop offset={1} stopColor="#c75700" />
            </radialGradient>
            <radialGradient id="radial-gradient-7" cx={-23.31} cy={10.11} r={70.76} gradientUnits="userSpaceOnUse">
                <stop offset={0} stopColor="#cf6800" />
                <stop offset={1} stopColor="#3d1f07" />
            </radialGradient>
        </defs>
        <g
            style={{
                isolation: 'isolate',
            }}
        >
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path
                        d="M31.53,17.86,41.05,1.38a153.33,153.33,0,0,0-41,0L9.51,17.86l11,19.07,11-19.07Z"
                        style={{
                            fill: '#999',
                            fillRule: 'evenodd',
                        }}
                    />
                    <path
                        d="M35.48,4.18a149.48,149.48,0,0,0-29.91,0l15,25.89,15-25.89Z"
                        style={{
                            fill: '#9712ff',
                            fillRule: 'evenodd',
                        }}
                    />
                    <polygon
                        points="20.52 30.07 20.52 14.06 5.57 4.18 20.52 30.07 20.52 30.07"
                        style={{
                            fillRule: 'evenodd',
                            fill: 'url(#radial-gradient)',
                        }}
                    />
                    <path
                        d="M5.57,4.18l15,9.88,15-9.88a149.48,149.48,0,0,0-29.91,0Z"
                        style={{
                            fillRule: 'evenodd',
                            fill: 'url(#radial-gradient-2)',
                        }}
                    />
                    <polygon
                        points="20.52 14.06 20.52 30.07 35.48 4.18 20.52 14.06 20.52 14.06"
                        style={{
                            fillRule: 'evenodd',
                            fill: 'url(#radial-gradient-3)',
                        }}
                    />
                    <polygon
                        points="9.09 6.5 19.86 14.5 20.52 27.95 21.2 14.51 26.91 9.84 20.52 13.23 9.09 6.5 9.09 6.5"
                        style={{
                            fillRule: 'evenodd',
                            mixBlendMode: 'screen',
                            fill: 'url(#radial-gradient-4)',
                        }}
                    />
                    <path
                        d="M5.57,4.18a149.48,149.48,0,0,1,29.91,0l5.57-2.8a153.33,153.33,0,0,0-41,0l5.57,2.8Z"
                        style={{
                            fillRule: 'evenodd',
                            fill: 'url(#radial-gradient-5)',
                        }}
                    />
                    <polygon
                        points="20.52 30.07 5.57 4.18 0 1.39 9.51 17.86 20.52 36.93 20.52 30.07 20.52 30.07"
                        style={{
                            fillRule: 'evenodd',
                            fill: 'url(#radial-gradient-6)',
                        }}
                    />
                    <polygon
                        points="35.48 4.18 20.52 30.07 20.52 36.93 31.53 17.86 41.05 1.39 35.48 4.18 35.48 4.18"
                        style={{
                            fillRule: 'evenodd',
                            fill: 'url(#radial-gradient-7)',
                        }}
                    />
                </g>
            </g>
        </g>
    </svg>,
    'PremiumWheelArrow',
)
