import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { APIEndpoint } from 'shared/types'
import { API_URL } from '../../../config'
import { RootState } from 'stores/store'

export const rentCharacter = createAsyncThunk('user/me/rent-character', async (arg, { rejectWithValue, getState }) => {
    // check if token is expired
    const token = (getState() as RootState).auth.token
    if (!token) {
        return rejectWithValue('Token is not found')
    }
    try {
        const { data: rent } = await axios.post(
            API_URL + APIEndpoint.RENT_CHARACTER,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        )
        return rent
    } catch (err: any) {
        return rejectWithValue(err.response.data)
    }
})
