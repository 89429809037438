import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { APIEndpoint } from 'shared/types'
import { API_URL } from '../../../config'
import { RootState } from 'stores/store'

export const getCharacters = createAsyncThunk('user/me/characters', async (arg, { rejectWithValue, getState }) => {
    // check if token is expired
    const token = (getState() as RootState).auth.token
    if (!token) {
        return rejectWithValue('Token is not found')
    }
    try {
        const { data: characters } = await axios.get(API_URL + APIEndpoint.ME_CHARACTERS, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        return characters
    } catch (err: any) {
        return rejectWithValue(err.response.data)
    }
})
