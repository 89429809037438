import React from 'react'
import { createSvgIcon } from '@mui/material'

export const NotificationIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="32" viewBox="0 0 26 32" fill="none">
        <path
            d="M13.2985 32C10.3377 32 7.77384 30.0905 7.01523 27.3921C5.04748 27.0358 3.11105 26.5242 1.2241 25.8621C0.595413 25.6416 0.172882 25.0651 0.172882 24.4226V21.9401C0.173568 21.7115 0.226406 21.486 0.327372 21.2809C0.428338 21.0758 0.574771 20.8964 0.755525 20.7564C1.6057 20.0858 2.0932 19.1164 2.0932 18.0964V13.5324C2.0932 10.5942 3.39967 7.77353 5.67756 5.7949C6.75659 4.85613 8.00955 4.13849 9.36524 3.68275C9.8067 1.96961 11.4196 0.729126 13.2985 0.729126C15.3032 0.729126 16.9764 2.11795 17.3002 3.96768C21.3168 5.66701 23.985 9.60894 23.985 13.9171V18.0979C23.985 19.1502 24.4966 20.1379 25.3861 20.8091C25.5755 20.9481 25.7297 21.1296 25.8363 21.339C25.9429 21.5484 25.999 21.7798 26 22.0148V24.5771C26 25.2252 25.5703 25.8048 24.9314 26.0242C23.1846 26.6147 21.397 27.0763 19.5827 27.4054C18.8175 30.0956 16.2541 32 13.2985 32ZM10.8431 27.8673C11.1579 28.203 11.5383 28.4705 11.9606 28.6534C12.3829 28.8363 12.8382 28.9307 13.2985 28.9307C13.7587 28.9307 14.214 28.8363 14.6364 28.6534C15.0587 28.4705 15.439 28.203 15.7539 27.8673C14.8725 27.9374 14.0775 27.9696 13.3169 27.9696C12.5353 27.9727 11.7286 27.9389 10.8436 27.8673H10.8431Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2984 31.9857C10.3402 31.9857 7.77894 30.0761 7.02084 27.3778C5.05477 27.0214 3.12003 26.5098 1.23482 25.8477C0.606651 25.6278 0.184631 25.0487 0.184631 24.4083V21.9258C0.185234 21.6972 0.23793 21.4719 0.338713 21.2667C0.439496 21.0616 0.585714 20.8822 0.766251 20.7421C1.61592 20.0714 2.10342 19.1021 2.10342 18.0821V13.5181C2.10342 10.5798 3.40835 7.7592 5.68419 5.78057C6.76186 4.84269 8.01324 4.12558 9.36727 3.66996C9.80975 1.95681 11.4211 0.71582 13.2984 0.71582C15.3016 0.71582 16.9728 2.10516 17.2966 3.95489C21.3097 5.65269 23.9748 9.59461 23.9748 13.9028V18.0836C23.9748 19.1358 24.4863 20.1236 25.3754 20.7948C25.5642 20.9342 25.7177 21.1158 25.8238 21.3252C25.9298 21.5345 25.9854 21.7658 25.9862 22.0005V24.5628C25.9862 25.2109 25.557 25.7905 24.9186 26.0099C23.1736 26.6003 21.3876 27.0619 19.575 27.3911C18.8123 30.0812 16.2511 31.9857 13.2984 31.9857ZM10.8456 27.853C11.16 28.1884 11.5398 28.4557 11.9616 28.6384C12.3834 28.8212 12.8382 28.9155 13.2979 28.9155C13.7576 28.9155 14.2124 28.8212 14.6342 28.6384C15.056 28.4557 15.4359 28.1884 15.7503 27.853C14.8699 27.9231 14.0755 27.9553 13.3153 27.9553C12.5357 27.9584 11.7301 27.9246 10.8456 27.853Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2985 31.9709C10.3428 31.9709 7.78355 30.0613 7.02596 27.3629C5.06224 27.0064 3.12989 26.4946 1.24711 25.8324C0.617912 25.613 0.195892 25.0344 0.195892 24.394V21.9115C0.196609 21.683 0.249355 21.4576 0.350129 21.2525C0.450904 21.0474 0.59706 20.868 0.777512 20.7278C1.62616 20.0571 2.11314 19.0878 2.11314 18.0678V13.5043C2.11314 10.566 3.41705 7.74541 5.69085 5.76678C6.76835 4.82865 8.01977 4.11152 9.37393 3.65617C9.81436 1.94302 11.4247 0.702026 13.3005 0.702026C15.3016 0.702026 16.9713 2.09137 17.2946 3.94109C21.304 5.63889 23.9671 9.58081 23.9671 13.889V18.0698C23.9671 19.122 24.4787 20.1098 25.3662 20.781C25.5549 20.9204 25.7084 21.1021 25.8144 21.3114C25.9203 21.5208 25.9758 21.752 25.9764 21.9867V24.549C25.9764 25.1971 25.5478 25.7767 24.9099 25.9961C23.1665 26.5866 21.382 27.0482 19.5709 27.3773C18.8072 30.0669 16.2485 31.9709 13.2985 31.9709ZM10.8477 27.8382C11.1616 28.1735 11.541 28.4408 11.9625 28.6235C12.3839 28.8063 12.8383 28.9006 13.2977 28.9006C13.757 28.9006 14.2115 28.8063 14.6329 28.6235C15.0543 28.4408 15.4338 28.1735 15.7477 27.8382C14.8684 27.9082 14.0745 27.9405 13.3153 27.9405C12.5363 27.944 11.7337 27.9103 10.8477 27.8387V27.8382Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.298 31.957C10.3454 31.957 7.78817 30.0469 7.03161 27.3491C5.06905 26.9927 3.13789 26.4811 1.25633 25.8191C0.62918 25.5986 0.207672 25.02 0.207672 24.3796V21.8971C0.208048 21.6688 0.260382 21.4435 0.360708 21.2385C0.461035 21.0334 0.606723 20.8538 0.786734 20.7134C1.63435 20.0422 2.12083 19.0734 2.12083 18.0534V13.4894C2.12083 10.5511 3.42372 7.73052 5.69546 5.75188C6.77125 4.81406 8.02094 4.09692 9.37343 3.64127C9.81336 1.92813 11.4196 0.687134 13.2959 0.687134C15.2955 0.687134 16.9637 2.07647 17.2859 3.9262C21.2949 5.62656 23.9554 9.56541 23.9554 13.8741V18.057C23.9554 19.1092 24.4669 20.097 25.3534 20.7681C25.5419 20.9077 25.6952 21.0895 25.801 21.2988C25.9069 21.5081 25.9624 21.7393 25.9632 21.9738V24.533C25.9632 25.1812 25.535 25.7607 24.8976 25.9802C23.1557 26.5706 21.3728 27.0322 19.5633 27.3613C18.8021 30.0526 16.2454 31.957 13.298 31.957ZM10.8497 27.8243C11.1633 28.1596 11.5423 28.4269 11.9634 28.6096C12.3845 28.7923 12.8387 28.8866 13.2977 28.8866C13.7567 28.8866 14.2109 28.7923 14.632 28.6096C15.0531 28.4269 15.4321 28.1596 15.7457 27.8243C14.8669 27.8944 14.074 27.9266 13.3154 27.9266C12.5368 27.9297 11.7337 27.8959 10.8497 27.8243Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2979 31.9427C10.3474 31.9427 7.79276 30.0326 7.03722 27.3348C5.07632 26.9785 3.14685 26.4669 1.26705 25.8048C0.640418 25.5843 0.219421 25.0057 0.219421 24.3653V21.8828C0.219994 21.6544 0.272519 21.4292 0.37302 21.2241C0.473522 21.019 0.619364 20.8395 0.799507 20.6991C1.64713 20.028 2.13258 19.0591 2.13258 18.0391V13.4751C2.13258 10.5369 3.43445 7.71623 5.70414 5.7376C6.77923 4.79961 8.02846 4.08244 9.38058 3.62699C9.8205 1.91385 11.4267 0.672852 13.3 0.672852C15.2976 0.672852 16.9641 2.06219 17.2874 3.91192C21.2892 5.60972 23.9477 9.55164 23.9477 13.8598V18.0406C23.9477 19.0929 24.4567 20.0806 25.3442 20.7518C25.5326 20.8914 25.6858 21.0732 25.7916 21.2825C25.8974 21.4918 25.9528 21.723 25.9534 21.9575V24.5198C25.9534 25.1679 25.5258 25.7475 24.8889 25.9669C23.1488 26.5573 21.3675 27.0189 19.5597 27.3481C18.797 30.0383 16.2429 31.9427 13.2979 31.9427ZM10.8518 27.81C11.1648 28.1453 11.5435 28.4126 11.9642 28.5954C12.3849 28.7782 12.8387 28.8725 13.2974 28.8725C13.7561 28.8725 14.21 28.7782 14.6307 28.5954C15.0514 28.4126 15.4301 28.1453 15.7431 27.81C14.8648 27.8801 14.0729 27.9123 13.3148 27.9123C12.5373 27.9154 11.7337 27.8816 10.8518 27.81Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2974 31.9283C10.35 31.9283 7.79788 30.0182 7.04234 27.3204C5.08314 26.964 3.15539 26.4524 1.27729 25.7904C0.651679 25.5699 0.230682 24.9913 0.230682 24.3489V21.8684C0.231255 21.64 0.28378 21.4148 0.384281 21.2097C0.484783 21.0046 0.630625 20.8251 0.810768 20.6847C1.65737 20.0136 2.14077 19.0447 2.14077 18.0247V13.4607C2.14077 10.5225 3.4411 7.70183 5.70876 5.7232C6.78215 4.78526 8.02986 4.06805 9.38058 3.61259C9.82 1.89944 11.4267 0.658447 13.2959 0.658447C15.2909 0.658447 16.957 2.04779 17.2798 3.89751C21.2785 5.59531 23.9344 9.53723 23.9344 13.8454V18.0262C23.9344 19.0785 24.4429 20.0662 25.3294 20.7374C25.5182 20.8771 25.6717 21.059 25.7777 21.2686C25.8837 21.4782 25.9391 21.7097 25.9396 21.9446V24.5023C25.9396 25.1504 25.5125 25.73 24.8761 25.9495C23.1376 26.5399 21.3579 27.0015 19.5515 27.3306C18.7929 30.0239 16.2398 31.9283 13.2974 31.9283ZM10.8538 27.7956C11.1664 28.1308 11.5447 28.3982 11.965 28.5809C12.3854 28.7637 12.8388 28.858 13.2972 28.858C13.7555 28.858 14.209 28.7637 14.6294 28.5809C15.0497 28.3982 15.4279 28.1308 15.7406 27.7956C14.8633 27.8657 14.0719 27.8979 13.3148 27.8979C12.5378 27.901 11.7337 27.8672 10.8538 27.7956Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2975 31.914C10.3525 31.914 7.8025 30.004 7.04798 27.3061C5.09044 26.9498 3.16438 26.4381 1.28805 25.7761C0.662947 25.5556 0.242462 24.9771 0.242462 24.3366V21.8541C0.243064 21.6258 0.295498 21.4007 0.395807 21.1956C0.496117 20.9906 0.641683 20.811 0.821525 20.6704C1.66761 19.9993 2.15153 19.0304 2.15153 18.0104V13.4465C2.15153 10.5082 3.45084 7.68755 5.71644 5.70891C6.78845 4.7712 8.03479 4.05399 9.38418 3.5983C9.8241 1.88516 11.4268 0.644165 13.2975 0.644165C15.2925 0.644165 16.9555 2.03351 17.2777 3.88323C21.2724 5.58103 23.9278 9.52295 23.9278 13.8311V18.0119C23.9278 19.0642 24.4357 20.052 25.3217 20.7231C25.5099 20.8629 25.6629 21.0447 25.7685 21.254C25.8741 21.4633 25.9293 21.6944 25.9299 21.9288V24.4911C25.9299 25.1392 25.5028 25.7188 24.8669 25.9382C23.1299 26.5286 21.3518 26.9902 19.5469 27.3194C18.7863 30.0096 16.2352 31.914 13.2975 31.914ZM10.8559 27.7813C11.1681 28.1165 11.546 28.3838 11.966 28.5666C12.386 28.7494 12.8391 28.8437 13.2972 28.8437C13.7553 28.8437 14.2084 28.7494 14.6284 28.5666C15.0484 28.3838 15.4263 28.1165 15.7385 27.7813C14.8617 27.8514 14.0709 27.8836 13.3143 27.8836C12.5383 27.8867 11.7337 27.8529 10.8559 27.7813Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2974 31.8998C10.3551 31.8998 7.80708 29.9897 7.05307 27.2918C5.09722 26.9354 3.17286 26.4238 1.29826 25.7618C0.673666 25.5413 0.253693 24.9628 0.253693 24.3223V21.8424C0.253907 21.6137 0.306155 21.388 0.406479 21.1825C0.506803 20.977 0.652574 20.797 0.832755 20.6561C1.67782 19.985 2.16276 19.0161 2.16276 17.9961V13.4322C2.16276 10.4939 3.46104 7.67327 5.7246 5.69463C6.7955 4.75698 8.04082 4.03975 9.38927 3.58402C9.82766 1.87088 11.4303 0.629883 13.2979 0.629883C15.2899 0.629883 16.9524 2.01922 17.2741 3.86895C21.2641 5.56675 23.917 9.50867 23.917 13.8169V17.9977C23.917 19.0499 24.4244 20.0377 25.3094 20.7088C25.4975 20.8487 25.6503 21.0305 25.7558 21.2398C25.8613 21.4491 25.9165 21.6801 25.9171 21.9145V24.4768C25.9171 25.1249 25.4905 25.7045 24.8557 25.924C23.1203 26.5142 21.3439 26.9759 19.5408 27.3051C18.7811 29.9953 16.2352 31.8998 13.2974 31.8998ZM10.8579 27.767C11.1696 28.1022 11.5471 28.3696 11.9667 28.5524C12.3864 28.7352 12.8392 28.8296 13.2969 28.8296C13.7547 28.8296 14.2075 28.7352 14.6271 28.5524C15.0468 28.3696 15.4242 28.1022 15.7359 27.767C14.8602 27.8371 14.0704 27.8693 13.3143 27.8693C12.5388 27.8724 11.7372 27.8387 10.8579 27.767Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2969 31.8855C10.3576 31.8855 7.81221 29.9754 7.05871 27.2775C5.10469 26.9211 3.1822 26.4095 1.30952 25.7475C1.0082 25.6447 0.746088 25.4511 0.559156 25.1934C0.372224 24.9356 0.269636 24.6264 0.265472 24.308V21.8255C0.26599 21.5973 0.318282 21.3723 0.418409 21.1672C0.518536 20.9622 0.663886 20.7825 0.843512 20.6418C1.68806 19.9707 2.17351 19.0018 2.17351 17.9818V13.4179C2.17351 10.4796 3.47078 7.65896 5.73229 5.68032C6.80196 4.74284 8.04608 4.02561 9.39338 3.56971C9.83023 1.85657 11.4314 0.613525 13.2969 0.613525C15.2873 0.613525 16.9483 2.00287 17.2701 3.85259C21.258 5.55244 23.9083 9.49436 23.9083 13.8025V17.9834C23.9083 19.0356 24.4152 20.0234 25.2997 20.6945C25.4868 20.8348 25.6388 21.0166 25.7435 21.2257C25.8483 21.4348 25.903 21.6653 25.9033 21.8992V24.4615C25.9033 25.1096 25.4767 25.6892 24.8424 25.9086C23.1087 26.499 21.3337 26.9606 19.5321 27.2898C18.776 29.981 16.2311 31.8855 13.2969 31.8855ZM10.86 27.7527C11.1712 28.0879 11.5482 28.3553 11.9675 28.5382C12.3868 28.721 12.8393 28.8154 13.2967 28.8154C13.7541 28.8154 14.2066 28.721 14.6259 28.5382C15.0451 28.3553 15.4222 28.0879 15.7334 27.7527C14.8587 27.8228 14.0694 27.855 13.3143 27.855C12.5393 27.8581 11.7383 27.8238 10.86 27.7527Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2969 31.8711C10.3597 31.8711 7.8168 29.9611 7.06433 27.2632C5.11181 26.9068 3.19085 26.3952 1.31974 25.7332C1.01832 25.6304 0.756098 25.4369 0.569069 25.1792C0.38204 24.9214 0.279378 24.6121 0.275177 24.2937V21.8112C0.275596 21.5831 0.327793 21.358 0.427832 21.153C0.527872 20.9479 0.673145 20.7683 0.852705 20.6275C1.69623 19.9564 2.17964 18.9875 2.17964 17.9675V13.402C2.17964 10.4637 3.47588 7.64311 5.73535 5.66448C6.80389 4.72705 8.047 4.0098 9.39336 3.55387C9.83073 1.84073 11.4308 0.599731 13.2949 0.599731C15.2832 0.599731 16.9427 1.98907 17.2644 3.8388C21.2483 5.53813 23.897 9.48005 23.897 13.7882V17.969C23.897 19.0213 24.4035 20.0091 25.2869 20.6802C25.4748 20.8202 25.6274 21.0021 25.7327 21.2114C25.838 21.4207 25.8931 21.6516 25.8936 21.8859V24.4482C25.8896 24.7697 25.7853 25.082 25.5953 25.3414C25.4053 25.6009 25.139 25.7945 24.8337 25.8953C23.1017 26.4856 21.3285 26.9473 19.5285 27.2765C18.7709 29.9667 16.2285 31.8711 13.2969 31.8711ZM10.864 27.7384C11.1749 28.0735 11.5516 28.3409 11.9705 28.5237C12.3895 28.7065 12.8416 28.8008 13.2987 28.8008C13.7558 28.8008 14.208 28.7065 14.6269 28.5237C15.0458 28.3409 15.4225 28.0735 15.7334 27.7384C14.8592 27.8085 14.0704 27.8407 13.3158 27.8407C12.5393 27.8438 11.7398 27.8095 10.864 27.7384Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2969 31.8568C10.3622 31.8568 7.82346 29.9467 7.06945 27.2488C5.11879 26.8924 3.1997 26.3808 1.33049 25.7188C1.02955 25.6157 0.767876 25.422 0.581332 25.1643C0.394787 24.9066 0.292498 24.5975 0.288483 24.2794V21.7969C0.289148 21.569 0.34141 21.3443 0.441345 21.1396C0.54128 20.9348 0.686293 20.7554 0.865499 20.6147C1.70749 19.942 2.19192 18.9732 2.19192 17.9547V13.3892C2.19192 10.4509 3.48663 7.6303 5.74405 5.65166C6.81137 4.71441 8.05327 3.99715 9.39849 3.54105C9.83585 1.82791 11.4344 0.586914 13.2969 0.586914C15.2837 0.586914 16.9411 1.97625 17.2624 3.82598C21.2432 5.52429 23.8873 9.4657 23.8873 13.7739V17.9547C23.8873 19.0069 24.3932 19.9947 25.2761 20.6658C25.4639 20.806 25.6164 20.9879 25.7216 21.1971C25.8268 21.4064 25.8818 21.6373 25.8823 21.8715V24.4338C25.8784 24.7553 25.7742 25.0674 25.5844 25.3268C25.3946 25.5863 25.1286 25.78 24.8234 25.881C23.0928 26.4713 21.321 26.933 19.5224 27.2621C18.7658 29.9523 16.2255 31.8568 13.2969 31.8568ZM10.864 27.7251C11.1745 28.0602 11.5508 28.3275 11.9694 28.5103C12.388 28.6931 12.8399 28.7875 13.2967 28.7875C13.7535 28.7875 14.2053 28.6931 14.6239 28.5103C15.0425 28.3275 15.4188 28.0602 15.7293 27.7251C14.8561 27.7952 14.0683 27.8274 13.3143 27.8274C12.5398 27.8274 11.7408 27.7952 10.864 27.7251Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2964 31.8424C10.3648 31.8424 7.82603 29.9323 7.07509 27.2344C5.12609 26.8781 3.2087 26.3664 1.34124 25.7044C1.0405 25.6011 0.779045 25.4073 0.592694 25.1496C0.406343 24.8919 0.304203 24.5829 0.300262 24.265V21.7825C0.300598 21.5544 0.352652 21.3294 0.452509 21.1244C0.552366 20.9193 0.697424 20.7396 0.876767 20.5988C1.71876 19.9276 2.20165 18.9588 2.20165 17.9388V13.3748C2.20165 10.435 3.49789 7.61589 5.74867 5.63675C6.81585 4.70058 8.05741 3.98436 9.40208 3.52921C9.84098 1.8135 11.436 0.57251 13.2964 0.57251C15.2817 0.57251 16.9381 1.96185 17.2588 3.81158C21.236 5.50937 23.8776 9.4513 23.8776 13.76V17.9408C23.8776 18.993 24.3835 19.9808 25.2654 20.652C25.4529 20.7922 25.6052 20.9742 25.7102 21.1834C25.8153 21.3927 25.8702 21.6235 25.8706 21.8577V24.42C25.8668 24.7413 25.7628 25.0534 25.5732 25.3128C25.3836 25.5722 25.1177 25.766 24.8127 25.8671C23.0838 26.4574 21.3137 26.919 19.5167 27.2483C18.7607 29.9379 16.2229 31.8424 13.2964 31.8424ZM10.8641 27.7097C11.174 28.0446 11.5499 28.3118 11.968 28.4946C12.3861 28.6773 12.8375 28.7717 13.2939 28.7717C13.7502 28.7717 14.2016 28.6773 14.6197 28.4946C15.0379 28.3118 15.4137 28.0446 15.7237 27.7097C14.8515 27.7797 14.0642 27.812 13.3108 27.812C12.5404 27.815 11.7419 27.7808 10.8641 27.7097Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2964 31.8281C10.3674 31.8281 7.83115 29.918 7.08021 27.2202C5.13222 26.8637 3.21585 26.3521 1.34944 25.6901C1.04922 25.5864 0.788391 25.3924 0.60261 25.1347C0.416829 24.8771 0.315167 24.5683 0.311523 24.2507V21.7682C0.312168 21.5401 0.364463 21.3151 0.464486 21.1101C0.564509 20.9051 0.709661 20.7254 0.889051 20.5845C1.73053 19.9133 2.21291 18.9445 2.21291 17.9245V13.3605C2.21291 10.4222 3.50506 7.60161 5.7589 5.62247C6.82404 4.68579 8.06369 3.9687 9.40669 3.51237C9.84354 1.79922 11.439 0.558228 13.298 0.558228C15.2812 0.558228 16.936 1.94757 17.2568 3.79729C21.2299 5.49509 23.8694 9.43701 23.8694 13.7452V17.926C23.8694 18.9782 24.3748 19.966 25.2557 20.6372C25.4429 20.7775 25.5948 20.9595 25.6996 21.1686C25.8043 21.3778 25.859 21.6084 25.8593 21.8424V24.4046C25.8555 24.7259 25.7516 25.0379 25.5621 25.2973C25.3725 25.5567 25.1068 25.7505 24.802 25.8518C23.0742 26.4425 21.3051 26.9045 19.5091 27.234C18.7556 29.9247 16.2199 31.8281 13.2964 31.8281ZM10.8676 27.6954C11.1772 28.0304 11.5527 28.2978 11.9706 28.4806C12.3885 28.6635 12.8398 28.7579 13.2959 28.7579C13.7521 28.7579 14.2033 28.6635 14.6212 28.4806C15.0391 28.2978 15.4146 28.0304 15.7242 27.6954C14.8546 27.7655 14.0658 27.7977 13.3133 27.7977C12.5409 27.8008 11.7434 27.7665 10.8676 27.6954Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2959 31.8138C10.3699 31.8138 7.83573 29.9037 7.08581 27.2059C5.14017 26.8494 3.22619 26.3378 1.3622 25.6759C1.06174 25.5723 0.80062 25.3784 0.614559 25.1207C0.428499 24.863 0.326583 24.5542 0.322754 24.2364V21.7539C0.323215 21.5259 0.375274 21.301 0.475026 21.096C0.574779 20.891 0.719638 20.7112 0.898747 20.5702C1.73921 19.8985 2.21903 18.9302 2.21903 17.9117V13.3462C2.21903 10.408 3.51015 7.58733 5.76144 5.60818C6.82565 4.67155 8.06445 3.95445 9.40667 3.49808C9.8425 1.78494 11.437 0.543945 13.2944 0.543945C15.2761 0.543945 16.9294 1.93329 17.2496 3.78301C21.2212 5.48081 23.8571 9.42273 23.8571 13.7309V17.9117C23.8571 18.964 24.362 19.9517 25.2424 20.6229C25.4297 20.7633 25.5817 20.9453 25.6866 21.1545C25.7914 21.3638 25.8462 21.5945 25.8465 21.8286V24.3899C25.8429 24.7109 25.7393 25.0229 25.5501 25.2823C25.3608 25.5417 25.0954 25.7356 24.7907 25.837C23.0651 26.4272 21.2982 26.8888 19.5045 27.2182C18.751 29.9094 16.2173 31.8138 13.2959 31.8138ZM10.8697 27.6811C11.1788 28.0161 11.5539 28.2834 11.9714 28.4663C12.389 28.6491 12.8398 28.7435 13.2956 28.7435C13.7515 28.7435 14.2023 28.6491 14.6199 28.4663C15.0374 28.2834 15.4125 28.0161 15.7216 27.6811C14.852 27.7512 14.0647 27.7834 13.3133 27.7834C12.5414 27.7865 11.7444 27.7522 10.8697 27.6811Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2959 31.7994C10.372 31.7994 7.84035 29.8893 7.09145 27.1915C5.14732 26.835 3.23487 26.3234 1.37245 25.6615C1.07223 25.5577 0.811401 25.3637 0.62562 25.106C0.43984 24.8484 0.338177 24.5396 0.334534 24.222V21.74C0.334833 21.512 0.386713 21.2871 0.486285 21.082C0.585858 20.8769 0.730543 20.697 0.909504 20.5558C1.74945 19.8847 2.23081 18.9158 2.23081 17.8958V13.3318C2.23081 10.393 3.52091 7.57292 5.77015 5.59378C6.83313 4.65732 8.07073 3.94021 9.4118 3.48368C9.84763 1.77053 11.4406 0.529541 13.2959 0.529541C15.2756 0.529541 16.9278 1.91888 17.2476 3.76861C21.214 5.46641 23.8464 9.40833 23.8464 13.7165V17.8973C23.8464 18.9496 24.3508 19.9373 25.2301 20.6085C25.4174 20.7489 25.5695 20.9309 25.6743 21.1401C25.7792 21.3494 25.8339 21.5801 25.8343 21.8142V24.3765C25.8306 24.6975 25.7271 25.0094 25.5379 25.2687C25.3488 25.5281 25.0835 25.7221 24.7789 25.8236C23.0549 26.4138 21.2896 26.8755 19.4973 27.2048C18.7459 29.895 16.2142 31.7994 13.2959 31.7994ZM10.8717 27.6662C11.1803 28.0012 11.5551 28.2686 11.9722 28.4515C12.3894 28.6344 12.8399 28.7288 13.2954 28.7288C13.7509 28.7288 14.2014 28.6344 14.6186 28.4515C15.0358 28.2686 15.4105 28.0012 15.7191 27.6662C14.8495 27.7363 14.0642 27.7685 13.3148 27.7685C12.5419 27.7721 11.7454 27.7378 10.8717 27.6662Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2959 31.7851C10.3745 31.7851 7.84496 29.8751 7.09658 27.1772C5.1543 26.8206 3.24372 26.309 1.3832 25.6472C1.08299 25.5433 0.822217 25.3492 0.636526 25.0914C0.450835 24.8337 0.349296 24.5249 0.345795 24.2072V21.7252C0.346172 21.4973 0.398088 21.2725 0.497658 21.0675C0.597228 20.8625 0.741872 20.6827 0.920765 20.5415C1.75969 19.8704 2.24054 18.9015 2.24054 17.8815V13.3176C2.24054 10.3788 3.52962 7.55864 5.77681 5.5795C6.83867 4.64309 8.07526 3.92596 9.41539 3.4694C9.85071 1.75625 11.4421 0.515259 13.2959 0.515259C15.2735 0.515259 16.9243 1.9046 17.244 3.75433C21.2064 5.45212 23.8387 9.39405 23.8387 13.7022V17.883C23.8387 18.9353 24.3426 19.9231 25.2199 20.5942C25.407 20.7347 25.5588 20.9168 25.6635 21.126C25.7681 21.3352 25.8227 21.5659 25.823 21.7999V24.3622C25.8195 24.683 25.7161 24.9947 25.5273 25.2541C25.3384 25.5134 25.0735 25.7075 24.7692 25.8093C23.0468 26.3996 21.2829 26.8612 19.4922 27.1905C18.7418 29.8807 16.2117 31.7851 13.2959 31.7851ZM10.8738 27.6519C11.1819 27.9869 11.5563 28.2544 11.9731 28.4373C12.3899 28.6203 12.8402 28.7147 13.2954 28.7147C13.7506 28.7147 14.2009 28.6203 14.6177 28.4373C15.0345 28.2544 15.4089 27.9869 15.717 27.6519C14.8474 27.722 14.0632 27.7542 13.3128 27.7542C12.5424 27.7578 11.747 27.7251 10.8738 27.6519Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2954 31.7709C10.3796 31.7709 7.85006 29.8608 7.10219 27.1629C5.16157 26.8064 3.25269 26.2948 1.39392 25.6329C1.09428 25.529 0.833968 25.3353 0.648496 25.078C0.463024 24.8208 0.361421 24.5126 0.357544 24.1955V21.7109C0.357799 21.4831 0.409557 21.2583 0.508947 21.0533C0.608336 20.8484 0.752786 20.6685 0.931491 20.5272C1.7699 19.8561 2.25024 18.8872 2.25024 17.8672V13.3033C2.25024 10.3645 3.53829 7.54436 5.78344 5.56521C6.84407 4.62898 8.07946 3.91185 9.41844 3.45512C9.85325 1.74197 11.4436 0.500977 13.2954 0.500977C15.2715 0.500977 16.9207 1.89032 17.2399 3.74004C21.1971 5.43784 23.829 9.37976 23.829 13.6879V17.8688C23.829 18.921 24.3324 19.9088 25.2102 20.5799C25.3971 20.7205 25.5488 20.9026 25.6533 21.1119C25.7579 21.3211 25.8125 21.5517 25.8128 21.7856V24.3489C25.8094 24.6697 25.7061 24.9814 25.5173 25.2408C25.3286 25.5001 25.0637 25.6942 24.7595 25.7961C23.0388 26.3862 21.2766 26.8479 19.4876 27.1772C18.7356 29.8664 16.2086 31.7709 13.2954 31.7709ZM10.8758 27.6376C11.1833 27.9728 11.5571 28.2404 11.9735 28.4236C12.3899 28.6067 12.8397 28.7014 13.2946 28.7017C13.7494 28.7019 14.1994 28.6077 14.616 28.425C15.0325 28.2422 15.4066 27.975 15.7145 27.6402C14.8448 27.7103 14.0622 27.7425 13.3102 27.7425C12.5429 27.7435 11.748 27.7092 10.8758 27.6376Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2954 31.7565C10.3796 31.7565 7.85467 29.8464 7.10578 27.1485C5.16736 26.7919 3.2607 26.2802 1.40416 25.6185C1.10434 25.5142 0.844005 25.32 0.658701 25.0623C0.473397 24.8046 0.372157 24.4959 0.368805 24.1785V21.6965C0.368752 21.4689 0.420109 21.2441 0.519047 21.0391C0.617984 20.834 0.761947 20.6539 0.940194 20.5123C1.77963 19.8417 2.25997 18.8754 2.25997 17.8523V13.2889C2.25997 10.3501 3.547 7.52996 5.78959 5.55081C6.84911 4.61464 8.08348 3.89749 9.42151 3.44071C9.85581 1.72757 11.4441 0.486572 13.2949 0.486572C15.2694 0.486572 16.9166 1.87591 17.2337 3.72564C21.192 5.4219 23.8193 9.36536 23.8193 13.6735V17.8523C23.8193 18.9045 24.3227 19.8923 25.2005 20.5635C25.3871 20.7044 25.5385 20.8868 25.6427 21.0962C25.7469 21.3056 25.8011 21.5363 25.801 21.7702V24.3325C25.798 24.6532 25.6952 24.9651 25.5068 25.2247C25.3184 25.4843 25.0538 25.6788 24.7498 25.7812C23.0301 26.3714 21.269 26.833 19.4809 27.1623C18.7305 29.852 16.2061 31.7565 13.2954 31.7565ZM10.8779 27.6227C11.1851 27.9576 11.5586 28.2251 11.9747 28.408C12.3908 28.5909 12.8404 28.6854 13.2949 28.6854C13.7494 28.6854 14.199 28.5909 14.6151 28.408C15.0311 28.2251 15.4047 27.9576 15.7119 27.6227C14.8423 27.6928 14.0612 27.725 13.3123 27.725C12.5434 27.7291 11.749 27.6948 10.8779 27.6227Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2954 31.7421C10.3796 31.7421 7.85928 29.8321 7.11294 27.1342C5.17569 26.7776 3.27021 26.266 1.41491 25.6042C1.11515 25.4999 0.854879 25.3057 0.669661 25.0479C0.484443 24.7902 0.383307 24.4816 0.380066 24.1642V21.6822C0.380334 21.4545 0.432046 21.2297 0.53134 21.0248C0.630635 20.8198 0.774946 20.6399 0.953501 20.4985C1.78987 19.8274 2.2702 18.8585 2.2702 17.84V13.2746C2.2702 10.3358 3.5557 7.51565 5.79727 5.5365C6.85537 4.60064 8.08838 3.88365 9.4251 3.42692C9.8594 1.71377 11.4462 0.472778 13.2954 0.472778C15.2679 0.472778 16.9135 1.86212 17.2343 3.71184C21.1849 5.40913 23.8096 9.35105 23.8096 13.6577V17.8385C23.8096 18.8907 24.3124 19.8785 25.1907 20.5497C25.3774 20.6904 25.5289 20.8726 25.6333 21.0818C25.7377 21.291 25.7921 21.5216 25.7923 21.7554V24.3182C25.789 24.6387 25.686 24.9502 25.4976 25.2095C25.3093 25.4688 25.0449 25.6631 24.7411 25.7653C23.0236 26.3558 21.2645 26.8175 19.4784 27.1465C18.7254 29.8377 16.203 31.7421 13.2954 31.7421ZM10.8799 27.6089C11.1866 27.944 11.5598 28.2116 11.9755 28.3947C12.3913 28.5778 12.8406 28.6723 13.2949 28.6723C13.7492 28.6723 14.1985 28.5778 14.6143 28.3947C15.03 28.2116 15.4031 27.944 15.7099 27.6089C14.8403 27.679 14.0607 27.7112 13.3123 27.7112C12.544 27.7148 11.7506 27.6805 10.8799 27.6089Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2949 31.7279C10.3842 31.7279 7.86387 29.8178 7.11804 27.12C5.18248 26.7633 3.27871 26.2517 1.42512 25.59C1.12565 25.4854 0.865711 25.2911 0.680783 25.0333C0.495856 24.7756 0.394944 24.4672 0.391815 24.15V21.668C0.391998 21.4403 0.443568 21.2156 0.54268 21.0107C0.641791 20.8057 0.785888 20.6258 0.964228 20.4843C1.80008 19.8131 2.27939 18.8443 2.27939 17.8243V13.2603C2.27939 10.3215 3.56387 7.50142 5.80288 5.52227C6.86011 4.58642 8.09225 3.86925 9.42815 3.41218C9.86194 1.69903 11.4477 0.460083 13.2949 0.460083C15.2658 0.460083 16.9104 1.84942 17.2291 3.69915C21.1772 5.39695 23.8004 9.33887 23.8004 13.6471V17.8279C23.8004 18.8801 24.3022 19.8679 25.1779 20.539C25.3639 20.6794 25.5148 20.8609 25.6191 21.0692C25.7233 21.2775 25.7781 21.5071 25.779 21.7401V24.3024C25.776 24.6233 25.673 24.9353 25.4844 25.1949C25.2958 25.4546 25.031 25.649 24.7268 25.7511C23.0108 26.3414 21.2532 26.8031 19.4686 27.1322C18.7203 29.8224 16.2004 31.7279 13.2949 31.7279ZM10.882 27.5947C11.1883 27.9295 11.5611 28.1969 11.9765 28.3799C12.3918 28.5628 12.8407 28.6573 13.2946 28.6573C13.7485 28.6573 14.1974 28.5628 14.6128 28.3799C15.0282 28.1969 15.4009 27.9295 15.7073 27.5947C14.8413 27.6648 14.0596 27.697 13.3118 27.697C12.5445 27.7006 11.7516 27.6663 10.882 27.5947Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2949 31.715C10.3868 31.715 7.86848 29.8049 7.12368 27.1071C5.18975 26.7503 3.28768 26.2385 1.43587 25.5765C1.13633 25.4719 0.876388 25.2773 0.691543 25.0194C0.506698 24.7615 0.405957 24.4528 0.403076 24.1355V21.6536C0.403272 21.4259 0.454796 21.2013 0.553812 20.9964C0.652828 20.7914 0.796788 20.6115 0.974977 20.4699C1.81032 19.7987 2.28912 18.8299 2.28912 17.8098V13.2485C2.28912 10.3097 3.57257 7.48954 5.80953 5.5104C6.86545 4.57372 8.09658 3.85565 9.43174 3.39774C9.86502 1.6846 11.4493 0.443604 13.2949 0.443604C15.2633 0.443604 16.9069 1.83294 17.225 3.68267C21.17 5.38047 23.7907 9.32239 23.7907 13.6306V17.8114C23.7907 18.8636 24.292 19.8514 25.1672 20.5225C25.3536 20.6635 25.5048 20.8457 25.609 21.0549C25.7132 21.2641 25.7675 21.4945 25.7677 21.7282V24.2905C25.7645 24.6108 25.6618 24.9222 25.4737 25.1815C25.2856 25.4408 25.0215 25.6352 24.7181 25.7377C23.0039 26.3281 21.248 26.7897 19.4651 27.1188C18.7152 29.809 16.1974 31.715 13.2949 31.715ZM10.8835 27.5818C11.1893 27.9168 11.5616 28.1844 11.9766 28.3676C12.3916 28.5507 12.8402 28.6455 13.2938 28.6457C13.7474 28.6459 14.1961 28.5517 14.6113 28.369C15.0265 28.1862 15.3991 27.919 15.7053 27.5843C14.8397 27.6544 14.0586 27.6866 13.3118 27.6866C12.545 27.6861 11.7526 27.6519 10.8835 27.5802V27.5818Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2949 31.6992C10.3894 31.6992 7.87258 29.7891 7.1288 27.0913C5.19658 26.7346 3.29621 26.2229 1.44611 25.5612C1.14696 25.4564 0.887398 25.2619 0.702771 25.0042C0.518143 24.7466 0.417427 24.4382 0.414337 24.1213V21.6377C0.414764 21.4104 0.466398 21.1861 0.565406 20.9814C0.664413 20.7767 0.808251 20.597 0.986238 20.4556C1.82056 19.7844 2.29885 18.8156 2.29885 17.7956V13.2316C2.29885 10.2928 3.58128 7.4727 5.81619 5.49356C6.8711 4.55797 8.10102 3.84078 9.43482 3.38346C9.86809 1.67032 11.4508 0.429321 13.2949 0.429321C15.2618 0.429321 16.9033 1.81866 17.2215 3.66839C21.1629 5.36619 23.7809 9.30964 23.7809 13.6163V17.7971C23.7809 18.8493 24.2822 19.8371 25.156 20.5083C25.3423 20.6493 25.4934 20.8315 25.5975 21.0407C25.7016 21.2499 25.7559 21.4803 25.756 21.714V24.2763C25.753 24.5964 25.6506 24.9077 25.4627 25.167C25.2749 25.4263 25.0111 25.6208 24.7078 25.7234C22.9952 26.3137 21.2408 26.7754 19.4595 27.1046C18.71 29.7947 16.1948 31.6992 13.2949 31.6992ZM10.8855 27.566C11.1909 27.901 11.5628 28.1686 11.9774 28.3519C12.392 28.5351 12.8403 28.6298 13.2936 28.6301C13.7469 28.6303 14.1952 28.536 14.61 28.3533C15.0249 28.1705 15.397 27.9032 15.7027 27.5685C14.8382 27.6386 14.0581 27.6708 13.3113 27.6708C12.5455 27.6739 11.7541 27.6376 10.8855 27.566Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2944 31.6849C10.3914 31.6849 7.8782 29.7748 7.13442 27.077C5.20366 26.7205 3.30481 26.2088 1.45633 25.547C1.15795 25.4414 0.899265 25.2466 0.715411 24.989C0.531556 24.7314 0.431438 24.4235 0.42865 24.107V21.625C0.428761 21.3975 0.480142 21.1729 0.578976 20.968C0.677809 20.763 0.821554 20.583 0.999528 20.4413C1.83334 19.7701 2.31111 18.8013 2.31111 17.7813V13.2173C2.31111 10.2785 3.58996 7.45842 5.82539 5.47928C6.87918 4.54374 8.10808 3.82654 9.44095 3.36918C9.8732 1.65603 11.4549 0.415039 13.2969 0.415039C15.2623 0.415039 16.9028 1.80438 17.2204 3.65411C21.1593 5.3519 23.7738 9.29383 23.7738 13.602V17.7828C23.7738 18.8351 24.2746 19.8228 25.1478 20.494C25.334 20.6351 25.485 20.8173 25.589 21.0265C25.693 21.2357 25.7472 21.4661 25.7473 21.6997V24.262C25.7444 24.5821 25.642 24.8933 25.4542 25.1526C25.2665 25.4119 25.0028 25.6064 24.6996 25.7091C22.9886 26.2995 21.2358 26.7612 19.4559 27.0903C18.7049 29.7805 16.1917 31.6849 13.2944 31.6849ZM10.8901 27.5517C11.1952 27.8865 11.5668 28.1539 11.9812 28.337C12.3955 28.52 12.8435 28.6145 13.2964 28.6145C13.7494 28.6145 14.1973 28.52 14.6117 28.337C15.026 28.1539 15.3976 27.8865 15.7027 27.5517C14.8392 27.6218 14.0596 27.654 13.3138 27.654C12.546 27.6576 11.7552 27.6228 10.8876 27.5517H10.8901Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2944 31.6705C10.3939 31.6705 7.8828 29.7604 7.13953 27.0626C5.21066 26.7059 3.31369 26.1942 1.46708 25.5326C1.16835 25.4275 0.909242 25.2329 0.724999 24.9754C0.540755 24.7178 0.440317 24.4097 0.437347 24.0931V21.6106C0.43736 21.3831 0.488646 21.1586 0.587392 20.9536C0.686138 20.7487 0.829806 20.5686 1.00771 20.4269C1.8405 19.7557 2.31828 18.7869 2.31828 17.7669V13.2029C2.31828 10.2641 3.59713 7.44402 5.82948 5.46487C6.88199 4.52943 8.1097 3.8122 9.44146 3.35477C9.87371 1.64163 11.4539 0.400635 13.2944 0.400635C15.2582 0.400635 16.8966 1.78998 17.2138 3.6397C21.148 5.3375 23.7615 9.27942 23.7615 13.5876V17.7684C23.7615 18.8207 24.2618 19.8084 25.1345 20.4796C25.3205 20.6209 25.4713 20.8033 25.5752 21.0125C25.6791 21.2218 25.7333 21.4522 25.7335 21.6858V24.2465C25.7306 24.5665 25.6284 24.8777 25.4409 25.137C25.2533 25.3962 24.9898 25.5908 24.6869 25.6937C22.9773 26.284 21.226 26.7456 19.4477 27.0748C18.6998 29.7661 16.1892 31.6705 13.2944 31.6705ZM10.8901 27.5373C11.1948 27.8721 11.566 28.1395 11.98 28.3225C12.3941 28.5055 12.8417 28.6001 13.2944 28.6001C13.747 28.6001 14.1947 28.5055 14.6087 28.3225C15.0227 28.1395 15.3939 27.8721 15.6986 27.5373C14.8356 27.6074 14.0566 27.6396 13.3118 27.6396C12.5465 27.6432 11.7562 27.6089 10.8896 27.5373H10.8901Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2939 31.6557C10.3965 31.6557 7.88741 29.7461 7.14466 27.0483C5.21747 26.6915 3.3222 26.1799 1.47731 25.5183C1.17874 25.4129 0.919835 25.2182 0.735787 24.9606C0.55174 24.7029 0.451472 24.3949 0.448608 24.0783V21.5963C0.448634 21.3689 0.499873 21.1444 0.598524 20.9395C0.697175 20.7345 0.840705 20.5545 1.01846 20.4126C1.85074 19.7415 2.328 18.7726 2.328 17.7526V13.1886C2.328 10.2499 3.60685 7.42974 5.83563 5.45059C6.88719 4.51518 8.11407 3.79794 9.44505 3.34049C9.87679 1.62735 11.4554 0.386353 13.2939 0.386353C15.2561 0.386353 16.8931 1.77569 17.2102 3.62542C21.1409 5.32322 23.7548 9.26514 23.7548 13.5733V17.7541C23.7548 18.8064 24.2546 19.7941 25.1263 20.4653C25.3121 20.6067 25.4628 20.7892 25.5666 20.9984C25.6704 21.2076 25.7246 21.4379 25.7248 21.6715V24.2338C25.7213 24.5537 25.6185 24.8647 25.4305 25.1236C25.2426 25.3825 24.9788 25.5765 24.6756 25.6789C22.9678 26.2692 21.2183 26.7309 19.4415 27.0601C18.6947 29.7518 16.1866 31.6557 13.2939 31.6557ZM10.8896 27.523C11.1938 27.8578 11.5646 28.1253 11.9782 28.3084C12.3919 28.4914 12.8392 28.586 13.2916 28.586C13.7439 28.586 14.1912 28.4914 14.6049 28.3084C15.0185 28.1253 15.3893 27.8578 15.6935 27.523C14.831 27.5936 14.053 27.6253 13.3087 27.6253C12.547 27.6289 11.7577 27.5946 10.8917 27.523H10.8896Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2939 31.6414C10.3991 31.6414 7.89202 29.7318 7.15029 27.034C5.22476 26.6773 3.33119 26.1656 1.48806 25.5039C1.18954 25.3986 0.930708 25.2038 0.746747 24.9462C0.562786 24.6886 0.462622 24.3805 0.459869 24.064V21.582C0.459895 21.3545 0.511134 21.13 0.609785 20.9251C0.708436 20.7202 0.851966 20.5401 1.02972 20.3983C1.86098 19.7271 2.33773 18.7583 2.33773 17.7383V13.1743C2.33773 10.2355 3.61658 7.4154 5.84229 5.43626C6.89249 4.50047 8.11817 3.7827 9.44813 3.32462C9.87935 1.61148 11.4564 0.370483 13.2939 0.370483C15.2541 0.370483 16.8895 1.75982 17.2066 3.60955C21.1337 5.30735 23.7426 9.24927 23.7426 13.5575V17.7398C23.7426 18.792 24.2418 19.7798 25.113 20.451C25.2986 20.5925 25.4491 20.775 25.5527 20.9842C25.6563 21.1934 25.7103 21.4237 25.7105 21.6572V24.2195C25.7078 24.5392 25.6059 24.8502 25.4188 25.1095C25.2316 25.3687 24.9685 25.5634 24.6659 25.6666C22.9597 26.2569 21.2116 26.7186 19.4364 27.0478C18.6906 29.7374 16.1841 31.6414 13.2939 31.6414ZM10.8937 27.5087C11.1975 27.8434 11.5679 28.1109 11.9812 28.294C12.3945 28.4771 12.8416 28.5716 13.2936 28.5716C13.7457 28.5716 14.1927 28.4771 14.606 28.294C15.0193 28.1109 15.3897 27.8434 15.6935 27.5087C14.8316 27.5793 14.0566 27.611 13.3108 27.611C12.5475 27.6146 11.7587 27.5803 10.8937 27.5087Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2939 31.627C10.4037 31.627 7.89712 29.72 7.15539 27.0196C5.23155 26.6628 3.33968 26.1512 1.49828 25.4896C1.20008 25.3839 0.941622 25.189 0.757961 24.9314C0.5743 24.6738 0.474331 24.366 0.471619 24.0496V21.5676C0.471874 21.3401 0.523317 21.1155 0.622137 20.9105C0.720957 20.7055 0.864623 20.5253 1.0425 20.3834C1.87324 19.7128 2.34948 18.7439 2.34948 17.7234V13.16C2.34743 10.2212 3.62372 7.40105 5.85097 5.4219C6.90007 4.48684 8.12454 3.76959 9.45322 3.31181C9.88241 1.59866 11.4579 0.357666 13.2939 0.357666C15.252 0.357666 16.8864 1.74701 17.203 3.59673C21.1265 5.29453 23.7328 9.23645 23.7328 13.5446V17.7254C23.7328 18.7777 24.2321 19.7655 25.1017 20.4366C25.2871 20.578 25.4374 20.7603 25.541 20.9692C25.6445 21.1781 25.6985 21.4081 25.6987 21.6413V24.2036C25.6961 24.5232 25.5943 24.8341 25.4073 25.0933C25.2204 25.3525 24.9576 25.5473 24.6551 25.6507C22.9505 26.2409 21.2039 26.7026 19.4303 27.0319C18.685 29.7231 16.181 31.627 13.2939 31.627ZM10.8958 27.4943C11.1991 27.829 11.5691 28.0965 11.982 28.2796C12.395 28.4627 12.8417 28.5572 13.2933 28.5572C13.745 28.5572 14.1917 28.4627 14.6047 28.2796C15.0176 28.0965 15.3876 27.829 15.6909 27.4943C14.83 27.5649 14.054 27.5966 13.3102 27.5966C12.548 27.6002 11.7598 27.5659 10.8958 27.4943Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2934 31.6127C10.4037 31.6127 7.90122 29.7032 7.16102 27.0053C5.23881 26.6483 3.34864 26.1362 1.50903 25.4743C1.21105 25.3686 0.952773 25.1739 0.769212 24.9165C0.585652 24.6591 0.48569 24.3514 0.48288 24.0353V21.5533C0.482902 21.326 0.533981 21.1015 0.632346 20.8966C0.730711 20.6916 0.873851 20.5113 1.0512 20.3691C1.88092 19.6985 2.35716 18.7296 2.35716 17.7091V13.1462C2.35716 10.2074 3.63243 7.38726 5.85558 5.40811C6.9035 4.47301 8.12697 3.75572 9.45477 3.29801C9.88548 1.58487 11.4595 0.343872 13.2913 0.343872C15.2479 0.343872 16.8808 1.73321 17.1969 3.58294C21.1173 5.28074 23.7211 9.22266 23.7211 13.5308V17.7117C23.7211 18.7639 24.2198 19.7517 25.0889 20.4228C25.2745 20.5644 25.4249 20.7469 25.5284 20.9561C25.632 21.1653 25.6859 21.3956 25.6859 21.629V24.1913C25.6834 24.5109 25.5817 24.8217 25.3948 25.081C25.208 25.3402 24.9452 25.535 24.6429 25.6385C22.9406 26.2286 21.1965 26.6903 19.4252 27.0196C18.6799 29.7088 16.1779 31.6127 13.2934 31.6127ZM10.8973 27.48C11.2002 27.8147 11.5699 28.0822 11.9825 28.2653C12.3951 28.4484 12.8414 28.5429 13.2928 28.5429C13.7442 28.5429 14.1906 28.4484 14.6032 28.2653C15.0158 28.0822 15.3855 27.8147 15.6884 27.48C14.8285 27.5506 14.0515 27.5823 13.3102 27.5823C12.5486 27.5859 11.7613 27.5516 10.8973 27.48Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2934 31.5985C10.4062 31.5985 7.90583 29.6889 7.16615 26.991C5.24563 26.6343 3.35716 26.1226 1.51926 25.461C1.2217 25.3548 0.963966 25.1597 0.780971 24.9021C0.597975 24.6446 0.498559 24.337 0.496185 24.021V21.5391C0.49622 21.3118 0.547252 21.0874 0.645522 20.8824C0.743791 20.6775 0.886795 20.4972 1.06399 20.3548C1.8932 19.6842 2.36893 18.7154 2.36893 17.6948V13.1314C2.36893 10.1926 3.64266 7.37248 5.86377 5.39334C6.91072 4.45851 8.13314 3.74123 9.45989 3.28324C9.89009 1.5701 11.4631 0.329102 13.2964 0.329102C15.2515 0.329102 16.8823 1.71844 17.1985 3.56817C21.1122 5.26852 23.7139 9.20738 23.7139 13.5161V17.6989C23.7139 18.7512 24.2117 19.7389 25.0802 20.4101C25.2657 20.5518 25.4159 20.7343 25.5194 20.9435C25.6228 21.1527 25.6766 21.3829 25.6767 21.6163V24.175C25.6742 24.4944 25.5726 24.8052 25.386 25.0644C25.1993 25.3236 24.9368 25.5185 24.6347 25.6222C22.9325 26.2125 21.1884 26.6742 19.417 27.0033C18.6748 29.6945 16.1754 31.5985 13.2934 31.5985ZM10.8994 27.4657C11.2018 27.8004 11.5711 28.0679 11.9834 28.251C12.3957 28.4341 12.8418 28.5287 13.2929 28.5287C13.7439 28.5287 14.19 28.4341 14.6023 28.251C15.0146 28.0679 15.3839 27.8004 15.6863 27.4657C14.827 27.5363 14.052 27.5681 13.3102 27.5681C12.5491 27.5716 11.7623 27.5374 10.8994 27.4657Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2934 31.5841C10.4083 31.5841 7.91094 29.6745 7.17176 26.9766C5.25277 26.6198 3.36584 26.1082 1.52949 25.4466C1.23196 25.3403 0.974271 25.1452 0.791286 24.8877C0.6083 24.6301 0.508856 24.3226 0.506409 24.0066V21.5246C0.506345 21.2974 0.557282 21.073 0.655464 20.8681C0.753647 20.6631 0.896572 20.4828 1.07371 20.3404C1.9024 19.6698 2.37762 18.701 2.37762 17.6804V13.117C2.3766 10.1792 3.65135 7.35808 5.86836 5.37894C6.91409 4.44428 8.1353 3.727 9.4609 3.26884C9.89213 1.55569 11.4626 0.314697 13.2934 0.314697C15.2464 0.314697 16.8741 1.70404 17.1918 3.55376C21.1051 5.25156 23.7037 9.19348 23.7037 13.5042V17.685C23.7037 18.7373 24.2014 19.7251 25.069 20.3962C25.2542 20.538 25.4044 20.7206 25.5077 20.9298C25.611 21.1389 25.6648 21.3691 25.6649 21.6024V24.1647C25.6618 24.4832 25.56 24.793 25.3737 25.0514C25.1874 25.3098 24.9257 25.5041 24.6245 25.6078C22.9245 26.198 21.1826 26.6597 19.4134 26.9889C18.6696 29.6801 16.1723 31.5841 13.2934 31.5841ZM10.9014 27.4513C11.2035 27.7857 11.5725 28.0531 11.9843 28.236C12.3962 28.419 12.8419 28.5135 13.2926 28.5135C13.7433 28.5135 14.1889 28.419 14.6008 28.236C15.0127 28.0531 15.3816 27.7857 15.6838 27.4513C14.8254 27.5219 14.0509 27.5537 13.3097 27.5537C12.5521 27.5572 11.7633 27.523 10.9014 27.4513Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2928 31.5698C10.4108 31.5698 7.91553 29.6602 7.17687 26.9624C5.25977 26.6053 3.37473 26.0936 1.54022 25.4323C1.24232 25.3261 0.984302 25.1308 0.801189 24.8729C0.618076 24.6151 0.51873 24.3071 0.516632 23.9908V21.5104C0.51658 21.2832 0.56747 21.0588 0.665557 20.8539C0.763645 20.649 0.906433 20.4686 1.08342 20.3262C1.91109 19.6555 2.3858 18.6867 2.3858 17.6661V13.1027C2.39017 11.64 2.70322 10.1947 3.30448 8.86126C3.90575 7.52784 4.78168 6.33633 5.87501 5.36465C6.91954 4.42996 8.13976 3.71265 9.46448 3.25455C9.89212 1.54141 11.4641 0.300415 13.2928 0.300415C15.2444 0.300415 16.8736 1.68976 17.1882 3.53948C21.0979 5.23728 23.6945 9.1792 23.6945 13.4874V17.6682C23.6945 18.7204 24.1917 19.7082 25.0587 20.3794C25.2441 20.5213 25.3942 20.7041 25.4975 20.9134C25.6007 21.1228 25.6544 21.3531 25.6542 21.5866V24.1443C25.6519 24.4635 25.5506 24.7742 25.3643 25.0334C25.1779 25.2927 24.9156 25.4876 24.6137 25.5914C22.9155 26.1816 21.1752 26.6433 19.4078 26.9726C18.6645 29.6658 16.1697 31.5698 13.2928 31.5698ZM10.9034 27.4371C11.205 27.7716 11.5736 28.0391 11.9851 28.2222C12.3967 28.4053 12.8421 28.5 13.2926 28.5C13.743 28.5 14.1885 28.4053 14.6 28.2222C15.0116 28.0391 15.3801 27.7716 15.6817 27.4371C14.8239 27.5077 14.0499 27.5394 13.3097 27.5394C12.5521 27.5429 11.7649 27.5087 10.9034 27.4371Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2928 31.5555C10.4134 31.5555 7.92167 29.6459 7.18199 26.9481C5.26658 26.591 3.38324 26.0793 1.55045 25.4181C1.25293 25.3118 0.995237 25.1167 0.812251 24.8591C0.629266 24.6015 0.529821 24.294 0.527374 23.9781V21.4961C0.527334 21.2689 0.578177 21.0447 0.676169 20.8397C0.774161 20.6348 0.916813 20.4544 1.09365 20.3119C1.92132 19.6428 2.39552 18.6709 2.39552 17.6539V13.0884C2.39979 11.626 2.71245 10.181 3.31306 8.84765C3.91367 7.5143 4.78874 6.3226 5.88114 5.35037C6.92464 4.41584 8.14383 3.69852 9.46755 3.24027C9.89673 1.52713 11.4656 0.286133 13.2928 0.286133C15.2423 0.286133 16.869 1.67547 17.1846 3.5252C21.0902 5.223 23.6853 9.16492 23.6853 13.4731V17.6539C23.6853 18.7061 24.182 19.6939 25.048 20.3651C25.233 20.507 25.3828 20.6897 25.4859 20.8989C25.589 21.108 25.6425 21.3381 25.6424 21.5713V24.1336C25.6403 24.4526 25.5392 24.7632 25.3531 25.0224C25.167 25.2816 24.9051 25.4767 24.6035 25.5807C22.9068 26.1709 21.1681 26.6326 19.4021 26.9619C18.6599 29.6516 16.1672 31.5555 13.2928 31.5555ZM10.9055 27.4228C11.2066 27.7573 11.5747 28.0248 11.9859 28.2079C12.3971 28.391 12.8422 28.4856 13.2923 28.4856C13.7424 28.4856 14.1875 28.391 14.5987 28.2079C15.0099 28.0248 15.378 27.7573 15.6792 27.4228C14.8223 27.4934 14.0494 27.5251 13.3092 27.5251C12.5521 27.5287 11.7659 27.4944 10.9055 27.4228Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2923 31.5411C10.4159 31.5411 7.92474 29.6315 7.18761 26.9337C5.27369 26.5766 3.39188 26.0649 1.56068 25.4036C1.26311 25.2974 1.00538 25.1023 0.822384 24.8448C0.639388 24.5872 0.539972 24.2796 0.537598 23.9637V21.4842C0.53736 21.2565 0.588257 21.0317 0.686533 20.8263C0.784808 20.6209 0.927955 20.4402 1.10541 20.2975C1.93154 19.6268 2.40523 18.658 2.40523 17.6375V13.074C2.40928 11.6119 2.72153 10.1671 3.32159 8.83374C3.92166 7.50042 4.79607 6.30858 5.88779 5.33597C6.93021 4.40156 8.14837 3.68423 9.47112 3.22587C9.89979 1.51272 11.4661 0.271729 13.2923 0.271729C15.2403 0.271729 16.8659 1.66107 17.18 3.51079C21.0825 5.20859 23.6766 9.15051 23.6766 13.4587V17.6395C23.6766 18.6917 24.1728 19.6795 25.0383 20.3507C25.2231 20.4928 25.3728 20.6754 25.4758 20.8846C25.5787 21.0937 25.6323 21.3237 25.6322 21.5569V24.1192C25.6301 24.4381 25.5292 24.7486 25.3433 25.0078C25.1574 25.2669 24.8957 25.4621 24.5943 25.5663C22.8991 26.1565 21.162 26.6182 19.3975 26.9475C18.6548 29.6371 16.1641 31.5411 13.2923 31.5411ZM10.9075 27.4084C11.2083 27.7429 11.576 28.0104 11.9869 28.1935C12.3977 28.3766 12.8425 28.4713 13.2923 28.4713C13.7421 28.4713 14.1869 28.3766 14.5978 28.1935C15.0086 28.0104 15.3764 27.7429 15.6771 27.4084C14.8208 27.479 14.0484 27.5107 13.3092 27.5107C12.5521 27.5143 11.7669 27.48 10.9075 27.4084Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2923 31.5268C10.418 31.5268 7.92934 29.6172 7.19272 26.9194C5.28049 26.5622 3.40038 26.0505 1.57091 25.3893C1.27382 25.2827 1.01664 25.0874 0.834123 24.8299C0.65161 24.5724 0.552568 24.265 0.550385 23.9494V21.4674C0.550246 21.2403 0.600994 21.016 0.698899 20.8111C0.796804 20.6061 0.939378 20.4257 1.11615 20.2832C1.94126 19.6125 2.41495 18.6462 2.41495 17.6231V13.0597C2.41874 11.5979 2.73046 10.1534 3.3298 8.8201C3.92914 7.48684 4.80264 6.2948 5.89341 5.32166C6.93487 4.38725 8.1522 3.6699 9.47419 3.21156C9.90235 1.49841 11.4682 0.255371 13.2923 0.255371C15.2362 0.255371 16.8623 1.64471 17.1769 3.49444C21.0759 5.19224 23.6658 9.13416 23.6658 13.4423V17.6231C23.6658 18.6754 24.162 19.6632 25.0265 20.3343C25.2113 20.4764 25.3609 20.6591 25.4638 20.8683C25.5667 21.0774 25.6201 21.3074 25.6199 21.5405V24.1033C25.6178 24.4221 25.517 24.7325 25.3313 24.9916C25.1457 25.2508 24.8842 25.446 24.583 25.5505C22.8895 26.1406 21.1538 26.6023 19.3909 26.9316C18.6497 29.6228 16.1615 31.5268 13.2923 31.5268ZM10.9091 27.3941C11.2094 27.7285 11.5767 27.996 11.9873 28.1792C12.3978 28.3623 12.8423 28.4569 13.2918 28.4569C13.7413 28.4569 14.1858 28.3623 14.5963 28.1792C15.0069 27.996 15.3742 27.7285 15.6746 27.3941C14.8193 27.4647 14.0473 27.4964 13.3092 27.4964C12.5521 27.5 11.7684 27.4657 10.9091 27.3941Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2923 31.5105C10.4205 31.5105 7.93395 29.6009 7.19785 26.9031C5.28751 26.5466 3.40928 26.0356 1.58166 25.3751C1.28467 25.2684 1.02759 25.073 0.845177 24.8155C0.66276 24.558 0.563793 24.2507 0.561648 23.9351V21.4531C0.561249 21.2263 0.611569 21.0022 0.708927 20.7973C0.806285 20.5924 0.948218 20.4119 1.12434 20.2689C1.9515 19.5983 2.42365 18.6294 2.42365 17.6109V13.0439C2.43696 10.8748 3.12242 8.76309 4.38565 6.99966C5.64887 5.23624 7.42773 3.90781 9.47726 3.1973C9.90542 1.48416 11.4723 0.243164 13.2923 0.243164C15.2362 0.243164 16.8588 1.6325 17.1734 3.48223C21.0687 5.18003 23.6566 9.12195 23.6566 13.4301V17.6109C23.6566 18.6632 24.1518 19.651 25.0158 20.3221C25.2004 20.4643 25.3499 20.647 25.4526 20.8562C25.5554 21.0653 25.6088 21.2953 25.6087 21.5283V24.0906C25.6065 24.409 25.5058 24.7189 25.3204 24.9777C25.135 25.2365 24.874 25.4317 24.5733 25.5362C22.8813 26.1263 21.1472 26.588 19.3858 26.9174C18.6446 29.6086 16.1585 31.5105 13.2923 31.5105ZM10.9111 27.3778C11.211 27.7122 11.578 27.9797 11.9882 28.1629C12.3984 28.346 12.8426 28.4407 13.2918 28.4407C13.741 28.4407 14.1852 28.346 14.5954 28.1629C15.0056 27.9797 15.3726 27.7122 15.6725 27.3778C14.8177 27.4484 14.0463 27.4801 13.3087 27.4801C12.5521 27.4857 11.7695 27.4514 10.9111 27.3798V27.3778Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2918 31.4981C10.4231 31.4981 7.93856 29.5886 7.2045 26.8907C5.2956 26.5336 3.41889 26.0219 1.59292 25.3607C1.29593 25.254 1.03885 25.0586 0.856437 24.8011C0.67402 24.5436 0.575053 24.2363 0.572908 23.9207V21.4387C0.572582 21.2119 0.622934 20.9878 0.720287 20.7829C0.81764 20.5781 0.959533 20.3975 1.1356 20.2545C1.95918 19.5839 2.43184 18.6176 2.43184 17.5945V13.0311C2.4442 10.8618 3.12897 8.74984 4.39179 6.98606C5.65462 5.22228 7.43335 3.89353 9.4829 3.1829C9.91003 1.46975 11.4733 0.22876 13.2939 0.22876C15.2377 0.22876 16.8578 1.6181 17.1718 3.46783C21.0616 5.16614 23.6469 9.10754 23.6469 13.4157V17.5965C23.6469 18.6488 24.1421 19.6366 25.005 20.3077C25.1891 20.45 25.3381 20.6325 25.4406 20.8414C25.5431 21.0502 25.5964 21.2797 25.5964 21.5124V24.0747C25.5946 24.3933 25.494 24.7036 25.3086 24.9627C25.1232 25.2219 24.862 25.4172 24.561 25.5218C22.8708 26.1119 21.1384 26.5736 19.3786 26.903C18.6395 29.5942 16.1559 31.4981 13.2918 31.4981ZM10.9152 27.3669C11.2146 27.7014 11.5813 27.969 11.9911 28.1522C12.401 28.3354 12.8449 28.4301 13.2939 28.4301C13.7428 28.4301 14.1867 28.3354 14.5966 28.1522C15.0065 27.969 15.3731 27.7014 15.6725 27.3669C14.8188 27.4375 14.0484 27.4692 13.3113 27.4692C12.5521 27.4692 11.7705 27.437 10.9152 27.3669Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2918 31.4839C10.4272 31.4839 7.94317 29.5743 7.2086 26.8764C5.30139 26.5193 3.42638 26.0076 1.60213 25.3464C1.3051 25.2397 1.04799 25.0444 0.865562 24.7869C0.683135 24.5294 0.584197 24.222 0.582125 23.9064V21.4244C0.581762 21.1976 0.632098 20.9735 0.729454 20.7686C0.826809 20.5638 0.968722 20.3832 1.14482 20.2402C1.96788 19.5696 2.44004 18.6007 2.44004 17.5802V13.0168C2.45233 10.849 3.13614 8.73829 4.39738 6.97514C5.65863 5.21198 7.4353 3.88304 9.48291 3.17118C9.69788 2.32457 10.1893 1.57396 10.8793 1.03835C11.5693 0.50274 12.4184 0.212794 13.2918 0.214485C15.2326 0.214485 16.8521 1.60383 17.1662 3.45355C21.0539 5.15135 23.6377 9.09327 23.6377 13.4015V17.5823C23.6377 18.6345 24.1319 19.6223 24.9943 20.2934C25.1787 20.4358 25.3279 20.6185 25.4306 20.8277C25.5332 21.0368 25.5864 21.2667 25.5862 21.4996V24.0619C25.5844 24.3805 25.484 24.6906 25.2988 24.9498C25.1136 25.2089 24.8526 25.4043 24.5518 25.5091C22.8631 26.0992 21.1323 26.5609 19.374 26.8902C18.6349 29.5799 16.1529 31.4839 13.2918 31.4839ZM10.9152 27.3511C11.2142 27.6855 11.5804 27.953 11.9898 28.1362C12.3993 28.3193 12.8428 28.414 13.2913 28.414C13.7399 28.414 14.1834 28.3193 14.5928 28.1362C15.0023 27.953 15.3684 27.6855 15.6674 27.3511C14.8147 27.4217 14.0448 27.4534 13.3082 27.4534C12.5521 27.457 11.772 27.4227 10.9152 27.3511Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2918 31.4696C10.4272 31.4696 7.94775 29.56 7.21369 26.8621C5.30817 26.5049 3.43487 25.9932 1.61234 25.3321C1.31588 25.225 1.05943 25.0294 0.877579 24.7719C0.695732 24.5144 0.597242 24.2074 0.595399 23.8921V21.4101C0.595109 21.1833 0.645478 20.9593 0.742828 20.7544C0.840178 20.5495 0.982052 20.369 1.15809 20.2259C1.98065 19.5553 2.45229 18.589 2.45229 17.5659V13.0025C2.46278 10.8351 3.1449 8.72425 4.40472 6.96058C5.66454 5.1969 7.44011 3.86713 9.48697 3.15434C9.70195 2.30871 10.1929 1.55897 10.8821 1.02388C11.5713 0.488786 12.4193 0.198922 13.2918 0.2002C15.2305 0.2002 16.849 1.58954 17.1621 3.43927C21.0472 5.13706 23.628 9.07899 23.628 13.3872V17.568C23.628 18.6202 24.1221 19.608 24.9835 20.2791C25.1676 20.4215 25.3167 20.6041 25.4192 20.8131C25.5217 21.022 25.575 21.2516 25.5749 21.4843V24.0466C25.5732 24.365 25.4729 24.6751 25.2879 24.9342C25.1029 25.1933 24.8422 25.3888 24.5416 25.4938C22.8544 26.0838 21.1251 26.5455 19.3684 26.8749C18.6297 29.5666 16.1503 31.4696 13.2918 31.4696ZM10.9152 27.3368C11.2138 27.6712 11.5796 27.9387 11.9887 28.1219C12.3978 28.305 12.841 28.3997 13.2892 28.3997C13.7375 28.3997 14.1807 28.305 14.5898 28.1219C14.9989 27.9387 15.3647 27.6712 15.6633 27.3368C14.8111 27.4074 14.0417 27.4391 13.3061 27.4391C12.5521 27.4427 11.773 27.4085 10.9152 27.3368Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2913 31.4552C10.4303 31.4552 7.95236 29.5456 7.21933 26.8478C5.31529 26.4906 3.44352 25.9789 1.62258 25.3178C1.32635 25.2104 1.07016 25.0148 0.888513 24.7573C0.706868 24.4998 0.6085 24.1929 0.606661 23.8778V21.3958C0.606334 21.169 0.656687 20.9449 0.754039 20.74C0.851392 20.5351 0.993286 20.3546 1.16935 20.2116C1.99089 19.5409 2.46201 18.5721 2.46201 17.5516V12.9881C2.47184 10.8214 3.15297 8.71103 4.41166 6.94739C5.67035 5.18376 7.44472 3.8536 9.49056 3.13998C9.71252 2.27074 10.2256 1.50377 10.9442 0.966786C11.6629 0.429807 12.5439 0.155231 13.4404 0.188783C14.3369 0.222334 15.1948 0.561989 15.8714 1.15118C16.5479 1.74038 17.0022 2.54355 17.1585 3.42695C21.0401 5.12475 23.6188 9.06667 23.6188 13.3749V17.5557C23.6188 18.6079 24.1124 19.5957 24.9728 20.2668C25.1569 20.4094 25.3059 20.5922 25.4083 20.8013C25.5107 21.0104 25.5638 21.2402 25.5636 21.473V24.0317C25.5621 24.35 25.462 24.66 25.2771 24.9191C25.0923 25.1783 24.8318 25.3738 24.5313 25.4789C22.8456 26.069 21.1176 26.5307 19.3622 26.8601C18.6246 29.5513 16.1477 31.4552 13.2913 31.4552ZM10.9193 27.3225C11.2174 27.6568 11.5828 27.9243 11.9915 28.1075C12.4003 28.2907 12.8431 28.3853 13.291 28.3853C13.739 28.3853 14.1818 28.2907 14.5906 28.1075C14.9993 27.9243 15.3647 27.6568 15.6628 27.3225C14.8116 27.3931 14.0433 27.4248 13.3097 27.4248C12.5521 27.4284 11.7741 27.3941 10.9193 27.3225Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2913 31.4409C10.4323 31.4409 7.95697 29.5313 7.22445 26.8335C5.32225 26.4763 3.45235 25.9646 1.63333 25.3034C1.33715 25.196 1.08103 25.0004 0.899472 24.7429C0.717914 24.4854 0.619649 24.1785 0.617921 23.8635V21.382C0.617667 21.1552 0.668052 20.9311 0.765399 20.7263C0.862747 20.5214 1.0046 20.3408 1.18061 20.1978C2.00163 19.5271 2.47225 18.5608 2.47225 17.5378V12.9738C2.48103 10.8078 3.16091 8.69784 4.41832 6.93421C5.67573 5.17058 7.44885 3.84001 9.49364 3.12565C9.71418 2.25605 10.2263 1.48837 10.9444 0.950716C11.6626 0.413062 12.5435 0.137951 13.44 0.171289C14.3365 0.204626 15.1945 0.544395 15.8707 1.1339C16.547 1.72341 17.0006 2.527 17.156 3.41058C21.034 5.10838 23.6101 9.0503 23.6101 13.3585V17.5393C23.6101 18.5915 24.1032 19.5793 24.9631 20.2505C25.1471 20.393 25.296 20.5759 25.3983 20.785C25.5007 20.9941 25.5537 21.2239 25.5534 21.4567V24.019C25.5516 24.3369 25.4515 24.6464 25.2669 24.9052C25.0823 25.164 24.8221 25.3594 24.5221 25.4646C22.8373 26.0547 21.1102 26.5164 19.3556 26.8457C18.6195 29.5369 16.1447 31.4409 13.2913 31.4409ZM10.9208 27.3082C11.2184 27.6427 11.5834 27.9105 11.9919 28.0939C12.4004 28.2773 12.843 28.3721 13.2908 28.3721C13.7385 28.3721 14.1812 28.2773 14.5897 28.0939C14.9982 27.9105 15.3632 27.6427 15.6608 27.3082C14.8101 27.3788 14.0422 27.4105 13.3077 27.4105C12.5521 27.414 11.7756 27.3798 10.9208 27.3082Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2913 31.4266C10.4349 31.4266 7.96158 29.517 7.22957 26.8192C5.32906 26.4619 3.46086 25.9502 1.64356 25.2891C1.34754 25.1816 1.0916 24.9859 0.910222 24.7285C0.728846 24.471 0.63076 24.1641 0.629181 23.8492V21.3672C0.629 21.1404 0.679417 20.9164 0.776759 20.7115C0.874102 20.5066 1.01592 20.3261 1.19187 20.183C2.01034 19.5123 2.483 18.5435 2.483 17.523V12.9595C2.491 10.7944 3.16967 8.685 4.42566 6.92144C5.68165 5.15787 7.45324 3.82681 9.49671 3.11136C9.71691 2.24249 10.2285 1.47543 10.946 0.938211C11.6635 0.40099 12.5435 0.12611 13.4392 0.159451C14.3349 0.192791 15.1921 0.532336 15.8677 1.12141C16.5433 1.71049 16.9964 2.51345 17.1514 3.39629C21.0258 5.09408 23.6014 9.03601 23.6014 13.3442V17.525C23.6014 18.5772 24.094 19.565 24.9529 20.2362C25.1368 20.3788 25.2856 20.5617 25.388 20.7708C25.4903 20.9799 25.5434 21.2096 25.5432 21.4424V24.0047C25.5417 24.3228 25.4419 24.6326 25.2573 24.8917C25.0728 25.1508 24.8126 25.3464 24.5124 25.4518C22.8298 26.0419 21.1049 26.5036 19.3525 26.833C18.6149 29.5226 16.1421 31.4266 13.2913 31.4266ZM10.9229 27.2939C11.2201 27.6281 11.5848 27.8957 11.9929 28.0789C12.401 28.2621 12.8432 28.3568 13.2905 28.3568C13.7379 28.3568 14.1801 28.2621 14.5882 28.0789C14.9963 27.8957 15.3609 27.6281 15.6582 27.2939C14.8085 27.3645 14.0412 27.3962 13.3051 27.3962C12.5547 27.3997 11.7766 27.367 10.9229 27.2939Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2908 31.4123C10.4374 31.4123 7.96619 29.5027 7.23521 26.8048C5.33705 26.4475 3.47124 25.9358 1.65636 25.2748C1.36046 25.1677 1.10447 24.9725 0.922839 24.7155C0.74121 24.4585 0.642657 24.1521 0.640442 23.8374V21.3528C0.640333 21.1261 0.690782 20.9021 0.788119 20.6972C0.885456 20.4924 1.02723 20.3118 1.20314 20.1686C2.0216 19.498 2.49273 18.5317 2.49273 17.5086V12.9452C2.49987 10.7807 3.17754 8.67155 4.43253 6.90798C5.68751 5.14441 7.45814 3.81304 9.50081 3.09704C9.72045 2.22864 10.2314 1.46189 10.9483 0.924858C11.6652 0.387829 12.5447 0.113041 13.4398 0.146391C14.3349 0.179741 15.1915 0.519211 15.8665 1.1081C16.5414 1.69698 16.9939 2.49963 17.1483 3.38197C21.0191 5.07977 23.5907 9.02169 23.5907 13.3299V17.5107C23.5907 18.5629 24.0828 19.5507 24.9416 20.2218C25.1253 20.3646 25.274 20.5476 25.3761 20.7566C25.4782 20.9657 25.5312 21.1954 25.5309 21.428V23.9909C25.5295 24.3088 25.4298 24.6186 25.2455 24.8777C25.0611 25.1367 24.8011 25.3325 24.5012 25.438C22.82 26.0277 21.0967 26.4894 19.3459 26.8192C18.6098 29.5083 16.1396 31.4123 13.2908 31.4123ZM10.9249 27.2795C11.2218 27.6138 11.5861 27.8813 11.9938 28.0645C12.4016 28.2477 12.8435 28.3425 13.2906 28.3425C13.7376 28.3425 14.1795 28.2477 14.5873 28.0645C14.995 27.8813 15.3593 27.6138 15.6562 27.2795C14.807 27.3501 14.0407 27.3818 13.3072 27.3818C12.5552 27.3854 11.7777 27.3512 10.9249 27.2795Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2908 31.3979C11.9164 31.4004 10.5794 30.9506 9.48596 30.118C8.39252 29.2853 7.6034 28.116 7.2403 26.7905C5.34314 26.4332 3.47834 25.9215 1.66452 25.2605C1.36879 25.1527 1.11317 24.9569 0.932083 24.6995C0.750997 24.442 0.653136 24.1353 0.651672 23.8205V21.3385C0.651636 21.1117 0.702117 20.8878 0.799449 20.6829C0.896781 20.4781 1.03852 20.2975 1.21437 20.1543C2.03283 19.4837 2.50293 18.5148 2.50293 17.4943V12.9309C2.50929 10.7672 3.18576 8.65867 4.43932 6.89516C5.69289 5.13166 7.46198 3.79979 9.50335 3.0827C9.72219 2.21449 10.2325 1.44774 10.9489 0.91066C11.6653 0.373582 12.5444 0.0987458 13.4391 0.132111C14.3339 0.165476 15.19 0.50502 15.8644 1.09396C16.5389 1.6829 16.9906 2.48553 17.1442 3.36763C21.0114 5.0639 23.5809 9.00735 23.5809 13.3155V17.4943C23.5809 18.5465 24.0725 19.5343 24.9303 20.2055C25.1139 20.3484 25.2624 20.5313 25.3644 20.7404C25.4665 20.9494 25.5194 21.179 25.5191 21.4117V23.974C25.5182 24.2922 25.4188 24.6024 25.2345 24.8619C25.0502 25.1214 24.7901 25.3174 24.4899 25.4232C22.8105 26.0129 21.0889 26.4747 19.3397 26.8043C18.6047 29.494 16.1365 31.3979 13.2908 31.3979ZM10.927 27.2647C11.2233 27.5989 11.5872 27.8664 11.9946 28.0497C12.402 28.2329 12.8436 28.3276 13.2903 28.3276C13.737 28.3276 14.1786 28.2329 14.5859 28.0497C14.9933 27.8664 15.3572 27.5989 15.6536 27.2647C14.8055 27.3353 14.0397 27.367 13.3072 27.367C12.5557 27.3711 11.7787 27.3368 10.927 27.2647Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2908 31.3836C11.9171 31.3856 10.5809 30.9355 9.48841 30.1029C8.39587 29.2702 7.60767 28.1012 7.24542 26.7761C5.34976 26.4188 3.48649 25.9071 1.67425 25.2461C1.37884 25.138 1.12359 24.9421 0.942807 24.6847C0.762019 24.4273 0.664354 24.1207 0.662933 23.8061V21.3241C0.662861 21.0973 0.713326 20.8734 0.81066 20.6685C0.907995 20.4637 1.04975 20.2831 1.22563 20.1399C2.04409 19.4693 2.51317 18.5004 2.51317 17.4799V12.9165C2.51881 10.7537 3.19417 8.64579 4.44641 6.88242C5.69865 5.11906 7.46631 3.78683 9.50642 3.06884C9.72443 2.20081 10.234 1.43402 10.9499 0.896853C11.6657 0.359685 12.5444 0.084745 13.4388 0.118065C14.3331 0.151385 15.1889 0.490942 15.8628 1.07989C16.5367 1.66883 16.9878 2.47141 17.1406 3.35325C21.0043 5.05105 23.5712 8.99297 23.5712 13.2996V17.4804C23.5712 18.5327 24.0628 19.5204 24.9196 20.1916C25.1031 20.3345 25.2515 20.5175 25.3535 20.7265C25.4554 20.9356 25.5082 21.1652 25.5079 21.3978V23.9601C25.5066 24.2779 25.4071 24.5875 25.2231 24.8466C25.039 25.1056 24.7794 25.3015 24.4797 25.4072C22.8019 25.997 21.0817 26.4587 19.3341 26.7884C18.9692 28.1106 18.1802 29.2763 17.0884 30.1065C15.9966 30.9367 14.6624 31.3854 13.2908 31.3836ZM10.929 27.2508C11.225 27.585 11.5885 27.8526 11.9955 28.0358C12.4026 28.219 12.8439 28.3138 13.2903 28.3138C13.7367 28.3138 14.178 28.219 14.585 28.0358C14.9921 27.8526 15.3556 27.585 15.6515 27.2508C14.8039 27.3214 14.0387 27.3531 13.3072 27.3531C12.5562 27.3567 11.7802 27.3224 10.929 27.2508Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2903 31.3693C11.9174 31.3709 10.5822 30.9206 9.49072 30.0879C8.39924 29.2552 7.61219 28.0864 7.25106 26.7619C5.35688 26.4047 3.49514 25.893 1.68449 25.2319C1.38923 25.1237 1.13416 24.9277 0.953559 24.6703C0.772954 24.4129 0.675467 24.1063 0.674196 23.7919V21.3099C0.673824 21.0834 0.723845 20.8596 0.820636 20.6548C0.917426 20.45 1.05856 20.2692 1.23382 20.1257C2.05228 19.4551 2.51983 18.4862 2.51983 17.4657V12.9022C2.5248 10.7398 3.19947 8.6319 4.45109 6.86843C5.7027 5.10496 7.46981 3.77245 9.5095 3.0541C9.72652 2.18611 10.2354 1.41913 10.9508 0.881792C11.6661 0.344449 12.5445 0.0694171 13.4386 0.102818C14.3327 0.136218 15.1881 0.476021 15.8614 1.06524C16.5347 1.65446 16.9849 2.45726 17.1366 3.33902C20.9971 5.03682 23.562 8.97874 23.562 13.2869V17.4677C23.562 18.52 24.0531 19.5078 24.9089 20.1789C25.0918 20.3216 25.2398 20.5041 25.3417 20.7126C25.4435 20.9211 25.4965 21.15 25.4966 21.382V23.9443C25.4959 24.2621 25.397 24.5719 25.2134 24.8312C25.0298 25.0905 24.7705 25.2868 24.471 25.393C22.7947 25.9828 21.0762 26.4445 19.33 26.7742C18.9659 28.0961 18.1776 29.2618 17.0864 30.092C15.9952 30.9222 14.6614 31.371 13.2903 31.3693ZM10.9306 27.2366C11.2261 27.5708 11.5892 27.8383 11.996 28.0216C12.4027 28.2048 12.8437 28.2996 13.2898 28.2996C13.7359 28.2996 14.1769 28.2048 14.5836 28.0216C14.9903 27.8383 15.3535 27.5708 15.649 27.2366C14.8024 27.3067 14.0382 27.3389 13.3067 27.3389C12.5567 27.3425 11.7812 27.3082 10.9306 27.2366Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2903 31.357C11.9178 31.3587 10.5829 30.9084 9.49184 30.0756C8.40082 29.2428 7.61441 28.074 7.25412 26.7496C5.36229 26.3918 3.50295 25.8798 1.69471 25.2185C1.39933 25.1102 1.14419 24.9141 0.96358 24.6565C0.782971 24.3989 0.685553 24.0921 0.684418 23.7775V21.2955C0.684417 21.0688 0.734915 20.8448 0.832244 20.64C0.929573 20.4352 1.07129 20.2546 1.24711 20.1113C2.06557 19.4407 2.5321 18.4718 2.5321 17.4513V12.8904C2.53553 10.7285 3.20854 8.62065 4.4586 6.85672C5.70866 5.09279 7.47439 3.75935 9.51307 3.03972C9.72813 2.17065 10.236 1.40219 10.9512 0.863648C11.6664 0.325105 12.5452 0.0493541 13.4399 0.0827987C14.3346 0.116243 15.1904 0.456842 15.8634 1.04728C16.5364 1.63772 16.9854 2.44195 17.135 3.32465C20.9925 5.02245 23.5502 8.96437 23.5502 13.2726V17.4534C23.5502 18.5056 24.0408 19.4934 24.8961 20.1645C25.0794 20.3076 25.2275 20.4906 25.3293 20.6997C25.4311 20.9087 25.4838 21.1382 25.4833 21.3707V23.933C25.4823 24.2506 25.3831 24.5601 25.1994 24.8191C25.0157 25.0782 24.7565 25.2741 24.4572 25.3802C22.7825 25.9699 21.0654 26.4316 19.3208 26.7613C18.958 28.0823 18.1713 29.2474 17.0818 30.0777C15.9922 30.9081 14.6601 31.3575 13.2903 31.357ZM10.9326 27.2243C11.2277 27.5584 11.5905 27.826 11.9969 28.0093C12.4033 28.1925 12.844 28.2873 13.2898 28.2873C13.7356 28.2873 14.1763 28.1925 14.5827 28.0093C14.989 27.826 15.3518 27.5584 15.6469 27.2243C14.8003 27.2944 14.0371 27.3266 13.3066 27.3266C12.5567 27.3281 11.7848 27.2938 10.9326 27.2222V27.2243Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2898 31.3407C11.9183 31.3418 10.5846 30.8912 9.4949 30.0583C8.40522 29.2255 7.62022 28.0569 7.26127 26.7332C5.37132 26.3758 3.51385 25.8641 1.70749 25.2032C1.41252 25.0948 1.15778 24.8987 0.977466 24.6413C0.797151 24.3839 0.699889 24.0775 0.698734 23.7632V21.2797C0.698264 21.0532 0.748189 20.8294 0.844893 20.6246C0.941596 20.4198 1.08265 20.2391 1.25785 20.0955C2.07631 19.4249 2.5413 18.456 2.5413 17.4355V12.8736C2.54452 10.7127 3.21675 8.60589 4.46566 6.8425C5.71456 5.07911 7.47885 3.74569 9.51614 3.02544C9.73131 2.15766 10.2387 1.39045 10.9531 0.852827C11.6674 0.315203 12.5451 0.0399658 13.4386 0.0734026C14.332 0.106839 15.1867 0.446911 15.8588 1.03643C16.531 1.62594 16.9797 2.42894 17.1294 3.31037C20.9833 5.00817 23.5431 8.95162 23.5431 13.2583V17.4391C23.5431 18.4913 24.0331 19.4791 24.8879 20.1502C25.0709 20.2933 25.2189 20.4763 25.3205 20.6853C25.422 20.8942 25.4746 21.1236 25.4741 21.3559V23.9187C25.4734 24.236 25.3747 24.5452 25.1916 24.8042C25.0084 25.0632 24.7498 25.2594 24.451 25.3659C22.7779 25.9556 21.0624 26.4173 19.3193 26.747C18.9569 28.0679 18.1704 29.233 17.0809 30.063C15.9915 30.893 14.6594 31.3419 13.2898 31.3407ZM10.9367 27.2079C11.2313 27.542 11.5937 27.8096 11.9997 27.9929C12.4057 28.1762 12.8461 28.271 13.2916 28.271C13.737 28.271 14.1774 28.1762 14.5834 27.9929C14.9894 27.8096 15.3518 27.542 15.6464 27.2079C14.8008 27.278 14.0381 27.3103 13.3087 27.3103C12.5572 27.3159 11.7848 27.2796 10.9346 27.2079H10.9367Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2898 31.3262C11.9191 31.3263 10.5866 30.8751 9.49798 30.0424C8.40934 29.2096 7.62511 28.0417 7.26639 26.7188C5.37726 26.3614 3.52063 25.8497 1.71516 25.1888C1.42038 25.0802 1.16587 24.884 0.985743 24.6267C0.805621 24.3693 0.708508 24.063 0.707431 23.7488V21.2668C0.707016 21.0404 0.756863 20.8168 0.853374 20.612C0.949885 20.4072 1.09065 20.2264 1.26552 20.0826C2.08091 19.412 2.54846 18.4431 2.54846 17.4226V12.8592C2.55108 10.6987 3.2226 8.59212 4.47077 6.82872C5.71894 5.06533 7.48251 3.73169 9.51921 3.01102C9.73405 2.14385 10.241 1.37713 10.9548 0.839849C11.6685 0.302569 12.5456 0.0275127 13.4383 0.0609537C14.3311 0.0943947 15.1851 0.434292 15.8567 1.02346C16.5283 1.61264 16.9764 2.41513 17.1258 3.29594C20.9762 4.99374 23.5338 8.93566 23.5338 13.2438V17.4247C23.5338 18.4769 24.0234 19.4642 24.8771 20.1358C25.0601 20.279 25.208 20.462 25.3096 20.6709C25.4111 20.8799 25.4637 21.1092 25.4634 21.3415V23.9043C25.4625 24.2215 25.3638 24.5307 25.1807 24.7897C24.9976 25.0487 24.739 25.2449 24.4403 25.3515C22.7689 25.9411 21.0551 26.4028 19.3136 26.7326C18.9523 28.053 18.1669 29.2179 17.0784 30.048C15.9899 30.8781 14.6587 31.3272 13.2898 31.3262ZM10.9367 27.1935C11.2309 27.5277 11.5929 27.7953 11.9987 27.9787C12.4044 28.162 12.8445 28.2569 13.2898 28.2569C13.735 28.2569 14.1751 28.162 14.5809 27.9787C14.9866 27.7953 15.3486 27.5277 15.6428 27.1935C14.7978 27.2636 14.0361 27.2958 13.3066 27.2958C12.5577 27.2994 11.7848 27.2646 10.9367 27.1935Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2898 31.312C11.9197 31.3121 10.5879 30.8608 9.50003 30.028C8.4122 29.1951 7.629 28.0272 7.27151 26.7046C5.38406 26.3472 3.52913 25.8355 1.7254 25.1746C1.43086 25.0659 1.17657 24.8698 0.99664 24.6125C0.816707 24.3552 0.719725 24.0491 0.718693 23.7351V21.2526C0.718181 21.0263 0.767932 20.8026 0.864356 20.5978C0.960779 20.393 1.10147 20.2122 1.27627 20.0684C2.09064 19.3978 2.55512 18.4289 2.55512 17.4084V12.845C2.5574 11.387 2.86416 9.94566 3.45576 8.61316C4.04736 7.28065 4.91074 6.0864 5.99062 5.1069C7.01501 4.17435 8.21611 3.45682 9.52279 2.9968C9.73658 2.12966 10.2427 1.36271 10.956 0.8252C11.6693 0.28769 12.546 0.0124788 13.4385 0.045941C14.331 0.0794032 15.1846 0.419493 15.8556 1.0089C16.5267 1.59831 16.974 2.40101 17.1222 3.28173C20.969 4.97953 23.5241 8.92145 23.5241 13.2296V17.4104C23.5241 18.4627 24.0137 19.45 24.8664 20.1216C25.0493 20.2648 25.1971 20.4478 25.2986 20.6568C25.4001 20.8657 25.4526 21.095 25.4521 21.3273V23.8886C25.4514 24.2058 25.3527 24.515 25.1696 24.774C24.9865 25.0331 24.7278 25.2292 24.429 25.3357C22.7592 25.9254 21.0469 26.3871 19.307 26.7169C18.9463 28.0364 18.162 29.2009 17.0748 30.0312C15.9876 30.8614 14.6577 31.3115 13.2898 31.312ZM10.9367 27.1793C11.2305 27.5134 11.5921 27.781 11.9975 27.9643C12.4028 28.1476 12.8426 28.2424 13.2875 28.2424C13.7323 28.2424 14.1721 28.1476 14.5775 27.9643C14.9828 27.781 15.3444 27.5134 15.6382 27.1793C14.7942 27.2494 14.033 27.2816 13.3046 27.2816C12.5583 27.2852 11.7848 27.2509 10.9382 27.1793H10.9367Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2893 31.2977C11.9201 31.2972 10.5892 30.8456 9.50243 30.0128C8.41566 29.1799 7.63359 28.0122 7.27715 26.6902C5.39119 26.3329 3.53778 25.8212 1.73565 25.1602C1.44121 25.0513 1.18709 24.8551 1.00734 24.5977C0.827594 24.3403 0.73081 24.0342 0.72996 23.7202V21.2383C0.729154 21.0116 0.778712 20.7876 0.875051 20.5825C0.971391 20.3773 1.11211 20.1961 1.28703 20.052C2.10088 19.3814 2.56587 18.4125 2.56587 17.392V12.8306C2.56792 11.373 2.87417 9.93193 3.46503 8.59948C4.0559 7.26704 4.91837 6.07259 5.99728 5.09255C7.02067 4.15995 8.22095 3.4424 9.5269 2.98245C9.73987 2.11547 10.2454 1.34849 10.9581 0.810913C11.6709 0.273333 12.5472 -0.00193938 13.4394 0.0315386C14.3315 0.0650165 15.1848 0.405195 15.8552 0.994681C16.5257 1.58417 16.9723 2.38688 17.1197 3.26738C20.9629 4.96517 23.5139 8.90709 23.5139 13.2153V17.3961C23.5139 18.4483 24.0024 19.4356 24.8547 20.1072C25.0377 20.2501 25.1859 20.4328 25.2878 20.6414C25.3897 20.8501 25.4428 21.0792 25.4429 21.3114V23.8742C25.4421 24.1913 25.3433 24.5005 25.1602 24.7594C24.9771 25.0183 24.7186 25.2144 24.4199 25.3209C22.7514 25.9104 21.0405 26.3721 19.3019 26.702C18.9426 28.0215 18.1594 29.1863 17.0729 30.0168C15.9863 30.8472 14.6568 31.2973 13.2893 31.2977ZM10.9403 27.165C11.2337 27.4989 11.595 27.7665 12 27.9497C12.405 28.133 12.8445 28.2278 13.289 28.2278C13.7336 28.2278 14.173 28.133 14.578 27.9497C14.983 27.7665 15.3443 27.4989 15.6377 27.165C14.7942 27.235 14.0336 27.2673 13.3056 27.2673C12.5588 27.2708 11.7874 27.2366 10.9403 27.165Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2893 31.2833C11.9209 31.2822 10.5911 30.8303 9.50539 29.9975C8.41972 29.1646 7.63875 27.9972 7.28328 26.6758C5.39864 26.3186 3.54659 25.8068 1.74587 25.1458C1.45153 25.0369 1.19752 24.8405 1.01787 24.5832C0.838216 24.3258 0.741506 24.0197 0.740695 23.7058V21.2239C0.740254 20.9975 0.790038 20.7739 0.886456 20.5691C0.982875 20.3643 1.12353 20.1835 1.29827 20.0396C2.11111 19.369 2.57712 18.4002 2.57712 17.3796V12.8162C2.57893 11.359 2.88467 9.91815 3.4748 8.58576C4.06494 7.25338 4.92649 6.05873 6.00443 5.07814C7.02607 4.14544 8.2246 3.42737 9.52894 2.96651C9.74124 2.09971 10.2462 1.33276 10.9586 0.795226C11.671 0.257689 12.547 -0.0174858 13.4388 0.0161891C14.3306 0.049864 15.1834 0.390324 15.8532 0.980053C16.523 1.56978 16.9687 2.37263 17.1151 3.25297C20.9547 4.95077 23.5052 8.89269 23.5052 13.2009V17.3817C23.5052 18.4339 23.9937 19.4212 24.8454 20.0928C25.0279 20.2363 25.1754 20.4194 25.2766 20.6283C25.3778 20.8372 25.4301 21.0664 25.4296 21.2985V23.8614C25.4289 24.1786 25.3302 24.4878 25.1471 24.7468C24.964 25.0058 24.7053 25.202 24.4065 25.3085C22.739 25.8981 21.0289 26.3599 19.2912 26.6897C18.9326 28.0074 18.151 29.1708 17.0665 30.0008C15.9821 30.8308 14.6549 31.2814 13.2893 31.2833ZM10.9423 27.1506C11.2352 27.4846 11.596 27.7523 12.0007 27.9357C12.4053 28.1191 12.8445 28.214 13.2888 28.214C13.733 28.214 14.1722 28.1191 14.5768 27.9357C14.9815 27.7523 15.3423 27.4846 15.6352 27.1506C14.7927 27.2206 14.033 27.2529 13.3056 27.2529C12.5593 27.2564 11.7884 27.2222 10.9423 27.1506Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.2892 31.269C11.9214 31.2678 10.5921 30.8158 9.50712 29.9829C8.42212 29.15 7.64194 27.9827 7.28736 26.6616C5.40562 26.304 3.55648 25.7923 1.75864 25.1316C1.46449 25.0224 1.2107 24.826 1.03124 24.5687C0.851783 24.3113 0.755224 24.0053 0.75449 23.6916V21.2091C0.753974 20.983 0.803572 20.7595 0.899716 20.5548C0.99586 20.3502 1.13616 20.1694 1.31053 20.0254C2.12286 19.3548 2.58938 18.3859 2.58938 17.3654V12.8019C2.59053 11.3453 2.89526 9.90486 3.48414 8.57254C4.07301 7.24023 4.93309 6.04526 6.00953 5.06388C7.03123 4.13161 8.22976 3.41405 9.53404 2.95378C9.74537 2.08714 10.2495 1.32008 10.9613 0.782354C11.673 0.244624 12.5487 -0.030776 13.4401 0.00273378C14.3315 0.0362436 15.184 0.376606 15.8533 0.966254C16.5227 1.5559 16.9678 2.35864 17.1135 3.23871C20.9501 4.93651 23.498 8.87843 23.498 13.1866V17.3674C23.498 18.4197 23.986 19.4069 24.8367 20.0786C25.0192 20.222 25.1666 20.4052 25.2679 20.6141C25.3691 20.823 25.4214 21.0522 25.4209 21.2843V23.8471C25.4203 24.164 25.322 24.473 25.1394 24.732C24.9568 24.991 24.6987 25.1873 24.4004 25.2942C22.735 25.8838 21.0272 26.3455 19.2916 26.6754C18.9339 27.9936 18.1523 29.1576 17.0676 29.9877C15.9829 30.8178 14.6552 31.2681 13.2892 31.269ZM10.9444 27.1363C11.2369 27.4702 11.5974 27.7378 12.0017 27.9211C12.406 28.1044 12.8448 28.1992 13.2887 28.1992C13.7327 28.1992 14.1715 28.1044 14.5758 27.9211C14.9801 27.7378 15.3406 27.4702 15.6331 27.1363C14.7911 27.2064 14.032 27.2386 13.3056 27.2386C12.5598 27.2422 11.7894 27.2079 10.9444 27.1363Z"
            fill="#1E1E1E"
        />
        <path
            d="M13.299 29.6617C11.5173 29.6617 10.017 28.5537 9.47319 27.0268C10.7484 27.1806 12.0314 27.2621 13.3159 27.2708C14.5865 27.2708 15.8557 27.1726 17.1243 27.0319C16.579 28.5553 15.0781 29.6617 13.299 29.6617ZM13.299 3.09748C13.5997 3.09586 13.8949 3.1786 14.151 3.3363C14.407 3.49399 14.6138 3.72033 14.7477 3.9896C14.5093 3.95022 14.2832 3.87809 14.0397 3.85712C13.3401 3.79455 12.6362 3.79831 11.9373 3.86837C12.0798 3.63477 12.2797 3.44159 12.5181 3.30726C12.7565 3.17293 13.0254 3.10194 13.299 3.10106V3.09748ZM24.9314 21.385C23.8572 20.5783 23.2433 19.3869 23.2433 18.1112V13.9314C23.2433 9.65388 20.4692 5.92834 16.6061 4.4817C16.5217 2.80437 15.0781 1.46362 13.2985 1.46362C11.6058 1.46362 10.2185 2.68262 10.018 4.24844C8.60114 4.66096 7.29028 5.37502 6.17529 6.34166C4.05343 8.18116 2.83289 10.8094 2.83289 13.5467V18.1122C2.83289 19.346 2.24462 20.5164 1.22103 21.3226C1.12491 21.3976 1.04706 21.4934 0.993366 21.6028C0.939667 21.7122 0.911518 21.8325 0.911041 21.9543V24.4368C0.913164 24.6074 0.96854 24.7731 1.06943 24.9107C1.17032 25.0482 1.31167 25.1509 1.47373 25.2042C3.47746 25.9075 5.53806 26.4368 7.63267 26.7864C8.12835 29.3471 10.4758 31.2976 13.3 31.2976C16.1242 31.2976 18.4666 29.3538 18.9668 26.7996C20.9097 26.475 22.8232 25.9944 24.6889 25.3627C24.8529 25.3098 24.9961 25.2068 25.0985 25.0683C25.2009 24.9297 25.2573 24.7626 25.2598 24.5903V22.028C25.2586 21.9027 25.2284 21.7794 25.1714 21.6678C25.1144 21.5562 25.0322 21.4594 24.9314 21.385Z"
            fill="url(#paint0_linear_40_354)"
        />
        <path
            d="M13.2985 29.6474C11.5188 29.6474 10.0195 28.5394 9.47675 27.0125C10.7508 27.1664 12.0326 27.2478 13.3159 27.2565C14.585 27.2565 15.8531 27.1583 17.1207 27.0176C16.5759 28.541 15.0786 29.6474 13.2985 29.6474ZM13.2985 3.0832C13.5989 3.08135 13.8937 3.16377 14.1496 3.32111C14.4055 3.47844 14.6122 3.70439 14.7461 3.97328C14.5083 3.93389 14.2821 3.86176 14.0387 3.84079C13.3397 3.77822 12.6365 3.78199 11.9383 3.85204C12.0805 3.61853 12.2802 3.42538 12.5183 3.29105C12.7565 3.15671 13.0251 3.08568 13.2985 3.08473V3.0832ZM24.9222 21.3707C23.8479 20.564 23.2341 19.3727 23.2341 18.0969V13.9171C23.2341 9.6396 20.4621 5.91405 16.6025 4.46742C16.5181 2.79008 15.0761 1.44934 13.2974 1.44934C11.6094 1.44934 10.2205 2.66834 10.0205 4.23416C8.60458 4.64662 7.29471 5.36071 6.1809 6.32738C4.06057 8.16687 2.84311 10.7931 2.84311 13.5324V18.0979C2.84311 19.3317 2.25586 20.5021 1.23278 21.3083C1.13666 21.3833 1.05881 21.4791 1.00512 21.5885C0.951417 21.698 0.923268 21.8182 0.922791 21.9401V24.4226C0.924913 24.5932 0.98029 24.7588 1.08118 24.8964C1.18207 25.034 1.32342 25.1366 1.48548 25.1899C3.48747 25.8932 5.54637 26.4225 7.6393 26.7721C8.13447 29.3328 10.4794 31.2833 13.301 31.2833C16.1227 31.2833 18.463 29.3395 18.9632 26.7854C20.9028 26.4605 22.8131 25.9799 24.6756 25.3485C24.8396 25.2955 24.9828 25.1926 25.0852 25.054C25.1876 24.9155 25.244 24.7483 25.2465 24.576V22.0137C25.2456 21.8886 25.2158 21.7655 25.1592 21.6539C25.1027 21.5423 25.021 21.4454 24.9206 21.3707H24.9222Z"
            fill="url(#paint1_linear_40_354)"
        />
        <path
            d="M13.2985 29.6331C11.5198 29.6331 10.0246 28.5252 9.47982 26.9982C10.753 27.1521 12.0339 27.2336 13.3164 27.2422C14.585 27.2422 15.8516 27.144 17.1181 27.0033C16.5738 28.5267 15.0776 29.6331 13.2995 29.6331H13.2985ZM13.2985 3.06891C13.5986 3.06723 13.8932 3.14974 14.1489 3.30708C14.4045 3.46441 14.6109 3.69026 14.7446 3.95899C14.5067 3.9196 14.2811 3.84748 14.0376 3.8265C13.3391 3.76336 12.6362 3.76662 11.9383 3.83622C12.0803 3.60279 12.2799 3.40969 12.5178 3.27535C12.7558 3.14102 13.0242 3.06994 13.2974 3.06891H13.2985ZM24.9104 21.3564C23.8387 20.5497 23.2223 19.3584 23.2223 18.0826V13.9028C23.2223 9.62531 20.4529 5.89977 16.5964 4.45314C16.5125 2.7758 15.0715 1.43506 13.2949 1.43506C11.6068 1.43506 10.2205 2.65406 10.021 4.21988C8.60602 4.63237 7.29716 5.34648 6.18449 6.3131C4.06518 8.15464 2.84823 10.7834 2.84823 13.5207V18.0862C2.84823 19.32 2.26149 20.4904 1.23944 21.2966C1.14331 21.3716 1.06547 21.4674 1.01177 21.5768C0.95807 21.6862 0.92992 21.8064 0.929443 21.9283V24.4108C0.931566 24.5814 0.986942 24.7471 1.08783 24.8847C1.18872 25.0222 1.33007 25.1249 1.49214 25.1782C3.49261 25.8806 5.54998 26.4091 7.64135 26.7578C8.13601 29.3186 10.4794 31.2691 13.2985 31.2691C16.1176 31.2691 18.4553 29.3252 18.9551 26.7711C20.8945 26.4464 22.8047 25.9659 24.6669 25.3342C24.831 25.2814 24.9744 25.1785 25.0769 25.0399C25.1794 24.9013 25.2359 24.7341 25.2383 24.5617V21.9994C25.2375 21.8745 25.2078 21.7513 25.1514 21.6398C25.0951 21.5282 25.0136 21.4312 24.9135 21.3564H24.9104Z"
            fill="url(#paint2_linear_40_354)"
        />
        <path
            d="M13.2985 29.6177C11.5214 29.6177 10.0246 28.5097 9.4829 26.9828C10.7547 27.1366 12.0343 27.2181 13.3153 27.2268C14.5824 27.2268 15.848 27.1286 17.1135 26.9879C16.5698 28.5123 15.075 29.6177 13.2985 29.6177ZM13.2985 3.05349C13.5984 3.05179 13.8928 3.13432 14.1482 3.29167C14.4036 3.44902 14.6097 3.67488 14.7431 3.94357C14.5057 3.90418 14.2796 3.83205 14.0371 3.81108C13.3392 3.74848 12.637 3.75224 11.9398 3.82233C12.0816 3.58891 12.281 3.39579 12.5188 3.26145C12.7565 3.1271 13.0248 3.05603 13.298 3.05502L13.2985 3.05349ZM24.9012 21.341C23.8305 20.5343 23.2167 19.3429 23.2167 18.0672V13.8884C23.2167 9.61091 20.4498 5.88537 16.5969 4.43874C16.513 2.7614 15.0735 1.42065 13.2985 1.42065C11.6104 1.42065 10.2292 2.63965 10.0246 4.20547C8.61112 4.61827 7.30382 5.33239 6.19268 6.29869C4.07541 8.14023 2.85949 10.769 2.85949 13.5063V18.0718C2.85949 19.3056 2.27326 20.476 1.25223 21.2822C1.15617 21.3572 1.0784 21.453 1.02479 21.5625C0.971181 21.6719 0.943128 21.7921 0.942749 21.9139V24.3964C0.944619 24.5666 0.999473 24.7319 1.09968 24.8694C1.19989 25.007 1.34047 25.1098 1.50186 25.1637C3.50074 25.8663 5.55659 26.3948 7.64647 26.7434C8.14062 29.3041 10.4819 31.2546 13.2985 31.2546C16.115 31.2546 18.4507 29.3108 18.95 26.7567C20.8877 26.4323 22.7962 25.9521 24.6567 25.3208C24.8204 25.2677 24.9634 25.1646 25.0655 25.0261C25.1676 24.8875 25.2237 24.7205 25.226 24.5484V21.985C25.2253 21.8601 25.1955 21.7369 25.1392 21.6254C25.0828 21.5138 25.0014 21.4168 24.9012 21.342V21.341Z"
            fill="url(#paint3_linear_40_354)"
        />
        <path
            d="M13.298 29.6045C11.5229 29.6045 10.0277 28.4965 9.48648 26.9695C10.7571 27.1234 12.0355 27.2049 13.3153 27.2135C14.5814 27.2135 15.8454 27.1153 17.1099 26.9746C16.5667 28.498 15.073 29.6045 13.298 29.6045ZM13.298 3.04023C13.5978 3.03861 13.892 3.12117 14.1472 3.27852C14.4024 3.43588 14.6083 3.6617 14.7415 3.93031C14.5042 3.89092 14.2786 3.81879 14.0361 3.79782C13.3391 3.73525 12.6377 3.73902 11.9414 3.80907C12.0829 3.57577 12.282 3.38271 12.5195 3.24837C12.757 3.11402 13.0251 3.04288 13.298 3.04176V3.04023ZM24.8905 21.3278C23.8208 20.5211 23.2075 19.3297 23.2075 18.0539V13.8741C23.2075 9.59663 20.4452 5.87109 16.5938 4.42445C16.5099 2.74712 15.0715 1.40637 13.298 1.40637C11.6099 1.40637 10.2287 2.62537 10.0297 4.19119C8.61714 4.60396 7.31084 5.3181 6.20086 6.28441C4.08564 8.12595 2.87074 10.7548 2.87074 13.492V18.057C2.87074 19.2908 2.28503 20.4612 1.26502 21.2674C1.16904 21.3425 1.09134 21.4383 1.03773 21.5477C0.98413 21.6572 0.956027 21.7773 0.955536 21.8991V24.3796C0.957369 24.5497 1.01215 24.715 1.11226 24.8525C1.21237 24.99 1.35284 25.0929 1.51414 25.1469C3.51088 25.8502 5.56468 26.3796 7.65261 26.7291C8.14624 29.2899 10.485 31.2404 13.299 31.2404C16.113 31.2404 18.4471 29.2965 18.9459 26.7424C20.8815 26.4176 22.7877 25.9371 24.6459 25.3055C24.8096 25.2523 24.9525 25.1492 25.0546 25.0107C25.1567 24.8722 25.2129 24.7051 25.2153 24.5331V21.9708C25.2145 21.8458 25.1848 21.7227 25.1284 21.6111C25.0721 21.4995 24.9906 21.4025 24.8905 21.3278Z"
            fill="url(#paint4_linear_40_354)"
        />
        <path
            d="M13.2979 29.5902C11.5244 29.5902 10.0307 28.4822 9.48954 26.9552C10.759 27.1091 12.0361 27.1906 13.3148 27.1992C14.5799 27.1992 15.8429 27.101 17.1064 26.9604C16.5631 28.4837 15.0715 29.5902 13.2979 29.5902ZM13.2979 3.02595C13.5976 3.0244 13.8917 3.10701 14.1467 3.26436C14.4017 3.42172 14.6075 3.64751 14.7405 3.91603C14.5031 3.87664 14.2801 3.80451 14.0351 3.78354C13.3387 3.72097 12.638 3.72474 11.9424 3.7948C12.0837 3.56154 12.2826 3.3685 12.52 3.23414C12.7573 3.09979 13.0252 3.02863 13.2979 3.02749V3.02595ZM24.8802 21.3135C23.8116 20.5068 23.1988 19.3154 23.1988 18.0396V13.8624C23.1988 9.58491 20.4365 5.85937 16.5912 4.41273C16.5073 2.7354 15.0704 1.39465 13.2985 1.39465C11.6135 1.39465 10.2328 2.61365 10.0328 4.17947C8.62236 4.59118 7.31786 5.30355 6.20904 6.26758C4.09587 8.10912 2.88403 10.7379 2.88403 13.4752V18.0407C2.88403 19.2745 2.29883 20.4449 1.27934 21.2511C1.18392 21.3265 1.10676 21.4225 1.05361 21.5318C1.00047 21.6412 0.972711 21.7612 0.972412 21.8828V24.3653C0.974245 24.5354 1.02902 24.7007 1.12914 24.8382C1.22925 24.9757 1.36971 25.0786 1.53101 25.1326C3.52534 25.8358 5.57675 26.3652 7.66232 26.7148C8.15545 29.2756 10.4927 31.2261 13.3041 31.2261C16.1155 31.2261 18.4471 29.2822 18.9459 26.7281C20.8801 26.4033 22.785 25.9228 24.6418 25.2912C24.8053 25.2379 24.948 25.1347 25.0499 24.9962C25.1518 24.8577 25.2079 24.6907 25.2102 24.5188V21.9565C25.2088 21.8311 25.1783 21.7077 25.1211 21.5961C25.0638 21.4844 24.9813 21.3877 24.8802 21.3135Z"
            fill="url(#paint5_linear_40_354)"
        />
        <path
            d="M13.2975 29.5758C11.5255 29.5758 10.0333 28.4678 9.49263 26.9408C10.761 27.0947 12.0372 27.1762 13.3148 27.1848C14.5784 27.1848 15.8403 27.0866 17.1002 26.946C16.5575 28.4693 15.0669 29.5758 13.2949 29.5758H13.2975ZM13.2975 3.01154C13.597 3.01001 13.8909 3.09263 14.1458 3.24999C14.4006 3.40736 14.6062 3.63314 14.739 3.90162C14.5016 3.86223 14.2786 3.7901 14.0346 3.76913C13.3387 3.70656 12.6385 3.71033 11.9434 3.78039C12.0845 3.54722 12.2831 3.35421 12.5202 3.21985C12.7573 3.08549 13.0249 3.01429 13.2975 3.01308V3.01154ZM24.8695 21.2991C23.8014 20.4924 23.1896 19.301 23.1896 18.0252V13.8454C23.1922 9.56794 20.4298 5.8424 16.5877 4.39577C16.5038 2.71843 15.0679 1.37769 13.2975 1.37769C11.6145 1.37769 10.2349 2.59668 10.0354 4.1625C8.625 4.57558 7.32097 5.28955 6.21315 6.25521C4.10203 8.09675 2.88815 10.7261 2.88815 13.4633V18.0262C2.88815 19.2601 2.30346 20.4305 1.28498 21.2367C1.18953 21.312 1.11235 21.408 1.05921 21.5174C1.00606 21.6268 0.978319 21.7468 0.978058 21.8684V24.3489C0.979818 24.5188 1.03445 24.684 1.13437 24.8215C1.23428 24.959 1.37453 25.062 1.53564 25.1162C3.52794 25.8205 5.57749 26.3511 7.66132 26.7019C8.15291 29.2596 10.4886 31.2117 13.2975 31.2117C16.1063 31.2117 18.4364 29.2678 18.9341 26.7137C20.8665 26.3889 22.7695 25.9084 24.6245 25.2768C24.7879 25.2234 24.9304 25.1202 25.0323 24.9816C25.1341 24.8431 25.19 24.6763 25.1923 24.5044V21.9446C25.1916 21.8197 25.1621 21.6967 25.1059 21.5851C25.0497 21.4736 24.9684 21.3765 24.8685 21.3016L24.8695 21.2991Z"
            fill="url(#paint6_linear_40_354)"
        />
        <path
            d="M13.2974 29.5615C11.527 29.5615 10.0364 28.4535 9.49619 26.9266C10.7632 27.0804 12.038 27.1619 13.3143 27.1706C14.5773 27.1706 15.8383 27.0723 17.0997 26.9317C16.558 28.455 15.0689 29.5615 13.2985 29.5615H13.2974ZM13.2974 2.99726C13.5967 2.99583 13.8904 3.07851 14.145 3.23588C14.3996 3.39325 14.6049 3.61898 14.7374 3.88734C14.5006 3.84795 14.277 3.77582 14.0335 3.75485C13.3384 3.69228 12.6389 3.69605 11.9444 3.7661C12.0853 3.53302 12.2838 3.34005 12.5207 3.20569C12.7576 3.07132 13.0251 3.00008 13.2974 2.99879V2.99726ZM24.8582 21.2848C23.7917 20.4781 23.1799 19.2867 23.1799 18.0109V13.8311C23.1799 9.55366 20.4227 5.82812 16.5841 4.38148C16.5002 2.70415 15.0658 1.3634 13.2969 1.3634C11.615 1.3634 10.2369 2.5824 10.0374 4.14822C8.62835 4.56127 7.32569 5.27508 6.21927 6.24042C4.11173 8.08196 2.89836 10.7092 2.89836 13.4465V18.012C2.89836 19.2458 2.31418 20.4162 1.29673 21.2224C1.20128 21.2978 1.1241 21.3937 1.07096 21.5031C1.01781 21.6125 0.990068 21.7325 0.989807 21.8541V24.3366C0.991531 24.5065 1.04609 24.6717 1.14591 24.8092C1.24573 24.9467 1.38586 25.0497 1.54687 25.1039C3.53754 25.8071 5.58539 26.3365 7.66744 26.6861C8.16005 29.2469 10.4927 31.1974 13.299 31.1974C16.1053 31.1974 18.4328 29.2535 18.9305 26.6994C20.8614 26.3747 22.7629 25.8941 24.6163 25.2625C24.7797 25.2091 24.9222 25.1059 25.0241 24.9674C25.1259 24.8289 25.1818 24.662 25.1841 24.4901V21.9278C25.1834 21.8029 25.1539 21.6798 25.0977 21.5683C25.0415 21.4567 24.9602 21.3597 24.8603 21.2848H24.8582Z"
            fill="url(#paint7_linear_40_354)"
        />
        <path
            d="M13.2974 29.5467C11.5285 29.5467 10.0389 28.4392 9.49926 26.9123C10.7653 27.0661 12.039 27.1476 13.3143 27.1563C14.5758 27.1563 15.8357 27.0581 17.0951 26.9174C16.5539 28.4408 15.0658 29.5467 13.2974 29.5467ZM13.2974 2.98298C13.5965 2.98166 13.89 3.0644 14.1443 3.22177C14.3986 3.37915 14.6036 3.60482 14.7359 3.87306C14.499 3.83367 14.2755 3.76154 14.0325 3.74057C13.3381 3.678 12.6392 3.68177 11.9454 3.75182C12.086 3.51862 12.2842 3.32552 12.521 3.19113C12.7578 3.05674 13.0252 2.98558 13.2974 2.98451V2.98298ZM24.848 21.2705C23.7825 20.4638 23.1712 19.2724 23.1712 17.9967V13.8169C23.1712 9.53937 20.4165 5.81384 16.581 4.3672C16.4976 2.68987 15.0643 1.34912 13.2974 1.34912C11.617 1.34912 10.24 2.56812 10.0405 4.13394C8.63234 4.54724 7.33069 5.26125 6.22541 6.22665C4.11787 8.06819 2.90757 10.6975 2.90757 13.4347V18.0002C2.90757 19.2341 2.32339 20.4045 1.30747 21.2107C1.21203 21.286 1.13485 21.382 1.0817 21.4914C1.02855 21.6008 1.00081 21.7208 1.00055 21.8424V24.3249C1.00227 24.4948 1.05683 24.66 1.15665 24.7974C1.25647 24.9349 1.3966 25.038 1.55762 25.0922C3.54635 25.7954 5.59233 26.3248 7.67256 26.6744C8.16414 29.2352 10.4947 31.1857 13.2995 31.1857C16.1043 31.1857 18.4287 29.2418 18.9264 26.6877C20.8556 26.3629 22.7554 25.8823 24.6071 25.2508C24.7703 25.1972 24.9126 25.0939 25.0142 24.9554C25.1159 24.8169 25.1717 24.6501 25.1738 24.4784V21.9135C25.1732 21.7887 25.1437 21.6656 25.0876 21.5541C25.0315 21.4425 24.9504 21.3455 24.8506 21.2705H24.848Z"
            fill="url(#paint8_linear_40_354)"
        />
        <path
            d="M13.2969 29.5323C11.5301 29.5323 10.0415 28.4248 9.50232 26.8979C10.7673 27.0517 12.04 27.1332 13.3143 27.1419C14.5742 27.1419 15.8331 27.0437 17.0915 26.903C16.5508 28.4263 15.0643 29.5323 13.2969 29.5323ZM13.2969 2.96857C13.5959 2.96727 13.8892 3.05003 14.1433 3.20741C14.3975 3.36479 14.6023 3.59046 14.7343 3.85865C14.498 3.81926 14.274 3.74714 14.032 3.72616C13.338 3.66359 12.6397 3.66736 11.9465 3.73742C12.0868 3.50443 12.2848 3.31148 12.5213 3.1771C12.7578 3.04272 13.0249 2.97144 13.2969 2.97011V2.96857ZM24.8372 21.2561C23.7727 20.4494 23.162 19.258 23.162 17.9822V13.8025C23.162 9.52497 20.4099 5.79943 16.5779 4.3528C16.4946 2.67546 15.0628 1.33472 13.2969 1.33472C11.6186 1.33472 10.2425 2.55371 10.0435 4.11954C8.63634 4.53288 7.3357 5.24691 6.23154 6.21224C4.12605 8.05378 2.91677 10.6831 2.91677 13.4203V17.9858C2.91677 19.2197 2.3331 20.3901 1.3177 21.1962C1.22228 21.2717 1.14512 21.3676 1.09197 21.477C1.03883 21.5864 1.01107 21.7064 1.01077 21.828V24.3079C1.01242 24.4777 1.06683 24.6428 1.16646 24.7803C1.26608 24.9177 1.406 25.0208 1.56682 25.0753C3.55365 25.7784 5.59776 26.3078 7.67613 26.6574C8.16772 29.2182 10.4962 31.1687 13.2974 31.1687C16.0986 31.1687 18.4225 29.2249 18.9187 26.6707C20.8472 26.3461 22.7461 25.8656 24.5968 25.2338C24.76 25.1802 24.9023 25.0769 25.0038 24.9384C25.1053 24.7999 25.161 24.6331 25.1631 24.4614V21.8991C25.1625 21.7742 25.133 21.6512 25.0769 21.5397C25.0208 21.4281 24.9396 21.3311 24.8398 21.2561H24.8372Z"
            fill="url(#paint9_linear_40_354)"
        />
        <path
            d="M13.2969 29.518C11.5311 29.518 10.0446 28.4105 9.5059 26.8836C10.7695 27.0374 12.0409 27.1189 13.3138 27.1276C14.5732 27.1276 15.8306 27.0294 17.0879 26.8887C16.5478 28.4121 15.0628 29.518 13.2969 29.518ZM13.2969 2.95429C13.5957 2.95303 13.8887 3.03583 14.1426 3.19322C14.3965 3.35062 14.6011 3.57626 14.7328 3.84437C14.4965 3.80498 14.2724 3.73285 14.031 3.71188C13.3377 3.64931 12.64 3.65308 11.9475 3.72313C12.0878 3.4903 12.2856 3.29744 12.5219 3.16307C12.7582 3.0287 13.0251 2.95733 13.2969 2.95583V2.95429ZM24.8291 21.2418C23.7651 20.4351 23.1553 19.2437 23.1553 17.968V13.7882C23.1553 9.51069 20.4058 5.78515 16.5769 4.33852C16.4935 2.66118 15.0628 1.32043 13.299 1.32043C11.6216 1.32043 10.2471 2.53943 10.0481 4.10525C8.64213 4.51888 7.34272 5.2329 6.23973 6.19796C4.13628 8.0395 2.92802 10.6688 2.92802 13.4061V17.9715C2.92802 19.2054 2.34487 20.3758 1.33048 21.182C1.23506 21.2574 1.1579 21.3533 1.10476 21.4627C1.05162 21.5721 1.02386 21.6921 1.02356 21.8137V24.2962C1.02517 24.4659 1.07951 24.6309 1.17903 24.7684C1.27856 24.9059 1.41837 25.009 1.57909 25.0635C3.56419 25.7666 5.60659 26.296 7.68329 26.6457C8.17437 29.2065 10.5008 31.157 13.2995 31.157C16.0981 31.157 18.4195 29.2131 18.9157 26.659C20.841 26.3329 22.7366 25.8511 24.584 25.2185C24.7471 25.1648 24.8893 25.0615 24.9907 24.923C25.0921 24.7845 25.1478 24.6178 25.1498 24.4461V21.8848C25.1494 21.7602 25.1202 21.6373 25.0646 21.5258C25.009 21.4143 24.9284 21.3171 24.8291 21.2418Z"
            fill="url(#paint10_linear_40_354)"
        />
        <path
            d="M13.2969 29.5037C11.5326 29.5037 10.0471 28.3962 9.50897 26.8693C10.7716 27.0225 12.0419 27.1033 13.3138 27.1113C14.5717 27.1113 15.828 27.013 17.0844 26.8724C16.5447 28.3957 15.0607 29.5017 13.299 29.5017L13.2969 29.5037ZM13.2969 2.94C13.5951 2.93924 13.8875 3.02227 14.1407 3.17965C14.394 3.33703 14.5979 3.5624 14.7292 3.83008C14.4934 3.79069 14.2688 3.71857 14.0279 3.69759C13.3353 3.63502 12.6383 3.63879 11.9465 3.70885C12.0864 3.47596 12.284 3.28303 12.5202 3.14864C12.7564 3.01425 13.0232 2.94293 13.2949 2.94154L13.2969 2.94ZM24.8163 21.2286C23.7533 20.4219 23.1441 19.2305 23.1441 17.9547V13.7739C23.1441 9.4964 20.3971 5.77086 16.5718 4.32423C16.491 2.64689 15.0587 1.30359 13.2969 1.30359C11.6211 1.30359 10.2476 2.52259 10.0486 4.08841C8.64379 4.50221 7.34558 5.21624 6.24382 6.18112C4.1419 8.02368 2.93518 10.652 2.93518 13.3892V17.9547C2.93518 19.1885 2.35254 20.3589 1.33918 21.1651C1.24376 21.2405 1.1666 21.3365 1.11346 21.4459C1.06031 21.5553 1.03256 21.6753 1.03226 21.7969V24.2794C1.03383 24.449 1.08809 24.614 1.18752 24.7514C1.28696 24.8889 1.42665 24.9921 1.58728 25.0467C3.57046 25.7498 5.61099 26.2791 7.68585 26.6289C8.17641 29.1896 10.4993 31.1401 13.2974 31.1401C16.0956 31.1401 18.4128 29.1963 18.9085 26.6422C20.8325 26.3173 22.727 25.8367 24.5733 25.2053C24.7362 25.1514 24.8783 25.0481 24.9796 24.9096C25.081 24.7711 25.1365 24.6044 25.1385 24.4328V21.8705C25.1378 21.746 25.1083 21.6232 25.0524 21.5119C24.9965 21.4005 24.9157 21.3036 24.8163 21.2286Z"
            fill="url(#paint11_linear_40_354)"
        />
        <path
            d="M13.2964 29.4893C11.5342 29.4893 10.0497 28.3818 9.51104 26.8549C10.7724 27.0087 12.0416 27.0902 13.3123 27.0989C14.5697 27.0989 15.8245 27.0007 17.0798 26.86C16.5406 28.3834 15.0582 29.4893 13.2944 29.4893H13.2964ZM13.2964 2.92559C13.5946 2.92464 13.8871 3.0076 14.1404 3.165C14.3937 3.3224 14.5975 3.54787 14.7287 3.81567C14.4929 3.77628 14.2684 3.70415 14.0284 3.68318C13.3364 3.62059 12.6399 3.62436 11.9485 3.69443C12.0882 3.46163 12.2855 3.26873 12.5215 3.13433C12.7574 2.99994 13.0239 2.92858 13.2954 2.92712L13.2964 2.92559ZM24.8061 21.2131C23.7441 20.4064 23.1349 19.215 23.1349 17.9393V13.76C23.1349 9.4825 20.3905 5.75696 16.5687 4.31032C16.4854 2.63299 15.0572 1.29224 13.2949 1.29224C11.6222 1.28917 10.2502 2.50612 10.0517 4.07399C8.64777 4.48773 7.35055 5.20179 6.24998 6.1667C4.15267 8.00824 2.9439 10.6376 2.9439 13.3748V17.9403C2.9439 19.1741 2.36228 20.3445 1.34943 21.1507C1.25407 21.2262 1.17695 21.3221 1.12381 21.4315C1.07067 21.5409 1.04288 21.6609 1.04251 21.7825V24.265C1.04405 24.4345 1.09823 24.5995 1.19757 24.7369C1.2969 24.8744 1.43649 24.9776 1.59702 25.0323C3.57912 25.7354 5.61865 26.2648 7.69252 26.6145C8.18258 29.1752 10.506 31.1257 13.2985 31.1257C16.091 31.1257 18.4093 29.1819 18.9049 26.6278C20.8268 26.3028 22.7191 25.8222 24.5631 25.1908C24.726 25.137 24.8681 25.0337 24.9694 24.8952C25.0708 24.7567 25.1263 24.59 25.1283 24.4184V21.8561C25.1277 21.7314 25.0982 21.6085 25.0424 21.497C24.9865 21.3854 24.9056 21.2883 24.8061 21.2131Z"
            fill="url(#paint12_linear_40_354)"
        />
        <path
            d="M13.2964 29.475C11.5357 29.475 10.0528 28.3676 9.51514 26.8406C10.7755 26.9945 12.0436 27.0759 13.3133 27.0846C14.5691 27.0846 15.8234 26.9864 17.0772 26.8457C16.5386 28.3691 15.0571 29.475 13.2964 29.475ZM13.2964 2.91132C13.5947 2.90995 13.8874 2.99274 14.1407 3.15019C14.3941 3.30763 14.5979 3.53334 14.7287 3.8014C14.4934 3.76201 14.2684 3.68988 14.029 3.66891C13.3376 3.60634 12.6418 3.61011 11.9511 3.68017C12.0906 3.44744 12.2878 3.25459 12.5235 3.12019C12.7592 2.98579 13.0256 2.91439 13.2969 2.91286L13.2964 2.91132ZM24.7953 21.1988C23.7344 20.3922 23.1262 19.2008 23.1262 17.925V13.7452C23.1262 9.46772 20.3838 5.74218 16.5657 4.29555C16.4823 2.61821 15.0556 1.27747 13.2964 1.27747C11.6237 1.27747 10.2528 2.49646 10.0543 4.06229C8.65146 4.47618 7.35545 5.19025 6.25612 6.15499C4.15881 7.99653 2.95311 10.6258 2.95311 13.3631V17.9286C2.95311 19.1624 2.372 20.3328 1.36018 21.139C1.26481 21.2145 1.18769 21.3104 1.13455 21.4198C1.08142 21.5292 1.05363 21.6491 1.05325 21.7708V24.2532C1.05479 24.4228 1.10898 24.5878 1.20831 24.7252C1.30765 24.8627 1.44723 24.9659 1.60776 25.0206C3.58709 25.7237 5.62389 26.2531 7.69508 26.6027C8.18513 29.1635 10.505 31.114 13.2964 31.114C16.0879 31.114 18.4026 29.1702 18.8973 26.616C20.8184 26.2904 22.7098 25.809 24.5529 25.1766C24.7153 25.1223 24.8568 25.0188 24.9577 24.8803C25.0585 24.7419 25.1137 24.5754 25.1155 24.4042V21.8424C25.115 21.7177 25.0856 21.5948 25.0298 21.4832C24.974 21.3717 24.8933 21.2746 24.7938 21.1994L24.7953 21.1988Z"
            fill="url(#paint13_linear_40_354)"
        />
        <path
            d="M13.2959 29.4608C11.5367 29.4608 10.0553 28.3533 9.51872 26.8263C10.7779 26.9802 12.0448 27.0617 13.3133 27.0703C14.5676 27.0703 15.8199 26.9721 17.0736 26.8314C16.5355 28.3548 15.0556 29.4608 13.2959 29.4608ZM13.2959 2.89704C13.5942 2.89567 13.8868 2.97846 14.1402 3.13591C14.3936 3.29335 14.5974 3.51906 14.7282 3.78712C14.4929 3.74773 14.2678 3.6756 14.029 3.65463C13.3382 3.59206 12.6431 3.59583 11.9531 3.66588C12.0923 3.43321 12.2892 3.24036 12.5247 3.10595C12.7602 2.97155 13.0263 2.90012 13.2975 2.89857L13.2959 2.89704ZM24.7846 21.1846C23.7247 20.3779 23.117 19.1865 23.117 17.9107V13.7309C23.117 9.45344 20.3787 5.72892 16.5626 4.28126C16.4792 2.60393 15.0536 1.26318 13.2959 1.26318C11.6247 1.26318 10.2553 2.48218 10.0569 4.048C8.65469 4.46112 7.35919 5.17432 6.26021 6.13815C4.1629 7.97969 2.96027 10.609 2.96027 13.3463V17.9117C2.96027 19.1456 2.37968 20.316 1.36887 21.1222C1.27351 21.1976 1.19639 21.2936 1.14325 21.403C1.09011 21.5124 1.06232 21.6323 1.06195 21.7539V24.2364C1.06345 24.4059 1.11756 24.5708 1.2168 24.7083C1.31604 24.8457 1.45551 24.9489 1.61595 25.0037C3.59356 25.7067 5.62865 26.2361 7.69815 26.5859C8.18718 29.1467 10.5055 31.0972 13.2939 31.0972C16.0823 31.0972 18.396 29.1533 18.8901 26.5992C20.8102 26.2744 22.7006 25.7939 24.5426 25.1623C24.7051 25.1081 24.8467 25.0046 24.9476 24.8661C25.0484 24.7276 25.1036 24.5612 25.1053 24.3899V21.8276C25.1049 21.7028 25.0756 21.5799 25.0198 21.4684C24.964 21.3568 24.8831 21.2597 24.7836 21.1846H24.7846Z"
            fill="url(#paint14_linear_40_354)"
        />
        <path
            d="M13.2959 29.4464C11.5382 29.4464 10.0579 28.3389 9.52176 26.8119C10.7797 26.9658 12.0455 27.0473 13.3128 27.0559C14.5666 27.0559 15.8193 26.9577 17.07 26.817C16.5324 28.3404 15.0535 29.4464 13.2959 29.4464ZM13.2959 2.88264C13.5938 2.88175 13.8858 2.96477 14.1387 3.12218C14.3916 3.2796 14.595 3.50505 14.7256 3.77271C14.4903 3.73333 14.2653 3.6612 14.0269 3.64023C13.3367 3.57765 12.6421 3.58142 11.9526 3.65148C12.0916 3.41889 12.2883 3.22608 12.5236 3.09167C12.7589 2.95726 13.0249 2.88579 13.2959 2.88417V2.88264ZM24.7743 21.1702C23.7154 20.3635 23.1077 19.1721 23.1077 17.8963V13.7165C23.1077 9.43903 20.3705 5.71298 16.56 4.26686C16.4772 2.58952 15.053 1.24878 13.2969 1.24878C11.6273 1.24878 10.2589 2.46778 10.0609 4.0336C8.66017 4.44771 7.36637 5.16181 6.2694 6.12631C4.17515 7.96785 2.97252 10.5972 2.97252 13.3344V17.8999C2.97252 19.1337 2.39193 20.3041 1.38215 21.1103C1.28681 21.1858 1.20971 21.2818 1.15658 21.3912C1.10344 21.5005 1.07564 21.6205 1.07523 21.7421V24.2246C1.07666 24.394 1.13061 24.5587 1.22966 24.6962C1.3287 24.8336 1.46795 24.9369 1.6282 24.9919C3.60391 25.6948 5.63713 26.2242 7.70477 26.5741C8.19381 29.1348 10.5095 31.0853 13.2959 31.0853C16.0823 31.0853 18.3929 29.1415 18.887 26.5874C20.8048 26.2616 22.6928 25.7802 24.5324 25.1479C24.6949 25.0937 24.8364 24.9902 24.9373 24.8517C25.0382 24.7132 25.0933 24.5468 25.0951 24.3755V21.8132C25.0946 21.6885 25.0654 21.5656 25.0096 21.4541C24.9539 21.3426 24.8732 21.2454 24.7738 21.1702H24.7743Z"
            fill="url(#paint15_linear_40_354)"
        />
        <path
            d="M13.2959 29.4321C11.5398 29.4321 10.0609 28.3246 9.52485 26.7976C10.7818 26.9515 12.0465 27.033 13.3128 27.0417C14.565 27.0417 15.8158 26.9434 17.0665 26.8028C16.5294 28.3261 15.052 29.4321 13.2959 29.4321ZM13.2959 2.86835C13.5935 2.86775 13.8853 2.95091 14.1378 3.10831C14.3904 3.26571 14.5936 3.491 14.7241 3.75843C14.4888 3.71904 14.2637 3.64692 14.0259 3.62594C13.3364 3.56337 12.6424 3.56714 11.9536 3.6372C12.0924 3.40462 12.2889 3.21179 12.5241 3.07737C12.7592 2.94296 13.0251 2.87149 13.2959 2.86989V2.86835ZM24.7636 21.1559C23.7057 20.3492 23.0991 19.1578 23.0991 17.882V13.7022C23.0991 9.42475 20.3644 5.6987 16.556 4.25258C16.4731 2.57524 15.05 1.2345 13.2959 1.2345C11.6278 1.2345 10.2599 2.4535 10.0625 4.01932C8.66286 4.4336 7.37026 5.14771 6.27453 6.11202C4.18233 7.95356 2.98073 10.5829 2.98073 13.3201V17.8831C2.98073 19.1169 2.40064 20.2873 1.39188 21.0935C1.29655 21.1689 1.21945 21.2649 1.16631 21.3743C1.11318 21.4837 1.08537 21.6036 1.08496 21.7252V24.2077C1.08635 24.3771 1.14024 24.5418 1.23919 24.6792C1.33813 24.8166 1.47727 24.92 1.63742 24.975C3.61137 25.678 5.6429 26.2074 7.70888 26.5572C8.19689 29.118 10.5111 31.0685 13.2949 31.0685C16.0787 31.0685 18.3873 29.1246 18.8809 26.5705C20.7972 26.2456 22.6839 25.765 24.5222 25.1336C24.6846 25.0794 24.8261 24.9758 24.927 24.8374C25.0278 24.6989 25.083 24.5325 25.0848 24.3612V21.7989C25.0844 21.6742 25.0551 21.5513 24.9994 21.4398C24.9437 21.3283 24.863 21.2311 24.7636 21.1559Z"
            fill="url(#paint16_linear_40_354)"
        />
        <path
            d="M13.2954 29.4178C11.5408 29.4178 10.0635 28.3103 9.52792 26.7834C10.784 26.9373 12.0479 27.0187 13.3133 27.0274C14.565 27.0274 15.8142 26.9292 17.0639 26.7885C16.5268 28.3118 15.051 29.4178 13.2964 29.4178H13.2954ZM13.2954 2.85356C13.593 2.85278 13.8847 2.93592 14.1372 3.09346C14.3896 3.25099 14.5925 3.47652 14.7226 3.74415C14.4878 3.70476 14.2648 3.63263 14.0249 3.61166C13.336 3.54909 12.6428 3.55286 11.9547 3.62291C12.0933 3.39049 12.2895 3.19775 12.5243 3.06334C12.7592 2.92892 13.0248 2.85737 13.2954 2.85561V2.85356ZM24.7539 21.1416C23.6965 20.3349 23.0904 19.1435 23.0904 17.8677V13.688C23.0898 9.412 20.3577 5.68442 16.5529 4.2383C16.4705 2.56096 15.0484 1.22021 13.2954 1.22021C11.6288 1.22021 10.2625 2.43921 10.065 4.00503C8.66615 4.41923 7.37437 5.13338 6.27964 6.09774C4.18898 7.93928 2.9884 10.5686 2.9884 13.3058V17.8713C2.9884 19.1052 2.40934 20.2756 1.40263 21.0817C1.30735 21.1573 1.23029 21.2533 1.17716 21.3626C1.12403 21.472 1.09619 21.5919 1.0957 21.7135V24.1955C1.09706 24.3648 1.15087 24.5294 1.24972 24.6669C1.34857 24.8043 1.4876 24.9077 1.64765 24.9628C3.61971 25.6657 5.64936 26.1951 7.71349 26.545C8.20149 29.1058 10.5131 31.0563 13.2944 31.0563C16.0756 31.0563 18.3827 29.1124 18.8758 26.5583C20.7905 26.2327 22.6755 25.7514 24.5119 25.1193C24.6744 25.0651 24.816 24.9616 24.9169 24.8231C25.0177 24.6847 25.0729 24.5182 25.0746 24.3469V21.7846C25.0741 21.66 25.0449 21.5372 24.9893 21.4257C24.9337 21.3141 24.8531 21.2169 24.7539 21.1416Z"
            fill="url(#paint17_linear_40_354)"
        />
        <path
            d="M13.2954 29.4034C11.5423 29.4034 10.066 28.2959 9.53149 26.769C10.786 26.9228 12.0484 27.0043 13.3123 27.013C14.5625 27.013 15.8111 26.9148 17.0593 26.7741C16.5227 28.2974 15.0484 29.4034 13.2954 29.4034ZM13.2954 2.83916C13.5927 2.83854 13.8842 2.92178 14.1364 3.07931C14.3885 3.23684 14.5912 3.46227 14.7211 3.72975C14.4863 3.69036 14.2637 3.61823 14.0243 3.59726C13.336 3.53469 12.6433 3.53846 11.9557 3.60851C12.094 3.3761 12.2901 3.18335 12.5248 3.04892C12.7595 2.9145 13.0249 2.84295 13.2954 2.84121V2.83916ZM24.7426 21.1262C23.6868 20.3195 23.0806 19.1281 23.0806 17.8523V13.6736C23.0806 9.39607 20.3511 5.67002 16.5498 4.2239C16.4675 2.54656 15.0464 1.20581 13.2954 1.20581C11.6314 1.20326 10.265 2.42225 10.0681 3.98961C8.67052 4.404 7.38011 5.11816 6.2868 6.08232C4.19818 7.92386 2.99862 10.5532 2.99862 13.2904V17.8523C2.99862 19.0862 2.42007 20.2566 1.41285 21.0627C1.31757 21.1383 1.24051 21.2343 1.18738 21.3436C1.13425 21.453 1.10641 21.5729 1.10593 21.6945V24.177C1.10725 24.3462 1.16098 24.5108 1.25973 24.6483C1.35849 24.7857 1.49741 24.8891 1.65737 24.9443C3.6275 25.6472 5.65528 26.1766 7.71757 26.5265C8.20558 29.0873 10.5152 31.0378 13.2933 31.0378C16.0715 31.0378 18.3765 29.0939 18.8691 26.5398C20.7826 26.2156 22.6664 25.7358 24.5017 25.1049C24.6642 25.0507 24.8058 24.9472 24.9066 24.8087C25.0075 24.6703 25.0626 24.5038 25.0644 24.3325V21.7702C25.0639 21.6453 25.0347 21.5223 24.9788 21.4105C24.923 21.2988 24.8422 21.2015 24.7426 21.1262Z"
            fill="url(#paint18_linear_40_354)"
        />
        <path
            d="M13.2954 29.3891C12.4726 29.3936 11.6687 29.1423 10.9949 28.6701C10.3211 28.1979 9.81061 27.528 9.53405 26.7531C10.7876 26.907 12.0489 26.9884 13.3118 26.9971C14.5604 26.9971 15.8081 26.8989 17.0552 26.7582C16.7774 27.5316 16.2666 28.1998 15.5931 28.6707C14.9196 29.1416 14.1167 29.3921 13.2949 29.3875L13.2954 29.3891ZM13.2954 2.82482C13.5925 2.82419 13.8838 2.90744 14.1357 3.06498C14.3877 3.22253 14.59 3.44798 14.7195 3.71541C14.4852 3.67602 14.2627 3.60389 14.0233 3.58292C13.3357 3.52035 12.6436 3.52412 11.9567 3.59418C12.0949 3.36184 12.2907 3.16913 12.5253 3.0347C12.7598 2.90028 13.0251 2.82869 13.2954 2.82687V2.82482ZM24.7324 21.1129C23.6771 20.3062 23.0719 19.1148 23.0719 17.839V13.6577C23.0719 9.3802 20.3444 5.65414 16.5467 4.20802C16.4644 2.53069 15.0449 1.18994 13.2954 1.18994C11.6314 1.18994 10.2676 2.40894 10.0727 3.97476C8.67627 4.38932 7.38707 5.10349 6.29499 6.06747C4.20842 7.90901 3.00988 10.5383 3.00988 13.2756V17.8411C3.00988 19.0749 2.43184 20.2453 1.42411 21.0515C1.3288 21.127 1.25172 21.223 1.19859 21.3323C1.14546 21.4417 1.11763 21.5616 1.11719 21.6832V24.1652C1.11847 24.3344 1.17213 24.499 1.27079 24.6364C1.36944 24.7738 1.50825 24.8772 1.66812 24.9325C3.63651 25.6354 5.66259 26.1648 7.72321 26.5147C8.21019 29.0755 10.5177 31.026 13.2944 31.026C16.071 31.026 18.3729 29.0821 18.865 26.528C20.7767 26.2032 22.6586 25.7226 24.492 25.0911C24.6545 25.0369 24.796 24.9334 24.8969 24.7949C24.9978 24.6565 25.0529 24.49 25.0547 24.3187V21.7559C25.0542 21.6313 25.025 21.5085 24.9695 21.397C24.914 21.2855 24.8335 21.1883 24.7344 21.1129H24.7324Z"
            fill="url(#paint19_linear_40_354)"
        />
        <path
            d="M13.2949 29.3747C12.4729 29.379 11.6698 29.1278 10.9967 28.6558C10.3237 28.1839 9.81381 27.5146 9.53762 26.7403C10.7899 26.8942 12.0501 26.9756 13.3118 26.9843C14.5599 26.9843 15.806 26.8861 17.0521 26.7454C16.775 27.5186 16.2647 28.1868 15.5917 28.6577C14.9188 29.1287 14.1162 29.3792 13.2949 29.3747ZM13.2949 2.81052C13.5919 2.80996 13.883 2.89325 14.1347 3.05079C14.3865 3.20834 14.5886 3.43376 14.718 3.70111C14.4842 3.66172 14.2617 3.58959 14.0223 3.56862C13.3353 3.50605 12.6439 3.50982 11.9577 3.57987C12.0956 3.34759 12.2911 3.15489 12.5254 3.02045C12.7597 2.88601 13.0248 2.8144 13.2949 2.81256V2.81052ZM24.7216 21.0986C23.6674 20.2919 23.0627 19.1005 23.0627 17.8247V13.6449C23.0627 9.36742 20.3377 5.64137 16.5421 4.19423C16.4598 2.51689 15.0418 1.17615 13.2933 1.17615C11.6314 1.17615 10.2686 2.39515 10.0706 3.96097C8.6753 4.37558 7.38728 5.08977 6.29651 6.05367C4.21403 7.8947 3.01703 10.523 3.01703 13.2602V17.8257C3.01703 19.0596 2.43899 20.23 1.43382 21.0361C1.33851 21.1117 1.26143 21.2076 1.20829 21.317C1.15516 21.4264 1.12734 21.5463 1.12689 21.6679V24.1499C1.12817 24.319 1.18183 24.4836 1.28049 24.621C1.37915 24.7584 1.51796 24.8619 1.67782 24.9172C3.64411 25.6201 5.66815 26.1495 7.72677 26.4994C8.21376 29.0602 10.5193 31.0107 13.2928 31.0107C16.0664 31.0107 18.3673 29.0668 18.8589 26.5127C20.7682 26.1877 22.6477 25.7071 24.4787 25.0758C24.6412 25.0217 24.7828 24.9181 24.8837 24.7797C24.9846 24.6412 25.0397 24.4747 25.0414 24.3033V21.74C25.0408 21.6155 25.0117 21.4927 24.9562 21.3812C24.9007 21.2697 24.8202 21.1725 24.7211 21.097L24.7216 21.0986Z"
            fill="url(#paint20_linear_40_354)"
        />
        <path
            d="M13.2949 29.362C12.4733 29.3661 11.6707 29.1148 10.9981 28.6428C10.3256 28.1709 9.81628 27.5016 9.54071 26.7275C10.792 26.8814 12.0511 26.9628 13.3118 26.9715C14.5584 26.9715 15.8035 26.8733 17.046 26.7326C16.7696 27.5051 16.2604 28.1728 15.5885 28.6437C14.9167 29.1146 14.1153 29.3656 13.2949 29.362ZM13.2949 2.79772C13.5917 2.79721 13.8826 2.88053 14.1341 3.0381C14.3856 3.19566 14.5874 3.42105 14.7165 3.68831C14.4827 3.64892 14.2607 3.5768 14.0218 3.55582C13.3354 3.49325 12.6445 3.49702 11.9588 3.56708C12.0965 3.33488 12.2919 3.14221 12.526 3.00777C12.7601 2.87333 13.0249 2.80168 13.2949 2.79977V2.79772ZM24.7114 21.0842C23.6582 20.2775 23.054 19.0862 23.054 17.8104V13.6306C23.054 9.3531 20.3316 5.62653 16.5421 4.18092C16.4598 2.50359 15.0428 1.16284 13.2964 1.16284C11.6355 1.16284 10.2743 2.38184 10.0773 3.94766C8.68297 4.36088 7.39564 5.0735 6.30523 6.03576C4.22224 7.87731 3.02575 10.5066 3.02575 13.2439V17.8114C3.02575 19.0452 2.44873 20.2156 1.44407 21.0218C1.34882 21.0974 1.27178 21.1934 1.21865 21.3027C1.16552 21.4121 1.13767 21.532 1.13715 21.6536V24.1356C1.13839 24.3046 1.19198 24.4692 1.29054 24.6066C1.3891 24.744 1.52779 24.8475 1.68756 24.9029C3.65211 25.6057 5.67445 26.1352 7.7314 26.4851C8.21736 29.0458 10.5208 30.9963 13.2923 30.9963C16.0639 30.9963 18.3648 29.055 18.8558 26.4973C20.7636 26.1729 22.6416 25.6929 24.471 25.062C24.6335 25.0078 24.7751 24.9042 24.8759 24.7658C24.9768 24.6273 25.032 24.4608 25.0337 24.2895V21.7272C25.0332 21.6027 25.0041 21.48 24.9487 21.3685C24.8932 21.2571 24.8129 21.1598 24.714 21.0842H24.7114Z"
            fill="url(#paint21_linear_40_354)"
        />
        <path
            d="M13.2944 29.3461C12.4733 29.3501 11.6713 29.0986 10.9994 28.6267C10.3275 28.1547 9.81902 27.4855 9.54427 26.7117C10.7941 26.8662 12.0519 26.9484 13.3112 26.9578C14.5574 26.9578 15.8009 26.8595 17.0455 26.7189C16.7695 27.4915 16.2605 28.1595 15.5887 28.6305C14.9168 29.1014 14.1153 29.3522 13.2949 29.3482L13.2944 29.3461ZM13.2944 2.7819C13.591 2.78141 13.8818 2.86475 14.1331 3.02232C14.3844 3.17989 14.5861 3.40527 14.7149 3.67249C14.4816 3.63311 14.2596 3.56098 14.0207 3.54001C13.335 3.47744 12.6448 3.48121 11.9598 3.55126C12.0972 3.31915 12.2923 3.12651 12.5261 2.99207C12.76 2.85762 13.0246 2.78593 13.2944 2.78395V2.7819ZM24.7017 21.0699C23.6495 20.2632 23.0458 19.0719 23.0458 17.7961V13.6163C23.0458 9.33881 20.326 5.61276 16.5385 4.16664C16.4562 2.4893 15.0408 1.14856 13.2954 1.14856C11.6365 1.14856 10.2773 2.36756 10.0799 3.93338C8.68644 4.34731 7.40029 5.06066 6.31134 6.02353C4.2304 7.86507 3.03749 10.4944 3.03749 13.2316V17.7971C3.03749 19.0309 2.46098 20.2013 1.45734 21.0075C1.36229 21.0829 1.28538 21.1787 1.23226 21.2878C1.17914 21.3968 1.15117 21.5164 1.15042 21.6378V24.1197C1.15163 24.2888 1.20514 24.4533 1.3036 24.5907C1.40207 24.7281 1.54065 24.8316 1.70033 24.887C3.66229 25.5898 5.68207 26.1192 7.73649 26.4692C8.22245 29.03 10.5239 30.9805 13.2923 30.9805C16.0608 30.9805 18.3566 29.0367 18.8487 26.4825C20.7546 26.1575 22.6307 25.677 24.4582 25.0456C24.6202 24.991 24.7612 24.8873 24.8616 24.7489C24.962 24.6104 25.0168 24.4442 25.0183 24.2732V21.7129C25.0179 21.5884 24.9889 21.4657 24.9334 21.3542C24.878 21.2427 24.7977 21.1454 24.6986 21.0699H24.7017Z"
            fill="url(#paint22_linear_40_354)"
        />
        <path
            d="M13.2944 29.3318C12.4737 29.3355 11.6723 29.084 11.0009 28.612C10.3296 28.14 9.82161 27.4709 9.54736 26.6974C10.7962 26.8513 12.053 26.9327 13.3113 26.9414C14.5558 26.9414 15.7989 26.8432 17.0414 26.7025C16.7661 27.4749 16.2577 28.1428 15.5865 28.6138C14.9153 29.0848 14.1144 29.3357 13.2944 29.3318ZM13.2944 2.76762C13.5909 2.76725 13.8814 2.85066 14.1326 3.00822C14.3837 3.16579 14.5852 3.3911 14.7139 3.65821C14.4801 3.61882 14.2586 3.54669 14.0203 3.52521C13.335 3.46306 12.6453 3.467 11.9608 3.53697C12.098 3.3049 12.2929 3.11228 12.5266 2.97783C12.7603 2.84338 13.0248 2.77166 13.2944 2.76966V2.76762ZM24.6905 21.0557C23.6392 20.249 23.0361 19.0576 23.0361 17.7818V13.602C23.0361 9.32453 20.3183 5.59847 16.5345 4.15235C16.4521 2.47502 15.0377 1.13171 13.2944 1.13171C11.6365 1.13171 10.2763 2.35071 10.0814 3.91653C8.68928 4.33153 7.40468 5.04577 6.3175 6.00924C4.2381 7.85078 3.04365 10.4801 3.04365 13.2173V17.7828C3.04365 19.0167 2.46715 20.1871 1.46453 20.9932C1.3699 21.0692 1.29348 21.1653 1.2409 21.2746C1.18831 21.384 1.1609 21.5037 1.16068 21.625V24.107C1.16185 24.2759 1.21528 24.4404 1.31365 24.5778C1.41201 24.7152 1.55049 24.8188 1.71007 24.8743C3.67109 25.5774 5.69004 26.107 7.74367 26.457C8.22861 29.0178 10.528 30.9683 13.2944 30.9683C16.0608 30.9683 18.355 29.0244 18.8456 26.4703C20.7499 26.1453 22.6243 25.6647 24.45 25.0334C24.612 24.9787 24.7529 24.875 24.8532 24.7366C24.9535 24.5981 25.0082 24.4319 25.0097 24.261V21.6987C25.0092 21.5742 24.9802 21.4515 24.9249 21.34C24.8695 21.2285 24.7893 21.1312 24.6905 21.0557Z"
            fill="url(#paint23_linear_40_354)"
        />
        <path
            d="M13.2944 29.3174C12.4741 29.3209 11.6731 29.0692 11.0023 28.5972C10.3315 28.1252 9.82411 27.4563 9.55042 26.683C10.7983 26.8369 12.054 26.9184 13.3113 26.927C14.5543 26.927 15.7963 26.8288 17.0378 26.6881C16.7631 27.4602 16.2554 28.1279 15.5848 28.5989C14.9142 29.0699 14.1139 29.321 13.2944 29.3174ZM13.2944 2.75322C13.5906 2.75296 13.8809 2.83643 14.1318 2.994C14.3827 3.15157 14.584 3.37682 14.7124 3.64381C14.4791 3.60442 14.2576 3.53229 14.0192 3.51081C13.3346 3.44866 12.6457 3.4526 11.9618 3.52257C12.0986 3.29092 12.2929 3.09855 12.526 2.96411C12.759 2.82967 13.0228 2.75773 13.2918 2.75526L13.2944 2.75322ZM24.6802 21.0413C23.6295 20.2346 23.0269 19.0432 23.0269 17.7674V13.5876C23.0269 9.31013 20.3122 5.58408 16.5304 4.13795C16.448 2.46062 15.0351 1.11987 13.2934 1.11987C11.637 1.11987 10.2794 2.33887 10.0829 3.90469C8.69191 4.31976 7.40848 5.03401 6.32261 5.9974C4.24525 7.83894 3.05183 10.4683 3.05183 13.2055V17.771C3.05183 19.0048 2.47635 20.1752 1.47475 20.9814C1.38004 21.0572 1.30356 21.1534 1.25097 21.2627C1.19838 21.3721 1.17101 21.4918 1.1709 21.6132V24.0931C1.17203 24.262 1.22539 24.4264 1.32366 24.5638C1.42193 24.7012 1.5603 24.8048 1.71978 24.8604C3.67873 25.5633 5.69564 26.0927 7.74725 26.4426C8.23219 29.0034 10.5295 30.9539 13.2934 30.9539C16.0572 30.9539 18.3494 29.01 18.8395 26.4559C20.7424 26.1309 22.6154 25.6503 24.4398 25.019C24.6016 24.9642 24.7424 24.8604 24.8426 24.722C24.9428 24.5836 24.9974 24.4174 24.9989 24.2466V21.6843C24.9985 21.5599 24.9695 21.4372 24.9143 21.3257C24.859 21.2143 24.779 21.1169 24.6802 21.0413Z"
            fill="url(#paint24_linear_40_354)"
        />
        <path
            d="M13.2938 29.3032C12.4741 29.3064 11.6736 29.0546 11.0034 28.5826C10.3332 28.1105 9.82651 27.4417 9.55348 26.6687C10.8001 26.8226 12.0546 26.904 13.3107 26.9127C14.5533 26.9127 15.7937 26.8145 17.0342 26.6739C16.7601 27.4457 16.253 28.1133 15.5829 28.5844C14.9128 29.0554 14.1129 29.3066 13.2938 29.3032ZM13.2938 2.73894C13.5902 2.73887 13.8805 2.82266 14.1312 2.98062C14.382 3.13857 14.5829 3.36425 14.7108 3.63157C14.4776 3.59218 14.2566 3.52006 14.0182 3.49857C13.3343 3.43643 12.646 3.44037 11.9628 3.51034C12.238 3.05865 12.7204 2.74303 13.2928 2.74303L13.2938 2.73894ZM24.6695 21.027C23.6198 20.2203 23.0182 19.0289 23.0182 17.7531V13.5733C23.0182 9.29585 20.3071 5.56979 16.5283 4.12367C16.446 2.44634 15.0341 1.10559 13.2938 1.10559C11.6395 1.10559 10.2829 2.32459 10.087 3.89041C8.69692 4.30552 7.4145 5.01979 6.32976 5.98312C4.25393 7.82466 3.06204 10.454 3.06204 13.1912V17.7567C3.06204 18.9905 2.48707 20.1609 1.48599 20.9671C1.39142 21.0431 1.31508 21.1392 1.26259 21.2486C1.21009 21.3579 1.18278 21.4776 1.18265 21.5989V24.0809C1.18374 24.2497 1.23703 24.4141 1.3352 24.5514C1.43338 24.6888 1.57163 24.7925 1.73102 24.8482C3.68807 25.551 5.7031 26.0804 7.75286 26.4304C8.23728 28.9911 10.5326 30.9416 13.2938 30.9416C16.0551 30.9416 18.3453 28.9978 18.8348 26.4437C20.7359 26.1187 22.6071 25.6381 24.4295 25.0067C24.5913 24.9519 24.732 24.8481 24.8321 24.7097C24.9322 24.5713 24.9868 24.4051 24.9881 24.2343V21.67C24.9878 21.5456 24.9589 21.4229 24.9036 21.3114C24.8484 21.1999 24.7682 21.1026 24.6695 21.027Z"
            fill="url(#paint25_linear_40_354)"
        />
        <path
            d="M13.2939 29.2889C12.4746 29.2919 11.6747 29.04 11.0051 28.5679C10.3355 28.0959 9.82944 27.4271 9.55706 26.6545C10.8025 26.8083 12.0558 26.8898 13.3107 26.8985C14.5517 26.8985 15.7912 26.8002 17.0312 26.6596C16.7575 27.4311 16.2509 28.0985 15.5814 28.5696C14.9118 29.0406 14.1125 29.292 13.2939 29.2889ZM13.2939 2.72465C13.5898 2.72445 13.8797 2.80797 14.1302 2.96556C14.3806 3.12315 14.5814 3.34839 14.7093 3.61524C14.4765 3.57585 14.2556 3.50373 14.0177 3.48224C13.3343 3.4201 12.6465 3.42404 11.9639 3.49401C12.1004 3.26212 12.2947 3.06956 12.5277 2.93509C12.7608 2.80061 13.0248 2.72882 13.2939 2.7267V2.72465ZM24.6592 21.0127C23.6106 20.206 23.009 19.0146 23.009 17.7388V13.5591C23.009 9.28156 20.2979 5.55551 16.5253 4.10939C16.4429 2.43205 15.0326 1.09131 13.2939 1.09131C11.6406 1.09131 10.2855 2.31031 10.0896 3.87613C8.70062 4.2914 7.41941 5.00569 6.3359 5.96884C4.26212 7.81038 3.07126 10.4397 3.07126 13.1769V17.7399C3.07126 18.9737 2.49629 20.1441 1.49623 20.9503C1.40228 21.0266 1.32656 21.1229 1.27461 21.2322C1.22266 21.3415 1.19578 21.461 1.19595 21.582V24.064C1.19701 24.2328 1.25022 24.3971 1.3483 24.5345C1.44638 24.6719 1.58452 24.7755 1.74381 24.8313C3.69911 25.5342 5.71244 26.0636 7.76054 26.4135C8.24445 28.9743 10.5377 30.9248 13.2964 30.9248C16.0551 30.9248 18.3433 28.9809 18.8323 26.4268C20.731 26.1017 22.5998 25.6213 24.4198 24.9904C24.5814 24.9354 24.7219 24.8315 24.8218 24.6931C24.9217 24.5547 24.9761 24.3887 24.9774 24.218V21.6557C24.9771 21.5313 24.9483 21.4086 24.8932 21.2972C24.838 21.1857 24.758 21.0884 24.6592 21.0127Z"
            fill="url(#paint26_linear_40_354)"
        />
        <path
            d="M13.2938 29.2745C12.4749 29.2776 11.6753 29.0255 11.0061 28.5535C10.337 28.0814 9.83139 27.4126 9.55961 26.6401C10.8039 26.7939 12.056 26.8754 13.3097 26.8841C14.5502 26.8841 15.7886 26.7859 17.0271 26.6452C16.754 27.4165 16.248 28.0839 15.579 28.555C14.91 29.026 14.111 29.2775 13.2928 29.2745H13.2938ZM13.2938 2.71027C13.5895 2.71017 13.8792 2.79375 14.1294 2.95135C14.3796 3.10894 14.5801 3.33412 14.7077 3.60086C14.475 3.56147 14.2545 3.48934 14.0167 3.46786C13.334 3.40546 12.6468 3.4094 11.9649 3.47962C12.2396 3.02793 12.7214 2.71231 13.2949 2.71231L13.2938 2.71027ZM24.65 20.9983C23.6024 20.1916 23.0018 19.0002 23.0018 17.7245V13.5447C23.0018 9.26718 20.2938 5.54112 16.5237 4.095C16.4398 2.41767 15.031 1.07385 13.2938 1.07385C11.6421 1.07385 10.288 2.29285 10.0921 3.85867C8.70411 4.27401 7.42392 4.98831 6.34152 5.95138C4.26979 7.79292 3.07995 10.4222 3.07995 13.1595V17.725C3.07995 18.9588 2.506 20.1292 1.50696 20.9354C1.41255 21.0114 1.33634 21.1076 1.28394 21.2169C1.23154 21.3263 1.20427 21.4459 1.20413 21.5671V24.0491C1.20512 24.2178 1.25818 24.382 1.35606 24.5194C1.45394 24.6567 1.59187 24.7605 1.75097 24.8164C3.70456 25.5191 5.71618 26.0486 7.76258 26.3986C8.24649 28.9594 10.5372 30.9099 13.2938 30.9099C16.0505 30.9099 18.3361 28.966 18.8246 26.4119C20.7225 26.0871 22.5904 25.6069 24.4096 24.976C24.5711 24.921 24.7115 24.817 24.8113 24.6786C24.9111 24.5402 24.9654 24.3742 24.9667 24.2036V21.6413C24.9664 21.5169 24.9376 21.3943 24.8824 21.2828C24.8272 21.1713 24.7472 21.074 24.6485 20.9983H24.65Z"
            fill="url(#paint27_linear_40_354)"
        />
        <path
            d="M13.2934 29.2597C12.475 29.2625 11.676 29.0103 11.0075 28.5382C10.3391 28.0661 9.83424 27.3975 9.56322 26.6253C10.8064 26.7791 12.0576 26.8606 13.3102 26.8693C14.5492 26.8693 15.7866 26.771 17.024 26.6304C16.7516 27.4014 16.2462 28.0686 15.5778 28.5397C14.9094 29.0108 14.1111 29.2624 13.2934 29.2597ZM13.2934 2.69546C13.5889 2.69537 13.8785 2.77897 14.1285 2.93657C14.3785 3.09418 14.5788 3.31935 14.7062 3.58605C14.474 3.54666 14.253 3.47453 14.0157 3.45304C13.3336 3.39065 12.6472 3.39459 11.9659 3.46481C12.2401 3.01312 12.7215 2.6975 13.2959 2.6975L13.2934 2.69546ZM24.6383 20.984C23.5917 20.1773 22.9911 18.9859 22.9911 17.7102V13.5304C22.9911 9.25288 20.2861 5.52682 16.5191 4.0807C16.4368 2.40337 15.029 1.06262 13.2934 1.06262C11.6431 1.06262 10.2906 2.28162 10.0947 3.84744C8.70784 4.26295 7.42884 4.97726 6.34768 5.94015C4.27799 7.78015 3.08867 10.4079 3.08867 13.1462V17.7117C3.08867 18.9455 2.51523 20.1159 1.51671 20.9221C1.42235 20.9982 1.34623 21.0944 1.29391 21.2037C1.2416 21.313 1.21443 21.4327 1.21439 21.5539V24.0358C1.21533 24.2044 1.26832 24.3686 1.36611 24.506C1.46389 24.6433 1.60171 24.7471 1.76071 24.8032C3.71238 25.5058 5.72214 26.0353 7.76669 26.3853C8.2501 28.9461 10.5392 30.8966 13.2913 30.8966C16.0434 30.8966 18.3295 28.9528 18.8159 26.3986C20.7134 26.0741 22.5808 25.5938 24.3994 24.9628C24.5608 24.9076 24.7011 24.8036 24.8008 24.6652C24.9005 24.5269 24.9547 24.3609 24.9559 24.1903V21.627C24.9557 21.5027 24.9269 21.3801 24.8718 21.2686C24.8167 21.1571 24.7368 21.0598 24.6383 20.984Z"
            fill="url(#paint28_linear_40_354)"
        />
        <path
            d="M13.2934 29.2459C12.4755 29.2481 11.6772 28.9956 11.0094 28.5236C10.3415 28.0515 9.83714 27.3832 9.56629 26.6115C10.8085 26.7653 12.0586 26.8468 13.3102 26.8555C14.5477 26.8555 15.784 26.7573 17.0204 26.6166C16.7487 27.3874 16.244 28.0545 15.5762 28.5256C14.9084 28.9967 14.1106 29.2485 13.2934 29.2459ZM13.2934 2.68168C13.5887 2.68165 13.878 2.76528 14.1278 2.9229C14.3776 3.08051 14.5776 3.30566 14.7047 3.57227C14.473 3.53289 14.252 3.46076 14.0151 3.43927C13.3343 3.377 12.6491 3.38094 11.969 3.45104C12.2432 2.99935 12.724 2.68373 13.2954 2.68373L13.2934 2.68168ZM24.628 20.9697C23.5819 20.163 22.9824 18.9717 22.9824 17.6959V13.5161C22.9824 9.2386 20.2795 5.51254 16.5155 4.06642C16.4337 2.38908 15.0275 1.04834 13.2928 1.04834C11.6442 1.04834 10.2927 2.26734 10.0973 3.83316C8.71133 4.24871 7.43334 4.96304 6.35331 5.92587C5.33397 6.83003 4.51707 7.93926 3.95607 9.18097C3.39507 10.4227 3.10262 11.7689 3.09787 13.1314V17.6989C3.09787 18.9328 2.52495 20.1032 1.52796 20.9094C1.43354 20.9854 1.35734 21.0816 1.30494 21.1909C1.25254 21.3002 1.22527 21.4199 1.22513 21.5411V24.0231C1.22607 24.1917 1.27906 24.3559 1.37685 24.4932C1.47463 24.6306 1.61245 24.7344 1.77145 24.7904C3.72138 25.4931 5.72944 26.0225 7.77232 26.3726C8.25521 28.9334 10.5418 30.8839 13.2934 30.8839C16.0449 30.8839 18.3269 28.94 18.8144 26.3859C20.7093 26.0608 22.5742 25.5802 24.3902 24.949C24.5515 24.8938 24.6917 24.7898 24.7914 24.6514C24.891 24.513 24.9451 24.3471 24.9462 24.1766V21.6127C24.946 21.4884 24.9174 21.3658 24.8624 21.2544C24.8074 21.1429 24.7276 21.0455 24.6291 20.9697H24.628Z"
            fill="url(#paint29_linear_40_354)"
        />
        <path
            d="M13.2934 29.2315C12.4759 29.2337 11.678 28.9812 11.0106 28.5091C10.3432 28.037 9.83945 27.3687 9.56936 26.5971C10.8104 26.7509 12.0593 26.8324 13.3097 26.8411C14.5466 26.8411 15.7815 26.7429 17.0169 26.6022C16.7457 27.3726 16.2417 28.0396 15.5745 28.5108C14.9073 28.9819 14.1101 29.2338 13.2934 29.2315ZM13.2934 2.66729C13.5885 2.66744 13.8776 2.75116 14.1272 2.90877C14.3768 3.06638 14.5767 3.29142 14.7037 3.55788C14.4714 3.51849 14.251 3.44636 14.0141 3.42488C13.3333 3.36247 12.648 3.36641 11.968 3.43664C12.2416 2.98495 12.7225 2.66933 13.2934 2.66933V2.66729ZM24.6173 20.9553C23.5727 20.1486 22.9737 18.9573 22.9737 17.6815V13.5042C22.9737 9.22675 20.2728 5.5007 16.5125 4.05458C16.4311 2.37724 15.0254 1.0365 13.2934 1.0365C11.6457 1.0365 10.2957 2.2555 10.1003 3.82132C8.71685 4.23576 7.44098 4.94833 6.36252 5.90891C5.34306 6.81352 4.5261 7.9232 3.96511 9.16534C3.40412 10.4075 3.11173 11.7541 3.10708 13.117V17.6825C3.10708 18.9163 2.53467 20.0867 1.53819 20.8929C1.44395 20.9691 1.3679 21.0653 1.3156 21.1746C1.2633 21.2839 1.23606 21.4035 1.23587 21.5247V24.0067C1.23678 24.1752 1.28969 24.3393 1.38738 24.4767C1.48507 24.614 1.62277 24.7178 1.78168 24.774C3.7297 25.4766 5.73588 26.006 7.77692 26.3562C8.2593 28.9169 10.5443 30.8674 13.2934 30.8674C16.0424 30.8674 18.3218 28.9236 18.8093 26.3695C20.7021 26.0445 22.565 25.564 24.3789 24.9331C24.5402 24.8778 24.6803 24.7738 24.7798 24.6354C24.8793 24.497 24.9334 24.3311 24.9345 24.1606V21.5983C24.9343 21.474 24.9056 21.3514 24.8506 21.24C24.7956 21.1285 24.7158 21.0311 24.6173 20.9553Z"
            fill="url(#paint30_linear_40_354)"
        />
        <path
            d="M13.2928 29.2172C12.4758 29.2192 11.6785 28.9666 11.0117 28.4945C10.3449 28.0223 9.84183 27.3541 9.5724 26.5828C10.8124 26.7366 12.0603 26.8181 13.3097 26.8268C14.5451 26.8268 15.7794 26.7286 17.0133 26.5879C16.7427 27.3581 16.2392 28.025 15.5725 28.4962C14.9059 28.9673 14.1092 29.2193 13.2928 29.2172ZM13.2928 2.653C13.5876 2.65339 13.8763 2.73724 14.1254 2.89485C14.3745 3.05246 14.574 3.27738 14.7006 3.54359C14.4689 3.5042 14.2484 3.43207 14.0115 3.41059C13.3307 3.34806 12.6454 3.352 11.9654 3.42235C12.2385 2.97066 12.7189 2.65504 13.2892 2.65504L13.2928 2.653ZM24.607 20.941C23.5635 20.1343 22.9645 18.943 22.9645 17.6672V13.4874C22.9645 9.20991 20.2666 5.48386 16.5094 4.03773C16.428 2.3604 15.0259 1.01965 13.2928 1.01965C11.6472 1.01965 10.2983 2.23865 10.1029 3.80447C8.71915 4.22021 7.44354 4.93458 6.36607 5.89718C5.34802 6.80209 4.53232 7.91159 3.97224 9.15321C3.41216 10.3948 3.12031 11.7406 3.11575 13.1027V17.6682C3.11575 18.902 2.54385 20.0724 1.5484 20.8786C1.45432 20.9549 1.37844 21.0511 1.32632 21.1604C1.27419 21.2697 1.24713 21.3893 1.2471 21.5104V23.9908C1.24797 24.1593 1.30081 24.3234 1.3984 24.4607C1.496 24.5981 1.63359 24.7019 1.7924 24.7581C3.73822 25.4619 5.74236 25.9926 7.7815 26.3439C8.26388 28.9047 10.5438 30.8552 13.2928 30.8552C16.0418 30.8552 18.3172 28.9113 18.8041 26.3567C20.6954 26.0315 22.5566 25.5506 24.3687 24.9188C24.5303 24.8634 24.6706 24.7591 24.7701 24.6203C24.8697 24.4815 24.9235 24.3151 24.9242 24.1443V21.5866C24.9239 21.4623 24.8952 21.3398 24.8402 21.2283C24.7852 21.1169 24.7055 21.0195 24.607 20.9436V20.941Z"
            fill="url(#paint31_linear_40_354)"
        />
        <path
            d="M13.2928 29.2029C12.4763 29.2048 11.6795 28.9521 11.0133 28.4799C10.3471 28.0077 9.84471 27.3396 9.57601 26.5685C10.8149 26.7224 12.0618 26.8039 13.3102 26.8125C14.5451 26.8125 15.7779 26.7143 17.0107 26.5736C16.7408 27.3435 16.238 28.0103 15.5719 28.4815C14.9059 28.9527 14.1097 29.2048 13.2939 29.2029H13.2928ZM13.2928 2.63872C13.5877 2.63899 13.8764 2.72279 14.1255 2.88041C14.3747 3.03803 14.5741 3.26302 14.7006 3.52931C14.4689 3.48992 14.2489 3.41779 14.0126 3.39631C13.333 3.3339 12.6489 3.33784 11.97 3.40807C12.2432 2.95638 12.723 2.64076 13.2928 2.64076V2.63872ZM24.5979 20.9268C23.5548 20.1201 22.9568 18.9287 22.9568 17.6529V13.4731C22.9568 9.19563 20.261 5.46957 16.5074 4.02345C16.426 2.34612 15.0239 1.00537 13.2939 1.00537C11.6493 1.00537 10.3014 2.22437 10.1065 3.79019C8.72355 4.20602 7.4488 4.92041 6.37224 5.8829C5.35497 6.78827 4.53999 7.89794 3.98047 9.1395C3.42095 10.3811 3.12945 11.7266 3.12498 13.0884V17.6539C3.12498 18.8878 2.5536 20.0582 1.55865 20.8643C1.46463 20.9406 1.38879 21.0369 1.33667 21.1462C1.28455 21.2555 1.25745 21.375 1.25735 21.4961V23.9781C1.25819 24.1465 1.31095 24.3105 1.40845 24.4479C1.50594 24.5852 1.64343 24.6891 1.80214 24.7454C3.74664 25.4481 5.74944 25.9775 7.78715 26.3276C8.26851 28.8883 10.5495 30.8389 13.2934 30.8389C16.0373 30.8389 18.3131 28.895 18.7996 26.3404C20.6889 26.0157 22.5482 25.5355 24.3585 24.9045C24.5196 24.8491 24.6595 24.745 24.7589 24.6066C24.8584 24.4683 24.9124 24.3024 24.9135 24.1321V21.5698C24.9133 21.4455 24.8846 21.323 24.8298 21.2115C24.7749 21.1001 24.6952 21.0027 24.5968 20.9268H24.5979Z"
            fill="url(#paint32_linear_40_354)"
        />
        <path
            d="M13.2923 29.1886C12.4763 29.1902 11.6801 28.9372 11.0145 28.4651C10.349 27.9929 9.84719 27.3249 9.57908 26.5542C10.8167 26.708 12.0622 26.7895 13.3092 26.7982C14.5425 26.7982 15.7743 26.7 17.0061 26.5593C16.7368 27.3289 16.2345 27.9956 15.569 28.4668C14.9035 28.938 14.1078 29.1903 13.2923 29.1886ZM13.2923 2.62436C13.5872 2.62424 13.8761 2.70782 14.1254 2.86538C14.3747 3.02294 14.5742 3.24801 14.7006 3.51443C14.4694 3.47556 14.2494 3.40343 14.0131 3.38195C13.3342 3.31955 12.6507 3.32349 11.9726 3.39371C12.2452 2.94202 12.725 2.6264 13.2939 2.6264L13.2923 2.62436ZM24.5866 20.9124C23.5446 20.1057 22.9471 18.9143 22.9471 17.6385V13.4588C22.9471 9.18126 20.2539 5.45521 16.5017 4.00909C16.4204 2.33176 15.0183 0.987943 13.2908 0.987943C12.5119 0.984696 11.7586 1.26591 11.1724 1.77877C10.5861 2.29163 10.2073 3.00085 10.107 3.77327C8.72521 4.18927 7.45166 4.90367 6.37633 5.86598C5.35978 6.77223 4.54562 7.88252 3.9869 9.12449C3.42819 10.3665 3.13747 11.7122 3.13368 13.0741V17.6396C3.13368 18.8734 2.56281 20.0438 1.5694 20.85C1.47495 20.9265 1.39886 21.0232 1.34672 21.1329C1.29457 21.2427 1.26771 21.3628 1.2681 21.4843V23.9663C1.2689 24.1346 1.32158 24.2986 1.41898 24.4359C1.51638 24.5733 1.65376 24.6772 1.81238 24.7336C3.7548 25.4362 5.75555 25.9657 7.79125 26.3158C8.2721 28.8765 10.551 30.827 13.2923 30.827C16.0337 30.827 18.3054 28.8832 18.7939 26.3286C20.6819 26.0032 22.5397 25.522 24.3482 24.8901C24.5093 24.8347 24.6492 24.7306 24.7485 24.5922C24.8478 24.4538 24.9017 24.288 24.9027 24.1177V21.5554C24.9025 21.4312 24.8739 21.3087 24.8191 21.1973C24.7644 21.0858 24.6848 20.9884 24.5866 20.9124Z"
            fill="url(#paint33_linear_40_354)"
        />
        <path
            d="M13.2923 29.1743C12.4767 29.1757 11.6809 28.9226 11.0159 28.4504C10.3509 27.9782 9.84968 27.3103 9.58214 26.5398C10.8187 26.6937 12.0632 26.7752 13.3092 26.7838C14.5415 26.7838 15.7718 26.6856 17.0025 26.545C16.7339 27.3143 16.2322 27.9809 15.5673 28.4521C14.9024 28.9233 14.1073 29.1757 13.2923 29.1743ZM13.2923 2.61004C13.5868 2.61032 13.8751 2.6941 14.1238 2.85165C14.3725 3.00919 14.5715 3.23405 14.6975 3.50012C14.4663 3.46124 14.2469 3.38911 14.011 3.36763C13.3326 3.30523 12.6497 3.30917 11.972 3.3794C12.2447 2.92771 12.7235 2.61209 13.2923 2.61209V2.61004ZM24.5764 20.8981C23.5349 20.0909 22.9394 18.9 22.9394 17.6242V13.4444C22.9394 9.16695 20.2488 5.4409 16.5017 3.99478C16.4189 2.31744 15.0188 0.971581 13.2923 0.971581C12.5138 0.968628 11.7609 1.25 11.1752 1.76287C10.5894 2.27573 10.211 2.9848 10.1111 3.75691C8.73043 4.17308 7.45808 4.88749 6.38399 5.84962C5.36759 6.75634 4.55365 7.86704 3.9952 9.10935C3.43676 10.3517 3.14634 11.6977 3.14288 13.0598V17.6253C3.14288 18.8591 2.57251 20.0295 1.57962 20.8357C1.48559 20.912 1.40976 21.0082 1.35764 21.1175C1.30552 21.2268 1.27842 21.3463 1.27832 21.4674V23.9494C1.27912 24.1178 1.3318 24.2818 1.4292 24.4191C1.5266 24.5564 1.66398 24.6603 1.8226 24.7167C3.76275 25.4194 5.76129 25.9491 7.79482 26.2994C8.27567 28.8602 10.5525 30.8107 13.2913 30.8107C16.0301 30.8107 18.3044 28.8668 18.7878 26.3122C20.6745 25.9875 22.5311 25.507 24.3385 24.8758C24.4994 24.8203 24.6391 24.7161 24.7383 24.5777C24.8374 24.4394 24.8911 24.2736 24.892 24.1034V21.5411C24.8919 21.4169 24.8634 21.2945 24.8087 21.183C24.754 21.0716 24.6745 20.9741 24.5764 20.8981Z"
            fill="url(#paint34_linear_40_354)"
        />
        <path
            d="M13.2923 29.1599C12.4771 29.1611 11.6818 28.9079 11.0173 28.4357C10.3528 27.9635 9.85219 27.2957 9.58521 26.5255C10.8206 26.6793 12.0638 26.7608 13.3087 26.7695C14.54 26.7695 15.7697 26.6713 16.9989 26.5306C16.7309 27.2997 16.23 27.9662 15.5657 28.4374C14.9014 28.9086 14.1068 29.1611 13.2923 29.1599ZM13.2923 2.59566C13.5865 2.59605 13.8746 2.67989 14.123 2.83744C14.3715 2.99499 14.5702 3.21979 14.696 3.48574C14.4653 3.44686 14.2458 3.37474 14.01 3.35325C13.3323 3.29085 12.6501 3.29479 11.9731 3.36502C12.2452 2.91333 12.724 2.59771 13.2923 2.59771V2.59566ZM24.5656 20.8837C23.5257 20.0765 22.9287 18.8856 22.9287 17.6098V13.4301C22.9287 9.15257 20.2401 5.42652 16.4966 3.9804C16.4158 2.30153 15.0167 0.959247 13.2923 0.959247C12.5141 0.956589 11.7617 1.23813 11.1764 1.751C10.5911 2.26386 10.2132 2.97277 10.1136 3.74458C8.73414 4.16093 7.46299 4.87535 6.39013 5.83729C5.3749 6.74404 4.56187 7.85431 4.0039 9.09591C3.44594 10.3375 3.15554 11.6826 3.15158 13.0438V17.6093C3.15158 18.8432 2.58172 20.0136 1.58985 20.8198C1.49597 20.8961 1.42027 20.9925 1.36825 21.1017C1.31622 21.211 1.28917 21.3305 1.28906 21.4515V23.9335C1.28978 24.1017 1.34232 24.2656 1.43953 24.4029C1.53673 24.5402 1.67389 24.6442 1.83232 24.7008C3.77127 25.4033 5.76861 25.9328 7.80096 26.283C8.28078 28.8458 10.5571 30.7943 13.2923 30.7943C16.0275 30.7943 18.2983 28.8504 18.7837 26.2958C20.6684 25.9713 22.5229 25.4912 24.3283 24.8604C24.4887 24.8046 24.6279 24.7005 24.7267 24.5623C24.8256 24.4242 24.8792 24.2589 24.8802 24.089V21.5267C24.8801 21.4026 24.8516 21.2801 24.7969 21.1686C24.7422 21.0572 24.6627 20.9597 24.5646 20.8837H24.5656Z"
            fill="url(#paint35_linear_40_354)"
        />
        <path
            d="M13.2918 29.1456C12.4771 29.1465 11.6824 28.8932 11.0185 28.421C10.3547 27.9487 9.85467 27.2811 9.58828 26.5112C10.8226 26.6643 12.0649 26.7451 13.3087 26.7531C14.5364 26.7531 15.7672 26.6549 16.9954 26.5142C16.7279 27.2831 16.2275 27.9495 15.5638 28.4207C14.9 28.892 14.1059 29.1446 13.2918 29.1436V29.1456ZM13.2918 2.58137C13.586 2.58174 13.874 2.66557 14.1224 2.82313C14.3707 2.98069 14.5693 3.20551 14.695 3.47145C14.4638 3.43258 14.2448 3.36045 14.009 3.33896C13.3319 3.27656 12.6504 3.2805 11.9741 3.35073C12.2462 2.89904 12.7245 2.58342 13.2918 2.58342V2.58137ZM24.5554 20.8704C23.5165 20.0637 22.9185 18.8729 22.9185 17.5966V13.4158C22.9185 9.13828 20.2324 5.41223 16.492 3.96611C16.4107 2.28877 15.0132 0.944961 13.2898 0.944961C12.512 0.942612 11.7601 1.22434 11.1753 1.73721C10.5906 2.25009 10.2132 2.95883 10.1141 3.73029C8.7357 4.14659 7.46571 4.86104 6.39423 5.823C5.37986 6.73049 4.56776 7.84123 4.0107 9.08307C3.45363 10.3249 3.16406 11.67 3.16079 13.0311V17.5966C3.16079 18.8304 2.59145 20.0008 1.60009 20.807C1.50599 20.8833 1.43008 20.9795 1.37788 21.0888C1.32567 21.1981 1.29848 21.3176 1.29828 21.4388V23.9207C1.29896 24.0889 1.35142 24.2528 1.44853 24.3901C1.54564 24.5274 1.68269 24.6314 1.84102 24.6881C3.77806 25.3906 5.77353 25.92 7.80403 26.2702C8.28386 28.831 10.5566 30.7815 13.2903 30.7815C16.024 30.7815 18.2921 28.8377 18.7771 26.283C20.6604 25.9584 22.5136 25.4781 24.3175 24.8471C24.4783 24.7915 24.6179 24.6872 24.7169 24.5489C24.8159 24.4105 24.8696 24.2449 24.8705 24.0747V21.5124C24.87 21.3885 24.8414 21.2664 24.7868 21.1552C24.7322 21.044 24.6531 20.9466 24.5554 20.8704Z"
            fill="url(#paint36_linear_40_354)"
        />
        <path
            d="M13.2918 29.1313C12.4776 29.1321 11.6834 28.8787 11.0201 28.4065C10.3567 27.9344 9.85725 27.267 9.59134 26.4974C10.8245 26.6512 12.0655 26.7327 13.3082 26.7414C14.5359 26.7414 15.7636 26.6432 16.9913 26.5025C16.7244 27.2711 16.2245 27.9374 15.5613 28.4086C14.8981 28.8799 14.1044 29.1327 13.2908 29.1318L13.2918 29.1313ZM13.2918 2.56706C13.5857 2.56759 13.8734 2.65151 14.1216 2.80907C14.3697 2.96663 14.568 3.19135 14.6934 3.45714C14.4627 3.41826 14.2438 3.34614 14.0085 3.32465C13.3319 3.26225 12.6509 3.26619 11.9751 3.33642C12.2467 2.88473 12.7245 2.56911 13.2918 2.56911V2.56706ZM24.5457 20.8546C23.5073 20.0479 22.9118 18.857 22.9118 17.5807V13.402C22.9118 9.12448 20.2283 5.39843 16.4915 3.95231C16.4107 2.27497 15.0142 0.931159 13.2923 0.931159C12.5152 0.929588 11.7642 1.21169 11.1802 1.72451C10.5963 2.23734 10.2196 2.94564 10.1208 3.71649C8.74348 4.13296 7.47469 4.84742 6.40445 5.8092C5.3904 6.71691 4.57855 7.82772 4.02159 9.06951C3.46463 10.3113 3.17501 11.6563 3.17152 13.0173V17.5828C3.17152 18.8166 2.60269 19.987 1.61287 20.7932C1.51905 20.8696 1.44343 20.9659 1.3915 21.0752C1.33956 21.1845 1.3126 21.304 1.31259 21.425V23.9069C1.31324 24.075 1.36562 24.2389 1.46264 24.3762C1.55965 24.5134 1.69658 24.6175 1.85482 24.6742C3.78926 25.3767 5.78218 25.9061 7.81016 26.2564C8.28947 28.8172 10.5602 30.7677 13.2918 30.7677C16.0234 30.7677 18.289 28.8239 18.7735 26.2692C20.6548 25.9444 22.506 25.464 24.3078 24.8328C24.4685 24.7771 24.608 24.6729 24.707 24.5345C24.8059 24.3962 24.8595 24.2305 24.8603 24.0604V21.4981C24.86 21.374 24.8315 21.2515 24.7769 21.14C24.7223 21.0285 24.6431 20.9309 24.5452 20.8546H24.5457Z"
            fill="url(#paint37_linear_40_354)"
        />
        <path
            d="M13.2918 29.1169C12.478 29.1175 11.6843 28.864 11.0215 28.3917C10.3588 27.9194 9.86001 27.2519 9.59492 26.4825C10.8268 26.6364 12.0667 26.7178 13.3082 26.7265C14.5359 26.7265 15.7636 26.6283 16.9913 26.4876C16.725 27.2559 16.2258 27.922 15.5632 28.3933C14.9005 28.8646 14.1075 29.1175 13.2944 29.1169H13.2918ZM13.2918 2.55269C13.5855 2.55327 13.873 2.63723 14.1209 2.79479C14.3687 2.95236 14.5667 3.17707 14.6919 3.44277C14.4617 3.40389 14.2428 3.33177 14.0075 3.31028C13.3316 3.24788 12.6512 3.25182 11.9761 3.32205C12.2478 2.87036 12.725 2.55474 13.2918 2.55474V2.55269ZM24.5349 20.8402C23.4975 20.0335 22.9026 18.8427 22.9026 17.5664V13.3871C22.9026 9.1096 20.2216 5.38355 16.4879 3.93692C16.4071 2.26009 15.0116 0.918836 13.2918 0.918836C12.5145 0.916605 11.7631 1.19847 11.179 1.71142C10.595 2.22437 10.2184 2.93305 10.1203 3.70417C8.12094 4.30789 6.36786 5.53676 5.11846 7.21032C3.86907 8.88388 3.1892 10.9139 3.17868 13.0024V17.5679C3.17868 18.8017 2.61036 19.9721 1.62104 20.7783C1.52723 20.8547 1.45161 20.9511 1.39967 21.0603C1.34774 21.1696 1.32078 21.2891 1.32077 21.4101V23.8921C1.32141 24.0602 1.3738 24.224 1.47082 24.3613C1.56783 24.4986 1.70476 24.6026 1.863 24.6594C3.79618 25.3619 5.78792 25.8914 7.81476 26.2416C8.29407 28.8023 10.5622 30.7528 13.2918 30.7528C16.0214 30.7528 18.2844 28.809 18.7683 26.2543C20.6482 25.9295 22.4978 25.449 24.2981 24.8179C24.4586 24.762 24.5978 24.6577 24.6965 24.5194C24.7953 24.381 24.8487 24.2155 24.8495 24.0455V21.4842C24.8493 21.3602 24.8209 21.2377 24.7664 21.1262C24.7119 21.0148 24.6327 20.9171 24.5349 20.8407V20.8402Z"
            fill="url(#paint38_linear_40_354)"
        />
        <path
            d="M13.2913 29.1026C12.478 29.1029 11.6849 28.8492 11.0227 28.3769C10.3606 27.9046 9.86249 27.2373 9.59799 26.4682C10.8289 26.622 12.0677 26.7035 13.3082 26.7122C14.5359 26.7122 15.76 26.614 16.9851 26.4733C16.7195 27.2414 16.2209 27.9074 15.5587 28.3787C14.8966 28.85 14.104 29.1031 13.2913 29.1026ZM13.2913 2.53836C13.5847 2.53881 13.8719 2.62256 14.1196 2.77985C14.3673 2.93715 14.5652 3.16154 14.6904 3.42691C14.4602 3.38803 14.2417 3.3159 14.0064 3.29442C13.3312 3.23202 12.6516 3.23596 11.9772 3.30619C12.2483 2.8545 12.725 2.53888 13.2913 2.53888V2.53836ZM24.5247 20.8259C23.4883 20.0192 22.8939 18.8283 22.8939 17.552V13.3728C22.8939 9.09527 20.215 5.36922 16.4848 3.92259C16.4406 3.1273 16.101 2.3772 15.5325 1.81924C14.9641 1.26129 14.2078 0.935687 13.4118 0.90624C12.6159 0.876792 11.8376 1.14562 11.2295 1.66005C10.6214 2.17448 10.2273 2.89744 10.1244 3.68728C8.12534 4.29173 6.37284 5.52128 5.12425 7.19533C3.87566 8.86938 3.1968 10.8997 3.18738 12.9881V17.5536C3.18738 18.7874 2.61957 19.9578 1.63127 20.764C1.53775 20.8406 1.46236 20.937 1.41053 21.0462C1.3587 21.1555 1.33171 21.2748 1.33151 21.3957V23.8777C1.33208 24.0457 1.38432 24.2094 1.48114 24.3467C1.57796 24.484 1.71467 24.5881 1.87272 24.645C3.80435 25.3475 5.79456 25.877 7.81988 26.2272C8.29817 28.788 10.5648 30.7385 13.2933 30.7385C16.0219 30.7385 18.2814 28.7993 18.7632 26.2416C20.6414 25.9167 22.4893 25.4362 24.2879 24.8052C24.4483 24.7492 24.5876 24.6449 24.6863 24.5066C24.7851 24.3682 24.8385 24.2027 24.8393 24.0327V21.4694C24.8391 21.3453 24.8106 21.2229 24.7561 21.1114C24.7016 20.9999 24.6225 20.9023 24.5247 20.8259Z"
            fill="url(#paint39_linear_40_354)"
        />
        <path
            d="M13.2913 29.0883C12.4784 29.0884 11.6858 28.8346 11.0241 28.3623C10.3625 27.8899 9.86499 27.2227 9.60106 26.4539C10.8307 26.6077 12.0684 26.6892 13.3077 26.6979C14.5354 26.6979 15.7574 26.5997 16.9815 26.459C16.7165 27.2268 16.2186 27.8927 15.5571 28.3641C14.8956 28.8354 14.1035 29.0886 13.2913 29.0883ZM13.2913 2.52406C13.5847 2.52469 13.8718 2.6087 14.1192 2.76629C14.3667 2.92389 14.5642 3.14857 14.6888 3.41414C14.4591 3.37526 14.2407 3.30314 14.0059 3.28165C13.3311 3.21925 12.6517 3.22319 11.9777 3.29342C12.2493 2.84173 12.7255 2.52611 13.2913 2.52611V2.52406ZM24.5145 20.8116C23.4786 20.0049 22.8852 18.814 22.8852 17.5393V13.3585C22.8852 9.08097 20.2088 5.35492 16.4818 3.90829C16.4382 3.11327 16.0992 2.36321 15.5313 1.80521C14.9633 1.24721 14.2073 0.921543 13.4117 0.892081C12.616 0.862619 11.838 1.13149 11.2304 1.64595C10.6227 2.16042 10.2291 2.88336 10.1269 3.67298C8.12893 4.27825 6.37766 5.50821 5.13029 7.18224C3.88292 8.85627 3.20517 10.8861 3.19658 12.9738V17.5393C3.19658 18.7731 2.62878 19.9435 1.64151 20.7497C1.54784 20.8263 1.4724 20.9227 1.42064 21.0321C1.36888 21.1415 1.3421 21.261 1.34226 21.382V23.8639C1.34278 24.0319 1.39495 24.1956 1.49167 24.3328C1.5884 24.4701 1.725 24.5743 1.88295 24.6313C3.81267 25.3337 5.801 25.8631 7.82448 26.2134C8.30277 28.7742 10.5668 30.7247 13.2913 30.7247C16.0158 30.7247 18.2752 28.7809 18.7581 26.2262C20.6346 25.9013 22.4808 25.4209 24.2776 24.7898C24.4382 24.7339 24.5774 24.6296 24.6762 24.4913C24.7749 24.353 24.8284 24.1874 24.8291 24.0174V21.4551C24.8289 21.3311 24.8005 21.2087 24.7461 21.0972C24.6917 20.9857 24.6127 20.888 24.515 20.8116H24.5145Z"
            fill="url(#paint40_linear_40_354)"
        />
        <path
            d="M13.2913 29.074C12.4788 29.074 11.6866 28.82 11.0255 28.3477C10.3645 27.8753 9.86748 27.2082 9.60412 26.4396C10.8328 26.5934 12.0694 26.6749 13.3077 26.6836C14.5323 26.6836 15.7549 26.5854 16.978 26.4447C16.7136 27.2122 16.2162 27.878 15.5554 28.3493C14.8945 28.8206 14.103 29.074 13.2913 29.074ZM13.2913 2.50979C13.5844 2.51052 13.8713 2.59459 14.1185 2.75219C14.3657 2.90979 14.5629 3.13441 14.6873 3.39987C14.4576 3.36099 14.2397 3.28886 14.0049 3.26738C13.3307 3.20497 12.6521 3.20892 11.9787 3.27914C12.2498 2.82745 12.726 2.51183 13.2913 2.51183V2.50979ZM24.5042 20.7973C23.4694 19.9906 22.876 18.7998 22.876 17.5235V13.3442C22.876 9.0667 20.2022 5.34065 16.4787 3.89401C16.4359 3.09926 16.0975 2.34923 15.53 1.79118C14.9625 1.23314 14.2069 0.9074 13.4115 0.877923C12.6161 0.848446 11.8385 1.11736 11.2312 1.63187C10.624 2.14637 10.231 2.8693 10.1295 3.6587C8.13237 4.26463 6.38218 5.49495 5.13591 7.16898C3.88964 8.843 3.21297 10.8725 3.20527 12.9595V17.525C3.20527 18.7588 2.63849 19.9292 1.65173 20.7354C1.5583 20.8121 1.48301 20.9085 1.43126 21.0177C1.37952 21.127 1.35262 21.2463 1.35248 21.3672V23.8492C1.35301 24.0171 1.40517 24.1808 1.5019 24.318C1.59862 24.4553 1.73522 24.5595 1.89318 24.6165C3.82029 25.3188 5.80607 25.8483 7.82703 26.1987C8.30481 28.7594 10.5673 30.7099 13.2892 30.7099C16.0111 30.7099 18.2686 28.7661 18.7509 26.2114C20.6261 25.8867 22.4709 25.4064 24.2664 24.7756C24.4266 24.7194 24.5654 24.6149 24.6639 24.4766C24.7624 24.3383 24.8156 24.1729 24.8163 24.0031V21.4408C24.8162 21.3168 24.7879 21.1945 24.7336 21.083C24.6792 20.9715 24.6003 20.8738 24.5027 20.7973H24.5042Z"
            fill="url(#paint41_linear_40_354)"
        />
        <path
            d="M13.2908 29.0596C12.4789 29.0592 11.6874 28.805 11.027 28.3327C10.3667 27.8603 9.87044 27.1934 9.6077 26.4252C10.8352 26.5791 12.0706 26.6605 13.3077 26.6692C14.5313 26.6692 15.7533 26.571 16.9749 26.4303C16.7111 27.1975 16.2145 27.8632 15.5542 28.3346C14.894 28.8059 14.103 29.0594 13.2918 29.0596H13.2908ZM13.2908 2.49541C13.5838 2.49624 13.8706 2.58036 14.1177 2.73795C14.3648 2.89554 14.5619 3.12011 14.6863 3.38548C14.4566 3.34661 14.2387 3.27448 14.0044 3.253C13.3307 3.1906 12.6526 3.19454 11.9797 3.26476C12.2508 2.81307 12.726 2.49745 13.2908 2.49745V2.49541ZM24.4935 20.7829C23.4602 19.9762 22.8673 18.7854 22.8673 17.5091V13.3298C22.8673 9.05232 20.196 5.32626 16.4761 3.87963C16.4333 3.08566 16.0953 2.33637 15.5284 1.77887C14.9614 1.22136 14.2066 0.895926 13.412 0.86645C12.6174 0.836974 11.8405 1.10559 11.2339 1.61955C10.6272 2.13352 10.2346 2.85571 10.133 3.64432C8.13673 4.25087 6.38749 5.4815 5.14215 7.15552C3.89682 8.82953 3.22101 10.8587 3.21397 12.9451V17.5106C3.21397 18.7445 2.6477 19.9149 1.66196 20.721C1.56862 20.7978 1.49343 20.8942 1.44178 21.0034C1.39012 21.1127 1.3633 21.232 1.36322 21.3528V23.8373C1.36367 24.0051 1.41569 24.1687 1.51222 24.306C1.60875 24.4432 1.74513 24.5475 1.90289 24.6046C3.82993 25.3072 5.81573 25.8367 7.83675 26.1868C8.07975 27.4551 8.75683 28.5991 9.75166 29.4224C10.7465 30.2457 11.997 30.6969 13.2883 30.6984C14.5796 30.6999 15.8312 30.2517 16.8279 29.4307C17.8247 28.6098 18.5045 27.4673 18.7504 26.1996C20.6237 25.8747 22.4667 25.3942 24.2602 24.7632C24.42 24.7066 24.5583 24.602 24.6563 24.4637C24.7542 24.3254 24.8071 24.1603 24.8076 23.9908V21.4265C24.8074 21.3024 24.7791 21.18 24.7246 21.0685C24.6702 20.957 24.5912 20.8594 24.4935 20.7829Z"
            fill="url(#paint42_linear_40_354)"
        />
        <path
            d="M13.2908 29.0453C12.4792 29.0449 11.688 28.7906 11.0281 28.3183C10.3681 27.8459 9.87239 27.179 9.61026 26.4109C10.8367 26.5647 12.0711 26.6462 13.3071 26.6549C14.5297 26.6549 15.7503 26.5567 16.9708 26.416C16.7076 27.1829 16.2115 27.8485 15.5518 28.3199C14.8921 28.7913 14.1016 29.0449 13.2908 29.0453ZM13.2908 2.48108C13.5836 2.48196 13.8702 2.56611 14.117 2.72371C14.3638 2.88131 14.5607 3.10586 14.6847 3.37116C14.4556 3.33228 14.2376 3.26015 14.0034 3.23867C13.3304 3.17627 12.6529 3.18021 11.9807 3.25043C12.2513 2.79874 12.7266 2.48312 13.2908 2.48312V2.48108ZM24.4833 20.7681C23.451 19.9614 22.8586 18.7705 22.8586 17.4942V13.3155C22.8586 9.03799 20.1894 5.31194 16.4731 3.8653C16.4314 3.07113 16.094 2.32131 15.5274 1.76336C14.9607 1.20541 14.2057 0.879745 13.411 0.850432C12.6163 0.821119 11.8393 1.09029 11.2331 1.60497C10.6268 2.11965 10.2351 2.84259 10.1351 3.63153C8.14015 4.23891 6.39243 5.46976 5.14844 7.14343C3.90444 8.81711 3.22967 10.8455 3.22318 12.9308V17.4942C3.22318 18.7281 2.65741 19.8985 1.67219 20.7047C1.57894 20.7814 1.50385 20.8779 1.45229 20.9871C1.40072 21.0963 1.37398 21.2156 1.37396 21.3364V23.8184C1.37438 23.9861 1.42632 24.1497 1.52275 24.2869C1.61919 24.4242 1.75546 24.5285 1.91312 24.5857C3.83744 25.2881 5.8205 25.8175 7.8388 26.1679C8.08086 27.4356 8.75704 28.5794 9.75107 29.4026C10.7451 30.2257 11.9949 30.6769 13.2855 30.6784C14.5761 30.6799 15.8269 30.2317 16.8229 29.4109C17.8189 28.59 18.4977 27.4478 18.7428 26.1807C20.6144 25.8558 22.4557 25.3753 24.2474 24.7443C24.4076 24.6881 24.5464 24.5836 24.6448 24.4453C24.7431 24.307 24.7963 24.1416 24.7968 23.9719V21.4121C24.7968 21.288 24.7686 21.1656 24.7142 21.054C24.6599 20.9424 24.5809 20.8446 24.4833 20.7681Z"
            fill="url(#paint43_linear_40_354)"
        />
        <path
            d="M13.2903 29.031C12.479 29.0303 11.6883 28.7757 11.0289 28.303C10.3695 27.8303 9.87457 27.1632 9.61335 26.3951C10.8388 26.5489 12.0722 26.6304 13.3072 26.6391C14.5282 26.6391 15.7477 26.5409 16.9677 26.4002C16.705 27.1669 16.2094 27.8325 15.5501 28.3038C14.8908 28.7752 14.1008 29.0289 13.2903 29.0295V29.031ZM13.2903 2.46682C13.583 2.46764 13.8695 2.55178 14.1161 2.7094C14.3628 2.86702 14.5595 3.09161 14.6832 3.35689C14.454 3.31802 14.2366 3.24589 14.0024 3.22441C13.3301 3.162 12.6533 3.16594 11.9818 3.23617C12.2524 2.78448 12.7271 2.46886 13.2903 2.46886V2.46682ZM24.471 20.7543C23.4392 19.9476 22.8479 18.7568 22.8479 17.4805V13.2997C22.8479 9.02219 20.1812 5.29614 16.468 3.84951C16.4273 3.05552 16.0908 2.30555 15.5247 1.74733C14.9586 1.18912 14.204 0.863139 13.4095 0.833617C12.615 0.804094 11.8383 1.07317 11.2323 1.58783C10.6264 2.10249 10.2351 2.82542 10.1356 3.6142C8.14146 4.22253 6.39477 5.45402 5.15189 7.12795C3.90901 8.80189 3.23538 10.8301 3.22985 12.915V17.4805C3.22985 18.7143 2.66716 19.8847 1.6804 20.6909C1.58715 20.7677 1.51206 20.8641 1.4605 20.9734C1.40893 21.0826 1.38219 21.2019 1.38217 21.3227V23.8046C1.38259 23.9724 1.43453 24.1359 1.53096 24.2732C1.6274 24.4104 1.76367 24.5147 1.92133 24.572C3.84508 25.2755 5.82785 25.8057 7.84598 26.1562C8.08575 27.4247 8.76057 28.5698 9.7541 29.3941C10.7476 30.2184 11.9976 30.6703 13.2886 30.6718C14.5795 30.6733 15.8306 30.2244 16.8261 29.4024C17.8215 28.5804 18.499 27.4369 18.7418 26.169C20.6105 25.8437 22.4488 25.3633 24.2377 24.7326C24.3978 24.6763 24.5365 24.5718 24.6348 24.4335C24.7331 24.2952 24.7861 24.1298 24.7866 23.9602V21.3979C24.7863 21.2737 24.7577 21.1512 24.703 21.0397C24.6483 20.9282 24.569 20.8306 24.471 20.7543Z"
            fill="url(#paint44_linear_40_354)"
        />
        <path
            d="M13.2903 29.0167C12.4797 29.0154 11.6898 28.7607 11.0311 28.2884C10.3723 27.816 9.87771 27.1496 9.61641 26.3823C10.8406 26.5361 12.0728 26.6176 13.3067 26.6263C14.5272 26.6263 15.7457 26.528 16.9642 26.3874C16.702 27.1537 16.207 27.8191 15.5483 28.2905C14.8897 28.7618 14.1002 29.0157 13.2903 29.0167ZM13.2903 2.45245C13.5828 2.45345 13.8689 2.53768 14.1153 2.69529C14.3617 2.85291 14.5581 3.0774 14.6817 3.34253C14.453 3.30365 14.2356 3.23153 14.0018 3.21004C13.3301 3.14764 12.6538 3.15158 11.9828 3.22181C12.2529 2.77012 12.7271 2.4545 13.2903 2.4545V2.45245ZM24.4628 20.74C23.4321 19.9333 22.8412 18.7424 22.8412 17.4661V13.2869C22.8412 9.00936 20.1766 5.28331 16.4669 3.83668C16.427 3.04294 16.0911 2.29297 15.5255 1.73469C14.9599 1.1764 14.2056 0.850339 13.4114 0.820801C12.6172 0.791263 11.8407 1.0604 11.2352 1.57512C10.6297 2.08984 10.239 2.81279 10.1402 3.60137C8.14723 4.21063 6.40195 5.4426 5.16047 7.1165C3.91899 8.79041 3.24666 10.8181 3.24212 12.9022V17.4677C3.24212 18.7015 2.67942 19.8719 1.6942 20.6781C1.60105 20.7549 1.52605 20.8514 1.47458 20.9606C1.42311 21.0698 1.39644 21.1891 1.39648 21.3098V23.7918C1.39682 23.9594 1.44861 24.1229 1.54485 24.2601C1.6411 24.3974 1.77715 24.5017 1.93462 24.5591C3.85403 25.2616 5.83233 25.7913 7.84597 26.1418C8.0856 27.4089 8.75976 28.5528 9.75225 29.3761C10.7447 30.1995 11.9934 30.6508 13.2829 30.6524C14.5725 30.6539 15.8222 30.2055 16.8166 29.3844C17.811 28.5634 18.4879 27.4212 18.7305 26.1546C20.5989 25.8296 22.437 25.3492 24.2255 24.7182C24.3862 24.6626 24.5257 24.5584 24.6247 24.42C24.7237 24.2816 24.7772 24.1159 24.7779 23.9458V21.382C24.7779 21.258 24.7497 21.1357 24.6954 21.0242C24.6412 20.9127 24.5624 20.815 24.4649 20.7384L24.4628 20.74Z"
            fill="url(#paint45_linear_40_354)"
        />
        <path
            d="M13.2903 29.004C12.4801 29.0027 11.6906 28.7481 11.0324 28.2757C10.3743 27.8032 9.88037 27.1367 9.61999 26.3695C10.843 26.5234 12.074 26.6049 13.3067 26.6135C14.5257 26.6135 15.7431 26.5153 16.9606 26.3746C16.6991 27.1408 16.2048 27.806 15.5468 28.2774C14.8887 28.7489 14.0998 29.0029 13.2903 29.004ZM13.2903 2.43972C13.5826 2.44076 13.8685 2.52503 14.1146 2.68266C14.3608 2.84028 14.5569 3.06476 14.6801 3.3298C14.4515 3.29092 14.2346 3.2188 14.0008 3.19731C13.3297 3.13491 12.6541 3.13885 11.9838 3.20908C12.2539 2.75739 12.7276 2.44177 13.2903 2.44177V2.43972ZM24.4526 20.7273C23.4229 19.919 22.8341 18.7282 22.8341 17.4534V13.2726C22.8341 8.9951 20.1741 5.26905 16.4659 3.82241C16.4268 3.02892 16.0915 2.27895 15.5263 1.72059C14.9612 1.16223 14.2072 0.836075 13.4133 0.806521C12.6194 0.776966 11.8432 1.04616 11.2381 1.56096C10.633 2.07575 10.2429 2.79872 10.1448 3.58711C8.15177 4.19668 6.40665 5.42917 5.16565 7.10362C3.92466 8.77808 3.25318 10.8063 3.24979 12.8905V17.4534C3.24979 18.6872 2.6871 19.8576 1.7029 20.6638C1.60975 20.7406 1.53475 20.8371 1.48327 20.9463C1.4318 21.0556 1.40513 21.1748 1.40518 21.2956V23.7776C1.40552 23.9452 1.45731 24.1086 1.55355 24.2459C1.64979 24.3831 1.78585 24.4875 1.94332 24.5449C3.86197 25.2475 5.83961 25.7772 7.85262 26.1276C8.09075 27.3946 8.76378 28.5387 9.75554 29.3624C10.7473 30.1862 11.9955 30.6378 13.2847 30.6393C14.5739 30.6408 15.8232 30.1921 16.8169 29.3707C17.8106 28.5494 18.4863 27.4068 18.7274 26.1404C20.5935 25.8153 22.4292 25.3348 24.2152 24.704C24.3751 24.6475 24.5136 24.5429 24.6117 24.4046C24.7097 24.2663 24.7626 24.1011 24.7631 23.9315V21.3692C24.7631 21.2453 24.735 21.123 24.6808 21.0115C24.6267 20.9 24.548 20.8023 24.4505 20.7257L24.4526 20.7273Z"
            fill="url(#paint46_linear_40_354)"
        />
        <path
            d="M13.2898 28.988C12.4801 28.9866 11.6911 28.7318 11.0336 28.2593C10.376 27.7869 9.88278 27.1205 9.62306 26.3536C10.8449 26.5074 12.0747 26.5889 13.3061 26.5976C14.5241 26.5976 15.74 26.4994 16.9565 26.3587C16.6956 27.1246 16.2019 27.7897 15.5443 28.2612C14.8868 28.7326 14.0983 28.9867 13.2893 28.988H13.2898ZM13.2898 2.42377C13.582 2.42479 13.8679 2.50905 14.1139 2.66668C14.36 2.82432 14.556 3.04881 14.6791 3.31385C14.4505 3.27497 14.2336 3.20285 14.0003 3.18136C13.3297 3.1187 12.6546 3.12265 11.9848 3.19313C12.2544 2.74144 12.7276 2.42582 13.2898 2.42582V2.42377ZM24.4413 20.7113C23.4126 19.9046 22.8223 18.7137 22.8223 17.4374V13.2582C22.8223 8.98068 20.1623 5.25463 16.4598 3.808C16.4165 3.01847 16.0799 2.27358 15.5159 1.71936C14.952 1.16515 14.2013 0.841597 13.4111 0.81214C12.621 0.782683 11.8483 1.04945 11.2446 1.56013C10.641 2.07081 10.2498 2.78858 10.1479 3.57269C8.15618 4.18292 6.41246 5.41539 5.17254 7.08929C3.93263 8.76319 3.26181 10.7904 3.25849 12.8735V17.439C3.25849 18.6728 2.6958 19.8432 1.71313 20.6494C1.62027 20.7261 1.54549 20.8224 1.49411 20.9313C1.44274 21.0402 1.41604 21.1592 1.41592 21.2796V23.7616C1.41622 23.9292 1.46794 24.0926 1.56408 24.2298C1.66023 24.367 1.79617 24.4714 1.95355 24.5289C3.87043 25.2316 5.84638 25.7613 7.85774 26.1116C8.09462 27.3783 8.76661 28.5224 9.75755 29.3462C10.7485 30.17 11.9961 30.6217 13.2847 30.6232C14.5734 30.6247 15.822 30.1759 16.8149 29.3545C17.8078 28.533 18.4825 27.3905 18.7223 26.1244C20.5876 25.7994 22.4224 25.3189 24.2076 24.688C24.3673 24.6314 24.5057 24.5268 24.6037 24.3886C24.7017 24.2503 24.7545 24.0851 24.7549 23.9156V21.3548C24.7549 21.2309 24.7268 21.1086 24.6727 20.9971C24.6185 20.8856 24.5398 20.7879 24.4424 20.7113H24.4413Z"
            fill="url(#paint47_linear_40_354)"
        />
        <path
            d="M13.2898 28.9737C12.4805 28.9721 11.692 28.7172 11.035 28.2447C10.3779 27.7723 9.88527 27.106 9.62612 26.3393C10.8469 26.4931 12.0757 26.5746 13.3061 26.5833C14.5231 26.5833 15.7385 26.4851 16.9534 26.3444C16.6932 27.11 16.2001 27.7751 15.5432 28.2465C14.8863 28.718 14.0984 28.9722 13.2898 28.9737ZM13.2898 2.40949C13.5818 2.41042 13.8675 2.49467 14.1133 2.65233C14.3592 2.81 14.5549 3.03453 14.6776 3.29957C14.4494 3.26069 14.2325 3.18856 13.9993 3.16708C13.3294 3.10442 12.655 3.10836 11.9859 3.17885C12.2554 2.72716 12.7281 2.41154 13.2898 2.41154V2.40949ZM24.4321 20.697C23.4044 19.8903 22.8146 18.6995 22.8146 17.4232V13.2439C22.8146 8.9664 20.1577 5.24035 16.4577 3.79372C16.4216 2.99969 16.0882 2.24828 15.5239 1.68856C14.9595 1.12884 14.2054 0.801721 13.4111 0.7721C12.6168 0.742479 11.8404 1.01252 11.2359 1.52865C10.6315 2.04479 10.2431 2.76929 10.1479 3.55841C8.1575 4.16967 6.41537 5.40268 5.17704 7.07655C3.93871 8.75043 3.26936 10.7771 3.26718 12.8592V17.4247C3.26718 18.6585 2.70448 19.8289 1.72335 20.6351C1.63029 20.712 1.55539 20.8085 1.50401 20.9177C1.45263 21.0269 1.42604 21.1462 1.42615 21.2669V23.7489C1.42641 23.9163 1.47805 24.0797 1.5741 24.2169C1.67015 24.3542 1.80598 24.4586 1.96326 24.5162C3.8784 25.2189 5.85265 25.7486 7.86234 26.0989C8.09826 27.365 8.76934 28.5089 9.75948 29.3326C10.7496 30.1562 11.9965 30.6079 13.2845 30.6094C14.5724 30.6109 15.8204 30.1622 16.8124 29.3409C17.8045 28.5195 18.4783 27.3773 18.7172 26.1117C20.5808 25.7867 22.4139 25.3062 24.1973 24.6753C24.357 24.6187 24.4953 24.5141 24.5932 24.3758C24.6911 24.2375 24.7439 24.0723 24.7442 23.9028V21.3405C24.7442 21.2166 24.7162 21.0944 24.6621 20.9829C24.6081 20.8714 24.5294 20.7737 24.4321 20.697Z"
            fill="url(#paint48_linear_40_354)"
        />
        <path
            d="M13.2898 28.9595C12.4809 28.9576 11.693 28.7025 11.0364 28.23C10.3799 27.7576 9.88784 27.0914 9.62919 26.325C10.8489 26.4789 12.0767 26.5603 13.3061 26.569C14.5216 26.569 15.7359 26.4708 16.9503 26.3301C16.6906 27.0954 16.1981 27.7604 15.5417 28.2318C14.8853 28.7033 14.0979 28.9577 13.2898 28.9595ZM13.2898 2.39522C13.5815 2.39645 13.8669 2.48083 14.1125 2.63847C14.358 2.79612 14.5535 3.0205 14.676 3.2853C14.4479 3.24642 14.2315 3.1743 13.9982 3.15281C13.3291 3.09015 12.6553 3.0941 11.9869 3.16458C12.2559 2.71289 12.7286 2.39727 13.2898 2.39727V2.39522ZM24.4198 20.6827C23.3932 19.876 22.8039 18.6852 22.8039 17.4089V13.2296C22.8039 8.95213 20.149 5.22608 16.4526 3.77945C16.4165 2.98621 16.0835 2.23554 15.5197 1.67636C14.9559 1.11719 14.2026 0.79037 13.4091 0.76075C12.6156 0.731129 11.8399 1.00087 11.236 1.51646C10.6321 2.03205 10.2441 2.75581 10.1489 3.54414C8.15983 4.15655 6.41924 5.39008 5.18235 7.06388C3.94545 8.73768 3.27731 10.7637 3.27587 12.8449V17.4104C3.27587 18.6443 2.71318 19.8147 1.73358 20.6209C1.64062 20.6978 1.56581 20.7943 1.51452 20.9035C1.46323 21.0127 1.43672 21.1319 1.43689 21.2526V23.7351C1.43711 23.9025 1.48868 24.0659 1.58463 24.2031C1.68058 24.3403 1.81631 24.4448 1.9735 24.5024C3.88672 25.2051 5.8591 25.7348 7.86694 26.0851C8.10161 27.3509 8.77164 28.4948 9.76096 29.3185C10.7503 30.1423 11.9966 30.594 13.2839 30.5956C14.5713 30.5971 15.8187 30.1482 16.8099 29.3268C17.8012 28.5054 18.4739 27.3631 18.7116 26.0979C20.573 25.7728 22.4039 25.2923 24.185 24.6615C24.3452 24.6052 24.4839 24.5006 24.5822 24.3622C24.6805 24.2238 24.7335 24.0583 24.7339 23.8886V21.3263C24.734 21.2024 24.706 21.0801 24.6519 20.9686C24.5979 20.8571 24.5192 20.7594 24.4219 20.6827H24.4198Z"
            fill="url(#paint49_linear_40_354)"
        />
        <path
            d="M13.2892 28.9451C12.4809 28.9429 11.6935 28.6877 11.0376 28.2152C10.3818 27.7427 9.8903 27.0767 9.63225 26.3106C10.8508 26.4645 12.0774 26.5459 13.3056 26.5546C14.5205 26.5546 15.7334 26.4564 16.9468 26.3158C16.6877 27.0809 16.1957 27.7457 15.5399 28.2172C14.884 28.6887 14.097 28.9432 13.2892 28.9451ZM13.2892 2.38084C13.5811 2.38248 13.8665 2.46734 14.1119 2.62544C14.3572 2.78355 14.5524 3.00835 14.6745 3.27348C14.4469 3.2346 14.2305 3.16247 13.9977 3.14099C13.329 3.07833 12.6558 3.08227 11.9879 3.15275C12.2564 2.70106 12.7286 2.38544 13.2892 2.38544V2.38084ZM24.4122 20.6684C23.3855 19.8617 22.7972 18.6708 22.7972 17.3945V13.2152C22.7972 8.93775 20.1449 5.2117 16.4541 3.76506C16.4228 2.96907 16.0922 2.21423 15.5284 1.65148C14.9645 1.08873 14.2091 0.759548 13.413 0.729753C12.617 0.699959 11.839 0.971749 11.2347 1.49078C10.6304 2.00981 10.2442 2.73782 10.1535 3.52925C8.16486 4.14192 6.42486 5.37573 5.18874 7.04969C3.95263 8.72365 3.28545 10.7497 3.28507 12.8306V17.396C3.28507 18.6299 2.72238 19.8003 1.74381 20.6065C1.65084 20.6834 1.57604 20.7799 1.52474 20.8891C1.47345 20.9984 1.44694 21.1176 1.44711 21.2382V23.7202C1.4473 23.8876 1.49879 24.0509 1.59465 24.1881C1.6905 24.3253 1.82612 24.4298 1.98321 24.4875C3.8947 25.1901 5.86538 25.7198 7.87153 26.0702C8.34522 28.631 10.5893 30.5815 13.2892 30.5815C14.5749 30.58 15.8197 30.1298 16.8089 29.3085C17.7981 28.4872 18.4695 27.3465 18.7075 26.083C20.5677 25.758 22.3974 25.2775 24.1774 24.6466C24.3371 24.5902 24.4754 24.4856 24.5731 24.3472C24.6709 24.2089 24.7233 24.0436 24.7232 23.8742V21.3114C24.7233 21.1877 24.6954 21.0655 24.6415 20.9541C24.5876 20.8428 24.5092 20.7451 24.4122 20.6684Z"
            fill="url(#paint50_linear_40_354)"
        />
        <path
            d="M13.2893 28.9307C11.5874 28.9307 10.1546 27.8227 9.63535 26.2963C10.8529 26.4501 12.0785 26.5316 13.3056 26.5403C14.519 26.5403 15.7314 26.4421 16.9432 26.3014C16.6848 27.0662 16.1935 27.731 15.5382 28.2025C14.883 28.674 14.0966 28.9287 13.2893 28.9307ZM13.2893 2.36648C13.5803 2.36824 13.8648 2.4529 14.1094 2.61054C14.354 2.76819 14.5486 2.99228 14.6704 3.25656C14.4428 3.21768 14.2269 3.14556 13.9942 3.12407C13.3262 3.06141 12.6536 3.06535 11.9864 3.13584C12.2549 2.68415 12.7266 2.36853 13.2867 2.36853L13.2893 2.36648ZM24.4019 20.654C23.3789 19.8473 22.7886 18.659 22.7886 17.3802V13.2009C22.7886 8.92339 20.1388 5.19734 16.4485 3.75071C16.4132 2.95847 16.0812 2.2085 15.5185 1.64976C14.9557 1.09102 14.2034 0.764389 13.411 0.734753C12.6185 0.705116 11.8439 0.97464 11.241 1.48979C10.6381 2.00494 10.251 2.72802 10.1566 3.5154C8.79368 3.93346 7.54025 4.64813 6.48631 5.60811C5.48214 6.52151 4.67952 7.63433 4.12972 8.87546C3.57993 10.1166 3.29505 11.4588 3.29328 12.8162V17.3817C3.29328 18.6155 2.73059 19.7859 1.75355 20.5921C1.66068 20.6691 1.58597 20.7656 1.53477 20.8748C1.48356 20.9841 1.45713 21.1032 1.45737 21.2239V23.7059C1.45751 23.8732 1.50893 24.0364 1.60469 24.1736C1.70045 24.3108 1.83595 24.4153 1.99295 24.4732C3.90253 25.1757 5.87133 25.7054 7.87565 26.0559C8.34934 28.6166 10.5914 30.5671 13.2888 30.5671C15.9861 30.5671 18.2236 28.6233 18.7019 26.0687C20.5606 25.7436 22.3888 25.2631 24.1672 24.6322C24.3266 24.5754 24.4645 24.4707 24.5622 24.3324C24.6598 24.1941 24.7123 24.0291 24.7125 23.8598V21.297C24.7126 21.1733 24.6847 21.0512 24.6308 20.9398C24.5769 20.8284 24.4985 20.7307 24.4014 20.654H24.4019Z"
            fill="url(#paint51_linear_40_354)"
        />
        <path
            d="M13.2893 28.9164C11.5889 28.9164 10.1571 27.8084 9.63841 26.282C10.8549 26.4358 12.0795 26.5173 13.3056 26.526C14.518 26.526 15.7288 26.4278 16.9376 26.2871C16.4173 27.8104 14.9871 28.9164 13.2872 28.9164H13.2893ZM13.2893 2.35216C13.5803 2.35392 13.8648 2.43858 14.1094 2.59623C14.354 2.75387 14.5486 2.97796 14.6704 3.24224C14.4428 3.20336 14.2269 3.13124 13.9942 3.10975C13.3272 3.04665 12.6557 3.05008 11.9894 3.11998C12.2575 2.66829 12.7291 2.35267 13.2888 2.35267L13.2893 2.35216ZM24.3897 20.6397C23.3666 19.833 22.7778 18.6421 22.7778 17.3658V13.1866C22.7778 8.90907 20.1301 5.18302 16.4434 3.73639C16.4097 2.94378 16.0787 2.19296 15.5163 1.63343C14.9539 1.0739 14.2014 0.746726 13.4086 0.717054C12.6159 0.687381 11.841 0.957391 11.2383 1.47331C10.6357 1.98923 10.2495 2.71321 10.1566 3.50108C8.79478 3.91921 7.54253 4.63391 6.48989 5.59379C5.48689 6.50782 4.68542 7.62088 4.13662 8.86196C3.58782 10.103 3.30373 11.4449 3.30249 12.8019V17.3674C3.30249 18.6012 2.7398 19.7716 1.76429 20.5778C1.67142 20.6548 1.59671 20.7513 1.54551 20.8605C1.49431 20.9697 1.46788 21.0889 1.46811 21.2095V23.6915C1.46822 23.8588 1.51956 24.022 1.61522 24.1592C1.71089 24.2963 1.84628 24.4009 2.00318 24.4588C3.91104 25.1613 5.87813 25.691 7.88077 26.0415C8.35394 28.6023 10.5919 30.5528 13.2893 30.5528C15.9866 30.5528 18.2195 28.609 18.6973 26.0543C20.5543 25.7292 22.3808 25.2488 24.1574 24.6179C24.317 24.5613 24.455 24.4566 24.5526 24.3183C24.6502 24.18 24.7024 24.0148 24.7022 23.8455V21.2827C24.7024 21.159 24.6746 21.0369 24.6208 20.9255C24.567 20.8142 24.4887 20.7164 24.3917 20.6397H24.3897Z"
            fill="url(#paint52_linear_40_354)"
        />
        <path
            d="M13.2893 28.9164C11.5889 28.9164 10.1571 27.8084 9.63841 26.282C10.8549 26.4358 12.0795 26.5173 13.3056 26.526C14.518 26.526 15.7288 26.4278 16.9376 26.2871C16.4173 27.8104 14.9871 28.9164 13.2872 28.9164H13.2893ZM13.2893 2.35216C13.5803 2.35392 13.8648 2.43858 14.1094 2.59623C14.354 2.75387 14.5486 2.97796 14.6704 3.24224C14.4428 3.20336 14.2269 3.13124 13.9942 3.10975C13.3272 3.04665 12.6557 3.05008 11.9894 3.11998C12.2575 2.66829 12.7291 2.35267 13.2888 2.35267L13.2893 2.35216ZM24.3897 20.6397C23.3666 19.833 22.7778 18.6421 22.7778 17.3658V13.1866C22.7778 8.90907 20.1301 5.18302 16.4434 3.73639C16.4097 2.94378 16.0787 2.19296 15.5163 1.63343C14.9539 1.0739 14.2014 0.746726 13.4086 0.717054C12.6159 0.687381 11.841 0.957391 11.2383 1.47331C10.6357 1.98923 10.2495 2.71321 10.1566 3.50108C8.79478 3.91921 7.54253 4.63391 6.48989 5.59379C5.48689 6.50782 4.68542 7.62088 4.13662 8.86196C3.58782 10.103 3.30373 11.4449 3.30249 12.8019V17.3674C3.30249 18.6012 2.7398 19.7716 1.76429 20.5778C1.67142 20.6548 1.59671 20.7513 1.54551 20.8605C1.49431 20.9697 1.46788 21.0889 1.46811 21.2095V23.6915C1.46822 23.8588 1.51956 24.022 1.61522 24.1592C1.71089 24.2963 1.84628 24.4009 2.00318 24.4588C3.91104 25.1613 5.87813 25.691 7.88077 26.0415C8.35394 28.6023 10.5919 30.5528 13.2893 30.5528C15.9866 30.5528 18.2195 28.609 18.6973 26.0543C20.5543 25.7292 22.3808 25.2488 24.1574 24.6179C24.317 24.5613 24.455 24.4566 24.5526 24.3183C24.6502 24.18 24.7024 24.0148 24.7022 23.8455V21.2827C24.7024 21.159 24.6746 21.0369 24.6208 20.9255C24.567 20.8142 24.4887 20.7164 24.3917 20.6397H24.3897Z"
            fill="url(#paint53_linear_40_354)"
        />
        <path
            d="M13.2893 28.7942C12.4876 28.7969 11.705 28.5497 11.0503 28.087C10.3956 27.6243 9.90136 26.9691 9.63637 26.2124C10.8531 26.3631 12.0776 26.4428 13.3036 26.4513C14.5159 26.4513 15.7268 26.3552 16.9355 26.2171C16.6697 26.9723 16.1755 27.6261 15.5214 28.0879C14.8674 28.5497 14.0858 28.7965 13.2852 28.7942H13.2893ZM13.2893 2.76093C13.5783 2.76157 13.8613 2.84381 14.1056 2.9982C14.35 3.15259 14.5458 3.37285 14.6704 3.63362C14.4428 3.59525 14.2269 3.52466 13.9942 3.50369C13.3268 3.44239 12.6551 3.44616 11.9884 3.51494C12.1214 3.28749 12.3113 3.09851 12.5393 2.96651C12.7674 2.83451 13.0258 2.76402 13.2893 2.76196V2.76093ZM24.3897 20.6832C23.3666 19.8924 22.7778 18.725 22.7778 17.4759V13.3784C22.7778 9.18689 20.1301 5.53552 16.4434 4.11958C16.3631 2.47549 14.9855 1.1593 13.2872 1.1593C11.6738 1.15777 10.3525 2.35272 10.1586 3.88734C8.80114 4.29479 7.5495 4.99499 6.49194 5.93861C5.49289 6.82497 4.69234 7.91236 4.1427 9.12958C3.59307 10.3468 3.30675 11.6664 3.30249 13.0019V17.4759C3.30249 18.6851 2.7398 19.8315 1.76429 20.6223C1.67188 20.6969 1.59732 20.7912 1.54608 20.8983C1.49483 21.0054 1.46819 21.1226 1.46811 21.2413V23.6737C1.46886 23.8389 1.52069 23.9998 1.61647 24.1345C1.71226 24.2691 1.84733 24.3708 2.00318 24.4256C3.91288 25.1147 5.87972 25.6337 7.88076 25.9766C8.35394 28.4862 10.5919 30.3979 13.2893 30.3979C15.9866 30.3979 18.2195 28.4924 18.6973 25.9894C20.5529 25.6708 22.3791 25.2 24.1574 24.5817C24.3155 24.5275 24.4528 24.4255 24.5504 24.2899C24.648 24.1543 24.7011 23.9917 24.7022 23.8246V21.3134C24.7021 21.1916 24.674 21.0715 24.6202 20.9623C24.5663 20.853 24.4882 20.7576 24.3917 20.6832H24.3897Z"
            fill="url(#paint54_linear_40_354)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_40_354"
                x1="13.0852"
                y1="1.46107"
                x2="13.0852"
                y2="31.2976"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_40_354"
                x1="13.0851"
                y1="1.44678"
                x2="13.0851"
                y2="31.2833"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_40_354"
                x1="13.0852"
                y1="1.4325"
                x2="13.0852"
                y2="31.2691"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_40_354"
                x1="13.0852"
                y1="1.4181"
                x2="13.0852"
                y2="31.2546"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint4_linear_40_354"
                x1="13.0852"
                y1="1.40381"
                x2="13.0852"
                y2="31.2404"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint5_linear_40_354"
                x1="13.0851"
                y1="1.38954"
                x2="13.0851"
                y2="31.2261"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint6_linear_40_354"
                x1="13.0852"
                y1="1.37513"
                x2="13.0852"
                y2="31.2117"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint7_linear_40_354"
                x1="13.0852"
                y1="1.36085"
                x2="13.0852"
                y2="31.1974"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint8_linear_40_354"
                x1="13.0852"
                y1="1.34656"
                x2="13.0852"
                y2="31.1831"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint9_linear_40_354"
                x1="13.0851"
                y1="1.33216"
                x2="13.0851"
                y2="31.1687"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint10_linear_40_354"
                x1="13.0851"
                y1="1.31788"
                x2="13.0851"
                y2="31.1544"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint11_linear_40_354"
                x1="13.0851"
                y1="1.30359"
                x2="13.0851"
                y2="31.1401"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint12_linear_40_354"
                x1="13.0852"
                y1="1.28917"
                x2="13.0852"
                y2="31.1257"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint13_linear_40_354"
                x1="13.0852"
                y1="1.27491"
                x2="13.0852"
                y2="31.1115"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint14_linear_40_354"
                x1="13.0852"
                y1="1.26063"
                x2="13.0852"
                y2="31.0972"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint15_linear_40_354"
                x1="13.0851"
                y1="1.24622"
                x2="13.0851"
                y2="31.0828"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint16_linear_40_354"
                x1="13.0852"
                y1="1.23194"
                x2="13.0852"
                y2="31.0685"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint17_linear_40_354"
                x1="13.0852"
                y1="1.21766"
                x2="13.0852"
                y2="31.0542"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint18_linear_40_354"
                x1="13.0852"
                y1="1.20326"
                x2="13.0852"
                y2="31.0398"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint19_linear_40_354"
                x1="13.0852"
                y1="1.18892"
                x2="13.0852"
                y2="31.0255"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint20_linear_40_354"
                x1="13.0851"
                y1="1.17461"
                x2="13.0851"
                y2="31.0112"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint21_linear_40_354"
                x1="13.0852"
                y1="1.16028"
                x2="13.0852"
                y2="30.9968"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint22_linear_40_354"
                x1="13.0851"
                y1="1.146"
                x2="13.0851"
                y2="30.9825"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint23_linear_40_354"
                x1="13.0852"
                y1="1.13171"
                x2="13.0852"
                y2="30.9683"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint24_linear_40_354"
                x1="13.0852"
                y1="1.11732"
                x2="13.0852"
                y2="30.9539"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint25_linear_40_354"
                x1="13.0851"
                y1="1.10303"
                x2="13.0851"
                y2="30.9396"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint26_linear_40_354"
                x1="13.0851"
                y1="1.08875"
                x2="13.0851"
                y2="30.9253"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint27_linear_40_354"
                x1="13.0851"
                y1="1.07436"
                x2="13.0851"
                y2="30.9109"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint28_linear_40_354"
                x1="13.0852"
                y1="1.06006"
                x2="13.0852"
                y2="30.8966"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint29_linear_40_354"
                x1="13.0852"
                y1="1.04578"
                x2="13.0852"
                y2="30.8823"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint30_linear_40_354"
                x1="13.0852"
                y1="1.03138"
                x2="13.0852"
                y2="30.8679"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint31_linear_40_354"
                x1="13.0851"
                y1="1.0171"
                x2="13.0851"
                y2="30.8536"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint32_linear_40_354"
                x1="13.0852"
                y1="1.00281"
                x2="13.0852"
                y2="30.8394"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint33_linear_40_354"
                x1="13.0852"
                y1="0.987943"
                x2="13.0852"
                y2="30.825"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint34_linear_40_354"
                x1="13.0852"
                y1="0.973627"
                x2="13.0852"
                y2="30.8107"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint35_linear_40_354"
                x1="13.0852"
                y1="0.959247"
                x2="13.0852"
                y2="30.7963"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint36_linear_40_354"
                x1="13.0852"
                y1="0.944961"
                x2="13.0852"
                y2="30.782"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint37_linear_40_354"
                x1="13.0852"
                y1="0.930647"
                x2="13.0852"
                y2="30.7677"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint38_linear_40_354"
                x1="13.0851"
                y1="0.916279"
                x2="13.0851"
                y2="30.7533"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint39_linear_40_354"
                x1="13.0852"
                y1="0.901951"
                x2="13.0852"
                y2="30.739"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint40_linear_40_354"
                x1="13.0851"
                y1="0.88765"
                x2="13.0851"
                y2="30.7247"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint41_linear_40_354"
                x1="13.0851"
                y1="0.873373"
                x2="13.0851"
                y2="30.7104"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint42_linear_40_354"
                x1="13.0851"
                y1="0.858993"
                x2="13.0851"
                y2="30.6961"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint43_linear_40_354"
                x1="13.0851"
                y1="0.844665"
                x2="13.0851"
                y2="30.6817"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint44_linear_40_354"
                x1="13.0852"
                y1="0.830402"
                x2="13.0852"
                y2="30.6675"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint45_linear_40_354"
                x1="13.0852"
                y1="0.816039"
                x2="13.0852"
                y2="30.6531"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint46_linear_40_354"
                x1="13.0852"
                y1="0.801775"
                x2="13.0852"
                y2="30.6388"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint47_linear_40_354"
                x1="13.0852"
                y1="0.787359"
                x2="13.0852"
                y2="30.6244"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint48_linear_40_354"
                x1="13.0851"
                y1="0.773076"
                x2="13.0851"
                y2="30.6101"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint49_linear_40_354"
                x1="13.0851"
                y1="0.758808"
                x2="13.0851"
                y2="30.5959"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint50_linear_40_354"
                x1="13.0851"
                y1="0.744426"
                x2="13.0851"
                y2="30.5815"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint51_linear_40_354"
                x1="13.0852"
                y1="0.730067"
                x2="13.0852"
                y2="30.5671"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint52_linear_40_354"
                x1="13.0852"
                y1="0.715748"
                x2="13.0852"
                y2="30.5528"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#727272" />
                <stop offset="0.57" stopColor="#606060" />
                <stop offset="0.72" stopColor="#575757" />
                <stop offset="0.97" stopColor="#404040" />
                <stop offset="1" stopColor="#3D3D3D" />
            </linearGradient>
            <linearGradient
                id="paint53_linear_40_354"
                x1="1.46811"
                y1="15.6343"
                x2="24.7022"
                y2="15.6343"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F2F2F2" />
                <stop offset="1" stopColor="#D8D8D8" />
            </linearGradient>
            <linearGradient
                id="paint54_linear_40_354"
                x1="13.0852"
                y1="1.15777"
                x2="13.0852"
                y2="30.3979"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#BCBCBC" />
                <stop offset="1" stopColor="#9E9E9E" />
            </linearGradient>
        </defs>
    </svg>,
    'NotificationIcon',
)
