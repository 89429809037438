import React from 'react'
import { createSvgIcon } from '@mui/material'

export const DailyWheelStand = createSvgIcon(
    <svg width={522} height={249} viewBox="0 0 522 249" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M93.8859 0.355957H427.433L494.63 213.097H26.6895L93.8859 0.355957Z"
            fill="url(#paint0_radial_1693_1197)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 208.955H521.29V249H0V208.955Z"
            fill="url(#paint1_linear_1693_1197)"
        />
        <defs>
            <radialGradient
                id="paint0_radial_1693_1197"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(112.66 132) scale(365 365.231)"
            >
                <stop offset={0.154} stopColor="#181818" />
                <stop offset={0.59} stopColor="#252525" />
                <stop offset={1} stopColor="#181818" />
            </radialGradient>
            <linearGradient
                id="paint1_linear_1693_1197"
                x1={260.66}
                y1={249}
                x2={260.66}
                y2={208.955}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.32} stopColor="#191919" />
                <stop offset={0.5} stopColor="#202020" />
                <stop offset={0.77} stopColor="#222222" />
                <stop offset={1} stopColor="#333333" />
            </linearGradient>
        </defs>
    </svg>,
    'DailyWheelStand',
)
