import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { APIEndpoint } from 'shared/types'
import { API_URL } from '../../../config'
import { RootState } from 'stores/store'

export const withdraw = createAsyncThunk(
    'user/me/withdraw',
    async (
        { identifier, nonce, amount }: { identifier: string; nonce: number; amount: string },
        { rejectWithValue, getState },
    ) => {
        // check if token is expired
        const token = (getState() as RootState).auth.token
        if (!token) {
            return rejectWithValue('Token is not found')
        }
        try {
            const { data: withdraw } = await axios.post(
                API_URL + APIEndpoint.ME_WITHDRAW,
                {
                    identifier,
                    nonce,
                    amount,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            )
            return withdraw
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    },
)
