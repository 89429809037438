import React from 'react'
import { createSvgIcon } from '@mui/material'

export const MedicIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.9 89.58">
        <g
            style={{
                isolation: 'isolate',
            }}
        >
            <g id="Layer_2" data-name="Layer 2">
                <g id="OBJECTS">
                    <path
                        d="M86.93,25.12a68.47,68.47,0,0,0-10-2.21,153.8,153.8,0,0,0-19.39-2.49A136.14,136.14,0,0,0,17.4,24.17c-4.15,1-8.25,1.95-11.34,5.31-2.72,3-3.71,6.85-2.41,10.18,4,12,18.66,31.7,37.85,46.85,5.24,4.24,11.65,3.95,17,0C77.68,71.36,92.31,51.67,96.34,39.66c1.52-3.89-.13-8.48-3.83-11.52A18,18,0,0,0,86.93,25.12Z"
                        style={{
                            fill: '#008d2b',
                        }}
                    />
                    <path
                        d="M3.43,34.12H96.65q-.48-3.57-1.17-7.11H4.6Q3.91,30.55,3.43,34.12Z"
                        style={{
                            fill: '#008d2b',
                        }}
                    />
                    <path
                        d="M85.31,17.07a64.37,64.37,0,0,0-9.66-2.19,144.12,144.12,0,0,0-18.5-2.46,126.56,126.56,0,0,0-38.27,3.7c-4,1-8,1.93-11.2,5.25-2.83,2.94-4.09,6.79-3,10.1,3.12,11.92,16.55,31.64,36.44,47,5.43,4.3,12.29,4,17.83,0,19.89-15.37,33.32-35.09,36.44-47,1.23-3.87-.73-8.42-4.49-11.42A18.56,18.56,0,0,0,85.31,17.07Z"
                        style={{
                            fill: '#00c841',
                        }}
                    />
                    <path
                        d="M7.24,23.34c3.18-3.34,7.19-4.27,11.25-5.27a129.26,129.26,0,0,1,38.76-3.72A147.06,147.06,0,0,1,76,16.82,66.62,66.62,0,0,1,85.75,19a18.52,18.52,0,0,1,5.56,3,12.83,12.83,0,0,1,4.37,6.34,12.32,12.32,0,0,0-4.82-8.3,18.56,18.56,0,0,0-5.55-3,65.63,65.63,0,0,0-9.66-2.19,144.12,144.12,0,0,0-18.5-2.46,126.56,126.56,0,0,0-38.27,3.7c-4,1-8,1.93-11.2,5.25a11.62,11.62,0,0,0-3.35,6.86A12.27,12.27,0,0,1,7.24,23.34Z"
                        style={{
                            fill: '#f9f9f9',
                        }}
                    />
                    <path
                        d="M49.78,75.12a6.51,6.51,0,0,1-4.15-1.49c-18.24-14.29-30.52-32.38-34-44.7A4.16,4.16,0,0,1,12.71,25c1.15-1.26,3.2-1.8,6.67-2.65l.16,0A127.67,127.67,0,0,1,50,18.59c2.35,0,4.73.07,7.09.21a142.92,142.92,0,0,1,18.23,2.36l1.89.34A45.27,45.27,0,0,1,84,23a8.86,8.86,0,0,1,2.78,1.45,4.33,4.33,0,0,1,1.6,4.44C84.88,41.22,72.66,59.25,54.5,73.52a8.35,8.35,0,0,1-4.72,1.6Z"
                        style={{
                            fill: '#f9f9f9',
                        }}
                    />
                    <path
                        d="M11.67,29a4.39,4.39,0,0,1,.51-.71c1.14-1.27,3.2-1.81,6.71-2.66l.16,0A130.77,130.77,0,0,1,50,21.81c2.39,0,4.82.07,7.22.21A143.71,143.71,0,0,1,75.72,24.4l1.92.34a45,45,0,0,1,6.84,1.54,8.93,8.93,0,0,1,2.79,1.46,4.91,4.91,0,0,1,1,1.24s0,0,0-.05a4.33,4.33,0,0,0-1.6-4.44A8.86,8.86,0,0,0,84,23a45.27,45.27,0,0,0-6.77-1.54l-1.89-.34A142.92,142.92,0,0,0,57.06,18.8c-2.36-.14-4.74-.21-7.09-.21a127.67,127.67,0,0,0-30.43,3.73l-.16,0c-3.47.85-5.52,1.39-6.67,2.65a4.16,4.16,0,0,0-1.05,3.92Z"
                        style={{
                            fill: '#008d2b',
                        }}
                    />
                    <path
                        d="M66.37,40.42H56.12q0-4.56-.14-9.08a2.47,2.47,0,0,0-2.56-2.28H46.56A2.47,2.47,0,0,0,44,31.34q-.09,4.52-.14,9.08H33.61A2.55,2.55,0,0,0,31,42.72q-.07,3.15-.1,6.31a2.47,2.47,0,0,0,2.61,2.3H43.8q0,4.58.05,9.12a2.48,2.48,0,0,0,2.61,2.3h7.06a2.48,2.48,0,0,0,2.61-2.3q0-4.55,0-9.12H66.52A2.46,2.46,0,0,0,69.13,49c0-2.11,0-4.21-.1-6.31A2.55,2.55,0,0,0,66.37,40.42Z"
                        style={{
                            fill: '#008d2b',
                        }}
                    />
                    <path
                        d="M66.26,37.13H56.08q-.06-4.55-.17-9.05a2.45,2.45,0,0,0-2.53-2.27H46.6a2.46,2.46,0,0,0-2.53,2.27q-.1,4.5-.17,9.05H33.72a2.56,2.56,0,0,0-2.66,2.3c-.08,2.1-.13,4.2-.17,6.3A2.43,2.43,0,0,0,33.47,48H43.81q0,4.56,0,9.12a2.49,2.49,0,0,0,2.62,2.31h7.1a2.49,2.49,0,0,0,2.62-2.31q0-4.56,0-9.12H66.51a2.43,2.43,0,0,0,2.58-2.31q0-3.15-.17-6.3A2.56,2.56,0,0,0,66.26,37.13Z"
                        style={{
                            fill: '#00c841',
                        }}
                    />
                    <g
                        style={{
                            mixBlendMode: 'screen',
                        }}
                    >
                        <path
                            d="M62.69,75.37A147.89,147.89,0,0,0,76,62.67c-17.18-16-37.8-32.29-51.21-47.85q-2.93.59-5.88,1.3c-4,1-8,1.93-11.2,5.25a14.64,14.64,0,0,0-1.15,1.37C21.29,40,44.69,58,62.69,75.37Z"
                            style={{
                                fill: '#494949',
                            }}
                        />
                        <path
                            d="M32.27,13.51C45.22,27.87,64,42.84,80.3,57.69a102.81,102.81,0,0,0,7-9.48c-14-12.14-29-24.25-40.73-35.94A126.71,126.71,0,0,0,32.27,13.51Z"
                            style={{
                                fill: '#494949',
                            }}
                        />
                    </g>
                    <path
                        d="M38.15,15.66c-.13,1.5,2,2.73,4.8,2.73s5-1.23,5-2.73S45.87,13,43.21,13,38.28,14.16,38.15,15.66Z"
                        style={{
                            fill: '#f9f9f9',
                        }}
                    />
                    <path
                        d="M52.93,14.47c-.1,1,1.34,2,3.22,2.21s3.46-.49,3.5-1.51-1.42-2-3.24-2.2S53,13.44,52.93,14.47Z"
                        style={{
                            fill: '#f9f9f9',
                        }}
                    />
                    <path
                        d="M16.35,25.37a11.66,11.66,0,0,1,4.32-6.66c2.95-2.05,6.73-2.1,10.18-2.05-4,0-8.55-.08-11.67-3a11.63,11.63,0,0,1-3.27-6.41A34.79,34.79,0,0,1,15.57,0a35.73,35.73,0,0,1-1,7.66,12,12,0,0,1-4,6.44C7.61,16.33,3.67,16.56,0,16.69a19.92,19.92,0,0,1,7.79,1.07c6.23,2.4,7.83,9.67,7.73,15.66A32.87,32.87,0,0,1,16.35,25.37Z"
                        style={{
                            fill: '#f9f9f9',
                        }}
                    />
                </g>
            </g>
        </g>
    </svg>,
    'MedicIcon',
)
